import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-leave-transaction-detailesreport',
  templateUrl: './leave-transaction-detailesreport.component.html',
  styleUrls: ['./leave-transaction-detailesreport.component.css']
})
export class LeaveTransactionDetailesreportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Leave Transaction Details Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  quotationdate: any;

  models: any = [];
  branch: any = [];

  isOk = true;
  columns: string[][] = [["EMPLOYEE_NAME", "Employee Name"], ["TRANSACTION_DATE", "Transaction Date"], ["CR_COUNT", "Cr Count"], ["DR_COUNT", "Dr Count"], ["DESCRIPTION", "Narration"]];
  dataList1: any;

  START_DATE: any
  END_DATE: any
  MEMO_START_DATE: any
  MEMO_END_DATE: any
  MODEL_ID: any = []
  BRANCH_ID: any = []
  Value1 :any=new Date();
  Value2 :any=new Date();
  DATE:any=[]


  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    // this.getAllModels();
 
  //  this.DATE=new Date()
  this.search(true)
    
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  // AddSchedule() {
  //   this.isVisible1 = true;
  //   var obj = {
  //     START_DATE: this.START_DATE,
  //     END_DATE: this.END_DATE,
  //     MODEL_ID: this.MODEL_ID,
  //   }
  //   this.FILTER_QUERY = JSON.stringify(obj);
  //   this.drawerTitle1 = "Manage Schedule"
  //   console.log("hi")
  //   this.api.getAllMembers(0, 0, '', '', ' AND REPORT_ID = 20').subscribe((data: any) => {
  //     this.members = data['data'];
  //   })
  // }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

 

  exportLoading: boolean=false;

  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean, exportInExcel: boolean = false) {

    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      
    } catch (error) {
      sort = "";
    }
  
    var likeQuery = "";
    console.log("search text : " + this.searchText);
  
    if (this.searchText != "") {
      likeQuery = " AND ";
  
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
  
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) ;
    }
 if (exportInExcel == false) {
  this.loadingRecords = true;
      this.api.getAllEmployeeLeave3(this.pageIndex, this.pageSize, this.sortKey, sort,
        this.filterQuery+likeQuery).subscribe(data => {
          console.log(data)
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }, err => {
          console.log(err);
        });
    }
    else {
      // this.exportLoading = false;
      this.api.getAllEmployeeLeave3(0, 0, this.sortKey, sort, this.filterQuery+likeQuery).subscribe(data => {
          if (data['code'] == 200) {
            // this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

  

    this.Value1 = this.datePipe.transform(this.Value1, 'yyyy-MM-dd');
    this.Value2 = this.datePipe.transform(this.Value2, 'yyyy-MM-dd');
 
    if (this.Value1 != null && this.Value2!=null) {
      this.filterQuery = " AND (TRANSACTION_DATE BETWEEN '" + this.datePipe.transform(this.Value1, 'yyyy-MM-dd')  + "' AND '" + this.datePipe.transform(this.Value2, 'yyyy-MM-dd') +"')"
}



 
    this.search(true);
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.Value1='';
    this.Value2='';
 
    
 


   
  }
 
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Employee Name'] = this.dataList1[i]['EMPLOYEE_NAME'];
      obj1['Transaction Date'] = this.dataList1[i]['TRANSACTION_DATE'];
      obj1['Transaction Date'] = this.datePipe.transform(this.dataList1[i]['TRANSACTION_DATE'], 'dd MMM yyyy');

      obj1['Cr Count'] = this.dataList1[i]['CR_COUNT'];
      obj1['Dr Count'] = this.dataList1[i]['DR_COUNT'];
      obj1['Narration'] = this.dataList1[i]['DESCRIPTION'];
     
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Leave Transaction Details Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
}

 

