import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivityTypeMaster } from 'src/app/Models/ActivityType';

@Component({
  selector: 'app-activity-type-list',
  templateUrl: './activity-type-list.component.html',
  styleUrls: ['./activity-type-list.component.css']
})
export class ActivityTypeListComponent implements OnInit {
  formTitle = "Manage Activity Type";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["ACTIVITY_TYPE_NAME", "Activity Type Name"],  ["SEQUENCE_NO", "Sequence Number"],]
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ActivityTypeMaster = new ActivityTypeMaster();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: ActivityTypeMaster = new ActivityTypeMaster();
  listOfData: ActivityTypeMaster[] = [];
  OPEN_TIME2 = null;
  CLOSE_TIME2 = null;
  DAYS = false;

  constructor(private api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.api.getAllActivityTypeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery ).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

      if (this.totalRecords == 0) {
        data.SEQUENCE_NO = 1;

      } else {
        data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Activity Type";
    this.drawerData = new ActivityTypeMaster();


    this.api.getAllActivityTypeMaster(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
      }

    }, err => {
      console.log(err);
    })

    this.drawerVisible = true;
  }

  // @ViewChild(AddActivityTypeMasterComponent, { static: false }) AddActivityMasterComponentVar: AddActivityMasterComponent;

  edit(data: ActivityTypeMaster): void {
    this.drawerTitle = "Update Activity Type";
    this.drawerData = Object.assign({}, data);
    this.OPEN_TIME2 = null;
    this.CLOSE_TIME2 = null;
    this.DAYS = false;
    this.drawerVisible = true;
    // this.AddActivityMasterComponentVar.getallorg2(data.ID);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
  datalist=[]
  Activity(dataa){
    this.drawerTitle1 = "Create  Activity";
    this.drawerVisible1 = true;
    this.drawerData1 = Object.assign({}, dataa);
    this.api.getAllActivityleadMaster(0, 0, '', 'desc', ' AND CUSTOMER_ID = ' + dataa.ID).subscribe(data => {
      if (data['code'] == 200) {
       this.datalist = data['data']
       console.log(this.datalist);
       
      } 
    }, err => {
      console.log(err);
    })

    // this.drawerData1 = new ActivityTypeMaster();
  }
}