export class PSFCOLLAPSEMASTER {

    ID: number = 0;

    // BASIC info
    SEQUENCE_NUMBER: any
    WORKSHOP_CODE: any;
    BILL_DATE: any;

    // CUSTOMER INFORMATION
    CUSTOMER_NAME: any;
    MOBILE_NUMBER: any;


    // VAHICLE INFORMATION
    VIN: any;
    REG_NUMBER: any;
    MODEL_NAME: any;


    // UNKNOWN 
    R_O_NUMBER: any;
    R_O_DATE: any;
    RUC_CALLING_REMARK: any;

    // RATING INFORMATION
    CAR_MAINTAINANCE_RATE: any = '';
    CAR_CLEANLINESS_RATE: any;
    RATE_FOR_SERVICE_ADVISOR: any;
    INSURANCE_CLAIM_RATE: any;

    RATE_FOR_WORKSHOP: any;
    DAY2_CALL_COMPLAINT: any;
    CRE_ROOT: any;
    DISSATISFIED_ROOT: any;

    FOLLOWUP_DATE: any;
    CONCERN_ROOT: any = '';
    SUB_ROOT: any;
    WASHING_AND_CLEANING: any;

    DAY5_STATUS: any;
    DAY3_PSF_STATUS: any;
    REGULAR_UPDATED_STATUS: any;
    LOYAL10_STATUS: any;


    // SERVICE ADVOISER 
    SERVICE_ADVISOR_NAME: any;
    WORK_TYPE: any;
    SALE_DATE: any;
    SPECIAL_MESSAGE: any;
    FOLLOW_UP_DATE: any;
    PSF_DATE: any;
    DELIVER_ON_DATE_TIME: any;
    REMARK: any;
    ACTION_TAKEN: any = '';
    HV_DATE_OR_FOLLOW_UP_DATE: any;
    FINAL_REMARK: any;
    FAIRNESS_OF_CHARGES: any;

}