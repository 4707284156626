export class insuranceMaster{
    CLIENT_ID:any;
    FROM_DATE:any;
    INCENTIVE_STRUCUTURE_NAME:string;
    MODEL_IDS:any;
    ROLE_IDS:any;
    TO_DATE:any;
    STATUS:boolean;
    ID:number;
    DIRECT_INCENTIVE_RATES_MASTER_ID:any
}