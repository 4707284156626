import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-day-wise-attendance-report",
  templateUrl: "./day-wise-attendance-report.component.html",
  styleUrls: ["./day-wise-attendance-report.component.css"],
})
export class DayWiseAttendanceReportComponent implements OnInit {
  formTitle = "Day Wise Attendance Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'DayWiseAttendanceReport.xlsx';

  orgId = this.cookie.get("orgId");
  START_DATE: any = [];
  END_DATE: any = [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = [];
    this.END_DATE = [];
    this.setDateToDateFilter();
  }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";

    this.setDateToDateFilter();
    this.getDayWiseAttendanceReport();
  }

  applyFilter() {
    if (
      this.START_DATE != undefined &&
      this.START_DATE != null &&
      this.START_DATE.length >= 0 &&
      this.END_DATE != undefined &&
      this.END_DATE != null &&
      this.END_DATE.length >= 0
    )
      this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getDayWiseAttendanceReport(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.DayWiseAttendanceReportSortKey = sort.key;
    this.DayWiseAttendanceReportSortValue = sort.value;
    this.getDayWiseAttendanceReport(true);
  }

  DayWiseAttendanceReportPageIndex = 1;
  DayWiseAttendanceReportPageSize = 10;
  DayWiseAttendanceReportTotalRecords = 15;
  DayWiseAttendanceReportSortValue: string = "desc";
  DayWiseAttendanceReportSortKey: string = "";
  DayWiseAttendanceReportData = [];

  columns: string[][] = [
    ["IN_TIME", "Intime"],
    ["LATECOMING", "Late Coming"],
    ["LATEMARK", "Latemark"],
    ["LEAVE_COUNT", "Leave"],
    ["ABSENT", "Absent"],
  ];

  getDayWiseAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DayWiseAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayWiseAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    this.loadingRecords = true;
    this.api
      .getDayWiseAttendanceReport(
        this.DayWiseAttendanceReportPageIndex,
        this.DayWiseAttendanceReportPageSize,
        this.DayWiseAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        fromDateFilter,
        toDateFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayWiseAttendanceReportTotalRecords = data["count"];
            this.DayWiseAttendanceReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  exportexcel(): void {
    this.loadingRecords = true;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Day Wise Attendance Report');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }, 1000);
  }
}
