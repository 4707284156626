import { Component, OnInit, Input } from '@angular/core';
import { feedbackforms } from 'src/app/Models/feedbackforms';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Role } from 'src/app/Models/role';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-feedbackformapprove',
  templateUrl: './feedbackformapprove.component.html',
  styleUrls: ['./feedbackformapprove.component.css']
})
export class FeedbackformapproveComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: feedbackforms;
  passwordVisible = false;
  password: string;
  isSpinning = false;
  isSpinning1 = false;
  isSpinning2 = false;
  dataList: feedbackforms;
  roleLoading = false;
  roles: Role[];
  ROLE_ID = Number(this.cookie.get('roleId'))

  constructor(public datePipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.api.getAllfeedarea(0, 0, '', '', '').subscribe(data => {
      this.roles = data['data'];

    },);
  }

  close(): void {
    this.drawerClose();
  }
  userId = Number(this.cookie.get("userId"));
  date = new Date()
  status

  save(): void {
    console.log(this.data.REMARK);

    console.log(this.data.REMARK);
    var isOk = true;

    if (this.ROLE_ID == 30) {
      if (this.data.GM_REMARK == undefined) {
        isOk = false;
        this.message.error("Please Enter GM Remark", "");
      }
      this.data.STATUS = "P"
      this.data.GM_USER_ID = this.userId

    }

    if (this.ROLE_ID != 30) {

      if (this.data.STATUS == 'P' && this.data.GM_REMARK == null) {
        if (this.data.REMARK == null || this.data.REMARK.trim() == '') {
          isOk = false;
          this.message.error("Please Enter Remark", "");
          console.log("err")
        }
        else {
          console.log("FW")
          this.data.STATUS = "FW"
        }
      }

      else if (this.data.STATUS == 'P' && this.data.REMARK != null && this.data.GM_REMARK != null) {
        this.data.STATUS = "A"
      }

      else if (this.data.STATUS == 'A' && this.data.REMARK != null && this.data.GM_REMARK != null) {
        if (this.data.INPROCESS_REMARK == null || this.data.INPROCESS_REMARK.trim() == '') {
          isOk = false;
          this.message.error("Please Enter In-Process Remark", "");
        }
        else {
          this.data.STATUS = "I"
        }
      }

      else if (this.data.STATUS == 'I' && this.data.REMARK != null && this.data.GM_REMARK != null && this.data.INPROCESS_REMARK != null) {
        if (this.data.CLOSED_REMARK == null || this.data.CLOSED_REMARK.trim() == '') {
          isOk = false;
          this.message.error("Please Enter Closed Remark", "");
        }
        else {
          this.data.STATUS = "C"
        }
      }

      else {
        console.log("Nothing")

      }

    }
    // if(this.data.REMARK != null){
    //   console.log(value)
    //   if (value == 'A') {
    //     this.isSpinning = true;
    //   }
    //   else if (value == 'OH') {
    //     this.isSpinning1 = true;

    //   }
    //   else {
    //     this.isSpinning2 = true;

    //   }


    // this.status =value
    // this.data.STATUS = this.status
    if (isOk) {
      this.data.CHECKED_BY_USER_ID = this.userId
      this.data.CHECKED_DATETIME = this.datePipe.transform(this.date, "yyyy-MM-dd")

      if (this.data.ID) {
        this.isSpinning = true
        this.api.updatefeedform(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false
            // if (value == 'R') {
            //   this.message.warning("feedback information Not updated Successfully...", "");
            // } else {
            this.message.success("Feedback Information Updated Successfully...", "");
            // }

            // if (!addNew)
            this.drawerClose();
            this.isSpinning = false;
            this.isSpinning1 = false;
            this.isSpinning2 = false;

          } else {
            this.message.error("Failed To Update Feedback Information...", "");
            this.isSpinning = false;
            this.isSpinning1 = false;
            this.isSpinning2 = false;

          }
        });

      }
    }
    // }

    // else{
    //   this.message.error('Please enter remark','')
    // }

  }

  reject(): void {

    var isOk = true

    if (this.ROLE_ID != 30) {

      if (this.data.STATUS == 'P' && this.data.GM_REMARK == null) {
        if (this.data.REMARK == null || this.data.REMARK.trim() == '') {
          isOk = false;
          this.message.error("Please Enter Remark", "");
          console.log("err")
        }
        else {
          console.log("FW")
          this.data.STATUS = "FW"
        }
      }
      else if (this.data.STATUS == 'P' && this.data.GM_REMARK != null && this.data.REMARK != null) {
        this.data.STATUS = 'R'
      }
      else {

      }

    }

    if (isOk) {

      this.data.CHECKED_BY_USER_ID = this.userId
      this.data.CHECKED_DATETIME = this.datePipe.transform(this.date, "yyyy-MM-dd")

      if (this.data.ID) {
        this.isSpinning2 = true
        this.api.updatefeedform(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning2 = false
            // if (value == 'R') {
            //   this.message.warning("feedback information updated Successfully...", "");
            // } 
            //  {
            this.message.success("feedback information updated Successfully...", "");
            // }

            // if (!addNew)
            this.drawerClose();
            this.isSpinning = false;
            this.isSpinning1 = false;
            this.isSpinning2 = false;

          } else {
            this.message.error("Failed to update feedback information...", "");
            this.isSpinning = false;
            this.isSpinning1 = false;
            this.isSpinning2 = false;

          }
        });

      }

    }
  }

  close1(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  searchEmail(emailId): void {
    var likeQuery = "EMAIL_ID=" + emailId + "";

    this.api.getAllfeedform(0, 0, "", "", likeQuery).subscribe(data => {
      console.log(data);
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

}
