import { ApiService } from 'src/app/Service/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit,Input } from '@angular/core';
// import html2canvas from 'html2canvas';
import { quotation } from 'src/app/Models/quotation';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-quotation-view',
  templateUrl: './quotation-view.component.html',
  styleUrls: ['./quotation-view.component.css']
})
export class QuotationViewComponent implements OnInit {
@Input() drawerClose
 isVisible:boolean=false
  loadingRecords:boolean=false
  formTitle="Quotation"
  totalRecords: any;
  ROLE_ID = Number(this.cookie.get('roleId'));
 
  // data:quotation=new quotation
  constructor(public api: ApiService,private sanitizer: DomSanitizer,private cookie: CookieService,private message: NzNotificationService,) { }
  sanitizedLink:any
  imgurl= this.api.retriveimgUrl;
  searchText: string = "";
  STATUS:any=[]
  orgId = this.cookie.get('orgId');
  userid:any = this.cookie.get('userId')
   filterQuery:any = [];
   filterClass: string = "filter-invisible";
  ACTION:any
  sortKey: string = "ID";
  sortValue: string = "desc";
 isFilterApplied = "default";
  pageIndex = 1;
  pageSize = 10;
  CANCELATION_STATUS: string;
  APPROVAL_REMARK: any;
  filterQuery1: string;
  @Input()dataList123
  @Input()dataList1
  @Input()data12 :quotation=new quotation

  // htmltoPDF() {
     
  //   const portalDiv = document.getElementById('print')!;
  // html2canvas(portalDiv, { scale: 3.0 }).then(canvas => {
  
  // var pdf = new jspDF .jsPDF('p', 'pt', [canvas.width, canvas.height]);
  
  // var imgData = canvas.toDataURL("image/jpeg", 7.0);
  // pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
  // pdf.save('PledgeForm.pdf');
  
  // });
  
  // }
  // handleApkVersionModalOk(){
   

  //   this.generatePDF()

  // }

  // public generatePDF() {
  

  //   var data = document.getElementById('print');
   
  //   var opt = {
  //     margin:       0.2,
  //     filename:     'myfile.pdf',
  //     image:        { type: 'jpeg', quality: 0.98 },
  //     html2canvas:  { scale: 4 },
  //     jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait' }
  //   };




  // }

  htmltoPDF() {
    var jsPDF: any;
    const portalDiv = document.getElementById('print')!;
  // html2canvas(portalDiv, { scale: 3.0 }).then(canvas => {
  
  // var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
  
  // var imgData = canvas.toDataURL("image/jpeg", 7.0);
  // pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
  // pdf.save('PledgeForm.pdf');
  
  // });
  
  }
//   htmltoPDF() {
//   let doc = new jsPDF();
// let elementHTML = document.getElementsByClassName('print');
// console.log(elementHTML);
// doc.html(document.body.innerHTML, {
// callback: function (doc) {
// doc.save();
// }
// });
//   }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible=false
    this.APPROVAL_REMARK=''
    this.APPROVAL_ID=''
    this.APPROVAL_ID=[]
    this.APPROVAL_ID==null
   
  }

columns: string[][] = [["CUSTOMER_NAME", "CUSTOMER_NAME"], ["APPLIER_NAME", "APPLIER_NAME"], ["APPROVER_NAME", "APPROVER_NAME"], ["VARIENT_NAME", "VARIENT_NAME"], ["MODEL_NAME", "Model"], ["QUOTATION_NO", "QUOTATION_NO."],["SALES_EXE_REMARK",'SALES_EXE_REMARK'] ,["STATUS", "Status"]];
 
//getS(data.IMAGE_PATH)
// getS(link:string){
    
//     var a= this.imgurl+'CancelationImage/'+link+'#toolbar=0'
//     this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

//     return  this.sanitizedLink ;
  
//   }
  ngOnInit() {
    // this.search(true)
 
  }
  options:any
  onGet(){
    this.api.getQuotation1(0,0,'','',this.filterQuery).subscribe((data:any)=>{
      this.options=data['data'];
      
    })
  }
  // saleHierarchy1:any
  // onGet1(){
  //   this.api

  //     .getAllemployeeMaster(

  //       0,

  //       0,

  //       "NAME",

  //       "asc",

  //       " AND STATUS=1 AND ORG_ID=" +

  //       this.orgId +

  //       " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +

  //       this.cookie.get("orgId") +

  //       " AND ROLE_ID=30)"

  //     )

  //     .subscribe(

  //       (data) => {

  //         if (data["code"] == 200) {

  //           this.saleHierarchy1 = data["data"];

  //         }

  //       },

  //       (err) => {

  //         if (err["ok"] == false)
  //         console.log("Server Not Found", "");

  //       }

  //     );
  // }


data1
isOk:boolean=false
isSpinning:boolean=false
APPROVAL_ID:any
IMAGE_PATH:''
isVisible1:boolean=false
ID:any
approval(){


    this.data12['CANCELATION_STATUS']="FW" 
    this.data12['APPROVAL_REMARK']=this.APPROVAL_REMARK
    this.data12['APPROVAL_ID']=this.userid
    this.data12['NEW_APPROVER_ID']=this.APPROVAL_ID

    this.data12['ID']=this.data12.ID
    this.api.approveQuotation(this.data12).subscribe(successCode => {
      if (successCode['code'] == 200) {
     this.isSpinning=false
      this.message.success("Forwarded Successfully...", "");
      this.drawerClose()
      // this.search()
      this.APPROVAL_ID=''
      this.APPROVAL_ID=[]
      this.APPROVAL_REMARK=''
     }
       else
       {
        this.message.error("Falied To Forward...", "");
        this.isSpinning = false;
       }
      });
      this.isVisible1=false
          this.isVisible=false
       
      this.APPROVAL_REMARK=''
      this.APPROVAL_ID=''
      this.APPROVAL_ID=[]
    
  }
Reject(){
  this.data12['CANCELATION_STATUS']="RJ"
    this.data12['APPROVAL_REMARK']=this.APPROVAL_REMARK
    this.data12['APPROVAL_ID']=this.APPROVAL_ID
this.isOk=true;
     if (this.APPROVAL_REMARK== null || this.APPROVAL_REMARK.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Remark', '');
    } 
 
    if(this.isOk)
    {
  
      this.api.approveQuotation(this.data12).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Rejected Successfully...", "");
          this.isVisible2=false
          this.isVisible=false
          this.drawerClose()
          // this.search()
          this.APPROVAL_ID=''
          this.APPROVAL_REMARK=''
         }
           else
           {
            this.message.error("Failed To Reject...", "");
            this.isSpinning = false;
           }
          });
  }
}
status
dataList2
search(reset: boolean = false,exportInExcel:boolean=false) {
  if (reset) {
    this.pageIndex = 1;
  }

  var sort: string;
  try {
    sort = this.sortValue.startsWith("a") ? "asc" : "desc";

  } catch (error) {
    sort = "";
  }

  var likeQuery = "";
  if (this.searchText != "") {
    likeQuery = " AND (";

    this.columns.forEach(column => {
      likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    });

    likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
  }

 


  if (this.STATUS.length > 0) {
    this.filterQuery =  'AND STATUS=' + "'" + this.STATUS + "'";

  }
  else{
    this.filterQuery =  'AND STATUS=' + "'" + ['RQ'] + "'";
  }
  this.filterQuery1 =  'AND APPROVAL_ID=' + "'" +this.userid+ "'";
  if (exportInExcel == false) {
  this.loadingRecords = true;
  this.api.getQuotation1(0,0,'','',this.filterQuery+ likeQuery+this.filterQuery1 ).subscribe(data => {
    if (data['code'] == 200) {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList2 = data['data'];
    
      this.status= data['data'][0]['STATUS']
      console.log( this.status,"status");

    }
  }, err => {
    if (err['ok'] == false)
    console.log('ok')
  });
}

}
applyFilter() {
  if ( (this.STATUS.length > 0))
    this.isFilterApplied = "primary";

  else
    this.isFilterApplied = "default";

  this.search(true);
  this.filterClass = "filter-invisible";
}
clearFilter() {
  this.STATUS = [];
  this.isFilterApplied = "default";
  this.filterClass = "filter-invisible";
  this.search(true);
}

showFilter(): void {
  if (this.filterClass === "filter-visible")
    this.filterClass = "filter-invisible";

  else
    this.filterClass = "filter-visible";
}
showModal(): void {
  this.isOk=true;
  if(this.APPROVAL_ID== null|| this.APPROVAL_ID == ''){
    this.isOk = false;
    if(this.ROLE_ID==34){
    this.message.error('Please Select VIN', '');
    }
    if(this.ROLE_ID==36){
    this.message.error('Please Select Accountant', '');
    }
    if(this.ROLE_ID==13){
    this.message.error('Please Select General Manager', '');
    }
    if(this.ROLE_ID==29){
      this.message.error('Please Select Sales Head', '');
      }
   }
   else if (this.APPROVAL_REMARK== null || this.APPROVAL_REMARK.trim() == '') {
   this.isOk = false;
   this.message.error('Please Enter Remark', '');
 } 
 if(this.isOk)
 {
  this.isVisible1 = true;
 }
}
saleHierarchy1:any
onGet1(){
  this.api

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID =36' + " AND CLUSTER_ID =" + this.cookie.get("clusterId") ).subscribe(data => {
      
          if (data["code"] == 200) {
            this.saleHierarchy1 = data["data"];
          }
      
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
  
  
      })

  }
onGet2(){
  this.api

 

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID =13' + " AND CLUSTER_ID =" + this.cookie.get("clusterId") ).subscribe(data => {
      
          if (data["code"] == 200) {
            this.saleHierarchy2 = data["data"];
          }
      
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
  
  
      })
}
onGet3(){
  this.api

  this.api.getAllEmpRoleMap(0,
    0,
    "ID",
    "asc", ' AND ROLE_ID =30' + " AND CLUSTER_ID =" + this.cookie.get("clusterId") ).subscribe(data => {
    
        if (data["code"] == 200) {
          this.saleHierarchy3 = data["data"];
        }
    
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }


    })
}
saleHierarchy2:any
saleHierarchy3:any
saleHierarchy111=[]
showModal1(): void {
  // this.data12=new quotation()
  this.isVisible = true;
this.onGet1()
this.onGet2()
  this.onGet3()

  if(this.ROLE_ID == 29){
    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID =34' + " AND CLUSTER_ID =" + this.cookie.get("clusterId") ).subscribe(data => {
      
          if (data["code"] == 200) {
            this.saleHierarchy111 = data["data"];
          }
      
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
  
  
      })
  }
// console.log(this.data12,'data')
}
handleCancel1(){
  this.isVisible1=false
}
isVisible2:boolean=false
handleCancel2(){
  this.isVisible2=false
}
showModal2(): void {
  this.isOk=true;
 
   if (this.APPROVAL_REMARK== null || this.APPROVAL_REMARK.trim() == '') {
   this.isOk = false;
   this.message.error('Please Enter Remark', '');
 } 
 if(this.isOk)
 {
  this.isVisible2 = true;
 }
}
}



