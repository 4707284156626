import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { InsuranceMaster } from 'src/app/Models/InsuranceMaster';
import { ApiService } from 'src/app/Service/api.service';
import { InsuranceMaster } from '../../Incentiveforms/Insuranceincentive/InsuranceMaster';

@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.css']
})

export class InsuranceListComponent implements OnInit {
  formTitle = "Manage Insurance";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortKey: string = "id";
  sortValue: string = "desc";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Insurance Name"], ["SHORT_NAME", "Short Name"], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: InsuranceMaster = new InsuranceMaster();

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.loadingRecords = true;

    this.api.getAllInsurances(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  add(): void {
    this.drawerTitle = "Add Insurance Details";
    this.drawerData = new InsuranceMaster();
    this.drawerData.STATUS = true;
    this.drawerVisible = true;
  }

  edit(data: InsuranceMaster): void {
    this.drawerTitle = "Update Insurance Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onStatusChange(data: InsuranceMaster, status) {
    data.STATUS = status;

    this.api.updateInsurance(data).subscribe((successCode) => {
      if (successCode["code"] == 200) {
        this.message.success("Status Updated Successfully", "");

      } else {
        this.message.error("Failed to Update Status", "");
      }

      this.search(false);
    });
  }
}
