import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { Leave } from '../Models/Leave';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
// import { rateMaster } from '../Models/IncentiveRateMaster';
import { idLocale } from 'ngx-bootstrap';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
import { Console } from 'console';
import { Loghistory } from 'src/app/Models/loghistory';


@Component({
  selector: 'app-support-staff-mapping',
  templateUrl: './support-staff-mapping.component.html',
  styleUrls: ['./support-staff-mapping.component.css']
})
export class SupportStaffMappingComponent implements OnInit {

  @Input() drawerClose12: Function;

  @Input() data: Leave;
  // @Input() dataListtt = []
  

  @Input() drawerVisible: boolean;
  isSpinning = false;
  roles = [];
  model = [];
  leaveTypes = [];
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];
  @Input() selectedIndex

  Array1 = [];
  dataList1 = []

  MODEL1: []
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence Number"],]

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  modeltype = []
  fueltype = []
  varienttype = []
  tranmission = []
  isSelected: any;
  @Input() dataListtt = []
  @Input() Roletype1 = [];



  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    // this.getLeaveTypes();
    // this.getLeaveTypeMaster();
    // this.loadRoles();
    this.getModels();
    this.getRateMaster();
    // this.getdata()
    this.getAreaMaster()
    // console.log(this.data1);

  }

  getAreaMaster() {
    this.api.getAreaMaster(0, 0, '', 'asc', 'AND STATUS=1').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  // loadRoles() {
  //   this.api.getAllRoles(0, 0, '', 'asc', 'AND STATUS=1').subscribe(roles => {
  //     this.roles = roles['data'];
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  getModels() {
    this.model = [];

    this.api.getAllModels(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllVarients(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllFuel(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


    this.api.getAllTransmission(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.tranmission = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }



  getLeaveTypes() {
    this.leaveTypes = [];

    this.api.getAllLeaveType(0, 0, 'NAME', 'asc', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  rateMaster = [];
  getRateMaster() {
    this.api.getRateMaster(0, 0, '', 'asc', '').subscribe((data1) => {
      console.log(data1);
      this.rateMaster = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }


  @Input() ROLES22 = []
  @Input() MODELS22 = []
  @Input() Modeldata = []

  // applyFilter() {
  //   this.dataList = [];
  //   this.finalData = []
  //   var isOk = true;

  //   if (this.ROLES2.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Roles", "");

  //   } else if (this.MODELS2.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Models", "");
  //   }

  //   if (isOk) {
  //     this.ROLES = this.ROLES2
  //     this.MODELS = this.MODELS2
  //     this.ROLES22 = []
  //     for (let i = 0; i < this.ROLES.length; i++) {
  //       var kk = this.roles.findIndex(x => x.ID == this.ROLES[i]);
  //       this.ROLES22.push(this.roles[kk]['NAME']);
  //       this.MODELS22 = []
  //       for (let j = 0; j < this.MODELS.length; j++) {
  //         this.finalData.push({ "ROLE_ID": this.ROLES[i], "MODEL_ID": this.MODELS[j], "AMOUNT": 0 })
  //         // console.log(this.finalData);
  //         var kkk = this.model.findIndex(x => x.ID == this.MODELS[j]);
  //         this.MODELS22.push(this.model[kkk]['NAME'])
  //       }
  //       if (i + 1 == this.ROLES.length) {
  //         this.dataList = [...[], ...this.finalData]
  //       }



  //     }
  //     // for (let m = 0; m < this.dataList.length; m++) {
  //     //   this.dataList.push({'ID': m + 1})
  //     //   // console.log(this.dataList);
  //     // }
  //   }
  //   this.MODELS2 = []
  //   this.ROLES2 = []
  // }

  // deleterow(index: any) {
  //   var selected = this.ROLES[index];
  //   var d = this.dataList.filter((item) => {
  //     if (item.ROLE_ID != this.ROLES[index])
  //       return item;
  //   })

  //   // this.ROLES = this.ROLES.filter(item => item !== selected)
  //   this.ROLES.splice(index, 1);
  //   this.ROLES22.splice(index, 1);
  //   this.ROLES = [...[], ...this.ROLES]
  //   this.ROLES22 = [...[], ...this.ROLES22]
  //   this.dataList = [...[], ...d]
  //   this.finalData = [...[], ...d];
  // }

  // deletecoloumn(index) {

  //   var d = this.dataList.filter((item) => {
  //     if (item.MODEL_ID != this.MODELS[index])
  //       return item;
  //   })
  //   console.log(d)
  //   this.MODELS.splice(index, 1);
  //   this.MODELS22.splice(index, 1);
  //   this.MODELS = [...[], ...this.MODELS]
  //   this.MODELS22 = [...[], ...this.MODELS22]

  //   this.dataList = [...[], ...d]
  //   this.finalData = [...[], ...d];
  // }

  close(): void {

    this.drawerClose12();

    this.ROLES = []
    this.Modeldata = []
    this.ROLESSS = []
    this.va = []

  }

  close1(): void {
    this.drawerClose12();
    this.dataListtt = []
    this.MODELSSSS = []
    this.va = []
    this.ROLESSS = []
  }
  closeDrawer() {
    this.drawerClose12()
    console.log('clicked');

  }


  // reset(myForm: NgForm) {
  //   myForm.form.reset();
  // }

  //   mapBranch(data: rateMaster) {

  //    this.MappingData = Object.assign({},data);
  //   //  console.log(this.assign,'dd')

  //   this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.leaveTypeDetails = data['data'];
  //     }

  //   }, err => {
  //     console.log(err);
  //   });



  //    // this.drawerTitleEmployeeCollege = "Location for " + data.NAME + "";
  //    // this.user = Object.assign({},Data);

  //  }
  kkkkk = []
  roleIds = []
  nextsdata = []

  save11(dataListtt: any) {
    console.log(this.SendData);
    this.message.success("Role Selected Successfully...", '')
    this.selectedIndex = 1
    this.nextsdata = []
    var m = ''
    var n = ''
    var l = ''
    var o = ''

    if (this.SendData.length > 0) {
      for (let i = 0; i < this.SendData.length; i++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.SendData[i]['MODEL1']);
        m = this.modeltype[kk]['NAME'];

        if (this.SendData[i]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.SendData[i]['FULES']);
          n = this.fueltype[kks]['NAME'];
        } else {
          n = 'All'
        }

        if (this.SendData[i]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.SendData[i]['VARIENT']);
          l = this.varienttype[aa]['NAME'];
        } else {
          l = 'All'
        }

        if (this.SendData[i]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.SendData[i]['TRANSMISSION']);
          o = this.tranmission[vv]['NAME'];
        } else {
          o = 'All'
        }

        this.nextsdata.push({
          'MODEL1': m,
          "FULES": n,
          "VARIENT": l,
          "TRANSMISSION": o
        })

      }
    }

    this.Modeldata = this.nextsdata;
    console.log(this.Modeldata);

    this.roleIds = []

    for (let i = 0; i < this.Roletype1.length; i++) {
      if (this.Roletype1[i]['IS_SELECTED'] == true) {
        this.roleIds.push(this.Roletype1[i]['ID'])
      }
    }

    console.log(this.roleIds);
  }
  SendData1 = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []
  @Input() ROLEIDS = []
  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []
  disabled2 = true
  disabled1 = true
  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }

a=[]

  // getAmount() {

  //   if (this.dataListtt.length > 0) {


  //     this.dataListtt.forEach((x, index) => {
  //       this.Final.forEach((x2, index2) => {
  //         if (x.SUPPORT_STAFF_MAPPING_ID == 0 &&
  //           x.SUPPORT_STAFF_AREA_ID == x.SUPPORT_STAFF_AREA_ID &&
  //           x.MODEL_ID == x2.MODEL_ID &&
  //           x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
  //           x.VARIENT_ID == x2.VARIENT_ID &&
  //           x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
  //           // this.Final[index2]['AMOUNT'] = this.dataListtt[index].AMOUNT

  //           this.Final[index2] = Object.assign({}, this.dataListtt[index])
  //           // console.log(this.dataListtt[index].AMOUNT,"datalist");

  //         }
  //       })
  //       if (index + 1 == this.dataListtt.length) {
  //         console.log(this.Final, "final")
         
  //         this.dataListtt = [...[], ...this.a];
  //         console.log(this.dataListtt);

  //       }
  //     });
  //   } else {
  //     this.dataListtt = [...[], ...this.Final];
  //   }

  // }

  getAmount() {

    if (this.dataListtt.length > 0) {


      this.dataListtt.forEach((x, index) => {
        this.Final.forEach((x2, index2) => {
          if (x.SUPPORT_STAFF_MAPPING_ID ==0  &&
            x.SUPPORT_STAFF_AREA_ID==x.SUPPORT_STAFF_AREA_ID &&
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Final[index2]['AMOUNT'] = this.dataListtt[index].AMOUNT
          }
        })
        if (index + 1 == this.dataListtt.length) {
          this.dataListtt = [...[], ...this.Final];
          console.log(this.dataListtt);

        }
      });
    } else {
      this.dataListtt = [...[], ...this.Final];
    }

  }
  save(Modeldata: any) {
    this.message.success("Model Selected Successfully...", '')
    this.selectedIndex = 2
    console.log(Modeldata);
    this.SendData1 = []
    this.Final = []
    this.va = []
    this.MODELIDS = []
    this.ROLEIDS = []
    this.ROLESSS = this.roleIds
    console.log(this.ROLESSS,"this.ROLESSS");
    
    this.MODELSSSS = this.SendData
    var abc = -2
    for (let i = 0; i < this.ROLESSS.length; i++) {
      var kk = this.Roletype1.findIndex(x => x.ID == this.ROLESSS[i]);
      this.ROLEIDS.push(this.Roletype1[kk]['NAME']);
      console.log(this.ROLEIDS,"ROLEIDS")
      this.va = [];
      this.SendData1 = [];
      for (let j = 0; j < this.MODELSSSS.length; j++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.MODELSSSS[j]['MODEL1']);
        var ss = this.modeltype[kk]['NAME']
        this.MODELIDS.push(this.MODELSSSS[j]['MODEL1']);

        var a = ''
        var b = ''
        var c = ''
        if (this.MODELSSSS[j]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.MODELSSSS[j]['FULES']);
          a = this.fueltype[kks]['NAME']

        } else {
          this.MODELSSSS[j]['FULES'] = 0

          a = ""
        }

        if (this.MODELSSSS[j]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.MODELSSSS[j]['VARIENT']);
          b = (this.varienttype[aa]['NAME']);
        } else {
          this.MODELSSSS[j]['VARIENT'] = 0
          b = ""
        }

        if (this.MODELSSSS[j]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.MODELSSSS[j]['TRANSMISSION']);
          c = (this.tranmission[vv]['NAME']);
        } else {
          this.MODELSSSS[j]['TRANSMISSION'] = 0
          c = ""

        }

        this.va.push(this.str(this.modeltype[kk]['NAME'], a, b, c))
        this.ids.push([this.MODELSSSS[j]['MODEL1'], this.MODELSSSS[j]['FULES'], this.MODELSSSS[j]['VARIENT'], this.MODELSSSS[j]['TRANSMISSION']])
        this.SendData1 = [...this.SendData1, this.MODELSSSS[j]['MODEL1'] + "." + this.MODELSSSS[j]['FULES'] + "." + this.MODELSSSS[j]['VARIENT'] + "." + this.MODELSSSS[j]['TRANSMISSION']];
        // console.log(this.ids);

        this.Final.push({
          "SUPPORT_STAFF_AREA_ID": this.ROLESSS[i],
          "MODEL_ID": this.MODELSSSS[j]['MODEL1'],
          "FUEL_TYPE_ID": this.MODELSSSS[j]['FULES'],
          "VARIENT_ID": this.MODELSSSS[j]['VARIENT'],
          "TRANSMISSION_TYPE_ID": this.MODELSSSS[j]['TRANSMISSION'],
          "AMOUNT": 0
        })


      }
      if (i + 1 == this.ROLESSS.length) {

        this.getAmount()
      }

    }
  }


  today = new Date().setDate(new Date().getDate() + 4);

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  // @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.FROM_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }


  sendjson(dataListtt: any) {
    var isOk = true;


    if (dataListtt.length == 0) {
      isOk = false;
      this.message.error("Please Select Roles And Models", "");
    }
    var obb = ({
      "DIRECT_INCENTIVE_RATES_MASTER_ID": this.data.ID,
      "STAFF_IDS": this.ROLESSS.toString(),
      "MODEL_IDS": this.SendData1.toString(),
      "data": this.dataListtt
    })

    if (isOk) {
      this.isSpinning = true;


      this.api.SupportStaffAddBulk(obb).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Roles And Models Mapped Successfully", "");

          this.dataListtt = []
          this.MODELSSSS = []
          this.va = []
          this.ROLESSS = []
          this.drawerClose12()
          this.isSpinning = false;


        } else {
          this.message.error("Roles And Models Mapping Failed", "");
          this.isSpinning = false;
        }
      });

    }
  }

  // Roletype1 = [];

  getLeaveTypeMaster() {

    this.api.getAreaMaster(0, 0, '', '', '').subscribe(roles => {
      this.dataListtt = roles['data'];
      // console.log(this.dataListtt, "Roletype1s");
      // this.dataListtt = this.dataListtt.filter(i => {

      //   if (i.ID > 38 && i.ID < 44) {

      //     return this.isSelected = true;
      //   }

      // })
      console.log(this.dataListtt, "Result");

    }, err => {
      console.log(err);
    });

  }

  // onLeaveTypeSelected(leaveType) {
  //   var leaveData = this.leaveTypeDetails.filter(obj => {
  //     return (obj.ID == leaveType);
  //   });

  //   if (leaveData.length != 0)
  //     this.today = new Date().setDate(new Date().getDate() + Number(leaveData[0]["LEAVE_APPROVAL_DAYS"]));
  //   else
  //     this.today = new Date().setDate(new Date().getDate() + Number(4));

  //   this.data.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
  //   this.data.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");

  //   this.disabledStartDate = (current: Date): boolean =>
  //     differenceInCalendarDays(current, this.today) < 0;
  // }

  onVChange(amt: number,s,support_staff_id) {
    console.log( support_staff_id,'support_staff_id');

    this.dataListtt.forEach(item=>{
       if(item.SUPPORT_STAFF_AREA_ID == support_staff_id){
        console.log( item.SUPPORT_STAFF_AREA_ID,'item.SUPPORT_STAFF_AREA_ID');
         item['AMOUNT'] = amt;
         item['AMOUNT'] = item['AMOUNT']
       }
    } ) 
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

}
