import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { memoMaster } from 'src/app/Models/memo';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-acsnewview',
  templateUrl: './acsnewview.component.html',
  styleUrls: ['./acsnewview.component.css']
})
export class AcsnewviewComponent implements OnInit {
  formTitle = "All Memo Details";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  isSpinning:boolean = false
  sortKey: string = "ID";
  sortValue: string = "desc";
  searchText: string = "";
  columns: string[][] = [["MEMO_NO", "Memo No."], ["CUSTOMER_NAME", "Customer Name"], 
  ["CR_DATE", "CR Date"], ["CR_REMARK", "CR Remark"], ["INSURANCE_DATE", "Insurance Date"], 
  ["INSURANCE_REMARK", "Insurance Remark"],   ["REG_NO", "Vehicle No."], 
  ["REG_DATE", "Registration Date"], ["REG_REMARK", "Registration Remark"]];
  drawerVisible: boolean;
  recieptData = []
  drawerTitle: string;
  drawerFormPrintData: any
  
  visible = false
  isVisible = false;
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  userId = this.cookie.get('userId')
  questionary = new questionaryMaster();
  employeeget = new EmployeeMaster()
  advanceArray: [];
  memoDetailsArray: [];
  quotedPrice: number;
  memoSignature = new memoSignatureDetails();
  dataid: any;
  discount1 = 0
  discount2 = 0
  exchange = 0
  sum1 = 0
  sum2 = 0
  sum3 = 0
  sum4 = 0
  sum5 = 0
  sum6 = 0
  sum7 = 0
  sum8 = 0
  sum9 = 0
  // sum3 = 0
  rTo: any
  tcs: any
  sdfsdf: any
  sdfsdf2: any
  sdfsdf3: any
  sdfsdf4: any
  sdfsdf5: any
  sdfsdf6: any
  sdfsdf7: any
  formname: any
  emproledata=[]
  iddd:any
  // signature = this.cookie.get('signature')
  // accountname = sessionStorage.getItem('accountname')

  constructor(private domSanitizer: DomSanitizer, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search1(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search1(true);
  }

  startValue = new Date()
  endValue: any = new Date()
  TO_DATE :any = new Date()
  FROM_DATE :any=  new Date(this.startValue.getTime() - 7 * 24 * 60 * 60 * 1000);
    
  moduleStartDateHandle() {
   
     
    this.TO_DATE = new Date()
    // this.moduleEndDatePicker.open();
  
}
  search1(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var memoDateFilter = "";
    if (this.FROM_DATE != null) {
      this.FROM_DATE =  this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd')
    }
    if (this.TO_DATE != null) {
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd')
    }

    var memoStatusFilter:any = [];
    if (this.STATUS.length > 0) {
      memoStatusFilter = this.STATUS

    } else {
      memoStatusFilter = ["RQ"];
    }

    this.loadingRecords = true;
    this.api.getMemoDetails(this.pageIndex, this.pageSize,'','',likeQuery +' AND INVOICE_NO != ' + "''" + " GROUP BY QUOTATION_ID " ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
           console.log(this.dataList,"ok")
   
          //  this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND ID =' + this.userId).subscribe(data => {
          //    if (data['code'] == 200 && data['count'] > 0) {
          //      // this.loadingRecords = false;
          //      this.employeeget = data['data'][0];
          //      this.employeeget.SIGNATURE = data['data'][0]['SIGNATURE']
          //      console.log(this.employeeget, "");
          //      sessionStorage.setItem('signature', data['data'][0]['SIGNATURE'])
          //      sessionStorage.setItem('accountname', data['data'][0]['NAME'])
          //      // sessionStorage.setItem('accountname',data['data'][0]['NAME'])
   
          //      // this.dataList = data['data'];
   
   
          //    }
   
          //  }, err => {
          //    if (err['ok'] == false)
          //      this.message.error("Server Not Found", "");
          //  });
         }
   
       }, err => {
         if (err['ok'] == false)
           this.message.error("Server Not Found", "");
       });
      
     

   
  }

  openDrawer(data): void {
    console.log(data);
    this.visible = true
    this.isSpinning=true
    this.api.getAdvanceReciept(0,0,'',"asc",  'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data['data']);
        this.recieptData = data['data']
        this.isSpinning=false
        for (let i = 0; i < this.recieptData.length; i++) {
          console.log(this.recieptData[i]['RECEIPT_PHOTO']);
        }
      }
    })
  }

  close(): void {
    this.visible = false;
  }

  drawerClose(): void {
    this.search1(false);
    this.drawerVisible = false;
  }


  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  DATE = [];
  STATUS = [];

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    if ((this.DATE.length > 0) || (this.STATUS.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search1(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.DATE = [];
    this.STATUS = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search1(true);
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  getStatusFullForm(status) {
    if (status == "RQ")
      return "Requested";

    else if (status == "FW")
      return "Forward";

    else if (status == "AP")
      return "Approved";

    else if (status == "RJ")
      return "Rejected";
  }
  url:any
  url1:any
  
  getAttachment(data) {
    console.log(data);
    
    this.api.getAllCustomer(0, 0, '', 'asc', ' AND ID ='+ data.CUSTOMER_ID).subscribe(data => {
         console.log(data['data']);
         if(data['data'][0]['PAN_URL']!=''){
          this.url = data['data'][0]['PAN_URL']
          window.open(this.api.retriveimgUrl + "panImages/" + this.url);
         }else{
          this.message.info('Pan Not Uploaded', '');
         }
        
        }, err => {
          console.log(err);
        });
    
  }
  getAttachment1(data) {
    console.log(data);
    
    this.api.getAllCustomer(0, 0, '', 'asc', ' AND ID ='+ data.CUSTOMER_ID).subscribe(data => {
         console.log(data['data']);
         if(data['data'][0]['AADHAR_URL']!=''){
          this.url1 = data['data'][0]['AADHAR_URL']
          window.open(this.api.retriveimgUrl + "aadharImages/" + this.url1);
         }else{
          this.message.info('Aadhar Not Uploaded', '');
         }
        
        }, err => {
          console.log(err);
        });
    
  }

  isApproveVisible: boolean = false;
  
  tempData = new Object();
  INVOICE_NO: string = "";

  openApproveModal(data: any) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisible = true;
    this.get(data.ID)
  }

  handleApproveCancel() {
    this.isApproveVisible = false;
  }
  ACCOUNT_DATETIME = new Date 
  handleApproveOk() {
    let isOk = true;

    if (this.INVOICE_NO == undefined || this.INVOICE_NO.toString() == '') {
      isOk = false;
      this.message.error('Please Enter Valid Invoice No.', '');
    }

    if (isOk) {
      this.isApproveModalConfirmLoading = true;
      this.tempData["INVOICE_NO"] = this.INVOICE_NO;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "AP";

      // this.memoSignature

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isApproveModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
          this.memoSignature.ACCOUNT_ID = 
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
      
            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });

          this.message.success("Invoice No. Updated Successfully", "");
          this.isApproveVisible = false;
          this.search1(false);



        } else {
          this.isApproveModalConfirmLoading = false;
          this.message.error("Invoice No. Updation Failed", "");
          this.search1(false);
        }
      });




    }
  }

  isRejectVisible: boolean = false;
  REMARK: string = "";

  // updatesign() {
   
  //   this.memoSignature.ACCOUNT_ID = this.userId
  //   // this.memoSignature.MEMO_ID = this.memoSignature.MEMO_ID


   
  // }

  get(ID) {
    this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memoSignature = data['data'][0];
        console.log(this.memoSignature,"this.memoSignature");
        

        
      }
    });

  }
  openRejectModal(data: any) {
    this.tempData = new Object();
    this.tempData = data;
    this.REMARK = "";
    this.isRejectVisible = true;
    this.get(data.ID)
  }

  handleRejectCancel() {
    this.isRejectVisible = false;
  }

  handleRejectOk() {
    let isOk = true;

    if (this.REMARK == undefined || this.REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please Enter Valid Remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["REMARK"] = this.REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.memoSignature.ACCOUNT_ID = this.userId
          this.memoSignature.ACCOUNT_DATETIME=this.datePipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd")
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
      
            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
          
          this.message.success("Remark Updated Successfully", "");
          this.isRejectVisible = false;
          this.search1(false);

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          this.search1(false);
        }
      });
    }
  }



  setQuotationStatus(quotationID: number, status: string) {
    this.api.setQuotationStatus(quotationID, status).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Quotation Updated Successfully", "");

      } else {
        this.message.error("Quotation Updation Failed", "");
      }
    });
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  drawerClose1(): void {
    this.isVisible = false;
    this.advanceArray = []
    this.sumoffall2 = 0
    this.sumofall = 0
    this.discount2 = 0
    this.discount1 = 0
    this.exchange = 0
    this.quotedPrice = 0
    this.sum2 = 0
    this.sum3 = 0
    this.sum4 = 0
    this.sum5 = 0
    this.sum6 = 0
    this.sum7 = 0
    this.sum8 = 0
    this.sum9 = 0

    this.memoDetailsArray = []
this.search1()


  }
  
  acc_remark:any
  iid:any
  memo12
isLoading=false
SAMBHADH_SCHEME=0
  printForm(data: memoMaster,data1:memoMaster) {

    this.formname = "Memo.Pdf"
    this.drawerFormPrintData = Object.assign({}, data);
    // this.acc_remark= data1['ACC_REMARK']
    console.log(this.acc_remark,"this.acc_remark");
    this.isLoading=true
       // this.message.info(data.MEMO_NO,"Please Wait...")
    if(data1 !=undefined){
      this.iid= data1.ID
    }


   
    var filter="AND ID=" + data.ID 
    this.api.getMemoDetails(0, 0, "", "", filter).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {
        // this.totalRecords = data['count'];
        this.memo12 = data['data'][0];
        if (this.memo12 != null) {
          if (data['data'][0]['ADDITIONAL_DISCOUNT'] != null)
          this.add1 = data['data'][0]['ADDITIONAL_DISCOUNT']
          else
          this.add1=0
          console.log(this.add1, "ADD1");
        }
        else{
          this.add1=0
        }
            this.isVisible = true;
            this.getcal2()
            this.api.getQuestionaryMaster(0, 0, "", "", "AND MEMO_ID=" + this.memo12 .ID).subscribe(data => {

              if (data['code'] == 200 && data['count'] > 0) {
        
                this.questionary = data['data'][0];
                this.isLoading=false
        
                if (this.questionary.CORPORATE_DISCOUNT != null) {
        
                  this.discount1 = data['data'][0]['CORPORATE_DISCOUNT']
                  console.log(this.discount1)
                }
                else {
                  this.discount1 = 0
                }
                if (this.questionary.EXCHANGE != null) {
        
                  this.exchange = data['data'][0]['EXCHANGE']
                  console.log(this.discount1)
                }
                else {
                  this.exchange = 0
                }

                if(this.questionary.SAMBHADH_SCHEME != null){
                  this.SAMBHADH_SCHEME=data['data'][0]['SAMBHADH_SCHEME']
    
                }
                else{
                  this.SAMBHADH_SCHEME=0
                }
                console.log(this.questionary);
                this.getcal()
                this.getcal2()
              }
        
        
        
            }, err => {
              if (err['ok'] == false)
                this.message.error("Server Not Found", "");
            });
        
            this.api.getAdvanceMaster(0, 0, "", "", "AND QUOTATION_ID=" + this.memo12 .QUOTATION_ID).subscribe(data => {
        
              if (data['code'] == 200 && data['count'] > 0) {
                this.totalRecords = data['count'];
                this.advanceArray = data['data'];
        
                for (let i = 0; i < this.advanceArray.length; i++) {
                  this.discount2 = this.discount2 + Number(this.advanceArray[i]['AMOUNT'])
        
                  if (i + 1 == this.advanceArray.length) {
                    this.getcal2()
                  }
        
                }
              }
        
            }, err => {
              if (err['ok'] == false)
                this.message.error("Server Not Found", "");
            });
        
        
            this.api.getMemoDetails1(0, 0, "", "", "AND MEMO_MASTER_ID=" + this.memo12 .ID).subscribe(data => {
        
              if (data['code'] == 200 && data['count'] > 0) {
                // this.totalRecords = data['count'];
                this.memoDetailsArray = data['data'];
                console.log(this.memoDetailsArray);
        
                this.quotedPrice = 0
        
                for (let i = 0; i < this.memoDetailsArray.length; i++) {
                  this.quotedPrice = this.quotedPrice + Number(this.memoDetailsArray[i]['CUSTOMER_QUOTED_PRICE'])
        
                  console.log(this.quotedPrice);
                  if (i + 1 == this.memoDetailsArray.length) {
                    this.getcal()
        
                  }
                }
        
                for (let j = 0; j < this.memoDetailsArray.length; j++) {
        
                  this.sum1 = Number(this.memoDetailsArray[j]['CUSTOMER_DISCOUNT'])
                  this.sum2 = Number(this.memoDetailsArray[j]['HEAD_DISCOUNT'])
        
                  // this.sdfsdf=
                  this.sdfsdf = this.memoDetailsArray[j]['COMPONENT']
        
                  if (this.sdfsdf == 'SOT') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum3 = this.sum1 + this.sum2
        
                      console.log(this.sum3, "sum3");
        
                    }
                  }
        
                  if (this.sdfsdf == 'Invoice amount') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum4 = this.sum1 + this.sum2
        
                      console.log(this.sum4, "sum4");
        
                    }
                  }
        
                  if (this.sdfsdf == 'RTO') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum5 = this.sum1 + this.sum2
        
                      console.log(this.sum5, "sum5");
        
                    }
                  }
        
                  if (this.sdfsdf == 'Accessories') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum6 = this.sum1 + this.sum2
        
                      console.log(this.sum6, "sum6");
        
                    }
                  }
        
                  if (this.sdfsdf == 'Insurance') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum7 = this.sum1 + this.sum2
        
                      console.log(this.sum4, "sum3");
        
                    }
                  }
        
                  if (this.sdfsdf == 'Fast Tag') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum8 = this.sum1 + this.sum2
        
                      console.log(this.sum4, "sum3");
        
                    }
                  }
        
                  if (this.sdfsdf == 'Extended Warranty') {
                    if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {
        
                      this.sum9 = this.sum1 + this.sum2
        
                      console.log(this.sum4, "sum3");
        
                    }
                  }
        
                  if (j + 1 == this.memoDetailsArray.length) {
                    this.getcal2()
                  }
        
        
        
        
                }
        
              }
        
            }, err => {
              if (err['ok'] == false)
                this.message.error("Server Not Found", "");
            });
        
            this.api.getmemoSignatureDetails(0, 0, "", "", "AND MEMO_ID=" + this.memo12 .ID).subscribe(data => {
        this.sumofall
              if (data['code'] == 200 && data['count'] > 0) {
                // this.totalRecords = data['count'];
                this.memoSignature = data['data'][0];
             
                    this.isVisible = true;
              
               
        
              }
        
            }, err => {
              if (err['ok'] == false)
                this.message.error("Server Not Found", "");
            });

      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });


  }

 
  
  


  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl, {
      mode:  'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: "application/json",
        apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
        applicationkey: "AfIpESwBr5eHp7w3",
      }
    });
    console.log(res)
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }


  sumofall = 0
  sumoffall2 = 0

  getcal() {

    if (this.quotedPrice != null && this.quotedPrice != undefined && this.discount1 != undefined && this.discount1 != null) {
      this.sumofall = Number(this.quotedPrice) + Number(this.discount1)+ Number(this.SAMBHADH_SCHEME)
      console.log(this.sumofall);

      // if (this.sumoffall2 >= this.sumofall) {
      //   this.getcal4()
      // }

      // else {
      //   this.getcal3()
      // }


    }
  }
  add1=0
  getcal2() {
    if (this.discount2 != null && this.discount2 != undefined && this.sum3 != undefined && this.sum3 != null && this.sum4 != undefined && this.sum4 != null
      && this.sum5 != undefined && this.sum5 != null && this.sum6 != undefined && this.sum6 != null && this.sum7 != undefined && this.sum7 != null && this.sum8 != undefined && this.sum8 != null && this.sum9 != undefined && this.sum9 != null && this.discount1 != undefined && this.discount1 != null
      && this.exchange != undefined && this.exchange != null && this.add1 != null) {

      this.sumoffall2 =
        Number(this.discount2) +
        Number(this.sum3) + Number(this.sum4) +
        Number(this.sum5) + Number(this.sum6) +
        Number(this.sum7) + Number(this.sum8) +
        Number(this.sum9) + Number(this.discount1) + Number(this.exchange) + Number(this.add1)+ Number(this.SAMBHADH_SCHEME)

      console.log(this.sumoffall2, "total2");

      if (this.sumofall >= this.sumoffall2) {
        this.getcal3()
      }
      else {
        this.getcal4()
      }


    }


  }

  getcal3() {

    console.log(this.sumofall, "ok", this.sumoffall2, "ok2");
    console.log();


    if (this.sumofall >= this.sumoffall2) {
      this.sdfsdf4 = this.sumofall - this.sumoffall2
      this.sdfsdf5 = 0
      console.log(this.sdfsdf4, "shortfall");


    }
    else {
      this.sdfsdf4 = 0
    }


  }

  getcal4() {
    if (this.sumoffall2 >= this.sumofall) {
      this.sdfsdf5 = this.sumoffall2 - this.sumofall
      this.sdfsdf4 = 0
      console.log(this.sdfsdf5,"Excess Amount");

    }

    else {
      this.sdfsdf5 = 0
    }
  }


  // getcallseco


  isApproveModalConfirmLoading: boolean = false;
  isRejectModalConfirmLoading: boolean = false;
  // allAccountantEmployeeList = [];

  // getAccountantEmployeeList() {
  //   this.allAccountantEmployeeList = [];

  //   this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=13)').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.allAccountantEmployeeList = data['data'];
  //       console.log(this.allAccountantEmployeeList);
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }


  imageModalVisible = false
  openPdf(data: memoMaster,i:any): void {
    // this.loadingRecords = true
    this.imageModalVisible = true
    this.isSpinning = true
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        this.isSpinning = false
        this.loadingRecords = false
      }
    })
  }


  handleimgCancel() {
    this.imageModalVisible = false
  }

  openReceipt(data: memoMaster, i: any) {
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        // this.isSpinning = false
        this.loadingRecords = false
        window.open(this.api.retriveimgUrl + "receiptPhoto/" + this.recieptData[i]['RECEIPT_PHOTO']);
        console.log(this.recieptData[i]['RECEIPT_PHOTO']);
      }
    })

  }

  openReceipt1(data: memoMaster, i: any) {
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        // this.isSpinning = false
        this.loadingRecords = false
        window.open(this.api.retriveimgUrl + "paymentPhoto/" + this.recieptData[i]['PAYMENT_PHOTO']);
        console.log(this.recieptData[i]['PAYMENT_PHOTO']);
      }
    })

  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };
}
