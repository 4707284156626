import { Component, OnInit } from '@angular/core';
// import { ApiService } from '../Service/api.service';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import * as xlsx from 'xlsx';
import { ExportService } from 'src/app/Service/export.service';


@Component({
  selector: 'app-sales-head-summary-report',
  templateUrl: './sales-head-summary-report.component.html',
  styleUrls: ['./sales-head-summary-report.component.css']
})
export class SalesHeadSummaryReportComponent implements OnInit {
  columns: string[][] = [['BRANCH_NAME', 'Branch'], ['EMPLOYEE_NAME', 'Sales Head '],
  ['TOTAL_SALES', 'Total Sales Achievement'], ['PRODUCT_IINCENTIVE', 'Product Earned Incentive Amount'],
  // ['INSURANCE_COUNT', 'Total Insurance Sold'], 
  ['INSURANCE_INCENTIVE_AMOUNT', 'Insurance Earned Incentive Amount'],
  ['TOTAL_INCENTIVE', 'Total Earned Incentive Amount'],]




  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Sales Head Summary Report"
  startValue: any;
  endValue: any;
  isSpinning = false;
  dataList = []
  totalRecords = []
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  filterQuery: string = ""
  BRANCH_ID: any = []
  orgId = this.cookie.get('orgId');
  branches = []
  EMPLOYEE_ID: string
  employee = []
  constructor(private api: ApiService, public message: NzNotificationService, public datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService) { }

  ngOnInit() {
    this.search()
    this.getBranches()
    this.getEmployee()
  }
  getBranches() {
    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.branches = data['data']
      console.log(this.branches);

    })
  }
  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=34').subscribe(data => {
      this.employee = data['data']
      console.log(this.employee);

    })
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  datas1 = [];
  LIKE_STRING:any;

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    this.LIKE_STRING=this.searchText
    // var likeQuery = "";
    // if (this.searchText != '') {
    //   likeQuery = ' AND (';
    //   this.columns.forEach((column) => {
    //     likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    // }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getsalesIncentiveSummaryReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.LIKE_STRING, this.BRANCH_ID, this.EMPLOYEE_ID, this.MONTH, this.YEAR).subscribe(data => {
        this.loadingRecords = false;
        this.dataList = data['data']
        this.totalRecords = data['count']
        console.log(this.dataList);
      });
    }
    else {
      this.exportLoading = true;

      this.api.getsalesIncentiveSummaryReport(0, 0, this.sortKey, sort, this.LIKE_STRING, this.BRANCH_ID, this.EMPLOYEE_ID, this.MONTH, this.YEAR).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.datas1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (this.EMPLOYEE_ID != undefined) {
      this.EMPLOYEE_ID = this.EMPLOYEE_ID;
    }
    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    this.search();

  }


  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.BRANCH_ID = []
    this.EMPLOYEE_ID = null
    this.MONTH = this.month;
    this.MONTH1 = new Date()
    this.YEAR = this.year
    this.search()
  }
  // fileName = 'Sales Head Summary Report.xlsx'
  // exportexcel(): void {
  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Back Office Employee Report');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }
  
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.datas1.length; i++) {
      obj1['Branch Name'] = this.datas1[i]['BRANCH_NAME'];
      obj1['Sales Head'] = this.datas1[i]['EMPLOYEE_NAME'];
      obj1['Total Sales Achievement'] = this.datas1[i]['TOTAL_SALES'];
      obj1['Product Earned Incentive Amount'] = this.datas1[i]['PRODUCT_IINCENTIVE'];
      obj1['Insurance Earned Incentive Amount'] = this.datas1[i]['INSURANCE_INCENTIVE_AMOUNT'];
      obj1['Total Earned Incentive Amount'] = this.datas1[i]['TOTAL_INCENTIVE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.datas1.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Head Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
