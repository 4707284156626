import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { ServiceSubDepartmentmaster } from 'src/app/Models/ServiceSubDepartmentmaster';
import { ApiService } from 'src/app/Service/api.service';
import { ServiceSubDepartmentmaster } from 'src/app/Models/servicesubdepartmentmaster';

@Component({
  selector: 'app-addserviceslubdepart',
  templateUrl: './addserviceslubdepart.component.html',
  styleUrls: ['./addserviceslubdepart.component.css']
})
export class AddserviceslubdepartComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: ServiceSubDepartmentmaster = new ServiceSubDepartmentmaster;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/

  constructor(private api: ApiService, private message: NzNotificationService) {

  }

  ngOnInit() {
    // this.selectedRole=new RoleMaster();
    // this.loadOrganizations();
    // this.loadRoles();
  }

  // loadOrganizations() {
  //   this.isSpinning = false;

  //   this.api.getAllOrganizations(0,0,'','','').subscribe(organizations => {
  //     this.organizations = organizations['data'];
  //     this.isSpinning = false;
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });
  // }

  loadRoles() {
    this.isSpinning = false;

    // this.api.getAllRoles(0,0,'','','').subscribe(roles => {
    //   this.roles = roles['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  getSequenceNo() {
    this.api.getserviceSubDepartmentMaster(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        var seqno = data['data'][0]['SEQUENCE_NO'];
        this.data.SEQUENCE_NO = Number(seqno) + 1;

      } else {
        this.data.SEQUENCE_NO = 1;
      }
    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
    myForm.form.markAsPristine();
    myForm.form.markAsUntouched();
  }

  save(addNew: boolean, myForm: NgForm): void {
    this.isSpinning = false;

    if (this.data.NAME.trim() == "" && this.data.STATUS && this.data.SEQUENCE_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");
    } else
      if (this.data.NAME == null || this.data.NAME.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter Name', '')
      } else
        if (!this.namepatt.test(this.data.NAME)) {
          this.isOk = false
          this.message.error('Please Enter Valid Name', '')
        } else
          if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Seq. No.', '')
          } else
            if (this.isOk) {
              this.isSpinning = true;

              if (this.data.ID) {
                this.api.updateserviceSubDepartmentMaster(this.data).subscribe(successCode => {
                  if (successCode.code == "200") {
                    this.message.success("Information Updated Successfully...", "");
                    this.isSpinning = false;

                    if (!addNew)
                      this.close(myForm);

                  } else {
                    this.message.error("Information Not Updated...',", "");
                    this.isSpinning = false;
                  }
                });

              } else {
                this.api.createserviceSubDepartmentMaster(this.data).subscribe(successCode => {
                  if (successCode.code == "200") {
                    this.message.success('Information Saved Successfully...',"");
                    this.isSpinning = false;

                    if (!addNew)
                      this.close(myForm);

                    else {
                      this.data = new ServiceSubDepartmentmaster();
                      this.getSequenceNo();
                    }

                  } else {
                    this.message.error('Information Not Saved...', "");
                    this.isSpinning = false;
                  }
                });
              }
            }

  }
}