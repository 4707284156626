export class tourExpenseMaster {
    ID: any
    TOUR_ID: any
    EMPLOYEE_ID: any
    EMPLOYEE_NAME:any
    HEAD_ID: any
    HEAD_NAME:any
    APPLICATION_DATE: any
    DATE: any
    PARTY_NAME: any
    AMOUNT: any
    APPROVED_AMOUNT: any
    STATUS: any
    ATTACHMENTS: any
    REMARK: any
    DESCRIPTION: any

    APPROVER_ID: any
    HEAD_REMARK: any
    HEAD_STATUS: any
    HEAD_APPROVED_DATETIME: any

    HR_REMARK: any
    HR_STATUS: any
    HR_APPROVED_DATETIME: any

    GM_REMARK: any
    GM_STATUS: any
    GM_APPROVED_DATETIME: any
    
    ACCOUNTANT_REMARK: any
    ACCOUNTANT_STATUS: any
    ACCOUNTANT_APPROVED_DATETIME: any
    PAYMENT_STATUS='C'
    EXPECTED_AMOUNT

}