export class VarientMaster {
  ID: number;
  CLIENT_ID: number;
  MODEL_ID: number;
  NAME: string;
  CODE: string;
  STATUS: boolean = true;
  SEQUENCE_NO: number;
  FUEL_TYPE: any;
  TRANSMISSION: any;
  CC: string;
  VARIENT_LITER: string;
  COLOR_ID: any;
}
