import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-lead-apt-transfer-report',
  templateUrl: './lead-apt-transfer-report.component.html',
  styleUrls: ['./lead-apt-transfer-report.component.css']
})
export class LeadAptTransferReportComponent implements OnInit {

  formTitle = "Lead Appointment Transfer Report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'kpi.xlsx';
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive name"], ["BRANCH_NAME", "Branch"], ["DATE", "Quotation Date"], ["CUSTOMER_NAME", "Customer name"], ["QUOTATION_NO", "Quotation No"], ["MODEL_NAME", "Model"]];
  STATUS = "AL";
  BRANCH = [];
  SalesExecutive = []
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));

  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();

  endOpen = false;
  // startOpen = false;

  sales: any = "";
  DATE: any;
  CCE_ID: number
  BRANCH_ID: any = [];

  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";

  isVisible1: boolean = false
  drawerTitle1: string = ''

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.getEmployee();
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])
        console.log(this.sales);
        this.search(true);
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }


  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  // keyup(event: any) {
  //   this.search();
  // }

  dates: any = [];
  today2 = new Date();


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'
    this.search(true);
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.MONTH1 = new Date()
    this.getEmployee()
    this.isFilterApplied = 'default';
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  leadAptData: any = []
  NoData: boolean = false
  cceName: any = []

  getMonth(MONTH: any) {
    if (MONTH == 1) {
      return 'January'
    }
    else if (MONTH == 2) {
      return 'February'
    }
    else if (MONTH == 3) {
      return 'March'
    }
    else if (MONTH == 4) {
      return 'April'
    }
    else if (MONTH == 5) {
      return 'May'
    }
    else if (MONTH == 6) {
      return 'June'
    }
    else if (MONTH == 7) {
      return 'July'
    }
    else if (MONTH == 8) {
      return 'August'
    }
    else if (MONTH == 9) {
      return 'September'
    }
    else if (MONTH == 10) {
      return 'October'
    }
    else if (MONTH == 11) {
      return 'November'
    }
    else if (MONTH == 12) {
      return 'December'
    }
    else {
      return '-'
    }
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }

    catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    var filter = ''

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
      filter = " AND MONTH = " + this.MONTH
    }
    else {
      filter = ''
    }
    var filter1 = ''
    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
      filter1 = " AND YEAR = " + this.YEAR
    }
    else {
      filter1 = ''
    }

    var filter2 = ''
    if (this.CCE_ID != undefined) {
      filter2 = " AND CCE_ID = " + this.CCE_ID
    }
    else {
      filter2 = ''
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getLeadAptTransferReport(this.pageIndex, this.pageSize, this.sortKey, sort, filter + filter1 + filter2 + likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          // this.NoData = false
          this.totalRecords = data['count'];
          this.leadAptData = data['data'];
          this.cceName = data['data'][0]['CCE_NAME']
          // console.log(this.cceName, "ccename")
          this.calculateTotal()
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }

      }, err => {
        console.log(err);
      });

    }
  }

  totalApt: any
  PS: any
  FS: any
  RR: any
  Total: any
  percent: any

  calculateTotal() {
    this.totalApt = 0
    this.PS = 0
    this.FS = 0
    this.RR = 0
    this.Total = 0
    this.percent = 0

    for (let e = 0; e < this.leadAptData.length; e++) {
      this.totalApt += this.leadAptData[e]['TOTAL_APPOINTMENT']
      this.PS += this.leadAptData[e]['PAID_SERVICE']
      this.FS += this.leadAptData[e]['FREE_SERVICE']
      this.RR += this.leadAptData[e]['RUNNING_REPAIR']
      this.Total += this.leadAptData[e]['TOTAL_CLOSE']
      this.percent = this.Total / this.totalApt * 100
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  modalVisible = false;
  openPrint() {
    this.modalVisible = true;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.modalVisible = false;
  }

}

