import { Component, OnInit, Input } from '@angular/core';
import { HolidayMaster } from 'src/app/Models/holiday-master';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-holiday-branch',
  templateUrl: './holiday-branch.component.html',
  styleUrls: ['./holiday-branch.component.css']
})
export class HolidayBranchComponent implements OnInit {
  @Input() drawerClose2: Function;
  @Input() data: HolidayMaster
  isVisible2: boolean = false
  @Input() BRANCH_ID:any
  SELECT_BRANCH: boolean = false;

  @Input() masterdata = []
  isOk = true;
  isSpinning = false
  @Input() holiday_id: any
  STATUS = 1
  close() {
    this.drawerClose2()
    console.log(this.BRANCH_ID, "id")
    console.log(this.masterdata, "id")
  }
  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
  }
  saveholiday(data: HolidayMaster) {

    // this.assign = Object.assign({}, data);
    this.data.ID = this.data.ID
    this.data.DATE = this.data.DATE
    this.data.IS_HOLIDAY = this.data.IS_HOLIDAY
    this.data.REASON = this.data.REASON
    this.data.IS_ATTENDANCE_ALLOWED = this.data.IS_ATTENDANCE_ALLOWED
    this.data.TYPE = this.data.TYPE
    this.api.updateHoliday(this.data).subscribe(successCode => {
    }, errorCode => {
      console.log("error");
    });
    this.isVisible2 = false

  }
  BRANCH_NAME
  BRANCH_ID1 = []
  save() {
    // this.BRANCH_ID=[]
    var branchmanager = []
    console.log(this.BRANCH_ID1, "this.BRANCH_ID")
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {


      for (var i = 0; i < this.masterdata.length; i++) {


        var isFounded = 0
        for (var j = 0; j < this.BRANCH_ID1.length; j++) {
          console.log(this.masterdata[i]['BRANCH_ID'] == this.BRANCH_ID1[j], "");

          if (this.masterdata[i]['BRANCH_ID'] == this.BRANCH_ID1[j]) {
            this.masterdata[i]['STATUS'] = 1
            console.log(this.BRANCH_ID1[j], "branch")

            isFounded = 1
          }
          if (j + 1 == this.BRANCH_ID1.length) {
            if (isFounded == 0)
              this.masterdata[i]['STATUS'] = 0;
          }

        }
        // branchmanager.push(this.masterdata[i])



      }

    }
    this.isSpinning = false;
    this.isOk = true;

    // if (this.BRANCH_ID == undefined || this.BRANCH_ID.length<1) {
    //   this.isOk = false
    //   this.message.error('Please Select Branches', '')
    // }
    // else{
    console.log(this.BRANCH_ID1, "branch")
    this.api.addbranch(this.holiday_id, this.masterdata).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Branch Mapping Updated Successfully...", "");
        this.drawerClose2()

        this.BRANCH_ID = []

      }
      else {
        this.message.error("Branch Mapping update failed", '')

      }
    });



  }

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    
      if (this.SELECT_BRANCH == true) {
       
        for (var i = 0; i < this.masterdata.length; i++) {
          // if(this.masterdata.length != 0){
          ids.push(this.masterdata[i]["BRANCH_ID"]);
        // }
      } 

      
    }
    else {
      ids = [];
    }
   
    this.BRANCH_ID = ids
  }

  onSelectOff(event: any) {
    // var branchmanager1 = []
    //  console.log(event)
    this.BRANCH_ID = event
    // this.BRANCH_ID=[]
    if (this.BRANCH_ID != undefined) {


      this.BRANCH_ID1 = this.BRANCH_ID
      this.SELECT_BRANCH=false
      console.log(this.BRANCH_ID1, " console.log(this.BRANCH_ID);")
    }


    else {
      this.BRANCH_ID1 = []
      console.log(this.BRANCH_ID1, "");
    }



  }


  
}
