export class EmployeeMaster {
    ID: number = 0;
    EMPLOYEE_CODE: string = '';
    NAME: string = '';
    EMAIL_ID: string;
    MOBILE_NUMBER: number;
    DESIGNATION_ID: any;
    BRANCH_ID: any;
    DEPARTMENT_ID: any;
    REPORTING_HEAD_ID: any;
    TEMPORARY_HEAD_ID: any;
    PASSWORD: any;
    STATUS:boolean = true
    SEQUENCE_NO: number = 0;
    ORG_ID = 0;
    ROLE_STATUS: boolean = true;
    SIGNATURE: string;
    REPORTING_PERSON_ID: any;
    H_PROMISE_ID: any;
    CORPORATE_MANAGER_ID: any;
    DIRECTOR_ID: any;
    ACCOUNT_ID: any;
    WEB_LOGIN:boolean
    BIO_LOGIN:boolean
    MOBILE_LOGIN:boolean
    JOINING_DATE:any;
    CONFIRMATION_DATE:any;
    LEAVE_DATE:any;
    TYPE_OF_LICENSE:any
    DRIVING_LICENSE_NO:any
    VALIDITY_OF_LICENSE:any
    BLOOD_GROUP:any
    REMARK:any
    DISABLE_DATE 
    DISABLE_REMARK
    AADHAR_NO
    PASSPORT_NO
    PANCARD_NO
    VEHICAL_TYPE
    SHIFT_NAME
    // WEB_LOGIN,BIO_LOGIN,MOBILE_LOGIN 
}

