import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { get } from 'http';
import { NzNotificationService } from 'ng-zorro-antd';
import { ModelMaster } from 'src/app/Models/ModelMaster';
import { ApiService } from 'src/app/Service/api.service';
// import { ModelDrawerComponent } from '../model-drawer/model-drawer.component';

@Component({
  selector: 'app-amountmap',
  templateUrl: './amountmap.component.html',
  styleUrls: ['./amountmap.component.css']
})
export class AmountmapComponent implements OnInit {
  formTitle = "Designation City Advance Mapping";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  AMOUNT: any
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ModelMaster = new ModelMaster();
  isSpinning = false;
  REMARK: any;
  uniqueSet = [];
  dataList1 = []
  uniqueArray = []
  Allcities = []


  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getSequenceNo();

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  designationsArray = []


  savebutton:boolean = true
  disablebutton(){
    this.savebutton = false
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.dataList1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.api.getAllamounts(0, 0, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.Allcities = data['CITY'];
        this.dataList1 = data['DESIGNATION'];

      }
    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    var filterquery = " AND STATUS = 1 AND IS_ACTIVE = 1 "

    this.loadingRecords = true;
    this.api.getAllcitydesignation(0, 0, this.sortKey, sort, likeQuery + filterquery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];

        for (let k = 0; k < this.Allcities.length; k++) {
          this.Allcities[k]['DESIGNATION'] = []
          for (let j = 0; j < this.dataList.length; j++) {
            //   this.dataList1.push(this.dataList[k]['DESIGNATION_NAME'])
            if (this.Allcities[k]['ID'] === this.dataList[j]['CITY_ID'])
              this.Allcities[k]['DESIGNATION'].push(this.dataList[j])
          }


        }
        // console.log(  this.Allcities);
        
        // for (let k = 0; k < this.dataList.length; k++) {
        //   this.dataList1.push(this.dataList[k]['DESIGNATION_NAME'])
        // }
        // this.uniqueArray = this.dataList1.filter((value, index, self) => self.indexOf(value) === index);
        // console.log(this.uniqueArray);
        //  this.designationsArray = this.dataList.length > 0 ? this.dataList : [];
        // console.log(this.designationsArray, "this.designationsArray");

      }
    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  updateAmount(rowIndex: number, designationIndex: number) {
    const designation = this.dataList[rowIndex]['DESTINATION_NAME'][designationIndex];
  }

  today = new Date().setDate(new Date().getDate() + 4);
  seqNo: number;

  getSequenceNo() {
    this.api.getAllModels(1, 1, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        this.seqNo = Number(data['data'][0]['SEQUENCE_NO']) + 1;

      } else {
        this.seqNo = 1;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }
  // @ViewChild(ModelDrawerComponent, { static: false }) ModelDrawerComponentVar: ModelDrawerComponent;

  getAllcitydesignation() {
    this.api.getAllcitydesignation(this.pageIndex, this.pageSize, this.sortKey, '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        console.log(data);



      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }


  save(dataListtt: any) {
    this.isSpinning = true;

    //  this.getAllcitydesignation()
    console.log(dataListtt);
    console.log(this.dataList);


    this.api.addcitydesignationDetails(this.dataList, this.REMARK).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("City Designation Advance Updated Successfully", "");
        this.search(true);
        this.isSpinning = false;
        // this.drawerClose()
      } else {
        this.message.error("City Designation Advance Updation Failed", "");
        this.isSpinning = false;
      }
    });

  }

  add(): void {
    this.drawerTitle = "Add Model";
    this.drawerData = new ModelMaster();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.drawerData.SEQUENCE_NO = this.seqNo;

  }

  edit(data: ModelMaster): void {
    this.drawerTitle = "Update Model Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    // this.ModelDrawerComponentVar.getFuels(this.drawerData.ID);
    // this.ModelDrawerComponentVar.getTransmissions(this.drawerData.ID);

    // COLOR MASTER
    let colorStringArray = [];
    if (data.COLOR_ID != undefined && data.COLOR_ID != null) {
      colorStringArray = data.COLOR_ID.split(",");
    }

    let colorArray = [];
    for (var i = 0; i < colorStringArray.length; i++) {
      colorArray.push(Number(colorStringArray[i]));
    }

    this.drawerData.COLOR_ID = colorArray;

    // if ((this.drawerData.IMAGE_URL) && (this.drawerData.IMAGE_URL != " "))
    //   this.ModelDrawerComponentVar.photoURL = this.api.retriveimgUrl + "modelImages/" + this.drawerData.IMAGE_URL;

    // else
    //   this.ModelDrawerComponentVar.photoURL = null;
  }

  drawerClose(): void {
    this.search();
    this.getSequenceNo();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: ModelMaster, status) {
    data.STATUS = status;

    this.api.updateModel(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
function uniqueDesignations() {
  throw new Error('Function not implemented.');
}

