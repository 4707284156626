import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { Department } from 'src/app/Models/department';
import { User } from 'src/app/Models/user';
import { Role } from 'src/app/Models/role';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-useraccessmapping',
  templateUrl: './useraccessmapping.component.html',
  styleUrls: ['./useraccessmapping.component.css']
})
export class UseraccessmappingComponent implements OnInit {

  formTitle = "Assign"
  roleLoading = false
  userLoading = false
  userVisible: boolean
  departmentVisible: boolean
  isDepartmentId: boolean
  isSpinning = false
  loadingFilterUsers = false
  ROLE_ID: number
  filterUserValue:any
  USER_ID: number
  filterDepartmentValue
  users = [];
  roles: Role[];
  STATUS = true
  departments: Department[];
  loadingFilterDepartments = false;
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  applicationId = this.cookie.get('applicationId')
  values:any
  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.loadData()

  }

  loadData() {

    this.roleLoading = true;
    this.userLoading = true
    this.loadingFilterDepartments = true
    this.loadingFilterUsers = true
    var roleFilterQuery = "AND PARENT_ROLE_ID=" + this.roleId
    this.api.getAllRoles(0, 0, '', '', roleFilterQuery).subscribe(roles => {
      this.roles = roles['data'];
      this.ROLE_ID = roles['data'][0]['ID']
      this.roleSelectChange(this.ROLE_ID)
      this.roleLoading = false;
    }, err => {
      console.log(err);
      this.roleLoading = false;
    });

    var filterQuery = " AND PARENT_ROLE_ID=" + this.roleId
    this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(users => {
      this.users = users['data'];
      // this.USER_ID = users['data'][0]['ID']
      // this.userSelectChange(this.USER_ID)
      this.userLoading = false;
      this.loadingFilterUsers = false
    }, err => {
      console.log(err);
      this.userLoading = false;
      this.loadingFilterUsers = false
    });

    // let deptFilter = "AND APPLICATION_ID=" + this.applicationId

    this.api.getAllDepartments(0, 0, '', '', '').subscribe(applications => {
      this.departments = applications['data'];
      this.loadingFilterDepartments = false;
    }, err => {
      console.log(err);
      this.loadingFilterDepartments = false;
    });
  }


  roleSelectChange(roleId) {
    var roleFilterQuery = "AND ID=" + roleId
    this.api.getAllRoles(0, 0, '', '', roleFilterQuery).subscribe(roles => {
      this.isDepartmentId = roles['data'][0]['REQUIRED_DEPARTMENT_ID']
      this.roleLoading = false;
      if (this.isDepartmentId == false) {
        var filterQuery = " AND ROLE_ID=" + roleId + " "
        this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(data => {
          this.values = ""
          data['data'].forEach(element => {
            this.values = element['USER_ID'] + "," + this.values
          });

          if (this.values.match("undefined")) {
            this.values = this.values.substring(0, this.values.length - 10)
            console.log(this.values)
          }
          else {
            this.values = this.values.substring(0, this.values.length - 1)
          }

          if (this.values != []) {
            var userIds = this.values.split(',').map(function (item) {
              return parseInt(item, 10);
            });
          }

          this.filterUserValue = userIds
          if (this.filterUserValue == [NaN]) {
            this.filterUserValue = undefined
          }

        }, err => {
          console.log(err);
        });
      }
    }, err => {
      console.log(err);
      this.roleLoading = false;
    });
  }

  save() {
    this.isSpinning = true;
    var userAccessMapping = []
    if (this.isDepartmentId == true) {
      this.filterDepartmentValue.map((item) => {
        var v = { "USER_ID": this.USER_ID, "ROLE_ID": this.ROLE_ID, "APPLICATION_ID": this.applicationId, "DEPARTMENT_ID": item, "MODULE_ID": 0, "STATUS": this.STATUS, "CLIENT_ID": this.api.clientId }
        userAccessMapping.push(v)
      })
    }
    else {
      this.filterUserValue.map((item) => {
        var v = { "USER_ID": item, "ROLE_ID": this.ROLE_ID, "APPLICATION_ID": this.applicationId, "DEPARTMENT_ID": 0, "MODULE_ID": 0, "STATUS": this.STATUS, "CLIENT_ID": this.api.clientId }
        userAccessMapping.push(v)
      })
    }
    
    this.api.AssignApplicationsData(userAccessMapping)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Application Assigned Successfully ...", "");
          this.isSpinning = false;
        }
        else {
          this.message.error("Failed to Assign Applications...", "");
          this.isSpinning = false;
        }
      });
  }



  userSelectChange(userId: number) {
    var filterQuery = " AND ROLE_ID=" + this.ROLE_ID + " AND USER_ID= " + userId + " "
    this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(data => {
      console.log(data['data'])
      this.values = ""
      data['data'].forEach(element => {
        this.values = element['DEPARTMENT_ID'] + "," + this.values
      });

      if (this.values.match("undefined")) {
        this.values = this.values.substring(0, this.values.length - 10)
      }
      else {
        this.values = this.values.substring(0, this.values.length - 1)
      }
      if (this.values != []) {
        var departmentIds = this.values.split(',').map(function (item) {
          return parseInt(item, 10);
        });
      }
      this.filterDepartmentValue = departmentIds
      if (this.filterDepartmentValue == [NaN]) {
        this.filterDepartmentValue = undefined
      }

    }, err => {
      console.log(err);
    });
  }


}
