import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branchwisedetailedinvoice',
  templateUrl: './branchwisedetailedinvoice.component.html',
  styleUrls: ['./branchwisedetailedinvoice.component.css']
})
export class BranchwisedetailedinvoiceComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Branch Wise Detailed Invoice Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  quotationdate: any;

  models: any = [];
  branch: any = [];

  isOk = true;
  columns: string[][] = [["BRANCH_NAME", "Branch Name"], ["CUSTOMER_NAME", "Customer Name"], ["QUOTATION_DATE", "Quotation Date"], ["DATE", "Memo Date"], ["INVOICE_NO", "Invoice Number"], ["MODEL_NAME", "Model"]];
  dataList1: any;

  START_DATE: any
  END_DATE: any
  MEMO_START_DATE: any
  MEMO_END_DATE: any
  MODEL_ID: any = []
  BRANCH_ID: any = []
  DATE = [];
  DATE2 = [];
  current = new Date();

  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    // this.getAllModels();
    this.getAllbranches();
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
  
    this.DATE2[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE2[1] = new Date();
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      START_DATE: this.START_DATE,
      END_DATE: this.END_DATE,
      MODEL_ID: this.MODEL_ID,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 20').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      if (this.branch.length > 0) {
        this.BRANCH_ID = [data['data'][0]['ID']];
        console.log(this.branch);
      }
      else {
        console.log('Error');
      }
      this.getAllModels();
    }, (err) => {
      console.log(err);
    });
  }

  getAllModels() {
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data']
      let ids = [];

      if (this.SELECT_MODEL == true) {
        for (var i = 0; i < this.models.length; i++) {
          ids.push(this.models[i]["ID"]);
        }
      } else {
        ids = [];
      }
      this.MODEL_ID = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  SELECT_MODEL: boolean = true;

  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids
  }

  SELECT_ALL11: boolean = false;

  onSelectAllChecked2(event: any) {
    this.SELECT_ALL11 = event;
    let id = [];
    if (this.SELECT_ALL11 == true) {
      for (var i = 0; i < this.branch.length; i++) {
        id.push(this.branch[i]["ID"]);
      }
    } else {
      id = [];
    }
    this.BRANCH_ID = id;
  }

  exportLoading: boolean=false;

  importInExcel() {
    this.search(true, true);
  }
  drawerClose1(){
    
  }
  search(reset: boolean, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery += this.searchText;
    }
    
    // if (this.searchText != "") {
    //   likeQuery = " AND (";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    // }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.START_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.END_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    if (this.DATE2 != undefined && this.DATE2.length != 0) {
      this.MEMO_START_DATE = this.datePipe.transform(this.DATE2[0], "yyyy-MM-dd");
      this.MEMO_END_DATE = this.datePipe.transform(this.DATE2[1], "yyyy-MM-dd");
    }


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getbranchwiseinvoicedetails(this.pageIndex, this.pageSize, this.sortKey, sort,
        likeQuery, this.START_DATE, this.END_DATE, this.MEMO_START_DATE, this.MEMO_END_DATE,
        this.MODEL_ID, this.BRANCH_ID).subscribe(data => {
          console.log(data)
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }, err => {
          console.log(err);
        });
    }
    else {
      this.exportLoading = false;
      this.api.getbranchwiseinvoicedetails(0, 0, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.MEMO_START_DATE, this.MEMO_END_DATE,
        this.MODEL_ID, this.BRANCH_ID).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    this.DATE2[0] = this.datePipe.transform(this.DATE2[0], 'yyyy-MM-dd')
    this.DATE2[1] = this.datePipe.transform(this.DATE2[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null) {
      this.START_DATE = this.DATE[0]
    }
    if (this.DATE[1] != null) {
      this.END_DATE = this.DATE[1]
    }

    if (this.DATE2[0] != null) {
      this.MEMO_START_DATE = this.DATE2[0]
    }
    if (this.DATE2[1] != null) {
      this.MEMO_END_DATE = this.DATE2[1]
    }

    if (this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID;
    }

    if (this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    this.search(true);
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE=[];
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-' + (this.current.getDate() - 1));
    this.DATE[1] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-' + (this.current.getDate() - 1));
    
    this.DATE2=[];
    this.DATE2[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-' + (this.current.getDate() - 1));
    this.DATE2[1] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-' + (this.current.getDate() - 1));
    this.SELECT_MODEL = true
    let ids = [];
    for (var i = 0; i < this.models.length; i++) {
      ids.push(this.models[i]["ID"]);
    }
    this.MODEL_ID = ids;

    this.getAllbranches();
   
  }
 
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Branch'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Quotation Date'] = this.dataList1[i]['QUOTATION_DATE'];
      obj1['Memo Date'] = this.dataList1[i]['DATE'];
      obj1['Invoice Number'] = this.dataList1[i]['INVOICE_NO'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Detailed Invoice' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
}
