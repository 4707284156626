import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
import { slabMaster } from '../slabMaster';
// import { slabMaster } from 'src/app/Models/slabMaster';

@Component({
  selector: 'app-finance-master-from2',
  templateUrl: './finance-master-from2.component.html',
  styleUrls: ['./finance-master-from2.component.css']
})
export class FinanceMasterFrom2Component implements OnInit {

  @Input() drawerClose1: Function
  @Input() data: slabMaster = new slabMaster()

  constructor(private api: ApiService, private message: NzNotificationService, private datepipe: DatePipe) { }
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;
  namepatte=/^[0-9]+([,.][0-9]+)?$/;
  date = null;
  isOk = false;
  switch1 = false;
  isSpinning = false;
  listOfData: any = [];

  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };
  disabledEndDate = (endValue: Date): boolean => {
    // if (!endValue || !this.startValue) {
    //   return false;
    // } return endValue.getTime() - 1 < this.startValue.getTime() ;
    return differenceInCalendarDays(endValue, this.startValue) < 0;
  };
  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }
  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  ngOnInit() {
    this.getData();
  }

  onChange1(result: Date): void {
    console.log('onChange: ', result);
  }
  onChange2(result: Date): void {
    console.log('onChange: ', result);
  }

  getData() {
    this.api.getFinanceMaster(this.pageIndex, this.pageSize, '', '', '').subscribe((data) => {
      this.listOfData = data['data'];
      console.log('this.listOfData', this.listOfData);

    })
  }

  switchChange2(e1: boolean) {
    this.switch1 = e1;
  }

  onSubmit(addNew: boolean, httpForm: NgForm) {

    this.isOk = true;
    if (this.data.STRUCUTURE_NAME == null || this.data.STRUCUTURE_NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Incentive Name.', "")
    }
    else if (this.data.FROM_DATE == null || this.data.FROM_DATE == 0) {
      this.isOk = false
      this.message.error('Please Enter From Date.', "")
    }
    else if (this.data.TO_DATE == null || this.data.TO_DATE == 0) {
      this.isOk = false
      this.message.error('Please Enter To Date.', "")
    }
    else if (this.data.SLAB == null || this.data.SLAB == 0) {
      this.isOk = false

      this.message.error('Please Enter Slab.', "")

    } else if (!this.namepatte.test(this.data.SLAB)) {

      this.isOk = false;

      this.message.error("Please Valid Enter Slab", '');

    }



    if (this.isOk) {

      if (this.data.ID) {
        this.data.FROM_DATE = this.datepipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
        this.data.TO_DATE = this.datepipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');
        this.api.updateEWMaster(this.data).subscribe((successCode: any) => {

          if (successCode['code'] == "200") {
            this.getData();

            this.message.success("EW Incentive information Updated successfully...", "");
            console.log(this.data, 'Update Success')

            if (!addNew) {
              this.drawerClose1();
              this.reset(httpForm);
              this.isSpinning = false;
            }

            else {
              this.data = new slabMaster();
              this.message.error("Failed To Update", "");
              console.log(this.data, "fail Update")

              this.isSpinning = false;
            }
            // console.log(this.data.APPLICABLE_TYPES);
          }

        });

      }
      else {

        this.isSpinning = true;
        this.data.FROM_DATE = this.datepipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
        this.data.TO_DATE = this.datepipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');

        this.api.createEWMaster(this.data)
          .subscribe(successCode => {

            if (successCode['code'] == "200") {
              this.message.success("EW Incentive information created successfully...", "");
              console.log(this.data, "create Success");
              this.getData();
              if (!addNew) {
                // this.isSpinning = true;
                this.drawerClose1();
                httpForm.resetForm();
              }
              else {
                this.data = new slabMaster();
                httpForm.resetForm();

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add user information...", "");
              console.log(this.data, "Fail data")

              this.isSpinning = false;
            }
          });

      }

    }

  }



  close(httpForm: NgForm) {
    this.reset(httpForm);
    this.drawerClose1();
    this.data = new slabMaster();
  }

  reset(httpForm: NgForm) {
    httpForm.reset();
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
