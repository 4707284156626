// import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ShiftMaster } from 'src/app/Models/shiftmaster';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';
import { ShiftMaster } from 'src/app/Models/shift1';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shiftmaster',
  templateUrl: './shiftmaster.component.html',
  styleUrls: ['./shiftmaster.component.css'],
  providers: [DatePipe]

})
export class ShiftmasterComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: ShiftMaster;
  @Input() drawerVisible: boolean;

  listdata1 = [];
  listdata2 = [];
  orgId = this.cookie.get('orgId');
  loadingRecords = true;
  isSpinning = false;
  fileData: File = null;
  isOk = true;

  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  longitudepattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
  latitudepattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
  pat1 = /^\d{6}$/;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  cityRegex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  aaddpattern = /^[a-zA-Z0-9\s,'-]*$/
  namepatte = /[ .a-zA-Z]+/
  passpattern = /^\S*$/
  imgUrl
  time
  time1
  time2
  date

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.getallorg1();

  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  close(shiftsMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(shiftsMasterPage);
  }

  resetDrawer(shiftsMasterPage: NgForm) {
    shiftsMasterPage.form.reset();
    if(this.data.TYPE=="R"){
      this.data.TYPE="R"
    }
    else{
      this.data.TYPE="F"
    }
    
  
  }

  add(): void {

  }

  emailExist = false;

  // getallorg1() {
  //   this.api.getAllShifts(0, 0, 'ID', 'desc', "").subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.listdata1 = data['data'];
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }

  // getallorg2(id) {
  //   this.api.getAllShifts(0, 0, 'ID', 'desc', " and ID !=" + id).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.listdata2 = data['data'];
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }


  getallorg1() {
    this.listdata1 = [];

    this.api.getAllShifts(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getallorg2(id) {
    this.listdata2 = [];

    var tempEmpList = this.listdata1.filter(obj1 => {
      return obj1["ID"] != id;
    });

    this.listdata2 = tempEmpList;
  }

  // NAME:string
  // CODE:string
  // TYPE:string="R"
  // BEGIN_TIME:any
  // END_TIME:any
  // END_NEXT_DAY:boolean
  // IS_BREAK:boolean
  // BREAK_START_TIME:any
  // BREAK_END_TIME:any
  // MARK_HALFDAY_AFTER:any
  // MARK_HALFDAY_BEFORE:any
  // MARK_FULLDAY_AFTRE:any
  // MARK_FULLDAY_BEFORE:any
  // GRACE_TIME:boolean
  // GRACE_MINUTES:string
  // TOTAL_WORKING_TIME:string
  // BREAKE_TIME:string
  // NO_OF_BREAKS:number

  save(addNew: boolean, shiftsMasterPage: NgForm): void {
    this.isOk = true;

    if (this.data.NAME == undefined && this.data.CODE == undefined) {
      this.isOk = false;
      this.message.error('Please Fill All Required Information', '');

    } else if (this.data.NAME == undefined || this.data.NAME == '') {

      this.isOk = false

      this.message.error('Please Enter Name', '')
    } else if (this.data.CODE == '' || this.data.CODE == undefined) {

      this.isOk = false;

      this.message.error('Please Enter Code', '');

    } else if (this.data.TYPE == 'R') {

      if (this.data.BEGIN_TIME == null || this.data.BEGIN_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Day Start Time", '');
      }

      else if (this.data.END_TIME == null || this.data.END_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Day End Time", '');
      }
      else if (this.data.MAX_LATEMARK_TIME == null || this.data.MAX_LATEMARK_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Max Latemark Time ", '');

      }

      else if (this.data.MAX_EARLYMARK_TIME == null || this.data.MAX_EARLYMARK_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Min Earlymark Time ", '');
      }

      else if (this.data.MAX_APPLIED_LATEMARK_TIME == null || this.data.MAX_APPLIED_LATEMARK_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select  Max Latemark Applied Time", '');
      }

      else if (this.data.MAX_EARLYMARK_APPLIED_TIME == null || this.data.MAX_EARLYMARK_APPLIED_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Max Earlymark Applied Time ", '');
      }

      else if (this.data.HALFDAY_TIME == null || this.data.HALFDAY_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Half Time ", '');

      }
      else if (this.data.MAX_LATEMARK_COUNT == undefined || this.data.MAX_LATEMARK_COUNT == null) {

        this.isOk = false

        this.message.error('Please Enter Max latemark count', '')
      }


      else if (this.data.MAX_EARLYMARK_COUNT == undefined || this.data.MAX_EARLYMARK_COUNT == null) {

        this.isOk = false

        this.message.error('Please Enter Max early count', '')
      }



      else if (this.data.MAX_LATECOMING_COUNT == undefined || this.data.MAX_LATECOMING_COUNT == null) {

        this.isOk = false

        this.message.error('Please Enter Max latecoming count', '')
      }

      if (this.data.IS_BREAK == true) {
        if (this.data.BREAK_START_TIME == null || this.data.BREAK_START_TIME == undefined) {
          this.isOk = false;
          this.message.error("Please Select Break Start Time ", '');
        }

        else if (this.data.BREAK_END_TIME == null || this.data.BREAK_END_TIME == undefined) {
          this.isOk = false;
          this.message.error("Please Select Break End Time ", '');

        }
      }

      if (this.data.GRACE_TIME == true) {
        if (this.data.GRACE_MINUTES == undefined && this.data.GRACE_MINUTES == '') {
          this.isOk = false;
          this.message.error('Please Enter Grace Minute Information', '');

        }
      }
    }
    else if (this.data.TYPE == 'F') {

      if (this.data.TOTAL_WORKING_TIME == undefined || this.data.TOTAL_WORKING_TIME == '') {

        this.isOk = false

        this.message.error('Please Enter Total Working Time', '')
      } if (this.data.BREAKE_TIME == '' || this.data.BREAKE_TIME == undefined) {

        this.isOk = false;

        this.message.error('Please Enter Break Time', '');

      }
    }

    if (this.isOk) {
      this.isSpinning = true;

      this.orgId = this.cookie.get('orgId');
     

      // this.data.MARK_HALFDAY_AFTER = this.datePipe.transform(this.data.MARK_HALFDAY_AFTER, "HH:mm:ss");
      // this.data.MARK_HALFDAY_BEFORE = this.datePipe.transform(this.data.MARK_HALFDAY_BEFORE, "HH:mm:ss");



      // this.data.MARK_FULLDAY_BEFORE = this.datePipe.transform(this.data.MARK_FULLDAY_BEFORE, "HH:mm:ss");


      if (this.data.ID) {

        var emailData2 = this.listdata2.filter(obj => {
          return (obj.CODE == this.data.CODE)
        });
        if (emailData2.length == 0) {

          if (this.data.TYPE == 'R') {
            this.data.BEGIN_TIME = this.datePipe.transform(this.data.BEGIN_TIME, "HH:mm:ss");
            console.log(this.data.BEGIN_TIME, "this.data.BEGIN_TIME")
            this.data.END_TIME = this.datePipe.transform(this.data.END_TIME, "HH:mm:ss");
            this.data.MAX_APPLIED_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_APPLIED_LATEMARK_TIME, "HH:mm:ss");
            this.data.MAX_EARLYMARK_APPLIED_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_APPLIED_TIME, "HH:mm:ss");
            this.data.MAX_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_LATEMARK_TIME, "HH:mm:ss");
            this.data.MAX_EARLYMARK_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_TIME, "HH:mm:ss");
            this.data.HALFDAY_TIME = this.datePipe.transform(this.data.HALFDAY_TIME, "HH:mm:ss");
            if (this.data.IS_BREAK == true) {
              this.data.BREAK_START_TIME = this.datePipe.transform(this.data.BREAK_START_TIME, "HH:mm:ss");
              this.data.BREAK_END_TIME = this.datePipe.transform(this.data.BREAK_END_TIME, "HH:mm:ss");
            }
          }
          this.api.updateShifts(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Shift Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.isSpinning = false;
              this.resetDrawer(shiftsMasterPage);

            } else {
              this.message.error("Shift Updation Failed", "");
              this.isSpinning = false;
            }
          });

        }
        else {

          this.message.error(" Code Already Exist. Please Enter Other  Code", "");
          this.isSpinning = false;
        }
      }
      else {

        var emailData = this.listdata1.filter(obj => {
          return (obj.CODE == this.data.CODE)

        })


        if (emailData.length == 0) {

          if (this.data.TYPE == 'R') {
            this.data.BEGIN_TIME = this.datePipe.transform(this.data.BEGIN_TIME, "HH:mm:ss");
            console.log(this.data.BEGIN_TIME, "this.data.BEGIN_TIME")
            this.data.END_TIME = this.datePipe.transform(this.data.END_TIME, "HH:mm:ss");
            this.data.MAX_APPLIED_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_APPLIED_LATEMARK_TIME, "HH:mm:ss");
            this.data.MAX_EARLYMARK_APPLIED_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_APPLIED_TIME, "HH:mm:ss");
            this.data.MAX_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_LATEMARK_TIME, "HH:mm:ss");
            this.data.MAX_EARLYMARK_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_TIME, "HH:mm:ss");
            this.data.HALFDAY_TIME = this.datePipe.transform(this.data.HALFDAY_TIME, "HH:mm:ss");
            if (this.data.IS_BREAK == true) {
              this.data.BREAK_START_TIME = this.datePipe.transform(this.data.BREAK_START_TIME, "HH:mm:ss");
              this.data.BREAK_END_TIME = this.datePipe.transform(this.data.BREAK_END_TIME, "HH:mm:ss");
            }
          }
          
          this.api.createShift(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Shift Created Successfully", "");

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(shiftsMasterPage);
                this.getallorg1();
                

              } else {
                this.data = new ShiftMaster();
                this.resetDrawer(shiftsMasterPage);
              }

            } else {
              this.message.error("Shift Creation Failed", "");
              this.isSpinning = false;
            }
          });


        }
        else {
          this.message.error("Code Already Exist. Please Enter Other Code", "");
          this.isSpinning = false;
        }
      }

    }


    // onFileSelected(event) {
    //   this.imgUrl = event.target.value;
    //   this.fileData = <File>event.target.files[0];
    //   console.log(this.fileData);
    //   var fileExt = this.fileData.name.split('.').pop();
    //   console.log("extesion: " + fileExt);
    // }

  }
}
