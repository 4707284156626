import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { directIncentiveRatesDetails } from 'src/app/Models/directIncentiveRateDetails';
// import { directIncentiveRatesDetails } from 'src/app/Models/directIncentiveRateDetails';
import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
import { ApiService } from 'src/app/Service/api.service';
// import * as html2pdf from 'html2pdf.js';
// import { directIncentiveRatesDetails } from '../Models/directIncentiveRateDetails';
// import { rateMaster } from '../Models/IncentiveRateMaster';
// import { ApiService } from '../Service/api.service';
@Component({
  selector: 'app-rate-master',
  templateUrl: './rate-master.component.html',
  styleUrls: ['./rate-master.component.css']
})
export class RateMasterComponent implements OnInit {

  @Input() data: rateMaster = new rateMaster();
  @Input() directIncentiveRatesDetails: directIncentiveRatesDetails = new directIncentiveRatesDetails();

  constructor(private datepipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService,) { }

  columns: string[][] = [["INCENTIVE_STRUCUTURE_NAME", "Incentive Name"],
  ["FROM_DATE", "From Date"],
  ["TO_DATE", "To date"]]

  formTitle = "Sales Incentive Rate Master";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isVisible1 = false;
  isVisible3 = false;

  isSpinning = false;
  loadingRecords = false;

  drawerTitle: string | undefined;
  drawerTitle1: string | undefined;
  drawerTitle3: string | undefined;


  // apkVersionModalTitle="Incentive Structure For Sales"

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;
  selectedIndex = 0

  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  dataList: any[] = [];
  user: rateMaster = new rateMaster();
  user1: rateMaster = new rateMaster()
  user12: rateMaster = new rateMaster()

  drawerClose() {

    this.isVisible = false;
    this.getData();

  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  //rateMapping
  getroles = []
  getRolesData() {
    this.api.getAllRoles(0, 0, '', 'asc', ' AND STATUS=1').subscribe(successCode => {
      if (successCode['code'] == 200) {

        this.getroles = successCode['data']
        console.log(this.getroles);

      }
    });

  }
  getModel = [];
  getModelNames() {
    this.api.getAllModels(0, 0, '', '', '').subscribe(successCode => {
      this.getModel = successCode['data']
    })
  }

  getDirectIncentiveDetails() {
    this.api.getDirectIncentiveRatesDetails(0, 0, '', '', 'AND STATUS=1').subscribe(data => {
      this.directIncentiveRatesDetails = data['data']
    })
  }

  getData() {
    this.api.getRateMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '').subscribe((data: any) => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.totalRecords = data['count'];
      console.log(this.dataList, 'getFunction');
      // console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE, 'getClubbed');


    }, err => {
      console.log(err);
    });
  }
  drawerClose1() {
    this.selectedIndex = 0
    this.isVisible1 = false;
    this.obj = []
    // this.getroles=[]
    this.drawerData12 = []
    this.getData();
    // this.getRolesData();
    // this.getModelNames();
    // this.getDirectIncentiveDetails();
  }

  drawerClose12() {
    this.selectedIndex = 0
    this.isVisible12 = false;
    this.obj = []
    // this.getroles=[]
    this.drawerData123 = []

    this.getData();
    // this.getRolesData();
    // this.getModelNames();
    // this.getDirectIncentiveDetails();
  }
  get closeCallback12() {
    return this.drawerClose12.bind(this);
  }


  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }




  drawerClose3() {

    this.isVisible3 = false;
    this.getData();
    // this.getRolesData();
    // this.getModelNames();
    // this.getDirectIncentiveDetails();
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


    this.api.getRateMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');


    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND FROM_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    // if (this.data.ALLOTMENT_TYPE_ID != null) {
    //   this.filterQuery =
    //     this.filterQuery + ' AND ALLOTMENT_TYPE_ID=' + "" + this.data.ALLOTMENT_TYPE_ID + "";
    // }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.api.getRateMaster(0, 0, this.sortKey, sort, this.filterQuery)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );

  }



  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = null;
    this.endValue = null;

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }

  modeltype = []
  varienttype = []
  fueltype = []
  tranmission = []
  ngOnInit() {
    this.search();
    this.getData();
    this.getModelNames();
    this.getRolesData();
    this.getDirectIncentiveDetails();
    this.getModels();
    this.getAreaData();
  }


  getModels() {
    this.model = [];

    this.api.getAllModels(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
        console.log(this.modeltype, "ok1");

      }
    }, err => {
      console.log(err);
    });

    this.api.getAllVarients(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllFuel(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


    this.api.getAllTransmission(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.tranmission = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  assign: any = []
  drawerVisible1: boolean = false
  drawerData: any
  mapBranch(data: rateMaster) {
    this.drawerTitle3 = "Branch Map"
    this.assign = Object.assign({}, data);
    console.log(this.assign, 'dd')

    this.api.getBranch(this.cookie.get('orgId'), this.assign.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerData = data['data'];
      }
      console.log(this.drawerData, "jhh")
    }, err => {
      console.log(err);
    });



    // this.drawerTitleEmployeeCollege = "Location for " + data.NAME + "";
    // this.user = Object.assign({},Data);
    this.isVisible3 = true;


  }

  add() {
    this.isVisible = true;
    this.drawerTitle = "Create Sales Incentive Rate";
    this.user12 = new rateMaster()
  }

  edit(data: rateMaster) {
    this.isVisible = true;
    this.drawerTitle = 'Update Sales Incentive Rate';
    this.user12 = Object.assign({}, data);
  }
  ROLES2 = []
  BRANCHES2 = []
  ROLES22 = []
  BRANCHES22 = []
  drawerData12 = []
  dataa = []
  model = []
  obj = []
  rateEdit(data) {
    this.isVisible1 = true;
    console.log(data);

    // this.user = Object.assign({},data1)
    this.drawerTitle1 = 'Direct Incentive Mapping';
    // this.getRolesData();
    // this.getModelNames();
    // this.getDirectIncentiveDetails();


    this.ROLES2 = []
    this.BRANCHES2 = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    this.user = Object.assign({}, data);
    if (data.ROLE_IDS != null && data.MODEL_IDS != null) {
      this.ROLES2 = data.ROLE_IDS.split(",")
      this.BRANCHES2 = data.MODEL_IDS.split(",")
      console.log(this.BRANCHES2);

      // this.BRANCHES2.split(",")
      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    }


    // console.log(this.model);


    this.dataa = [];

    for (let i = 0; i < this.getroles.length; i++) {

      var kk = this.ROLES2.findIndex(x => x == this.getroles[i]['ID']);
      if (kk > -1) {
        this.getroles[i]['IS_SELECTED'] = true;
      } else {
        this.getroles[i]['IS_SELECTED'] = false;
      }


    }
    // console.log(this.getroles);

    // for (let i = 0; i < this.BRANCHES2.length; i++) {
    //   var kk = this.getModel.findIndex(x => x.ID == this.BRANCHES2[i]);
    //   this.BRANCHES22.push(this.getModel[kk]['NAME']);
    // }

    this.api.getDirectIncentiveRatesDetails(0, 0, '', 'asc', ' AND DIRECT_INCENTIVE_RATES_MASTER_ID=' + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.dataa = data['data'];
        console.log(data['data']);
        this.drawerData12 = this.dataa

      }
    }, err => {
      console.log(err);
    });


  }

  drawerTitle2 = ''
  show1: any = []
  Roletype = [];
  Roletype1 = []
  ROLES21 = []
  data24 = []
  drawerData123
  isVisible12 = false

  getAreaData() {
    this.api.getAreaMaster(0, 0, '', 'asc', 'AND STATUS=1').subscribe(successCode => {
      if (successCode['code'] == 200) {

        this.Roletype1 = successCode['data']
        console.log(this.Roletype1);

      }
    });

  }
  supportStaff(data: any) {
    console.log(data);

    this.isVisible12 = true;
    this.drawerTitle2 = " Support Staff Mapping"

    // this.show1 = Object.assign({}, data);
    console.log(this.show1, 'dd')

    this.ROLES2 = []
    this.BRANCHES2 = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    this.user1 = Object.assign({}, data);
    if (data.STAFF_IDS != null) {
      this.ROLES21 = data.STAFF_IDS.split(",")

      for (let i = 0; i < this.Roletype1.length; i++) {

        var kk = this.ROLES21.findIndex(x => x == this.Roletype1[i]['ID']);
        if (kk > -1) {
          this.Roletype1[i]['IS_SELECTED'] = true;
        } else {
          this.Roletype1[i]['IS_SELECTED'] = false;
        }


      }

    }
    if (data.MODEL_IDS != null) {

      this.BRANCHES2 = data.MODEL_IDS.split(",")
      console.log(this.BRANCHES2);
      console.log(this.ROLES2, "this.ROLES2")
      this.obj = []
      // this.BRANCHES2.split(",")
      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    } else {

      // this.message.error("Models does not present", '');
    }


    this.data24 = [];

    this.api.getSupportStaffIncentiveRatesDetails(0, 0, '', 'asc', ' AND DIRECT_INCENTIVE_RATES_MASTER_ID=' + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.data24 = data['data'];
        console.log(data['data'], "");
        this.drawerData123 = this.data24

      }
    }, err => {
      console.log(err);
    });

  }


  // rateEdit(data){

  //   this.isVisible1=true;
  //     console.log(data);
  //     this.user = Object.assign({},data)



  //   this.api.getAllRoles(0, 0, '', '', '').subscribe(successCode =>{
  //     if(successCode['code']==200)
  //     this.drawerData12 = successCode['data']
  //     console.log(this.drawerData12);
  //    })

  // }


  isApkVersionModalVisible: boolean = false

  handleApkVersionModalCancel() {
    this.isApkVersionModalVisible = false
    this.dataListtt = []
    this.dataListdata = []
    this.MODELSadd = []
    this.obj = []
    this.va = []
    this.ROLEIDSSS = []

  }
  handleApkVersionModalOk() {
    // const printContents = document.getElementById('report_id').innerHTML;

    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();

    // document.body.innerHTML = originalContents;
    // window.location.reload();
    var i = 0;
    var date = new Date();
    var datef = this.datepipe.transform(date, "dd-MM-yyyy");
    var dates = this.datepipe.transform(date, "h:mm:ss a");
    var data = document.getElementById('report_id');

    var opt = {

      margin: 0.2,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
    };
    // html2pdf().set(opt).from(data).save();

    this.isApkVersionModalVisible = false

    // this.generatePDF()

  }

  public generatePDF() {

    var i = 0;
    var date = new Date();
    var datef = this.datepipe.transform(date, "dd-MM-yyyy");
    var dates = this.datepipe.transform(date, "h:mm:ss a");
    var data = document.getElementById('report_id');

    var opt = {

      margin: 0.2,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
    };
    // html2pdf().set(opt).from(data).save();



  }

  loadingTable:boolean = false;
  loadingTable12:boolean = false;
  SendData1 = []
  Final = []
  Finalll = []
  va = []
  MODELIDS = []
  ROLEIDS = []
  ROLESSS = []
  dataListtt = []
  MODELSSSS = []
  // Roletype=[]
  ids = []
  dataaa = []
  dataListdata = []
  AllROLESSS = []
  ROLEIDSSS = []
  MODELSadd = []
  va1 = []
  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }


  STAFFIDSD = []
  isLoading = false
  namequote = '';
  print(data) {
    this.namequote = data.INCENTIVE_STRUCUTURE_NAME;
    this.loadingTable = true;
    this.loadingTable12 = false;
    this.isApkVersionModalVisible = false
    this.isLoading = true
    console.log(data);
    this.ROLES2 = []
    this.AllROLESSS = []

    this.MODELSadd = []
    this.BRANCHES2 = []

    this.ROLESSS = []
    this.va = [];
    this.va1 = []

    this.MODELSSSS = []
    this.Roletype = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    this.user = Object.assign({}, data);
    if (data.ROLE_IDS != null && data.MODEL_IDS != null) {
      this.ROLES2 = data.ROLE_IDS.split(",")
      this.BRANCHES2 = data.MODEL_IDS.split(",")
      console.log(this.BRANCHES2);

      // this.BRANCHES2.split(",")
      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    }
    this.dataa = [];
    for (let i = 0; i < this.getroles.length; i++) {
      var kk = this.ROLES2.findIndex(x => x == this.getroles[i]['ID']);
      if (kk > -1) {
        this.getroles[i]['IS_SELECTED'] = true;
      } else {
        this.getroles[i]['IS_SELECTED'] = false;
      }
    }

    this.api.getDirectIncentiveRatesDetails(0, 0, '', 'asc', ' AND DIRECT_INCENTIVE_RATES_MASTER_ID=' + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.dataa = data['data'];
        console.log(data['data']);
        this.drawerData12 = this.dataa
        this.dataListtt = this.drawerData12

        console.log(this.dataListtt, "this.dataListtt");
        setTimeout(()=>{
          this.loadingTable = false;
          this.loadingTable12 = true;
        },2500);
      }
    }, err => {
      console.log(err);
    });
    this.SendData1 = []
    this.Final = []

    this.va1 = []

    this.ROLESSS = this.ROLES2
    if (data.STAFF_IDS != null) {
      this.STAFFIDSD = data.STAFF_IDS.split(",")
      console.log(this.STAFFIDSD, "hii");

    } else {
      this.ROLEIDSSS = []
      this.AllROLESSS = []
      this.STAFFIDSD = []
    }


    this.MODELSSSS = this.obj
    this.Roletype = this.getroles
    this.Finalll = []
    this.MODELIDS = []
    this.ROLEIDS = []
    this.AllROLESSS = this.STAFFIDSD
    console.log(this.AllROLESSS);

    this.api.getSupportStaffIncentiveRatesDetails(0, 0, '', 'asc', ' AND DIRECT_INCENTIVE_RATES_MASTER_ID=' + data.ID).subscribe(data11 => {
      if (data11['code'] == 200) {
        this.isLoading = false
        this.isApkVersionModalVisible = true
        // this.dataaa = data11['data'];
        // console.log(data['data']);

        this.dataListdata = data11['data'];
        console.log(this.dataListdata);
        this.MODELSadd = this.obj
        for (let i = 0; i < this.AllROLESSS.length; i++) {
          var kk = this.Roletype1.findIndex(x => x.ID == this.AllROLESSS[i]);
          this.ROLEIDSSS.push(this.Roletype1[kk]['NAME']);
          this.va = [];
          this.SendData1 = [];
          for (let j = 0; j < this.MODELSadd.length; j++) {
            var kk = this.modeltype.findIndex(x => x.ID == this.MODELSadd[j]['MODEL1']);


            var ss = this.obj[kk]['NAME']
            this.MODELIDS.push(this.MODELSadd[j]['MODEL1']);

            var a = ''
            var b = ''
            var c = ''

            if (this.MODELSadd[j]['FULES'] != 0) {
              var kks = this.fueltype.findIndex(x => x.ID == this.MODELSadd[j]['FULES']);
              a = this.fueltype[kks]['NAME']

            } else {
              this.MODELSadd[j]['FULES'] = 0

              a = ""
            }

            if (this.MODELSadd[j]['VARIENT'] != 0) {
              var aa = this.varienttype.findIndex(x => x.ID == this.MODELSadd[j]['VARIENT']);
              b = (this.varienttype[aa]['NAME']);
            } else {
              this.MODELSadd[j]['VARIENT'] = 0
              b = ""
            }

            if (this.MODELSadd[j]['TRANSMISSION'] != 0) {
              var vv = this.tranmission.findIndex(x => x.ID == this.MODELSadd[j]['TRANSMISSION']);
              c = (this.tranmission[vv]['NAME']);
            } else {
              this.MODELSadd[j]['TRANSMISSION'] = 0
              c = ""

            }

            this.va.push(this.str(this.modeltype[kk]['NAME'], a, b, c))
            this.ids.push([this.MODELSadd[j]['MODEL1'], this.MODELSadd[j]['FULES'], this.MODELSadd[j]['VARIENT'], this.MODELSadd[j]['TRANSMISSION']])
            this.SendData1 = [...this.SendData1, this.MODELSadd[j]['MODEL1'] + "." + this.MODELSadd[j]['FULES'] + "." + this.MODELSadd[j]['VARIENT'] + "." + this.MODELSadd[j]['TRANSMISSION']];
            // console.log(this.ids);

            this.Finalll.push({
              "SUPPORT_STAFF_AREA_ID": this.AllROLESSS[i],
              "MODEL_ID": this.MODELSadd[j]['MODEL1'],
              "FUEL_TYPE_ID": this.MODELSadd[j]['FULES'],
              "VARIENT_ID": this.MODELSadd[j]['VARIENT'],
              "TRANSMISSION_TYPE_ID": this.MODELSadd[j]['TRANSMISSION'],
              "AMOUNT": 0
            })


          }
          if (i + 1 == this.dataListdata.length) {
            this.getAmount()
            this.dataListdata = [...[], ...this.Finalll];

          }

        }
        console.log(this.dataListdata);
        console.log(this.MODELSadd);
        setTimeout(()=>{
          this.loadingTable = false;
          this.loadingTable12 = true;
        },2500);
      }
    }, err => {
      console.log(err);
    });


    var abc = -2
    for (let i = 0; i < this.ROLESSS.length; i++) {
      var kk = this.getroles.findIndex(x => x.ID == this.ROLESSS[i]);
      this.ROLEIDS.push(this.getroles[kk]['NAME']);
      this.va1 = [];
      this.SendData1 = [];
      for (let j = 0; j < this.MODELSSSS.length; j++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.MODELSSSS[j]['MODEL1']);
        var ss = this.modeltype[kk]['NAME']
        this.MODELIDS.push(this.MODELSSSS[j]['MODEL1']);


        var a = ''
        var b = ''
        var c = ''
        if (this.MODELSSSS[j]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.MODELSSSS[j]['FULES']);
          a = this.fueltype[kks]['NAME']

        } else {
          this.MODELSSSS[j]['FULES'] = 0

          a = ""
        }

        if (this.MODELSSSS[j]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.MODELSSSS[j]['VARIENT']);
          b = (this.varienttype[aa]['NAME']);
        } else {
          this.MODELSSSS[j]['VARIENT'] = 0
          b = ""
        }

        if (this.MODELSSSS[j]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.MODELSSSS[j]['TRANSMISSION']);
          c = (this.tranmission[vv]['NAME']);
        } else {
          this.MODELSSSS[j]['TRANSMISSION'] = 0
          c = ""

        }

        this.va1.push(this.str(ss, a, b, c))

        this.ids.push([this.MODELSSSS[j]['MODEL1'], this.MODELSSSS[j]['FULES'], this.MODELSSSS[j]['VARIENT'], this.MODELSSSS[j]['TRANSMISSION']])
        this.SendData1 = [...this.SendData1, this.MODELSSSS[j]['MODEL1'] + "." + this.MODELSSSS[j]['FULES'] + "." + this.MODELSSSS[j]['VARIENT'] + "." + this.MODELSSSS[j]['TRANSMISSION']];
        //  console.log(this.va1.push(this.str(ss, a, b, c)));

        this.Final.push({
          "ROLE_ID": this.ROLESSS[i],
          "MODEL_ID": this.MODELSSSS[j]['MODEL1'],
          "FUEL_TYPE_ID": this.MODELSSSS[j]['FULES'],
          "VARIENT_ID": this.MODELSSSS[j]['VARIENT'],
          "TRANSMISSION_TYPE_ID": this.MODELSSSS[j]['TRANSMISSION'],
          "AMOUNT": 0
        })


      }
      if (i + 1 == this.ROLESSS.length) {

        this.dataListtt = [...[], ...this.Final];
        this.getAmount1()
      }

    }

    // setTimeout(()=>{
    //   this.loadingTable = false;
    //   this.loadingTable12 = true;
    // },2500);
  }

  getAmount() {

    if (this.dataListdata.length > 0) {


      this.dataListdata.forEach((x, index) => {
        this.Final.forEach((x2, index2) => {
          if (x.SUPPORT_STAFF_MAPPING_ID == 0 &&
            x.SUPPORT_STAFF_AREA_ID == x.SUPPORT_STAFF_AREA_ID &&
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Final[index2]['AMOUNT'] = this.dataListdata[index].AMOUNT
            console.log(this.dataListdata[index].AMOUNT, "")
          }
        })
        if (index + 1 == this.dataListdata.length) {
          this.dataListdata = [...[], ...this.Final];
          console.log(this.Final);

        }
      });
    } else {
      this.dataListdata = [...[], ...this.Final];
    }

  }

  getAmount1() {

    if (this.dataListtt.length > 0) {


      this.dataListtt.forEach((x, index) => {
        this.Final.forEach((x2, index2) => {
          if (x.DIRECT_INCENTIVE_RATES_MASTER_ID == x.DIRECT_INCENTIVE_RATES_MASTER_ID &&
            x.ROLE_ID == x.ROLE_ID &&
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Final[index2]['AMOUNT'] = this.dataListtt[index].AMOUNT
            console.log(this.dataListtt[index].AMOUNT, "")
          }
        })
        if (index + 1 == this.dataListtt.length) {
          this.dataListtt = [...[], ...this.Final];
          console.log(this.dataListtt);

        }
      });
    } else {
      this.dataListtt = [...[], ...this.Final];
    }

  }

}
