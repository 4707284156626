
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Branchmaster } from 'src/app/Models/Branchmaster';
import { Designationmaster } from 'src/app/Models/Designationmaster';
// import { Branchmaster } from 'src/app/Models/branchmaster';
// import { Branchmaster } from 'src/app/Models/Branchmaster';
import { Department } from 'src/app/Models/department';
// import { Designationmaster } from 'src/app/Models/designationmaster';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
// import { feedback } from 'src/app/Models/feedback';
import { ApiService } from 'src/app/Service/api.service';
import { feedback } from 'src/app/Models/feedformback';
// import { feedback } from 'src/app/Models/feedback';

@Component({
  selector: 'app-feedback-area',
  templateUrl: './feedback-area.component.html',
  styleUrls: ['./feedback-area.component.css']
})
export class FeedbackAreaComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: feedback;
  @Input() disa
  passwordVisible = false;
  password: string;
  isSpinning = false;
  dataList: feedback;
  roleLoading = false;
  // roles: Role[];

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
    //   this.roles = roles['data'];
    // }, err => {
    // });
  }

  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
   
    var isok = true
    if (this.data.NAME == undefined || this.data.NAME == null || this.data.NAME == '') {
      isok=false
      this.message.error("Please enter name", '')
    }
    else if (this.data.TYPE==''||this.data.TYPE==null||this.data.TYPE==undefined){
      isok=false
      this.message.error("Please select type", '')
    }
    else{
      isok=true
     }
     if(isok) {
      this.isSpinning = true;
      if (this.data.ID) {
        this.api.updatefeedarea(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Area information updated Successfully...", "");
            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
  
          } else {
            this.message.error("Failed to update Area information...", "");
            this.isSpinning = false;
          }
        });
  
      } else {
        this.api.createfeedarea(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Area information added successfully...", "");
  
            if (!addNew)
              this.drawerClose();
            else {
              this.data = new feedback();
            }
            this.isSpinning = false;
  
          } else {
            this.message.error("Failed to add area information...", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  // searchEmail(emailId): void {
  //   var likeQuery = "EMAIL_ID=" + emailId + "";

  //   this.api.getAllUsers(0, 0, "", "", likeQuery).subscribe(data => {
  //     console.log(data);
  //     this.dataList = data['data'];

  //   }, err => {
  //     console.log(err);
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });
  // }

}
