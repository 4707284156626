import { Component, OnInit } from '@angular/core';
import { tourModeMaster } from 'src/app/Models/tourMode';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-tour-mode-master',
  templateUrl: './tour-mode-master.component.html',
  styleUrls: ['./tour-mode-master.component.css']
})
export class TourModeMasterComponent implements OnInit {

  formTitle = "Tour Mode Master";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["MODE_NAME", "Tour Mode Name"]]
  drawerVisible!: boolean;
  drawerTitle!: string;

 
  drawerData: tourModeMaster=new tourModeMaster()
  data: tourModeMaster = new tourModeMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;

    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getTourMode(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerData = new tourModeMaster();
    this.drawerTitle = "Create New Tour Mode";
    this.drawerVisible = true;
  }

  edit(data:tourModeMaster): void {
    this.drawerTitle = "Update Tour Mode";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

}
