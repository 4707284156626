import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-sales-exemodal-insurance-report',
  templateUrl: './sales-exemodal-insurance-report.component.html',
  styleUrls: ['./sales-exemodal-insurance-report.component.css']
})
export class SalesExemodalInsuranceReportComponent implements OnInit { 
  columns: string[][] = [['BRANCH_NAME', 'Branch'], ['SALES_CONSULTANT_NAME', 'Sales Executive'], ['SALES_DATE', 'Date'],
  ['MODEL_NAME', 'Model'], ['INS_SALES_CONSULTANT_RATE', 'Insurance Ammount'],
  ['CUSTOMER_NAME', 'Customer Name'], ['INS_SALES_MANAGER_RATE', 'SM Incentive'], ['SALES_HEAD_NAME', 'SH Name'],
  ['INS_SALES_HEAD_RATE', 'SH Incentive'],]
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Sales Executive Wise Model Wise Detailed Insurance Report"
  startValue: any = new Date()
  endValue: any = new Date()
  isSpinning = false;
  insuranceExe = []
  totalRecords = []
  filterQuery: string = '';
  branches = []
  orgId = this.cookie.get('orgId')
  STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  BRANCH_ID: any = []
  SALES_EXECUTIVE_ID: any = []
  employee = []

  constructor(private api: ApiService, private cookie: CookieService, public datePipe: DatePipe,
    private _exportService: ExportService, private message: NzNotificationService) { }


  ngOnInit() {
    this.search()
    this.getBranches()
    this.getEmployee()
  }
  getBranches() {
    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.branches = data['data']
    })
  }
  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=28').subscribe(data => {
      this.employee = data['data']
    })
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  insurance2=[]
  LIKE_STRING:any

  search(reset: boolean = false, exportInExcel: boolean = false) {
    
    var filter = ""
    
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    var likeQuery = "";
    this.LIKE_STRING=this.searchText;

    // if (this.searchText != '') {
    //   likeQuery = ' AND (';
    //   this.columns.forEach((column) => {
    //     likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
    //   });

    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
      
    // }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getInsuranceExeModelReport(this.pageIndex, this.pageSize, this.sortKey, sort,this.LIKE_STRING, this.BRANCH_ID, this.SALES_EXECUTIVE_ID, this.STARTDATE, this.ENDDATE).subscribe(data => {
        this.loadingRecords = false;
        this.insuranceExe = data['data']
        this.totalRecords = data['count']
        console.log(this.insuranceExe);

      });
    }
    else {
      this.exportLoading = true;

      this.api.getInsuranceExeModelReport(0,0, this.sortKey, sort, this.LIKE_STRING, this.BRANCH_ID, this.SALES_EXECUTIVE_ID, this.STARTDATE, this.ENDDATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.insurance2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }

  }
  // startValue: Date | null = null;

  // endValue: Date | null = null;

  endOpen = false;

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }
  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_EXECUTIVE_ID != undefined) {
      this.filterQuery = this.SALES_EXECUTIVE_ID
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue
    }
    this.search();
    this.filterClass = "filter-invisible";
  }

  // clearFilter() {
  //   this.filterClass = 'filter-invisible';
  //   this.isFilterApplied = 'default';
  //   this.search()
  //   this.BRANCH_ID = []
  //   this.SALES_EXECUTIVE_ID = null
  //   this.startValue = new Date()
  //   this.endValue = new Date()
  //   this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  //   this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  
  // }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.BRANCH_ID = [] 
     this.SALES_EXECUTIVE_ID = []
    this.startValue = new Date()
    this.endValue = new Date()
    this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.search()
  }

  // fileName = 'Detailed Insurance Report.xlsx'
  // importInExcel(): void {
  //   this.loadingRecords = true;/* pass here the table id */

  //   let element = document.getElementById('excel-table');
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     XLSX.writeFile(wb, this.fileName);
  //   }, 1000);
  // }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.insurance2.length; i++) {
      obj1['Branch Name'] = this.insurance2[i]['BRANCH_NAME'];
      obj1['Sales Executive Name'] = this.insurance2[i]['SALES_CONSULTANT_NAME'];
      obj1['Sales Date'] = this.datePipe.transform(this.insurance2[i]['SALES_DATE'], 'yyyy-MM-dd');
      obj1['Model Name'] = this.insurance2[i]['MODEL_NAME'];
      obj1['Insurance Amount'] = this.insurance2[i]['INS_SALES_CONSULTANT_RATE'];
      obj1['Customer Name'] = this.insurance2[i]['CUSTOMER_NAME'];
      obj1['SM Incentive'] = this.insurance2[i]['INS_SALES_MANAGER_RATE'];
      obj1['SH Name'] = this.insurance2[i]['SALES_HEAD_NAME'];
      obj1['SH Incentive'] = this.insurance2[i]['INS_SALES_HEAD_RATE'];
     

      arry1.push(Object.assign({}, obj1));
      if (i == this.insurance2.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Model Wise Detailed Insurance Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }
}
