import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branchwisemodelwisesalesreport',
  templateUrl: './branchwisemodelwisesalesreport.component.html',
  styleUrls: ['./branchwisemodelwisesalesreport.component.css']
})
export class BranchwisemodelwisesalesreportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Branch Wise Model Wise Sales Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODEL_ID: any = [];
  BRANCH_ID: any = [];
  models: any = [];
  branch: any = [];
  frmquotationdate: any;
  toquotationdate: any;
  isOk = true;
  columns: string[][] = [["BRANCH_NAME", "Branch Name"], ["MODEL_NAME", "Model"]
    // ["QUTATION_GENERATED","Total Quotations"],["CONVERTED_TO_MEMO","No. of Memo Converted"],
    // ["CONVERTED_TO_INVOICE","No. of invoice generated"],["DISCOUNT_APPLIED","Total discount applied"]
  ];
  dataList1: any;
  exportLoading: boolean;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {
    // this.MODEL = "";
    // this.BRANCH = "";
    this.getAllModels();
    // this.getAllBranch();
    // this.search(false);
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    this.frmquotationdate = new Date(y, m, 1)
    this.toquotationdate = new Date()
    this.toquotationdate = this.datePipe.transform(this.toquotationdate, "yyyy-MM-dd")
    this.frmquotationdate = this.datePipe.transform(this.frmquotationdate, "yyyy-MM-dd")
    // console.log(this.toquotationdate)

  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      frmquotationdate:this.frmquotationdate, 
      toquotationdate:this.toquotationdate, 
      BRANCH_ID:this.BRANCH_ID,
      MODEL_ID: this.MODEL_ID,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 17').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(){
    
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  getAllModels() {
    this.api.getAllModels(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.models = data['data']
      if (this.models.length > 0) {
        this.MODEL_ID = [data['data'][0]['ID']];
        console.log(this.models);
      }
      else {
        console.log('Error');
      }
      this.getAllbranches();
    }, (err) => {
      console.log(err);
    });
  }

  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      let ids = [];
      if (this.SELECT_BRANCH == true) {
        for (var i = 0; i < this.branch.length; i++) {
          ids.push(this.branch[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.BRANCH_ID = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids
  }

  SELECT_BRANCH: boolean = true;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    if (this.searchText != "") {
      likeQuery += this.searchText;
    }
    console.log("search text:" + this.searchText);

    // if (this.searchText != "") {
    //   var likeQuery = " AND (";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

    //   var filterQuery = this.filterQuery + likeQuery
    // }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getbranchwisemodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.frmquotationdate, this.toquotationdate, this.MODEL_ID, this.BRANCH_ID).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }

      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = false;
      this.api.getbranchwisemodelwiseprice(0, 0, this.sortKey, sort, likeQuery, this.frmquotationdate, this.toquotationdate, this.MODEL_ID, this.BRANCH_ID).subscribe(data => {
        if (data['code'] == 200) {
        this.exportLoading = false;
        this.dataList1 = data['data'];
        this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    // this.isSpinning = true
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.frmquotationdate = this.datePipe.transform(this.frmquotationdate, "yyyy-MM-dd")
    this.toquotationdate = this.datePipe.transform(this.toquotationdate, "yyyy-MM-dd")

    // if (this.DATE2[0] != null) {
    //   this.MEMO_START_DATE = this.DATE2[0]
    // }
    // if (this.DATE2[1] != null) {
    //   this.MEMO_END_DATE = this.DATE2[1]
    // }

    if (this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID;
    }

    if (this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

   
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
    this.search(true);
  }
  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    this.frmquotationdate = new Date(y, m, 1)
    this.toquotationdate = new Date()
    this.toquotationdate = this.datePipe.transform(this.toquotationdate, "yyyy-MM-dd")
    this.frmquotationdate = this.datePipe.transform(this.frmquotationdate, "yyyy-MM-dd")
    this.getAllModels();

    this.SELECT_BRANCH = true
    let ids = [];
    for (var i = 0; i < this.branch.length; i++) {
      ids.push(this.branch[i]["ID"]);
    }
    this.BRANCH_ID = ids;

  }
  importInExcel() {
    this.search(true, true);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Branch'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Quotation Generated'] = this.dataList1[i]['QUTATION_GENERATED'];
      obj1['Converted To Memo'] = this.dataList1[i]['CONVERTED_TO_MEMO'];
      obj1['Converted To Invoice'] = this.dataList1[i]['CONVERTED_TO_INVOICE'];
      obj1['Discount Applied'] = this.dataList1[i]['DISCOUNT_APPLIED'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Model Wise Sales Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
}
