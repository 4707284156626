import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: 'app-apptlisttreport',
  templateUrl: './apptlisttreport.component.html',
  styleUrls: ['./apptlisttreport.component.css']
})
export class ApptlisttreportComponent implements OnInit {

  formTitle = "Daily Appointment Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'EWSalesSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  newdate = new Date()
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["SERVICE_ADVISER_NAME", "Service Adviser Name"], ["BT_NUMBER", "BT No."], ["BOOKING_DATE", "Booking Date"], ["CUSTOMER_NAME", "Customer"], ["APPOINTMENT_BRANCH_NAME", "Branch"],
  ["MOBILE_NUMBER", "Mobile No."], ["REGISTRATION_NO", "Reg. No."], ["WORK_TYPE", "Work Type"], ["EXPRESS_CARE", "Express Care"], ["VEHICALE_IN_TIME", "Vehicle In Time"], ["REMARK", "Remark"], ["SPECIAL_MESSAGE", "Special Message"]];

  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  TOTAL = ''

  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;

  SELECT_EXECUTIVE
  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_CCE_ID: any = [];

  START_DATE: any;
  END_DATE: any;
  DATE = [];
  DATE1: any = new Date()
  current = new Date();

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      START_DATE: this.START_DATE,
      END_DATE: this.END_DATE,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 69').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }


  allemp = []
  CCE_ID: any

  counter: number = 1;
  ngOnInit(): void {

    this.dataList.forEach(() => {
      this.counter++;
    });

    this.getEmployee()
  }

  sales = []
  // CCE_ID
  employees = []
  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])
        console.log(this.sales);
        this.getSalesadvisor()
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  keyup(event: any) {
    this.search(true);
  }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }
  onPrint() {
    const printContents = document.getElementById('report_id').innerHTML;

    const originalContents = document.body.innerHTML;
    // var newWin = window.open('', 'Print-Window', 'width=1200,height=700');
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload();

  }
  isApproveVisible11 = false
  handleCancel2(): void {
    console.log('Button cancel clicked!');
    this.isApproveVisible11 = false;
    this.ok = false
    this.search()
  }

  drawerClose1() {

  }

  ok: boolean = false
  showModal2(): void {
    this.isApproveVisible11 = true;

    this.ok = true

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    // if (this.DATE != undefined && this.DATE.length != 0) {
    //   this.START_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
    //   this.END_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    // }

    this.DATE1 = this.datePipe.transform(this.DATE1, "yyyy-MM-dd");

    if (this.roleId == 43) {
      // if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
      this.CCE_ID = this.userId
      // }
    }
    else {
      // if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
      this.CCE_ID = this.CCE_ID
      // }
    }

    this.loadingRecords = true;
    this.api.getappointmentdata(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.DATE1, this.CCE_ID)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          console.log(this.dataList);

          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        },
        (err) => {
          console.log(err);
        }
      );

  }

  SERVICE_ADVISOR: any
  BRANCH_IDS: any
  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;


    if (this.SALES_CCE_ID != undefined) {
      this.SALES_CCE_ID = this.SALES_CCE_ID;
    }

    this.DATE1 = this.datePipe.transform(this.DATE1, "yyyy-MM-dd");

    // this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    // this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    // if (this.DATE[0] != null) {
    //   this.START_DATE = this.DATE[0]
    // }

    // if (this.DATE[1] != null) {
    //   this.END_DATE = this.DATE[1]
    // }

    if (this.BRANCH_IDS != undefined) {
      this.BRANCH_IDS = this.BRANCH_IDS;
    }
    if (this.SERVICE_ADVISOR != undefined) {
      this.SERVICE_ADVISOR = this.SERVICE_ADVISOR;
    }

    this.search(true);

  }

  getstatus(CATEGOREY: any) {
    // console.log(CATEGOREY);
    if (CATEGOREY == "L")
      return "HMIL Lost";
    else if (CATEGOREY == "NS")
      return "No Show";
    else if (CATEGOREY == "C")
      return "Confirmation";
    else if (CATEGOREY == "SE")
      return "Service Due";
    else if (CATEGOREY == "WD")
      return "Work Done";
    else if (CATEGOREY == "OY")
      return "One Year";
    else if (CATEGOREY == "A")
      return "Appointment";
    else if (CATEGOREY == "MT")
      return "Mid Term";
    else if (CATEGOREY == "SD")
      return "Sales Data";
    else if (CATEGOREY == "O")
      return "Own Calling";
    else
      return CATEGOREY;


  }


  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.getEmployee()
    this.DATE1 = new Date()
    // this.CCE_ID = this.CCE_ID
    // this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    // this.DATE[1] = new Date();

    this.search(true);

  }
  ewsalesdata = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }


  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    this.filterQuery = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    // if (this.DATE != undefined && this.DATE.length != 0) {
    //   this.START_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
    //   this.END_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    // }

    this.DATE1 = this.datePipe.transform(this.DATE1, "yyyy-MM-dd");

    if (this.roleId == 43) {
      // if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
      this.CCE_ID = this.userId
      // }
    }
    else {
      // if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
      this.CCE_ID = this.CCE_ID
      // }
    }


    var filterService = "";
    if (this.SERVICE_ADVISOR != '' && this.SERVICE_ADVISOR != null && this.SERVICE_ADVISOR != undefined) {
      filterService = " AND SERVICE_ADVISER_ID IN (" + this.SERVICE_ADVISOR + ")";
    }

    var filterBranch = "";
    if (this.BRANCH_IDS != '' && this.BRANCH_IDS != null && this.BRANCH_IDS != undefined) {
      filterBranch = " AND APPOINTMENT_BRANCH_ID IN (" + this.BRANCH_IDS + ")";
    }





    // if (this.SERVICE_ADVISOR != undefined) {
    //   this.filterService += ' AND SERVICE_ADVISER_ID IN' + "(" + this.SERVICE_ADVISOR + ")"
    // }

    // if (this.BRANCH_IDS != undefined) {
    //   this.filterBranch += ' AND APPOINTMENT_BRANCH_ID IN' + "(" + this.BRANCH_IDS + ")"
    // }

    if (exportInExcel == false && this.ok == false) {
      this.loadingRecords = true;
      this.api.getappointmentdata(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + filterBranch + filterService, this.DATE1, this.CCE_ID)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            console.log(this.dataList);

            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }

    else if (exportInExcel == false && this.ok == true) {
      this.api.getappointmentdata(0, 0, this.sortKey, sort, this.filterQuery + filterBranch + filterService, this.DATE1, this.CCE_ID)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            console.log(this.dataList);

            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;
      this.api.getappointmentdata(0, 0, this.sortKey, sort, this.filterQuery + filterBranch + filterService, this.DATE1, this.CCE_ID)
        .subscribe(
          (data) => {
            console.log(data);
            this.exportLoading = false;

            this.ewsalesdata = data['data'];
            this.convertInExcel();


          },
          (err) => {
            console.log(err);
          }
        );

    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['Sales Consultant Name'] = this.ewsalesdata[i]['SERVICE_ADVISER_NAME'];
      obj1['BT Number'] = this.ewsalesdata[i]['BT_NUMBER'];
      obj1['Booking Date'] = this.ewsalesdata[i]['BOOKING_DATE'];
      obj1[' Customer Name'] = this.ewsalesdata[i]['CUSTOMER_NAME'];
      obj1[' Branch Name'] = this.ewsalesdata[i]['APPOINTMENT_BRANCH_NAME'];
      obj1[' Mobile No.'] = this.ewsalesdata[i]['MOBILE_NUMBER'];
      obj1[' Registration No.'] = this.ewsalesdata[i]['REGISTRATION_NO'];
      obj1[' Work Type'] = this.ewsalesdata[i]['WORK_TYPE'];
      obj1[' Express Care'] = this.ewsalesdata[i]['EXPRESS_CARE'];
      obj1[' Remark'] = this.ewsalesdata[i]['REMARK'];
      obj1[' Special Message'] = this.ewsalesdata[i]['SPECIAL_MESSAGE'];



      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'Appointment Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }


  sales2
  getAllBranch() {
    this.api.getAllBranch(0, 0, this.sortKey, this.sortValue, ' ').subscribe(data => {
      this.sales2 = data['data'];
      if (this.sales2.length > 0) {
        // this.BRANCH_IDS = Number([data['data'][0]['ID']])
        console.log(this.sales2);
        this.search(true)
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  sales1
  getSalesadvisor() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=48').subscribe(data => {
      this.sales1 = data['data'];
      if (this.sales1.length > 0) {
        // this.SERVICE_ADVISOR = Number([data['data'][0]['EMPLOYEE_ID']])
        console.log(this.sales);
        this.getAllBranch()

      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

}
