import { Component, Input, OnInit } from '@angular/core';
// import { ApiService } from '../Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service';
// import { EmployeeMaster } from '../Models/employeemaster';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-report-person',
  templateUrl: './report-person.component.html',
  styleUrls: ['./report-person.component.css']
})
export class ReportPersonComponent implements OnInit {
  allEmployeeList = []
  hPromiseEmployeeList = []
  allHPromiseEmployeeList = [];
  allCorporateManagerEmployeeList = []
  corporateManagerEmployeeList = []
  allDirectorEmployeeList = []
  directorEmployeeList = []
  accountantEmployeeList = []
  allAccountantEmployeeList = []
  employeeList
  orgId = this.cookie.get('orgId');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  @Input() data: EmployeeMaster = new EmployeeMaster();


  constructor(private api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() {
    this.getEmployeeList();
    this.getHPromiseEmployeeList();
    this.getCorporateManagerEmployeeList();
    this.getDirectorEmployeeList();
    this.getAccountantEmployeeList();
  }
  getEmployeeList() {
    this.allEmployeeList = [];
    this.api.getAllemployeeMaster(0, 0, "NAME", "asc", ' AND ORG_ID=' + this.orgId + ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ')').subscribe(data => {
      if (data['code'] == 200) {
        this.allEmployeeList = data['data'];
      }
    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }


  getHPromiseEmployeeList() {
    this.allHPromiseEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=31)').subscribe(data => {
      if (data['code'] == 200) {
        this.allHPromiseEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  filterHPromiseEmployeeList(userID: number) {
    this.hPromiseEmployeeList = [];

    var tempEmpList = this.allHPromiseEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.hPromiseEmployeeList = tempEmpList;
  }


  getCorporateManagerEmployeeList() {
    this.allCorporateManagerEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=32)').subscribe(data => {
      if (data['code'] == 200) {
        this.allCorporateManagerEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }


  filterCorporateManagerEmployeeList(userID: number) {
    this.corporateManagerEmployeeList = [];

    var tempEmpList = this.allCorporateManagerEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.corporateManagerEmployeeList = tempEmpList;
  }


  getDirectorEmployeeList() {
    this.allDirectorEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=33)').subscribe(data => {
      if (data['code'] == 200) {
        this.allDirectorEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }


  filterDirectorEmployeeList(userID: number) {
    this.directorEmployeeList = [];

    var tempEmpList = this.allDirectorEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.directorEmployeeList = tempEmpList;
  }

  filterEmployeeList(userID: number) {
    console.log(userID);
    
    this.employeeList = [];

    var tempEmpList = this.allEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.employeeList = tempEmpList;
  }

  getAccountantEmployeeList() {
    this.allAccountantEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=13)').subscribe(data => {
      if (data['code'] == 200) {
        this.allAccountantEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  filterAccountantEmployeeList(userID: number) {
    this.accountantEmployeeList = [];

    var tempEmpList = this.allAccountantEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.accountantEmployeeList = tempEmpList;
  }

  sumbitSalesForm() {
    this.data.EMAIL_ID = ''
    this.data.MOBILE_NUMBER = 0
    this.data.DESIGNATION_ID = 0
    this.data.BRANCH_ID = 0
    this.data.DEPARTMENT_ID = 0
    this.data.REPORTING_HEAD_ID = 0
    this.data.TEMPORARY_HEAD_ID = 0
    this.data.PASSWORD = ''
    this.api.updateemployeeMaster(this.data).subscribe(data => {
      if (data['code'] == '200') {
        this.message.success('Sales map successfull ', '')
      }
    })
  }

}
