import { Component, OnInit, ViewChild } from '@angular/core';
import { tourExpenseMaster } from 'src/app/Models/tourExpenseMaster';
import { TourExpenseFormComponent } from '../tour-expense-form/tour-expense-form.component';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tour-expense-head',
  templateUrl: './tour-expense-head.component.html',
  styleUrls: ['./tour-expense-head.component.css']
})
export class TourExpenseHeadComponent implements OnInit {


  formTitle = "Manage Tour Expense";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  tourRequestData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["APPLICATION_DATE", "Application Date"],["EMPLOYEE_NAME", "Employee Name"], ["EMPLOYEE_REPORTING_HEAD_NAME", "Head Name "], ["AMOUNT", "Amount"], ["DESCRIPTION", "Description"], ["HEAD_REMARK", "Head Remark"], ["GM_REMARK", "GM Remark"], ["HR_REMARK", "HR Remark"], ["ACCOUNTANT_REMARK", "Accountant Remark"]];

  approveTableColumns: string[][] = [["APPLICATION_DATE", "Application Date"],["EMPLOYEE_NAME", "Employee Name"], ["DESIGNATION_NAME", "Designation Name "], ["MODE_NAME", "Mode Name"], ["CLASS_NAME", "Class Name"], ["FROM_DATE", "From Date"], ["TO_DATE", "To date"], ["FROM_TIME", "From Time"], ["TO_TIME", "To Time "], ["ADVANCE_AMOUNT", "Advanced Amount"], ["REASON", "Reason"], ["HEAD_REMARK", "Head Remark"], ["HR_REMARK", "HR Remark"], ["GM_REMARK", "GM Remark"], ["ACCOUNTANT_REMARK", "Accountant Remark"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: tourExpenseMaster = new tourExpenseMaster();
  drawerVisible1: boolean;
  dateFormat = "dd/MMM/yyyy";
  constructor(private api: ApiService, private sanitizer: DomSanitizer,private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  STATUS = "P";
  userId = Number(this.cookie.get("userId"));
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var dateFilter = "";
    if (this.date != undefined && this.date.length != 0) {
      dateFilter = " AND (APPLICATION_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "')";
    }

    // var empFilter = ";

    var status = "";
    if (this.STATUS == "P")
      status = " AND STATUS in ('P') AND APPROVER_ID = " + this.userId;

    else if (this.STATUS == "AP")
    status = " AND STATUS = 'AP' AND REPORTING_PERSON_ID = " + this.userId;

    else if (this.STATUS == "R")
    status = " AND STATUS ='R' AND FORWARD_APPROVER_ID = " + this.userId;

    else if (this.STATUS == "FW")
      status = " AND STATUS = 'FW' AND HEAD_STATUS = 'AP' AND FORWARD_APPROVER_ID = " + this.userId;

    this.api.getTourExpense(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + dateFilter + status).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.tourRequestData = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  @ViewChild(TourExpenseFormComponent, { static: false }) TourComponentVar: TourExpenseFormComponent;


  HEAD_STATUS: boolean = false
  edit(data: tourExpenseMaster): void {
    this.drawerTitle = "Update Tour Expense";
    this.drawerData = Object.assign({}, data);

    this.drawerVisible = true;
    this.drawerData.APPROVED_AMOUNT = 0

    if (this.drawerData.HEAD_STATUS == 'AP') {
      this.HEAD_STATUS = true

    }
    else {
      this.HEAD_STATUS = false
    }

  }

  isVisible: boolean = false;

  certificate:any 
  pdfDisplay(data: tourExpenseMaster) {

    this.isVisible = true;
    this.isSpinning = true
    this.api.getTourExpense(0, 0, '', '', ' AND ID ='+data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.isSpinning = false
        console.log(data['data']);
        this.certificate = data['data'][0]['ATTACHMENTS']
        console.log(this.certificate, 'cer')
      }
    },
      (err) => {
        console.log(err);
      }
    );

  }


  imgUrl=this.api.retriveimgUrl
  sanitizedLink:any
  getS(link:string){
   var a= this.imgUrl+'tourExpense/'+link+'#toolbar=0'
   this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);
 
   return  this.sanitizedLink ;

 }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }



  changeRadioButton(earlymarkStatus) {
    this.STATUS = earlymarkStatus;
    this.search(true);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
  filterClass: string = "filter-invisible";
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  date: Date[] = [];
  filterStatusValue: string[] = ['P'];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  employeeFilter

  clearFilter() {
    this.date = [];
    this.filterStatusValue = ['P'];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.employeeFilter = 1;
    this.filterQuery = "";
    this.search(true);
  }
  // date
  isSpinning
  filterQuery: string = "";
  isFilterApplied: string = "default";
  applyFilter() {
    // var sort: string;
    // try {
    //   sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    // } catch (error) {
    //   sort = "";
    // }

    // if (this.date == undefined || this.date.length == 0) {
    //   this.message.error("Please Select Date", "");

    // } else {
    //   this.filterQuery = " AND (FROM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "') AND ";
    //   this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);

    //   var empFilter = " and REPORTING_HEAD_ID = " + this.userId

    //   var status = "";
    //   if (this.STATUS == "P")
    //     status = " AND STATUS = 'FW' AND HR_STATUS = 'AP' AND HEAD_REMARK = '' ";

    //   else if (this.STATUS == "AP")
    //     status = " AND STATUS = 'AP'";

    //   else if (this.STATUS == "R")
    //     status = " AND STATUS ='R'";

    //   else if (this.STATUS == "FW")
    //     status = " AND STATUS = 'FW' AND HEAD_STATUS = 'AP'  AND HEAD_REMARK ! = ''";



    //     this.loadingRecords = true;
    //   this.api.getAllEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilter + status).subscribe(data => {
    //     if (data['code'] == 200) {
    //       this.isSpinning = false;
    //       this.loadingRecords = false;
    //       this.totalRecords = data['count'];
    //       this.tourRequestData = data['data'];
    //       this.isFilterApplied = "primary";
    //     }

    //   }, err => {
    //     console.log(err);
    //   });
    // }

    this.search(true)
    this.filterClass = "filter-invisible";
  }

}
