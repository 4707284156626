import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-customerwiseinvoicedetailedreport',
  templateUrl: './customerwiseinvoicedetailedreport.component.html',
  styleUrls: ['./customerwiseinvoicedetailedreport.component.css']
})
export class CustomerwiseinvoicedetailedreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Customer Wise Invoice Detailed Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODELS: any;
  MODELG: any
  BRANCH: any;
  CUSTOMER_NAME: any;
  model: any = [];
  branch: any = [];
  customers: any = [];
  SaleExicutive: any = [];
  quotationdate: any;
  quotationdate1: any;
  SALES_EXECUTIVE_NAME: any
  CUSTOMER_ID:any;
  SALES_EXECUTIVE: any
  y = new Date().getFullYear();
  m = new Date().getMonth();
  startValue: any = new Date(this.y,this.m,1);
  endValue: any = new Date();
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  isOk: boolean = true
  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model name"], ["BRANCH_NAME", "Branch Name"]];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }

  ngOnInit() {
    // this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    // this.endValue = new Date();
    this.getbranches()
    this.getmodels()
    this.getcustomers()
    this.getsalehead()
    setTimeout(()=>{
      this.search(false);
    },2000);

    // this.BRANCH="AL";
    // this.quotationdate=new Date();
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")
    // this.applyFilter();
  }
  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      if (data['code'] == 200) {
        this.SaleExicutive = data['data'];
        console.log(this.SaleExicutive);
        this.onSelectAllSalesExecutive(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
    
  }
  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
        console.log(this.branch);
        this.onSelectAllBranch(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
   
  }
  MODEL_ID: any
  modelname: any
  selexicutivename: any
  branchname: any
  getmodels() {
    this.api.getAllModels(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {

        this.model = data['data'];
      //   let ids = [];

      // if (this.SELECT_MODEL == true) {
      //   for (var i = 0; i < this.model.length; i++) {
      //     ids.push(this.model[i]["ID"]);
      //   }
      // } else {
      //   ids = [];
      // }

      // this.MODELS = ids;
        console.log(this.model);
        this.onSelectAllModel(true)
        // this.search(true)
        }
      }, err => {
        console.log(err);
      });
      

  }
  
  getcustomers() {
    this.api.getAllCustomer(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.customers = data['data'];
        console.log(this.customers);
        this.onSelectAllCustomer(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };
  
  START_DATE:any
  END_DATE:any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.customerwiseinovice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH, this.SALES_EXECUTIVE, this.CUSTOMER_ID, this.MODELS, this.START_DATE,this.END_DATE,).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.customerwiseinovice(0, 0, this.sortKey, sort, likeQuery, this.BRANCH, this.SALES_EXECUTIVE, this.CUSTOMER_ID, this.MODELS,this.START_DATE,this.END_DATE,).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }


  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Sales Executive Name'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Variant'] = this.dataList1[i]['VARIENT_NAME'];

      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Converted To Invoice'] = this.dataList1[i]['CONVERTED_INVOICE'];
      obj1['Converted To Invoice Date'] = this.dataList1[i]['INVOICE_DATE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer Wise Invoice Deatailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  } 

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.startValue = new Date(this.y,this.m,1);
    this.endValue = new Date();
    // this.SELECT_CUSTOMER = false;

    let ids = [];
    this.SELECT_BRANCH = true
    for (var i = 0; i < this.branch.length; i++) {
      ids.push(this.branch[i]["ID"]);
    }
    this.BRANCH = ids;

    this.SELECT_MODEL = true
    let ids1 = [];

    for (var i = 0; i < this.model.length; i++) {
      ids1.push(this.model[i]["ID"]);
    }
    this.MODELS = ids1;


    this.SELECT_SALES_EXICUTIVE = true
    let ids2 = [];

    for (var i = 0; i < this.SaleExicutive.length; i++) {
      ids2.push(this.SaleExicutive[i]["EMPLOYEE_ID"]);
    }
    this.SALES_EXECUTIVE = ids2;

    this.SELECT_CUSTOMER = true
    let ids3 = [];

    for (var i = 0; i < this.customers.length; i++) {
      ids3.push(this.customers[i]["NAME"]);
    }
    this.CUSTOMER_NAME = ids3;

    this.filterQuery = '';
    
    this.search(true)
    //  this.isFilterApplied="default";

  }
  
  SELECT_SALES_EXICUTIVE: boolean = false;
  SELECT_MODEL: boolean = false;
  SELECT_BRANCH: boolean = false;
  SELECT_CUSTOMER: boolean = false;

  onSelectAllSalesExecutive(event: any) {
    this.SELECT_SALES_EXICUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXICUTIVE == true) {
      for (var i = 0; i < this.SaleExicutive.length; i++) {
        ids.push(this.SaleExicutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE = ids
  }

  onSelectOff1(event1: any) {
    var a = this.SaleExicutive.length;
    var b = this.SaleExicutive.length - event1.length;
    if ((a! = b)) {
      this.SELECT_SALES_EXICUTIVE = false;
    } else {
      this.SELECT_SALES_EXICUTIVE = true;
    }
    this.SALES_EXECUTIVE = event1;
    if (this.SALES_EXECUTIVE.length == 0) {
      this.SELECT_SALES_EXICUTIVE = false;
    }
  }

  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.model.length; i++) {
        ids.push(this.model[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODELS = ids
  }

  onSelectOff2(event1: any) {
    var a = this.model.length;
    var b = this.model.length - event1.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODELS = event1;
    if (this.MODELS.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  onSelectAllCustomer(event: any) {
    this.SELECT_CUSTOMER = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_CUSTOMER == true) {
      for (var i = 0; i < this.customers.length; i++) {
        ids.push(this.customers[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.CUSTOMER_ID = ids
  }

  onSelectOff4(event1: any) {
    var a = this.customers.length;
    var b = this.customers.length - event1.length;
    if ((a! = b)) {
      this.SELECT_CUSTOMER = false;
    } else {
      this.SELECT_CUSTOMER = true;
    }
    this.CUSTOMER_ID = event1;
    if (this.CUSTOMER_ID.length == 0) {
      this.CUSTOMER_ID = false;
    }
  }

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  onSelectOff3(event1: any) {
    var a = this.branch.length;
    var b = this.branch.length - event1.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event1;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.BRANCH != undefined && this.BRANCH.length > 0) {
      this.BRANCH = this.BRANCH
    }

    if (this.CUSTOMER_NAME != undefined && this.CUSTOMER_NAME.length > 0) {
      this.CUSTOMER_NAME = this.CUSTOMER_NAME
    }


    if (this.SALES_EXECUTIVE != undefined && this.SALES_EXECUTIVE.length > 0) {
      this.SALES_EXECUTIVE = this.SALES_EXECUTIVE
    }

    if (this.MODELS != undefined && this.MODELS.length > 0) {
      this.MODELS = this.MODELS
    }

    this.filterClass = "filter-invisible";
    this.search(true);

  }


  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

}

