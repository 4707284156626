import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzButtonType } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainerloginview',
  templateUrl: './trainerloginview.component.html',
  styleUrls: ['./trainerloginview.component.css']
})

export class TrainerloginviewComponent implements OnInit {
  formTitle = "Quality Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  filterValue = [];
  isFilterApplied: NzButtonType = "default";
  columns: string[][] = [["PROGRAM_TITLE", "Program Title"], ["SUBJECT_TITLE", "Subject Name"]];
  isSpinning = false;
  i = -1;
  REASON: string = '';
  isStatusSpinning = false;
  isloadSpinning = false;
  selectedDate: Date[] = [];
  value1: any;
  value2: any;
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  filterClass: string = "filter-invisible";
  current = new Date();
  inwardlist = [];
  suppliernm: string = 'AL';
  brokernm: string = 'AL';
  vehiclenm: string = 'AL';
  statusnm: string = 'AL';
  ulocationnm: string = 'AL';
  lotnonm: string = 'AL';
  datas: TrainingSchedule = new TrainingSchedule();
  exportSpinning = false;
  scheduleId = 0;
  empId = 0;
  TRAINER_ID = this.cookie.get('userId');
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainingSchedule = new TrainingSchedule();
  drawerData2: TrainingSchedule = new TrainingSchedule();
  data: TrainingSchedule = new TrainingSchedule();
  drawerVisible1!: boolean;
  drawerTitle1!: string;
  drawerData1 = [];

  constructor( private cookie: CookieService,private message: NzNotificationService, private api: ApiService) { }

  ngOnInit(): void {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    var filter = "";
    if (likeQuery)
      filter = this.filterQuery + likeQuery;

    else
      filter = this.filterQuery;

    var filter = " AND STATUS = ('AM' OR 'AS' OR 'AR')" + " " + " AND TRAINER_ID =" + this.TRAINER_ID;
    this.api.getAlltrainingSchedule(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
      if (data['code'] = 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  MarkAttendies(data: TrainingSchedule): void {
    this.scheduleId = data.ID;
    this.drawerTitle = "Mark Attendance Of Mapped Attendies";

    this.api.getAlltrainingAttendiedMapping(0, 0, '', '', ' AND SCHEDULE_ID = ' + this.scheduleId + ' AND ATTENDANCE_STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.drawerData2 = data['data'];
        this.drawerVisible = true;
      }

    }, err => {
      console.log(err);
    });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  completeattendance(data1: any): void {
    this.scheduleId = data1.ID;
    var filter = " AND STATUS = 'AS' " + " " + " AND TRAINER_ID =" + this.TRAINER_ID;
    var filter = " AND ATTENDANCE_STATUS = 1 " + " " + " AND SCHEDULE_ID =" + this.scheduleId;

    this.api.getAlltrainingAttendiedMapping(0, 0, '', '', filter).subscribe(data => {
      if (data['code'] == '200') {
        this.drawerData1 = data['data'];
        this.drawerVisible1 = true;

      } else {
        this.message.error('Please add training points first.', '');
      }

    }, err => {
      this.message.error('Something Went wrong.', '');
    });

    this.drawerTitle1 = "Complete Training Attendance";
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  ATTENDANCE_STATUS: any
  EVALUATION_STATUS
  TRAINER_REMARK
  ID
  x = []
  y = []
  z = []
  t = []
  arraytrain: any
  pusharray = []

  save1() {
    for (var i = 0; i < this.drawerData1.length; i++) {
      this.arraytrain = [
        {
          'ATTENDANCE_STATUS': this.drawerData1[i]['ATTENDANCE_STATUS'],
          'TRAINER_REMARK': this.drawerData1[i]['TRAINER_REMARK'],
          'IS_SATISFIED': this.drawerData1[i]['IS_SATISFIED'],
          'ID': this.drawerData1[i]['ID']
        }]

      this.pusharray.push(this.arraytrain[0]);
    }

    this.REASON = 'AR'
    this.isSpinning = true;
    this.api.updatetrainingAttendiedMapping(this.pusharray, this.REASON, this.scheduleId).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Training Attendance Created Successfully", "");
        this.isSpinning = false;
        this.close1();

      } else {
        this.message.error("Training Attendance Creation Failed", "");
        this.isSpinning = false;
      }

    }, err => {
      this.isSpinning = false;
    });
  }

  close1(): void {
    this.drawerClose1();
    this.search(false);
  }

  sort1(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search1(false);
  }

  search1(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log("likeQuery" + likeQuery);
    }

    likeQuery = likeQuery + ' AND ATTENDANCE_STATUS = 1' + ' AND SCHEDULE_ID=' + this.scheduleId;
    this.api.getAlltrainingAttendiedMapping(0, 0, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.drawerData1 = data['data'];

    }, err => {
      console.log(err);
    });
  }
}
