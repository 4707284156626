import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { Branchmaster } from 'src/app/Models/Branchmaster';
import { Department } from 'src/app/Models/department';
import { Designationmaster } from 'src/app/Models/designationmaster';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-activityleadform',
  templateUrl: './activityleadform.component.html',
  styleUrls: ['./activityleadform.component.css']
})
export class ActivityleadformComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() 
  data : ActivityLeadMaster = new ActivityLeadMaster();
  @Input() ide : number
  listdata1 = [];
  listdata2 = [];
  isSpinning = false;
  dateFormat = 'dd/MM/yyyy HH:mm:ss';
  OPEN_TIME;
  CLOSE_TIME;
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
 
  isOk = true;

  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  passwordVisible = false;
  branch: Branchmaster[] = [];
  department: Department[] = [];
  designation: Designationmaster[] = [];
  orgId = this.cookie.get('orgId');
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))
  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }
  branchId =  this.cookie.get('branchId')
  ngOnInit() {
    
    this.dept();
    this.des()
    this.getallorg1();
    this.getEmployeeList();
    this.getHPromiseEmployeeList();
    this.getCorporateManagerEmployeeList();
    this.getDirectorEmployeeList();
    this.getAccountantEmployeeList();
    this.data.ACTIVITY_TYPE_ID = 2
  }

  ActivityTypes=[]
  Allactivity=[]
  allactions=[]
  ACTIVITY_NAME
  dept() {
    this.api.getAllActivityTypeMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
      this.ActivityTypes = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  desig(event) {
    this.data.ACTIVITY_ID=null
    this.api.getAllActivityMaster(0, 0, '', '', ' AND STATUS=1 '+ ' AND ACTIVITY_TYPE_ID = '+ event ).subscribe(data => {
      this.Allactivity = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }
  des() {
    
    this.api.getAllActivityMaster(0, 0, '', '', ' AND STATUS=1 ').subscribe(data => {
      this.Allactivity = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  getallorg1() {

    this.api.getAllActionMaster(0, 0, 'ID', 'desc', ' ' ).subscribe(data => {
      if (data['code'] == 200) {
        this.allactions = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  clsAlphaNoOnly(event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);

    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }



  getallorg2(userID) {
    this.listdata2 = [];

    var tempEmpList = this.listdata1.filter(obj1 => {
      return obj1["ID"] != userID;
    });

    this.listdata2 = tempEmpList;
  }

  resetDrawer2() {
    // this.userFrm.form.reset();
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add1();
  }

  add1() {
    // this.api.getAllemployeeMaster(1, 1, 'SEQUENCE_NO', 'desc', ' AND ORG_ID=' + this.orgId).subscribe(data => {
    //   if (data['count'] == 0) {
    //     this.data.SEQUENCE_NO = 1;

    //   } else {
    //     this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
    //   }

    //   this.data.STATUS = true;

    // }, err => {
    //   console.log(err);
    // })
  }
  ddd:any
  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    this.data.EXECUTIVE_ID = Number(this.cookie.get('userId'))
    this.data.CUSTOMER_ID = this.ide
    console.log( this.ide);
    console.log(this.data.EXECUTIVE_ID);

    
    

    if (this.data.ACTIVITY_ID == undefined || this.data.ACTIVITY_ID == null) {
      this.isOk = false;
      this.message.error('Please Select Activity', '');
    } else

    if (this.data.ACTIVITY_TYPE_ID == null || this.data.ACTIVITY_TYPE_ID == undefined) {
      this.isOk = false;
      this.message.error('Please Select Activity Type', '');
    } else

    if (this.data.ACTION_ID == null || this.data.ACTION_ID == undefined) {
      this.isOk = false;
      this.message.error('Please Select Action', '');
    } else

    if(this.data.ACTIVITY_TYPE_ID==4){
      if (this.data.CALL_STATUS == null || this.data.CALL_STATUS == undefined) {
        this.isOk = false;
        this.message.error('Please Select Call Status', '');
      } 
    }
   else

    if (this.data.CALLING_DATE_TIME == null || this.data.CALLING_DATE_TIME == undefined) {
      this.isOk = false;
      this.message.error('Please Select Date & Time', '');
    }


    this.ddd = this.datePipe.transform(this.data.CALLING_DATE_TIME,"yyyy-MM-dd HH:mm:ss")
    if(this.data.ACTIVITY_TYPE_ID==2){
      this.data.CALL_STATUS=this.data.CALL_STATUS
    }

    else{
      this.data.CALL_STATUS=""
    }
    this.data.CALLING_DATE_TIME = this.ddd
   if (this.isOk) {
    
      if (this.data.ID) {
    
          this.api.updateActivityleadmaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Activity lead Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.resetDrawer(accountMasterPage);
              this.isSpinning = false;

            } else {
              this.message.error("Information Updation Failed", "");
              this.isSpinning = false;
            }
          });

       
      } else {
    
          this.api.creteActivityleadmaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Information Saved Successfully", "");

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);

              } else {
                this.data = new ActivityLeadMaster();
                this.resetDrawer(accountMasterPage);
             
              }

            } else {
              this.message.error("Information Saved Failed", "");
              this.isSpinning = false;
            }
          });

      }
    }
  }

  fileURL1: any = null;

  clear1() {
    this.fileURL1 = null;
    // this.data.SIGNATURE = null;
  }

  viewImage(imageName) {
    window.open(imageName);
  }

  onFileSelected1(event: any) {
    if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.fileURL1 = <File>event.target.files[0];

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          // this.data.SIGNATURE = reader.result as string;
        };
      }

    } else {
      this.message.error('Please Choose Only JPEG/ JPG/ PNG File', '');
      this.fileURL1 = null;
    }
  }

  folderName = "signature";
  photo1Str: string;

  imageUpload1() {
    // this.photo1Str = "";

    // if (!this.data.ID) {
    //   if (this.fileURL1) {
    //     var number = Math.floor(100000 + Math.random() * 900000);
    //     var fileExt = this.fileURL1.name.split('.').pop();
    //     var url = "S" + number + "." + fileExt;

    //     this.api.onUpload2(this.folderName, this.fileURL1, url).subscribe(res => {
    //       if (res["code"] == 200) {
    //         console.log("Uploaded");

    //       } else {
    //         console.log("Not Uploaded");
    //       }
    //     });

    //     this.photo1Str = url;

    //   } else {
    //     this.photo1Str = "";
    //   }

    // } else {
    //   if (this.fileURL1) {
    //     var number = Math.floor(100000 + Math.random() * 900000);
    //     var fileExt = this.fileURL1.name.split('.').pop();
    //     var url = "S" + number + "." + fileExt;

    //     this.api.onUpload2(this.folderName, this.fileURL1, url).subscribe(res => {
    //       if (res["code"] == 200) {
    //         console.log("Uploaded");

    //       } else {
    //         console.log("Not Uploaded");
    //       }
    //     });

    //     this.photo1Str = url;

    //   } else {
    //     if (this.data.SIGNATURE) {
    //       let photoURL = this.data.SIGNATURE.split("/");
    //       this.photo1Str = photoURL[photoURL.length - 1];

    //     } else
    //       this.photo1Str = "";
    //   }
    // }
  }

  cancel() { }

  allEmployeeList = [];
  allHPromiseEmployeeList = [];
  allCorporateManagerEmployeeList = [];
  allDirectorEmployeeList = [];
  allAccountantEmployeeList = [];

  employeeList = [];
  hPromiseEmployeeList = [];
  corporateManagerEmployeeList = [];
  directorEmployeeList = [];
  accountantEmployeeList = [];

  getEmployeeList() {
    this.allEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, "NAME", "asc", ' AND ORG_ID=' + this.orgId + ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ')').subscribe(data => {
      if (data['code'] == 200) {
        this.allEmployeeList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getHPromiseEmployeeList() {
    this.allHPromiseEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=31)').subscribe(data => {
      if (data['code'] == 200) {
        this.allHPromiseEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getCorporateManagerEmployeeList() {
    this.allCorporateManagerEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=32)').subscribe(data => {
      if (data['code'] == 200) {
        this.allCorporateManagerEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDirectorEmployeeList() {
    this.allDirectorEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=33)').subscribe(data => {
      if (data['code'] == 200) {
        this.allDirectorEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getAccountantEmployeeList() {
    this.allAccountantEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=13)').subscribe(data => {
      if (data['code'] == 200) {
        this.allAccountantEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  filterEmployeeList(userID: number) {
    console.log(userID);
    
    this.employeeList = [];

    var tempEmpList = this.allEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.employeeList = tempEmpList;
  }

  filterHPromiseEmployeeList(userID: number) {
    this.hPromiseEmployeeList = [];

    var tempEmpList = this.allHPromiseEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.hPromiseEmployeeList = tempEmpList;
  }

  filterCorporateManagerEmployeeList(userID: number) {
    this.corporateManagerEmployeeList = [];

    var tempEmpList = this.allCorporateManagerEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.corporateManagerEmployeeList = tempEmpList;
  }

  filterDirectorEmployeeList(userID: number) {
    this.directorEmployeeList = [];

    var tempEmpList = this.allDirectorEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.directorEmployeeList = tempEmpList;
  }

  filterAccountantEmployeeList(userID: number) {
    this.accountantEmployeeList = [];

    var tempEmpList = this.allAccountantEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.accountantEmployeeList = tempEmpList;
  }
}
