import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { Latemark } from 'src/app/Models/Latemark';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-addlatemark',
  templateUrl: './addlatemark.component.html',
  styleUrls: ['./addlatemark.component.css']
})

export class AddlatemarkComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input()
  data: Latemark = new Latemark();
  orgId = this.cookie.get('orgId');
  isSpinning = false;
  @Input() listOfData: Latemark[] = [];
  listdata2 = [];
  listdata1 = [];
  acctype = [];
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  @Input() DAYS = false;
  isOk = true;

  constructor(private api: ApiService,private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm) {
    this.drawerClose();
    this.resetDrawer(myForm);
  }

  resetDrawer(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Date", "");
    }

    if (this.data.EXPECTED_TIME != undefined) {
      let givenTime = new Date(this.datePipe.transform(this.data.EXPECTED_TIME, "yyyy-MM-dd HH:mm") + ":00");
      let lateTime = new Date(this.datePipe.transform(this.data.EXPECTED_TIME, "yyyy-MM-dd 12:30") + ":00");

      if ((givenTime.getTime()) > (lateTime.getTime())) {
        isOk = false;
        this.message.error("Please Select Valid Time upto 12:30 PM", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Select Valid Time", "");
    }

    if (this.data.REASON == undefined || this.data.REASON.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Reason", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");
      this.data.EXPECTED_TIME = this.datePipe.transform(this.data.EXPECTED_TIME, "HH:mm") + ":00";
      this.data.EMPLOYEE_ID = this.api.userId;

      if (this.data.ID) {
        this.api.updatelatemark(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Latemark Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Latemark Updation Failed", "");
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
        });

      } else {
        this.api.createlatemark(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Latemark Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else
              this.data = new Latemark();

          } 
           else if (successCode['code'] == 300) {
            this.message.error('Leave Cannot be applied more than 2 times in a single month','')
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
           else{
            this.message.error("Earlymark Creation Failed", "");
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
         
        });
      }
    }
  }

  today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledHours(): number[] {
    return [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  }

  disabledMinutes(hour: number): number[] {
    if (hour === 12) {
      return [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

    } else {
      return [];
    }
  }
}


