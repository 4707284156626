export class actionMaster {
    CLIENT_ID:any
    ID:any;
    ACTION_NAME:any;
    STATUS:boolean;
    SEQUENCE_NO:any;
    FIELD_1:any;
    FIELD_2:any;
    FIELD_3:any;
    FIELD_4:any;
    FIELD_5:any;
}