import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-employee-leave',
  templateUrl: './add-employee-leave.component.html',
  styleUrls: ['./add-employee-leave.component.css']
})

export class AddEmployeeLeaveComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Leave;
  @Input() visibility
  @Input() drawerVisible: boolean;
  @Input() status
  @Input() balance
  @Input() daydiff
  @Input() isDisabled2
  @Input() length
  @Input() PAYROLL_LEAVE_TYPE_ID:any
  isSpinning = false;
  fileURL
  leaveTypes = [];
  imgUrl = this.api.imgUrl
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getLeaveTypes();
    this.getLeaveTypeMaster();
    this.getdata()
  }

  getLeaveTypes() {
    this.leaveTypes = [];
    var filter = "AND EMPLOYEE_ID=" + this.api.userId
    this.api.getAllEmployeeLeave1(0, 0, '', 'asc', '', this.api.userId).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  onFileSelected(event: any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png" || event.target.files[0].type == "application/pdf") {
      this.fileURL = <File>event.target.files[0];
      if (this.fileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.MEDICAL_CERTIFICATE != undefined &&
          this.data.MEDICAL_CERTIFICATE.trim() != ''
        ) {
          var arr = this.data.MEDICAL_CERTIFICATE.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }



      }
    } else {
      this.message.error("Please Select image of type JPEG/ JPG/ PNG/ PDF.", "");
      this.fileURL = null;
      this.data.MEDICAL_CERTIFICATE = '';

    }
    this.isVisible1=false
  }
  removeImage() {
    this.data.MEDICAL_CERTIFICATE = '';
    this.fileURL = '';
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.TYPE_ID == undefined || this.data.TYPE_ID == 0) {
      isOk = false;
      this.message.error("Please Select Valid Leave Type", "");
    }

    else if (this.data.FROM_DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid From Date", "");
    }

    else if (this.data.TO_DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid To Date", "");
    }


    else if (this.data.MODE == null || this.data.MODE == 0) {
      isOk = false;
      this.message.error("Please Select Mode", "");
    }
    else if (this.data.REASON == undefined || this.data.REASON.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Reason", "");
    }

    // else if (this.data.MODE == '2') {
    //   isOk = false;
    //   this.message.error("Please Select Session", "");
    // }

    else if (this.balance == 0) {
      isOk = false;
      this.message.error("You don't have enough balance to take this leave, please select Loss of pay TYPE LEAVE and apply again.", "");
    }
    if (isOk) {
      this.isSpinning = true;

      this.data.FROM_DATE = this.datePipe.transform(this.data.FROM_DATE, "yyyy-MM-dd");
      if (this.data.MODE == '2') {
        this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, "yyyy-MM-dd");
        this.data.SESSION = this.data.SESSION
      }
      else {
        this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, "yyyy-MM-dd");
        this.data.SESSION = '0'
      }

      this.data.EMPLOYEE_ID = this.api.userId;

      this.data.MODE = this.data.MODE.toString()



      if (this.data.ID) {
        this.api.updateLeave(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Leave Updated Successfully", "");

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Leave Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        console.log(this.data)
        this.api.createLeave(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Leave Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new Leave();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Leave Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }


  update(addNew: boolean) {

  }

  today = new Date().setDate(new Date().getDate() + 4);
  today12 = new Date().setDate(new Date().getDate() - 0 * 24 * 60 * 60 * 1000);


  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledStartDate1 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;
  @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

sabledmode = false
  // isSpinning = false;
  monthFormat = 'MM/yyyy';
  // leaveTypes = [];
  month = new Date();
  monthchange(event: any) {
    this.MONTH=event
    this.MONTH = this.datePipe.transform(this.month, "MM")
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.month, "yyyy");
    this.MONTH1 = this.datePipe.transform(this.month, "MMMM,y");
    // try {
    //   sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    // } catch (error) {
    //   sort = "";
    // }
    
      this.api.getAllEmployeeLeave2(this.api.userId,this.MONTH,this.YEAR).subscribe((data:any)=>{
        if (data['code'] == 200) {
          this.masterdata=data['data'][0]['LEAVE_DATA'];
    this.mastersdata=data['data'][0]['PIE_DATA'];
    this.PAYROLL_LEAVE_TYPE_ID=data['data'][0]['LEAVE_DATA'][0]['PAYROLL_LEAVE_ID']

    this.USED=data['data'][0]['LEAVE_DATA'][0]['USED']
    this.PAYROLL_LEAVE_NAME=data['data'][0]['LEAVE_DATA'][0]['PAYROLL_LEAVE_NAME']
    this.LAPSE_COUNT=data['data'][0]['LEAVE_DATA'][0]['LAPSE_COUNT']
    this.REMAIN=data['data'][0]['LEAVE_DATA'][0]['REMAIN']
    this.TOTAL=data['data'][0]['LEAVE_DATA'][0]['TOTAL']
    this.ENCASHED_COUNT=data['data'][0]['LEAVE_DATA'][0]['ENCASHED_COUNT']

    this.USED1=data['data'][0]['LEAVE_DATA'][1]['USED']
    this.PAYROLL_LEAVE_NAME1=data['data'][0]['LEAVE_DATA'][1]['PAYROLL_LEAVE_NAME']
    this.LAPSE_COUNT1=data['data'][0]['LEAVE_DATA'][1]['LAPSE_COUNT']
    this.REMAIN1=data['data'][0]['LEAVE_DATA'][1]['REMAIN']
    this.TOTAL1=data['data'][0]['LEAVE_DATA'][1]['TOTAL']
    this.ENCASHED_COUNT1=data['data'][0]['LEAVE_DATA'][1]['ENCASHED_COUNT']

    this.USED2=data['data'][0]['LEAVE_DATA'][2]['USED']
    this.PAYROLL_LEAVE_NAME2=data['data'][0]['LEAVE_DATA'][2]['PAYROLL_LEAVE_NAME']
    this.LAPSE_COUNT2=data['data'][0]['LEAVE_DATA'][2]['LAPSE_COUNT']
    this.REMAIN2=data['data'][0]['LEAVE_DATA'][2]['REMAIN']
    this.TOTAL2=data['data'][0]['LEAVE_DATA'][2]['TOTAL']
    this.ENCASHED_COUNT2=data['data'][0]['LEAVE_DATA'][2]['ENCASHED_COUNT']

    this.USED3=data['data'][0]['LEAVE_DATA'][3]['USED']
    this.PAYROLL_LEAVE_NAME3=data['data'][0]['LEAVE_DATA'][3]['PAYROLL_LEAVE_NAME']
    this.LAPSE_COUNT3=data['data'][0]['LEAVE_DATA'][3]['LAPSE_COUNT']
    this.REMAIN3=data['data'][0]['LEAVE_DATA'][3]['REMAIN']
    this.TOTAL3=data['data'][0]['LEAVE_DATA'][3]['TOTAL']
    this.ENCASHED_COUNT3=data['data'][0]['LEAVE_DATA'][3]['ENCASHED_COUNT']

    this.USED4=data['data'][0]['LEAVE_DATA'][4]['USED']
    this.PAYROLL_LEAVE_NAME4=data['data'][0]['LEAVE_DATA'][4]['PAYROLL_LEAVE_NAME']
    this.LAPSE_COUNT4=data['data'][0]['LEAVE_DATA'][4]['LAPSE_COUNT']
    this.REMAIN4=data['data'][0]['LEAVE_DATA'][4]['REMAIN']
    this.TOTAL4=data['data'][0]['LEAVE_DATA'][4]['TOTAL']
    this.ENCASHED_COUNT4=data['data'][0]['LEAVE_DATA'][4]['ENCASHED_COUNT']
   
        }
        else {
          // this.loadingRecords = false;
          this.isSpinning = false;
        }
      }, err => {
        console.log(err);
        // this.loadingRecords = false;
        this.isSpinning = false;
      });
      // this.onGetLeaves()
  }
  // disabledEndDate = (current: Date): boolean => {
  //   if (this.data.FROM_DATE != null) {
  //     if (!current) {
  //       return false;
  //     }
  //     differenceInCalendarDays(

  //       current,

  //       new Date(this.data.FROM_DATE)

  //     ) < 0;
  //     // var modulePreviousDate = new Date(this.data.FROM_DATE);
  //     // modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

  //     // var modulePreviousDate = new Date(this.data.FROM_DATE)

  //     // return endValue < new Date(modulePreviousDate);

  //   };
  // }
  disabledEndDate = (current: Date): boolean =>

    differenceInCalendarDays(

      current,

      new Date(this.data.FROM_DATE)

    ) < 0;
  //  this.disabledDate2 = (current: Date): boolean =>
  show:boolean=false
  show2:boolean=false
  show3:boolean=false
  MONTH1:any
  PAYROLL_LEAVE_NAME:any
  LAPSE_COUNT:any
  REMAIN:any
  TOTAL:any
  USED:any
  ENCASHED_COUNT:any

  PAYROLL_LEAVE_NAME1:any
  LAPSE_COUNT1:any
  REMAIN1:any
  TOTAL1:any
  USED1:any
  ENCASHED_COUNT1:any

  PAYROLL_LEAVE_NAME2:any
  LAPSE_COUNT2:any
  REMAIN2:any
  TOTAL2:any
  USED2:any
  ENCASHED_COUNT2:any

  PAYROLL_LEAVE_NAME3:any
  LAPSE_COUNT3:any
  REMAIN3:any
  TOTAL3:any
  USED3:any
  ENCASHED_COUNT3:any

  PAYROLL_LEAVE_NAME4:any
  LAPSE_COUNT4:any
  REMAIN4:any
  TOTAL4:any
  USED4:any
  ENCASHED_COUNT4:any
  datas:any

  masterdata2:any
  TRANSACTION_DATE:any
  CR_COUNT:any
  TRANSACTION_TYPE:any
  DR_COUNT:any

  TRANSACTION_DATE1:any
  CR_COUNT1:any
  TRANSACTION_TYPE1:any
  DR_COUNT1:any

  TRANSACTION_DATE2:any
  CR_COUNT2:any
  TRANSACTION_TYPE2:any
  DR_COUNT2:any

  TRANSACTION_DATE3:any
  CR_COUNT3:any
  TRANSACTION_TYPE3:any
  DR_COUNT3:any

  TRANSACTION_DATE4:any
  CR_COUNT4:any
  TRANSACTION_TYPE4:any
  DR_COUNT4:any

  TRANSACTION_DATE5:any
  CR_COUNT5:any
  TRANSACTION_TYPE5:any
  DR_COUNT5:any
  masterdata1:any
  ID:any

  handleCancel(){
    this.isVisible = false;
  }
  add(){
    this.api.getLeaveTransaction1(0,0,'','','AND EMPLOYEE_ID='+this.api.userId).subscribe((data:any)=>{
      this.masterdata1=data['data'];

      this.monthchange(this.MONTH)
      console.log("sawdf");
      
      // this.api.getAllEmployeeLeave2(this.api.userId,this.MONTH,this.YEAR).subscribe((data:any)=>{
      //   if (data['code'] == 200) {
      //     // this.exportLoading = false;
      //     this.masterdata=data['data'][0]['LEAVE_DATA'];
      //     this.mastersdata=data['data'][0]['PIE_DATA'];
      //     this.PAYROLL_LEAVE_TYPE_ID=data['data'][0]['LEAVE_DATA'][0]['PAYROLL_LEAVE_ID']
      
      //     this.USED=data['data'][0]['LEAVE_DATA'][0]['USED']
      //     this.PAYROLL_LEAVE_NAME=data['data'][0]['LEAVE_DATA'][0]['PAYROLL_LEAVE_NAME']
      //     this.LAPSE_COUNT=data['data'][0]['LEAVE_DATA'][0]['LAPSE_COUNT']
      //     this.REMAIN=data['data'][0]['LEAVE_DATA'][0]['REMAIN']
      //     this.TOTAL=data['data'][0]['LEAVE_DATA'][0]['TOTAL']
      //     this.ENCASHED_COUNT=data['data'][0]['LEAVE_DATA'][0]['ENCASHED_COUNT']
      
      //     this.USED1=data['data'][0]['LEAVE_DATA'][1]['USED']
      //     this.PAYROLL_LEAVE_NAME1=data['data'][0]['LEAVE_DATA'][1]['PAYROLL_LEAVE_NAME']
      //     this.LAPSE_COUNT1=data['data'][0]['LEAVE_DATA'][1]['LAPSE_COUNT']
      //     this.REMAIN1=data['data'][0]['LEAVE_DATA'][1]['REMAIN']
      //     this.TOTAL1=data['data'][0]['LEAVE_DATA'][1]['TOTAL']
      //     this.ENCASHED_COUNT1=data['data'][0]['LEAVE_DATA'][1]['ENCASHED_COUNT']
      
      //     this.USED2=data['data'][0]['LEAVE_DATA'][2]['USED']
      //     this.PAYROLL_LEAVE_NAME2=data['data'][0]['LEAVE_DATA'][2]['PAYROLL_LEAVE_NAME']
      //     this.LAPSE_COUNT2=data['data'][0]['LEAVE_DATA'][2]['LAPSE_COUNT']
      //     this.REMAIN2=data['data'][0]['LEAVE_DATA'][2]['REMAIN']
      //     this.TOTAL2=data['data'][0]['LEAVE_DATA'][2]['TOTAL']
      //     this.ENCASHED_COUNT2=data['data'][0]['LEAVE_DATA'][2]['ENCASHED_COUNT']
      
      //     this.USED3=data['data'][0]['LEAVE_DATA'][3]['USED']
      //     this.PAYROLL_LEAVE_NAME3=data['data'][0]['LEAVE_DATA'][3]['PAYROLL_LEAVE_NAME']
      //     this.LAPSE_COUNT3=data['data'][0]['LEAVE_DATA'][3]['LAPSE_COUNT']
      //     this.REMAIN3=data['data'][0]['LEAVE_DATA'][3]['REMAIN']
      //     this.TOTAL3=data['data'][0]['LEAVE_DATA'][3]['TOTAL']
      //     this.ENCASHED_COUNT3=data['data'][0]['LEAVE_DATA'][3]['ENCASHED_COUNT']
      
      //     this.USED4=data['data'][0]['LEAVE_DATA'][4]['USED']
      //     this.PAYROLL_LEAVE_NAME4=data['data'][0]['LEAVE_DATA'][4]['PAYROLL_LEAVE_NAME']
      //     this.LAPSE_COUNT4=data['data'][0]['LEAVE_DATA'][4]['LAPSE_COUNT']
      //     this.REMAIN4=data['data'][0]['LEAVE_DATA'][4]['REMAIN']
      //     this.TOTAL4=data['data'][0]['LEAVE_DATA'][4]['TOTAL']
      //     this.ENCASHED_COUNT4=data['data'][0]['LEAVE_DATA'][4]['ENCASHED_COUNT']
   
      //   }
      //   else {
      //     // this.loadingRecords = false;
      //     this.isSpinning = false;
      //   }
      // }, err => {
      //   console.log(err);
      //   // this.loadingRecords = false;
      //   this.isSpinning = false;
      // });
      
    })
    console.log(this.ENCASHED_COUNT)
    console.log(this.ENCASHED_COUNT1)
    console.log(this.ENCASHED_COUNT2)
    console.log(this.ENCASHED_COUNT3)
    console.log(this.ENCASHED_COUNT4)
    // this.onGetdetails()
   this.onGetLeaves()
this.isVisible=true
 }
 disabledmode=false
 onGetLeaves(){
  // this.onGetdetails()
  this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID ='+this.api.userId+ ' AND PAYROLL_LEAVE_TYPE_ID = '+ 1 + ' AND MONTH(TRANSACTION_DATE)= '+this.MONTH+ ' AND YEAR(TRANSACTION_DATE)= '+this.YEAR).subscribe((data:any)=>{
  
    if(data['code']==200 && data['count']>0){
      this.masterdata2=data['data'];
      this.TRANSACTION_DATE1=data['data'][0]['TRANSACTION_DATE']
      this.TRANSACTION_TYPE1=data['data'][0]['TRANSACTION_TYPE']
      this.CR_COUNT1=data['data'][0]['CR_COUNT']
      this.DR_COUNT1=data['data'][0]['DR_COUNT']
    }
    else{
      this.TRANSACTION_DATE1=0
      this.TRANSACTION_TYPE1=0
      this.CR_COUNT1=0
      this.DR_COUNT1=0
    }
   
   
   
    console.log(this.datas)
  })
 
 
}

isVisible:boolean=false
masterdata:any=[]
mastersdata
MONTH:any
YEAR:any
 onGetdetails(){
  this.api.getAllEmployeeLeave2(this.api.userId,this.MONTH,this.YEAR).subscribe((data:any)=>{
   

  })
  console.log(this.PAYROLL_LEAVE_TYPE_ID,"this.PAYROLL_LEAVE_TYPE_ID")
}

  attendance: any

  getdata() {
    let currentDate = new Date();
    let currentDate1 = this.datePipe.transform(currentDate, "yyyy-MM-dd");
    let newDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    let newDate1 = this.datePipe.transform(newDate, "yyyy-MM-dd");
    this.api.getAttendance1(1, 10, '', 'asc', ` AND IS_COMPOFF = 1  AND EMPLOYEE_ID=${this.api.userId} AND DATE >= '${newDate1}' AND DATE <='${currentDate1}'`).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.attendance = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  leaveTypeDetails = [];
  // EMPLOYEE_ID
  getLeaveTypeMaster() {
    // this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.leaveTypeDetails = data['data'];
    //   }

    // }, err => {
    //   console.log(err);
    // });

    // this.leaveTypeDetails = [];
    // var filter =" AND LEAVE_POLICY_STATUS=1"
    this.api.getAllEmployeeLeave1(0, 0, '', 'asc', '', this.api.userId).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypeDetails = data['data'];

      }

    }, err => {
      console.log(err);
    });
  }

  leavetype
  leaveTypeDetails1
  today122: number
  onLeaveTypeSelected(leaveType) {

    // this.api.getAllEmployeeLeave1(0, 0, '', 'asc', ' AND PAYROLL_LEAVE_ID='+ leaveType,this.api.userId ).subscribe(data => {
    //   if (data['code'] == 200) {
    //     console.log(data);
    //     this.leaveTypeDetails1 = data['data'][0];
    //     console.log(this.leaveTypeDetails1,"this.leaveTypeDetails1")

    //   }

    // }, err => {
    //   console.log(err);
    // });

    if (this.leaveTypeDetails.length > 0) {
      var leaveData = this.leaveTypeDetails.filter(obj => {
        return (obj.PAYROLL_LEAVE_ID == leaveType);


      });

      console.log(leaveData, leaveType, "leaveType");

      if (leaveData.length > 0) {
        this.today = new Date().setDate(new Date().getDate() + Number(leaveData[0]["LEAVE_APPROVAL_DAYS"]));
        this.balance = leaveData[0]["BALANCE"]
        this.data.MODE = leaveData[0]['APPLICABLE_TYPES']
        this.leavetype = leaveData[0]["PAYROLL_LEAVE_NAME"]
        this.leaveTypeDetails1 = leaveData[0]['PAYROLL_LEAVE_ID']
        this.today122 = leaveData[0]['COMPOFF_LAPS_DAY']
        // this.today12 = new Date().setDate(new Date().getDate() + this.today122* 24 * 60 * 60 * 1000);
        // this.leaveTypeDetails1 = leaveData[0]['COMPOFF_LAPS_DAY']
        console.log(this.today12, "ok");


        // console.log(this.data.MODE);
        if (this.balance == 0 && this.leavetype != "LWP") {
          this.disabledmode = true
        }

        if (this.leaveTypeDetails1 == "4") {
          let currentDate = new Date();
          let currentDate1 = this.datePipe.transform(currentDate, "yyyy-MM-dd");
          let newDate = new Date(currentDate.getTime() - this.today122 * 24 * 60 * 60 * 1000);
          let newDate1 = this.datePipe.transform(newDate, "yyyy-MM-dd");
          this.api.getAttendance1(1, 10, '', 'asc', ` AND IS_COMPOFF_TAKEN = 0 AND IS_COMPOFF = 1  AND EMPLOYEE_ID=${this.api.userId} AND DATE >= '${newDate1}' AND DATE <='${currentDate1}'`).subscribe(data => {
            if (data['code'] == 200) {
              console.log(data);
              this.attendance = data['data'];
            }

          }, err => {
            console.log(err);
          });
        }

      }
      else
        this.today = new Date().setDate(new Date().getDate() + Number(4));

      this.data.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
      this.data.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
      console.log(this.data.FROM_DATE, "E");

      this.disabledStartDate = (current: Date): boolean =>
        differenceInCalendarDays(current, this.today) < 0;

      this.data.MODE = null

    }


  }

  onGetLeaves1(){
    this.onGetdetails()
    this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID ='+this.api.userId+ ' AND PAYROLL_LEAVE_TYPE_ID = '+ 1 + ' AND MONTH(TRANSACTION_DATE)= '+this.MONTH+ ' AND YEAR(TRANSACTION_DATE)= '+this.YEAR).subscribe((data:any)=>{
   

      if(data['code']==200 && data['count']>0){
      
        this.masterdata2=data['data'];
        this.TRANSACTION_DATE1=data['data'][0]['TRANSACTION_DATE']
        this.TRANSACTION_TYPE1=data['data'][0]['TRANSACTION_TYPE']
        this.CR_COUNT1=data['data'][0]['CR_COUNT']
        this.DR_COUNT1=data['data'][0]['DR_COUNT']
      }
      else{
        this.TRANSACTION_DATE1=0
        this.TRANSACTION_TYPE1=0
        this.CR_COUNT1=0
        this.DR_COUNT1=0
      }
    
     
      console.log(this.datas)
    })
    this.show=true
    this.Show=false
   
  }
  // onGetLeaves(){
  //   this.onGetdetails()
  //   this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID ='+this.api.userId+ ' AND PAYROLL_LEAVE_TYPE_ID = '+ 1 + ' AND MONTH(TRANSACTION_DATE)= '+this.MONTH+ ' AND YEAR(TRANSACTION_DATE)= '+this.YEAR).subscribe((data:any)=>{
  //     this.masterdata2=data['data'];
  //     this.TRANSACTION_DATE1=data['data'][0]['TRANSACTION_DATE']
  //     this.TRANSACTION_TYPE1=data['data'][0]['TRANSACTION_TYPE']
  //     this.CR_COUNT1=data['data'][0]['CR_COUNT']
  //     this.DR_COUNT1=data['data'][0]['DR_COUNT']
     
  //     console.log(this.datas)
  //   })
   
   
  // }
  Show:boolean=false
  onGetLeave(){
    this.Show=true
    this.show=false
    console.log(this.Show,"hide")

  }
  onGetLeaves2(){
    this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID = '+ this.api.userId + ' AND PAYROLL_LEAVE_TYPE_ID = '+ 2 +' AND MONTH(TRANSACTION_DATE) = '+  this.MONTH  +' AND YEAR(TRANSACTION_DATE) = '+  this.YEAR ).subscribe((data:any)=>{
      if(data['code']==200 && data['count']>0){
        this.masterdata2=data['data'];
        this.TRANSACTION_DATE2=data['data'][0]['TRANSACTION_DATE']
        this.TRANSACTION_TYPE2=data['data'][0]['TRANSACTION_TYPE']
        this.CR_COUNT2=data['data'][0]['CR_COUNT']
        this.DR_COUNT2=data['data'][0]['DR_COUNT']
      }
      else{
        this.TRANSACTION_DATE2=0
        this.TRANSACTION_TYPE2=0
        this.CR_COUNT2=0
        this.DR_COUNT2=0
      }
      
      // this.masterdata2=data['data'];
      // this.TRANSACTION_DATE2=data['data'][0]['TRANSACTION_DATE']
      // this.TRANSACTION_TYPE2=data['data'][0]['TRANSACTION_TYPE']
      // this.CR_COUNT2=data['data'][0]['CR_COUNT']
      // this.DR_COUNT2=data['data'][0]['DR_COUNT']
    })
    this.show2=true
    this.Show2=false
  }
  Show2:boolean=false
  onGetLeave2(){
    this.Show2=true
    this.show2=false
    console.log(this.Show3,"hide")

  }
  onGetLeaves3(){
    this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID = '+ this.api.userId + ' AND PAYROLL_LEAVE_TYPE_ID = '+ 3 +' AND MONTH(TRANSACTION_DATE) = '+ this.MONTH +' AND YEAR(TRANSACTION_DATE) = '+ this.YEAR ).subscribe((data:any)=>{
      
      if(data['code']==200 && data['count']>0){
        this.masterdata2=data['data'];
        this.TRANSACTION_DATE3=data['data'][0]['TRANSACTION_DATE']
        this.TRANSACTION_TYPE3=data['data'][0]['TRANSACTION_TYPE']
        this.CR_COUNT3=data['data'][0]['CR_COUNT']
        this.DR_COUNT3=data['data'][0]['DR_COUNT']
      }
      else{
        this.TRANSACTION_DATE3=0
        this.TRANSACTION_TYPE3=0
        this.CR_COUNT3=0
        this.DR_COUNT3=0
      }
      
      // this.masterdata2=data['data'];
      
    })
    this.show3=true
    this.Show3=false
    console.log(this.show3,"hide")
  }
  Show3:boolean=false
  onGetLeave3(){
    this.Show3=true
    this.show3=false
    console.log(this.Show3,"hide")

  }
  onGetLeaves4(){
    this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID = '+ this.api.userId + ' AND PAYROLL_LEAVE_TYPE_ID = '+ 4 +' AND MONTH(TRANSACTION_DATE) = '+ this.MONTH +' AND YEAR(TRANSACTION_DATE) = '+ this.YEAR ).subscribe((data:any)=>{
      if(data['code']==200 && data['count']>0){
        this.masterdata2=data['data'];
        this.TRANSACTION_DATE4=data['data'][0]['TRANSACTION_DATE']
        this.TRANSACTION_TYPE4=data['data'][0]['TRANSACTION_TYPE']
        this.CR_COUNT4=data['data'][0]['CR_COUNT']
        this.DR_COUNT4=data['data'][0]['DR_COUNT']
      }
      else{
        this.TRANSACTION_DATE4=0
        this.TRANSACTION_TYPE4=0
        this.CR_COUNT4=0
        this.DR_COUNT4=0
      }
      
      // this.masterdata2=data['data'];
      // this.TRANSACTION_DATE4=data['data'][0]['TRANSACTION_DATE']
      // this.TRANSACTION_TYPE4=data['data'][0]['TRANSACTION_TYPE']
      // this.CR_COUNT4=data['data'][0]['CR_COUNT']
      // this.DR_COUNT4=data['data'][0]['DR_COUNT']
    })
    this.show4=true
    this.Show4=false
    console.log(this.show3,"hide")
  }
  Show4:boolean=false
  onGetLeave4(){
    this.Show4=true
    this.show4=false
    console.log(this.Show3,"hide")

  }
  show5:any
  show4:any
  onGetLeaves5(){
    this.api.getAllEmployeeLeave3(0,0,'','',' AND EMPLOYEE_ID = '+ this.api.userId + ' AND PAYROLL_LEAVE_TYPE_ID = '+ 5 +' AND MONTH(TRANSACTION_DATE) = '+ this.MONTH +' AND YEAR(TRANSACTION_DATE) = '+ this.YEAR ).subscribe((data:any)=>{
      this.masterdata2=data['data'];

      if(data['code']==200 && data['count']>0){
        this.masterdata2=data['data'];
        this.TRANSACTION_DATE5=data['data'][0]['TRANSACTION_DATE']
        this.TRANSACTION_TYPE5=data['data'][0]['TRANSACTION_TYPE']
        this.CR_COUNT5=data['data'][0]['CR_COUNT']
        this.DR_COUNT5=data['data'][0]['DR_COUNT']
      }
      else{
        this.TRANSACTION_DATE5=0
        this.TRANSACTION_TYPE5=0
        this.CR_COUNT5=0
        this.DR_COUNT5=0
      }
     
    })
    this.show5=true
    this.Show5=false
    console.log(this.show3,"hide")
  }
  Show5:boolean=false
  onGetLeave5(){
    this.Show5=true
    this.show5=false
    console.log(this.Show3,"hide")

  }
  isVisible1:boolean=false
  makesur(){
    this.isVisible1=true
  }
  handleCancel1(){
    this.isVisible1=false
  }
}
