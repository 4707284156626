import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-leavereport',
  templateUrl: './leavereport.component.html',
  styleUrls: ['./leavereport.component.css'],
  providers: [DatePipe]
})

export class LeavereportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy";
  formTitle = "Leave Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  exportToExcelDataList = [];
   loadingRecords = true;
  exportToExcelLoading = false;
  sortValue: string = "desc";
  sortKey: string = "DATE";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = 
  [["APPLICATION_NO", "Application No."], ["DATE", "Date"], ["LEAVE_TYPE_NAME", "Leave Type"], ["APPLICATION_DATE", "Application Date"], ["HEAD_STATUS", "Head Status"], ["HEAD_REMARK", "Head Remark"], ["HEAD_TIME", "Head Date Time"], ["HR_STATUS", "HR Status"], ["REMARK", "HR Remark"], ["HR_TIME", "HR Time"], ["STATUS", "Final Status"], ["REMARK", "Remark"]];
  isSpinning = false;
  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  initFilter = true;
  filterStatus = [];
  filterEmployees = [];
  employeeFilter = [];
  filterStatusValue: string[] = [];
  date: Date[] = [];
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))
  ClusterId = this.cookie.get("clusterId");

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private _exportService: ExportService, private cookie: CookieService) { }
    branchId =  this.cookie.get('branchId')

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.loadDataFilter();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    console.log(this.branchId);
    
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  orgId = this.cookie.get('orgId');

  search(reset: boolean = false, exportToExcel: boolean = false) {
    this.selectedRoleStatus = this.cookie.get('selectedLoginType');

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var empFilterStr = "";
    if (this.employeeFilter.length > 0) {
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.employeeFilter + ")";
    }

    var statusFilterStr = "";
    if (this.filterStatusValue.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.filterStatusValue + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd ') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd ') + "')";
    }

    if(this.ROLE_ID == 2) {
      var emplogin  =''
      emplogin = ''
     }
     else{
      emplogin = "AND CLUSTER_ID = " + this.ClusterId
     }


    if (exportToExcel == false) {
    
      if (this.ROLE_ID ==14) {
        // branchId =  sessionStorage.getItem('branchId')
        var branches = ''
        branches = " and BRANCH_ID in ("+this.branchId + ")"
      this.loadingRecords = true;
      this.api.getLeaveReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery +emplogin + dateFilter + statusFilterStr  +empFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + branches).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }
    else{
      this.loadingRecords = true;
      this.api.getLeaveReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + emplogin + dateFilter + statusFilterStr  +empFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
}
     else {
      this.exportToExcelLoading = true;

      this.api.getLeaveReport(0, 0, this.sortKey, sort, this.filterQuery  + emplogin + dateFilter + statusFilterStr + empFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  onKeypressEvent(reset: boolean = false) {
    document.getElementById('button').focus();
    this.search(true);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  getNameOfHeadStatus(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "HA")
      return "Head Approved";

    else if (STATUS == "HR")
      return "Head Rejected";

    else
      return "";
  }

  getNameOfHR_Status(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "A")
      return "Approved";

    else if (STATUS == "R")
      return "Rejected";

    else
      return "";
  }

  getNameOfFinalStatus(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "A")
      return "Approved";

    else if (STATUS == "R")
      return "Rejected";
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(employees => {
      if (employees['code'] == 200) {
        this.loadingFilterEmployees = false;
        this.filterEmployees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.date = [];
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.filterStatusValue = [];
    this.employeeFilter = [];
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    if ((this.date.length > 0) || (this.filterStatusValue.length > 0) || (this.employeeFilter.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Status'] = this.getNameOfFinalStatus(this.exportToExcelDataList[i]['STATUS']);
      obj1['Application No.'] = this.exportToExcelDataList[i]['APPLICATION_NO'];
      obj1['Employee Name'] = this.exportToExcelDataList[i]['EMP_NAME'];
      obj1['Branch Name'] = this.exportToExcelDataList[i]['BRANCH_NAME'];
      obj1['Department Name'] = this.exportToExcelDataList[i]['DEPARTMENT_NAME'];
      obj1['Designation Name'] = this.exportToExcelDataList[i]['DESIGNATION_NAME'];
      obj1['Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd MMM yyyy');
      obj1['Application Date Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['APPLICATION_DATE'], 'dd MMM yyyy, hh:mm:ss a');
      obj1['Leave Type'] = this.exportToExcelDataList[i]['LEAVE_TYPE_NAME'];
      // obj1['Mode'] = this.exportToExcelDataList[i]['MODE'];
      if(this.exportToExcelDataList[i]['MODE']==1)
      {
        obj1['Mode']="Full Day"
      }
      else if (this.exportToExcelDataList[i]['MODE']==2){
        obj1['Mode']="Half Day"
      }
      else{
        obj1['Mode']=''
      }
      // obj1['Mode'] = this.exportToExcelDataList[i]['MODE'];
      if(this.exportToExcelDataList[i]['SESSION']==1)
      {
        obj1['Session']="First"
      }
      else if(this.exportToExcelDataList[i]['SESSION']==2){
        obj1['Session']="Second" 
      }
      else{
        obj1['Session']=''
      }
      // obj1['Leave Type'] = this.exportToExcelDataList[i]['SESSION'];

      
      obj1['Working Day(Compoff Date)'] = this.datePipe.transform(this.exportToExcelDataList[i]['COMPOFF_DATE'], 'dd MMM yyyy');
    
      // COMPOFF_DATE
      obj1['Reason'] = this.exportToExcelDataList[i]['REASON'];
      obj1['Head Remark'] = this.exportToExcelDataList[i]['HEAD_REMARK'];
      obj1['Head Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['HEAD_TIME'], 'dd MMM yyyy, hh:mm:ss a');

      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Leave Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }
}
