import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-memo-detailed-report',
  templateUrl: './memo-detailed-report.component.html',
  styleUrls: ['./memo-detailed-report.component.css']
})
export class MemoDetailedReportComponent implements OnInit {
  formTitle = "Memo Detailed Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Memodetailed.xlsx';
  dataList = [];
  exportToExcelLoading = false
  index = 0;
  exportToExcelDataList: any = []
  filter_invisible: boolean = false
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  dateQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["MEMO_NO", "Memo No"], ["MEMO_DATE", " Memo Date"], ["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"],
  ["SALES_EXECUTIVE_NAME", "Sales Executive Name"], ["INVOICE_DATE", "Converted To Invoice"], ["PENDING_AT", "Pending At"],]

  isSpinning = false

  SALES_EXECUTIVE_ID: any = []
  Value1: any
  Value2: any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  MEMO_DATE: any = []
  data1 = [];
  INVOICE_DATE: any = []
  MODEL_ID: any = []
  DATE: any
  ticketQuestion = {};

  departments = []
  supportusers = []

  MEMO_STATUS: any[];
  constructor(private api: ApiService, private datePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.Value1 = new Date()
    this.Value2 = new Date()
    this.getAllmodels();
    // this.getSales()
    // this.search();

  }
  models = []
  getAllmodels() {
    this.api.getAllModels(0, 0, "", "", "AND STATUS=1").subscribe(data => {
      this.models = data['data'];
      this.getSales()
    })
  }

  sales:any=[];
  getSales() {
    this.api.getAllEmpRoleMap(0,0, this.sortKey, this.sortValue, ' AND ROLE_ID IN(28)')
      .subscribe(data => {
        this.sales = data['data']
        let ids = [];

        if (this.SELECT_ALL2 == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids = [];
        }
  
        this.SALES_EXECUTIVE_ID = ids;
        this.search();
      },
        (err) => {
          console.log(err);
        }
      );
    }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  keyup(event: any) {
    this.search();
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event: any) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff2(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    this.search(true, true)
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  clearFilter() {
    this.filter_invisible = false;
    this.MODEL_ID = []
    this.INVOICE_DATE = [];
    this.SALES_EXECUTIVE_ID = []
    this.SELECT_ALL2 = false
    let ids = [];
    this.Value1 = new Date()
    this.Value2 = new Date()
    this.search()

    for (var i = 0; i < this.sales.length; i++) {
      ids.push(this.sales[i]["EMPLOYEE_ID"]);
    }
    ids = [];

    this.SALES_EXECUTIVE_ID = ids;
    this.MEMO_DATE = []
   

   
  }


  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportToExcel: boolean = false) {

    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.Value1 = this.datePipe.transform(this.Value1, 'yyyy-MM-dd');

    if (this.Value1 != null || this.Value1 != '') {
      this.MEMO_DATE = this.Value1
    }
    this.Value2 = this.datePipe.transform(this.Value2, 'yyyy-MM-dd');

    if (this.Value2 != null || this.Value2 != '') {
      this.INVOICE_DATE = this.Value2
    }
    else
    {
      this.INVOICE_DATE=''
    }

    if (this.SALES_EXECUTIVE_ID != null || this.SALES_EXECUTIVE_ID != '') {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }

    if (this.SALES_EXECUTIVE_ID != null || this.SALES_EXECUTIVE_ID != '') {
      this.SALES_EXECUTIVE_ID
    }

    if (exportToExcel == false) {
      this.loadingRecords = true

      this.api.getMemoDetailed(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MEMO_DATE, this.SALES_EXECUTIVE_ID, this.MODEL_ID, this.INVOICE_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];

        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.exportToExcelLoading = true;
      this.api.getMemoDetailed(0, 0, this.sortKey, sort, likeQuery, this.MEMO_DATE, this.SALES_EXECUTIVE_ID, this.MODEL_ID, this.INVOICE_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();

        }

      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }
  }

  showFilter() {
    if (this.filter_invisible == true)
      this.filter_invisible = false
    else
      this.filter_invisible = true

  }
  Value: any
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object()
    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Memo No'] = this.exportToExcelDataList[i]['MEMO_NO'];
      obj1['Memo Date'] = this.exportToExcelDataList[i]['MEMO_DATE'];
      obj1['Customer Name'] = this.exportToExcelDataList[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.exportToExcelDataList[i]['MODEL_NAME'];
      obj1['sales Executive Name'] = this.exportToExcelDataList[i]['SALES_EXECUTIVE_NAME'];
      obj1['Converted to Invoice'] = this.datePipe.transform(this.exportToExcelDataList[i]['CONVERTED_TO_INVOICE'], 'dd-MM-yyyy');

      obj1['Pending at'] = this.exportToExcelDataList[i]['PENDING_AT'];

      arry1.push(Object.assign({}, obj1));
      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Memo Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }

    }
  }
  applyFilter() {
    this.filter_invisible = false
    this.isSpinning = true
    this.loadingRecords = true;

    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.SALES_EXECUTIVE_ID != null && this.SALES_EXECUTIVE_ID != 0 && this.SALES_EXECUTIVE_ID != undefined) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }
    if (this.MODEL_ID != null && this.MODEL_ID != 0 && this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID
    }
    this.Value1 = this.datePipe.transform(this.Value1, 'yyyy-MM-dd');

    if (this.Value1 != null) {
      this.MEMO_DATE = this.Value1
    }
    this.Value2 = this.datePipe.transform(this.Value2, 'yyyy-MM-dd');

    if (this.Value2 != null) {
      this.INVOICE_DATE = this.Value2
    }


    this.search();

  }

}

