import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-corporate-sales-report',
  templateUrl: './corporate-sales-report.component.html',
  styleUrls: ['./corporate-sales-report.component.css']
})
export class CorporateSalesReportComponent implements OnInit {

  columns: string[][] = [['BRANCH_NAME', 'Branch Name'], ['CUSTOMER_NAME', 'Customer'], ['COMPANY_NAME', 'Company'],
  ['CORPORATE_DISCOUNT', 'Discount Amt'], ['REQUESTOR_NAME', 'SC Name'], ['SALES_MANAGER_NAME', 'A SM Name']]
  roleId = Number(sessionStorage.getItem('roleId'))
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "CUSTOMER_ID";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Corporate Sales Report"
  startValue: any;
  endValue: any;
  isSpinning = false;
  dataList = []
  totalRecords = []

  filterQuery: string = ""

  orgId = this.cookie.get('orgId');
  employee = []

  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";

  constructor(private api: ApiService, public message: NzNotificationService, public datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService) { }

  ngOnInit() {
    if (this.roleId == 33) {
      this.SEFilter()
      this.getEmployee()
    }
    else { this.getEmployeeChilds() }
    this.search(false)
    this.getBranch()
    // this.FROM_DATE =new Date(new Date().setDate(new Date().getDate() - 7))
    // this.TO_DATE = new Date();
  }

  viewFilter: boolean = true
  getSalesGM(event) {
    let ids12 = [];
    this.viewFilter = true

    this.api.getEmployeeChildGM(0, 0, '', '', '', event,this.cluster)
      .subscribe(data => {
        this.sales = data['data']
        this.viewFilter = false

        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids12.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids12 = [];
        }
        this.ids1 = ids12;
        // this.search(true);
      },

      );
  }
cluster:any
  getEmployee() {
    this.api.getAllEmployees(0, 0, '', '', "AND ROLE_IDS  like '%" + 29 + "%'")
      .subscribe(data => {
        this.emp = data['data']
        this.cluster =data['data'][0]['CLUSTER_ID']
      },
        (err) => {
          // console.log(err);
        }
      );
  }

  BRANCH_ID: any = [];
  branches = [];
  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', '').subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  idss
  getEmployeeChilds() {
    this.api.getEmployeeChilds(0, 0, '', '', '')
      .subscribe(data => {
        this.sales = data['data']
        this.idss = [];
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            this.idss.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          this.idss = [];
        }
        console.log(this.SALES_EXECUTIVE_ID)
        this.search(true)
      },
        (err) => {
          console.log(err);
        }
      );
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  datas1 = [];
  GENERAL_MANAGER
  SALES_HEAD

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }

    if (this.ROLE_ID == 29) {
      this.ROLESS = this.SALES_MANAGER_ID;
    }
    else if (this.ROLE_ID == 30) {
      this.ROLESS = this.GENERAL_MANAGER;
    }
    else {
      this.ROLESS = this.SALES_HEAD;
    }
    //  if(this.ROLE_ID == null && this.ROLESS==null){
    //   this.SEFilter()
    // }
    // if(this.ROLE_ID)
    if (this.roleId != 33) {
      this.SALES_EXECUTIVE_ID = this.idss;
    }
    else if (this.ROLE_ID == null) {
      this.SALES_EXECUTIVE_ID = this.id2

    }

    else {
      this.SALES_EXECUTIVE_ID = this.ids1
      console.log(this.SALES_EXECUTIVE_ID, "")

    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getCorporateSalesReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS, this.BRANCH_ID).subscribe(data => {
        this.loadingRecords = false;
        this.dataList = data['data']
        this.totalRecords = data['count']
        console.log(this.dataList);
      });
    }
    else {
      this.exportLoading = true;

      this.api.getCorporateSalesReport(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS, this.BRANCH_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.datas1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }
  ROLESS
  applyFilter() {
    // if(this.ROLE_ID == null && this.ROLESS==null){
    //   this.SEFilter()
    // }
    this.filterClass = 'filter-invisible';
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    this.search(true);
  }


  clearFilter() {
    // if(this.ROLE_ID == null && this.ROLESS==null){
    //   this.SEFilter()
    // }
    this.SALES_EXECUTIVE_ID = this.id2
    this.filterClass = 'filter-invisible';
    this.MONTH = this.month;
    this.MONTH1 = new Date()
    this.YEAR = this.year
    this.SELECT_ALL = true
    let ids1 = [];
    // for (var i = 0; i < this.sales.length; i++) {
    //   ids1.push(this.sales[i]["ID"]);
    // }
    this.SALES_EXECUTIVE_ID = this.id2;
    this.ROLESS = null
    this.ROLE_ID = null
    this.SALES_MANAGER_ID = null
    this.SALES_HEAD = null
    this.GENERAL_MANAGER = null
    this.BRANCH_ID = null
    this.viewFilter = true

    this.search(true)

  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.datas1.length; i++) {
      obj1['Customer Name'] = this.datas1[i]['CUSTOMER_NAME'];
      obj1['Company Name'] = this.datas1[i]['COMPANY_NAME'];
      obj1['Discount Amt'] = this.datas1[i]['CORPORATE_DISCOUNT'];
      obj1['SC Name'] = this.datas1[i]['REQUESTOR_NAME'];
      obj1['A SM Name'] = this.datas1[i]['SALES_MANAGER_NAME'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.datas1.length - 1) {
        this._exportService.exportExcel(arry1, 'Corporate Sales Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  id2
  SEFilter() {

    this.api.getAllEmployees12(0, 0, '', '', "AND ROLE_IDS  like '%" + 28 + "%'", '')
      .subscribe(data => {
        this.sales = data['data']
        this.id2 = [];
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            this.id2.push(this.sales[i]["ID"]);
          }
        } else {
          this.id2 = [];
        }

        // this.SALES_EXECUTIVE_ID = ids1;
        setTimeout(() => {
          this.search(true);
        }, 3000);

      },
        (err) => {
          console.log(err);
        }
      );

  }

  SELECT_ALL: boolean = true;

  onSelectAllChecked(event: any) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }
  SALES_EXECUTIVE_ID
  onSelectOff(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if (a! = b) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }
  ROLE_ID
  showSE: boolean = false
  emp
  getSales1() {
    this.showSE = true
    this.api.getAllEmployees(0, 0, '', '', "AND ROLE_IDS  like '%" + this.ROLE_ID + "%'")
      .subscribe(data => {
        this.emp = data['data']

      },
        (err) => {
          console.log(err);
        }
      );
  }
  sales = [];
  SALES_MANAGER_ID
  ids1: any = []
  getSales(event) {
    let ids12 = [];
    this.viewFilter = true

    this.api.getEmployeeChild(0, 0, '', '', '', event,this.cluster)
      .subscribe(data => {
        this.sales = data['data']
        this.viewFilter = false

        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids12.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids12 = [];
        }
        this.ids1 = ids12;
        // this.search(true);
      },

      );
  }

}
