import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { FuelMaster } from "src/app/Models/FuelMaster";
import { ApiService } from "src/app/Service/api.service";
import { FuelDrawerComponent } from "../fuel-drawer/fuel-drawer.component";

@Component({
  selector: "app-fuel-master",
  templateUrl: "./fuel-master.component.html",
  styleUrls: ["./fuel-master.component.css"],
})

export class FuelMasterComponent implements OnInit {
  formTitle = "Manage Fuel";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["NAME", "Name"], ["SEQUENCE_NO", "Sequence No."], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: FuelMaster = new FuelMaster();
  isSpinning = false;

  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.loadingRecords = true;
    this.api
      .getAllFuel(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.totalRecords = data["count"];
            this.dataList = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  today = new Date().setDate(new Date().getDate() + 4);
  @ViewChild(FuelDrawerComponent, { static: false }) FuelDrawerComponentVar: FuelDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Fuel";
    this.drawerData = new FuelMaster();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.FuelDrawerComponentVar.getSequenceNo();
  }

  edit(data: FuelMaster): void {
    this.drawerTitle = "Update Fuel Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: FuelMaster, status) {
    data.STATUS = status;

    this.api.updateFuel(data).subscribe((successCode) => {
      if (successCode["code"] == 200)
        this.message.success("Status Updated Successfully", "");
      else this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
