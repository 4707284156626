import { Component, OnInit } from '@angular/core';
import { EmmService } from 'src/app/Service/emm.service';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-earlymarkreport',
  templateUrl: './earlymarkreport.component.html',
  styleUrls: ['./earlymarkreport.component.css'],
  providers: [DatePipe]
})

export class EarlymarkreportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy"
  formTitle = "Evening Short Leave Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  exportToExcelDataList = [];
  loadingRecords = false;
  exportToExcelLoading = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DATE", "Date"], ["EXPECTED_OUT_TIME", "Expected Time"],, ["REASON", "Reason"], ["STATUS", "Status"], ["REMARK", "Remark"], ["APPROVER_NAME", "Approver Name"],["DATE_APPLIED","Application Date Time"],["APPROVED_DATETIME","Head Date Time"]]
  isSpinning = false;
  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  filterStatus = [];
  filterEmployees: [];
  employeeFilter = [];
  filterStatusValue: string[] = [];
  date: Date[] = [];
  excelDataList = [];
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private _exportService: ExportService, private cookie: CookieService) { }
        branchId =  sessionStorage.getItem('branchId')

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.loadDataFilter();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  orgId = this.cookie.get('orgId');

  search(reset: boolean = false, exportToExcel: boolean = false) {
    this.selectedRoleStatus = this.cookie.get('selectedLoginType');

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + " ) ";
    }

    var empFilterStr = "";
    if (this.employeeFilter.length > 0) {
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.employeeFilter + ")";
    }

    var statusFilterStr = "";
    if (this.filterStatusValue.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.filterStatusValue + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd 23:59:59') + "')";
    }
    if (exportToExcel == false) {
      this.loadingRecords = true;

      this.api.getEarlymarkReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + dateFilter + statusFilterStr + empFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
       }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      if ( this.ROLE_ID ==14) {
        // branchId =  sessionStorage.getItem('branchId')

        var branches = ''
        branches = " and BRANCH_ID in (" + this.branchId + ")"
      this.loadingRecords = true;

      this.api.getEarlymarkReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + dateFilter + statusFilterStr + empFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + branches).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }

    }
     else {
      this.exportToExcelLoading = true;

      this.api.getEarlymarkReport(0, 0, this.sortKey, sort, this.filterQuery + dateFilter + statusFilterStr + empFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
        if (data['code'] == 200) {
         
          this.exportToExcelDataList = data['data'];
          this.exportToExcelLoading = false;
         
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  getName(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "A")
      return "Approved";

    else if (STATUS == "R")
      return "Rejected";
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(employees => {
      if (employees['code'] == 200) {
        this.loadingFilterEmployees = false;
        this.filterEmployees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.date = [];
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.filterStatusValue = [];
    this.employeeFilter = [];
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    if ((this.date.length > 0) || (this.filterStatusValue.length > 0) || (this.employeeFilter.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Status'] = this.getName(this.exportToExcelDataList[i]['STATUS']);
      obj1['Employee Name'] = this.exportToExcelDataList[i]['EMPLOYEE_NAME'];
      obj1['Branch Name'] = this.exportToExcelDataList[i]['BRANCH_NAME'];
      obj1['Department Name'] = this.exportToExcelDataList[i]['DEPARTMENT_NAME'];
      obj1['Designation Name'] = this.exportToExcelDataList[i]['DESIGNATION_NAME'];
      obj1['Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd MMM yyyy');
      obj1['Expected Time'] = this.datePipe.transform(this.getTimeInAM_PM(this.exportToExcelDataList[i]['EXPECTED_OUT_TIME']), 'hh:mm:ss a');
      obj1['Reason'] = this.exportToExcelDataList[i]['REASON'];
      obj1['Remark'] = this.exportToExcelDataList[i]['REMARK'];
      obj1['Approver Name'] = this.exportToExcelDataList[i]['APPROVER_NAME'];
      obj1['Application Date Time']= this.datePipe.transform(this.exportToExcelDataList[i]['DATE_APPLIED'],'dd MMM yyy, hh:mm:ss a')
      obj1['Head Date Time']=this.datePipe.transform(this.exportToExcelDataList[i]['APPROVED_DATETIME'],'dd MMM yyy, hh:mm:ss a')
      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Evening Short Leave Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }
}