import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service'
@Component({
  selector: 'app-model-wise-sales-summry',
  templateUrl: './model-wise-sales-summry.component.html',
  styleUrls: ['./model-wise-sales-summry.component.css']
})
export class ModelWiseSalesSummryComponent implements OnInit {
  formTitle = "Model Wise Sales Summary report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'ModelWisesales.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "MODEL_NAME";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns:string[][] =[["MODEL_NAME", "Model"] ]
  columns1:string[][] =[["MODEL_NAME", "Model"] ]
  BRANCH = [];
  SalesExecutive = [];
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  leaves = [];
  supportusers = [];
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  isButtonSpinning: boolean = false;
  FROM_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  employees = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM";
  current = new Date();

constructor(private api: ApiService, private datePipe: DatePipe,private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

ngOnInit(): void {
  this.search(true);
}
  
onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
}

keyup(event: any) {
    this.search();
}

dates: any = [];
today2 = new Date();

  // startDateChange() {
  //   var startDate = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd');
  //   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  //   console.log(this.getDaysArray(startDate, endDate));
  //   console.log(this.dates);
  // }

getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
}

timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }

showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
}

  // onFromDateChange(fromDate: any) {
  //   if (fromDate == null)
  //     this.date2 = new Date();

  //   else
  //     this.date2 = new Date(fromDate);
  // }

  // disabledStartDate = (FROM_DATE: Date): boolean => {
  //   if (!FROM_DATE || !this.TO_DATE) {
  //     return false;
  //   }
  //   return FROM_DATE.getTime() > this.TO_DATE;
  // };


  // disabledEndDate = (selected: Date): boolean =>
  //   // Can not select days before today and today
  //   differenceInCalendarDays(selected, this.current) < 0;


  // disabledEndDate = (TO_DATE: Date): boolean => {
  //   if (!TO_DATE || !this.FROM_DATE) {
  //     return true;
  //   }
  //   return TO_DATE.getTime() < this.FROM_DATE;
  // };

  onStartChange(date: Date): void {
    this.FROM_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void { 
    if (!open) {
      this.endOpen = true;
    }
  }
   
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

applyFilter() {
if ((this.MONTH1 != undefined) )
  this.isFilterApplied = "primary";

  else
  this.isFilterApplied = "default";

  this.search(true);
  this.filterClass = "filter-invisible";
}

setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
}

supportAgentWiseDeptArray = [];

exportLoading: boolean = false;

importInExcel() {
    this.search(true, true);
}

dataList2=[]
search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery =  " AND (";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+ ")";
    }
   
    // var likeQuery = "";
    // if (this.searchText != "" ) {
    //   likeQuery = " AND ";

    //   likeQuery += " STATUS like '%" + this.searchText + "%' OR EMPLOYEE_NAME like '%" + this.searchText + "%' OR TYPE_NAME like '%" + this.searchText + "%' OR FROM_DATE like '%" + this.searchText + "%' OR TO_DATE like '%" + this.searchText + "%' OR REASON like '%" + this.searchText + "%' OR APPROVER_NAME like '%" + this.searchText + "%' OR APPROVED_DATETIME like '%" + this.searchText + "%' OR REMARK like '%" + this.searchText + "%' ";
      

    //   likeQuery += likeQuery.substring(0, likeQuery.length -1) + "";
    // }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }
    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }
    
if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.ModelWiseSalesSummry(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery,this.MONTH, this.YEAR).subscribe(data => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        },err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.ModelWiseSalesSummry(0,0, this.sortKey, this.sortValue, this.filterQuery + likeQuery,this.MONTH, this.YEAR).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
       });
    }
}



sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
}

clearFilter() {
  this.FROM_DATE = new Date();
  this.TO_DATE = new Date();
  this.filterQuery = '';
  this.dataList = [];

  this.MONTH1 = new Date();
  this.MONTH = this.month;
  this.YEAR = this.year;
  this.search(true);
  this.isFilterApplied = "default";
  this.filterClass = "filter-invisible";
}

convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Model'] = this.dataList2[i]['MODEL_NAME'];
      obj1['Sales'] = this.dataList2[i]['SALES'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Model Wise Sales Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
   }
}

}


  
