import { Component, OnInit, Input } from '@angular/core';
import { EmployeeRoleMaster } from 'src/app/Models/employee-role-master';
// import { EvaluationMapping } from 'src/app/Models/evaluation-mapping';
import { EmmService } from 'src/app/Service/emm.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Designationmaster } from 'src/app/Models/Designationmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-evaluationmapping',
  templateUrl: './evaluationmapping.component.html',
  styleUrls: ['./evaluationmapping.component.css']
})
export class EvaluationmappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Designationmaster;
  @Input() evaluationData: string[];
  @Input() drawerVisible: boolean;
  
  
  isSpinning = false
  formTitle = "Evaluation Criteria Mapping";
  loadingRecords = true;

  //eData=[{"CRITERIA_ID":1,"IS_SELECTED":0,"WEITAGE":"0","NAME":"level of execution","REPORTING_PERSON":"0","CLIENT_ID":1},{"CRITERIA_ID":2,"IS_SELECTED":0,"WEITAGE":"0","NAME":"Quality of work","REPORTING_PERSON":0,"CLIENT_ID":1},{"CRITERIA_ID":3,"IS_SELECTED":0,"WEITAGE":"0","NAME":"Creativity","REPORTING_PERSON":0,"CLIENT_ID":1},{"CRITERIA_ID":5,"IS_SELECTED":0,"WEITAGE":"0","NAME":"Honesty","REPORTING_PERSON":0,"CLIENT_ID":1},{"CRITERIA_ID":6,"IS_SELECTED":0,"WEITAGE":"0","NAME":"pp1","REPORTING_PERSON":0,"CLIENT_ID":1},{"CRITERIA_ID":7,"IS_SELECTED":0,"WEITAGE":"0","NAME":"hiee","REPORTING_PERSON":0,"CLIENT_ID":1},{"CRITERIA_ID":8,"IS_SELECTED":0,"WEITAGE":"0","NAME":"hieee","REPORTING_PERSON":0,"CLIENT_ID":1}];
  
  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
  }

  
  close(): void {
    this.drawerClose();
  }

  save(): void {
    this.isSpinning = true;
    
    console.log(this.evaluationData);
      this.api.mapEvaluationCriteria(this.data.ID,this.evaluationData)
      .subscribe(successCode => {
        if(successCode['code']=="200")
        { 
            this.message.success("Criteria mapping Done Successfully...", "");
            this.drawerClose();
            this.isSpinning = false;
        }
        else
        {
          this.message.error("Criteria mapping Failed...", "");
          this.isSpinning = false;
        }
      });
  }
}
