import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ShiftMaster } from 'src/app/Models/shift1';
// import { ShiftMaster } from 'src/app/Models/shiftmaster';
import { ApiService } from 'src/app/Service/api.service';
import { ShiftmasterComponent } from '../shiftmaster/shiftmaster.component';

@Component({
  selector: 'app-shiftmasters',
  templateUrl: './shiftmasters.component.html',
  styleUrls: ['./shiftmasters.component.css']
})
export class ShiftmastersComponent implements OnInit {
  // [x: string]: any;

  @ViewChild(ShiftmasterComponent, { static: false }) shiftmaster: ShiftmasterComponent;

  formTitle = 'Manage Shifts';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", 'Shift Name'],
  ["CODE", ' Short Code'],
  ["BEGIN_TIME", 'Day Start Time'],
  ["END_TIME", 'Day End Time'],
  ["BREAK_START_TIME", 'Break Start Time'],
  ["BREAK_END_TIME", 'Break End Time'],
  ["MAX_LATEMARK_TIME", 'Max latemark Tim'],
  ["MAX_EARLYMARK_TIME", 'Max Earlymark Time'],
  ["MAX_APPLIED_LATEMARK_TIME", 'Max Latemark Applied Time'],
  ["MAX_EARLYMARK_APPLIED_TIME", 'Max Earlymark Applied Time'],
  ["HALFDAY_TIME", 'Halfday Time'],
  ["MAX_LATEMARK_COUNT", 'Max latemark Count'],
  ["MAX_EARLYMARK_COUNT", 'Max Earlymark Count'],
  ["MAX_LATECOMING_COUNT", 'Max Latecoming Count'],
  ["GRACE_MINUTES", 'Grace Minuties'],
  ["TOTAL_WORKING_TIME", 'Total Working Time(hours)'],
  ["BREAKE_TIME", 'Break Time(hours)'],
  ["NO_OF_BREAKS", 'Number Of Breaks'],
  ]


 

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ShiftMaster = new ShiftMaster();
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  key = "7061737323313233"


  drawerData2: string[];
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: ShiftMaster = new ShiftMaster();

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {

    this.search()
  }

  shifts: any = [];

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllShifts(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create Shifts"
    this.drawerData = new ShiftMaster();
    this.drawerVisible = true;
  }

  edit(data: ShiftMaster): void {
    this.drawerTitle = "Update Shifts ";
    this.drawerData = Object.assign({}, data);
    this.shiftmaster.getallorg2(data.ID);
    // this.shiftmaster.getallOrg2
    // this.drawerData.MAX_APPLIED_LATEMARK_TIME= new Date('01-01-1970 '+this.drawerData.MAX_APPLIED_LATEMARK_TIME)
    // this.drawerData.MAX_APPLIED_LATEMARK_TIME= new Date(this.drawerData.MAX_APPLIED_LATEMARK_TIME)
    this.drawerData.BEGIN_TIME = new Date('01-01-1970 ' + this.drawerData.BEGIN_TIME)
    this.drawerData.BEGIN_TIME = new Date(this.drawerData.BEGIN_TIME)

    this.drawerData.END_TIME = new Date('01-01-1970 ' + this.drawerData.END_TIME)
    this.drawerData.END_TIME = new Date(this.drawerData.END_TIME)

    this.drawerData.MARK_HALFDAY_AFTER = new Date('01-01-1970 ' + this.drawerData.MARK_HALFDAY_AFTER)
    this.drawerData.MARK_HALFDAY_AFTER = new Date(this.drawerData.MARK_HALFDAY_AFTER)

    this.drawerData.MARK_HALFDAY_BEFORE = new Date('01-01-1970 ' + this.drawerData.MARK_HALFDAY_BEFORE)
    this.drawerData.MARK_HALFDAY_BEFORE = new Date(this.drawerData.MARK_HALFDAY_BEFORE)

    this.drawerData.MARK_FULLDAY_AFTRE = new Date('01-01-1970 ' + this.drawerData.MARK_FULLDAY_AFTRE)
    this.drawerData.MARK_FULLDAY_AFTRE = new Date(this.drawerData.MARK_FULLDAY_AFTRE)

    this.drawerData.MARK_FULLDAY_BEFORE = new Date('01-01-1970 ' + this.drawerData.MARK_FULLDAY_BEFORE)
    this.drawerData.MARK_FULLDAY_BEFORE = new Date(this.drawerData.MARK_FULLDAY_BEFORE)
    console.log(this.drawerData.MARK_FULLDAY_BEFORE, "this.drawerData.MARK_FULLDAY_BEFORE");

    if (this.drawerData.IS_BREAK == true) {
      this.drawerData.BREAK_START_TIME = new Date('01-01-1970 ' + this.drawerData.BREAK_START_TIME)
      this.drawerData.BREAK_START_TIME = new Date(this.drawerData.BREAK_START_TIME)

      console.log(this.drawerData.BREAK_START_TIME, "this.drawerData.BREAK_START_TIME");

      this.drawerData.BREAK_END_TIME = new Date('01-01-1970 ' + this.drawerData.BREAK_END_TIME)
      this.drawerData.BREAK_END_TIME = new Date(this.drawerData.BREAK_END_TIME)
      console.log(this.drawerData.BREAK_END_TIME, "this.drawerData.BREAK_END_TIME");
    }


    this.drawerData.MAX_APPLIED_LATEMARK_TIME = new Date('01-01-1970 ' + this.drawerData.MAX_APPLIED_LATEMARK_TIME)
    this.drawerData.MAX_APPLIED_LATEMARK_TIME = new Date(this.drawerData.MAX_APPLIED_LATEMARK_TIME)
    console.log(this.drawerData.MAX_APPLIED_LATEMARK_TIME, "this.drawerData.MAX_APPLIED_LATEMARK_TIME");

    this.drawerData.MAX_EARLYMARK_APPLIED_TIME = new Date('01-01-1970 ' + this.drawerData.MAX_EARLYMARK_APPLIED_TIME)
    this.drawerData.MAX_EARLYMARK_APPLIED_TIME = new Date(this.drawerData.MAX_EARLYMARK_APPLIED_TIME)
    console.log(this.drawerData.MAX_EARLYMARK_APPLIED_TIME, "this.drawerData.MAX_EARLYMARK_APPLIED_TIME");

    this.drawerData.MAX_LATEMARK_TIME = new Date('01-01-1970 ' + this.drawerData.MAX_LATEMARK_TIME)
    this.drawerData.MAX_LATEMARK_TIME = new Date(this.drawerData.MAX_LATEMARK_TIME)
    console.log(this.drawerData.MAX_LATEMARK_TIME, "this.drawerData.MAX_LATEMARK_TIME");

    this.drawerData.MAX_EARLYMARK_TIME = new Date('01-01-1970 ' + this.drawerData.MAX_EARLYMARK_TIME)
    this.drawerData.MAX_EARLYMARK_TIME = new Date(this.drawerData.MAX_EARLYMARK_TIME)
    console.log(this.drawerData.MAX_EARLYMARK_TIME, "this.drawerData.MAX_EARLYMARK_TIME");

    this.drawerData.HALFDAY_TIME = new Date('01-01-1970 ' + this.drawerData.HALFDAY_TIME)
    this.drawerData.HALFDAY_TIME = new Date(this.drawerData.HALFDAY_TIME)
    console.log(this.drawerData.HALFDAY_TIME, "this.drawerData.HALFDAY_TIME");

    // this.data.MAX_APPLIED_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_APPLIED_LATEMARK_TIME, "HH:mm:ss");
    // this.data.MAX_EARLYMARK_APPLIED_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_APPLIED_TIME, "HH:mm:ss");
    // this.data.MAX_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_LATEMARK_TIME, "HH:mm:ss");
    // this.data.MAX_EARLYMARK_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_TIME, "HH:mm:ss");
    // this.data.HALFDAY_TIME = this.datePipe.transform(this.data.HALFDAY_TIME, "HH:mm:ss");

    this.drawerVisible = true;

  

    // this.api.getAllShifts(0, 0, '', '', '').subscribe(data => {
    //   this.shifts = data['data'];

    // }, err => {
    //   console.log(err);
    // });


  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }


  drawerClose1(): void {
    this.search(false);
    
    this.drawerVisible1 = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  shiftID
  shiftMapping(data: ShiftMaster) {
    this.message.info("Please Apply Filter", "")
    // this.api.getShiftMappingData(data.ID).subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.drawerData2 = data['data'];
    console.log(data);
    this.shiftID = data.ID
    //   }

    // }, err => {
    //   console.log(err);
    // });
    // Bugs Resolved=> 1) Incentive report memo view 
    //                 2) Image upload for notification in HRMS
    //                 3) Shift Master 

    this.drawerTitle1 = "Shift Mapping";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }

}
