import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

// import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd';
// import { ApiService } from '../Service/api.service';
import { ApiService } from 'src/app/Service/api.service';
// import { memoMaster } from '../Models/memo';
// import { advanceMaster } from '../Models/advanceMaster';
// import { memoDetails } from '../Models/memoDetails';
// import { questionaryMaster } from '../Models/questionaryMaster';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { memoSignatureDetails } from '../Models/memoSignatureDetails';
import { memoMaster } from 'src/app/Models/memo';
import { advanceMaster } from 'src/app/Models/advanceMaster';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { memoDetails } from 'src/app/Models/memoDetails';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import html2pdf from 'html2pdf.js';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({

  selector: 'app-memoform',
  templateUrl: './memoform.component.html',
  styleUrls: ['./memoform.component.css']
})
export class MemoformComponent implements OnInit {

  @Input() drawerClose1: Function;
  @Input() isSpinning1: boolean;
  // @Input() drawerClose12:Function;
  @Input() search: Function;
  @Input() search1: Function;
  @Input() formname: String;
  @Input() data: memoMaster = new memoMaster()
  @Input() dataid: any
  @Input() memoDetailsArray = new memoDetails()
  @Input() questionary = new questionaryMaster()
  @Input() advanceArray = [new advanceMaster()]
  @Input() memoSignature = new memoSignatureDetails()
  @Input() quotedPrice: any
  @Input() sumofall: number
  @Input() sum3: number
  @Input() sum4: number
  @Input() sum5: number
  @Input() sum6: number
  @Input() sum7: number
  @Input() sum8: number
  @Input() sum9: number
  @Input() sumoffall2: number
  @Input() sdfsdf5: number
  @Input() sdfsdf4: number
  @Input() acc_remark: any
  @Input() acc_remark1: any
  @Input() iid: number
  @Input() memo13 = new memoMaster()
  @Input() memo12 = new memoMaster()
  @Input() memo14 = new memoMaster()
  @Input() memo15 = new memoMaster()
  @Input() memo16 = new memoMaster()
  @Input() forassaddition: any
  @Input() date24:any
  @Input() discount1 
  @Input() exchange
  @Input()SAMBHADH_SCHEME
  saleHierarchy = []

  saleHierarchy1 = []
  saleHierarchy2 = []
  saleHierarchy3 = []
  saleHierarchy4 = []
  saleHierarchy5 = []

  clusterId = Number(this.cookie.get('clusterId'));
  ACC_REMARK
  VIN_REMARK: string
  // acc_remark1
  roleId = Number(this.cookie.get('roleId'));

  advanceMaster = [new advanceMaster()]

  memo: memoMaster = new memoMaster()

  memoDetails: memoDetails = new memoDetails();
  imgUrl: any
  // questionary:questionary=new questionaryMaster()

  // memoDetailsArray=[new memoDetails]

  // advanceArray=[new advanceMaster]
  isApproveVisible11 = false

  constructor(private domSanitizer: DomSanitizer, private cookie: CookieService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.getData();
    // this.F();this.
    this.imgUrl = this.api.retriveimgUrl
  }

  today21 = new Date()

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today21) > 0;

  switchValue = false;
  switchValue1 = true;

  options: [] = [];

  // quotedPrice:any;

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  isVisible = false;
  date = null;
  isOk = false;


  onChange($event: string[]): void {
    console.log($event);
  }

  ID



  advanceArray1() {

    var array = []
    if (this.advanceArray != undefined) {

      for (let i = 0; i <= (this.advanceArray.length - 1); i++) {

        array.push(1)

        if (i + 1 == this.advanceArray.length - 1) {

          // console.log(array, "ok");


          return array
        }


      }
    }


  }



  sanatizeUrl(generatedImageUrl): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(generatedImageUrl);
  }


  onPrint() {
    const printContents = document.getElementById('report_id').innerHTML;

    const originalContents = document.body.innerHTML;
    // var newWin = window.open('', 'Print-Window', 'width=1200,height=700');
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload();

  }



  public generatePDF() {


    // this.displayRemark = 'displayTA';
    // this.displayTA = 'displayRemark';


    // this.isButtonSpinning = true
    var i = 0;
    var date = new Date();
    var datef = this.datePipe.transform(date, "yyyy-MM-dd");
    var dates = this.datePipe.transform(date, "h:mm:ss a");
    var data = document.getElementById('report_id');
    // html2pdf().from(data).set({ margin: [16, 13, 12, 13], pagebreak: { mode: ['avoid-all', 'css', 'legecy'] }, jsPDF: { unit: 'mm', format: 'legal', orientation: 'portrait' } }).toPdf().get('pdf').then(function (pdf) {

    //   var totalPages = pdf.internal.getNumberOfPages();

    //   for (i = 1; i <= totalPages; i++) {
    //     pdf.setPage(i);
    //     pdf.setFontSize(12);
    //     pdf.setTextColor(150);
    //     pdf.text(i.toString(), pdf.internal.pageSize.getWidth() / 2, 10);
    //   }
    // }).save(this.formname + "_" + datef + "_" + dates + '.pdf');

    // this.isButtonSpinning = false;

    // var data = document.getElementById('contentToConvert');
    // var HTML_Width = data.offsetWidth;
    // var HTML_Height = data.offsetHeight;
    // var top_left_margin = 20;
    // var PDF_Width = HTML_Width + (top_left_margin * 2);
    // var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 3);
    // var canvas_image_width = HTML_Width;
    // var canvas_image_height = HTML_Height;

    // var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // html2canvas(data,  { allowTaint: true }).then(canvas => {
    //   canvas.getContext('2d');
    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height-5]);
    //   pdf.setFont('Arial Unicode');
    //   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

    //   for (var i = 1; i <= totalPDFPages; i++) {
    //     pdf.addPage(PDF_Width, PDF_Height);
    //     pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    //   }
    //   pdf.save('form.pdf'); // Generated PDF  
    // });
  }






  close() {
    // console.log(this.acc_remark);

    // this.isVisible=false;

    // this.sumofall = 0
    // this.sumoffall2 = 0
    // this.acc_remark=''
    this.drawerClose1();
    // this.getData(this.memoid);
  }

  save() {
    this.onPrint()
  }

  INVOICE_NO: string = "";
  emproledata = []
  iddd: any
  tempData = new Object();
  userId = this.cookie.get('userId')
  isApproveModalConfirmLoading: boolean = false;
  isApproveModalConfirmLoading12: boolean = false;
  isRejectModalConfirmLoading: boolean = false;
  isRejectModalConfirmLoadingAS = false
  isApproveVisible: boolean = false;
  isRejectVisible: boolean = false;
  REMARK: string = "";
  REMARK_ASM: string = ""
  isApproveModalConfirmLoading123: boolean = false

  setQuotationStatus(quotationID: number, status: string) {
    this.api.setQuotationStatus(quotationID, status).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Quotation Updated Successfully", "");
        // this.isRejectVisible = false;
      } else {
        this.message.error("Quotation Updation Failed", "");
        // this.isRejectVisible = true;
      }
    });
  }


  openApproveModal() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.INVOICE_NO = this.data.INVOICE_NO
    this.isApproveVisible = true;
    this.get(this.iid)
    // this.drawerClose1()
    // this.search1()
  }
  openRejectModal() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.REMARK = "";
    this.isRejectVisible = true;
    this.get(this.iid)
  }

  isRejectVisibleAS = false
  openRejectModalAS() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.ACC_VERIFICATION_REMARK = "";
    this.isRejectVisibleAS = true;
    this.get(this.iid)
  }
  isRejectVisibleHP = false
  openRejectModalHP() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.ACC_VERIFICATION_REMARK = "";
    this.isRejectVisibleHP = true;
    this.get(this.iid)
  }


  get(ID) {
    this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memoSignature = data['data'][0];
        console.log(this.memoSignature, "this.memoSignature");



      }
    });

  }
  handleApproveCancel() {
    this.isApproveVisible = false;
    this.isApproveModalConfirmLoading = false;
  }


  handleRejectCancelAS() {
    this.isRejectVisibleAS = false;
    this.isRejectModalConfirmLoadingAS = false;
  }
  isRejectModalConfirmLoadingHP: boolean
  handleRejectCancelHP() {
    this.isRejectVisibleHP = false;
    this.isRejectModalConfirmLoadingHP = false;
  }
  isRejectVisiblevin = false
  isRejectModalConfirmLoadingvin = false
  handleRejectCancelvin() {
    this.isRejectVisiblevin = false;
    this.isRejectModalConfirmLoadingvin = false;
  }

  handleRejectCancel() {
    this.isRejectVisible = false;
    this.isRejectModalConfirmLoading = false;
  }

  handleRejectOk() {
    let isOk = true;

    if (this.REMARK == undefined || this.REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["APPROVER_REMARK"] = this.REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";
      this.tempData["VIN_NUMBER"] = this.tempData["VIN_NUMBER"]
      this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
      this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
      this.tempData["DATE"] = this.tempData["DATE"]
      this.tempData["REMARK"] = "";
      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.memoSignature.ACCOUNT_ID = this.userId

          this.memoSignature.ACCOUNT_DATETIME = this.datePipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd HH:mm:ss")
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");

            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");

          this.message.success("Remark Updated Successfully", "");
          this.isRejectVisible = false;
          this.drawerClose1();

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          // this.search(false);
        }
      });
    }
  }

  handleRejectOkvin() {
    let isOk = true;

    if (this.VIN_REMARK == undefined || this.VIN_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["APPROVER_REMARK"] = this.VIN_REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";
      this.tempData["VIN_NUMBER"] = this.tempData["VIN_NUMBER"]
      this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
      this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
      this.tempData["DATE"] = this.tempData["DATE"]
      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {

          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
              this.isRejectVisiblevin = false;
              this.drawerClose1()

            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");

          this.message.success("Remark Updated Successfully", "");
          // this.isRejectVisible = false;
          // this.search(false);

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          // this.search(false);
        }


      });
    }
  }
  ACCOUNT_DATETIME = new Date
  handleApproveOk() {
    let isOk = true;

    if (this.INVOICE_NO == undefined || this.INVOICE_NO.toString() == '') {
      isOk = false;
      this.message.error('Please enter valid Invoice No.', '');
    }

    if (isOk) {
      this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND EMPLOYEE_ID=' + this.api.userId).subscribe(data => {

        this.emproledata = data['data'].filter((item: any) => item.ROLE_ID == 38);
        this.iddd = this.emproledata[0]['REPORTING_EMP_ID']
        console.log(this.iddd);
      });
      this.isApproveModalConfirmLoading = true;
      this.tempData["INVOICE_NO"] = this.INVOICE_NO;
      this.data.DATE = this.tempData["DATE"]
      this.tempData['APPROVED_DATETIME'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss ')
      this.tempData["APPROVER_ID"] = this.iddd;
      this.tempData["STATUS"] = "AP";
      this.tempData["VIN_NUMBER"] = this.tempData["VIN_NUMBER"]

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isApproveModalConfirmLoading = false;
          this.memoSignature.ACCOUNT_DATETIME = this.datePipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd HH:mm:ss")
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");

          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");

            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });

          this.message.success("Invoice No. Updated Successfully", "");
          this.isApproveVisible = false;
          this.drawerClose1();

          // this.search(false);



        } else {
          this.isApproveModalConfirmLoading = false;
          this.message.error("Invoice No. Updation Failed", "");
          // this.search(false);
        }
      });




    }
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  handleApproveCancel11() {
    this.isApproveVisible11 = false;
    this.ACC_REMARK = ''
  }
  isApproveVisible111: boolean
  openApproveModal11() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.INVOICE_NO = "";
    this.isApproveVisible11 = true;
    // this.get(data.ID)
    this.getsalesHierarchy3()

  }
  isApproveVisibleSH = false
  isApproveVisibleSM = false
  isApproveModalConfirmLoadingSH = false
  isApproveModalConfirmLoadingSM = false
  SH_REMARK = ''
  SM_REMARK=''

  isRejectVisibleSM = false
  isRejectModalConfirmLoadingSM = false

  openApproveModalsahead() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.APPROVER_ID = [];
    this.isApproveVisibleSH = true;
    this.getsalesHierarchySH()
    this.get12(this.data.ID)
    this.get(this.data.ID)
  }
  handleApproveCancelSH() {
    this.isApproveVisibleSH = false;
    this.drawerClose1();
  }

  handleApproveOkSH() {
    let isOk = true;
    if (this.APPROVER_ID == "" || this.APPROVER_ID == undefined) {
      this.message.error('Please select General Manager Or Accountant ', '')

      //   // }
    }


    else {
      if (isOk) {


        this.isApproveModalConfirmLoadingSH = true;
        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"] = this.SH_REMARK
        this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
        this.tempData["SH_REMARK"] = this.SH_REMARK
        this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
        this.tempData["DATE"] = this.datePipe.transform(this.today21, "yyyy-MM-dd HH:mm:ss")
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
           
            // this.isApproveModalConfirmLoading = true;


            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
         
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"] = 0
            this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"] = this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
            this.tempData["SH_REMARK"] = this.SH_REMARK
            this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
            this.tempData["REMARK"] = this.ACC_VERIFICATION_REMARK
            this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
            this.tempData["DATE"] = this.date24
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
            this.tempData['IS_TCS5'] = this.tempData['IS_TCS5']
            this.tempData['IS_CORPORATE'] = this.tempData['IS_CORPORATE']
            this.tempData['IS_SAMBHADH_SCHEME'] = this.tempData['IS_SAMBHADH_SCHEME']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.isApproveModalConfirmLoadingSH = false;
                this.memoSignature.MEMO_ID = successCode['data']
                this.memoSignature.SALES_HEAD_ID = this.userId
                this.memoSignature.SALES_HEAD_DATETIME = this.datePipe.transform(this.SALES_HEAD_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }

                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.isApproveVisibleSH = false;
                this.drawerClose1();
                // this.search(false);



              } else {
                this.isApproveModalConfirmLoadingSH = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });


          } else {
            this.isApproveModalConfirmLoadingSH = false;
            this.message.error("Updation Failed", "");

          }
        });
      }
    }







  }

  openRejectModalsahead() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.SH_REMARK = "";

    this.isRejectVisibleSH = true;
    this.get(this.iid)
    // this.drawerClose1();
  }
  isRejectVisibleSH = false
  isRejectModalConfirmLoadingSH = false
  handleRejectOkSH() {
    let isOk = true;

    if (this.SH_REMARK == undefined || this.SH_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    else {
      if (isOk) {
        this.isRejectModalConfirmLoadingSH = true;
        this.tempData["APPROVER_REMARK"] = this.SH_REMARK;
        this.tempData["APPROVER_ID"] = this.api.userId;
        this.tempData["STATUS"] = "RJ";
        this.tempData["VIN_NUMBER"] = this.tempData["VIN_NUMBER"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["DATE"] = this.tempData["DATE"]
        this.tempData["REMARK"] = "";
        this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isRejectModalConfirmLoadingSH = false;
            this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
            this.message.success("Remark Updated Successfully", "");
            this.isRejectVisibleSH = false;
            this.drawerClose1()

          } else {
            this.isRejectModalConfirmLoadingSH = false;
            this.message.error("Remark Updation Failed", "");

          }
        });
      }
    }


  }

  // handleApproveOk11() {
  //   let isOk = true;

  //   //  if (this.APPROVER_ID == undefined || this.APPROVER_ID == 0) {
  //   //   isOk = false;
  //   //   this.message.error('Please select ACS', '');
  //   // }

  //   if (this.ACC_REMARK == undefined || this.ACC_REMARK == '') {
  //     isOk = false;
  //     this.message.error('Please enter remark.', '');
  //   }



  //   else{
  //     if (isOk) {
  //       this.isApproveModalConfirmLoading = true;
  //       // this.tempData["INVOICE_NO"] = this.INVOICE_NO;
  //       // this.tempData["APPROVER_ID"] = this.api.userId;
  //       this.tempData["STATUS"] = "RQ";
  //       this.tempData["ACC_REMARK"] = this.ACC_REMARK
  //       this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
  //       // this.memoSignature
  //       this.tempData["VIN_NUMBER"] =this.tempData["VIN_NUMBER"] 
  //       this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
  //         if (successCode['code'] == 200) {
  //           this.isApproveModalConfirmLoading = false;
  //           // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
  //          
  //           this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
  //             if (successCode['code'] == 200) {
  //               this.message.success("Account Signature Updated Successfully", "");

  //             } else {
  //               this.message.error("Account Signature Updated  Failed", "");
  //             }
  //           });


  //           this.isApproveVisible11 = false;
  //           this.drawerClose1()
  //           // this.search(false);



  //         } else {
  //           this.isApproveModalConfirmLoading = false;
  //           this.message.error("Updation Failed", "");
  //           // this.search(false);
  //         }
  //       });




  //     }
  //   }


  // }


  handleApproveOk11() {
    let isOk = true;

    if (this.APPROVER_ID == undefined || this.APPROVER_ID == 0) {
      isOk = false;
      this.message.error('Please select ACS', '');
    }

    else if (this.ACC_REMARK == undefined || this.ACC_REMARK == '') {
      isOk = false;
      this.message.error('Please enter remark.', '');
    }



    else {
      if (isOk) {
        this.isApproveModalConfirmLoading = true;
        // this.tempData["INVOICE_NO"] = this.INVOICE_NO;
        // this.tempData["APPROVER_ID"] = this.api.userId;
        // this.tempData["STATUS"] = "RQ";
        this.tempData["ACC_REMARK"] = this.ACC_REMARK
        this.cookie.set('ACC_REMARK', this.ACC_REMARK)
        this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
        this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
        this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
        this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
        this.tempData["STATUS"] = "FW";
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
        this.tempData["DATE"] = this.datePipe.transform(this.today21, "yyyy-MM-dd HH:mm:ss")
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.VINisApproveModalConfirmLoading = false;
            // this.isApproveModalConfirmLoading = true;


            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["REMARK"] = this.VIN_REMARK;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"] = 0
            this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"] = this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
            this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
            this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
            this.tempData["APPROVER_REMARK"] = this.ACC_REMARK
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
            this.tempData["DATE"] = this.date24
            this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
            this.tempData['IS_TCS5'] = this.tempData['IS_TCS5']
            this.tempData['IS_CORPORATE'] = this.tempData['IS_CORPORATE']
            this.tempData['IS_SAMBHADH_SCHEME'] = this.tempData['IS_SAMBHADH_SCHEME']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.VINisApproveModalConfirmLoading = false;

                this.memoSignature.ACCOUNT_ID = this.userId

                this.memoSignature.ACCOUNT_DATETIME = this.datePipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                      if (successCode['code'] == 200) {
                        this.message.success('memo Signature Update sucessfully..', "")
                      }

                    }, err => {
                      if (err['ok'] == false)
                        this.message.error("Server Not Found", "");
                    });

                  } else {
                    this.message.error("Account Signature Updated  Failed", "");
                  }
                });
                this.memoSignature.MEMO_ID = successCode['data']
                // this.memoSignature.HPROMISE_ID = this.userId
                // this.memoSignature.HPROMISE_DATETIME=this.datePipe.transform(this.HPROMISE_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.memoSignature.ACCOUNT_ID = this.userId

                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Memo Approve Updated Successfully", "");
                this.isApproveModalConfirmLoading = false;
                this.isApproveVisible11 = false

                // this.isApproveVisibleHP = false;
                this.drawerClose1();
                // this.search(false);



              } else {
                this.VINisApproveModalConfirmLoading = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });


          } else {
            this.VINisApproveModalConfirmLoading = false;
            this.message.error("Updation Failed", "");

          }
        });




      }
    }


  }
  orgId = this.cookie.get("orgId");
  salesExecutives = []
  //for Hp 
  getsalesHierarchy() {

    
    // this.api.getsalesHierarchy('AND EMPLOYEE_ID= ' + this.cookie.get('userId')).subscribe(successCode => {
    //   if (successCode['code'] == 200) {
    //     console.log(successCode['data'],"rutuja");
    //     this.saleHierarchy = successCode['data']
    //   }
    // })

    // this.api
    //   .getAllemployeeMaster(
    //     0,
    //     0,
    //     "NAME",
    //     "asc",
    //     " AND STATUS=1 AND ORG_ID=" +
    //     this.orgId +
    //     " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
    //     this.cookie.get("orgId") +
    //     " AND ROLE_ID=37)"
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data["code"] == 200) {
    //         this.saleHierarchy = data["data"];
    //       }
    //     },
    //     (err) => {
    //       if (err["ok"] == false) this.message.error("Server Not Found", "");
    //     }
    //   );

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID= 37' + " AND CLUSTER_ID =" + this.clusterId ).subscribe(data => {
        
          if (data["code"] == 200) {
            this.saleHierarchy = data["data"];
          }
       
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      })
    
  }

  getsalesHierarchySH() {


    
    // this.api.getsalesHierarchy('AND EMPLOYEE_ID= ' + this.cookie.get('userId')).subscribe(successCode => {
    //   if (successCode['code'] == 200) {
    //     console.log(successCode['data'],"rutuja");
    //     this.saleHierarchy = successCode['data']
    //   }
    // })

    //   this.api
    //     .getAllemployeeMaster(
    //       0,
    //       0,
    //       "NAME",
    //       "asc",
    //       " AND STATUS=1 AND ORG_ID=" +
    //       this.orgId +
    //       " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
    //       this.cookie.get("orgId") +
    //       " AND ROLE_ID IN (30,13))"
    //     )
    //     .subscribe(
    //       (data) => {
    //         if (data["code"] == 200) {
    //           this.saleHierarchy5 = data["data"];
    //         }
    //       },
    //       (err) => {
    //         if (err["ok"] == false) this.message.error("Server Not Found", "");
    //       }
    //     );
    // }

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID IN (30,13)'+" AND CLUSTER_ID =" + this.clusterId ).subscribe(data => {
       
          if (data["code"] == 200) {
            this.saleHierarchy5 = data["data"];
            console.log(this.saleHierarchy5,"this.saleHierarchy5")
          }
        
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      });

  }

  // for VIN
  getsalesHierarchy1() {


    // this.api
    //   .getAllemployeeMaster(
    //     0,
    //     0,
    //     "NAME",
    //     "asc",
    //     " AND STATUS=1 AND ORG_ID=" +
    //     this.orgId +
    //     " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
    //     this.cookie.get("orgId") +
    //     " AND ROLE_ID=34)"
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data["code"] == 200) {
    //         this.saleHierarchy1 = data["data"];
    //       }
    //     },
    //     (err) => {
    //       if (err["ok"] == false) this.message.error("Server Not Found", "");
    //     }
    //   );

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID= 34'+" AND CLUSTER_ID =" + this.clusterId ).subscribe(data => {
       
          if (data["code"] == 200) {
            this.saleHierarchy1 = data["data"];
          }
       
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      })
  }
  // setTimeout( ()=>{
  //   console.log('works')
  //   }, 5000)
  // for ACV
  getsalesHierarchy2() {


    // this.api
    //   .getAllemployeeMaster(
    //     0,
    //     0,
    //     "NAME",
    //     "asc",
    //     " AND STATUS=1 AND ORG_ID=" +
    //     this.orgId +
    //     " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
    //     this.cookie.get("orgId") +
    //     " AND ROLE_ID=36)"
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data["code"] == 200) {
    //         this.saleHierarchy2 = data["data"];
    //       }
    //     },
    //     (err) => {
    //       if (err["ok"] == false) this.message.error("Server Not Found", "");
    //     }
    //   );

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID =36' + " AND CLUSTER_ID =" + this.clusterId ).subscribe(data => {
          if (data["code"] == 200) {
            this.saleHierarchy2 = data["data"];
          }
       
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      })
  }

  //for ACcount
  getsalesHierarchy3() {


    // this.api
    //   .getAllemployeeMaster(
    //     0,
    //     0,
    //     "NAME",
    //     "asc",
    //     " AND STATUS=1 AND ORG_ID=" +
    //     this.orgId +
    //     " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
    //     this.cookie.get("orgId") +
    //     " AND ROLE_ID=38)"
    //   )
    //   .subscribe(
    //     (data) => {
    //       if (data["code"] == 200) {
    //         this.saleHierarchy3 = data["data"];
    //       }
    //     },
    //     (err) => {
    //       if (err["ok"] == false) this.message.error("Server Not Found", "");
    //     }
    //   );

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", ' AND ROLE_ID =38' + " AND CLUSTER_ID =" + this.clusterId ).subscribe(data => {
      
          if (data["code"] == 200) {
            this.saleHierarchy3 = data["data"];
          }
      
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      })
  }

  openApproveModal12(data: memoMaster) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisible12 = true;
    this.getsalesHierarchy2()
    this.get12(data.ID)
    this.get(data.ID)



  }

  openApproveModalHP(data: memoMaster) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisibleHP = true;
    this.getsalesHierarchy()
    this.get12(data.ID)
    this.get(data.ID)

  }

  openApproveModalVIN(data: memoMaster) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.VINApproveVisible = true;
    this.getsalesHierarchy1()
    this.get12(data.ID)
    this.get(data.ID)

  }
  openRejectModalVIN() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.VIN_REMARK = "";

    this.isRejectVisiblevin = true;
    this.get(this.iid)
    this.drawerClose1();
  }


  get12(ID) {
    this.api.getMemoDetails1(this.pageIndex, this.pageSize, "", "", "AND MEMO_MASTER_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {
        // this.totalRecords = data['count'];
        this.memoDetailsArray = data['data'];
        console.log(this.memoDetailsArray);

      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  APPROVER_ID
  userid = this.cookie.get('userId')
  ACC_VERIFICATION_REMARK: string = ""
  isApproveVisible12: boolean = false;
  DATE = sessionStorage.getItem('DATE')
  handleApproveOk12() {
    let isOk = true;

    if (this.APPROVER_ID == "" || this.APPROVER_ID == undefined) {
      this.message.error('Please select VIN', '')

      //   // }
    }


    else {
      if (isOk) {


        this.isApproveModalConfirmLoading123 = true;
        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"] = this.ACC_VERIFICATION_REMARK
        this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
        this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
        this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
        this.tempData["DATE"] = this.datePipe.transform(this.today21, "yyyy-MM-dd HH:mm:ss")
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            // this.isApproveModalConfirmLoading12 = false;
            // this.isApproveModalConfirmLoading = true;


            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"] = 0
            this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"] = this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
            this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
            this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
            this.tempData["REMARK"] = this.ACC_VERIFICATION_REMARK
            this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
            console.log(this.DATE,"this.DATE")
            if(this.tempData["STATUS"] == "RQ"){
              this.tempData["DATE"] = this.date24
            }
           console.log(this.tempData["DATE"]);
           
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
            this.tempData['IS_TCS5'] = this.tempData['IS_TCS5']
            this.tempData['IS_CORPORATE'] = this.tempData['IS_CORPORATE']
            this.tempData['IS_SAMBHADH_SCHEME'] = this.tempData['IS_SAMBHADH_SCHEME']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.isApproveModalConfirmLoading123 = false;
                this.memoSignature.MEMO_ID = successCode['data']
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }

                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.isApproveVisible12 = false;
                this.drawerClose1();
                // this.search(false);



              } else {
                this.isApproveModalConfirmLoading = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });


          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("Updation Failed", "");

          }
        });
      }
    }







  }

  handleRejectCancelSH() {
    this.isRejectVisibleSH = false;
    this.isRejectModalConfirmLoadingSH = false;
  }

  handleApproveCancel12() {
    this.isApproveVisible12 = false;
    this.drawerClose1();
  }


  handleApproveCancel122() {
    this.isApproveVisible12 = false;
    // this.drawerClose1();
  }



  handleRejectOkAS() {
    let isOk = true;

    if (this.ACC_VERIFICATION_REMARK == undefined || this.ACC_VERIFICATION_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    else {
      if (isOk) {
        this.isRejectModalConfirmLoading = true;
        this.tempData["APPROVER_REMARK"] = this.ACC_VERIFICATION_REMARK;
        this.tempData["APPROVER_ID"] = this.api.userId;
        this.tempData["STATUS"] = "RJ";
        this.tempData["VIN_NUMBER"] = ""
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["DATE"] = this.tempData["DATE"]
        this.tempData["REMARK"] = "";
        this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isRejectModalConfirmLoadingAS = false;
            this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
            this.message.success("Remark Updated Successfully", "");
            this.isRejectVisibleAS = false;
            this.drawerClose1()

          } else {
            this.isRejectModalConfirmLoadingAS = false;
            this.message.error("Remark Updation Failed", "");

          }
        });
      }
    }


  }


  handleRejectOkHP() {
    let isOk = true;

    if (this.HP_REMARK == undefined || this.HP_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    else {
      if (isOk) {
        this.isRejectModalConfirmLoading = true;
        this.tempData["APPROVER_REMARK"] = this.HP_REMARK;
        this.tempData["APPROVER_ID"] = this.api.userId;
        this.tempData["STATUS"] = "RJ";
        this.tempData["VIN_NUMBER"] = this.tempData["VIN_NUMBER"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["DATE"] = this.tempData["DATE"]
        this.tempData["REMARK"] = "";
        this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isRejectModalConfirmLoadingHP = false;
            this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
            this.message.success("Remark Updated Successfully", "");
            this.isRejectVisibleHP = false;
            this.drawerClose1()

          } else {
            this.isRejectModalConfirmLoadingHP = false;
            this.message.error("Remark Updation Failed", "");

          }
        });
      }
    }

  }




  HP_REMARK: string = ""
  isApproveVisibleHP: boolean
  isApproveModalConfirmLoadingHP: boolean = false
  HPROMISE_DATETIME = new Date()
  SALES_HEAD_DATETIME = new Date()

  // this.datePipe.transform(this.HPROMISE_DATETIME, "yyyy-MM-dd HH:mm:ss")

  dateok = new Date()
  handleApproveCancelHP() {
    this.isApproveVisibleHP = false;
    this.APPROVER_ID = null
    this.HP_REMARK = ""
    this.drawerClose1()
  }

  handleApproveOkHP() {
    let isOk = true;

    if (this.APPROVER_ID == undefined || this.APPROVER_ID == 0) {
      isOk = false;
      this.message.error('Please select Sales Executive', '');
    }


    else {
      if (isOk) {

        this.isApproveModalConfirmLoadingHP = true;

        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"] = this.HP_REMARK
        this.tempData["VIN_NUMBER"] = ""
        this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
        this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["DATE"] = this.datePipe.transform(this.today21, "yyyy-MM-dd HH:mm:ss")
        this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
        // this.tempData["REMARK"] = this.HP_REMARK;
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            // this.isApproveModalConfirmLoadingHP = false;
            // this.isApproveModalConfirmLoading = true;


            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["REMARK"] = this.HP_REMARK;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"] = 0
            this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"] = this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
            this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
            this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
            this.tempData["APPROVER_REMARK"] = ""
            this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
            this.tempData["DATE"] = this.date24
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']

            this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
            this.tempData['IS_TCS5'] = this.tempData['IS_TCS5']
            this.tempData['IS_CORPORATE'] = this.tempData['IS_CORPORATE']
            this.tempData['IS_SAMBHADH_SCHEME'] = this.tempData['IS_SAMBHADH_SCHEME']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.isApproveModalConfirmLoadingHP = false;
                this.memoSignature.MEMO_ID = successCode['data']
                this.memoSignature.HPROMISE_ID = this.userId

                this.memoSignature.HPROMISE_DATETIME = this.datePipe.transform(this.HPROMISE_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }

                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.handleApproveCancelHP()
                // this.isApproveVisibleHP = false;
                // this.drawerClose1();
                // this.search(false);



              } else {
                this.isApproveModalConfirmLoadingHP = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });


          } else {
            this.isApproveModalConfirmLoadingHP = false;
            this.message.error("Updation Failed", "");

          }
        });
      }
    }

  }
  VINApproveVisible: boolean
  VINisApproveModalConfirmLoading: boolean




  VINhandleApproveCancel() {
    this.VINApproveVisible = false;
    this.APPROVER_ID = null
    this.VIN_REMARK = ""
    this.VIN_NUMBER = ''
    this.drawerClose1()
  }

  VIN_NUMBER: string = ""

  VINhandleApproveOk() {
    let isOk = true;
    if (this.APPROVER_ID == null || this.APPROVER_ID == 0) {
      this.message.error('Please select Sales Head', '')

      //   // }
    }

    else if (this.VIN_NUMBER == "" || !this.VIN_NUMBER == null) {
      this.message.error('Please enter VIN NO.', '')

    }

    else {
      if (isOk) {


        this.VINisApproveModalConfirmLoading = true;
        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"] = this.VIN_REMARK
        this.tempData["VIN_NUMBER"] = this.VIN_NUMBER
        this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
        this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
        this.tempData["DATE"] = this.datePipe.transform(this.today21, "yyyy-MM-dd HH:mm:ss")
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            // this.VINisApproveModalConfirmLoading = false;
            // this.isApproveModalConfirmLoading = true;


            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["REMARK"] = this.VIN_REMARK;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"] = 0
            this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"] = this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"] = this.VIN_NUMBER
            this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
            this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
            this.tempData["APPROVER_REMARK"] = this.VIN_REMARK
            this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
            this.tempData["DATE"] = this.date24
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
            this.tempData['IS_TCS5'] = this.tempData['IS_TCS5']
            this.tempData['IS_CORPORATE'] = this.tempData['IS_CORPORATE']
            this.tempData['IS_SAMBHADH_SCHEME'] = this.tempData['IS_SAMBHADH_SCHEME']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.VINisApproveModalConfirmLoading = false;
                this.memoSignature.MEMO_ID = successCode['data']
                // this.memoSignature.HPROMISE_ID = this.userId

                // this.memoSignature.HPROMISE_DATETIME=this.datePipe.transform(this.HPROMISE_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }

                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.VINApproveVisible = false;

                // this.isApproveVisibleHP = false;
                this.drawerClose1();
                // this.search(false);



              } else {
                this.VINisApproveModalConfirmLoading = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });


          } else {
            this.VINisApproveModalConfirmLoading = false;
            this.message.error("Updation Failed", "");

          }
        });


      }
    }

    // this.VIN_NUMBER=''
    // this.VIN_REMARK=''
    // this.APPROVER_ID=null


  }



  MEMO_ID: any
  isregisterVisible = false
  // openregistartionModal(data: any) {

  //   this.isregisterVisible = true;
  //   this.get(this.iid)
  //   // this.drawerClose1()
  //   // this.search1()
  // }
  handleregistereOk() {
    let isOk = true;
    this.REG_DATE = this.datePipe.transform(this.REG_DATE, "yyyy-MM-dd")

    if (this.REG_NO == undefined || this.REG_NO.toString() == '') {
      isOk = false;
      this.message.error('Please enter valid Vehicle No.', '');
    } else if (!this.vehiclepattern.test(this.REG_NO.toString())) {
      isOk = false;
      this.message.error("Please Enter Valid Vehicle No.", '');
    } else if (this.REG_DATE == undefined || this.REG_DATE.toString() == '') {
      isOk = false;
      this.message.error('Please enter valid Requested Date', '');
    } else if (isOk) {
      this.isApproveModalConfirmLoading = true;

      if (this.INSURANCE_DATE == '-' && this.REG_NO1 == '-' && this.CR_DATE == '-') {
        this.api.createregiDetails(this.REG_REMARK, this.REG_DATE, this.REG_NO, this.MEMO_ID, this.QUOTATION_ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading = false;
            this.message.success("Registration Updated Successfully", "");
            this.isregisterVisible = false;
            this.REG_DATE = ''
            this.REG_NO = ''
            this.REG_REMARK = ''
            this.drawerClose1();

          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("Registration Updation Failed", "");
            // this.search(false);
          }
        });
      } else {
        this.api.updateregiDetails(this.REG_REMARK, this.REG_DATE, this.REG_NO, this.MEMO_ID, this.QUOTATION_ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading = false;
            this.message.success("Registration Updated Successfully", "");
            this.isregisterVisible = false;
            this.REG_DATE = ''
            this.REG_NO = ''
            this.REG_REMARK = ''
            this.drawerClose1();

          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("Registration Updation Failed", "");
            // this.search(false);
          }
        });
      }





    }
  }
  CR_REMARK: any
  CR_DATE: any
  handleCReOk() {
    let isOk = true;
    this.CR_DATE = this.datePipe.transform(this.CR_DATE, "yyyy-MM-dd")

    if (this.CR_DATE == undefined || this.CR_DATE.toString() == '') {
      isOk = false;
      this.message.error('Please enter valid Delivery Date', '');
    }

    if (isOk) {
      this.isApproveModalConfirmLoading = true;





      if (this.INSURANCE_DATE == '-' && this.REG_NO1 == '-' && this.CR_DATE1 == '-') {
        this.api.createCRDetails(this.CR_DATE, this.CR_REMARK, this.MEMO_ID, this.QUOTATION_ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading = false;
            this.message.success("CR Updated Successfully", "");
            this.isCRVisible = false;
            this.drawerClose1();
            this.CR_DATE = ''
            this.CR_REMARK = ''
          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("CR Updation Failed", "");
            // this.search(false);
          }
        });
      } else {
        this.api.updatecrDetails(this.CR_DATE, this.CR_REMARK, this.MEMO_ID, this.QUOTATION_ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading = false;
            this.message.success("CR Updated Successfully", "");
            this.isCRVisible = false;
            this.drawerClose1();
            this.CR_DATE = ''
            this.CR_REMARK = ''
          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("CR Updation Failed", "");
            // this.search(false);
          }
        });
      }

    }
  }

  handleregisterCancel() {
    this.isregisterVisible = false;
    this.isApproveModalConfirmLoading = false;
    this.REG_DATE = ''
    this.REG_NO = ''
    this.REG_REMARK = ''
  }
  handleCRCancel() {
    this.isCRVisible = false;
    this.isApproveModalConfirmLoading = false;
    this.CR_DATE = ''
    this.CR_REMARK = ''
  }
  INSURANCE_DATE: any
  REG_NO1: any
  QUOTATION_ID: any
  openRegisterModal(data: any) {
    this.MEMO_ID = data.ID
    this.QUOTATION_ID = data.QUOTATION_ID
    this.isregisterVisible = true;
    this.REG_NO1 = data.REG_NO
    this.CR_DATE = data.CR_DATE
    this.INSURANCE_DATE = data.INSURANCE_DATE

  }
  isCRVisible = false
  CR_DATE1: any
  openCRModal(data: any) {
    this.MEMO_ID = data.ID
    this.isCRVisible = true;
    this.REG_NO1 = data.REG_NO
    this.QUOTATION_ID = data.QUOTATION_ID
    this.CR_DATE1 = data.CR_DATE
    this.INSURANCE_DATE = data.INSURANCE_DATE
  }
  REG_NO: any
  REG_REMARK: any
  REG_DATE: any

  vehiclepattern = /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/

  INSURANCE_REMARK: any
  INSURANCE_DATE1: any
  isInsuranceVisible = false
  handleInsuranceCancel() {
    this.isInsuranceVisible = false;
    this.isApproveModalConfirmLoading = false;
    this.INSURANCE_DATE = ''
    this.INSURANCE_REMARK = ''
  }
  today2 = new Date()



  openInsuranceModal(data: any) {
    this.MEMO_ID = data.ID
    this.isInsuranceVisible = true;
    this.REG_NO1 = data.REG_NO
    this.QUOTATION_ID = data.QUOTATION_ID
    this.CR_DATE1 = data.CR_DATE
    this.INSURANCE_DATE1 = data.INSURANCE_DATE
  }

  handleInsuranceeOk() {
    let isOk = true;
    this.INSURANCE_DATE = this.datePipe.transform(this.INSURANCE_DATE, "yyyy-MM-dd")

    if (this.INSURANCE_DATE == undefined || this.INSURANCE_DATE.toString() == '') {
      isOk = false;
      this.message.error('Please enter valid Insurance Date', '');
    }

    if (isOk) {
      this.isApproveModalConfirmLoading = true;





      if (this.INSURANCE_DATE1 == '-' && this.REG_NO1 == '-' && this.CR_DATE1 == '-') {
        this.api.createInsuranceDetails(this.INSURANCE_DATE, this.INSURANCE_REMARK, this.MEMO_ID, this.QUOTATION_ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading = false;
            this.message.success("Insurance Updated Successfully", "");
            this.isInsuranceVisible = false;
            this.drawerClose1();
          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("Insurance Updation Failed", "");
            // this.search(false);
          }
        });
      } else {
        this.api.updateInsuranceDetails(this.INSURANCE_DATE, this.INSURANCE_REMARK, this.MEMO_ID, this.QUOTATION_ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading = false;
            this.message.success("Insurance Updated Successfully", "");
            this.isInsuranceVisible = false;
            this.drawerClose1();
          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("Insurance Updation Failed", "");
            // this.search(false);
          }
        });
      }

    }
  }
  APPROVER_IDS
  saleHierarchy5ss=[]
  SALES_MANAGER_DATETIME  = new Date()

  getsalesHierarchySM() {
    let filter
       if (this.discount1== 0 && this.exchange== 0) {
   
         filter = ` AND ROLE_ID IN (37) AND  CLUSTER_ID IN (${this.clusterId}) `; //acv
         // HeadIds = '37';
       } else if (this.discount1== 0 && this.exchange!= 0) {
         // only exchange
         filter = ` AND ROLE_ID IN (31) AND  CLUSTER_ID IN (${this.clusterId}) `; //hp
         // HeadIds = '31';
       } else if (this.discount1 != 0 && this.exchange== 0) {
         //only corp
         filter = ` AND ROLE_ID IN (32) AND  CLUSTER_ID IN (${this.clusterId}) `; //cm
         // HeadIds = '32';
       } else if (this.discount1 != 0 && this.exchange!= 0) {
         //both
         filter = ` AND ROLE_ID IN (32) AND  CLUSTER_ID IN (${this.clusterId}) `; //cm
         // HeadIds = '32';
       }else if (this.SAMBHADH_SCHEME != 0 ) {
         //both
         filter = ` AND ROLE_ID IN (32) AND  CLUSTER_ID IN (${this.clusterId}) `; //cm
         // HeadIds = '32';
       } else {
         filter = ` AND ROLE_ID IN (37,31,32) AND  CLUSTER_ID IN (${this.clusterId}) `;
         // HeadIds = '37,31,32';
       }
   
   this.api.getAllEmpRoleMap(0,
       0,
       "ID",
       "asc", filter ).subscribe(data => {
       
           if (data["code"] == 200) {
             this.saleHierarchy5ss = data["data"];
             console.log(this.saleHierarchy5,"this.saleHierarchy5")
           }
        
         (err) => {
           if (err["ok"] == false) this.message.error("Server Not Found", "");
         }
   
       });
   }
  openApproveModalSMhead  () {
    this.tempData = new Object();
    this.tempData = this.data;
    this.APPROVER_IDS = [];
    this.isApproveVisibleSM = true;
    this.getsalesHierarchySM()
    this.get12(this.data.ID)
    this.get(this.data.ID)
  }
  handleApproveCancelSM() {
    this.isApproveVisibleSM = false;
    this.drawerClose1();
  }
  // isApproveModalConfirmLoadingSM = false
  handleApproveOkSM() {

    this.isApproveModalConfirmLoadingSM = true
    let isOk = true;
    if (this.APPROVER_IDS == "" || this.APPROVER_IDS == undefined) {
      this.message.error('Please select H-Promise Manger Or Corporate Manager Or Account Verification Manager', '')

      //   // }
    }


    else {
      if (isOk) {


        // this.isApproveModalConfirmLoadingSM = true;
        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"] = this.SM_REMARK
        this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
        this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
        this.tempData["SM_REMARK"] = this.SM_REMARK
        this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
        this.tempData["DATE"] = this.datePipe.transform(this.today21, "yyyy-MM-dd HH:mm:ss")
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            // this.isApproveModalConfirmLoadingSM = false;
            // this.isApproveModalConfirmLoading = true;


            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
         
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_IDS;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"] = 0
            this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"] = this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
            this.tempData["SH_REMARK"] = this.tempData["SH_REMARK"]
            this.tempData["SM_REMARK"] = this.SM_REMARK
            this.tempData["GM_REMARK"] = this.tempData["GM_REMARK"]
            this.tempData["REMARK"] = this.ACC_VERIFICATION_REMARK
            this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
            this.tempData["DATE"] = this.date24
            this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
            this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
            this.tempData['INSURANCE_PERCENTAGE'] = this.tempData['INSURANCE_PERCENTAGE']
            this.tempData['IS_TCS5'] = this.tempData['IS_TCS5']
            this.tempData['IS_CORPORATE'] = this.tempData['IS_CORPORATE']
            this.tempData['IS_SAMBHADH_SCHEME'] = this.tempData['IS_SAMBHADH_SCHEME']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.isApproveModalConfirmLoadingSM = false;
                this.memoSignature.MEMO_ID = successCode['data']
                this.memoSignature.SALES_MANAGER_ID = this.userId
                this.memoSignature.SALES_MANAGER_DATETIME = this.datePipe.transform(this.SALES_MANAGER_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }

                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.isApproveVisibleSM = false;
                this.drawerClose1();
                // this.search(false);



              } else {
                this.isApproveModalConfirmLoadingSM = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });


          } else {
            this.isApproveModalConfirmLoadingSM = false;
            this.message.error("Updation Failed", "");

          }
        });
      }
    }







  }


  handleRejectCancelSM() {
    this.isRejectVisibleSM = false;
    this.isRejectModalConfirmLoadingSM = false;
  }
  openRejectModalSM() {
    this.tempData = new Object();
    this.tempData = this.data;
    this.SH_REMARK = "";

    this.isRejectVisibleSM = true;
    this.get(this.iid)
    // this.drawerClose1();
  }
  // isRejectVisibleSM = false
  // isRejectModalConfirmLoadingSM = false
  handleRejectOkSM() {
    let isOk = true;

    if (this.SM_REMARK == undefined || this.SM_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    else {
      if (isOk) {
        this.isRejectModalConfirmLoadingSM = true;
        this.tempData["APPROVER_REMARK"] = this.SM_REMARK;
        this.tempData["APPROVER_ID"] = this.api.userId;
        this.tempData["STATUS"] = "RJ";
        this.tempData["VIN_NUMBER"] = this.tempData["VIN_NUMBER"]
        this.tempData["COMPANY_NAME"] = this.questionary['COMPANY_NAME']
        this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
        this.tempData["DATE"] = this.tempData["DATE"]
        this.tempData["REMARK"] = "";
        this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isRejectModalConfirmLoadingSM = false;
            this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
            this.message.success("Remark Updated Successfully", "");
            this.isRejectVisibleSM = false;
            this.drawerClose1()

          } else {
            this.isRejectModalConfirmLoadingSM = false;
            this.message.error("Remark Updation Failed", "");

          }
        });
      }
    }


  }



}
