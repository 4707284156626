import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import {
  SingleDataSet,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip
} from 'ng2-charts';

@Component({
  selector: 'app-sa-wise-psf-report',
  templateUrl: './sa-wise-psf-report.component.html',
  styleUrls: ['./sa-wise-psf-report.component.css']
})
export class SAWisePSFReportComponent implements OnInit {

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private message: NzNotificationService) { }

  formTitle = "SA Wise PSF Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["CATEGOREY", "Category"],
  ["TOATL", "Total"],
  ["TOTAL_APPOINTMENTS", "Total Appointment"],
  ["TOTAL_WORK_DONE", "Total Work Done"],
  ["TOTAL_REMINDERS", "Total Reminders"],
  ["TOTAL_LOST", "Total Lost"],
  ["NOT_CONNECTED", "Not Connected"],
  ["TOTAL_CALLS", "Total Calls"],];


  date1: any = new Date()
  date
  modalVisible = false;
  isSpinning = false;
  filterClass: string = "filter-invisible";
  sales: any = []
  DATE: any;
  DATE1: any
  CCE_ID: number
  datalist: any = []
  datalist1: any = []
  ngOnInit() {

    this.date1 = new Date();
    this.getEmployee();
    this.date = new Date();
    this.date.setDate(1); 
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, '', 'asc', " AND STATUS = 1 AND ROLE_ID = 48").subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])

        this.search(false, false);
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  isVisible: boolean = false

  handleOk(): void {

    this.isVisible = false;
  }

  handleCancel(): void {

    this.isVisible = false;
  }

  openPrint() {
    this.isVisible = true;
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getstatus(CATEGOREY: any) {
    if (CATEGOREY == "L")
      return "HMIL Lost";
    else if (CATEGOREY == "NS")
      return "No Show";
    else if (CATEGOREY == "C")
      return "Confirmation";
    else if (CATEGOREY == "SE")
      return "Service Due";
    else if (CATEGOREY == "WD")
      return "Work Done";
    else if (CATEGOREY == "OY")
      return "One Year";
    else if (CATEGOREY == "A")
      return "Appointment";
    else if (CATEGOREY == "MT")
      return "Mid Term";
    else if (CATEGOREY == "SD")
      return "Sales Data";
    else if (CATEGOREY == "O")
      return "Own Calling";
    else
      return CATEGOREY;
  }

  NoData: boolean = false
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));

  CCE_NAME
  datalist2
  arraya = []
  TOTAL_HQ_CONCERN = []
  TOTAL_INTERNAL_CONCERN = []
  CONCERN_PERCENTAGE = []
  SERVICE_ADVISER_NAME = []

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }


    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')

    this.DATE1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')

    if (this.roleId == 43) {
      if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
        this.CCE_ID = this.userId
      }
    }
    else {
      if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
        this.CCE_ID = this.CCE_ID
      }
    }
    this.filterQuery = ''
    if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
    this.filterQuery = " AND SERVICE_ADVISER_ID IN ("+ this.CCE_ID  +")";
    }
    this.arraya = []
    this.TOTAL_HQ_CONCERN = []
    this.TOTAL_INTERNAL_CONCERN = []
    this.CONCERN_PERCENTAGE = []
    this.SERVICE_ADVISER_NAME = []
    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getSAwisepsfreport(0, 0, this.sortKey, sort, likeQuery+ this.filterQuery, this.DATE, this.DATE1).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.datalist = data['ALL_DATA'];
          this.datalist1 = data['ROW_TOTAL'];
          this.datalist2 = data['PERCENTAGES'];

          for (let k = 0; k < this.datalist2.length; k++) {
            this.arraya.push(this.datalist2[k]['SERVICE_QUALITY'])
            this.arraya.push(this.datalist2[k]['SA_DEALING'])
            this.arraya.push(this.datalist2[k]['DELAY_IN'])
            this.arraya.push(this.datalist2[k]['WASH_AND_CLEAN'])
            this.arraya.push(this.datalist2[k]['PART'])
            this.arraya.push(this.datalist2[k]['WARRANTY'])
            this.arraya.push(this.datalist2[k]['OTHERS'])



          }
          for (let v = 0; v < this.datalist.length; v++) {
            this.TOTAL_INTERNAL_CONCERN.push(this.datalist[v]['TOTAL_INTERNAL_CONCERN'])
            this.TOTAL_HQ_CONCERN.push(this.datalist[v]['TOTAL_HQ_CONCERN'])
            this.CONCERN_PERCENTAGE.push(this.datalist[v]['CONCERN_PERCENTAGE'])
            this.SERVICE_ADVISER_NAME.push(this.datalist[v]['ADVISER_NAME'])




          }
          this.pieChartData  = this.arraya;
          this.barChartLabels  = this.SERVICE_ADVISER_NAME;
           this.barChartData  = [
            { data: this.TOTAL_INTERNAL_CONCERN, label: 'Total Internal Concern', yAxisID: 'y-axis-0' },
            { data: this.TOTAL_HQ_CONCERN, label: 'HQ Concern', yAxisID: 'y-axis-0' },
            { data: this.CONCERN_PERCENTAGE, label: '% Concern', yAxisID: 'y-axis-1', type: 'line' }
          ];

          console.log(this.TOTAL_INTERNAL_CONCERN);
          console.log(this.TOTAL_HQ_CONCERN);

          console.log(this.CONCERN_PERCENTAGE);

          console.log(this.SERVICE_ADVISER_NAME);

          console.log(this.arraya);



          this.NoData = false
          this.filterClass = 'filter-invisible';

        }
        else if (data['code'] == 300) {
          this.NoData = true
        }

      }, err => {
        console.log(err);
      });

    }
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'

    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')

    this.DATE1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')


    if (this.date != null && this.date1 != null) {
      this.DATE = this.date
      this.DATE1 = this.date1
    }

    // if(){


    // }

    if (this.CCE_ID != undefined && this.CCE_ID == null) {
      this.CCE_ID = this.CCE_ID
    }
    this.arraya = []
    this.TOTAL_HQ_CONCERN = []
    this.TOTAL_INTERNAL_CONCERN = []
    this.CONCERN_PERCENTAGE = []
    this.SERVICE_ADVISER_NAME = []
    this.search(true, false);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.date = new Date();
    this.date1 = new Date();

    this.getEmployee();
    this.search(true, false);
    this.isFilterApplied = 'default';

  }



  public pieChartOptions: ChartOptions = {
    responsive: true
  };
  public pieChartLabels: Label[] = [
    ['Service Quality'],
    ['SA Dealing'],
    ['Delay In'],
    ['Washing & Cleaning'],
    ['Part'],
    ['Warranty'],
    ['Others'],


  ];
  public pieChartData: SingleDataSet = [0,0,0,0,0,0,0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];


  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        id: 'y-axis-0',
        position: 'left',
        ticks: {
          fontColor: 'black'
        }
      }, {
        id: 'y-axis-1',
        position: 'right',
        ticks: {
          fontColor: 'black'
        }
      }]
    }
  };
  public barChartLabels: Label[] = this.SERVICE_ADVISER_NAME;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [];
  public barChartData: ChartDataSets[] = [
    { data: this.TOTAL_INTERNAL_CONCERN, label: 'Total Internal Concern', yAxisID: 'y-axis-0' },
    { data: this.TOTAL_HQ_CONCERN, label: 'HQ Concern', yAxisID: 'y-axis-0' },
    { data: this.CONCERN_PERCENTAGE, label: '% Concern', yAxisID: 'y-axis-1', type: 'line' }
  ];
}