import { Component, OnInit } from '@angular/core';
import { TrainingTypeMaster } from 'src/app/Models/trainingtypemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainingtypelist',
  templateUrl: './trainingtypelist.component.html',
  styleUrls: ['./trainingtypelist.component.css']
})

export class TrainingtypelistComponent implements OnInit {
  formTitle = "Manage Training Type";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TITLE", "Title"], ["SEQ_NO", "Seq. No."]]
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainingTypeMaster = new TrainingTypeMaster();
  data: TrainingTypeMaster = new TrainingTypeMaster;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAlltrainingType(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

      if (this.totalRecords == 0) {
        data.SEQ_NO = 1;
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerData = new TrainingTypeMaster();

    this.api.getAlltrainingType(1, 1, 'SEQ_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQ_NO = 1;

      } else {
        this.drawerData.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle = "Create New Training Type";
    this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;
  }

  edit(data: TrainingTypeMaster): void {
    this.drawerTitle = "Update Training Type";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}