import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-customerwisefinancereport',
  templateUrl: './customerwisefinancereport.component.html',
  styleUrls: ['./customerwisefinancereport.component.css']
})
export class CustomerwisefinancereportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 20;
  totalRecords = 1;
  dataList = [];
  formTitle = "Customer Wise Finance Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "CUSTOMER_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";\

  exportLoading: boolean = false;
  date1 = new Date();
  date2 = new Date();
  today = new Date();

  startValue: any = new Date();
  endValue: any = new Date();
  dataList1 = [];
  MODELS: any;
  BRANCH: any;
  
  model: any = [];
  branch: any = [];
  SALES_EXECUTIVE: any = [];
  SaleExicutive: any = [];
  quotationdate: any;
  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model name"], ["VARIENT_NAME", "Variant"], ["SALES_EXECUTIVE_NAME", "Sales Executive"], ["BRANCH_NAME", "Branch Name"], ["BANK_NAME", "Finance Bank"],];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {
    
    // this.endValue = this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +7)
    this.endValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +4);
    this.startValue =new Date(this.endValue.getFullYear(), this.endValue.getMonth(), -2);
    this.getbranches();
    this.getmodels();
    this.getsalehead();
    setTimeout(()=>{
      this.search(false); 
    },1000);
  }

  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      if (data['code'] == 200) {
        this.SaleExicutive = data['data'];
        console.log(this.SaleExicutive);
        this.onSelectAllSalesExecutive(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }
  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
        console.log(this.branch);
        this.onSelectAllBranch(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }
  getmodels() {
    this.api.getAllModels(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.model = data['data'];
        console.log(this.model);
        this.onSelectAllModel(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };
  
  START_DATE:any
  END_DATE:any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };
 
  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }
  


  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.Customerwisefinancereport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH, this.SALES_EXECUTIVE, this.MODELS, this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.Customerwisefinancereport(0,0, this.sortKey, sort, likeQuery, this.BRANCH, this.SALES_EXECUTIVE, this.MODELS, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Variant'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Finance Bank'] = this.dataList1[i]['BANK_NAME'];
      obj1['Inhouse'] = this.dataList1[i]['INHOUSE'];
      obj1['Outhouse'] = this.dataList1[i]['OUTHOUSE'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer wise finance report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  // applyFilter() {
  //   this.isSpinning = true
  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
  //   this.filterQuery = "";
  //   if (this.MODELS != undefined) {
  //     //  this.message.error("Please Select Atleast One Option From The Following",'')
  //     // this.filterQuery = " AND MODEL_NAME = " + this.MODELS;
  //     this.isSpinning = false;
  //     this.loadingRecords = false;
  //     this.isFilterApplied = "primary";
  //     console.log("FilterQuery", this.filterQuery)

  //   }
  //   else if (this.MODELS == undefined) {
  //     this.message.error("Please Select Option To Filter", '')
  //     this.loadingRecords = false;
  //     this.isSpinning = false;
  //     this.isFilterApplied = "default";
  //   }
  // }

  isOk = true

  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.START_DATE == undefined && this.END_DATE == undefined && this.SALES_EXECUTIVE == undefined && this.MODELS == undefined && this.BRANCH == undefined ) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.START_DATE = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd")
      this.END_DATE = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd")

      // var Start_Date:any=""
      // var End_Date:any=""
      // if(this.startValue=null){
      //   Start_Date=""
      // }
      // if(this.endValue=null){
      //   End_Date=""
      // }

      // this.api.Customerwisefinancereport(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery, this.BRANCH, this.SALES_EXECUTIVE, this.MODELS, this.startValue, this.endValue).subscribe(data => {
      //   console.log(data)
      //   this.loadingRecords = false;
      //   this.isSpinning = false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   this.filterClass = 'filter-invisible';
      // }, err => {
      //   console.log(err);
      // });
      this.search(true)
    }
  }
  
  importInExcel() {
    this.search(true, true);
  }

  clearFilter() {
    this.endValue = new Date ()
    this.startValue = new Date(this.endValue.getFullYear(), this.endValue.getMonth(), -7)
    // this.MODELS = [];
    // this.SALES_EXECUTIVE = [];
    // this.BRANCH = [];
    // this.SELECT_SALES_EXICUTIVE = false;
    // this.SELECT_MODEL = false;
    // this.SELECT_BRANCH = false;
    let ids = [];
    this.SELECT_BRANCH = true
    for (var i = 0; i < this.branch.length; i++) {
      ids.push(this.branch[i]["ID"]);
    }
    this.BRANCH = ids;

    this.SELECT_MODEL = true
    let ids1 = [];

    for (var i = 0; i < this.model.length; i++) {
      ids1.push(this.model[i]["ID"]);
    }
    this.MODELS = ids1;


    this.SELECT_SALES_EXICUTIVE = true
    let ids2 = [];

    for (var i = 0; i < this.SaleExicutive.length; i++) {
      ids2.push(this.SaleExicutive[i]["EMPLOYEE_ID"]);
    }
    this.SALES_EXECUTIVE = ids2;

    this.searchText = '';
    this.filterQuery = '';
    this.filterClass = 'filter-invisible';
    this.search(true)
    //  this.isFilterApplied="default";
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  SELECT_SALES_EXICUTIVE: boolean = false;
  SELECT_MODEL: boolean = false;
  SELECT_BRANCH: boolean = false;

  onSelectAllSalesExecutive(event: any) {
    this.SELECT_SALES_EXICUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXICUTIVE == true) {
      for (var i = 0; i < this.SaleExicutive.length; i++) {
        ids.push(this.SaleExicutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE = ids
  }
  onSelectOff1(event1: any) {
    var a = this.SaleExicutive.length;
    var b = this.SaleExicutive.length - event1.length;
    if ((a! = b)) {
      this.SELECT_SALES_EXICUTIVE = false;
    } else {
      this.SELECT_SALES_EXICUTIVE = true;
    }
    this.SALES_EXECUTIVE = event1;
    if (this.SALES_EXECUTIVE.length == 0) {
      this.SELECT_SALES_EXICUTIVE = false;
    }
  }
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.model.length; i++) {
        ids.push(this.model[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODELS = ids
  }

  onSelectOff2(event1: any) {
    var a = this.model.length;
    var b = this.model.length - event1.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODELS = event1;
    if (this.MODELS.length == 0) {
      this.SELECT_MODEL = false;
    }
  }
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  onSelectOff3(event1: any) {
    var a = this.branch.length;
    var b = this.branch.length - event1.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event1;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

}
