import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TrainerMaster } from 'src/app/Models/trainermaster';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainattendancecardlist',
  templateUrl: './trainattendancecardlist.component.html',
  styleUrls: ['./trainattendancecardlist.component.css']
})

export class TrainattendancecardlistComponent implements OnInit {
  formTitle = "Training Scedules";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["PROGRAM_TITLE", "Program Title"], ["SUBJECT_TITLE", "Subject Name"], ["DATE", "Date"], ["START_TIME", "Start Time"], ["END_TIME", "End Time"], ["ASSESSMENT_TYPE", "Assessment Type"], ["VENUE", "Venue"]]
  trainers: TrainerMaster = new TrainerMaster();
  trainerid = 0
  emailId = this.cookie.get('emailId')
  isSpinning = false
  attendmap: any = [{ SCHEDULE_ID: 1, EMP_ID: 1, ATTENDANCE: 0, FEEDBACK: "valid", RATINGS: 1, PERFORMANCE: 1, IS_ACTIVE: 0 }];
  traintrainermap: any = [{ SCHEDULE_ID: 1, TRAINER_ID: 1, IS_ACTIVE: 0 }];
  scheduleId = 0
  empId = 0
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainingSchedule = new TrainingSchedule();
  drawerVisible1: boolean = false;
  drawerTitle1: string = '';
  drawerData1: TrainingSchedule = new TrainingSchedule();
  drawerData2: string[] = [];
  drawerVisible3: boolean = false;
  drawerTitle3: string = '';
  drawerData3: TrainingSchedule = new TrainingSchedule();
  drawerData4: string[] = [];

  constructor( private cookie: CookieService,private api2: ApiService, private api: ApiService) { }

  ngOnInit() {
    this.api2.getAlltrainer(0, 0, '', '', ' AND EMAIL_ID="' + this.emailId + '"').subscribe(data => {
      if (data['code'] == 200) {
        this.trainers = data['data'][0];
        this.trainerid = this.trainers.ID
        this.search();
      }

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery;

    else
      filter = this.filterQuery;

    // this.api.getAlltrainingSchedule(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    // }, err => {
    //   console.log(err);
    // });

    this.api.getAlltrainingTrainerMapping(this.pageIndex, this.pageSize, this.sortKey, sort, 'AND ID= ' + this.trainerid).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      // console.log(this.trainerid);

    }, err => {
      console.log(err);
    });
  }

  //Drawer Methods
  // get closeCallback() {
  //   return this.drawerClose.bind(this);
  // }
  // add(): void {
  //   this.drawerTitle = "Create New Training Schedule";
  //   this.drawerData = new TrainingSchedule();
  //   // this.drawerData.IS_ACTIVE=true;
  //   this.drawerVisible = true;
  // }
  // edit(data: TrainingSchedule): void {
  //   this.drawerTitle = "Update Training Schedule";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }
  // drawerClose(): void {
  //   this.search();
  //   this.drawerVisible = false;
  // }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  MapAttendies(data: TrainingSchedule): void {
    this.scheduleId = data.ID;
    this.empId = data.ID;
    this.drawerData1 = data;

    // this.api.trainingAttendiedMapping(Number(this.scheduleId) ).subscribe(data => {
    //   if (data['code'] == '200') {
    //     this.drawerData2 = Object.assign([], data['data']);
    //     this.drawerVisible1 = true;
    //   } else {
    //     // this.message.error('Something Went wrong.','')
    //   }
    // }, err => {
    //   // this.message.error('Something Went wrong.','')
    // });

    this.api.getAlltrainingAttendiedMapping(this.pageIndex, this.pageSize, this.sortKey, 'ASC', ' AND IS_ACTIVE=1 AND SCHEDULE_ID=' + this.scheduleId).subscribe(data => {
      this.drawerVisible1 = true;
      this.drawerData2 = data['data'];

    }, err => {
      console.log(err);
    });

    this.drawerTitle1 = "Attendies for " + data.PROGRAM_TITLE + "";
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }

  MapTrainer(data: TrainingSchedule): void {
    this.drawerData3 = Object.assign({}, data);

    this.api.trainingTrainerMapping(Number(this.drawerData3.ID)).subscribe(data => {
      if (data['code'] == '200') {
        this.drawerData4 = Object.assign([], data['data']);
        this.drawerVisible3 = true;

      } else {
        // this.message.error('Something Went wrong.','')
      }

    }, err => {
      // this.message.error('Something Went wrong.','')
    });
    this.drawerTitle3 = "Trainer for " + data.PROGRAM_TITLE + "";
  }

  drawerClose3(): void {
    this.drawerVisible3 = false;
  }
}
