import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-insurance-company-wise-sm-wise-insurance-outhouse-report',
  templateUrl: './insurance-company-wise-sm-wise-insurance-outhouse-report.component.html',
  styleUrls: ['./insurance-company-wise-sm-wise-insurance-outhouse-report.component.css']
})
export class InsuranceCompanyWiseSmWiseInsuranceOuthouseReportComponent implements OnInit {
  columns: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["SALES_MANAGER_NAME", "Sales Manager Name"],
    ["INSURANCE_NAME", "Insurance Company"],
    ["NO_OF_INSURANCE", "No. of Insurances"],
  ];

  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  startValue:any = new Date();
  endValue: any = new Date();
  START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Insurance Company Wise Sales Manager Wise Insurance Outhouse Summary Report";
  insuranceOuthouseData = []
  totalRecords = []
  filterQuery: string = ''
  BRANCH_ID: any = []
  SALES_MANAGER_ID: any = []
  employee = []
  branches = []
  orgId = this.cookie.get('orgId');
  SALES_CONSULTANT_ID: any = []
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";

  constructor(private api: ApiService, private cookie: CookieService, public datePipe: DatePipe,
    private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search()
    this.getBranches()
    // this.getEmployee()
  }

  getBranches() {
    this.branches = [];
    this.api.getAllBranch(0, 0, "NAME", "asc", " AND STATUS=1 AND ORG_ID=" + this.orgId)
      .subscribe(
        (data: any) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // get employee
  getEmployee(event) {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=28').subscribe(data => {
      this.employee = data['data']
    })
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let BranchName = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        BranchName.push(this.branches[i]["ID"]);
      }
    } else {
      BranchName = [];
    }
    this.BRANCH_ID = BranchName;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL_MANAGER: boolean = false;
  onSelectAllManager(event) {
    this.SELECT_ALL_MANAGER = event;
    let empid = [];
    if (this.SELECT_ALL_MANAGER == true) {
      for (var i = 0; i < this.employee.length; i++) {
        empid.push(this.employee[i]["EMPLOYEE_ID"]);
      }
    } else {
      empid = [];
    }
    this.SALES_CONSULTANT_ID = empid;
  }

  onSelectOffManager(event) {
    var a = this.employee.length;
    var b = this.employee.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL_MANAGER = false;
    } else {
      this.SELECT_ALL_MANAGER = true;
    }
    this.SALES_CONSULTANT_ID = event;
    if (this.SALES_CONSULTANT_ID.length == 0) {
      this.SELECT_ALL_MANAGER = false;
    }
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  // START_DATE
  // END_DATE

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    if (this.searchText != '') {
      likeQuery = ' AND(';
      this.columns.forEach((column) => {
        if (column[0] == "BRANCH_NAME") {
          likeQuery += ' ' + "BRANCH_NAME" + " like '%" + this.searchText + "%' OR";
        }
        else if (column[0] == "SALES_MANAGER_NAME") {
          likeQuery += ' ' + "SALES_MANAGER_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length) + ")";
    }
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_CONSULTANT_ID != undefined && this.SALES_CONSULTANT_ID > 0) {
      this.SALES_CONSULTANT_ID = this.SALES_CONSULTANT_ID
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      
      this.END_DATE = this.endValue
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getInsuranceSalesManagerWiseOuthouseReport(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery + this.filterQuery,
        this.BRANCH_ID, this.SALES_CONSULTANT_ID,
        this.START_DATE, this.END_DATE)
        .subscribe(data => {
          this.loadingRecords = false;
          this.insuranceOuthouseData = data['data']
          this.totalRecords = data['count']
          // console.log(this.insuranceOuthouseData)

        });
    }
    else {
      this.exportLoading = true;
      this.api.getInsuranceSalesManagerWiseOuthouseReport(
        0,
        0,
        this.sortKey,
        sort,
        likeQuery + this.filterQuery,
        this.BRANCH_ID,
        this.SALES_CONSULTANT_ID,
        this.START_DATE, this.END_DATE)
        .subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.insuranceOuthouseData = data['data'];
            console.log(this.insuranceOuthouseData)
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_CONSULTANT_ID != undefined && this.SALES_CONSULTANT_ID) {
      this.SALES_CONSULTANT_ID = this.SALES_CONSULTANT_ID
    }
    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    this.search();
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND(';
      this.columns.forEach((column) => {
        if (column[0] == "BRANCH_NAME") {
          likeQuery += ' ' + "BRANCH_NAME" + " like '%" + this.searchText + "%' OR";
        }
        else if (column[0] == "SALES_MANAGER_NAME") {
          likeQuery += ' ' + "SALES_MANAGER_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length) + ")";
    }
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.SELECT_ALL1 = false;
    this.SELECT_ALL_MANAGER = false;
    this.BRANCH_ID = []
    this.SALES_CONSULTANT_ID = [];
    this.startValue = new Date()
    this.endValue = new Date()
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.insuranceOuthouseData = [];
    this.search()
    this.filterQuery = '';
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.insuranceOuthouseData.length; i++) {
      obj1['Insurance Company'] = this.insuranceOuthouseData[i]['INSURANCE_NAME'];
      obj1['Sales Manager Name'] = this.insuranceOuthouseData[i]['SALES_MANAGER_NAME'];
      obj1['No. of Insurances'] = this.insuranceOuthouseData[i]['No_OF_INSURANCE'];
      obj1['Branch Name'] = this.insuranceOuthouseData[i]['BRANCH_NAME'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.insuranceOuthouseData.length - 1) {
        this._exportService.exportExcel(arry1, 'Insurance company wise Sales Manager wise insurance outhouse summary report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  FILTER_QUERY: any
  members: any = []

  AddSchedule() {
    this.isVisible1 = true
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    var obj = {
      BRANCH_ID: this.BRANCH_ID,
      MONTH1: this.MONTH,
      YEAR: this.YEAR,
      SALES_CONSULTANT_ID: this.SALES_CONSULTANT_ID,
      ORG_ID: this.orgId
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    this.api.getAllMembers(0, 0, '', '', ' AND REPORT_ID = 44').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

}
