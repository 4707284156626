import { Component, OnInit } from '@angular/core';
// import { feedbackforms } from 'src/app/Models/feedbackforms';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { feedbackforms } from 'src/app/Models/feedbackforms';
import { DatePipe } from '@angular/common';
// import { feedbackformsaccessmapping } from 'src/app/Models/feedbackformsaccessmapping';

@Component({
  selector: 'app-feedbackforms',
  templateUrl: './feedbackforms.component.html',
  styleUrls: ['./feedbackforms.component.css']
})
export class FeedbackformsComponent implements OnInit {

  formTitle = "Manage Feedback form";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["AREA_NAME", "Area Name"], ["FEEDBACK_TYPE", "Area Type"], ["EMPLOYEE_NAME", "Employee Name"], ["FEEDBACK", "Feedback"], ["REMARK", "Remark"],
  ["GM_REMARK", "GM Remark"], ["INPROCESS_REMARK", "In-Process Remark"], ["CLOSED_REMARK", "Closed Remark"]]


  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: feedbackforms = new feedbackforms();

  drawerData2: string[];
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: feedbackforms = new feedbackforms();
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
  }
  STATUS = 'P'
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  userId = sessionStorage.getItem('userId')
  filter = ''
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);

    var likeQuery = ''
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    var filterQuery = ''
    if (this.STATUS == '') {
      filterQuery += " AND STATUS='P'";
    }
    else {
      filterQuery += " AND STATUS='" + this.STATUS + "'";
    }
    this.FROM_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    if (this.FROM_DATE != undefined && this.TO_DATE != undefined) {
      this.filter = ` AND DATE(APPLIED_DATETIME) BETWEEN '${this.FROM_DATE}' AND '${this.FROM_DATE}'`
    }

    console.log(filterQuery, "filterQuery")
    console.log("likeQuery" + likeQuery);

    this.api.getfeedform(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + filterQuery + ` AND EMPLOYEE_ID= ${this.userId} ` + this.filter, this.userId).subscribe(data => {
      console.log(data);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  changeRadioButton(event) {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.search(true);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New feedback";
    this.drawerData = new feedbackforms();
    // this.drawerData.IS_ACTIVE = true
    this.drawerVisible = true;
  }

  // MapApplicationModules(data:feedbackforms)
  // {
  //   this.api.getApplicationModuleMapping(data.ID).subscribe(data => {
  //     console.log(data);
  //    this.drawerData2 =data['data'];
  //     }, err => {
  //     console.log(err);
  //   });
  //   this.drawerTitle1 = "Map Application Modules for " + data.NAME + "";
  //   this.drawerData1 = Object.assign({}, data);
  //   this.drawerVisible1 = true;
  // }

  filterClass: string = "filter-invisible";

  FROM_DATE: any = new Date()
  TO_DATE: any = new Date()
  startValue = new Date()
  endValue = new Date()
  datepicker = new Date()

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onEnterKeyPress() {
    document.getElementById("searchbtn").focus();
  }

  applyFilter() {
    console.log((this.STATUS.length > 0) || (this.FROM_DATE != null) || (this.TO_DATE != null));

    // if ((this.STATUS.length > 0) || (this.FROM_DATE != null) || (this.TO_DATE != null))
    //   this.isFilterApplied = "primary";

    // else
    //   this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }
  clearFilter() {
    // this.STATUS = [];
    // this.date = [];
    this.FROM_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  edit(data: feedbackforms): void {
    this.drawerTitle = "Update feedback forms";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.drawerData.FEEDBACK_TYPE = data.FEEDBACK_TYPE
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}
