import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-product-transmission-report',
  templateUrl: './product-transmission-report.component.html',
  styleUrls: ['./product-transmission-report.component.css']
})
export class ProductTransmissionReportComponent implements OnInit {

  columns: string[][] = [['SALES_EXECUTIVE_NAME', 'Sales Executive Name']]
  ClusterId = Number(this.cookie.get("clusterId"));
  userId = Number(this.cookie.get("userId"));

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "SALES_EXECUTIVE_ID";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Transmission Sales Report"
  startValue: any;
  endValue: any;
  isSpinning = false;
  dataList = []
  totalRecords = []

  showfilter: boolean = false
  filterQuery: string = ""

  orgId = this.cookie.get('orgId');
  employee = []

  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_EXECUTIVE_ID: any = []

  constructor(private api: ApiService, public message: NzNotificationService, public datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService) { }

  carComparisonData: any = []

  ngOnInit(): void {
    if (this.roleId == 33) {
      this.SEFilter()
      this.getEmployee()
    }
    else {
      this.getEmployeeChilds()
    }
    this.search(false)
    this.getModels()

  }

  getSalesGM(event) {
    let ids12 = [];
    this.viewFilter = true
    this.api.getEmployeeChildGM(0, 0, '', '', '', event,this.cluster)
      .subscribe(data => {
        this.sales = data['data']
        this.viewFilter = false
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids12.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids12 = [];
        }
        this.ids1 = ids12;
        // this.search(true);
      },

      );
  }


  SELECT_ALL: boolean = true;

  onSelectAllChecked(event: any) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if (a! = b) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  viewFilter: boolean = true
  sales = [];
  ids1: any = []
  getSales(event) {
    let ids12 = [];
    this.viewFilter = true
    this.api.getEmployeeChild(0, 0, '', '', '', event,this.cluster)
      .subscribe(data => {
        this.sales = data['data']
        this.viewFilter = false
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids12.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids12 = [];
        }
        this.ids1 = ids12;
        // this.search(true);
      },

      );
  }
  emp
  cluster:any
  getSales1() {

    this.api.getAllEmployees(0, 0, '', '', "AND ROLE_IDS  like '%" + this.ROLE_ID + "%'")
      .subscribe(data => {
        this.emp = data['data']
        this.cluster =data['data'][0]['CLUSTER_ID']
      },
        (err) => {
          console.log(err);
        }
      );
  }
  models: any = [];
  getModels() {
    this.api.getAllModels(0, 0, '', '', 'AND STATUS = 1').subscribe(data => {
      this.models = data['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  SALES_MANAGER_ID
  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  value1: any;
  value2: any

  Total: any = [];
  model: any = []
  matchedData: any[] = [];
  NoData: boolean = false
  showdata: any = []

  GENERAL_MANAGER: null;
  SALES_HEAD: null;
  ROLESS: null;
  ROLE_ID: null;

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    // var likeQuery = "";
    // if (this.searchText != '') {
    //   likeQuery = ' AND (';
    //   this.columns.forEach((column) => {
    //     likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    // }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (this.SALES_EXECUTIVE_ID != undefined || this.SALES_EXECUTIVE_ID == '') {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID;
    }

    if (this.ROLE_ID == 29) {
      this.ROLESS = this.SALES_MANAGER_ID;
    }
    else if (this.ROLE_ID == 30) {
      this.ROLESS = this.GENERAL_MANAGER;
    }
    else {
      this.ROLESS = this.SALES_HEAD;
    }

    if (this.roleId != 33) {
      this.SALES_EXECUTIVE_ID = this.idss;
    }
    else if (this.ROLE_ID == null) {
      this.SALES_EXECUTIVE_ID = this.id2

    }

    else {
      this.SALES_EXECUTIVE_ID = this.ids1
      console.log(this.SALES_EXECUTIVE_ID, "")

    }

    this.loadingRecords = true;

    this.api.getTransmissionProductReport(0, 0, this.sortKey, sort, '', this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count']
        this.dataList = data['data']
        console.log(this.dataList);
        // this.Total = data['total'];
        this.model = data['name']
      }
      else if (data['code'] == 300) {
        this.NoData = true
      }
      else {
        console.log("Nothing")
      }
    });
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    this.search();
  }


  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.MONTH = this.month;
    this.MONTH1 = new Date()
    this.YEAR = this.year

    this.SELECT_ALL = true

    this.SALES_EXECUTIVE_ID = this.id2;
    this.ROLESS = null
    this.ROLE_ID = null
    this.SALES_MANAGER_ID = null
    this.SALES_HEAD = null
    this.GENERAL_MANAGER = null
    this.viewFilter = true
    this.search()
  }


  getEmployee() {
    this.api.getAllEmployees(0, 0, '', '', "AND ROLE_IDS  like '%" + 29 + "%'")
      .subscribe(data => {
        this.emp = data['data']

      },
        (err) => {
          // console.log(err);
        }
      );
  }
  idss


  getEmployeeChilds() {

    this.api.getEmployeeChilds(0, 0, '', '', '')
      .subscribe(data => {
        this.sales = data['data']
        this.idss = [];
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            this.idss.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          this.idss = [];
        }

        console.log(this.SALES_EXECUTIVE_ID)
        this.search(true)
      },
        (err) => {
          console.log(err);
        }
      );
  }
  roleId = Number(sessionStorage.getItem('roleId'))
  modalVisible = false;
  openPrint() {
    this.api.getTransmissionProductReport(0, 0, this.sortKey, '', '', this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe(data => {
      console.log(data)
      console.log(this.SALES_EXECUTIVE_ID)
      if (data['code'] == 300) {
        this.message.error("No Data", "")
        this.modalVisible = false;

      }
      else {
        this.modalVisible = true;
      }
    }, err => {
      console.log(err);
    });


  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.modalVisible = false;
  }
  id2
  SEFilter() {

    this.api.getAllEmployees12(0, 0, '', '', "AND ROLE_IDS  like '%" + 28 + "%'", '')
      .subscribe(data => {
        this.sales = data['data']
        this.id2 = [];
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            this.id2.push(this.sales[i]["ID"]);
          }
        } else {
          this.id2 = [];
        }

        // this.SALES_EXECUTIVE_ID = ids1;
        setTimeout(() => {
          this.search(true);
        }, 3000);

      },
        (err) => {
          console.log(err);
        }
      );

  }
}