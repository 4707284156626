import { Component, OnInit } from '@angular/core';
import { tourClassMaster } from 'src/app/Models/tourClass';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-tour-class-master',
  templateUrl: './tour-class-master.component.html',
  styleUrls: ['./tour-class-master.component.css']
})
export class TourClassMasterComponent implements OnInit {

  formTitle = "Tour Class Master";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [
    ["CLASS_NAME", "Tour Class Name"],
    ["MODE_NAME", "Mode Name"]]
  drawerVisible!: boolean;
  drawerTitle!: string;


  drawerData: tourClassMaster = new tourClassMaster()

  data: tourClassMaster = new tourClassMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;

    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getTourClass(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerData = new tourClassMaster();
    this.drawerTitle = "Create New Tour Class";
    this.drawerVisible = true;
  }

  listOfData:any=[]
  edit(data: tourClassMaster): void {
    this.drawerTitle = "Update Tour Class";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.api.getTourClass(0, 0, '', '', " AND ID !=" + data.ID).subscribe(data => {
      this.listOfData = data['data'];

    }, err => {
      console.log(err);
    });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
