import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-sales-exe-wise-model-wise-quotations-generated-report',
  templateUrl: './sales-exe-wise-model-wise-quotations-generated-report.component.html',
  styleUrls: ['./sales-exe-wise-model-wise-quotations-generated-report.component.css']
})
export class SalesExeWiseModelWiseQuotationsGeneratedReportComponent implements OnInit {

  formTitle = "Sales Executive Wise Model Wise Quotations Generated Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'salesexemodelwisequotationgeneratedreport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["ALCAZAR_", "Alcazar"],
  ["Aura", "Aura"],
  ["Creta", "Creta"],
  ["I20", "I20"],
  ["I20_N_Line", "I20 N Line"],
  ["NIOS", "NIOS"],
  ["New_Tucson", "New Tucson"],
  ["New_Venue", "New Venue"],
  ["Santro", "Santro"],
  ["Test_16", "Test 16"],
  ["Venue_N_Line_", "Venue N Line"],
  ["Verna", "Verna"],
  ["TOTAL_QUOTATION_GENERATED", "Total Quotations Generated"],]


  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  // ticketGroups: Ticketgroup[] = [];
  index = 0;

  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_EXECUTIVE_ID: any = [];

  FROM_DATE: any;
  TO_DATE: any;
  DATE = [];
  current = new Date();

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.DATE[0] = new Date()
    this.DATE[1] = new Date();
    this.getAllbranches();

  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      SALES_EXECUTIVE_ID: this.SALES_EXECUTIVE_ID,
      FROM_DATE: this.FROM_DATE,
      TO_DATE: this.TO_DATE,

    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 33').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  keyup(event: any) {
    this.search();
  }

  branch: any = []
  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      let ids = [];
      if (this.SELECT_BRANCH == true) {
        for (var i = 0; i < this.branch.length; i++) {
          ids.push(this.branch[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.BRANCH = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_BRANCH: boolean = true;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  onSelectOff(event: any) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }


  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }

    this.search();

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';

    this.DATE = []
    this.DATE[0] = new Date()
    this.DATE[1] = new Date();

    this.SELECT_BRANCH = true
   
    this.getAllbranches()
  }
  salesmodeldata = [];

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    this.FROM_DATE = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd');


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.salesExeWiseModelQuotationReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH, this.FROM_DATE, this.TO_DATE)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.salesExeWiseModelQuotationReport(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH, this.FROM_DATE, this.TO_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.salesmodeldata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.salesmodeldata.length; i++) {
      obj1['Sales Executive Name'] = this.salesmodeldata[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.salesmodeldata[i]['BRANCH_NAME'];
      obj1['Alcazar'] = this.salesmodeldata[i]['ALCAZAR_'];
      obj1['Aura'] = this.salesmodeldata[i]['Aura'];
      obj1['Creta'] = this.salesmodeldata[i]['Creta'];
      obj1['I20'] = this.salesmodeldata[i]['I20'];
      obj1['I20_N_Line'] = this.salesmodeldata[i]['I20 N Line'];
      obj1['NIOS'] = this.salesmodeldata[i]['NIOS'];
      obj1['NewTucson'] = this.salesmodeldata[i]['New_Tucson'];
      obj1['New Venue'] = this.salesmodeldata[i]['New_Venue'];
      obj1['Santro'] = this.salesmodeldata[i]['Santro'];
      obj1['Test 16'] = this.salesmodeldata[i]['Test_16'];
      obj1['Venue N Line'] = this.salesmodeldata[i]['Venue_N_Line_'];
      obj1['Verna'] = this.salesmodeldata[i]['Verna'];
      obj1['Total Quotations Generated'] = this.salesmodeldata[i]['TOTAL_QUOTATION_GENERATED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.salesmodeldata.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Model Wise Quotations Generated Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}
