import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-memo-wise-discount-detailed-report',
  templateUrl: './memo-wise-discount-detailed-report.component.html',
  styleUrls: ['./memo-wise-discount-detailed-report.component.css']
})
export class MemoWiseDiscountDetailedReportComponent implements OnInit {

  formTitle = "Memo Wise Discount Detailed Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Memowisedetailed.xlsx';
  dataList = [];
  exportToExcelLoading = false
  index = 0;
  exportToExcelDataList: any = []

  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";

  isFilterApplied: string = "default";

  columns: string[][] = [["MEMO_NO", "Memo No"], ["MEMO_DATE", " Memo Date"], ["MODEL_NAME", "Model"], ["SALES_EXECUTIVE_NAME", "Sales Executive Name"], ["CUSTOMER_NAME", "Customer Name"],
  ["SALES_CONSULTANT_DISCOUNT", "Discount given by SE"], ["HEAD_DISCOUNT", "Approved Discount"], ["APPROVER_NAME", "Discount Approved By"], ["TOTAL_DISCOUNT", "Total Discount"],]


  APPROVER_ID: any = []



  isSpinning = false
  filterClass: string = "filter-invisible";
  SALES_EXECUTIVE_ID: any = []
  Value1: any
  Value2: any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  MEMO_DATE = []
  data1 = [];
  INVOICE_DATE: any = []
  MODEL_ID: any = []
  DATE: any
  ticketQuestion = {};

  departments = []
  supportusers = []

  MEMO_STATUS: any[];
  constructor(private api: ApiService, private DatePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.Value1 = new Date()
    this.getSales();
    this.getModels();
    this.getApproverHead()
    this.search();

  }

  sales = [];
  getSales() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=28')
      .subscribe(data => {
        this.sales = data['data']
      },
        (err) => {
          console.log(err);
        }
      );
  }

  importInExcel() {
    this.search(true, true);
  }

  models = [];
  getModels() {
    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1")
      .subscribe(data => {
        this.models = data['data']
      },
        (err) => {
          console.log(err);
        }
      );
  }

  approver = [];
  getApproverHead() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID IN(29,30,33,34)')
      .subscribe(data => {
        this.approver = data['data']
      },
        (err) => {
          console.log(err);
        }
      );
  }

  SELECT_ALL3: boolean = false;
  onSelectAllChecked3(event: any) {
    this.SELECT_ALL3 = event;
    let ids3 = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.approver.length; i++) {
        ids3.push(this.approver[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids3 = [];
    }
    this.APPROVER_ID = ids3;
  }

  onSelectOff3(event: any) {
    var a = this.approver.length;
    var b = this.approver.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.APPROVER_ID = event;
    if (this.APPROVER_ID.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }


  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event: any) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff2(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.APPROVER_ID = []
    this.SELECT_ALL3 = false
    this.Value1 = new Date()
    this.MODEL_ID = []
    this.filterQuery = ""
    this.INVOICE_DATE = null;
    this.SALES_EXECUTIVE_ID = []
    this.SELECT_ALL2 = false
    this.MEMO_DATE = []
    this.search()
    this.isFilterApplied = "default";

  }

  search(reset: boolean = false, exportToExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.Value1 = this.DatePipe.transform(this.Value1, 'yyyy-MM-dd');

    if (this.Value1 != null) {
      this.MEMO_DATE = this.Value1
    }
    if (this.SALES_EXECUTIVE_ID != null && this.SALES_EXECUTIVE_ID != 0 && this.SALES_EXECUTIVE_ID != undefined) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }
    if (this.MODEL_ID != null && this.MODEL_ID != 0 && this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID
    }

    if (this.APPROVER_ID != null && this.APPROVER_ID != 0 && this.APPROVER_ID != undefined) {
      this.APPROVER_ID = this.APPROVER_ID
    }

    if (exportToExcel == false) {
      this.loadingRecords = true

      this.api.getMemodiscountDetailed(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MEMO_DATE, this.SALES_EXECUTIVE_ID, this.MODEL_ID, this.APPROVER_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.exportToExcelLoading = true;
      this.api.getMemodiscountDetailed(0, 0, this.sortKey, sort, likeQuery, this.MEMO_DATE, this.SALES_EXECUTIVE_ID, this.MODEL_ID, this.APPROVER_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  Value: any
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Memo No'] = this.exportToExcelDataList[i]['MEMO_NO'];
      obj1['Memo Date'] = this.DatePipe.transform(this.exportToExcelDataList[i]['MEMO_DATE'], 'dd MMM yyyy');
      obj1['Model Name'] = this.exportToExcelDataList[i]['MODEL_NAME'];
      obj1['Sales Executive name'] = this.exportToExcelDataList[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.exportToExcelDataList[i]['CUSTOMER_NAME'];
      obj1['Discount given by SE'] = this.exportToExcelDataList[i]['SALES_CONSULTANT_DISCOUNT'];
      obj1['Approved Discount'] = this.exportToExcelDataList[i]['HEAD_DISCOUNT'];
      obj1['Discount Approved By'] = this.exportToExcelDataList[i]['APPROVER_NAME'];
      obj1['Total Discount'] = this.exportToExcelDataList[i]['TOTAL_DISCOUNT'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Memo Wise Discount Detailed Report ' + this.DatePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  applyFilter() {

    this.isSpinning = true
    this.loadingRecords = true;
    this.isFilterApplied = "primary";
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }


    this.Value1 = this.DatePipe.transform(this.Value1, 'yyyy-MM-dd');

    if (this.Value1 != null) {
      this.MEMO_DATE = this.Value1
    }
    if (this.SALES_EXECUTIVE_ID != null && this.SALES_EXECUTIVE_ID != 0 && this.SALES_EXECUTIVE_ID != undefined) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }
    if (this.MODEL_ID != null && this.MODEL_ID != 0 && this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID
    }

    if (this.APPROVER_ID != null && this.APPROVER_ID != 0 && this.APPROVER_ID != undefined) {
      this.APPROVER_ID = this.APPROVER_ID
    }



    this.search();
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);

    }


  }

}

