import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { LeadCateogaryMaster } from 'src/app/Models/LeadCateogaryMaster';
import { ApiService } from 'src/app/Service/api.service';


@Component({
  selector: 'app-listlead-category-master',
  templateUrl: './listlead-category-master.component.html',
  styleUrls: ['./listlead-category-master.component.css']
})
export class ListleadCategoryMasterComponent implements OnInit {

  formTitle = "Manage Leading Category";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["Name", "Name"], ["SEQUENCE_NO", "Sequence No."]]
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: LeadCateogaryMaster = new LeadCateogaryMaster();
  data: LeadCateogaryMaster = new LeadCateogaryMaster;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getAllleadCategory(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

      if (this.totalRecords == 0) {
        data.SEQUENCE_NO = 1;
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerData = new LeadCateogaryMaster();

    this.api.getAllleadCategory(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      //this.loadingRecords = false;
      //  this.totalRecords = data['count'];
      //this.dataList = data['data'];

      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle = "Create New Leading Category";
    this.drawerData.STATUS = true;
    this.drawerVisible = true;
  }

  edit(data: LeadCateogaryMaster): void {
    this.drawerTitle = "Update Leading Category";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
