import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-montlymodelwiseinvoicegenerated',
  templateUrl: './montlymodelwiseinvoicegenerated.component.html',
  styleUrls: ['./montlymodelwiseinvoicegenerated.component.css']
})
export class MontlymodelwiseinvoicegeneratedComponent implements OnInit {

  exportLoading: boolean = false;
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Montly Model Wise Invoice Generated";
  isFilterApplied = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  date1 = new Date();
  date2 = new Date();
  newDate = new Date();
  startValue = new Date(this.newDate.getFullYear(),this.newDate.getMonth(),1);
  endValue = new Date();
  month = new Date();
  MONTH: any = this.datePipe.transform(this.month, "MM");
  FROM_DATE: any = this.datePipe.transform(this.startValue, "yyyy-MM-dd");
  TO_DATE: any = this.datePipe.transform(this.endValue, "yyyy-MM-dd");

  columns: string[][] = [["MODEL_NAME", "Model"]];

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {

    this.search(true);

  }

  monthchange(event: any) {
    this.MONTH = this.datePipe.transform(this.month, "MM")
  }

  dates: any = [];

  START_DATE: any
  END_DATE: any
  endOpen = false;

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    // console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.month != null) {
      this.MONTH = this.month
    }

    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.START_DATE = this.datePipe.transform(this.startValue, "yyyy-MM-dd");
    this.END_DATE = this.datePipe.transform(this.endValue, "yyyy-MM-dd");

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getMontlyModelWiseInvoiceGenerated(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MONTH, this.START_DATE,
        this.END_DATE).subscribe(data => {
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';
          }
        }, err => {
          // console.log(err);
        });

    }
    else {
      this.exportLoading = true;
      this.api.getMontlyModelWiseInvoiceGenerated(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.START_DATE,
        this.END_DATE).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  isOk : boolean;
  applyFilter() {
    this.isOk = true;
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    // this.MONTH = this.datePipe.transform(this.month, "MM");
    // this.START_DATE = this.datePipe.transform(this.startValue, "yyyy-MM-dd");
    // this.END_DATE = this.datePipe.transform(this.endValue, "yyyy-MM-dd");
    
    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.month != null) {
      this.MONTH = this.month
    }

    if (this.START_DATE == undefined && this.END_DATE == undefined && this.MONTH == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";
    }

    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.START_DATE = this.datePipe.transform(this.startValue, "yyyy-MM-dd");
    this.END_DATE = this.datePipe.transform(this.endValue, "yyyy-MM-dd");

    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.search(true);
      this.isFilterApplied = 'primary';
      this.filterClass = 'filter-invisible';
    }

  }
  clearFilter() {
    this.startValue = null;
    this.endValue = null;
    this.month = null;
    this.searchText = "";
    this.filterQuery = "";
    this.search(true);
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    // console.log("SortKey", this.sortKey);
    // console.log("SortValue", this.sortValue);
  }

  importInExcel(){
    this.search(true,true);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Count of Invoice Generated'] = this.dataList1[i]['INVOICE_COUNT'];
      obj1['Invoice Date'] = this.dataList1[i]['INVOICE_DATE'];
      obj1['Memo Created Date'] = this.dataList1[i]['MEMO_DATE'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Montly Model Wise Invoice Generated' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
