
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
// import { financeMaster } from '../Models/FinanceMaster';
// import { ApiService } from '../Service/api.service';
import {differenceInCalendarDays, setHours} from 'date-fns'
import { CookieService } from 'ngx-cookie-service';
// import { financeMaster } from 'src/app/Models/FinanceMaster';
// import { financeMaster } from 'src/app/Models/FinanceMaster';
import { Leave } from 'src/app/Models/Leave';
import { SOTMaster } from 'src/app/Models/SOTmaster';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-newincentive',
  templateUrl: './newincentive.component.html',
  styleUrls: ['./newincentive.component.css']
})
export class NewincentiveComponent implements OnInit {

  constructor(private datepipe:DatePipe,private api:ApiService, private cookie: CookieService) { }

  columns: string[][] = [["INCENTIVE_STRUCUTURE_NAME", "Incentive Name"],
  ["FROM_DATE", "From Date"],
  ["TO_DATE", "To date"]]

  formTitle = "SOT Incentive Master";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isVisible6 = false;
  isSpinning = false;
  loadingRecords = false;

  drawerTitle: string | undefined;

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;
  drawerTitle1: string
  drawerVisible1: boolean;
  drawerVisible11: boolean;

  drawerData1: Leave = new Leave();
  orgId = this.cookie.get('orgId');

  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  dataList: any[] = [];
  user: SOTMaster = new SOTMaster();
  assign

  drawerClose1() {
    
    this.isVisible = false;
    this.getData();
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  drawerClose6() {
    
    this.isVisible6 = false;
    this.getData();
  }
  get closeCallback6() {
    return this.drawerClose6.bind(this);
  }

  drawerClose3(): void {
    // this.STATUS = "P";
    // this.search(true);
    this.drawerVisible1 = false;
    this.getData();
  }

  
  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }
  drawerClose33(): void {
    // this.STATUS = "P";
    // this.search(true);
    this.drawerVisible11 = false;
    this.getData();
  }

  
  get closeCallback33() {
    return this.drawerClose33.bind(this);
  }
  
  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
  branch=[]
  drawerData12=[]
  ROLES2 = []
  BRANCHES2 = []
  ROLES22 =[]
  BRANCHES22=[]
  roles=[]
  isVisible1
  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }
  branch1=[]
  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch1 = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  TYP:string
  roleidddd=[]
  branchidddd=[]

  add1(data): void {
   console.log(data);
   this.branchidddd=[]
   this.roleidddd=[]
   
    this.drawerTitle1 = "SOT Roles & Branch Mapping";
    this.ROLES2=[]
    this.BRANCHES2=[]
   

    this.ROLES22=[]
    this.BRANCHES22=[]

   
    // this.drawerData = new Leave();
    this.drawerVisible1 = true;
    this.drawerData1 =  Object.assign({}, data);


  if(data.ROLE_IDS!=null && data.BRANCH_IDS!=null){
    this.ROLES2 = data.ROLE_IDS.split(",")
    this.BRANCHES2 = data.BRANCH_IDS.split(",")
  }

  // ,,,ROLE_IDS_OT,BRANCH_IDS_CT,ROLE_IDS_CT
  
    this.branch = [];
    for (let i = 0; i < this.ROLES2.length; i++) {
      var kk = this.roles.findIndex(x => x.ID == this.ROLES2[i]);
      this.ROLES22.push(this.roles[kk]['NAME']);
      this.roleidddd.push(Number(parseInt(this.ROLES2[i])))
    }
    for (let f = 0; f < this.BRANCHES2.length; f++) {
      var kka = this.branch1.findIndex(x => x.ID == this.BRANCHES2[f]);
      this.BRANCHES22.push(this.branch1[kka]['NAME']);
      this.branchidddd.push(Number(parseInt(this.BRANCHES2[f])))
    }



   
      this.api.SOTIncentiveDetails(0, 0, '', 'asc', ' AND SOT_INCENTIVE_MASTER_ID =' + data.ID).subscribe(data => {
        if (data['code'] == 200) {
          this.branch = data['data'];
          // console.log(this.branch);
          this.drawerData12 = this.branch
          
        }
      }, err => {
        console.log(err);
      });
   
  }




 
  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


    this.api.getSOTMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  getData() {
    this.api.getSOTMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '').subscribe((data: any) => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.totalRecords = data['count'];
      console.log(this.dataList, 'getFunction');
      // console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE, 'getClubbed');


    }, err => {
      console.log(err);
    });
  }
  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');


    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND FROM_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    // if (this.data.ALLOTMENT_TYPE_ID != null) {
    //   this.filterQuery =
    //     this.filterQuery + ' AND ALLOTMENT_TYPE_ID=' + "" + this.data.ALLOTMENT_TYPE_ID + "";
    // }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
  }

    this.api
      .getSOTMaster(0, 0, this.sortKey, sort, this.filterQuery)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );

  }



  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = null;
    this.endValue = null;

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }
  ngOnInit() {
    this.search();
    this.getData();
    this.loadRoles();
    this.getBranch();
  }

  add(){
    this.isVisible = true;
    this.drawerTitle = "Create SOT Incentive";
    this.user= new SOTMaster();
  }

  edit(data: SOTMaster) {
    this.isVisible=true;
    this.drawerTitle = 'Update SOT Incentive';
    this.user = Object.assign({}, data);

  }

}
