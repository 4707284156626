import { Component, OnInit } from '@angular/core';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRMaster } from 'src/app/Models/SMRmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-extendwarrenty',
  templateUrl: './extendwarrenty.component.html',
  styleUrls: ['./extendwarrenty.component.css']
})
export class ExtendwarrentyComponent implements OnInit {

  formTitle = "Extended Warrenty Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TABLE_NAME", "Name"], ["SEQUENCE_NO", "Sequence No."],["EXCEL_URL","Excel URL"],["COLUMN_JSON","Column JSON"]]
  Extendwarrentyvisible!: boolean;
  drawerTitle!: string;
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  // Extendwarrentyvisible!: boolean; 
  // dataList:any[]=[]
  constructor() { }

  ngOnInit() {
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.Extendwarrentyvisible = false;
  }

  edit(data: ServiceModuleExcel): void {
    // this.drawerTitle = " Update Coupon Type Information ";
    this.drawerData = Object.assign({}, data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    this.Extendwarrentyvisible = true;
  }

  add(): void {
    this.drawerTitle = " Extended Warrenty Leads "; 
    this.drawerData = new ServiceModuleExcel();
    this.Extendwarrentyvisible = true;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    // this.search(false);
  }

  keyup(event: any) {
    this.search();
  
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    // this.api.getdatabasetable(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];

    // }, err => {
    //   console.log(err);
    // });
  }

  
}
