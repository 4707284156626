import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-department-wise-attendance-report",
  templateUrl: "./department-wise-attendance-report.component.html",
  styleUrls: ["./department-wise-attendance-report.component.css"],
})
export class DepartmentWiseAttendanceReportComponent implements OnInit {
  formTitle = "Department Wise Attendance Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'DepartmentWiseAttendanceReport.xlsx';

  orgId = this.cookie.get("orgId");
  START_DATE: any = [];
  END_DATE: any = [];
  DEPARTMENT_ID = [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  SELECT_ALL: boolean = false;
  onSelectAllChecked(event) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.departments.length; i++) {
        ids.push(this.departments[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.DEPARTMENT_ID = ids;
  }

  onSelectOff(event) {
    var a = this.departments.length;
    var b = this.departments.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.DEPARTMENT_ID = event;
    if (this.DEPARTMENT_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  departments = [];

  getAttendanceDepartments() {
    this.departments = [];

    this.api
      .getAllAttendanceDepartments(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.departments = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = [];
    this.END_DATE = [];
    this.DEPARTMENT_ID = [];
    this.SELECT_ALL = false;
    this.setDateToDateFilter();
  }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getAttendanceDepartments();
    this.getDepartmentWiseAttendanceReport();
  }

  applyFilter() {
    if (
      (this.START_DATE != undefined &&
        this.START_DATE != null &&
        this.START_DATE.length >= 0 &&
        this.END_DATE != undefined &&
        this.END_DATE != null &&
        this.END_DATE.length >= 0) ||
      this.DEPARTMENT_ID.length > 0
    )
      this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getDepartmentWiseAttendanceReport(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.DepartmentWiseAttendanceReportSortKey = sort.key;
    this.DepartmentWiseAttendanceReportSortValue = sort.value;
    this.getDepartmentWiseAttendanceReport(true);
  }

  DepartmentWiseAttendanceReportPageIndex = 1;
  DepartmentWiseAttendanceReportPageSize = 10;
  DepartmentWiseAttendanceReportTotalRecords = 1;
  DepartmentWiseAttendanceReportSortValue: string = "desc";
  DepartmentWiseAttendanceReportSortKey: string = "ID";
  DepartmentWiseAttendanceReportData = [];

  columns: string[][] = [
    ["DEPARTMENT_NAME", "Department Name"],
    ["TOTAL", "Total No.Of. Employees"],
    ["IN_TIME", "In time"],
    ["LATECOMING", "Late Coming"],
    ["LATEMARK", "Latemark"],
    ["LEAVE_COUNT", "Leave"],
    ["ABSENT", "Absent"],
    ["DAY_IN", "Day In"],
    ["DAY_OUT", "Day OUt"],
    ["DAY_OUT_PERCENTAGE", "Day Out %"],
  ];

  getDepartmentWiseAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DepartmentWiseAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DepartmentWiseAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
    }
    this.loadingRecords = true;
    this.api
      .getDepartmentWiseAttendanceReport(
        this.DepartmentWiseAttendanceReportPageIndex,
        this.DepartmentWiseAttendanceReportPageSize,
        this.DepartmentWiseAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        fromDateFilter,
        toDateFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DepartmentWiseAttendanceReportTotalRecords = data["count"];
            this.DepartmentWiseAttendanceReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }
  exportexcel(): void {
    this.loadingRecords = true;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Department Wise Attendance');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }, 1000);
  }
}
