import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
import { NgForm } from '@angular/forms';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
// import { Leave } from '../Models/Leave';
@Component({
  selector: 'app-emprolemap',
  templateUrl: './emprolemap.component.html',
  styleUrls: ['./emprolemap.component.css']
})
export class EmprolemapComponent implements OnInit {

  @Input() drawerClose2: Function;
  @Input() Roletype = [];

  @Input() data: Leave;
  @Input() dataListtt = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  roles = [];
  model = [];
  leaveTypes = [];
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];
  Array1 = [];
  dataList1 = []

  MODEL1: any
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  SELECTED_EMP: any
  SELECTED_ROLES: any
  POINTS: any



  @Input() selectedIndex

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Cluster Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence Number"],]

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  allemp = []

  varienttype = []
  tranmission = []

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {


    this.loadRoles();
    this.getModels();


  }

  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  getModels() {
    this.model = [];

    this.api.getAllEmployees(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.allemp = data['data'];
      }
    }, err => {
      console.log(err);
    });



  }



  @Input() ROLES22 = []
  @Input() MODELS22 = []



  close(): void {
    this.drawerClose2();
   
    this.ROLES = []
    this.Modeldata = []
    this.ROLESSS = []
    // this.SendData=[]
    this.va = []
    // this.reset(myForm);
  }

  MappingData

  kkkkk = []
  roleIds = []
  nextsdata = []

  MODELeee = []


  @Input() Modeldata = []
  // SendData = []
  SendData1 = []
  i = 2
  mod: any
  ful: any
  var: any
  trans: any
  mod1: any
  ful1: any
  var1: any
  trans1: any

  finalDataa = []
  // dataListtt = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []
  @Input() ROLEIDS = []
  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []
  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }
  applytable(Modeldata) {
    // console.log(this.dataListtt);

    // this.message.success("Model Selected Successfully...", '')
    this.selectedIndex = 2
    console.log(Modeldata);
    this.SendData1 = []
    this.Final = []
    this.va = []
    this.MODELIDS = []
    this.ROLEIDS = []
    this.ROLESSS = this.roleIds
    this.MODELSSSS = this.SendData
    var abc = -2
    for (let i = 0; i < this.ROLESSS.length; i++) {
      var kk = this.Roletype.findIndex(x => x.ID == this.ROLESSS[i]);
      this.ROLEIDS.push(this.Roletype[kk]['NAME']);
      this.va = [];
      this.SendData1 = [];
      for (let j = 0; j < this.MODELSSSS.length; j++) {
        // var kk = this.modeltype.findIndex(x => x.ID == this.MODELSSSS[j]['MODEL1']);
        // var ss = this.modeltype[kk]['NAME']
        this.MODELIDS.push(this.MODELSSSS[j]['MODEL1']);

        var a = ''
        var b = ''
        var c = ''
        // if (this.MODELSSSS[j]['FULES'] != 0) {
        //   var kks = this.fueltype.findIndex(x => x.ID == this.MODELSSSS[j]['FULES']);
        //   a = this.fueltype[kks]['NAME']

        // } else {
        //   this.MODELSSSS[j]['FULES'] = 0

        //   a = ""
        // }

        if (this.MODELSSSS[j]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.MODELSSSS[j]['VARIENT']);
          b = (this.varienttype[aa]['NAME']);
        } else {
          this.MODELSSSS[j]['VARIENT'] = 0
          b = ""
        }

        if (this.MODELSSSS[j]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.MODELSSSS[j]['TRANSMISSION']);
          c = (this.tranmission[vv]['NAME']);
        } else {
          this.MODELSSSS[j]['TRANSMISSION'] = 0
          c = ""

        }

        // this.va.push(this.str(this.modeltype[kk]['NAME'], a, b, c))
        this.ids.push([this.MODELSSSS[j]['MODEL1'], this.MODELSSSS[j]['FULES'], this.MODELSSSS[j]['VARIENT'], this.MODELSSSS[j]['TRANSMISSION']])
        this.SendData1 = [...this.SendData1, this.MODELSSSS[j]['MODEL1'] + "." + this.MODELSSSS[j]['FULES'] + "." + this.MODELSSSS[j]['VARIENT'] + "." + this.MODELSSSS[j]['TRANSMISSION']];
        // console.log(this.ids);

        this.Final.push({
          "ROLE_ID": this.ROLESSS[i],
          "MODEL_ID": this.MODELSSSS[j]['MODEL1'],
          "FUEL_TYPE_ID": this.MODELSSSS[j]['FULES'],
          "VARIENT_ID": this.MODELSSSS[j]['VARIENT'],
          "TRANSMISSION_TYPE_ID": this.MODELSSSS[j]['TRANSMISSION'],
          "AMOUNT": 0
        })


      }
      if (i + 1 == this.ROLESSS.length) {

        this.getAmount()
      }

    }
  }

  getAmount() {

    if (this.dataListtt.length > 0) {


      this.dataListtt.forEach((x, index) => {
        this.Final.forEach((x2, index2) => {
          if (x.ROLE_ID == x2.ROLE_ID &&
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Final[index2]['AMOUNT'] = this.dataListtt[index].AMOUNT
          }
        })
        if (index + 1 == this.dataListtt.length) {
          this.dataListtt = [...[], ...this.Final];
          console.log(this.dataListtt);

        }
      });
    } else {
      this.dataListtt = [...[], ...this.Final];
    }



  }
  indexx = -1

  adddata() {
    if (this.indexx > -1) {
      this.Modeldata[this.indexx]['SELECTED_EMP'] = this.empname
      this.Modeldata[this.indexx]['SELECTED_ROLES'] = this.rolesname
      this.Modeldata[this.indexx]['POINTS'] = this.points


      this.indexx = -1
    } else {
      this.addmodeldetails();
    }
    this.SELECTED_EMP = null

    this.POINTS = null

  }

  points: any
  empname: any
  rolesname: any
  addmodeldetails() {

    if (this.Modeldata.length == 0) {


      var aa = this.allemp.findIndex(x => x.ID == this.SELECTED_EMP);
      this.empname = this.allemp[aa]['NAME'];


      this.points = this.POINTS;


      this.Modeldata = [
        {
          EMPLOYEE_NAME: this.empname,

          POINTS: this.points,

        }
      ];

      this.SendData = [{
        EMPLOYEE_ID: this.SELECTED_EMP,
        ROLE_ID: 0,
        POINTS: this.POINTS,
      }
      ];

      this.SELECTED_EMP = null

      this.POINTS = 0

    } else {
      var newindex = false
      console.log(this.Modeldata);
      this.SendData.forEach((x, k) => {

        if (
          this.SendData[k]['EMPLOYEE_ID'] == this.SELECTED_EMP
        ) {
          newindex = true
        }

        if (k + 1 == this.SendData.length && newindex == false) {
          var aa = this.allemp.findIndex(x => x.ID == this.SELECTED_EMP);
          if (this.allemp[aa] != undefined) {
            this.empname = this.allemp[aa]['NAME'];
          }
          this.Modeldata = [...this.Modeldata, {
            EMPLOYEE_NAME: this.empname,
            POINTS: this.POINTS,
          }];

          this.SendData = [...this.SendData, {
            EMPLOYEE_ID: this.SELECTED_EMP,
            ROLE_ID: 0,
            POINTS: this.POINTS,
          }];
          this.i++
        }
        if (k + 1 == this.SendData.length && newindex == true) {
          this.message.info('Data Already Present', '')
        }
      })



      this.SELECTED_EMP = null
      this.POINTS = 0
    }
    console.log(this.SendData);
    console.log(this.Modeldata);


  }


  deletedata(index: any) {
   
    this.Modeldata.splice(index, 1);
    this.Modeldata = [...[],...this.Modeldata]

  }

  sendjson(dataListtt) {
    var isOk = true;


    if (dataListtt.length == 0) {
      isOk = false;
      this.message.error("Please Select Roles And Models", "");
    }
    var obb = ({
      "SUPPORT_STAFF_BRANCH_AREA_MAPPING_ID": this.data.ID,

      "data": this.SendData
    })

    if (isOk) {
      this.isSpinning = true;


      this.api.AddBulkForAreaMap(obb).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Employee Mapped Successfully", "");

          this.drawerClose2()
          this.isSpinning = false;
          this.Modeldata = []
          this.ROLESSS = []
          this.va = []


        } else {
          this.message.error("Employee Mapping Failed", "");
          this.isSpinning = false;
        }
      });

    }
  }

  // save(dataList: any): void {
  //   var isOk = true;

  //   console.log(this.data);

  //   if (this.dataList.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Roles And Models", "");
  //   }

  //   var obb = ({
  //     "DIRECT_INCENTIVE_RATES_MASTER_ID": this.data.ID,
  //     // "TYPE": 'S', 
  //     "ROLE_IDS": this.ROLES.toString(),
  //     "MODEL_IDS": this.MODELS.toString(),
  //     "data": this.dataList
  //   })
  //   // Object.assign(obb,this.dataList)
  //   if (isOk) {
  //     this.isSpinning = true;


  //     this.api.rateAddBulk(obb).subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         this.message.success("Roles And Models Mapped Successfully", "");

  //         this.drawerClose1()
  //         this.isSpinning = false;

  //       } else {
  //         this.message.error("Roles And Models Mapping Failed", "");
  //         this.isSpinning = false;
  //       }
  //     });

  //   }
  // }

  today = new Date().setDate(new Date().getDate() + 4);

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  // @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.FROM_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }





}
