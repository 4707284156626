export class feedbackforms{
    ID;
    AREA_ID: number
    AREA_NAME: string;
    FEEDBACK_TYPE: string;
    EMPLOYEE_ID;
    FEEDBACK;
    ATTACHMENT
    APPLIED_DATETIME
    CHECKED_BY_USER_ID
    CHECKED_BY_USER_NAME
    CHECKED_DATETIME
    REMARK
    STATUS
    CLIENT_ID
    ATTACHMENT_URL
    // EMPLOYEE_ID:any
    GM_REMARK:any
    INPROCESS_REMARK:any
    CLOSED_REMARK:any
    GM_USER_ID:any
}