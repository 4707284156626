import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { TourMaster } from 'src/app/Models/tourmaster';
import { ApiService } from 'src/app/Service/api.service';
import { TourComponent } from '../tour/tour.component';
// import { TourMasterToAdd } from 'src/app/Models/tourmastertoadd';

@Component({
  selector: 'app-tour-hr',
  templateUrl: './tour-hr.component.html',
  styleUrls: ['./tour-hr.component.css']
})
export class TourHRComponent implements OnInit {


  
    formTitle = "Manage Tour";
    pageIndex = 1;
    pageSize = 10;
    totalRecords = 1;
    tourRequestData = [];
    loadingRecords = true;
    sortValue: string = "desc";
    sortKey: string = "ID";
    searchText: string = "";
    columns: string[][] = [["EMPLOYEE_NAME", "Employee Name"], ["DESIGNATION_NAME", "Designation Name "], ["MODE_NAME", "Mode Name"], ["CLASS_NAME", "Class Name"], ["FROM_DATE", "From Date"], ["TO_DATE", "To date"], ["ADVANCE_AMOUNT", "Advanced Amount"], ["EXPECTED_AMOUNT", "Expected Amount"],["FROM_TIME", "From Time"], ["TO_TIME", "To Time "], ["REASON", "Reason"]];
    approveTableColumns: string[][] = [["EMPLOYEE_NAME", "Employee Name"], ["DESIGNATION_NAME", "Designation Name "], ["MODE_NAME", "Mode Name"], ["CLASS_NAME", "Class Name"], ["FROM_DATE", "From Date"], ["TO_DATE", "To date"], ["FROM_TIME", "From Time"], ["TO_TIME", "To Time "], ["ADVANCE_AMOUNT", "Advanced Amount"], ["REASON", "Reason"]];
    drawerVisible: boolean;
    drawerTitle: string;
    drawerData: TourMaster = new TourMaster();
    drawerVisible1: boolean;
    ROLE_ID = Number(this.cookie.get('roleId'))
    userId = Number(this.cookie.get('userId'))
    orgId = this.cookie.get('orgId');
    constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }
  
    ngOnInit() {
      this.search();
      this.api.getTourMode(0, 0, '', 'asc', '').subscribe(data => {
        if (data['code'] == 200) {
          this.model = data['data'];
        }
      }, err => {
        console.log(err);
      });

      this.api.getTourClass(0, 0, '', '', '').subscribe((data) => {
        this.tour = data['data'];
      })
    }
  
    sort(sort: { key: string; value: string }): void {
      this.sortKey = sort.key;
      this.sortValue = sort.value;
      this.search(true);
    }
    STATUS = "P";
    search(reset: boolean = false) {
      if (reset) {
        this.pageIndex = 1;
      }
  
      this.loadingRecords = true;
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      } catch (error) {
        sort = "";
      }
  
      var likeQuery = "";
      console.log("search text : " + this.searchText);
  
      if (this.searchText != "") {
        likeQuery = " AND (";
  
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
  
        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
      }
  
      var dateFilter = "";
      if (this.date != undefined && this.date.length != 0) {
        dateFilter = " AND (APPLICATION_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "')";
      }
  
      // var empFilter = " AND (REPORTING_PERSON1 = " + this.userId + " OR REPORTING_PERSON2 = " + this.userId + ")";
      var empFilter = " and EMPLOYEE_ID = " + this.userId;
  
      var status = "";
      if (this.STATUS == "P")
        status = " AND STATUS = 'P'";
  
      else if (this.STATUS == "AP")
        status = " AND STATUS = 'AP'";
  
      else if (this.STATUS == "R")
        status = " AND STATUS ='R'";
  
      else if (this.STATUS == "FW")
        status = " AND STATUS = 'FW' ";
  
      this.api.getAllTour(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + empFilter+dateFilter  + status).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.tourRequestData = data['data'];
        }
  
      }, err => {
        console.log(err);
      });
    }
  
    @ViewChild(TourComponent, { static: false }) TourComponentVar: TourComponent;
    tour = []
    model = []
    alldestinations = []
    ID:any
    add(): void {
      this.drawerTitle = "Add Tour";
      this.drawerData = new TourMaster();
      this.drawerVisible = true;

 
        this.api.getAllemployeeMaster(0, 0, '', '', ' AND ID = ' + this.userId).subscribe((data) => {
          if (data['code'] == 200) {
           this.ID=data['data'][0]['REPORTING_HEAD_ID']
           console.log(this.ID)
          }
        })
     
      // this.TourComponentVar.getTourMaster();
   
      this.api.getTourMode(0, 0, '', 'asc', '').subscribe(data => {
        if (data['code'] == 200) {
          this.model = data['data'];
        }
      }, err => {
        console.log(err);
      });

      this.api.getTourClass(0, 0, '', '', '').subscribe((data) => {
        if (data['code'] == 200) {
        this.tour = data['data'];
        }
      })

      this.api.getTourDestination(0, 0, '', 'asc', 'AND IS_ACTIVE = 1').subscribe(data => {
        if (data['code'] == 200) {
          this.alldestinations = data['data'];
        }
      }, err => {
        console.log(err);
      });

        // this.api.getAllDesignation(0, 0, 'ID', 'desc', ' AND ORG_ID =' + this.orgId ).subscribe(data => {
        //   if (data['code'] == 200) {
        //     this.listdata2 = data['data']; 
        //   }
        // }, err => {
        //   console.log(err);
        // });
      
  
    }
    HEAD_STATUS: boolean = true
    edit(data: TourMaster): void {
      this.drawerTitle = "Update Tour";
      this.drawerData = Object.assign({}, data);
      this.HEAD_STATUS = true
      this.drawerVisible = true;
      // if (this.drawerData.HEAD_STATUS == 'AP') {
      //   this.HEAD_STATUS = true
      // }
      // else {
      //   this.HEAD_STATUS = false
      // }
  
      if (data.FROM_TIME != null)
        this.drawerData.FROM_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.FROM_TIME;
      // }
      if (data.TO_TIME != null)
        this.drawerData.TO_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.TO_TIME;
      // }
  
      // }
    }
  
    drawerClose(): void {
      this.search();
      this.drawerVisible = false;
    }
  
    get closeCallback() {
      return this.drawerClose.bind(this);
    }
  
  
  
    changeRadioButton(earlymarkStatus) {
      this.STATUS = earlymarkStatus;
      this.search(true);
    }
  
    getTimeInAM_PM(time) {
      return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
    }
  
    onSearching() {
      document.getElementById("button1").focus();
      this.search(true);
    }
  
    // filterQuery
  
  
    filterClass: string = "filter-invisible";
  
    showFilter(): void {
      if (this.filterClass === "filter-visible")
        this.filterClass = "filter-invisible";
  
      else
        this.filterClass = "filter-visible";
    }
  
    date: Date[] = [];
    filterStatusValue: string[] = ['P'];
    date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
    employeeFilter
    dateFormat = "dd/MMM/yyyy";
  
    clearFilter() {
      this.date = [];
      this.filterStatusValue = ['P'];
      this.isFilterApplied = "default";
      this.filterClass = "filter-invisible";
      this.employeeFilter = 1;
      this.filterQuery = "";
      this.search(true);
    }
    // date
    isSpinning
    filterQuery: string = "";
    isFilterApplied: string = "default";
    applyFilter() {
      // var sort: string;
      // try {
      //   sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      // } catch (error) {
      //   sort = "";
      // }
  
      // if (this.date == undefined || this.date.length == 0) {
      //   this.message.error("Please Select Date", "");
  
      // } else {
      //   this.filterQuery = " AND (FROM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "') AND ";
      //   this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);
  
      //   var empFilter = " and REPORTING_HEAD_ID = " + this.userId
  
      //   var status = "";
      //   if (this.STATUS == "P")
      //     status = " AND STATUS = 'FW' AND HR_STATUS = 'AP' AND HEAD_REMARK = '' ";
  
      //   else if (this.STATUS == "AP")
      //     status = " AND STATUS = 'AP'";
  
      //   else if (this.STATUS == "R")
      //     status = " AND STATUS ='R'";
  
      //   else if (this.STATUS == "FW")
      //     status = " AND STATUS = 'FW' AND HEAD_STATUS = 'AP'  AND HEAD_REMARK ! = ''";
  
  
  
      //     this.loadingRecords = true;
      //   this.api.getAllEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilter + status).subscribe(data => {
      //     if (data['code'] == 200) {
      //       this.isSpinning = false;
      //       this.loadingRecords = false;
      //       this.totalRecords = data['count'];
      //       this.tourRequestData = data['data'];
      //       this.isFilterApplied = "primary";
      //     }
  
      //   }, err => {
      //     console.log(err);
      //   });
      // }
  
      this.search(true)
      this.filterClass = "filter-invisible";
    }
  }
  