import { Injectable } from "@angular/core";
import { BehaviorSubject, concat, forkJoin, Observable, of } from "rxjs";
import { HttpHeaders, HttpClient, HttpEvent, HttpParams, HttpRequest } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { NzNotificationService } from "ng-zorro-antd";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { Application } from "../Models/application";
import { Module } from "../Models/module";
import { User } from "../Models/user";
import { ApplicationModuleMapping } from "../Models/application-module-mapping";
import { UserModuleMapping } from "../Models/user-module-mapping";
import { Role } from "../Models/role";
import { RoleModuleMapping } from "../Models/roleModulemapping";
import { Form } from "../Models/form";
import { Faqhead } from "../Models/faqhead";
import { Faq } from "../Models/faq";
import { Roledetails } from "../Models/roledetails";
import { Faqresponse } from "../Models/faqresponse";
import { Department } from "../Models/department";
import { DepartmentworkingDetails } from "../Models/departmentworking-details";
import { Emailserver } from "../Models/emailserver";
import { Smsserver } from "../Models/smsserver";
import { Emailhistory } from "../Models/emailhistory";
import { Smshistory } from "../Models/smshistory";
import { Folder } from "../Models/folder";
import { Ticket } from "../Models/ticket";
import { Ticketdetails } from "../Models/ticketdetails";
import { Useraccessmapping } from "../Models/useraccessmapping";
import { Task } from "../Models/task";
import { Viewhistory } from "../Models/viewhistory";
import { Ticketgroup } from "../Models/ticketgroup";
import { Ticketfaqmapping } from "../Models/ticketfaqmapping";
import { AWSTemplets } from "../Models/AWSTemplets";
import { AWSSendMail } from "../Models/AWSSendMail";
import { OrganizationMaster } from "../Models/organization-master";
import { fileMaster } from "../Models/fileMaster";
import { folderMaster } from "../Models/folderMaster";
import { Branchmaster } from "../Models/Branchmaster";
import { catchError } from "rxjs/operators";
import { FolderSharing } from "../Models/folderSharing";
import { LeaveType } from "../Models/leave-type";
import { Leave } from "../Models/Leave";
import { ExpensesHeadMaster } from "../Models/expenses-head-master";
import { EvaluationCriateria } from "../Models/evaluation-criateria";
import { CustomerMaster } from "../Models/customer-master";
import { HolidayMaster } from "../Models/holiday-master";
import { ThoughMaster } from "../Models/though-master";
import { Latemark } from "../Models/Latemark";
import { BirthdayMaster } from "../Models/birthday-master";
import { Earlymark } from "../Models/Earlymark";
import { Expensedetails } from "../Models/expensedetails";
import { Expenses } from "../Models/Expenses";
import { Employeecollegedetails } from "../Models/Employeecollegedetails";
import { Attendance } from "../Models/Attedance";
import { AttendanceReport } from "../Models/AttendanceReport";
import { EmployeeMaster } from "../Models/employeemaster";
import { LeaveReport } from "../Models/LeaveReport";
import { Earlymarkreport } from "../Models/Earlymarkreport";
import { ExpenseReport } from "../Models/ExpenseReport";
import { EvaluationReport } from "../Models/EvaluationReport";
import { AssetCategory } from "../Models/AssetCategory";
import { Asset } from "../Models/Asset";
import { AssetMapping } from "../Models/AssetMapping";
import { AssetRequest } from "../Models/AssetRequest";
import { ExpenseSummeryReport } from "../Models/ExpenseSummaryReport";
import { Attendanceregister } from "../Models/AttendanceRegister";
import { UpdateAttendanceRegister } from "../Models/UpdateAttendanceRegister";
import { ModelMaster } from "../Models/ModelMaster";
import { VarientMaster } from "../Models/VarientMaster";
import { PriceList } from "../Models/PriceList";
import { PriceListDetails } from "../Models/PriceListDetails";
import { ColorMaster } from "../Models/ColorMaster";
import { FuelMaster } from "../Models/FuelMaster";
import { TransmissionMaster } from "../Models/TransmissionMaster";
import { BankMaster } from "../Models/BankMaster";
import { advanceMaster } from "../Models/advanceMaster";
import { memoSignatureDetails } from "../Models/memoSignatureDetails";
import { questionaryMaster } from "../Models/questionaryMaster";
import { memoDetails } from "../Models/memoDetails";
import { memoMaster } from "../Models/memo";
// import { schedule } from "../pages/Schedulemaster/schedule";
// import { directIncentiveRatesDetails } from "../pages/Incentiveforms/Insuranceincentive/directIncentiveRateDetails";
// import { rateMaster } from "../pages/Incentiveforms/Salesincentive/IncentiveRateMaster";
// import { financeMaster } from "../pages/Incentiveforms/Financeincentive/FinanceMaster";
import { financeMaster } from 'src/app/Models/FinanceMaster';
import { rateMaster } from "../Models/IncentiveRateMaster";
import { directIncentiveRatesDetails } from "../Models/directIncentiveRateDetails";
import { SOTMaster } from "../Models/SOTmaster";
import { slabMaster } from "../pages/Incentiveforms/EWIncentive/slabMaster";
import { supportStaffIncentiveRatesDetails } from "../Models/supoortingstaff";
import { areaMaster } from "../Models/supportAreaMaster";
import { assetLog } from "../Models/assetLog";
import { leaveYearConfiguration } from "../pages/New folder/leaveyearconfiguration";
import { leavePolicy } from "../pages/New folder/leavePolicy";
// import { leaveMaster } from '../LeaveMaster';
import { leaveMaster } from "../pages/New folder/LeaveMaster";
import { activityMaster } from "../pages/ActivityMaster/ActivityMaster1/activityMaster";
import { activityTypeMaster } from "../pages/ActivityMaster/ActivityMaster1/activityType";
import { ActivityLeadMaster } from "../Models/ActivityLeadmaster";
import { actionMaster } from "../Models/actionMaster";
import { ShiftMaster } from "../Models/shift1";
import { schedule } from "src/app/Models/schedule";
import { payslip } from 'src/app/Models/payslip';
import { quotation } from "../Models/quotation";
import { NewCustomerMaster } from "../Models/newcustomer";
import { TourMaster } from "../Models/tourmaster";
import { tourExpenseMaster } from "../Models/tourExpenseMaster";
import { PSFActivityLeadMaster } from "../Models/psfaddacivity";
import { Log } from "../Models/logs";
import { feedback } from "../Models/feedformback";
import { feedbackforms } from "../Models/feedbackforms";
import { EvaluationMapping } from "../pages/DesignationMaster/evaluation-mapping";
// import { leaveMaster } from '../../LeaveMaster';
// import { assetLog } from 'src/app/Models/assetLog';
@Injectable({
  providedIn: "root",
})

export class ApiService {
  currentMessage = new BehaviorSubject(null);
  cloudID;
  clientId = 1;
  ORGANIZATION_NAME = '';

  httpHeaders = new HttpHeaders();
  options = {
    headers: this.httpHeaders,
  };

  httpHeaders1 = new HttpHeaders();
  options1 = {
    headers: this.httpHeaders1,
  };

  gmUrl = "https://gm.tecpool.in:8078/";

  // new url 
  // baseUrl = "https://api.maihyundai.in/";




  // 192.168.43.203

  // baseUrl = "http://192.168.29.194:9440/"

  //  192.168.29.197  
  // http://192.168.29.34:9440/

  // Live URL192.168.29.207     192.168.29.219

  baseUrl = "https://maihyundaihrms.uvtechsoft.com:9440/";
  // 
  // Development URL
  // baseUrl = "http://maihyundaihrms.tecpool.in:9443/";

  // new server MAi 

  // baseUrl = "https://dc56-2405-201-1011-10a3-a8c5-3afe-622-8fec.ngrok-free.app/";

  // https://6c96-157-119-205-11.ngrok-free.app; 

  url = this.baseUrl + "api/";
  //loggerUrl =  this.baseUrl + "logger/";
  imgUrl = this.baseUrl + "upload/";
  imgUrl1 = this.baseUrl + "api/upload/";
  apkUrl = this.baseUrl + "upload/App/";
  retriveimgUrl = this.baseUrl + "static/";
  applicationId = 1;

  moduleId = Number(this.cookie.get("moduleId"));
  userId = Number(this.cookie.get("userId"));
  roleId = Number(this.cookie.get("roleId"));
  orgId = Number(this.cookie.get("orgId"));
  deviceId = Number(this.cookie.get("deviceId"));
  ClusterId = Number(this.cookie.get("clusterId"));

  constructor(
    private cookie: CookieService,
    private message: NzNotificationService,
    private httpClient: HttpClient,
    private angularFireMessaging: AngularFireMessaging
  ) {
    if (
      this.cookie.get("deviceId") === "" ||
      this.cookie.get("deviceId") === null
    ) {
      var deviceId = this.randomstring(16);
      this.cookie.set(
        "deviceId",
        deviceId.toString(),
        365,
        "",
        "",
        false,
        "Strict"
      );
    }

    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  login(email: string, password: string): Observable<Useraccessmapping[]> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      username: email,
      password: password,
      TYPE: "W",
      DEVICE_ID: "A",
    };

    return this.httpClient.post<Useraccessmapping[]>(
      this.baseUrl + "user/login",
      JSON.stringify(data),
      this.options
    );
  }

  generate16DigitRandomNumber() {
    return String(Math.floor(Math.random() * 1e16));
  }

  finalDeviceID: string;

  employeelogin(
    email: string,
    password: string
  ): Observable<Useraccessmapping[]> {
    let deviceID = "";
    console.log(this.finalDeviceID);

    if (this.finalDeviceID != "") {
      deviceID = this.finalDeviceID;
      // console.log(deviceID,"");

    } else {
      deviceID = this.generate16DigitRandomNumber();
      // console.log(deviceID,"dev");
    }

    // set Final Device ID
    this.finalDeviceID = deviceID;
    this.cookie.set(
      "DEVICE_ID",
      String(deviceID),
      365,
      "",
      "",
      false,
      "Strict"
    );

    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      username: email,
      password: password,
      DEVICE_ID: deviceID,
    };

    return this.httpClient.post<Useraccessmapping[]>(
      this.baseUrl + "employee/login",
      JSON.stringify(data),
      this.options
    );
  }

  trainerAccessorlogin(
    email: string,
    password: string
  ): Observable<Useraccessmapping[]> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      username: email,
      password: password,
      TYPE: "W",
      DEVICE_ID: "A",
    };

    return this.httpClient.post<Useraccessmapping[]>(
      this.baseUrl + "trainer/login",
      JSON.stringify(data),
      this.options
    );
  }

  randomstring(L) {
    var s = "";

    var randomchar = function () {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    };

    while (s.length < L) s += randomchar();
    return s;
  }



  getsalesHierarchy(filter: string): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    }); this.options =
      { headers: this.httpHeaders, };
    var data = {
      // pageIndex: pageIndex,  
      // pageSize: pageSize,  
      // sortKey: sortKey,  
      // sortValue: sortValue,  
      filter: filter,
    };
    return this.httpClient.post<any>(this.url + 'salesHierarchy/get', JSON.stringify(data), this.options)
  }



  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload);
      this.message.info(
        payload["notification"]["title"],
        payload["notification"]["body"],
        { nzDuration: 0 }
      );
    });
  }

  subscribeTokenToTopic(token, topic) {
    // var bodyArray = []
    // for (var i = 0; i < channels.length; i++) {
    //   if (channels[i] != null && channels[i].trim() != '') {
    //     var b = {
    //       to: '/topics/' + channels[i], 'registration_tokens': [token]
    //     }
    //     bodyArray.push(b);
    //   }
    //   if (i == channels.length - 1) {

    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`,
    });

    var options22 = {
      headers: this.httpHeaders,
    };

    let httpReqs = topic.map((i) =>
      this.httpClient
        .post(
          `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${i}`,
          {},
          options22
        )
        .pipe(catchError((err) => of({ err })))
    );

    concat(...httpReqs).subscribe((data) => {
      window.location.reload();

      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    });

    // }
    // }
    return true;

    // fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    //   method: 'POST',
    //   headers: new Headers({
    //     Authorization: `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`
    //   })
    // })
    //   .then((response) => {
    //     if (response.status < 200 || response.status >= 400) {
    //       console.log(response.status, response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error.result);
    //   });
    // return true;
  }

  unsubscribeTokenToTopic(token) {
    var d = this.cookie.get("channels");
    var channels = d.split(",");
    var bodyArray = [];

    for (var i = 0; i < channels.length; i++) {
      if (channels[i] != null && channels[i].trim() != "") {
        var b = {
          to: "/topics/" + channels[i],
          registration_tokens: [token],
        };
        bodyArray.push(b);
      }

      if (i == channels.length - 1) {
        this.httpHeaders = new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`,
        });

        var options22 = {
          headers: this.httpHeaders,
        };

        let httpReqs = bodyArray.map((i) =>
          this.httpClient
            .post(`https://iid.googleapis.com/iid/v1:batchRemove`, i, options22)
            .pipe(catchError((err) => of({ err })))
        );

        concat(...httpReqs).subscribe((data) => {
          let deviceID = this.cookie.get("DEVICE_ID");
          this.cookie.deleteAll();
          this.cookie.set("DEVICE_ID", deviceID, 365, "", "", false, "Strict");
          sessionStorage.clear();
          window.location.reload();
        });
      }
    }

    return true;

    // fetch(`https://iid.googleapis.com/iid/v1:batchRemove`, {
    //   method: 'POST',
    //   body: JSON.stringify(b),
    //   headers: new Headers({
    //     'Content-Type': 'application/json',
    //     'Authorization': `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`
    //   })
    // })
    //   .then((response) => {
    //     if (response.status < 200 || response.status >= 400) {
    //       console.log(response.status, response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error.result);
    //   });
  }

  // public async updatePushToken(token: string,userId) {
  //   try {
  //     const devices = await this.angularFireMessaging.firestore.collection('Devices').where('token', '==', token).get();

  //     if (devices.empty) {
  //       const deviceInfo = this.deviceService.getDeviceInfo();
  //       const data = {
  //         token: token,
  //         userId: userId,
  //         deviceType: 'web',
  //         deviceInfo: {
  //           browser: deviceInfo.browser,
  //           userAgent: deviceInfo.userAgent
  //         },
  //         createdAt: firestore.FieldValue.serverTimestamp()
  //       };

  //       await this.angularFireMessaging.firestore.collection('Devices').add(data);
  //       console.log('New Device Added');
  //     } else {
  //       console.log('Already existing Device');
  //     }
  //   } catch (error) {
  //     console.log("Error Message", error);
  //   }
  // }

  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.cloudID = token;
        // this.subscribeTokenToTopic(this.cloudID, 'new');
        // this.updatePushToken(token,userId);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  onUploadNewMethod(selectedFile, ext, fKey) {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      f_key: fKey,
      f_ext: ext,
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });

    this.options1 = {
      headers: this.httpHeaders1,
    };

    const fd = new FormData();
    fd.append("F_DATA", selectedFile);
    fd.append("F_EXT", ext);
    fd.append("F_KEY", fKey);
    return this.httpClient.post(
      this.baseUrl + "file/upload",
      fd,
      this.options1
    );
  }

  getAdvanceReciept(pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string): Observable<any> {
    this.httpHeaders1 = new HttpHeaders
      ({
        supportkey: this.cookie.get("supportKey"),
        apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
        applicationkey: "AfIpESwBr5eHp7w3",
        Token: this.cookie.get("token"),
      });
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    }
    return this.httpClient.post<any>(this.url + 'advance/get', JSON.stringify(data), this.options)
  }

  getFile(lkey) {
    var data = {
      L_KEY: lkey,
    };
    return this.httpClient.post(
      this.baseUrl + "file/getFile",
      JSON.stringify(data),
      this.options
    );
  }

  onUpload(folderName, selectedFile, filename) {
    //console.log(this.httpHeaders1)
    const fd = new FormData();
    fd.append("Image", selectedFile, filename);
    //console.log("selected file" + JSON.stringify(selectedFile))
    //console.log("form data: " + fd)
    this.httpClient
      .post(this.imgUrl + folderName, fd, this.options1)
      .subscribe((res) => {
        //console.log(res);
      });
  }

  onUpload2(folderName, selectedFile, filename) {
    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options1 = {
      headers: this.httpHeaders1,
    };

    const fd = new FormData();
    fd.append("Image", selectedFile, filename);

    return this.httpClient.post(this.imgUrl + folderName, fd, this.options1);
  }

  onFileUploadWithProgress(
    folderName,
    selectedFile,
    filename
  ): Observable<HttpEvent<any>> {
    this.httpHeaders1 = new HttpHeaders({
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });

    const fd = new FormData();
    fd.append("Apk", selectedFile, filename);
    let params = new HttpParams();

    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest("POST", this.apkUrl, fd, options);
    return this.httpClient.request(req);
  }

  onFileUpload2(file): Observable<HttpEvent<any>> {
    this.httpHeaders1 = new HttpHeaders({
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });

    const fd = new FormData();
    fd.append("FILE", file);
    let params = new HttpParams();

    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest(
      "POST",
      this.url + "folder/upload/",
      fd,
      options
    );
    return this.httpClient.request(req);
  }

  loggerInit() {
    var data = {
      CLIENT_ID: this.clientId,
    };

    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      supportkey: this.cookie.get("supportKey"),
      apikey: "SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
      deviceid: this.cookie.get("deviceId"),
      "Content-Type": "application/json",
    });

    this.options1 = {
      headers: this.httpHeaders1,
    };

    return this.httpClient.post(
      this.gmUrl + "device/init",
      JSON.stringify(data),
      this.options1
    );
  }

  addLog(type, text, userId): Observable<number> {
    var data = {
      LOG_TYPE: type,
      LOG_TEXT: text,
      USER_ID: userId,
      CLIENT_ID: this.clientId,
    };

    return this.httpClient.post<number>(
      this.gmUrl + "device/addLog",
      JSON.stringify(data),
      this.options
    );
  }

  getAllclusterMaster(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "cluster/get",
      JSON.stringify(data),
      this.options
    );
  }

  createclusterMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "cluster/create/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  updateclusterMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "cluster/update/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  getAllclusterbranchMaster(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "clusterBranch/get",
      JSON.stringify(data),
      this.options
    );
  }

  createclusterbranchMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "clusterBranch/create/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  updateclusterbranchMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "clusterBranch/update/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  getAllemployeeMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {
      console.log("getemplyee1");
    } else {
      return this.httpClient.post<any>(this.url + "employee/get", JSON.stringify(data), this.options);
    }

  }

  createemployeeMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "employee/create/", JSON.stringify(ticketGroup), this.options);
  }

  updateemployeeMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "employee/update/", JSON.stringify(ticketGroup), this.options);
  }

  updateReportingPerson(data: any): Observable<any> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "salesHierarchy/deleteSalesMemoHead", JSON.stringify(data), this.options);
  }

  getAllOrganizations(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<OrganizationMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<OrganizationMaster[]>(this.url + "organisation/get", JSON.stringify(data), this.options);
  }

  createOrganization(organization: OrganizationMaster): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "organisation/addOrganisation/",
      JSON.stringify(organization),
      this.options
    );
  }

  updateOrganization(organization: OrganizationMaster): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "organisation/updateOrganisation/",
      JSON.stringify(organization),
      this.options
    );
  }

  changeemployeePassword(data: any): Observable<any> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "employee/changePassword", JSON.stringify(data), this.options);
  }

  changeUserPassword(data: any): Observable<any> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "user/changePassword", JSON.stringify(data), this.options);
  }

  getAllFeature(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<OrganizationMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "features/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFeature(organization: any): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "features/create/",
      JSON.stringify(organization),
      this.options
    );
  }

  updateFeature(organization: any): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "features/update/",
      JSON.stringify(organization),
      this.options
    );
  }

  getAllBranch(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Branchmaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {

    }
    else {
      return this.httpClient.post<any>(
        this.url + "branch/get",
        JSON.stringify(data),
        this.options
      );
    }

  }

  createBranch(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "branch/create/",
      JSON.stringify(department),
      this.options
    );
  }

  updateBranch(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "branch/update/",
      JSON.stringify(department),
      this.options
    );
  }

  getAllDesignation(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Department[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any[]>(
      this.url + "designation/get",
      JSON.stringify(data),
      this.options
    );
  }

  createDesignation(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "designation/create/",
      JSON.stringify(department),
      this.options
    );
  }

  updateDesignation(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "designation/update/",
      JSON.stringify(department),
      this.options
    );
  }

  getAllApplications(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Application[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Application[]>(
      this.url + "application/get",
      JSON.stringify(data),
      this.options
    );
  }

  createApplication(application: Application): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "application/create/",
      JSON.stringify(application),
      this.options
    );
  }

  updateApplication(application: Application): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "application/update/",
      JSON.stringify(application),
      this.options
    );
  }

  getAllForms(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Form[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Form[]>(
      this.url + "form/get",
      JSON.stringify(data),
      this.options
    );
  }

  createForm(form: Form): Observable<number> {
    form.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "form/create/",
      JSON.stringify(form),
      this.options
    );
  }

  updateForm(form: Form): Observable<number> {
    form.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "form/update/",
      JSON.stringify(form),
      this.options
    );
  }

  getAllRoles2(parentId: string, employeeId: number): Observable<Role[]> {
    var data = {
      parentId: parentId,
      employeeId: employeeId,
    };

    return this.httpClient.post<any[]>(
      this.url + "role/getChildRoles",
      JSON.stringify(data),
      this.options
    );
  }

  getAllRoles(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Role[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any[]>(
      this.url + "role/get",
      JSON.stringify(data),
      this.options
    );
  }

  createRole(application: any): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "role/create/",
      JSON.stringify(application),
      this.options
    );
  }

  updateRole(application: any): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "role/update/",
      JSON.stringify(application),
      this.options
    );
  }

  getRoleModules(roleId: number) {
    var data = {
      ROLE_ID: roleId,
    };

    return this.httpClient.post<RoleModuleMapping[]>(
      this.url + "roleModuleMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addRoleModuleDetails(roleId1: number, data1: string[]): Observable<number> {
    var data = {
      ROLE_ID: roleId1,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "roleModuleMapping/addBulk",
      data,
      this.options
    );
  }

  getAllModules(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Module[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Module[]>(
      this.url + "module/get",
      JSON.stringify(data),
      this.options
    );
  }

  createModule(module: Module): Observable<number> {
    module.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "module/create/",
      JSON.stringify(module),
      this.options
    );
  }

  updateModule(module: Module): Observable<number> {
    module.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "module/update/",
      JSON.stringify(module),
      this.options
    );
  }

  getAllUsers(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<User[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<User[]>(
      this.url + "user/get",
      JSON.stringify(data),
      this.options
    );
  }

  createUser(user: User): Observable<number> {
    user.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "user/create/",
      JSON.stringify(user),
      this.options
    );
  }

  updateUser(user: User): Observable<number> {
    user.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "user/update/",
      JSON.stringify(user),
      this.options
    );
  }

  getMappingModules(applicationId: number) {
    var data = {
      APPLICATION_ID: applicationId,
    };

    return this.httpClient.post<ApplicationModuleMapping[]>(
      this.url + "applicationModulemapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addMappingModules(
    applicationId1: number,
    data1: string[]
  ): Observable<number> {
    var data = {
      APPLICATION_ID: applicationId1,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "applicationModulemapping/addBulk",
      data,
      this.options
    );
  }

  getApplicationModuleMapping(userId: number) {
    var data = {
      USER_ID: userId,
    };

    return this.httpClient.post<UserModuleMapping[]>(
      this.url + "userModuleMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addApplicationModuleMapping(
    userId: number,
    data1: string[]
  ): Observable<number> {
    var data = {
      USER_ID: userId,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "userModuleMapping/addBulk",
      data,
      this.options
    );
  }

  getAllFaqHeads(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any[]>(
      this.url + "faqHead/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFaqHead(faqHead: Faqhead): Observable<number> {
    faqHead.APPLICATION_ID = this.applicationId;
    faqHead.CLIENT_ID = this.clientId;
    faqHead.STATUS = faqHead.STATUS ? 1 : 0;
    faqHead.IS_PARENT = faqHead.IS_PARENT ? 1 : 0;

    return this.httpClient.post<number>(
      this.url + "faqHead/create/",
      JSON.stringify(faqHead),
      this.options
    );
  }

  updateFaqHead(faqHead: Faqhead): Observable<number> {
    faqHead.APPLICATION_ID = this.applicationId;
    faqHead.CLIENT_ID = this.clientId;
    faqHead.STATUS = faqHead.STATUS ? 1 : 0;
    faqHead.IS_PARENT = faqHead.IS_PARENT ? 1 : 0;

    return this.httpClient.put<number>(
      this.url + "faqHead/update/",
      JSON.stringify(faqHead),
      this.options
    );
  }

  getAllFaqs(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any[]>(
      this.url + "faq/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFaq(faq: Faq): Observable<number> {
    faq["USER_ID"] = this.cookie.get("userId");
    faq.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(
      this.url + "faq/create/",
      JSON.stringify(faq),
      this.options
    );
  }

  updateFaq(faq: Faq): Observable<number> {
    faq["USER_ID"] = this.cookie.get("userId");
    faq.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(
      this.url + "faq/update/",
      JSON.stringify(faq),
      this.options
    );
  }

  getRoleDetails(roleId: number) {
    var data = {
      ROLE_ID: roleId,
    };

    return this.httpClient.post<Roledetails[]>(
      this.url + "roleDetails/getData",
      JSON.stringify(data),
      this.options
    );
  }

  createRoleDetails(roleId: any) {
    roleId.CLIENT_ID = this.clientId;
    return this.httpClient.post<Roledetails[]>(
      this.url + "roleDetails/create",
      JSON.stringify(roleId),
      this.options
    );
  }

  getForms(userId: string, roleId: number) {
    var data = {
      USER_ID: userId,
      ROLE_ID: roleId,
    };

    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
      deviceid: this.cookie.get("deviceId"),
      "Content-Type": "application/json",
    });

    this.options = {
      headers: this.httpHeaders1,
    };

    return this.httpClient.post<Roledetails>(
      this.url + "user/getForms",
      JSON.stringify(data),
      this.options
    );
  }

  // getForms(roleId: number) {
  //   var data = {
  //     ROLE_ID: roleId,
  //   };
  //   // return this.httpClient.post<Roledetails>(this.url + "user/getForms", JSON.stringify(data), this.options);
  // return this.httpClient.post<Roledetails>(this.url + "user/getMenu", JSON.stringify(data), this.options);
  // }

  addRoleDetails(roleId: number, data1: string[]): Observable<number> {
    var data = {
      ROLE_ID: roleId,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "roleDetails/addBulk",
      data,
      this.options
    );
  }

  getAllFaqResponses(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Faqresponse[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Faqresponse[]>(
      this.url + "faqResponses/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFaqResponse(data: any): Observable<number> {
    return this.httpClient.post<number>(
      this.url + "faqResponse/create/",
      data,
      this.options
    );
  }

  updateFaqResponse(data: Faqresponse): Observable<number> {
    return this.httpClient.put<number>(
      this.url + "faqResponse/update/",
      data,
      this.options
    );
  }

  getAllDepartments(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Department[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "department/get",
      JSON.stringify(data),
      this.options
    );
  }

  createDepartment(department: any): Observable<number> {
    // department.CALENDER_DATA = JSON.stringify(calenderData)
    // department.APPLICATION_ID = this.applicationId;
    // department.MODULE_ID = this.moduleId
    department.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(
      this.url + "department/create/",
      JSON.stringify(department),
      this.options
    );
  }

  updateDepartment(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "department/update/",
      JSON.stringify(department),
      this.options
    );
  }

  getAllEmailServers(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Emailserver[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Emailserver[]>(
      this.url + "emailServer/get",
      JSON.stringify(data),
      this.options
    );
  }

  createEmailServer(emailServer: Emailserver): Observable<number> {
    emailServer.APPLICATION_ID = this.applicationId;
    emailServer.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(
      this.url + "emailServer/create/",
      JSON.stringify(emailServer),
      this.options
    );
  }

  updateEmailServer(emailServer: Emailserver): Observable<number> {
    emailServer.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(
      this.url + "emailServer/update/",
      JSON.stringify(emailServer),
      this.options
    );
  }

  getAllSmsServers(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Smsserver[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Smsserver[]>(
      this.url + "smsServer/get",
      JSON.stringify(data),
      this.options
    );
  }

  createSmsServer(smsServer: Smsserver): Observable<number> {
    smsServer.SENDER_ID = 0;
    smsServer.APPLICATION_ID = this.applicationId;
    smsServer.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(
      this.url + "smsServer/create/",
      JSON.stringify(smsServer),
      this.options
    );
  }

  updateSmsServer(smsServer: Smsserver): Observable<number> {
    smsServer.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(
      this.url + "smsServer/update/",
      JSON.stringify(smsServer),
      this.options
    );
  }

  getAllEmailHistory(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Emailhistory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Emailhistory[]>(
      this.url + "emailHistory/get",
      JSON.stringify(data),
      this.options
    );
  }

  updateEmailHistory(emailHistory: Emailhistory): Observable<number> {
    emailHistory.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(
      this.url + "emailHistory/update/",
      JSON.stringify(emailHistory),
      this.options
    );
  }

  getAllSmsHistory(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Smshistory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Smshistory[]>(
      this.url + "smsHistory/get",
      JSON.stringify(data),
      this.options
    );
  }

  updateSmsHistory(smsHistory: Smshistory): Observable<number> {
    smsHistory.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(
      this.url + "smsHistory/update/",
      JSON.stringify(smsHistory),
      this.options
    );
  }

  getAllFolders(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Folder[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Folder[]>(
      this.url + "folder/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFolder(folder: Folder): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "folder/create/",
      JSON.stringify(folder),
      this.options
    );
  }

  updateFolder(folder: Folder): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "folder/update/",
      JSON.stringify(folder),
      this.options
    );
  }

  getAllDepartmentWorkingDetails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<DepartmentworkingDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<DepartmentworkingDetails[]>(
      this.url + "departmentWorkingDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  updateDepartmentWorkingDetails(
    departmentDetails: DepartmentworkingDetails
  ): Observable<number> {
    departmentDetails.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "departmentWorkingDetails/update/",
      JSON.stringify(departmentDetails),
      this.options
    );
  }

  getAllTickets(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Ticket[]>(
      this.url + "ticket/get",
      JSON.stringify(data),
      this.options
    );
  }

  updateTicket(ticket: Ticket): Observable<number> {
    ticket.CLIENT_ID = this.clientId;
    ticket["KEY"] =
      ticket["KEY"] != undefined && ticket["KEY"] == "USER"
        ? "USER"
        : "SUPPORT_USER";
    return this.httpClient.put<number>(
      this.url + "ticket/update/",
      JSON.stringify(ticket),
      this.options
    );
  }

  getAllTicketDetails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Ticketdetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Ticketdetails[]>(
      this.url + "ticketDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTicketDetail(ticketDetail: Ticketdetails): Observable<number> {
    return this.httpClient.post<number>(
      this.url + "ticketDetails/create/",
      JSON.stringify(ticketDetail),
      this.options
    );
  }

  createTicket(ticket: any): Observable<number> {
    ticket["ORG_ID"] = Number(this.cookie.get("orgId"));
    return this.httpClient.post<number>(
      this.url + "ticket/create/",
      JSON.stringify(ticket),
      this.options
    );
  }

  AssignApplicationsData(data1: string[]): Observable<number> {
    var data = {
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "userAccessMapping/addBulk",
      data,
      this.options
    );
  }

  getAllUserAccessMapping(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Useraccessmapping[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Useraccessmapping[]>(
      this.url + "userAccessMapping/getData",
      JSON.stringify(data),
      this.options
    );
  }

  getApplicationsAssigned(roleId: number, userId: number) {
    var data = {
      ROLE_ID: roleId,
      USER_ID: userId,
    };

    return this.httpClient.post<Useraccessmapping[]>(
      this.url + "userAccessMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllTasks(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Task[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Task[]>(
      this.url + "task/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTask(task: Task): Observable<number> {
    task.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "task/create/",
      JSON.stringify(task),
      this.options
    );
  }

  updateTask(task: Task): Observable<number> {
    task.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "task/update/",
      JSON.stringify(task),
      this.options
    );
  }

  getAllScheduleHistory(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Viewhistory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Viewhistory[]>(
      this.url + "task/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllLogHistory(applicationId, supportKey) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/string",
      apiKey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportKey: this.cookie.get("supportKey"),
      TOKEN: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      APPLICATION_ID: applicationId,
      SUPPORT_KEY: supportKey,
    };

    return this.httpClient.post(
      this.url + "device/getDeviceLogs",
      data,
      this.options
    );
  }

  getAllLogUserHistory(type, email, applicationId) {
    var data = {
      TYPE: type,
      TEXT: email,
      APPLICATION_ID: applicationId,
    };

    return this.httpClient.post(
      this.url + "device/getLogs",
      JSON.stringify(data),
      this.options
    );
  }

  getAllTicketGroups(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Ticketgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Ticketgroup[]>(
      this.url + "ticketGroup/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTicketGroup(ticketGroup: Ticketgroup): Observable<number> {
    ticketGroup.IS_LAST = ticketGroup.IS_LAST ? 1 : 0;
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "ticketGroup/create/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  updateTicketGroup(ticketGroup: Ticketgroup): Observable<number> {
    ticketGroup.IS_LAST = ticketGroup.IS_LAST ? 1 : 0;
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "ticketGroup/update/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  getMappingFaqs2(ticketId: number) {
    var data = {
      TICKET_GROUP_ID: ticketId,
      ORG_ID: Number(this.cookie.get("orgId")),
    };

    return this.httpClient.post<Ticketfaqmapping[]>(
      this.url + "ticketFaqMapping/getTicketFaqMapping",
      JSON.stringify(data),
      this.options
    );
  }

  getMappingFaqs(ticketId: number, headID: number) {
    var data = {
      TICKET_GROUP_ID: ticketId,
      FAQ_HEAD_ID: headID,
      ORG_ID: Number(this.cookie.get("orgId")),
    };

    return this.httpClient.post<Ticketfaqmapping[]>(
      this.url + "ticketFaqMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addMappingFaqs(ticketId: number, data1: string[]): Observable<number> {
    var data = {
      TICKET_GROUP_ID: ticketId,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "ticketFaqMapping/addBulk",
      data,
      this.options
    );
  }

  getAllModuleRoles(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Role[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Role[]>(
      this.url + "userAccessMapping/getData",
      JSON.stringify(data),
      this.options
    );
  }

  getScheduledEmails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<AWSTemplets[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<AWSTemplets[]>(
      this.url + "emailSchedular/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllAwsTemplates(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<AWSTemplets[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<AWSTemplets[]>(
      this.url + "awsEmailTemplate/get",
      JSON.stringify(data),
      this.options
    );
  }

  createAwsTemplates(awsTemplates: AWSTemplets): Observable<number> {
    awsTemplates.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "awsEmailTemplate/create/",
      JSON.stringify(awsTemplates),
      this.options
    );
  }

  sendAWSMails(awsMailData: AWSSendMail): Observable<number> {
    return this.httpClient.post<number>(
      this.url + "awsEmailSender/send/",
      JSON.stringify(awsMailData),
      this.options
    );
  }

  scheduleMails(awsMailData: AWSSendMail): Observable<number> {
    var data = {
      TEMPLATE_ID: awsMailData.TEMPLATE_ID,
      SHEET_KEY: awsMailData.SHEET_ID,
      TOTAL_RECORDS: awsMailData.ROWS_COUNT,
      START_TIME: awsMailData.START_TIME,
      IS_SCHEDULED: awsMailData.IS_SCHEDULED,
      CLIENT_ID: 1,
    };

    return this.httpClient.post<number>(
      this.url + "emailSchedular/create/",
      JSON.stringify(data),
      this.options
    );
  }

  updateAwsTemplates(awsTemplates: AWSTemplets): Observable<number> {
    awsTemplates.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "awsEmailTemplate/update/",
      JSON.stringify(awsTemplates),
      this.options
    );
  }

  getDashboardReport(
    APPLICATION_KEY: string,
    DEPARTMENT,
    USER_ID,
    FROM_DATE,
    TO_DATE
  ): Observable<Ticket[]> {
    var data = {
      APPLICATION_KEY: APPLICATION_KEY,
      DEPARTMENT_ID: DEPARTMENT,
      USER_ID: USER_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
    };

    return this.httpClient.post<Ticket[]>(
      this.url + "ticket/getDashboardReport",
      JSON.stringify(data),
      this.options
    );
  }

  getUserwiseReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    APPLICATION_KEY,
    DEPARTMENT
  ): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: this.cookie.get("orgId"),
    };

    return this.httpClient.post<Ticket[]>(
      this.url + "ticket/getUserwiseReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDepartmentwiseReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    APPLICATION_KEY,
    DEPARTMENT
  ): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: this.cookie.get("orgId"),
      DEPARTMENT_ID: DEPARTMENT,
    };

    return this.httpClient.post<Ticket[]>(
      this.url + "ticket/getDepartmentwiseReport",
      JSON.stringify(data),
      this.options
    );
  }

  getAllEmpRoleMap(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "employeeRoleMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  createEmpRoleMap(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    ticketGroup.PARENT_ID = ticketGroup.PARENT_ROLE_ID;
    ticketGroup.STATUS = ticketGroup.STATUS ? 1 : 0;
    ticketGroup.REPORTING_EMP_ID = ticketGroup.REPORTING_EMP_ID

    return this.httpClient.post<any>(
      this.url + "employeeRoleMapping/create/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  createEmpRoleMap1(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    ticketGroup.PARENT_ID = ticketGroup.PARENT_ROLE_ID;
    ticketGroup.STATUS = ticketGroup.STATUS ? 1 : 0;

    return this.httpClient.post<any>(
      this.url + "employeeRoleMapping/addRole/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  updateEmpRoleMap(ticketGroup: any): Observable<any> {
    ticketGroup.PARENT_ID = ticketGroup.PARENT_ROLE_ID;
    ticketGroup.CLIENT_ID = this.clientId;
    ticketGroup.STATUS = ticketGroup.STATUS ? 1 : 0;

    return this.httpClient.put<any>(
      this.url + "employeeRoleMapping/update/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  deleteEmpRoleMap(data: any): Observable<any> {
    return this.httpClient.post<any>(
      this.url + "employeeRoleMapping/delete/",
      JSON.stringify(data),
      this.options
    );
  }

  deleteEmpRoleMap1(data: any): Observable<any> {
    return this.httpClient.post<any>(
      this.url + "employeeRoleMapping/updateRole/",
      JSON.stringify(data),
      this.options
    );
  }

  gettickdeskDepartmentAdminMapping(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "tickdeskDepartmentAdminMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  createEmpRoleMapBulk(ticketGroup: any, EMPLOYEE_ID: any): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      data: ticketGroup,
    };

    return this.httpClient.post<any>(
      this.url + "tickdeskDepartmentAdminMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  tickdeskSupportUserMapping(
    ticketGroup: any,
    EMPLOYEE_ID: any
  ): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      data: ticketGroup,
    };

    return this.httpClient.post<any>(
      this.url + "tickdeskSupportUserMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  salesExecutiveManagerHeadMapping(
    headMappingData: any,
    EMPLOYEE_ID: any
  ): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      data: headMappingData,
    };

    return this.httpClient.post<any>(
      this.url + "salesHeadMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  featuresRoleMapping(ticketGroup: any, FEATURE_ID: number): Observable<any> {
    var data = {
      FEATURE_ID: FEATURE_ID,
      data: ticketGroup,
    };

    return this.httpClient.post<any>(
      this.url + "featuresRoleMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  organisationFeatureMapping(
    ticketGroup: any,
    ORG_ID: number
  ): Observable<any> {
    var data = {
      ORG_ID: ORG_ID,
      data: ticketGroup,
    };

    return this.httpClient.post<any>(
      this.url + "organisationFeatureMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  gettickdeskSupportUserMapping(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "tickdeskSupportUserMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFolderSharigDetailsBulk(
    folderID: any,
    folderData: any
  ): Observable<any> {
    var data = {
      FOLDER_ID: folderID,
      data: folderData,
    };

    return this.httpClient.post<any>(
      this.url + "folderSharingDetails/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  createFileSharigDetailsBulk(fileID: any, fileData: any): Observable<any> {
    var data = {
      FILE_ID: fileID,
      data: fileData,
    };

    return this.httpClient.post<any>(
      this.url + "fileSharingDetails/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  checkTextBoxIsValid1(value: any) {
    const expression = /^[A-Za-z1-9 ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  getChannels(): Observable<number> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apiKey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportKey: this.cookie.get("supportKey"),
      TOKEN: this.cookie.get("token"),
      DEVICE_ID: this.cookie.get("DEVICE_ID")
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      CLOUD_ID: this.cloudID,
      EMPLOYEE_ID: Number(this.cookie.get("userId")),
    };

    return this.httpClient.post<any>(
      this.url + "employee/submitToken/",
      JSON.stringify(data),
      this.options
    );
  }

  logout(): Observable<number> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apiKey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportKey: this.cookie.get("supportKey"),
      TOKEN: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      EMPLOYEE_ID: Number(this.cookie.get("userId")),
    };

    return this.httpClient.post<any>(
      this.url + "employee/clearToken/",
      JSON.stringify(data),
      this.options
    );
  }

  getAllnotification(): Observable<number> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apiKey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportKey: this.cookie.get("supportKey"),
      TOKEN: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      EMPLOYEE_ID: Number(this.cookie.get("userId")),
    };

    return this.httpClient.post<any>(
      this.url + "employee/getNotifications/",
      JSON.stringify(data),
      this.options
    );
  }

  // Folder (22-06-2022, by: Ashish)
  // getAllMyNotications(
  //   pageIndex: number,
  //   pageSize: number,
  //   empId: number,
  //   deptId: number,
  //   branchId: number,
  //   designationId: number,
  //   orgId: number
  // ): Observable<folderMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     EMPLOYEE_ID: empId,
  //     DEPT_ID: deptId,
  //     BRANCH_ID: branchId,
  //     DESIGNATION_ID: designationId,
  //     ORG_ID: orgId,
  //   };

  //   return this.httpClient.post<folderMaster[]>(
  //     this.url + "notification/getNotifications",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }


  getAllMyNotications(

    pageIndex: number,

    pageSize: number,

    empId: number,

    deptId: number,

    branchId: number,

    designationId: number,

    orgId: number,

    filter: any

  ): Observable<folderMaster[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      EMPLOYEE_ID: empId,

      DEPT_ID: deptId,

      BRANCH_ID: branchId,

      DESIGNATION_ID: designationId,

      ORG_ID: orgId,

      filter: filter,

    };




    return this.httpClient.post<folderMaster[]>(

      this.url + "notification/getNotifications",

      JSON.stringify(data),

      this.options

    );

  }

  getAllMyserviceNotications(

    pageIndex: number,

    pageSize: number,

    empId: number,

    deptId: number,

    branchId: number,

    designationId: number,

    orgId: number,

    filter: any

  ): Observable<folderMaster[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      EMPLOYEE_ID: empId,

      DEPT_ID: deptId,

      BRANCH_ID: branchId,

      DESIGNATION_ID: designationId,

      ORG_ID: orgId,

      filter: filter,

    };




    return this.httpClient.post<folderMaster[]>(

      this.url + "getServiceNotification/get",

      JSON.stringify(data),

      this.options

    );

  }

  // Folder (26-05-2022, by: Ashish)
  getAllMyFolders(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<folderMaster[]>(
      this.url + "folder/get",
      JSON.stringify(data),
      this.options
    );
  }

  // Searching Folders and Files (20-06-2022, by: Ashish)
  getAllMyFoldersFilesOnSearchig(
    empID: number,
    deptID: number,
    branchID: number,
    designationID: number,
    searchText: string
  ): Observable<folderMaster[]> {
    var data = {
      EMPLOYEE_ID: empID,
      DEPARTMENT_ID: deptID,
      BRANCH_ID: branchID,
      DESIGNATION_ID: designationID,
      SEARCH_TEXT: searchText,
    };

    return this.httpClient.post<folderMaster[]>(
      this.url + "folder/searchFile",
      JSON.stringify(data),
      this.options
    );
  }

  sendNoti(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<folderMaster[]>(
      this.url + "folder/generate",
      JSON.stringify(data),
      this.options
    );
  }

  createNewMyFolder(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "folder/create/",
      JSON.stringify(folder),
      this.options
    );
  }

  updateExistingMyFolder(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "folder/update/",
      JSON.stringify(folder),
      this.options
    );
  }

  // File (26-05-2022, by: Ashish)
  getAllFiles(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<fileMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<fileMaster[]>(
      this.url + "file/get",
      JSON.stringify(data),
      this.options
    );
  }

  createNewFile(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "file/create/",
      JSON.stringify(file),
      this.options1
    );
  }

  updateExistingFile(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "file/update/",
      JSON.stringify(file),
      this.options
    );
  }

  onFileUpload(folderID, file, ownerID, tag, desc) {
    this.httpHeaders1 = new HttpHeaders({
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });

    this.options1 = {
      headers: this.httpHeaders1,
    };

    const fd = new FormData();
    fd.append("PARENT_ID", folderID);
    fd.append("FOLDER_FILE", "L");
    fd.append("FILE", file);
    fd.append("OWNER_ID", ownerID);
    fd.append("TAGS", tag);
    fd.append("DESCRIPTION", desc);

    return this.httpClient.post(this.url + "folder/upload/", fd, this.options1);
  }

  onFileUpload1(
    folderID,
    file,
    ownerID,
    tag,
    desc
  ): Observable<HttpEvent<any>> {
    this.httpHeaders1 = new HttpHeaders({
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });

    const fd = new FormData();
    fd.append("PARENT_ID", folderID);
    fd.append("FOLDER_FILE", "L");
    fd.append("FILE", file);
    fd.append("OWNER_ID", ownerID);
    fd.append("TAGS", tag);
    fd.append("DESCRIPTION", desc);

    let params = new HttpParams();

    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest(
      "POST",
      this.url + "folder/upload/",
      fd,
      options
    );
    return this.httpClient.request(req);
  }

  // File Download (25-08-2022, by: Ashish)
  downloadFileWithProgress(
    empId: number,
    accessType: string,
    url: string,
    fileId: number
  ): Observable<HttpEvent<Blob>> {
    var data = {
      EMPLOYEE_ID: empId,
      URL: url,
      ACCESS_TYPE: accessType,
      FOLDER_ID: fileId,
    };

    let params = new HttpParams();
    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest(
      "GET",
      this.url +
      "folder/downloadFile?EMPLOYEE_ID=" +
      empId +
      "&FOLDER_ID=" +
      fileId,
      options
    );
    return this.httpClient.request(req);
  }

  // Folder Sharing (30-05-2022, by: Ashish)
  getAllFolderSharigDetails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<folderMaster[]>(
      this.url + "folderSharingDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateList(folderId, empid, roleId, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ROLE_ID: roleId,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "folderSharingDetails/getSubOrdinateExceptShared",
      JSON.stringify(data),
      this.options
    );
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateDepartmentList(folderId, empid, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "folderSharingDetails/getDepartmentExceptShared",
      JSON.stringify(data),
      this.options
    );
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateBranchList(folderId, empid, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "folderSharingDetails/getBranchExceptShared",
      JSON.stringify(data),
      this.options
    );
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateDesignationList(folderId, empid, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "folderSharingDetails/getDesignationExceptShared",
      JSON.stringify(data),
      this.options
    );
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateList(empid, roleId, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ROLE_ID: roleId,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "notification/getSubOrdinateExceptNotified",
      JSON.stringify(data),
      this.options
    );
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateDepartmentList(empid, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "notification/getDepartmentNotified",
      JSON.stringify(data),
      this.options
    );
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateBranchList(empid, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "notification/getBranchNotified",
      JSON.stringify(data),
      this.options
    );
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateDesignationList(empid, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "notification/getDesignationNotified",
      JSON.stringify(data),
      this.options
    );
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateEntireList(orgId): Observable<[]> {
    var data = {
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "notification/getEntireOrganisationNotified",
      JSON.stringify(data),
      this.options
    );
  }

  updateFolderSharingDetails(folder: FolderSharing): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "folderSharingDetails/update/",
      JSON.stringify(folder),
      this.options
    );
  }

  removeFolderSharingDetails(folder: FolderSharing): Observable<number> {
    var data = {
      ID: folder.ID,
    };
    return this.httpClient.post<number>(
      this.url + "folderSharingDetails/removeAccess/",
      JSON.stringify(data),
      this.options
    );
  }

  // Folder Sharing (10-06-2022, by: Ashish)
  folderSharigDetailsAddBulk(
    folderID: number,
    sharingType: any,
    empID: number,
    empData: any
  ): Observable<[]> {
    for (var i = 0; i < empData.length; i++) {
      empData[i].CLIENT_ID = this.clientId;
    }

    var data = {
      FOLDER_ID: folderID,
      SHARING_TYPE: sharingType,
      EMPLOYEE_ID: empID,
      data: empData,
      IS_PANEL: 0,
    };

    return this.httpClient.post<[]>(
      this.url + "folderSharingDetails/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  // Notification Sharing (22-06-2022, by: Ashish)
  notiDetailsAddBulk(
    empID: number,
    title: string,
    desc: string,
    sharingType: number,
    nData: any,
    orgId: number,
    imageUrl: string,
    notiType: string
  ): Observable<[]> {
    for (var i = 0; i < nData.length; i++) {
      nData[i].CLIENT_ID = this.clientId;
    }

    var data = {
      TITLE: title,
      DESCRIPTION: desc,
      data: nData,
      SHARING_TYPE: sharingType,
      EMPLOYEE_ID: empID,
      ORG_ID: orgId,
      IS_PANEL: 1,
      ATTACHMENT: imageUrl,
      TYPE: notiType,
    };

    return this.httpClient.post<[]>(
      this.url + "notification/sendNotification",
      JSON.stringify(data),
      this.options
    );
  }

  createFolderSharigDetails(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "folderSharingDetails/create/",
      JSON.stringify(folder),
      this.options1
    );
  }

  updateExistingFolderSharigDetails(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "folderSharingDetails/update/",
      JSON.stringify(folder),
      this.options
    );
  }

  // File Sharing (30-05-2022, by: Ashish)
  getAllFileSharigDetails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<fileMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<fileMaster[]>(
      this.url + "fileSharingDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  // Get Shared Folder(s) Details (19-06-2022, by: Ashish)
  getAllSharedFolderDetails(
    pageIndex: number,
    pageSize: number,
    folderID: number,
    empID: number,
    orgID: number,
    branchID: number,
    deptID: number,
    designationID: number,
    accessType: string
  ): Observable<fileMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      FOLDER_ID: folderID,
      EMPLOYEE_ID: empID,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      DEPT_ID: deptID,
      DESIGNATION_ID: designationID,
      ACCESS_TYPE: accessType,
    };

    return this.httpClient.post<fileMaster[]>(
      this.url + "folderSharingDetails/getSharedFolders",
      JSON.stringify(data),
      this.options
    );
  }

  // File Sharing (10-06-2022, by: Ashish)
  fileSharigDetailsAddBulk(fileID: number, fileData: any): Observable<[]> {
    fileData.CLIENT_ID = this.clientId;

    var data = {
      FILE_ID: fileID,
      data: [fileData],
    };

    return this.httpClient.post<[]>(
      this.url + "fileSharingDetails/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  // File Download (23-06-2022, by: Ashish)
  downloadFile(
    empId: number,
    accessType: string,
    url: string,
    fileId: number
  ): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empId,
      URL: url,
      ACCESS_TYPE: accessType,
      ID: fileId,
    };

    return this.httpClient.post<[]>(
      this.url + "folder/downloadFile",
      JSON.stringify(data),
      this.options
    );
  }

  // File Download (23-06-2022, by: Ashish)
  downloadFile1(
    empId: number,
    accessType: string,
    url: string,
    fileId: number
  ): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empId,
      URL: url,
      ACCESS_TYPE: accessType,
      FOLDER_ID: fileId,
    };

    return this.httpClient.get<[]>(
      this.url +
      "folder/downloadFile?EMPLOYEE_ID=" +
      empId +
      "&FOLDER_ID=" +
      fileId,
      this.options
    );
  }

  createFileSharigDetails(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "fileSharingDetails/create/",
      JSON.stringify(file),
      this.options1
    );
  }

  updateExistingFileSharigDetails(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "fileSharingDetails/update/",
      JSON.stringify(file),
      this.options
    );
  }

  // Text box Validation
  checkTextBoxIsValid(value: any) {
    const expression = /^[A-Za-z0-9 ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  // Text box Validation
  checkTextBoxIsValidWithRoundBracketsDotPlus(value: any) {
    const expression = /^[A-Za-z0-9().+ ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  transferTicket(ticket: Ticket): Observable<number> {
    ticket.CLIENT_ID = this.clientId;
    ticket["KEY"] =
      ticket["KEY"] != undefined && ticket["KEY"] == "USER"
        ? "USER"
        : "SUPPORT_USER";
    return this.httpClient.put<number>(
      this.url + "ticket/update/",
      JSON.stringify(ticket),
      this.options
    );
  }

  // Text box Validation
  checkTextBoxIsValidWithComma(value: any) {
    const expression = /^[A-Za-z0-9, ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  getTicketGroupParent(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any[]>(
      this.url + "ticketGroup/getParent",
      JSON.stringify(data),
      this.options
    );
  }

  getOptionwiseReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    APPLICATION_KEY,
    DEPARTMENT
  ): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: this.cookie.get("orgId"),
      DEPARTMENT_ID: DEPARTMENT,
    };

    return this.httpClient.post<Ticket[]>(
      this.url + "ticket/getOptionWiseCount",
      JSON.stringify(data),
      this.options
    );
  }

  getBreadInChat(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    DEPARTMENT,
    filter: string,
    TICKET_GROUP_ID: any
  ): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      TICKET_GROUP_ID: TICKET_GROUP_ID,
      filter: filter,
      ORG_ID: this.cookie.get("orgId"),
    };

    return this.httpClient.post<Ticket[]>(
      this.url + "ticketGroup/getParent",
      JSON.stringify(data),
      this.options
    );
  }

  getcountData(fromDate: any, toDate: any, orgId: number) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "scheduler/sendEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  sendEmail(fromDate: any, toDate: any, orgId: number) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(
      this.url + "scheduler/sendEmail",
      JSON.stringify(data),
      this.options
    );
  }

  // EMM Component's Services
  getAllLeaveType(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<LeaveType[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<LeaveType[]>(
      this.url + "leaveType/get",
      JSON.stringify(data),
      this.options
    );
  }

  createLeaveType(leavetype: LeaveType): Observable<number> {
    leavetype.ORGANIZATION_ID = this.orgId;
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "leaveType/create",
      JSON.stringify(leavetype),
      this.options
    );
  }

  updateLeaveType(leavetype: LeaveType): Observable<number> {
    leavetype.ORGANIZATION_ID = this.orgId;
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "leaveType/update",
      JSON.stringify(leavetype),
      this.options
    );
  }

  getAllEmployeeLeave(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Leave[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Leave[]>(
      this.url + "employeeLeaveApplication/get",
      JSON.stringify(data),
      this.options
    );
  }

  createLeave(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "employeeLeaveApplication/add", JSON.stringify(leave), this.options);
  }

  updateLeave(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "employeeLeaveApplication/update", JSON.stringify(leave), this.options);
  }

  getAllExpenseHead(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<ExpensesHeadMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<ExpensesHeadMaster[]>(
      this.url + "expensehead/get",
      JSON.stringify(data),
      this.options
    );
  }

  createExpenseHead(expenseHead: ExpensesHeadMaster): Observable<number> {
    expenseHead.ORGANIZATION_ID = +this.orgId;
    expenseHead.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "expensehead/create/",
      JSON.stringify(expenseHead),
      this.options
    );
  }

  updateExpenseHead(expenseHead: ExpensesHeadMaster): Observable<number> {
    expenseHead.ORGANIZATION_ID = +this.orgId;
    expenseHead.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "expensehead/update/",
      JSON.stringify(expenseHead),
      this.options
    );
  }

  getAllEmployeeExpenses(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Expenses[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Expenses[]>(
      this.url + "expenseTransaction/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllEmployeeSplitDetails(transID: any): Observable<[]> {
    var data = {
      EXPENSE_TRANSACTION_ID: transID,
    };

    return this.httpClient.post<[]>(
      this.url + "expenseSplitBillDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  createEmployeeExpenses(expenses: Expenses): Observable<number> {
    expenses.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "expenseTransaction/add",
      JSON.stringify(expenses),
      this.options
    );
  }

  updateEmployeeExpenses(expenses: Expenses): Observable<number> {
    expenses.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "expenseTransaction/update",
      JSON.stringify(expenses),
      this.options
    );
  }

  getAllEvaluationCriteria(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<EvaluationCriateria[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<EvaluationCriateria[]>(
      this.url + "evaluationCriteria/get",
      JSON.stringify(data),
      this.options
    );
  }

  createEvaluationCriteria(
    evaluationCriteria: EvaluationCriateria
  ): Observable<number> {
    evaluationCriteria.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "evaluationCriteria/create",
      JSON.stringify(evaluationCriteria),
      this.options
    );
  }

  updateEvaluationCriteria(
    evaluationCriteria: EvaluationCriateria
  ): Observable<number> {
    evaluationCriteria.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "evaluationCriteria/update",
      JSON.stringify(evaluationCriteria),
      this.options
    );
  }

  getAllEmployeesSplitBill(
    orgID: number,
    empID: number,
    designationID: number
  ): Observable<[]> {
    var data = {
      ORG_ID: orgID,
      EMPLOYEE_ID: empID,
      DESIGNATION_ID: designationID,
    };

    return this.httpClient.post<[]>(
      this.url + "employee/getEmployeeList",
      JSON.stringify(data),
      this.options
    );
  }

  getAllCustomers(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<CustomerMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<CustomerMaster[]>(
      this.url + "customer/get",
      JSON.stringify(data),
      this.options
    );
  }

  createCustomer(customer: CustomerMaster): Observable<number> {
    customer.CLIENT_ID = this.clientId;
    customer.ORGANIZATION_ID = this.orgId;

    return this.httpClient.post<number>(
      this.url + "customer/create",
      JSON.stringify(customer),
      this.options
    );
  }

  updateCustomer(customer: CustomerMaster): Observable<number> {
    customer.CLIENT_ID = this.clientId;
    customer.ORGANIZATION_ID = this.orgId;

    return this.httpClient.put<number>(
      this.url + "customer/update",
      JSON.stringify(customer),
      this.options
    );
  }

  getAllHolidays(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<HolidayMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<HolidayMaster[]>(
      this.url + "holiday/get",
      JSON.stringify(data),
      this.options
    );
  }

  createHoliday(holiday: HolidayMaster): Observable<number> {
    return this.httpClient.post<number>(
      this.url + "holiday/create/",
      JSON.stringify(holiday),
      this.options
    );
  }

  updateHoliday(holiday: HolidayMaster): Observable<number> {
    return this.httpClient.put<number>(
      this.url + "holiday/update/",
      JSON.stringify(holiday),
      this.options
    );
  }

  updateHoliday1(holiday: HolidayMaster): Observable<number> {
    return this.httpClient.put<number>(
      this.url + "holiday/updateHoliday/",
      JSON.stringify(holiday),
      this.options
    );
  }

  // addbranch(
  //   holidayid: number,
  //   data1:string[]
  // ): Observable<any> {
  //   var data = {
  //     ID: holidayid,
  //     data:data1
  //   };

  //   return this.httpClient.post<any>(
  //     this.url + "holidayBranchMapping/addBulk",
  //     data,
  //     this.options
  //   );
  // }

  addbranch(holiday_id: number, branchmap: any): Observable<any> {
    var data = {
      HOLIDAY_ID: holiday_id,
      data: branchmap,

    };
    return this.httpClient.post<any>(this.url + 'holidayBranchMapping/addBulk', JSON.stringify(data), this.options)
  }
  getAllThough(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<ThoughMaster[]>(
      this.url + "thoughts/get",
      JSON.stringify(data),
      this.options
    );
  }

  createThough(though: ThoughMaster): Observable<number> {
    though.CLIENT_ID = this.clientId;
    though.TYPE = "";
    return this.httpClient.post<number>(
      this.url + "thoughts/create/",
      JSON.stringify(though),
      this.options
    );
  }

  updateThough(though: ThoughMaster): Observable<number> {
    though.TYPE = "";
    though.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "thoughts/update/",
      JSON.stringify(though),
      this.options
    );
  }

  getAllBirthday(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<BirthdayMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<BirthdayMaster[]>(
      this.url + "birthday/get",
      JSON.stringify(data),
      this.options
    );
  }

  createBirthday(birthday: BirthdayMaster): Observable<number> {
    birthday.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "birthday/create",
      JSON.stringify(birthday),
      this.options
    );
  }

  updateBirthday(birthday: BirthdayMaster): Observable<number> {
    birthday.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "birthday/update",
      JSON.stringify(birthday),
      this.options
    );
  }

  getAllLateMarks(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Latemark[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Latemark[]>(
      this.url + "lateMark/get",
      JSON.stringify(data),
      this.options
    );
  }

  createlatemark(latemark: Latemark): Observable<number> {
    latemark.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "lateMark/add",
      JSON.stringify(latemark),
      this.options
    );
  }

  updatelatemark(latemark: Latemark): Observable<number> {
    latemark.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "lateMark/update",
      JSON.stringify(latemark),
      this.options
    );
  }

  getAllEarlyMarks(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Earlymark[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Earlymark[]>(
      this.url + "earlyMark/get",
      JSON.stringify(data),
      this.options
    );
  }

  createEarlymark(earlymark: Earlymark): Observable<number> {
    earlymark.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "earlyMark/add",
      JSON.stringify(earlymark),
      this.options
    );
  }

  updateEarlymark(earlymark: Earlymark): Observable<number> {
    earlymark.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "earlyMark/update",
      JSON.stringify(earlymark),
      this.options
    );
  }

  getAllEmployeeExpenseHead(employeeId: number) {
    var data = {
      EMPLOYEE_ID: employeeId,
    };

    return this.httpClient.post<Expensedetails[]>(
      this.url + "employeeExpenseHeadMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addEmployeeExpenseHeadDetails(
    employeeId: number,
    data1: string[]
  ): Observable<number> {
    console.log(employeeId);
    var data = {
      EMPLOYEE_ID: employeeId,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "employeeExpenseHeadMapping/addBulk",
      data,
      this.options
    );
  }

  getAllEmployeeColleges(employeeId: number) {
    var data = {
      EMPLOYEE_ID: employeeId,
    };

    return this.httpClient.post<Employeecollegedetails[]>(
      this.url + "employeeCollegeMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addEmployeeCollegeDetails(
    employeeId: number,
    data1: string[]
  ): Observable<number> {
    var data = {
      EMPLOYEE_ID: employeeId,
      data: data1,
    };

    return this.httpClient.post<number>(
      this.url + "employeeCollegeMapping/addBulk",
      data,
      this.options
    );
  }

  getAttendanceRegister(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Attendance[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Attendance[]>(
      this.url + "employeeAttendanceReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAttendance1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Attendance[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Attendance[]>(
      this.url + "attendance/get",
      JSON.stringify(data),
      this.options
    );
  }

  createAttendance(attendance: Attendance): Observable<number> {
    attendance.CLIENT_ID = this.clientId;
    console.log(attendance);
    return this.httpClient.post<number>(
      this.url + "attendance/create",
      JSON.stringify(attendance),
      this.options
    );
  }

  updateAttendance(attendance: Attendance): Observable<number> {
    attendance.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "attendance/update/",
      JSON.stringify(attendance),
      this.options
    );
  }

  getSalesManagerWiseBranchWiseDetails(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: string,

    BRANCH_ID: Array<any>,

    START_DATE: any,

    END_DATE: any

  ): Observable<any> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      BRANCH_ID: BRANCH_ID, START_DATE: START_DATE, END_DATE: END_DATE

    };




    return this.httpClient.post<any>(

      this.url + "salesManagerWiseBranchWiseSummaryReport/getSalesManagerWiseBranchWiseSummaryReport",

      JSON.stringify(data),

      this.options

    );

  }


  // getsalesManagerExecutivewisereport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any, SALES_MANAGER_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     VARIENT_ID: VARIENT_ID,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     SALES_MANAGER_ID: SALES_MANAGER_ID,
  //   };
  //   return this.httpClient.post<any>(this.url + "salesManagerExeWiseReport/getSalesManagerExeWiseReport", JSON.stringify(data), this.options);
  // }

  getAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    status: string,
    startDate: string,
    enddate: string
  ): Observable<AttendanceReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      STATUS: status,
      START_DATE: startDate,
      END_DATE: enddate,
    };

    return this.httpClient.post<AttendanceReport[]>(
      this.url + "employeeAttendanceReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAbsentReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    startDate: string,
    endDate: string
  ): Observable<AttendanceReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: startDate,
      END_DATE: endDate,
    };

    return this.httpClient.post<AttendanceReport[]>(
      this.url + "employeeAttendanceReport/getAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getAllEmployees(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<EmployeeMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {
      console.log("getemp2");
    } else {
      return this.httpClient.post<any>(this.url + "employee/get", JSON.stringify(data), this.options);

    }
  }

  getLeaveReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<LeaveReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<LeaveReport[]>(
      this.url + "employeeLeaveReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getLatemarkReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<[]>(
      this.url + "employeeLatemarkReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getEarlymarkReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Earlymarkreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Earlymarkreport[]>(
      this.url + "employeeEarlymarkReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getExpenseReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<ExpenseReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<ExpenseReport[]>(
      this.url + "employeeExpensesReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getEvaluationReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<EvaluationReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<EvaluationReport[]>(
      this.url + "employeeEvaluationReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllAssetCategory(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<AssetCategory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<AssetCategory[]>(
      this.url + "assetCategory/get",
      JSON.stringify(data),
      this.options
    );
  }




  createAssetCategory(category: AssetCategory): Observable<number> {
    category.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "assetCategory/create",
      JSON.stringify(category),
      this.options
    );
  }

  updateAssetCategory(category: AssetCategory): Observable<number> {
    category.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "assetCategory/update",
      JSON.stringify(category),
      this.options
    );
  }

  getAssetLog(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + 'assetsLoggs/get', JSON.stringify(data), this.options)
  }

  craeteAssetLog(data: any): Observable<any> {
    data.CLIENT_ID = this.clientId

    return this.httpClient.post<assetLog>(this.url + 'assetsLoggs/create', JSON.stringify(data), this.options)
  }

  getAllAssets(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Asset[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Asset[]>(
      this.url + "asset/get",
      JSON.stringify(data),
      this.options
    );
  }

  createAssets(asset: Asset): Observable<number> {
    asset.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "asset/create",
      JSON.stringify(asset),
      this.options
    );
  }

  updateAssets(asset: Asset): Observable<number> {
    asset.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "asset/update",
      JSON.stringify(asset),
      this.options
    );
  }

  getAllAssetMapping(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<AssetMapping[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<AssetMapping[]>(
      this.url + "assetEmployeeMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  createAssetMapping(assetMapping: AssetMapping): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "asset/create",
      JSON.stringify(assetMapping),
      this.options
    );
  }

  updateAssetMapping(assetMapping: AssetMapping): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "asset/update",
      JSON.stringify(assetMapping),
      this.options
    );
  }

  addAssetRequest(assetRequest: AssetRequest): Observable<number> {
    assetRequest.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "assetRequest/assetRequests",
      JSON.stringify(assetRequest),
      this.options
    );
  }

  getAllRequestedAsset(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    empID: number
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: empID,
    };

    return this.httpClient.post<[]>(
      this.url + "assetRequest/getRequestedAssets",
      JSON.stringify(data),
      this.options
    );
  }

  getAllAllocatedAsset(empID: number): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empID,
    };

    return this.httpClient.post<[]>(
      this.url + "assetRequest/getAllocatedAssets",
      JSON.stringify(data),
      this.options
    );
  }

  getAllReAllocatedAsset(empID: number): Observable<[]> {
    var data = {
      NEW_EMPLOYEE_ID: empID,
    };

    return this.httpClient.post<[]>(
      this.url + "assetRequest/getReallocatedAssets",
      JSON.stringify(data),
      this.options
    );
  }

  allocatingAsset(assetMapping: AssetMapping): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "assetRequest/updateAssetApproval",
      JSON.stringify(assetMapping),
      this.options
    );
  }

  allocatingAssetWithoutRequest(data: any): Observable<number> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "asset/allocateAssetsWithoutRequest",
      JSON.stringify(data),
      this.options
    );
  }

  doAssetMaintenance(data: any): Observable<number> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "assetMaintenanceRegister/updateAssetRegister",
      JSON.stringify(data),
      this.options
    );
  }

  getEmployeeAttendanceRegister(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Attendanceregister[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Attendanceregister[]>(
      this.url + "attendanceRegister/get",
      JSON.stringify(data),
      this.options
    );
  }

  getExpenseSummeryReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<ExpenseSummeryReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<ExpenseSummeryReport[]>(
      this.url + "expensesSummaryReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  updateAttendanceRegister(
    attendanceRegister: UpdateAttendanceRegister
  ): Observable<number> {
    return this.httpClient.put<number>(
      this.url + "attendance/updateStatus",
      JSON.stringify(attendanceRegister),
      this.options
    );
  }

  // Training Module
  getAlltrainingSubject(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "trainingSubject/get",
      JSON.stringify(data),
      this.options
    );
  }

  createtrainingSubject(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "trainingSubject/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updatetrainingSubject(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "trainingSubject/update/",
      JSON.stringify(role),
      this.options
    );
  }

  addBulktrainingPointsMapping(
    SUBJECT_MASTER_ID: number,
    datas: any
  ): Observable<any> {
    var data = {
      SUBJECT_MASTER_ID: SUBJECT_MASTER_ID,
      data: datas,
    };

    return this.httpClient.post<any>(
      this.url + "trainingPointsMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  trainingPointsMapping(SUBJECT_MASTER_ID: number): Observable<any> {
    var data = {
      SUBJECT_MASTER_ID: SUBJECT_MASTER_ID,
    };

    return this.httpClient.post<any>(
      this.url + "trainingPointsMapping/getData",
      JSON.stringify(data),
      this.options
    );
  }

  getAlltrainer(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "trainer/get",
      JSON.stringify(data),
      this.options
    );
  }

  createtrainer(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "trainer/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updatetrainer(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "trainer/update/",
      JSON.stringify(role),
      this.options
    );
  }

  getAlltrainingType(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any>(
      this.url + "trainingType/get",
      JSON.stringify(data),
      this.options
    );
  }

  createtrainingType(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "trainingType/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updatetrainingType(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "trainingType/update/",
      JSON.stringify(role),
      this.options
    );
  }

  getAllannualTraniningPoints(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "annualTraniningPoints/get",
      JSON.stringify(data),
      this.options
    );
  }

  createannualTraniningPoints(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "annualTraniningPoints/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updateannualTraniningPoints(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "annualTraniningPoints/update/",
      JSON.stringify(role),
      this.options
    );
  }

  addBulktrainingTrainerMapping(
    SCHEDULE_ID: number,
    datas: any
  ): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
      data: datas,
    };

    return this.httpClient.post<any>(
      this.url + "trainingTrainerMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  getAlltrainingPoints(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "trainingPoints/get",
      JSON.stringify(data),
      this.options
    );
  }

  createtrainingPoints(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "trainingPoints/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updatetrainingPoints(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "trainingPoints/update/",
      JSON.stringify(role),
      this.options
    );
  }

  getAlltrainingSchedule(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "trainingSchedule/get",
      JSON.stringify(data),
      this.options
    );
  }

  trainingAttendiedMapping(
    SCHEDULE_ID: number,
    a: number,
    b: number
  ): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
      DEPARTMENT_ID: a,
      DESIGNATION_ID: b,
    };

    return this.httpClient.post<any>(
      this.url + "trainingAttendiedMapping/getData",
      JSON.stringify(data),
      this.options
    );
  }

  getAlltrainingAttendiedMapping(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "trainingAttendiedMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  createtrainingSchedule(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "trainingSchedule/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updatetrainingSchedule(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "trainingSchedule/update/",
      JSON.stringify(role),
      this.options
    );
  }

  addBulktrainingAttendiedMapping(
    SCHEDULE_ID: number,
    datas: any
  ): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
      data: datas,
    };

    return this.httpClient.post<any>(
      this.url + "trainingAttendiedMapping/addBulk",
      JSON.stringify(data),
      this.options
    );
  }

  getAllinductionArea(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "inductionArea/get",
      JSON.stringify(data),
      this.options
    );
  }

  createinductionArea(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "inductionArea/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updateinductionArea(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "inductionArea/update/",
      JSON.stringify(role),
      this.options
    );
  }

  getAlltrainingTrainerMapping(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "trainingTrainerMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  trainingTrainerMapping(SCHEDULE_ID: number): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
    };

    return this.httpClient.post<any>(
      this.url + "trainingTrainerMapping/getData",
      JSON.stringify(data),
      this.options
    );
  }

  updatetrainingAttendiedMapping(
    data: any,
    reason: string,
    scheduleId: number
  ): Observable<any> {
    var datas = {
      data: data,
      REASON: reason,
      SCHEDULE_ID: scheduleId,
    };

    return this.httpClient.post<any>(
      this.url + "trainingAttendiedMapping/updateStatus/",
      JSON.stringify(datas),
      this.options
    );
  }

  clearDeviceID(empID: number, type: string): Observable<any> {
    var data = {
      ID: empID,
      TYPE: type,
    };

    return this.httpClient.post<any>(
      this.url + "employee/clearDeviceID/",
      JSON.stringify(data),
      this.options
    );
  }

  // File Download (21-09-2022, by: Ashish)
  getLogs(empEmailID: string): Observable<any> {
    var data = {
      EMAIL_ID: empEmailID,
    };

    return this.httpClient.post<any>(
      this.url + "employee/getLogs/",
      JSON.stringify(data),
      this.options
    );
  }

  // Password Validation(Alphanumeric)
  passwordIsValid(value: any) {
    const expression = /^[A-Za-z0-9@#]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  updateGlobalSettingInfo(apkInfo: any): Observable<any> {
    apkInfo.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.baseUrl + "globalSettings/updatedVersion/",
      JSON.stringify(apkInfo),
      this.options
    );
  }

  // QR Code for Day In, Day Out
  createQRCodeInfo(QRInfo: any): Observable<number> {
    QRInfo.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "webBasedLogin/createActivity",
      JSON.stringify(QRInfo),
      this.options
    );
  }

  getEmployeeOfTheMonth(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<[]>(
      this.baseUrl + "globalSettings/getEmployeeOfTheMonth/",
      JSON.stringify(data),
      this.options
    );
  }

  setEmployeeOfTheMonth(EOM_Info: any): Observable<any> {
    EOM_Info.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.baseUrl + "globalSettings/setEmployeeOfTheMonth/",
      JSON.stringify(EOM_Info),
      this.options
    );
  }

  getAssetMaintenance(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Asset[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<Asset[]>(
      this.url + "assetMaintenanceRegister/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllModels(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<ModelMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<ModelMaster[]>(
      this.url + "model/get",
      JSON.stringify(data),
      this.options
    );
  }

  createModel(model: ModelMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "model/create",
      JSON.stringify(model),
      this.options
    );
  }

  updateModel(model: ModelMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "model/update",
      JSON.stringify(model),
      this.options
    );
  }

  getAllVarients(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<VarientMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<VarientMaster[]>(
      this.url + "varient/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllVarientsForPricelist(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<VarientMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<VarientMaster[]>(this.url + "varient/getOrderBy", JSON.stringify(data), this.options);
  }

  createVarient(varient: VarientMaster): Observable<number> {
    varient.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "varient/create", JSON.stringify(varient), this.options);
  }

  createVarientWithPriceList(varient: VarientMaster): Observable<number> {
    varient.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "varient/createVarientPriceList", JSON.stringify(varient), this.options);
  }

  updateVarient(varient: VarientMaster): Observable<number> {
    varient.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "varient/update", JSON.stringify(varient), this.options);
  }

  getAllPriceList(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<PriceList[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<PriceList[]>(this.url + "priceList/get", JSON.stringify(data), this.options);
  }

  createPriceList(priceList: PriceList): Observable<number> {
    priceList.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "priceList/create",
      JSON.stringify(priceList),
      this.options
    );
  }

  // createPriceList1(priceList: PriceList): Observable<number> {
  //   priceList.CLIENT_ID = this.clientId;
  //   return this.httpClient.post<number>(
  //     this.url + "priceList/createPriceList",
  //     JSON.stringify(priceList),
  //     this.options
  //   );
  // }

  createPriceList1(priceList: PriceList): Observable<number> {

    priceList.CLIENT_ID = this.clientId;

    priceList.CLUSTER_ID = this.ClusterId;

    return this.httpClient.post<number>(

      this.url + "priceList/createPriceList",

      JSON.stringify(priceList),

      this.options

    );

  }

  updatePriceList(priceList: PriceList): Observable<number> {
    priceList.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "priceList/update",
      JSON.stringify(priceList),
      this.options
    );
  }

  updatePriceList1(priceList: PriceList): Observable<number> {
    priceList.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "priceList/updatePriceList",
      JSON.stringify(priceList),
      this.options
    );
  }

  copyPriceList(priceList: PriceList): Observable<number> {
    priceList.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "priceList/copyPriceList",
      JSON.stringify(priceList),
      this.options
    );
  }

  getPriceListMappingDetails(priceListID: number, filter: any) {
    var data = {
      PRICELIST_ID: priceListID,
      filter: filter

    };

    return this.httpClient.post<Expensedetails[]>(
      this.url + "priceListBranchMapping/get",
      JSON.stringify(data),
      this.options
    );
  }

  addPriceListMappingDetails(
    priceListID: number,
    mappingData: string[]
  ): Observable<number> {
    var data = {
      PRICELIST_ID: priceListID,
      data: mappingData,
    };

    return this.httpClient.post<number>(
      this.url + "priceListBranchMapping/addBulk",
      data,
      this.options
    );
  }

  getAllPriceListDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<PriceListDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<PriceListDetails[]>(this.url + "priceListDetails/get", JSON.stringify(data), this.options);
  }

  getAllColors(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<ColorMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<ColorMaster[]>(
      this.url + "color/get",
      JSON.stringify(data),
      this.options
    );
  }

  createColor(model: ColorMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "color/create",
      JSON.stringify(model),
      this.options
    );
  }

  updateColor(model: ColorMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "color/update",
      JSON.stringify(model),
      this.options
    );
  }

  getAllFuel(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<FuelMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<FuelMaster[]>(
      this.url + "fuelType/get",
      JSON.stringify(data),
      this.options
    );
  }

  createFuel(model: FuelMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "fuelType/create",
      JSON.stringify(model),
      this.options
    );
  }

  updateFuel(model: FuelMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "fuelType/update",
      JSON.stringify(model),
      this.options
    );
  }

  getAllTransmission(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<TransmissionMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<TransmissionMaster[]>(
      this.url + "transmissionType/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTransmission(model: TransmissionMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "transmissionType/create",
      JSON.stringify(model),
      this.options
    );
  }

  updateTransmission(model: TransmissionMaster): Observable<number> {
    model.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "transmissionType/update",
      JSON.stringify(model),
      this.options
    );
  }

  logoutForSessionValues() {
    this.cookie.delete("supportKey")
    this.cookie.delete("token")
    // this.cookie.delete("token")
    sessionStorage.clear();
    window.location.reload()
  }

  getCheckAccessOfForm(roleId: number, link: string) {
    var data = {
      ROLE_ID: roleId,
      LINK: link,
    };

    return this.httpClient.post<Roledetails>(
      this.url + "roleDetails/checkAccess",
      JSON.stringify(data),
      this.options
    );
  }

  // Send Mail Quotation
  sendQuotationSummaryEmail(): Observable<[]> {
    return this.httpClient.post<[]>(
      this.url + "quotation/sendEmail",
      "",
      this.options
    );
  }

  getQuotation(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<[]>(this.url + "quotation/get", JSON.stringify(data), this.options);
  }

  getMemoDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<[]>(this.url + "memo/get", JSON.stringify(data), this.options);
  }

  getMemoDetails12(EMPLOYEE_ID: any, STATUS: any, FROM_DATE: any, TO_DATE: any, filter: string): Observable<[]> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      STATUS: STATUS,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      likeString: filter

    };



    return this.httpClient.post<[]>(this.url + "memo/getMemowise", JSON.stringify(data), this.options);
  }

  updateMemoDetails(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "memo/approveMemo/", JSON.stringify(ticketGroup), this.options);
  }
  updateMemoDetails1(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "memo/updateVIN", JSON.stringify(ticketGroup), this.options);
  }

  setQuotationStatus(quotationID: any, status: any): Observable<any> {
    var data = {
      QUOTATION_ID: quotationID,
      STATUS: status,
    };

    return this.httpClient.post<any>(this.url + "quotation/updateStatus/", JSON.stringify(data), this.options);
  }

  getBranchWisesalesSummary(

    pageIndex: any,

    pageSize: any,

    sortKey: any,

    sortValue: any,

    filter: any,

    orgID: any,




    fromDate: any,

    toDate: any,

    // modelID: string,

    BRANCH_ID: any

    // varientID: string,

    // salesExecutiveID: string,

    // fuelFilter: string,

    // transmissionFilter: string

  ): Observable<[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      ORG_ID: orgID,




      START_DATE: fromDate,

      END_DATE: toDate,

      BRANCH_ID: BRANCH_ID,

      // MODEL_ID: modelID,

      // VARIENT_ID: varientID,

      // SALES_EXECUTIVE_ID: salesExecutiveID,

      // FUEL_TYPE: fuelFilter,

      // TRANSMISSION: transmissionFilter,

      CLUSTER_ID: this.ClusterId,

    };




    return this.httpClient.post<[]>(

      this.url + "branchwiseSalesSummary/getBranchwiseSalesSummary",

      JSON.stringify(data),

      this.options

    );

  }


  getBranchWisesalesSummary1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,

    fromDate: string,
    toDate: string,
    modelID: string,
    branchID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,

      START_DATE: fromDate,
      END_DATE: toDate,
      BRANCH_ID: branchID,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseSalesSummary/getBranchwiseSalesSummary",
      JSON.stringify(data),
      this.options
    );
  }
  getBranchWiseDiscountReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,

    fromDate: string,
    toDate: string,
    branchID: string,
    // modelID: string,
    // varientID: string,
    // salesExecutiveID: string,
    // fuelFilter: string,
    // transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,

      START_DATE: fromDate,
      END_DATE: toDate,
      BRANCH_ID: branchID,

    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseSalesSummary/getBranchwiseDiscountSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getBranchWiseDiscountReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,

    fromDate: string,
    toDate: string,
    branchID: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,

      START_DATE: fromDate,
      END_DATE: toDate,
      BRANCH_ID: branchID,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,

    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseSalesSummary/getBranchwiseDiscountSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getSalesExecutiveWiseSummaryReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    // modelID: string,
    // varientID: string,
    salesExecutiveID: string,
    // fuelFilter: string,
    // transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      // MODEL_ID: modelID,
      // VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      // FUEL_TYPE: fuelFilter,
      // TRANSMISSION: transmissionFilter,
      CLUSTER_ID: this.ClusterId,
    };

    return this.httpClient.post<[]>(
      this.url + "salesExecutivewiseSummary/getSalesExecutivewiseSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getSalesExecutiveWiseSummaryReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url + "salesExecutivewiseSummary/getSalesExecutivewiseSummary",
      JSON.stringify(data),
      this.options
    );
  }

  // getSalesExecutiveWiseDetailedReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url +
  //     "salesExecutivewiseSummary/getSalesExecutivewiseSalesDetailedReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  getSalesExecutiveWiseDetailedReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url +
      "salesExecutivewiseSummary/getSalesExecutivewiseSalesDetailedReport",
      JSON.stringify(data),
      this.options
    );
  }

  // getModelWiseSummary(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   // salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     // SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url + "modelWiseSalesSummary/getModelWiseSalesSummary",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  getModelWiseSummary1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url + "modelWiseSalesSummary/getModelWiseSalesSummary",
      JSON.stringify(data),
      this.options
    );
  }

  // getSalesExecutiveWiseModelWiseSummaryReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url +
  //     "salesExecutivewiseSummary/getSalesExecutiveWiseModelWiseSummary",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  getSalesExecutiveWiseModelWiseSummaryReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url +
      "salesExecutivewiseSummary/getSalesExecutiveWiseModelWiseSummary",
      JSON.stringify(data),
      this.options
    );
  }


  // getModelwiseDailyDetailedReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url +
  //     "modelWiseSalesSummary/getModelwiseDailyDetailedReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  getModelwiseDailyDetailedReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url +
      "modelWiseSalesSummary/getModelwiseDailyDetailedReport",
      JSON.stringify(data),
      this.options
    );
  }


  getFuelTypeWiseBranchWiseSummaryReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url +
      "branchwiseSalesSummary/getFuelwiseBranchwiseSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getTransmissionTypeWiseBranchWiseSummaryReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    fuelFilter: string,
    transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      FUEL_TYPE: fuelFilter,
      TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url +
      "branchwiseSalesSummary/getTransmissioinwiseBranchwiseSummary",
      JSON.stringify(data),
      this.options
    );
  }

  // ATTENDANCE FILTER
  getAllAttendanceBranch(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Branchmaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {

    }
    else {
      return this.httpClient.post<any>(
        this.url + "branch/get",
        JSON.stringify(data),
        this.options
      );
    }

  }

  getAllAttendanceDepartments(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "department/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAllAttendanceDesignations(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "designation/get",
      JSON.stringify(data),
      this.options
    );
  }

  //ATTENDANCE REPORT
  getDayWiseAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    // departmentId: string,
    // designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      // DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDaywiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }


  getDayWiseAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDaywiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getBranchWiseAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    // designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseAttendanceReport/getBranchwiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getBranchWiseAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseAttendanceReport/getBranchwiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDepartmentWiseAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,

    fromDate: string,
    toDate: string,
    departmentId: string,

  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url +
      "departmentwiseAttendanceReport/getDepartmentwiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDepartmentWiseAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: any,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string

  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url +
      "departmentwiseAttendanceReport/getDepartmentwiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDesignationWiseAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    // departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      // DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url +
      "designationwiseAttendanceReport/getDesignationwiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }


  getDesignationWiseAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url +
      "designationwiseAttendanceReport/getDesignationwiseAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getMonthlyAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    // departmentId: string,
    // designationId: string,
    dayCount: any
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      // DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url + "monthlyAttendanceReport/getMonthlyAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getMonthlyAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string,
    dayCount: any
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url + "monthlyAttendanceReport/getMonthlyAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getBranchWiseAvgAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    // departmentId: string,
    branchID: string,
    dayCount: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,

      START_DATE: fromDate,
      END_DATE: toDate,
      BRANCH_ID: branchID,
      // DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseAttendanceReport/getBranchwiseAvgAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getBranchWiseAvgAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string,
    dayCount: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,

      START_DATE: fromDate,
      END_DATE: toDate,
      BRANCH_ID: branchID,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url + "branchwiseAttendanceReport/getBranchwiseAvgAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDepartmentWiseAvgAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    // designationId: string,
    dayCount: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url +
      "departmentwiseAttendanceReport/getDepartmentwiseAvgAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDepartmentWiseAvgAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string,
    dayCount: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url +
      "departmentwiseAttendanceReport/getDepartmentwiseAvgAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDesignationWiseAvgAttendanceReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    // departmentId: string,
    designationId: string,
    dayCount: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      // DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url +
      "designationwiseAttendanceReport/getDesignationwiseAvgAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDesignationWiseAvgAttendanceReport1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string,
    dayCount: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
      DAY_COUNT: dayCount,
    };

    return this.httpClient.post<[]>(
      this.url +
      "designationwiseAttendanceReport/getDesignationwiseAvgAttendanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDaywiseSuperLateTop10Employees(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDaywiseSuperLateEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getMonthlySuperLateTop10Employees(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "monthlyAttendanceReport/getMonthlySuperLateEmployeesReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDayWiseLeaveReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,

    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDaywiseEmployeeLeaveReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDayWiseLatemarkReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDaywiseLatemarkEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDayInFromMaxDistanceDayWise(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDayInMaxDistanceEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDayInFromMaxDistanceMonthlyWise(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "monthlyAttendanceReport/getDayInMaxDistanceEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDayOutMaxDistanceDayEmployeeReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "daywiseAttendanceReport/getDayOutMaxDistanceEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getDayOutMaxDistanceMonthlyEmployeeReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "monthlyAttendanceReport/getDayOutMaxDistanceEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getTop10DayOutMismatchedEmployees(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,
    };

    return this.httpClient.post<[]>(
      this.url + "monthlyAttendanceReport/getDayOutMismatchedEmployeeReport",
      JSON.stringify(data),
      this.options
    );
  }

  getGraphicalAttendanceSummary(
    orgID: number,
    // branchID: string,
    fromDate: string,
    toDate: string,
    // departmentId: string,
    // designationId: string,

  ): Observable<[]> {
    var data = {
      ORG_ID: orgID,
      // BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      // DEPARTMENT_ID: departmentId,
      // DESIGNATION_ID: designationId,

    };

    return this.httpClient.post<[]>(
      this.url + "graphicalAttendanceSummary/getGraphicalAttendanceSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getGraphicalAttendanceSummary1(
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    departmentId: string,
    designationId: string,

  ): Observable<[]> {
    var data = {
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      DEPARTMENT_ID: departmentId,
      DESIGNATION_ID: designationId,

    };

    return this.httpClient.post<[]>(
      this.url + "graphicalAttendanceSummary/getGraphicalAttendanceSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getAllBanks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<BankMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<BankMaster[]>(this.url + "bankMaster/get", JSON.stringify(data), this.options);
  }

  createBank(leavetype: BankMaster): Observable<number> {
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "bankMaster/create", JSON.stringify(leavetype), this.options);
  }

  updateBank(leavetype: BankMaster): Observable<number> {
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "bankMaster/update", JSON.stringify(leavetype), this.options);
  }

  getAllInsurances(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<BankMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<BankMaster[]>(this.url + "insuranceMaster/get", JSON.stringify(data), this.options);
  }

  createNewInsurance(leavetype: BankMaster): Observable<number> {
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "insuranceMaster/create", JSON.stringify(leavetype), this.options);
  }

  updateInsurance(leavetype: BankMaster): Observable<number> {
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "insuranceMaster/update", JSON.stringify(leavetype), this.options);
  }

  //hrrolemapping 


  humanResourceHeadMapping(branchmap: any, EMPLOYEE_ID: any): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID, data: branchmap,
    };
    return this.httpClient.post<any>(this.url + 'hrBranchMapping/addBulk', JSON.stringify(data), this.options)
  }

  getHrMapData(filter: any): Observable<any> {

    var data = { filter: filter }
    return this.httpClient.post<any>(this.url + 'hrBranchMapping/get', JSON.stringify(data), this.options)
  }




  getAdvanceMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<advanceMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }; return this.httpClient.post<advanceMaster[]>(this.url + "advance/get", JSON.stringify(data), this.options);
  }


  getMemoDetails1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<memoDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<memoDetails[]>(this.url + "memoDetails/get", JSON.stringify(data), this.options);
  }

  getQuestionaryMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<questionaryMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }; return this.httpClient.post<questionaryMaster[]>(this.url + "questionaryMaster/get", JSON.stringify(data), this.options);
  }

  getmemoSignatureDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<memoSignatureDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<memoSignatureDetails[]>(this.url + "memoSignatureDetails/get", JSON.stringify(data), this.options);
  }



  updatememoSignatureDetails(memoSignatureDetails: memoSignatureDetails): Observable<number> {
    memoSignatureDetails.CLIENT_ID = this.clientId
    return this.httpClient.put<number>(this.url + "memoSignatureDetails/update", JSON.stringify(memoSignatureDetails), this.options);
  }
  creatememoSignatureDetails(memoSignatureDetails: memoSignatureDetails): Observable<number> {
    memoSignatureDetails.CLIENT_ID = this.clientId
    return this.httpClient.post<number>(this.url + "memoSignatureDetails/create", JSON.stringify(memoSignatureDetails), this.options);
  }


  createMemo(ticketGroup: any): Observable<Object> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apiKey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportKey: this.cookie.get("supportKey"),
      TOKEN: this.cookie.get("token"),
    }); this.options = { headers: this.httpHeaders, }

    return this.httpClient.post<any>(this.url + 'memo/createMemo', JSON.stringify(ticketGroup), this.options)
  }


  // insurance incentive master

  getInsuranceData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }
    return this.httpClient.post<any>(this.url + 'insuranceIncentiveRates/get', JSON.stringify(data), this.options)
  }

  createInsurance(data: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "application/json",
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    }); this.options1 = { headers: this.httpHeaders1, };
    data.CLIENT_ID = this.clientId
    return this.httpClient.post<any>(this.url + 'insuranceIncentiveRates/create', JSON.stringify(data), this.options)
  }

  updateincetiveInsurance(insurance: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "application/json",
      // Accept: "application/json",  
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });
    this.options1 = { headers: this.httpHeaders1, };
    return this.httpClient.put<any>(this.url + 'insuranceIncentiveRates/update', JSON.stringify(insurance), this.options)
  }

  //sales incentive master form 



  // getInsuranceAddBulk(data1: any): Observable<number> {
  //   return this.httpClient.post<number>(this.url + "insuranceIncentiveRatesDetails/addBulk", JSON.stringify(data1), this.options);
  // }

  // getInsuranceIncentiveRatesDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<directIncentiveRatesDetails[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //   };

  //   return this.httpClient.post<directIncentiveRatesDetails[]>(this.url + "insuranceIncentiveRatesDetails/get", JSON.stringify(data), this.options);
  // }

  getRateMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<rateMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<rateMaster[]>(this.url + "directIncentiveRates/get", JSON.stringify(data), this.options);
  }

  createData(rateMaster: rateMaster) {
    rateMaster.CLIENT_ID = this.clientId;

    return this.httpClient.post<any[]>(this.baseUrl + "api/directIncentiveRates/create", JSON.stringify(rateMaster),
      this.options);
  }

  updateData(rateMaster: rateMaster) {
    return this.httpClient.put<any[]>(this.baseUrl + "api/directIncentiveRates/update", JSON.stringify(rateMaster),
      this.options);
  }

  getDirectIncentiveRatesDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<directIncentiveRatesDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<directIncentiveRatesDetails[]>(this.url + "directIncentiveRatesDetails/get", JSON.stringify(data), this.options);
  }

  rateAddBulk(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "directIncentiveRatesDetails/addBulk", JSON.stringify(data1), this.options);
  }

  getBranch(ORG_ID: string, DIRECT_INCENTIVE_RATES_MASTER_ID: number,): Observable<any> {
    var data =
    {
      ORG_ID: ORG_ID,
      DIRECT_INCENTIVE_RATES_MASTER_ID: DIRECT_INCENTIVE_RATES_MASTER_ID,
    }; return this.httpClient.post<any>(this.url + "directIncentiveRatesBranchMapping/getMappingData", JSON.stringify(data), this.options);
  }


  addBranch(DIRECT_INCENTIVE_RATES_MASTER_ID: number, data1: any): Observable<any> {
    var data =
    {
      DIRECT_INCENTIVE_RATES_MASTER_ID: DIRECT_INCENTIVE_RATES_MASTER_ID, data: data1
    };
    return this.httpClient.post<any>(this.url + "directIncentiveRatesBranchMapping/addBulk", data, this.options);
  }

  getBranch1(ORG_ID: string, INSURANCE_INCENTIVE_RATES_MASTER_ID: number,): Observable<any> {
    var data =
    {
      ORG_ID: ORG_ID,
      INSURANCE_INCENTIVE_RATES_MASTER_ID: INSURANCE_INCENTIVE_RATES_MASTER_ID,
    }; return this.httpClient.post<any>(this.url + "insuranceIncentiveRatesBranchMapping/getMappingData", JSON.stringify(data), this.options);
  }
  // api/insuranceIncentiveRatesBranchMapping/getMappingData


  addBranch12(INSURANCE_INCENTIVE_RATES_MASTER_ID: number, data1: any): Observable<any> {
    var data =
    {
      INSURANCE_INCENTIVE_RATES_MASTER_ID: INSURANCE_INCENTIVE_RATES_MASTER_ID,
      data: data1
    };
    return this.httpClient.post<any>(this.url + "insuranceIncentiveRatesBranchMapping/addBulk", data, this.options);
  }

  //finance

  Addbulkformapping(data1: any): Observable<number> {

    return this.httpClient.post<number>(
      this.url + "financeIncentiveDetails/addBulk", JSON.stringify(data1), this.options
    );
  }


  getFinanceMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<financeMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<financeMaster[]>(this.url + "financeIncentive/get", JSON.stringify(data), this.options);
  }
  createFinance(financeMaster: financeMaster) {
    financeMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any[]>(this.baseUrl + "api/financeIncentive/create", JSON.stringify(financeMaster), this.options);
  }
  updateFinance(financeMaster: financeMaster) {
    financeMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(this.baseUrl + "api/financeIncentive/update", JSON.stringify(financeMaster), this.options);
  }

  financeIncentiveDetails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "financeIncentiveDetails/get",
      JSON.stringify(data),
      this.options
    );
  }


  InsuranceAddBulk(data1: any): Observable<number> {

    return this.httpClient.post<number>(this.url + "insuranceIncentiveRatesDetails/addBulk", JSON.stringify(data1), this.options);
  }

  getInsuranceIncentiveRatesDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<directIncentiveRatesDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }; return this.httpClient.post<directIncentiveRatesDetails[]>(this.url + "insuranceIncentiveRatesDetails/get", JSON.stringify(data), this.options);
  }


  getLeaveData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter

    };

    return this.httpClient.post<any[]>(this.url + "payrollLeaveMaster/get", JSON.stringify(data), this.options);

  }




  getAllMembers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apiKey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportKey: this.cookie.get("supportKey"),
      TOKEN: this.cookie.get("token"),
    }); this.options = { headers: this.httpHeaders, }
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize, sortKey:
        sortKey,
      sortValue: sortValue,
      filter: filter,
    }; return this.httpClient.post<any[]>(this.baseUrl + "api/reportSchedule/get", JSON.stringify(data), this.options);
  }

  createschedule(schedule: schedule): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
      deviceid: this.cookie.get("deviceId"),
      "Content-Type": "application/json",
    }); this.options = { headers: this.httpHeaders1 };
    return this.httpClient.post<any[]>(this.baseUrl + "api/reportSchedule/create", JSON.stringify(schedule), this.options);
  }
  updateschedule(schedule: schedule): Observable<any> {

    return this.httpClient.put<any[]>(this.baseUrl + "api/reportSchedule/update", JSON.stringify(schedule), this.options);
  }


  getMembers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any) {
    this.httpHeaders = new HttpHeaders({
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportkey: this.cookie.get("supportKey"),
      "Content-Type": "application/json",
    });
    this.options1 = { headers: this.httpHeaders, };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(this.baseUrl + "api/report/get", JSON.stringify(data), this.options1);
  }

  SOTIncentiveDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any>(this.url + "sotIncentiveDetails/get", JSON.stringify(data), this.options);
  }

  getSOTMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<SOTMaster[]> {
    var data = {
      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter,
    };
    return this.httpClient.post<SOTMaster[]>(this.url + "sotIncentive/get", JSON.stringify(data), this.options);
  }
  createSOT(financeMaster: SOTMaster) {
    financeMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any[]>(this.baseUrl + "api/sotIncentive/create", JSON.stringify(financeMaster),
      this.options);
  }
  updateSOT(financeMaster: SOTMaster) {
    financeMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(this.baseUrl + "api/sotIncentive/update", JSON.stringify(financeMaster), this.options);
  }
  AddbulkforSOTmapping(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "sotIncentiveDetails/addBulk", JSON.stringify(data1), this.options);
  }

  getEWMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<slabMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<slabMaster[]>(this.url + "ewIncentive/get", JSON.stringify(data), this.options);
  }
  createEWMaster(slabMaster: slabMaster) {
    slabMaster.CLIENT_ID = this.clientId; return this.httpClient.post<any[]>(this.baseUrl + "api/ewIncentive/create", JSON.stringify(slabMaster), this.options);
  }

  updateEWMaster(slabMaster: slabMaster) {
    slabMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(this.baseUrl + "api/ewIncentive/update", JSON.stringify(slabMaster), this.options);
  }
  AddBulkForEWMapping(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "ewIncentiveDetails/addBulk", JSON.stringify(data1), this.options);
  }

  ewIncentiveDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any>(this.url + "ewIncentiveDetails/get", JSON.stringify(data), this.options);
  }

  getSupportStaffAddBulk(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "supportStaffIncentiveRatesDetails/addBulk", JSON.stringify(data1), this.options);
  }

  getSupportStaffIncentiveRatesDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<supportStaffIncentiveRatesDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<supportStaffIncentiveRatesDetails[]>(this.url + "supportStaffIncentiveRatesDetails/get", JSON.stringify(data), this.options);
  }

  getincentive(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
    };

    return this.httpClient.post<any[]>(this.url + "incentiveTransactions/get", JSON.stringify(data), this.options);
  }


  getareaMappingData(supportID): Observable<any[]> {
    var data = {

      BRANCH_ID: supportID,

    };
    return this.httpClient.post<any[]>(this.url + "branchSupportStaffAreaMapping/getMappingData", JSON.stringify(data), this.options);
  }

  getempMapdata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<areaMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<areaMaster[]>(this.url + "branchSupportStaffAreaEmployeeMapping/get", JSON.stringify(data), this.options);
  }

  getAreaMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<areaMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<areaMaster[]>(this.url + "supportStaffArea/get", JSON.stringify(data), this.options);
  }
  createAreaMaster(areaMaster: areaMaster) {
    areaMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any[]>(this.baseUrl + "api/supportStaffArea/create", JSON.stringify(areaMaster), this.options);
  }
  updateAreaMaster(areaMaster: areaMaster) {
    areaMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(this.baseUrl + "api/supportStaffArea/update", JSON.stringify(areaMaster), this.options);
  }

  areaAddBulk(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "branchSupportStaffAreaMapping/addBulk", JSON.stringify(data1), this.options);
  }


  AddBulkForAreaMap(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "branchSupportStaffAreaEmployeeMapping/addBulk", JSON.stringify(data1), this.options);
  }

  SupportStaffAddBulk(data1: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "supportStaffIncentiveRatesDetails/addBulk", JSON.stringify(data1), this.options);
  }

  // supportStaffAreaWiseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string, BRANCH_ID: any, MONTH: any, YEAR: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     LIKE_STRING: LIKE_STRING,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     MONTH: MONTH,
  //     YEAR: YEAR.toString(),
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/supportStaffAreaWiseSummary", JSON.stringify(data1), this.options);
  // }
  supportStaffEmployeeWiseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(this.url + "supportStaffIncentiveSummaryDetails/get", JSON.stringify(data1), this.options);
  }


  getsalesIncentiveSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string, BRANCH_ID: any, SALES_CONSULTANT_ID: any, MONTH: any, YEAR: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_CONSULTANT_ID:
        SALES_CONSULTANT_ID,
      MONTH: MONTH,
      YEAR: YEAR,
    }; return this.httpClient.post<any>(this.url + 'reports/salesIncentive/salesHeadSummary', JSON.stringify(data), this.options)
  }
  // getSalesExeModelReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   }; return this.httpClient.post<any>(this.url + 'reports/salesIncentive/salesExecutiveWiseModelWiseDetailedDirectSales', JSON.stringify(data), this.options)
  // }
  // getInsuranceExeModelReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
  //     FROM_DATE: FROM_DATE, TO_DATE: TO_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   }; return this.httpClient.post<any>(this.url + 'reports/salesIncentive/salesExecutiveWiseModelWiseDetailedInsurance', JSON.stringify(data), this.options)
  // }



  // getincentive1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "incentiveTransactions/get", JSON.stringify(data), this.options);
  // }

  // salesExecutiveWiseDetailedEw(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/salesExecutiveWiseDetailedEw", JSON.stringify(data1), this.options
  //   );
  // }


  // salesExecutiveWiseDetailedSot(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/salesExecutiveWiseDetailedSot", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseDetailedEw(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
  //   BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     LIKE_STRING: LIKE_STRING,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE

  //   };
  //   return this.httpClient.post<any[]>(this.url1 + "api/reports/salesIncentive/salesExecutiveWiseDetailedEw", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseDetailedSot(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
  //   BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     LIKE_STRING: LIKE_STRING,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE
  //   };
  //   return this.httpClient.post<any[]>(this.url1 + "api/reports/salesIncentive/salesExecutiveWiseDetailedSot", JSON.stringify(data1), this.options
  //   );
  // }

  // branchSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   BRANCH_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/branchwiseSummaryReport", JSON.stringify(data1), this.options
  //   );
  // }

  // ewSalesSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
  //   BRANCH_ID: any, SALES_CONSULTANT_ID: any, MONTH: any, YEAR: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     LIKE_STRING: LIKE_STRING,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID.toString(),
  //     MONTH: MONTH,
  //     YEAR: YEAR,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/ewSalesSummaryReport", JSON.stringify(data1), this.options
  //   );
  // }

  // sotSalesSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   BRANCH_ID: any, SALES_CONSULTANT_ID: any, MONTH: any, YEAR: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_CONSULTANT_ID: SALES_CONSULTANT_ID.toString(),
  //     MONTH: MONTH,
  //     YEAR: YEAR,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/sotSalesSummaryReport", JSON.stringify(data1), this.options
  //   );
  // }

  getAssetCategary(PARENT_ID: any): Observable<any> {
    var data = { PARENT_ID: PARENT_ID }
    return this.httpClient.post(this.url + 'assetCategory/getChildrenData', JSON.stringify(data), this.options)

  }

  getAllCustomer(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<CustomerMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<CustomerMaster[]>(
      this.url + "customers/get",
      JSON.stringify(data),
      this.options
    );
  }


  // service Module API

  getserviceSubDepartmentMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }
    return this.httpClient.post<any>(this.url + 'serviceSubDepartmentMaster/get', JSON.stringify(data), this.options)
  }

  createserviceSubDepartmentMaster(data: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "application/json",
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    }); this.options1 = { headers: this.httpHeaders1, };
    data.CLIENT_ID = this.clientId
    return this.httpClient.post<any>(this.url + 'serviceSubDepartmentMaster/create', JSON.stringify(data), this.options)
  }

  updateserviceSubDepartmentMaster(insurance: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "application/json",
      // Accept: "application/json",  
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });
    this.options1 = { headers: this.httpHeaders1, };
    return this.httpClient.put<any>(this.url + 'serviceSubDepartmentMaster/update', JSON.stringify(insurance), this.options)
  }

  ////Database Table master

  getdatabasetable(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }
    return this.httpClient.post<any>(this.url + 'excelImportMaster/get', JSON.stringify(data), this.options)
  }

  createdatabasetable(data: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "application/json",
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    }); this.options1 = { headers: this.httpHeaders1, };
    data.CLIENT_ID = this.clientId
    return this.httpClient.post<any>(this.url + 'excelImportMaster/create', JSON.stringify(data), this.options)
  }

  updatedatabsetable(insurance: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      "Content-Type": "application/json",
      // Accept: "application/json",  
      supportkey: this.cookie.get("supportKey"),
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      Token: this.cookie.get("token"),
    });
    this.options1 = { headers: this.httpHeaders1, };
    return this.httpClient.put<any>(this.url + 'excelImportMaster/update', JSON.stringify(insurance), this.options)
  }


  getAllleadCategory(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "leadCategoryMaster/get",
      JSON.stringify(data),
      this.options
    );
  }

  createleadCategoryMaster(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "leadCategoryMaster/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updateleadCategoryMaster(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "leadCategoryMaster/update/",
      JSON.stringify(role),
      this.options
    );
  }


  onUpload3(folderName: any, selectedFile: any, filename: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });
    this.options1 = { headers: this.httpHeaders1 };
    const fd = new FormData()
    fd.append("Image", selectedFile, filename)
    return this.httpClient.post<any>(this.imgUrl1 + folderName, fd, this.options1)
  }

  // Leave Calculations Api 


  updateLeaveBalance(dataaaa): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustmentDetails/updateLeaveBalance', JSON.stringify(dataaaa), this.options)
  }

  getLeaveMap(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
    BRANCH_ID: any, EMPLOYEE_ID: any, DEPARTMENT_ID: any, DESIGNATION_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      EMPLOYEE_ID: EMPLOYEE_ID.toString(),
      DEPARTMENT_ID: DEPARTMENT_ID,
      DESIGNATION_ID: DESIGNATION_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<any[]>(this.url + "employeeLeaveMapping/get", JSON.stringify(data), this.options);
  }

  getLeaveTransaction(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
    BRANCH_ID: any, EMPLOYEE_ID: any, DEPARTMENT_ID: any, DESIGNATION_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      EMPLOYEE_ID: EMPLOYEE_ID.toString(),
      DEPARTMENT_ID: DEPARTMENT_ID,
      DESIGNATION_ID: DESIGNATION_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE

    };
    return this.httpClient.post<any[]>(this.url + "employeeLeaveTransactions/get", JSON.stringify(data), this.options);
  }

  getLeaveTransaction1(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: any

  ): Observable<any[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      // LIKE_STRING: LIKE_STRING,

      // BRANCH_ID: BRANCH_ID.toString(),

      // EMPLOYEE_ID: EMPLOYEE_ID.toString(),

      // DEPARTMENT_ID: DEPARTMENT_ID,

      // DESIGNATION_ID: DESIGNATION_ID,

      // FROM_DATE: FROM_DATE,

      // TO_DATE: TO_DATE

    };

    return this.httpClient.post<any[]>(

      this.url + "employeeLeaveTransactions/get",

      JSON.stringify(data),

      this.options

    );

  }

  getAllEmployeeLeave2(

    EMPLOYEE_ID: any,

    MONTH: any,

    YEAR: any

  ): Observable<Leave[]> {

    var data = {

      EMPLOYEE_ID: EMPLOYEE_ID,

      MONTH: MONTH,

      YEAR: YEAR,

    };




    return this.httpClient.post<Leave[]>(

      this.url + "employeeLeaveMapping/getEmpLeaveBalance",

      JSON.stringify(data),

      this.options

    );

  }

  getLeaveConfiguration(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<leaveYearConfiguration[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<leaveYearConfiguration[]>(this.url + "leaveYearConfiguration/get", JSON.stringify(data), this.options);
  }
  createLeaveConfiguration(leaveYearConfiguration: leaveYearConfiguration) {
    // areaMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any[]>(this.baseUrl + "api/leaveYearConfiguration/create", JSON.stringify(leaveYearConfiguration), this.options);
  }

  updateLeaveConfiguration(leaveYearConfiguration: leaveYearConfiguration) {
    // areaMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(this.baseUrl + "api/leaveYearConfiguration/update", JSON.stringify(leaveYearConfiguration), this.options);
  }

  addbulkpolicymap(data1): Observable<number> {
    var data = { ...data1, };
    return this.httpClient.post<number>(this.url + "employeeLeavePolicyMapping/addBulk", data, this.options);
  }
  getselectedemp(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "employeeLeavePolicyMapping/get", JSON.stringify(data), this.options);
  }

  getMappedPolicy(EMPLOYEE_ID: number): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID
    }
    return this.httpClient.post<any>(this.url + "employeeLeavePolicyMapping/getMappingData", JSON.stringify(data), this.options)
  }

  deletMappedPolicy(ID: any, USER_ID: any): Observable<any> {
    var data = {
      ID: ID,
      USER_ID: USER_ID
    }
    return this.httpClient.post(this.url + "employeeLeavePolicyMapping/deletePolicy", JSON.stringify(data), this.options)
  }
  getAllleavepolicy(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "leavePolicy/get", JSON.stringify(data), this.options);
  }


  getLeaveAdjustmentMappingData(BRANCH_IDS: number, DEPARTMENT_IDS: number, DESIGNATION_IDS: number): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      BRANCH_IDS: BRANCH_IDS,
      DEPARTMENT_IDS: DEPARTMENT_IDS,
      DESIGNATION_IDS: DESIGNATION_IDS
    }
    return this.httpClient.post<any>(this.baseUrl + '/api/leaveBalanceAdjustmentDetails/getMappingData', JSON.stringify(data), this.options)
  }
  getAdjustmentData(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustment/get', JSON.stringify(data), this.options)
  }



  createadjustment(userid, date, remark): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      USER_ID: userid,
      DATE: date,
      REMARK: remark,

    };
    return this.httpClient.post<leavePolicy>(this.baseUrl + 'api/leaveBalanceAdjustment/create', JSON.stringify(data), this.options)

  }

  getLeaveadjustmentDetails(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustmentDetails/get', JSON.stringify(data), this.options)
  }

  leaveAdjustmentaddbulk(data11): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      data: data11
    };

    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustmentDetails/addBulk', JSON.stringify(data), this.options)
  }



  updateleaveAdjustment(userid, date, remark, ide, IS_COMPLETED): Observable<any> {

    this.httpHeaders = new HttpHeaders({

      "Content-Type": "application/json",

      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",

      applicationkey: "AfIpESwBr5eHp7w3",

      deviceid: this.cookie.get("deviceId"),

      supportkey: this.cookie.get("supportKey"),

      Token: this.cookie.get("token"),

    });




    this.options = {

      headers: this.httpHeaders,

    };

    var data = {

      USER_ID: ide,

      DATE: date,

      REMARK: remark,

      ID: userid,

      IS_COMPLETED: IS_COMPLETED




    };

    return this.httpClient.put<leavePolicy>(this.baseUrl + 'api/leaveBalanceAdjustment/update', JSON.stringify(data), this.options)

  }



  getleavePolicy(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leavePolicy/get', JSON.stringify(data), this.options)
  }

  createleavePolicy(data: leavePolicy): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    return this.httpClient.post<leavePolicy>(this.baseUrl + 'api/leavePolicy/create', JSON.stringify(data), this.options)

  }
  updateleavePolicy(data: leavePolicy): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    return this.httpClient.put<leavePolicy>(this.baseUrl + 'api/leavePolicy/update', JSON.stringify(data), this.options)
  }
  getEmployeeLeave(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any, LEAVE_POLICY_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      LEAVE_POLICY_ID: LEAVE_POLICY_ID

    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leavePolicyLeaveMapping/getMappingData', JSON.stringify(data), this.options)
  }
  NAMES: any = []
  policyMapAddBulk(LEAVE_POLICY_ID: any, PAYROLL_LEAVE_IDS: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      LEAVE_POLICY_ID: LEAVE_POLICY_ID,
      PAYROLL_LEAVE_IDS: PAYROLL_LEAVE_IDS.toString()
    }
    return this.httpClient.post<any>(this.baseUrl + 'api/leavePolicyLeaveMapping/addBulk', JSON.stringify(data), this.options)
  }



  getLeaveData1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter

    };

    return this.httpClient.post<any[]>(this.baseUrl + "api/payrollLeaveMaster/get", JSON.stringify(data), this.options);

  }

  createData1(leaveMaster: leaveMaster) {
    leaveMaster.CLIENT_ID = this.clientId;

    if (leaveMaster.APPLICABLE_TYPES.toString() != undefined) {
      leaveMaster.APPLICABLE_TYPES = leaveMaster.APPLICABLE_TYPES.toString();

    }
    // console.log(leaveMaster.APPLICABLE_TYPES);
    if (leaveMaster.CLUBBED_WITH_NOT_POSSIBLE != undefined) {
      leaveMaster.CLUBBED_WITH_NOT_POSSIBLE = leaveMaster.CLUBBED_WITH_NOT_POSSIBLE.toString();

    }
    // console.log(leaveMaster.CLUBBED_WITH_NOT_POSSIBLE);
    return this.httpClient.post<any[]>(this.baseUrl + "api/payrollLeaveMaster/create", JSON.stringify(leaveMaster),
      this.options);
  }

  updateData1(leaveMaster: leaveMaster) {

    leaveMaster.APPLICABLE_TYPES = leaveMaster.APPLICABLE_TYPES.toString();
    // console.log(leaveMaster.APPLICABLE_TYPES);

    leaveMaster.CLUBBED_WITH_NOT_POSSIBLE = (leaveMaster.CLUBBED_WITH_NOT_POSSIBLE).toString();
    // console.log(leaveMaster.CLUBBED_WITH_NOT_POSSIBLE);
    return this.httpClient.put<any[]>(this.baseUrl + "api/payrollLeaveMaster/update", JSON.stringify(leaveMaster),
      this.options);
  }

  // getCustomerwiseMemoDetailed(

  //   pageIndex: number,

  //   pageSize: number,

  //   sortKey: string,

  //   sortValue: string,

  //   filter: any,

  //   SALES_EXECUTIVE_ID: any

  // ) {

  //   this.httpHeaders = new HttpHeaders({

  //     apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",

  //     applicationkey: "AfIpESwBr5eHp7w3",

  //     supportkey: this.cookie.get("supportKey"),

  //     "Content-Type": "application/json",

  //     // Token: this.cookie.get("token"),

  //   }); this.options1 = {

  //     headers: this.httpHeaders,

  //   };

  //   var data = {

  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID

  //   };

  //   return this.httpClient.post<any[]>(

  //     this.baseUrl + "api/reports/customerWiseMemoDetailed/getCustomerWiseMemoDetailed", JSON.stringify(data), this.options);

  // }

  // getQuotationDetailedReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: any,
  //   DATE: any,
  //   SALES_EXECUTIVE_ID: any,
  //   MODEL_ID: any,
  //   MEMO_DATE: any,
  //   INVOICE_DATE: any,) {
  //   this.httpHeaders = new HttpHeaders({
  //     apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
  //     applicationkey: "AfIpESwBr5eHp7w3",
  //     supportkey: this.cookie.get("supportKey"),
  //     "Content-Type": "application/json",
  //     // Token: this.cookie.get("token"),
  //   });
  //   this.options1 = {
  //     headers: this.httpHeaders,
  //   };
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     DATE: DATE,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     MODEL_ID: MODEL_ID,
  //     MEMO_DATE: MEMO_DATE,
  //     INVOICE_DATE: INVOICE_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.baseUrl + "api/reports/quotationDetailed/getQuotationDetailed", JSON.stringify(data), this.options);
  // }



  // getMemoDetailed(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: any,
  //   MEMO_DATE: any,
  //   SALES_EXECUTIVE_ID: any,
  //   MODEL_ID: any,
  //   INVOICE_DATE: any
  // ) {
  //   this.httpHeaders = new HttpHeaders({
  //     apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
  //     applicationkey: "AfIpESwBr5eHp7w3",
  //     supportkey: this.cookie.get("supportKey"),
  //     "Content-Type": "application/json",
  //     // Token: this.cookie.get("token"),
  //   });
  //   this.options1 = {
  //     headers: this.httpHeaders,
  //   };


  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MEMO_DATE: MEMO_DATE,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     MODEL_ID: MODEL_ID,
  //     INVOICE_DATE: INVOICE_DATE
  //   };
  //   return this.httpClient.post<any[]>(this.baseUrl + "api/reports/memoDetailed/getMemoDetailedReport ", JSON.stringify(data), this.options);
  // }


  // getMemodiscountDetailed(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: any,
  //   MEMO_DATE: any,
  //   SALES_EXECUTIVE_ID: any,
  //   MODEL_ID: any,
  //   APPROVER_ID: any
  // ) {
  //   this.httpHeaders = new HttpHeaders
  //     ({
  //       apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
  //       applicationkey: "AfIpESwBr5eHp7w3",
  //       supportkey: this.cookie.get("supportKey"),
  //       "Content-Type": "application/json",
  //       // Token: this.cookie.get("token"), 
  //     });
  //   this.options1 = {
  //     headers: this.httpHeaders,
  //   };
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MEMO_DATE: MEMO_DATE,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     MODEL_ID: MODEL_ID,
  //     APPROVER_ID: APPROVER_ID
  //   };


  //   return this.httpClient.post<any[]>(this.baseUrl + "api/reports/memoWiseDiscountDetailed/getMemoWiseDiscountDetailed", JSON.stringify(data), this.options);
  // }



  // getSalesExecutiveQuotationReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "salesExecutiveWiseDetailedReport/salesExecutiveWiseDetailedReport", JSON.stringify(data), this.options);
  // }

  // getSalesExecutiveDiscountReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
  //     CLUSTER_ID: this.ClusterId
  //   };
  //   return this.httpClient.post<any>(this.url + "salesExecutiveWiseDetailedDiscountReport/salesExecutiveWiseDetailedDiscountReport", JSON.stringify(data), this.options);
  // }


  // getSalesExecutiveMemoReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
  //   };
  //   return this.httpClient.post<any>(this.url + "reports/salesExecutiveWiseDetailedMemo/getSalesExecutiveWiseDetailedMemo	", JSON.stringify(data), this.options);
  // }

  // getSalesExecutiveInvoiceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, INVOICE_START_DATE: any,
  //   INVOICE_END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     INVOICE_START_DATE: INVOICE_START_DATE,
  //     INVOICE_END_DATE: INVOICE_END_DATE,
  //     BRANCH_ID: BRANCH_ID.toString(),
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "salesExecutiveWiseInvoiceReport/getsalesExecutiveWiseInvoiceReport", JSON.stringify(data), this.options);
  // }


  // getSalesExecutiveWiseDetailedReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //     CLUSTER_ID: this.ClusterId,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url +
  //     "salesExecutivewiseSummary/getSalesExecutivewiseSalesDetailedReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // getModelWiseSummary(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   // salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     // SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url + "modelWiseSalesSummary/getModelWiseSalesSummary",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // getSalesExecutiveWiseModelWiseSummaryReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //     CLUSTER_ID: this.ClusterId,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url +
  //     "salesExecutivewiseSummary/getSalesExecutiveWiseModelWiseSummary",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // getModelwiseDailyDetailedReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   orgID: number,
  //   branchID: string,
  //   fromDate: string,
  //   toDate: string,
  //   modelID: string,
  //   varientID: string,
  //   salesExecutiveID: string,
  //   // fuelFilter: string,
  //   // transmissionFilter: string
  // ): Observable<[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     ORG_ID: orgID,
  //     BRANCH_ID: branchID,
  //     START_DATE: fromDate,
  //     END_DATE: toDate,
  //     MODEL_ID: modelID,
  //     VARIENT_ID: varientID,
  //     SALES_EXECUTIVE_ID: salesExecutiveID,
  //     // FUEL_TYPE: fuelFilter,
  //     // TRANSMISSION: transmissionFilter,
  //   };

  //   return this.httpClient.post<[]>(
  //     this.url +
  //     "modelWiseSalesSummary/getModelwiseDailyDetailedReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }
  getAllActivityTypeMaster(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {

    }
    else {
      return this.httpClient.post<any>(
        this.url + "activitytypemasterService/get",
        JSON.stringify(data),
        this.options
      );
    }

  }

  createActivityMaster(abc: any): Observable<any> {
    abc.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "activitytypemasterService/create",
      JSON.stringify(abc),
      this.options
    );
  }

  updateActivityMaster(abc: any): Observable<any> {
    abc.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "activitytypemasterService/update",
      JSON.stringify(abc),
      this.options
    );
  }


  getActivityMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<activityMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {

    }
    else {
      return this.httpClient.post<activityMaster[]>(this.url + "activitymaster/get", JSON.stringify(data), this.options);
    }
  }

  createActivity(activityMaster: activityMaster): Observable<number> {
    activityMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "activitymaster/create", JSON.stringify(activityMaster), this.options);
  }

  updateActivity(activityMaster: activityMaster): Observable<number> {
    activityMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "activitymaster/update", JSON.stringify(activityMaster), this.options);
  }


  // getactivityTypeMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<activityTypeMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter
  //   };

  //   return this.httpClient.post<activityTypeMaster[]>(this.url + "activitytypemasterService/get", JSON.stringify(data), this.options);
  // }

  getAllserviceModuleExcelMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "serviceModuleExcelMaster/get", JSON.stringify(data), this.options);
  }
  createserviceModuleExcelMaster(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId; return this.httpClient.post<any>(
      this.url + "serviceModuleExcelMaster/create/",
      JSON.stringify(role),
      this.options);
  }
  updateserviceModuleExcelMaster(role: any): Observable<any> {
    return this.httpClient.put<any>
      (this.url + "serviceModuleExcelMaster/update/", JSON.stringify(role), this.options);
  }

  //   updateserviceReminderSMR(role: any): Observable<any> { return this.httpClient.put<any>
  //  ( this.url + "serviceModuleExcelMaster/update/",  JSON.stringify(role),  this.options ); }

  download(fileUrl: string) {
    var data = {
      fileUrl: fileUrl,
    };

    return this.httpClient.post<any>(
      this.retriveimgUrl + "ExcelMasters/" + fileUrl,
      JSON.stringify(data),
      this.options
    );
  }


  importexcelstep2(
    EXCEL_FILE_NAME: string,
    COLUMN_JSON: any,
    TABLE_ID: number,
    IS_ALLOW_DUPLICATES: string,

  ): Observable<any> {
    var data = {
      EXCEL_FILE_NAME: EXCEL_FILE_NAME,
      COLUMN_JSON: COLUMN_JSON,
      TABLE_ID: TABLE_ID,
      IS_ALLOW_DUPLICATES: IS_ALLOW_DUPLICATES,
    };

    return this.httpClient.post<any>(
      this.url + "excelImportMaster/ImportExcel",
      JSON.stringify(data),
      this.options
    );
  }

  serviceReminderSMR(
    EXCEL_FILE_NAME: string,
    COLUMN_JSON: any,
    TABLE_ID: number,
    IS_ALLOW_DUPLICATES: string,
    ALL_COLUMNS: any,
    CATEGORY_STATUS: any,
    SHEET_ID: any

  ): Observable<any> {
    var data = {
      EXCEL_FILE_NAME: EXCEL_FILE_NAME,
      COLUMN_JSON: COLUMN_JSON,
      TABLE_ID: TABLE_ID,
      IS_ALLOW_DUPLICATES: IS_ALLOW_DUPLICATES,
      DUPLICATE_FIELDS: ALL_COLUMNS,
      CATEGORY_STATUS: CATEGORY_STATUS,
      SHEET_ID: SHEET_ID
    };

    return this.httpClient.post<any>(
      this.url + "serviceReminderSMR/ImportSMRDetailsToExcel",
      JSON.stringify(data),
      this.options
    );
  }
  postservicefollowup(
    EXCEL_FILE_NAME: string,
    COLUMN_JSON: any,
    TABLE_ID: number,
    IS_ALLOW_DUPLICATES: string,
    SHEET_ID: any

  ): Observable<any> {
    var data = {
      EXCEL_FILE_NAME: EXCEL_FILE_NAME,
      COLUMN_JSON: COLUMN_JSON,
      TABLE_ID: TABLE_ID,
      IS_ALLOW_DUPLICATES: IS_ALLOW_DUPLICATES,
      SHEET_ID: SHEET_ID
    };

    return this.httpClient.post<any>(
      this.url + "postservicefollowup/ImportPSFDetailsToExcel",
      JSON.stringify(data),
      this.options
    );
  }
  hapAndGDMSLost(
    EXCEL_FILE_NAME: string,
    COLUMN_JSON: any,
    TABLE_ID: number,
    IS_ALLOW_DUPLICATES: string,

  ): Observable<any> {
    var data = {
      EXCEL_FILE_NAME: EXCEL_FILE_NAME,
      COLUMN_JSON: COLUMN_JSON,
      TABLE_ID: TABLE_ID,
      IS_ALLOW_DUPLICATES: IS_ALLOW_DUPLICATES,
    };

    return this.httpClient.post<any>(
      this.url + "hapAndGDMSLost/ImportHapDetailsToExcel",
      JSON.stringify(data),
      this.options
    );
  }

  getSMRMastertabledata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,

    };
    return this.httpClient.post<any>(this.url + "serviceReminderSMR/getSMRLeadsData", JSON.stringify(data), this.options);
  }
  // getPSFMastertabledata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter
  //   };
  //   return this.httpClient.post<any>(this.url + "postservicefollowup/get", JSON.stringify(data), this.options);
  // }
  gethapAndGDMSLostMastertabledata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "hapAndGDMSLost/get", JSON.stringify(data), this.options);
  }

  getAllActivityMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, };

    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {

    }
    else {
      return this.httpClient.post<any>(this.url + "activitymaster/get", JSON.stringify(data), this.options);

    }
  }


  getAllActionMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, };

    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {

    }
    else {
      return this.httpClient.post<any>(this.url + "actionmaster/get", JSON.stringify(data), this.options);

    }

  }

  //   getAllActivityTypeMaster(pageIndex:number,pageSize:number,sortKey:string,sortValue:string,filter:string):Observable<any> {
  //     var data={pageIndex:pageIndex,pageSize:pageSize,sortKey:sortKey,sortValue:sortValue,filter:filter,};
  //     return this.httpClient.post<any>(this.url+"activitytypemasterService/get",JSON.stringify(data),this.options);}
  // }

  getActionMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<actionMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {
      console.log("getActionMaster1");

    } else {
      return this.httpClient.post<actionMaster[]>(this.url + "actionmaster/get", JSON.stringify(data), this.options);
    }


  }

  createAction(actionMaster: actionMaster): Observable<number> {
    actionMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "actionmaster/create", JSON.stringify(actionMaster), this.options);
  }

  updateAction(actionMaster: actionMaster): Observable<number> {
    actionMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "actionmaster/update", JSON.stringify(actionMaster), this.options);
  }

  updateServiceInsuranceMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "hapAndGDMSLost/update",
      JSON.stringify(ticketGroup),
      this.options
    );
  }
  updatePSFMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "postservicefollowup/update",
      JSON.stringify(ticketGroup),
      this.options
    );
  }
  updateServiceSMRMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(
      this.url + "serviceReminderSMR/update/",
      JSON.stringify(ticketGroup),
      this.options
    );
  }

  // SMR Lead Activity 
  // creteActivityleadmaster(ActivityLeadMaster1: ActivityLeadMaster): Observable<number> {
  //   ActivityLeadMaster1.CLIENT_ID = this.clientId;
  //   return this.httpClient.post<number>(this.url + "SMRleadactivitydetailes/create", JSON.stringify(ActivityLeadMaster1), this.options);
  // }
  // updateActivityleadmaster(ActivityLeadMaster1: ActivityLeadMaster): Observable<number> {
  //   ActivityLeadMaster1.CLIENT_ID = this.clientId;
  //   return this.httpClient.put<number>(this.url + "SMRleadactivitydetailes/update", JSON.stringify(ActivityLeadMaster1), this.options);
  // }
  // getAllActivityleadMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  //   var data = { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, };
  //   return this.httpClient.post<any>(this.url + "SMRleadactivitydetailes/get", JSON.stringify(data), this.options);
  // }

  creteActivityleadmaster(ActivityLeadMaster1: ActivityLeadMaster): Observable<number> {
    ActivityLeadMaster1.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "SMRleadactivitydetailes/AddNewActivity", JSON.stringify(ActivityLeadMaster1), this.options);
  }
  updateActivityleadmaster(ActivityLeadMaster1: ActivityLeadMaster): Observable<number> {
    ActivityLeadMaster1.CLIENT_ID = this.clientId; return this.httpClient.put<number>(this.url + "serviceReminderSMR/update", JSON.stringify(ActivityLeadMaster1), this.options);
  }
  getAllActivityleadMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    }; return this.httpClient.post<any>(this.url + "leadactivitydetailes/get", JSON.stringify(data), this.options);

  }

  MapCCE(
    cceId1: number,
    data1: any,
    CATEGORY_STATUS: any,
    ASSIGNED_BY: any
  ): Observable<number> {
    var data = {
      CCE_ID: cceId1,
      LEAD_IDS: data1,
      CATEGORY_STATUS: CATEGORY_STATUS,
      ASSIGNED_BY: ASSIGNED_BY

    };

    return this.httpClient.post<number>(
      this.url + "serviceReminderSMR/AssignBulkLeadstoCCE",
      data,
      this.options
    );
  }

  Mapservice(
    SERVICE_ADVISER_ID: number,
    data1: any,

  ): Observable<number> {
    var data = {
      SERVICE_ADVISER_ID: SERVICE_ADVISER_ID,
      LEAD_IDS: data1,
      // CATEGORY_STATUS: CATEGORY_STATUS

    };

    return this.httpClient.post<number>(
      this.url + "serviceReminderSMR/transferLeadstoServiceAdviser",
      data,
      this.options
    );
  }

  TransferCCE(
    CATEGORY_STATUSSS: any,
    data1: any
  ): Observable<number> {
    var data = {
      CATEGORY_STATUS: CATEGORY_STATUSSS,
      LEAD_IDS: data1,
    };

    return this.httpClient.post<number>(
      this.url + "serviceReminderSMR/updateBulkstatus",
      data,
      this.options
    );
  }


  // new reports 24 

  // bankwisememoreport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   START_DATE: string,
  //   END_DATE: string,
  //   BANK_NAME: any,
  //   BRANCH_ID: any,
  // ): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     BANK_NAME: BANK_NAME,
  //     BRANCH_ID: BRANCH_ID,
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(
  //     this.url + "reports/bankWiseMemoDetailed/getBanckWiseMemoDetailed",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  modelwisequotationreport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    MODEL_ID: any,
    BRANCH_ID: any,
    START_DATE: any,
    END_DATE: any,
  ): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
    };
    return this.httpClient.post<ThoughMaster[]>(
      this.url + "reports/modelWiseQuotationdetails/getModelWiseQuotationdetails",
      JSON.stringify(data),
      this.options
    );
  }


  // SalesHeadwisedetaileddiscount(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   START_DATE: string,
  //   END_DATE: string,
  //   REPORTING_HEAD_ID: any,
  //   BRANCH_ID: any,
  //   // CUSTOMER_NAME: any,
  //   // MODEL_ID:any ,
  //   // BRANCH_ID:any ,
  //   // DATE:any ,
  // ): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     REPORTING_HEAD_ID: REPORTING_HEAD_ID,
  //     BRANCH_ID: BRANCH_ID,
  //     // CUSTOMER_NAME: CUSTOMER_NAME,
  //     // MODEL_ID: MODEL_ID,
  //     // BRANCH_ID: BRANCH_ID,
  //     // DATE: DATE,
  //   };

  //   return this.httpClient.post<ThoughMaster[]>(
  //     this.url + "salesHeadWiseDiscountReport/getSalesHeadWiseDiscountReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }


  // Salesexecutiveinhouseinsurancesummary(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   START_DATE: any,
  //   END_DATE: any,
  //   SALES_EXECUTIVE_ID: any,
  // ): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     // SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     // BRANCH_ID: BRANCH_ID,
  //     // START_DATE: START_DATE,
  //     // END_DATE: END_DATE,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(
  //     this.url + "salesExecutiveWiseInsuranceSummary/getSalesExecutiveWiseInsuranceSummary",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // SalesHeadwisedetaileddiscountsummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, REPORTING_HEAD_ID: any, BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, REPORTING_HEAD_ID: REPORTING_HEAD_ID, BRANCH_ID: BRANCH_ID, START_DATE: START_DATE, END_DATE: END_DATE
  //     // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(this.url + "salesHeadwiseDiscountSummaryReport/getsalesHeadwiseDiscountReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // branchwisenhousefinancedetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: string, END_DATE: string, BRANCH_ID: any, VARIENT_ID: string, MODEL_ID: any, BANK_ID: any): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, START_DATE: START_DATE, END_DATE: END_DATE, BRANCH_ID: BRANCH_ID, VARIENT_ID: VARIENT_ID, MODEL_ID: MODEL_ID, BANK_ID: BANK_ID
  //     // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(this.url + "branchwiseInhousefinanceDetails/getbranchwiseInhousefinanceDetails",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // Customerwisefinancereport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, BRANCH_ID: BRANCH_ID, SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID, MODEL_ID: MODEL_ID, START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(this.url + "customerWiseFinanceReport/getCustomerWiseFinanceReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // Modelwiseinvoicesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, MODEL_ID: MODEL_ID, START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(this.url + "modelWiseInvoiceSummaryReport/getModelWiseInvoiceSummaryReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // branchwiseadvancecollectedreports(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   BRANCH_ID: any,
  //   START_DATE: any,
  //   END_DATE: any
  // ): Observable<ThoughMaster[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE
  //   };
  //   return this.httpClient.post<ThoughMaster[]>(
  //     this.url + "branchwiseAdvanceCollected/getbranchwiseAdvanceCollected",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // customerwiseinovice(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   BRANCH_ID: any,
  //   SALES_EXECUTIVE_ID: any,
  //   CUSTOMER_ID: any,
  //   MODEL_ID: any,
  //   START_DATE: any,
  //   END_DATE: any,
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     CUSTOMER_ID: CUSTOMER_ID,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any>(
  //     this.url + "customerWiseInvoiceReport/getCustomerWiseInvoiceReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }


  // customerwisedetaildiscountreport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   START_DATE: any,
  //   END_DATE: any,
  //   MODEL_ID: any,
  //   SALES_EXECUTIVE_ID: any,
  //   VARIENT_ID: any,
  //   BRANCH_ID: any,
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     MODEL_ID: MODEL_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     VARIENT_ID: VARIENT_ID,
  //     BRANCH_ID: BRANCH_ID,
  //   };
  //   return this.httpClient.post<any>(
  //     this.url + "reports/customerWiseDiscountDetailedReport/get",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // customerwiseinsurancereport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   START_DATE: any,
  //   END_DATE: any,
  //   SALES_EXECUTIVE_ID: any,
  //   MODEL_ID: any,
  //   BRANCH_ID: any,
  //   INSURANCE_TYPE: any,
  //   INSURANCE_NAME: any,
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     MODEL_ID: MODEL_ID,
  //     BRANCH_ID: BRANCH_ID,
  //     INSURANCE_TYPE: INSURANCE_TYPE,
  //     INSURANCE_NAME: INSURANCE_NAME,
  //   };
  //   return this.httpClient.post<any>(
  //     this.url + "customerwiseInsuranceReport/getcustomerwiseInsuranceReport",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // Customerwisedetcailedreport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   MODEL_ID: any,
  //   VARIENT_ID: any,
  //   FUEL_TYPE: any,
  //   TRANSMISSION_TYPE: any,
  //   SALES_EXECUTIVE_ID: any,
  //   GENEREL_MANGER_ID: any,
  //   SALES_HEAD_ID: any,
  //   ASSISTANT_SALES_MANAGER_ID: any,
  //   QUOTATION_DATE: any,
  //   MEMO_DATE: any,
  //   INVOICE_DATE: any,
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     VARIENT_ID: VARIENT_ID,
  //     FUEL_TYPE: FUEL_TYPE,
  //     TRANSMISSION_TYPE: TRANSMISSION_TYPE,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     GENEREL_MANGER_ID: GENEREL_MANGER_ID,
  //     SALES_HEAD_ID: SALES_HEAD_ID,
  //     ASSISTANT_SALES_MANAGER_ID: ASSISTANT_SALES_MANAGER_ID,
  //     QUOTATION_DATE: QUOTATION_DATE,
  //     MEMO_DATE: MEMO_DATE,
  //     INVOICE_DATE: INVOICE_DATE,
  //   };
  //   return this.httpClient.post<any>(
  //     this.url + "reports/customerWiseDetailedReport/get",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  SalesHeadwisedetaileddiscounts(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, REPORTING_HEAD_ID: any, BRANCH_ID: any, START_DATE: string, END_DATE: string,): Observable<ThoughMaster[]> {

    var data = {

      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, REPORTING_HEAD_ID: REPORTING_HEAD_ID, BRANCH_ID: BRANCH_ID, START_DATE: START_DATE,

      END_DATE: END_DATE,



    };

    return this.httpClient.post<ThoughMaster[]>(

      this.url + "salesHeadWiseDiscountReport/getSalesHeadWiseDiscountReport ", JSON.stringify(data), this.options

    );

  }

  getstatus(empId: any, version: string): Observable<financeMaster[]> {
    var data = {

      empId: empId,

      VERSION: version,

    };
    return this.httpClient.post<financeMaster[]>(this.url + "attendance/getStatus", JSON.stringify(data), this.options);
  }


  // salesManagerwiseInsuranceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesManagerwiseInsuranceSummary/getsalesManagerwiseInsuranceSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // salesManagerwiseFinanceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesManagerwiseFinanceSummary/getsalesManagerwiseFinanceSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // branchwisesalesManagerwiseInhouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_MANAGER_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_MANAGER_ID: SALES_MANAGER_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "branchwisesalesManagerwiseInhouseFinanceSummary/getbranchwisesalesManagerwiseInhouseFinanceSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // salesManagerWiseSalesExecutiveWise(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, MODEL_ID: any, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     MODEL_ID: MODEL_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesManagerWiseSalesExecutiveWiseModelWiseSummary/getsalesManagerWiseSalesExecutiveWiseModelWiseSummary", JSON.stringify(data1), this.options
  //   );
  // }

  gmwiseDiscountSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    GM_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      GM_ID: GM_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
    };
    return this.httpClient.post<any[]>(this.url + "gmwiseDiscountSummary/getGMwiseDiscountSummary", JSON.stringify(data1), this.options
    );
  }

  getGMwiseCustomerwise(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    GM_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      GM_ID: GM_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
    };
    return this.httpClient.post<any[]>(this.url + "gmwiseDiscountSummary/getGMwiseCustomerwiseDiscountDetailed", JSON.stringify(data1), this.options
    );
  }

  // discountDetailedReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "discountDetailedReports/getdiscountDetailedReports", JSON.stringify(data1), this.options
  //   );
  // }

  // discountSummaryReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "discountDetailedReports/getdiscountDetailedReportSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExeWiseAdvanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExeWiseAdvanceReport/getsalesExeWiseAdvanceReport", JSON.stringify(data1), this.options
  //   );
  // }


  // BranchsalesExeWiseAdvanceDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/branchWiseSalesExecutiveWiseAdvanceDetailed/get", JSON.stringify(data1), this.options
  //   );
  // }

  // branchWiseFinance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/branchWiseFinanceSummary/get", JSON.stringify(data1), this.options
  //   );
  // }

  // modelWiseRept(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "modelWiseSummaryReports/getModelWiseSummaryReports", JSON.stringify(data1), this.options
  //   );
  // }

  // inhouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "branchwiseInhouseInsuranceSummary/getbranchwiseInhouseInsuranceSummary", JSON.stringify(data1), this.options
  //   );
  // }
  // outhouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "branchwiseOuthouseInsuranceSummary/getbranchwiseOuthouseInsuranceSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // branchWiseAdvance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "branchWiseAdvanceReport/getbranchWiseAdvanceReport", JSON.stringify(data1), this.options
  //   );
  // }

  // branchWiseDiscount(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/branchWiseDiscountSummary/get", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseInhouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesExecutiveWiseInhouseInsuranceDetailed/get", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseOuthouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/salesExecutiveWiseOuthouseInsuranceDetailed/get", JSON.stringify(data1), this.options
  //   );
  // }

  // inhouseInsuranceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/InhouseInsuranceSummary/get", JSON.stringify(data1), this.options
  //   );
  // }

  // outhouseInsuranceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/OuthouseInsuranceSummary/get", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseFinanceInhouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseFinanceInhouseReport/getSalesExecutiveWiseFinanceInhouseReport", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseFinanceOuthouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseFinanceOuthouseReport/getSalesExecutiveWiseFinanceOuthouseReport", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutivewiseBankwiseInhouseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   BANK_ID: any, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BANK_ID: BANK_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutivewiseBankwiseInhouseSummary/getsalesExecutivewiseBankwiseInhouseSummary", JSON.stringify(data1), this.options
  //   );
  // }


  // salesExecutivewiseInhouseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutivewiseInhouseSummary/getsalesExecutivewiseInhouseSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutivewiseOuthouseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutivewiseOuthouseSummary/getsalesExecutivewiseOuthouseSummary", JSON.stringify(data1), this.options
  //   );
  // }

  // salesExecutiveWiseBankWiseOuthouseReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseBankWiseOuthouseReports/getSalesExecutiveWiseBankWiseOuthouseReport", JSON.stringify(data1), this.options
  //   );
  // }


  // salesExecutiveWiseRsaDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  //   SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseRsaDetailedReport/getSalesExecutiveWiseRsaDetailedReport", JSON.stringify(data1), this.options
  //   );
  // }


  addbulkShiftMap(data11): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };


    data11


    return this.httpClient.post<any>(this.baseUrl + 'api/employeeShiftMapping/addBulk', JSON.stringify(data11), this.options)
  }

  getShiftMappingData(shift_id: number, BRANCHIDS: string, departids: string, designationids: string): Observable<any> {

    var data =

    {

      SHIFT_ID: shift_id,

      BRANCH_IDS: BRANCHIDS,



      DEPARTMENT_IDS: departids,

      DESIGNATION_IDS: designationids

    };

    return this.httpClient.post<any>(this.url + "employeeShiftMapping/getMappingData", JSON.stringify(data), this.options);

  }

  getAllShifts(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ShiftMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<ShiftMaster[]>(this.url + "shift/get/", JSON.stringify(data), this.options);
  }

  createShift(shift: ShiftMaster): Observable<number> {
    shift.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shift/create/", JSON.stringify(shift), this.options);
  }

  updateShifts(shift: ShiftMaster): Observable<number> {
    shift.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shift/update/", JSON.stringify(shift), this.options);
  }


  getAllEmployeeLeave1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, EMPLOYEE_ID: any): Observable<Leave[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: EMPLOYEE_ID
    };

    return this.httpClient.post<Leave[]>(this.url + "employeeLeaveMapping/get", JSON.stringify(data), this.options);
  }

  getAllEmployeeLeave12(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,): Observable<Leave[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,

    };

    return this.httpClient.post<Leave[]>(this.url + "employeeLeaveMapping/getData", JSON.stringify(data), this.options);
  }

  createLeave1(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "employeeLeaveMapping/create", JSON.stringify(leave), this.options);
  }

  updateLeave1(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "employeeLeaveMapping/update", JSON.stringify(leave), this.options);
  }


  monthlyLeaveReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "reports/EmployeesMonthlyAttendanceReport/getEmployeesMonthlyAttendanceReport", JSON.stringify(data1), this.options
    );
  }


  // getmodelwiseprice(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, FUEL_TYPE: any, TRANSMISSION: any, PRICELIST_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     FUEL_TYPE: FUEL_TYPE,
  //     TRANSMISSION: TRANSMISSION,
  //     PRICELIST_ID: PRICELIST_ID
  //   };
  //   return this.httpClient.post<any>(this.url + "modelwisePriceReport/getModelwisePriceReport", JSON.stringify(data), this.options);
  // }

  // getbranchwisemodelwiseprice(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, MODEL_ID: any, BRANCH_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     MODEL_ID: MODEL_ID,
  //     BRANCH_ID: BRANCH_ID
  //   };
  //   return this.httpClient.post<any>(this.url + "branchwiseModelwiseReport/getbranchwiseModelwiseReport", JSON.stringify(data), this.options);
  // }

  // getbranchwiseinvoicedetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, MEMO_START_DATE: any, MEMO_END_DATE: any, MODEL_ID: string, BRANCH_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     // DATE: DATE,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     MEMO_START_DATE: MEMO_START_DATE,
  //     MEMO_END_DATE: MEMO_END_DATE,
  //     MODEL_ID: MODEL_ID,
  //     BRANCH_ID: BRANCH_ID
  //   };
  //   return this.httpClient.post<any>(this.url + "branchwiseInvoiceDetails/getbranchwiseInvoiceDetails", JSON.stringify(data), this.options);
  // }

  // getmodelwisevarientdetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, ID: any, FUEL_TYPE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     ID: ID,
  //     FUEL_TYPE: FUEL_TYPE
  //   };
  //   return this.httpClient.post<any>(this.url + "modelWiseVarientDetailedReport/getModelWiseVarientDetailedReport", JSON.stringify(data), this.options);
  // }
  // getModelwisequotationsummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE
  //   };
  //   return this.httpClient.post<any>(this.url + "modelWiseQuotationReport/getModelWiseQuotationReport", JSON.stringify(data), this.options);
  // }
  // getModelWiseQuotationdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: Array<any>, BRANCH_ID: Array<any>, DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     BRANCH_ID: BRANCH_ID,
  //     DATE: DATE
  //   };
  //   return this.httpClient.post<any>(this.url + "reports/modelWiseQuotationdetails/getModelWiseQuotationdetails", JSON.stringify(data), this.options);
  // }

  // getsalesManagerExecutivewisereport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any, SALES_MANAGER_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     VARIENT_ID: VARIENT_ID,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     SALES_MANAGER_ID: SALES_MANAGER_ID,
  //   };
  //   return this.httpClient.post<any>(this.url + "salesManagerExeWiseReport/getSalesManagerExeWiseReport", JSON.stringify(data), this.options);
  // }
  // getCustomerWiseRSAdetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, MODEL_ID: any, FROM_DATE: any, TO_DATE: any, CUSTOMER_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     VARIENT_ID: VARIENT_ID,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: FROM_DATE,
  //     END_DATE: TO_DATE,
  //     CUSTOMER_ID: CUSTOMER_ID
  //     // SALES_MANAGER_ID:SALES_MANAGER_ID,
  //   };
  //   return this.httpClient.post<any>(this.url + "customerWiseRsaDetailedReport/getCustomerWiseRsaDetailedReport", JSON.stringify(data), this.options);
  // }
  // getSalesExWiseRSAsummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "salesExecutiveWiseRsaSummaryReport/getSalesExecutiveWiseRsaSummaryReport", JSON.stringify(data), this.options);
  // }
  // getinsurancesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, INSURANCE_NAME: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     INSURANCE_NAME: INSURANCE_NAME,
  //   };
  //   return this.httpClient.post<any>(this.url + "insuranceComapnyWiseSummaryReport/getInsuranceComapnyWiseSummaryReport", JSON.stringify(data), this.options);
  // }
  // getfinancebankwisesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any, BANK_NAME: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MONTH: MONTH,
  //     YEAR: YEAR,
  //     BANK_NAME: BANK_NAME,
  //   };
  //   return this.httpClient.post<any>(this.url + "financeBankWiseSummaryReport/getFinanceBankWiseSummaryReport", JSON.stringify(data), this.options);
  // }
  // getcustomerwisequotationdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, START_DATE: any, END_DATE: any, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, FUEL: any, TRANSMISSION: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MODEL_ID: MODEL_ID,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     BRANCH_ID: BRANCH_ID,
  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
  //     VARIENT_ID: VARIENT_ID,
  //     FUEL_TYPE: FUEL,
  //     TRANSMISSION: TRANSMISSION
  //   };
  //   return this.httpClient.post<any>(this.url + "customerwiseQuotationDetails/getcustomerwiseQuotationDetails", JSON.stringify(data), this.options);
  // }

  getAllShortleave(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: string

  ): Observable<Latemark[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

    };




    return this.httpClient.post<Latemark[]>(

      this.url + "shortLeave/get",

      JSON.stringify(data),

      this.options

    );

  }

  getbranches(pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string): Observable<any> {
    this.httpHeaders1 = new HttpHeaders
      ({
        supportkey: this.cookie.get("supportKey"),
        apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
        applicationkey: "AfIpESwBr5eHp7w3",
        Token: this.cookie.get("token"),
      });
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    }
    return this.httpClient.post<any>(this.url + 'holidayBranchMapping/get', JSON.stringify(data), this.options)
  }

  addbranch2(holiday_id: number, branchmap: any): Observable<any> {
    var data = {
      HOLIDAY_ID: holiday_id,
      data: branchmap,

    };
    return this.httpClient.post<any>(this.url + 'holidayBranchMapping/addBulk', JSON.stringify(data), this.options)
  }


  getAllMembers1(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: any
  ) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(this.url + "reportSchedule/get", JSON.stringify(data), this.options
    );
  }

  create11(schedule: schedule): Observable<any> {
    schedule.CLIENT_ID = this.clientId;


    return this.httpClient.post<any[]>(
      this.url + "reportSchedule/create",
      JSON.stringify(schedule),
      this.options
    );
  }
  update11(schedule: schedule): Observable<any> {
    schedule.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(
      this.url + "reportSchedule/update",
      JSON.stringify(schedule),
      this.options
    );
  }

  getholidaybranch(HOLIDAY_ID): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      HOLIDAY_ID: HOLIDAY_ID,

    }
    return this.httpClient.post<any>(this.baseUrl + 'api/holidayBranchMapping/getMappingData', JSON.stringify(data), this.options)
  }

  // get for salary-slip

  getAllsalaryslip(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: any
  ) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(this.baseUrl + "api/employeePaySlip/get", JSON.stringify(data), this.options
    );
  }

  createsalaryslip(payslip: payslip): Observable<any> {
    payslip.CLIENT_ID = this.clientId;


    return this.httpClient.post<any[]>(
      this.baseUrl + "api/employeePaySlip/create",
      JSON.stringify(schedule),
      this.options
    );
  }
  updatesalaryslip(payslip: payslip): Observable<any> {
    payslip.CLIENT_ID = this.clientId;
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/employeePaySlip/UploadSalrySlip",
      JSON.stringify(payslip),
      this.options
    );
  }

  onUpload6(folderName, selectedFile, filename) {

    this.httpHeaders1 = new HttpHeaders({

      Accept: "application/json",

      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",

      applicationkey: "AfIpESwBr5eHp7w3",

      deviceid: this.cookie.get("deviceId"),

      supportkey: this.cookie.get("supportKey"),

      Token: this.cookie.get("token"),

    });




    this.options1 = {

      headers: this.httpHeaders1,

    };




    const fd = new FormData();

    fd.append("Image", selectedFile, filename);




    return this.httpClient.post(this.imgUrl + folderName, fd, this.options1);

  }

  // /employeePaySlip/getSalarySlip

  getempsalary(EMPLOYEE_ID, YEAR, MONTH_NAME): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      YEAR: YEAR,
      MONTH_NAME: MONTH_NAME

    }
    return this.httpClient.post<any>(this.baseUrl + 'api/employeePaySlip/getSalarySlip', JSON.stringify(data), this.options)
  }

  onUpload5(folderName, selectedFile, filename) {
    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options1 = {
      headers: this.httpHeaders1,
    };

    const fd = new FormData();
    fd.append("Image", selectedFile, filename);

    return this.httpClient.post(this.imgUrl + folderName, fd, this.options1);
  }

  getRoleDetails1(roleId: number, PARENT_ID: string,) {

    var data = {

      ROLE_ID: roleId,

      filter: PARENT_ID,

    };




    return this.httpClient.post<Roledetails[]>(

      this.url + "roleDetails/getData",

      JSON.stringify(data),

      this.options

    );

  }

  getBranchWiseLeaveSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/branchWiseLeaveSummaryReport/getBranchWiseLeaveSummaryReport", JSON.stringify(data), this.options);
  }

  getBranchWiseAbsentSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/branchWiseAbsentSummaryReport/getBranchWiseAbsentSummaryReport", JSON.stringify(data), this.options);
  }

  getEmployeeLeaveApplication(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/employeeLeaveApplication/get", JSON.stringify(data), this.options);
  }

  getAttendance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/attendance/get", JSON.stringify(data), this.options);
  }


  employeeStartedDayMaxDistanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/employeeStartedDayMaxDistanceReport/getemployeeStartedDayMaxDistanceReport", JSON.stringify(data), this.options);
  }

  dailyMorningShortLeave(pageIndex4: number, pageSize4: number, sortKey: string, sortValue: string, filter: any,
    FROM_DATE: any, TO_DATE: any, SHIFT_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex4,
      pageSize: pageSize4,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      SHIFT_ID: SHIFT_ID
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/dailyMorningShortLeave/getDailyMorningShortLeave", JSON.stringify(data), this.options);
  }

  dailyEveningShortLeave(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    FROM_DATE: any, TO_DATE: any, SHIFT_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      SHIFT_ID: SHIFT_ID
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/dailyEveningShortLeave/getDailyEveningShortLeave", JSON.stringify(data), this.options);
  }

  mostLateCommingEmployee(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    MONTH: any, YEAR: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/mostLateCommingEmployee/getMostLateCommingEmployee", JSON.stringify(data), this.options);
  }

  suparLateEmployeeReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SHIFT_ID: any, FROM_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SHIFT_ID: SHIFT_ID,
      FROM_DATE: FROM_DATE,

    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/suparLateEmployeeReport/getSuparLateEmployeeReport", JSON.stringify(data), this.options);
  }


  // getMontlyModelWiseInvoiceGenerated(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, START_DATE: any,
  //   END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MONTH: MONTH,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //   };
  //   return this.httpClient.post<any>(this.url + "monthlyModelWiseInvoiceGeneratedReport/getMonthlyModelWiseInvoiceGeneratedReport", JSON.stringify(data), this.options);
  // }

  getEmployeeLeaveApplicationDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(
      this.baseUrl + "api/employeeLeaveApplicationDetails/get", JSON.stringify(data), this.options);
  }

  // getMontlyBranchWiseModelWiseInvoiceGenerated(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE
  //   };
  //   return this.httpClient.post<any>(this.url + "monthlyBranchWiseModelWiseInvoiceGeneratedReport/getMonthlyBranchWiseModelWiseInvoiceGeneratedReport", JSON.stringify(data), this.options);
  // }

  // getInhouseFinanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "inhouseFinanceSummary/get", JSON.stringify(data), this.options);
  // }


  // getOuthouseFinanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "outhouseFinanceSummary/get", JSON.stringify(data), this.options);
  // }

  // getSalesExecutiveWiseQuotationGeneratedTodayReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
  //   END_DATE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "reports/quotationGeneratedToday/getQuotationGeneratedToday", JSON.stringify(data), this.options);
  // }






  // getOuthouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "outhouseInsuranceSummary/get",
  //     JSON.stringify(data), this.options);
  // }











  // getInhouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

  //   START_DATE: any, END_DATE: any): Observable<any[]> {

  //   var data = {

  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     START_DATE: START_DATE,

  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };




  //   return this.httpClient.post<any[]>(this.url + "inhouseInsuranceSummary/get",

  //     JSON.stringify(data), this.options);

  // }













  // getBranchWiswMostDiscountApproval(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

  //   START_DATE: any, END_DATE: any): Observable<any[]> {

  //   var data = {

  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     START_DATE: START_DATE,

  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,

  //   };

  //   return this.httpClient.post<any[]>(this.url + "monthlyBranchWiseMostAprrovalDiscount/getBranchWiseMostApprovalDiscount",

  //     JSON.stringify(data), this.options);

  // }











  // getBranchWiseMemoPendancy(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "reports/branchWiseMemoPendancy/getBranchWiseMemoPendancy",
  //     JSON.stringify(data), this.options);
  // }












  // getBranchWiseDiscountPendancy(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
  //   START_DATE: any, END_DATE: any): Observable<any[]> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "branchWiseDiscountPendancyReport/getBranchWiseDiscountPendancyReport",
  //     JSON.stringify(data), this.options);
  // }


  getLeaveTransactionSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any): Observable<any[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      START_DATE: START_DATE,

      END_DATE: END_DATE,

    }; return this.httpClient.post<any[]>(this.url + "reports/leaveTransactionSummary/getLeaveTransactionSummary",

      JSON.stringify(data), this.options);

  };
  getAllEmployeeLeave3(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Leave[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,





    };



    return this.httpClient.post<Leave[]>(this.url + "employeeLeaveTransactions/getData", JSON.stringify(data), this.options);

  }

  getLeaveBalance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, EMPLOYEE_ID: any): Observable<any[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      EMPLOYEE_ID: EMPLOYEE_ID



    };



    return this.httpClient.post<any[]>(this.url + "employeeLeaveMapping/get/", JSON.stringify(data), this.options);

  }

  // getbranchwiseOuthouseFinanceDetails(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   BRANCH_ID: any,
  //   START_DATE: any,
  //   END_DATE: any,
  //   VARIENT_ID: any,
  //   MODEL_ID: any,
  //   BANK_ID: Array<any>
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     BRANCH_ID: BRANCH_ID.toString(), START_DATE: START_DATE, END_DATE: END_DATE, VARIENT_ID: VARIENT_ID.toString(), MODEL_ID: MODEL_ID.toString(), BANK_ID: BANK_ID
  //   };

  //   return this.httpClient.post<any>(
  //     this.url + "branchwiseOuthouseFinanceDetails/getbranchwiseOuthouseFinanceDetails",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }

  // getbranchwisequotationdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, MODEL_ID: string, BRANCH_ID: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     MODEL_ID: MODEL_ID,
  //     BRANCH_ID: BRANCH_ID
  //   };
  //   return this.httpClient.post<any>(this.url + "branchwiseQuotationDetails/getbranchwiseQuotationDetails", JSON.stringify(data), this.options);
  // }

  getBankWiseSalesManagerWiseFinanceOuthouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

    BRANCH_ID: Array<any>, SALES_MANAGER_ID: Array<any>, MONTH: any, YEAR: any): Observable<any[]> {

    var data1 = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      BRANCH_ID: BRANCH_ID,

      SALES_MANAGER_ID: SALES_MANAGER_ID,

      MONTH: MONTH,

      YEAR: YEAR,

    };

    return this.httpClient.post<any[]>(this.url + "bankWiseSalesManagerWiseFinanceOuthouseReport/getbankSalesManagerWiseFinanceSummary", JSON.stringify(data1), this.options

    );

  }


  // getSalesManagerWise(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

  //   BRANCH_NAME: Array<any>, SALES_EXECUTIVE_ID: Array<any>, START_DATE: any, END_DATE: any): Observable<any[]> {

  //   var data = {

  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     BRANCH_NAME: BRANCH_NAME,

  //     SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,

  //     START_DATE: START_DATE,

  //     END_DATE: END_DATE,

  //   };




  //   return this.httpClient.post<any[]>

  //     (this.url + "salesManagerWiseSaleExecutiveWiseSummaryReport/getSalesManagerWiseSalesExecutiveWiseSummaryReport",

  //       JSON.stringify(data), this.options);

  // }


  // getInsuranceSalesManagerWiseInhouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

  //   BRANCH_ID: any, SALES_MANAGER_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {

  //   var data = {

  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     BRANCH_ID: BRANCH_ID,

  //     SALES_MANAGER_ID: SALES_MANAGER_ID,

  //     START_DATE: START_DATE,

  //     END_DATE: END_DATE,

  //   };




  //   return this.httpClient.post<any[]>

  //     (this.url + "insuranceSalesManagerWiseInhouseReport/getInsuranceSalesManagerWiseInhouseReport",

  //       JSON.stringify(data), this.options);

  // }


  // getInsuranceSalesManagerWiseOuthouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

  //   BRANCH_ID: any, SALES_MANAGER_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {

  //   var data1 = {

  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     BRANCH_ID: BRANCH_ID,

  //     SALES_MANAGER_ID: SALES_MANAGER_ID,

  //     START_DATE: START_DATE,

  //     END_DATE: END_DATE,

  //   };

  //   return this.httpClient.post<any[]>(this.url + "insuranceSalesManagerWiseOuthouseReport/getInsuranceSalesManagerWiseOuthouseReport", JSON.stringify(data1), this.options

  //   );

  // }

  // VINDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "vindetails/getVinDetailsReport", JSON.stringify(data1), this.options
  //   );
  // }

  // hpromiseDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
  //   var data1 = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //   };
  //   return this.httpClient.post<any[]>(this.url + "HPromieseDetailsReport/getHPromieseDetailsReport", JSON.stringify(data1), this.options
  //   );
  // }


  getQuotation1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any[]>(this.url + "cancelationFlow/get/", JSON.stringify(data), this.options);
  }



  // approveQuotation(quotation: quotation): Observable<any> {



  //   return this.httpClient.post<any>(this.url + 'cancelationMaster/UpdateData', JSON.stringify(quotation), this.options)

  // }

  BranchWiseSalesSummry(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "salesSummary/getBranchWiseSalesSummary", JSON.stringify(data1), this.options);
  }



  ModelWiseSalesSummry(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "salesSummary/getModelWiseSalesSummary", JSON.stringify(data1), this.options);
  }

  TransmissionTypeWiseSalesSummry(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "salesSummary/getTransmissionTypeWiseSalesSummary", JSON.stringify(data1), this.options);
  }

  FuelTypeWiseSalesSummry(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "salesSummary/getFuelTypeWiseSalesSummary", JSON.stringify(data1), this.options);
  }

  TalukaWiseSalesSummry(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "salesSummary/getTalukaWiseSalesSummary", JSON.stringify(data1), this.options);
  }

  DistrictWiseSalesSummry(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any[]>(this.url + "salesSummary/getDistrictWiseSalesSummary", JSON.stringify(data1), this.options);
  }

  getBranchwiseinsurancesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any> {
    var data = { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, BRANCH_ID: BRANCH_ID, START_DATE: START_DATE, END_DATE: END_DATE };

    return this.httpClient.post<any>(
      this.url + "branchWiseInsuranceSummary/get",
      JSON.stringify(data),
      this.options
    );
  }

  getAlliedservices(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any> {
    var data = { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, BRANCH_ID: BRANCH_ID, START_DATE: START_DATE, END_DATE: END_DATE };

    return this.httpClient.post<any>(
      this.url + "alliedServices/get",
      JSON.stringify(data),
      this.options
    );
  }

  salesExeWiseModelQuotationReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseModelWiseQuotationsGeneratedReport/get", JSON.stringify(data1), this.options
    );
  }

  getSalesExewise(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    FROM_DATE: any,
    TO_DATE: any,
    BRANCH_ID: any,
    SALES_EXECUTIVE_ID: any

  ): Observable<LeaveType[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      BRANCH_ID: BRANCH_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID
    };

    return this.httpClient.post<LeaveType[]>(
      this.url + "salesExecutiveWiseModelWiseMemosGeneratedReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  // serviceReminderSMR/getLeadsCount


  getAllcount(CCE_ID: any, filter: any, FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      CCE_ID: CCE_ID,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE

    }

    return this.httpClient.post<any>(
      this.url + "serviceReminderSMR/getLeadsCount",
      JSON.stringify(data),
      this.options
    );
  }

  getAllSubcount(CATEGORY_STATUS: any, CCE_ID: any, filter: any, FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      CATEGORY_STATUS: CATEGORY_STATUS,
      CCE_ID: CCE_ID,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    }

    return this.httpClient.post<any>(
      this.url + "serviceReminderSMR/getSubCategoreyCount",
      JSON.stringify(data),
      this.options
    );
  }
  getAllSubc(pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: string,

    event: any): Observable<any> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      CCE_ID: event



    };



    return this.httpClient.post<any>(

      this.url + "serviceReminderSMR/getCCEwiseCount",

      JSON.stringify(data),

      this.options

    );

  }



  getAllSubcount1(pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,

    };

    return this.httpClient.post<any>(
      this.url + "serviceReminderSMR/getAdminDashBoardCount",
      JSON.stringify(data),
      this.options
    );
  }

  getLostcount(CCE_ID: any, filter: string): Observable<any> {

    var data = {

      CCE_ID: CCE_ID,

      filter: filter

    }




    return this.httpClient.post<any>(

      this.url + "serviceReminderSMR/getLostCategoreyCount",

      JSON.stringify(data),

      this.options

    );

  }

  getOwnCalling(CCE_ID: any, filter: string): Observable<any> {

    var data = {

      CCE_ID: CCE_ID,

      filter: filter

    }




    return this.httpClient.post<any>(

      this.url + "serviceReminderSMR/getOwnCallingCategoreyCount",

      JSON.stringify(data),

      this.options

    );

  }

  getOwnCalling1(CCE_ID: any, filter: string): Observable<any> {

    var data = {

      CCE_ID: CCE_ID,

      filter: filter

    }




    return this.httpClient.post<any>(

      this.url + "serviceReminderSMR/getOwnCallingCategoreyCountforCCM",

      JSON.stringify(data),

      this.options

    );

  }

  // /serviceReminderSMR/getOwnCallingCategoreyCountforCCM

  // /serviceReminderSMR/getLostCategoreyCount



  createregiDetails(REG_REMARK, REG_DATE, REG_NO, MEMO_ID, QUOTATION_ID): Observable<[]> {
    var data = {
      REG_REMARK: REG_REMARK,
      REG_DATE: REG_DATE,
      REG_NO: REG_NO,
      MEMO_ID: MEMO_ID,
      QUOTATION_ID: QUOTATION_ID,
      CLIENT_ID: this.clientId

    };

    return this.httpClient.post<[]>(
      this.url + "afterInvoiceGeneration/create",
      JSON.stringify(data),
      this.options
    );
  }
  updateregiDetails(REG_REMARK, REG_DATE, REG_NO, MEMO_ID, QUOTATION_ID): Observable<[]> {
    var data = {
      REG_REMARK: REG_REMARK,
      REG_DATE: REG_DATE,
      REG_NO: REG_NO,

      MEMO_ID: MEMO_ID,
      QUOTATION_ID: QUOTATION_ID,
      ID: MEMO_ID,


    };

    return this.httpClient.put<[]>(
      this.url + "afterInvoiceGeneration/update",
      JSON.stringify(data),
      this.options
    );
  }
  createCRDetails(CR_DATE, CR_REMARK, MEMO_ID, QUOTATION_ID): Observable<[]> {
    var data = {
      CR_DATE: CR_DATE,
      CR_REMARK: CR_REMARK,
      MEMO_ID: MEMO_ID,
      QUOTATION_ID: QUOTATION_ID,
      CLIENT_ID: this.clientId

    };

    return this.httpClient.post<[]>(
      this.url + "afterInvoiceGeneration/create",
      JSON.stringify(data),
      this.options
    );
  }
  updatecrDetails(CR_DATE, CR_REMARK, MEMO_ID, QUOTATION_ID): Observable<[]> {
    var data = {
      CR_DATE: CR_DATE,
      CR_REMARK: CR_REMARK,

      ID: MEMO_ID,
      QUOTATION_ID: QUOTATION_ID,
      MEMO_ID: MEMO_ID


    };

    return this.httpClient.put<[]>(
      this.url + "afterInvoiceGeneration/update",
      JSON.stringify(data),
      this.options
    );
  }

  createInsuranceDetails(INSURANCE_DATE, INSURANCE_REMARK, MEMO_ID, QUOTATION_ID): Observable<[]> {
    var data = {
      INSURANCE_DATE: INSURANCE_DATE,
      INSURANCE_REMARK: INSURANCE_REMARK,
      MEMO_ID: MEMO_ID,
      QUOTATION_ID: QUOTATION_ID,
      CLIENT_ID: this.clientId

    };

    return this.httpClient.post<[]>(
      this.url + "afterInvoiceGeneration/create",
      JSON.stringify(data),
      this.options
    );
  }
  updateInsuranceDetails(INSURANCE_DATE, INSURANCE_REMARK, MEMO_ID, QUOTATION_ID): Observable<[]> {
    var data = {
      INSURANCE_DATE: INSURANCE_DATE,
      INSURANCE_REMARK: INSURANCE_REMARK,

      ID: MEMO_ID,
      QUOTATION_ID: QUOTATION_ID,
      MEMO_ID: MEMO_ID


    };

    return this.httpClient.put<[]>(
      this.url + "afterInvoiceGeneration/update",
      JSON.stringify(data),
      this.options
    );
  }


  // getMontlyModelWiseInvoiceGenerated(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any,
  //   START_DATE: any,
  //   END_DATE: any
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MONTH: MONTH,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "monthlyModelWiseInvoiceGeneratedReport/getMonthlyModelWiseInvoiceGeneratedReport", JSON.stringify(data), this.options);
  // }

  // getMontlyBranchWiseModelWiseInvoiceGenerated(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any
  //   , START_DATE: any,
  //   END_DATE: any
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     MONTH: MONTH,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     CLUSTER_ID: this.ClusterId,
  //   };
  //   return this.httpClient.post<any>(this.url + "monthlyBranchWiseModelWiseInvoiceGeneratedReport/getMonthlyBranchWiseModelWiseInvoiceGeneratedReport", JSON.stringify(data), this.options);
  // }

  getappointmentdata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,

    DATE: any, CCE_ID: any): Observable<any[]> {

    var data1 = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      DATE: DATE,

      CCE_ID: CCE_ID,

    };

    return this.httpClient.post<any[]>(this.url + "SMRleadactivitydetailes/getTodaysAppointmentforWeb", JSON.stringify(data1), this.options

    );

  }

  approveQuotation(quotation: quotation): Observable<any> {
    quotation.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + 'cancelationMaster/UpdateData', JSON.stringify(quotation), this.options)
  }

  CallingSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, DATE: any, EXECUTIVE_ID: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      DATE: DATE,
      EXECUTIVE_ID: EXECUTIVE_ID
    };
    return this.httpClient.post<any[]>(this.url + "cceWiseCallingSummaryReport/get", JSON.stringify(data1), this.options
    );
  }


  getKPISheetReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    DATE: any, CCE_ID: number): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      DATE: DATE,
      CCE_ID: CCE_ID
    };
    return this.httpClient.post<any>(this.url + "KpiSheetSummaryReport/get", JSON.stringify(data), this.options);
  }

  getKPIReportCount(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    DATE: any, CCE_ID: number): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      DATE: DATE,
      CCE_ID: CCE_ID
    };
    return this.httpClient.post<any>(this.url + "KpiSheetReportCounts/get", JSON.stringify(data), this.options);
  }


  getdailyTargetReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

    DATE: any): Observable<any> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      DATE: DATE,

    };

    return this.httpClient.post<any>(this.url + "dailyCCEwiseTargetSummery/get", JSON.stringify(data), this.options);

  }

  getMemoWebget(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

    };




    return this.httpClient.post<[]>(this.url + "memo/webGet", JSON.stringify(data), this.options);

  }

  categoreyWiseCallingReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

    FROM_DATE: any, TO_DATE: any, CCE_ID: any) {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      FROM_DATE: FROM_DATE,

      TO_DATE: TO_DATE,

      CCE_ID: CCE_ID,

    };




    return this.httpClient.post<any[]>(this.baseUrl + "api/categoreyWiseCallingSummary/get", JSON.stringify(data), this.options);

  }

  getAppointmentSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    DATE: any, CCE_ID: any) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      DATE: DATE,
      CCE_ID: CCE_ID,
    };

    return this.httpClient.post<any[]>(this.baseUrl + "api/CceWiseAppointmentSummery/get", JSON.stringify(data), this.options);
  }


  // getRoleDetails1(roleId: number, PARENT_ID: string,) {
  //   var data = {
  //     ROLE_ID: roleId,
  //     filter: PARENT_ID,
  //   };
  //   return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  // }
  getRoleDetails2(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }

  getRoleDetails3(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }

  getRoleDetails4(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails5(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails6(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails7(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails8(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails9(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails10(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails11(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails12(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }
  getRoleDetails13(roleId: number, PARENT_ID: string,) {
    var data = {
      ROLE_ID: roleId,
      filter: PARENT_ID,
    };
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }

  createnewCustomer(customer: NewCustomerMaster): Observable<number> {

    customer.CLIENT_ID = this.clientId;

    // customer.ORGANIZATION_ID = this.orgId;




    return this.httpClient.post<number>(

      this.url + "serviceReminderSMR/createNewCustomer",

      JSON.stringify(customer),

      this.options

    );

  }




  updatenewCustomer(customer: NewCustomerMaster): Observable<number> {

    customer.CLIENT_ID = this.clientId;

    // customer.ORGANIZATION_ID = this.orgId;




    return this.httpClient.put<number>(

      this.url + "serviceReminderSMR/update",

      JSON.stringify(customer),

      this.options

    );

  }

  getAllnewCustomers(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: string

  ): Observable<NewCustomerMaster[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

    };




    return this.httpClient.post<NewCustomerMaster[]>(

      this.url + "serviceReminderSMR/getLeadsAddedByCCE",

      JSON.stringify(data),

      this.options

    );

  }

  addTour(tour: TourMaster): Observable<number> {
    tour.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "tour/create",
      JSON.stringify(tour),
      this.options
    );
  }


  updateTour(tour: TourMaster): Observable<number> {
    tour.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "tour/update", JSON.stringify(tour), this.options);
  }

  getAllTour(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<[]>(
      this.url + "tour/get",
      JSON.stringify(data),
      this.options
    );
  }

  getTourDestination(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "tourDestination/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTourDestination(tourDestinationMaster: any): Observable<any> {
    tourDestinationMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "tourDestination/create/",
      JSON.stringify(tourDestinationMaster),
      this.options
    );
  }

  updateTourDestination(tourDestinationMaster: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "tourDestination/update/",
      JSON.stringify(tourDestinationMaster),
      this.options
    );
  }

  getTourMode(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "tourMode/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTourMode(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "tourMode/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updateTourMode(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "tourMode/update/",
      JSON.stringify(role),
      this.options
    );
  }

  getTourClass(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "tourClass/get",
      JSON.stringify(data),
      this.options
    );
  }

  createTourClass(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(
      this.url + "tourClass/create/",
      JSON.stringify(role),
      this.options
    );
  }

  updateTourClass(role: any): Observable<any> {
    return this.httpClient.put<any>(
      this.url + "tourClass/update/",
      JSON.stringify(role),
      this.options
    );
  }



  getAllamounts(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: string

  ): Observable<ModelMaster[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

    };




    return this.httpClient.post<ModelMaster[]>(

      this.url + "advanceMapping/getCityDesignation",

      JSON.stringify(data),

      this.options

    );

  }



  getAllcitydesignation(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: string

  ): Observable<Role[]> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

    };




    return this.httpClient.post<any[]>(

      this.url + "advanceMapping/get",

      JSON.stringify(data),

      this.options

    );

  }

  addcitydesignationDetails(data1: string[], REMARK: any): Observable<number> {

    var data = {

      data: data1,

      CLIENT_ID: 1,

      REMARK: REMARK

    };




    return this.httpClient.post<number>(

      this.url + "advanceMapping/addBulk",

      data,

      this.options

    );

  }

  getTourExpense(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "tourExpence/get",
      JSON.stringify(data),
      this.options
    );
  }

  updateExpense(data: any): Observable<any> {
    data.ROLE_ID = this.roleId;

    return this.httpClient.put<tourExpenseMaster>(this.url + "tourExpence/update", JSON.stringify(data),
      this.options
    );

  }

  createExpense(data: any): Observable<any> {
    data.ROLE_ID = this.roleId;

    return this.httpClient.put<tourExpenseMaster>(this.url + "tourExpence/create", JSON.stringify(data),
      this.options
    );

  }



  //testtt

  supportStaffAreaWiseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string, BRANCH_ID: any, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      MONTH: MONTH,
      YEAR: YEAR.toString(),
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/supportStaffAreaWiseSummary", JSON.stringify(data1), this.options);
  }

  getSalesExeModelReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      CLUSTER_ID: this.ClusterId,
    }; return this.httpClient.post<any>(this.url + 'reports/salesIncentive/salesExecutiveWiseModelWiseDetailedDirectSales', JSON.stringify(data), this.options)
  }

  getInsuranceExeModelReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
      FROM_DATE: FROM_DATE, TO_DATE: TO_DATE,
      CLUSTER_ID: this.ClusterId,
    }; return this.httpClient.post<any>(this.url + 'reports/salesIncentive/salesExecutiveWiseModelWiseDetailedInsurance', JSON.stringify(data), this.options)
  }


  salesExecutiveWiseDetailedEw(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/salesExecutiveWiseDetailedEw", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseDetailedSot(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    BRANCH_ID: any, SALES_CONSULTANT_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_CONSULTANT_ID: SALES_CONSULTANT_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/salesExecutiveWiseDetailedSot", JSON.stringify(data1), this.options
    );
  }

  branchSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    BRANCH_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID.toString(),
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/branchwiseSummaryReport", JSON.stringify(data1), this.options
    );
  }

  ewSalesSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
    BRANCH_ID: any, SALES_CONSULTANT_ID: any, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_CONSULTANT_ID: SALES_CONSULTANT_ID.toString(),
      MONTH: MONTH,
      YEAR: YEAR,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/ewSalesSummaryReport", JSON.stringify(data1), this.options
    );
  }

  sotSalesSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    BRANCH_ID: any, SALES_CONSULTANT_ID: any, MONTH: any, YEAR: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_CONSULTANT_ID: SALES_CONSULTANT_ID.toString(),
      MONTH: MONTH,
      YEAR: YEAR,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesIncentive/sotSalesSummaryReport", JSON.stringify(data1), this.options
    );
  }

  getCustomerwiseMemoDetailed(

    pageIndex: number,

    pageSize: number,

    sortKey: string,

    sortValue: string,

    filter: any,

    SALES_EXECUTIVE_ID: any

  ) {

    this.httpHeaders = new HttpHeaders({

      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",

      applicationkey: "AfIpESwBr5eHp7w3",

      supportkey: this.cookie.get("supportKey"),

      "Content-Type": "application/json",

      // Token: this.cookie.get("token"),

    }); this.options1 = {

      headers: this.httpHeaders,

    };

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,

      CLUSTER_ID: this.ClusterId,
    };

    return this.httpClient.post<any[]>(

      this.baseUrl + "api/reports/customerWiseMemoDetailed/getCustomerWiseMemoDetailed", JSON.stringify(data), this.options);
  }

  getQuotationDetailedReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: any,
    DATE: any,
    SALES_EXECUTIVE_ID: any,
    MODEL_ID: any,
    MEMO_DATE: any,
    INVOICE_DATE: any,) {
    this.httpHeaders = new HttpHeaders({
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportkey: this.cookie.get("supportKey"),
      "Content-Type": "application/json",
      // Token: this.cookie.get("token"),
    });
    this.options1 = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      DATE: DATE,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      MODEL_ID: MODEL_ID,
      MEMO_DATE: MEMO_DATE,
      INVOICE_DATE: INVOICE_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.baseUrl + "api/reports/quotationDetailed/getQuotationDetailed", JSON.stringify(data), this.options);
  }

  getMemoDetailed(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: any,
    MEMO_DATE: any,
    SALES_EXECUTIVE_ID: any,
    MODEL_ID: any,
    INVOICE_DATE: any
  ) {
    this.httpHeaders = new HttpHeaders({
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      supportkey: this.cookie.get("supportKey"),
      "Content-Type": "application/json",
      // Token: this.cookie.get("token"),
    });
    this.options1 = {
      headers: this.httpHeaders,
    };

    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MEMO_DATE: MEMO_DATE,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      MODEL_ID: MODEL_ID,
      INVOICE_DATE: INVOICE_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.baseUrl + "api/reports/memoDetailed/getMemoDetailedReport ", JSON.stringify(data), this.options);
  }

  getMemodiscountDetailed(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: any,
    MEMO_DATE: any,
    SALES_EXECUTIVE_ID: any,
    MODEL_ID: any,
    APPROVER_ID: any
  ) {
    this.httpHeaders = new HttpHeaders
      ({
        apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
        applicationkey: "AfIpESwBr5eHp7w3",
        supportkey: this.cookie.get("supportKey"),
        "Content-Type": "application/json",
        // Token: this.cookie.get("token"), 
      });
    this.options1 = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MEMO_DATE: MEMO_DATE,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      MODEL_ID: MODEL_ID,
      APPROVER_ID: APPROVER_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.baseUrl + "api/reports/memoWiseDiscountDetailed/getMemoWiseDiscountDetailed", JSON.stringify(data), this.options);
  }

  getSalesExecutiveQuotationReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
    END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "salesExecutiveWiseDetailedReport/salesExecutiveWiseDetailedReport", JSON.stringify(data), this.options);
  }

  getSalesExecutiveDiscountReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
    END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
      CLUSTER_ID: this.ClusterId
    };
    return this.httpClient.post<any>(this.url + "salesExecutiveWiseDetailedDiscountReport/salesExecutiveWiseDetailedDiscountReport", JSON.stringify(data), this.options);
  }

  getSalesExecutiveMemoReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
    END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
      CLUSTER_ID: this.ClusterId

    };
    return this.httpClient.post<any>(this.url + "reports/salesExecutiveWiseDetailedMemo/getSalesExecutiveWiseDetailedMemo	", JSON.stringify(data), this.options);
  }

  getSalesExecutiveInvoiceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, INVOICE_START_DATE: any,
    INVOICE_END_DATE: any, BRANCH_ID: string, SALES_EXECUTIVE_ID: string,): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      INVOICE_START_DATE: INVOICE_START_DATE,
      INVOICE_END_DATE: INVOICE_END_DATE,
      BRANCH_ID: BRANCH_ID.toString(),
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "salesExecutiveWiseInvoiceReport/getsalesExecutiveWiseInvoiceReport", JSON.stringify(data), this.options);
  }

  getSalesExecutiveWiseDetailedReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    // fuelFilter: string,
    // transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      // FUEL_TYPE: fuelFilter,
      // TRANSMISSION: transmissionFilter,
      CLUSTER_ID: this.ClusterId,
    };

    return this.httpClient.post<[]>(
      this.url +
      "salesExecutivewiseSummary/getSalesExecutivewiseSalesDetailedReport",
      JSON.stringify(data),
      this.options
    );
  }

  getModelWiseSummary(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    // salesExecutiveID: string,
    // fuelFilter: string,
    // transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      CLUSTER_ID: this.ClusterId,

      // SALES_EXECUTIVE_ID: salesExecutiveID,
      // FUEL_TYPE: fuelFilter,
      // TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url + "modelWiseSalesSummary/getModelWiseSalesSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getSalesExecutiveWiseModelWiseSummaryReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    // fuelFilter: string,
    // transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      // FUEL_TYPE: fuelFilter,
      // TRANSMISSION: transmissionFilter,
      CLUSTER_ID: this.ClusterId,
    };

    return this.httpClient.post<[]>(
      this.url +
      "salesExecutivewiseSummary/getSalesExecutiveWiseModelWiseSummary",
      JSON.stringify(data),
      this.options
    );
  }

  getModelwiseDailyDetailedReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    orgID: number,
    branchID: string,
    fromDate: string,
    toDate: string,
    modelID: string,
    varientID: string,
    salesExecutiveID: string,
    // fuelFilter: string,
    // transmissionFilter: string
  ): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      START_DATE: fromDate,
      END_DATE: toDate,
      MODEL_ID: modelID,
      VARIENT_ID: varientID,
      SALES_EXECUTIVE_ID: salesExecutiveID,
      CLUSTER_ID: this.ClusterId,

      // FUEL_TYPE: fuelFilter,
      // TRANSMISSION: transmissionFilter,
    };

    return this.httpClient.post<[]>(
      this.url +
      "modelWiseSalesSummary/getModelwiseDailyDetailedReport",
      JSON.stringify(data),
      this.options
    );
  }

  bankwisememoreport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    START_DATE: string,
    END_DATE: string,
    BANK_NAME: any,
    BRANCH_ID: any,
  ): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      BANK_NAME: BANK_NAME,
      BRANCH_ID: BRANCH_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<ThoughMaster[]>(
      this.url + "reports/bankWiseMemoDetailed/getBanckWiseMemoDetailed",
      JSON.stringify(data),
      this.options
    );
  }


  SalesHeadwisedetaileddiscount(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    START_DATE: string,
    END_DATE: string,
    REPORTING_HEAD_ID: any,
    BRANCH_ID: any,
    // CUSTOMER_NAME: any,
    // MODEL_ID:any ,
    // BRANCH_ID:any ,
    // DATE:any ,
  ): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      REPORTING_HEAD_ID: REPORTING_HEAD_ID,
      BRANCH_ID: BRANCH_ID,
      CLUSTER_ID: this.ClusterId,

      // CUSTOMER_NAME: CUSTOMER_NAME,
      // MODEL_ID: MODEL_ID,
      // BRANCH_ID: BRANCH_ID,
      // DATE: DATE,
    };

    return this.httpClient.post<ThoughMaster[]>(
      this.url + "salesHeadWiseDiscountReport/getSalesHeadWiseDiscountReport",
      JSON.stringify(data),
      this.options
    );
  }

  Salesexecutiveinhouseinsurancesummary(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    START_DATE: any,
    END_DATE: any,
    SALES_EXECUTIVE_ID: any,
  ): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      // SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      // BRANCH_ID: BRANCH_ID,
      // START_DATE: START_DATE,
      // END_DATE: END_DATE,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<ThoughMaster[]>(
      this.url + "salesExecutiveWiseInsuranceSummary/getSalesExecutiveWiseInsuranceSummary",
      JSON.stringify(data),
      this.options
    );
  }

  SalesHeadwisedetaileddiscountsummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, REPORTING_HEAD_ID: any, BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, REPORTING_HEAD_ID: REPORTING_HEAD_ID, BRANCH_ID: BRANCH_ID, START_DATE: START_DATE, END_DATE: END_DATE, CLUSTER_ID: this.ClusterId,

      // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
    };
    return this.httpClient.post<ThoughMaster[]>(this.url + "salesHeadwiseDiscountSummaryReport/getsalesHeadwiseDiscountReport",
      JSON.stringify(data),
      this.options
    );
  }

  branchwisenhousefinancedetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: string, END_DATE: string, BRANCH_ID: any, VARIENT_ID: string, MODEL_ID: any, BANK_ID: any): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, START_DATE: START_DATE, END_DATE: END_DATE, BRANCH_ID: BRANCH_ID, VARIENT_ID: VARIENT_ID, MODEL_ID: MODEL_ID, BANK_ID: BANK_ID, CLUSTER_ID: this.ClusterId,

      // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
    };
    return this.httpClient.post<ThoughMaster[]>(this.url + "branchwiseInhousefinanceDetails/getbranchwiseInhousefinanceDetails",
      JSON.stringify(data),
      this.options
    );
  }

  Customerwisefinancereport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, BRANCH_ID: BRANCH_ID, SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID, MODEL_ID: MODEL_ID, START_DATE: START_DATE,
      END_DATE: END_DATE, CLUSTER_ID: this.ClusterId,

      // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
    };
    return this.httpClient.post<ThoughMaster[]>(this.url + "customerWiseFinanceReport/getCustomerWiseFinanceReport",
      JSON.stringify(data),
      this.options
    );
  }

  Modelwiseinvoicesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, MODEL_ID: MODEL_ID, START_DATE: START_DATE,
      END_DATE: END_DATE, CLUSTER_ID: this.ClusterId,

      // MODEL_ID: MODEL_ID,   // BRANCH_ID: BRANCH_ID,   // DATE: DATE,
    };
    return this.httpClient.post<ThoughMaster[]>(this.url + "modelWiseInvoiceSummaryReport/getModelWiseInvoiceSummaryReport",
      JSON.stringify(data),
      this.options
    );
  }

  branchwiseadvancecollectedreports(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    BRANCH_ID: any,
    START_DATE: any,
    END_DATE: any
  ): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<ThoughMaster[]>(
      this.url + "branchwiseAdvanceCollected/getbranchwiseAdvanceCollected",
      JSON.stringify(data),
      this.options
    );
  }

  customerwiseinovice(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    BRANCH_ID: any,
    SALES_EXECUTIVE_ID: any,
    CUSTOMER_ID: any,
    MODEL_ID: any,
    START_DATE: any,
    END_DATE: any,
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      CUSTOMER_ID: CUSTOMER_ID,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(
      this.url + "customerWiseInvoiceReport/getCustomerWiseInvoiceReport",
      JSON.stringify(data),
      this.options
    );
  }


  customerwisedetaildiscountreport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    START_DATE: any,
    END_DATE: any,
    MODEL_ID: any,
    SALES_EXECUTIVE_ID: any,
    VARIENT_ID: any,
    BRANCH_ID: any,
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      MODEL_ID: MODEL_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      VARIENT_ID: VARIENT_ID,
      BRANCH_ID: BRANCH_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(
      this.url + "reports/customerWiseDiscountDetailedReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  customerwiseinsurancereport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    START_DATE: any,
    END_DATE: any,
    SALES_EXECUTIVE_ID: any,
    MODEL_ID: any,
    BRANCH_ID: any,
    INSURANCE_TYPE: any,
    INSURANCE_NAME: any,
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      MODEL_ID: MODEL_ID,
      BRANCH_ID: BRANCH_ID,
      INSURANCE_TYPE: INSURANCE_TYPE,
      INSURANCE_NAME: INSURANCE_NAME,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(
      this.url + "customerwiseInsuranceReport/getcustomerwiseInsuranceReport",
      JSON.stringify(data),
      this.options
    );
  }

  Customerwisedetcailedreport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    MODEL_ID: any,
    VARIENT_ID: any,
    FUEL_TYPE: any,
    TRANSMISSION_TYPE: any,
    SALES_EXECUTIVE_ID: any,
    GENEREL_MANGER_ID: any,
    SALES_HEAD_ID: any,
    ASSISTANT_SALES_MANAGER_ID: any,
    QUOTATION_DATE: any,
    MEMO_DATE: any,
    INVOICE_DATE: any,
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      VARIENT_ID: VARIENT_ID,
      FUEL_TYPE: FUEL_TYPE,
      TRANSMISSION_TYPE: TRANSMISSION_TYPE,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      GENEREL_MANGER_ID: GENEREL_MANGER_ID,
      SALES_HEAD_ID: SALES_HEAD_ID,
      ASSISTANT_SALES_MANAGER_ID: ASSISTANT_SALES_MANAGER_ID,
      QUOTATION_DATE: QUOTATION_DATE,
      MEMO_DATE: MEMO_DATE,
      INVOICE_DATE: INVOICE_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(
      this.url + "reports/customerWiseDetailedReport/get",
      JSON.stringify(data),
      this.options
    );
  }

  salesManagerwiseInsuranceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "salesManagerwiseInsuranceSummary/getsalesManagerwiseInsuranceSummary", JSON.stringify(data1), this.options
    );
  }

  salesManagerwiseFinanceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "salesManagerwiseFinanceSummary/getsalesManagerwiseFinanceSummary", JSON.stringify(data1), this.options
    );
  }

  branchwisesalesManagerwiseInhouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_MANAGER_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_MANAGER_ID: SALES_MANAGER_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "branchwisesalesManagerwiseInhouseFinanceSummary/getbranchwisesalesManagerwiseInhouseFinanceSummary", JSON.stringify(data1), this.options
    );
  }

  salesManagerWiseSalesExecutiveWise(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, MODEL_ID: any, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      MODEL_ID: MODEL_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "salesManagerWiseSalesExecutiveWiseModelWiseSummary/getsalesManagerWiseSalesExecutiveWiseModelWiseSummary", JSON.stringify(data1), this.options
    );
  }

  discountDetailedReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "discountDetailedReports/getdiscountDetailedReports", JSON.stringify(data1), this.options
    );
  }

  discountSummaryReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "discountDetailedReports/getdiscountDetailedReportSummary", JSON.stringify(data1), this.options
    );
  }

  salesExeWiseAdvanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID.toString(),
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExeWiseAdvanceReport/getsalesExeWiseAdvanceReport", JSON.stringify(data1), this.options
    );
  }

  BranchsalesExeWiseAdvanceDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "reports/branchWiseSalesExecutiveWiseAdvanceDetailed/get", JSON.stringify(data1), this.options
    );
  }

  branchWiseFinance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "reports/branchWiseFinanceSummary/get", JSON.stringify(data1), this.options
    );
  }

  modelWiseRept(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "modelWiseSummaryReports/getModelWiseSummaryReports", JSON.stringify(data1), this.options
    );
  }

  inhouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "branchwiseInhouseInsuranceSummary/getbranchwiseInhouseInsuranceSummary", JSON.stringify(data1), this.options
    );
  }
  outhouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "branchwiseOuthouseInsuranceSummary/getbranchwiseOuthouseInsuranceSummary", JSON.stringify(data1), this.options
    );
  }

  branchWiseAdvance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "branchWiseAdvanceReport/getbranchWiseAdvanceReport", JSON.stringify(data1), this.options
    );
  }

  branchWiseDiscount(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BRANCH_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "reports/branchWiseDiscountSummary/get", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseInhouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesExecutiveWiseInhouseInsuranceDetailed/get", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseOuthouse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/salesExecutiveWiseOuthouseInsuranceDetailed/get", JSON.stringify(data1), this.options
    );
  }

  inhouseInsuranceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/InhouseInsuranceSummary/get", JSON.stringify(data1), this.options
    );
  }

  outhouseInsuranceSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/OuthouseInsuranceSummary/get", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseFinanceInhouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseFinanceInhouseReport/getSalesExecutiveWiseFinanceInhouseReport", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseFinanceOuthouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseFinanceOuthouseReport/getSalesExecutiveWiseFinanceOuthouseReport", JSON.stringify(data1), this.options
    );
  }

  salesExecutivewiseBankwiseInhouseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    BANK_ID: any, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BANK_ID: BANK_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutivewiseBankwiseInhouseSummary/getsalesExecutivewiseBankwiseInhouseSummary", JSON.stringify(data1), this.options
    );
  }

  salesExecutivewiseInhouseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutivewiseInhouseSummary/getsalesExecutivewiseInhouseSummary", JSON.stringify(data1), this.options
    );
  }

  salesExecutivewiseOuthouseSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutivewiseOuthouseSummary/getsalesExecutivewiseOuthouseSummary", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseBankWiseOuthouseReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseBankWiseOuthouseReports/getSalesExecutiveWiseBankWiseOuthouseReport", JSON.stringify(data1), this.options
    );
  }

  salesExecutiveWiseRsaDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "salesExecutiveWiseRsaDetailedReport/getSalesExecutiveWiseRsaDetailedReport", JSON.stringify(data1), this.options
    );
  }


  getmodelwiseprice(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, FUEL_TYPE: any, TRANSMISSION: any, PRICELIST_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      FUEL_TYPE: FUEL_TYPE,
      TRANSMISSION: TRANSMISSION,
      PRICELIST_ID: PRICELIST_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "modelwisePriceReport/getModelwisePriceReport", JSON.stringify(data), this.options);
  }

  getbranchwisemodelwiseprice(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, MODEL_ID: any, BRANCH_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      MODEL_ID: MODEL_ID,
      BRANCH_ID: BRANCH_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "branchwiseModelwiseReport/getbranchwiseModelwiseReport", JSON.stringify(data), this.options);
  }

  getbranchwiseinvoicedetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, MEMO_START_DATE: any, MEMO_END_DATE: any, MODEL_ID: string, BRANCH_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      // DATE: DATE,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      MEMO_START_DATE: MEMO_START_DATE,
      MEMO_END_DATE: MEMO_END_DATE,
      MODEL_ID: MODEL_ID,
      BRANCH_ID: BRANCH_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "branchwiseInvoiceDetails/getbranchwiseInvoiceDetails", JSON.stringify(data), this.options);
  }

  getmodelwisevarientdetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, ID: any, FUEL_TYPE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      ID: ID,
      FUEL_TYPE: FUEL_TYPE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "modelWiseVarientDetailedReport/getModelWiseVarientDetailedReport", JSON.stringify(data), this.options);
  }
  getModelwisequotationsummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, START_DATE: any, END_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "modelWiseQuotationReport/getModelWiseQuotationReport", JSON.stringify(data), this.options);
  }
  getModelWiseQuotationdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: Array<any>, BRANCH_ID: Array<any>, DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      BRANCH_ID: BRANCH_ID,
      DATE: DATE,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "reports/modelWiseQuotationdetails/getModelWiseQuotationdetails", JSON.stringify(data), this.options);
  }

  getsalesManagerExecutivewisereport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, MODEL_ID: any, START_DATE: any, END_DATE: any, SALES_MANAGER_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      VARIENT_ID: VARIENT_ID,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      SALES_MANAGER_ID: SALES_MANAGER_ID,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "salesManagerExeWiseReport/getSalesManagerExeWiseReport", JSON.stringify(data), this.options);
  }
  getCustomerWiseRSAdetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, MODEL_ID: any, FROM_DATE: any, TO_DATE: any, CUSTOMER_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      VARIENT_ID: VARIENT_ID,
      MODEL_ID: MODEL_ID,
      START_DATE: FROM_DATE,
      END_DATE: TO_DATE,
      CUSTOMER_ID: CUSTOMER_ID,
      CLUSTER_ID: this.ClusterId,

      // SALES_MANAGER_ID:SALES_MANAGER_ID,
    };
    return this.httpClient.post<any>(this.url + "customerWiseRsaDetailedReport/getCustomerWiseRsaDetailedReport", JSON.stringify(data), this.options);
  }
  getSalesExWiseRSAsummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, SALES_EXECUTIVE_ID: any, START_DATE: any, END_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "salesExecutiveWiseRsaSummaryReport/getSalesExecutiveWiseRsaSummaryReport", JSON.stringify(data), this.options);
  }
  getinsurancesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, INSURANCE_NAME: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      INSURANCE_NAME: INSURANCE_NAME,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "insuranceComapnyWiseSummaryReport/getInsuranceComapnyWiseSummaryReport", JSON.stringify(data), this.options);
  }
  getfinancebankwisesummaryreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any, YEAR: any, BANK_NAME: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      BANK_NAME: BANK_NAME,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "financeBankWiseSummaryReport/getFinanceBankWiseSummaryReport", JSON.stringify(data), this.options);
  }
  getcustomerwisequotationdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MODEL_ID: any, START_DATE: any, END_DATE: any, BRANCH_ID: any, SALES_EXECUTIVE_ID: any, VARIENT_ID: any, FUEL: any, TRANSMISSION: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MODEL_ID: MODEL_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      BRANCH_ID: BRANCH_ID,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      VARIENT_ID: VARIENT_ID,
      FUEL_TYPE: FUEL,
      TRANSMISSION: TRANSMISSION,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any>(this.url + "customerwiseQuotationDetails/getcustomerwiseQuotationDetails", JSON.stringify(data), this.options);
  }


  getInhouseFinanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
    END_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "inhouseFinanceSummary/get", JSON.stringify(data), this.options);
  }


  getOuthouseFinanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
    END_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "outhouseFinanceSummary/get", JSON.stringify(data), this.options);
  }

  getSalesExecutiveWiseQuotationGeneratedTodayReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any,
    END_DATE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "reports/quotationGeneratedToday/getQuotationGeneratedToday", JSON.stringify(data), this.options);
  }

  getOuthouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "outhouseInsuranceSummary/get",
      JSON.stringify(data), this.options);
  }

  getInhouseInsurance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "inhouseInsuranceSummary/get",
      JSON.stringify(data), this.options);
  }

  getBranchWiswMostDiscountApproval(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "monthlyBranchWiseMostAprrovalDiscount/getBranchWiseMostApprovalDiscount",
      JSON.stringify(data), this.options);

  }

  getBranchWiseMemoPendancy(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "reports/branchWiseMemoPendancy/getBranchWiseMemoPendancy",
      JSON.stringify(data), this.options);
  }

  getBranchWiseDiscountPendancy(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "branchWiseDiscountPendancyReport/getBranchWiseDiscountPendancyReport",
      JSON.stringify(data), this.options);
  }

  getbranchwiseOuthouseFinanceDetails(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    BRANCH_ID: any,
    START_DATE: any,
    END_DATE: any,
    VARIENT_ID: any,
    MODEL_ID: any,
    BANK_ID: Array<any>
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID.toString(), START_DATE: START_DATE, END_DATE: END_DATE, VARIENT_ID: VARIENT_ID.toString(), MODEL_ID: MODEL_ID.toString(), BANK_ID: BANK_ID,
      CLUSTER_ID: this.ClusterId,

    };

    return this.httpClient.post<any>(
      this.url + "branchwiseOuthouseFinanceDetails/getbranchwiseOuthouseFinanceDetails",
      JSON.stringify(data),
      this.options
    );
  }

  getbranchwisequotationdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, MODEL_ID: string, BRANCH_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      MODEL_ID: MODEL_ID,
      BRANCH_ID: BRANCH_ID,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "branchwiseQuotationDetails/getbranchwiseQuotationDetails", JSON.stringify(data), this.options);
  }

  getSalesManagerWise(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

    BRANCH_NAME: Array<any>, SALES_EXECUTIVE_ID: Array<any>, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_NAME: BRANCH_NAME,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>
      (this.url + "salesManagerWiseSaleExecutiveWiseSummaryReport/getSalesManagerWiseSalesExecutiveWiseSummaryReport",
        JSON.stringify(data), this.options);
  }

  getInsuranceSalesManagerWiseInhouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    BRANCH_ID: any, SALES_MANAGER_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_MANAGER_ID: SALES_MANAGER_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>
      (this.url + "insuranceSalesManagerWiseInhouseReport/getInsuranceSalesManagerWiseInhouseReport",
        JSON.stringify(data), this.options);
  }

  getInsuranceSalesManagerWiseOuthouseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,
    BRANCH_ID: any, SALES_MANAGER_ID: any, START_DATE: any, END_DATE: any): Observable<any[]> {
    var data1 = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BRANCH_ID: BRANCH_ID,
      SALES_MANAGER_ID: SALES_MANAGER_ID,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any[]>(this.url + "insuranceSalesManagerWiseOuthouseReport/getInsuranceSalesManagerWiseOuthouseReport", JSON.stringify(data1), this.options
    );

  }

  VINDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "vindetails/getVinDetailsReport", JSON.stringify(data1), this.options
    );
  }

  hpromiseDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      CLUSTER_ID: this.ClusterId,

    };
    return this.httpClient.post<any[]>(this.url + "HPromieseDetailsReport/getHPromieseDetailsReport", JSON.stringify(data1), this.options
    );
  }

  getMontlyModelWiseInvoiceGenerated(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any,
    START_DATE: any,
    END_DATE: any
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "monthlyModelWiseInvoiceGeneratedReport/getMonthlyModelWiseInvoiceGeneratedReport", JSON.stringify(data), this.options);
  }

  getMontlyBranchWiseModelWiseInvoiceGenerated(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MONTH: any
    , START_DATE: any,
    END_DATE: any
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      START_DATE: START_DATE,
      END_DATE: END_DATE,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "monthlyBranchWiseModelWiseInvoiceGeneratedReport/getMonthlyBranchWiseModelWiseInvoiceGeneratedReport", JSON.stringify(data), this.options);
  }

  getretensionSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE: any, TO_DATE: any): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE

    };
    return this.httpClient.post<Ticket[]>(this.url + "cceRetationSummeryReport/get", JSON.stringify(data), this.options);
  }


  getModelProductReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      ROLE_ID: this.roleId,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,


    };
    return this.httpClient.post<any>(this.url + "modelProductReport/getModelProductReport", JSON.stringify(data), this.options);
  }

  getFuelProductReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      ROLE_ID: this.roleId,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,

    };
    return this.httpClient.post<any>(this.url + "fuelProductReport/getFuelProductReport", JSON.stringify(data), this.options);
  }
  getTransmissionProductReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      ROLE_ID: this.roleId,
      SALES_MANAGER_IDS: ROLESS

    };
    return this.httpClient.post<any>(this.url + "transmissionProductReport/getTransmissionProductReport", JSON.stringify(data), this.options);
  }

  getVarientProductReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any,MODELS:any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      ROLE_ID: this.roleId,
      SALES_MANAGER_IDS: ROLESS,
      MODEL_ID:MODELS
    };
    return this.httpClient.post<any>(this.url + "varientProductReport/getVarientProductReport", JSON.stringify(data), this.options);
  }

  // api/cceAppointmentsAndVehicleIn/get

  vehicleappo(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,

    DATE: any,) {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      DATE: DATE,



    };




    return this.httpClient.post<any[]>(this.baseUrl + "api/cceAppointmentsAndVehicleIn/get", JSON.stringify(data), this.options);

  }

  // getEmployeeChild(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any): Observable<any> {

  //   var data = {




  //     pageIndex: pageIndex,

  //     pageSize: pageSize,

  //     sortKey: sortKey,

  //     sortValue: sortValue,

  //     filter: filter,

  //     EMPLOYEE_ID: this.userId




  //   };

  //   return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);

  // }

  getServicesInsuranceReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId

    };
    return this.httpClient.post<any>(this.url + "alliedServicesInsuranceReport/getAlliedServicesInsuranceReport", JSON.stringify(data), this.options);
  }

  getServicesSOTReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId

    };
    return this.httpClient.post<any>(this.url + "alliedServicesSOTReport/getAlliedServicesSOTReport", JSON.stringify(data), this.options);
  }

  getServicesEWReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId

    };
    return this.httpClient.post<any>(this.url + "alliedServicesEWReport/getAlliedServicesEWReport", JSON.stringify(data), this.options);
  }

  getServicesAccesoriesReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId

    };
    return this.httpClient.post<any>(this.url + "alliedServicesAccesoriesReport/getAlliedServicesAccesoriesReport", JSON.stringify(data), this.options);
  }

  getcancelationSummaryReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,

      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId
    };
    return this.httpClient.post<any>(this.url + "cancellationSummaryReport/getcancelationSummaryReport", JSON.stringify(data), this.options);
  }

  getModiticationSummaryReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId
    };
    return this.httpClient.post<any>(this.url + "moditicationSummaryReport/getModiticationSummaryReport", JSON.stringify(data), this.options);
  }

  getExchangeSummaryReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      ROLE_ID: this.roleId,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,


    };
    return this.httpClient.post<any>(this.url + "exchangeSummaryReport/getExchangeSummaryReport", JSON.stringify(data), this.options);
  }

  // getEmployeeChild(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any): Observable<any> {
  //   var data = {

  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     EMPLOYEE_ID: this.userId

  //   };
  //   return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  // }

  getSalesExecutedWiseDiscountReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      ROLE_ID: this.roleId,
      SALES_MANAGER_IDS: ROLESS,


    };
    return this.httpClient.post<any>(this.url + "salesExecutedWiseDiscountReport/getSalesExecutedWiseDiscountReport", JSON.stringify(data), this.options);
  }

  getCancelationDetailedReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      CLUSTER_ID: this.ClusterId,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,

      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId
    };
    return this.httpClient.post<any>(this.url + "cancelationDetailedReport/getCancelationDetailedReport", JSON.stringify(data), this.options);
  }


  getModiticationDetailedReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      CLUSTER_ID: this.ClusterId,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId
    };
    return this.httpClient.post<any>(this.url + "moditicationDetailedReport/getModiticationDetailedReport", JSON.stringify(data), this.options);
  }

  getCorporateSalesReport(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any, ROLESS: any, BRANCH_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      ROLE_ID: this.roleId,
      SALES_MANAGER_IDS: ROLESS,
      BRANCH_ID: BRANCH_ID
    };
    return this.httpClient.post<any>(this.url + "corporateDetailedReport/getCorporateDetailedReport", JSON.stringify(data), this.options);
  }

  getDetailedAlliedServices(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, START_DATE: any,

    END_DATE: any, BRANCH_ID: any, SALES_EXECUTIVE_ID: any,): Observable<any> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      START_DATE: START_DATE,

      END_DATE: END_DATE,

      BRANCH_ID: BRANCH_ID,

      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,

      CLUSTER_ID: this.ClusterId,

    };

    return this.httpClient.post<any>(this.url + "detailedAlliedServices/get", JSON.stringify(data), this.options);

  }


  DetailedCallingReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {

    var data1 = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      CLUSTER_ID: this.ClusterId,




    };

    return this.httpClient.post<any[]>(this.url + "cceDetailedCallingReport/get", JSON.stringify(data1), this.options

    );

  }

  // serviceReminderSMR/getOwnCallingCategoreyCount



  // psf module 


  getPSFMastertabledata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {

    var data = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter

    };

    return this.httpClient.post<any>(this.url + "postservicefollowup/getPsfLeadsData", JSON.stringify(data), this.options);

  }

  cretePSFActivityleadmaster(ActivityLeadMaster1: PSFActivityLeadMaster): Observable<number> {

    ActivityLeadMaster1.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(this.url + "PSFleadactivitydetailes/create", JSON.stringify(ActivityLeadMaster1), this.options);

  }

  updatePSFActivityleadmaster(ActivityLeadMaster1: PSFActivityLeadMaster): Observable<number> {

    ActivityLeadMaster1.CLIENT_ID = this.clientId; return this.httpClient.put<number>(this.url + "leadactivitydetailes/update", JSON.stringify(ActivityLeadMaster1), this.options);

  }

  getAllPSFActivityleadMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {

    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,

    }; return this.httpClient.post<any>(this.url + "PSFleadactivitydetailes/get", JSON.stringify(data), this.options);




  }

  // HMIL 

  // api/hapAndGDMSLost/ImportHapDetailsToExcel



  //attendance adjutment 

  getlogsdata(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, emp: any, mon: any, year: any): Observable<any> {

    var data = {

      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMP_ID: emp,
      MONTH: mon,
      YEAR: year

    };

    return this.httpClient.post<any>(this.url + "attendanceRegister/getEmpDatewiseAttendanceStatus", JSON.stringify(data), this.options);

  }

  updatelogsss(assetMapping: Log): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    console.log(assetMapping);
    return this.httpClient.put<number>(
      this.url + "attendanceRegister/updateEmpAttendanceStatus",
      JSON.stringify(assetMapping),
      this.options
    );

  }


  AttendanceAdjustmentReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {

    var data1 = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,

      CLUSTER_ID: this.ClusterId,

    };

    return this.httpClient.post<any[]>(this.url + "attendanceRegister/getStatusUpdateHistory", JSON.stringify(data1), this.options

    );

  }

  getAllpsfsubcount(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {

    var data1 = {

      pageIndex: pageIndex,

      pageSize: pageSize,

      sortKey: sortKey,

      sortValue: sortValue,

      filter: filter,



    };

    return this.httpClient.post<any>(
      this.url + "postservicefollowup/getPSFSubCategoreyCount",
      JSON.stringify(data1),
      this.options
    );
  }


  getAllEmployees12(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    EMPLOYEE_ID: any
  ): Observable<EmployeeMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: this.userId
    };
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {
      console.log("getemp2");
    } else {
      return this.httpClient.post<any>(this.url + "employee/get", JSON.stringify(data), this.options);
    }
  }

  // getEmployeeChild(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, SALES_MANAGER_IDS: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     EMPLOYEE_ID: SALES_MANAGER_IDS
  //   };
  //   return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  // }
  getEmployeeChild1(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, SALES_MANAGER_IDS: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: this.userId
    };
    return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  }

  // getEmployeeChilds(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     EMPLOYEE_ID: this.userId
  //   };
  //   return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  // }

  getEmployeeChilds(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: this.userId,
      ROLE_ID: this.roleId,
      CLUSTER_ID: this.ClusterId,
    };
    return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  }


  getAllfeedarea(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<feedback[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<feedback[]>(
      this.url + "feedbackAreas/get",
      JSON.stringify(data),
      this.options
    );
  }

  createfeedarea(feedback: feedback): Observable<number> {
    feedback.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "feedbackAreas/create/",
      JSON.stringify(feedback),
      this.options
    );
  }

  updatefeedarea(feedback: feedback): Observable<number> {
    feedback.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "feedbackAreas/update/",
      JSON.stringify(feedback),
      this.options
    );
  }


  getAllfeedform(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<feedbackforms[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<feedbackforms[]>(
      this.url + "employeeFeedbackDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  createfeedform(feedback: feedbackforms): Observable<number> {
    feedback.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(
      this.url + "employeeFeedbackDetails/create",
      JSON.stringify(feedback),
      this.options
    );
  }

  updatefeedform(feedback: feedbackforms): Observable<number> {
    feedback.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(
      this.url + "employeeFeedbackDetails/update",
      JSON.stringify(feedback),
      this.options
    );
  }

  MappsfCCE(
    cceId1: number,
    data1: any,
    CATEGORY_STATUS: any
  ): Observable<number> {
    var data = {
      CCE_ID: cceId1,
      LEAD_IDS: data1,
      CATEGORY_STATUS: CATEGORY_STATUS
    };
    return this.httpClient.post<number>(
      this.url + "postservicefollowup/AssignPSFBulkLeadstoCCE",
      data,
      this.options
    );
  }

  getAllSubcountforpsf(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, event: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      CCE_ID: event
    };
    return this.httpClient.post<any>(this.url + "postservicefollowup/getPSFCCEwiseCount", JSON.stringify(data),
      this.options
    );
  }

  getEvaluationCriteriaMapping(roleId: number) {
    var data = {
      ROLE_ID: roleId
    };
    return this.httpClient.post<EvaluationMapping[]>(this.url + "roleCriteriaMapping/get", JSON.stringify(data), this.options);
  }

  mapEvaluationCriteria(roleId1: number, data1: string[]): Observable<number> {
    var data = {
      roleId: roleId1,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "roleCriteriaMapping/addBulk", data, this.options);
  }

  onUpload12(folderName: any, selectedFile: any, filename: any): Observable<any> {
    this.httpHeaders1 = new HttpHeaders({
      Accept: "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });
    this.options1 = {
      headers: this.httpHeaders1,
    };
    const fd = new FormData();
    fd.append('Image', selectedFile, filename);
    return this.httpClient.post<any>(this.imgUrl1 + folderName, fd, this.options1
    );
  }

  getEmployeeEvaluation(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,

  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,

    };
    return this.httpClient.post<any>(
      this.url + "employeeEvaluationChart/get",
      JSON.stringify(data),
      this.options
    );
  }



  FinanceAllocationDetailReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  ): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,


    };
    return this.httpClient.post<any[]>(this.url + "financeIncentiveAllocationDetails/get", JSON.stringify(data1), this.options
    );
  }

  FinanceAllocationSummaryReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
  ): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,


    };
    return this.httpClient.post<any[]>(this.url + "financeIncentiveAllocationSummary/get", JSON.stringify(data1), this.options
    );
  }



  isResolverReport: any = 1
  // getUserwisefeedbackReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   FROM_DATE: any,
  //   TO_DATE: any,
  //   TYPE: any,
  //   BRANCH_ID: any
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE,
  //     TYPE: TYPE,
  //     BRANCH_ID: BRANCH_ID
  //   };
  //   return this.httpClient.post<any>(
  //     this.url + "feedbackReports/getEmpWiseCounts",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }
  // getresolverwisefeedbackReport(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortKey: string,
  //   sortValue: string,
  //   filter: string,
  //   FROM_DATE: any,
  //   TO_DATE: any,
  //   TYPE: any,
  //   BRANCH_ID: any
  // ): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     FROM_DATE: FROM_DATE,
  //     TO_DATE: TO_DATE,
  //     TYPE: TYPE,
  //     BRANCH_ID: BRANCH_ID,
  //     isResolverReport: this.isResolverReport
  //   };
  //   return this.httpClient.post<any>(
  //     this.url + "feedbackReports/getEmpWiseCounts",
  //     JSON.stringify(data),
  //     this.options
  //   );
  // }
  // getBranchwisefeedbackdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: any, END_DATE: any, TYPE: any): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //     START_DATE: START_DATE,
  //     END_DATE: END_DATE,
  //     TYPE: TYPE
  //   };
  //   return this.httpClient.post<any>(this.url + "feedbackReports/getBranchWiseCounts", JSON.stringify(data), this.options);
  // }
  // getUserwisefeedbackdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  //   var data = {
  //     pageIndex: pageIndex,
  //     pageSize: pageSize,
  //     sortKey: sortKey,
  //     sortValue: sortValue,
  //     filter: filter,
  //   };
  //   return this.httpClient.post<any>(this.url + "employeeFeedbackDetails/get", JSON.stringify(data), this.options);
  // }


  getfeedform(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    EMP_ID: any
  ): Observable<feedbackforms[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMP_ID: EMP_ID
    };
    return this.httpClient.post<feedbackforms[]>(
      this.url + "employeeFeedbackDetails/get",
      JSON.stringify(data),
      this.options
    );
  }
  branchareamappaddbulk(CLIENT_ID, FEED_AREA_ID, BRANCH_ID, ID, STATUS): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });
    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      CLIENT_ID: CLIENT_ID,
      AREAS: FEED_AREA_ID,
      BRANCHES: BRANCH_ID,
      EMP_ID: ID,
      STATUS: STATUS
    };

    return this.httpClient.post<any>(this.baseUrl + 'api/empFeedbackAreasMapping/create', JSON.stringify(data), this.options)
  }


  branchareamappaddbulk1(CLIENT_ID, FEED_AREA_ID, BRANCH_ID, EMP_ID, STATUS, ID): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
      applicationkey: "AfIpESwBr5eHp7w3",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });
    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      CLIENT_ID: CLIENT_ID,
      AREAS: FEED_AREA_ID,
      BRANCHES: BRANCH_ID,
      EMP_ID: EMP_ID,
      STATUS: STATUS,
      ID: ID
    };

    return this.httpClient.put<any>(this.baseUrl + 'api/empFeedbackAreasMapping/update', JSON.stringify(data), this.options)
  }

  branchareamappaget(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<feedbackforms[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<feedbackforms[]>(
      this.url + "empFeedbackAreasMapping/get",
      JSON.stringify(data),
      this.options
    );
  }


  MaphmilCCE(

    cceId1: number,

    data1: any,

    CATEGORY_STATUS: any,

  ): Observable<number> {

    var data = {

      CCE_ID: cceId1,

      LEAD_IDS: data1,

      CATEGORY_STATUS: CATEGORY_STATUS




    };
    return this.httpClient.post<number>(

      this.url + "hapAndGDMSLost/AssignBulkLeadstoCCE",

      data,

      this.options

    );





    // api/postservicefollowupgetPSFSubCategoreyCount

  }

  insertNewLeadsToHMIL(UntouchedData: any, NewData: any, SuccessedRecords: any, CATEGORY_STATUS): Observable<any> {

    this.httpHeaders = new HttpHeaders({

      "Content-Type": "application/json",

      apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",

      applicationkey: "AfIpESwBr5eHp7w3",

      deviceid: this.cookie.get("deviceId"),

      supportkey: this.cookie.get("supportKey"),

      Token: this.cookie.get("token"),

    });




    this.options = {

      headers: this.httpHeaders,

    };




    var data = {

      UntouchedData: UntouchedData,

      NewData: NewData,

      CalldoneData: SuccessedRecords,

      CATEGORY_STATUS: CATEGORY_STATUS

    };
    return this.httpClient.post<any>(this.baseUrl + 'api/hapAndGDMSLost/insertNewLeadsToHMIL', JSON.stringify(data), this.options)

  }


  hmilhapAndGDMSLost(

    EXCEL_FILE_NAME: string,

  ): Observable<any> {

    var data = {

      EXCEL_FILE_NAME: EXCEL_FILE_NAME,

    };




    return this.httpClient.post<any>(

      this.url + "Hyundai/HapAndGDMSLost/ImportHapDetailsToExcel",

      JSON.stringify(data),

      this.options

    );

  }

  EmployeeAllocationReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any,
    EMPLOYEE_ID: any, BRANCH_ID: any, DEPARTMENT_ID: any, DESIGNATION_ID: any, HOD_ID: any): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: EMPLOYEE_ID,
      BRANCH_ID: BRANCH_ID,
      DEPARTMENT_ID: DEPARTMENT_ID,
      DESIGNATION_ID: DESIGNATION_ID,
      HOD_ID: HOD_ID
    };
    return this.httpClient.post<any[]>(this.url + "employeeEvaluationChart/getEmployeeEvaluation", JSON.stringify(data1), this.options
    );
  }


  TourDetailedReports(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any, EMPLOYEE_ID: any, FROM_DATE: any, TO_DATE: any,
    ADVANCE_APPROVED_BY: any, ADVANCE_APPROVED_DATE: any, ADVANCE_PENDING_AT: any, EXPENSES_APPROVED_BY: any, EXPENSES_PENDING_AT: any,
    EXPENSE_APPROVED_DATE: any
  ): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: EMPLOYEE_ID,

      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      ADVANCE_APPROVED_BY: ADVANCE_APPROVED_BY,

      ADVANCE_APPROVED_DATE: ADVANCE_APPROVED_DATE,
      ADVANCE_PENDING_AT: ADVANCE_PENDING_AT,
      EXPENSES_APPROVED_BY: EXPENSES_APPROVED_BY,
      EXPENSES_PENDING_AT: EXPENSES_PENDING_AT,
      EXPENSE_APPROVED_DATE: EXPENSE_APPROVED_DATE

    };
    return this.httpClient.post<any[]>(this.url + "tour/tourDetailedReport", JSON.stringify(data1), this.options
    );
  }


  leaveTransactionSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
    MONTH: any, YEAR: any) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      MONTH: MONTH,
      YEAR: YEAR,
    };
    return this.httpClient.post<any>(this.url + "reports/leaveTransactionSummary/getEmployeeLeaveSummary", JSON.stringify(data), this.options);
  }

  asOnDateBalance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any, FROM_DATE: any
  ): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE

    };
    return this.httpClient.post<any[]>(this.url + "asOnDateBalance/get", JSON.stringify(data1), this.options
    );
  }


  getLeaveTransactionDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any, FROM_DATE: any, TO_DATE: any
  ): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<any[]>(this.url + "leaveTransactionDetailedReport/get", JSON.stringify(data1), this.options
    );
  }


  getSCAndASM(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: any, MONTH: any, YEAR: any, SALES_EXECUTIVE_ID: any,
    ROLESS: any,
  ): Observable<any[]> {
    var data1 = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MONTH: MONTH,
      YEAR: YEAR,
      SALES_EXECUTIVE_ID: SALES_EXECUTIVE_ID,
      SALES_MANAGER_ID: this.userId,
      CLUSTER_ID: this.ClusterId,
      SALES_MANAGER_IDS: ROLESS,
      ROLE_ID: this.roleId
    };
    return this.httpClient.post<any[]>(this.url + "incentiveSummaryReport/getIncentiveSummaryReport", JSON.stringify(data1), this.options
    );
  }


  getUserwisefeedbackReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    FROM_DATE: any,
    TO_DATE: any,
    TYPE: any,
    BRANCH_ID: any
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      TYPE: TYPE,
      BRANCH_ID: BRANCH_ID
    };
    return this.httpClient.post<any>(
      this.url + "feedbackReports/getEmpWiseCounts",
      JSON.stringify(data),
      this.options
    );
  }
  getresolverwisefeedbackReport(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string,
    FROM_DATE: any,
    TO_DATE: any,
    TYPE: any,
    BRANCH_ID: any
  ): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      TYPE: TYPE,
      BRANCH_ID: BRANCH_ID,
      isResolverReport: this.isResolverReport
    };
    return this.httpClient.post<any>(
      this.url + "feedbackReports/getEmpWiseCounts",
      JSON.stringify(data),
      this.options
    );
  }
  getBranchwisefeedbackdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE: any, TO_DATE: any, TYPE: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      TYPE: TYPE
    };
    return this.httpClient.post<any>(this.url + "feedbackReports/getBranchWiseCounts", JSON.stringify(data), this.options);
  }
  getUserwisefeedbackdetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<any>(this.url + "employeeFeedbackDetails/get", JSON.stringify(data), this.options);
  }


  getEmployeeChild(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, SALES_MANAGER_IDS: any, CLUSTER_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: SALES_MANAGER_IDS,
      CLUSTER_ID: CLUSTER_ID,
    };
    return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  }
  role = 30
  getEmployeeChildGM(pageIndex: any, pageSize: any, sortKey: any, sortValue: any, filter: any, SALES_MANAGER_IDS: any, CLUSTER_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: SALES_MANAGER_IDS,
      CLUSTER_ID: CLUSTER_ID,
      ROLE_ID: this.role,
    };
    return this.httpClient.post<any>(this.url + "reports/getEmployeeChild", JSON.stringify(data), this.options);
  }

  getSMRIncentiveReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "ServiceIncentiveReport/get", JSON.stringify(data), this.options);
  }
  getLeadAptTransferReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "ApoointmentTranseferReport/get", JSON.stringify(data), this.options);
  }
  getDailyDSIReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "PsfDailyDsiReport/get", JSON.stringify(data), this.options);
  }

  psfcomplaintdetailedreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, START_DATE: string,
    END_DATE: string): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortValue: sortValue, filter: filter, START_DATE: START_DATE,
      END_DATE: END_DATE, CLUSTER_ID: this.ClusterId
    };
    return this.httpClient.post<ThoughMaster[]>(this.url + "PsfComplaintDetails/get",
      JSON.stringify(data),
      this.options
    );
  }

  getSAwisepsfreport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE: any, TO_DATE: any): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<Ticket[]>(this.url + "PsfSAwiseSummery/get", JSON.stringify(data), this.options);
  }

    updatPSFActivityleadmaster(ActivityLeadMaster1: PSFActivityLeadMaster): Observable<number> {


    ActivityLeadMaster1.CLIENT_ID = this.clientId; return this.httpClient.put<number>(this.url + "PSFleadactivitydetailes/update", JSON.stringify(ActivityLeadMaster1), this.options);


  }

}
