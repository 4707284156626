import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd";
import { FuelMaster } from "src/app/Models/FuelMaster";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-fuel-drawer",
  templateUrl: "./fuel-drawer.component.html",
  styleUrls: ["./fuel-drawer.component.css"],
})

export class FuelDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: FuelMaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 s]){1,}";

  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined && this.data.NAME != null) {
      if (this.data.NAME.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Valid Fuel Name", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Fuel Name", "");
    }

    if (this.data.SEQUENCE_NO != undefined && this.data.SEQUENCE_NO != null) {
      if (this.data.SEQUENCE_NO == 0) {
        isOk = false;
        this.message.error("Please Enter Valid Sequence No.", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Sequence No.", "");
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateFuel(this.data).subscribe((successCode) => {
          if (successCode["code"] == 200) {
            this.message.success("Fuel Details Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Fuel Details Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createFuel(this.data).subscribe((successCode) => {
          if (successCode["code"] == 200) {
            this.message.success("Fuel Details Added Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new FuelMaster();
              this.getSequenceNo();
            }

          } else {
            this.message.error("Failed to Add Fuel", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  getSequenceNo(): void {
    this.api.getAllFuel(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;

      } else {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
      }

    }, err => {
      console.log(err);
    })
  }
}
