import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { schedule } from 'src/app/Models/schedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-sales-exe-wise-detailed-memo-schedule',
  templateUrl: './sales-exe-wise-detailed-memo-schedule.component.html',
  styleUrls: ['./sales-exe-wise-detailed-memo-schedule.component.css']
})
export class SalesExeWiseDetailedMemoScheduleComponent implements OnInit {
  menu
  data: schedule = new schedule();
  drawerTitle: string | undefined;
  isVisible = false;
  @Input()isVisible1:false
  options:any=[]
  mails1:any=[]
  masterdata:any=[]
  @Input() drawerClose1!: Function;
 dataList:any
 @Input() FILTER_QUERY: any;
 @Input() members: any;
  
  constructor(  private api: ApiService,
    private message: NzNotificationService,) { }
  onGet(){
    this.api.getAllMembers1(0,0,'','','AND REPORT_ID = 10').subscribe((data:any)=>{
      this.members=data['data'];
    })
  }
  onGetReports(){
    this.api.getMembers(0,0,'','','').subscribe((data:any)=>{
      this.masterdata=data['data'];
    })
  }
  onGetMails(){
    this.api.getAllemployeeMaster(0,0,'','','').subscribe((data:any)=>{
      this.mails1=data['data'];
    })
  }
  ngOnInit() {
    // this.onGetMails()
    this. onGetReports()
  }
  add(){
    this.drawerTitle = "Create Schedule";
   this.data=new schedule()
   this.data.REPORT_ID = 10
    // this.user = Object.assign({});
    console.log(this.data,"addition")
    this.isVisible = true;
    console.log(this.mails1,"reportname")
    
  }
  edit(data:any) {
    this.drawerTitle = "Update Report";
  
    this.data = Object.assign({}, data);
   console.log(this.data,"")
   
   
    this.data.TIME= this.data.TIME == ''||this.data.TIME ==  null ? [] : this.data.TIME.split(',')
    this.data.MONTHS= this.data.MONTHS == '' ||this.data.MONTHS ==  null? [] : this.data.MONTHS.split(',')
    this.data.MAIL_LIST= this.data.MAIL_LIST == ''||this.data.MAIL_LIST ==   null ? [] : this.data.MAIL_LIST.split(',')
    this.data.DAYS= this.data.DAYS == '' ||this.data.DAYS ==   null ? [] : this.data.DAYS.split(',')
    this.data.YEARS= this.data.YEARS == '' ||this.data.YEARS ==   null ? [] : this.data.YEARS.split(',')

    console.log(this.data.TIME,"TIME")
    this.isVisible = true;
  }
 
save()
{
  this.drawerClose1()
}
  close(){
 this.drawerClose1()
    console.log(this.options,"data")
  }
  drawerClose(): void {
   
    this.isVisible= false;
    this.onGet()
    console.log(this.options)
  }
  get closeCallback1() {
    return this.drawerClose.bind(this);
  }

}
