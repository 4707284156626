import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
// import { rateMaster } from '../Models/IncentiveRateMaster';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays} from 'date-fns';
import { ApiService } from 'src/app/Service/api.service';
import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
@Component({
  selector: 'app-rate-master-form',
  templateUrl: './rate-master-form.component.html',
  styleUrls: ['./rate-master-form.component.css']
})
export class RateMasterFormComponent implements OnInit {

  @Input() drawerClose!: Function;
  @Input() data: rateMaster = new rateMaster();

  rateMaster = new rateMaster();

  constructor(private api: ApiService, private message: NzNotificationService, private datepipe: DatePipe) { }
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  date = null;
  isOk = false;
  switch1 = false;
  isSpinning = false;
  listOfData: any = [];
  today=new Date();
  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;
  startOpen=false;

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    // if (!endValue || !this.startValue) {
    //   return false;
    // } return endValue.getTime() <= this.startValue.getTime();
    return differenceInCalendarDays(endValue, this.startValue) < 0;
  }; 
  
  // disabledEndDate = (startValue: Date): boolean => {
  //   // Can not select days before today and today
  //   return differenceInCalendarDays(startValue, this.today) > 0;
  // };

  onStartChange(date: Date): void {
    this.startValue = new Date(date);
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }
  
  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
    console.log('handleStartOpenChange', open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    console.log(open);
    this.endOpen = open;
    this.startOpen = open;
  }

  ngOnInit() {
    this.getData();
  }

  onChange1(result: Date): void {
    console.log('onChange: ', result);
  }
  onChange2(result: Date): void {
    console.log('onChange: ', result);
  }

  getData() {
    this.api.getRateMaster(this.pageIndex, this.pageSize, '', '', '').subscribe((data) => {
      this.listOfData = data['data'];
      console.log('this.listOfData', this.listOfData);

    })
  }

  switchChange2(e1: boolean) {
    this.switch1 = e1;
  }

  onSubmit(addNew: boolean, httpForm: NgForm) {

    this.isOk = true;
    if (this.data.INCENTIVE_STRUCUTURE_NAME == null || this.data.INCENTIVE_STRUCUTURE_NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Structure Name.', "")
    }
    else if (this.data.FROM_DATE == null || this.data.FROM_DATE == 0) {
      this.isOk = false
      this.message.error('Please Enter From Date.', "")
    }
    else if (this.data.TO_DATE == null || this.data.TO_DATE == 0) {
      this.isOk = false
      this.message.error('Please Enter To Date.', "")
    }

    if (this.isOk) {

      if (this.data.ID) {
        this.data.FROM_DATE = this.datepipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
        this.data.TO_DATE = this.datepipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');
        this.api.updateData(this.data).subscribe((successCode: any) => {

          if (successCode['code'] == 200) {
            this.getData();

            this.message.success("Information Updated Successfully...", "");
            console.log(this.data, 'Update Success')

            if (!addNew) {
              this.drawerClose();
              this.reset(httpForm);
              this.isSpinning = false;
            }



            else {
              this.data = new rateMaster();
              this.message.error("Failed To Update", "");
              console.log(this.data, "fail Update")

              this.isSpinning = false;
            }
            // console.log(this.data.APPLICABLE_TYPES);
          }

          else if(successCode['code']==400){
            this.message.error("Not Allowed To Update Overlapped From date and To date Structure...", "");
          }

          else{
            this.message.error("Failed To Update Sales Incentive Structure...","")
          }

        });

      }
      else {

        this.isSpinning = true;
        this.data.FROM_DATE = this.datepipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
        this.data.TO_DATE = this.datepipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');

        this.api.createData(this.data)
          .subscribe(successCode => {

            if (successCode['code'] == 200) {
              this.message.success("Direct incentive added successfully...", "");
              console.log(this.data, "create Success");
              this.getData();
              if (!addNew) {
                // this.isSpinning = true;
                this.drawerClose();
                this.reset(httpForm);

              }
              else {
                this.data = new rateMaster();
                httpForm.resetForm();

              }
              this.isSpinning = false;
            }
            else if(successCode['code']==400){
              this.message.error("Not Allowed To Create Overlapped From date and To date Structure...", "");
            }
  
            else{
              this.message.error("Failed To Update Sales Incentive Structure...","")
            }
          });

      }

    }

  }



  close(httpForm: NgForm) {
    this.reset(httpForm);
    this.drawerClose();
    this.data = new rateMaster();
  }

  reset(httpForm: NgForm) {
    httpForm.reset();
  }

}
