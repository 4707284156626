import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { CustomerMaster } from 'src/app/Models/customer-master';
import { NewCustomerMaster } from 'src/app/Models/newcustomer';
import { ApiService } from 'src/app/Service/api.service';
import { NewcustomerComponent } from '../newcustomer/newcustomer.component';

@Component({
  selector: 'app-newcustomers',
  templateUrl: './newcustomers.component.html',
  styleUrls: ['./newcustomers.component.css']
})
export class NewcustomersComponent implements OnInit {

  formTitle = "Manage Customers";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [  ["BOOKING_DATE", "Booking Date"], ["SERVICE_ADVISOR_NAME", "Service Advisor Name"], ["BT_NUMBER", "BT Number"], ["BT_DATE_TIME", "BT Date Time"],
  ["CUSTOMER_NAME", "Customer Name"], ["BOOKING_CONTACT_NUMBER", "Booking Contact Number"], ["MOBILE_NUMBER", "Mobile Number"], ["REGISTRATION_NO", "Registration No"], ["VIN", "VIN"], 
  ["WORK_TYPE", "Work Type"], ["EXPRESS_CARE", "Express Care"], ["CALLING_DATE", "Calling Date"], ["CALLING_STATUS", "Calling Status"], ["REASON", "Reason"],
   ["BILL_NUMBER", "Bill Number"], ["BILL_AMOUNT", "Bill Amount"], ["BILL_DATE", "Bill Date"], ["MODEL", "Model"],
    ["REMARK", "Remark"], ["REMINDER_DATE", "Reminder Date"], ["VEDIO_CALL", "Video Call"],
    ["SPECIAL_MESSAGE", "Special Message"], ["RO_DATE", "Ro Date"],
  ["RO_NUMBER", "Ro Number"], ["NUMBER_OF_CALLS", "Number Of Calls"], ["LIVE_STREAMING", "Live Streaming"], 
  ["STATUS", "Status"],   ["LOCATION", "Location"], 
   ["VARIANT", "Varient"], ["CHASSIS_NUMBER", "Chassis Number"], 
    
       ["SME_NAME", "SME Name"], ["PICK_UP_AND_DROP", "Pick Up and Drop"],  
       ["SRV_MODEL", "SRV Model"], ["EMAIL", "Email"], ["ADDRESS", "Address"]  ]
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: NewCustomerMaster = new NewCustomerMaster();
  orgId = this.cookie.get('orgId');
  userid= this.cookie.get('userId')
  @ViewChild(NewcustomerComponent, { static: false }) addnewcuster: NewcustomerComponent;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe,) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    // console.log("search text : " + this.searchText);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.api.getAllnewCustomers(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + " AND ASSIGNED_ID ="+this.userid ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Customer";
    this.drawerData = new NewCustomerMaster();
    // this.drawerData.IS_ACTIVE = true;
    this.stepsdisabled = true
    this.drawerData.CALLING_DATE = new Date()
    this.drawerData.CALLING_TIME = new Date() 
    this.drawerData.BOOKING_TIME = new Date()
    this.drawerVisible = true;
    this.drawerData.CALL_TYPE = 'I'
  }

  stepsdisabled : boolean

  edit(data: NewCustomerMaster): void {
    this.drawerTitle = "Update Customer Details";
    this.drawerData = Object.assign({}, data);
    this.stepsdisabled = false
    this.drawerVisible = true;

    if (data.BOOKING_TIME != null)
    this.drawerData.BOOKING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.BOOKING_TIME;

    if (data.CALLING_TIME != null)
    this.drawerData.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.CALLING_TIME;

    if (data.PICK_UP_TIME != null)
    this.drawerData.PICK_UP_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.PICK_UP_TIME;
  
  
  
    this.addnewcuster.getallorg2(data.ID);
    
  
  
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

}
