import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branch-wise-finance-detailed-report',
  templateUrl: './branch-wise-finance-detailed-report.component.html',
  styleUrls: ['./branch-wise-finance-detailed-report.component.css']
})
export class BranchWiseFinanceDetailedReportComponent implements OnInit {
  formTitle = "Branch Wise Outhouse Finance Detailed Report";

  pageIndex = 1; 
  pageSize = 10;
  totalRecords = 1;
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = false;
  // SearchData : string = '';
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  filterQuery: string = "";
  columns: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["BANK_NAME", "Bank Name"],
    ["CUSTOMER_NAME", "Customer name"],
    ["MODEL_NAME", "Model"],
    ["VARIENT_NAME", "Varient"]
  ];
  searchText: string = "";
  date = null;

  orgId = this.cookie.get("orgId");
  BRANCH_ID  :any = [];
  BANK_ID:any = [];
  MODEL_ID: any = [];
  VARIENT_ID:any = [];
  startValue: any = new Date()
  endValue: any = new Date()
  STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  // month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  // year = new Date().getFullYear()
  // MONTH: any = this.month;
  // MONTH1: any = new Date()
  // YEAR :any= this.year
  // monthFormat = "MMM-yyyy";

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService
  ) { }

  tableData: any;
  ngOnInit() {
    this.search();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.getBranches();
    this.getModels();
    this.getBank();
    
  }

  endOpen = false;
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue -2;
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let BranchName = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        BranchName.push(this.branches[i]["ID"]);
      }
    } else {
      BranchName = [];
    }
    this.BRANCH_ID  = BranchName;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID  = event;
    if (this.BRANCH_ID .length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  // Bank Operation starts --------------------------------------------
  SELECT_ALL_BANK: boolean = false;
  banks = [];
  onSelectAllCheckedbank(event) {
    this.SELECT_ALL_BANK = event;
    let bankid = [];
    if (this.SELECT_ALL_BANK == true) {
      for (var i = 0; i < this.banks.length; i++) {
        bankid.push(this.banks[i]["NAME"]);
      }
    } else {
      bankid = [];
    }
    this.BANK_ID = bankid;
  }

  // Getting Bank Data From API -----------------------------------------
  getBank() {
    this.banks = [];

    this.api
      .getAllBanks(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.banks = data["data"];
          }
          // console.log(this.banks);

        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  onSelectOffBank(event) {
    var a = this.banks.length;
    var b = this.banks.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL_BANK = false;
    } else {
      this.SELECT_ALL_BANK = true;
    }
    this.BANK_ID = event;
    if (this.BANK_ID.length == 0) {
      this.SELECT_ALL_BANK = false;
    }
  }
  // Bank Operation Over ---------------------------------------------------

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
      this.SELECT_ALL3 = false;
    }
    this.MODEL_ID = ids;
    this.getVarients(this.MODEL_ID);
  }

  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  SELECT_ALL3: boolean = false;
  onSelectAllChecked3(event) {
    this.SELECT_ALL3 = event;
    let ids = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.varients.length; i++) {
        ids.push(this.varients[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT_ID = ids;
  }

  onSelectOff3(event) {
    var a = this.varients.length;
    var b = this.varients.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.VARIENT_ID = event;
    if (this.VARIENT_ID.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data: any) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
          console.log(this.branches);

        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  models = [];

  getModels() {
    this.models = [];
    this.varients = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
      (data: any) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
        // console.log(this.models);

      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  varients = [];

  getVarients(modelID) {
    this.varients = [];
    this.VARIENT_ID = [];

    this.api
      .getAllVarients(
        0,
        0,
        "NAME",
        "asc",
        " AND MODEL_ID IN (" + modelID + ") AND STATUS=1"
      )
      .subscribe(
        (data: any) => {
          if (data["code"] == 200) {
            this.varients = data["data"];
          }
          // console.log(this.varients);

        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // setDateToDateFilter() {
  //   // let currentDate: Date = new Date();
  //   // this.date[0] = currentDate;
  //   // this.date[1] = currentDate;
  // }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  // getInitial(empName) {
  //   let initial: string = empName.charAt(0);
  //   return initial.trim();
  // }

  applyFilter() {

    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.BRANCH_ID  != undefined || this.BRANCH_ID  > 0) {
      this.BRANCH_ID  = this.BRANCH_ID ;
    }
    if (this.BANK_ID != undefined || this.BANK_ID > 0) {
      this.BANK_ID = this.BANK_ID;
    }
    if (this.MODEL_ID != undefined || this.MODEL_ID > 0) {
      this.MODEL_ID = this.MODEL_ID;
    }
    if (this.VARIENT_ID != undefined || this.VARIENT_ID > 0) {
      this.VARIENT_ID = this.VARIENT_ID;
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue
    }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND(';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+")";
    }

  }

  clearFilter() {
    this.BRANCH_ID  = [];
    this.MODEL_ID = [];
    this.VARIENT_ID = [];
    this.BANK_ID = [];
    this.varients = []
    this.startValue = new Date()
    this.endValue = new Date()
    this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.SELECT_ALL1 = false;
    this.SELECT_ALL2 = false;
    this.SELECT_ALL3 = false;
    this.SELECT_ALL_BANK = false;
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.branchOuthouseData = [];
    this.search(true);
    this.filterQuery = '';
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  branchOuthouseData = [];
  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    if (this.searchText != '') {
      likeQuery = ' AND(';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+")";
    }
    
    if (this.BRANCH_ID  != undefined || this.BRANCH_ID  > 0) {
      this.BRANCH_ID  = this.BRANCH_ID ;
    }
    if (this.BANK_ID != undefined || this.BANK_ID > 0) {
      this.BANK_ID = this.BANK_ID;
    }
    if (this.MODEL_ID != undefined || this.MODEL_ID > 0) {
      this.MODEL_ID = this.MODEL_ID;
    }
    if (this.VARIENT_ID != undefined || this.VARIENT_ID > 0) {
      this.VARIENT_ID = this.VARIENT_ID;
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue;
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue;
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getbranchwiseOuthouseFinanceDetails(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID , this.STARTDATE, this.ENDDATE, this.VARIENT_ID,this.MODEL_ID,this.BANK_ID)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.branchOuthouseData = data['data'];
            this.filterClass = 'filter-invisible';
          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = true;
      this.api.getbranchwiseOuthouseFinanceDetails(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID , this.STARTDATE, this.ENDDATE, this.VARIENT_ID,this.MODEL_ID,this.BANK_ID)
        .subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.branchOuthouseData = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.branchOuthouseData.length; i++) {
      obj1['Branch Name'] = this.branchOuthouseData[i]['BRANCH_NAME'];
      obj1['Bank Name'] = this.branchOuthouseData[i]['BANK_NAME'];
      obj1['Customer name'] = this.branchOuthouseData[i]['CUSTOMER_NAME'];
      obj1['Model'] = this.branchOuthouseData[i]['MODEL_NAME'];
      obj1['Varient'] = this.branchOuthouseData[i]['VARIENT_NAME'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.branchOuthouseData.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Outhouse Finance Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}

