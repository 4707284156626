import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-financemasteradd',
  templateUrl: './financemasteradd.component.html',
  styleUrls: ['./financemasteradd.component.css']
})
export class FinancemasteraddComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Leave;
  @Input() dataList = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  roles = [];
  branch = [];
  leaveTypes = [];
  // dataList = [];
  @Input() BRANCHES = [];
  @Input() ROLES = [];
  @Input() TYP
  Array1 = [];
  dataList1 = []

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Cluster Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence Number"],]

  orgId = this.cookie.get('orgId');

  @Input() ROLES22 = []
  @Input() BRANCHES22 = []
  finalData = []
  @Input() ROLES2 = []
  @Input() BRANCHES2 = []

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.getLeaveTypes();
    this.getLeaveTypeMaster();
    this.loadRoles();
    this.getBranch();

    
    // console.log(this.data1);

  }

  loadRoles() {
    this.api.getAllRoles(0, 0, '', 'asc', 'AND STATUS=1').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }
  cancel(){
    
  }
  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  getLeaveTypes() {
    this.leaveTypes = [];

    this.api.getAllLeaveType(0, 0, 'NAME', 'asc', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }


  
  applyFilter() {
    // this.dataList=[]
    this.finalData=[]
    console.log(this.ROLES2);
    
   
    var isOk = true;

    if (this.ROLES2.length == 0) {
      isOk = false;
      this.message.error("Please Select Roles", "");

    } else if (this.BRANCHES2.length == 0) {
      isOk = false;
      this.message.error("Please Select Branches", "");
    }

    if (isOk) {
      this.ROLES = this.ROLES2
      this.BRANCHES = this.BRANCHES2
      this.ROLES22 = []
      for (let i = 0; i < this.ROLES.length; i++) {
        var kk = this.roles.findIndex(x => x.ID == this.ROLES[i]);
        this.ROLES22.push(this.roles[kk]['NAME']);
        this.BRANCHES22 = []
        for (let j = 0; j < this.BRANCHES.length; j++) {
          this.finalData.push({ "ROLE_ID": this.ROLES[i], "BRANCH_ID": this.BRANCHES[j], "RATE": 0 })
          // console.log(this.finalData);
          var kkk = this.branch.findIndex(x => x.ID == this.BRANCHES[j]);
          this.BRANCHES22.push(this.branch[kkk]['NAME'])
        }
        if (i + 1 == this.ROLES.length) {

          this.getAmount()
          // this.dataList = [...[], ...this.finalData]
        }
      }
    }
    console.log(this.dataList);
    console.log(this.finalData);
    // this.BRANCHES2=[]
    // this.ROLES2=[]
  }

  getAmount() {  
    if (this.dataList.length > 0) {  
      this.dataList.forEach((x, index) => {
        this.finalData.forEach((x2, index2) => {
          if (x.BRANCH_ID == x2.BRANCH_ID &&
          x.ROLE_ID == x2.ROLE_ID) {
            this.finalData[index2]['RATE'] = this.dataList[index].RATE
          }
        })
        if (index + 1 == this.dataList.length) {
          this.dataList = [...[],...this.finalData];
          console.log(this.dataList);
        }
      });
    } else {
      this.dataList = [...[], ...this.finalData];
    }
  }

  deleterow(index: any) {
    var selected = this.ROLES[index];
    var d = this.dataList.filter((item) => {
      if (item.ROLE_ID != this.ROLES[index])
        return item;
    })

    // this.ROLES = this.ROLES.filter(item => item !== selected)
    this.ROLES.splice(index, 1);
    this.ROLES22.splice(index, 1);
    this.ROLES = [...[],...this.ROLES]
    this.ROLES22 = [...[],...this.ROLES22] 
    this.dataList = [...[],...d]
    this.finalData = [...[],...d];
  }

  deletecoloumn(index) {

    var d = this.dataList.filter((item) => {
      if (item.BRANCH_ID != this.BRANCHES[index])
        return item;
    })
    console.log(d)
    this.BRANCHES.splice(index, 1);
    this.BRANCHES22.splice(index, 1);
    this.BRANCHES = [...[],...this.BRANCHES]
    this.BRANCHES22 = [...[],...this.BRANCHES22]

    this.dataList = [...[],...d]
    this.finalData = [...[],...d];
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.ROLES = []
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(dataList): void {
    var isOk = true;

    console.log(this.data);

    if (this.dataList.length == 0) {
      isOk = false;
      this.message.error("Please Select Roles And Branches", "");
    }

    var obb = ({ "FINANCE_INCENTIVE_MASTER_ID": this.data.ID, "TYPE": this.TYP, "ROLE_IDS": this.ROLES.toString(), "BRANCH_IDS": this.BRANCHES.toString(), "data": this.dataList })
    // Object.assign(obb,this.dataList)
    if (isOk) {
      this.isSpinning = true;


      this.api.Addbulkformapping(obb).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Roles And Branches Mapped Successfully", "");
          this.drawerClose()
          this.isSpinning = false;

        } else {
          this.message.error("Roles And Branches Mapping Failed", "");
          this.isSpinning = false;
        }
      });

    }
  }

  today = new Date().setDate(new Date().getDate() + 4);

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.FROM_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }

  leaveTypeDetails = [];

  getLeaveTypeMaster() {
    this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.leaveTypeDetails = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  onLeaveTypeSelected(leaveType) {
    var leaveData = this.leaveTypeDetails.filter(obj => {
      return (obj.ID == leaveType);
    });

    if (leaveData.length != 0)
      this.today = new Date().setDate(new Date().getDate() + Number(leaveData[0]["LEAVE_APPROVAL_DAYS"]));
    else
      this.today = new Date().setDate(new Date().getDate() + Number(4));

    this.data.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
    this.data.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");

    this.disabledStartDate = (current: Date): boolean =>
      differenceInCalendarDays(current, this.today) < 0;
  }
}
