export class SMRCOLLAPSEMASTER {
    ID: any
    SEQUENCE_NUMBER: any
    BOOKING_DATE: any
    SERVICE_ADVISOR_NAME: any
    BT_NUMBER: any
    BT_DATE_TIME: any
    CUSTOMER_NAME: any
    BOOKING_CONTACT_NUMBER: any
    MOBILE_NUMBER: any
    REGISTRATION_NO: any
    VIN: any
    WORK_TYPE: any
    EXPRESS_CARE: any
    CALLING_DATE: any
    CALLING_STATUS: any
    SME_EMPLOYEE_ID: any
    BILL_NUMBER: any
    BILL_AMOUNT: any
    BILL_DATE: any
    MODEL: any
    REMARK: any
    REMINDER_DATE: any
    FOLLOWUP_NO: any
    CALL_DATE: any
    SERVICE_COUNT: any
    CUST_NO: any
    SPECIAL_MESSAGE: any
    RO_DATE: any
    RO_NUMBER: any
    NUMBER_OF_CALLS: any
    LIVE_STREAMING: any
    STATUS: any
    DELIVERY_DATE: any
    LOCATION: any
    PIN_CODE: any
    VARIANT: any
    CHASSIS_NUMBER: any
    SALES_CONSULTANT_NAME: any
    SALE_DATE: any
    LAST_SERVICE_TYPE: any
    LAST_RO_DATE: any
    SEGMENT: any
    LAST_RO_WORKSHOP: any
    LAST_RO_DEALER_NAME: any
    LAST_RO_REGION: any
    NAME: any
    WORK_PHONE_NUMBER: any
    ROOT: any
    SME_NAME: any
    PICK_UP_AND_DROP: any
    LOST_CUSTOMER_NAME: any
    SRV_MODEL: any
    EMAIL: any
    ADDRESS: any
    PURCHASE_DATE: any
    PREFERRED_DLR_NO: any
    OFFICE_NUMBER: any
    RESIDENCE_NUMBER: any
    MILEAGE: any
    FIELD1: any
    FIELD2: any
    FIELD3: any
    FIELD4: any
    FIELD5: any
    FIELD6: any
    FIELD7: any
    FIELD8: any
    FIELD9: any
    FIELD10: any
    REASON
    DUE_DATE


}