import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { insuranceMaster } from 'src/app/Models/incentiveInsurance';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-branch-map2',
  templateUrl: './branch-map2.component.html',
  styleUrls: ['./branch-map2.component.css']
})
export class BranchMap2Component implements OnInit {
  


  @Input() data: insuranceMaster;
  @Input() drawerClose22: Function;
  @Input() drawerVisible: boolean;
  @Input() MappingData1: any;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
  }

  close() {
    console.log(this.MappingData1, "Input")
    this.drawerClose22();
  }

  save() {

    if (this.MappingData1.length > 0) {

      this.api.addBranch12(this.data.ID,this.MappingData1).subscribe(successCode => {
      console.log(successCode,"successCode")
        if (successCode['code'] == 200) {
          this.message.success("Insurance Mapping Updated Successfully...", "");
          this.api.getincentive(0,0,'','','','','').subscribe(successCode => {
            this.drawerClose22();
          });
         

        }
        else {
          this.message.error("cannot update Insurance Mapping", '')
        }
      });
    }
    else {
      this.message.error("no data", '')
    }
  }

}
