import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, AsyncPipe, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ApplicationComponent } from './pages/CommonModule/application/application/application.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModuleComponent } from './pages/CommonModule/module/module/module.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UserComponent } from './pages/CommonModule/user/user/user.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { ApplicationModuleMappingComponent } from './pages/CommonModule/application/application-module-mapping/application-module-mapping.component';
import { UserModuleMappingComponent } from './pages/CommonModule/user/user-module-mapping/user-module-mapping.component';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { RoleComponent } from './pages/CommonModule/role/role/role.component';
import { RolemodulemappingComponent } from './pages/CommonModule/role/rolemodulemapping/rolemodulemapping.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component'
import { FaqHeadComponent } from './pages/SupportModule/FaqHead/faq-head/faq-head.component'
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FaqComponent } from './pages/SupportModule/FAQ/faq/faq.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component'
import { FormComponent } from './pages/CommonModule/form/form/form.component'
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { RoledetailsComponent } from './pages/CommonModule/role/roledetails/roledetails.component';
import { FaqresponsesComponent } from './pages/SupportModule/FAQ/faqresponses/faqresponses.component';
import { DepartmentComponent } from './pages/SupportModule/Department/department/department.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
// import { DepartmentcalenderComponent } from './pages/SupportModule/Department/departmentcalender/departmentcalender/departmentcalender.component';
import { EmailComponent } from './pages/CommunicationModule/EmailServer/email/email.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { HistoryComponent } from './pages/CommunicationModule/EmailServer/history/history.component';
import { SMSHistoryComponent } from './pages/CommunicationModule/SMSServer/history/SMShistory.component';
import { SmsComponent } from './pages/CommunicationModule/SMSServer/sms/sms.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FolderComponent } from './pages/DocumentModule/folder/folder/folder.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { TicketdetailsComponent } from './pages/SupportModule/Tickets/ticketdetails/ticketdetails.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TaskComponent } from './pages/SchedularModule/Task/task/task.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TicketgroupsComponent } from './pages/SupportModule/Tickets/ticketgroups/ticketgroups.component';
import { TestsComponent } from './pages/tests/tests.component';
import { ViewbodyComponent } from './pages/CommunicationModule/EmailServer/viewbody/viewbody.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { CreateticketComponent } from './pages/UserModule/createticket/createticket.component';
import { ViewchatticketComponent } from './pages/UserModule/viewchatticket/viewchatticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { ChattdetailsicketComponent } from './pages/Reports/chattdetailsicket/chattdetailsicket.component';
import { OrganizationComponent } from './pages/Organizations/organization/organization.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { DesignationComponent } from './pages/DesignationMaster/designation/designation.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
// import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
// import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
// import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
// import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { TickRoleComponent } from './pages/RoleMaster/tick-role/tick-role.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeatureComponent } from './pages/FeatureMaster/feature/feature.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { HelploginComponent } from './helplogin/helplogin.component';
// import { Clusterbranchmasterlistcomponent } from './pages/clusterbranchmaster/clusterbranchmasterlist/clusterbranchmasterlist.component';
// import { AddclusterbranchmasterComponent } from './pages/clusterbranchmaster/addclusterbranchmaster/addclusterbranchmaster.component';
import { AddemployeemasterComponent } from './pages/employeemaster/addemployeemaster/addemployeemaster.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { AddclustermasterComponent } from './pages/clustermaster/addclustermaster/addclustermaster.component';
import { AddemployeerolemapComponent } from './pages/employeerolemap/addemployeerolemap/addemployeerolemap.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { FolderDrawerComponent } from './pages/FolderAndFileModule/Folder/folder-drawer/folder-drawer.component';
import { FileDrawerComponent } from './pages/FolderAndFileModule/Folder/file-drawer/file-drawer.component';
import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { TransferTicketDrawerComponent } from './pages/SupportModule/Tickets/transfer-ticket-drawer/transfer-ticket-drawer.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { MynotificationsComponent } from './pages/Notify/mynotifications/mynotifications.component';
import { SendnotificationsComponent } from './pages/Notify/sendnotifications/sendnotifications.component';
import { SharingExistingFolderComponent } from './pages/FolderAndFileModule/Folder/sharing-existing-folder/sharing-existing-folder.component';
// import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
// import { AddNewNotificationDrawerComponent } from './pages/NotificationModule/add-new-notification-drawer/add-new-notification-drawer.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
// import { SendEmailDrawerComponent } from './pages/NotificationModule/send-email-drawer/send-email-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { LeavetypesComponent } from './pages/EMM_Module/LeaveType/leavetypes/leavetypes.component';
import { LeavetypeComponent } from './pages/EMM_Module/LeaveType/leavetype/leavetype.component';
import { AddEmployeeLeaveComponent } from './pages/EMM_Module/leave/add-employee-leave/add-employee-leave.component';
import { ExpenseheadComponent } from './pages/EMM_Module/ExpenseHead/expensehead/expensehead.component';
import { ExpensesheadComponent } from './pages/EMM_Module/ExpenseHead/expenseshead/expenseshead.component';
import { AddEmployeeExpensesComponent } from './pages/EMM_Module/expenses/add-employee-expenses/add-employee-expenses.component';
import { EvaluationcriteriaComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriteria/evaluationcriteria.component';
import { EvaluationcriteriasComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriterias/evaluationcriterias.component';
import { CustomersComponent } from './pages/EMM_Module/Customer/customers/customers.component';
import { CustomerComponent } from './pages/EMM_Module/Customer/customer/customer.component';
import { HolidaysComponent } from './pages/EMM_Module/Holidays/holidays/holidays.component';
import { ThoughsComponent } from './pages/EMM_Module/Though/thoughs/thoughs.component';
import { ThoughComponent } from './pages/EMM_Module/Though/though/though.component';
import { BirthdaysComponent } from './pages/EMM_Module/birthday/birthdays/birthdays.component';
import { BirthdayComponent } from './pages/EMM_Module/birthday/birthday/birthday.component';
import { AddearlymarkComponent } from './pages/EMM_Module/Earlymark/addearlymark/addearlymark.component';
import { AddlatemarkComponent } from './pages/EMM_Module/LateMark/addlatemark/addlatemark.component';
import { EmployeeexpensetypemappingComponent } from './pages/employeemaster/employeeexpensetypemapping/employeeexpensetypemapping.component';
import { LeaveApproveDrawerComponent } from './pages/EMM_Module/leave/leave-approve-drawer/leave-approve-drawer.component';
import { HeadLeaveApproveDrawerComponent } from './pages/EMM_Module/leave/head-leave-approve-drawer/head-leave-approve-drawer.component';
import { HeadLatemarkApproveDrawerComponent } from './pages/EMM_Module/LateMark/head-latemark-approve-drawer/head-latemark-approve-drawer.component';
import { HeadEarlymarkApproveDrawerComponent } from './pages/EMM_Module/Earlymark/head-earlymark-approve-drawer/head-earlymark-approve-drawer.component';
import { EmployeecollegemappingComponent } from './pages/employeemaster/employeecollegemapping/employeecollegemapping.component';
import { AccountantExpensesApproveDrawerComponent } from './pages/EMM_Module/expenses/accountant-expenses-approve-drawer/accountant-expenses-approve-drawer.component';
import { HeadExpensesApproveDrawerComponent } from './pages/EMM_Module/expenses/head-expenses-approve-drawer/head-expenses-approve-drawer.component';
import { AttendancereportComponent } from './pages/EMM_Module/Reports/attendancereport/attendancereport.component';
import { LeavereportComponent } from './pages/EMM_Module/Reports/leavereport/leavereport.component';
// import { LatemarkreportComponent } from './pages/EMM_Module/Reports/latemarkreport/latemarkreport.component';
// import { EarlymarkreportComponent } from './pages/EMM_Module/Reports/earlymarkreport/earlymarkreport.component';
import { ExpensereportComponent } from './pages/EMM_Module/Reports/expensereport/expensereport.component';
import { EvaluationreportComponent } from './pages/EMM_Module/Reports/evaluationreport/evaluationreport.component';
import { AssetCategoryListComponent } from './pages/materialIssueModule/assetCategory/asset-category-list/asset-category-list.component';
import { AssetCategoryDrawerComponent } from './pages/materialIssueModule/assetCategory/asset-category-drawer/asset-category-drawer.component';
import { AssetListComponent } from './pages/materialIssueModule/asset/asset-list/asset-list.component';
import { AssetDrawerComponent } from './pages/materialIssueModule/asset/asset-drawer/asset-drawer.component';
import { EarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/earlymark-tiles/earlymark-tiles.component';
import { LatemarkTilesComponent } from './pages/EMM_Module/LateMark/latemark-tiles/latemark-tiles.component';
import { LeaveTilesComponent } from './pages/EMM_Module/leave/leave-tiles/leave-tiles.component';
import { ExpensesTilesComponent } from './pages/EMM_Module/expenses/expenses-tiles/expenses-tiles.component';
import { HeadApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/head-approve-employee-leaves-tiles/head-approve-employee-leaves-tiles.component';
import { ApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/approve-employee-leaves-tiles/approve-employee-leaves-tiles.component';
import { HeadApproveEmployeeLatemarkTilesComponent } from './pages/EMM_Module/LateMark/head-approve-employee-latemark-tiles/head-approve-employee-latemark-tiles.component';
import { HeadApproveEmployeeEarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/head-approve-employee-earlymark-tiles/head-approve-employee-earlymark-tiles.component';
import { ExpensesApproveFromHeadTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component';
import { ExpensesApproveFromAccountantTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-accountant-tiles/expenses-approve-from-accountant-tiles.component';
import { AssetMappingListComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-list/asset-mapping-list.component';
import { AssetMappingDrawerComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-drawer/asset-mapping-drawer.component';
import { AssetRequestListComponent } from './pages/materialIssueModule/assetRequest/asset-request-list/asset-request-list.component';
import { AssetRequestDrawerComponent } from './pages/materialIssueModule/assetRequest/asset-request-drawer/asset-request-drawer.component';
import { AttendanceregisterComponent } from './pages/EMM_Module/Reports/attendanceregister/attendanceregister.component';
import { UpdateAttendanceRegisterComponent } from './pages/EMM_Module/Reports/update-attendance-register/update-attendance-register.component';
import { TrainingsublistComponent } from './pages/TrainingModule/trainingsubmaster/trainingsublist/trainingsublist.component';
import { AddtrainingsubComponent } from './pages/TrainingModule/trainingsubmaster/addtrainingsub/addtrainingsub.component';
import { TrainermasterlistComponent } from './pages/TrainingModule/trainermaster/trainermasterlist/trainermasterlist.component';
import { AddtrainermasterComponent } from './pages/TrainingModule/trainermaster/addtrainermaster/addtrainermaster.component';
import { TrainingtypelistComponent } from './pages/TrainingModule/trainingtypemaster/trainingtypelist/trainingtypelist.component';
import { AddtrainingtypeComponent } from './pages/TrainingModule/trainingtypemaster/addtrainingtype/addtrainingtype.component';
import { AnnualtrainpointlistComponent } from './pages/TrainingModule/annualtrainpoint/annualtrainpointlist/annualtrainpointlist.component';
import { AddannualtrainpointComponent } from './pages/TrainingModule/annualtrainpoint/addannualtrainpoint/addannualtrainpoint.component';
import { TrainingtrainerMapComponent } from './pages/TrainingModule/annualtrainpoint/trainingtrainermap/trainingtrainermap.component';
import { TrainingpointmasterlistComponent } from './pages/TrainingModule/trainingpointmaster/trainingpointmasterlist/trainingpointmasterlist.component';
import { AddtrainingpointmasterComponent } from './pages/TrainingModule/trainingpointmaster/addtrainingpointmaster/addtrainingpointmaster.component';
import { TrainingschedulelistComponent } from './pages/TrainingModule/trainingschedule/trainingschedulelist/trainingschedulelist.component';
import { AddtrainingscheduleComponent } from './pages/TrainingModule/trainingschedule/addtrainingschedule/addtrainingschedule.component';
import { TrainattendmapComponent } from './pages/TrainingModule/trainingschedule/trainattendmap/trainattendmap.component';
import { InductionarealistComponent } from './pages/TrainingModule/inductionareamaster/inductionarealist/inductionarealist.component';
import { AddinductionareaComponent } from './pages/TrainingModule/inductionareamaster/addinductionarea/addinductionarea.component';
import { TrainfeedformmasterlistComponent } from './pages/TrainingModule/trainingfeedbackformmaster/trainfeedformmasterlist/trainfeedformmasterlist.component';
import { AddtrainfeedformmasterComponent } from './pages/TrainingModule/trainingfeedbackformmaster/addtrainfeedformmaster/addtrainfeedformmaster.component';
import { TrainingevalutionformlistComponent } from './pages/TrainingModule/trainingevalutionform/trainingevalutionformlist/trainingevalutionformlist.component';
import { AddtrainingevalutionformComponent } from './pages/TrainingModule/trainingevalutionform/addtrainingevalutionform/addtrainingevalutionform.component';
import { TrainfeedmasterlistComponent } from './pages/TrainingModule/trainingfeedbackmaster/trainfeedmasterlist/trainfeedmasterlist.component';
import { AddtrainfeedmasterComponent } from './pages/TrainingModule/trainingfeedbackmaster/addtrainfeedmaster/addtrainfeedmaster.component';
import { TrainattendancecardlistComponent } from './pages/TrainingModule/trainattendancecard/trainattendancecardlist/trainattendancecardlist.component';
import { AddtrainattendancecardComponent } from './pages/TrainingModule/trainattendancecard/addtrainattendancecard/addtrainattendancecard.component';
import { AttendcardComponent } from './pages/TrainingModule/trainattendancecard/attendcard/attendcard.component';
import { TrainerloginviewComponent } from './pages/TrainingModule/trainerlogin/trainerloginview/trainerloginview.component';
import { TrainerloginviewdrawerComponent } from './pages/TrainingModule/trainerlogin/trainerloginviewdrawer/trainerloginviewdrawer.component';
import { AccessorviewComponent } from './pages/TrainingModule/accessor/accessorview/accessorview.component';
import { AccessorviewdrawerComponent } from './pages/TrainingModule/accessor/accessorviewdrawer/accessorviewdrawer.component';
import { AssetRegisterComponent } from './pages/materialIssueModule/Reports/asset-register/asset-register.component';
import { AssetAllocationRegisterComponent } from './pages/materialIssueModule/Reports/asset-allocation-register/asset-allocation-register.component';
import { AssetMaintenanceRegisterComponent } from './pages/materialIssueModule/Reports/asset-maintenance-register/asset-maintenance-register.component';
import { AssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-category-register/asset-category-register.component';
import { AssetRegisterForAssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-register-for-asset-category-register/asset-register-for-asset-category-register.component';
import { TrainerAccessorLoginComponent } from '../app/trainer-accessor-login/trainer-accessor-login.component';
import { ModelMasterComponent } from './pages/SalesModule/model/model-master/model-master.component';
import { ModelDrawerComponent } from './pages/SalesModule/model/model-drawer/model-drawer.component';
import { VarientMasterComponent } from './pages/SalesModule/varient/varient-master/varient-master.component';
import { VarientDrawerComponent } from './pages/SalesModule/varient/varient-drawer/varient-drawer.component';
import { PriceListMasterComponent } from './pages/SalesModule/priceList/price-list-master/price-list-master.component';
import { PriceListDrawerComponent } from './pages/SalesModule/priceList/price-list-drawer/price-list-drawer.component';
import { PriceListMappingComponent } from './pages/SalesModule/priceList/price-list-mapping/price-list-mapping.component';
import { ColorMasterComponent } from './pages/SalesModule/color/color-master/color-master.component';
import { ColorDrawerComponent } from './pages/SalesModule/color/color-drawer/color-drawer.component';
import { EmployeeOfTheMonthComponent } from './pages/EMM_Module/employee-of-the-month/employee-of-the-month.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { SalesModuleReportsComponent } from './pages/SalesModule/Reports/sales-module-reports/sales-module-reports.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { FuelMasterComponent } from './pages/SalesModule/Fuel/fuel-master/fuel-master.component';
import { FuelDrawerComponent } from './pages/SalesModule/Fuel/fuel-drawer/fuel-drawer.component';
import { TransmissionMasterComponent } from './pages/SalesModule/Transmission/transmission-master/transmission-master.component';
import { TransmissionDrawerComponent } from './pages/SalesModule/Transmission/transmission-drawer/transmission-drawer.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { MemoDetailsComponent } from './pages/SalesModule/Reports/memo-details/memo-details.component';
import { AttendanceReportsComponent } from './pages/EMM_Module/Reports/attendance-reports/attendance-reports.component';
import { ChartsModule } from 'ng2-charts';
import { NgxPrintModule } from 'ngx-print';
import { BankListComponent } from './pages/BankMaster/bank-list/bank-list.component';
import { BankDrawerComponent } from './pages/BankMaster/bank-drawer/bank-drawer.component';
import { InsuranceListComponent } from './pages/InsuranceMaster/insurance-list/insurance-list.component';
import { InsuranceDrawerComponent } from './pages/InsuranceMaster/insurance-drawer/insurance-drawer.component';
import { ImageComponent } from './pages/image/image.component';
import { MemoformComponent } from './pages/SalesModule/memoform/memoform.component';
import { MemodetaisforacComponent } from './pages/SalesModule/memodetaisforac/memodetaisforac.component';
import { MemoDetails2Component } from './pages/SalesModule/Reports/memo-details2/memo-details2.component';
// import { FinanceMasterComponent } from './pages/Incentiveforms/Financeincentive/finance-master/finance-master.component';
// import { FinanceMasterFormComponent } from './pages/Incentiveforms/Financeincentive/finance-master-form/finance-master-form.component';
// import { FinancemasteraddComponent } from './pages/Incentiveforms/Financeincentive/financemasteradd/financemasteradd.component';
// import { RateMasterComponent } from './pages/Incentiveforms/Salesincentive/rate-master/rate-master.component';
// import { RateMasterFormComponent } from './pages/Incentiveforms/Salesincentive/rate-master-form/rate-master-form.component';
// import { RatesmapComponent } from './pages/Incentiveforms/Salesincentive/ratesmap/ratesmap.component';
// import { ReportbranchComponent } from './pages/Incentiveforms/Salesincentive/reportbranch/reportbranch.component';
// import { InsuranceTableComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-table/insurance-table.component';
// import { InsuranceMapComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-map/insurance-map.component';
// import { InsuranceFormComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-form/insurance-form.component';
// import { BranchMapComponent } from './pages/Incentiveforms/Insuranceincentive/branch-map/branch-map.component';
// import { RolesmapComponent } from './pages/Incentiveforms/Salesincentive/rolesmap/rolesmap.component';
// import { ModelmapComponent } from './pages/Incentiveforms/Salesincentive/modelmap/modelmap.component';
// import { TablemapComponent } from './pages/Incentiveforms/Salesincentive/tablemap/tablemap.component';
import { ReportPersonComponent } from './pages/employeemaster/report-person/report-person.component';
import { DayWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-attendance-report/day-wise-attendance-report.component";
import { BranchWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/branch-wise-attendance-report/branch-wise-attendance-report.component";
import { DepartmentWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/department-wise-attendance-report/department-wise-attendance-report.component";
import { DesignationWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/designation-wise-attendance-report/designation-wise-attendance-report.component";
import { MonthlyAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/monthly-avg-attendance-report/monthly-avg-attendance-report.component";
import { BranchWiseAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/branch-wise-avg-attendance-report/branch-wise-avg-attendance-report.component";
import { DepartmentWiseAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/department-wise-avg-attendance-report/department-wise-avg-attendance-report.component";
import { DesignationWiseAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/designation-wise-avg-attendance-report/designation-wise-avg-attendance-report.component";
import { DayWiseSuperLate10EmpAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-super-late10-emp-attendance-report/day-wise-super-late10-emp-attendance-report.component";
import { MonthlySuperLate10EmpAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/monthly-super-late10-emp-attendance-report/monthly-super-late10-emp-attendance-report.component";
import { DayWiseLeaveAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-leave-attendance-report/day-wise-leave-attendance-report.component";
import { DayWiseLatemarkAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-latemark-attendance-report/day-wise-latemark-attendance-report.component";
import { GraphicalAttendanceSummaryReportComponent } from "./pages/Attendance Report/AttendanceReports/graphical-attendance-summary-report/graphical-attendance-summary-report.component";
import { Top10DayInMaxDistanceDayWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-in-max-distance-day-wise-attendance-report/top10-day-in-max-distance-day-wise-attendance-report.component";
import { Top10DayInMaxDistanceMonthlyWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-in-max-distance-monthly-wise-attendance-report/top10-day-in-max-distance-monthly-wise-attendance-report.component";
import { Top10DayOutMaxDistanceDayWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-out-max-distance-day-wise-attendance-report/top10-day-out-max-distance-day-wise-attendance-report.component";
import { Top10DayOutMaxDistanceMonthlyWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-out-max-distance-monthly-wise-attendance-report/top10-day-out-max-distance-monthly-wise-attendance-report.component";
import { Top10DayOutMismatchedEmpAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-out-mismatched-emp-attendance-report/top10-day-out-mismatched-emp-attendance-report.component";
import { BranchWiseDiscountReportComponent } from "./pages/SalesModule/SalesReports/branch-wise-discount-report/branch-wise-discount-report.component";
import { BranchWiseSalesSummaryComponent } from "./pages/SalesModule/SalesReports/branch-wise-sales-summary/branch-wise-sales-summary.component";
import { ModelWiseDailyDetailedReportComponent } from "./pages/SalesModule/SalesReports/model-wise-daily-detailed-report/model-wise-daily-detailed-report.component";
import { ModelWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/model-wise-summary-report/model-wise-summary-report.component";
import { SalesExecutiveWiseDetailedReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-detailed-report/sales-executive-wise-detailed-report.component";
import { SalesExecutiveWiseModelWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-model-wise-summary-report/sales-executive-wise-model-wise-summary-report.component";
import { SalesExecutiveWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-summary-report/sales-executive-wise-summary-report.component";
// import { ScheduleFormComponent } from './pages/Schedulemaster/schedule-form/schedule-form.component';
// import { ScheduleMasterComponent } from './pages/Schedulemaster/schedule-master/schedule-master.component';
import { RateMasterComponent } from './pages/Incentiveforms/Salesincentive/rate-master/rate-master.component';
import { RateMasterFormComponent } from './pages/Incentiveforms/Salesincentive/rate-master-form/rate-master-form.component';
import { RatesmapComponent } from './pages/Incentiveforms/Salesincentive/ratesmap/ratesmap.component';
import { ReportbranchComponent } from './pages/Incentiveforms/Salesincentive/reportbranch/reportbranch.component';
import { RolesmapComponent } from './pages/Incentiveforms/Salesincentive/rolesmap/rolesmap.component';
import { ModelmapComponent } from './pages/Incentiveforms/Salesincentive/modelmap/modelmap.component';
// // import { TablemapComponent } from './pages/Incentiveforms/Salesincentive/tablemap/tablemap.component';
// // import { InsuranceTableComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-table/insurance-table.component';
// // import { InsuranceMapComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-map/insurance-map.component';
// // import { InsuranceFormComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-form/insurance-form.component';
// // import { BranchMapComponent } from './pages/Incentiveforms/Insuranceincentive/branch-map/branch-map.component';
import { NewincentiveComponent } from './pages/Incentiveforms/SOTIncentive/newincentive/newincentive.component';
import { NewincentivemappingComponent } from './pages/Incentiveforms/SOTIncentive/newincentivemapping/newincentivemapping.component';
import { NewincentiveMasterformComponent } from './pages/Incentiveforms/SOTIncentive/newincentive-masterform/newincentive-masterform.component';
import { FinanceMasterAdd2Component } from './pages/Incentiveforms/EWIncentive/finance-master-add2/finance-master-add2.component';
import { FinanceMappingComponent } from './pages/Incentiveforms/EWIncentive/finance-mapping/finance-mapping.component';
import { FinanceMasterFrom2Component } from './pages/Incentiveforms/EWIncentive/finance-master-from2/finance-master-from2.component';
import { FinanceMasterFormComponent } from './pages/Incentiveforms/Financeincentive/finance-master-form/finance-master-form.component';
import { FinancemasteraddComponent } from './pages/Incentiveforms/Financeincentive/financemasteradd/financemasteradd.component';
import { FinanceMasterComponent } from './pages/Incentiveforms/Financeincentive/finance-master/finance-master.component';
// import { RatesmapComponent } from './pages/Incentiveforms/Salesincentive/ratesmap/ratesmap.component';
// import { ReportbranchComponent } from './pages/Incentiveforms/Salesincentive/reportbranch/reportbranch.component';
// import { RateMasterFormComponent } from './pages/Incentiveforms/Salesincentive/rate-master-form/rate-master-form.component';
import { TablemapComponent } from './pages/Incentiveforms/Salesincentive/tablemap/tablemap.component';
// import { RolesmapComponent } from './pages/Incentiveforms/Salesincentive/rolesmap/rolesmap.component';
import { InsuranceTableComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-table/insurance-table.component';
import { InsuranceMapComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-map/insurance-map.component';
import { InsuranceFormComponent } from './pages/Incentiveforms/Insuranceincentive/insurance-form/insurance-form.component';
import { BranchMapComponent } from './pages/Incentiveforms/Insuranceincentive/branch-map/branch-map.component';
// import { SupportStaffMappingComponent } from './pages/Incentiveforms/support-staff-mapping/support-staff-mapping.component';
import { AreaFormComponent } from './pages/Area Master/area-form/area-form.component';
import { AreaTableComponent } from './pages/Area Master/area-table/area-table.component';
import { BranchareamapComponent } from './pages/BranchMaster/branchareamap/branchareamap.component';
import { EmprolemapComponent } from './pages/BranchMaster/emprolemap/emprolemap.component';
import { SupportStaffMappingComponent } from './pages/Incentiveforms/Salesincentive/support-staff-mapping/support-staff-mapping.component';
import { BackOfficeAreaReortComponent } from './pages/SalesModule/SalesReports/back-office-area-reort/back-office-area-reort.component';
import { BackofficeareaempComponent } from './pages/SalesModule/SalesReports/backofficeareaemp/backofficeareaemp.component';
import { SalesHeadSummaryReportComponent } from './pages/SalesModule/SalesReports/sales-head-summary-report/sales-head-summary-report.component';
import { SalesExemodalDsReportComponent } from './pages/SalesModule/SalesReports/sales-exemodal-ds-report/sales-exemodal-ds-report.component';
import { SalesExemodalInsuranceReportComponent } from './pages/SalesModule/SalesReports/sales-exemodal-insurance-report/sales-exemodal-insurance-report.component';
import { IncentiveReportComponent } from './pages/SalesModule/SalesReports/incentive-report/incentive-report.component';
import { SalesExecutiveEWComponent } from './pages/SalesModule/SalesReports/sales-executive-ew/sales-executive-ew.component';
import { SalesExecutiveSOTreportComponent } from './pages/SalesModule/SalesReports/sales-executive-sotreport/sales-executive-sotreport.component';
import { BranchWiseSummaryReportComponent } from './pages/SalesModule/SalesReports/branch-wise-summary-report/branch-wise-summary-report.component';
import { EWSalesSummaryReportComponent } from './pages/SalesModule/SalesReports/ewsales-summary-report/ewsales-summary-report.component';
import { SOTSalesSummaryReportComponent } from './pages/SalesModule/SalesReports/sotsales-summary-report/sotsales-summary-report.component';
import { AddScheduleComponent } from './pages/Schedulemaster/add-schedule/add-schedule.component';
import { AssetLogTableComponent } from './pages/materialIssueModule/asset/asset-log-table/asset-log-table.component';
import { BranchesallComponent } from './pages/BranchMaster/SalesMapping/branchesall/branchesall.component';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { AddNewNotificationDrawerComponent } from './pages/NotificationModule/add-new-notification-drawer/add-new-notification-drawer.component';
import { SendEmailDrawerComponent } from './pages/NotificationModule/send-email-drawer/send-email-drawer.component';
import { MemoAssAccComponent } from './pages/SalesModule/Reports/memo-ass-acc/memo-ass-acc.component';
import { MemoforincentiveComponent } from './pages/SalesModule/SalesReports/memoforincentive/memoforincentive.component';
import { VINmemoComponent } from './pages/SalesModule/Reports/vinmemo/vinmemo.component';
import { HPROMISEComponent } from './pages/SalesModule/Reports/hpromise/hpromise.component';
// import { ServiceslubdepartlistComponent } from './pages/Servicemodule/serviceslubdepartmentmaster/serviceslubdepartlist/serviceslubdepartlist.component';
// import { AddserviceslubdepartComponent } from './pages/Servicemodule/serviceslubdepartmentmaster/addserviceslubdepart/addserviceslubdepart.component';
// import { AddRoleServiceDMComponent } from './pages/Servicemodule/serviceslubdepartmentmaster/add-role-service-dm/add-role-service-dm.component';
// import { ListleadCategoryMasterComponent } from './pages/Servicemodule/LeadCategoryMaster/listlead-category-master/listlead-category-master.component';
// import { AddleadCategoryMasterComponent } from './pages/Servicemodule/LeadCategoryMaster/addlead-category-master/addlead-category-master.component';
// import { AdddatabasetablemasterComponent } from './pages/Servicemodule/databasetablemaster/adddatabasetablemaster/adddatabasetablemaster.component';
// import { DatabasetablemasterlistComponent } from './pages/Servicemodule/databasetablemaster/databasetablemasterlist/databasetablemasterlist.component';
import { EmpLeaveMappingComponent } from './pages/New folder/emp-leave-mapping/emp-leave-mapping.component';
import { EmpLeavePolicyMaptableComponent } from './pages/New folder/emp-leave-policy-maptable/emp-leave-policy-maptable.component';
import { EmpTransactionMappingComponent } from './pages/New folder/emp-transaction-mapping/emp-transaction-mapping.component';
import { LeavePolicyMapComponent } from './pages/New folder/Leave Policy/leave-policy-map/leave-policy-map.component';
import { LeavePolicyMasterComponent } from './pages/New folder/Leave Policy/leave-policy-master/leave-policy-master.component';
import { LeavePolicyTableComponent } from './pages/New folder/Leave Policy/leave-policy-table/leave-policy-table.component';
// import { MasterFormComponent } from './pages/New folder/Leave Policy/master-form/master-form.component';
// import { MasterTableComponent } from './pages/New folder/Leave Policy/master-table/master-table.component';
import { LeaveAdjustmentMasterComponent } from './pages/New folder/leave-adjustment-master/leave-adjustment-master.component';
import { LeaveAdjustmentTableComponent } from './pages/New folder/leave-adjustment-table/leave-adjustment-table.component';
import { LeaveYearconfigurationformComponent } from './pages/New folder/leave-yearconfigurationform/leave-yearconfigurationform.component';
import { LeaveYearconfigurationtableComponent } from './pages/New folder/leave-yearconfigurationtable/leave-yearconfigurationtable.component';
import { MasterFormComponent } from './pages/New folder/Leave Policy/master-form/master-form.component';
import { MasterTableComponent } from './pages/New folder/Leave Policy/master-table/master-table.component';
// import { LeadmasterComponent } from './pages/Servicemodule/LeadAndActivity/leadmaster/leadmaster.component';
// import { LeadmastersComponent } from './pages/Servicemodule/LeadAndActivity/leadmasters/leadmasters.component';
// import { ActivityComponent } from './pages/Servicemodule/LeadAndActivity/activity/activity.component';
// import { ActivitiesComponent } from './pages/Servicemodule/LeadAndActivity/activities/activities.component';
import { CustomerWiseMemoDetailedReportComponent } from "./pages/SalesModule/SalesReports/customer-wise-memo-detailed-report/customer-wise-memo-detailed-report/customer-wise-memo-detailed-report.component";
import { MemoDetailedReportComponent } from "./pages/SalesModule/SalesReports/memo-detailed-report/memo-detailed-report/memo-detailed-report.component";
import { MemoWiseDiscountDetailedReportComponent } from "./pages/SalesModule/SalesReports/memo-wise-discount-detailed-report/memo-wise-discount-detailed-report/memo-wise-discount-detailed-report.component";

// import { ActivityTypeListComponent } from './pages/ActivityMaster/ActivityType/activity-type-list/activity-type-list.component';
// import { ActivityTypeformComponent } from './pages/ActivityMaster/ActivityType/activity-typeform/activity-typeform.component';
import { ActivityMasterTableComponent } from './pages/ActivityMaster/ActivityMaster1/activity-master-table/activity-master-table.component';
import { ActivityMasterFormComponent } from './pages/ActivityMaster/ActivityMaster1/activity-master-form/activity-master-form.component';
import { InsuranceComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/insurance/insurance.component';
import { PsfmasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/psfmaster/psfmaster.component';
import { SmrmasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/smrmaster/smrmaster.component';
import { ImportmasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/importmaster/importmaster.component';
import { ExtendwarrentyComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/extendwarrenty/extendwarrenty.component';
// import { ActivityLeadMaster } from './Models/ActivityLeadmaster';
import { ActivityleadmasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/activityleadform/activityleadmaster/activityleadmaster.component';
import { ActivityleadformComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/activityleadform/activityleadform/activityleadform.component';
import { ActionMasterFormComponent } from './pages/ActivityMaster/action-master-form/action-master-form.component';
import { ActionMasterTableComponent } from './pages/ActivityMaster/action-master-table/action-master-table.component';
import { MemoreportFormComponent } from './pages/SalesModule/SalesReports/memoreport-form/memoreport-form.component';
import { SalesExecutiveWiseDetailedDiscountReportComponent } from './pages/SalesModule/SalesReports/sales-executive-wise-detailed-discount-report/sales-executive-wise-detailed-discount-report.component';
import { SalesExecutiveWiseDetailedInvoiceReportComponent } from './pages/SalesModule/SalesReports/sales-executive-wise-detailed-invoice-report/sales-executive-wise-detailed-invoice-report.component';
import { SalesExecutiveWiseDetailedMemoReportComponent } from './pages/SalesModule/SalesReports/sales-executive-wise-detailed-memo-report/sales-executive-wise-detailed-memo-report.component';
import { SalesExecutiveWiseDetailedQuotationReportComponent } from './pages/SalesModule/SalesReports/sales-executive-wise-detailed-quotation-report/sales-executive-wise-detailed-quotation-report.component';
import { QuotationDetailsComponent } from './pages/SalesModule/Reports/quotation-details/quotation-details.component';
import { QuotationDetailedReportComponent } from './pages/SalesModule/SalesReports/quotation-detailed-report/quotation-detailed-report.component';
import { AddinsuranceComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/addinsurance/addinsurance.component';
import { AddPsfMasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/add-psf-master/add-psf-master.component';
import { AddSmrComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/add-smr/add-smr.component';
import { ActivityTypeListComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/ActivityType/activity-type-list/activity-type-list.component';
import { ActivityTypeformComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/ActivityType/activity-typeform/activity-typeform.component';
import { CallcenterexecutivetableComponent } from './pages/ActivityMaster/callcenterexecutivetable/callcenterexecutivetable.component';
import { ModelWiseQuotationDetailedReportScheduleComponent } from './pages/Schedule Reports/model-wise-quotation-detailed-report-schedule/model-wise-quotation-detailed-report-schedule.component';
// new reports 24

import { SalewisedetaileddiscountreportComponent } from './pages/New Reports 24/salewisedetaileddiscountreport/salewisedetaileddiscountreport.component';
import { SalesheadwisediscountsummaryreportComponent } from './pages/New Reports 24/salesheadwisediscountsummaryreport/salesheadwisediscountsummaryreport.component';
import { SalesexecutivewiseinhouseinsurancesummaryreportComponent } from './pages/New Reports 24/salesexecutivewiseinhouseinsurancesummaryreport/salesexecutivewiseinhouseinsurancesummaryreport.component';
import { ModelwisesummaryComponent } from './pages/New Reports 24/modelwisesummary/modelwisesummary.component';
import { ModelwisequotationdetailsComponent } from './pages/New Reports 24/modelwisequotationdetails/modelwisequotationdetails.component';
import { CustwisediscountdetailComponent } from './pages/New Reports 24/custwisediscountdetail/custwisediscountdetail.component';
import { CustwisedetailComponent } from './pages/New Reports 24/custwisedetail/custwisedetail.component';
import { CustomerwiseinvoicedetailedreportComponent } from './pages/New Reports 24/customerwiseinvoicedetailedreport/customerwiseinvoicedetailedreport.component';
import { CustomerwisefinancereportComponent } from './pages/New Reports 24/customerwisefinancereport/customerwisefinancereport.component';
import { CustomerwiseInsurancereportComponent } from './pages/New Reports 24/customerwise-insurancereport/customerwise-insurancereport.component';
import { BranchwiseinhousefinancedetailedreportComponent } from './pages/New Reports 24/branchwiseinhousefinancedetailedreport/branchwiseinhousefinancedetailedreport.component';
import { BranchwiseadvancecollectedreportComponent } from './pages/New Reports 24/branchwiseadvancecollectedreport/branchwiseadvancecollectedreport.component';
import { BankwisememosummaryreportComponent } from './pages/New Reports 24/bankwisememosummaryreport/bankwisememosummaryreport.component';


import { GeneralManagerWiseDiscountSummaryReportComponent } from "./pages/New Reports 25/general-manager-wise-discount-summary-report/general-manager-wise-discount-summary-report.component";
import { DirectorWiseDiscountSummaryReportComponent } from "./pages/New Reports 25/director-wise-discount-summary-report/director-wise-discount-summary-report.component";
import { BranchWiseInhouseInsuraceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-inhouse-insurace-summary-report/branch-wise-inhouse-insurace-summary-report.component";
import { BranchWiseOuthouseInsuraceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-outhouse-insurace-summary-report/branch-wise-outhouse-insurace-summary-report.component";
import { BranchWiseAdvanceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-advance-summary-report/branch-wise-advance-summary-report.component";
import { BranchWiseSalesExecutiveWiseAdvanceDetailedReportComponent } from "./pages/New Reports 25/branch-wise-sales-executive-wise-advance-detailed-report/branch-wise-sales-executive-wise-advance-detailed-report.component";
import { BranchWiseFinanceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-finance-summary-report/branch-wise-finance-summary-report.component";
// import { BranchWiseDiscountSummaryReportComponent } from "../../branch-wise-discount-summary-report/branch-wise-discount-summary-report.component";
// import { SalesExecutiveWiseInhouseInsuraceDetailedReportComponent, SalesExecutiveWiseInhouseInsuraceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-insurace-detailed-report/sales-executive-wise-inhouse-insurace-detailed-report.component";
import { SalesExecutiveWiseInsuranceCompanyWiseInhouseInsuranceSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-insurance-company-wise-inhouse-insurance-summary-report/sales-executive-wise-insurance-company-wise-inhouse-insurance-summary-report.component";
import { SalesExecutiveWiseOuthouseInsuraceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-outhouse-insurace-detailed-report/sales-executive-wise-outhouse-insurace-detailed-report.component";
import { SalesExecutiveWiseInsuranceCompanyWiseOuthouseInsuranceSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-insurance-company-wise-outhouse-insurance-summary-report/sales-executive-wise-insurance-company-wise-outhouse-insurance-summary-report.component";
import { SalesExecutiveWiseFinanceWiseInhouseDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-finance-wise-inhouse-detailed-report/sales-executive-wise-finance-wise-inhouse-detailed-report.component";
import { SalesExecutiveWiseFinanceWiseOuthouseDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-finance-wise-outhouse-detailed-report/sales-executive-wise-finance-wise-outhouse-detailed-report.component";
import { SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent } from "./pages/New Reports 25/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report.component";
import { SalesExecutiveWiseBankWiseOuthouseFinanceSummarydReportComponent } from "./pages/New Reports 25/sales-executive-wise-bank-wise-outhouse-finance-summaryd-report/sales-executive-wise-bank-wise-outhouse-finance-summaryd-report.component";
// import { SalesExecutiveWiseInhouseSummaryReportComponent, SalesExecutiveWiseInhouseSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-summary-report/sales-executive-wise-inhouse-summary-report.component";
import { SalesExecutiveWiseOuthouseSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-outhouse-summary-report/sales-executive-wise-outhouse-summary-report.component";
import { SalesExecutiveWiseRSADetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-rsadetailed-report/sales-executive-wise-rsadetailed-report.component";
import { NewModelWiseSummaryReportComponent } from "./pages/New Reports 25/new-model-wise-summary-report/new-model-wise-summary-report.component";
import { SalesManagerWiseSalesExeModelWiseSummaryReportComponent } from "./pages/New Reports 25/sales-manager-wise-sales-exe-model-wise-summary-report/sales-manager-wise-sales-exe-model-wise-summary-report.component";
import { SalesManagerWiseInsuranceWiseSummaryReportComponent } from "./pages/New Reports 25/sales-manager-wise-insurance-wise-summary-report/sales-manager-wise-insurance-wise-summary-report.component";
import { SalesManagerWiseFinanceWiseSummaryReportComponent } from "./pages/New Reports 25/sales-manager-wise-finance-wise-summary-report/sales-manager-wise-finance-wise-summary-report.component";
import { BankWiseSalesManagerWiseFinanceInhouseSummaryReportComponent } from "./pages/New Reports 25/bank-wise-sales-manager-wise-finance-inhouse-summary-report/bank-wise-sales-manager-wise-finance-inhouse-summary-report.component";
import { DiscountDetailedReportComponent } from "./pages/New Reports 25/discount-detailed-report/discount-detailed-report.component";
import { SalesExecutiveWiseInhouseInsuraceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-insurace-detailed-report/sales-executive-wise-inhouse-insurace-detailed-report.component";
import { SalesExecutiveWiseAdvanceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-advance-detailed-report/sales-executive-wise-advance-detailed-report.component";
import { GeneralManagerWiseDetailedDiscountReportComponent } from "./pages/New Reports 25/general-manager-wise-detailed-discount-report/general-manager-wise-detailed-discount-report.component";
// import { SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent } from "./pages/New Reports 25/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report.component";
import { SalesExecutiveWiseInhouseSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-summary-report/sales-executive-wise-inhouse-summary-report.component";
import { ShiftMappingComponent } from './pages/Shift Module/shift-mapping/shift-mapping.component';
import { ShiftmasterComponent } from './pages/Shift Module/shiftmaster/shiftmaster.component';
import { ShiftmastersComponent } from './pages/Shift Module/shiftmasters/shiftmasters.component';
import { MonthlyLeavereportComponent } from './pages/New Reports 24/monthly-leavereport/monthly-leavereport.component';



import { BranchwisedetailedinvoiceComponent } from "./pages/New Reports 26/Shubham Reports/branchwisedetailedinvoice/branchwisedetailedinvoice.component";
import { BranchwisemodelwisesalesreportComponent } from "./pages/New Reports 26/Shubham Reports/branchwisemodelwisesalesreport/branchwisemodelwisesalesreport.component";
// import { CustomerwiseRSAdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/customerwise-rsadetailedreport/customerwise-rsadetailedreport.component";
import { CustomerwisequotationdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/customerwisequotationdetailedreport/customerwisequotationdetailedreport.component";
import { ModelwisequotationsummaryComponent } from "./pages/New Reports 26/Shubham Reports/modelwisequotationsummary/modelwisequotationsummary.component";
import { ModelwisevarientdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/modelwisevarientdetailedreport/modelwisevarientdetailedreport.component";
import { FinancebankwisesummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/financebankwisesummaryreport/financebankwisesummaryreport.component";
import { InsurancecompanywisesummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/insurancecompanywisesummaryreport/insurancecompanywisesummaryreport.component";
// import { SalesmanagerwisesalesexecutivesummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/salesmanagerwisesalesexecutivesummaryreport/salesmanagerwisesalesexecutivesummaryreport.component";
import { ModelwisepricelistComponent } from "./pages/New Reports 26/Shubham Reports/modelwisepricelist/modelwisepricelist.component";
import { SalesexecutivewiseRSAsummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/salesexecutivewise-rsasummaryreport/salesexecutivewise-rsasummaryreport.component";
import { AshiftmapingComponent } from './pages/employeemaster/ashiftmaping/ashiftmaping.component';

// import { AddshortleaveComponent } from './pages/EMM_Module/Shortleave/addshortleave/addshortleave.component';
import { ShortleavetableComponent } from './pages/EMM_Module/Shortleave/shortleavetable/shortleavetable.component';
import { HeadapproveshortleaveComponent } from './pages/EMM_Module/Shortleave/headapproveshortleave/headapproveshortleave.component';
import { HeadapproveshortleavedrawerComponent } from './pages/EMM_Module/Shortleave/headapproveshortleavedrawer/headapproveshortleavedrawer.component';
import { AddshortleaveComponent } from './pages/EMM_Module/Shortleave/addshortleave/addshortleave.component';
import { HolidayTypeComponent } from './pages/EMM_Module/holiday-type/holiday-type.component';
import { HolidayBranchComponent } from './pages/EMM_Module/holiday-branch/holiday-branch.component';
import { SalesManagerWiseSalesexeModelWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-manager-wise-salesexe-model-wise-summary-report-schedule/sales-manager-wise-salesexe-model-wise-summary-report-schedule.component';
import { SalesManagerWiseInsuranceWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-manager-wise-insurance-wise-summary-report-schedule/sales-manager-wise-insurance-wise-summary-report-schedule.component';
import { SalesManagerWiseFinanceWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-manager-wise-finance-wise-summary-report-schedule/sales-manager-wise-finance-wise-summary-report-schedule.component';
import { BankWiseSalesManagerWiseFinanceInhouseSummaryReportScheduleComponent } from './pages/Schedule Reports/bank-wise-sales-manager-wise-finance-inhouse-summary-report-schedule/bank-wise-sales-manager-wise-finance-inhouse-summary-report-schedule.component';
import { SalesExecutiveWiseRSADetailedReportScheduleComponent } from './pages/Schedule Reports/sales-executive-wise-rsadetailed-report-schedule/sales-executive-wise-rsadetailed-report-schedule.component';
import { SalesExecutiveWiseFinanceWiseInhouseDetailedReportScheduleComponent } from './pages/Schedule Reports/sales-executive-wise-finance-wise-inhouse-detailed-report-schedule/sales-executive-wise-finance-wise-inhouse-detailed-report-schedule.component';
import { NewModelWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/new-model-wise-summary-report-schedule/new-model-wise-summary-report-schedule.component';
import { SalesExecutiveWiseAdvanceDetailedReportScheduleComponent } from './pages/Schedule Reports/sales-executive-wise-advance-detailed-report-schedule/sales-executive-wise-advance-detailed-report-schedule.component';
import { DirectorWiseDiscountSummaryReportScheduleComponent } from './pages/Schedule Reports/director-wise-discount-summary-report-schedule/director-wise-discount-summary-report-schedule.component';
import { BranchWiseInhouseInsuranceSummaryReportScheduleComponent } from './pages/Schedule Reports/branch-wise-inhouse-insurance-summary-report-schedule/branch-wise-inhouse-insurance-summary-report-schedule.component';
import { BranchWiseOuthouseInsuranceSummaryReportScheduleComponent } from './pages/Schedule Reports/branch-wise-outhouse-insurance-summary-report-schedule/branch-wise-outhouse-insurance-summary-report-schedule.component';
import { BranchWiseAdvanceSummaryReportScheduleComponent } from './pages/Schedule Reports/branch-wise-advance-summary-report-schedule/branch-wise-advance-summary-report-schedule.component';
import { BranchWiseSalesExecutiveWiseAdvanceDetailedReportScheduleComponent } from './pages/Schedule Reports/branch-wise-sales-executive-wise-advance-detailed-report-schedule/branch-wise-sales-executive-wise-advance-detailed-report-schedule.component';
import { BranchWiseFinanceSummaryReportScheduleComponent } from './pages/Schedule Reports/branch-wise-finance-summary-report-schedule/branch-wise-finance-summary-report-schedule.component';
import { BranchWiseDiscountSummaryReportScheduleComponent } from './pages/Schedule Reports/branch-wise-discount-summary-report-schedule/branch-wise-discount-summary-report-schedule.component';
import { SalesExeInhouseInsuranceDetailedReportScheduleComponent } from './pages/Schedule Reports/sales-exe-inhouse-insurance-detailed-report-schedule/sales-exe-inhouse-insurance-detailed-report-schedule.component';
import { SalesExeOuthouseInsuranceDetailedReportScheduleComponent } from './pages/Schedule Reports/sales-exe-outhouse-insurance-detailed-report-schedule/sales-exe-outhouse-insurance-detailed-report-schedule.component';
import { SalesExeInsuranceCompanyInhouseInsuranceSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-exe-insurance-company-inhouse-insurance-summary-report-schedule/sales-exe-insurance-company-inhouse-insurance-summary-report-schedule.component';
import { SalesExeInsuranceCompanyOuthouseInsuranceSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-exe-insurance-company-outhouse-insurance-summary-report-schedule/sales-exe-insurance-company-outhouse-insurance-summary-report-schedule.component';
import { SalesExeBankwiseInhouseFinanceSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-exe-bankwise-inhouse-finance-summary-report-schedule/sales-exe-bankwise-inhouse-finance-summary-report-schedule.component';
import { SalesExeBankwiseOuthouseFinanceSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-exe-bankwise-outhouse-finance-summary-report-schedule/sales-exe-bankwise-outhouse-finance-summary-report-schedule.component';
import { SalesInhouseSummaryScheduleComponent } from './pages/Schedule Reports/sales-inhouse-summary-schedule/sales-inhouse-summary-schedule.component';
import { DiscountDetailedReportScheduleComponent } from './pages/Schedule Reports/discount-detailed-report-schedule/discount-detailed-report-schedule.component';
import { SalesExeOutHouseSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-exe-out-house-summary-report-schedule/sales-exe-out-house-summary-report-schedule.component';
import { GeneralManagerWiseDetailedDiscountReportScheduleComponent } from './pages/Schedule Reports/general-manager-wise-detailed-discount-report-schedule/general-manager-wise-detailed-discount-report-schedule.component';
import { GeneralManagerWiseDiscountSummaryReportScheduleComponent } from './pages/Schedule Reports/general-manager-wise-discount-summary-report-schedule/general-manager-wise-discount-summary-report-schedule.component';
import { SalesExecutiveWiseFinanceWiseOuthouseDetailedReportScheduleComponent } from './pages/Schedule Reports/sales-executive-wise-finance-wise-outhouse-detailed-report-schedule/sales-executive-wise-finance-wise-outhouse-detailed-report-schedule.component';
import { SalesExeWiseDetailedQuotationScheduleComponent } from './pages/Schedule Reports/sales-exe-wise-detailed-quotation-schedule/sales-exe-wise-detailed-quotation-schedule.component';
import { SalesExeWiseDetailedDiscountScheduleComponent } from './pages/Schedule Reports/sales-exe-wise-detailed-discount-schedule/sales-exe-wise-detailed-discount-schedule.component';
import { SalesExeWiseDetailedMemoScheduleComponent } from './pages/Schedule Reports/sales-exe-wise-detailed-memo-schedule/sales-exe-wise-detailed-memo-schedule.component';
import { SalesExeWiseDetailedInvoiceScheduleComponent } from './pages/Schedule Reports/sales-exe-wise-detailed-invoice-schedule/sales-exe-wise-detailed-invoice-schedule.component';
import { ScheduleFormComponent } from './pages/Schedule Reports/schedule-form/schedule-form.component';
import { ScheduleMasterComponent } from './pages/Schedule Reports/schedule-master/schedule-master.component';
// import { CustomerwiseRSAdetailedreportComponent } from './pages/New Reports 26/Shubham Reports/salesexecutivewise-rsasummaryreport/customerwise-rsadetailedreport/customerwise-rsadetailedreport.component';
import { CustomerWiseRSADetailedReportScheduleComponent } from './pages/Schedule Reports/customer-wise-rsadetailed-report-schedule/customer-wise-rsadetailed-report-schedule.component';
import { CustomerwiseRSAdetailedreportComponent } from './pages/New Reports 26/Shubham Reports/customerwise-rsadetailedreport/customerwise-rsadetailedreport.component';
import { BranchWiseDetailedInvoiceReportScheduleComponent } from './pages/Schedule Reports/branch-wise-detailed-invoice-report-schedule/branch-wise-detailed-invoice-report-schedule.component';
import { BranchWiseModelWiseSalesReportScheduleComponent } from './pages/Schedule Reports/branch-wise-model-wise-sales-report-schedule/branch-wise-model-wise-sales-report-schedule.component';
import { CustomerWiseQuotationDetailedReportScheduleComponent } from './pages/Schedule Reports/customer-wise-quotation-detailed-report-schedule/customer-wise-quotation-detailed-report-schedule.component';
import { ModelWiseQuotatioSummaryReportScheduleComponent } from './pages/Schedule Reports/model-wise-quotatio-summary-report-schedule/model-wise-quotatio-summary-report-schedule.component';
import { ModelWiseVarientDetailedReportScheduleComponent } from './pages/Schedule Reports/model-wise-varient-detailed-report-schedule/model-wise-varient-detailed-report-schedule.component';
import { FinanceBankWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/finance-bank-wise-summary-report-schedule/finance-bank-wise-summary-report-schedule.component';
import { InsuranceCompanyWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/insurance-company-wise-summary-report-schedule/insurance-company-wise-summary-report-schedule.component';
import { SalesManagerWiseSalesExeWiseWiseSummaryReportScheduleComponent } from './pages/Schedule Reports/sales-manager-wise-sales-exe-wise-wise-summary-report-schedule/sales-manager-wise-sales-exe-wise-wise-summary-report-schedule.component';
import { ModelWisePriceListReportScheduleComponent } from './pages/Schedule Reports/model-wise-price-list-report-schedule/model-wise-price-list-report-schedule.component';
import { SalesExeWiseRSASummaryReportScheduleComponent } from './pages/Schedule Reports/sales-exe-wise-rsasummary-report-schedule/sales-exe-wise-rsasummary-report-schedule.component';
import { SalesheadmemoComponent } from './pages/SalesModule/Reports/salesheadmemo/salesheadmemo.component';
import { PayslipComponent } from './pages/salary_slip/Salary_slip/payslip/payslip.component';
import { SalesreportComponent } from './pages/Reports_UI/salesreport/salesreport.component';

import { InhouseInsuranceReportComponent } from "./pages/Sales Dash report/inhouse-insurance-report/inhouse-insurance-report.component";
import { OuthouseInsuranceReportComponent } from "./pages/Sales Dash report/outhouse-insurance-report/outhouse-insurance-report.component";
import { BranchWiseMemoPendancyReportComponent } from "./pages/Sales Dash report/branch-wise-memo-pendancy-report/branch-wise-memo-pendancy-report.component";
import { BranchWiseMostDiscountApprovalReportComponent } from "./pages/Sales Dash report/branch-wise-most-discount-approval-report/branch-wise-most-discount-approval-report.component";
import { BranchWiseDiscountPendancyReportComponent } from "./pages/Sales Dash report/branch-wise-discount-pendancy-report/branch-wise-discount-pendancy-report.component";
import { MontlymodelwiseinvoicegeneratedComponent } from "./pages/Sales Dash report/montlymodelwiseinvoicegenerated/montlymodelwiseinvoicegenerated.component";
import { MontlybranchwisemodelwiseinvoicegeneratedComponent } from "./pages/Sales Dash report/montlybranchwisemodelwiseinvoicegenerated/montlybranchwisemodelwiseinvoicegenerated.component";
import { InhouseFinanceReportComponent } from "./pages/Sales Dash report/inhouse-finance-report/inhouse-finance-report.component";
import { OuthouseFinanceReportComponent } from "./pages/Sales Dash report/outhouse-finance-report/outhouse-finance-report.component";
import { SalesExecutiveWiseQuotationGeneratedTodayReportComponent } from "./pages/Sales Dash report/sales-executive-wise-quotation-generated-today-report/sales-executive-wise-quotation-generated-today-report.component";

import { LeaveBalancereportComponent } from "./pages/Reports_UI/Leave reports/leave-balancereport/leave-balancereport.component";
import { LeaveTransactionDetailesreportComponent } from "./pages/Reports_UI/Leave reports/leave-transaction-detailesreport/leave-transaction-detailesreport.component";
import { LeaveTransactionSummaryReportComponent } from "./pages/Reports_UI/Leave reports/leave-transaction-summary-report/leave-transaction-summary-report.component";
// import { SalesheadmemoComponent } from './pages/SalesModule/Reports/salesheadmemo/salesheadmemo.component';
// import { SalaryComponent } from './pages/salary_slip/salary/salary.component';
// import { CustomerwiseRSAdetailedreportComponent } from './pages/New Reports 26/Shubham Reports/salesexecutivewise-rsasummaryreport/customerwise-rsadetailedreport/customerwise-rsadetailedreport.component';


// import { VINmemoComponent } from './pages/SalesModule/vinmemo/vinmemo.component';
// import { HPROMISEComponent } from './pages/SalesModule/hpromise/hpromise.component';
// import { EWSalesSummaryReportComponent } from './pages/SalesModule/SalesReports/ewsales-summary-report/ewsales-summary-report.component';
// import { BranchWiseSummaryReportComponent } from './pages/SalesModule/SalesReports/branch-wise-summary-report/branch-wise-summary-report.component';
// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
// import { MemoformComponent } from './pages/SalesModule/Reports/memoform/memoform.component';
import { SalesDashboardComponent } from "./pages/CommonModule/Dashboard/sales-dashboard/sales-dashboard.component";
import { HRDashboardComponent } from "./pages/CommonModule/Dashboard/hrdashboard/hrdashboard.component";
// import { HRDashboardComponent } from "./pages/CommonModule/Dashboard/hrdashboard/hrdashboard.component";
// import { SalesDashboardComponent } from "./pages/CommonModule/Dashboard/sales-dashboard/sales-dashboard.component";
import { MemoWiseReportComponent } from "./pages/Reports_UI/memo-wise-report/memo-wise-report.component";
import { ModelWiseReportComponent } from "./pages/Reports_UI/model-wise-report/model-wise-report.component";
import { IncentiveReportsComponent } from "./pages/Reports_UI/incentive-reports/incentive-reports.component";
import { SalesHeadReportComponent } from "./pages/Reports_UI/sales-head-report/sales-head-report.component";
import { SalesManageReportComponent } from "./pages/Reports_UI/sales-manage-report/sales-manage-report.component";
import { CustomerWiseReportComponent } from "./pages/Reports_UI/customer-wise-report/customer-wise-report.component";
import { BankWiseReportComponent } from "./pages/Reports_UI/bank-wise-report/bank-wise-report.component";
import { GeneralManagerWiseReportComponent } from "./pages/Reports_UI/general-manager-wise-report/general-manager-wise-report.component";
import { DiscountReportComponent } from "./pages/Reports_UI/discount-report/discount-report.component";
import { BranchWiseComponent } from "./pages/Reports_UI/branch-wise/branch-wise.component";
import { BranchwisequotationdetailsComponent } from './pages/New Reports 26/Shubham Reports/branchwisequotationdetails/branchwisequotationdetails.component';
import { BranchWiseFinanceDetailedReportComponent } from './pages/New Reports 25/branch-wise-Outhouse-finance-detailed-report/branch-wise-finance-detailed-report/branch-wise-finance-detailed-report.component';
import { QuotationwisereportsComponent } from './pages/Reports_UI/quotationwisereports/quotationwisereports.component';
import { BankWiseSMWiseFinanceReportComponent } from './pages/New Reports 25/bank-wise-sm-wise-finance-report/bank-wise-sm-wise-finance-report.component';
import { SalesManagerWiseBranchWiseReportComponent } from "./pages/New Reports 24/sales-manager-wise-branch-wise-report/sales-manager-wise-branch-wise-report.component";
import { SalesManagerWiseSalesExecutiveWiseReportComponent } from "./pages/New Reports 24/sales-manager-wise-sales-executive-wise-report/sales-manager-wise-sales-executive-wise-report.component";
import { InsuranceCompanyWiseSmWiseInsuranceReportComponent } from './pages/New Reports 24/insurance-company-wise-sm-wise-insurance-report/insurance-company-wise-sm-wise-insurance-report.component';
import { InsuranceCompanyWiseSmWiseInhouseSummaryReportScheduleComponent } from './pages/New Reports 24/insurance-company-wise-sm-wise-inhouse-summary-report-schedule/insurance-company-wise-sm-wise-inhouse-summary-report-schedule.component';
import { InsurancereportComponent } from './pages/Reports_UI/insurancereport/insurancereport.component';
import { InsuranceCompanyWiseSmWiseInsuranceOuthouseReportComponent } from "./pages/New Reports 24/insurance-company-wise-sm-wise-insurance-outhouse-report/insurance-company-wise-sm-wise-insurance-outhouse-report.component";
import { InsuranceCompanyWiseSmWiseOuthouseSummaryReportScheduleComponent } from './pages/New Reports 24/insurance-company-wise-sm-wise-outhouse-summary-report-schedule/insurance-company-wise-sm-wise-outhouse-summary-report-schedule.component';
import { DetailedReportsComponent } from './pages/Reports_UI/detailed-reports/detailed-reports.component';
import { DetailedHPromiseReportComponent } from './pages/New Reports 24/detailed-hpromise-report/detailed-hpromise-report.component';
import { DetailedVINReportComponent } from './pages/New Reports 24/detailed-vinreport/detailed-vinreport.component';
import { AttendancereportsComponent } from './pages/Reports_UI/attendancereports/attendancereports.component';
import { QuotationComponent } from "./pages/View/quotation/quotation.component";
import { Quotation1Component } from "./pages/View/quotation1/quotation1.component";
import { Quotation2Component } from "./pages/View/quotation2/quotation2.component";
import { InsuranceMemoComponent } from './pages/SalesModule/Reports/insurance-memo/insurance-memo.component';
import { FinanceMemoComponent } from './pages/SalesModule/Reports/finance-memo/finance-memo.component';
import { SalesMemoComponent } from './pages/SalesModule/Reports/sales-memo/sales-memo.component';

import { BranchWiseSalesSummryComponent } from "./pages/Reports_UI/branch-wise-sales-summry/branch-wise-sales-summry.component";
import { ModelWiseSalesSummryComponent } from "./pages/Reports_UI/model-wise-sales-summry/model-wise-sales-summry.component";
import { TransmissionTypeWiseSalesSummaryComponent } from "./pages/Reports_UI/transmission-type-wise-sales-summary/transmission-type-wise-sales-summary.component";
import { FuelTypeWiseSalesSummaryComponent } from "./pages/Reports_UI/fuel-type-wise-sales-summary/fuel-type-wise-sales-summary.component";
import { FinanceuireportComponent } from './pages/Reports_UI/financeuireport/financeuireport.component';
import { DistrictWiseSalesSummryComponent } from './pages/Reports_UI/district-wise-sales-summry/district-wise-sales-summry.component'

// import { DistrictWiseSalesSummryComponent } from './pages/Reports_UI/district-wise-sales-summry/district-wise-sales-summry.component'

// import { TalukaWiseSalesSummryComponent } from './pages/Reports_UI/taluka-wise-sales-summry/taluka-wise-sales-summry.component'
import { BranchwiseinsurancesummaryreportComponent } from "./pages/Report/branchwiseinsurancesummaryreport/branchwiseinsurancesummaryreport.component";
import { AlliedservicesComponent } from "./pages/Report/alliedservices/alliedservices.component";

import { SalesExeWiseModelWiseQuotationsGeneratedReportComponent } from "./pages/Report/sales-exe-wise-model-wise-quotations-generated-report/sales-exe-wise-model-wise-quotations-generated-report.component";
import { SalesExecutiveWiseModelWiseGeneratedReportComponent } from "./pages/Report/sales-executive-wise-model-wise-generated-report/sales-executive-wise-model-wise-generated-report.component";


import { TalukaWiseSalesSummryComponent } from './pages/Reports_UI/taluka-wise-sales-summry/taluka-wise-sales-summry.component'
import { ApptlisttreportComponent } from "./pages/ActivityMaster/S Reports/apptlisttreport/apptlisttreport.component";
import { QuotationViewComponent } from './pages/View/quotation-view/quotation-view.component';
import { Quotation3Component } from "./pages/View/quotation3/quotation3.component";
import { LatemarkreportComponent } from "./pages/EMM_Module/latemarkreport/latemarkreport.component";
import { EarlymarkreportComponent } from "./pages/EMM_Module/earlymarkreport/earlymarkreport.component";
import { BranchWiseDiscountSummaryReportComponent } from './pages/New Reports 25/branch-wise-discount-summary-report/branch-wise-discount-summary-report.component';
import { CallingSummaryReportComponent } from './pages/ActivityMaster/S Reports/calling-summary-report/calling-summary-report.component';
import { KpiReportComponent } from "./pages/ActivityMaster/S Reports/kpi-report/kpi-report.component";
import { CCEassignselfComponent } from './pages/ActivityMaster/cceassignself/cceassignself.component';
import { AcsnewviewComponent } from './pages/SalesModule/Reports/acsnewview/acsnewview.component';

import { DailyTargetReportComponent } from "./pages/ActivityMaster/S Reports/daily-target-report/daily-target-report.component";
import { RetensionSummaryReportComponent } from "./pages/ActivityMaster/S Reports/retension-summary-report/retension-summary-report.component";
import { AppointmentSummaryReportComponent } from "./pages/ActivityMaster/S Reports/appointment-summary-report/appointment-summary-report.component";
import { AllReportsComponent } from "./pages/Reports_UI/all-reports/all-reports.component";
// import { AddleadCategoryMasterComponent } from './pages/Servicemodule/LeadCategoryMaster/addlead-category-master/addlead-category-master.component';

// import { AddleadCategoryMasterComponent } from './pages/Servicemodule/LeadCategoryMaster/addlead-category-master/addlead-category-master.component';
import { AddleadCategoryMasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/LeadCategoryMaster/addlead-category-master/addlead-category-master.component';

import { ListleadCategoryMasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/LeadCategoryMaster/listlead-category-master/listlead-category-master.component';
import { AdddatabasetablemasterComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/databasetablemaster/adddatabasetablemaster/adddatabasetablemaster.component';
import { DatabasetablemasterlistComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/databasetablemaster/databasetablemasterlist/databasetablemasterlist.component';
import { AddRoleServiceDMComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/serviceslubdepartmentmaster/add-role-service-dm/add-role-service-dm.component';
import { AddserviceslubdepartComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/serviceslubdepartmentmaster/addserviceslubdepart/addserviceslubdepart.component';
import { ServiceslubdepartlistComponent } from './pages/ActivityMaster/Servicesss Module/ServiceModule/serviceslubdepartmentmaster/serviceslubdepartlist/serviceslubdepartlist.component';
import { SalesmanagerwisesalesexecutivesummaryreportComponent } from './pages/New Reports 26/Shubham Reports/salesmanagerwisesalesexecutivesummaryreport/salesmanagerwisesalesexecutivesummaryreport.component';
// import { SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent } from './pages/New Reports 25/sales-executive-wise-outhouse-insurace-detailed-report/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report.component';


import { SalesmanagerwisesdetaileddiscountreportComponent } from './pages/New Reports 26/Shubham Reports/salesmanagerwisesdetaileddiscountreport/salesmanagerwisesdetaileddiscountreport.component';
import { NewcustomerComponent } from './pages/ActivityMaster/newcustomer/newcustomer.component';
import { NewcustomersComponent } from './pages/ActivityMaster/newcustomers/newcustomers.component';
import { ServiceadviserComponent } from './pages/ActivityMaster/serviceadviser/serviceadviser.component';
import { ToursComponent } from "./pages/TourModule/TourMaster/tours/tours.component";
import { TourComponent } from './pages/TourModule/TourMaster/tour/tour.component';

import { TourDestinationMasterComponent } from "./pages/TourModule/Tour Destination/tour-destination-master/tour-destination-master.component";
import { TourModeMasterComponent } from "./pages/TourModule/Tour Mode/tour-mode-master/tour-mode-master.component";
import { TourClassMasterComponent } from "./pages/TourModule/Tour Class/tour-class-master/tour-class-master.component";
import { TourDestinationFormComponent } from './pages/TourModule/Tour Destination/tour-destination-form/tour-destination-form.component';
import { TourModeFormComponent } from './pages/TourModule/Tour Mode/tour-mode-form/tour-mode-form.component';
import { TourClassFormComponent } from './pages/TourModule/Tour Class/tour-class-form/tour-class-form.component';
import { TourACComponent } from './pages/TourModule/TourMaster/tour-ac/tour-ac.component';
import { TourHRComponent } from './pages/TourModule/TourMaster/tour-hr/tour-hr.component';
import { AmountmapComponent } from "./pages/TourModule/amountmap/amountmap.component";
import { TourGMComponent } from './pages/TourModule/TourMaster/tour-gm/tour-gm.component';
import { TourExpenseMasterComponent } from './pages/TourModule/Tour Expense/tour-expense-master/tour-expense-master.component';
import { ExpensetourComponent } from './pages/TourModule/Tour Expense/expensetour/expensetour.component';

// import { TourExpenseMasterComponent } from "./pages/TourModule/Tour Expense/tour-expense-master/tour-expense-master.component";
import { TourExpenseHRComponent } from "./pages/TourModule/Tour Expense/tour-expense-hr/tour-expense-hr.component";
import { TourExpenseGMComponent } from "./pages/TourModule/Tour Expense/tour-expense-gm/tour-expense-gm.component";
import { TourExpenseACComponent } from "./pages/TourModule/Tour Expense/tour-expense-ac/tour-expense-ac.component";
import { TourExpenseHeadComponent } from "./pages/TourModule/Tour Expense/tour-expense-head/tour-expense-head.component";
import { TourExpenseFormComponent } from './pages/TourModule/Tour Expense/tour-expense-form/tour-expense-form.component';

import { InactiveEmpReportComponent } from "./pages/EMM_Module/Reports/inactive-emp-report/inactive-emp-report.component";
import { HmilcceComponent } from './pages/ActivityMaster/hmilcce/hmilcce.component';
import { HmiladminComponent } from './pages/ActivityMaster/hmiladmin/hmiladmin.component';
import { HmiladmindrawerComponent } from './pages/ActivityMaster/hmiladmindrawer/hmiladmindrawer.component';

import { RetensionSummaryreport2Component } from './pages/ActivityMaster/S Reports/retension-summaryreport2/retension-summaryreport2.component';
import { DailyVehicleInComponent } from './pages/ActivityMaster/S Reports/daily-vehicle-in/daily-vehicle-in.component';


import { ProductModelReportComponent } from "./pages/Report/product-model-report/product-model-report.component";
import { ProductFuelReportComponent } from "./pages/Report/product-fuel-report/product-fuel-report.component";
import { ProductTransmissionReportComponent } from "./pages/Report/product-transmission-report/product-transmission-report.component";
import { ProductVarientWiseReportComponent } from "./pages/Report/product-varient-wise-report/product-varient-wise-report.component";


import { AlliedSInsuranceSalesReportComponent } from "./pages/Report/allied-sinsurance-sales-report/allied-sinsurance-sales-report.component";
import { AlliedEWSalesReportComponent } from "./pages/Report/allied-ewsales-report/allied-ewsales-report.component";
import { AlliedSOTSalesReportComponent } from "./pages/Report/allied-sotsales-report/allied-sotsales-report.component";
import { AlliedACCESSORIESSalesReportComponent } from "./pages/Report/allied-accessoriessales-report/allied-accessoriessales-report.component";
import { ServiceDiscountSalesReportComponent } from "./pages/Report/service-discount-sales-report/service-discount-sales-report.component";
import { CancellationSummaryReportComponent } from "./pages/Report/cancellation-summary-report/cancellation-summary-report.component";
import { ModificationSummaryreportComponent } from "./pages/Report/modification-summaryreport/modification-summaryreport.component";
import { ExchangeSalesReportComponent } from "./pages/Report/exchange-sales-report/exchange-sales-report.component";
import { DetailesCancellationReportComponent } from "./pages/Report/detailes-cancellation-report/detailes-cancellation-report.component";
import { DetailedModificationReportComponent } from "./pages/Report/detailed-modification-report/detailed-modification-report.component";
import { CorporateSalesReportComponent } from "./pages/Report/corporate-sales-report/corporate-sales-report.component";

import { DetailedNewReportComponent } from "./pages/Report/detailed-new-report/detailed-new-report.component";
import { CallsdestailedreportComponent } from "./pages/ActivityMaster/S Reports/callsdestailedreport/callsdestailedreport.component";


import { PsfleadstableComponent } from "./pages/ActivityMaster/PSF_master/psfleadstable/psfleadstable.component";
import { DessatisfiedleadstableComponent } from "./pages/ActivityMaster/PSF_master/dessatisfiedleadstable/dessatisfiedleadstable.component";
import { PsfleadactivityComponent } from './pages/ActivityMaster/PSF_master/psfleadactivity/psfleadactivity.component';
import { HmilimportComponent } from './pages/ActivityMaster/hmilimport/hmilimport.component';
import { ForlogstableComponent } from './pages/EMM_Module/forlogstable/forlogstable.component';


import { AttendanceadjustmentreportComponent } from "./pages/EMM_Module/Reports/attendanceadjustmentreport/attendanceadjustmentreport.component";
import { PsfsatisleadComponent } from './pages/ActivityMaster/PSF_master/psfsatislead/psfsatislead.component';
import { SMmemoformComponent } from './pages/SalesModule/Reports/smmemoform/smmemoform.component';
import { Quotation44Component } from './pages/View/quotation44/quotation44.component';
import { FeedbackformapproveComponent } from './pages/Feed form/feedbackformapprove/feedbackformapprove.component';
import { FeedbackformapprovesComponent } from './pages/Feed form/feedbackformapproves/feedbackformapproves.component';
import { FeedbackformComponent } from './pages/Feed form/feedbackform/feedbackform.component';
import { FeedbackformsComponent } from './pages/Feed form/feedbackforms/feedbackforms.component';
import { FeedbackAreaComponent } from './pages/Feed form/feedback-area/feedback-area.component';
import { FeedbackAreasComponent } from './pages/Feed form/feedback-areas/feedback-areas.component';
import { EvaluationmappingComponent } from './pages/DesignationMaster/evaluationmapping/evaluationmapping.component';
import { EmployeeCriteriaReportComponent } from "./pages/Feed form/employee-criteria-report/employee-criteria-report.component";

// import { FeedbackformapproveComponent } from './feedbackformapprove/feedbackformapprove.component';
// import { FeedbackformapprovesComponent } from './feedbackformapproves/feedbackformapproves.component';




// import { HmilimportComponent } from './hmilimport/hmilimport.component';

import { FinanceincentiveallocationComponent } from "./pages/Report/financeincentiveallocation/financeincentiveallocation.component";
import { FinanceincentiveallocationsummaryComponent } from "./pages/Report/financeincentiveallocationsummary/financeincentiveallocationsummary.component";

import { UserwiseFeedBackComplaintReportComponent } from "./pages/Report/userwise-feed-back-complaint-report/userwise-feed-back-complaint-report.component";
import { ResolverwiseFeedBackComplaintSummaryReportComponent } from "./pages/Report/resolverwise-feed-back-complaint-summary-report/resolverwise-feed-back-complaint-summary-report.component";
import { UserWiseDetailedReportComponent } from "./pages/Report/user-wise-detailed-report/user-wise-detailed-report.component";
import { BranchWiseFeedbackReportComponent } from "./pages/Report/branch-wise-feedback-report/branch-wise-feedback-report.component";

import { TourreportComponent } from "./pages/TourModule/Reportsfortour/tourreport/tourreport.component";
import { EmployeeevaluationreportComponent } from "./pages/TourModule/Reportsfortour/employeeevaluationreport/employeeevaluationreport.component";


import { LeaveSummarysReportComponent } from "./pages/New folder/leave-summarys-report/leave-summarys-report.component";
import { AsOnDateBalanceReportComponent } from "./pages/New folder/as-on-date-balance-report/as-on-date-balance-report.component";
import { LeaveTransactionDetailedReportComponent } from "./pages/New folder/leave-transaction-detailed-report/leave-transaction-detailed-report.component";

import { ScAndTlIncentiveComponent } from "./pages/Report/sc-and-tl-incentive/sc-and-tl-incentive.component";

import { SMRIncentiveReportComponent } from "./pages/ActivityMaster/S Reports/smrincentive-report/smrincentive-report.component";
import { LeadAptTransferReportComponent } from "./pages/ActivityMaster/S Reports/lead-apt-transfer-report/lead-apt-transfer-report.component";
import { DailyDSIReportReportComponent } from "./pages/ActivityMaster/S Reports/daily-dsireport-report/daily-dsireport-report.component";
import { FeedBackGMApproveTableComponent } from './pages/Feed form/feed-back-gmapprove-table/feed-back-gmapprove-table.component';
import { FeedBackGMApproveComponent } from './pages/Feed form/feed-back-gmapprove/feed-back-gmapprove.component';

import { PSFComplaintDetailesComponent } from "./pages/ActivityMaster/S Reports/psf-complaint-detailes/psf-complaint-detailes.component";
import { SAWisePSFReportComponent } from "./pages/ActivityMaster/S Reports/sa-wise-psf-report/sa-wise-psf-report.component";
import { ViewlogsDetailedCallingComponent } from './pages/ActivityMaster/S Reports/viewlogs-detailed-calling/viewlogs-detailed-calling.component';
import { PsfDetailedCallingReportComponent } from "./pages/ActivityMaster/S Reports/psf-detailed-calling-report/psf-detailed-calling-report.component";
import { BranchMap2Component } from './pages/Incentiveforms/Insuranceincentive/branch-map2/branch-map2.component';
import { InsuranceForm2Component } from './pages/Incentiveforms/Insuranceincentive/insurance-form2/insurance-form2.component';
import { InsuranceMap2Component } from './pages/Incentiveforms/Insuranceincentive/insurance-map2/insurance-map2.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    BranchMap2Component,
    InsuranceForm2Component,
    InsuranceMap2Component,
    PsfDetailedCallingReportComponent,
    ViewlogsDetailedCallingComponent,
    PSFComplaintDetailesComponent,
    SAWisePSFReportComponent,
    SMRIncentiveReportComponent,
    LeadAptTransferReportComponent,
    DailyDSIReportReportComponent,
    ScAndTlIncentiveComponent,
    LeaveSummarysReportComponent,
    AsOnDateBalanceReportComponent,
    LeaveTransactionDetailedReportComponent,
    TourreportComponent,
    EmployeeevaluationreportComponent,
    UserwiseFeedBackComplaintReportComponent,
    ResolverwiseFeedBackComplaintSummaryReportComponent,
    UserWiseDetailedReportComponent,
    BranchWiseFeedbackReportComponent,

    EvaluationmappingComponent,
    FinanceincentiveallocationComponent,
    FinanceincentiveallocationsummaryComponent,
    EmployeeCriteriaReportComponent,

    FeedbackAreaComponent,
    FeedbackAreasComponent,
    FeedbackformComponent,

    FeedbackformsComponent,
    AttendanceadjustmentreportComponent,
    PsfleadactivityComponent,
    PsfleadstableComponent,
    DessatisfiedleadstableComponent,

    CallsdestailedreportComponent,

    DetailedNewReportComponent,
    AlliedSInsuranceSalesReportComponent,
    AlliedEWSalesReportComponent,
    AlliedSOTSalesReportComponent,
    AlliedACCESSORIESSalesReportComponent,
    ServiceDiscountSalesReportComponent,
    CancellationSummaryReportComponent,
    ModificationSummaryreportComponent,
    ExchangeSalesReportComponent,
    DetailesCancellationReportComponent,
    DetailedModificationReportComponent,
    CorporateSalesReportComponent,



    ProductModelReportComponent,
    ProductFuelReportComponent,
    ProductTransmissionReportComponent,
    ProductVarientWiseReportComponent,
    DailyVehicleInComponent,
    RetensionSummaryreport2Component,
    TourExpenseHeadComponent,
    // TourExpenseMasterComponent,
    TourExpenseHRComponent,
    TourExpenseGMComponent,
    TourExpenseACComponent,

    TourExpenseFormComponent,

    AmountmapComponent,
    TourDestinationMasterComponent,
    TourModeMasterComponent,
    TourClassMasterComponent,
    TourClassFormComponent,
    TourModeFormComponent,
    TourDestinationFormComponent,
    TourComponent,
    ToursComponent,
    ModelWiseQuotationDetailedReportScheduleComponent,
    SalesmanagerwisesdetaileddiscountreportComponent,
    AddleadCategoryMasterComponent,
    AllReportsComponent,
    DailyTargetReportComponent,
    AcsnewviewComponent,
    AppointmentSummaryReportComponent,
    RetensionSummaryReportComponent,
    Quotation3Component,
    QuotationViewComponent,
    ApptlisttreportComponent,
    SalesExecutiveWiseModelWiseGeneratedReportComponent,
    SalesExeWiseModelWiseQuotationsGeneratedReportComponent,
    BranchwiseinsurancesummaryreportComponent,
    AlliedservicesComponent,
    TalukaWiseSalesSummryComponent,
    DistrictWiseSalesSummryComponent,
    BranchWiseSalesSummryComponent,
    ModelWiseSalesSummryComponent,
    TransmissionTypeWiseSalesSummaryComponent,
    FuelTypeWiseSalesSummaryComponent,
    SalesMemoComponent,
    FinanceMemoComponent,
    InsuranceMemoComponent,
    QuotationComponent,
    Quotation1Component,
    Quotation2Component,
    DetailedHPromiseReportComponent,
    DetailedVINReportComponent,
    InsuranceCompanyWiseSmWiseOuthouseSummaryReportScheduleComponent,
    InsuranceCompanyWiseSmWiseInsuranceOuthouseReportComponent,
    SalesManagerWiseSalesExecutiveWiseReportComponent,
    SalesManagerWiseBranchWiseReportComponent,
    BankWiseSMWiseFinanceReportComponent,
    BranchWiseComponent,
    DiscountReportComponent,
    GeneralManagerWiseReportComponent,
    BankWiseReportComponent,
    CustomerWiseReportComponent,
    SalesManageReportComponent,
    SalesHeadReportComponent,
    IncentiveReportsComponent,
    ModelWiseReportComponent,
    MemoWiseReportComponent,
    SalesDashboardComponent,
    HRDashboardComponent,
    LeaveBalancereportComponent,
    LeaveTransactionDetailesreportComponent,
    LeaveTransactionSummaryReportComponent,
    InhouseInsuranceReportComponent,
    OuthouseInsuranceReportComponent,
    BranchWiseMemoPendancyReportComponent,
    BranchWiseMostDiscountApprovalReportComponent,
    BranchWiseDiscountPendancyReportComponent,
    MontlymodelwiseinvoicegeneratedComponent,
    OuthouseFinanceReportComponent,
    MontlybranchwisemodelwiseinvoicegeneratedComponent,
    SalesExecutiveWiseQuotationGeneratedTodayReportComponent,

    InhouseFinanceReportComponent,
    AddshortleaveComponent,
    ShortleavetableComponent,
    HeadapproveshortleaveComponent,
    HeadapproveshortleavedrawerComponent,
    BranchwisedetailedinvoiceComponent,
    BranchwisemodelwisesalesreportComponent,
    CustomerwiseRSAdetailedreportComponent,
    CustomerwisequotationdetailedreportComponent,
    ModelwisequotationsummaryComponent,
    ModelwisevarientdetailedreportComponent,
    FinancebankwisesummaryreportComponent,
    InsurancecompanywisesummaryreportComponent,
    SalesmanagerwisesalesexecutivesummaryreportComponent,
    ModelwisepricelistComponent,
    SalesexecutivewiseRSAsummaryreportComponent,
    CustomerWiseRSADetailedReportScheduleComponent,
    BranchWiseDetailedInvoiceReportScheduleComponent,
    BranchWiseModelWiseSalesReportScheduleComponent,
    AppComponent,
    TestComponent,
    SalesreportComponent,
    AddPsfMasterComponent,
    GeneralManagerWiseDiscountSummaryReportComponent,
    DirectorWiseDiscountSummaryReportComponent,
    BranchWiseInhouseInsuraceSummaryReportComponent,
    BranchWiseOuthouseInsuraceSummaryReportComponent,
    BranchWiseAdvanceSummaryReportComponent,
    BranchWiseSalesExecutiveWiseAdvanceDetailedReportComponent,
    BranchWiseFinanceSummaryReportComponent,
    BranchWiseDiscountSummaryReportComponent,
    SalesExecutiveWiseInsuranceCompanyWiseInhouseInsuranceSummaryReportComponent,
    SalesExecutiveWiseOuthouseInsuraceDetailedReportComponent,
    SalesExecutiveWiseInsuranceCompanyWiseOuthouseInsuranceSummaryReportComponent,
    SalesExecutiveWiseFinanceWiseInhouseDetailedReportComponent,
    SalesExecutiveWiseFinanceWiseOuthouseDetailedReportComponent,
    SalesExecutiveWiseBankWiseOuthouseFinanceSummarydReportComponent,
    SalesExecutiveWiseOuthouseSummaryReportComponent,
    SalesExecutiveWiseRSADetailedReportComponent,
    NewModelWiseSummaryReportComponent,
    SalesManagerWiseSalesExeModelWiseSummaryReportComponent,
    SalesManagerWiseInsuranceWiseSummaryReportComponent,
    SalesManagerWiseFinanceWiseSummaryReportComponent,
    BankWiseSalesManagerWiseFinanceInhouseSummaryReportComponent,
    DiscountDetailedReportComponent,
    SalesExecutiveWiseInhouseInsuraceDetailedReportComponent,
    SalesExecutiveWiseAdvanceDetailedReportComponent,
    GeneralManagerWiseDetailedDiscountReportComponent,
    SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent,
    SalesExecutiveWiseInhouseSummaryReportComponent,
    InsuranceCompanyWiseSmWiseInsuranceReportComponent,
    InsuranceCompanyWiseSmWiseInhouseSummaryReportScheduleComponent,

    // AwsbulksenderComponent,
    // AwsemailsComponent,
    // AwstemplateComponent,
    // AwstemplatesComponent,
    // SalesExecutiveEWComponent,
    AddinsuranceComponent,
    QuotationDetailedReportComponent,
    QuotationDetailsComponent,
    CustomerWiseMemoDetailedReportComponent,
    AddScheduleComponent,
    AssetLogTableComponent,
    BranchesallComponent,
    ActionMasterFormComponent,
    ActionMasterTableComponent,
    FeedBackGMApproveTableComponent,
    FeedBackGMApproveComponent,
    // AssetRequestDrawerComponent,
    // BackOfficeAreaReortComponent,
    // PsfmasterComponent,
    MemoWiseDiscountDetailedReportComponent,
    MemoDetailedReportComponent,
    LeaveYearconfigurationtableComponent,
    LeaveYearconfigurationformComponent,
    LeaveAdjustmentTableComponent,
    LeaveAdjustmentMasterComponent,
    MasterFormComponent,
    MasterTableComponent,
    EmpLeaveMappingComponent,
    LeavePolicyMasterComponent,
    LeavePolicyTableComponent,
    LeavePolicyMapComponent,
    EmpLeavePolicyMaptableComponent,
    EmpTransactionMappingComponent,
    ServiceslubdepartlistComponent,
    ListleadCategoryMasterComponent,
    AdddatabasetablemasterComponent,
    DatabasetablemasterlistComponent,

    AddRoleServiceDMComponent,
    AddserviceslubdepartComponent,
    SOTSalesSummaryReportComponent,
    BranchWiseSummaryReportComponent,
    EWSalesSummaryReportComponent,
    // ListleadCategoryMasterComponent,
    // AddleadCategoryMasterComponent,
    SalesExecutiveSOTreportComponent,
    SalesExecutiveEWComponent,
    TransferTicketDrawerComponent,
    FinanceMasterFormComponent,
    FinancemasteraddComponent,
    LoginComponent,
    ApplicationComponent,
    ApplicationsComponent,
    ModuleComponent,
    ModulesComponent,
    ScheduleFormComponent,
    UserComponent,
    UsersComponent,
    ApplicationModuleMappingComponent,
    UserModuleMappingComponent,
    DashboardComponent,
    MemoAssAccComponent,
    RolesComponent,
    RoleComponent,
    RolemodulemappingComponent,
    FaqHeadsComponent,
    FaqHeadComponent,
    FaqsComponent,
    FaqComponent,
    FormsComponent,
    FormComponent,
    RoledetailsComponent,
    SelectpageComponent,
    FaqresponsesComponent,
    DepartmentComponent,
    DepartmentsComponent,
    ImageComponent,
    MemoformComponent,
    MemoDetails2Component,
    FinanceMasterComponent,
    RateMasterComponent,
    InsuranceTableComponent,
    InsuranceMapComponent,
    InsuranceFormComponent,
    BranchMapComponent,
    ReportPersonComponent,
    DayWiseAttendanceReportComponent,
    DesignationWiseAttendanceReportComponent,
    DepartmentWiseAvgAttendanceReportComponent,
    DesignationWiseAvgAttendanceReportComponent,
    DayWiseSuperLate10EmpAttendanceReportComponent,
    MonthlySuperLate10EmpAttendanceReportComponent,
    DayWiseLeaveAttendanceReportComponent,
    DayWiseLatemarkAttendanceReportComponent,
    GraphicalAttendanceSummaryReportComponent,
    Top10DayInMaxDistanceDayWiseAttendanceReportComponent,
    Top10DayInMaxDistanceMonthlyWiseAttendanceReportComponent,
    Top10DayOutMaxDistanceDayWiseAttendanceReportComponent,
    ScheduleMasterComponent,
    MemoreportFormComponent,

    SMmemoformComponent,
    Quotation44Component,
    NewincentiveComponent,

    NewincentiveMasterformComponent,
    NewincentivemappingComponent,
    FinanceMasterAdd2Component,
    FinanceMappingComponent,
    FinanceMasterFrom2Component,

    MonthlyLeavereportComponent,


    // InsurancemasterComponent,
    // InsurancemastertableComponent,
    // InsuranceMapComponent,
    // RateMasterComponent,
    RateMasterFormComponent,
    RatesmapComponent,
    // InsurancemappingComponent,
    ReportbranchComponent,
    BranchWiseAttendanceReportComponent,
    DepartmentWiseAttendanceReportComponent,
    MonthlyAvgAttendanceReportComponent,
    BranchWiseAvgAttendanceReportComponent,
    Top10DayOutMaxDistanceMonthlyWiseAttendanceReportComponent,
    Top10DayOutMismatchedEmpAttendanceReportComponent,
    BranchWiseDiscountReportComponent,
    BranchWiseSalesSummaryComponent,
    ModelWiseDailyDetailedReportComponent,
    ModelWiseSummaryReportComponent,
    SalesExecutiveWiseDetailedReportComponent,
    SalesExecutiveWiseModelWiseSummaryReportComponent,
    SalesExecutiveWiseSummaryReportComponent,
    ShiftMappingComponent,
    ShiftmasterComponent,
    ShiftmastersComponent,

    // FinancemasteraddComponent,
    // FinanceMasterFormComponent,
    // FinanceMasterComponent,
    // // InsurancemappingComponent,
    // InsurancemastertableComponent,
    // DepartmentcalenderComponent,
    SupportStaffMappingComponent,
    BranchareamapComponent,
    EmprolemapComponent,
    AreaTableComponent,
    AreaFormComponent,
    EmailComponent,
    EmailsComponent,
    HistoryComponent,
    SalesExemodalDsReportComponent,
    SalesExemodalInsuranceReportComponent,
    SmsComponent,
    BackOfficeAreaReortComponent,
    BackofficeareaempComponent,
    SmssComponent,
    FolderComponent,
    FoldersComponent,
    TicketsComponent,
    SalesHeadSummaryReportComponent,
    TicketdetailsComponent,
    UserapplicationmappingComponent,
    UsermodulemappingComponent,
    UseraccessmappingComponent,
    UserdepartmentmappingComponent,
    IncentiveReportComponent,
    UserrolemappingComponent,
    TaskComponent,
    TasksComponent,
    ViewhistoryComponent,
    LogmoduleComponent,
    TicketgroupComponent,
    TicketgroupsComponent,
    SMSHistoryComponent,
    ExportDirective,
    TestsComponent,
    ViewbodyComponent,
    MyticketComponent,
    CreateticketComponent,
    ViewchatticketComponent,
    TicketreportComponent,
    SummaryreportComponent,
    UserwisesummaryComponent,
    DashboardreportComponent,
    AwsbulksenderComponent,
    AwstemplateComponent,
    AwsemailsComponent,
    AwstemplatesComponent,
    ChattdetailsicketComponent,
    OrganizationComponent,
    OrganizationsComponent,
    BranchesComponent,
    BranchComponent,
    DesignationComponent,
    DesignationsComponent,
    TickRoleComponent,
    TickRolesComponent,
    FeatureComponent,
    FeaturesComponent,
    HelploginComponent,
    ClustermasterlistComponent,
    AddclustermasterComponent,
    EmployeemasterlistComponent,
    AddemployeemasterComponent,
    AddemployeerolemapComponent,
    EmployeerolemaplistComponent,
    EmploginComponent,
    SearchfaqComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    FaqresponsereportComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    MynotificationsComponent,
    SendnotificationsComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    SharingExistingFolderComponent,
    ViewNotificationDrawerComponent,
    AddNewNotificationDrawerComponent,
    OptionreportComponent,
    SendEmailDrawerComponent,
    ChangePasswordDrawerComponent,
    LeavetypesComponent,
    LeavetypeComponent,
    AddEmployeeLeaveComponent,
    ExpenseheadComponent,
    ExpensesheadComponent,
    AddEmployeeExpensesComponent,
    EvaluationcriteriaComponent,
    EvaluationcriteriasComponent,
    CustomersComponent,
    CustomerComponent,
    HolidaysComponent,
    HolidayTypeComponent,
    HolidayBranchComponent,
    ThoughsComponent,
    ThoughComponent,
    BirthdaysComponent,
    BirthdayComponent,
    AddearlymarkComponent,
    AddlatemarkComponent,
    EmployeeexpensetypemappingComponent,
    LeaveApproveDrawerComponent,
    HeadLeaveApproveDrawerComponent,
    HeadLatemarkApproveDrawerComponent,
    HeadEarlymarkApproveDrawerComponent,
    EmployeecollegemappingComponent,
    AccountantExpensesApproveDrawerComponent,
    HeadExpensesApproveDrawerComponent,
    AttendancereportComponent,
    LeavereportComponent,
    LatemarkreportComponent,
    EarlymarkreportComponent,
    ExpensereportComponent,
    EvaluationreportComponent,
    EarlymarkTilesComponent,
    LatemarkTilesComponent,
    LeaveTilesComponent,
    ExpensesTilesComponent,
    HeadApproveEmployeeLeavesTilesComponent,
    ApproveEmployeeLeavesTilesComponent,
    HeadApproveEmployeeLatemarkTilesComponent,
    HeadApproveEmployeeEarlymarkTilesComponent,
    ExpensesApproveFromHeadTilesComponent,
    ExpensesApproveFromAccountantTilesComponent,
    AttendanceregisterComponent,
    UpdateAttendanceRegisterComponent,
    AssetCategoryListComponent,
    AssetCategoryDrawerComponent,
    AssetListComponent,
    AssetDrawerComponent,
    AssetMappingListComponent,
    AssetMappingDrawerComponent,
    AssetRequestListComponent,
    AssetRequestDrawerComponent,
    TrainingsublistComponent,
    AddtrainingsubComponent,
    TrainermasterlistComponent,
    AddtrainermasterComponent,
    TrainingtypelistComponent,
    AddtrainingtypeComponent,
    AnnualtrainpointlistComponent,
    AddannualtrainpointComponent,
    TrainingtrainerMapComponent,
    TrainingpointmasterlistComponent,
    AddtrainingpointmasterComponent,
    TrainingschedulelistComponent,
    AddtrainingscheduleComponent,
    TrainattendmapComponent,
    InductionarealistComponent,
    AddinductionareaComponent,
    TrainfeedformmasterlistComponent,
    AddtrainfeedformmasterComponent,
    TrainingevalutionformlistComponent,
    AddtrainingevalutionformComponent,
    TrainfeedmasterlistComponent,
    AddtrainfeedmasterComponent,
    TrainattendancecardlistComponent,
    AddtrainattendancecardComponent,
    AttendcardComponent,
    TrainerloginviewComponent,
    TrainerloginviewdrawerComponent,
    AccessorviewComponent,
    AccessorviewdrawerComponent,
    AssetRegisterComponent,
    AssetAllocationRegisterComponent,
    AssetMaintenanceRegisterComponent,
    AssetCategoryRegisterComponent,
    AssetRegisterForAssetCategoryRegisterComponent,
    TrainerAccessorLoginComponent,
    ModelMasterComponent,
    ModelDrawerComponent,
    VarientMasterComponent,
    VarientDrawerComponent,
    PriceListMasterComponent,
    PriceListDrawerComponent,
    PriceListMappingComponent,
    ColorMasterComponent,
    ColorDrawerComponent,
    EmployeeOfTheMonthComponent,

    SalesModuleReportsComponent,
    FuelMasterComponent,
    FuelDrawerComponent,
    TransmissionMasterComponent,
    TransmissionDrawerComponent,
    MemoDetailsComponent,
    AttendanceReportsComponent,
    BankListComponent,
    BankDrawerComponent,
    InsuranceListComponent,
    InsuranceDrawerComponent,
    MemodetaisforacComponent,
    RolesmapComponent,
    ModelmapComponent,
    TablemapComponent,
    MemoforincentiveComponent,
    VINmemoComponent,
    HPROMISEComponent,
    // LeadmasterComponent,
    // LeadmastersComponent,
    // ActivityComponent,
    // ActivitiesComponent,
    ActivityTypeListComponent,
    ActivityTypeformComponent,
    ActivityMasterTableComponent,
    ActivityMasterFormComponent,
    SalesExecutiveWiseDetailedQuotationReportComponent,
    SalesExecutiveWiseDetailedDiscountReportComponent,
    SalesExecutiveWiseDetailedMemoReportComponent,
    SalesExecutiveWiseDetailedInvoiceReportComponent,
    ImportmasterComponent,
    InsuranceComponent,
    PsfmasterComponent,
    SmrmasterComponent,
    ExtendwarrentyComponent,
    AddSmrComponent,
    ActivityleadmasterComponent,
    ActivityleadformComponent,
    // AddleadCategoryMasterComponent,
    CallcenterexecutivetableComponent,
    CCEassignselfComponent,
    CallingSummaryReportComponent,
    KpiReportComponent,

    // new reports 24
    SalewisedetaileddiscountreportComponent,
    SalesheadwisediscountsummaryreportComponent,
    SalesexecutivewiseinhouseinsurancesummaryreportComponent,
    ModelwisesummaryComponent,
    ModelwisequotationdetailsComponent,
    CustwisediscountdetailComponent,
    CustwisedetailComponent,
    CustomerwiseinvoicedetailedreportComponent,
    CustomerwisefinancereportComponent,
    CustomerwiseInsurancereportComponent,
    BranchwiseinhousefinancedetailedreportComponent,
    BranchwiseadvancecollectedreportComponent,
    BankwisememosummaryreportComponent,
    AshiftmapingComponent,
    BranchwisequotationdetailsComponent,
    BranchWiseFinanceDetailedReportComponent,

    //for schedule 

    SalesExeWiseDetailedQuotationScheduleComponent,

    SalesExeWiseDetailedDiscountScheduleComponent,

    SalesExeWiseDetailedMemoScheduleComponent,

    SalesExeWiseDetailedInvoiceScheduleComponent,

    SalesManagerWiseSalesexeModelWiseSummaryReportScheduleComponent,

    SalesManagerWiseInsuranceWiseSummaryReportScheduleComponent,

    SalesManagerWiseFinanceWiseSummaryReportScheduleComponent,

    BankWiseSalesManagerWiseFinanceInhouseSummaryReportScheduleComponent,

    SalesExecutiveWiseRSADetailedReportScheduleComponent,
    ModelWisePriceListReportScheduleComponent,
    SalesExeWiseRSASummaryReportScheduleComponent,

    SalesExecutiveWiseFinanceWiseInhouseDetailedReportScheduleComponent,
    SalesManagerWiseSalesExeWiseWiseSummaryReportScheduleComponent,
    FinanceBankWiseSummaryReportScheduleComponent,
    InsuranceCompanyWiseSummaryReportScheduleComponent,
    NewModelWiseSummaryReportScheduleComponent,
    SalesExecutiveWiseAdvanceDetailedReportScheduleComponent,
    DirectorWiseDiscountSummaryReportScheduleComponent,
    BranchWiseInhouseInsuranceSummaryReportScheduleComponent,
    BranchWiseOuthouseInsuranceSummaryReportScheduleComponent,
    ModelWiseVarientDetailedReportScheduleComponent,
    ModelWiseQuotatioSummaryReportScheduleComponent,
    CustomerWiseQuotationDetailedReportScheduleComponent,
    BranchWiseAdvanceSummaryReportScheduleComponent,
    BranchWiseSalesExecutiveWiseAdvanceDetailedReportScheduleComponent,
    BranchWiseFinanceSummaryReportScheduleComponent,
    BranchWiseDiscountSummaryReportScheduleComponent,
    SalesExeInhouseInsuranceDetailedReportScheduleComponent,
    SalesExeOuthouseInsuranceDetailedReportScheduleComponent,
    SalesExeInsuranceCompanyInhouseInsuranceSummaryReportScheduleComponent,
    SalesExeInsuranceCompanyOuthouseInsuranceSummaryReportScheduleComponent,
    SalesExeBankwiseInhouseFinanceSummaryReportScheduleComponent,
    SalesExeBankwiseOuthouseFinanceSummaryReportScheduleComponent,
    SalesInhouseSummaryScheduleComponent,
    SalesExeOutHouseSummaryReportScheduleComponent,
    DiscountDetailedReportScheduleComponent,
    GeneralManagerWiseDetailedDiscountReportScheduleComponent,
    GeneralManagerWiseDiscountSummaryReportScheduleComponent,
    SalesExecutiveWiseFinanceWiseOuthouseDetailedReportScheduleComponent,
    SalesheadmemoComponent,
    PayslipComponent,
    QuotationwisereportsComponent,
    InsurancereportComponent,
    DetailedReportsComponent,
    AttendancereportsComponent,
    FinanceuireportComponent,
    NewcustomerComponent,
    NewcustomersComponent,
    ServiceadviserComponent,
    TourACComponent,
    TourHRComponent,
    TourGMComponent,
    TourExpenseMasterComponent,

    ExpensetourComponent,
    InactiveEmpReportComponent,
    HmilcceComponent,
    HmiladminComponent,
    HmiladmindrawerComponent,
    HmilimportComponent,
    // SalaryComponent,
    ForlogstableComponent,
    PsfsatisleadComponent,
    FeedbackformapproveComponent,
    FeedbackformapprovesComponent

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NzSwitchModule,
    NgxPrintModule,
    NgxQRCodeModule,
    AngularFireModule.initializeApp(environment.firebase),
    NzCollapseModule,
    NzInputModule,
    ChartsModule,
    // NgIdleKeepaliveModule.forRoot(),
  ],

  // imports:[
  //   BrowserModule,
  //   AppRoutingModule,
  //   IconsProviderModule,
  //   NgZorroAntdModule,
  //   FormsModule,
  //   HttpClientModule,
  //   BrowserAnimationsModule,
  //   AngularFireDatabaseModule,
  //   AngularFireAuthModule,
  //   AngularFireMessagingModule,
  //   NzSwitchModule,
  //   NgxPrintModule,
  //   NgxQRCodeModule,
  //   NgxQRCodeModule,
  //     AngularFireModule.initializeApp(environment.firebase),
  //     NzCollapseModule,
  //     NzInputModule,
  //     ChartsModule,
  // ],

  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService, AsyncPipe, DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule { }
