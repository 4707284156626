import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as xlsx from 'xlsx';
import { differenceInCalendarDays, setHours } from 'date-fns';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-insurance-company-wise-sm-wise-insurance-report',
  templateUrl: './insurance-company-wise-sm-wise-insurance-report.component.html',
  styleUrls: ['./insurance-company-wise-sm-wise-insurance-report.component.css']
})
export class InsuranceCompanyWiseSmWiseInsuranceReportComponent implements OnInit {
  columns: string[][] = [
    ['SALES_MANAGER_NAME', 'Sales Manager'],
    ['INSURANCE_NAME', 'Insurance Name'],
    ['NO_OF_INSURANCE', 'No. Of Insurance'],
    ['BRANCH_NAME', 'Branch Name'],

  ]
  DATE: string;
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  // startValue:any = new Date();
  // endValue: any = new Date();
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Insurance Company wise Sales Manager Wise Insurance Inhouse Summary Report"
  startValue: any = new Date()
  endValue: any = new Date()
  isSpinning = false;
  salesExe: any[] = []
  salesExe2 = []
  SALES_MANAGER_NAME = [];
  totalRecords = []
  filterQuery: string = ''
  BRANCH_ID = [];
  SALES_MANAGER_ID = []
  BRANCH_NAME = [];

  // BRANCH_ID1
  employee = []
  branches = []
  orgId = this.cookie.get('orgId');
  STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  SALES_CONSULTANT_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";

  constructor(private api: ApiService, private cookie: CookieService, public datePipe: DatePipe,
    private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search()
    this.getBranches()
    this.getSalesExecutives();

    this.getEmployee()
  }
  getBranches() {
    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.branches = data['data']
    })
  }
  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=29').subscribe(data => {
      this.employee = data['data']
    })
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  salesExeData = [];
  START_DATE: any
  END_DATE: any
  search(reset: boolean = false, exportexcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);
    var likeQuery = "";

    if (this.searchText != '') {
      likeQuery = ' AND(';
      this.columns.forEach((column) => {
        if (column[0] == "BRANCH_NAME") {
          likeQuery += ' ' + "BRANCH_NAME" + " like '%" + this.searchText + "%' OR";
        }
        else if (column[0] == "SALES_MANAGER_NAME") {
          likeQuery += ' ' + "SALES_MANAGER_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length) + ")";
    }

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_CONSULTANT_ID != undefined && this.SALES_CONSULTANT_ID > 0) {
      this.SALES_CONSULTANT_ID = this.SALES_CONSULTANT_ID
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (exportexcel == false) {
      this.loadingRecords = true;
      this.api.getInsuranceSalesManagerWiseInhouseReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.BRANCH_ID, this.SALES_CONSULTANT_ID, this.START_DATE, this.END_DATE).subscribe(data => {
        this.loadingRecords = false;
        this.salesExe = data['data']
        this.totalRecords = data['count']
        console.log(this.salesExe)

      });
    }
    else {
      this.exportLoading = true;
      this.api.getInsuranceSalesManagerWiseInhouseReport(0, 0, this.sortKey, sort, likeQuery + this.filterQuery, this.BRANCH_ID, this.SALES_CONSULTANT_ID, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.salesExe = data['data'];
            this.exportexcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  endOpen = false;
  // disabledStartDate = (startValue: Date): boolean => {
  //   if (!startValue || !this.endValue) {
  //     return false;
  //   }
  //   return startValue.getTime() > this.endValue.getTime();
  // };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };
  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_CONSULTANT_ID != undefined && this.SALES_CONSULTANT_ID) {
      this.SALES_CONSULTANT_ID = this.SALES_CONSULTANT_ID
    }
    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    this.search();

    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.BRANCH_ID = []
    this.SALES_CONSULTANT_ID = []
    this.startValue = new Date()
    this.endValue = new Date()
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
   
    // if (this.startValue != null) {
    //   this.START_DATE = this.startValue
    // }

    // if (this.endValue != null) {
    //   this.END_DATE = this.endValue
    // }
    this.search()
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let BranchName = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        BranchName.push(this.branches[i]["ID"]);
      }
    } else {
      BranchName = [];
    }
    this.BRANCH_ID = BranchName;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL_MANAGER: boolean = false;

  onSelectAllManager(event) {
    this.SELECT_ALL_MANAGER = event;
    let empid = [];
    if (this.SELECT_ALL_MANAGER == true) {
      for (var i = 0; i < this.employee.length; i++) {
        empid.push(this.employee[i]["EMPLOYEE_ID"]);
      }
    } else {
      empid = [];
    }
    this.SALES_CONSULTANT_ID = empid;
  }
  onSelectOffManager(event) {
    var a = this.employee.length;
    var b = this.employee.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL_MANAGER = false;
    } else {
      this.SELECT_ALL_MANAGER = true;
    }
    this.SALES_CONSULTANT_ID = event;
    if (this.SALES_CONSULTANT_ID.length == 0) {
      this.SELECT_ALL_MANAGER = false;
    }
  }
  salesExecutives = [];

  getSalesExecutives() {
    this.salesExecutives = [];

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" +
        this.orgId +
        " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
        this.cookie.get("orgId") +
        " AND ROLE_ID=28)"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.salesExecutives = data["data"];
            console.log(this.salesExecutives);

          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }


  fileName = 'insurance-company-wise-sm-wise-insurance-report.xlsx';

  // today = new Date();
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;



  exportexcel(): void {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  FILTER_QUERY: any
  members: any = []

  AddSchedule() {
    this.isVisible1 = true
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    var obj = {
      BRANCH_ID: this.BRANCH_ID,
      MONTH: this.MONTH,
      YEAR: this.YEAR,
      SALES_CONSULTANT_ID: this.SALES_CONSULTANT_ID,
      ORG_ID: this.orgId
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    this.api.getAllMembers(0, 0, '', '', ' AND REPORT_ID = 44').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  // importInExcel(): void {
  //   this.loadingRecords = true;/* pass here the table id */

  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Sales Executive Wise Model Wise Detailed Direct Sales Report');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }

  // convertInExcel() {
  //   var arry1 = [];
  //   var obj1: any = new Object();
  //   for (var i = 0; i < this.salesExeData.length; i++) {
  //     obj1['Branch Name'] = this.salesExeData[i]['BRANCH_NAME'];
  //     obj1['Sales Executive Name'] = this.salesExeData[i]['SALES_CONSULTANT_NAME'];
  //     obj1['Sales Date'] = this.datePipe.transform(this.salesExeData[i]['SALES_DATE'], 'yyyy-MM-dd');
  //     obj1['Model Name'] = this.salesExeData[i]['MODEL_NAME'];
  //     obj1['Variant Name'] = this.salesExeData[i]['VARIENT_NAME'];
  //     obj1['Fuel Type Name'] = this.salesExeData[i]['FUEl_TYPE_NAME'];
  //     obj1['Transmission Type Name'] = this.salesExeData[i]['TRANSMISSION_TYPE_NAME'];
  //     obj1['Customer Name'] = this.salesExeData[i]['CUSTOMER_NAME'];
  //     obj1['SE Incentive'] = this.salesExeData[i]['SALES_CONSULTANT_RATE'];
  //     obj1['SM Name'] = this.salesExeData[i]['SALES_MANAGER_NAME'];
  //     obj1['SM Incentive'] = this.salesExeData[i]['SALES_MANAGER_RATE'];
  //     obj1['SH Incentive'] = this.salesExeData[i]['SALES_HEAD_NAME'];

  //          arry1.push(Object.assign({}, obj1));
  //     if (i == this.salesExeData.length - 1) {
  //       this._exportService.exportExcel(arry1, 'Sales Executive Wise Model Wise Detailed Direct Sales Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  //     }
  //   }
  // }
}
