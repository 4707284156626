import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TourMaster } from 'src/app/Models/tourmaster';
@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: TourMaster;
  @Input() drawerVisible: boolean;
  @Input() HEAD_STATUS: boolean
  @Input() HR_STATUS: boolean
  @Input() GM_STATUS: boolean
  @Input() ACCOUNTANT_STATUS: boolean

  orgId = this.cookie.get('orgId');
  ROLE_ID = Number(this.cookie.get('roleId'))

  selectedRoleStatus = this.cookie.get('selectedLoginType');

  HEAD_APPROVED_DATETIME = new Date()
  HR_APPROVED_DATETIME = new Date()
  ACCOUNTANT_APPROVED_DATETIME = new Date()
  d1: any
  d2: any
  d3: any
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    //     this.getTourMaster()
    //     this.loadDataFilter()
    //     this.loadDataFilter1()
    //     this.loadDataFilter2()

    //     this.loadDataFilter3()
    // console.log(this.switchChange1(this.d1))

  }

  categories = [];
  employees = []
  data1() {
    if (this.data.HR_STATUS == "Approved") {
      this.data.HR_STATUS = "AP"
    }
    else {
      this.data.HR_STATUS = "R"
    }
  }

  switchChange2(e1: boolean) {
    this.HR_STATUS = e1;
    if (this.HR_STATUS = true) {
      this.data.HR_STATUS = "AP"

    }
    else {
      this.data.HR_STATUS = "R"

    }

  }

  switchChange1(e1: boolean) {
    this.HEAD_STATUS = e1;
    if (this.HEAD_STATUS = true) {
      this.data.HEAD_STATUS = "AP"

    }
    else if (this.HEAD_STATUS = false) {
      this.data.HEAD_STATUS = "R"

    }
    console.log()
  }

  // ACCOUNTANT_STATUS

  loadDataFilter() {
    this.employees = [];

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(employees => {
      if (employees['code'] == 200) {
        this.employees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  modes = []
  loadDataFilter1() {
    this.modes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.modes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  classes = []
  loadDataFilter2() {
    this.classes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.classes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  designations = []
  loadDataFilter3() {
    this.designations = [];

    this.api.getAllEmployees(0, 0, 'NAME ', 'asc', ' AND ORG_ID =' + this.orgId).subscribe(employees => {
      if (employees['code'] == 200) {
        this.designations = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  FROM_TIME: Date | null = null;
  TO_TIME: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
    this.rejectbutton = true
    this.forward = true
    this.submitbutton = true
  }
  TypeDetails = []
  getTourMaster() {

    this.api.getAllTour(0, 0, '', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.TypeDetails = data['data'];

      }

    }, err => {
      console.log(err);
    });
  }
  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  rejectbutton: boolean = true
  forward: boolean = true

  remarkchange() {
    this.rejectbutton = false
    this.forward = false
  }
  cancel(){

  }

  reject(myForm: NgForm) {

    var isOk = true;
    // this.switchChange1(this.d1)
    this.switchChange2(this.d2)
    // this.switchChange3(this.d3)


    if (this.ROLE_ID != 14 && this.ROLE_ID != 13) {


      if (this.data.HEAD_REMARK == undefined || this.data.HEAD_REMARK == null || this.data.HEAD_REMARK == '' ) {
        isOk = false;
        this.message.error("Please Enter Head Remark", "");
      } else {
        this.data.HEAD_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");
        this.data.HEAD_STATUS = 'R'
      }


    }

     if (isOk) {
      console.log(this.data);
      this.isSpinning = true;
      this.data.STATUS = 'R'

      this.data.FROM_DATE = this.datePipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
      this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');
      // this.data.EMPLOYEE_ID = this.api.userId;
      this.data.TO_TIME = this.data.TO_TIME
      this.data.FROM_TIME = this.data.FROM_TIME

      console.log(this.data);
      this.isSpinning = true;



      // if(this.ROLE_ID ==14){            
      //   if(this.data.STATUS=="AP"){
      //     this.data.STATUS="FW"
      //   }
      if (this.data.ID) {
        this.api.updateTour(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {

            this.message.warning("Tour Rejected", "");

            // if (!addNew)
            //  
            this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Tour  Rejected  Failed", "");
            this.isSpinning = false;
          }
        });

      }
      // }

      this.isSpinning = true;

    }

  }

  isSpinning = false;
  requestedData: any
  date1 = new Date()

  isApproveVisible: Boolean = false;
  isApproveModalConfirmLoading = false
  openApproveModal() {

    var isOk = true;
    if (this.GM_STATUS == true) {
      if (this.data.GM_REMARK == undefined) {
        isOk = false;
        this.message.error("Please Enter GM Remark", "");
      }
      else {
        this.getsalesHierarchy()
        this.isApproveVisible = true;
      }
    }
  }
  handleApproveCancel() {
    this.isApproveVisible = false;
  }


  isApproveVisibleSH = false
  isApproveModalConfirmLoadingSH = false
  SH_REMARK = ''
  submitbutton: boolean = true

  paymentstatus() {
    this.submitbutton = false
  }
  openApproveModalsahead() {
    var isOk = true;
    if (this.HEAD_STATUS == true) {
      if (this.data.HEAD_REMARK == '' || this.data.HEAD_REMARK == undefined) {
        isOk = false;
        this.message.error("Please Enter Head Remark", "");
      }
      else {
        this.getsalesHierarchySH()
        this.isApproveVisibleSH = true;
      }
    }

  }

  handleApproveCancelSH() {
    this.isApproveVisibleSH = false;
    this.approveforward = true
  }

  approveforward: boolean = true
  forwardtodo() {
    this.approveforward = false
  }
  APPROVER_ID: any
  save(myForm: NgForm): void {
    var isOk = true;
    // this.switchChange1(this.d1)
    // this.switchChange2(this.d2)


    // this.switchChange3(this.d3)
   


    if (this.ROLE_ID != 14 && this.ROLE_ID != 13 && this.ROLE_ID != 30) {
      if (this.HEAD_STATUS == true) {

        if (this.APPROVER_ID == undefined || this.APPROVER_ID == 0 || this.APPROVER_ID == null ) {
          isOk = false;
          this.message.error("Please select Approver name ", "");
        }
        this.data.HEAD_STATUS = 'AP'
        this.data.HEAD_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");

      }
      // else {
      //   this.data.HEAD_STATUS = 'R'
      // }
    }

    // else if (this.ROLE_ID == 14) {
    //   if (this.HR_STATUS == true) {
    //     if (this.data.HR_REMARK == undefined) {
    //       isOk = false;
    //       this.message.error("Please Enter HR Remark", "");
    //     }
    //     this.data.HR_STATUS = 'AP'
    //     this.data.HR_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");

    //   }
    //   else {
    //     this.data.HR_STATUS = 'R'
    //   }
    // }

    else if (this.ROLE_ID == 13) {
      if (this.ACCOUNTANT_STATUS == true) {
        if (this.data.PAYMENT_STATUS == undefined ||this.data.PAYMENT_STATUS == null || this.data.PAYMENT_STATUS == ''   ) {
          isOk = false;
          this.message.error("Please select payment status ", "");
        }
        this.data.ACCOUNTANT_STATUS = "AP"
        this.data.ACCOUNTANT_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");

      }
      else {
        this.data.ACCOUNTANT_STATUS = 'R'
      }
    }

    // else if (this.ROLE_ID == 30) {
    //   if (this.GM_STATUS == true) {
    //     if (this.data.GM_REMARK == undefined) {
    //       isOk = false;
    //       this.message.error("Please Enter GM Remark", "");
    //     }
    //     this.data.GM_STATUS = "AP"
    //     this.data.GM_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");

    //   }
    //   else {
    //     this.data.GM_STATUS = 'R'
    //   }
    // }

    if (this.ROLE_ID == 13 && this.ACCOUNTANT_STATUS == true) {
      this.data.STATUS = 'AP'
    }
    else {
      this.data.STATUS = 'FW'
    }

    if (isOk) {
      console.log(this.data);
      this.isSpinning = true;
   
      this.data.FROM_DATE = this.datePipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
      this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');
      // this.data.EMPLOYEE_ID = this.api.userId;
      this.data.TO_TIME = this.data.TO_TIME
      this.data.FROM_TIME = this.data.FROM_TIME

      if (this.APPROVER_ID != null) {
        this.data.APPROVER_ID = this.APPROVER_ID
      } else {
        this.data.APPROVER_ID = this.data.APPROVER_ID
      }


      console.log(this.data);
      this.isSpinning = true;



      // if(this.ROLE_ID ==14){            
      //   if(this.data.STATUS=="AP"){
      //     this.data.STATUS="FW"
      //   }
      if (this.data.ID) {
        this.isApproveModalConfirmLoadingSH = true
        this.api.updateTour(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoadingSH = false
            console.log(this.data.STATUS)
            // if(this.data.STATUS != "R"){

            this.message.success("Tour Updated Successfully", "");
            // }
            // else{
            //   this.message.warning("Tour Reject Successfully", "");
            // }

            this.isApproveVisibleSH = false
            this.isApproveVisible = false
            this.close(myForm);
            this.isSpinning = false;
          } else {
            this.message.error("Tour to appprove  Failed", "");
            this.isSpinning = false;
            this.isApproveVisibleSH = false
            this.isApproveVisible = false
          }
        });

      }
      // }

      this.isSpinning = true;

    }
  }

  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: Date): void {
    console.log('onOk', result);
  }

  saleHierarchy5 = []
  clusterId = Number(this.cookie.get('clusterId'));
  getsalesHierarchySH() {

    var filterquery

    filterquery = ' AND ROLE_ID IN (13) '

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", filterquery + " AND CLUSTER_ID =" + this.clusterId).subscribe(data => {

        if (data["code"] == 200) {
          this.saleHierarchy5 = data["data"];
        }

        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      });

  }


  saleHierarchy: any = []

  getsalesHierarchy() {

    var filterquery = ''
    filterquery = ' AND ROLE_ID IN (13) '

    this.api.getAllEmpRoleMap(0, 0, "ID", "asc", filterquery).subscribe(data => {

      if (data["code"] == 200) {
        this.saleHierarchy = data["data"];
      }

      (err) => {
        if (err["ok"] == false) {
        } this.message.error("Server Not Found", "");

      }
    });

  }

}

