import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { LeadCateogaryMaster } from 'src/app/Models/LeadCateogaryMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addlead-category-master',
  templateUrl: './addlead-category-master.component.html',
  styleUrls: ['./addlead-category-master.component.css']
})
export class AddleadCategoryMasterComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: LeadCateogaryMaster = new LeadCateogaryMaster;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  // organizations: OrganizationMaster[];
  // roles: RoleMaster[];
  // selectedRole:RoleMaster;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.selectedRole=new RoleMaster();
    // this.loadOrganizations();
    // this.loadRoles();
  }

 



  getSequenceNo() {
    this.api.getAllleadCategory(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        var seqno = data['data'][0]['SEQUENCE_NO'];
        this.data.SEQUENCE_NO = Number(seqno) + 1;

      } else {
        this.data.SEQUENCE_NO = 1;
      }

    }, err => {
      console.log(err);
    });
  }

  
//Alphabets
 alphaOnly(event:any) {
  event = (event) ? event : window.event;
  var charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
    return false;
  }
  return true;
}

omit(event:any) {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {

    this.isSpinning = false;
    
    if (this.data.NAME.trim() == "" && this.data.SEQUENCE_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");
    } else
      if
       (this.data.NAME == null || this.data.NAME.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter NAME', '')
      } else
        if (!this.namepatt.test(this.data.NAME)) {
          this.isOk = false
          this.message.error('Please Enter Valid NAME', '')
        } else
          if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Seq. No.', '')
          }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateleadCategoryMaster(this.data).subscribe(successCode => {
          if (successCode.code == 200) {
            this.message.success("Leading Category Updated Successfully...", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Leading Category Updation Failed...", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createleadCategoryMaster(this.data).subscribe(successCode => {
          if (successCode.code == 200) {
            this.message.success("Leading Category Created Successfully...", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new LeadCateogaryMaster();
              this.getSequenceNo();
            }

          } else {
            this.message.error("Leading Category Creation Failed...", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }


}
