import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { log } from 'console';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRCOLLAPSEMASTER } from 'src/app/Models/smrcollapsemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { ViewNotificationDrawerComponent } from '../../NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { ActivityleadmasterComponent } from '../Servicesss Module/ServiceModule/activityleadform/activityleadmaster/activityleadmaster.component';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { SMRMaster } from 'src/app/Models/SMRmaster';


@Component({
  selector: 'app-callcenterexecutivetable',
  templateUrl: './callcenterexecutivetable.component.html',
  styleUrls: ['./callcenterexecutivetable.component.css']
})
export class CallcenterexecutivetableComponent implements OnInit {
  dataList11: any = []
  formTitle = "SMR Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  value1: any;
  confirmationdone: any
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NUMBER", "Sequence Number"], ["BOOKING_DATE", "Booking Date"], ["SERVICE_ADVISOR_NAME", "Service Advisor Name"], ["BT_NUMBER", "BT Number"], ["BT_DATE_TIME", "BT Date Time"],
  ["CUSTOMER_NAME", "Customer Name"], ["BOOKING_CONTACT_NUMBER", "Booking Contact Number"], ["MOBILE_NUMBER", "Mobile Number"], ["REGISTRATION_NO", "Registration No"], ["VIN", "Vin"], ["WORK_TYPE", "Work Type"], ["EXPRESS_CARE", "Express Care"], ["CALLING_DATE", "Calling Date"], ["CALLING_STATUS", "Calling Status"], ["REASON", "Reason"],
  ["SME_EMPLOYEE_ID", "SME Employee ID"], ["BILL_NUMBER", "Bill Number"], ["BILL_AMOUNT", "Bill Amount"], ["BILL_DATE", "Bill Date"], ["MODEL", "Model"], ["REMARK", "Remark"], ["REMINDER_DATE", "Reminder Date"], ["VEDIO_CALL", "Video Call"], ["ONLINE_STREAMING", "Online Streaming"],
  ["FOLLOWUP_NO", "Followup No."], ["CALL_DATE", "Call Date"], ["SERVICE_COUNT", "Service Count"], ["CUST_NO", "Customer Number"], ["SPECIAL_MESSAGE", "Special Message"], ["RO_DATE", "Ro Date"],
  ["RO_NUMBER", "Ro Number"], ["NUMBER_OF_CALLS", "Number Of Calls"], ["LIVE_STREAMING", "Live Streaming"], ["STATUS", "Status"], ["DELIVERY_DATE", "Delivery Date"], ["LOCATION", "Location"], ["PIN_CODE", "Pincode"], ["VARIANT", "Varient"], ["CHASSIS_NUMBER", "Chassis Number"], ["SALES_CONSULTANT_NAME", "Sale Consultant Name"], ["SALE_DATE", "Sale Date"], ["LAST_SERVICE_TYPE", "Last Service type"], ["LAST_RO_DATE", "Last Ro Date"], ["SEGMENT", "Segment"], ["LAST_RO_WORKSHOP", "Last Ro Workshop"], ["LAST_RO_DEALER_NAME", "Last Ro Dealer Name"], ["LAST_RO_REGION", "Last Ro region"], ["NAME", "Name"], ["WORK_PHONE_NUMBER", "Work Phone Number"], ["ROOT", "Root"], ["SME_NAME", "SME Name"], ["PICK_UP_AND_DROP", "Pick Up and Drop"], ["LOST_CUSTOMER_NAME", "Lost Customer Name"], ["SRV_MODEL", "SRV Model"], ["EMAIL", "Email"], ["ADDRESS", "Address"], ["PURCHASE_DATE", "Purchase Date"], ["PREFERED_DLR_NO", "Preferred DLR No."], ["OFFICE_NUMBER", "Office Number"], ["RESIDENCE_NUMBER", "Resindence Number"], ["MILEAGE", "Mileage"], ["FIELD1", "Field 1"], ["FIELD2", "Field 2"], ["FIELD3", "Field 3"], ["FIELD4", "Field 4"], ["FIELD5", "Field 5"], ["FIELD6", "Field 6"], ["FIELD7", "Field 7"], ["FIELD8", "Field 8"], ["FIELD9", "Field 9"], ["FIELD10", "Field 10"]]
  SMRdrawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  isSMRLead: boolean = true
  // SMRdrawerVisible!: boolean; 

  // dataList:any[]=[]
  index: number = 0;
  isClosed = false;
  confrmation: string = 'default'
  noshow: string = 'default'
  midterm: string = 'default'
  servicedone: string = 'default'
  salesdata: string = 'default'
  amslost: string = 'default'
  amslost1: string = 'default'
  oneyear: string = 'default'
  appoint: string = 'default'
  NotConnected: string = 'default'
  servicedue: string = 'default'
  own: string = 'default'

  Reminder: string = 'default'
  Untouched: string = 'default'
  Connected: string = 'default'
  NoShowUntouched: string = 'default'
  PendingAt: string = 'default'

  Sold: string = 'default'
  Sold1: string = 'default'
  Sold123: string = 'default'
  Sold124: string = 'default'
  Sold125: string = 'default'


  Migrated: string = 'default'
  Wrong: string = 'default'
  Within: string = 'default'
  Permently: string = 'default'
  Local: string = 'default'

  untoch1: string = 'default'
  reminder1: string = 'default'
  notconnected1: string = 'default'
  workdone1: string = 'default'
  lost1: string = 'default'
  appoint1: string = 'default'




  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  orgId = this.cookie.get('orgId');


  collapseDrawerVisible: boolean = false;
  drawerData1: SMRCOLLAPSEMASTER = new SMRCOLLAPSEMASTER();
  drawerTitle1: string;
  drawerVisible1: boolean;
  drawerData2: ActivityLeadMaster = new ActivityLeadMaster();

  active1: boolean = false
  active2: boolean = false
  active3: boolean = false
  active4: boolean = false
  active5: boolean = false
  active6: boolean = false
  active7: boolean = false
  active8: boolean = false

  employee = []
  CCE_ID

  // tomorrow.setDate(today.getDate() + 1)

  constructor(private _exportService: ExportService, private api: ApiService, public datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService,) { }

  ngOnInit() {
    // this.search();
    // this.getEmployee();
    this.getAllcount();

    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID=43').subscribe(data => {
      if (data['code'] == 200) {
        this.employee = data['data']

        // console.log(this.employee, "rutuja");

      }



    })
    this.salesdata = "primary"


    this.Untouched = 'primary'
    this.NoShowUntouched = 'default'
    this.PendingAt = 'default'
    this.confirmationdone = 'default'
    this.Reminder = 'default'
    this.NotConnected = 'default'
    // this.showFilter1("SD", "")
    //  this.Untouched = 'primary'

    if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
      this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    }
    this.filterClass1 = "filter-visible";
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    // console.log("search text : " + this.searchText);
    if (this.searchText != "") {
      likeQuery = "  AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    // ASSIGNED_DATE

    //  this.showFilter1()
    if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

      this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='SD' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND ASSIGNED_DATE = ${("'")} ${this.ASSIGNED_DATE} ${("'")}`

    }
    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


    }, err => {
      // console.log(err);
    });
    this.api.getAllSubcount('SD', this.userid, " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.subcount = data['CategoryCounts'][0]
        // this.value = Value
        // console.log("counts");
        // console.log(this.value, "this.value");

      }
      else {
        this.message.error("Failed To get Count", "")
      }


    })
  }
  // getEmployee() {
  //   this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
  //     this.employee = data['data']
  //   })
  // }





  get closeCallback() {
    // this.index=0;
    return this.drawerClose.bind(this);
  }
  CCE_IDS
  val
  MapCCE() {
    this.val = ''
    // console.log(this.assigndate.ASSIGNED_DATE != this.datePipe.transform(this.currentdate, "yyyy-MM-dd") && this.CCE_IDS != this.userid, "rutuja")
    if (this.assigndate.ASSIGNED_DATE != this.datePipe.transform(this.currentdate, "yyyy-MM-dd") || this.CCE_IDS != this.userid) {

      if (this.CCE_IDS != undefined && this.array.length > 0) {

        if (this.searchText == "") {
          this.val = this.value
          console.log(this.val, "console.log(this.val)")
        } else {
          this.val = this.categoryforassign
          console.log(this.val, "console.log(this.val)")
        }
        console.log(this.val, "")
        this.api.MapCCE(this.CCE_IDS, this.array, this.val, this.userid)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("CCE Mapped Successfully ...", "");
              this.drawerClose();
              // this.isSpinning = false;
              this.array = []
              // this.checked = false
              this.CCE_IDS = null
              this.dataList.forEach(item => {
                this.isChecked = this.value1
                if (this.value1) {
                  if (this.value1 == true) {
                    this.checked = false
                    this.checked1 = false
                    this.indeterminate = false
                  }
                  // console.log(this.array);
                  // item.IS_SELECTED = 1
                } else {
                  // item.IS_SELECTED = 0
                  this.array = []
                  this.checked1 = false
                  this.indeterminate = true
                }

              });
            }
            else {
              this.message.error("CCE Mapping Failed...", "");
              this.array = []
              // this.isSpinning = false;
            }
          });
      } else {
        this.message.error("Please Select Leads & Call Center Executive", "");
      }
    }

    else {
      this.message.warning("This lead has already been assigned to you for today's date", "");
    }

  }

  cancel(): void {
    // this.nzMessageService.info('click cancel');
  }
  CATEGORY_STATUSSS
  TransferCCE() {
    if (this.CATEGORY_STATUSSS != undefined && this.array.length > 0) {
      this.api.TransferCCE(this.CATEGORY_STATUSSS, this.array)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Lead Transfered Successfully ...", "");
            this.drawerClose();
            this.array = []
            this.CATEGORY_STATUSSS = null
            // this.isSpinning = false;

          }
          else {
            this.message.error("Lead Transfer Failed...", "");
            // this.isSpinning = false;
            this.array = []
          }
        });
    } else {
      this.message.error("Please Select Leads & Category Status", "");
    }

  }
  value12: any
  drawerClose(): void {
    // this.search();
    this.SMRdrawerVisible = false;
    // this.isClosed=true
    this.active1 = false
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
    this.search1(this.value12, this.value13, '', this.value24);
    // this.search2()
    // this.search3('')
    // this.index=0;
  }

  confrmations(event: any) {
    this.confrmation = 'primary'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'default'
    this.amslost1 = 'default'
  }
  noshows(event: any) {

    this.confrmation = 'default'
    this.noshow = 'primary'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'default'
    this.amslost1 = 'default'
  }
  midterms(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'primary'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'default'
    this.amslost1 = 'default'

  }

  servicedones(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'primary'
    this.salesdata = 'default'
    this.amslost = 'default'
    this.amslost1 = 'default'

  }

  salesdatas(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'primary'
    this.amslost = 'default'
  }

  amslosts(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'primary'
  }




  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }
  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  drawerClose1(): void {
    this.search1(this.value, this.value13, '', this.value24);
    this.collapseDrawerVisible = false;
    // this.isClosed=true
    this.active1 = false
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
    this.getAllcount();
    this.api.getAllSubcount(this.value, this.userid, " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.subcount = data['CategoryCounts'][0]
        this.value = this.value
        // console.log("counts");
        // console.log(this.value, "this.value");

      }
      else {
        this.message.error("Failed To get Count", "")
      }


    })
    // this.index=0;
  }
  filterClass: string = "filter-invisible";

  filterClass1: string = "filter-invisible";

  filterClass2: string = "filter-invisible";

  filterClass3: string = "filter-invisible";

  filterClass4: string = "filter-invisible";

  filterClass5: string = "filter-invisible";

  filterClass6: string = "filter-invisible";

  filterClass123: string = "filter-invisible";

  filterClass24: string = "filter-invisible";


  Counts: any

  startValue: any
  endValue: any

  // endValue11: any 

  startValue1: any = new Date()
  someDate = new Date();

  numberOfDaysToAdd = 1;

  // datee = this.datePipe.transform(this.otherday, "yyyy-MM-dd")
  endValue2: any = new Date(this.startValue1.getTime() + 2 * 24 * 60 * 60 * 1000);

  endValue21: any = new Date(this.startValue1.getTime() + 1 * 24 * 60 * 60 * 1000);

  dateforbooking: any = new Date(this.someDate.getTime() + 1 * 24 * 60 * 60 * 1000);


  booking1: any = new Date(this.dateforbooking);

  booking2: any = new Date(this.dateforbooking);


  bookingdate: any = new Date()


  reminderDate
  STARTDATE = this.datePipe.transform(this.startValue1, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.startValue1, 'yyyy-MM-dd')
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  show1234(): void {
    if (this.filterClass123 === "filter-visible")
      this.filterClass123 = "filter-invisible";
    else
      this.filterClass123 = "filter-visible";
  }

  show24(): void {
    if (this.filterClass24 === "filter-visible")
      this.filterClass24 = "filter-invisible";
    else
      this.filterClass24 = "filter-visible";
  }


  subcount: any
  value: string
  Date: any

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search1(this.value12, this.value13, "", this.value24);
  }

  disable: boolean = false
  disable1: boolean = false
  disable12: boolean = false
  forno: boolean = false
  Untouche1
  // disable1:boolean = false
  showFilter1(Value: string, VALUE) {

    this.disable = false
    this.disable1 = false
    this.disable12 = false
    this.forno = false
    if (Value == "C") {
      this.showfiltervalue = ''
      this.disable = true
      this.disable1 = true
      // this.Untouche1 = 'primary'
      this.Untouched = 'default'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'primary'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'
      // this.Untouche1 = 'primary'
      this.own = 'default'


      this.confrmation = 'primary'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.amslost = 'default'
      this.amslost1 = 'default'

      this.appoint = 'default'
      this.servicedue = 'default'

      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }


      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }


      this.booking1 = this.datePipe.transform(this.booking1, "yyyy-MM-dd")
      this.booking2 = this.datePipe.transform(this.booking2, "yyyy-MM-dd")
      // var datee12 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")
      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('A') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE = 0 AND DATE(BOOKING_DATE) BETWEEN '${this.booking1}' AND '${this.booking2}'  `
      }

      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });

    }

    if (Value == "L") {
      this.showfiltervalue = ''
      // this.disable = true
      // this.disable1 = true
      // this.Untouche1 = 'primary'
      this.Untouched = 'primary'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'
      // this.Untouche1 = 'primary'
      this.own = 'default'


      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.amslost = 'default'
      this.amslost1 = 'primary'

      this.appoint = 'default'
      this.servicedue = 'default'

      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }


      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }
      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`
      }

      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
    }


    else if (Value == 'SE') {
      this.showfiltervalue = ''
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.servicedue = 'primary'
      this.own = 'default'
      this.amslost1 = 'default'

      this.Untouched = 'primary'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }



      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }
      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`
      }

      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
      // this.
      // this.search1(this.value, VALUE, '','')
    }



    else if (Value == 'SD') {
      this.showfiltervalue = ''

      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'primary'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.servicedue = 'default'
      this.amslost1 = 'default'

      this.own = 'default'

      this.Untouched = 'primary'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'

      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }

      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }

      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`

      }
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
      // this.
      // this.search1(this.value, VALUE, '')
    } else if (Value == 'NS') {
      this.showfiltervalue = ''


      this.disable12 = true

      this.forno = true




      this.confrmation = 'default'
      this.noshow = 'primary'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.amslost = 'default'
      this.appoint = 'default'
      this.servicedue = 'default'
      this.own = 'default'
      this.amslost1 = 'default'


      this.Untouched = 'default'
      this.NoShowUntouched = 'primary'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'



      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }

      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }

      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('A' ,'C') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`
      }

      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
    } else if (Value == 'MT') {
      this.showfiltervalue = 'MT'

      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'primary'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.servicedue = 'default'
      this.own = 'default'
      this.amslost1 = 'default'

      this.Untouched = 'primary'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'


      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }

      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }

      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`
      }

      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
    } else if (Value == 'OY') {
      this.showfiltervalue = 'OY'

      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.amslost = 'default'
      this.oneyear = 'primary'
      this.appoint = 'default'
      this.servicedue = 'default'
      this.own = 'default'
      this.amslost1 = 'default'


      this.Untouched = 'primary'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'


      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }

      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }

      this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
    } else if (Value == 'WD') {
      this.showfiltervalue = 'WD'

      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'primary'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.servicedue = 'default'
      this.own = 'default'
      this.amslost1 = 'default'

      this.filterClass1 = "filter-invisible";
      this.filterClass2 = "filter-invisible"
      this.filterClass123 = "filter-invisible"



    }
    else if (Value == 'A') {
      this.showfiltervalue = 'A'
      this.own = 'default'

      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.amslost = 'default'
      this.amslost1 = 'default'

      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'primary'
      this.servicedue = 'default'
    } else if (Value == 'O') {
      this.showfiltervalue = 'A'
      this.own = 'primary'

      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.amslost = 'default'
      this.amslost1 = 'default'

      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.servicedue = 'default'

      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      } catch (error) {
        sort = "";
      }
      var likeQuery = "";
      // console.log("search text : " + this.searchText);
      if (this.searchText != "") {
        likeQuery = "  AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });

        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      }

      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }

      if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

        this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='${Value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}`

      }
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


      }, err => {
        // console.log(err);
      });
    }

    if (Value == 'WD') {
      this.value = Value
      this.search1(this.value, VALUE, '', this.value24)
      // this.filterClass1 = "filter-invisible";
      // this.filterClass2 = "filter-invisible";
    }
    else if (Value == 'A') {
      this.value = Value
      this.search1(this.value, VALUE, '', this.value24)
      // console.log("AAAAAAAA");

      // this.filterClass1 = "filter-invisible";
      // this.filterClass2 = "filter-invisible";

    }




    else if (Value == 'C') {


      // console.log(this.value12, "value12")
      var filter = ''
      var datee1 = this.datePipe.transform(this.bookingdate, "yyyy-MM-dd")

      this.booking1 = this.datePipe.transform(this.booking1, "yyyy-MM-dd")
      this.booking2 = this.datePipe.transform(this.booking2, "yyyy-MM-dd")
      // console.log(filter)
      // if ( this.startValue != undefined &&  this.endValue !=undefined) {
      //  console.log(filter);

      //   filter = ` AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} ' `

      // }
      // else {
      //   filter = ` AND DATE(BOOKING_DATE) BETWEEN '${datee1}' AND '${datee1}'  `
      //   console.log(filter);

      // }
      this.api.getAllSubcount(Value, this.userid, '', this.booking1, this.booking2).subscribe(data => {
        if (data['code'] == 200) {
          this.subcount = data['CategoryCounts'][0]
          this.value = Value
          // console.log("counts");
          // console.log(this.value, "this.value");

        }
        else {
          this.message.error("Failed To get Count", "")
        }


      })
    }

    else {
      this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      this.api.getAllSubcount(Value, this.userid, " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", '', '').subscribe(data => {
        if (data['code'] == 200) {
          this.subcount = data['CategoryCounts'][0]
          this.value = Value
          // console.log("counts");
          // console.log(this.value, "this.value");

        }
        else {
          this.message.error("Failed To get Count", "")
        }


      })
    }





    if (Value == 'A' || Value == 'WD') {
      // if(Value== 'A' ||Value== 'WD' )
      this.filterClass1 = "filter-invisible";
      this.filterClass2 = "filter-invisible"
      this.filterClass123 = "filter-invisible"



    }
    else {
      this.filterClass1 = "filter-visible";
      this.filterClass2 = "filter-invisible";
      this.filterClass123 = "filter-invisible"

    }
  }

  showFilter2(Value: any) {
    this.showfiltervalue = ''
    this.searchText = ''
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.oneyear = 'default'
    this.appoint = 'default'
    this.amslost = 'primary'
    this.servicedue = 'default'
    this.amslost1 = 'default'

    this.Sold1 = 'primary'

    this.Sold123 = 'default'
    this.Sold124 = 'default'
    this.Sold125 = 'default'
    this.Sold = 'default'

    this.Migrated = 'default'

    this.Wrong = 'default'

    this.Within = 'default'

    this.Permently = 'default'

    this.Local = 'default'

    var filter = ` AND ASSIGNED_DATE ='${this.ASSIGNED_DATE}' AND ASSIGNED_ID = ${this.cookie.get('userId')} `
    this.api.getLostcount(this.userid, filter).subscribe(data => {
      if (data['code'] == 200) {
        this.subcount11 = data['CategoryCounts'][0]
        this.value = Value
        // console.log("counts");
        // console.log(this.value,"ok")
      }
      else {
        this.message.error("Failed To get Count", "")
      }


    })

    if (this.filterClass2 === "filter-visible") {
      this.filterClass2 = "filter-invisible";


    }


    else {
      this.filterClass2 = "filter-visible";
      this.filterClass123 = "filter-invisible"

      // this.filterClass1 = "filter-visible";
      this.filterClass1 = "filter-invisible";
      this.filterClass3 = "filter-invisible";
      // this.filterClass4 = "filter-invisible";
      // this.filterClass5= "filter-invisible";
      // this.filterClass6 = "filter-invisible";
      // this.filterClass7= "filter-invisible";

    }


  }
  subcount12: any
  showFilter3(Value: any, VALUE) {
    this.showfiltervalue = ''

    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.oneyear = 'default'
    this.appoint = 'default'
    this.amslost = 'default'
    this.servicedue = 'default'
    this.own = 'primary'
    this.amslost1 = 'default'

    this.Sold1 = 'default'


    this.Sold123 = 'default'
    this.Sold124 = 'default'
    this.Sold125 = 'default'

    this.Sold = 'default'

    this.Migrated = 'default'

    this.Wrong = 'default'

    this.Within = 'default'

    this.Permently = 'default'

    this.Local = 'default'


    this.untoch1 = 'primary'
    this.reminder1 = 'default'
    this.notconnected1 = 'default'
    this.workdone1 = 'default'
    this.lost1 = 'default'
    this.appoint1 = 'default'




    this.api.getAllSubcount(Value, this.userid, '', '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.subcount12 = data['CategoryCounts'][0]
        this.value = Value
        // console.log("counts");
      }
      else {
        this.message.error("Failed To get Count", "")
      }


    })

    if (this.filterClass3 === "filter-visible") {
      this.filterClass3 = "filter-invisible";


    }


    else {
      this.filterClass3 = "filter-visible";
      // this.filterClass1 = "filter-visible";
      this.filterClass1 = "filter-invisible";
      this.filterClass2 = "filter-invisible";
      this.filterClass123 = "filter-invisible"

      // this.filterClass3 = "filter-invisible";
      // this.filterClass4 = "filter-invisible";
      // this.filterClass5= "filter-invisible";
      // this.filterClass6 = "filter-invisible";
      // this.filterClass7= "filter-invisible";

    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    if (this.searchText != "") {
      likeQuery = "  AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    // console.log(exportInExcel, "true ");


    this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='O' AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND ASSIGNED_DATE= '${this.ASSIGNED_DATE}'`


    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


      // console.log(this.dataList, "this.dataList12434")


    }, err => {
      // console.log(err);
    });


  }

  importInExcel() {
    this.search1(this.value12, this.value13, '', this.value24, true);
  }
  exportLoading = false
  Datevisible: boolean = true
  // value12
  value13
  value24
  filterQuery1
  okqqq
  otherday
  nextday
  search1(value, VALUE, value1, value123, exportInExcel: boolean = false) {


    // console.log(this.value ,"")

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.reminderDate = this.datePipe.transform(this.reminderDate, 'yyyy-MM-dd')
    this.booking1 = this.datePipe.transform(this.booking1, 'yyyy-MM-dd')
    this.booking2 = this.datePipe.transform(this.booking2, 'yyyy-MM-dd')

    this.dateforbooking = this.datePipe.transform(this.dateforbooking, 'yyyy-MM-dd')
    // this.dateforbooking = this.datePipe.transform(this.booking2, 'yyyy-MM-dd')


    if (VALUE == '-') {

      this.Untouched = 'primary'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'



    } else if (VALUE == 'R') {

      this.Reminder = 'primary'
      this.Untouched = 'default'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.NotConnected = 'default'


    } else if (VALUE == 'NC') {

      this.Untouched = 'default'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'primary'

    }

    else if (VALUE == 'A') {
      this.Untouched = 'default'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'primary'
      this.confirmationdone = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'default'

    } else if (VALUE == 'C') {
      this.Untouched = 'default'
      this.NoShowUntouched = 'default'
      this.PendingAt = 'default'
      this.confirmationdone = 'primary'
      this.Reminder = 'default'
      this.NotConnected = 'default'
      if (this.value24 != 'PC') {

        this.filterClass123 = "filter-invisible"
      }

      if (this.disable = true) {
        this.Untouched = 'default'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'primary'
        this.confirmationdone = 'default'
        this.Reminder = 'default'
        this.NotConnected = 'default'
      }
      if (this.disable1 = true) {
        this.Untouched = 'default'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'default'
        this.confirmationdone = 'primary'
        this.Reminder = 'default'
        this.NotConnected = 'default'
      }

    }

    else if (VALUE == 'VS') {
      this.Sold = 'primary'
      this.Sold1 = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    } else if (VALUE == 'ML') {
      this.Sold = 'default'
      this.Sold1 = 'default'
      this.Migrated = 'primary'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    } else if (VALUE == 'WR') {
      this.Sold = 'default'
      this.Sold1 = 'default'
      this.Migrated = 'default'
      this.Wrong = 'primary'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    } else if (VALUE == 'WM') {
      this.Sold = 'default'
      this.Sold1 = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'primary'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    }
    else if (VALUE == 'L') {
      this.Sold1 = 'primary'
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    } else if (VALUE == 'PL') {
      this.Sold1 = 'default'
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'primary'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    }
    else if (VALUE == 'LS') {
      this.Sold1 = 'default'
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'primary'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'default'

    }

    if (VALUE == '-') {

      this.Sold1 = 'default'
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'primary'
      this.Sold125 = 'default'




    } else if (VALUE == 'R') {

      this.Sold1 = 'default'
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'default'
      this.Sold123 = 'default'
      this.Sold125 = 'primary'


    } else if (VALUE == 'NC') {

      this.Sold1 = 'default'
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'default'
      this.Sold124 = 'primary'
      this.Sold123 = 'default'
      this.Sold125 = 'default'


    }

    else if (VALUE == 'R') {
      this.untoch1 = 'default'
      this.reminder1 = 'primary'
      this.notconnected1 = 'default'
      this.workdone1 = 'default'
      this.lost1 = 'default'
      this.appoint1 = 'default'
    }
    else if (VALUE == 'NC') {
      this.untoch1 = 'default'
      this.reminder1 = 'default'
      this.notconnected1 = 'primary'
      this.workdone1 = 'default'
      this.lost1 = 'default'
      this.appoint1 = 'default'
    }
    else if (VALUE == 'W') {
      this.untoch1 = 'default'
      this.reminder1 = 'default'
      this.notconnected1 = 'default'
      this.workdone1 = 'primary'
      this.lost1 = 'default'
      this.appoint1 = 'default'
    }
    else if (VALUE == 'ML') {
      this.untoch1 = 'default'
      this.reminder1 = 'default'
      this.notconnected1 = 'default'
      this.workdone1 = 'default'
      this.lost1 = 'primary'
      this.appoint1 = 'default'
    }
    else if (VALUE == 'A') {
      this.untoch1 = 'default'
      this.reminder1 = 'default'
      this.notconnected1 = 'default'
      this.workdone1 = 'default'
      this.lost1 = 'default'
      this.appoint1 = 'primary'
    }


    // console.log(VALUE);

    if (VALUE == 'R') {
      this.Datevisible = false
    } else {
      this.Datevisible = true
    }

    if (value1 == 'C') {
      this.pageIndex = 1
      this.pageSize = 10
    } else {
      this.pageIndex = this.pageIndex
      this.pageSize = this.pageSize
    }

    this.value12 = value
    this.value13 = VALUE
    this.value24 = value123
    // console.log(value123, "a")

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    // `AND CATEGORY_STATUS='${value}'`
    // console.log(this.value, "1");
    // console.log(value, "2")

    if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {

      if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
        this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      }

      if (this.value == "A") {
        // if ((this.startValue != null || this.startValue != undefined) && (this.endValue != null || this.endValue != undefined)) {
        //   var datefilter = `  AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} '`

        //   console.log(datefilter, "datefilter");

        // }
        if (this.startValue != undefined && this.endValue != undefined) {
          this.filterQuery = ` AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = '${this.cookie.get('userId')} ' AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} '`

        }

        else {
          this.filterQuery = ` AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = '${this.cookie.get('userId')} '`

        }
      }
      else if (this.value == "WD") {
        this.filterQuery = ` AND CATEGORY_STATUS = 'WD' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND ASSIGNED_DATE = '${this.ASSIGNED_DATE}' `
      }
      else if (this.value == "ML") {

        // if (VALUE == '-' || VALUE == 'R' || VALUE == 'NC') {
        //   this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='ML' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND ASSIGNED_DATE = '${this.ASSIGNED_DATE}' `

        // }
        // else {
        this.filterQuery = ` AND LOST_SUB_CATEGOREY='${VALUE}' AND CATEGORY_STATUS='ML' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND ASSIGNED_DATE = '${this.ASSIGNED_DATE}'`

        // }


      }

      // else if (VALUE == "R") {
      //   this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='${this.value}' AND CCE_ID = ${this.cookie.get('userId')} AND DATE(REMINDER_DATE) = '${this.Date}'`

      // }


      else if (this.value == "C") {
        console.log(this.value24, "");
        // console.log(this.value24 == 'AP', "this.value24 == 'AP'");

        if (this.value24 == 'AP') {
          this.filterClass123 = "filter-invisible"
          this.otherday = this.someDate.setDate(this.someDate.getDate() + 2);
          var datee1 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")

          // console/.log(datee1)
          // if (datefilter != undefined) {
          //   this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND IS_CONFIRMATION_DONE = 1  AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} ' `

          // }
          // else {
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND IS_CONFIRMATION_DONE = 1  AND ASSIGNED_ID = ${this.cookie.get('userId')}`

          // }


          // console.log('okqqq', "");
          // console.log(this.filterQuery1, "12343");



        }
        else if (VALUE == 'R' || VALUE == 'NC') {
          this.filterClass123 = "filter-invisible"
          this.otherday = this.someDate.setDate(this.someDate.getDate() + 1);
          var datee1 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")

          // if (datefilter != null || datefilter != undefined) {
          //   this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE IN (1,0) AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} ' `

          // }
          // else {
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE IN (1,0) `

          // }

          // this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE IN (1,0) AND DATE(BOOKING_DATE) BETWEEN '${this.STARTDATE}' AND '${datee1}'  `
        }
        else {
          this.showfiltervalue = 'PC'
          if ((this.startValue != null || this.startValue != undefined) && (this.endValue != null || this.endValue != undefined)) {
            var datefilter = `  AND DATE(BOOKING_DATE) BETWEEN '${this.booking1}' AND '${this.booking2}'`

            // console.log(datefilter, "datefilter");

          }



          this.otherday = this.someDate.setDate(this.someDate.getDate() + 2);
          var datee1 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")
          var datee12 = this.datePipe.transform(this.endValue21, "yyyy-MM-dd")
          this.nextday = this.someDate.setDate(this.someDate.getDate() + 1);

          var dd12 = this.datePipe.transform(this.nextday, "yyyy-MM-dd")
          // console.log('ok111')

          // if (datefilter != undefined) {
          //   this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE = 0 AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} ' `

          // }

          if (this.booking1 != undefined || this.booking2 != undefined) {
            this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE = 0 AND DATE(BOOKING_DATE) BETWEEN '${this.booking1}' AND '${this.booking2}'`

          }

          else {
            this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE = 0 AND DATE(BOOKING_DATE) BETWEEN '${this.dateforbooking}' AND '${this.dateforbooking}'`

          }







          // console.log('okqqq', "123");
        }



      }

      else if (this.value == "NS") {

        if (this.value13 == 'A') {

          // this.filterQuery1 = ` AND CALLING_STATUS IN ('${VALUE}','C') AND CATEGORY_STATUS='${this.value}' `
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}','C') AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND ASSIGNED_DATE= '${this.ASSIGNED_DATE}'`

          // console.log('okqqq', "");
          // console.log(this.filterQuery1, "12343");



        }
        else {
          // this.filterQuery1 = ` AND CALLING_STATUS IN ('${VALUE}')  AND CATEGORY_STATUS='${this.value}' `
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND ASSIGNED_DATE= '${this.ASSIGNED_DATE}'`


          // console.log('okqqq', "123");
        }
      }

      else if (this.value == "O") {
        this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND ASSIGNED_DATE= '${this.ASSIGNED_DATE}'`


      }

      // else if()

      else {
        this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND ASSIGNED_DATE= '${this.ASSIGNED_DATE}'`
      }
      // console.log("search text1 : " + this.searchText);
    }

    else {
      this.filterQuery = ` AND ASSIGNED_ID = ${this.cookie.get('userId')}`
    }


    // console.log("search text : " + this.searchText);


    var likeQuery = "";

    console.log(this.searchText, "searchtext")
    // console.log(this.searchText,"searchtext" )

    if (this.searchText != "") {
      likeQuery = "  AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    console.log("exportInExcel = ", exportInExcel);

    if (exportInExcel == false) {
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200)
          this.searchText = ''
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];


        // console.log(this.dataList, "this.dataList12434")


      }, err => {
        // console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getSMRMastertabledata(0, 0, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {

        if (data['code'] == 200)
          this.searchText = ''
        this.dataList11 = data['data'];
        console.log(this.dataList11, "ok")
        this.convertInExcel();

        this.exportLoading = false;

        // this.SalesExecutiveWiseModelWiseSummary = data['data'];






        this.loadingRecords = false;

        // this.totalRecords = data['count'];







      }, err => {

        // console.log(err);

      });
    }

  }

  ASSIGNED_DATE: string
  date1 = new Date()
  categoryforassign

  searchbyvin(value, VALUE, value1, exportInExcel: boolean = false) {
    this.filterQuery1 = ''

    if (this.searchText != '') {
      this.checked1 = false
      this.array = []
      console.log(this.checked1, "this.checked1");
    }

    // } else{
    //   this.checked1=false
    //   console.log(this.checked1,"this.checked12");

    // }
    console.log(this.checked1, "this.checked123");


    // console.log(VALUE);

    if (value1 == 'R') {
      this.Datevisible = false
    } else {
      this.Datevisible = true
    }

    if (value1 == 'C') {
      this.pageIndex = 1
      this.pageSize = 10
    } else {
      this.pageIndex = this.pageIndex
      this.pageSize = this.pageSize
    }

    this.value12 = value
    this.value13 = VALUE


    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    // `AND CATEGORY_STATUS='${value}'`
    // console.log(this.value, "1");
    // console.log(value, "2")

    if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
      this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    }


    if (this.searchText == '' || this.searchText == null || this.searchText == undefined) {
      if (this.value == "A") {
        this.filterQuery1 = ` AND CATEGORY_STATUS='${this.value}' `
      }
      else if (this.value == "WD") {
        this.filterQuery1 = ` AND CATEGORY_STATUS = 'WD' `
      }
      else if (this.value == "L") {
        if (VALUE == '-' || VALUE == 'R' || VALUE == 'NC') {
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='${this.value}' AND CCE_ID = ${this.cookie.get('userId')} `

        }
        else {
          this.filterQuery = ` AND LOST_SUB_CATEGOREY='${VALUE}' AND CATEGORY_STATUS='${this.value}' AND CCE_ID = ${this.cookie.get('userId')} `

        }
      }

      else if (this.value == "C") {

        if (this.value24 == 'AP') {
          this.otherday = this.someDate.setDate(this.someDate.getDate() + 1);
          var datee1 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")
          // console.log(datee1)
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND IS_CONFIRMATION_DONE = 1  AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND DATE(BOOKING_DATE) BETWEEN '${this.STARTDATE}' AND '${datee1}' `

          // console.log('okqqq', "");
          // console.log(this.filterQuery1, "12343");



        }
        else {
          this.otherday = this.someDate.setDate(this.someDate.getDate() + 1);
          var datee1 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")
          // console.log('ok111')
          this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE = 0  AND DATE(BOOKING_DATE) BETWEEN '${this.STARTDATE}' AND '${datee1}'  `


          // console.log('okqqq', "123");
        }
      }

      else if (this.value == "NS") {

        if (this.value13 == 'A') {

          this.filterQuery1 = ` AND CALLING_STATUS IN ('${VALUE}','C') AND CATEGORY_STATUS='${this.value}' `

          // console.log('okqqq', "");
          // console.log(this.filterQuery1, "12343");



        }
        else {
          this.filterQuery1 = ` AND CALLING_STATUS IN ('${VALUE}')  AND CATEGORY_STATUS='${this.value}' `

          // console.log('okqqq', "123");
        }
      }

      else {
        this.filterQuery1 = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='${this.value}'`
      }
    }

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = "  AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    ;


    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery1 + ` AND ASSIGNED_ID = ${this.cookie.get('userId')} `, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

      if (this.searchText != "" && this.dataList.length == 1) {
        this.categoryforassign = data['data'][0]['CATEGORY_STATUS']
      } else {
        this.categoryforassign = ''
      }

      console.log(this.searchText, "ook");
      console.log(((this.searchText != "") && (this.dataList.length == 1)), "ook");


      // console.log(this.dataList, "q111this.dataList")
      var key = data['data'][0]['CATEGORY_STATUS']
      var key1 = data['data'][0]['CALLING_STATUS']

      this.value12 = key
      this.value13 = key1

      if (VALUE == '-') {

        this.Untouched = 'primary'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'default'
        this.confirmationdone = 'default'
        this.Reminder = 'default'
        this.NotConnected = 'default'


      } else if (VALUE == 'R') {

        this.Reminder = 'primary'
        this.Untouched = 'default'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'default'
        this.confirmationdone = 'default'
        this.NotConnected = 'default'
        if (value == 'L') {
          this.Sold125 = 'primary'

        }

      } else if (VALUE == 'NC') {

        this.Untouched = 'default'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'default'
        this.confirmationdone = 'default'
        this.Reminder = 'default'
        this.NotConnected = 'primary'
        if (value == 'L') {
          this.Sold124 = 'primary'
        }
      }

      else if (VALUE == 'A') {
        this.Untouched = 'default'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'primary'
        this.confirmationdone = 'default'
        this.Reminder = 'default'
        this.NotConnected = 'default'

      } else if (VALUE == 'C') {
        this.Untouched = 'default'
        this.NoShowUntouched = 'default'
        this.PendingAt = 'default'
        this.confirmationdone = 'primary'
        this.Reminder = 'default'
        this.NotConnected = 'default'

        if (this.disable = true) {
          this.Untouched = 'default'
          this.NoShowUntouched = 'default'
          this.PendingAt = 'primary'
          this.confirmationdone = 'default'
          this.Reminder = 'default'
          this.NotConnected = 'default'
        }
        if (this.disable1 = true) {
          this.Untouched = 'default'
          this.NoShowUntouched = 'default'
          this.PendingAt = 'default'
          this.confirmationdone = 'primary'
          this.Reminder = 'default'
          this.NotConnected = 'default'
        }

      }

      else if (VALUE == 'VS') {
        this.Sold = 'primary'
        this.Sold1 = 'default'
        this.Migrated = 'default'
        this.Wrong = 'default'
        this.Within = 'default'
        this.Permently = 'default'
        this.Local = 'default'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      } else if (VALUE == 'ML') {
        this.Sold = 'default'
        this.Sold1 = 'default'
        this.Migrated = 'primary'
        this.Wrong = 'default'
        this.Within = 'default'
        this.Permently = 'default'
        this.Local = 'default'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      } else if (VALUE == 'WR') {
        this.Sold = 'default'
        this.Sold1 = 'default'
        this.Migrated = 'default'
        this.Wrong = 'primary'
        this.Within = 'default'
        this.Permently = 'default'
        this.Local = 'default'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      } else if (VALUE == 'WM') {
        this.Sold = 'default'
        this.Sold1 = 'default'
        this.Migrated = 'default'
        this.Wrong = 'default'
        this.Within = 'primary'
        this.Permently = 'default'
        this.Local = 'default'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      }
      else if (VALUE == 'L') {
        this.Sold1 = 'primary'
        this.Sold = 'default'
        this.Migrated = 'default'
        this.Wrong = 'default'
        this.Within = 'default'
        this.Permently = 'default'
        this.Local = 'default'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      } else if (VALUE == 'PL') {
        this.Sold1 = 'default'
        this.Sold = 'default'
        this.Migrated = 'default'
        this.Wrong = 'default'
        this.Within = 'default'
        this.Permently = 'primary'
        this.Local = 'default'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      }
      else if (VALUE == 'LS') {
        this.Sold1 = 'default'
        this.Sold = 'default'
        this.Migrated = 'default'
        this.Wrong = 'default'
        this.Within = 'default'
        this.Permently = 'default'
        this.Local = 'primary'
        this.Sold124 = 'default'
        this.Sold123 = 'default'
        this.Sold125 = 'default'

      }
    }, err => {
      // console.log(err);
    });



  }

  convertInExcel() {
    console.log("   this.convertInExcel();");

    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList11.length; i++) {

      if (this.dataList11[i]['CATEGORY_STATUS'] == "A") {
        obj1['Category Status'] = "Appointment"
      }
      else if (this.dataList11[i]['CATEGORY_STATUS'] == "CO") {
        obj1['Category Status'] = "Confirmation"
      } else if (this.dataList11[i]['CATEGORY_STATUS'] == "NS") {
        obj1['Category Status'] = "No Show"
      } else if (this.dataList11[i]['CATEGORY_STATUS'] == "MT") {
        obj1['Category Status'] = "Mid Term"
      } else if (this.dataList11[i]['CATEGORY_STATUS'] == "OY") {
        obj1['Category Status'] = "One Year"
      }
      // else if(this.dataList11[i]['CATEGORY_STATUS']=="SE"){
      //   obj1['Category Status'] = "Service "
      // } 
      else if (this.dataList11[i]['CATEGORY_STATUS'] == "WD") {
        obj1['Category Status'] = "Work Done"
      } else if (this.dataList11[i]['CATEGORY_STATUS'] == "L") {
        obj1['Category Status'] = "Lost"
      }
      // else if(this.dataList[i]['CATEGORY_STATUS']=="A"){
      //   obj1['Category Status'] = "Appointment"
      // }


      else if (this.dataList11[i]['CATEGORY_STATUS'] == "SD") {
        obj1['Category Status'] = "Sales Data"
      }


      obj1['Booking Date'] = this.dataList11[i]['BOOKING_DATE'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Service Advisor Name'] = this.dataList11[i]['SERVICE_ADVISER_NAME'];
      obj1['BT Number'] = this.dataList11[i]['BT_NUMBER'];
      obj1['BT Date Time'] = this.dataList11[i]['BT_DATE_TIME'];
      obj1['Customer Name'] = this.dataList11[i]['CUSTOMER_NAME'];
      obj1['Booking Contact Number'] = this.dataList11[i]['BOOKING_CONTACT_NUMBER'];
      obj1['Mobile Number'] = this.dataList11[i]['MOBILE_NUMBER'];
      obj1['Registration No'] = this.dataList11[i]['REGISTRATION_NO'];
      obj1['Work Type'] = this.dataList11[i]['WORK_TYPE'];
      obj1['Express Care'] = this.dataList11[i]['EXPRESS_CARE'];
      obj1['Calling Date'] = this.dataList11[i]['CALLING_DATE'];
      obj1['Calling Status'] = this.dataList11[i]['CALLING_STATUS'];
      obj1['SME Employee ID'] = this.dataList11[i]['SME_EMPLOYEE_ID'];
      obj1['Bill Number'] = this.dataList11[i]['BILL_NUMBER'];
      obj1['Bill Amount'] = this.dataList11[i]['BILL_AMOUNT'];
      obj1['Bill Date'] = this.dataList11[i]['BILL_DATE'];
      obj1['Model'] = this.dataList11[i]['MODEL'];
      obj1['Remark'] = this.dataList11[i]['REMARK'];
      obj1['Reminder Date'] = this.dataList11[i]['REMINDER_DATE'];
      obj1['Followup No.'] = this.dataList11[i]['FOLLOWUP_NO'];
      obj1['Call Date'] = this.dataList11[i]['CALL_DATE'];
      obj1['Service Count'] = this.dataList11[i]['SERVICE_COUNT'];
      obj1['Customer Number'] = this.dataList11[i]['CUST_NO'];
      obj1['Special Message'] = this.dataList11[i]['SPECIAL_MESSAGE'];
      obj1['Ro Date'] = this.dataList11[i]['RO_DATE'];
      obj1['Ro Number'] = this.dataList11[i]['RO_NUMBER'];
      obj1['Number Of Calls'] = this.dataList11[i]['NUMBER_OF_CALLS'];
      obj1['Live Streaming'] = this.dataList11[i]['LIVE_STREAMING'];
      obj1['Status'] = this.dataList11[i]['STATUS'];
      obj1['Delivery Date'] = this.dataList11[i]['DELIVERY_DATE'];
      obj1['Location'] = this.dataList11[i]['LOCATION'];
      obj1['Pincode'] = this.dataList11[i]['PIN_CODE'];
      obj1['Varient'] = this.dataList11[i]['VARIANT'];
      obj1['Chassis Number'] = this.dataList11[i]['CHASSIS_NUMBER'];
      obj1['Sale Consultant Name'] = this.dataList11[i]['SALES_CONSULTANT_NAME'];
      obj1['Sale Date'] = this.dataList11[i]['SALE_DATE'];
      obj1['Last Service type'] = this.dataList11[i]['LAST_SERVICE_TYPE'];
      obj1['Last Ro Date'] = this.dataList11[i]['LAST_RO_DATE'];
      obj1['Segment'] = this.dataList11[i]['SEGMENT'];
      obj1['Last Ro Workshop'] = this.dataList11[i]['LAST_RO_WORKSHOP'];
      obj1['Last Ro Dealer Name'] = this.dataList11[i]['LAST_RO_DEALER_NAME'];
      obj1['Last Ro region'] = this.dataList11[i]['LAST_RO_REGION'];
      obj1['Name'] = this.dataList11[i]['NAME'];
      obj1['Work Phone Number'] = this.dataList11[i]['WORK_PHONE_NUMBER'];
      obj1['Root'] = this.dataList11[i]['ROOT'];
      obj1['SME Name'] = this.dataList11[i]['SME_NAME'];
      obj1['Pick Up and Drop'] = this.dataList11[i]['PICK_UP_AND_DROP'];
      obj1['Lost Customer Name'] = this.dataList11[i]['LOST_CUSTOMER_NAME'];
      obj1['SRV Model'] = this.dataList11[i]['SRV_MODEL'];
      obj1['Email'] = this.dataList11[i]['EMAIL'];
      obj1['Address'] = this.dataList11[i]['ADDRESS'];
      obj1['Purchase Date'] = this.dataList11[i]['PURCHASE_DATE'];
      obj1['Preferred DLR No.e'] = this.dataList11[i]['PREFERRED_DLR_NO'];
      obj1['Office Number'] = this.dataList11[i]['OFFICE_NUMBER'];
      obj1['Resindence Number'] = this.dataList11[i]['RESIDENCE_NUMBER'];
      obj1['Mileage'] = this.dataList11[i]['MILEAGE'];
      obj1['Field 1'] = this.dataList11[i]['FIELD1'];
      obj1['Field 2'] = this.dataList11[i]['FIELD2'];
      obj1['Field 3'] = this.dataList11[i]['FIELD3'];
      obj1['Field 4'] = this.dataList11[i]['FIELD4'];
      obj1['Field 5'] = this.dataList11[i]['FIELD5'];
      obj1['Field 6'] = this.dataList11[i]['FIELD6'];
      obj1['Field 7'] = this.dataList11[i]['FIELD7'];
      obj1['Field 8'] = this.dataList11[i]['FIELD8'];
      obj1['Field 9'] = this.dataList11[i]['FIELD9'];
      obj1['Field 10'] = this.dataList11[i]['FIELD10'];
      // obj1['Resindence Number'] = this.dataList11[i]['NO_OF_INVOICE_GENERATED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList11.length - 1) {
        this._exportService.exportExcel(arry1, 'Service Data' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }


  search2() {
    // if (reset) {
    //   this.pageIndex = 1;
    //   this.sortKey = "id";
    //   this.sortValue = "desc"
    // }
    this.filterClass1 = "filter-invisible";
    this.filterClass2 = "filter-invisible";

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }
    this.filterQuery = ' AND CATEGORY_STATUS = "A" AND CCE_ID = ' + this.cookie.get('userId')

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


    }, err => {
      // console.log(err);
    });
  }
  subcount11: any
  ok




  userid = this.cookie.get('userId')


  getAllcount1(date: any) {

    date = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    this.booking1 = this.datePipe.transform(this.booking1, 'yyyy-MM-dd')
    this.booking2 = this.datePipe.transform(this.booking2, 'yyyy-MM-dd')

    this.api.getAllcount(this.userid, " AND ASSIGNED_DATE= " + "'" + date + "'", this.booking1, this.booking2).subscribe(data => {
      this.Counts = data['CategoryCounts'][0]
      // console.log(this.Counts, "counts");

    })
  }
  getAllcount() {


    if (this.ASSIGNED_DATE != undefined || this.ASSIGNED_DATE != '') {
      this.ASSIGNED_DATE = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    }
    this.booking1 = this.datePipe.transform(this.booking1, 'yyyy-MM-dd')
    this.booking2 = this.datePipe.transform(this.booking2, 'yyyy-MM-dd')
    this.api.getAllcount(this.userid, " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", this.booking1, this.booking2).subscribe(data => {
      this.Counts = data['CategoryCounts'][0]
      // console.log(this.Counts, "counts");

    })
  }



  endOpen = false;
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };
  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')


    this.booking1 = this.datePipe.transform(this.booking1, 'yyyy-MM-dd')
    this.booking2 = this.datePipe.transform(this.booking2, 'yyyy-MM-dd')






    this.reminderDate = this.datePipe.transform(this.reminderDate, 'yyyy-MM-dd')
    // try {
    //   sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    // } catch (error) {
    //   sort = '';
    // }
    this.filterQuery = '';

    if (this.startValue != null) {
      this.STARTDATE = this.startValue
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue
    }

    // this.search();
    this.filter()
    // if(this.value12=='C'){


    // }
    this.filterClass = "filter-invisible";
  }
  showfiltervalue: any

  filter() {

    this.getAllcount()


    // this.showFilter1('C', "")
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }


    if (this.value == "WD") {
      this.filterQuery = ` AND CATEGORY_STATUS = 'WD' AND ASSIGNED_ID = ${this.cookie.get('userId')}  AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}' AND ASSIGNED_DATE = ${this.ASSIGNED_DATE}`
    } else {
      this.filterQuery = ` AND CALLING_STATUS IN ('${this.value13}') AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}' AND ASSIGNED_DATE = ${this.ASSIGNED_DATE}`
    }
    if (this.value == "A") {

      if ((this.startValue != null || this.startValue != undefined) && (this.endValue != null || this.endValue != undefined)) {

        var datefilter = `  AND DATE(BOOKING_DATE) BETWEEN '${this.startValue}' AND '${this.endValue} '`

        // console.log(datefilter, " var datefilter");


      }

      this.filterQuery = ` AND CATEGORY_STATUS='${this.value}' AND ASSIGNED_ID = '${this.cookie.get('userId')}'` + datefilter

    }
    // console.log(this.value13, "rutuja")
    if (this.value == "C") {
      if (this.value24 == 'PC') {

        var datefilter = `  AND DATE(BOOKING_DATE) BETWEEN '${this.booking1}' AND '${this.booking2}'`

        // console.log(datefilter, "datefilter");





        // this.otherday = this.someDate.setDate(this.someDate.getDate() + 2);
        // var datee1 = this.datePipe.transform(this.endValue2, "yyyy-MM-dd")
        // var datee12 = this.datePipe.transform(this.endValue21, "yyyy-MM-dd")
        // this.nextday = this.someDate.setDate(this.someDate.getDate() + 1);

        var dd12 = this.datePipe.transform(this.nextday, "yyyy-MM-dd")
        // console.log('ok111')


        this.filterQuery = ` AND CALLING_STATUS IN ('A') AND CATEGORY_STATUS='C' AND ASSIGNED_ID = ${this.cookie.get('userId')} AND IS_CONFIRMATION_DONE = 0 ` + datefilter



        this.api.getAllSubcount(this.value, this.userid, '', this.booking1, this.booking2).subscribe(data => {
          if (data['code'] == 200) {
            this.subcount = data['CategoryCounts'][0]
            // this.value = Value
            // console.log("counts");
            // console.log(this.value, "this.value");

          }
          else {
            this.message.error("Failed To get Count", "")
          }


        })
      }







    }



    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


    }, err => {
      // console.log(err);
    });
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    // this.BRANCH_ID = []
    this.getAllcount()
    // this.SALES_CONSULTANT_ID = ''
    this.CATEGORY_STATUS = null
    this.reminderDate = ''
    this.startValue = null
    this.endValue = null
    this.booking1 = this.datePipe.transform(this.dateforbooking, 'yyyy-MM-dd')

    this.booking2 = this.datePipe.transform(this.dateforbooking, 'yyyy-MM-dd')



    this.STARTDATE = this.datePipe.transform(this.startValue1, 'yyyy-MM-dd')
    this.ENDDATE = this.datePipe.transform(this.startValue1, 'yyyy-MM-dd')
    this.search1(this.value, this.value13, '', this.value24)
  }
  //date disabled 
  today11 = new Date();
  today111 = new Date().setDate(new Date().getDate() + 1);

  disabledStartDate2 = (current: Date): boolean => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return (
      differenceInCalendarDays(current, today) !== 1 &&
      differenceInCalendarDays(current, tomorrow) !== 1
    );
  };


  // disabledStartDate2 = current => {
  //     let index = this.dates.findIndex(date => date === moment(current).format('YYYY-MM-DD'))
  //     return index === -1 && true
  //   }



  checked = false;
  checked1 = false;
  checkedd = true;
  indeterminate = true;
  indeterminate1 = false;
  setOfCheckedId = new Set<number>();
  setOfCheckedId1 = new Set<number>();
  array = []
  checked2 = true
  isChecked: boolean
  code: any
  disable11: boolean = false


  onAllChecked1(value: boolean): void {
    // console.log(value);

    this.dataList.forEach(item => {
      this.isChecked = value

      // console.log(this.isChecked, "")
      if (value) {
        if (value == true) {
          this.array.push(item.ID)
          this.checked1 = true
          this.indeterminate = false
          this.value1 = value
        }
        // console.log(this.array);
        // item.IS_SELECTED = 1
      } else {
        // item.IS_SELECTED = 0
        this.array = []
        this.checked1 = false
        // this.disable11=false
        this.indeterminate = true
      }

    });

  }

  assigndate: ActivityLeadMaster
  onChecked1(id: number, checked: boolean, value, data): void {
    this.assigndate = data
    // this.code=value
    if (checked) {
      // console.log(checked);
      this.isChecked = checked
      this.array.push(id)
      this.checked = false
      this.disable11 = true
      // console.log(this.array);
      this.indeterminate = false
      // this.dataList[ind].IS_SELECTED = 1
    } else {
      // this.dataList[ind].IS_SELECTED = 0
      this.array.pop()
      this.checked = true
      this.disable11 = false
      // console.log(this.array);
      this.indeterminate = true

    }

  }

  drawerClose2(): void {
    this.drawerVisible1 = false;
    this.getAllcount();

    this.api.getAllSubcount(this.value, this.userid, " AND ASSIGNED_DATE= " + "'" + this.ASSIGNED_DATE + "'", '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.subcount = data['CategoryCounts'][0]
        this.value = this.value
        // console.log("counts");
        // console.log(this.value, "this.value");

      }
      else {
        this.message.error("Failed To get Count", "")
      }


    })
    this.dataList = []
    // console.log(this.value, "this.valuethis.value")
    this.search1(this.value, this.value13, '', this.value24);
    if (this.value == 'A' || this.value == 'WD') {
      // if(Value== 'A' ||Value== 'WD' )
      this.filterClass1 = "filter-invisible";


    }
    else {
      this.filterClass1 = "filter-visible";
      this.filterClass2 = "filter-invisible";
      this.filterClass123 = "filter-invisible"

    }
    // this.filterClass5 = "filter-invisible";

    // this.filterClass1 = "filter-visible";
    // this.filterClass2 = "filter-invisible";
    // this.filterClass3 = "filter-invisible";
    // this.filterClass4 = "filter-invisible";
    // this.filterClass1 = "filter-invisible";
    // this.filterClass6 = "filter-invisible";
    // this.filterClass7 = "filter-invisible";
  }
  datalist = []

  preventSingleClick = false;

  timer: any;

  delay: Number;

  singleClick() {

    this.preventSingleClick = false;

    const delay = 200;

    this.timer = setTimeout(() => {

      if (!this.preventSingleClick) {

        //Navigate on single click

      }

    }, delay);

  }
  IS_CONFIRMATION_DONE: boolean = true
  CALL_STATUSS
  currentdate = new Date()
  branchId = this.cookie.get('branchId')
  LOCATIONs = []
  @ViewChild(ActivityleadmasterComponent, { static: false }) ActivityLeadMasterComponentVar: ActivityleadmasterComponent;

  customername
  mobileno

  Activity(dataa) {
    this.preventSingleClick = true;


    clearTimeout(this.timer);

    if (dataa.ASSIGNED_DATE == this.datePipe.transform(this.currentdate, "yyyy-MM-dd") || dataa.CATEGORY_STATUS == 'C') {
      this.drawerTitle1 = "View Activities";
      this.drawerVisible1 = true;



      this.CALL_STATUSS = "C"
      this.drawerData2 = Object.assign({}, dataa);
      console.log(dataa);

      // if(dataa.ACTIVITY_TYPE_ID == 5 || dataa.ACTIVITY_TYPE_ID == 6){
      this.drawerData2.ACTIVITY_TYPE_ID = 2
      // }else{
      //   this.drawerData2.ACTIVITY_TYPE_ID = 2
      // }

      this.drawerData2.CALLING_DATE_TIME = new Date()
      this.drawerData2.CALLING_TIME = new Date()

      this.customername = dataa.CUSTOMER_NAME
      this.mobileno = dataa.MOBILE_NUMBER
      // sessionStorage.setItem('customername',dataa.CUSTOMER_NAME)
      // sessionStorage.setItem('mobileno',dataa.MOBILE_NUMBER)
      // this.drawerData2.IS_CONFIRMATION_DONE

      // if()

      this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
        this.LOCATIONs = data['data'];

        var i = -1
        i = this.LOCATIONs.findIndex(x => x.ID == this.branchId);
        var roles = this.LOCATIONs[i]['ID']
        if (i > -1) {
          this.drawerData2.APPOINTMENT_BRANCH = roles
          console.log(this.drawerData2.APPOINTMENT_BRANCH, "")


        }
        console.log(this.drawerData2.SERVICE_ADVISER_ID, "");

        if (this.drawerData2.SERVICE_ADVISER_ID != undefined || this.drawerData2.SERVICE_ADVISER_ID != null || this.drawerData2.SERVICE_ADVISER_ID != '') {
          this.api.getAllemployeeMaster(0, 0, "", "asc", ` AND ORG_ID = ${this.orgId} AND ROLE_IDS LIKE '%48%' AND BRANCH_ID = '${roles}' `).subscribe(data => {
            if (data['code'] == 200) {
              this.EmployeeList24 = data['data'];
            }

          }, err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
        }
      }, err => {
        // this.isSpinning = false;
      });

      if (this.drawerData2.IS_CONFIRMATION_DONE == 0) {
        this.drawerData2.CALL_STATUS = 'R'
        this.drawerData2.CALL_TYPE = "O"
      }
      else {

      }



      // console.log(this.drawerData2, "this.drawerData2")
      // if (this.drawerData2.CALLING_STATUS == 'A' && this.drawerData2.CATEGORY_STATUS == 'C') {
      if (dataa.BOOKING_TIME != null)
        this.drawerData2.BOOKING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.BOOKING_TIME;

      if (dataa.SELF_REMINDER_TIME != null)
        this.drawerData2.SELF_REMINDER_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.SELF_REMINDER_TIME;

      if (dataa.PICK_UP_TIME != null)
        this.drawerData2.PICK_UP_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.PICK_UP_TIME;
      // }
      if (dataa.REMINDER_TIME != null)
        this.drawerData2.REMINDER_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.REMINDER_TIME;
      // IS_CONFIRMATION_DONE:boolean=true

      if (dataa.RESHEDULED_TIME != null)
        this.drawerData2.RESHEDULED_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.RESHEDULED_TIME;

      // if (dataa.REMINDER_DATE != null)

      //   this.drawerData2.REMINDER_DATE = this.datePipe.transform(new Date(), "dd/MM/yyyy") + " " + this.drawerData2.REMINDER_DATE;


      if (this.drawerData2.CALLING_STATUS == 'C' && this.drawerData2.CATEGORY_STATUS == 'C' && this.drawerData2.IS_CONFIRMATION_DONE == 1) {
        this.IS_CONFIRMATION_DONE = true


      }
      else {

        this.IS_CONFIRMATION_DONE = false
      }



      // console.log(this.IS_CONFIRMATION_DONE, "this.IS_CONFIRMATION_DONE =")

      //  this.ActivityLeadMasterComponentVar.pageSize = 8;
      this.ActivityLeadMasterComponentVar.getactivities(dataa);
      // this.drawerData2.ca

      this.ok11 = false
      this.getHolidayList()
      this.getEmployeeList1()
    }
    else {
      // this.message.info('Assign Date of this lead is', "'" + dataa.ASSIGNED_DATE + "'",)
      this.message.info('Assign Date of this lead is ', '<strong>' + dataa.ASSIGNED_DATE + '</strong>');
      this.drawerVisible1 = false;
    }



  }
  ok11: boolean

  EmployeeList24
  getEmployeeList1() {
    this.EmployeeList24 = [];

    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID=48').subscribe(data => {
      if (data['code'] == 200) {
        this.EmployeeList24 = data['data']
      }

    })
  }

  // this.drawerData1 = new ActivityTypeMaster();}
  edit(data: SMRCOLLAPSEMASTER): void {
    this.drawerTitle = " Forms ";
    this.drawerData1 = Object.assign({}, data);
    // this.drawerData1=Object.assign({},data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    // this.SMRdrawerVisible = true;
    this.collapseDrawerVisible = true;
  }



  add(): void {
    this.drawerTitle = " SMR Leads ";
    this.drawerData = new ServiceModuleExcel();
    this.SMRdrawerVisible = true;
    // this.index=0;
  }
  allHolidayList = []
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()
  month = this.today;
  getHolidayList() {
    this.allHolidayList = [];
    var splitmonth = this.month.split("-", -1);
    this.api.getAllHolidays(0, 0, '', 'asc', " AND MONTH(DATE) = '" + splitmonth[1] + "' AND YEAR(DATE) = '" + splitmonth[0] + "'").subscribe(data => {
      if (data['code'] == 200) {
        this.allHolidayList = data['data'];
        // console.log("this.allHolidayList", this.allHolidayList);

      }

    }, err => {
      // console.log(err);
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search1(this.value12, this.value13, '', this.value24);
    // rutuja 
  }

  keyup(event: any) {
    this.search();

  }
  YEAR_COUNT: number
  eleven_TO_fourteen_MONTHS_COUNT: number
  fourteen_TO_twentyfour_MONTHS_COUNT: number
  twentyfour_MONTHS_COUNT: number
  AMS_LOST_COUNT: number
  COINFIRMATION_COUNT: number
  MID_TERM_COUNT: number
  NO_SHOW_COUNT: number
  SALES_DATA_COUNT: number
  SERVICE_DONE_COUNT: number
  SERVICE_DUE_COUNT: number

  CATEGORY_STATUS

  search(reset: boolean = false,) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.getAllcount()

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }
    this.filterQuery = "AND CCE_ID = " + this.cookie.get('userId')

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)

        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


    }, err => {
      // console.log(err);
    });
  }

  searchfilter(reset: boolean = false,) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.getAllcount()

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }
    this.Date = this.datePipe.transform(this.Date, 'yyyy-MM-dd')
    this.filterQuery = ` AND CALLING_STATUS IN ('R') AND CATEGORY_STATUS='${this.value}' AND CCE_ID = ${this.cookie.get('userId')} AND DATE(SELF_REMINDER_DATE) = '${this.Date}'`

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


    }, err => {
      // console.log(err);
    });
  }

  search3(value: any, VALUE) {
    // if (reset) {
    //   this.pageIndex = 1;
    //   this.sortKey = "id";
    //   this.sortValue = "desc"
    // }
    if (value == 'PL') {
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'primary'
      this.Local = 'default'
    } else if (value == 'LS') {
      this.Sold = 'default'
      this.Migrated = 'default'
      this.Wrong = 'default'
      this.Within = 'default'
      this.Permently = 'default'
      this.Local = 'primary'
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }
    this.filterQuery = ` AND LOST_SUB_CATEGOREY='${value}' AND CATEGORY_STATUS='L' AND CCE_ID = ${this.cookie.get('userId')}`
    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


    }, err => {
      // console.log(err);
    });
  }




















}
