import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { log } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { PSFActivityLeadMaster } from 'src/app/Models/psfaddacivity';
import { PSFCOLLAPSEMASTER } from 'src/app/Models/psfmaster';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRMaster } from 'src/app/Models/SMRmaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-dessatisfiedleadstable',
  templateUrl: './dessatisfiedleadstable.component.html',
  styleUrls: ['./dessatisfiedleadstable.component.css']
})
export class DessatisfiedleadstableComponent implements OnInit {
  formTitle = "PSF Dissatisfied Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "PSF_CALLING_DATE";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  isPSFLead: boolean = true;
  PSFdrawerVisible!: boolean;
  drawerTitle!: string;
  NO_OF_DAYS
  index: number = 0;
  filterClass: string = "filter-invisible";
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  // PSFdrawerVisible!: boolean; 
  // dataList:any[]=[]

  columns: string[][] = [["SEQUENCE_NUMBER", "Sequence No."], ["WORKSHOP_CODE", "WorkShop Code"],
  ["RO_NUMBER", "RO Number"], ["PSF_CALLING_DATE", "Calling Date"], ["PSF_CALLING_TIME", "Calling Time"], ["BILL_DATE", "Bill Date"], ["RO_DATE", "RO Date"],
  ["CUSTOMER_NAME", "Customer Name"], ["MOBILE_NUMBER", "Mobile Number"], ["VIN", "VIN"],
  ["REGISTRATION_NO", "Reg Number"], ["MODEL", "Model Name"], ["SERVICE_ADVISER_NAME", "Service Advisor Name"],
  ["WORK_TYPE", "Work Type"], ["SALE_DATE", "Sale Date"], ["SPECIAL_MESSAGE", "Special Massage"],
  ["FOLLOWUP_DATE", "FollowUp Date"], ["PSF_CALLING_DATE", "PSF Date"], ["PSF_CALLING_TIME", "PSF_CALLING_TIME"],
  ["PSF_CALLING_STATUS", "PSF Status"]];
  collapseDrawerVisible: boolean = false;
  drawerData1: PSFCOLLAPSEMASTER = new PSFCOLLAPSEMASTER();
  isClosed = false;
  drawerVisible1: boolean;
  drawerTitle1: string;


  drawerData2: PSFActivityLeadMaster = new PSFActivityLeadMaster();
  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false

  constructor(private api: ApiService, private _exportService: ExportService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.STATUS = 'DS'
    this.getallcce()


  }
  employee = []
  CCE_IDS
  getallcce() {
    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID=43').subscribe(data => {
      if (data['code'] == 200) {
        this.employee = data['data']
        this.CCE_IDS = Number([data['data'][0]['EMPLOYEE_ID']])
      }

      this.search();

    })
  }

  preventSingleClick = false;

  timer: any;

  delay: Number;

  singleClick() {

    this.preventSingleClick = false;

    const delay = 200;

    this.timer = setTimeout(() => {

      if (!this.preventSingleClick) {

        //Navigate on single click

      }

    }, delay);

  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.PSFdrawerVisible = false;
  }

  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }

  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  drawerClose1(): void {
    this.search();
    this.collapseDrawerVisible = false;

    this.isClosed = true
    // this.index=0;
  }
  drawerClose2(): void {
    this.drawerVisible1 = false;
    this.SERVICE_ADVISER_ID = null
    this.btnLoadMoreStatus = null;
    this.CCEREMARK = ''
    this.complaint = ''
    this.search();
  }
  datalist = []
  btnLoadMoreStatus: boolean
  customername: any
  SERVICE_ADVISER_NAME: any
  modile_no: any
  SERVICE_ADVISER_ID: any
  CCEREMARK: any
  feedbackdate: any
  complaint
  RO_NUMBER: any
  IS_HQ_CONCERN: boolean = true
  updateSAdata: boolean = false
  userId = this.cookie.get('userId')
  roleId = Number(this.cookie.get('roleId'));
  data24: PSFActivityLeadMaster = new PSFActivityLeadMaster()

  Activity(dataa) {
    this.drawerTitle1 = "Create New PSF Lead Activity";
    this.btnLoadMoreStatus = null



    this.drawerData2 = Object.assign({}, dataa);
    // this.updateSAdata = false

    // if(dataa.IS_CALLED_BY_SA == 'Y')

    this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + dataa.RO_NUMBER + "'" + " AND IS_CALLED_BY_SA = 'Y'").subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        this.drawerData2 = data['data'][0];

        console.log(this.drawerData2);

        this.drawerData2.ACTIVITY_TYPE_ID = 2
        this.drawerData2.PREVEOUS_WORK_TYPE = dataa.PREVEOUS_WORK_TYPE
        this.feedbackdate = this.drawerData2.FOLLOWUP_DATE
        this.customername = this.drawerData2.CUSTOMER_NAME
        this.SERVICE_ADVISER_NAME = this.drawerData2.SERVICE_ADVISER_NAME
        this.SERVICE_ADVISER_ID = this.drawerData2.SERVICE_ADVISER_ID
        this.CCEREMARK = this.drawerData2.REMARK
        this.complaint = this.drawerData2.COMPLAINT
        this.modile_no = this.drawerData2.MOBILE_NUMBER
        this.RO_NUMBER = this.drawerData2.RO_NUMBER

        if (this.drawerData2.CALLING_TIME != null) {
          this.drawerData2.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.CALLING_TIME;
        }

        this.drawerData2.CALLING_TIME = this.datePipe.transform(new Date(), " yyyy-MM-dd HH:mm:ss")

      } else {
 
        this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + dataa.RO_NUMBER + "'" + " AND IS_ADDED_FIRST = 'Y'").subscribe(data => {
          if (data['code'] == 200 && data['count'] > 0) {
            this.drawerData2 = data['data'][0];
    
            console.log(this.drawerData2);
    
            this.drawerData2.ACTIVITY_TYPE_ID = 2
            this.drawerData2.PREVEOUS_WORK_TYPE = dataa.PREVEOUS_WORK_TYPE
            this.feedbackdate = this.drawerData2.FOLLOWUP_DATE
            this.customername = this.drawerData2.CUSTOMER_NAME
            this.SERVICE_ADVISER_NAME = this.drawerData2.SERVICE_ADVISER_NAME
            this.SERVICE_ADVISER_ID = this.drawerData2.SERVICE_ADVISER_ID
            this.CCEREMARK = this.drawerData2.REMARK
            this.complaint = this.drawerData2.COMPLAINT
            this.modile_no = this.drawerData2.MOBILE_NUMBER
            this.RO_NUMBER = this.drawerData2.RO_NUMBER
    
            if (this.drawerData2.CALLING_TIME != null) {
              this.drawerData2.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.CALLING_TIME;
            }
    
            this.drawerData2.CALLING_TIME = this.datePipe.transform(new Date(), " yyyy-MM-dd HH:mm:ss")
    
          } else {
    
            
            console.log(this.drawerData2);
            this.drawerData2 = Object.assign({}, dataa);
    
            if (dataa.CALLING_TIME != null) {
              this.drawerData2.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + dataa.CALLING_TIME;
            }
    
            this.drawerData2.ACTIVITY_TYPE_ID = 2
            this.feedbackdate = this.drawerData2.FOLLOWUP_DATE
            this.drawerData2.PREVEOUS_WORK_TYPE = dataa.PREVEOUS_WORK_TYPE
            this.customername = this.drawerData2.CUSTOMER_NAME
            this.SERVICE_ADVISER_NAME = this.drawerData2.SERVICE_ADVISER_NAME
            this.SERVICE_ADVISER_ID = this.drawerData2.SERVICE_ADVISER_ID
            this.CCEREMARK = this.drawerData2.REMARK
            this.complaint = this.drawerData2.COMPLAINT
            this.modile_no = this.drawerData2.MOBILE_NUMBER
            this.RO_NUMBER = this.drawerData2.RO_NUMBER
            this.drawerData2.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss")
          }
        }, err => {
          // console.log(err);
        });
      }
    }, err => {
      // console.log(err);
    });
  


    if (dataa.PSF_CATEGORY_STATUS == 'D') {
      this.IS_HQ_CONCERN = false
    } else {
      this.IS_HQ_CONCERN = true
    }

    this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + dataa.RO_NUMBER + "'").subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        this.totalRecords = data['count'];
        this.datalist = data['data'];
        console.log(this.datalist);

      }



      // for (let a = 0; a < this.datalist.length; a++) {
      //   console.log(this.datalist[a]['IS_CALLED_BY_SA']=='Y');


      //   if(this.datalist[a]['IS_CALLED_BY_SA'] == 'Y'){
      //     console.log("rutuja")
      //     this.updateSAdata = true;
      //   } 

      // }
      if (this.datalist.length > 4) {
        this.btnLoadMoreStatus = true;

      } else {
        this.btnLoadMoreStatus = false;
      }

    }, err => {
      // console.log(err);
    });

    console.log(this.roleId,"")
    if (this.roleId == 45) {
      this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + dataa.RO_NUMBER + "'" + " AND IS_CALLED_BY_SA = 'Y'").subscribe(data => {
        if (data['code'] == 200 && data['count'] > 0) {
          this.totalRecords = data['count'];
          this.data24 = data['data'][0]


          console.log(this.data24,"ok")

        }



      
        if (this.datalist.length > 4) {
          this.btnLoadMoreStatus = true;

        } else {
          this.btnLoadMoreStatus = false;
        }

      }, err => {
        // console.log(err);
      });
    }

    this.drawerVisible1 = true;
  }
  edit(data: PSFCOLLAPSEMASTER): void {
    this.drawerTitle = " Forms ";
    this.drawerData1 = Object.assign({}, data);
    // this.drawerData1=Object.assign({},data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    // this.SMRdrawerVisible = true;
    this.collapseDrawerVisible = true;
    this.active1 == false
    this.active2 == false
    this.active3 == false
    this.active4 == false
    this.active5 == false
    this.active6 == false
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.startValue = null
    this.NO_OF_DAYS = null
    this.endValue = null
    this.search(true);

  }
  STATUS
  startValue: any
  endValue: any
  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }
  endOpen = false;
  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }
  applyFilter() {
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.isFilterApplied = 'primary'
    this.filterClass = 'filter-invisible';
    this.loadingRecords = false;

    if (this.STATUS != undefined) {
      this.STATUS = this.STATUS;
    }
    this.search(true);

  }
  importInExcel() {
    console.log("true")
    this.search(false, true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false, false);
  }
  // dataList12:any
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList12.length; i++) {
      obj1['Workshop Code'] = this.dataList12[i]['WORKSHOP_CODE'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Model'] = this.dataList12[i]['MODEL_NAME'];
      obj1['RO Number'] = this.dataList12[i]['RO_NUMBER'];
      obj1['Bill Date'] = this.dataList12[i]['BILL_DATE'];
      obj1['RO Date'] = this.dataList12[i]['RO_DATE'];
      obj1['Customer Name'] = this.dataList12[i]['CUSTOMER_NAME'];
      obj1['Mobile Number'] = this.dataList12[i]['MOBILE_NUMBER'];
      obj1['VIN'] = this.dataList12[i]['VIN'];
      obj1['Registration No'] = this.dataList12[i]['REGISTRATION_NO'];
      obj1['Model'] = this.dataList12[i]['MODEL'];
      obj1['Service Advisor Name'] = this.dataList12[i]['SERVICE_ADVISER_NAME'];
      obj1['Work Type'] = this.dataList12[i]['WORK_TYPE'];
      obj1['Sale Date'] = this.dataList12[i]['SALE_DATE'];
      obj1['Special Message'] = this.dataList12[i]['SPECIAL_MESSAGE'];
      obj1['Follow-up-Date'] = this.dataList12[i]['FOLLOWUP_DATE'];
      // obj1['Bill Date'] = this.dataList12[i]['BILL_DATE'];
      // obj1['Model'] = this.dataList12[i]['MODEL'];
      // obj1['Remark'] = this.dataList12[i]['REMARK'];
      // obj1['Reminder Date'] = this.dataList12[i]['REMINDER_DATE'];
      // obj1['Followup No.'] = this.dataList12[i]['FOLLOWUP_NO'];
      // obj1['Call Date'] = this.dataList12[i]['CALL_DATE'];
      // obj1['Service Count'] = this.dataList12[i]['SERVICE_COUNT'];
      // obj1['Customer Number'] = this.dataList12[i]['CUST_NO'];
      // obj1['Special Message'] = this.dataList12[i]['SPECIAL_MESSAGE'];
      // obj1['RO Date'] = this.dataList12[i]['RO_DATE'];
      // obj1['RO Number'] = this.dataList12[i]['RO_NUMBER'];
      // obj1['Number Of Calls'] = this.dataList12[i]['NUMBER_OF_CALLS'];
      // obj1['Live Streaming'] = this.dataList12[i]['LIVE_STREAMING'];
      // obj1['Status'] = this.dataList12[i]['STATUS'];
      // obj1['Delivery Date'] = this.dataList12[i]['DELIVERY_DATE'];
      // obj1['Location'] = this.dataList12[i]['LOCATION'];
      // obj1['Pincode'] = this.dataList12[i]['PIN_CODE'];
      // obj1['Varient'] = this.dataList12[i]['VARIANT'];
      // obj1['Chassis Number'] = this.dataList12[i]['CHASSIS_NUMBER'];
      // obj1['Sale Consultant Name'] = this.dataList12[i]['SALES_CONSULTANT_NAME'];
      // obj1['Sale Date'] = this.dataList12[i]['SALE_DATE'];
      // obj1['Last Service type'] = this.dataList12[i]['LAST_SERVICE_TYPE'];
      // obj1['Last RO Date'] = this.dataList12[i]['LAST_RO_DATE'];
      // obj1['Segment'] = this.dataList12[i]['SEGMENT'];
      // obj1['Last RO Workshop'] = this.dataList12[i]['LAST_RO_WORKSHOP'];
      // obj1['Last RO Dealer Name'] = this.dataList12[i]['LAST_RO_DEALER_NAME'];
      // obj1['Last RO region'] = this.dataList12[i]['LAST_RO_REGION'];
      // obj1['Name'] = this.dataList12[i]['NAME'];
      // obj1['Work Phone Number'] = this.dataList12[i]['WORK_PHONE_NUMBER'];
      // obj1['Root'] = this.dataList12[i]['ROOT'];
      // obj1['SME Name'] = this.dataList12[i]['SME_NAME'];
      // obj1['Pick Up and Drop'] = this.dataList12[i]['PICK_UP_AND_DROP'];
      // obj1['Lost Customer Name'] = this.dataList12[i]['LOST_CUSTOMER_NAME'];
      // obj1['SRV Model'] = this.dataList12[i]['SRV_MODEL'];
      // obj1['Email'] = this.dataList12[i]['EMAIL'];
      // obj1['Address'] = this.dataList12[i]['ADDRESS'];
      // obj1['Purchase Date'] = this.dataList12[i]['PURCHASE_DATE'];
      // obj1['Preferred DLR No.e'] = this.dataList12[i]['PREFERRED_DLR_NO'];
      // obj1['Office Number'] = this.dataList12[i]['OFFICE_NUMBER'];
      // obj1['Resindence Number'] = this.dataList12[i]['RESIDENCE_NUMBER'];
      // obj1['Mileage'] = this.dataList12[i]['MILEAGE'];
      // obj1['Field 1'] = this.dataList12[i]['FIELD1'];
      // obj1['Field 2'] = this.dataList12[i]['FIELD2'];
      // obj1['Field 3'] = this.dataList12[i]['FIELD3'];
      // obj1['Field 4'] = this.dataList12[i]['FIELD4'];
      // obj1['Field 5'] = this.dataList12[i]['FIELD5'];
      // obj1['Field 6'] = this.dataList12[i]['FIELD6'];
      // obj1['Field 7'] = this.dataList12[i]['FIELD7'];
      // obj1['Field 8'] = this.dataList12[i]['FIELD8'];
      // obj1['Field 9'] = this.dataList12[i]['FIELD9'];
      // obj1['Field 10'] = this.dataList12[i]['FIELD10'];
      // obj1['Resindence Number'] = this.dataList12[i]['NO_OF_INVOICE_GENERATED'];
      arry1.push(Object.assign({}, obj1));
      if (this.STATUS == 'S') {
        if (i == this.dataList12.length - 1) {
          this._exportService.exportExcel(arry1, `PSF satisfied Data ` + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        }
      } else if (this.STATUS == 'D') {
        if (i == this.dataList12.length - 1) {
          this._exportService.exportExcel(arry1, `PSF Delighted Data ` + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        }
      } else if (this.STATUS == 'DS') {
        if (i == this.dataList12.length - 1) {
          this._exportService.exportExcel(arry1, `PSF Dissatisfied Data ` + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        }
      }

    }
  }
  add(): void {
    this.drawerTitle = " PSF Leads ";
    this.drawerData = new ServiceModuleExcel();
    this.PSFdrawerVisible = true;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  keyup(event: any) {
    this.search();

  }

  exportLoading
  dataList12: any = []

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }

    var datefilter = ''
    if ((this.startValue != null || this.startValue != undefined) && (this.endValue != null || this.endValue != undefined)) {
      datefilter = ` AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}'`

    }

    var filter = ''
    if (this.STATUS == 'DS') {
      filter = ` AND PSF_CATEGORY_STATUS ='${this.STATUS}'  AND PSF_CALLING_STATUS ='C' ` + datefilter
    } else {
      filter = ` AND PSF_CATEGORY_STATUS ='${this.STATUS}'` + datefilter
    }
    var NO_OF_DAYSNO = ''

    if ((this.NO_OF_DAYS != null || this.NO_OF_DAYS != undefined)) {
      NO_OF_DAYSNO = ` AND NO_OF_DAYS = '${this.NO_OF_DAYS}'`

    }


    var ccefilter = ''
    if ((this.CCE_IDS != null || this.CCE_IDS != undefined)) {
      ccefilter = ` AND CCE_ID = '${this.CCE_IDS}'`

    }




    if (exportInExcel == false) {
      this.api.getPSFMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ccefilter + filter + NO_OF_DAYSNO).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];

        }
        else {
          this.loadingRecords = false;
        }

      }, err => {
        // console.log(err);
      });
    } else {
      this.exportLoading = true
      this.api.getPSFMastertabledata(0, 0, this.sortKey, sort, likeQuery + ccefilter + filter + NO_OF_DAYSNO).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          // this.SalesExecutiveWiseModelWiseSummary = data['data'];
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList12 = data['data'];
          this.convertInExcel();
        }
        else {
          this.exportLoading = false;
          // this.SalesExecutiveWiseModelWiseSummary = data['data'];
          this.loadingRecords = false;
        }

      }, err => {
        // console.log(err);
      });
    }
  }
}




