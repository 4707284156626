import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { memoMaster } from 'src/app/Models/memo';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';
// import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-incentive-report',
  templateUrl: './incentive-report.component.html',
  styleUrls: ['./incentive-report.component.css']
})
export class IncentiveReportComponent implements OnInit {

  isSpinning:boolean=false
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  filter = ''
  formTitle = "Incentive Report"
  incentiveData = []
  totalRecords = []
  loadingRecords = false;
  searchText: string = "";
  filterQuery: string = '';
  columns1: string[][] = [["MEMO_NO", "Memo No."], ["DATE", "Date"], ["SALES_EXECUTIVE_NAME", "Sales Executive"], ["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"], ["INVOICE_NO", "Invoice No."], ["REMARK", "Remark"], ["STATUS", "Status"]];
  columns: string[][] = [["CREATED_MODIFIED_DATE", "DATE"], ['CUSTOMER_MOBILE_NO', 'Mobile No'], ['CUSTOMER_NAME', 'CUSTOMER NAME'],
  ['EW_AMOUNT', 'EW'], ['FUEl_TYPE_NAME', 'Fuel'], ['MODEL', 'CAR'], ['MODEL_NAME', 'Car'], ['SALES_CONSULTANT_NAME', 'SC'],
  ['SALES_CONSULTANT_RATE', 'Ds SC Slab'], ['SALES_DATE', 'Sales Date '], ['SALES_HEAD_NAME', 'Sales Head Name'],
  ['SALES_HEAD_RATE', 'Ds SH Slab'], ['SALES_MANAGER_NAME', 'ASM'], ['SALES_MANAGER_RATE', 'Ds Manager Slab']
    , ['SOT_AMOUNT', 'SOT'], ['TRANSMISSION_TYPE_NAME', 'Transmission Type'], ['VARIENT_NAME', 'Vareint Name'],
  ['YEAR', 'Year'], ['INS_SALES_CONSULTANT_RATE', 'INS SC Slab'], ['INS_SALES_HEAD_ID', 'INS Sh Slab'], ['INS_SALES_HEAD_RATE', 'INS SH Slab'],
  ['INS_SALES_MANAGER_ID', 'Ins Sales Manager Id'], ['INS_SALES_MANAGER_RATE', 'INS Manager Slab'], ['MONTH', 'Month'],
  ['VARIENT_NAME', 'Vareint Name'], ['IS_SOT', 'SOT'], ['IS_EW', 'EW'], ['IS_INSURANCE', 'HAP']]
  isVisible = false;
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  userId = this.cookie.get('userId')
  ClusterId =Number(this.cookie.get("clusterId"));

  ACCOUNT_DATETIME = new Date()
  questionary = new questionaryMaster();
  employeeget = new EmployeeMaster()
  advanceArray: [];
  memoDetailsArray: [];
  quotedPrice: number;
  memoSignature = new memoSignatureDetails();
  dataid: any;
  discount1 = 0
  discount2 = 0
  exchange = 0
  sum1 = 0
  sum2 = 0
  sum3 = 0
  sum4 = 0
  sum5 = 0
  sum6 = 0
  sum7 = 0
  sum8 = 0
  sum9 = 0
  // sum3 = 0
  rTo: any
  tcs: any
  sdfsdf: any
  sdfsdf2: any
  sdfsdf3: any
  sdfsdf4: any
  sdfsdf5: any
  sdfsdf6: any
  sdfsdf7: any
  formname: any
  filterClass: string = "filter-invisible";
  isFilterApplied: any = "default";
  DATE = [];
  START_DATE: any;
  END_DATE: any;

  signature = sessionStorage.getItem('signature')
  accountname = sessionStorage.getItem('accountname')
  data: memoMaster = new memoMaster()
  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService, private _exportService: ExportService) { }

  ngOnInit() {
    this.DATE[0] = new Date();
    this.DATE[1] = new Date();
    this.search()

  }
  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  keyup(event: any) {
    this.search();
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }

    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null) {
      this.START_DATE = this.DATE[0]
    }

    if (this.DATE[1] != null) {
      this.END_DATE = this.DATE[1]
    }
    this.search(true);
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.DATE = [];
    this.DATE[0] = new Date();
    this.DATE[1] = new Date();
    this.search(true)
  }

  incentive = []
  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.START_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.END_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var filter = ''

    if(this.ClusterId==0){
      filter=''
    }
    else {
      filter = 'AND CLUSTER_ID = ' + this.ClusterId

    }

    if (exportInExcel == false) {
      console.log("loading Begining")
      this.loadingRecords = true;
      this.api.getincentive(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery + filter, this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.incentiveData = data['data'];
          this.filterClass = 'filter-invisible';
        
        }
      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }

    else {
      this.exportLoading = true;
      this.api.getincentive(0, 0, this.sortKey, this.sortValue,likeQuery + filter, this.START_DATE, this.END_DATE).subscribe(data => {
        console.log(data['data']);
        this.exportLoading = false;
        this.incentive = data['data'];
        this.convertInExcel();

      },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
    }

  }

  // exportexcel(): void {
  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Back Office Employee Report');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }



  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.incentive.length; i++) {

      obj1['Date'] = this.datePipe.transform(this.incentive[i]['SALES_DATE'],'dd-MMM');
      obj1['Customer Name'] = this.incentive[i]['CUSTOMER_NAME'];
      obj1['CAR'] = this.incentive[i]['MODEL'];
      obj1['Ds SC Slab'] = this.incentive[i]['SALES_CONSULTANT_RATE'];
      obj1['Ds Manager Slab'] = this.incentive[i]['SALES_MANAGER_RATE'];
      obj1['Ds SH Slab'] = this.incentive[i]['SALES_HEAD_RATE'];
      obj1['HAP'] = this.incentive[i]['IS_INSURANCE'];
      obj1['INS SC Slab'] = this.incentive[i]['INS_SALES_CONSULTANT_RATE'];
      obj1['INS Manager Slab'] = this.incentive[i]['INS_SALES_MANAGER_RATE'];
      obj1['INS Sh Slab'] = this.incentive[i]['INS_SALES_HEAD_RATE'];
      obj1['EW'] = this.incentive[i]['IS_EW'];
      obj1['SOT'] = this.incentive[i]['IS_SOT'];
      obj1['SC'] = this.incentive[i]['SALES_CONSULTANT_NAME'];
      obj1['ASM'] = this.incentive[i]['SALES_MANAGER_NAME'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.incentive.length - 1) {
        this._exportService.exportExcel(arry1, 'Incentive Report' + this.datePipe.transform(new Date(), 'dd-MMM-yy'));
      }
    }
  }
  isVisible1 = false
  drawerClose12() {
    this.isVisible1 = false;
  }

  getStatusFullForm(status) {
    if (status == "RQ")
      return "Requested";

    else if (status == "FW")
      return "Forward";

    else if (status == "AP")
      return "Approved";

    else if (status == "RJ")
      return "Rejected";
  }
  dataList = []
  getAttachment(data) {
    this.isVisible = true;
    console.log(data);
    this.loadingRecords = true;
    this.api.getMemoDetails(0, 0, '', '', " AND ID=" + data.MEMO_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        console.log(this.dataList);

      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }
  sumoffall2 = 0
  sumofall = 0
  drawerClose1(): void {
    this.isVisible = false;
    this.advanceArray = []
    this.sumoffall2 = 0
    this.sumofall = 0
    this.discount2 = 0
    this.discount1 = 0
    this.exchange = 0
    this.quotedPrice = 0
    this.sum2 = 0
    this.sum3 = 0
    this.sum4 = 0
    this.sum5 = 0
    this.sum6 = 0
    this.sum7 = 0
    this.sum8 = 0
    this.sum9 = 0


    this.memoDetailsArray = []



  }
  drawerFormPrintData
  // printForm(data: memoMaster) {
  // console.log(data);

  //   this.formname = "Memo.Pdf"
  //   this.drawerFormPrintData = Object.assign({}, data);



  //   this.api.getQuestionaryMaster(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + data.ID).subscribe(data => {

  //     if (data['code'] == 200 && data['count'] > 0) {

  //       this.questionary = data['data'][0];


  //       if (this.questionary.CORPORATE_DISCOUNT != null) {

  //         this.discount1 = data['data'][0]['CORPORATE_DISCOUNT']
  //         console.log(this.discount1,"discount1")
  //       }
  //       else {
  //         this.discount1 = 0
  //       }
  //       if (this.questionary.EXCHANGE != null) {

  //         this.exchange = data['data'][0]['EXCHANGE']
  //         console.log(this.exchange,"exchange")
  //       }
  //       else {
  //         this.discount1 = 0
  //       }
  //       console.log(this.questionary);
  //       this.getcal()
  //       this.getcal2()
  //     }



  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });

  //   this.api.getAdvanceMaster(this.pageIndex, this.pageSize, "", "", "AND QUOTATION_ID=" + data.QUOTATION_ID).subscribe(data => {

  //     if (data['code'] == 200 && data['count'] > 0) {
  //       this.totalRecords = data['count'];
  //       this.advanceArray = data['data'];

  //       for (let i = 0; i < this.advanceArray.length; i++) {
  //         this.discount2 = this.discount2 + Number(this.advanceArray[i]['AMOUNT'])

  //         console.log(this.discount2,"discount2" );


  //         if (i + 1 == this.advanceArray.length) {
  //           this.getcal2()
  //         }

  //       }
  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });


  //   this.api.getMemoDetails1(this.pageIndex, this.pageSize, "", "", "AND MEMO_MASTER_ID=" + data.ID).subscribe(data => {

  //     if (data['code'] == 200 && data['count'] > 0) {
  //       // this.totalRecords = data['count'];
  //       this.memoDetailsArray = data['data'];
  //       console.log(this.memoDetailsArray);

  //       this.quotedPrice = 0

  //       for (let i = 0; i < this.memoDetailsArray.length; i++) {
  //         this.quotedPrice = this.quotedPrice + Number(this.memoDetailsArray[i]['CUSTOMER_QUOTED_PRICE'])

  //         console.log(this.quotedPrice);
  //         if (i + 1 == this.memoDetailsArray.length) {
  //           this.getcal()

  //         }
  //       }

  //       for (let j = 0; j < this.memoDetailsArray.length; j++) {

  //         this.sum1 = Number(this.memoDetailsArray[j]['CUSTOMER_DISCOUNT'])
  //         this.sum2 = Number(this.memoDetailsArray[j]['HEAD_DISCOUNT'])

  //         console.log(this.sum1,"sum1");
  //         console.log(this.sum2,"sum2");


  //         // this.sdfsdf=
  //         this.sdfsdf = this.memoDetailsArray[j]['COMPONENT']

  //         if (this.sdfsdf == 'SOT') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum3 = this.sum1 + this.sum2

  //             console.log(this.sum3, "sum3");

  //           }
  //         }

  //         if (this.sdfsdf == 'Invoice amount') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum4 = this.sum1 + this.sum2

  //             console.log(this.sum4, "sum4");

  //           }
  //         }

  //         if (this.sdfsdf == 'RTO') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum5 = this.sum1 + this.sum2

  //             console.log(this.sum5, "sum5");

  //           }
  //         }

  //         if (this.sdfsdf == 'Accessories') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum6 = this.sum1 + this.sum2

  //             console.log(this.sum6, "sum6");

  //           }
  //         }

  //         if (this.sdfsdf == 'Insurance') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum7 = this.sum1 + this.sum2

  //             console.log(this.sum7, "sum7");

  //           }
  //         }

  //         if (this.sdfsdf == 'Additional Discount') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum8 = this.sum1 + this.sum2

  //             console.log(this.sum8, "sum8");

  //           }
  //         }

  //         if (this.sdfsdf == 'Extended Warranty') {
  //           if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

  //             this.sum9 = this.sum1 + this.sum2

  //             console.log(this.sum9, "sum9");

  //           }
  //         }

  //         if (j + 1 == this.memoDetailsArray.length) {
  //           this.getcal2()
  //         }




  //       }

  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });

  //   this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + data.ID).subscribe(data => {

  //     if (data['code'] == 200 && data['count'] > 0) {
  //       // this.totalRecords = data['count'];
  //       this.memoSignature = data['data'][0];
  //       // console.log(this.memoSignature, 'signature');
  //       // this.getBase64ImageFromUrl((this.api.retriveimgUrl + 'signature/' + this.memoSignature.CONSULTANT_SIGNATURE))
  //       //   .then((result: any) => {
  //       //     console.log(result)
  //       //     this.memoSignature.CONSULTANT_SIGNATURE = result;

  //           this.isVisible = true;
  //       //   })
  //       //   .catch(err => console.error(err));


  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });



  // }
  memo12
  add1
  printForm(data: memoMaster) {

    this.formname = "Memo.Pdf"
    this.drawerFormPrintData = Object.assign({}, data);
    // this.isLoading=true
    // this.acc_remark= data1['ACC_REMARK']
    // console.log(this.acc_remark,"this.acc_remark");


    var filter = "AND ID=" + data.ID
    this.api.getMemoDetails(this.pageIndex, this.pageSize, "", "", filter).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {
        // this.totalRecords = data['count'];
        this.memo12 = data['data'][0];
        this.add1 = data['data'][0]['ADDITIONAL_DISCOUNT']
        this.getcal2()
        // this.isLoading=false
        this.isVisible = true;

        this.api.getQuestionaryMaster(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {

            this.questionary = data['data'][0];


            if (this.questionary.CORPORATE_DISCOUNT != null) {

              this.discount1 = data['data'][0]['CORPORATE_DISCOUNT']
              console.log(this.discount1, "discount1")
            }
            else {
              this.discount1 = 0
            }
            if (this.questionary.EXCHANGE != null) {

              this.exchange = data['data'][0]['EXCHANGE']
              console.log(this.exchange, "exchange")
            }
            else {
              this.discount1 = 0
            }
            console.log(this.questionary);
            this.getcal()
            this.getcal2()
          }



        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getAdvanceMaster(this.pageIndex, this.pageSize, "", "", "AND QUOTATION_ID=" + this.memo12.QUOTATION_ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            this.totalRecords = data['count'];
            this.advanceArray = data['data'];

            for (let i = 0; i < this.advanceArray.length; i++) {
              this.discount2 = this.discount2 + Number(this.advanceArray[i]['AMOUNT'])

              console.log(this.discount2, "discount2");


              if (i + 1 == this.advanceArray.length) {
                this.getcal2()
              }

            }
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });


        this.api.getMemoDetails1(this.pageIndex, this.pageSize, "", "", "AND MEMO_MASTER_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoDetailsArray = data['data'];
            console.log(this.memoDetailsArray);

            this.quotedPrice = 0

            for (let i = 0; i < this.memoDetailsArray.length; i++) {
              this.quotedPrice = this.quotedPrice + Number(this.memoDetailsArray[i]['CUSTOMER_QUOTED_PRICE'])

              console.log(this.quotedPrice);
              if (i + 1 == this.memoDetailsArray.length) {
                this.getcal()

              }
            }

            for (let j = 0; j < this.memoDetailsArray.length; j++) {

              this.sum1 = Number(this.memoDetailsArray[j]['CUSTOMER_DISCOUNT'])
              this.sum2 = Number(this.memoDetailsArray[j]['HEAD_DISCOUNT'])

              console.log(this.sum1, "sum1");
              console.log(this.sum2, "sum2");


              // this.sdfsdf=
              this.sdfsdf = this.memoDetailsArray[j]['COMPONENT']

              if (this.sdfsdf == 'SOT') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum3 = this.sum1 + this.sum2

                  console.log(this.sum3, "sum3");

                }
              }

              if (this.sdfsdf == 'Invoice amount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum4 = this.sum1 + this.sum2

                  console.log(this.sum4, "sum4");

                }
              }

              if (this.sdfsdf == 'RTO') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum5 = this.sum1 + this.sum2

                  console.log(this.sum5, "sum5");

                }
              }

              if (this.sdfsdf == 'Accessories') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum6 = this.sum1 + this.sum2

                  console.log(this.sum6, "sum6");

                }
              }

              if (this.sdfsdf == 'Insurance') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum7 = this.sum1 + this.sum2

                  console.log(this.sum7, "sum7");

                }
              }

              if (this.sdfsdf == 'Additional Discount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum8 = this.sum1 + this.sum2

                  console.log(this.sum8, "sum8");

                }
              }

              if (this.sdfsdf == 'Extended Warranty') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum9 = this.sum1 + this.sum2

                  console.log(this.sum9, "sum9");

                }
              }

              if (j + 1 == this.memoDetailsArray.length) {
                this.getcal2()
              }




            }

          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoSignature = data['data'][0];


            this.isVisible = true;



          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });



  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }


  getcal() {

    if (this.quotedPrice != null && this.quotedPrice != undefined && this.discount1 != undefined && this.discount1 != null) {
      this.sumofall = Number(this.quotedPrice) + Number(this.discount1)
      console.log(this.sumofall, "Total1");

      // if (this.sumoffall2 >= this.sumofall) {
      //   this.getcal4()
      // }

      // else {
      //   this.getcal3()
      // }


    }
  }

  getcal2() {
    if (this.discount2 != null && this.discount2 != undefined && this.sum3 != undefined && this.sum3 != null && this.sum4 != undefined && this.sum4 != null
      && this.sum5 != undefined && this.sum5 != null && this.sum6 != undefined && this.sum6 != null && this.sum7 != undefined && this.sum7 != null && this.sum8 != undefined && this.sum8 != null && this.sum9 != undefined && this.sum9 != null && this.discount1 != undefined && this.discount1 != null
      && this.exchange != undefined && this.exchange != null) {

      this.sumoffall2 =
        Number(this.discount2) +
        Number(this.sum3) + Number(this.sum4) +
        Number(this.sum5) + Number(this.sum6) +
        Number(this.sum7) + Number(this.sum8) +
        Number(this.sum9) + Number(this.discount1) + Number(this.exchange)

      console.log(this.sumoffall2, "total2");

      if (this.sumofall >= this.sumoffall2) {
        this.getcal3()
      }
      else {
        this.getcal4()
      }


    }


  }

  getcal3() {

    console.log(this.sumofall, "ok", this.sumoffall2, "ok2");
    console.log();


    if (this.sumofall > this.sumoffall2) {
      this.sdfsdf4 = this.sumofall - this.sumoffall2
      this.sdfsdf5 = 0
      console.log(this.sdfsdf4, "shortfall");


    }
    else {
      this.sdfsdf4 = 0
    }


  }

  getcal4() {
    if (this.sumoffall2 > this.sumofall) {
      this.sdfsdf5 = this.sumoffall2 - this.sumofall
      this.sdfsdf4 = 0
      console.log(this.sdfsdf5);

    }

    else {
      this.sdfsdf5 = 0
    }
  }






}
