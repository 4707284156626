import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { memoMaster } from 'src/app/Models/memo';
import { memoDetails } from 'src/app/Models/memoDetails';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-smmemoform',
  templateUrl: './smmemoform.component.html',
  styleUrls: ['./smmemoform.component.css']
})
export class SMmemoformComponent implements OnInit {

  formTitle = "Memo Details";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList: any = [];
  loadingRecords = true;
  sortKey: string = "ID";
  sortValue: string = "desc";
  searchText: string = "";
  columns: string[][] = [["MEMO_NO", "Memo No."], ["DATE", "Date"], ["SALES_EXECUTIVE_NAME", "Sales Executive"], ["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"], ["INVOICE_NO", "Invoice No."], ["STATUS", "Status"]];
  drawerVisible: boolean;
  drawerTitle: string;
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  visible = false;
  modalVisible = false
  visible1 = false
  questionary = new questionaryMaster();
  quotationId: any
  saleHierarchy = []
  APPROVER_ID: number = 0;
  memoSignature: memoSignatureDetails = new memoSignatureDetails()
  userid = this.cookie.get('userId')
  // memo: createMemo = new createMemo()
  acc_remark1
  memo12
  iid
  recieptData = []
  memoGet: memoMaster = new memoMaster()
  memoDetailsArray = [new memoDetails()]
  array = []
  COMPANY_NAME = ""
  SAMBHADH_SCHEME = 0

  constructor(private _exportService: ExportService, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }
  showModal(): void {
    this.modalVisible = true;
  }

  close(): void {
    this.visible = false;
  }

  ngOnInit() {
    // this.getsalesHierarchy()
    // this.getAccountantEmployeeList()
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  close1(): void {
    this.visible1 = false;
  }
  status: any = []
  exportLoading: boolean = false
  dataList1: any
  startValue = new Date()
  endValue: any = new Date()
  TO_DATE: any = new Date()
  FROM_DATE: any = new Date(this.startValue.getTime() - 7 * 24 * 60 * 60 * 1000);
  userId = this.cookie.get('userId')

  moduleStartDateHandle(open: boolean) {


    this.TO_DATE = new Date();

    // this.moduleEndDatePicker.open();

  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var memoDateFilter = "";
    if (this.FROM_DATE != null) {
      this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd')
    }
    if (this.TO_DATE != null) {
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd')
    }

    var memoStatusFilter: any = [];
    if (this.STATUS.length > 0) {
      memoStatusFilter = this.STATUS

    } else {
      memoStatusFilter = ["RQ"];
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getMemoDetails12(this.userId, memoStatusFilter, this.FROM_DATE, this.TO_DATE, likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.tempData = this.dataList
          // this.status= data['data'][0]['STATUS']
          console.log(this.status, "status");

          // for (let i = 0; i < this.dataList.length; i++) {
          //   this.dataList[i]['QUOTATION_ID']
          //   this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + this.dataList[i]['QUOTATION_ID']).subscribe(data => {
          //     this.recieptData = data['data']

          //   })
          // }

        }
      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
    else {
      this.exportLoading = false;
      this.api.getMemoDetails12(this.userId, memoStatusFilter, this.FROM_DATE, this.TO_DATE, likeQuery).subscribe(data => {
        console.log(data)
        this.exportLoading = false;
        this.dataList1 = data['data'];
        this.convertInExcel();
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  openDrawer(data): void {
    console.log(data);
    this.visible1 = true
    this.api.getAdvanceReciept(0, 0, '', "desc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data['data']);
        this.recieptData = data['data']
        for (let i = 0; i < this.recieptData.length; i++) {
          console.log(this.recieptData[i]['RECEIPT_PHOTO']);
        }
      }
    })
  }

  get12(ID) {
    this.api.getMemoDetails1(this.pageIndex, this.pageSize, "", "", "AND MEMO_MASTER_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {
        // this.totalRecords = data['count'];
        this.memoDetailsArray = data['data'];
        console.log(this.memoDetailsArray);

      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  DATE = [];
  STATUS = [];
  get(ID) {
    this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memoSignature = data['data'][0];
        console.log(this.memoSignature, "this.memoSignature");



      }
    });

    this.api.getQuestionaryMaster(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.questionary = data['data'][0]
      }



    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });


  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    if ((this.DATE.length > 0) || (this.STATUS.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.DATE = [];
    this.STATUS = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  getStatusFullForm(status) {
    if (status == "RQ")
      return "Requested";

    else if (status == "FW")
      return "Forward";

    else if (status == "AP")
      return "Approved";

    else if (status == "RJ")
      return "Rejected";
  }

  getAttachment(url: string) {
    window.open(this.api.retriveimgUrl + "memoFile/" + url);
  }

  isApproveVisible: boolean = false;
  tempData = new Object();
  INVOICE_NO: string = "";

  // openApproveModal(data: any) {
  //   // this.modalVisible = true
  //   this.isApproveVisible = true;
  //   
  // }

  openApproveModal(data: any) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisible = true;
    this.get12(data.ID)
    this.get(data.ID)
    this.getsalesHierarchy()
  }

  handleApproveCancel() {
    this.isApproveVisible = false;
  }

  handleApproveOk() {
    let isOk = true;
    if (!this.APPROVER_ID || !this.APPROVER_ID.toString()) {
      this.message.error('Please enter Approver Id', '')

      //   // }
    }


    if (isOk) {


      this.isApproveModalConfirmLoading = true;
      this.tempData["STATUS"] = "FW";
      this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isApproveModalConfirmLoading = false;
          // this.isApproveModalConfirmLoading = true;


          // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

          console.log(this.APPROVER_ID);
          this.tempData["CREATOR_ID"] = this.userid;
          this.tempData["STATUS"] = "RQ";
          this.tempData["APPROVER_ID"] = this.APPROVER_ID;
          this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
          this.tempData["ID"] = 0
          this.tempData["MEMO_DETAILS"] = this.memoDetailsArray
          this.tempData["CORPORATE_DISCOUNT"] = this.questionary.CORPORATE_DISCOUNT
          this.tempData["EXCHANGE"] = this.questionary.EXCHANGE
          this.tempData["IS_FINANCE"] = this.questionary.IS_FINANCE
          this.tempData["FINANCE_TYPE"] = this.questionary.FINANCE_TYPE
          this.tempData["BANK_NAME"] = this.questionary.BANK_NAME
          this.tempData["INSURANCE_TYPE"] = this.questionary.INSURANCE_TYPE
          this.tempData["INSURANCE_NAME"] = this.questionary['INSURANCE_NAME']
          this.tempData["CGST_NO"] = this.questionary.CGST_NO
          this.tempData["VIN_NUMBER"] = this.questionary['VIN_NUMBER']
          this.tempData["GM_REMARK"] = ""
          this.tempData["SH_REMARK"] = ""
          this.tempData["REMARK"] = ""
          this.tempData["IS_RSA_AVAILABLE"] = this.questionary['IS_RSA_AVAILABLE']
          this.api.createMemo(this.tempData).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isApproveModalConfirmLoading = false;
              this.memoSignature.MEMO_ID = successCode['data']
              this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                if (successCode['code'] == 200) {
                  this.message.success('memo Signature create sucessfully..', "")
                }

              }, err => {
                if (err['ok'] == false)
                  this.message.error("Server Not Found", "");
              });
              // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
              // this.updatesign()
              this.message.success("Approve Status Updated Successfully", "");
              this.isApproveVisible = false;
              this.search(false);



            } else {
              this.isApproveModalConfirmLoading = false;
              this.message.error("Approve Status Updation Failed", "");
              this.search(false);
            }
          });


        } else {
          this.isApproveModalConfirmLoading = false;
          this.message.error("Invoice No. Updation Failed", "");
          this.search(false);
        }
      });
    }





  }

  isRejectVisible: boolean = false;
  REMARK: string = "";

  openRejectModal(data: any) {
    this.tempData = new Object();
    this.tempData = data;
    this.REMARK = "";
    this.isRejectVisible = true;
  }

  handleRejectCancel() {
    this.isRejectVisible = false;
  }

  handleRejectOk() {
    let isOk = true;

    if (this.REMARK == undefined || this.REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please Enter Valid Remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["REMARK"] = this.REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
          this.message.success("Remark Updated Successfully", "");
          this.isRejectVisible = false;
          this.search(false);

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          this.search(false);
        }
      });
    }
  }

  setQuotationStatus(quotationID: number, status: string) {
    this.api.setQuotationStatus(quotationID, status).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Quotation Updated Successfully", "");

      } else {
        this.message.error("Quotation Updation Failed", "");
      }
    });
  }




  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  isApproveModalConfirmLoading: boolean = false;
  isRejectModalConfirmLoading: boolean = false;
  allAccountantEmployeeList = [];
  getsalesHierarchy() {
    this.api.getsalesHierarchy('AND EMPLOYEE_ID= ' + this.cookie.get('userId')).subscribe(successCode => {
      if (successCode['code'] == 200) {
        console.log(successCode['data']);
        this.saleHierarchy = successCode['data']
      }
    })
  }

  SALESMAN: any
  // handleOk(): void {
  //   // if (!this.APPROVER_ID || !this.APPROVER_ID.toString()) {
  //   //   this.message.error('Please enter salseman', '')
  //   // }
  //   // else {

  //   //      this.api.createMemo(this.memoGet).subscribe(data => {
  //   //        if(data['code']==200 ){
  //   //         console.log(data['data']);
  //   //         this.modalVisible = false;
  //   //        }

  //   //   })

  //   // }


  // }

  // handleApproveOk() {
  //   }
  // }



  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.modalVisible = false;
  }

  imageModalVisible = false

  openPdf(data: memoMaster, i: any): void {
    // this.loadingRecords = true
    this.imageModalVisible = true
    // this.isSpinning = true
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        // this.isSpinning = false
        this.loadingRecords = false
      }
    })
  }

  // openReceipt(data: memoMaster, i: any) {
  //   this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.recieptData = data['data']
  //       console.log(this.recieptData);
  //       // this.isSpinning = false
  //       this.loadingRecords = false
  //       window.open(this.api.retriveimgUrl + "receiptPhoto/" + this.recieptData[i]['RECEIPT_PHOTO']);
  //       console.log(this.recieptData[i]['RECEIPT_PHOTO']);
  //     }
  //   })

  // }
  handleimgCancel() {
    this.imageModalVisible = false
  }

  //memoform //
  isVisible12 = false
  drawerFormPrintData: any
  employeeget = new EmployeeMaster()
  advanceArray: [];
  // memoSignature = new memoSignatureDetails();
  dataid: any;
  discount1 = 0
  discount2 = 0
  exchange = 0
  sum1 = 0
  sum2 = 0
  sum3 = 0
  sum4 = 0
  sum5 = 0
  sum6 = 0
  sum7 = 0
  sum8 = 0
  sum9 = 0
  // sum3 = 0
  rTo: any
  tcs: any
  sdfsdf: any
  sdfsdf2: any
  sdfsdf3: any
  sdfsdf4: any
  sdfsdf5: any
  sdfsdf6: any
  sdfsdf7: any
  formname: any
  sumofall = 0
  sumoffall2 = 0
  signature = sessionStorage.getItem('signature')
  accountname = sessionStorage.getItem('accountname')
  data: memoMaster = new memoMaster()
  quotedPrice: number;
  // memoDetailsArray: [];
  drawerClose1(): void {
    this.isVisible12 = false;
    this.advanceArray = []
    this.sumoffall2 = 0
    this.sumofall = 0
    this.discount2 = 0
    this.discount1 = 0
    this.exchange = 0
    this.quotedPrice = 0
    this.sum2 = 0
    this.sum3 = 0
    this.sum4 = 0
    this.sum5 = 0
    this.sum6 = 0
    this.sum7 = 0
    this.sum8 = 0
    this.sum9 = 0


    this.memoDetailsArray = []
    this.search()


  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  isLoading = false
  add1 = 0
  date24: any
  printForm(data: memoMaster) {

    this.formname = "Memo.Pdf"
    this.drawerFormPrintData = Object.assign({}, data);
    this.isLoading = true
    sessionStorage.setItem('DATE', data.DATE)
    this.date24 = data.DATE


    if (data.STATUS == "FW") {
      var filter = "AND QUOTATION_ID=" + data.QUOTATION_ID
    }


    else if (data.STATUS == "AP") {
      var filter = "AND QUOTATION_ID=" + data.QUOTATION_ID
    }

    else {
      var filter = "AND ID=" + data.ID
    }

    this.api.getMemoDetails(0, 0, "ID", "desc", filter).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memo12 = data['data'][0];
        this.isLoading = false
        if (this.memo12 != null) {
          if (data['data'][0]['ADDITIONAL_DISCOUNT'] != null)
            this.add1 = data['data'][0]['ADDITIONAL_DISCOUNT']
          else
            this.add1 = 0
          console.log(this.add1, "ADD1");
        }
        else {
          this.add1 = 0
        }
        this.getcal2()
        console.log(this.add1, "this.add1")
        this.isVisible12 = true;
        this.api.getmemoSignatureDetails(0, 0, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoSignature = data['data'][0];


            this.isVisible12 = true;



          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getQuestionaryMaster(0, 0, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {

            this.questionary = data['data'][0];


            if (this.questionary.CORPORATE_DISCOUNT != null &&  this.questionary.CORPORATE_DISCOUNT != "") {

              this.discount1 = parseInt(data['data'][0]['CORPORATE_DISCOUNT'])
              console.log(this.discount1, "discount1")
            }
            else {
              this.discount1 = 0
              console.log(this.discount1, "discount1")
            }
            if (this.questionary.EXCHANGE != null && this.questionary.EXCHANGE != "") {
              console.log(this.questionary.EXCHANGE != null && this.questionary.EXCHANGE != "", "")
              this.exchange = parseInt(data['data'][0]['EXCHANGE'])
              console.log(this.exchange, "exchange")
            }
            else {
              this.exchange = 0
            }

            if (this.questionary.SAMBHADH_SCHEME != null &&  this.questionary.SAMBHADH_SCHEME != "") {
              this.SAMBHADH_SCHEME = parseInt(data['data'][0]['SAMBHADH_SCHEME'])
              console.log(this.SAMBHADH_SCHEME, "SAMBHADH_SCHEME")
            }
            else {
              this.SAMBHADH_SCHEME = 0
              console.log(this.SAMBHADH_SCHEME, "SAMBHADH_SCHEME")
            }
            console.log(this.questionary);
            this.getcal()
            this.getcal2()
          }



        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getAdvanceMaster(0, 0, "", "", "AND QUOTATION_ID=" + this.memo12.QUOTATION_ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            this.totalRecords = data['count'];
            this.advanceArray = data['data'];

            for (let i = 0; i < this.advanceArray.length; i++) {
              this.discount2 = this.discount2 + Number(this.advanceArray[i]['AMOUNT'])

              console.log(this.discount2, "discount2");


              if (i + 1 == this.advanceArray.length) {
                this.getcal2()
              }

            }
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });


        this.api.getMemoDetails1(0, 0, "", "", "AND MEMO_MASTER_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoDetailsArray = data['data'];
            console.log(this.memoDetailsArray);

            this.quotedPrice = 0

            for (let i = 0; i < this.memoDetailsArray.length; i++) {
              this.quotedPrice = this.quotedPrice + Number(this.memoDetailsArray[i]['CUSTOMER_QUOTED_PRICE'])

              console.log(this.quotedPrice);
              if (i + 1 == this.memoDetailsArray.length) {
                this.getcal()

              }
            }

            for (let j = 0; j < this.memoDetailsArray.length; j++) {

              this.sum1 = Number(this.memoDetailsArray[j]['CUSTOMER_DISCOUNT'])
              this.sum2 = Number(this.memoDetailsArray[j]['HEAD_DISCOUNT'])

              console.log(this.sum1, "sum1");
              console.log(this.sum2, "sum2");


              // this.sdfsdf=
              this.sdfsdf = this.memoDetailsArray[j]['COMPONENT']

              if (this.sdfsdf == 'SOT') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum3 = this.sum1 + this.sum2

                  console.log(this.sum3, "sum3");

                }
              }

              if (this.sdfsdf == 'Invoice amount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum4 = this.sum1 + this.sum2

                  console.log(this.sum4, "sum4");

                }
              }

              if (this.sdfsdf == 'RTO') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum5 = this.sum1 + this.sum2

                  console.log(this.sum5, "sum5");

                }
              }

              if (this.sdfsdf == 'Accessories') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum6 = this.sum1 + this.sum2

                  console.log(this.sum6, "sum6");

                }
              }

              if (this.sdfsdf == 'Insurance') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum7 = this.sum1 + this.sum2

                  console.log(this.sum7, "sum7");

                }
              }

              if (this.sdfsdf == 'Additional Discount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum8 = this.sum1 + this.sum2

                  console.log(this.sum8, "sum8");

                }
              }

              if (this.sdfsdf == 'Extended Warranty') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum9 = this.sum1 + this.sum2

                  console.log(this.sum9, "sum9");

                }
              }

              if (j + 1 == this.memoDetailsArray.length) {
                this.getcal2()
              }




            }

          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });


      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });



  }

  corporatedis: any
  samband: any

  getcal() {

    if (this.quotedPrice != null && this.quotedPrice != undefined && this.discount1 != undefined && this.discount1 != null) {


      if (this.drawerFormPrintData.IS_CORPORATE == 1) {
        this.corporatedis = this.discount1
      }
      else {
        this.corporatedis = 0
      }

      if (this.drawerFormPrintData.IS_SAMBHADH_SCHEME == 1) {
        this.samband = this.SAMBHADH_SCHEME
      }
      else {
        this.samband = 0
      }



      this.sumofall = Number(this.quotedPrice) + Number(this.corporatedis) + Number(this.samband)
      console.log(this.quotedPrice, this.corporatedis, this.samband, this.sumofall, "Total1");

      // if (this.sumoffall2 >= this.sumofall) {
      //   this.getcal4()
      // }

      // else {
      //   this.getcal3()
      // }


    }
  }

  getcal2() {


    if (this.discount2 != null && this.discount2 != undefined && this.sum3 != undefined && this.sum3 != null && this.sum4 != undefined && this.sum4 != null
      && this.sum5 != undefined && this.sum5 != null && this.sum6 != undefined && this.sum6 != null && this.sum7 != undefined && this.sum7 != null && this.sum8 != undefined && this.sum8 != null && this.sum9 != undefined && this.sum9 != null && this.discount1 != undefined && this.discount1 != null
      && this.exchange != undefined && this.exchange != null && this.add1 != null) {



      this.sumoffall2 =
        Number(this.discount2) +
        Number(this.sum3) + Number(this.sum4) +
        Number(this.sum5) + Number(this.sum6) +
        Number(this.sum7) + Number(this.sum8) +
        Number(this.sum9) + Number(this.discount1) + Number(this.exchange) + Number(this.add1) + Number(this.SAMBHADH_SCHEME)

      console.log(this.sumoffall2, "total2");

      if (this.sumofall >= this.sumoffall2) {
        this.getcal3()
      }
      else {
        this.getcal4()
      }


    }


  }

  getcal3() {

    console.log(this.sumofall, "ok", this.sumoffall2, "ok2");
    console.log();


    if (this.sumofall >= this.sumoffall2) {
      this.sdfsdf4 = this.sumofall - this.sumoffall2
      this.sdfsdf5 = 0
      console.log(this.sdfsdf4, "shortfall");


    }
    else {
      this.sdfsdf4 = 0
    }


  }

  getcal4() {
    console.log(this.sumoffall2 >= this.sumofall)
    if (this.sumoffall2 >= this.sumofall) {
      this.sdfsdf5 = this.sumoffall2 - this.sumofall
      this.sdfsdf4 = 0
      console.log(this.sdfsdf5, "Excess Amount");

    }

    else {
      this.sdfsdf5 = 0
    }
  }


  openReceipt(data: memoMaster, i: any) {
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        // this.isSpinning = false
        this.loadingRecords = false
        window.open(this.api.retriveimgUrl + "receiptPhoto/" + this.recieptData[i]['RECEIPT_PHOTO']);
        console.log(this.recieptData[i]['RECEIPT_PHOTO']);
      }
    })

  }

  openReceipt1(data: memoMaster, i: any) {
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        // this.isSpinning = false
        this.loadingRecords = false
        window.open(this.api.retriveimgUrl + "paymentPhoto/" + this.recieptData[i]['PAYMENT_PHOTO']);
        console.log(this.recieptData[i]['PAYMENT_PHOTO']);
      }
    })

  }

  importInExcel() {
    this.search(true, true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Remark'] = this.dataList1[i]['REMARK'];
      obj1['Memo Number'] = this.dataList1[i]['MEMO_NO'];
      obj1['Date'] = this.datePipe.transform(this.dataList1[i]['DATE'], 'dd MMM yyyy');
      obj1['Sales Executive Name'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Invoice Number'] = this.dataList1[i]['INVOICE_NO'];
      if (this.status == 'AP') {
        this.status = "Approved"
        console.log(this.status, "this.status1")
      }
      else if (this.status == 'RQ') {
        this.status = "Requested"
        console.log(this.status, "this.status2")
      }
      else if (this.status == 'FW') {
        this.status = "Forwarded"
        console.log(this.status, "this.status3")
      }
      else if (this.status == 'RJ') {
        this.status = "Rejected"
        console.log(this.status, "this.status4")
      }
      obj1['Status'] = this.status;

      console.log(this.status, "status");
      obj1['Pan Number'] = this.dataList1[i]['PAN_NUMBER'];
      obj1['Aadhar Number'] = this.dataList1[i]['AADHAR_NUMBER'];



      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Hpromise Memo Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }


}
