import { Component, Input, OnInit } from '@angular/core';
import { INSURANCECOLLAPSEMASTER } from 'src/app/Models/insuranceCollapseMaster';

import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';

import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {

  formTitle = "Insurance Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  excel:any;
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  isInsuranceLead:boolean=true;
  columns: string[][] = [
    ["DEALER_ID", "Dealer Id"],
    ["DEALER","Dealer"],
    ["INSURANCE_COMPANY_NAME","Insurance Company Name"],
    ["POLICY_TYPE","Policy Type"],
    ["POLICY_EXPIRY_DATE","Policy Expiry Date"],
    ["POLICY_NUMBER","Policy Number"],
    ["VEHICLE_IDV","Vehicle Idv"],
    ["TOTAL_PREMIUM","Total Premium"],
    ["ADD_ON","Add On"],
    ["VEHICLE_CLASS","Vehicle Class"],
    ["HYPOTHECATION_DETAIL","Hypothecation Detail"],
    ["MODEL_CODE","Model Code"],
    ["ENGINE_NUMBER","Engine Number"],
    ["CHASSIS_NUMBER","Chassis Number"],
    ["VEHICLE_REG_NO","Vehicle Reg No"],
    ["INSURED_NAME","Insured Name"],
    ["ADDRESS","Address"],
    ["MOBILE_COUNTRY_CODE","Mobile Country Code"],
    ["MOBILE_NUMBER","Mobile Number"],
    ["STD_CODE","STD Code"],
    ["LANDLINE_NUMBER","Landline Number"],
    ["EMAIL_ID","Email Id"],
    ["STR_COVER_TYPE","Str Cover Type"],
    ["PREV_POLICY_EXPIRY_DATE","Previous Policy Expiry Date"],
    ["STR_COVER_TYPE_DATA","Str Cover Type Data"],
    ["ADD_ON_COVER_PREM","Add On Cover Prem"],
    ["NUMBER_OF_CLAIMS","Number of Claims"],
    ["TOTAL_IDV","Total Idv"],
    ["RENEWAL_VEHICLE_IDV","Renewal Vehicle Idv"],
    ["ELEC_ACC_IDV","Elec Acc Idv"],
    ["NONELEC_ACC_IDV","Nonelec Acc Idv"],
    ["RENEWAL_CNGLPG_KIT_IDV","Renewal CngLpg Kit Idv"],
    ["BASIC_PREMIUM_VEHICLE","Basic Premium Vehicle"],
    ["BASIC_PREMIUM_ELEC_ACCES","Basic Premium Elec Acces"],
    ["BASIC_PREMIUM_NONELEC_ACCES","Basic Premium NonElec Acces"],
    ["BASIC_PREMIUM_CNGLPG","Basic Premium CngLpg"],
    ["SUB_TOTAL","Sub Total"],
    ["NCB_AMOUNT","Ncb Amount"],
    ["TOTAL_OWN_DAMAGE_PREM","Total Own Damage Prem"],
    ["NET_OWN_DAMAGE_PREM_A","Net Own Damage Prem A"], 
    ["NET_LIABILITY_PREM_B","Net Liability Prem B"],
    ["TOTAL_PREM_AB","Total Prem AB"],
    ["SERVICE_TAX","Service Tax"],
    ["GROSS_PREMIUM","Gross Premium"],
    ["MANUFACTURING_YEAR","Manufacturing Year"],
    ["ADD_ON_NAME","Add On Name"],
    ["ALTERNATE_NUMBER","Alternate Number"],
    ["DATA_TYPE","Data Type"],
    ["LOCATION","Location"],
    ["EXECUTIVE_NAME","Executive Name"],
    ["REMINDER_DATE","Reminder Date"],
    ["STATUS","Status"],
    ["REMARK_DATE","remark Date"],
    ["RENEWAL_NOTICE_SEND","Renewal Notice Sent"],
    ["COURIER_STATUS","Courier Status"],
    ["SEQUENCE_NUMBER","Seqence Number"],
    ["MAIN_DEALER_CODE","Main Dealer Code"],
    ["INVOICE_DATE", "Invoice Date"], 
    ["DELIVERY_DATE", "Delivery Date"],
    ["INVOICE_NUMBER","Invoice Number"],
    ["REGISTRATION_NAME","Registration Name"],
    ["CONTACT_NUMBER1","Contact Number 1"],
    ["CONTACT_NUMBER2","Contact Number 2"],
    ["CONTACT_NUMBER3","Contact Number 3"],
    ["MODEL_NAME","Model Name"],
    ["VIN_NUMBER","VIN Number"],
    ["INVOICE_PRICE","Invoice Price"],
    ["MODEL_YEAR","Model Year"],
    ["MODEL","Model"],
    ["EXECUTIVE_NAME","Executive Name"],
  ]
  InsurancedrawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  // InsurancedrawerVisible!: boolean; 
  // dataList:any[]=[]

  collapseDrawerVisible: boolean = false;
  drawerData1: INSURANCECOLLAPSEMASTER = new INSURANCECOLLAPSEMASTER();
  isClosed=false;
  drawerTitle1: string;
  drawerVisible1: boolean;
  drawerData2: any;

  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false
  constructor(private api:ApiService) { }

  ngOnInit() {
    this.search();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.InsurancedrawerVisible = false;
  }

  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }
  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }
  drawerClose1(): void {
    // this.search();
    this.collapseDrawerVisible = false;
    this.isClosed = true
    this.active1 = false
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false

    // this.index=0;
  }
  drawerClose2(): void {
    this.drawerVisible1 = false;
  }
  datalist=[]
  Activity(dataa){
    this.drawerTitle1 = "Create New Insurance Lead Activity";
    this.drawerVisible1 = true;
    this.drawerData2= Object.assign({}, dataa);
    this.api.getAllActivityleadMaster(0, 0, '', 'desc', ' AND CUSTOMER_ID = ' + dataa.ID).subscribe(
    data => {
      if (data['code'] == 200) 
    {
    this.datalist = data['data']
    console.log(this.datalist);}
    }, err => {
    console.log(err);
    });
    }
    edit(data: INSURANCECOLLAPSEMASTER): void {
      this.drawerTitle = " Forms ";
      this.drawerData1 = Object.assign({}, data);
      // this.drawerData1=Object.assign({},data);
      (localStorage.setItem('editData', JSON.stringify(data.ID)));
      // this.SMRdrawerVisible = true;
      this.collapseDrawerVisible = true;
  
      this.active1 == false
      this.active2 == false
      this.active3 == false
      this.active4 == false
      this.active5 == false
      this.active6 == false
  
      console.log(this.active1,"this.active1")
      console.log(this.active2,"this.active2")
      console.log(this.active3,"this.active3")
      console.log(this.active4,"this.active4")
      console.log(this.active5,"this.active5")
      console.log(this.active6,"this.active6")
    }

  add(): void {
    this.drawerTitle = " Insurance Leads "; 
    this.drawerData = new ServiceModuleExcel();
    this.InsurancedrawerVisible = true;
    
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    // this.search(false);
  }

  keyup(event: any) {
    this.search();
  
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.gethapAndGDMSLostMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  
}
