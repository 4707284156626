import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { quotation } from 'src/app/Models/quotation';
import { NzNotificationService } from "ng-zorro-antd";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-quotation44',
  templateUrl: './quotation44.component.html',
  styleUrls: ['./quotation44.component.css']
})
export class Quotation44Component implements OnInit {
  drawerTitle1
  data:quotation=new quotation
  formTitle="Quotation"
  CANCELATION_STATUS: string;
  APPROVAL_REMARK: any;
  filterQuery1: string;
  REMARK: any;
  REMARK1: any;
  REMARK2: any;
  REMARK3: any;
  constructor(private api: ApiService,private sanitizer: DomSanitizer,private cookie: CookieService,private message: NzNotificationService,private datePipe: DatePipe) { }
  sanitizedLink:any
  imgurl= this.api.retriveimgUrl;
   filterQuery:any = [];
   filterClass: string = "filter-invisible";
  ACTION:any
  sortKey: string = "ID";
  sortValue: string = "desc";
 isFilterApplied = "default";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  orgId = this.cookie.get('orgId');
  userid:any = this.cookie.get('userId')
  startValue = new Date()
  endValue: any = new Date()
  TO_DATE: any = new Date()
  FROM_DATE: any = new Date(this.startValue.getTime() - 7 * 24 * 60 * 60 * 1000);
  dataList: any = [];
  loadingRecords = false;
  searchText: string = "";
  STATUS:any=[]
  SIGNATURE: any;
  SIGNATURE1: any;
  SIGNATURE2: any;
  SIGNATURE3: any;
  dataList1
  drawerVisible:boolean=false
     
       
  columns: string[][] = [["CUSTOMER_NAME", "CUSTOMER_NAME"], ["APPLIER_NAME", "APPLIER_NAME"], ["APPROVER_NAME", "APPROVER_NAME"], ["VARIENT_NAME", "VARIENT_NAME"], ["MODEL_NAME", "Model"], ["QUOTATION_NO", "QUOTATION_NO."],["SALES_EXE_REMARK",'SALES_EXE_REMARK'] ,["STATUS", "Status"]];
  getS(link:string){
    var a= this.imgurl+'CancelationImage/'+link+'#toolbar=0'
    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

    return  this.sanitizedLink ;

  }
  ngOnInit() {
    this.search(true)
    this.onGet1()
  }
  options:any
  onGet(){
    this.api.getQuotation1(0,0,'','',this.filterQuery).subscribe((data:any)=>{
      this.options=data['data'];
      
    })
  }
  saleHierarchy1:any
  onGet1(){
    this.api

      .getAllemployeeMaster(

        0,

        0,

        "NAME",

        "asc",

        " AND STATUS=1 AND ORG_ID=" +

        this.orgId +

        " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +

        this.cookie.get("orgId") +

        " AND ROLE_ID=30)"

      )

      .subscribe(

        (data) => {

          if (data["code"] == 200) {

            this.saleHierarchy1 = data["data"];

          }

        },

        (err) => {

          if (err["ok"] == false)
          console.log("Server Not Found", "");

        }

      );
  }
  isVisible:boolean=false
  data12:any
  modalDisplay(data){
    this.drawerVisible = true;
    console.log(this.isVisible,'isVisible')
    this.data12 = Object.assign({}, data);
    this.api.getQuotation1(0,0,'','','AND CANCELATION_ID='+data.CANCELATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList123 = data['data'];

      
        this.status= data['data'][0]['STATUS']
        console.log( this.status,"status");
        this.REMARK=data['data'][0]['APPROVAL_REMARK']
        this.REMARK1=data['data'][1]['APPROVAL_REMARK']
        this.REMARK2=data['data'][2]['APPROVAL_REMARK']
        this.REMARK3=data['data'][3]['APPROVAL_REMARK']

        this.SIGNATURE=data['data'][0]['APPLIER_SIGNATURE']
        this.SIGNATURE1=data['data'][1]['APPLIER_SIGNATURE']
        this.SIGNATURE2=data['data'][2]['APPLIER_SIGNATURE']
        this.SIGNATURE3=data['data'][3]['APPLIER_SIGNATURE']
  
      }
    }, err => {
      if (err['ok'] == false)
      console.log('ok')
    });
}
handleCancel(){
  this.drawerVisible = false;
}
data1
isOk:boolean=false
isSpinning:boolean=false
APPROVAL_ID:any
isVisible1:boolean=false
ID
dataList123
get closeCallback1() {
  return this.drawerClose1.bind(this);
}
drawerClose1(): void {
  this.search();
  this.drawerVisible = false;
}
approval(){


    this.data['CANCELATION_STATUS']="FW" 
    this.data['APPROVAL_REMARK']=this.APPROVAL_REMARK
    this.data['APPROVAL_ID']=this.userid
    this.data['NEW_APPROVER_ID']=this.APPROVAL_ID
    this.data['ID']=this.ID
    this.api.approveQuotation(this.data).subscribe(successCode => {
      if (successCode['code'] == 200) {
     this.isSpinning=false
      this.message.success("Verified Successfully...", "");
      this.search()
      this.APPROVAL_ID=[]
      this.APPROVAL_REMARK=''
     }
       else
       {
        this.message.error("Falied To Verify...", "");
        this.isSpinning = false;
       }
      });
      this.isVisible1=false
          this.drawerVisible = false;
      this.APPROVAL_REMARK=''
      this.APPROVAL_ID=[]
    
  }
Reject(){
  this.data['CANCELATION_STATUS']="RJ"
    this.data['APPROVAL_REMARK']=this.APPROVAL_REMARK
    this.data['APPROVAL_ID']=this.APPROVAL_ID
this.isOk=true;
     if (this.APPROVAL_REMARK== null || this.APPROVAL_REMARK.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Remark', '');
    } 
 
    if(this.isOk)
    {
  
      this.api.approveQuotation(this.data).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Rejected Successfully...", "");
          
          this.search()
          this.APPROVAL_ID=[]
          this.APPROVAL_REMARK=''
         }
           else
           {
            this.message.error("Failed To Reject...", "");
            this.isSpinning = false;
           }
           this.isVisible1=false
          this.drawerVisible = false;
          });
  }
}
status

search(reset: boolean = false,exportInExcel:boolean=false) {
  if (reset) {
    this.pageIndex = 1;
  }

  var sort: string;
  try {
    sort = this.sortValue.startsWith("a") ? "asc" : "desc";

  } catch (error) {
    sort = "";
  }

  var likeQuery = "";
  if (this.searchText != "") {
    likeQuery = " AND (";

    this.columns.forEach(column => {
      likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    });

    likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
  }

  var dateFilter = "";
    if ((this.FROM_DATE != null) || (this.TO_DATE.length != null)) {
      dateFilter = " AND DATE(DATE) BETWEEN '" + this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd') + "'";
    }


  if (this.STATUS.length > 0) {
    this.filterQuery =  'AND STATUS=' + "'" + this.STATUS + "'";

  }
  else{
    this.filterQuery =  'AND STATUS=' + "'" + ['RQ'] + "'";
  }
  this.filterQuery1 =  'AND APPROVAL_ID=' + "'" + this.userid + "'";
  if (exportInExcel == false) {
  this.loadingRecords = true;
  this.api.getQuotation1(0,0,'','',this.filterQuery+ likeQuery+this.filterQuery1+ dateFilter ).subscribe(data => {
    if (data['code'] == 200) {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList1 = data['data'];
    
      this.status= data['data'][0]['STATUS']
      console.log( this.status,"status");

    }
  }, err => {
    if (err['ok'] == false)
    console.log('ok')
  });
}

}
applyFilter() {
  if ((this.STATUS.length > 0) || (this.FROM_DATE != null) || (this.TO_DATE != null))
  this.isFilterApplied = "primary";

else
  this.isFilterApplied = "default";

this.search(true);
this.filterClass = "filter-invisible";
}
clearFilter() {
  this.STATUS = [];
  this.isFilterApplied = "default";
  this.filterClass = "filter-invisible";
  this.search(true);
}

showFilter(): void {
  if (this.filterClass === "filter-visible")
    this.filterClass = "filter-invisible";

  else
    this.filterClass = "filter-visible";
}
showModal(): void {
  this.isOk=true;
  if(this.APPROVAL_ID==null){
   this.isOk = false;
   this.message.error('Please Select General Manager', '');
  }
  else if (this.APPROVAL_REMARK== null || this.APPROVAL_REMARK.trim() == '') {
   this.isOk = false;
   this.message.error('Please Enter Remark', '');
 } 

 if(this.isOk)
 {
  this.isVisible1 = true;
 }
}



handleCancel1(): void {
  console.log('Button cancel clicked!');
  this.isVisible1 = false;
}
receipt:any
imageModalVisible = false
TYPE:any

  openPdf(data,type ): void {

    // this.loadingRecords = true

    console.log(data);

 

    this.TYPE = type

 

    if(this.TYPE == "R"){

      this.receipt = data.RECEIPT_PDF

    }
    else if(this.TYPE == "C")
    {

      this.receipt = data.CHEQUE

    }

 

    //  window.open(this.api.retriveimgUrl + "cancelationReciept/" + data.RECEIPT_PDF);

     

    if(this.receipt != null){

      this.imageModalVisible = true

      this.isSpinning = true

    }else{

      if(type=='R'){

        this.message.error('Receipt Not Found', '');

      }else{

        this.message.error('Cheque Not Found', '');

      }

    }

 

    // this.api

   

  }


getS1(link:string){

  if(this.TYPE == "R"){

    var a= this.imgurl+'cancelationReciept/'+link+'#toolbar=0'

    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

 

    return  this.sanitizedLink ;

  }else{

    var a= this.imgurl+'ChequeImages/'+link+'#toolbar=0'

    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

 

    return  this.sanitizedLink ;

  }





}



handleimgCancel() {
  this.imageModalVisible = false
}


}
