import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { INSURANCECOLLAPSEMASTER } from 'src/app/Models/insuranceCollapseMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addinsurance',
  templateUrl: './addinsurance.component.html',
  styleUrls: ['./addinsurance.component.css']
})
export class AddinsuranceComponent implements OnInit {

  @Input()
  collapseDrawerVisible: boolean = false;
  @Input()
  data1: INSURANCECOLLAPSEMASTER = new INSURANCECOLLAPSEMASTER();

  @Input()
  drawerClose1!: Function;
  isOk = true;

  date = null;
  isEnglish = false;


  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  longitudepattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
  latitudepattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
  pat1 = /^\d{6}$/;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  cityRegex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  aaddpattern = /^[a-zA-Z0-9\s,'-]*$/
  namepatte = /[ .a-zA-Z]+/
  passpattern = /^\S*$/
  landline = /"[0-9]{3}-[0-9]{3}-[0-9]{4}"/

  isSpinning = false;


  constructor(private message: NzNotificationService, private api: ApiService) { }

  ngOnInit() {
  }


  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  alphaOnly(event: any) {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 32 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  resetDrawer(accountMasterPage: NgForm) {
    this.data1 = new INSURANCECOLLAPSEMASTER();
    accountMasterPage.form.markAsPristine();
    accountMasterPage.form.markAsUntouched();
  }


  onChange(result: Date): void {
    console.log('onChange: ', result);
  }

  close1(): void {
    this.h();
    this.drawerClose1();
    console.log(this.active1,"this.active1")
    console.log(this.active2,"this.active2")
    console.log(this.active3,"this.active3")
    console.log(this.active4,"this.active4")
    console.log(this.active5,"this.active5")
    console.log(this.active6,"this.active6")
  }

  @Input() active1: any = false;
  @Input() active2: any = false;
  @Input() active3: any = false;
  @Input() active4: any = false;
  @Input() active5: any = false;
  @Input() active6: any = false;


  basicinfo() {
    this.active1 = true
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    //  console.log('ff')
  }
  customerinfo() {
    this.active2 = true
    this.active1 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    //  console.log('ff') 
  }
  serviceadvisorinfo() {
    this.active3 = true
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false
  }

  insuranceinfo() {
    this.active4 = true
    this.active3 = false
    this.active5 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false
  }

  insurancecalculation() {
    this.active5 = true
    this.active3 = false
    this.active4 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false

  }
  unknowninfo() {
    this.active6 = true
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
  }

  h() {
    this.active6 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
  }

  //save
  //save
  save(addNew: boolean ): void {
    this.isSpinning = false;
    this.isOk = true;



    // create update

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data1.ID) {
          this.api.updateServiceInsuranceMaster(this.data1).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success(' Information Updated Successfully...', '');
              if (!addNew) this.drawerClose1();
              this.isSpinning = false;
              this.active6 = false;
            } else {
              this.message.error(' Failed To Update Information...', '');
              this.isSpinning = false;
            }
          });
        }

      }
    }

  }

}
