import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-retension-summary-report',
  templateUrl: './retension-summary-report.component.html',
  styleUrls: ['./retension-summary-report.component.css']
})

export class RetensionSummaryReportComponent implements OnInit {

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private message: NzNotificationService) { }

  formTitle = "Retention Summary Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "m1.CATEGOREY";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["CATEGOREY", "Category"],
  ["TOATL", "Total"],
  ["TOTAL_APPOINTMENTS", "Total Appointment"],
  ["TOTAL_WORK_DONE", "Total Work Done"],
  ["TOTAL_REMINDERS", "Total Reminders"],
  ["TOTAL_LOST", "Total Lost"],
  ["NOT_CONNECTED", "Not Connected"],
  ["TOTAL_CALLS", "Total Calls"],];

  date: any = new Date();
  date1: any = new Date()
  modalVisible = false;
  isSpinning = false;
  filterClass: string = "filter-invisible";
  sales: any = []
  DATE: any;
  DATE1: any
  CCE_ID: number
  datalist: any = []
  datalist1: any = []
  datalist2: any = []
  ngOnInit() {
    this.date = new Date();
    this.date1 = new Date();
    this.getEmployee();
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, '', this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])

        this.search();
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  isVisible: boolean = false

  handleOk(): void {

    this.isVisible = false;
  }

  handleCancel(): void {

    this.isVisible = false;
  }

  openPrint() {
    this.isVisible = true;
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getstatus(CATEGOREY: any) {
    // console.log(CATEGOREY);
    if (CATEGOREY == "L")
      return "HMIL Lost";
    else if (CATEGOREY == "NS")
      return "No Show";
    else if (CATEGOREY == "C")
      return "Confirmation";
    else if (CATEGOREY == "SE")
      return "Service Due";
    else if (CATEGOREY == "WD")
      return "Work Done";
    else if (CATEGOREY == "OY")
      return "One Year";
    else if (CATEGOREY == "A")
      return "Appointment";
    else if (CATEGOREY == "MT")
      return "Mid Term";
    else if (CATEGOREY == "SD")
      return "Sales Data";
    else if (CATEGOREY == "O")    
      return "Own Calling";
    else
      return CATEGOREY;

      
  }

  NoData: boolean = false
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));

  CCE_NAME

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }


    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')

    this.DATE1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')

    if (this.roleId == 43) {
      if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
        this.CCE_ID = this.userId
      }
    }
    else {
      if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
        this.CCE_ID = this.CCE_ID
      }
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.categoreyWiseCallingReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.DATE, this.DATE1, this.CCE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.datalist = data['data'];
          console.log(this.datalist);
          
          this.CCE_NAME = data['data'][0]['CCE_NAME']
          this.datalist1 = data['TOTAL_COUNTS'];
          this.datalist2 = data['TOTAL_ROWS_COUNT']

          this.NoData = false
          this.filterClass = 'filter-invisible';

        }
        else if (data['code'] == 300) {
          this.NoData = true
        }

      }, err => {
        console.log(err);
      });

    }
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'

    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')

    this.DATE1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')


    if (this.date != null && this.date1 != null) {
      this.DATE = this.date
      this.DATE1 = this.date1
    }

    // if(){


    // }

    if (this.CCE_ID != undefined && this.CCE_ID == null) {
      this.CCE_ID = this.CCE_ID
    }

    this.search(true);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.date = new Date();
    this.date1 = new Date();

    this.getEmployee();
    this.search(true);
    this.isFilterApplied = 'default';

  }

}
