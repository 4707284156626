import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-insurancecompanywisesummaryreport',
  templateUrl: './insurancecompanywisesummaryreport.component.html',
  styleUrls: ['./insurancecompanywisesummaryreport.component.css']
})
export class InsurancecompanywisesummaryreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Insurance Company Wise Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "INSURANCE_NAME";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  // DATE:any;
  // MODEL:any;

  // BRANCH:any;
  models: any = [];
  INSURANCECOMPANY: any = [];
  month = new Date();
  year = new Date();
  YEAR: any = this.year;
  MONTH: any = this.month;
  Insurancecomp: any = [];
  // branch:any=[];
  // quotationdate:any;
  isOk = true;
  columns: string[][] = [["INSURANCE_NAME", "Insurance Company"]];
  exportLoading: boolean;
  dataList1: any;
  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }


  ngOnInit() {
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    this.getAllInsurancecompany();
    // this.search(false);
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      MONTH: this.MONTH,
      YEAR: this.YEAR,
      INSURANCECOMPANY: this.INSURANCECOMPANY
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 64').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }
  getAllInsurancecompany() {
    this.api.getAllInsurances(0, 0, "", "", "").subscribe(data => {
      if (data['code'] == 200) {
        this.Insurancecomp = data['data']
        this.onSelectAllCompany(true);
        this.search(false);
      }
      else { }
    })
  }
  SELECT_COMPANY: boolean = false;
  onSelectAllCompany(event: any) {
    this.SELECT_COMPANY = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_COMPANY == true) {
      for (var i = 0; i < this.Insurancecomp.length; i++) {
        ids.push(this.Insurancecomp[i]["NAME"]);
      }
    } else {
      ids = [];
    }
    this.INSURANCECOMPANY = ids
  }

  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  startValue:any = new Date();
  endValue: any = new Date();
  START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  search(reset: boolean, exportInExcel: boolean = false) {
    // this.MONTH = this.datePipe.transform(this.month, "MM");
    // this.YEAR = this.datePipe.transform(this.year, "yyyy");
    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }


    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getinsurancesummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.INSURANCECOMPANY).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
        // else {
        //   this.loadingRecords = false;
        //   this.isSpinning = false;
        // }
      }, err => {
        console.log(err);
        // this.loadingRecords = false;
        // this.isSpinning = false;
      });
    }
    else {
      this.exportLoading = false;
      this.api.getinsurancesummaryreport(0, 0, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.INSURANCECOMPANY).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            //   this.exportLoading = false;
            // this.loadingRecords = false;
            // this.isSpinning = false;
            this.message.error("Server Not Found", "");
        });
    }

    //  this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,"").subscribe(data => {
    //     console.log(data)
    //     if(data['code']==200)
    //     this.loadingRecords = false;
    //     this.isSpinning=false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //   }, err => {
    //     console.log(err);
    //   });
  }
  monthchange(event: any) {
    this.MONTH = this.datePipe.transform(this.month, "MM")
  }
  yearchange(event: any) {
    this.YEAR = this.datePipe.transform(this.year, "yyyy")
  }
  importInExcel() {
    this.search(true, true);
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
  
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")
    // if (this.MONTH == undefined && this.YEAR == undefined && this.INSURANCECOMPANY == undefined) {
    //   this.message.error("Please Select Option To Filter", '')
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isOk = false;
    //   this.isFilterApplied = "default";
    // }
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.isOk == true) {
      // this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,""+this.MODEL).subscribe(data => {
      //   console.log(data)
      //   if(data['code']==200)
      //   {
      //   this.loadingRecords = false;
      //   this.isSpinning=false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   }
      //   else{}
      // }, err => {
      //   console.log(err);
      // });
      this.api.getinsurancesummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.INSURANCECOMPANY).subscribe(data => {
        if (data['code'] == 200) {
          // this.exportLoading = false;
          this.dataList1 = data['data'];
          this.loadingRecords = false;
          this.isSpinning = false;
          this.search(false)
          // this.convertInExcel();
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
        else {
          this.loadingRecords = false;
          this.isSpinning = false;
        }
      }, err => {
        console.log(err);
        this.loadingRecords = false;
        this.isSpinning = false;
      });
    }
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    //  this.MODEL=null;
    // this.MONTH = [];
    // this.YEAR = [];
    this.month = new Date();
    this.year = new Date();
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    this.INSURANCECOMPANY = [];
    this.SELECT_COMPANY = false;
    this.searchText = "";
    //  this.BRANCH="";
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(false);

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Insurance Company'] = this.dataList1[i]['INSURANCE_NAME'];
      obj1['In House'] = this.dataList1[i]['INHOUSE'];
      obj1['Out House'] = this.dataList1[i]['OUTHOUSE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Insurance Company Wise Summary Report"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  onStartChange(date: Date): void {
    this.startValue = date;
  }
}
