import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branch-wise-feedback-report',
  templateUrl: './branch-wise-feedback-report.component.html',
  styleUrls: ['./branch-wise-feedback-report.component.css']
})
export class BranchWiseFeedbackReportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Branch Wise Feedback Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  quotationdate: any;

  models: any = [];
  branch: any = [];

  isOk = true;
  columns: string[][] = [["NAME", "Branch Name"], ["TYPE", "Feedback"]];
  dataList1: any;

  FROM_DATE: any
  TO_DATE: any
  MEMO_START_DATE: any
  MEMO_END_DATE: any
  MODEL_ID: any = []
  BRANCH_ID: any = []
  DATE = [];
  DATE2 = [];
  current = new Date();

  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    // this.getAllModels();

    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
    this.search(false)

  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any


  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      if (this.branch.length > 0) {
        this.BRANCH_ID = [data['data'][0]['ID']];
        console.log(this.branch);
      }
      else {
        console.log('Error');
      }

    }, (err) => {
      console.log(err);
    });
  }
  BRANCH
  getAllBranch() {
    this.api.getAllBranch(0, 0, "", "", "").subscribe(data => {
      this.branch = data['data']
      let ids = [];

      if (this.SELECT_BRANCH == true) {
        for (var i = 0; i < this.branch.length; i++) {
          ids.push(this.branch[i]["ID"]);
        }
      } else {
        ids = [];
      }
      this.BRANCH = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false,false);
  }

  SELECT_BRANCH: boolean = true;

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }


  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }
  drawerClose1() {

  }
  TYPE='C'
  search(reset: boolean, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    // filterQuery += " EMPLOYEE_ID=" + this.userId + " AND";

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getBranchwisefeedbackdetails(this.pageIndex, this.pageSize, this.sortKey, sort,
        likeQuery, this.FROM_DATE, this.TO_DATE, this.TYPE).subscribe(data => {
        
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          console.log(this.dataList)
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }, err => {
          console.log(err);
        });
    }
    else {
      this.exportLoading = false;
      this.api.getBranchwisefeedbackdetails(0,0, this.sortKey, sort,
        likeQuery, this.FROM_DATE, this.TO_DATE, this.TYPE).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            console.log(this.dataList)
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')


    if (this.DATE[0] != null) {
      this.FROM_DATE = this.DATE[0]
    }
    if (this.DATE[1] != null) {
      this.TO_DATE = this.DATE[1]
    }

    this.search(true);




  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE = [];
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
    this.TYPE='C'

    this.search(true)


  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Branch Name'] = this.dataList1[i]['NAME'];
      if (this.dataList1[i]['FEEDBACK_TYPE'] == 'F') {
        obj1['Feedback/Complaint'] = 'Feedback';
      }
      else {
        obj1['Feedback/Complaint'] = 'Complaint';

      }
      obj1['Numbers'] = this.dataList1[i]['TOTAL'];
      obj1['Approved'] = this.dataList1[i]['APPROVED'];
      obj1['Pending'] = this.dataList1[i]['PENDING'];
      obj1['Rejected'] = this.dataList1[i]['REJECTED'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Feedback Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }


}
