import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-model-wise-daily-detailed-report",
  templateUrl: "./model-wise-daily-detailed-report.component.html",
  styleUrls: ["./model-wise-daily-detailed-report.component.css"],
})
export class ModelWiseDailyDetailedReportComponent implements OnInit {
  formTitle = "Model Wise Daily Detailed Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;

  orgId = this.cookie.get("orgId");
  DATE = [];
  BRANCH_ID = [];
  SALES_EXECUTIVE_ID = [];
  MODEL_ID = [];
  VARIENT_ID = [];
  pageIndex=1;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  totalRecords: any;
  dataList: any;
  isSpinning: boolean=false;
  filterQuery: string = "";
  pageSize=10
  FROMDATE:any=new Date()
  TODATE:any=new Date()
  ewsalesdata: any;
  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService
  ) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getBranches();
    this.getSalesExecutives();
    this.getModels();
    this.getModelwiseDailyDetailedReport();
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api
      .getModelwiseDailyDetailedReport(
        this.ModelwiseDailyDetailedReportPageIndex,
        this.ModelwiseDailyDetailedReportPageSize,
        this.ModelwiseDailyDetailedReportSortKey,
        sort,
        "",
        this.api.orgId,
      '','','','','',''
      ).subscribe
          ((data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api
      .getModelwiseDailyDetailedReport(
        this.ModelwiseDailyDetailedReportPageIndex,
        this.ModelwiseDailyDetailedReportPageSize,
        this.ModelwiseDailyDetailedReportSortKey,
        sort,
        "",
        this.api.orgId,
      '','','','','',''
      ).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let ids = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  SELECT_ALL3: boolean = false;
  onSelectAllChecked3(event) {
    this.SELECT_ALL3 = event;
    let ids = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.varients.length; i++) {
        ids.push(this.varients[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT_ID = ids;
  }

  onSelectOff3(event) {
    var a = this.varients.length;
    var b = this.varients.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.VARIENT_ID = event;
    if (this.VARIENT_ID.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }

  SELECT_ALL4: boolean = false;
  onSelectAllChecked4(event) {
    this.SELECT_ALL4 = event;
    let ids = [];
    if (this.SELECT_ALL4 == true) {
      for (var i = 0; i < this.salesExecutives.length; i++) {
        ids.push(this.salesExecutives[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff4(event) {
    var a = this.salesExecutives.length;
    var b = this.salesExecutives.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL4 = false;
    } else {
      this.SELECT_ALL4 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL4 = false;
    }
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  models = [];

  getModels() {
    this.models = [];
    this.varients = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  varients = [];

  getVarients(modelID) {
    this.varients = [];
    this.VARIENT_ID = [];

    this.api
      .getAllVarients(
        0,
        0,
        "NAME",
        "asc",
        " AND MODEL_ID IN (" + modelID + ") AND STATUS=1"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.varients = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  salesExecutives = [];

  getSalesExecutives() {
    this.salesExecutives = [];

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" +
        this.orgId +
        " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
        this.cookie.get("orgId") +
        " AND ROLE_ID=28)"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.salesExecutives = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.FROMDATE = currentDate;
    this.TODATE = currentDate;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    if (this.FROMDATE.length > 0 && this.TODATE.length > 0) this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getModelwiseDailyDetailedReport(true);
  }

  clearFilter() {
    this.SELECT_ALL1=false;
    this.SELECT_ALL2=false;
    this.SELECT_ALL3=false;
    this.SELECT_ALL4=false;
    this.DATE = [];
    this.BRANCH_ID = [];
    this.SALES_EXECUTIVE_ID = [];
    this.MODEL_ID = [];
    this.VARIENT_ID = [];
    this.setDateToDateFilter();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.ModelwiseDailyDetailedReportData = [];
    this.getModelwiseDailyDetailedReport(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.ModelwiseDailyDetailedReportSortKey = sort.key;
    this.ModelwiseDailyDetailedReportSortValue = sort.value;
    this.getModelwiseDailyDetailedReport(true);
  }

  ModelwiseDailyDetailedReportPageIndex = 1;
  ModelwiseDailyDetailedReportPageSize = 10;
  ModelwiseDailyDetailedReportTotalRecords = 1;
  ModelwiseDailyDetailedReportSortValue: string = "desc";
  ModelwiseDailyDetailedReportSortKey: string = "ID";
  ModelwiseDailyDetailedReportData = [];

  columns: string[][] = [
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["SALES_EXECUTIVE_BRANCH_NAME", "Branch Name"],
    ["DATE", "Date Of Quotation"],
    ["MEMO_DATE", "Converted To Memo Date"],
    ["INVOICE_DATE", "Converted To Invoice Dates"]
  ];

  columns1: string[][] = [
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["SALES_EXECUTIVE_BRANCH_NAME", "Branch Name"],
   
  ];

  exportLoading: boolean = false;
  importInExcel() {
    this.getModelwiseDailyDetailedReport(true, true);
  }
  ModelwiseDailyData=[];

  getModelwiseDailyDetailedReport(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.ModelwiseDailyDetailedReportPageIndex = 1; 
    }

    var sort: string;
    try {
      sort = this.ModelwiseDailyDetailedReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND ";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) ;
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.FROMDATE != undefined && this.FROMDATE.length != 0 && this.TODATE != undefined && this.TODATE.length != 0) {
      this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
      this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api
        .getModelwiseDailyDetailedReport(
          this.ModelwiseDailyDetailedReportPageIndex,
          this.ModelwiseDailyDetailedReportPageSize,
          this.ModelwiseDailyDetailedReportSortKey,
          sort,
        likeQuery,
          this.api.orgId,
          branchFilter,
          this.FROMDATE,
          this.TODATE,

          modelFilter,
          varientFilter,
          salesExecutiveFilter
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.loadingRecords = false;
              this.ModelwiseDailyDetailedReportTotalRecords = data["count"];
              this.ModelwiseDailyDetailedReportData = data["data"];
            }
          },
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        );
    }

    else {
      this.exportLoading = true;
      this.api
        .getModelwiseDailyDetailedReport(
          0,
          0,
          this.ModelwiseDailyDetailedReportSortKey,
          sort,
          likeQuery,
          this.api.orgId,
          branchFilter,
          this.FROMDATE,
          this.TODATE,

          modelFilter,
          varientFilter,
          salesExecutiveFilter
        ).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ModelwiseDailyData = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ModelwiseDailyData.length; i++) {
      obj1['Model Name'] = this.ModelwiseDailyData[i]['MODEL_NAME'];
      obj1['Variant Name'] = this.ModelwiseDailyData[i]['VARIENT_NAME'];
      obj1['Sales Executive Name'] = this.ModelwiseDailyData[i]['SALES_EXECUTIVE_NAME'];

      obj1['Branch Name'] = this.ModelwiseDailyData[i]['SALES_EXECUTIVE_BRANCH_NAME'];

      obj1['Date Of Quotation'] = this.datePipe.transform(this.ModelwiseDailyData[i]['DATE'], 'dd MMM yyyy'); 
      obj1['Converted To Memo Date'] = this.datePipe.transform(this.ModelwiseDailyData[i]['MEMO_DATE]'], 'dd MMM yyyy ');
      obj1['Converted To Invoice Date'] = this.datePipe.transform(this.ModelwiseDailyData[i]['INVOICE_DATE'], 'dd MMM yyyy ');

      arry1.push(Object.assign({}, obj1));
      if (i == this.ModelwiseDailyData.length - 1) {
        this._exportService.exportExcel(arry1, 'Model Wise Daily Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd '));
      }
    }
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
}
