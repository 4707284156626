import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
import { NgForm } from '@angular/forms';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
// import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
// import { Leave } from '../Models/Leave';
@Component({
  selector: 'app-shift-mapping',
  templateUrl: './shift-mapping.component.html',
  styleUrls: ['./shift-mapping.component.css']
})
export class ShiftMappingComponent implements OnInit {

  @Input() drawerClose1: Function;

  @Input() data: Leave;
  @Input() dataListtt = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  isSpinning11 = false;
  roles = [];
  model = [];
  leaveTypes = [];
  formTitle = "Shift Mapping Form"
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];
  Array1 = [];
  dataList1 = []
  disabled1 = true
  disabled2 = true


  MODEL1: any
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  @Input() selectedIndex

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  // columns = []
  columns: string[][] = [["NAME", "Employee Name"], ["DEPARTMENT_NAME", "Department Name"], ["BRANCH_NAME", "Branch Name"], ["DESIGNATION_NAME", "Designation Name"]]

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  modeltype = []
  fueltype = []
  varienttype = []
  tranmission = []


  BRANCH_IDS:any 
  DEPARTMENT_IDS :any
  DESIGNATION_IDS:any 




  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.getModels();
  }

  filterClass: string = 'filter-visible';

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  cancel() {
    this.drawerClose1()
    this.dataList = []
    this.array = []
    this.BRANCH_IDS = []
    this.DEPARTMENT_IDS = []
    this.checked1 = false
    this.DESIGNATION_IDS = []
    this.filterClass = 'filter-visible'
    this.isSpinning = false;
    this.adjustmentremark = ''
    this.loadingRecords = false;

    this.search();
  }

  checked = false;
  checked1 = false;
  checkedd = true;
  indeterminate = true;
  indeterminate1 = false;
  setOfCheckedId = new Set<number>();
  setOfCheckedId1 = new Set<number>();
  array = []

  isChecked: boolean
  onChecked1(data, checked: boolean): void {
    console.log(data);

    if (checked) {
      console.log(checked);
      this.isChecked = checked
      this.array.push({ EMPLOYEE_ID: data.EMPLOYEE_ID })
      console.log(this.array);
      this.indeterminate = false
      // this.dataList[ind].IS_SELECTED = 1
    } else {
      // this.dataList[ind].IS_SELECTED = 0
      this.array.pop()
      console.log(this.array);
      this.indeterminate = true

    }

  }

  onAllChecked1(value: boolean): void {
    console.log(value);
    this.dataList.forEach(item => {
      this.isChecked = value
      if (value) {
        if (value == true) {
          this.array.push({ EMPLOYEE_ID: item.EMPLOYEE_ID })
          this.checked1 = true
          this.indeterminate = false
        }
        console.log(this.array);
        // item.IS_SELECTED = 1
      } else {
        // item.IS_SELECTED = 0
        this.array = []
        this.checked1 = false
        this.indeterminate = true
      }
    });
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    // this.startValue = null;
    // this.endValue = null;

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.BRANCH_IDS = []
    this.DEPARTMENT_IDS =[]
    this.DESIGNATION_IDS = []
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }
  // isSpinning = false
  // @Input() drawerClose: Function
  @Input() dataList = []

  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  @Input() shiftID: number

  applyFilter() {


    if (

      this.BRANCH_IDS != null ||
      this.DEPARTMENT_IDS != null ||
      this.DESIGNATION_IDS != null
    ) {
      this.isFilterApplied = "primary";
    }
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";

    this.search(true);
  }

  lastid: number

  EMPID = []
  allemps = []
  allempids = []

  DesignationFilter: any 
  DepartmentFilter: any 
  BranchFilter: any 

  search(reset: boolean = false) {
    var filter = ""
    this.BranchFilter =""
    this.DepartmentFilter = ""
    this.DesignationFilter = ""
    this.filterQuery = ''
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


    if (this.BRANCH_IDS != undefined && this.BRANCH_IDS.length != 0) {
      this.BranchFilter = this.BRANCH_IDS;

    }
    else{
      this.BRANCH_IDS=""
    }


    if (this.DEPARTMENT_IDS != undefined && this.DEPARTMENT_IDS.length != 0) {
      this.DepartmentFilter = this.DEPARTMENT_IDS

    }
    else{
      this.DEPARTMENT_IDS=""
    }


    if (this.DESIGNATION_IDS != undefined && this.DESIGNATION_IDS.length != 0) {
      this.DesignationFilter = this.DESIGNATION_IDS

    }
    else{
      this.DESIGNATION_IDS=""
    }

    this.filterQuery += " AND SHIFT_ID = " + this.shiftID;

    this.api.getShiftMappingData(this.shiftID, this.BranchFilter, this.DepartmentFilter, this.DesignationFilter).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList);

    }, err => {
      console.log(err);
    });

  }


  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  getModels() {
    this.model = [];

    this.api.getAllDesignation(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllDepartments(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllAttendanceBranch(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


  }


  close(): void {
    this.drawerClose1();
    this.dataList = []
    this.BRANCH_IDS = []
    this.DEPARTMENT_IDS = []
    this.DESIGNATION_IDS = []
    this.checked1 = false
    this.isSpinning = false;
  }


  MappingData



  MODELeee = []


  Modeldata = []
  // SendData = []
  SendData1 = []



  finalDataa = []
  // dataListtt = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []

  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []





  adjustmentremark: any
  save(dataListtt, adjustmentremark) {


    this.isSpinning = true;


    this.api.leaveAdjustmentaddbulk(dataListtt).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("Leave Adjustment Updated Successfully", "");

        // this.drawerClose()
      } else {
        this.message.error("Leave Adjustment Updation Failed", "");
        this.isSpinning = false;
      }
    });

  }

  todaydate: any
  remark: any

  add() {

    this.todaydate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.remark = ''
    this.api.createadjustment(this.cookie.get('userId'), this.todaydate, this.remark)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {

          // this.message.success("Role Created Successfully...", "");  


          this.message.info("Please Apply Filter...", "");
        }
        else {
          this.message.error("Role Creation Failed...", "");
          this.isSpinning11 = false;
        }
      });
  }
  // SendData = new Object()
  Time1: any
  Time2: any
  START_DATE: any
  END_DATE: any
  SendData11
  arr
  handleApkVersionModalOk(): void {
    var isOk = true;
    this.Time1 = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd")
    this.Time2 = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd")


    if ((this.START_DATE == undefined) || (this.START_DATE == null)) {
      isOk = false;
      this.message.error("Please Enter Start Date", "");
    }

    if ((this.END_DATE == undefined) || (this.END_DATE == null)) {
      isOk = false;
      this.message.error("Please Enter End Date", "");
    }


    if (isOk) {

      this.SendData11 = ({
        SHIFT_ID: this.shiftID,
        START_DATE: this.Time1,
        END_DATE: this.Time2,
        data: this.array,

      });


      console.log(this.array);
      this.api.addbulkShiftMap(this.SendData11)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Shift Mapped Successfully ...", "");
            this.drawerClose1();
            this.dataList = []
            this.array = []
            this.START_DATE = null;
            this.END_DATE = null;
            this.indeterminate = true
            this.BRANCH_IDS = []
            this.DEPARTMENT_IDS = []
            this.DESIGNATION_IDS = []
            this.checked1 = false
            this.filterClass = 'filter-visible'
            this.loadingRecords = false;
          }
          else {
            this.message.error("Shift Mapping Failed...", "");

            // this.array = []
            this.indeterminate = true
          }
        });
    }
  }

  lastid1: any
  date111: any
  dataaaa: any
  ide: any
  isOk = false;

  // calculate(){

  //   this.isSpinning11 = true; 

  //   this.api.getAdjustmentData(0, 0, '', 0, '').subscribe(data1 => {
  //     console.log(data1['data']);
  //     if (data1['code'] == 200) {

  //       // this.totalRecords = data1['count'];
  //       this.lastid1 = data1['data'][0]['ID'];
  //       this.date111 = data1['data'][0]['DATE'];
  //       this.ide = data1['data'][0]['USER_ID'];

  //       if(this.adjustmentremark!=''){
  //         this.api.updateleaveAdjustment(this.lastid1,this.date111,this.adjustmentremark,this.ide)
  //         .subscribe(successCode => {
  //           if (successCode['code'] == "200") {
  //             this.message.success("Remark updated Successfully...", "");

  //           }
  //           else {
  //             this.message.error("Failed to update Remark...", "");
  //             this.isSpinning = false;
  //           }
  //         });
  //       }


  //       this.dataaaa = {LEAVE_BALANCE_ADJUSTMENT_MASTER_ID:this.lastid1,USER_ID:this.ide}

  //       this.api.updateLeaveBalance(this.dataaaa).subscribe(successCode => {
  //         if (successCode['code'] == "200") {
  //           this.message.success("Leave Balance Updated Successfully", "");
  //           this.isSpinning11 = false;
  //           this.drawerClose1()

  //           this.isSpinning = false;
  //           this.dataList=[]
  //           this.adjustmentremark=''
  //         } else {
  //           this.message.error("Leave Balance Updation Failed", "");
  //           this.isSpinning = false;
  //         }
  //       });

  //       console.log(this.lastid);
  //     }
  //   }, err => {
  //     console.log(err);
  //   });

  // }
}
