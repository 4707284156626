export class memoDetails {
    ID:any;
    MEMO_MASTER_ID:any;
    COMPONENT:any;
    ORIGINAL_AMOUNT:any;
    PRICE:any;
    CLIENT_ID:any;
    CUSTOMER_QUOTED_PRICE:any;
    PRICELIST_AMOUNT:any;
    CUSTOMER_DISCOUNT:any;
    HEAD_DISCOUNT:any;
}