import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Role } from 'src/app/Models/role';
import { DatePipe } from '@angular/common';
import { Roledetails } from 'src/app/Models/roledetails';

@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrls: ['./salesreport.component.css']
})

export class SalesreportComponent implements OnInit {
  @Input() data: Roledetails = new Roledetails

  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  menus = [];
  PARENT_ID=194
  parentId=194
  Allactivity: any;
  pageName2: string = "";

constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) {}

ngOnInit(){
  console.log(this.data.PARENT_ID)
    // this.loadForms();
    
    // "AND PARENT_ID=236"
    // 253
this.api.getRoleDetails1(this.roleId,"AND PARENT_ID=253").subscribe(data => {
      console.log("data");
      console.log(data['data']);
      this.menus = data['data'];
    }, err => {
      console.log(err);
});
}

loadForms() {
    this.api.getForms(this.userId, this.roleId).subscribe(data => {
      if(data['code'] == 200) {
        data['data'].forEach(element => {
          element['children'].sort(this.sortFunction)
            if (element['children'].length == 0)
            delete element['children']
        });
          this.menus = data['data'].sort(this.sortFunction);
      }
  });
}

sortFunction(a, b) {
    var dateA = a.SEQ_NO;
    var dateB = b.SEQ_NO;
    return dateA > dateB ? 1 : -1;
};

selected:any;
target:any;
// navigateTo(item) {
//   this.isSelected = item.value; 
//     if (item) {
//         this.ngOnInit()
//         this.router.navigate([item]);
//         this.target = item
//         console.log(this.target)
//     }
//     return false;
// }

currentIndex = -1
dataShow:boolean=true;
isSelected:boolean;
sm1: Boolean = undefined;
navigateTo(data,index: number) {
  this.dataShow=false;
  this.currentIndex = index;
  this.isSelected = true;
  if (data.value === '/SalesExecutiveWiseDetailedReport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

backPage(){
  this.dataShow=true
}

setUserId(event:any) {
  this.PARENT_ID = event;
  this.parentId = event;
  let currentUrl = this.router.url;
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([currentUrl]);
});
}

// if (this.PARENT_ID==91) {
// if ( this.pageName2 = "Monthly Leave Report") {
//   this.router.navigateByUrl('/MonthlyLeaveReport');
// } 
// this.router.navigateByUrl('/MonthlyLeaveReport');
// console.log(this.PARENT_ID);
// console.log(this.parentId);
// this.router.navigate(['dashboard']).then(() => {
//   window.location.reload();
// });

}