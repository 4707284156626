export class PSFActivityLeadMaster {
    CUSTOMER_ID: any;
    ID: any;
    CLIENT_ID: any;
    LEAD_ID
    ACTIVITY_TYPE_ID: any;
    SERVICE_ADVISER_ID:any
    SERVICE_ADVISER_NAME:any
    IS_CALLED: any;
    CALL_STATUS='C'
    CALLING_TIME :any = new Date()
    CALLING_DATE :any = new Date()
    SELF_REMINDER_DATE
    SELF_REMINDER_TIME
    REMINDER_DATE
    REMINDER_TIME
    FOLLOW_UP_DATE
    PSF_DATE:any=new Date()
    CUSTOMER_NAME
    IS_SOP_DSI_FILLED_BY_SA='Y'
    IS_PICK_UP_AND_DROP='Y'
    IS_REGISTER_WITH_HYUNDAI='Y'
    FILL_UP_WA_LINK='Y'
    MAINTAIN_AND_REPAIR_RATING=8
    CONDITION_CLEANESS_RATING=8
    SA_EXPLAIN_RATING=8
    PICK_UP_DROP_RATING=8
    DEALER_FACILITY_RATING=8
    OVERALL_EXPERIENCE_RATING=8
    SA_OPEN_RO_ON_TAB='Y'
    ALL_WORK_COMPLETED='Y'
    RECEIVED_ON_DELIVERY_DATE='Y'
    IS_CHARGES_REASONABLE='Y'
    LAST_PAYMENT_METHOD='CD'
    IS_SA_TOOK_TEST_DRIVE='Y'
    POST_RODE_TEST_DRIVE='Y'
    HOW_DID_YOU_BOOK='P'
    DLR_NPS="Y"
    HYN_NPS
    DID_YOU_FILLED_SOP='Y'
    COMPLAINT
    REMARK
    PSF_SHEET_ID
    PSF_ASSIGNED_ID
    PSF_CCE_ID
    STATUS
    NO_OF_CALLS=1
    PSF_CALLING_STATUS
    MOBILE_NUMBER
    IS_REMINDER = false
    FOLLOWUP_DATE
    PSF_CATEGORY_STATUS
    IS_CLOSED
    NC_CALLING_STATUS = 'NC'
    RO_NUMBER
    COMPLAINT_REMARK
    ACTION_REMARK
    FOLLOWUP_REMARK
    FINAL_REMARK
    UPLOADED_REMARK
    RATE_US_REMARK
    FAIRNESS_REMARK
    TECH_ROOT_REMARK
    TECHNICIAN_NAME
    // FI_REMARK
    IS_ADDED_FIRST
    IS_HQ_CONCERN
    TECHNICIAN_ID:any=null
    PREVEOUS_WORK_TYPE
    SERVICE_ADMIN_ID
    IS_CALLED_BY_SA

    ROOT
    SUB_ROOT
    FI_ID
    COMPLAINT_TYPE
    // COMPLAINT
}


