import { Component, OnInit, ViewChild } from '@angular/core';
import { Designationmaster } from 'src/app/Models/Designationmaster';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { DepartmentworkingDetails } from 'src/app/Models/departmentworking-details';
import { DesignationComponent } from '../designation/designation.component';
import { EmployeeRoleMaster } from 'src/app/Models/employee-role-master';

@Component({
  selector: 'app-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.css']
})

export class DesignationsComponent implements OnInit {
  orgId = this.cookie.get('orgId');
  formTitle = "Manage Designations";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Designation Name"], ["PARENT_DESIGNATION_NAME", "Parent Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence no"]]
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Designationmaster = new Designationmaster();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Designationmaster = new Designationmaster();
  listOfData: DepartmentworkingDetails[] = [];
  OPEN_TIME2 = null
  CLOSE_TIME2 = null
  DAYS = false;

  constructor(private api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    if (likeQuery)
      filter += likeQuery;

    this.api.getAllDesignation(this.pageIndex, this.pageSize, this.sortKey, sort, filter + ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  @ViewChild(DesignationComponent, { static: false }) DesignationComponentVar: DesignationComponent;

  add(): void {
    this.drawerTitle = "Create New Designation";
    this.drawerData = new Designationmaster();
    this.drawerData.ORG_ID = Number(this.orgId);
    this.drawerData.PARENT_ID = 0;
    this.listOfData = [];
    this.drawerVisible = true;

    this.api.getAllDesignation(1, 1, 'SEQUENCE_NO', 'desc', ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
      }

    }, err => {
      console.log(err);
    })

    this.DesignationComponentVar.fillAllDesignations();
  }

  edit(data: Designationmaster): void {
    this.drawerTitle = "Update Designation";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.DesignationComponentVar.fillAllDesignations();
    this.DesignationComponentVar.getallorg2(data.ID);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
  drawerData2: string[];
  // drawerData1: EmployeeRoleMaster = new EmployeeRoleMaster();
  evaluation(data: Designationmaster): void {
    this.api.getEvaluationCriteriaMapping(data.ID).subscribe(data => {
      console.log("data: "+JSON.stringify(data));
      this.drawerData2 =data['data'];
      }, err => {
      console.log(err);
    });
    this.drawerTitle1 = "Evaluation Criteria Mapping for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }
}