import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { setHours } from 'date-fns';

@Component({
  selector: 'app-branchwiseinsurancesummaryreport',
  templateUrl: './branchwiseinsurancesummaryreport.component.html',
  styleUrls: ['./branchwiseinsurancesummaryreport.component.css']
})
export class BranchwiseinsurancesummaryreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Branch Wise Insurance Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODEL: any;
  BRANCH: any;
  model: any = [];
  branch: any = [];
  salesHead: any[] = []
  quotationdate: any ;
  reportingHead: any ; 
  SELECT_SALES_HEAD: boolean = false;
  SELECT_BRANCH: boolean = false;
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  startValue: any = new Date();
  endValue: any = new Date();
  columns: string[][] = [["BRANCH_NAME", "Branch"]];
  isVisible1: boolean;
  FILTER_QUERY: string;
  drawerTitle1: string;
  members: any;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe,private _exportService: ExportService,) { }

  ngOnInit() {
    this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    this.endValue = new Date();
    this.getbranches()
    // this.getsalehead()
    setTimeout(()=>{
      this.search(false);
    },1000);
  }

  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
        console.log(this.branch);
        this.onSelectAllBranch(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }
  // getsalehead() {
  //   this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(34)').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.salesHead = data['data'];
  //       console.log(this.salesHead);
  //       this.onSelectAllSaleHead(true)
  //       // this.search(true)
  //       // this.search(true)
  //     }
  //   },
  //     err => { console.log(err); });
  // }

  
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  // onSelectAllSaleHead(event: any) {
  //   this.SELECT_SALES_HEAD = event
  //   console.log(event, "Event")
  //   let ids = [];
  //   if (this.SELECT_SALES_HEAD == true) {
  //     for (var i = 0; i < this.salesHead.length; i++) {
  //       ids.push(this.salesHead[i]["EMPLOYEE_ID"]);
  //     }
  //   } else {
  //     ids = [];
  //   }
  //   this.reportingHead = ids
  // }

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };
  
  START_DATE:any
  END_DATE:any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  exportLoading: boolean = false;
  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getBranchwiseinsurancesummaryreport(this.pageIndex,this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH,this.START_DATE,this.END_DATE ).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.getBranchwiseinsurancesummaryreport(0,0, this.sortKey, sort, likeQuery,this.BRANCH,this.START_DATE,this.END_DATE ).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  importInExcel() {
    this.search(true, true);
  }
  
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.BRANCH != undefined && this.BRANCH.length > 0) {
      this.BRANCH = this.BRANCH
    }
   
    this.search(true);
    this.filterClass = "filter-invisible";

  }

  clearFilter() {
    this.startValue = new Date();
    // this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    this.endValue = new Date();
    this.BRANCH = [];
    this.searchText = '';
    this.filterQuery = '';
    this.filterClass = 'filter-invisible';
    this.SELECT_BRANCH= false
    this.isFilterApplied="default";
    this.search(true)

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Branch name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['No. of invoices'] = this.dataList1[i]['NO_INVOICE'];
      obj1['No. of Inhouse insurance'] = this.dataList1[i]['NO_INHOUSE_INSURANCE'];
      obj1['No. of outhouse insurance'] = this.dataList1[i]['NO_OUTHOUSE_INSURANCE'];
      obj1['Total insurance'] = this.dataList1[i]['TOTAL_INSURANCE'];

     
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Insurance Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
