import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
  selector: 'app-quotation-detailed-report',
  templateUrl: './quotation-detailed-report.component.html',
  styleUrls: ['./quotation-detailed-report.component.css']
})
export class QuotationDetailedReportComponent implements OnInit {

  formTitle = "Quotation Detailed Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Quotationdetailed.xlsx';
  dataList = [];
  exportToExcelLoading = false
  index = 0;
  exportToExcelDataList: any = []

  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  dateQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["QUOTATION_NO", "Quotation No"], ["DATE", "Quotation Date"], ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
  ["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"], ["CONVERTED_MEMO", "Convertd To Memo"], ["MEMO_DATE", "Converted Date"], ["CONVERTED_INVOICE", "Converted To Invoice"], ["INVOICE_DATE", "Invoice Date"]]

  isSpinning = false
  filterClass: string = "filter-invisible";
  SALES_EXECUTIVE_ID: any = []
  Value1: any
  Value2: any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  MEMO_DATE: any
  data1 = [];
  INVOICE_DATE: any
  MODEL_ID: any = []
  DATE: any
  ticketQuestion = {};
  value1: any = []
  value2: any = []
  departments = []
  supportusers = []

  MEMO_STATUS: any[];
  constructor(private api: ApiService, private datePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.Value = new Date();
    this.Value1 = new Date();
    this.Value2 = new Date();
    this.getModels()
    this.getSales()
    this.search();
  }

  models=[];

  getModels() {
    this.api.getAllModels(0, 0, '', 'asc', 'AND STATUS=1').subscribe((data1) => {
      this.models = data1['data'];
      this.onSelectAllChecked2(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  sales = [];
  getSales() {
    this.api.getAllEmpRoleMap(0, 0,"ID", this.sortValue, ' AND ROLE_ID=28')
      .subscribe(data => {
        this.sales = data['data'];
      this.onSelectAllChecked333(true);
      },
        (err) => {
          console.log(err);
        }
      );
  }

  onKeypressEvent(reset: any) {

    const element = window.document.getElementById('button');

    if (element != null) element.focus();

    this.search();

  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.Value = currentDate;
    this.Value1 = currentDate;
    this.Value2 = currentDate;
  }

  
  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  
  SELECT_ALL333: boolean = false;
  onSelectAllChecked333(event) {
    this.SELECT_ALL333 = event;
    let ids = [];
    if (this.SELECT_ALL333 == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff333(event) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL333 = false;
    } else {
      this.SELECT_ALL333 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL333 = false;
    }
  }

  exportexcel(): void {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  

  search(reset: boolean = false, exportToExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
    this.Value = this.datePipe.transform(this.Value, 'yyyy-MM-dd');

    if (this.Value != null && this.Value != undefined && this.Value != '') {
      this.DATE = this.Value
    }else{
      this.DATE=null;
    }

    this.Value1 = this.datePipe.transform(this.Value1, 'yyyy-MM-dd');

    if (this.Value1 != null && this.Value1 != undefined && this.Value1 != '') {
      this.MEMO_DATE = this.Value1
    }else{
      this.MEMO_DATE=null;
    }
    this.Value2 = this.datePipe.transform(this.Value2, 'yyyy-MM-dd');

    if (this.Value2 != null && this.Value2 != undefined && this.Value2 != '') {
      this.INVOICE_DATE = this.Value2
    }else{
      this.INVOICE_DATE=null;
    }
    if (exportToExcel == false) {
      this.loadingRecords = true

      this.api.getQuotationDetailedReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.DATE, this.SALES_EXECUTIVE_ID, this.MODEL_ID, this.MEMO_DATE, this.INVOICE_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
          console.log("fsgdghvhvhvghvbhvdbhvbh")
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.exportToExcelLoading = true;


      this.api.getQuotationDetailedReport(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.DATE, this.SALES_EXECUTIVE_ID, this.MODEL_ID, this.MEMO_DATE, this.INVOICE_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied="dafault";
    this.DATE=[]
    this.searchText='';
    this.SELECT_ALL333=true;
    this.SELECT_ALL2=true;
    this.onSelectAllChecked2(true);
    this.onSelectAllChecked333(true);
    this.setDateToDateFilter();
    this.search()
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }
  Value: any

  applyFilter() {
this.isFilterApplied = "primary";
   
    this.filterClass = "filter-invisible";
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.dateQuery = '';
    this.Value = this.datePipe.transform(this.Value, 'yyyy-MM-dd');

    if (this.Value != null && this.Value != undefined) {
      this.DATE = this.Value
    }else{
      this.DATE=null;
    }

    if (this.SALES_EXECUTIVE_ID != null && this.SALES_EXECUTIVE_ID != 0 && this.SALES_EXECUTIVE_ID != undefined) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }
    if (this.MODEL_ID != null && this.MODEL_ID != 0 && this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID
    }
    this.Value1 = this.datePipe.transform(this.Value1, 'yyyy-MM-dd');

    if (this.Value1 != null && this.Value1 != undefined) {
      this.MEMO_DATE = this.Value1
    }else{
      this.MEMO_DATE=null;
    }
    this.Value2 = this.datePipe.transform(this.Value2, 'yyyy-MM-dd');

    if (this.Value2 != null && this.Value2 != undefined) {
      this.INVOICE_DATE = this.Value2
    }else{
      this.INVOICE_DATE=null;
    }


    this.search();
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);

    }

    //   this.api.getQuotationDetailedReport(this.pageIndex, this.pageSize, this.sortKey, sort,this.filterQuery).subscribe(data => {
    //     console.log(data)
    //     this.loadingRecords = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //     this.isSpinning = false
    //     this.isFilterApplied = "primary";
    //     this.filterClass = "filter-invisible";
    //   }, err => {
    //     console.log(err);
    //   });
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Quotation No'] = this.exportToExcelDataList[i]['QUOTATION_NO'];
      obj1['Quotation Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd MMM yyyy');

      obj1['Sales Executive Name'] = this.exportToExcelDataList[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.exportToExcelDataList[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.exportToExcelDataList[i]['MODEL_NAME'];
      obj1['Converted Memo'] = this.exportToExcelDataList[i]['CONVERTED_MEMO'];
      obj1['Memo Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['MEMO_DATE'], 'dd MMM yyyy');
      obj1['Converted To Invoice'] = this.exportToExcelDataList[i]['CONVERTED_INVOICE'];
      obj1['Invoice Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['INVOICE_DATE'], 'dd MMM yyyy');



      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Quotation Detailed Report' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }
}