import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.css']
})
export class SalesDashboardComponent implements OnInit {

  constructor(private cookie: CookieService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  pageSize: number = 10;
  pageIndex: number = 1;
  totalRecords: any;
  // sortValue: string = "";
  // sortKey: string = "";

  sortValue1: string = "desc";

  sortKey1: string = "BANK_NAME";
  sortKey2: string = "BANK_NAME";
  sortKey3: string = "BRANCH_ID";
  sortKey4: string = "MODEL_ID";
  sortKey5: string = "SALES_EXECUTIVE_ID";
  sortKey6: string = "INSURANCE_NAME";
  sortKey7: string = "INSURANCE_NAME";
  sortKey8: string = "BRANCH_ID";
  sortKey9: string = "BRANCH_ID";
  sortKey10: string = "BRANCH_ID";

  inHouseFinance: string[][] = [["BANK_NAME", "Bank Name"],
  ["FINANCE_COUNT", "Count"],];

  OutHouseFinance: string[][] = [["BANK_NAME", "Bank Name"],
  ["FINANCE_COUNT", "Count"],];

  monthlybranchwisemodel: string[][] = [["BRANCH_NAME", "Branch"],
   ["INVOICE_COUNT", "Count Of Invoice Genereated"]];
  // ["MODEL_NAME", "Model"],["INVOICE_DATE", "Invoice Date"], ["MEMO_DATE", "Memo Created Date"]];

  modelwiseinvoicegenerate: string[][] = [["MODEL_NAME", "Model"],
  ["INVOICE_COUNT", "Count Of Invoice Genereated"]];
  // ["INVOICE_DATE", "Invoice Date"], ["MEMO_DATE", "Memo Created Date"]];

  Quotationgeneratedtoday: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
  ["COUNT", "Count"],];

  inHouseInsurance: string[][] = [["INSURANCE_NAME", "Insurance Company"],
  ["INSURANCE_COUNT", "Insurance Count"],];

  OutHouseInsurance: string[][] = [["INSURANCE_NAME", "Insurance Company"],
  ["INSURANCE_COUNT", "Insurance Count"],];

  MonthlyBranchWiseMostDiscountApproval: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["SALES_EXECUTIVE_DISCOUNT", "Sales Executive Discount"], ["APPROVED_DISCOUNT", "Approved Discount"],
  ["TOTAL_DISCOUNT", "Total Discount"]];

  BranchWiseMemoPendancy: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["PENDING_MEMOS", "Count of Pending Memo"]];

  BranchWiseDiscountPendancy: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["PENDING_DISCOUNT", "Count of Pending Discount"],];

  formTitle8 = "Inhouse Finance Report"
  formTitle9 = "Outhouse Finance Report"
  formTitle10 = "Monthly Branch Wise Invoice Generated Report"
  formTitle12 = "Monthly Model Wise Invoice Generated Report"
  formTitle13 = "Quotation Generated Today Report"
  formTitle14 = "Outhouse Insurance Report"
  formTitle15 = "Inhouse Insurance Report"
  formTitle16 = "Monthly Branch Wise Most Discount Approval Report"
  formTitle17 = "Branch Wise Memo Pendency Report"
  formTitle18 = "Branch Wise Discount Pendency Report"



  filterClass8: string = "filter-invisible";
  isFilterApplied8: any = "default";
  loadingRecords8 = false;
  totalRecords8 = 1
  isSpinning8 = false;
  pageIndex8:number = 1;
  pageSize8:number = 10;

  filterClass9: string = "filter-invisible";
  isFilterApplied9: any = "default";
  loadingRecords9 = false;
  totalRecords9 = 1
  isSpinning9 = false;
  pageIndex9:number = 1;
  pageSize9:number = 10;

  filterClass10: string = "filter-invisible";
  isFilterApplied10: any = "default";
  loadingRecords10 = false;
  totalRecords10 = 1
  isSpinning10 = false;
  pageIndex10:number = 1;
  pageSize10:number = 10;

  filterClass12: string = "filter-invisible";
  isFilterApplied12: any = "default";
  loadingRecords12 = false;
  totalRecords12 = 1
  isSpinning12 = false;
  pageIndex12:number = 1;
  pageSize12:number = 10;

  filterClass13: string = "filter-invisible";
  isFilterApplied13: any = "default";
  loadingRecords13 = false;
  totalRecords13 = 1
  isSpinning13 = false;
  pageIndex13:number = 1;
  pageSize13:number = 10;

  filterClass14: string = "filter-invisible";
  isFilterApplied14: any = "default";
  loadingRecords14 = false;
  totalRecords14 = 1
  isSpinning14 = false;
  pageIndex14:number = 1;
  pageSize14:number = 10;

  filterClass15: string = "filter-invisible";
  isFilterApplied15: any = "default";
  loadingRecords15 = false;
  totalRecords15 = 1
  isSpinning15 = false;
  pageIndex15:number = 1;
  pageSize15:number = 10;

  filterClass16: string = "filter-invisible";
  isFilterApplied16: any = "default";
  loadingRecords16 = false;
  totalRecords16 = 1
  isSpinning16 = false;
  pageIndex16:number = 1;
  pageSize16:number = 10;

  filterClass17: string = "filter-invisible";
  isFilterApplied17: any = "default";
  loadingRecords17 = false;
  totalRecords17 = 1
  isSpinning17 = false;
  pageIndex17:number = 1;
  pageSize17:number = 10;

  filterClass18: string = "filter-invisible";
  isFilterApplied18: any = "default";
  loadingRecords18 = false;
  totalRecords18 = 1
  isSpinning18 = false;
  pageIndex18:number = 1;
  pageSize18:number = 10;

  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  MONTH2: any = new Date()
  YEAR: any = this.year
  YEAR2: any = this.year
  monthFormat = "MMM-yyyy";
  filterQuery: string = '';

  FROM_DATE: any;
  TO_DATE: any;
  DATE = [];
  DATE1 = [];
  DATE2 = [];
  DATE3 = []
  DATE4 = []
  DATE5 = []
  DATE6 = []
  date = new Date()
   startValue = new Date();
  endValue = new Date();
  //  startValue = new Date(this.date.getFullYear(),this.date.getMonth(),this.date.getDate()-1);
  // endValue = new Date(this.date.getFullYear(),this.date.getMonth(),this.date.getDate()-1);
  // startValue1 = new Date();
  // endValue1 = new Date();
  // startValue2 = new Date();
  // endValue2 = new Date();
  // startValue3 = new Date();
  // endValue3 = new Date();
  // startValue4 = new Date();
  // endValue4 = new Date();
  // startValue5 = new Date();
  // endValue5 = new Date();
  // startValue6 = new Date();
  // endValue6 = new Date();
  // startValue7 = new Date();
  // endValue7 = new Date();
  // startValue8 = new Date();
  // endValue8 = new Date();
  // startValue9 = new Date();
  // endValue9 = new Date();
  // startValue10 = new Date();
  // endValue10 = new Date();
  START_DATE: any;
  END_DATE: any;

  current = new Date();

  ngOnInit() {
    this.END_DATE = this.datePipe.transform(this.endValue, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue, "yyyy-MM-dd");
    this.MONTH = this.datePipe.transform(this.month1, "MM");

    this.inhouseFinanceReport();
    this.OuthouseFinanceReport();
    this.MontlyBranchWiseModelWiseInvoiceGenerated();
    this.MontlyModelWiseInvoiceGenerated();
    this.SalesExecutiveWiseQuotationGeneratedTodayReport();
    this.OuthouseInsurance();
    this.InhouseInsurance();
    this.BranchWiswMostDiscountApproval(); 
    this.branchWiseMemoPendancy();
    this.branchWiseDiscountPendancy();
  }

  // Table 8
  // showFilter8() {
  //   if (this.filterClass8 === "filter-visible")
  //     this.filterClass8 = "filter-invisible";

  //   else
  //     this.filterClass8 = "filter-visible";
  // }

  dataList: any;

  inhouseFinanceReport() {
    this.api.getInhouseFinanceReport(this.pageIndex8, this.pageSize8, this.sortKey1, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords8 = false;
        this.totalRecords8 = data['count'];
        this.dataList = data['data'];
        this.isSpinning8 = false;
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  // applyFilter8() {
  //   this.isFilterApplied8 = 'primary'
  //   this.filterClass8 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue1, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue1, "yyyy-MM-dd");

  //   this.loadingRecords8 = true;
  //   // this.api.getInhouseFinanceReport(this.pageIndex8, this.pageSize8, this.sortKey1, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords8 = false;
  //   //     this.totalRecords8 = data['count'];
  //   //     this.dataList = data['data'];
  //   //     this.isSpinning8 = false;
  //   //     this.filterClass8 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter8() {
  //   this.filterClass8 = 'filter-invisible';
  //   this.isFilterApplied8 = 'default';
  //   this.startValue1 = new Date();
  //   this.endValue1 = new Date();
  //   this.applyFilter8()

  // }

  // Table 9
  // showFilter9() {
  //   if (this.filterClass9 === "filter-visible")
  //     this.filterClass9 = "filter-invisible";

  //   else
  //     this.filterClass9 = "filter-visible";
  // }

  dataList9: any;

  OuthouseFinanceReport() {
    this.api.getOuthouseFinanceReport(this.pageIndex9, this.pageSize9, this.sortKey2, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords9 = false;
        this.totalRecords9 = data['count'];
        this.dataList9 = data['data'];
        this.isSpinning9 = false;
        this.filterClass9 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  // applyFilter9() {
  //   this.isFilterApplied9 = 'primary'
  //   this.filterClass9 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue2, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue2, "yyyy-MM-dd");

  //   this.loadingRecords9 = true;
  //   // this.api.getOuthouseFinanceReport(this.pageIndex9, this.pageSize9, this.sortKey1, this.sortValue1, '', this.START_DATE,this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords9 = false;
  //   //     this.totalRecords9 = data['count'];
  //   //     this.dataList9 = data['data'];
  //   //     this.isSpinning9 = false;
  //   //     this.filterClass9 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter9() {
  //   this.filterClass9 = 'filter-invisible';
  //   this.isFilterApplied9 = 'default';
  //   this.startValue2 = new Date();
  //   this.endValue2 = new Date();
  //   this.applyFilter9()

  // }
  // Table 10
  // showFilter10() {
  //   if (this.filterClass10 === "filter-visible")
  //     this.filterClass10 = "filter-invisible";

  //   else
  //     this.filterClass10 = "filter-visible";
  // }
  // END_DATE:any;
  // START_DATE:any;
  dataList10: any;
  date12 = new Date();
  startdate = new Date(this.date12.getFullYear(),this.date12.getMonth(),1);
  enddate = new Date();
  enddatesend:any
  startdatesend:any;
  MontlyBranchWiseModelWiseInvoiceGenerated() {
    this.enddatesend = this.datePipe.transform(this.enddate, "yyyy-MM-dd");
    this.startdatesend = this.datePipe.transform(this.startdate, "yyyy-MM-dd");
    this.MONTH = this.datePipe.transform(this.month1, "MM");
    this.api.getMontlyBranchWiseModelWiseInvoiceGenerated(this.pageIndex10, this.pageSize10, this.sortKey3, this.sortValue1, '',this.MONTH,this.startdatesend,this.enddatesend ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords10 = false;
        this.totalRecords10 = data['count'];
        this.dataList10 = data['data'];
        this.isSpinning10 = false;
        this.filterClass10 = 'filter-invisible'; 
      }
    }, err => {
      console.log(err);
    });
  }

  // applyFilter10() {
  //   this.isFilterApplied10 = 'primary'
  //   this.filterClass10 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue3, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue3, "yyyy-MM-dd");

  //   this.loadingRecords10 = true;
  //   // this.api.getMontlyBranchWiseModelWiseInvoiceGenerated(this.pageIndex10, this.pageSize10, this.sortKey1, this.sortValue1, '', this.START_DATE,this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords10 = false;
  //   //     this.totalRecords10 = data['count'];
  //   //     this.dataList10 = data['data'];
  //   //     this.isSpinning10 = false;
  //   //     this.filterClass10 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter10() {
  //   this.filterClass10 = 'filter-invisible';
  //   this.isFilterApplied10 = 'default';
  //   this.startValue3 = new Date();
  //   this.endValue3 = new Date();
  //   this.applyFilter10()

  // }
  // Table 12
  // showFilter12() {
  //   if (this.filterClass12 === "filter-visible")
  //     this.filterClass12 = "filter-invisible";

  //   else
  //     this.filterClass12 = "filter-visible";
  // }
  month1 = new Date();
  dataList12: any;
  MontlyModelWiseInvoiceGenerated() {
    this.enddatesend = this.datePipe.transform(this.enddate, "yyyy-MM-dd");
    this.startdatesend = this.datePipe.transform(this.startdate, "yyyy-MM-dd");
    this.MONTH = this.datePipe.transform(this.month1, "MM");
    this.api.getMontlyModelWiseInvoiceGenerated(this.pageIndex12, this.pageSize12, this.sortKey4, this.sortValue1, '', this.MONTH, this.startdatesend,this.enddatesend).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords12 = false;
        this.totalRecords12 = data['count'];
        this.dataList12 = data['data'];
        this.isSpinning12 = false;
        this.filterClass12 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  // applyFilter12() {
  //   this.isFilterApplied12 = 'primary'
  //   this.filterClass12 = "filter-invisible";

  //   this.loadingRecords12 = false;
  //   this.END_DATE = this.datePipe.transform(this.endValue4, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue4, "yyyy-MM-dd");
  //   this.MONTH = this.datePipe.transform(this.month1, "MM");
  //   this.loadingRecords12 = true;
  //   // this.api.getMontlyModelWiseInvoiceGenerated(this.pageIndex12, this.pageSize12, this.sortKey1, this.sortValue1, '', this.MONTH, this.START_DATE, this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords12 = false;
  //   //     this.totalRecords12 = data['count'];
  //   //     this.dataList12 = data['data'];
  //   //     this.isSpinning12 = false;
  //   //     this.filterClass12 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter12() {
  //   this.filterClass12 = 'filter-invisible';
  //   this.isFilterApplied12 = 'default';
  //   this.startValue4 = new Date();
  //   this.endValue4 = new Date();
  //   this.month1 = new Date();
  //   this.applyFilter12()
  // }

  // Table 13
  // showFilter13() {
  //   if (this.filterClass13 === "filter-visible")
  //     this.filterClass13 = "filter-invisible";

  //   else
  //     this.filterClass13 = "filter-visible";
  // }

  dataList13: any;

  SalesExecutiveWiseQuotationGeneratedTodayReport() {
    this.api.getSalesExecutiveWiseQuotationGeneratedTodayReport(this.pageIndex13, this.pageSize13, this.sortKey5, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords13 = false;
        this.totalRecords13 = data['count'];
        this.dataList13 = data['data'];
        this.isSpinning13 = false;
        this.filterClass13 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  // applyFilter13() {
  //   this.isFilterApplied13 = 'primary'
  //   this.filterClass13 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue5, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue5, "yyyy-MM-dd");

  //   this.loadingRecords13 = true;
  //   // this.api.getSalesExecutiveWiseQuotationGeneratedTodayReport(this.pageIndex13, this.pageSize13, this.sortKey1, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords13 = false;
  //   //     this.totalRecords13 = data['count'];
  //   //     this.dataList13 = data['data'];
  //   //     this.isSpinning13 = false;
  //   //     this.filterClass13 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter13() {
  //   this.filterClass13 = 'filter-invisible';
  //   this.isFilterApplied13 = 'default';
  //   this.startValue5 = new Date();
  //   this.endValue5 = new Date();
  //   this.applyFilter13()

  // }
  // Table 14
  // showFilter14() {
  //   if (this.filterClass14 === "filter-visible")
  //     this.filterClass14 = "filter-invisible";

  //   else
  //     this.filterClass14 = "filter-visible";
  // }

  dataList14: any;
  OuthouseInsurance() {
    this.api.getOuthouseInsurance(this.pageIndex14, this.pageSize14, this.sortKey6, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords14 = false;
        this.totalRecords14 = data['count'];
        this.dataList14 = data['data'];
        this.isSpinning14 = false;
        this.filterClass14 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }
  // applyFilter14() {
  //   this.isFilterApplied14 = 'primary'
  //   this.filterClass14 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue6, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue6, "yyyy-MM-dd");

  //   this.loadingRecords14 = true;
  //   // this.api.getOuthouseInsurance(this.pageIndex14, this.pageSize14, this.sortKey1, this.sortValue1, '', this.START_DATE, this.END_DATE ).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords14 = false;
  //   //     this.totalRecords14 = data['count'];
  //   //     this.dataList14 = data['data'];
  //   //     this.isSpinning14 = false;
  //   //     this.filterClass14 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter14() {
  //   this.filterClass14 = 'filter-invisible';
  //   this.isFilterApplied14 = 'default';
  //   this.startValue6 = new Date();
  //   this.endValue6 = new Date();
  //   this.applyFilter14()

  // }

  // Table 15
  // showFilter15() {
  //   if (this.filterClass15 === "filter-visible")
  //     this.filterClass15 = "filter-invisible";

  //   else
  //     this.filterClass15 = "filter-visible";
  // }

  dataList15: any;
  InhouseInsurance() {
    this.api.getInhouseInsurance(this.pageIndex15, this.pageSize15, this.sortKey7, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords15 = false;
        this.totalRecords15 = data['count'];
        this.dataList15 = data['data'];
        this.isSpinning15 = false;
        this.filterClass15 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }
  // applyFilter15() {
  //   this.isFilterApplied15 = 'primary'
  //   this.filterClass15 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue7, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue7, "yyyy-MM-dd");

  //   this.loadingRecords15 = true;
  //   // this.api.getInhouseInsurance(this.pageIndex15, this.pageSize15, this.sortKey1, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords15 = false;
  //   //     this.totalRecords15 = data['count'];
  //   //     this.dataList15 = data['data'];
  //   //     this.isSpinning15 = false;
  //   //     this.filterClass15 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter15() {
  //   this.filterClass15 = 'filter-invisible';
  //   this.isFilterApplied15 = 'default';
  //   this.startValue7 = new Date();
  //   this.endValue7 = new Date();
  //   this.applyFilter15()

  // }

  // Table 16
  // showFilter16() {
  //   if (this.filterClass16 === "filter-visible")
  //     this.filterClass16 = "filter-invisible";

  //   else
  //     this.filterClass16 = "filter-visible";
  // }

  dataList16: any;
  BranchWiswMostDiscountApproval() {
    this.api.getBranchWiswMostDiscountApproval(this.pageIndex16, this.pageSize16, this.sortKey8, this.sortValue1, '', this.startdatesend, this.enddatesend).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords16 = false;
        this.totalRecords16 = data['count'];
        this.dataList16 = data['data'];
        this.isSpinning16 = false;
        this.filterClass16 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }
  // applyFilter16() {
  //   this.isFilterApplied16 = 'primary'
  //   this.filterClass16 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue8, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue8, "yyyy-MM-dd");

  //   this.loadingRecords16 = true;
  //   // this.api.getBranchWiswMostDiscountApproval(this.pageIndex16, this.pageSize16, this.sortKey1, this.sortValue1, '', this.START_DATE,this.END_DATE).subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.loadingRecords16 = false;
  //   //     this.totalRecords16 = data['count'];
  //   //     this.dataList16 = data['data'];
  //   //     this.isSpinning16 = false;
  //   //     this.filterClass16 = 'filter-invisible';
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  // clearFilter16() {
  //   this.filterClass16 = 'filter-invisible';
  //   this.isFilterApplied16 = 'default';
  //   this.startValue8 = new Date();
  //   this.endValue8 = new Date();
  //   this.applyFilter16()

  // }

  // Table 17
  // showFilter17() {
  //   if (this.filterClass17 === "filter-visible")
  //     this.filterClass17 = "filter-invisible";

  //   else
  //     this.filterClass17 = "filter-visible";
  // }

  dataList17: any;

  branchWiseMemoPendancy() {

  this.api.getBranchWiseMemoPendancy(this.pageIndex17, this.pageSize17, this.sortKey9, this.sortValue1, '', this.START_DATE, this.END_DATE).subscribe(data => {
    if (data['code'] == 200) {
      this.loadingRecords17 = false;
      this.totalRecords17 = data['count'];
      this.dataList17 = data['data'];
      this.isSpinning17 = false;
      this.filterClass17 = 'filter-invisible';
    }
  }, err => {
    console.log(err);
  });
}
// applyFilter17() {
//   this.isFilterApplied17 = 'primary'
//   this.filterClass17 = "filter-invisible";

//   this.END_DATE = this.datePipe.transform(this.endValue9, "yyyy-MM-dd");
//   this.START_DATE = this.datePipe.transform(this.startValue9, "yyyy-MM-dd");

//   this.loadingRecords17 = true;
//   // this.api.getBranchWiseMemoPendancy(this.pageIndex17, this.pageSize17, this.sortKey1, this.sortValue1, '', this.START_DATE,this.END_DATE).subscribe(data => {
//   //   if (data['code'] == 200) {
//   //     this.loadingRecords17 = false;
//   //     this.totalRecords17 = data['count'];
//   //     this.dataList17 = data['data'];
//   //     this.isSpinning17 = false;
//   //     this.filterClass17 = 'filter-invisible';
//   //   }
//   // }, err => {
//   //   console.log(err);
//   // });
// }

// clearFilter17() {
//   this.filterClass17 = 'filter-invisible';
//   this.isFilterApplied17 = 'default';
//   this.startValue9 = new Date();
//   this.endValue9 = new Date();
//   this.applyFilter17()

// }

// Table 18
// showFilter18() {
//   if (this.filterClass18 === "filter-visible")
//     this.filterClass18 = "filter-invisible";

//   else
//     this.filterClass18 = "filter-visible";
// }

dataList18: any;

branchWiseDiscountPendancy(){
 this.api.getBranchWiseDiscountPendancy(this.pageIndex18, this.pageSize18, this.sortKey10, this.sortValue1, '', this.START_DATE,this.END_DATE).subscribe(data => {
    if (data['code'] == 200) {
      this.loadingRecords18 = false;
      this.totalRecords18 = data['count'];
      this.dataList18 = data['data'];
      this.isSpinning18 = false;
      this.filterClass18 = 'filter-invisible';
    }
  }, err => {
    console.log(err);
  });
}

// applyFilter18() {
//   this.isFilterApplied18 = 'primary'
//   this.filterClass18 = "filter-invisible";

//   this.END_DATE = this.datePipe.transform(this.endValue10, "yyyy-MM-dd");
//   this.START_DATE = this.datePipe.transform(this.startValue10, "yyyy-MM-dd");

//   this.loadingRecords18 = true;
//   // this.api.getBranchWiseDiscountPendancy(this.pageIndex18, this.pageSize18, this.sortKey1, this.sortValue1, '', this.START_DATE,this.END_DATE).subscribe(data => {
//   //   if (data['code'] == 200) {
//   //     this.loadingRecords18 = false;
//   //     this.totalRecords18 = data['count'];
//   //     this.dataList18 = data['data'];
//   //     this.isSpinning18 = false;
//   //     this.filterClass18 = 'filter-invisible';
//   //   }
//   // }, err => {
//   //   console.log(err);
//   // });
// }

// clearFilter18() {
//   this.filterClass18 = 'filter-invisible';
//   this.isFilterApplied18 = 'default';
//   this.startValue10 = new Date();
//   this.endValue10 = new Date();
//   this.applyFilter18()

// }

}
