export class Log {
    CLIENT_ID:number
    USER_ID:any
    REMARK:any
    NEW_STATUS:any
    EMP_ID:any
    CURRENT_STATUS:any
    USER_NAME:any
    DATE:any
}
