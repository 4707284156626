export class rateMaster{
    CLIENT_ID:any;
    FROM_DATE:any;
    INCENTIVE_STRUCUTURE_NAME:any;
    MODEL_IDS:any;
    ROLE_IDS:any;
    TO_DATE:any;
    STATUS:boolean;
    ID:any;
    
}