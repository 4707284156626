import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modelmap',
  templateUrl: './modelmap.component.html',
  styleUrls: ['./modelmap.component.css']
})
export class ModelmapComponent implements OnInit {

  @Input()modeltype=[]
  @Input()fueltype:any
  @Input()varienttype:any
  @Input()tranmission:any

  finalData


  // fueltype=[]
  // varienttype=[]
  // tranmission=[]

  constructor() { }


  MODEL1:[]
  FULES:any
  VARIENT:any
  TRANSMISSION:any
  ngOnInit() {
  }

  // ROLES2
  MODELS2=[]
  MODELS21=[]
  models=[]

  fule
  varient
  transmission

  // save() {
  //   this.modeltype = [];
  //   this.finalData = []
  //   var isOk = true;

  //   if (this.ROLES2.length == 0) {
  //     isOk = false;
  //     // this.message.error("Please Select Roles", "");

  //   } else if (this.MODELS2.length == 0) {
  //     isOk = false;
  //     // this.message.error("Please Select Models", "");
  //   }

  //   if (isOk) {
  //     this.ROLES = this.ROLES2
  //     this.MODELS = this.MODELS2
  //     this.ROLES22 = []
  //     for (let i = 0; i < this.ROLES.length; i++) {
  //       var kk = this.roles.findIndex(x => x.ID == this.ROLES[i]);
  //       this.ROLES22.push(this.roles[kk]['NAME']);
  //       this.MODELS22 = []
  //       for (let j = 0; j < this.MODELS.length; j++) {
  //         this.finalData.push({ "ROLE_ID": this.ROLES[i], "MODEL_ID": this.MODELS[j], "AMOUNT": 0 })
  //         // console.log(this.finalData);
  //         var kkk = this.model.findIndex(x => x.ID == this.MODELS[j]);
  //         this.MODELS22.push(this.model[kkk]['NAME'])
  //       }
  //       if (i + 1 == this.ROLES.length) {
  //         this.dataList = [...[], ...this.finalData]
  //       }



  //     }
     
  //   }
  //   this.MODELS2=[]
  //   this.ROLES2=[]
  // }
  
  save(){

    var isOk=true
    if(isOk){
      for (let i = 0; i < this.MODEL1.length; i++) {
        // var kk = this.models.findIndex(x => x.ID == this.MODEL1[i]);
        this.MODELS21.push(this.MODEL1);
        // this.MODELS22 = []
  
        console.log(this.MODELS21)
       
    }

   
    
    }

   
  // console.log(this.fule);
  
    this.MODEL1=[]

}
}


  

