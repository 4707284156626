import { Component, OnInit, Input } from '@angular/core';
import * as XLSX from 'xlsx';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';

import { NgForm } from '@angular/forms';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-importmaster',
  templateUrl: './importmaster.component.html',
  styleUrls: ['./importmaster.component.css']
})
export class ImportmasterComponent implements OnInit {
  isOk: boolean = true;
  databaseexcel1: any = [];
  databaseexcel111: any = [];
  downloads: any;
  checked = false;
  @Input()
  DuplicateCount: any
  @Input()
  FailedCount: any
  @Input()
  SuccessCount: any
  @Input()
  TotalCount: any


  updatecount: any


  @Input()
  FailedRecords: any = []
  @Input()
  DuplicateRecords: any = []
  @Input()
  SuccessedRecords: any = []
  @Input()
  keys: any = []
  @Input()
  keys1: any = []
  @Input()
  keys2: any = []

  @Input() value

  @Input()SHEET_ID
  @Input() MASTER:any


  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }
  @Input()
  drawerVisible: boolean = false;
  @Input()
  PSFdrawerVisible: boolean = false;
  @Input()
  SMRdrawerVisible: boolean = false;
  @Input()
  Extendwarrentyvisible: boolean = false;
  @Input()
  InsurancedrawerVisible: boolean = false;
  Excelbox: any
  ngOnInit() {
    this.DuplicateCount = []
    this.FailedCount = []
    this.SuccessCount = []
    this.TotalCount = []
    this.FailedRecords = []
    this.DuplicateRecords = []
    this.SuccessedRecords = []
  }

  @Input()
  drawerClose!: Function;


  @Input()
  data: ServiceModuleExcel = new ServiceModuleExcel();
  @Input()
  psfdata: ServiceModuleExcel = new ServiceModuleExcel();
  @Input()
  SMRdata: ServiceModuleExcel = new ServiceModuleExcel();
  @Input()
  Extenddata: ServiceModuleExcel = new ServiceModuleExcel();
  @Input()
  Insurancedata: ServiceModuleExcel = new ServiceModuleExcel();

  isSpinning: boolean = false
  loadingRecords: boolean = false
  @Input()
  index = 0
  downloadexcel: any = [];
  uploaddata: any = []
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  @Input()
  fileURL: any
  databaseexcel: any = [];
  conditionalvalue: any = [];
  @Input()
  excel: any;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  @Input()
  dataList = [];
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["Name", "Name"], ["SEQUENCE_NO", "Sequence No."]]
  // failedrecorddata: any[] = [];
  ALL_COLUMNS = []
  stepsdisabled = true;
  stepsdisabled1 = true;
  @Input()
  isClosed: boolean = false;
  @Input()
  isInsuranceLead: boolean = false;
  @Input()
  isSMRLead: boolean = false;
  @Input()
  isExtendedWarrantyLead: boolean = false;
  @Input()
  isPSFLead: boolean = false;

  COLUMN_JSON = [
    {
      "TABLE_FIELD": "EMPLOYEE_NAME",
      "EXCEL_FIELD": "EMPLOYEE_NAME",
      "DATA_TYPE": "VARCHAR",
      "SIZE": "128",
      
    },


  ]
  // "CATEGORY_STATUS" :this.value
  // excelurl="http://192.168.29.203:9440/static/ExcelMasters/EMPLOYEE_MASTER.xlsx"

  downloadMyFile() {
    this.loadingRecords = true;
    this.api.getdatabasetable(0, 0, '', '', "").subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0)
        // this.downloadexcel = data['data'];
        if (this.isInsuranceLead == true) {
          this.excel = "https://api.maihyundai.in/static/ExcelMasters/" + data['data'][1]['EXCEL_URL']
          console.log('excel_url', this.excel);
          window.open(this.excel, '_self')
        }
        else if (this.isSMRLead == true) {
          this.excel = "https://api.maihyundai.in/static/ExcelMasters/" + data['data'][3]['EXCEL_URL']
          console.log('excel_url', this.excel);
          window.open(this.excel, '_self')
        }
        else if (this.isPSFLead == true) {
          this.excel = "https://api.maihyundai.in/static/ExcelMasters/" + data['data'][2]['EXCEL_URL']
          console.log('excel_url', this.excel);
          window.open(this.excel, '_self')
        }
        else {
          this.excel = "https://api.maihyundai.in/static/ExcelMasters/" + data['data'][4]['EXCEL_URL']
          console.log('excel_url', this.excel);
          window.open(this.excel, '_self')
        }
      // this.api.download(this.excel).subscribe(blob => 
      //   {
      //     // const a = document.createElement('a')
      //     // const objectUrl = URL.createObjectURL(blob)
      //     // a.download = 'EMPLOYEE_MASTER.xlsx';
      //     // a.click();
      //     // URL.revokeObjectURL(objectUrl);})
      //   })
      // const link = document.createElement('a');
      // link.setAttribute('target', '_blank');
      // link.setAttribute('href', 'abc.net/files/test.ino');
      // link.setAttribute('download', 'abcd.xlsx');
      // document.body.appendChild(link);
      // link.click();
      // link.remove();
      // this.excelService.exportAsExcelFile(this.excel, 'sample');
      this.isSpinning = false;
      this.loadingRecords = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }
  // downloadMyFile() {
  //   this.loadingRecords = true;
  //   this.api.getdatabasetable(0, 0, '', '', " ").subscribe(data => {
  //     if (data['code'] == 200 && data['count'] > 0) {
  //       if (this.isInsuranceLead == true) {
  //         var json = data['data'][1]['COLUMN_JSON'];
  //       }
  //       else if (this.isSMRLead == true) {
  //         var json = data['data'][3]['COLUMN_JSON'];
  //       }
  //       else if (this.isPSFLead == true) {
  //         var json = data['data'][2]['COLUMN_JSON'];
  //       }
  //       else {
  //         var json = data['data'][4]['COLUMN_JSON'];
  //       }
  //       var val = JSON.parse(json)
  //       this.databaseexcel1 = val
  //       this.COLUMN_JSON = []
  //       for (var i = 0; i < this.data1.length; i++) {
  //         this.COLUMN_JSON.push(

  //           {
  //             "TABLE_FIELD": "",
  //             "EXCEL_FIELD": this.data1[i],
  //             "DATA_TYPE": "",
  //             "SIZE": "",
             
  //           },


  //         )
  //         for (var j = 0; j < val.length; j++) {
  //           if (this.data1[i] == val[j]['FIELD_NAME'])
  //             this.COLUMN_JSON[i] =

  //             {
  //               "EXCEL_FIELD": this.data1[i],
  //               "TABLE_FIELD": val[j]['FIELD_NAME'],
  //               "DATA_TYPE": val[j]["DATA_TYPE"],
  //               "SIZE": val[j]["SIZE"],
                
  //             }

  //         }


  //       }



  //       //  this.databaseexcel1 = json.map(object => object.FIELD_NAME);
  //       //  console.log("Jsontoarry:",this.databaseexcel1)
  //     }
  //     else { }
  //     // for (let i = 0; i < this.databaseexcel1.length; i++) {
  //     //   if (this.databaseexcel1[i] == this.data1[i]) {
  //     //     this.databaseexcel1 = this.data1
  //     //     console.log('console:', this.databaseexcel1)
  //     //   }
  //     //   for (this.databaseexcel1; this.databaseexcel1.length < this.data1.length; this.databaseexcel1.length++) {

  //     //     if (this.databaseexcel1[i] != this.data1[i]) {
  //     //       this.databaseexcel1[i] = null
  //     //     }
  //     //   }
  //     // }
  //     // for (this.databaseexcel1; this.databaseexcel1.length > this.data1.length; this.databaseexcel1.length--) {
  //     //   console.log(this.databaseexcel1.length)
  //     this.loadingRecords = false;
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //     this.loadingRecords = false;

  //   });
  // }

  // DownloadExcel(): void {
  //   /* generate worksheet */
  //   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data["EXCEL_URL"]);



  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   /* save to file */
  //   XLSX.writeFile(wb, this.fileName);
  // }


  onIndexChange(index: number): void {
    this.index = index;
  }
  data1: any = [];
  // data: AOA = [];

  progress: number;

  onFileSelected(event: any) {
    this.onFileChange(event)
    console.log(event.target.files[0].type);
    if (
      event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    ) {
      this.fileURL = <File>event.target.files[0];
      // this.message.info("Upload And Next", '');
      console.log(typeof (this.fileURL));

    } else {
      this.message.error('Please select only excel file type.', '');
      this.fileURL = null;
      this.data.EXCEL_URL = '';

    }
  }

  onFileChange(evt: any) {
    this.data1 = [];

    // this.progress = 1;


    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.uploaddata = XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log('data:', ...this.uploaddata[0]);

      this.uploaddata.map((res) => {
        if (res[0] === 'no') {
          console.log(res[0]);
        } else {
          console.log(res[0]);
        }
      });

      for (let index = 0; index < this.uploaddata[0].length; index++) {
        var uploadedData = this.uploaddata[0][index].replace(/_/g, ' ').toUpperCase()

        this.data1.push(uploadedData);

      }

      // console.log('data1:', this.data1);
      // console.log(this.data1.length, 'this.data1.length()');

    };


    // console.log(this.data, 'this.data');
    reader.readAsBinaryString(target.files[0]);

    console.log('data1', this.data1);
    // this.message.success("Upload Successfull", '')
    // if(this.databaseexcel1.length<this.data1)
    // {
    //   this.databaseexcel1.length++;
    // }
    // else{}

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    // this.search(false);
  }


  save(addNew: boolean, myForm: NgForm): void {
    if (this.isInsuranceLead == true) {
      this.data.TABLE_ID = 4
    }
    else if (this.isPSFLead == true) {
      this.data.TABLE_ID = 3
    }
    else if (this.isSMRLead == true) {
      this.data.TABLE_ID = 2
    }
    else {
      this.data.TABLE_ID = 1
    }

    if (this.isOk) {
      this.data.UPLOADED_DATE_TIME = this.datePipe.transform(
        new Date(), 'yyyy-MM-dd hh:mm:ss');
      console.log(this.fileURL, "fileURL")
      console.log(this.data.EXCEL_URL, "data.EXCEL_URL ")

      this.isSpinning = true;
      if (this.fileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.EXCEL_URL != undefined &&
          this.data.EXCEL_URL.trim() != ''
        ) {
          var arr = this.data.EXCEL_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }

        this.api
          .onUpload3('serviceModuleFiles', this.fileURL, url)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              this.data.EXCEL_URL = url
              this.api.createserviceModuleExcelMaster(this.data).subscribe(successCode => {
                if (successCode.code == '200') {
                  this.message.success('Information Upload Successfully', '');
                  this.stepsdisabled = false
                  this.index = 1

                  this.isSpinning = false;
                }
                else {
                  this.message.error('Something went wrong', '')
                  this.isSpinning = false;
                }
              });

              console.log(this, "this.data.EXCEL_URL1");
            }
          });
      }
      else if (this.data.EXCEL_URL == null || this.data.EXCEL_URL == '') {
        this.message.error('Please Select An Excel To Upload ...', '');
        this.isSpinning = false;

        this.api.createserviceModuleExcelMaster(this.data).subscribe((successCode) => {
          if (successCode.code == "200") {
            this.message.success('Information Saved Successfully...', "");
            this.isSpinning = false;

            this.data = new ServiceModuleExcel();
            this.index = 1

          }
          else {
            // this.message.error('Something went wrong','')
          }
        })
      }

      if(this.MASTER=='PSF_MASTER'){
        console.log(this.MASTER);
        
        this.loadingRecords = true;
        this.api.getdatabasetable(0, 0, '', '',  " AND TABLE_NAME = 'PSF_MASTER' ").subscribe(data => {
          if (data['code'] == 200 && data['count'] > 0) {
            // if (this.isInsuranceLead == true) {
              var json = data['data'][0]['COLUMN_JSON'];
            // }
            // else if (this.isSMRLead == true) {
            //   var json = data['data'][3]['COLUMN_JSON'];
            // }
            // else if (this.isPSFLead == true) {
            //   var json = data['data'][2]['COLUMN_JSON'];
            // }
            // else {
            //   var json = data['data'][4]['COLUMN_JSON'];
            // }
            var val = JSON.parse(json)
            this.databaseexcel1 = val
            for (let index = 0; index < this.databaseexcel1.length; index++) {
              this.databaseexcel111.push(this.databaseexcel1[index]['FIELD_NAME'].replace(/_/g, ' '))
  
            }
  
            console.log('this.databaseexcel1', this.databaseexcel111);
  
            this.COLUMN_JSON = []
            for (var i = 0; i < this.data1.length; i++) {
              this.COLUMN_JSON.push(
  
                {
                  "TABLE_FIELD": "",
                  "EXCEL_FIELD": this.data1[i],
                  "DATA_TYPE": "",
                  "SIZE": "",
                  
                },
  
  
              )
              for (var j = 0; j < val.length; j++) {
                if (this.data1[i] == val[j]['FIELD_NAME'].replace(/_/g, ' '))
                  this.COLUMN_JSON[i] =
  
                  {
                    "EXCEL_FIELD": this.data1[i],
                    "TABLE_FIELD": val[j]['FIELD_NAME'].replace(/_/g, ' '),
                    "DATA_TYPE": val[j]["DATA_TYPE"],
                    "SIZE": val[j]["SIZE"],
                    
                  }
  
              }
  
  
            }
  
  
  
            //  this.databaseexcel1 = json.map(object => object.FIELD_NAME);
            //  console.log("Jsontoarry:",this.databaseexcel1)
          }
          else { }
          // for (let i = 0; i < this.databaseexcel1.length; i++) {
          //   if (this.databaseexcel1[i] == this.data1[i]) {
          //     this.databaseexcel1 = this.data1
          //     console.log('console:', this.databaseexcel1)
          //   }
          //   for (this.databaseexcel1; this.databaseexcel1.length < this.data1.length; this.databaseexcel1.length++) {
  
          //     if (this.databaseexcel1[i] != this.data1[i]) {
          //       this.databaseexcel1[i] = null
          //     }
          //   }
          // }
          // for (this.databaseexcel1; this.databaseexcel1.length > this.data1.length; this.databaseexcel1.length--) {
          //   console.log(this.databaseexcel1.length)
          this.loadingRecords = false;
        }, err => {
          console.log(err);
          this.isSpinning = false;
          this.loadingRecords = false;
  
        });
      }else{
        this.loadingRecords = true;
        this.api.getdatabasetable(0, 0, '', '',  " AND TABLE_NAME = 'SMR_MASTER' ").subscribe(data => {
          if (data['code'] == 200 && data['count'] > 0) {
            // if (this.isInsuranceLead == true) {
              var json = data['data'][0]['COLUMN_JSON'];
            // }
            // else if (this.isSMRLead == true) {
            //   var json = data['data'][3]['COLUMN_JSON'];
            // }
            // else if (this.isPSFLead == true) {
            //   var json = data['data'][2]['COLUMN_JSON'];
            // }
            // else {
            //   var json = data['data'][4]['COLUMN_JSON'];
            // }
            var val = JSON.parse(json)
            this.databaseexcel1 = val
            for (let index = 0; index < this.databaseexcel1.length; index++) {
              this.databaseexcel111.push(this.databaseexcel1[index]['FIELD_NAME'].replace(/_/g, ' '))
  
            }
  
            console.log('this.databaseexcel1', this.databaseexcel111);
  
            this.COLUMN_JSON = []
            for (var i = 0; i < this.data1.length; i++) {
              this.COLUMN_JSON.push(
  
                {
                  "TABLE_FIELD": "",
                  "EXCEL_FIELD": this.data1[i],
                  "DATA_TYPE": "",
                  "SIZE": "",
                  
                },
  
  
              )
              for (var j = 0; j < val.length; j++) {
                if (this.data1[i] == val[j]['FIELD_NAME'].replace(/_/g, ' '))
                  this.COLUMN_JSON[i] =
  
                  {
                    "EXCEL_FIELD": this.data1[i],
                    "TABLE_FIELD": val[j]['FIELD_NAME'].replace(/_/g, ' '),
                    "DATA_TYPE": val[j]["DATA_TYPE"],
                    "SIZE": val[j]["SIZE"],
                    
                  }
  
              }
  
  
            }
  
  
  
            //  this.databaseexcel1 = json.map(object => object.FIELD_NAME);
            //  console.log("Jsontoarry:",this.databaseexcel1)
          }
          else { }
          // for (let i = 0; i < this.databaseexcel1.length; i++) {
          //   if (this.databaseexcel1[i] == this.data1[i]) {
          //     this.databaseexcel1 = this.data1
          //     console.log('console:', this.databaseexcel1)
          //   }
          //   for (this.databaseexcel1; this.databaseexcel1.length < this.data1.length; this.databaseexcel1.length++) {
  
          //     if (this.databaseexcel1[i] != this.data1[i]) {
          //       this.databaseexcel1[i] = null
          //     }
          //   }
          // }
          // for (this.databaseexcel1; this.databaseexcel1.length > this.data1.length; this.databaseexcel1.length--) {
          //   console.log(this.databaseexcel1.length)
          this.loadingRecords = false;
        }, err => {
          console.log(err);
          this.isSpinning = false;
          this.loadingRecords = false;
  
        });
      }
   
    }
  }



  

  fieldchange(event: any, c: any) {
    console.log(event);
    for (var j = 0; j < this.databaseexcel1.length; j++) {
      var vvv = this.databaseexcel1[j]['FIELD_NAME'].replace(/_/g, ' ')
      if (event == vvv) {
        this.COLUMN_JSON[c]["TABLE_FIELD"] = this.databaseexcel1[j]['FIELD_NAME']
        this.COLUMN_JSON[c]["DATA_TYPE"] = this.databaseexcel1[j]['DATA_TYPE'];
        this.COLUMN_JSON[c]["SIZE"] = this.databaseexcel1[j]['SIZE'];
      }
    }
    // console.log(' this.COLUMN_JSON[c]["TABLE_FIELD"]................', this.COLUMN_JSON[c]["TABLE_FIELD"]);

  }
  // save2(addNew: boolean, myForm: NgForm){
  //   // for(var f=0;f<this.COLUMN_JSON.length;f++){
  //   //    if(this.COLUMN_JSON[f]["TABLE_FIELD"] == this.databaseexcel1[f]['FIELD_NAME'])
  //   //    {
  //   //     // console.log(this.COLUMN_JSON[f]["TABLE_FIELD"] == this.databaseexcel1[f]['FIELD_NAME'])
  //   //    }
  //   // }
  // if(this.data.IS_ALLOW_DUPLICATES==true){
  //   this.data.IS_ALLOW_DUPLICATES="Y"
  // }
  // if(this.data.IS_ALLOW_DUPLICATES==false){
  //   this.data.IS_ALLOW_DUPLICATES="N"
  // }
  //   console.log( this.data.IS_ALLOW_DUPLICATES," this.data.IS_ALLOW_DUPLICATES")
  // }
  a: boolean;
  allowDuplicateRecords: boolean = false;
  data2: any = [];
  save2(addNew: boolean, myForm: NgForm) {
    console.log(this.ALL_COLUMNS);

    var index1: any
    var evt: any
    this.fieldchange(evt, index1)
    console.log(this.databaseexcel1)
    this.a = false;
    var fcount = 0;



    for (var f = 0; f < this.COLUMN_JSON.length; f++) {
      for (var r = 0; r < this.COLUMN_JSON.length; r++) {


        if (this.COLUMN_JSON[f]['TABLE_FIELD'] == this.COLUMN_JSON[r]['TABLE_FIELD']) {
          this.a = true;
          fcount++;

          if (this.COLUMN_JSON[f]['TABLE_FIELD'] == null || this.COLUMN_JSON[r]['TABLE_FIELD'] == null) {
            this.COLUMN_JSON[f]['TABLE_FIELD'] = '';
            this.COLUMN_JSON[r]['TABLE_FIELD'] = '';
          }
          // console.log(this.COLUMN_JSON[f]['EXCEL_FIELD'], this.databaseexcel1[r]['FIELD_NAME'])
        }

      }
      if (f + 1 == this.COLUMN_JSON.length && this.a) {
        if (fcount > this.COLUMN_JSON.length && this.COLUMN_JSON[f]['TABLE_FIELD'] != '') {
          this.message.error("Same Column on Application Fields Are Same", '');
          this.a = true;
        }
        else {
          this.a = false;
        }
      }

      for (let index = 0; index < this.COLUMN_JSON.length; index++) {

        this.COLUMN_JSON[index]['TABLE_FIELD'] = this.COLUMN_JSON[index]['TABLE_FIELD'].replace(/ /g, '_')
        this.COLUMN_JSON[index]['EXCEL_FIELD'] = this.COLUMN_JSON[index]['EXCEL_FIELD'].replace(/ /g, '_')

        this.COLUMN_JSON[index]['EXCEL_FIELD'] = this.COLUMN_JSON[index]['EXCEL_FIELD'].replace('.', "")
        this.COLUMN_JSON[index]['EXCEL_FIELD'] = this.COLUMN_JSON[index]['EXCEL_FIELD'].replace('/', "")

        this.COLUMN_JSON[index]['TABLE_FIELD'] = this.COLUMN_JSON[index]['TABLE_FIELD'].replace('.', "")
        this.COLUMN_JSON[index]['TABLE_FIELD'] = this.COLUMN_JSON[index]['TABLE_FIELD'].replace('/', "")

      }
      console.log(this.COLUMN_JSON)
    }

    if (this.a == false) {
      this.isSpinning = true
      this.data2 = this.COLUMN_JSON

      console.log(this.data, "this.data")
      // this.data2=JSON.stringify(this.COLUMN_JSON)

      if (this.allowDuplicateRecords == true) {
        this.data.IS_ALLOW_DUPLICATES = "Y"
      }
      if (this.allowDuplicateRecords == false) {
        this.data.IS_ALLOW_DUPLICATES = "N"
      }
      console.log(this.data.IS_ALLOW_DUPLICATES, " this.data.IS_ALLOW_DUPLICATES")
      // }
      if (this.isSMRLead == true) {
        this.api.serviceReminderSMR(this.data.EXCEL_URL, this.data2, 2, this.data.IS_ALLOW_DUPLICATES, this.ALL_COLUMNS,this.value,this.SHEET_ID).subscribe((successCode) => {
          if (successCode.code == "200") {
            this.message.success('Information Saved Successfully...', "");
            this.isSpinning = false;
            this.stepsdisabled1 = false;
            this.index = 2

            this.DuplicateCount = successCode.DuplicateCount
            this.FailedCount = successCode.FailedCount
            this.SuccessCount = successCode.SuccessCount
            this.TotalCount = successCode.TotalCount
            this.totalRecords = this.TotalCount
            this.FailedRecords = successCode.FailedRecords
            this.DuplicateRecords = successCode.DuplicateRecords
            this.SuccessedRecords = successCode.SuccessedRecords
            var failedData = JSON.parse(JSON.stringify(this.FailedRecords).replace(/null/g, ''))
            console.log("failed record:", failedData)
            for (let i = 0; i < this.SuccessCount; i++) {

              this.keys.push(Object.values(this.SuccessedRecords[i]))
            }

            for (let n = 0; n < this.DuplicateCount; n++) {

              this.keys1.push(Object.values(this.DuplicateRecords[n]))
            }
            for (let b = 0; b < this.FailedCount; b++) {

              this.keys2.push(Object.values(this.FailedRecords[b]))
            }

            // this.SuccessedRecords=this.keys
            console.log(this.keys, 'This is a Array1')
            console.log(this.keys1, 'This is a Array2')
            console.log(this.keys2, 'This is a Array3')

            // console.log(this.keys2.replace(/"|'/g, ''));
            // for (let i = 0; i < this.FailedRecords.length; i++) {


            //   // this.failedrecorddata.push({},this.FailedRecords[i])
            //   // this.keys = {...this.keys,}
            //   // Object.keys(this.SuccessedRecords[i]).map((item) => {
            //   //   console.log(item)
            //   //   this.keys.push(...this.keys,item)
            //   // });
            // }
            // console.log(this.failedrecorddata,"this.failedrecorddata");

            console.log(this.FailedRecords, "successCode.FailedRecords");

            console.log(this.DuplicateRecords, "successCode.DuplicateRecords");
            console.log(this.SuccessedRecords, "successCode.SuccessedRecords");

          }
          else {
            this.message.error('Failed to import data','')
            this.drawerClose()
            this.isSpinning = false;

            this.index=0

            this.fileURL = null;
          }
        })
      }
      else if (this.isPSFLead == true) {
        this.api.postservicefollowup(this.data.EXCEL_URL, this.data2, 3, this.data.IS_ALLOW_DUPLICATES,this.SHEET_ID).subscribe((successCode) => {
          if (successCode.code == "200") {
            this.message.success('Information Saved Successfully...', "");
            this.isSpinning = false;
            this.stepsdisabled1 = false;
            this.index = 2

            this.DuplicateCount = successCode.DuplicateCount
            this.FailedCount = successCode.FailedCount
            this.SuccessCount = successCode.SuccessCount
            this.TotalCount = successCode.TotalCount
            this.totalRecords = this.TotalCount
            this.FailedRecords = successCode.FailedRecords
            this.DuplicateRecords = successCode.DuplicateData
            this.SuccessedRecords = successCode.insertedRecords


            for (let i = 0; i < this.SuccessCount; i++) {

              this.keys.push(Object.values(this.SuccessedRecords[i]))
            }

            for (let n = 0; n < this.DuplicateCount; n++) {

              this.keys1.push(Object.values(this.DuplicateRecords[n]))
            }
            for (let b = 0; b < this.FailedCount; b++) {

              this.keys2.push(Object.values(this.FailedRecords[b]))
            }

            // this.SuccessedRecords=this.keys
            console.log(this.keys, 'This is a Array1')
            console.log(this.keys1, 'This is a Array2')
            console.log(this.keys2, 'This is a Array3')

            // console.log(this.keys2.replace(/"|'/g, ''));
            // for (let i = 0; i < this.FailedRecords.length; i++) {


            //   // this.failedrecorddata.push({},this.FailedRecords[i])
            //   // this.keys = {...this.keys,}
            //   // Object.keys(this.SuccessedRecords[i]).map((item) => {
            //   //   console.log(item)
            //   //   this.keys.push(...this.keys,item)
            //   // });
            // }
            // console.log(this.failedrecorddata,"this.failedrecorddata");

            console.log(this.FailedRecords, "successCode.FailedRecords");

            console.log(this.DuplicateRecords, "successCode.DuplicateRecords");
            console.log(this.SuccessedRecords, "successCode.SuccessedRecords");

          }
          else {
            // this.message.error('Something went wrong','')
            this.isSpinning = false;

            this.index=0

            this.fileURL = null;
          }
        })
      }
      else if (this.isInsuranceLead == true) {
        this.api.hapAndGDMSLost(this.data.EXCEL_URL, this.data2, 4, this.data.IS_ALLOW_DUPLICATES).subscribe((successCode) => {
          if (successCode.code == "200") {
            this.message.success('Information Saved Successfully...', "");
            this.isSpinning = false;
            this.stepsdisabled1 = false;
            this.index = 2

            this.DuplicateCount = successCode.DuplicateCount
            this.FailedCount = successCode.FailedCount
            this.SuccessCount = successCode.SuccessCount
            this.TotalCount = successCode.TotalCount
            this.totalRecords = this.TotalCount
            this.FailedRecords = successCode.FailedRecords
            this.DuplicateRecords = successCode.DuplicateRecords
            this.SuccessedRecords = successCode.SuccessedRecords
            var failedData = JSON.parse(JSON.stringify(this.FailedRecords).replace(/NULL/g, ''))
            console.log("failed record:", failedData)

            for (let i = 0; i < this.SuccessCount; i++) {

              this.keys.push(Object.values(this.SuccessedRecords[i]))
            }

            for (let n = 0; n < this.DuplicateCount; n++) {

              this.keys1.push(Object.values(this.DuplicateRecords[n]))
            }
            for (let b = 0; b < this.FailedCount; b++) {

              this.keys2.push(Object.values(failedData[b]))
            }

            // this.SuccessedRecords=this.keys
            console.log(this.keys, 'This is a Array1')
            console.log(this.keys1, 'This is a Array2')
            console.log(this.keys2, 'This is a Array3')

            // console.log(this.keys2.replace(/"|'/g, ''));
            // for (let i = 0; i < this.FailedRecords.length; i++) {


            //   // this.failedrecorddata.push({},this.FailedRecords[i])
            //   // this.keys = {...this.keys,}
            //   // Object.keys(this.SuccessedRecords[i]).map((item) => {
            //   //   console.log(item)
            //   //   this.keys.push(...this.keys,item)
            //   // });
            // }
            // console.log(this.failedrecorddata,"this.failedrecorddata");

            console.log(this.FailedRecords, "successCode.FailedRecords");

            console.log(this.DuplicateRecords, "successCode.DuplicateRecords");
            console.log(this.SuccessedRecords, "successCode.SuccessedRecords");

          }
          else if (successCode.code == "300") {
            this.message.error('Your Excel Data Should Not be Empty. please..."', '')
          }

          else {
            this.message.error('Server Not Found..."', '')
            this.isSpinning = false;

            this.index=0

            this.fileURL = null;
          }
        })
      }
      else {
        this.api.importexcelstep2(this.data.EXCEL_URL, this.data2, 1, this.data.IS_ALLOW_DUPLICATES).subscribe((successCode) => {
          if (successCode.code == "200") {
            this.message.success('Information Saved Successfully...', "");
            this.isSpinning = false;
            this.stepsdisabled1 = false;
            this.index = 2

            this.DuplicateCount = successCode.DuplicateCount
            this.FailedCount = successCode.FailedCount
            this.SuccessCount = successCode.SuccessCount
            this.TotalCount = successCode.TotalCount
            this.updatecount = successCode.UpdateCount
            this.totalRecords = this.TotalCount
            this.FailedRecords = successCode.FailedRecords
            this.DuplicateRecords = successCode.DuplicateRecords
            this.SuccessedRecords = successCode.SuccessedRecords


            for (let i = 0; i < this.SuccessedRecords.length; i++) {

              this.keys.push(Object.values(this.SuccessedRecords[i]))
            }

            for (let n = 0; n < this.DuplicateRecords.length; n++) {

              this.keys1.push(Object.values(this.DuplicateRecords[n]))
            }
            for (let b = 0; b < this.FailedRecords.length; b++) {

              this.keys2.push(Object.values(this.FailedRecords[b]))
            }

            // this.SuccessedRecords=this.keys
            console.log(this.keys2, 'This is a Array')
            // console.log(this.keys2.replace(/"|'/g, ''));
            // for (let i = 0; i < this.FailedRecords.length; i++) {


            //   // this.failedrecorddata.push({},this.FailedRecords[i])
            //   // this.keys = {...this.keys,}
            //   // Object.keys(this.SuccessedRecords[i]).map((item) => {
            //   //   console.log(item)
            //   //   this.keys.push(...this.keys,item)
            //   // });
            // }
            // console.log(this.failedrecorddata,"this.failedrecorddata");

            console.log(this.FailedRecords, "successCode.FailedRecords");

            console.log(this.DuplicateRecords, "successCode.DuplicateRecords");
            console.log(this.SuccessedRecords, "successCode.SuccessedRecords");

          }
          else {
            // this.message.error('Something went wrong','')
            this.isSpinning = false;

            this.index=0

            this.fileURL = null;
          }
        })
      }
    }
  }
  finish(addnew: boolean, myForm: NgForm) {
    this.drawerClose();
    myForm.reset();
    this.stepsdisabled = true;
    this.stepsdisabled1 = true;
    this.index = 0;
    this.fileURL = ''
    this.DuplicateCount = []
    this.FailedCount = []
    this.SuccessCount = []
    this.TotalCount = []
    this.FailedRecords = []
    this.DuplicateRecords = []
    this.SuccessedRecords = []
    this.keys = []
    this.keys1 = []
    this.keys2 = []
  }
}