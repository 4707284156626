import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { feedback } from 'src/app/Models/feedformback';
// import { feedback } from 'src/app/Models/feedback';
// import { feedback } from 'src/app/Models/feedback';
import { ApiService } from 'src/app/Service/api.service';
// import { AddemployeemasterComponent } from '../addemployeemaster/addemployeemaster.component';
import { ExportService } from 'src/app/Service/export.service';
// import { AddemployeemasterComponent } from '../employeemaster/addemployeemaster/addemployeemaster.component';
// import { feedback } from 'src/app/Models/feedback';
// import { feedback } from 'src/app/Models/feedback';


@Component({
  selector: 'app-feedback-areas',
  templateUrl: './feedback-areas.component.html',
  styleUrls: ['./feedback-areas.component.css']
})
export class FeedbackAreasComponent implements OnInit {

    formTitle = "Manage Area";
    pageIndex = 1;
    pageSize = 10;
    totalRecords = 1;
    dataList = [];

    loadingRecords = false;
    sortValue: string = "desc";
    sortKey: string = "ID";
    searchText: string = "";
    filterQuery: string = "";
    isFilterApplied: string = "default";
    orgId = this.cookie.get('orgId');
    branchId = this.cookie.get('branchId')
    status=false
    columns: string[][] = [["NAME", "Name"], ["TYPE", "Email"],["STATUS", "STATUS"]]
  

    drawerVisible: boolean;
    drawerVisible2: boolean;

    drawerTitle: string;
    drawerData: any=[]
    applicationId = Number(this.cookie.get('applicationId'))
    drawerVisible1: boolean;
    listOfData: feedback[] = [];
    OPEN_TIME2 = null;
    CLOSE_TIME2 = null;
    DAYS = false;
    table2 = 0;
    dataList3 = [];
    loginRoleStatus = this.cookie.get('loginType');
    selectedRoleStatus = this.cookie.get('selectedLoginType');
    ROLE_ID = Number(this.cookie.get('roleId'))
    data24: any;
  
    constructor(private _exportService: ExportService, private datePipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }
  
    ngOnInit() {
      this.search();
    }
  
    disa:boolean = false
    // Basic Methods
    sort(sort: { key: string; value: string }): void {
      this.sortKey = sort.key;
      this.sortValue = sort.value;
      this.search(true);
    }
   
    search(reset: boolean = false) {
      if (reset) {
        this.pageIndex = 1;
      }
      this.loadingRecords = true;
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      } catch (error) {
        sort = "";
      }
  
      console.log("search text:" + this.searchText);
  
      if (this.searchText != "") {
        var likeQuery = " AND";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
        likeQuery = likeQuery.substring(0, likeQuery.length - 2)
        console.log("likeQuery" + likeQuery);
      }
  
      this.api.getAllfeedarea(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
        console.log(data);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
  
      }, err => {
        console.log(err);
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  
    //Drawer Methods
    get closeCallback() {
      return this.drawerClose.bind(this);
    }
  
    get closeCallback1() {
      return this.drawerClose1.bind(this);
    }
  
    add(): void {
      this.drawerTitle = "Create Area";
      this.drawerData = new feedback();
      this.drawerData.IS_ACTIVE = true
      this.drawerVisible = true;
    }
  
    // MapApplicationModules(data:User)
    // {
    //   this.api.getApplicationModuleMapping(data.ID).subscribe(data => {
    //     console.log(data);
    //    this.drawerData2 =data['data'];
    //     }, err => {
    //     console.log(err);
    //   });
    //   this.drawerTitle1 = "Map Application Modules for " + data.NAME + "";
    //   this.drawerData1 = Object.assign({}, data);
    //   this.drawerVisible1 = true;
    // }
  
    edit(data: feedback): void {
      this.drawerTitle = "Update Area";
      this.drawerData = Object.assign({}, data);
      this.drawerVisible = true;
      this.disa = true
    }
  
    drawerClose(): void {
      this.search();
      this.drawerVisible = false;
    }
  
    drawerClose1(): void {
      this.drawerVisible1 = false;
    }
  }


