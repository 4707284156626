import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-sales-manager-wise-sales-executive-wise-report',
  templateUrl: './sales-manager-wise-sales-executive-wise-report.component.html',
  styleUrls: ['./sales-manager-wise-sales-executive-wise-report.component.css']
})
export class SalesManagerWiseSalesExecutiveWiseReportComponent implements OnInit {


  formTitle = "Sales Manager Wise Sales Executive Wise Summary Report";
  fileName = 'SalesManagerWiseSalesExecutiveWiseSummaryReport.xlsx';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  isFilterApplied: string = "default";
  filterClass: string = "filter-invisible";
  loadingRecords = false;
  // SearchData : string = '';
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  filterQuery: string = "";

  columns: string[][] = [
    ["SALES_MANAGER_NAME", "Sales Manager Name"],
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
  ];

  searchText: string = "";
  date = null;

  orgId = this.cookie.get("orgId");
  BRANCH_ID = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_EXECUTIVE_ID = [];
  // SELECT_ALL1: boolean = false
  isSpinning = false;
  exportLoading: boolean = false;
  salesManagerWiseSaleExecutiveWise: any[] = [];
  startValue: any = new Date()
  endValue: any = new Date()
  START_DATE: any
  END_DATE: any;

  constructor(private api: ApiService, private cookie: CookieService, public datePipe: DatePipe,
    private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.getSalesExecutives();
    this.search();
    this.getSalesExecutives();
    this.getBranches();

  }

  branches = [];

  getBranches() {
    this.branches = [];
    this.api.getAllBranch(0, 0, "NAME", "asc", " AND STATUS=1 AND ORG_ID=" + this.orgId)
      .subscribe(
        (data: any) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
          // console.log(this.branches);
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  endOpen = false;
  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2
  };
  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.filterQuery = '';
    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }
    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }

    if (this.SALES_EXECUTIVE_ID != undefined && this.SALES_EXECUTIVE_ID.length > 0) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }
    this.search(true);

  }

  clearFilter() {
    this.BRANCH_ID = [];
    this.SELECT_ALL1 = false;
    this.SALES_EXECUTIVE_ID = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.filterQuery = '';
    this.search();

  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);

  }


  search(reset: boolean = false, exportexcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);


    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }
    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (exportexcel == false) {
      this.loadingRecords = true;

      this.api.getSalesManagerWise(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH_ID, this.SALES_EXECUTIVE_ID, this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.salesManagerWiseSaleExecutiveWise = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.getSalesManagerWise(0, 0, this.sortKey, sort, likeQuery, this.BRANCH_ID, this.SALES_EXECUTIVE_ID, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.salesManagerWiseSaleExecutiveWise = data['data'];
            this.exportexcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let ids = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["NAME"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.salesExecutives.length; i++) {
        ids.push(this.salesExecutives[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.salesExecutives.length;
    var b = this.salesExecutives.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  salesExecutives = [];

  getSalesExecutives() {
    this.salesExecutives = [];

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" +
        this.orgId +
        " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
        this.cookie.get("orgId") +
        " AND ROLE_ID=28)"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.salesExecutives = data["data"];
            console.log(this.salesExecutives);

          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  FILTER_QUERY: any
  members: any = []


  AddSchedule() {
    this.isVisible1 = true
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    var obj = {
      START_DATE: this.START_DATE,
      END_DATE: this.END_DATE,
      SALES_EXECUTIVE_ID: this.SALES_EXECUTIVE_ID,
      ORG_ID: this.orgId
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    this.api.getAllMembers(0, 0, '', '', ' AND REPORT_ID = 44').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  today = new Date();
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;



  exportexcel(): void {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  }

  // convertInExcel() {
  //   var arry1 = [];
  //   var obj1: any = new Object();
  //   for (var i = 0; i < this.branchOuthouseData.length; i++) {
  //     obj1['Sales Manager Name'] = this.branchOuthouseData[i]['SALES_MANAGER_NAME'];
  //     obj1['Sales Executive'] = this.branchOuthouseData[i]['SALES_EXECUTIVE'];
  //     obj1['Branch Name'] = this.branchOuthouseData[i]['BRANCH_NAME'];
  //     obj1['No. Of Quotations'] = this.branchOuthouseData[i]['NO_OF_QUOTATIONS'];
  //     obj1['No. Of Memo Converted'] = this.branchOuthouseData[i]['NO_OF_MEMO_CONVERTED'];
  //     obj1['No. Of Invoices Converted'] = this.branchOuthouseData[i]['NO_OF_INVOICES_CONVERTED'];

  //     arry1.push(Object.assign({}, obj1));
  //     if (i == this.branchOuthouseData.length - 1) {
  //       this._exportService.exportExcel(arry1, 'Sales Manager Wise Sales Executive Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  //     }
  //   }
  // }
}
