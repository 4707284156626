import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { Latemark } from 'src/app/Models/Latemark';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { ShortLeave } from 'src/app/Models/Shortleave';

@Component({
  selector: 'app-addshortleave',
  templateUrl: './addshortleave.component.html',
  styleUrls: ['./addshortleave.component.css']
})
export class AddshortleaveComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input()
  data: ShortLeave = new ShortLeave();
  orgId = this.cookie.get('orgId');
  isSpinning = false;
  @Input() listOfData: ShortLeave[] = [];
  @Input() inOut
  @Input() daystarttime
  @Input() dayendtime
  @Input() C_STATUSS: string = "M"
  @Input() MaxLatemarktime
  @Input() MaxEarlytime

  listdata2 = [];
  listdata1 = [];
  acctype = [];
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  @Input() DAYS = false;
  isOk = true;


  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {

  }

  timecheck(event) {
    // console.log(event);

    if (this.data.C_STATUSS == 'M') {

      this.Starttime = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.daystarttime)
      this.maxtime = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.MaxLatemarktime)

      if (this.Starttime <= event && event <= this.maxtime) {
        console.log("INTIME");

      } else {

        this.message.error("Please Select Valid Time In Between " + this.daystarttime + " To " + this.MaxLatemarktime + "", "");
        this.data.EXPECTED_TIME = this.maxtime
      }
    } else if (this.data.C_STATUSS == 'E') {

      let dayendtime: any = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.dayendtime)
      let earlyendtime: any = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.MaxEarlytime)

      if (earlyendtime <= event && event <= dayendtime) {
        console.log("INTIME");

      } else {

        this.message.error("Please Select Valid Time In Between " + this.MaxEarlytime + " To " + this.dayendtime + "", "");
        this.data.EXPECTED_TIME = earlyendtime
      }
    }
  }

  close(myForm: NgForm) {
   
    this.drawerClose();
    this.resetDrawer(myForm);
  }

  resetDrawer(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;





    if (this.data.DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Date", "");
    }

    if (this.data.EXPECTED_TIME == null) {
      isOk = false;
      this.message.error("Please Select Valid Time", "");
    }

    if (this.data.REASON == undefined || this.data.REASON.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Reason", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");
      this.data.EXPECTED_TIME = this.datePipe.transform(this.data.EXPECTED_TIME, "HH:mm") + ":00";
      this.data.EMPLOYEE_ID = this.api.userId;



      if (this.data.C_STATUSS == 'M') {
        this.api.createlatemark(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Shortleave Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else
              this.data = new ShortLeave();

          } else {
            this.message.error("Shortleave Creation Failed", "");
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
        });
      } else {
        this.api.createEarlymark(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Shortleave Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else
              this.data = new ShortLeave();

          } else {
            this.message.error("Shortleave Creation Failed", "");
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
        });


      }
    }
  }

  Newdate: any
  Startdate: any
  Endtime: any
  diff: any
  today1: any
  rStartdate: any
  a = []
  Starttime: any
  maxtime: any
  abc = []
  onStatuschange(event) {

    if (event == 'M') {
      this.Newdate = new Date()
      this.rStartdate = this.datePipe.transform(new Date(), "yyyy-MM-dd ")

      this.Startdate = new Date(this.rStartdate + this.daystarttime)


      this.diff = new Date(this.Startdate).getTime() - new Date(this.Newdate).getTime()
      console.log(this.diff, "diff");


      if (this.diff < 0) {
        this.today1 = new Date().setDate(new Date().getDate() + 1);
        this.disabledStartDate = (current: Date): boolean =>
          differenceInCalendarDays(current, this.today1) < 0;
      } else {
        this.today1 = new Date().setDate(new Date().getDate() + 0);
        this.disabledStartDate = (current: Date): boolean =>
          differenceInCalendarDays(current, this.today1) < 0;
      }


      // this.Starttime= new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.daystarttime).getHours()
      // this.maxtime= new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.MaxLatemarktime).getHours()
      // console.log(Starttime);
      // console.log(maxtime);



      // for (let index = 1; index <= 24; index++) {
      //   if (this.Starttime <= index && index <= this.maxtime) {

      //   } else {
      //     this.abc.push(index)
      //   }
      //   if(index==24){
      //     console.log(this.abc);
      //     this.abc= [...[],...this.abc]
      //   }
      // }
      // console.log(a);
      // this.disabledMinutes()
    } else if (event == 'E') {


      let dateee: any = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd ") + this.dayendtime)

      // dateee = dateee.setHours(dateee.getHours() - 2);

      this.Newdate = new Date()

      this.Endtime = new Date(dateee)
      console.log(this.Endtime);
      console.log(this.Newdate)

      this.diff = new Date(this.Endtime).getTime() - new Date(this.Newdate).getTime()
      console.log(this.diff, "diff");


      if (this.diff < 0) {
        this.today1 = new Date().setDate(new Date().getDate() + 1);
        this.disabledStartDate = (current: Date): boolean =>
          differenceInCalendarDays(current, this.today1) < 0;
      } else {
        this.today1 = new Date().setDate(new Date().getDate() + 0);
        this.disabledStartDate = (current: Date): boolean =>
          differenceInCalendarDays(current, this.today1) < 0;
      }


      // this.disabledHours1()
      // this.disabledMinutes1()
    }

  }
  today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledHours() {


    return this.abc


  }

  disabledMinutes(): number[] {

    return [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

  }
  disabledHours1(): number[] {
    return [16, 17, 18, 19, 20, 21, 22, 23];
  }

  disabledMinutes1(): number[] {

    return [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

  }
}


