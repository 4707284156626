import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ChartOptions, ChartDataSets, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import "chart.js";

@Component({
  selector: "app-attendance-reports",
  templateUrl: "./attendance-reports.component.html",
  styleUrls: ["./attendance-reports.component.css"],
})

export class AttendanceReportsComponent implements OnInit {
  formTitle = "Sales Reports";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords: boolean = false;
  orgId = this.cookie.get("orgId");
  START_DATE: any;
  END_DATE: any;
  BRANCH_ID = ([] = []);
  DEPARTMENT_ID = [];
  DESIGNATION_ID = [];
  DAY_COUNT: any;

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    // this.onChange(this.date);
    this.countSpins = true;

    this.setDateToDateFilter();
    this.getCount();
    this.getAttendanceBranches();
    this.getAttendanceDepartments();
    this.getAttendanceDesignations();
    this.getDayWiseAttendanceReport();
    this.getBranchWiseAttendanceReport();
    this.getDepartmentWiseAttendanceReport();
    this.getDesignationWiseAttendanceReport();
    this.getMonthlyAttendanceReport();
    this.getBranchWiseAvgAttendanceReport();
    this.getDepartmentWiseAvgAttendanceReport();
    this.getDesignationWiseAvgAttendanceReport();
    this.getDaywiseSuperLateTop10Employees();
    this.getMonthlySuperLateTop10Employees();
    this.getDayWiseLeaveReport();
    this.getDayWiseLatemarkReport();
    this.getDayInFromMaxDistanceDayWise();
    this.getDayInFromMaxDistanceMonthlyWise();
    this.getDayOutMaxDistanceDayEmployeeReport();
    this.getDayOutMaxDistanceMonthlyEmployeeReport();
    this.getTop10DayOutMismatchedEmployees();
    this.getGraphicalAttendanceSummary(this.date);
  }

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  reportStatus1: boolean = false;
  reportStatus2: boolean = false;
  reportStatus3: boolean = false;
  reportStatus4: boolean = false;
  reportStatus5: boolean = false;
  reportStatus6: boolean = false;
  reportStatus7: boolean = false;
  reportStatus8: boolean = false;
  reportStatus9: boolean = false;
  reportStatus10: boolean = false;
  reportStatus11: boolean = false;
  reportStatus12: boolean = false;
  reportStatus13: boolean = false;
  reportStatus14: boolean = false;
  reportStatus15: boolean = false;
  reportStatus16: boolean = false;
  reportStatus17: boolean = false;
  reportStatus18: boolean = false;

  reportName1: string = " 1. Day Wise Attendance Report";
  reportName2: string = " 2. Branch Wise Attendance Report";
  reportName3: string = " 3. Department Wise Attendance Report";
  reportName4: string = " 4. Designation Wise Attendance Report";
  reportName5: string = " 5. Monthly Attendance Avg Report";
  reportName6: string = " 6. Branch Wise Attendance Avg Report";
  reportName7: string = " 7. Department Wise Attendance Avg Report";
  reportName8: string = " 8. Designation Wise Avg Attendance Avg Report";
  reportName9: string = " 9. Day wise Super Late Top 10 Employees";
  reportName10: string = " 10. Monthly Super Late Top 10 Employees ";
  reportName11: string = " 11. Day Wise Leave Report";
  reportName12: string = " 12. Day Wise Latemark Report";
  reportName13: string = " 13. Top 10 Day in from Max distance (Day wise)";
  reportName14: string = " 14. Top 10 Day in from Max distance (Monthly wise)";
  reportName15: string = " 15. Top 10 Day out from Max distance (Day wise)";
  reportName16: string = " 16. Top 10 Day out from Max distance (Monthly wise)";
  reportName17: string = " 17. Top 10 Day out Mismatched Employees";
  reportName18: string = " 18. Graphical Attendance Summary (LINE CHART)";

  branches = [];

  getAttendanceBranches() {
    this.branches = [];

    this.api
      .getAllAttendanceBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  departments = [];

  getAttendanceDepartments() {
    this.departments = [];

    this.api.getAllAttendanceDepartments(0, 0, "NAME", "asc", "").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.departments = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  designations = [];

  getAttendanceDesignations() {
    this.designations = [];

    this.api.getAllAttendanceDesignations(0, 0, "NAME", "asc", "").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.designations = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  clearFilter() {
    this.START_DATE = [];
    this.END_DATE = [];
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.DESIGNATION_ID = [];
    this.setDateToDateFilter();
    this.getCount();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  applyFilter() {
    if (this.START_DATE.length >= 0 && this.END_DATE.length >= 0) {
      this.isFilterApplied = "primary";
      this.filterClass = "filter-invisible";
    } else {
      this.isFilterApplied = "default";
      this.filterClass = "filter-invisible";
    }

    this.getCount();
    this.getDayWiseAttendanceReport(false);
    this.getBranchWiseAttendanceReport(false);
    this.getDepartmentWiseAttendanceReport(false);
    this.getDesignationWiseAttendanceReport(false);
    this.getMonthlyAttendanceReport(false);
    this.getBranchWiseAvgAttendanceReport(false);
    this.getDepartmentWiseAvgAttendanceReport(false);
    this.getDesignationWiseAvgAttendanceReport(false);
    this.getDaywiseSuperLateTop10Employees(false);
    this.getMonthlySuperLateTop10Employees(false);
    this.getDayWiseLeaveReport(false);
    this.getDayWiseLatemarkReport(false);
    this.getDayInFromMaxDistanceDayWise(false);
    this.getDayInFromMaxDistanceMonthlyWise(false);
    this.getDayOutMaxDistanceDayEmployeeReport(false);
    this.getDayOutMaxDistanceMonthlyEmployeeReport(false);
    this.getTop10DayOutMismatchedEmployees(false);
    this.getGraphicalAttendanceSummary(this.date);
  }

  sort(sort: { key: string; value: string }): void {
    this.DayWiseAttendanceReportSortKey = sort.key;
    this.DayWiseAttendanceReportSortValue = sort.value;
    this.getDayWiseAttendanceReport(true);

    this.BranchWiseAttendanceReportSortKey = sort.key;
    this.BranchWiseAttendanceReportSortValue = sort.value;
    this.getBranchWiseAttendanceReport(true);

    this.DepartmentWiseAttendanceReportSortKey = sort.key;
    this.DepartmentWiseAttendanceReportSortValue = sort.value;
    this.getDepartmentWiseAttendanceReport(true);

    this.DesignationWiseAttendanceReportSortKey = sort.key;
    this.DesignationWiseAttendanceReportSortValue = sort.value;
    this.getDesignationWiseAttendanceReport(true);

    this.MonthlyAttendanceReportSortKey = sort.key;
    this.MonthlyAttendanceReportSortValue = sort.value;
    this.getMonthlyAttendanceReport(true);

    this.BranchWiseAvgAttendanceReportSortKey = sort.key;
    this.BranchWiseAvgAttendanceReportSortValue = sort.value;
    this.getBranchWiseAvgAttendanceReport(true);

    this.DepartmentWiseAvgAttendanceReportSortKey = sort.key;
    this.DepartmentWiseAvgAttendanceReportSortValue = sort.value;
    this.getDepartmentWiseAvgAttendanceReport(true);

    this.DesignationWiseAvgAttendanceReportSortKey = sort.key;
    this.DesignationWiseAvgAttendanceReportSortValue = sort.value;
    this.getDesignationWiseAvgAttendanceReport(true);

    this.DaywiseSuperLateTop10EmployeesSortKey = sort.key;
    this.DaywiseSuperLateTop10EmployeesSortValue = sort.value;
    this.getDaywiseSuperLateTop10Employees(true);

    this.MonthlySuperLateTop10EmployeesSortKey = sort.key;
    this.MonthlySuperLateTop10EmployeesSortValue = sort.value;
    this.getMonthlySuperLateTop10Employees(true);

    this.DayWiseLeaveReportSortKey = sort.key;
    this.DayWiseLeaveReportSortValue = sort.value;
    this.getDayWiseLeaveReport(true);

    this.DayWiseLatemarkReportSortKey = sort.key;
    this.DayWiseLatemarkReportSortValue = sort.value;
    this.getDayWiseLatemarkReport(true);

    this.DayInFromMaxDistanceDayWiseSortKey = sort.key;
    this.DayInFromMaxDistanceDayWiseSortValue = sort.value;
    this.getDayInFromMaxDistanceDayWise(true);

    this.DayInFromMaxDistanceMonthlyWiseSortKey = sort.key;
    this.DayInFromMaxDistanceMonthlyWiseSortValue = sort.value;
    this.getDayInFromMaxDistanceMonthlyWise(true);

    this.DayOutMaxDistanceDayEmployeeReportSortKey = sort.key;
    this.DayOutMaxDistanceDayEmployeeReportSortValue = sort.value;
    this.getDayOutMaxDistanceDayEmployeeReport(true);

    this.DayOutMaxDistanceMonthlyEmployeeReportSortKey = sort.key;
    this.DayOutMaxDistanceMonthlyEmployeeReportSortValue = sort.value;
    this.getDayOutMaxDistanceMonthlyEmployeeReport(true);

    this.Top10DayOutMismatchedEmployeesSortKey = sort.key;
    this.Top10DayOutMismatchedEmployeesSortValue = sort.value;
    this.getTop10DayOutMismatchedEmployees(true);
  }

  getCount() {
    const fromDateDCFilter = new Date(this.START_DATE);
    const toDateDCFilter = new Date(this.END_DATE);

    const Time = Math.round(
      toDateDCFilter.getTime() - fromDateDCFilter.getTime()
    );

    this.DAY_COUNT = Math.round(Time / (1000 * 3600 * 24));
  }

  activePanel1(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayWiseAttendanceReportPageIndex = 1;
      this.DayWiseAttendanceReportPageSize = 10;
      this.getDayWiseAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayWiseAttendanceReportPageIndex = 1;
  DayWiseAttendanceReportPageSize = 10;
  DayWiseAttendanceReportTotalRecords = 15;
  DayWiseAttendanceReportSortValue: string = "desc";
  DayWiseAttendanceReportSortKey: string = "ID";
  DayWiseAttendanceReportData = [];

  columns1: string[][] = [
    ["IN_TIME", "Intime"],
    ["LATECOMING", "Late Coming"],
    ["LATEMARK", "Latemark"],
    ["LEAVE_COUNT", "Leave"],
    ["ABSENT", "Absent"],
  ];

  getDayWiseAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DayWiseAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayWiseAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayWiseAttendanceReport1(
        this.DayWiseAttendanceReportPageIndex,
        this.DayWiseAttendanceReportPageSize,
        this.DayWiseAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        
        DesignationFilter,
        DepartmentFilter,
        fromDateFilter,
        toDateFilter,
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayWiseAttendanceReportTotalRecords = data["count"];
            this.DayWiseAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel2(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.BranchWiseAttendanceReportPageIndex = 1;
      this.BranchWiseAttendanceReportPageSize = 10;
      this.getBranchWiseAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  BranchWiseAttendanceReportPageIndex = 1;
  BranchWiseAttendanceReportPageSize = 10;
  BranchWiseAttendanceReportTotalRecords = 1;
  BranchWiseAttendanceReportSortValue: string = "desc";
  BranchWiseAttendanceReportSortKey: string = "ID";
  BranchWiseAttendanceReportData = [];

  columns2: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["TOTAL", "Total No.Of. Employees"],
    ["IN_TIME", "In time"],
    ["LATECOMING", "Late Coming"],
    ["LATEMARK", "Latemark"],
    ["LEAVE_COUNT", "Leave"],
    ["ABSENT", "Absent"],
    ["DAY_IN", "Day In"],
    ["DAY_OUT", "Day OUt"],
    ["DAY_OUT_PERCENTAGE", "Day Out %"],
  ];
  ROLE_ID = Number(this.cookie.get('roleId'))
  getBranchWiseAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.BranchWiseAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.BranchWiseAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }


    this.loadingRecords = true;
    this.api
      .getBranchWiseAttendanceReport1(
        this.BranchWiseAttendanceReportPageIndex,
        this.BranchWiseAttendanceReportPageSize,
        this.BranchWiseAttendanceReportSortKey,
        sort,
       "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.BranchWiseAttendanceReportData = data["data"];
            this.BranchWiseAttendanceReportTotalRecords = data["count"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel3(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DepartmentWiseAttendanceReportPageIndex = 1;
      this.DepartmentWiseAttendanceReportPageSize = 10;
      this.getDepartmentWiseAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DepartmentWiseAttendanceReportPageIndex = 1;
  DepartmentWiseAttendanceReportPageSize = 10;
  DepartmentWiseAttendanceReportTotalRecords = 1;
  DepartmentWiseAttendanceReportSortValue: string = "desc";
  DepartmentWiseAttendanceReportSortKey: string = "ID";
  DepartmentWiseAttendanceReportData = [];

  columns3: string[][] = [
    ["DEPARTMENT_NAME", "Department Name"],
    ["TOTAL", "Total No.Of. Employees"],
    ["IN_TIME", "In time"],
    ["LATECOMING", "Late Coming"],
    ["LATEMARK", "Latemark"],
    ["LEAVE_COUNT", "Leave"],
    ["ABSENT", "Absent"],
    ["DAY_IN", "Day In"],
    ["DAY_OUT", "Day OUt"],
    ["DAY_OUT_PERCENTAGE", "Day Out %"],
  ];

  getDepartmentWiseAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DepartmentWiseAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DepartmentWiseAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDepartmentWiseAttendanceReport1(
        this.DepartmentWiseAttendanceReportPageIndex,
        this.DepartmentWiseAttendanceReportPageSize,
        this.DepartmentWiseAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DepartmentWiseAttendanceReportTotalRecords = data["count"];
            this.DepartmentWiseAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel4(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DesignationWiseAttendanceReportPageIndex = 1;
      this.DesignationWiseAttendanceReportPageSize = 10;
      this.getDesignationWiseAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DesignationWiseAttendanceReportPageIndex = 1;
  DesignationWiseAttendanceReportPageSize = 10;
  DesignationWiseAttendanceReportTotalRecords = 1;
  DesignationWiseAttendanceReportSortValue: string = "desc";
  DesignationWiseAttendanceReportSortKey: string = "ID";
  DesignationWiseAttendanceReportData = [];

  columns4: string[][] = [
    ["DESIGNATION_NAME", "Designation Name"],
    ["TOTAL", "Total No.Of.Employees"],
    ["IN_TIME", "In time"],
    ["LATECOMING", "Late Coming"],
    ["LATEMARK", "Latemark"],
    ["LEAVE_COUNT", "Leave Count"],
    ["ABSENT", "Absent"],
    ["DAY_IN", "Day In"],
    ["DAY_OUT", "Day OUt"],
    ["DAY_OUT_PERCENTAGE", "Day Out %"],
  ];

  getDesignationWiseAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DesignationWiseAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DesignationWiseAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDesignationWiseAttendanceReport1(
        this.DesignationWiseAttendanceReportPageIndex,
        this.DesignationWiseAttendanceReportPageSize,
        this.DesignationWiseAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DesignationWiseAttendanceReportTotalRecords = data["count"];
            this.DesignationWiseAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel5(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.MonthlyAttendanceReportPageIndex = 1;
      this.MonthlyAttendanceReportPageSize = 10;
      this.getMonthlyAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  MonthlyAttendanceReportPageIndex = 1;
  MonthlyAttendanceReportPageSize = 10;
  MonthlyAttendanceReportTotalRecords = 1;
  MonthlyAttendanceReportSortValue: string = "desc";
  MonthlyAttendanceReportSortKey: string = "ID";
  MonthlyAttendanceReportData = [];

  columns5: string[][] = [
    ["AVERAGE_INTIME", "Avg In Time"],
    ["AVERAGE_LATE ", "Avg Late"],
    ["AVERAGE_LATEMARK", "Avg Latemark"],
    ["AVERAGE_LEAVE", "Avg Leave"],
    ["AVERAGE_ABSENT", "Avg Absent"],
  ];

  getMonthlyAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.MonthlyAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.MonthlyAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    if (this.DAY_COUNT != undefined && this.DAY_COUNT.length != 0) {
      this.getCount();
    }

    this.loadingRecords = true;
    this.api
      .getMonthlyAttendanceReport1(
        this.MonthlyAttendanceReportPageIndex,
        this.MonthlyAttendanceReportPageSize,
        this.MonthlyAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter,
        this.DAY_COUNT
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.MonthlyAttendanceReportTotalRecords = data["count"];
            this.MonthlyAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel6(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.BranchWiseAvgAttendanceReportPageIndex = 1;
      this.BranchWiseAvgAttendanceReportPageSize = 10;
      this.getBranchWiseAvgAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  BranchWiseAvgAttendanceReportPageIndex = 1;
  BranchWiseAvgAttendanceReportPageSize = 10;
  BranchWiseAvgAttendanceReportTotalRecords = 1;
  BranchWiseAvgAttendanceReportSortValue: string = "desc";
  BranchWiseAvgAttendanceReportSortKey: string = "ID";
  BranchWiseAvgAttendanceReportData = [];

  columns6: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["TOTAL", "Total"],
    ["AVERAGE_INTIME", "Avg In Time"],
    ["AVERAGE_LATE", "Avg Late"],
    ["AVERAGE_LATEMARK", "Avg Latemark"],
    ["AVERAGE_LEAVE", "Avg Leave"],
    ["AVERAGE_ABSENT", "Avg Absent"],
    ["AVERAGE_DAYIN", "Avg Day In"],
    ["AVERAGE_DAYOUT", "Avg Day Out"],
    ["AVERAGE_DAYOUT_PERCENTAGE", "Avg Day Out %"],
  ];

  getBranchWiseAvgAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.BranchWiseAvgAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.BranchWiseAvgAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    if (this.DAY_COUNT != undefined && this.DAY_COUNT.length != 0) {
      this.getCount();
    }

    this.loadingRecords = true;
    this.api
      .getBranchWiseAvgAttendanceReport1(
        this.BranchWiseAvgAttendanceReportPageIndex,
        this.BranchWiseAvgAttendanceReportPageSize,
        this.BranchWiseAvgAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter,
        this.DAY_COUNT
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.BranchWiseAvgAttendanceReportTotalRecords = data["count"];
            this.BranchWiseAvgAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel7(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DepartmentWiseAvgAttendanceReportPageIndex = 1;
      this.DepartmentWiseAvgAttendanceReportPageSize = 10;
      this.getDepartmentWiseAvgAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DepartmentWiseAvgAttendanceReportPageIndex = 1;
  DepartmentWiseAvgAttendanceReportPageSize = 10;
  DepartmentWiseAvgAttendanceReportTotalRecords = 1;
  DepartmentWiseAvgAttendanceReportSortValue: string = "desc";
  DepartmentWiseAvgAttendanceReportSortKey: string = "ID";
  DepartmentWiseAvgAttendanceReportData = [];

  columns7: string[][] = [
    ["DEPARTMENT_NAME", "Department Name"],
    ["TOTAL", "Total"],
    ["AVERAGE_INTIME", "Avg In Time"],
    ["AVERAGE_LATE", "Avg Late"],
    ["AVERAGE_LATEMARK", "Avg Latemark"],
    ["AVERAGE_LEAVE", "Avg Leave"],
    ["AVERAGE_ABSENT", "Avg Absent"],
    ["AVERAGE_DAYIN", "Avg Day In"],
    ["AVERAGE_DAYOUT", "Avg Day Out"],
    ["AVERAGE_DAYOUT_PERCENTAGE", "Avg Day Out %"],
  ];

  getDepartmentWiseAvgAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DepartmentWiseAvgAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DepartmentWiseAvgAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    if (this.DAY_COUNT != undefined && this.DAY_COUNT.length != 0) {
      this.getCount();
    }

    this.loadingRecords = true;
    this.api
      .getDepartmentWiseAvgAttendanceReport1(
        this.DepartmentWiseAvgAttendanceReportPageIndex,
        this.DepartmentWiseAvgAttendanceReportPageSize,
        this.DepartmentWiseAvgAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter,
        this.DAY_COUNT
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DepartmentWiseAvgAttendanceReportTotalRecords = data["count"];
            this.DepartmentWiseAvgAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel8(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DesignationWiseAvgAttendanceReportPageIndex = 1;
      this.DesignationWiseAvgAttendanceReportPageSize = 10;
      this.getDesignationWiseAvgAttendanceReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DesignationWiseAvgAttendanceReportPageIndex = 1;
  DesignationWiseAvgAttendanceReportPageSize = 10;
  DesignationWiseAvgAttendanceReportTotalRecords = 1;
  DesignationWiseAvgAttendanceReportSortValue: string = "desc";
  DesignationWiseAvgAttendanceReportSortKey: string = "ID";
  DesignationWiseAvgAttendanceReportData = [];

  columns8: string[][] = [
    ["DESIGNATION_NAME", "Designation Name"],
    ["TOTAL", "Total"],
    ["AVERAGE_INTIME", "Avg In Time"],
    ["AVERAGE_LATE", "Avg Late"],
    ["AVERAGE_LATEMARK", "Avg Latemark"],
    ["AVERAGE_LEAVE", "Avg Leave"],
    ["AVERAGE_ABSENT", "Avg Absent"],
    ["AVERAGE_DAYIN", "Avg Day In"],
    ["AVERAGE_DAYOUT", "Avg Day Out"],
    ["AVERAGE_DAYOUT_PERCENTAGE", "Avg Day Out %"],
  ];

  getDesignationWiseAvgAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DesignationWiseAvgAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DesignationWiseAvgAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    if (this.DAY_COUNT != undefined && this.DAY_COUNT.length != 0) {
      this.getCount();
    }

    this.loadingRecords = true;
    this.api
      .getDesignationWiseAvgAttendanceReport1(
        this.DesignationWiseAvgAttendanceReportPageIndex,
        this.DesignationWiseAvgAttendanceReportPageSize,
        this.DesignationWiseAvgAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter,
        this.DAY_COUNT
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DesignationWiseAvgAttendanceReportTotalRecords = data["count"];
            this.DesignationWiseAvgAttendanceReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel9(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DaywiseSuperLateTop10EmployeesPageIndex = 1;
      this.DaywiseSuperLateTop10EmployeesPageSize = 10;
      this.getDaywiseSuperLateTop10Employees(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DaywiseSuperLateTop10EmployeesPageIndex = 1;
  DaywiseSuperLateTop10EmployeesPageSize = 10;
  DaywiseSuperLateTop10EmployeesTotalRecords = 1;
  DaywiseSuperLateTop10EmployeesSortValue: string = "desc";
  DaywiseSuperLateTop10EmployeesSortKey: string = "ID";
  DaywiseSuperLateTop10EmployeesData = [];

  columns9: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["IN_TIME", "In Time"],
  ];

  getDaywiseSuperLateTop10Employees(reset: boolean = false) {
    if (reset) {
      this.DaywiseSuperLateTop10EmployeesPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DaywiseSuperLateTop10EmployeesSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDaywiseSuperLateTop10Employees(
        this.DaywiseSuperLateTop10EmployeesPageIndex,
        this.DaywiseSuperLateTop10EmployeesPageSize,
        this.DaywiseSuperLateTop10EmployeesSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DaywiseSuperLateTop10EmployeesTotalRecords = data["count"];
            this.DaywiseSuperLateTop10EmployeesData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel10(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.MonthlySuperLateTop10EmployeesPageIndex = 1;
      this.MonthlySuperLateTop10EmployeesPageSize = 10;
      this.getMonthlySuperLateTop10Employees(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  MonthlySuperLateTop10EmployeesPageIndex = 1;
  MonthlySuperLateTop10EmployeesPageSize = 10;
  MonthlySuperLateTop10EmployeesTotalRecords = 1;
  MonthlySuperLateTop10EmployeesSortValue: string = "desc";
  MonthlySuperLateTop10EmployeesSortKey: string = "ID";
  MonthlySuperLateTop10EmployeesData = [];

  columns10: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department"],
    ["DESIGNATION_NAME", "Designation"],
    ["TOTAL_DAYS", "Total Days"],
    ["LATE_DAYS", "Late Days"],
  ];

  getMonthlySuperLateTop10Employees(reset: boolean = false) {
    if (reset) {
      this.MonthlySuperLateTop10EmployeesPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.MonthlySuperLateTop10EmployeesSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getMonthlySuperLateTop10Employees(
        this.MonthlySuperLateTop10EmployeesPageIndex,
        this.MonthlySuperLateTop10EmployeesPageSize,
        this.MonthlySuperLateTop10EmployeesSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.MonthlySuperLateTop10EmployeesTotalRecords = data["count"];
            this.MonthlySuperLateTop10EmployeesData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel11(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayWiseLeaveReportPageIndex = 1;
      this.DayWiseLeaveReportPageSize = 10;
      this.getDayWiseLeaveReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayWiseLeaveReportPageIndex = 1;
  DayWiseLeaveReportPageSize = 10;
  DayWiseLeaveReportTotalRecords = 1;
  DayWiseLeaveReportSortValue: string = "desc";
  DayWiseLeaveReportSortKey: string = "EMPLOYEE_ID ";
  DayWiseLeaveReportData = [];

  columns11: string[][] = [
    ["EMP_NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["LEAVE_TYPE_NAME", "Leave Type Name"],
    ["REASON", "Reason"],
  ];

  getDayWiseLeaveReport(reset: boolean = false) {
    if (reset) {
      this.DayWiseLeaveReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayWiseLeaveReportSortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayWiseLeaveReport(
        this.DayWiseLeaveReportPageIndex,
        this.DayWiseLeaveReportPageSize,
        this.DayWiseLeaveReportSortKey,
        sort,
        "filter",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayWiseLeaveReportTotalRecords = data["count"];
            this.DayWiseLeaveReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel12(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayWiseLatemarkReportPageIndex = 1;
      this.DayWiseLatemarkReportPageSize = 10;
      this.getDayWiseLatemarkReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayWiseLatemarkReportPageIndex = 1;
  DayWiseLatemarkReportPageSize = 10;
  DayWiseLatemarkReportTotalRecords = 1;
  DayWiseLatemarkReportSortValue: string = "desc";
  DayWiseLatemarkReportSortKey: string = "ID";
  DayWiseLatemarkReportData = [];

  columns12: string[][] = [
    ["EMPLOYEE_NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["REASON", "Reason"],
  ];

  getDayWiseLatemarkReport(reset: boolean = false) {
    if (reset) {
      this.DayWiseLatemarkReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayWiseLatemarkReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayWiseLatemarkReport(
        this.DayWiseLatemarkReportPageIndex,
        this.DayWiseLatemarkReportPageSize,
        this.DayWiseLatemarkReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayWiseLatemarkReportTotalRecords = data["count"];
            this.DayWiseLatemarkReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel13(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayInFromMaxDistanceDayWisePageIndex = 1;
      this.DayInFromMaxDistanceDayWisePageSize = 10;
      this.getDayInFromMaxDistanceDayWise(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayInFromMaxDistanceDayWisePageIndex = 1;
  DayInFromMaxDistanceDayWisePageSize = 10;
  DayInFromMaxDistanceDayWiseTotalRecords = 1;
  DayInFromMaxDistanceDayWiseSortValue: string = "desc";
  DayInFromMaxDistanceDayWiseSortKey: string = "ID";
  DayInFromMaxDistanceDayWiseData = [];

  columns13: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["IN_DISTANCE", "In Distance"],
    ["DAYIN_REASON", "Reason"],
  ];

  getDayInFromMaxDistanceDayWise(reset: boolean = false) {
    if (reset) {
      this.DayInFromMaxDistanceDayWisePageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayInFromMaxDistanceDayWiseSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayInFromMaxDistanceDayWise(
        this.DayInFromMaxDistanceDayWisePageIndex,
        this.DayInFromMaxDistanceDayWisePageSize,
        this.DayInFromMaxDistanceDayWiseSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayInFromMaxDistanceDayWiseTotalRecords = data["count"];
            this.DayInFromMaxDistanceDayWiseData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel14(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayInFromMaxDistanceMonthlyWisePageIndex = 1;
      this.DayInFromMaxDistanceMonthlyWisePageSize = 10;
      this.getDayInFromMaxDistanceMonthlyWise(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayInFromMaxDistanceMonthlyWisePageIndex = 1;
  DayInFromMaxDistanceMonthlyWisePageSize = 10;
  DayInFromMaxDistanceMonthlyWiseTotalRecords = 1;
  DayInFromMaxDistanceMonthlyWiseSortValue: string = "desc";
  DayInFromMaxDistanceMonthlyWiseSortKey: string = "ID";
  DayInFromMaxDistanceMonthlyWiseData = [];

  columns14: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["DAYS", "Days"],
    ["AVERAGE_DISTANCE", "Avg Distance"],
  ];

  getDayInFromMaxDistanceMonthlyWise(reset: boolean = false) {
    if (reset) {
      this.DayInFromMaxDistanceMonthlyWisePageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayInFromMaxDistanceMonthlyWiseSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayInFromMaxDistanceMonthlyWise(
        this.DayInFromMaxDistanceMonthlyWisePageIndex,
        this.DayInFromMaxDistanceMonthlyWisePageSize,
        this.DayInFromMaxDistanceMonthlyWiseSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayInFromMaxDistanceMonthlyWiseTotalRecords = data["count"];
            this.DayInFromMaxDistanceMonthlyWiseData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel15(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayOutMaxDistanceDayEmployeeReportPageIndex = 1;
      this.DayOutMaxDistanceDayEmployeeReportPageSize = 10;
      this.getDayOutMaxDistanceDayEmployeeReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayOutMaxDistanceDayEmployeeReportPageIndex = 1;
  DayOutMaxDistanceDayEmployeeReportPageSize = 10;
  DayOutMaxDistanceDayEmployeeReportTotalRecords = 1;
  DayOutMaxDistanceDayEmployeeReportSortValue: string = "desc";
  DayOutMaxDistanceDayEmployeeReportSortKey: string = "ID";
  DayOutMaxDistanceDayEmployeeReportData = [];

  columns15: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["OUT_DISTANCE", "Out Distance"],
    ["DAYOUT_REASON", "Day Out Reason"],
  ];

  getDayOutMaxDistanceDayEmployeeReport(reset: boolean = false) {
    if (reset) {
      this.DayOutMaxDistanceDayEmployeeReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayOutMaxDistanceDayEmployeeReportSortValue.startsWith("a")
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayOutMaxDistanceDayEmployeeReport(
        this.DayOutMaxDistanceDayEmployeeReportPageIndex,
        this.DayOutMaxDistanceDayEmployeeReportPageSize,
        this.DayOutMaxDistanceDayEmployeeReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayOutMaxDistanceDayEmployeeReportTotalRecords = data["count"];
            this.DayOutMaxDistanceDayEmployeeReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel16(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DayOutMaxDistanceMonthlyEmployeeReportPageIndex = 1;
      this.DayOutMaxDistanceMonthlyEmployeeReportPageSize = 10;
      this.getDayOutMaxDistanceMonthlyEmployeeReport(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DayOutMaxDistanceMonthlyEmployeeReportPageIndex = 1;
  DayOutMaxDistanceMonthlyEmployeeReportPageSize = 10;
  DayOutMaxDistanceMonthlyEmployeeReportTotalRecords = 1;
  DayOutMaxDistanceMonthlyEmployeeReportSortValue: string = "desc";
  DayOutMaxDistanceMonthlyEmployeeReportSortKey: string = "ID";
  DayOutMaxDistanceMonthlyEmployeeReportData = [];

  columns16: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["DAYS", "Days"],
    ["AVERAGE_DISTANCE", "Avg Distance"],
  ];

  getDayOutMaxDistanceMonthlyEmployeeReport(reset: boolean = false) {
    if (reset) {
      this.DayOutMaxDistanceMonthlyEmployeeReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DayOutMaxDistanceMonthlyEmployeeReportSortValue.startsWith(
        "a"
      )
        ? "asc"
        : "desc";

    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getDayOutMaxDistanceMonthlyEmployeeReport(
        this.DayOutMaxDistanceMonthlyEmployeeReportPageIndex,
        this.DayOutMaxDistanceMonthlyEmployeeReportPageSize,
        this.DayOutMaxDistanceMonthlyEmployeeReportSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DayOutMaxDistanceMonthlyEmployeeReportTotalRecords =
              data["count"];
            this.DayOutMaxDistanceMonthlyEmployeeReportData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  activePanel17(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.Top10DayOutMismatchedEmployeesPageIndex = 1;
      this.Top10DayOutMismatchedEmployeesPageSize = 10;
      this.getTop10DayOutMismatchedEmployees(false);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  Top10DayOutMismatchedEmployeesPageIndex = 1;
  Top10DayOutMismatchedEmployeesPageSize = 10;
  Top10DayOutMismatchedEmployeesTotalRecords = 1;
  Top10DayOutMismatchedEmployeesSortValue: string = "desc";
  Top10DayOutMismatchedEmployeesSortKey: string = "ID";
  Top10DayOutMismatchedEmployeesData = [];

  columns17: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["DAYS", "Total Days"],
    ["DAY_IN", "Day In"],
    ["DAY_OUT", "Day OUt"],
    ["DAYOUT_PERCENTAGE", "Day Out %"],
  ];

  getTop10DayOutMismatchedEmployees(reset: boolean = false) {
    if (reset) {
      this.Top10DayOutMismatchedEmployeesPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.Top10DayOutMismatchedEmployeesSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getTop10DayOutMismatchedEmployees(
        this.Top10DayOutMismatchedEmployeesPageIndex,
        this.Top10DayOutMismatchedEmployeesPageSize,
        this.Top10DayOutMismatchedEmployeesSortKey,
        sort,
        "",
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.Top10DayOutMismatchedEmployeesTotalRecords = data["count"];
            this.Top10DayOutMismatchedEmployeesData = data["data"];
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }

  EMPLOYEE_COUNT: any = [];
  LEAVES: any = [];
  LATE_MARK: any = [];
  EARLY_MARK: any = [];
  LATE_COMING: any = [];
  PRESENT: any = [];
  ABSENT: any = [];
  date = new Date();
  countSpins = false;
  lineSpins = false;

  public lineChartColors: Color[] = [
    {
      backgroundColor: "rgba(148,159,177,0.0)",
      borderColor: "#bc0250",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];

  public lineChartLegend = true;
  public lineChartType: ChartType = "line";
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
  };

  onLineChange(event) {
    this.EMPLOYEE_COUNT = event;

    if (this.date == undefined || this.date == null) {
      this.message.error("Please Select Month", "");

    } else {
      this.getGraphicalAttendanceSummary(this.date);
    }
  }

  activePanel18(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.getGraphicalAttendanceSummary(this.date);

    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);

    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  getGraphicalAttendanceSummary(result: Date): void {
    this.lineSpins = true;
    var month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var lastDate = new Date(result.getFullYear(), result.getMonth() + 1, 0);
    this.lineChartLabels = [];
    this.lineChartData = [
      { data: [], label: "Employee Count" },
      { data: [], label: "Leaves" },
      { data: [], label: "Late Mark" },
      { data: [], label: "Early Mark" },
      { data: [], label: "Late Comming" },
      { data: [], label: "Present" },
      { data: [], label: "Absent" },
    ];

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID.toString();
    }

    var DepartmentFilter = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID.toString();
    }

    var DesignationFilter = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID.toString();
    }

    this.loadingRecords = true;
    this.api
      .getGraphicalAttendanceSummary1(
        this.api.orgId,
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DesignationFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            for (var i = 0; i < data["data"].length; i++) {
              this.lineChartLabels.push(data["data"][i]["DATE"]);
              this.lineChartData[0].data.push(
                data["data"][i]["EMPLOYEE_COUNT"]
              );
              this.lineChartData[1].data.push(data["data"][i]["LEAVES"]);
              this.lineChartData[2].data.push(data["data"][i]["LATE_MARK"]);
              this.lineChartData[3].data.push(data["data"][i]["EARLY_MARK"]);
              this.lineChartData[4].data.push(data["data"][i]["LATE_COMING"]);
              this.lineChartData[5].data.push(data["data"][i]["PRESENT"]);
              this.lineChartData[6].data.push(data["data"][i]["ABSENT"]);
            }
          }
        },
        (err) => {
          this.loadingRecords = false;
          if (err["ok"] == false)
            this.message.error("Server Not Found", "");
        }
      );
  }
}
