import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-modelwisequotationsummary',
  templateUrl: './modelwisequotationsummary.component.html',
  styleUrls: ['./modelwisequotationsummary.component.css']
})
export class ModelwisequotationsummaryComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Model Wise Quotation Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "MODEL_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODEL: any;
  // BRANCH:any;
  models: any = [];
  // branch:any=[];
  // quotationdate:any;
  isOk = true;
  columns: string[][] = [["MODEL_NAME", "Model Name"]];
  dataList1: any;
  exportLoading: boolean;
  frmdate: any;
  todate: any
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {
    // this.MODEL="";
    this.getAllmodels();
    // this.BRANCH="AL";
    // this.quotationdate=new Date();
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")
    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    this.frmdate = new Date(y, m, 1)
    this.todate = new Date()
    this.todate = this.datePipe.transform(this.todate, "yyyy-MM-dd")
    this.frmdate = this.datePipe.transform(this.frmdate, "yyyy-MM-dd")
    
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = { 
      MODEL:this.MODEL, 
      frmdate:this.frmdate, 
      todate:this.todate, 
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 22').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerclose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerclose1.bind(this);
  }
  getAllmodels() {
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data'];
      this.onSelectAllModel(true);
      this.search(false);
    })
  }
  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }

  onSelectOffModel(event: any) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL = event;
    if (this.MODEL.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }

  search(reset: boolean, exportInExcel: boolean = false) {

    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      this.sortValue = "desc"
      // this.dataList = []
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";

      var filterQuery = this.filterQuery + likeQuery
    }
      // else {
      //   // this.applyFilter()
      // }
      //  this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,"").subscribe(data => {
      //     console.log(data)
      //     if(data['code']==200)
      //     this.loadingRecords = false;
      //     this.isSpinning=false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      if (exportInExcel == false) {
        this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate).subscribe(data => {
          console.log(data);
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
          (err) => {
            console.log(err);
          }
        );
      }
      else {
        this.exportLoading = false;
        this.api.getModelwisequotationsummary(0, 0, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
      }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")

    this.frmdate = this.datePipe.transform(this.frmdate, "yyyy-MM-dd")
    this.todate = this.datePipe.transform(this.todate, "yyyy-MM-dd")

    if (this.MODEL == undefined && this.frmdate == undefined && this.todate == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false;
      this.isFilterApplied = "default";
    }
     if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";

      var filterQuery = this.filterQuery + likeQuery
    }
    this.search(true);
    // else {
    //   // this.applyFilter()
    // }
    // if (this.isOk == true) {
    //   //   this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,""+this.MODEL).subscribe(data => {
    //   //     console.log(data)
    //   //     if(data['code']==200)
    //   //     {
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning=false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //     }
    //   //     else{}
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    //   // this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate).subscribe(data => {
    //   //   console.log(data)
    //   //   if (data['code'] == 200) {
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     if (data['count'] > 0) {
    //   //       this.dataList = data['data'];
    //   //       this.dataList1 = data['data'];
    //   //     }
    //   //     else {
    //   //       this.dataList = [];
    //   //       this.dataList1 = [];
    //   //     }
    //   //     this.isFilterApplied = "primary"
    //   //   }
    //   //   else {
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //   }
    //   // }, err => {
    //   //   console.log(err);
    //   // });
    // }
  }
  clearFilter() {
    // this.MODEL = [];
    //  this.frmdate="";
    //  this.todate="";
    this.filterClass = 'filter-invisible';
    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    this.frmdate = new Date(y, m, 1)
    this.todate = new Date()
    this.SELECT_MODEL = true;
    this.getAllmodels();
    //  this.BRANCH="";
    //  this.isFilterApplied="default";
    // this.search(false);

  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Total Quotations'] = this.dataList1[i]['TOTAL_QUOTATIONS'];
      obj1['No of Memo Converted'] = this.dataList1[i]['NO_OF_MEMO_CONVERTED'];
      obj1['No of Invoice Generated'] = this.dataList1[i]['NO_OF_INVOICE_GENEREATED'];
      
      // obj1['RTO'] = this.dataList1[i]['RTO'];
      // obj1['Fastag'] = this.dataList1[i]['FASTAG'];
      // obj1['Accessories'] = this.dataList1[i]['ACCESSORIES'];
      // obj1['Warranty'] = this.dataList1[i]['EX_WARRANTY'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Model Wise quotation summary' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  importInExcel() {
    this.search(true, true);
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
}
