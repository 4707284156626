import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-branch-wise-discount-report",
  templateUrl: "./branch-wise-discount-report.component.html",
  styleUrls: ["./branch-wise-discount-report.component.css"],
})
export class BranchWiseDiscountReportComponent implements OnInit {
  formTitle = "Branch Wise Discount Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'BranchWiseDiscountReport.xlsx';

  orgId = this.cookie.get("orgId");
  DATE = [];
  BRANCH_ID = [];
  FROM_DATE
  TO_DATE
  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService,
  ) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getBranches();
    this.getBranchWiseDiscountReport();
  }

  SELECT_ALL: boolean = false;
  onSelectAllChecked(event) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  setDateToDateFilter() {

    this.FROM_DATE = new Date();
    this.TO_DATE = new Date();
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    if (this.FROM_DATE.length > 0 && this.TO_DATE.length > 0) this.isFilterApplied = "primary";
    else
      this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getBranchWiseDiscountReport(true);
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";

    this.DATE = [];
    this.BRANCH_ID = [];
    this.setDateToDateFilter();
    this.SELECT_ALL = false;
    this.getBranchWiseDiscountReport();
  }

  sort(sort: { key: string; value: string }): void {
    this.branchWiseDiscountSortKey = sort.key;
    this.branchWiseDiscountSortValue = sort.value;
    this.getBranchWiseDiscountReport(true);
  }

  branchWiseDiscountPageIndex = 1;
  branchWiseDiscountPageSize = 10;
  branchWiseDiscountTotalRecords = 1;
  branchWiseDiscountSortValue: string = "desc";
  branchWiseDiscountSortKey: string = "BRANCH_ID";
  branchWiseDiscountData = [];

  columns: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["REQUESTED_DISCOUNT_AMOUNT", "Total Discount Requested"],
    ["APPROVED_DISCOUNT_AMOUNT", "Total Discount Approved"],
    ["REJECTED_DISCOUNT_AMOUNT", "Total Discount Rejected"],
  ];
  branchDiscount = [];
  exportLoading: boolean = false;
  importInExcel() {
    this.getBranchWiseDiscountReport(true, true);
  }

  getBranchWiseDiscountReport(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.branchWiseDiscountPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.branchWiseDiscountSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.FROM_DATE != undefined && this.FROM_DATE.length != 0 && this.TO_DATE != undefined && this.TO_DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    if (exportInExcel == false) {

      this.loadingRecords = true;
      this.api
        .getBranchWiseDiscountReport(
          this.branchWiseDiscountPageIndex,
          this.branchWiseDiscountPageSize,
          this.branchWiseDiscountSortKey,
          sort,
          "",
          this.api.orgId,
          this.FROM_DATE,
          this.TO_DATE,
          branchFilter
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.loadingRecords = false;
              this.branchWiseDiscountTotalRecords = data["count"];
              this.branchWiseDiscountData = data["data"];
            }
          },
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api
        .getBranchWiseDiscountReport(
          0,
          0,
          this.branchWiseDiscountSortKey,
          sort,
          "",
          this.api.orgId,
          this.FROM_DATE,
          this.TO_DATE,
          branchFilter
        )
        .subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.branchDiscount = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.branchDiscount.length; i++) {
      obj1['Branch Name'] = this.branchDiscount[i]['BRANCH_NAME'];
      obj1['Total Discount Requested'] = this.branchDiscount[i]['REQUESTED_DISCOUNT_AMOUNT'];
      obj1['Total Discount Approved'] = this.branchDiscount[i]['APPROVED_DISCOUNT_AMOUNT'];
      obj1['Total Discount Rejected'] = this.branchDiscount[i]['REJECTED_DISCOUNT_AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.branchDiscount.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Discount Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  // exportexcel(): void {
  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Branch Wise Discount Report');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }
}
