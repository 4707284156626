import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-general-manager-wise-discount-summary-report',
  templateUrl: './general-manager-wise-discount-summary-report.component.html',
  styleUrls: ['./general-manager-wise-discount-summary-report.component.css']
})
export class GeneralManagerWiseDiscountSummaryReportComponent implements OnInit {


  formTitle = "General Manager Wise Discount Summary Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'EWSalesSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  DATE = [];

  columns: string[][] = [["APPROVER_NAME", "General Manager Name"],
  ["REQUESTED_DISCOUNT", "Total Discounts Requested Count"],
  ["REJECTED_DISCOUNT", "Total Discounts Rejected Count"],];

  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  START_DATE: any;
  END_DATE: any;

  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;

  // startValue: any = new Date();
  // endValue: any = new Date();

  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  GM_ID = []
  empId = ''
  current = new Date();

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {

    this.empId = sessionStorage.getItem('empId');
    this.getSalesHierarchy();
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      START_DATE: this.START_DATE,
      END_DATE: this.END_DATE,
      GM_ID: this.GM_ID,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 28').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }

  SELECT_ALL2: boolean = true;

  onSelectAllChecked2(event: any) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.salesHeierachy.length; i++) {
        ids.push(this.salesHeierachy[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.GM_ID = ids;
  }

  onSelectOff2(event: any) {
    var a = this.salesHeierachy.length;
    var b = this.salesHeierachy.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.GM_ID = event;
    if (this.GM_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  salesHeierachy = [];

  getSalesHierarchy() {
    this.api.getsalesHierarchy('AND DIRECTOR_ID= ' + this.userId).subscribe((data1) => {
      console.log(data1);
      this.salesHeierachy = data1['data'];

      let ids = [];

      if (this.SELECT_ALL2 == true) {
        for (var i = 0; i < this.salesHeierachy.length; i++) {
          ids.push(this.salesHeierachy[i]["EMPLOYEE_ID"]);
        }
      } else {
        ids = [];
      }

      this.GM_ID = ids;
      this.search();
    },
      (err) => {
        console.log(err);
      }
    );
  }



  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }

    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null) {
      this.START_DATE = this.DATE[0]
    }

    if (this.DATE[1] != null) {
      this.END_DATE = this.DATE[1]
    }

    if (this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (this.GM_ID != undefined) {
      this.GM_ID = this.GM_ID;
    }
    this.search(true);
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE=[];
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
    this.SELECT_ALL2 = true
    let ids = [];
    for (var i = 0; i < this.salesHeierachy.length; i++) {
      ids.push(this.salesHeierachy[i]["EMPLOYEE_ID"]);
    }
    this.GM_ID=ids;
    this.dataList = [];
    // this.search();

  }
  ewsalesdata = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    console.log(this.salesHeierachy, "okkkkk");
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    var GMID = []
    if (this.GM_ID.length == 0) {
      this.salesHeierachy.forEach((ele) => {
        console.log('here', ele)
        GMID.push(ele.EMPLOYEE_ID)
      })
    } else {
      GMID = this.GM_ID
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }


    if (this.DATE != undefined && this.DATE.length != 0) {
      this.START_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.END_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.gmwiseDiscountSummary(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, GMID.toString(), this.START_DATE, this.END_DATE)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.gmwiseDiscountSummary(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.GM_ID, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['General Manager Name'] = this.ewsalesdata[i]['APPROVER_NAME'];
      obj1['Total Discounts Requested Count'] = this.ewsalesdata[i]['REQUESTED_DISCOUNT'];
      obj1['Total Discounts Rejected Count'] = this.ewsalesdata[i]['REJECTED_DISCOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'General Manager Wise Discount Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
