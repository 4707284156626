import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Role } from 'src/app/Models/role';
import { DatePipe } from '@angular/common';
import { Roledetails } from 'src/app/Models/roledetails';
@Component({
  selector: 'app-bank-wise-report',
  templateUrl: './bank-wise-report.component.html',
  styleUrls: ['./bank-wise-report.component.css']
})
export class BankWiseReportComponent implements OnInit {
  @Input() data: Roledetails = new Roledetails
  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  menus = [];
  PARENT_ID=200


constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) {}

ngOnInit(){
    console.log(this.data.PARENT_ID)
    this.api.getRoleDetails1(this.roleId,"AND PARENT_ID=293").subscribe(data => {
      console.log("data");
      console.log(data['data']);
      this.menus = data['data'];
    }, err => {
      console.log(err);
  });
}


loadForms() {
    this.api.getForms(this.userId, this.roleId).subscribe(data => {
      if (data['code'] == 200) {
        data['data'].forEach(element => {
          element['children'].sort(this.sortFunction)
            if (element['children'].length == 0)
            delete element['children']
        });
          this.menus = data['data'].sort(this.sortFunction);
      }
  });
}

sortFunction(a, b) {
    var dateA = a.SEQ_NO;
    var dateB = b.SEQ_NO;
    return dateA > dateB ? 1 : -1;
};

selected:any;
target:any;
// navigateTo(item) {
//   this.selected = item.value; 
//     if (item) {
//       this.ngOnInit()
//         this.router.navigate([item]);
//         this.target = item
//         console.log(this.target)
//     }
//     return false;
// }

currentIndex = -1
dataShow:boolean=true;
isSelected:boolean;
sm1: Boolean = undefined;
navigateTo(data,index: number) {
  this.dataShow=false
  this.currentIndex = index;
  this.isSelected = true;
  if (data.value === '/Bankwisememosummary') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

backPage(){
  this.dataShow=true
}

}