import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Branchmaster } from 'src/app/Models/Branchmaster';
import { ApiService } from 'src/app/Service/api.service';
import { OrganizationMaster } from '../../../Models/organization-master';
import { EmmService } from '../../../Service/emm.service';
import { BranchComponent } from '../branch/branch.component';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})

export class BranchesComponent implements OnInit {
  formTitle = "Manage Branches";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Branch Name"], ["CLUSTER_NAME", "Cluster Name"], ["CITY", "City"], ["TEHSIL", "Tehsil"], ["DISTRICT", "District"], ["STATE", "State"], ["COUNTRY", "Country"]];
  columns1: string[][] = [["NAME", "Branch Name"], ["CLUSTER_NAME", "Cluster Name"], ["CITY", "City"], ["TEHSIL", "Tehsil"], ["DISTRICT", "District"], ["STATE", "State"], ["COUNTRY", "Country"], ["PINCODE", "Pincode"], ["SEQUENCE_NO", "Sequence no"]];
  time = new Date()
  drawerVisible: boolean;
  drawerTitle: string;
  drawerTitle1: string
  drawerData: Branchmaster = new Branchmaster();

  constructor(private api: ApiService, private cookie: CookieService,) { }

  ngOnInit() {
    this.search();
    this.getRolesData()
  }



  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "descend";
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
      console.log("likeQuery" + likeQuery);
    }

    if (likeQuery)
      filter += likeQuery

    this.api.getAllBranch(this.pageIndex, this.pageSize, this.sortKey, sort, filter + ' AND ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  @ViewChild(BranchComponent, { static: false }) BranchComponentVar: BranchComponent;

  add(): void {
    this.drawerTitle = "Create New Branch";
    this.drawerData = new Branchmaster();
    this.drawerData.ORG_ID = Number(this.cookie.get('orgId'));
    this.drawerData.STATUS = true;

    this.api.getAllBranch(1, 1, 'SEQUENCE_NO', 'desc', '' + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }

    }, err => {
      console.log(err);
    })

    this.drawerVisible = true;
    this.BranchComponentVar.getClusters(this.drawerData.ID);
  }

  edit(data: Branchmaster): void {
    this.drawerTitle = "Update Branch Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.BranchComponentVar.getClusters(this.drawerData.ID);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }








  //areamap

  Roletype = []
  getroles = []

  getRolesData() {
    this.api.getAreaMaster(0, 0, '', 'asc', 'AND STATUS=1').subscribe(successCode => {
      this.getroles = successCode['data']
      console.log(this.getroles);
    })
  }

  isVisible1: boolean = false



  drawerClose1() {
    this.isVisible1 = false;
    // this.getData();
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this)
  }

  user
  getareaMappingData = []
  allareas = []
  areamap(data) {
    this.drawerTitle1="Area Mapping"
    this.user = Object.assign({}, data);

    // this.Roletype=this.getroles

    this.api.getareaMappingData(data.ID).subscribe(successCode => {
      this.getareaMappingData = successCode['data']
      this.Roletype = successCode['data']
      // this.is

      for (let i = 0; i < this.getareaMappingData.length; i++) {
        // if (this.getareaMappingData[i]['STATUS'] == 1) {
        //   this.allareas.push(this.getareaMappingData[i]['ID'])
        // }
        this.Roletype[i]['STATUS'] = this.Roletype[i]['STATUS'] == 1 ? true : false
      }
      console.log(this.Roletype);


    })
    this.isVisible1 = true;

  }


}



