export class HolidayMaster {
CLIENT_ID:number
    ID:number;
    DATE:Date;
    IS_HOLIDAY:boolean;
    REASON:string;
    ATTENDANCE_ALLOW:boolean
    TYPE:string='P';
    IS_ATTENDANCE_ALLOWED

}  
