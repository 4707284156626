import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-sales-exemodal-ds-report',
  templateUrl: './sales-exemodal-ds-report.component.html',
  styleUrls: ['./sales-exemodal-ds-report.component.css']
})
export class SalesExemodalDsReportComponent implements OnInit {
  columns: string[][] = [['BRANCH_NAME', 'Branch'], ['SALES_CONSULTANT_NAME', 'Sales Executive'], ['SALES_DATE', 'Date'],
  ['MODEL_NAME', 'Model'], ['VARIENT_NAME', 'Variant'], ['FUEl_TYPE_NAME', 'Fuel'], ['TRANSMISSION_TYPE_NAME', 'Transmission'],
  ['CUSTOMER_NAME', 'Customer Name'], ['SALES_CONSULTANT_RATE', 'SE Incentive'], ['SALES_MANAGER_NAME', 'SM Name'],
  ['SALES_MANAGER_NAME', 'SM Name'], ['SALES_MANAGER_RATE', 'SM Incentive'], ['SALES_HEAD_NAME', 'SH Name'], ['SALES_HEAD_RATE', 'SH Incentive'],]
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "SALES_EXECUTIVE_ID";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Sales Executive Wise Model Wise Detailed Direct Sales Report"
  startValue: any = new Date()
  endValue: any = new Date()
  isSpinning = false;
  salesExe = []
  salesExe2 = []



  totalRecords = []
  filterQuery: string = ''
  BRANCH_ID: any = ""
  // BRANCH_ID1
  employee :any= ""
  branches: any = ""
  orgId = this.cookie.get('orgId');
  STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  SALES_EXECUTIVE_ID: any = ""
  constructor(private api: ApiService, private cookie: CookieService, public datePipe: DatePipe,
    private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search()
    this.getBranches()
    this.getEmployee()
  }
  getBranches() {
    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1').subscribe(data => {
      this.branches = data['data']
      if (this.branches.length > 0) {
        this.BRANCH_ID = data['data'][0]['ID']
        console.log(this.branches);
        // this.name = data['data'][0]['NAME'];
      }
      else {
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=28').subscribe(data => {
      this.employee = data['data']
      if (this.employee.length > 0) {
        this.SALES_EXECUTIVE_ID = data['data'][0]['EMPLOYEE_ID']
        console.log(this.employee);
        // this.name = data['data'][0]['NAME'];
      }
      else {
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }


  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  salesExeData = [];


  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    if (this.BRANCH_ID != "" && this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_EXECUTIVE_ID != "" && this.SALES_EXECUTIVE_ID != undefined) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getSalesExeModelReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.SALES_EXECUTIVE_ID, this.STARTDATE, this.ENDDATE).subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.salesExe = data['data'];

      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getSalesExeModelReport(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.SALES_EXECUTIVE_ID, this.STARTDATE, this.ENDDATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.salesExeData = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  endOpen = false;
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';

    if (this.BRANCH_ID != "" && this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_EXECUTIVE_ID != "") {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue
    }

    this.search();
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.getBranches()
    this.getEmployee()
    this.startValue = new Date()
    this.endValue = new Date()
    this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.search()
  }
  fileName = 'Sales Exemodelreports.xlsx';

  // importInExcel(): void {
  //   this.loadingRecords = true;/* pass here the table id */

  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Sales Executive Wise Model Wise Detailed Direct Sales Report');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.salesExeData.length; i++) {
      obj1['Branch Name'] = this.salesExeData[i]['BRANCH_NAME'];
      obj1['Sales Executive Name'] = this.salesExeData[i]['SALES_CONSULTANT_NAME'];
      obj1['Sales Date'] = this.datePipe.transform(this.salesExeData[i]['SALES_DATE'], 'yyyy-MM-dd');
      obj1['Model Name'] = this.salesExeData[i]['MODEL_NAME'];
      obj1['Varient Name'] = this.salesExeData[i]['VARIENT_NAME'];
      obj1['Fuel Type Name'] = this.salesExeData[i]['FUEl_TYPE_NAME'];
      obj1['Transmission Type Name'] = this.salesExeData[i]['TRANSMISSION_TYPE_NAME'];
      obj1['Customer Name'] = this.salesExeData[i]['CUSTOMER_NAME'];
      obj1['SE Incentive'] = this.salesExeData[i]['SALES_CONSULTANT_RATE'];
      obj1['SM Name'] = this.salesExeData[i]['SALES_MANAGER_NAME'];
      obj1['SM Incentive'] = this.salesExeData[i]['SALES_MANAGER_RATE'];
      obj1['SH Incentive'] = this.salesExeData[i]['SALES_HEAD_NAME'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.salesExeData.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Model Wise Detailed Direct Sales Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

}
