import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../Service/api.service';
export const APPLICATION_ID = '3';
@Component({
  selector: 'app-helplogin',
  templateUrl: './helplogin.component.html',
  styleUrls: ['./helplogin.component.css']
})
export class HelploginComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: [];
  @Input() drawerVisible: boolean;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  total: number=null;
  loadingRecords = true;
  dataList=[]
  datalist:any[] = [];
  qrloading = false;
  courseLoading = false;
  buttonLoading = false;
  filterValue = 0
  coursesNodes = []
  Started = false;
  datalist2:any[] = [];
  result = [];
  courseMappingData = [];
  courseData2 = [];
  searchtext = ""
  likeQuery = ""
  check3 = true
  check2 = true
  check1 = true
  check4 = true
  check5 = true
  check6 = true
  apierror = false;
  interval: any;
  tags = [];
  isPaid = 0;
  isWeb = true;
  arr:any[] = []
  courseNAME = ''
  date1 = null;
  date2 = null;
  columns: string[][] = [["NAME", "Name"],["EMAIL_ID","Email"], ["CITY", "city"],["TEHSIL","tehshil"],["DISTRICT","district"],["STATE","state"],["COUNTRY","country"],["PINCODE","pincode"],["MOBILE_NUMBER","Mobile"]]


  constructor(private api:ApiService, private cookie: CookieService, private message: NzNotificationService,public datepipe: DatePipe) { }

  ngOnInit() {
    // this.faqs();
  }

  faqs(){
    this.api.getAllFaqs(this.pageIndex, this.pageSize, this.sortKey,'','').subscribe(data => {
      this.loadingRecords = false;
      
      this.dataList = data['data'];
      }, err => {
      // console.log(err);
    });
  }
  apply() {
    this.filterQuery = '';

    if (this.date1 != null && this.date2 != null && this.date1 != '' && this.date2 != '') {
      var date1 = new Date(this.date1);
      var date2 = new Date(this.date2);
      var Time = date1.getTime() - date2.getTime();
      // console.log(Time);

      if (Time > 0) {
        this.message.error('Please select dates properly ','')
      }
      else {

        if (this.date1 != null && this.date2 != null && this.date1 != '' && this.date2 != '') {
          var d1 = this.datepipe.transform(this.date1, "yyyy-MM-dd")
          var d2 = this.datepipe.transform(this.date2, "yyyy-MM-dd")
          this.filterQuery = " AND ( DATE BETWEEN '" + d1 + " 00:00:00" + "' AND '" + d2 + " 23:59:59" + "' ) "

        } else {
          this.filterQuery = '';
        }
        this.search();
      }
    } else {
      if ((this.date1 == null || this.date1 == '') && this.date2 != null && this.date2 != '') {
        this.message.error(' Please select "From" date ','')
      }
      if (this.date1 != null && this.date1 != '' && (this.date2 == null || this.date2 == '')) {
        this.message.error('Please select "To" date. ','')
      }

      if ((this.date1 == null || this.date1 == '') && (this.date2 == null || this.date2 == '')) {

        this.search();
      }
    }
  }
  apply2() {
    this.datalist = []
    var datalist2 = []
    this.qrloading = true;

    if (this.searchtext.trim() == '')
      datalist2 = this.datalist
    else
      datalist2 = this.datalist2.filter(el => (el.MAPPED_NAME.toLowerCase()).includes(this.searchtext.toLowerCase()));

    this.datalist = this.datalist2.filter((el) => {
      if (this.check1 && el.STATUS == "P")
        return true;
      if (this.check2 && el.STATUS == "O")
        return true;
      if (this.check3 && el.STATUS == "A")
        return true;
      if (this.check3 && el.STATUS == "R")
        return true;
      if (this.check3 && el.STATUS == "C")
        return true;
      if (this.check3 && el.STATUS == "E")
        return true;
      return false;
    });
    if (this.date1 != null && this.date2 != null && this.date1 != '' && this.date2 != '') {
      var date1 = new Date(this.date1);
      var date2 = new Date(this.date2);
      var Time = date1.getTime() - date2.getTime();
      // console.log(Time);

      if (Time > 0) {
        this.message.error('Please select dates properly ','')
      }
      else {

        if (this.date1 != null && this.date2 != null && this.date1 != '' && this.date2 != '') {
          var d1 = this.datepipe.transform(this.date1, "yyyy-MM-dd")
          var d2 = this.datepipe.transform(this.date2, "yyyy-MM-dd")
          // this.filterQuery = " AND ( START_DATE_TIME BETWEEN '" + d1 + ":00:00:00" + "' AND '" + d2 + ":23:59:59" + "' ) "
          // this.isFilterApplied = true
        } else {
          // this.filterQuery = '';
        }

      }
    } else {
      if ((this.date1 == null || this.date1 == '') && this.date2 != null && this.date2 != '') {
        this.message.error(' Please select "From" date ','')
      }
      if (this.date1 != null && this.date1 != '' && (this.date2 == null || this.date2 == '')) {
        this.message.error('Please select "To" date. ','')
      }

      if ((this.date1 == null || this.date1 == '') && (this.date2 == null || this.date2 == '')) {


      }
    }
    this.qrloading = false;

  }
  checked1(event:any) {
    this.check1 = event;
    this.apply()
  }
  checked2(event:any) {
    this.check2 = event;
    this.apply()
  }
  checked3(event:any) {
    this.check3 = event;
    this.apply()
  }
  checked4(event:any) {
    this.check4 = event;
    this.apply()
  }
  checked5(event:any) {
    this.check5 = event;
    this.apply()
  }
  checked6(event:any){
    this.check6 = event;
    this.apply()
  }
  search() {
    this.likeQuery = " ";
    if (this.searchtext != "") {
      this.likeQuery = " AND (";
      this.likeQuery += " QUESTION like '%" + this.searchtext + "%' OR";
      this.likeQuery = this.likeQuery.substring(0, this.likeQuery.length - 2) + ")";
      this.total = null;
      this.pageIndex = 1;
      this.datalist = []
      this.datalist2 = [];
    } else {
      this.pageIndex = 1;
      this.total =null;
      this.datalist = []
      this.datalist2 = [];
    }

    this.loadAllCourses2();
  }
  loadAllCourses2(){
    if (this.pageIndex < 2)
      this.courseLoading = true;

    this.buttonLoading = true;

    if (this.total != undefined && this.courseData2.length >= this.total) {
      this.courseLoading = false;
      this.buttonLoading = false;
      return false;
    }
    var statusQuery = " AND ("
    if (this.check1)
      statusQuery = statusQuery + " STATUS = 'P' ||";
    if (this.check2)
      statusQuery = statusQuery + " STATUS = 'O' ||"
    if (this.check3)
      statusQuery = statusQuery + " STATUS = 'A' ||"
    if (this.check4)
      statusQuery = statusQuery + " STATUS = 'R' ||"
    if (this.check5)
      statusQuery = statusQuery + " STATUS = 'C' ||"
    if (this.check6)
      statusQuery = statusQuery + " STATUS = 'E' ||"
    statusQuery = statusQuery.slice(0, -2) + ")"

    if (this.check1 && this.check2 && this.check3 && this.check4 && this.check5 && this.check6)
      statusQuery = ""

    if (!this.check1 && !this.check2 && !this.check3 && !this.check4 && !this.check5 && !this.check6)
      statusQuery = ""

      // " AND APPLICATION_ID= " + APPLICATION_ID +
    var filterQuery = " AND USER_ID=" + this.cookie.get("userID") +  "" + statusQuery;
    this.api.getAllTickets(this.pageIndex, 6, 'ID', 'desc', this.likeQuery + this.filterQuery + filterQuery)
      .subscribe(result => {
        this.qrloading = false

        this.courseLoading = false;
        this.buttonLoading = false;

        if (result['code'] == "200") {
          this.total = result['count'];
          var data = Object.assign([], result['data']);
          clearInterval(this.interval);

          this.datalist = [...this.datalist, ...data];
          this.datalist2 = [...this.datalist2, ...data];
        } else {
          this.apierror = true;
        }
      }, err => {
        this.apierror = true;
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
  }

}
