import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-sales-manager-wise-branch-wise-report',
  templateUrl: './sales-manager-wise-branch-wise-report.component.html',
  styleUrls: ['./sales-manager-wise-branch-wise-report.component.css']
})
export class SalesManagerWiseBranchWiseReportComponent implements OnInit {

  formTitle = "Sales Manager Wise Branch Wise Summary Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  isFilterApplied: string = "default";
  filterClass: string = "filter-invisible";
  loadingRecords = false;
  // SearchData : string = '';
  sortValue: string = "desc";
  sortKey: string = "SALES_MANAGER_ID";
  filterQuery: string = "";
  columns: string[][] = [
    ["SALES_MANAGER_NAME", "Sales Manager Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["NO_OF_SALES_EXECUTIVE", "No. Of Sales Executive"],
    ["TOTAL_QUOTATIONS", "No. Of Quotations"],
    ["MEMO_CONVERTED", "No. Of Memo Converted"],
    ["INVOICE_CONVERTED", "No. Of Invoices Converted"],
  ];


  searchText: string = "";
  date = null;

  orgId = this.cookie.get("orgId");
  BRANCH_ID: any = [];
  startValue: any = new Date()
  endValue: any = new Date()
  STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  // month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  // year = new Date().getFullYear()
  // MONTH: any = this.month;
  // MONTH1: any = new Date()
  // YEAR: any = this.year
  // monthFormat = "MMM-yyyy";

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService
  ) { }

  ngOnInit() {
    this.search();
    this.getBranches();
  }

  endOpen = false;
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };
  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data: any) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
          // console.log(this.branches);

        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }
  SELECT_ALL1: boolean = false; 
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let ids = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  applyFilter() {

    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.BRANCH_ID != undefined || this.BRANCH_ID > 0) {
      this.BRANCH_ID = this.BRANCH_ID;
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue
    }

    this.search();
    var likeQuery = '';

    if (this.searchText != '') {
       likeQuery = ' AND(';
      this.columns.forEach((column) => {
        if( column[0] == "SALES_MANAGER_NAME"){
          likeQuery += ' ' + "SALES_MANAGER_NAME" + " like '%" + this.searchText + "%' OR";
        }
        else if(column[0] == "BRANCH_NAME"){
          likeQuery += ' ' + "BRANCH_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length)+")";
    }

  }

  clearFilter() {
    this.BRANCH_ID = [];
    this.SELECT_ALL1 = false;
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    // this.MONTH1 = new Date();
    this.startValue = new Date()
    this.endValue = new Date()
    this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.filterQuery = '';
    // this.SalesManagerWiseBranchWise = [];
    this.search();
  }


  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  SalesManagerWiseBranchWise = [];
  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    if (this.searchText != '') {
      likeQuery = ' AND(';
      this.columns.forEach((column) => {
        if (column[0] == "SALES_MANAGER_NAME") {
          likeQuery += ' ' + "SALES_MANAGER_NAME" + " like '%" + this.searchText + "%'  OR";
        }
        else if (column[0] == "BRANCH_NAME") {
          likeQuery += ' ' + "BRANCH_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length) + ")";
    }

    if (this.BRANCH_ID != undefined || this.BRANCH_ID > 0) {
      this.BRANCH_ID = this.BRANCH_ID;
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue;
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue;
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getSalesManagerWiseBranchWiseDetails(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.STARTDATE, this.ENDDATE)
        .subscribe(
          (data) => {
            console.log(this.SalesManagerWiseBranchWise);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.SalesManagerWiseBranchWise = data['data'];
            this.filterClass = 'filter-invisible';
          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = true;
      this.api.getSalesManagerWiseBranchWiseDetails(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.STARTDATE, this.ENDDATE)
        .subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.SalesManagerWiseBranchWise = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.SalesManagerWiseBranchWise.length; i++) {
      obj1['Sales Manager Name'] = this.SalesManagerWiseBranchWise[i]['SALES_MANAGER_NAME'];
      obj1['Branch Name'] = this.SalesManagerWiseBranchWise[i]['BRANCH_NAME'];
      obj1['No. Of Sales Executive'] = this.SalesManagerWiseBranchWise[i]['NO_OF_SALES_EXECUTIVE'];
      obj1['No. Of Quotations'] = this.SalesManagerWiseBranchWise[i]['NO_OF_QUOTATIONS'];
      obj1['No. Of Memo Converted'] = this.SalesManagerWiseBranchWise[i]['NO_OF_MEMO_CONVERTED'];
      obj1['No. Of Invoices Converted'] = this.SalesManagerWiseBranchWise[i]['NO_OF_INVOICES_CONVERTED'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.SalesManagerWiseBranchWise.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Manager Wise Branch Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}
