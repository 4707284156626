import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { AssetRequest } from 'src/app/Models/AssetRequest';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-asset-request-drawer',
  templateUrl: './asset-request-drawer.component.html',
  styleUrls: ['./asset-request-drawer.component.css']
})

export class AssetRequestDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: AssetRequest;
  @Input() drawerVisible: boolean;

  
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAssetCategory();
  }

  categories = [];
  assets = [];

  getAssetCategory() {
    this.categories = [];
    this.assets = [];
    this.data.ASSET_ID = undefined;

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' AND IS_LAST=1 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getAssets(assetCategoryID) {
    this.assets = [];
    this.data.ASSET_ID = undefined;

    this.api.getAllAssets(0, 0, 'NAME', 'asc', " AND STATUS in ('NA','DA') AND CATEGORY_ID=" + assetCategoryID).subscribe(data => {
      if (data['code'] == 200) {
        this.assets = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  isSpinning = false;

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.CATEGORY_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Asset Category", "");
    }

    if (this.data.ASSET_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Asset Category", "");
    }

    // if (this.data.REQUESTED_QUANTITY == undefined || this.data.REQUESTED_QUANTITY.trim() == '') {
    //   isOk = false;
    //   this.message.error("Please Enter Valid Category Name", "");
    // }

    if (this.data.DESCRIPTION == undefined || this.data.DESCRIPTION.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Description", "");
    }

    if (isOk) {
      this.isSpinning = true;
      this.data.EMPLOYEE_ID = this.api.userId;
      this.data.REQUESTED_QUANTITY = "1";
      this.data.EXPECTED_DATE_TIME = this.datePipe.transform(this.data.EXPECTED_DATE_TIME, "yyyy-MM-dd HH:mm" + ":00");
      console.log(this.data);

      if (!this.data.ID) {
        this.api.addAssetRequest(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Asset Successfully Requested", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.reset(myForm);
              this.data = new AssetRequest();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Asset Request Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }
}
