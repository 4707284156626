import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { tourExpenseMaster } from 'src/app/Models/tourExpenseMaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-expensetour',
  templateUrl: './expensetour.component.html',
  styleUrls: ['./expensetour.component.css']
})
export class ExpensetourComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data : tourExpenseMaster= new tourExpenseMaster();
  @Input() drawerVisible: boolean;
  @Input() HEAD_STATUS: boolean
  @Input() HR_STATUS: boolean
  @Input() ACCOUNTANT_STATUS: boolean
  @Input() GM_STATUS: boolean

  orgId = this.cookie.get('orgId');
  ROLE_ID = Number(this.cookie.get('roleId'))
  dateFormat = "dd/MMM/yyyy";
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  userId = Number(this.cookie.get("userId"));

  HEAD_APPROVED_DATETIME = new Date()
  HR_APPROVED_DATETIME = new Date()
  ACCOUNTANT_APPROVED_DATETIME = new Date()
  d1: any
  d2: any
  d3: any
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {

  }

  APPLICATION_DATE: any
  DATE: any

  categories = [];
  employees = []

  data1() {
    if (this.data.HR_STATUS == "Approved") {
      this.data.HR_STATUS = "AP"
    }
    else {
      this.data.HR_STATUS = "R"
    }
  }

  switchChange2(e1: boolean) {
    this.HR_STATUS = e1;
    if (this.HR_STATUS = true) {
      this.data.HR_STATUS = "AP"

    }
    else {
      this.data.HR_STATUS = "R"

    }

  }

  switchChange1(e1: boolean) {
    this.HEAD_STATUS = e1;
    if (this.HEAD_STATUS = true) {
      this.data.HEAD_STATUS = "AP"

    }
    else if (this.HEAD_STATUS = false) {
      this.data.HEAD_STATUS = "R"

    }
    console.log()
  }

  // ACCOUNTANT_STATUS

  loadDataFilter() {
    this.employees = [];

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(employees => {
      if (employees['code'] == 200) {
        this.employees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  modes = []
  loadDataFilter1() {
    this.modes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.modes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  classes = []
  loadDataFilter2() {
    this.classes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.classes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  designations = []
  loadDataFilter3() {
    this.designations = [];

    this.api.getAllEmployees(0, 0, 'NAME ', 'asc', ' AND ORG_ID =' + this.orgId).subscribe(employees => {
      if (employees['code'] == 200) {
        this.designations = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }
  TypeDetails = []

  getTourExpense() {

    this.api.getTourExpense(0, 0, '', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.TypeDetails = data['data'];

      }

    }, err => {
      console.log(err);
    });
  }
  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  isSpinning = false;
  requestedData: any
  date1 = new Date()

  APPROVER_ID: any

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (isOk) {
      console.log(this.data);
      this.isSpinning = true;

      this.data.APPLICATION_DATE = this.datePipe.transform(this.data.APPLICATION_DATE, 'yyyy-MM-dd');
      this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd');

      // if()

      if (this.data.ID) {
        this.api.updateExpense(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {

            this.message.success("Tour information updated successfully...", "");
            this.isVisible = false

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Failed to update Tour information...", "");
            this.isSpinning = false;
            this.isVisible = false
          }
        });

      }

      else{
        this.api.createExpense(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Tour information added successfully...", "");
  
            if (!addNew)
              this.drawerClose();
            else {
              this.data = new tourExpenseMaster();
            }
            this.isSpinning = false;
  
          } else {
            this.message.error("Failed to add Tour information...", "");
            this.isSpinning = false;
          }
        });
      }
      // }

      this.isSpinning = true;

    }
  }

  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: Date): void {
    console.log('onOk', result);
  }

  emppData: any = []
  isVisible: boolean = false;

  handleOk(): void {

    this.api.getAllEmpRoleMap(0, 0, "ID", "asc", ' AND ROLE_ID IN (14)').subscribe(data => {
      if (data["code"] == 200) {
        this.emppData = data["data"];
        console.log(this.emppData, "this.emppData")
      }
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    });

  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  modalOpen() {


    var filter

    if (this.ROLE_ID == 14) {
      filter = ' AND ROLE_ID IN (30)'
    }
    else if (this.ROLE_ID == 30) {
      filter = ' AND ROLE_ID IN (13)'
    }

    else {
      filter = ' AND ROLE_ID IN (14)'
    }

    this.api.getAllEmpRoleMap(0, 0, "ID", "asc", filter).subscribe(data => {
      if (data["code"] == 200) {
        this.emppData = data["data"];
        console.log(this.emppData, "this.emppData")
      }
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    });
    this.isVisible = true
  }

}
