import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';

// import { ClientmasterService } from '../Services/clientmaster.service';
import * as moment from 'moment';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';


@Component({
  selector: 'app-backofficeareaemp',
  templateUrl: './backofficeareaemp.component.html',
  styleUrls: ['./backofficeareaemp.component.css']
})
export class BackofficeareaempComponent implements OnInit {

  formTitle = "Back office Area wise & employee wise summary report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'BackofficeareaEmployee.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [["EMPLOYEE_NAME", "Name"], ["AREA_NAME", "Role"], ["BRANCH_NAME", "Branch"], ["TOTAL_VEHICLE_SALE", "Veh Regn done"], ["RATE", "Rate"], ["SANCTION_AMOUNT", "Sanctioned Amount"], ["MEAN_AMOUNT", "Mean"], ["POINTS", "Points"], ["AMOUNT", "Final Amount"]];
  STATUS = "AL";
  BRANCH = [];
  SalesExecutive = []
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  ClusterId = Number(this.cookie.get('clusterId'));
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  // startOpen = false;

  employees = [];

  BRANCH_ID: any;
  EMPLOYEE_ID: any;
  // MONTH='';
  // YEAR=''
  // LIKE_STRING='';


  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.search();
    // this.applyFilter();
    this.getBranch();
    this.getEmployee();
  }

  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  getEmployee() {
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.employees = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }

  dates: any = [];
  today2 = new Date();

  // startDateChange() {
  //   var startDate = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
  //   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  //   console.log(this.getDaysArray(startDate, endDate));
  //   console.log(this.dates);
  // }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  // onFromDateChange(fromDate: any) {
  //   if (fromDate == null)
  //     this.date2 = new Date();

  //   else
  //     this.date2 = new Date(fromDate);
  // }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue;
  };
  current = new Date();


  // disabledEndDate = (selected: Date): boolean =>
  //   // Can not select days before today and today
  //   differenceInCalendarDays(selected, this.current) > 0;


  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue;
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND DATE(CREATED_MODIFIED_DATE) BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    if (this.BRANCH_ID != undefined || this.BRANCH_ID > 0) {
      this.filterQuery = this.filterQuery + ' AND BRANCH_ID=' + "" + this.BRANCH_ID + "";
    }
    if (this.EMPLOYEE_ID != null) {
      this.filterQuery = this.filterQuery + ' AND EMPLOYEE_ID=' + "" + this.EMPLOYEE_ID + "";
    }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    var filter = '';
    if(this.ClusterId != 0){
      filter=' AND CLUSTER_ID =' + this.ClusterId
    }

    // this.api.supportStaffEmployeeWiseSummary(0, 0, this.sortKey, sort, " AND CLUSTER_ID = " + this.ClusterId + this.filterQuery)
    this.api.supportStaffEmployeeWiseSummary(0, 0, this.sortKey, sort, this.filterQuery + filter)

      .subscribe((data) => {
        console.log(data);
        this.loadingRecords = false;
        this.isFilterApplied = 'primary';
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.isSpinning = false;
        this.filterClass = 'filter-invisible';
        // this.search();
      },
        (err) => {
          console.log(err);
        }
      );

  }


  setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date();
    this.endValue = new Date();

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    this.BRANCH_ID = null;
    this.EMPLOYEE_ID = null;

  }

  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  dataList2 = []
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND DATE(CREATED_MODIFIED_DATE) BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "
    }
    var filter = '';
    if(this.ClusterId != 0){
      filter=' AND CLUSTER_ID =' + this.ClusterId
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.supportStaffEmployeeWiseSummary(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery + filter).subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];

      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.supportStaffEmployeeWiseSummary(0, 0, this.sortKey, this.sortValue, this.filterQuery + likeQuery + filter).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Employee Name'] = this.dataList2[i]['EMPLOYEE_NAME'];
      obj1['Role Name'] = this.dataList2[i]['AREA_NAME'];
      obj1['Branch Name'] = this.dataList2[i]['BRANCH_NAME'];
      obj1['Veh Regn done'] = this.dataList2[i]['TOTAL_VEHICLE_SALE'];
      obj1['Rate'] = this.dataList2[i]['RATE'];
      obj1['Sanctioned Amount'] = this.dataList2[i]['SANCTION_AMOUNT'];
      obj1['Mean Amount'] = this.dataList2[i]['MEAN_AMOUNT'];
      obj1['Points'] = this.dataList2[i]['POINTS'];
      obj1['Final Amount'] = this.dataList2[i]['AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Back Office Area Wise & Employee Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}

