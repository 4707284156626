export class ShiftMaster {
    ID: number
    NAME: string
    CODE: string
    TYPE: string = "R"
    BEGIN_TIME: any
    END_TIME: any
    END_NEXT_DAY: boolean
    IS_BREAK: boolean
    BREAK_START_TIME: any
    BREAK_END_TIME: any
    MARK_HALFDAY_AFTER: any
    MARK_HALFDAY_BEFORE: any
    MARK_FULLDAY_AFTRE: any
    MARK_FULLDAY_BEFORE: any
    GRACE_TIME: boolean
    GRACE_MINUTES: string
    TOTAL_WORKING_TIME: string
    BREAKE_TIME: string
    NO_OF_BREAKS: number
    CLIENT_ID: number

    MAX_LATEMARK_TIME:any
    MAX_EARLYMARK_TIME:any
    MAX_LATEMARK_COUNT:number
    MAX_EARLYMARK_COUNT:number
    MAX_LATECOMING_COUNT:number
    MAX_APPLIED_LATEMARK_TIME:any
    MAX_EARLYMARK_APPLIED_TIME:any
    HALFDAY_TIME:any
    IS_DEFAULT:boolean

}