import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-sales-module-reports",
  templateUrl: "./sales-module-reports.component.html",
  styleUrls: ["./sales-module-reports.component.css"],
})
export class SalesModuleReportsComponent implements OnInit {
  formTitle = "Sales Reports";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;

  orgId = this.cookie.get("orgId");
  DATE = [];
  BRANCH_ID = [];
  SALES_EXECUTIVE_ID = [];
  MODEL_ID = [];
  VARIENT_ID = [];
  FUEL_TYPE = [];
  TRANSMISSION = [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  // REPORTS NAME AND STATUS
  reportStatus1: boolean = false;
  reportStatus2: boolean = false;
  reportStatus3: boolean = false;
  reportStatus4: boolean = false;
  reportStatus5: boolean = false;
  reportStatus6: boolean = false;
  reportStatus7: boolean = false;
  reportStatus8: boolean = false;
  reportStatus9: boolean = false;

  reportName1: string = "Branch Wise Sales Summary";
  reportName2: string = "Branch Wise Discount Report";
  reportName3: string = "Sales Executive Wise Summary Report";
  reportName4: string = "Sales Executive Wise Detailed Report";
  reportName5: string = "Model Wise Summary";
  reportName6: string = "Sales Executive Wse Model Wise Summary Report";
  reportName7: string = "Model ise Daily Detailed Report";
  reportName8: string = "Fuel Type Wise Branch Wise Summary Report";
  reportName9: string = "Transmission Type Wise Branch Wise Summary Report";

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getBranches();
    this.getSalesExecutives();
    this.getModels();
    this.getBranchWiseSalesSummary();
    this.getBranchWiseDiscountReport();
    this.getSalesExecutiveWiseSummaryReport();
    this.getSalesExecutiveWiseDetailedReport();
    this.getModelWiseSummary();
    this.getSalesExecutiveWiseModelWiseSummaryReport();
    this.getModelwiseDailyDetailedReport();
    this.getFuelTypeWiseBranchWiseSummaryReport();
    this.getTransmissionTypeWiseBranchWiseSummaryReport();
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  models = [];

  getModels() {
    this.models = [];
    this.varients = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  varients = [];

  getVarients(modelID) {
    this.varients = [];
    this.VARIENT_ID = [];

    this.api
      .getAllVarients(
        0,
        0,
        "NAME",
        "asc",
        " AND MODEL_ID IN (" + modelID + ") AND STATUS=1"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.varients = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  salesExecutives = [];

  getSalesExecutives() {
    this.salesExecutives = [];

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" +
        this.orgId +
        " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
        this.cookie.get("orgId") +
        " AND ROLE_ID=28)"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.salesExecutives = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.DATE[0] = currentDate;
    this.DATE[1] = currentDate;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    if (this.DATE.length > 0) this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getBranchWiseSalesSummary(false);
    this.getBranchWiseDiscountReport(false);
    this.getSalesExecutiveWiseSummaryReport(false);
    this.getSalesExecutiveWiseDetailedReport(false);
    this.getModelWiseSummary(false);
    this.getSalesExecutiveWiseModelWiseSummaryReport(false);
    this.getModelwiseDailyDetailedReport(false);
    this.getFuelTypeWiseBranchWiseSummaryReport(false);
    this.getTransmissionTypeWiseBranchWiseSummaryReport(false);
  }

  clearFilter() {
    this.DATE = [];
    this.BRANCH_ID = [];
    this.SALES_EXECUTIVE_ID = [];
    this.MODEL_ID = [];
    this.VARIENT_ID = [];
    this.FUEL_TYPE = [];
    this.TRANSMISSION = [];
    this.setDateToDateFilter();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  // TABLE 1
  activePanel1(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.branchWiseSalesPageIndex = 1;
      this.branchWiseSalesPageSize = 10;
      this.getBranchWiseSalesSummary(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  branchWiseSalesPageIndex = 1;
  branchWiseSalesPageSize = 10;
  branchWiseSalesTotalRecords = 15;
  branchWiseSalesSortValue: string = "desc";
  branchWiseSalesSortKey: string = "VQM.ID";
  branchWiseSalesData = [];

  columns1: string[][] = [
    ["BRANCH_NAME", "Branch"],
    ["QUOTATION_COUNT", "Total Quotation"],
    ["DISCOUNT_COUNT", "Total Discount Applied"],
    ["APPROVED_DISCOUNTS", "Total Discount Approved"],
    ["REJECTED_DISCOUNTS", "Total Discount Rejected"],
  ];

  getBranchWiseSalesSummary(reset: boolean = false) {
    if (reset) {
      this.branchWiseSalesPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.branchWiseSalesSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getBranchWisesalesSummary1(
        this.branchWiseSalesPageIndex,
        this.branchWiseSalesPageSize,
        this.branchWiseSalesSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.branchWiseSalesTotalRecords = data["count"];
            this.branchWiseSalesData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 2
  activePanel2(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.branchWiseDiscountPageIndex = 1;
      this.branchWiseDiscountPageSize = 10;
      this.getBranchWiseDiscountReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }
  branchWiseDiscountPageIndex = 1;
  branchWiseDiscountPageSize = 10;
  branchWiseDiscountTotalRecords = 1;
  branchWiseDiscountSortValue: string = "desc";
  branchWiseDiscountSortKey: string = "ID";
  branchWiseDiscountData = [];

  columns2: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["REQUESTED_DISCOUNT_AMOUNT", "Total Discount Requested"],
    ["APPROVED_DISCOUNT_AMOUNT", "Total Discount Approved"],
    ["REJECTED_DISCOUNT_AMOUNT", "Total Discount Rejected"],
  ];

  getBranchWiseDiscountReport(reset: boolean = false) {
    if (reset) {
      this.branchWiseDiscountPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.branchWiseDiscountSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getBranchWiseDiscountReport1(
        this.branchWiseDiscountPageIndex,
        this.branchWiseDiscountPageSize,
        this.branchWiseDiscountSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.branchWiseDiscountTotalRecords = data["count"];
            this.branchWiseDiscountData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 3
  activePanel3(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.SalesExecutiveWiseSummaryReportPageIndex = 1;
      this.SalesExecutiveWiseSummaryReportPageSize = 10;
      this.getSalesExecutiveWiseSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  SalesExecutiveWiseSummaryReportPageIndex = 1;
  SalesExecutiveWiseSummaryReportPageSize = 10;
  SalesExecutiveWiseSummaryReportTotalRecords = 1;
  SalesExecutiveWiseSummaryReportSortValue: string = "desc";
  SalesExecutiveWiseSummaryReportSortKey: string = "ID";
  SalesExecutiveWiseSummaryReport = [];

  columns3: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["SALES_EXECUTIVE_DESIGNATION_NAME", "Sales Executive Designation Name"],
    ["QUOTATIONS", "Total Quotation"],
  ];

  getSalesExecutiveWiseSummaryReport(reset: boolean = false) {
    if (reset) {
      this.SalesExecutiveWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.SalesExecutiveWiseSummaryReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getSalesExecutiveWiseSummaryReport1(
        this.SalesExecutiveWiseSummaryReportPageIndex,
        this.SalesExecutiveWiseSummaryReportPageSize,
        this.SalesExecutiveWiseSummaryReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.SalesExecutiveWiseSummaryReportTotalRecords = data["count"];
            this.SalesExecutiveWiseSummaryReport = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 4
  activePanel4(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.SalesExecutiveWiseDetailedReportPageIndex = 1;
      this.SalesExecutiveWiseDetailedReportPageSize = 10;
      this.getSalesExecutiveWiseDetailedReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  SalesExecutiveWiseDetailedReportPageIndex = 1;
  SalesExecutiveWiseDetailedReportPageSize = 10;
  SalesExecutiveWiseDetailedReportTotalRecords = 1;
  SalesExecutiveWiseDetailedReportSortValue: string = "desc";
  SalesExecutiveWiseDetailedReportSortKey: string = "VQM.ID";
  SalesExecutiveWiseDetailedReport = [];

  columns4: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["SALES_EXECUTIVE_DESIGNATION_NAME", "Sales Executive Designation Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["DATE", "Date Of Quotation"],
    ["DISCOUNT_GIVEN", "Discount Given"],
    ["DISCOUNT", "Discount Amount"],
  ];

  // columnsDG: string[][] = [["DISCOUNT", "Discount Given"]];

  // columnsDA: string[][] = [["DISCOUNT", "Discount Amount"]];

  getSalesExecutiveWiseDetailedReport(reset: boolean = false) {
    if (reset) {
      this.SalesExecutiveWiseDetailedReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.SalesExecutiveWiseDetailedReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getSalesExecutiveWiseDetailedReport1(
        this.SalesExecutiveWiseDetailedReportPageIndex,
        this.SalesExecutiveWiseDetailedReportPageSize,
        this.SalesExecutiveWiseDetailedReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.SalesExecutiveWiseDetailedReportTotalRecords = data["count"];
            this.SalesExecutiveWiseDetailedReport = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 5
  activePanel5(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.ModelWiseSummaryPageIndex = 1;
      this.ModelWiseSummaryPageSize = 10;
      this.getModelWiseSummary(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  ModelWiseSummaryPageIndex = 1;
  ModelWiseSummaryPageSize = 10;
  ModelWiseSummaryTotalRecords = 1;
  ModelWiseSummarySortValue: string = "desc";
  ModelWiseSummarySortKey: string = "ID";
  ModelWiseSummary = [];

  columns5: string[][] = [
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["QUOTATION_COUNT", "Total Quotation Generated"],
  ];

  getModelWiseSummary(reset: boolean = false) {
    if (reset) {
      this.ModelWiseSummaryPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.ModelWiseSummarySortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getModelWiseSummary1(
        this.ModelWiseSummaryPageIndex,
        this.ModelWiseSummaryPageSize,
        this.ModelWiseSummarySortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.ModelWiseSummaryTotalRecords = data["count"];
            this.ModelWiseSummary = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 6
  activePanel6(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.SalesExecutiveWiseModelWiseSummaryReportPageIndex = 1;
      this.SalesExecutiveWiseModelWiseSummaryReportPageSize = 10;
      this.getSalesExecutiveWiseModelWiseSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  SalesExecutiveWiseModelWiseSummaryReportPageIndex = 1;
  SalesExecutiveWiseModelWiseSummaryReportPageSize = 10;
  SalesExecutiveWiseModelWiseSummaryReportTotalRecords = 1;
  SalesExecutiveWiseModelWiseSummaryReportSortValue: string = "desc";
  SalesExecutiveWiseModelWiseSummaryReportSortKey: string = "ID";
  SalesExecutiveWiseModelWiseSummaryReport = [];

  columns6: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["SALES_EXECUTIVE_DESIGNATION_NAME", "Sales Executive Designation Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["QUOTATION_COUNT", "Total Quotation Count"],
  ];

  getSalesExecutiveWiseModelWiseSummaryReport(reset: boolean = false) {
    if (reset) {
      this.SalesExecutiveWiseModelWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.SalesExecutiveWiseModelWiseSummaryReportSortValue.startsWith(
        "a"
      )
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getSalesExecutiveWiseModelWiseSummaryReport1(
        this.SalesExecutiveWiseModelWiseSummaryReportPageIndex,
        this.SalesExecutiveWiseModelWiseSummaryReportPageSize,
        this.SalesExecutiveWiseModelWiseSummaryReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.SalesExecutiveWiseModelWiseSummaryReportTotalRecords =
              data["count"];
            this.SalesExecutiveWiseModelWiseSummaryReport = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 7
  activePanel7(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.ModelwiseDailyDetailedReportPageIndex = 1;
      this.ModelwiseDailyDetailedReportPageSize = 10;
      this.getModelwiseDailyDetailedReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  ModelwiseDailyDetailedReportPageIndex = 1;
  ModelwiseDailyDetailedReportPageSize = 10;
  ModelwiseDailyDetailedReportTotalRecords = 1;
  ModelwiseDailyDetailedReportSortValue: string = "desc";
  ModelwiseDailyDetailedReportSortKey: string = "ID";
  ModelwiseDailyDetailedReport = [];

  columns7: string[][] = [
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["SALES_EXECUTIVE_BRANCH_NAME", "Branch Name"],
    ["DATE", "Date Of Quotation"],
  ];

  getModelwiseDailyDetailedReport(reset: boolean = false) {
    if (reset) {
      this.ModelwiseDailyDetailedReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.ModelwiseDailyDetailedReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getModelwiseDailyDetailedReport1(
        this.ModelwiseDailyDetailedReportPageIndex,
        this.ModelwiseDailyDetailedReportPageSize,
        this.ModelwiseDailyDetailedReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.ModelwiseDailyDetailedReportTotalRecords = data["count"];
            this.ModelwiseDailyDetailedReport = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 8
  activePanel8(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.FuelTypeWiseBranchWiseSummaryReportPageIndex = 1;
      this.FuelTypeWiseBranchWiseSummaryReportPageSize = 10;
      this.getFuelTypeWiseBranchWiseSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  FuelTypeWiseBranchWiseSummaryReportPageIndex = 1;
  FuelTypeWiseBranchWiseSummaryReportPageSize = 10;
  FuelTypeWiseBranchWiseSummaryReportTotalRecords = 1;
  FuelTypeWiseBranchWiseSummaryReportSortValue: string = "desc";
  FuelTypeWiseBranchWiseSummaryReportSortKey: string = "ID";
  FuelTypeWiseBranchWiseSummaryReport = [];

  columns8: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["FUEL_TYPE", "Fuel Type"],
    ["QUOTATION_COUNT", "Total Quotation Count"],
  ];

  getFuelTypeWiseBranchWiseSummaryReport(reset: boolean = false) {
    if (reset) {
      this.FuelTypeWiseBranchWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.FuelTypeWiseBranchWiseSummaryReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getFuelTypeWiseBranchWiseSummaryReport(
        this.FuelTypeWiseBranchWiseSummaryReportPageIndex,
        this.FuelTypeWiseBranchWiseSummaryReportPageSize,
        this.FuelTypeWiseBranchWiseSummaryReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.FuelTypeWiseBranchWiseSummaryReportTotalRecords =
              data["count"];
            this.FuelTypeWiseBranchWiseSummaryReport = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 9
  activePanel9(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.TransmissionTypeWiseBranchWiseSummaryReportPageIndex = 1;
      this.TransmissionTypeWiseBranchWiseSummaryReportPageSize = 10;
      this.getTransmissionTypeWiseBranchWiseSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  TransmissionTypeWiseBranchWiseSummaryReportPageIndex = 1;
  TransmissionTypeWiseBranchWiseSummaryReportPageSize = 10;
  TransmissionTypeWiseBranchWiseSummaryReportTotalRecords = 1;
  TransmissionTypeWiseBranchWiseSummaryReportSortValue: string = "desc";
  TransmissionTypeWiseBranchWiseSummaryReportSortKey: string = "ID";
  TransmissionTypeWiseBranchWiseSummaryReport = [];

  columns9: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["TRANSMISSION", "Sales Executive Designation Name"],
    ["QUOTATION_COUNT", "Total Quotation Count"],
  ];

  getTransmissionTypeWiseBranchWiseSummaryReport(reset: boolean = false) {
    if (reset) {
      this.TransmissionTypeWiseBranchWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort =
        this.TransmissionTypeWiseBranchWiseSummaryReportSortValue.startsWith(
          "a"
        )
          ? "asc"
          : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var fuelFilter = "";
    if (this.FUEL_TYPE != undefined && this.FUEL_TYPE.length != 0) {
      fuelFilter = this.FUEL_TYPE.toString();
    }

    var transmissionFilter = "";
    if (this.TRANSMISSION != undefined && this.TRANSMISSION.length != 0) {
      transmissionFilter = this.TRANSMISSION.toString();
    }

    this.loadingRecords = true;
    this.api
      .getTransmissionTypeWiseBranchWiseSummaryReport(
        this.TransmissionTypeWiseBranchWiseSummaryReportPageIndex,
        this.TransmissionTypeWiseBranchWiseSummaryReportPageSize,
        this.TransmissionTypeWiseBranchWiseSummaryReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        fromDateFilter,
        toDateFilter,
        modelFilter,
        varientFilter,
        salesExecutiveFilter,
        fuelFilter,
        transmissionFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.TransmissionTypeWiseBranchWiseSummaryReportTotalRecords =
              data["count"];
            this.TransmissionTypeWiseBranchWiseSummaryReport = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  sort(sort: { key: string; value: string }): void {
    this.branchWiseSalesSortKey = sort.key;
    this.branchWiseSalesSortValue = sort.value;
    this.getBranchWiseSalesSummary(true);

    this.branchWiseDiscountSortKey = sort.key;
    this.branchWiseDiscountSortValue = sort.value;
    this.getBranchWiseDiscountReport(true);

    this.SalesExecutiveWiseSummaryReportSortKey = sort.key;
    this.SalesExecutiveWiseSummaryReportSortValue = sort.value;
    this.getSalesExecutiveWiseSummaryReport(true);

    this.SalesExecutiveWiseDetailedReportSortKey = sort.key;
    this.SalesExecutiveWiseDetailedReportSortValue = sort.value;
    this.getSalesExecutiveWiseDetailedReport(true);

    this.ModelWiseSummarySortKey = sort.key;
    this.ModelWiseSummarySortValue = sort.value;
    this.getModelWiseSummary(true);

    this.SalesExecutiveWiseModelWiseSummaryReportSortKey = sort.key;
    this.SalesExecutiveWiseModelWiseSummaryReportSortValue = sort.value;
    this.getSalesExecutiveWiseModelWiseSummaryReport(true);

    this.ModelwiseDailyDetailedReportSortKey = sort.key;
    this.ModelwiseDailyDetailedReportSortValue = sort.value;
    this.getModelwiseDailyDetailedReport(true);

    this.FuelTypeWiseBranchWiseSummaryReportSortKey = sort.key;
    this.FuelTypeWiseBranchWiseSummaryReportSortValue = sort.value;
    this.getFuelTypeWiseBranchWiseSummaryReport(true);
  }
}
