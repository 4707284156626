import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-sales-executive-wise-model-wise-generated-report',
  templateUrl: './sales-executive-wise-model-wise-generated-report.component.html',
  styleUrls: ['./sales-executive-wise-model-wise-generated-report.component.css']
})
export class SalesExecutiveWiseModelWiseGeneratedReportComponent implements OnInit {

  formTitle = "Sales Executive Wise Model Wise Memo Generated Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'SalesExecutiveWiseModelWiseGeneratedReport.xlsx';
  dataList = [];
  startValue:any=new Date();
  endValue:any=new Date();
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";

  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"], ["BRANCH_NAME", "Branch Name"],
  ["ALCAZAR_", "Alcazar"], ["Aura", "Aura"], ["Creta", "Creta"], ["I20", "I20"], ["I20_N_Line", "I20_N_Line"], ["New_Tucson", "New Tuscon"], ["New_Venue", "New Venue"], ["Santro", "Santro"], ["Venue_N_Line_", "Venue_N_Line"], ["Verna", "Verna"], ["Test_16", "Test16"], ["TOTAL_QUOTATION_GENERATED", "Total Memo Geneated"]]

  isSpinning = false
  filterClass: string = "filter-visible";


  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];

  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  departments = []
  supportusers = []
  SALES_EXECUTIVE_ID: any = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.search();
    this.getAllbranches()
    this.getSales()
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  BRANCH_ID1 = []
  BRANCH_ID :any = []

  SELECT_ALL: boolean = true;

  onSelectAllChecked(event: any) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  exportexcel(): void {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  clearFilter() {

    this.filterQuery = ""
    this.selectedDate = null;
    this.value1 = ''
    this.value2 = ''
    this.filterClass = "filter-invisible";
    this.applyFilter()
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      // this.sortValue = "desc"
      // this.dataList = []
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery

      this.api.getSalesExewise(this.pageIndex, this.pageSize, this.sortKey, sort,this.filterQuery, this.startValue, this.endValue, this.BRANCH_ID,this.SALES_EXECUTIVE_ID).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      });
    }
    else {
      this.applyFilter()
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.filterQuery = '';
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');

    // if (this.startValue != null && this.endValue != null) {
    //   this.filterQuery = " AND FROM_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

    //   var filter = '';
    //   filter = this.filterQuery;
    //   var likeQuery = "";
    // }


    this.api.getSalesExewise(this.pageIndex, this.pageSize, this.sortKey, sort,this.filterQuery, this.startValue, this.endValue, this.BRANCH_ID,this.SALES_EXECUTIVE_ID).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.isSpinning = false
      this.isFilterApplied = "primary";
      this.filterClass = "filter-invisible";
    }, err => {
      console.log(err);
    });
  }


 branch: any = []
  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      let ids = [];
      if (this.SELECT_BRANCH == true) {
        for (var i = 0; i < this.branch.length; i++) {
          ids.push(this.branch[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.BRANCH_ID = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_BRANCH: boolean = true;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }

  onSelectOffBranch(event: any) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }


  sales = [];
  getSales() {
    this.api.getAllEmpRoleMap(0, 0,'','', ' AND ROLE_ID=28')
      .subscribe(data => {
        this.sales = data['data']
        let ids = [];

        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids = [];
        }

        this.SALES_EXECUTIVE_ID = ids;
        this.search();
      },
        (err) => {
          console.log(err);
        }
      );
  }


}
