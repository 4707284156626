import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';


@Component({
  selector: 'app-monthly-leavereport',
  templateUrl: './monthly-leavereport.component.html',
  styleUrls: ['./monthly-leavereport.component.css']
})
export class MonthlyLeavereportComponent implements OnInit {
  formTitle = "Monthly Leave Report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'MonthlyLeave.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [['STATUS', "Status"],["EMPLOYEE_NAME", "Employee Name"],["TYPE_NAME", "Leave Type"],["NO_OF_DAYS", "No Of Days"],["FROM_DATE", "From Date"],["TO_DATE", "To Date"],["REASON", "Reason"],
  ["APPROVER_NAME", "Approval Name"],["APPROVED_DATETIME", "Approval Time"],["REMARK", "Head Remark"] ]
  columns1: string[][] = [['STATUS', "Status"],["EMPLOYEE_NAME", "Employee Name"],["TYPE_NAME", "Leave Type"],["REASON", "Reason"],
  ["APPROVER_NAME", "Approval Name"],["REMARK", "Head Remark"] ]
  // ["APPROVED_DATETIME", "Approval Time"]["FROM_DATE", "From Date"],["TO_DATE", "To Date"],
  BRANCH = [];
  SalesExecutive = [];
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  leaves = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  FROM_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  // startOpen = false;
  employees = [];
  TYPE_ID=[];
  APPROVAR_ID= [];
  // MONTH='';
  // YEAR=''
  // LIKE_STRING='';
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM";

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.getLeave();
    this.getEmployee();
  //  this.search(true);
   console.log(this.TYPE_ID)
  }

  // this.api.getAllLeaveType(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND ORGANIZATION_ID =' + this.orgId).subscribe(data => {
  //   if (data['code'] == 200) {
  //     this.loadingRecords = false;
  //     this.totalRecords = data['count'];
  //     this.dataList = data['data'];
  //   }
  
  // }, err => {
  //   if (err['ok'] == false)
  //     this.message.error("Server Not Found", "");
  // });

  
branchname:any



// getLeave() {
//   this.leaves = [];
//   this.api.getLeaveData(0, 0, this.sortKey,this.sortValue, "",).subscribe(data1 => {
   
//     this.leaves = data1['data'];
//     if (this.leaves.length > 0) {
//         this.TYPE_ID =[data1['data'][0]['ID']] ;
//         console.log('leavetype', this.branchname);
//         // this.applyFilter()
//         console.log(this.leaves);
//         this.branchname = data1['data'][0]['NAME'];
//         this.search(true)
//       }
//     });
// }


getLeave() {
  this.api.getLeaveData(0, 0, 'ID', 'desc', '').subscribe(data => {
    if (data['code'] == 200) {
      this.leaves = data['data'];
      console.log(this.leaves);
      this.onSelectAllChecked(true);
      this.search(true)
    }
  }, err => {
    console.log(err);
  });
}
getEmployee() {
    this.employees = [];
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.employees = data1['data'];
      // this.onSelectAllSelesExicutive(true)
    },
      (err) => {
        console.log(err);
      }
    );
  }

onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
}

  keyup(event: any) {
    this.search();
  }

  dates: any = [];
  today2 = new Date();

  // startDateChange() {
  //   var startDate = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd');
  //   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  //   console.log(this.getDaysArray(startDate, endDate));
  //   console.log(this.dates);
  // }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
}

  // onFromDateChange(fromDate: any) {
  //   if (fromDate == null)
  //     this.date2 = new Date();

  //   else
  //     this.date2 = new Date(fromDate);
  // }

  // disabledStartDate = (FROM_DATE: Date): boolean => {
  //   if (!FROM_DATE || !this.TO_DATE) {
  //     return false;
  //   }
  //   return FROM_DATE.getTime() > this.TO_DATE;
  // };
  current = new Date();


  // disabledEndDate = (selected: Date): boolean =>
  //   // Can not select days before today and today
  //   differenceInCalendarDays(selected, this.current) < 0;


  // disabledEndDate = (TO_DATE: Date): boolean => {
  //   if (!TO_DATE || !this.FROM_DATE) {
  //     return true;
  //   }
  //   return TO_DATE.getTime() < this.FROM_DATE;
  // };

  onStartChange(date: Date): void {
    this.FROM_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void { 
    if (!open) {
      this.endOpen = true;
    }
  }
   
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {

  if ((this.MONTH1 != undefined) ||  (this.TYPE_ID!= undefined)||  (this.APPROVAR_ID.length > 0) )
  this.isFilterApplied = "primary";

  else
  this.isFilterApplied = "default";

  this.search(true);
  this.filterClass = "filter-invisible";
  
//     var sort: string;
//     try {
//       sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
//     } catch (error) {
//       sort = '';
//     }
 

//     this.filterQuery = '';
//     this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd');
//     this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd');
//     this.MONTH = this.datePipe.transform(this.MONTH1, 'yyyy-MM-dd');

// if (this.TYPE_ID != undefined || this.TYPE_ID > 0 ||this.APPROVAR_ID != null) {
//       this.filterQuery = " AND TYPE_ID  IN (" + "" +this.TYPE_ID + ") AND APPROVER_ID IN ( "+ "" + this.APPROVAR_ID + ")";

//       var filter = '';
//       filter = this.filterQuery;
//       var likeQuery = "";
// }

  // if (this.FROM_DATE != null && this.TO_DATE != null) {
  //   this.filterQuery = this.filterQuery+" AND FROM_DATE BETWEEN '" + this.FROM_DATE + "' AND '"+ this.FROM_DATE + "' AND TO_DATE BETWEEN '" + this.TO_DATE + "' AND '" +this.TO_DATE +  "' "
  // }
  // if (this.MONTH != undefined) {
  //   this.filterQuery = this.filterQuery+" AND  MONTH ('" + this.MONTH + "')"
  // }

 
    // if (this.APPROVAR_ID != null) {
    //   this.filterQuery = this.filterQuery + ' AND APPROVER_ID=' + "" + this.APPROVAR_ID + "";
    // }

//     if (this.MONTH1 != undefined) {
//       this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
//     }
//     if (this.MONTH1 != undefined) {
//       this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
//     }
//     var likeQuery = '';

// if (this.searchText != '') {
//       likeQuery = ' AND';
//       this.columns.forEach((column) => {
//         likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
//       });
//       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
// }

// this.api.monthlyLeaveReport(1, this.pageSize, this.sortKey,sort, this.filterQuery,this.MONTH, this.YEAR).subscribe((data) => {
//         console.log(data);
//         this.loadingRecords = false;
//         this.isFilterApplied = 'primary';
//         this.totalRecords = data['count'];
//         this.dataList = data['data'];
//         this.isSpinning = false;
//         this.filterClass = 'filter-invisible';
// },
//         (err) => {
//           console.log(err);
//         });
//   console.log(this.filterQuery);

}

getNameOfFinalStatus(STATUS) {
    if (STATUS == "P")
      return "Pending";
    else if (STATUS == "A")
      return "Approved";
    else if (STATUS == "R")
      return "Rejected";
    else if(STATUS == "HR")
      return "HR Approved"
    else if(STATUS=="HA")
      return "Head Approved"
    else if(STATUS=="D")
     return "Deleted"
}

setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
}

supportAgentWiseDeptArray = [];

exportLoading: boolean = false;

importInExcel() {
    this.search(true, true);
}

 dataList2=[]
search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery =  " AND (";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+ ")";
    }
   
    // var likeQuery = "";
    // if (this.searchText != "" ) {
    //   likeQuery = " AND ";

    //   likeQuery += " STATUS like '%" + this.searchText + "%' OR EMPLOYEE_NAME like '%" + this.searchText + "%' OR TYPE_NAME like '%" + this.searchText + "%' OR FROM_DATE like '%" + this.searchText + "%' OR TO_DATE like '%" + this.searchText + "%' OR REASON like '%" + this.searchText + "%' OR APPROVER_NAME like '%" + this.searchText + "%' OR APPROVED_DATETIME like '%" + this.searchText + "%' OR REMARK like '%" + this.searchText + "%' ";
      

    //   likeQuery += likeQuery.substring(0, likeQuery.length -1) + "";
    // }

    this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd');
    // var dateFilterStr = "";
    // if (this.FROM_DATE != null && this.TO_DATE != null) {
    //   dateFilterStr = " AND FROM_DATE BETWEEN '" + this.FROM_DATE + "' AND '"+ this.FROM_DATE + "' AND TO_DATE BETWEEN '" + this.TO_DATE + "' AND '" +this.TO_DATE +  "' "
    // }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }
    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }
    
    var modelFilterStr = "";
    if(this.TYPE_ID.length > 0){
      modelFilterStr = " AND TYPE_ID IN (" + this.TYPE_ID + ")";
    }
  
    var approverFilterStr = "";
    if (this.APPROVAR_ID.length > 0) {
      approverFilterStr = " AND APPROVER_ID IN (" + this.APPROVAR_ID + ")";
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.monthlyLeaveReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery+modelFilterStr+approverFilterStr,this.MONTH, this.YEAR).subscribe(data => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        },err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.monthlyLeaveReport(0,0, this.sortKey, this.sortValue, this.filterQuery + likeQuery+modelFilterStr+approverFilterStr,this.MONTH, this.YEAR).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
       });
    }
 }

  SELECT_ALL: boolean = false;
  
  onSelectAllChecked(event) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.leaves.length; i++) {
        ids.push(this.leaves[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.TYPE_ID = ids;
  }

  // onSelectAllBank(event: any) {
  //   this.SELECT_BANK = event
  //   console.log(event, "Event")
  //   let ids = [];
  //   if (this.SELECT_BANK == true) {
  //     for (var i = 0; i < this.bank.length; i++) {
  //       ids.push(this.bank[i]["NAME"]);
  //     }
  //   } else {
  //     ids = [];
  //   }
  //   this.BANK_NAME = ids
  // }

  onSelectOff(event) {
    var a = this.leaves.length;
    var b = this.leaves.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.TYPE_ID = event;
    if (this.TYPE_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  APPROVAR:boolean=false
  onSelectAllSelesExicutive(event: any) {
    this.APPROVAR = event
    console.log(event, "Event")
    let ids = [];
    if (this.APPROVAR == true) {
      for (var i = 0; i < this.employees.length; i++) {
        ids.push(this.employees[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.APPROVAR_ID = ids
  }


  onSelectOffOne(event) {
    var a = this.employees.length;
    var b = this.employees.length - event.length;
    if ((a! = b)) {
      this.APPROVAR = false;
    } else {
      this.APPROVAR = true;
    }
    this.APPROVAR_ID = event;
    if (this.APPROVAR_ID.length == 0) {
      this.APPROVAR = false;
    }
  }

sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
}

clearFilter() {
  this.FROM_DATE = new Date();
  this.TO_DATE = new Date();
  this.filterQuery = '';
  this.dataList = [];
  this.SELECT_ALL=false;
  this.APPROVAR=false;
  this.TYPE_ID = [];
  this.MONTH1 = new Date();
  this.MONTH = this.month;
  this.YEAR = this.year;
  this.APPROVAR_ID = [];
  this.search(true);
  this.isFilterApplied = "default";
  this.filterClass = "filter-invisible";
}

convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Status'] = this.getNameOfFinalStatus(this.dataList2[i]['STATUS']);
      obj1['Employee Name'] = this.dataList2[i]['EMPLOYEE_NAME'];
      obj1['Leave Type'] = this.dataList2[i]['TYPE_NAME'];
      obj1['No Of Days'] = this.dataList2[i]['NO_OF_DAYS'];
      obj1['From Date'] = this.dataList2[i]['FROM_DATE'];
      obj1['To Date'] = this.dataList2[i]['TO_DATE'];
      obj1['Reason'] = this.dataList2[i]['REASON'];
      obj1['Approval Name'] = this.dataList2[i]['APPROVER_NAME'];
      obj1['Approval Date & Time'] = this.dataList2[i]['APPROVED_DATETIME'];
      obj1['Head Remark'] = this.dataList2[i]['REMARK'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Monthly Leave Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
   }
}

}

