import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { areaMaster } from 'src/app/Models/supportAreaMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.css']
})
export class AreaFormComponent implements OnInit {
  
  @Input() drawerClose: Function;
  @Input() data: areaMaster;
  @Input() listOfData2: any = []

  constructor(private message: NzNotificationService, private api: ApiService, private datepipe: DatePipe) { }
  isSpinning = false;

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  date = null;
  isOk = false;
  listOfData: any = []


  ngOnInit() {
  }

  getData() {
    this.api.getAreaMaster(0, 0, '', '', 'AND STATUS=1' ).subscribe((data) => {
      this.listOfData = data['data'];
      console.log('this.listOfData', this.listOfData);
      // this.listOfOptions1 = data['data'];
      // console.log(this.listOfOptions1,"hhghh");
    })
  }

  onSubmit(addNew: boolean, httpForm: NgForm) {

    this.isOk = true;
    if (this.data.NAME == null || this.data.NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Name.', "")
    }
    else if (this.data.SHORT_CODE == null || this.data.SHORT_CODE.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Short Code.', "")
    }
    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Sequence No.', "")
    }  


    if (this.isOk) {
      if (this.data.ID) {        

        this.isSpinning = false;

        var bundleData = this.listOfData2.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });


        if (bundleData.length == 0) {
          this.api.updateAreaMaster(this.data).subscribe((successCode: any) => {
            if (successCode['code'] == 200) {
              this.getData();
              // this.isSpinning = true;
              this.message.success("User information Updated successfully...", "");
              console.log(this.data, 'Update Success')
              if (!addNew) {
                this.drawerClose();
                this.reset(httpForm);
                this.isSpinning = false;
              }
              else {
                this.data = new areaMaster();
                this.message.error("Failed To Update", "");
                console.log(this.data, "fail Update")

                this.isSpinning = false;
              }
              // console.log(this.data.APPLICABLE_TYPES);
            }

          });
        }

        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }
      else {
        this.isSpinning = true;

        var bundleData = this.listOfData.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });

        if (bundleData.length == 0) {

          console.log(this.listOfData, 'Code')

          this.api.createAreaMaster(this.data)
            .subscribe(successCode => {

              if (successCode['code'] == 200) {
                this.message.success("User information added successfully...", "");
                console.log(this.data, "create Success");
                this.getData();
                if (!addNew) {
                  // this.isSpinning = true;
                  this.drawerClose();

                }
                else {
                  this.data = new areaMaster();
                  httpForm.resetForm();
                  
                }
                this.isSpinning = false;
              }
              else {
                this.message.error("Failed to add user information...", "");
                console.log(this.data, "Fail data")

                this.isSpinning = false;
              }
            });

        }

        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }


    }
  }

  close(httpForm: NgForm) {
    // this.isSpinning=true;
    this.reset(httpForm);
    this.drawerClose();
  }

  reset(httpForm: NgForm) {
    // this.isSpinning = true;
    httpForm.reset();
  }
}
