import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

// import * as html2pdf from 'html2pdf.js';
import { NzNotificationService } from 'ng-zorro-antd';
// import { ApiService } from '../Service/api.service';
import { ApiService } from 'src/app/Service/api.service';
// import { memoMaster } from '../Models/memo';
// import { advanceMaster } from '../Models/advanceMaster';
// import { memoDetails } from '../Models/memoDetails';
// import { questionaryMaster } from '../Models/questionaryMaster';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { memoSignatureDetails } from '../Models/memoSignatureDetails';
import { memoMaster } from 'src/app/Models/memo';
import { advanceMaster } from 'src/app/Models/advanceMaster';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { memoDetails } from 'src/app/Models/memoDetails';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
// import html2pdf from 'html2pdf.js';


@Component({
  selector: 'app-memoreport-form',
  templateUrl: './memoreport-form.component.html',
  styleUrls: ['./memoreport-form.component.css']
})
export class MemoreportFormComponent implements OnInit {

  @Input() drawerClose1: Function;
  // @Input() drawerClose12:Function;
  @Input() search: Function;
  @Input() search1: Function;
  @Input() formname: String;
  @Input() data: memoMaster = new memoMaster()
  @Input() dataid: any
  @Input() memoDetailsArray = new memoDetails()
  @Input() questionary = new questionaryMaster()
  @Input() advanceArray = [new advanceMaster()]
  @Input() memoSignature = new memoSignatureDetails()
  @Input() quotedPrice: any
  @Input() sumofall: number
  @Input() sum3: number
  @Input() sum4: number
  @Input() sum5: number
  @Input() sum6: number
  @Input() sum7: number
  @Input() sum8: number
  @Input() sum9: number
  @Input() sumoffall2: number
  @Input() sdfsdf5: number
  @Input() sdfsdf4: number
  @Input() acc_remark:any
  @Input() acc_remark1:any
  @Input() iid:number
  @Input() memo13= new memoMaster()
  @Input() memo12=new memoMaster()
  @Input() memo14=new memoMaster()
  @Input() memo15=new memoMaster()
  @Input() memo16=new memoMaster()
  saleHierarchy = []
  
  ACC_REMARK
  VIN_REMARK:string
  // acc_remark1
  roleId = Number(this.cookie.get('roleId'));

  advanceMaster = [new advanceMaster()]

  memo: memoMaster = new memoMaster()

  memoDetails: memoDetails = new memoDetails();
  imgUrl: any
  // questionary:questionary=new questionaryMaster()

  // memoDetailsArray=[new memoDetails]

  // advanceArray=[new advanceMaster]
  isApproveVisible11=false

  constructor(private domSanitizer: DomSanitizer, private cookie: CookieService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.getData();
    // this.F();this.
    this.imgUrl = this.api.retriveimgUrl
  }
  isSpinning = false;
  switchValue = false;
  switchValue1 = true;

  options: [] = [];

  // quotedPrice:any;

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  isVisible = false;
  date = null;
  isOk = false;


  onChange($event: string[]): void {
    console.log($event);
  }

  ID



  advanceArray1() {

    var array = []
    if (this.advanceArray != undefined) {

      for (let i = 0; i <= (this.advanceArray.length - 1); i++) {

        array.push(1)

        if (i + 1 == this.advanceArray.length - 1) {

          console.log(array, "ok");


          return array
        }


      }
    }


  }


  
  sanatizeUrl(generatedImageUrl): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(generatedImageUrl);
  }


  onPrint() {
    const printContents = document.getElementById('report_id').innerHTML;

    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload();
  }



 




  close() {
    // console.log(this.acc_remark);
    
    // this.isVisible=false;
   
    // this.sumofall = 0
    // this.sumoffall2 = 0
    // this.acc_remark=''
    this.drawerClose1();
    // this.getData(this.memoid);
  }

  save() {
    this.onPrint()
  }

  INVOICE_NO: string = "";
  emproledata=[]
  iddd:any
  tempData = new Object();
  userId = this.cookie.get('userId')
  isApproveModalConfirmLoading: boolean = false;
  isApproveModalConfirmLoading12: boolean = false;
  isRejectModalConfirmLoading: boolean = false;
  isRejectModalConfirmLoadingAS=false
  isApproveVisible: boolean = false;
  isRejectVisible: boolean = false;
  REMARK: string = "";
  REMARK_ASM:string=""

  setQuotationStatus(quotationID: number, status: string) {
    this.api.setQuotationStatus(quotationID, status).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Quotation Updated Successfully", "");

      } else {
        this.message.error("Quotation Updation Failed", "");
      }
    });
  }


  openApproveModal( ) {
    this.tempData = new Object();
    this.tempData = this.data;
    this.INVOICE_NO = "";
    this.isApproveVisible = true;
    this.get(this.iid)
    // this.drawerClose1()
    // this.search1()
  }
  openRejectModal( ) {
    this.tempData = new Object();
    this.tempData = this.data;
    this.REMARK = "";
    this.isRejectVisible = true;
    this.get(this.iid)
  }

  isRejectVisibleAS=false
  openRejectModalAS( ) {
    this.tempData = new Object();
    this.tempData =this.data;
    this.ACC_VERIFICATION_REMARK = "";
    this.isRejectVisibleAS = true;
    this.get(this.iid)
  }
  isRejectVisibleHP=false
  openRejectModalHP( ) {
    this.tempData = new Object();
    this.tempData =this.data;
    this.ACC_VERIFICATION_REMARK = "";
    this.isRejectVisibleHP = true;
    this.get(this.iid)
  }


  get(ID) {
    this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memoSignature = data['data'][0];
        console.log(this.memoSignature,"this.memoSignature");
        

        
      }
    });

  }
  handleApproveCancel() {
    this.isApproveVisible = false;
    this.isApproveModalConfirmLoading = false;
  }

  
  handleRejectCancelAS() {
    this.isRejectVisibleAS = false;
    this.isRejectModalConfirmLoadingAS = false;
  }
  isRejectModalConfirmLoadingHP:boolean
  handleRejectCancelHP() {
    this.isRejectVisibleHP = false;
    this.isRejectModalConfirmLoadingHP = false;
  }
  isRejectVisiblevin=false
  isRejectModalConfirmLoadingvin=false
  handleRejectCancelvin() {
    this.isRejectVisiblevin = false;
    this.isRejectModalConfirmLoadingvin = false;
  }

  handleRejectCancel() {
    this.isRejectVisible = false;
    this.isRejectModalConfirmLoading = false;
  }

  handleRejectOk() {
    let isOk = true;

    if (this.REMARK == undefined || this.REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["REMARK"] = this.REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.memoSignature.ACCOUNT_ID = this.userId

        this.memoSignature.ACCOUNT_DATETIME=this.datePipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd HH:mm:ss")
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
      
            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
          
          this.message.success("Remark Updated Successfully", "");
          this.isRejectVisible = false;
          // this.search(false);

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          // this.search(false);
        }
      });
    }
  }

  handleRejectOkvin() {
    let isOk = true;

    if (this.VIN_REMARK == undefined || this.VIN_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["REMARK"] = this.VIN_REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
      
            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
          
          this.message.success("Remark Updated Successfully", "");
          this.isRejectVisible = false;
          // this.search(false);

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          // this.search(false);
        }
      });
    }
  }
  ACCOUNT_DATETIME=new Date 
  handleApproveOk() {
    let isOk = true;

    if (this.INVOICE_NO == undefined || this.INVOICE_NO.toString() == '') {
      isOk = false;
      this.message.error('Please enter valid Invoice No.', '');
    }

    if (isOk) {
      this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND EMPLOYEE_ID=' + this.api.userId).subscribe(data => {
      
        this.emproledata = data['data'].filter((item:any) => item.ROLE_ID == 38);
        this.iddd= this.emproledata[0]['REPORTING_EMP_ID']
        console.log(this.iddd);
      });
      this.isApproveModalConfirmLoading = true;
      this.tempData["INVOICE_NO"] = this.INVOICE_NO;
      this.data.APPROVED_DATETIME = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
      this.tempData['APPROVED_DATETIME'] = this.data.APPROVED_DATETIME
      this.tempData["APPROVER_ID"] = this.iddd;
      this.tempData["STATUS"] = "AP";


      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isApproveModalConfirmLoading = false;
          this.memoSignature.ACCOUNT_DATETIME=this.datePipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd HH:mm:ss")
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
        
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
      
            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });

          this.message.success("Invoice No. Updated Successfully", "");
          this.isApproveVisible = false;
          this.drawerClose1();
        
          // this.search(false);



        } else {
          this.isApproveModalConfirmLoading = false;
          this.message.error("Invoice No. Updation Failed", "");
          // this.search(false);
        }
      });




    }
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  handleApproveCancel11() {
    this.isApproveVisible11 = false;
  }

  openApproveModal11(data: any) {
    this.tempData = new Object();
    this.tempData = this.data;
    this.INVOICE_NO = "";
    this.isApproveVisible11 = true;
    this.get(data.ID)
    
  }

  handleApproveOk11() {
    let isOk = true;

    if (this.ACC_REMARK == undefined || this.ACC_REMARK == '') {
      isOk = false;
      this.message.error('Please enter remark.', '');
    }

    if (isOk) {
      this.isApproveModalConfirmLoading = true;
      // this.tempData["INVOICE_NO"] = this.INVOICE_NO;
      // this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RQ";
      this.tempData["ACC_REMARK"]=this.ACC_REMARK
      this.tempData["IS_RSA_AVAILABLE"]=this.questionary['IS_RSA_AVAILABLE']
      // this.memoSignature

      this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isApproveModalConfirmLoading = false;
          // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
          this.memoSignature.ACCOUNT_ID = this.userId
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");
      
            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });

        
          this.isApproveVisible11 = false;
          this.drawerClose1()
          // this.search(false);



        } else {
          this.isApproveModalConfirmLoading = false;
          this.message.error("Invoice No. Updation Failed", "");
          // this.search(false);
        }
      });




    }
  }

  getsalesHierarchy() {
    this.api.getsalesHierarchy('AND EMPLOYEE_ID= ' + this.cookie.get('userId')).subscribe(successCode => {
      if (successCode['code'] == 200) {
        console.log(successCode['data'],"rutuja");
        this.saleHierarchy = successCode['data']
      }
    })
  }

  openApproveModal12(data: memoMaster) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisible12 = true;
     this.getsalesHierarchy()
    this.get12(data.ID)
    this.get(data.ID)
   
   
    
  }

  openApproveModalHP(data: memoMaster) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisibleHP = true;
     this.getsalesHierarchy()
    this.get12(data.ID)
    this.get(data.ID)
   
  }

  openApproveModalVIN(data: memoMaster) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.VINApproveVisible = true;
     this.getsalesHierarchy()
    this.get12(data.ID)
    this.get(data.ID)
   
  }
  openRejectModalVIN( ) {
    this.tempData = new Object();
    this.tempData =this.data;
    this.VIN_REMARK = "";
    this.isRejectVisiblevin = true;
    this.get(this.iid)
  }


  get12(ID){
    this.api.getMemoDetails1(this.pageIndex, this.pageSize, "", "", "AND MEMO_MASTER_ID=" + ID).subscribe(data => {
  
      if (data['code'] == 200 && data['count'] > 0) {
        // this.totalRecords = data['count'];
        this.memoDetailsArray = data['data'];
        console.log(this.memoDetailsArray);
  
   }
  
    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  APPROVER_ID
  userid = this.cookie.get('userId')
  ACC_VERIFICATION_REMARK:string=""
  isApproveVisible12: boolean = false;
  handleApproveOk12() {
    let isOk = true;
    if (this.APPROVER_ID == "" || this.APPROVER_ID==undefined) {
          this.message.error('Please select VIN', '')
         
      //   // }
    }
   

    else{
      if (isOk) {

      
        this.isApproveModalConfirmLoading12 = true;
        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"]=this.ACC_VERIFICATION_REMARK
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoading12 = false;
            // this.isApproveModalConfirmLoading = true;
           
           
            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;
  
            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"]=0
            this.tempData["MEMO_DETAILS"]=this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"]=this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"]=this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"]=this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"]=this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"]=this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"]=this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"]=this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"]=this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"]=this.questionary['VIN_NUMBER']
            this.tempData["GM_REMARK"]=""
            this.tempData["SH_REMARK"]=""
            this.tempData["REMARK"]=this.ACC_VERIFICATION_REMARK
            this.tempData["IS_RSA_AVAILABLE"]=this.questionary['IS_RSA_AVAILABLE']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.isApproveModalConfirmLoading = false;
                this.memoSignature.MEMO_ID = successCode['data']
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }
        
                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.isApproveVisible12 = false;
                this.drawerClose1();
                // this.search(false);
  
  
  
              } else {
                this.isApproveModalConfirmLoading = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });
  
  
          } else {
            this.isApproveModalConfirmLoading = false;
            this.message.error("Updation Failed", "");
            
          }
        });
      }
    }
    
     
  
  



  }

  handleApproveCancel12() {
    this.isApproveVisible12 = false;
    this.drawerClose1();
  }

  
  handleApproveCancel122() {
    this.isApproveVisible12 = false;
    // this.drawerClose1();
  }

 

  handleRejectOkAS() {
    let isOk = true;

    if (this.ACC_VERIFICATION_REMARK == undefined || this.ACC_VERIFICATION_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    else{
      if (isOk) {
        this.isRejectModalConfirmLoading = true;
        this.tempData["ACC_VERIFICATION_REMARK"] = this.ACC_VERIFICATION_REMARK;
        this.tempData["APPROVER_ID"] = this.api.userId;
        this.tempData["STATUS"] = "RJ";
  
        this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isRejectModalConfirmLoadingAS = false;
            this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
            this.message.success("Remark Updated Successfully", "");
            this.isRejectVisibleAS = false;
           this.drawerClose1()
  
          } else {
            this.isRejectModalConfirmLoadingAS = false;
            this.message.error("Remark Updation Failed", "");
            
          }
        });
      }
    }

    
  }

  
  handleRejectOkHP() {
    let isOk = true;

    if (this.HP_REMARK == undefined || this.HP_REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please enter remark', '');
    }

    else{
      if (isOk) {
        this.isRejectModalConfirmLoading = true;
        this.tempData["APPROVER_REMARK"] = this.HP_REMARK;
        this.tempData["APPROVER_ID"] = this.api.userId;
        this.tempData["STATUS"] = "RJ";
  
        this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isRejectModalConfirmLoadingHP = false;
            this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");
            this.message.success("Remark Updated Successfully", "");
            this.isRejectVisibleHP = false;
           this.drawerClose1()
  
          } else {
            this.isRejectModalConfirmLoadingHP = false;
            this.message.error("Remark Updation Failed", "");
            
          }
        });
      }
    }
   
  }




  HP_REMARK:string=""
  isApproveVisibleHP:boolean
  isApproveModalConfirmLoadingHP:boolean
  HPROMISE_DATETIME=new Date()

  handleApproveCancelHP(){
    this.isApproveVisibleHP = false;
    this.APPROVER_ID=null
    this.HP_REMARK=""
    this.drawerClose1()
  }

  handleApproveOkHP(){
    let isOk = true;
    if (this.APPROVER_ID == undefined || this.APPROVER_ID == 0) {
      isOk = false;
      this.message.error('Please select Sales Executive', '');
    }
    
    else{
      if (isOk) {

      
        this.isApproveModalConfirmLoadingHP = true;
        this.tempData["STATUS"] = "FW";
        this.tempData["APPROVER_REMARK"]=this.HP_REMARK
        // this.tempData["REMARK"] = this.HP_REMARK;
        this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isApproveModalConfirmLoadingHP = false;
            // this.isApproveModalConfirmLoading = true;
           
           
            // this.tempData["INVOICE_NO"] = this.INVOICE_NO;
  
            console.log(this.APPROVER_ID);
            this.tempData["CREATOR_ID"] = this.userid;
            this.tempData["STATUS"] = "RQ";
            this.tempData["APPROVER_ID"] = this.APPROVER_ID;
            this.tempData["REMARK"] = this.HP_REMARK;
            this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
            this.tempData["ID"]=0
            this.tempData["MEMO_DETAILS"]=this.memoDetailsArray
            this.tempData["CORPORATE_DISCOUNT"]=this.questionary.CORPORATE_DISCOUNT
            this.tempData["EXCHANGE"]=this.questionary.EXCHANGE
            this.tempData["IS_FINANCE"]=this.questionary.IS_FINANCE
            this.tempData["FINANCE_TYPE"]=this.questionary.FINANCE_TYPE
            this.tempData["BANK_NAME"]=this.questionary.BANK_NAME
            this.tempData["INSURANCE_TYPE"]=this.questionary.INSURANCE_TYPE
            this.tempData["INSURANCE_NAME"]=this.questionary['INSURANCE_NAME']
            this.tempData["CGST_NO"]=this.questionary.CGST_NO
            this.tempData["VIN_NUMBER"]=this.questionary['VIN_NUMBER']
            this.tempData["GM_REMARK"]=""
            this.tempData["SH_REMARK"]=""
            this.tempData["APPROVER_REMARK"]=""
            this.tempData["IS_RSA_AVAILABLE"]=this.questionary['IS_RSA_AVAILABLE']
            this.api.createMemo(this.tempData).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.isApproveModalConfirmLoadingHP = false;
                this.memoSignature.MEMO_ID =  successCode['data']
                this.memoSignature.HPROMISE_ID = this.userId

                this.memoSignature.HPROMISE_DATETIME=this.datePipe.transform(this.HPROMISE_DATETIME, "yyyy-MM-dd HH:mm:ss")
                this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
                  if (successCode['code'] == 200) {
                    this.message.success('memo Signature create sucessfully..', "")
                  }
        
                }, err => {
                  if (err['ok'] == false)
                    this.message.error("Server Not Found", "");
                });
                // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
                // this.updatesign()
                this.message.success("Approve Status Updated Successfully", "");
                this.handleApproveCancelHP()
                // this.isApproveVisibleHP = false;
                // this.drawerClose1();
                // this.search(false);
  
  
  
              } else {
                this.isApproveModalConfirmLoadingHP = false;
                this.message.error("Approve Status Updation Failed", "");
                // this.search(false);
              }
            });
  
  
          } else {
            this.isApproveModalConfirmLoadingHP = false;
            this.message.error("Updation Failed", "");
            
          }
        });
      }
    }
     
  }
  VINApproveVisible:boolean
  VINisApproveModalConfirmLoading:boolean

 

  
  VINhandleApproveCancel(){
    this.VINApproveVisible = false;
    this.APPROVER_ID=null
    this.VIN_REMARK=""
    this.drawerClose1()
  }

  VIN_NO:string=""

  VINhandleApproveOk(){
    let isOk = true;
    if (this.APPROVER_ID==null || this.APPROVER_ID==0) {
          this.message.error('Please select Sales Head', '')
         
      //   // }
    }

    else if (this.VIN_NO=="" || !this.VIN_NO==null) {
      this.message.error('Please enter VIN NO.', '')
     
   }
   
else{
  if (isOk) {

      
    this.VINisApproveModalConfirmLoading = true;
    this.tempData["STATUS"] = "FW";
    this.tempData["APPROVER_REMARK"]=this.VIN_REMARK
    this.api.updateMemoDetails1(this.tempData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.VINisApproveModalConfirmLoading = false;
        // this.isApproveModalConfirmLoading = true;
       
       
        // this.tempData["INVOICE_NO"] = this.INVOICE_NO;

        console.log(this.APPROVER_ID);
        this.tempData["CREATOR_ID"] = this.userid;
        this.tempData["STATUS"] = "RQ";
        this.tempData["APPROVER_ID"] = this.APPROVER_ID;
        this.tempData["REMARK"] = this.VIN_REMARK;
        this.tempData["PREVIOUS_REQUEST_ID"] = this.tempData["ID"]
        this.tempData["ID"]=0
        this.tempData["MEMO_DETAILS"]=this.memoDetailsArray
        this.tempData["CORPORATE_DISCOUNT"]=this.questionary.CORPORATE_DISCOUNT
        this.tempData["EXCHANGE"]=this.questionary.EXCHANGE
        this.tempData["IS_FINANCE"]=this.questionary.IS_FINANCE
        this.tempData["FINANCE_TYPE"]=this.questionary.FINANCE_TYPE
        this.tempData["BANK_NAME"]=this.questionary.BANK_NAME
        this.tempData["INSURANCE_TYPE"]=this.questionary.INSURANCE_TYPE
        this.tempData["INSURANCE_NAME"]=this.questionary['INSURANCE_NAME']
        this.tempData["CGST_NO"]=this.questionary.CGST_NO
        this.tempData["VIN_NUMBER"]=this.VIN_NO
        this.tempData["GM_REMARK"]=""
        this.tempData["SH_REMARK"]=""
        this.tempData["APPROVER_REMARK"]=""
        this.tempData["IS_RSA_AVAILABLE"]=this.questionary['IS_RSA_AVAILABLE']
        this.api.createMemo(this.tempData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.VINisApproveModalConfirmLoading = false;
            this.memoSignature.MEMO_ID =  successCode['data']
            // this.memoSignature.HPROMISE_ID = this.userId

            // this.memoSignature.HPROMISE_DATETIME=this.datePipe.transform(this.HPROMISE_DATETIME, "yyyy-MM-dd HH:mm:ss")
            this.api.creatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.message.success('memo Signature create sucessfully..', "")
              }
    
            }, err => {
              if (err['ok'] == false)
                this.message.error("Server Not Found", "");
            });
            // this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
            // this.updatesign()
            this.message.success("Approve Status Updated Successfully", "");
            this.VINhandleApproveCancel()
            // this.isApproveVisibleHP = false;
            // this.drawerClose1();
            // this.search(false);



          } else {
            this.VINisApproveModalConfirmLoading = false;
            this.message.error("Approve Status Updation Failed", "");
            // this.search(false);
          }
        });


      } else {
        this.VINisApproveModalConfirmLoading = false;
        this.message.error("Updation Failed", "");
        
      }
    });
  }
}
    
      
  }

 
}
