import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-insurance-map2',
  templateUrl: './insurance-map2.component.html',
  styleUrls: ['./insurance-map2.component.css']
})
export class InsuranceMap2Component implements OnInit {

  @Input() drawerClose21: Function;

  @Input() data: Leave;
  @Input() dataListtt = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  roles = [];
  model = [];
  leaveTypes = [];
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];
  Array1 = [];
  dataList1 = []
  disabled1 = true
  disabled2 = true


  MODEL1: any
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  @Input() selectedIndex

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Cluster Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence Number"],]

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  modeltype = []
  fueltype = []
  varienttype = []
  tranmission = []

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.getLeaveTypes();
    this.getLeaveTypeMaster();
    this.loadRoles();
    this.getModels();
    this.getRateMaster();
    this.getdata()
    // console.log(this.data1);

  }






  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  getModels() {
    this.model = [];

    this.api.getAllModels(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllVarients(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllFuel(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


    this.api.getAllTransmission(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.tranmission = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }


  getdata() {
    // this.dataListtt = this.Roletype
    // console.log(this.dataListtt, "this.dataList");

  }

  getLeaveTypes() {
    this.leaveTypes = [];

    this.api.getAllLeaveType(0, 0, 'NAME', 'asc', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  rateMaster = [];
  getRateMaster() {
    this.api.getRateMaster(0, 0, '', 'asc', '').subscribe((data1) => {
      console.log(data1);
      this.rateMaster = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }


  @Input() ROLES22 = []
  @Input() MODELS22 = []

  // applyFilter() {
  //   this.dataListtt = [];
  //   this.finalData = []
  //   var isOk = true;

  //   if (this.ROLES2.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Roles", "");

  //   } else if (this.MODELS2.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Models", "");
  //   }

  //   if (isOk) {
  //     this.ROLES = this.ROLES2
  //     this.MODELS = this.MODELS2
  //     this.ROLES22 = []
  //     for (let i = 0; i < this.ROLES.length; i++) {
  //       var kk = this.roles.findIndex(x => x.ID == this.ROLES[i]);
  //       this.ROLES22.push(this.roles[kk]['NAME']);
  //       this.MODELS22 = []
  //       for (let j = 0; j < this.MODELS.length; j++) {
  //         this.finalData.push({ "ROLE_ID": this.ROLES[i], "MODEL_ID": this.MODELS[j], "AMOUNT": 0 })
  //         // console.log(this.finalData);
  //         var kkk = this.model.findIndex(x => x.ID == this.MODELS[j]);
  //         this.MODELS22.push(this.model[kkk]['NAME'])
  //       }
  //       if (i + 1 == this.ROLES.length) {
  //         this.dataList = [...[], ...this.finalData]
  //       }



  //     }
  //     // for (let m = 0; m < this.dataList.length; m++) {
  //     //   this.dataList.push({'ID': m + 1})
  //     //   // console.log(this.dataList);
  //     // }
  //   }
  //   this.MODELS2 = []
  //   this.ROLES2 = []
  // }

  // deleterow(index: any) {
  //   var selected = this.ROLES[index];
  //   var d = this.dataList.filter((item) => {
  //     if (item.ROLE_ID != this.ROLES[index])
  //       return item;
  //   })

  //   // this.ROLES = this.ROLES.filter(item => item !== selected)
  //   this.ROLES.splice(index, 1);
  //   this.ROLES22.splice(index, 1);
  //   this.ROLES = [...[], ...this.ROLES]
  //   this.ROLES22 = [...[], ...this.ROLES22]
  //   this.dataList = [...[], ...d]
  //   this.finalData = [...[], ...d];
  // }

  // deletecoloumn(index) {

  //   var d = this.dataList.filter((item) => {
  //     if (item.MODEL_ID != this.MODELS[index])
  //       return item;
  //   })
  //   console.log(d)
  //   this.MODELS.splice(index, 1);
  //   this.MODELS22.splice(index, 1);
  //   this.MODELS = [...[], ...this.MODELS]
  //   this.MODELS22 = [...[], ...this.MODELS22]

  //   this.dataList = [...[], ...d]
  //   this.finalData = [...[], ...d];
  // }

  close(): void {
    this.drawerClose21();
    this.ROLES = []
    this.Modeldata = []
    this.ROLESSS = []
    // this.SendData=[]
    this.va = []
    // this.reset(myForm);
  }

  // reset(myForm: NgForm) {
  //   myForm.form.reset();
  // }
  MappingData
  //   mapBranch(data: rateMaster) {

  //    this.MappingData = Object.assign({},data);
  //   //  console.log(this.assign,'dd')

  //   this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.leaveTypeDetails = data['data'];
  //     }

  //   }, err => {
  //     console.log(err);
  //   });



  //    // this.drawerTitleEmployeeCollege = "Location for " + data.NAME + "";
  //    // this.user = Object.assign({},Data);

  //  }
  kkkkk = []
  roleIds = []
  nextsdata = []
  save11(Roletype) {
    console.log(this.SendData);
    this.message.success("Role Selected Successfully...", '')
    this.selectedIndex = 1
    this.nextsdata = []
    var m = ''
    var n = ''
    var l = ''
    var o = ''

    if (this.SendData.length > 0) {
      for (let i = 0; i < this.SendData.length; i++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.SendData[i]['MODEL1']);
        m = this.modeltype[kk]['NAME'];

        if (this.SendData[i]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.SendData[i]['FULES']);
          n = this.fueltype[kks]['NAME'];
        } else {
          n = 'All'
        }

        if (this.SendData[i]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.SendData[i]['VARIENT']);
          l = this.varienttype[aa]['NAME'];
        } else {
          l = 'All'
        }

        if (this.SendData[i]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.SendData[i]['TRANSMISSION']);
          o = this.tranmission[vv]['NAME'];
        } else {
          o = 'All'
        }

        this.nextsdata.push({
          'MODEL1': m,
          "FULES": n,
          "VARIENT": l,
          "TRANSMISSION": o
        })

      }
    }

    this.Modeldata = this.nextsdata;
    console.log(this.Modeldata);

    this.roleIds = []

    for (let i = 0; i < this.Roletype.length; i++) {
      if (this.Roletype[i]['IS_SELECTED'] == true) {
        this.roleIds.push(this.Roletype[i]['ID'])
      }
    }

    console.log(this.roleIds);
  }
  MODELeee = []
  // save22(MODEL1, FULES, VARIENT, TRANSMISSION) {

  //   var kk = this.modeltype.findIndex(x => x.ID == MODEL1);
  //   this.MODELeee.push(this.modeltype[kk]['NAME']);


  //   var kks = this.fueltype.findIndex(x => x.ID == FULES);
  //   this.MODELeee.push(this.fueltype[kks]['NAME']);

  //   var aa = this.varienttype.findIndex(x => x.ID == VARIENT);
  //   this.MODELeee.push(this.varienttype[aa]['NAME']);

  //   var vv = this.tranmission.findIndex(x => x.ID == TRANSMISSION);
  //   this.MODELeee.push(this.tranmission[vv]['NAME']);

  //   console.log(this.MODELeee);

  // }

  Modeldata = []
  // SendData = []
  SendData1 = []
  i = 2
  mod: any
  ful: any
  var: any
  trans: any
  mod1: any
  ful1: any
  var1: any
  trans1: any
  addmodeldetails() {

    if (this.Modeldata.length == 0) {


      var kk = this.modeltype.findIndex(x => x.ID == this.MODEL1);
      this.mod = this.modeltype[kk]['NAME'];

      console.log(this.FULES);

      if (this.FULES != 0) {
        var kks = this.fueltype.findIndex(x => x.ID == this.FULES);
        this.ful = this.fueltype[kks]['NAME'];
      } else {
        this.ful = "All"
      }

      if (this.VARIENT != 0) {
        var aa = this.varienttype.findIndex(x => x.ID == this.VARIENT);
        this.var = this.varienttype[aa]['NAME'];
      } else {
        this.var = "All"
      }

      if (this.TRANSMISSION != 0) {
        var vv = this.tranmission.findIndex(x => x.ID == this.TRANSMISSION);
        this.trans = this.tranmission[vv]['NAME'];
      } else {
        this.trans = "All"
      }

      this.Modeldata = [
        {
          MODEL1: this.mod,
          FULES: this.ful,
          VARIENT: this.var,
          TRANSMISSION: this.trans
        }
      ];

      this.SendData = [{
        MODEL1: this.MODEL1,
        FULES: this.FULES,
        VARIENT: this.VARIENT,
        TRANSMISSION: this.TRANSMISSION
      }
      ];
      this.SendData1 = [[this.MODEL1 + '.' + this.FULES + '.' + this.VARIENT + '.' + this.TRANSMISSION]];
      // console.log(this.Modeldata);
      this.MODEL1 = 0
      this.FULES = null
      this.VARIENT = null
      this.TRANSMISSION = null
    } else {
      var newindex = false
      console.log(this.Modeldata);
      this.SendData.forEach((x, k) => {


        if (
          this.SendData[k]['MODEL1'] == this.MODEL1 &&
          this.SendData[k]['FULES'] == this.FULES &&
          this.SendData[k]['VARIENT'] == this.VARIENT &&
          this.SendData[k]['TRANSMISSION'] == this.TRANSMISSION
        ) {

          newindex = true
        }


        if (k + 1 == this.SendData.length && newindex == false) {
          var kk = this.modeltype.findIndex(x => x.ID == this.MODEL1);
          if (this.modeltype[kk] != undefined) {
            this.mod = this.modeltype[kk]['NAME'];
          }

          if (this.FULES != 0) {
            var kks = this.fueltype.findIndex(x => x.ID == this.FULES);
            if (this.fueltype[kks] != undefined) {
              this.ful = this.fueltype[kks]['NAME'];
            }
          } else {
            this.ful = "All"
          }

          if (this.VARIENT != 0) {
            var aa = this.varienttype.findIndex(x => x.ID == this.VARIENT);
            if (this.varienttype[aa] != undefined) {
              this.var = this.varienttype[aa]['NAME'];
            }
          } else {
            this.var = "All"
          }


          if (this.TRANSMISSION != 0) {
            var vv = this.tranmission.findIndex(x => x.ID == this.TRANSMISSION);
            if (this.varienttype[vv] != undefined) {
              this.trans = this.tranmission[vv]['NAME'];
            }
          } else {
            this.trans = "All"
          }
          this.Modeldata = [...this.Modeldata, {
            MODEL1: this.mod,
            FULES: this.ful,
            VARIENT: this.var,
            TRANSMISSION: this.trans
          }];

          this.SendData = [...this.SendData, {
            MODEL1: this.MODEL1,
            FULES: this.FULES,
            VARIENT: this.VARIENT,
            TRANSMISSION: this.TRANSMISSION
          }];
          this.SendData1 = [...this.SendData1, this.MODEL1 + '.' + this.FULES + '.' + this.VARIENT + '.' + this.TRANSMISSION];
          this.i++
        }
        if (k + 1 == this.SendData.length && newindex == true) {
          this.message.info('Data Already Present', '')
        }
      })
      //   if( 
      //     this.Modeldata[k]['MODEL1']==this.mod && 
      //     this.Modeldata[k]['FULES']==this.ful &&
      //     this.Modeldata[k]['VARIENT']==this.var &&
      //     this.Modeldata[k]['TRANSMISSION']==this.trans 
      //   ){
      // this.message.error("Model,Fuel,Varient And Transmission Alredy Selected",'');
      //   }


      // }









      // console.log(this.Modeldata);

      this.MODEL1 = 0
      this.FULES = null
      this.VARIENT = null
      this.TRANSMISSION = null
    }
    console.log(this.SendData);
    console.log(this.Modeldata);


  }
  finalDataa = []
  // dataListtt = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []
  @Input() ROLEIDS = []
  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []
  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }
  applytable(Modeldata) {
    this.message.success("Model Selected Successfully...", '')
    this.selectedIndex = 2
    console.log(Modeldata);
    this.SendData1 = []
    this.Final = []
    this.va = []
    this.MODELIDS = []
    this.ROLEIDS = []
    this.ROLESSS = this.roleIds
    this.MODELSSSS = this.SendData
    var abc = -2
    for (let i = 0; i < this.ROLESSS.length; i++) {
      var kk = this.Roletype.findIndex(x => x.ID == this.ROLESSS[i]);
      this.ROLEIDS.push(this.Roletype[kk]['NAME']);
      this.va = [];
      this.SendData1 = [];
      for (let j = 0; j < this.MODELSSSS.length; j++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.MODELSSSS[j]['MODEL1']);
        var ss = this.modeltype[kk]['NAME']
        this.MODELIDS.push(this.MODELSSSS[j]['MODEL1']);

        var a = ''
        var b = ''
        var c = ''
        if (this.MODELSSSS[j]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.MODELSSSS[j]['FULES']);
          a = this.fueltype[kks]['NAME']

        } else {
          this.MODELSSSS[j]['FULES'] = 0

          a = ""
        }

        if (this.MODELSSSS[j]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.MODELSSSS[j]['VARIENT']);
          b = (this.varienttype[aa]['NAME']);
        } else {
          this.MODELSSSS[j]['VARIENT'] = 0
          b = ""
        }

        if (this.MODELSSSS[j]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.MODELSSSS[j]['TRANSMISSION']);
          c = (this.tranmission[vv]['NAME']);
        } else {
          this.MODELSSSS[j]['TRANSMISSION'] = 0
          c = ""

        }

        this.va.push(this.str(this.modeltype[kk]['NAME'], a, b, c))
        this.ids.push([this.MODELSSSS[j]['MODEL1'], this.MODELSSSS[j]['FULES'], this.MODELSSSS[j]['VARIENT'], this.MODELSSSS[j]['TRANSMISSION']])
        this.SendData1 = [...this.SendData1, this.MODELSSSS[j]['MODEL1'] + "." + this.MODELSSSS[j]['FULES'] + "." + this.MODELSSSS[j]['VARIENT'] + "." + this.MODELSSSS[j]['TRANSMISSION']];
        // console.log(this.ids);

        this.Final.push({
          "ROLE_ID": this.ROLESSS[i],
          "MODEL_ID": this.MODELSSSS[j]['MODEL1'],
          "FUEL_TYPE_ID": this.MODELSSSS[j]['FULES'],
          "VARIENT_ID": this.MODELSSSS[j]['VARIENT'],
          "TRANSMISSION_TYPE_ID": this.MODELSSSS[j]['TRANSMISSION'],
          "INSURANCE_PERCENTAGE_TYPE": 50,
          "AMOUNT": 0
        })


      }
      if (i + 1 == this.ROLESSS.length) {

        this.getAmount()
      }

    }
  }

  getAmount() {

    if (this.dataListtt.length > 0) {


      this.dataListtt.forEach((x, index) => {
        this.Final.forEach((x2, index2) => {
          if (x.ROLE_ID == x2.ROLE_ID &&
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Final[index2]['AMOUNT'] = this.dataListtt[index].AMOUNT
          }
        })
        if (index + 1 == this.dataListtt.length) {
          this.dataListtt = [...[], ...this.Final];
          console.log(this.dataListtt);

        }
      });
    } else {
      this.dataListtt = [...[], ...this.Final];
    }



  }
  indexx = -1

  adddata() {
    if (this.indexx > -1) {
      this.Modeldata[this.indexx]['MODEL1'] = this.mod
      this.Modeldata[this.indexx]['FULES'] = this.ful
      this.Modeldata[this.indexx]['VARIENT'] = this.var
      this.Modeldata[this.indexx]['TRANSMISSION'] = this.trans

      this.indexx = -1
    } else {
      this.addmodeldetails();
    }
    this.MODEL1 = 0
    this.FULES = null
    this.VARIENT = null
    this.TRANSMISSION = null
  }

  sendjson(dataListtt) {
    var isOk = true;


    if (dataListtt.length == 0) {
      isOk = false;
      this.message.error("Please Select Roles And Models", "");
    }
    var obb = ({
      "INSURANCE_INCENTIVE_RATES_MASTER_ID": this.data.ID,
      "ROLE_IDS": this.ROLESSS.toString(),
      "MODEL_IDS": this.SendData1.toString(),
      "INSURANCE_PERCENTAGE_TYPE": 50,
      "CLIENT_ID:": 1,
      "data": this.dataListtt
    })

    if (isOk) {
      this.isSpinning = true;


      this.api.InsuranceAddBulk(obb).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Roles And Models Mapped Successfully", "");

          this.drawerClose21()
          this.isSpinning = false;
          this.Modeldata = []
          this.ROLESSS = []
          this.va = []


        } else {
          this.message.error("Roles And Models Mapping Failed", "");
          this.isSpinning = false;
        }
      });

    }
  }

  // save(dataList: any): void {
  //   var isOk = true;

  //   console.log(this.data);

  //   if (this.dataList.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Roles And Models", "");
  //   }

  //   var obb = ({
  //     "DIRECT_INCENTIVE_RATES_MASTER_ID": this.data.ID,
  //     // "TYPE": 'S', 
  //     "ROLE_IDS": this.ROLES.toString(),
  //     "MODEL_IDS": this.MODELS.toString(),
  //     "data": this.dataList
  //   })
  //   // Object.assign(obb,this.dataList)
  //   if (isOk) {
  //     this.isSpinning = true;


  //     this.api.rateAddBulk(obb).subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         this.message.success("Roles And Models Mapped Successfully", "");

  //         this.drawerClose1()
  //         this.isSpinning = false;

  //       } else {
  //         this.message.error("Roles And Models Mapping Failed", "");
  //         this.isSpinning = false;
  //       }
  //     });

  //   }
  // }

  today = new Date().setDate(new Date().getDate() + 4);

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  // @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.FROM_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }

  @Input() Roletype = [];

  getLeaveTypeMaster() {
    // this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.leaveTypeDetails = data['data'];
    //   }

    // }, err => {
    //   console.log(err);
    // });
    // this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
    //   this.Roletype = roles['data'];
    // }, err => {
    //   console.log(err);
    // });

  }

  // onLeaveTypeSelected(leaveType) {
  //   var leaveData = this.leaveTypeDetails.filter(obj => {
  //     return (obj.ID == leaveType);
  //   });

  //   if (leaveData.length != 0)
  //     this.today = new Date().setDate(new Date().getDate() + Number(leaveData[0]["LEAVE_APPROVAL_DAYS"]));
  //   else
  //     this.today = new Date().setDate(new Date().getDate() + Number(4));

  //   this.data.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
  //   this.data.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");

  //   this.disabledStartDate = (current: Date): boolean =>
  //     differenceInCalendarDays(current, this.today) < 0;
  // }

}

