import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-salesmanagerwisesalesexecutivesummaryreport',
  templateUrl: './salesmanagerwisesalesexecutivesummaryreport.component.html',
  styleUrls: ['./salesmanagerwisesalesexecutivesummaryreport.component.css']
})
export class SalesmanagerwisesalesexecutivesummaryreportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Sales Manager Wise Sales Executive Wise Varient Wise Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  DATE: any;
  BRANCH: any = [];
  branch: any = [];
  // BRANCH:any=[];
  SALESEXECUTIVE: any = [];
  salesexecutive: any = [];
  MODEL: any = [];
  VARIENT: any = [];
  SALESMANAGER: any = [];
  models: any = [];
  varient: any = [];
  salesmanager: any = [];
  // isFilterApplied: string = "default";
  isOk = true;
  columns: string[][] = [["NAME_OF_SALES_MANAGER", "Name Of Sales Manager"], ["SALES_EXECUTIVE_NAME", "Name Of Sales Executive"], ["BRANCH_NAME", "Branch"], ["DESIGNATION", "Designation"], ["MODEL_NAME", "Model"], ["VARIENT_NAME", "Varient"], ["NO_OF_QUOTATIONS", "No. Of Quotation Generated"]];
  exportLoading: boolean;
  dataList1: any;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }
  FROMDATE: any;
  TODATE: any;
  ngOnInit() {
    // this.DATE = new Date();
    // this.BRANCH = "";
    // this.SALESEXECUTIVE = "";
    // this.MODEL ="";
    // this.VARIENT ="";
    // this.SALESMANAGER ="";
    // this.DATE="";
    // this.FROMDATE=new Date();
    this.FROMDATE = this.datePipe.transform(new Date, "yyyy-MM-dd");
    this.TODATE = this.datePipe.transform(new Date, "yyyy-MM-dd")
    // this.getAllBranch();
    // this.getAllModels();
    // this.getAllVarients();
    this.getsalehead();
    // this.getsalesmanager();
    // this.applyFilter();
    // this.search(false);
  }
  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      BRANCH:this.BRANCH, 
      SALESEXECUTIVE:this.SALESEXECUTIVE, 
      VARIENT:this.VARIENT, 
      MODEL:this.MODEL, 
      FROMDATE:this.FROMDATE, 
      TODATE:this.TODATE, 
      SALESMANAGER:this.SALESMANAGER
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 24').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(
      data => {
        if (data['code'] == 200) {
          this.salesexecutive = data['data'];
          this.onSelectAllSalesExecutie(true)
          this.getsalesmanager();
          console.log(this.salesexecutive);
        }
      }, err => { console.log(err); });
  }
  getsalesmanager() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(29)').subscribe(
      data => {
        if (data['code'] == 200) {
          this.salesmanager = data['data'];
          this.onSelectAllSalesManager(true);
          this.getAllModels();
          console.log(this.salesmanager);
        }
      }, err => { console.log(err); });
  }
  getAllModels() {
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data']
      this.onSelectAllModel(true);
      this.getAllVarients();
    })
  }
  getAllVarients() {
    this.api.getAllVarients(0, 0, "", "", "").subscribe(data => {
      this.varient = data['data']
      this.onSelectAllVarient(true);
      this.getAllBranch();
    })
  }
  getAllBranch() {
    this.api.getAllBranch(0, 0, "", "", "").subscribe(data => {
      this.branch = data['data']
      this.onSelectAllBranch(true);
      this.search(false);
    })
  }
  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }
  SELECT_BRANCH: boolean = false;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }
  SELECT_VARIENT: boolean = false;
  onSelectAllVarient(event: any) {
    this.SELECT_VARIENT = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_VARIENT == true) {
      for (var i = 0; i < this.varient.length; i++) {
        ids.push(this.varient[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT = ids
  }
  SELECT_SALESMANAGER: boolean = false;
  onSelectAllSalesManager(event: any) {
    this.SELECT_SALESMANAGER = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALESMANAGER == true) {
      for (var i = 0; i < this.salesmanager.length; i++) {
        ids.push(this.salesmanager[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESMANAGER = ids
  }
  SELECT_SALESEXECUTIVE: boolean = false;
  onSelectAllSalesExecutie(event: any) {
    this.SELECT_SALESEXECUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALESEXECUTIVE == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESEXECUTIVE = ids
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }


    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        if (column[0] == "NAME_OF_SALES_MANAGER") {
          likeQuery += " " + "NAME_OF_SALES_MANAGER" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "SALES_EXECUTIVE_NAME") {
          likeQuery += " " + "SALES_EXECUTIVE_NAME" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "BRANCH_NAME") {
          likeQuery += " " + "BRANCH_NAME" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "DESIGNATION") {
          likeQuery += " " + "DESIGNATION" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "MODEL_NAME") {
          likeQuery += " " + "MODEL_NAME" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "VARIENT_NAME") {
          likeQuery += " " + "VARIENT_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    // else {
    //   // this.applyFilter()
    // }
    // this.api.getsalesManagerExecutivewisereport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,"","","","","",""+this.SALESMANAGER).subscribe(data => {
    //     console.log(data)
    //     if(data['code']==200)
    //     {
    //     this.loadingRecords = false;
    //     this.isSpinning=false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //     }
    //     else{}
    //   }, err => {
    //     console.log(err);
    //   });
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getsalesManagerExecutivewisereport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.MODEL, this.FROMDATE, this.TODATE, this.SALESMANAGER).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = 'filter-invisible';

        }
        // else {
        //   this.loadingRecords = false;
        //   this.isSpinning = false
        // }
      }, err => {
        // this.loadingRecords = false;
        // this.isSpinning = false
        console.log(err);
      });
    }
    else {
      this.exportLoading = false;
      this.api.getsalesManagerExecutivewisereport(0, 0, this.sortKey, sort, likeQuery, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.MODEL, this.FROMDATE, this.TODATE, this.SALESMANAGER).subscribe(data => {
        console.log(data)
        this.exportLoading = false;
        this.dataList1 = data['data'];
        this.convertInExcel();
      },
        err => {
          if (err['ok'] == false)
          this.message.error("Server Not Found", "");
        });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
    this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    // this.DATE =this.datePipe.transform(this.DATE,"yyyy-MM-dd")
    // this.SALESEXECUTIVE=this.datePipe.transform(this.SALESEXECUTIVE,"yyyy-MM-dd")
    // this.filterQuery = "";
    // if (this.DATE != undefined && this.BRANCH != undefined && this.SALESEXECUTIVE != undefined && this.MODEL != undefined && this.VARIENT != undefined && this.SALESMANAGER != undefined) {
    //   //  this.message.error("Please Select Atleast One Option From The Following",'')
    //   this.filterQuery = " AND DATE = " + this.DATE + " AND BRANCH = " + this.BRANCH + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE + " AND MODEL = " + this.MODEL + " AND VARIENT = " + this.VARIENT + " AND SALES_MANAGER = " + this.SALESMANAGER;
    //   this.isSpinning = false; 
    //   this.loadingRecords = false;
    //   this.isFilterApplied = "primary";
    //   console.log("FilterQuery", this.filterQuery)

    // }
    // else if (this.DATE == undefined && this.BRANCH != undefined && this.SALESEXECUTIVE != undefined && this.MODEL != undefined && this.VARIENT != undefined && this.SALESMANAGER != undefined) {
    //   //  this.message.error("Please Select Atleast One Option From The Following",'')
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE + " AND MODEL = " + this.MODEL + " AND VARIENT = " + this.VARIENT + " AND SALES_MANAGER = " + this.SALESMANAGER;
    //   this.isSpinning = false; 
    //   this.loadingRecords = false;
    //   this.isFilterApplied = "primary";
    //   console.log("FilterQuery", this.filterQuery)

    // }
    // else if (this.DATE == undefined && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined && this.MODEL != undefined && this.VARIENT != undefined && this.SALESMANAGER != undefined) {
    //   //  this.message.error("Please Select Atleast One Option From The Following",'')
    //   this.filterQuery =  " AND MODEL = " + this.MODEL + " AND VARIENT = " + this.VARIENT + " AND SALES_MANAGER = " + this.SALESMANAGER;
    //   this.isSpinning = false; 
    //   this.loadingRecords = false;
    //   this.isFilterApplied = "primary";
    //   console.log("FilterQuery", this.filterQuery)

    // }
    // else if (this.DATE != undefined && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND DATE = " + this.DATE;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE == undefined && this.BRANCH != undefined && this.SALESEXECUTIVE == undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE == undefined && this.BRANCH == undefined && this.SALESEXECUTIVE != undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE == undefined && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined && this.MODEL != undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE == undefined && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined && this.MODEL == undefined && this.VARIENT != undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND VARIENT = " + this.VARIENT;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }else if (this.DATE == undefined && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined && this.MODEL != undefined && this.VARIENT == undefined && this.SALESMANAGER != undefined) {
    //   this.filterQuery = " AND SALES_MANAGER = " + this.SALESMANAGER;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE != undefined && this.BRANCH != undefined && this.SALESEXECUTIVE == undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND DATE = " + this.DATE + " AND BRANCH = " + this.BRANCH;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE != undefined && this.BRANCH == undefined && this.SALESEXECUTIVE != undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND DATE = " + this.DATE + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.DATE == undefined && this.BRANCH != undefined && this.SALESEXECUTIVE != undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    if (this.DATE == undefined && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined && this.MODEL == undefined && this.VARIENT == undefined && this.SALESMANAGER == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false;
      this.isFilterApplied = "default";
    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        if (column[0] == "NAME_OF_SALES_MANAGER") {
          likeQuery += " " + "NAME_OF_SALES_MANAGER" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "SALES_EXECUTIVE_NAME") {
          likeQuery += " " + "SALES_EXECUTIVE_NAME" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "BRANCH_NAME") {
          likeQuery += " " + "BRANCH_NAME" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "DESIGNATION") {
          likeQuery += " " + "DESIGNATION" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "MODEL_NAME") {
          likeQuery += " " + "MODEL_NAME" + " like '%" + this.searchText + "%' OR";

        }
        if (column[0] == "VARIENT_NAME") {
          likeQuery += " " + "VARIENT_NAME" + " like '%" + this.searchText + "%'";
        }
      });
      likeQuery = likeQuery.substring(0, likeQuery.length) + ")";

      var filterQuery = this.filterQuery + likeQuery
    }
    // [["NAME_OF_SALES_MANAGER", "Name Of Sales Manager"], ["SALES_EXECUTIVE_NAME", "Name Of Sales Executive"], ["BRANCH_NAME", "Branch"], ["DESIGNATION", "Designation"], ["MODEL_NAME", "Model"], ["VARIENT_NAME", "Varient"], ["NO_OF_QUOTATIONS", "No. Of Quotation Generated"]];
    // else {
    //   // this.applyFilter()
    // }
    // if (this.isOk == true) {
    //   // this.api.getsalesManagerExecutivewisereport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,""+this.BRANCH,""+this.SALESEXECUTIVE,""+this.VARIENT,""+this.MODEL,""+this.DATE,""+this.SALESMANAGER).subscribe(data => {
    //   //   console.log(data)
    //   //   if(data['code']==200)
    //   //   {
    //   //   this.loadingRecords = false;
    //   //   this.isSpinning=false;
    //   //   this.totalRecords = data['count'];
    //   //   this.dataList = data['data'];
    //   //   }
    //   //   else{}
    //   // }, err => {
    //   //   console.log(err);
    //   // });
    //   this.api.getsalesManagerExecutivewisereport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.MODEL, this.FROMDATE, this.TODATE, this.SALESMANAGER).subscribe(data => {

    //     console.log(data)
    //     if (data['code'] == 200) {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //       this.totalRecords = data['count'];
    //       this.isFilterApplied = "primary"
    //       if (data['count'] > 0) {
    //         this.dataList = data['data'];
    //         this.dataList1 = data['data'];
    //       }
    //       else {
    //         this.dataList = [];
    //         this.dataList1 = [];
    //       }
    //     }
    //     else {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //   });
    // }
    this.search(true);
    this.filterClass = 'filter-invisible';
  }
  clearFilter() {
    // this.DATE =null;
    this.SALESEXECUTIVE = [];
    //  this.SALESEXECUTIVE=this.datePipe.transform(this.SALESEXECUTIVE,"dd-MM-yyyy");
    this.FROMDATE = this.datePipe.transform(new Date, "yyyy-MM-dd");
    this.TODATE = this.datePipe.transform(new Date, "yyyy-MM-dd")
    this.getsalehead()
    // this.FROMDATE = "";
    // this.TODATE = ""
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Name Of Sales Manager'] = this.dataList1[i]['NAME_OF_SALES_MANAGER'];
      obj1['Sales Executive Name'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Designation'] = this.dataList1[i]['DESIGNATION'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Variant Name'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['No Of Quotations'] = this.dataList1[i]['NO_OF_QUOTATIONS'];
      // obj1['RTO'] = this.dataList1[i]['RTO'];
      // obj1['Fastag'] = this.dataList1[i]['FASTAG'];
      // obj1['Accessories'] = this.dataList1[i]['ACCESSORIES'];
      // obj1['Warranty'] = this.dataList1[i]['EX_WARRANTY'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales manager sales executive wise summary' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  importInExcel() {
    this.search(true, true);
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue)
  }
}
