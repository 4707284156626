import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-sales-executive-wise-model-wise-summary-report",
  templateUrl:
    "./sales-executive-wise-model-wise-summary-report.component.html",
  styleUrls: ["./sales-executive-wise-model-wise-summary-report.component.css"],
})
export class SalesExecutiveWiseModelWiseSummaryReportComponent
  implements OnInit
{
  formTitle = "Sales Executive Wise Model Wise Summary Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  
  fileName = 'SalesExecutiveWiseModelWiseSummaryReport.xlsx';
  orgId = this.cookie.get("orgId");
  FROM_DATE 
  TO_DATE
  BRANCH_ID = [];
  MODEL_ID = [];
  VARIENT_ID = [];
  SALES_EXECUTIVE_ID = [];
  dateFormat = 'yyyy-MM-dd';
  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService
  ) {}

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.FROM_DATE = new Date();
    this.TO_DATE = new Date();

    this.getBranches();
    this.getSalesExecutives();
    this.getModels();
    this.search();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let ids = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  SELECT_ALL3: boolean = false;
  onSelectAllChecked3(event) {
    this.SELECT_ALL3 = event;
    let ids = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.varients.length; i++) {
        ids.push(this.varients[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT_ID = ids;
  }

  onSelectOff3(event) {
    var a = this.varients.length;
    var b = this.varients.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.VARIENT_ID = event;
    if (this.VARIENT_ID.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }

  SELECT_ALL4: boolean = false;
  onSelectAllChecked4(event) {
    this.SELECT_ALL4 = event;
    let ids = [];
    if (this.SELECT_ALL4 == true) {
      for (var i = 0; i < this.salesExecutives.length; i++) {
        ids.push(this.salesExecutives[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff4(event) {
    var a = this.salesExecutives.length;
    var b = this.salesExecutives.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL4 = false;
    } else {
      this.SELECT_ALL4 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL4 = false;
    }
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  models = [];

  getModels() {
    this.models = [];
    this.varients = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  varients = [];

  getVarients(modelID) {
    this.varients = [];
    this.VARIENT_ID = [];

    this.api
      .getAllVarients(
        0,
        0,
        "NAME",
        "asc",
        " AND MODEL_ID IN (" + modelID + ") AND STATUS=1"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.varients = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  salesExecutives = [];

  getSalesExecutives() {
    this.salesExecutives = [];

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" +
          this.orgId +
          " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
          this.cookie.get("orgId") +
          " AND ROLE_ID=28)"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.salesExecutives = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }



  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    if (this.FROM_DATE.length > 0 && this.TO_DATE.length > 0) this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.search(true);
  }

  clearFilter() {
    this.SELECT_ALL1=false;
    this.SELECT_ALL2=false;
    this.SELECT_ALL3=false;
    this.SELECT_ALL4=false;
    
    this.FROM_DATE=new Date(); 
    this.TO_DATE=new Date(); 
    this.BRANCH_ID = [];
    this.MODEL_ID = [];
    this.VARIENT_ID = [];
    this.SALES_EXECUTIVE_ID = [];

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.SalesExecutiveWiseModelWiseSummaryReportData=[];
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.SalesExecutiveWiseModelWiseSummaryReportSortKey = sort.key;
    this.SalesExecutiveWiseModelWiseSummaryReportSortValue = sort.value;
    this.search(true);
  }

  SalesExecutiveWiseModelWiseSummaryReportPageIndex = 1;
  SalesExecutiveWiseModelWiseSummaryReportPageSize = 10;
  SalesExecutiveWiseModelWiseSummaryReportTotalRecords = 1;
  SalesExecutiveWiseModelWiseSummaryReportSortValue: string = "desc";
  SalesExecutiveWiseModelWiseSummaryReportSortKey: string = "SALES_EXECUTIVE_ID";
  SalesExecutiveWiseModelWiseSummaryReportData = [];

  columns: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
    // ["SALES_EXECUTIVE_DESIGNATION_NAME", "Sales Executive Designation Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "variant Name"],
    ["QUOTATION_COUNT", "Total Quotation Count"],
    ["NO_OF_MEMO_GENERATED","No. Of Memo Generated"],
    ["NO_OF_INVOICE_GENERATED","No. Of Invoice Generated"] 
  ];

  columns1: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
    // ["SALES_EXECUTIVE_DESIGNATION_NAME", "Sales Executive Designation Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "variant Name"],
   
  ];
  exportLoading: boolean = false;
  searchText: string = "";
  

  importInExcel() {
    this.search(true, true);
  }
  SalesExecutiveWiseModelWiseSummary=[];

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.SalesExecutiveWiseModelWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.SalesExecutiveWiseModelWiseSummaryReportSortValue.startsWith(
        "a"
      )
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


   



    if (this.FROM_DATE != undefined && this.FROM_DATE.length != 0 && this.TO_DATE != undefined && this.TO_DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (
      this.SALES_EXECUTIVE_ID != undefined &&
      this.SALES_EXECUTIVE_ID.length != 0
    ) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }

    if (exportInExcel == false) {
    this.loadingRecords = true;
    this.api
      .getSalesExecutiveWiseModelWiseSummaryReport(
        this.SalesExecutiveWiseModelWiseSummaryReportPageIndex,
        this.SalesExecutiveWiseModelWiseSummaryReportPageSize,
        this.SalesExecutiveWiseModelWiseSummaryReportSortKey,
        sort,
        likeQuery,
        this.api.orgId,
        branchFilter,
        this.FROM_DATE,
        this.TO_DATE,
       
        modelFilter,
        varientFilter,
        salesExecutiveFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.SalesExecutiveWiseModelWiseSummaryReportTotalRecords =
              data["count"];
            this.SalesExecutiveWiseModelWiseSummaryReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
    }
    else {
      this.exportLoading = true;
     this.api
      .getSalesExecutiveWiseModelWiseSummaryReport(
        0,
        0,
        this.SalesExecutiveWiseModelWiseSummaryReportSortKey,
        sort,
        "",
        this.api.orgId,
        branchFilter,
        this.FROM_DATE,
        this.TO_DATE,
       
        modelFilter,
        varientFilter,
        salesExecutiveFilter
      ).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.SalesExecutiveWiseModelWiseSummary = data['data'];
            this.convertInExcel();
           
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.SalesExecutiveWiseModelWiseSummary.length; i++) {
      obj1['Sales Executive Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['BRANCH_NAME'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Model Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['MODEL_NAME'];
      obj1['Variant Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['VARIENT_NAME'];
      obj1['Total Quotation Count'] = this.SalesExecutiveWiseModelWiseSummary[i]['QUOTATION_COUNT'];
      obj1['No. Of Memo Generated'] = this.SalesExecutiveWiseModelWiseSummary[i]['NO_OF_MEMO_GENERATED'];
      obj1['No. Of Invoice Generated'] = this.SalesExecutiveWiseModelWiseSummary[i]['NO_OF_INVOICE_GENERATED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.SalesExecutiveWiseModelWiseSummary.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Model Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}
