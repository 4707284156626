import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { TransmissionMaster } from "src/app/Models/TransmissionMaster";
import { ApiService } from "src/app/Service/api.service";
import { TransmissionDrawerComponent } from "../transmission-drawer/transmission-drawer.component";

@Component({
  selector: "app-transmission-master",
  templateUrl: "./transmission-master.component.html",
  styleUrls: ["./transmission-master.component.css"],
})

export class TransmissionMasterComponent implements OnInit {
  formTitle = "Manage Transmission";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["NAME", "Name"], ["SEQUENCE_NO", "Sequence No."], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: TransmissionMaster = new TransmissionMaster();
  isSpinning = false;

  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.loadingRecords = true;
    this.api.getAllTransmission(
      this.pageIndex,
      this.pageSize,
      this.sortKey,
      sort,
      likeQuery
    ).subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data["count"];
          this.dataList = data["data"];
        }

      }, (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  today = new Date().setDate(new Date().getDate() + 4);
  @ViewChild(TransmissionDrawerComponent, { static: false }) TransmissionDrawerComponentVar: TransmissionDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Transmission";
    this.drawerData = new TransmissionMaster();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.TransmissionDrawerComponentVar.getSequenceNo();
  }

  edit(data: TransmissionMaster): void {
    this.drawerTitle = "Update Transmission Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: TransmissionMaster, status) {
    data.STATUS = status;

    this.api.updateTransmission(data).subscribe((successCode) => {
      if (successCode["code"] == 200)
        this.message.success("Status Updated Successfully", "");
      else this.message.error("Failed to Update Status", "");

      this.search(false);
    });
  }
}
