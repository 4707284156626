import { Component, Input, OnInit,Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { schedule } from 'src/app/Models/schedule';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
// import { ExpensesApproveFromHeadTilesComponent } from '../EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component';


@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.css'],
  providers:[DatePipe]
})
export class ScheduleFormComponent implements OnInit {
  index1: number;
  DAYS: any;
  loadingRecords: boolean=true;

  constructor(private api:ApiService,private cookie:CookieService,private message: NzNotificationService, private datepipe: DatePipe) { }
@Input() drawerClose!: Function;
@Input() data: schedule = new schedule();
@Input() TimeDetails:any
@Input() TYPE_OF_ATTACHMENT:any
@Input()masterdata:any=[]
@Input()mails1:any=[]
isDisabled=true
DATE1:any
MONTH1:any;
HOUR:any
MINUTES:any
SECONDS:any
isSpinning = false;
switchValue = false;
switchValue1 = true;
startValue: Date | null = null;
endValue: Date | null = null;
assign:any=[]
custom:any=[]
listOfData: schedule[] = [];
dataList:any = [];
options:any=[];
pageIndex = 1;
pageSize = 10;
totalRecords = 100;
mails:any=[]
date :any
month:any
isOk = false;
@Input() FILTER_QUERY:any
userId=this.cookie.get('userId')



ngOnInit() {
   this.onGet()
   this.onGetData()
  this.onGetReports()
  }
 
  onGetData(){
    this.api.getAllemployeeMaster(0,0,'','','').subscribe((data:any)=>{
      this.mails=data['data'];
    })
  }
  // obj = ({
      
  //      "filter":"this.quotationDateFilter"
      
  //      })
  
 
  onGet(){
    this.api.getAllMembers(0,0,'','','').subscribe((data:any)=>{
      this.options=data['data'];
    })
  }
  onGetReports(){
    this.api.getMembers(0,0,'','','').subscribe((data:any)=>{
      this.assign=data['data'];
    })
  }
 
 
  onSubmit(addNew: boolean, httpForm: NgForm) {
   this.isOk = true;
   if (
    this.data.MAIL_LIST == null &&
    this.data.TIME == null || this.data.TIME == '' &&
    this.data.TYPE_OF_ATTACHMENT == '' &&
    this.data.TYPES == null|| this.data.TYPES == '' 
   ) {
    this.isOk = false;
    this.message.error('Please fill all the required fields ', '');
  }

   else if (this.data.MAIL_LIST == null ) {
        this.isOk = false
        this.message.error('Please Select Mail ID.', "")
      }
      else if (this.data.TIME == null || this.data.TIME == '') {
      this.isOk = false
      this.message.error('Please Select Frequency.', "")
    }
    else if (this.data.TYPE_OF_ATTACHMENT == '' ) {
      this.isOk = false
      this.message.error('Please Select the Type of Attachment.', "")
    }
    else if (this.data.TYPES == null|| this.data.TYPES == '' ) {
      this.isOk = false
      this.message.error('Please Select Frequency Type.', "")
    }
    if (this.isOk) {
    
    if(this.data.TYPES=="W"){
        if( this.data.DAYS.length>0){
          // this.data.DAYS = this.datepipe.transform(this.data.DAYS, 'E');
          this.data.DAYS=this.data.DAYS.toString()
        
       }
      }
      else {
        this.data.DAYS=null
      }
      if(this.data.TYPES=="M"){
        if( this.data.MONTHS.length>0){
          
     this.data.MONTHS=this.data.MONTHS.toString() 
        }
      }
      else {
        this.data.MONTHS=null
      }
      if(this.data.TYPES=="Y"){
        this.familyDetails = this.datepipe.transform(this.familyDetails, 'MM-dd');
       this.data.YEARS=this.familyDetails.toString()
       
       
       console.log(this.data.YEARS);
       }
      else {
        this.data.YEARS=null
      }
    if(this.data.TYPES=="C"){
      this.data.CUSTOM = this.datepipe.transform(this.data.CUSTOM, 'yyyy-MM-dd');
      }
      else{
        this.data.CUSTOM=null
      }
    
   
    this.data.MAIL_LIST=this.data.MAIL_LIST.toString()
    this.data.TIME=this.TimeDetails.toString()
    this.data.FILTER_QUERY=this.FILTER_QUERY
    this.data.EMPLOYEE_ID=+this.userId
   

      if (this.data.ID) {
        this.isSpinning = true;
        this.api.update11(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Schedular Information Updated Successfully", "");
            this.onGet();
                if (!addNew)
                this.reset(httpForm);
                this.drawerClose();
                this.isSpinning = false;
                }
         else {
            this.message.error("Schedular Information Updation Failed", "");
            this.isSpinning = false;
          }
        });
      } 

     else {
      this.isSpinning = true;
      this.data.FILTER_QUERY=this.FILTER_QUERY
     
      this.api.create11(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.loadingRecords=false
            this.isSpinning=false
            this.message.success("Schedular Information Created Successfully", "");
            this.onGet();
           if (!addNew) {
             this.reset(httpForm);
              this.drawerClose();
              this.isSpinning=false
            } else {
              this.data = new schedule();
              this.reset(httpForm);
              this.isSpinning=false
             }
             console.log(this.options)
          }
          });
       }
      }

       this.familyDetails=[]
       this.TimeDetails=[]
       this.data.TYPE_OF_ATTACHMENT=''
       }
   close(httpForm: NgForm) {
    console.log(this.data.TIME)
    this.isSpinning=false;
    this.reset(httpForm);
    this.drawerClose();
    
    this.familyDetails=[]
    this.TimeDetails=[]
    this.data.TYPE_OF_ATTACHMENT=''
   
  }

  reset(httpForm: NgForm) {
    this.isSpinning = true;
    httpForm.reset();
  }
  @Input()familyDetails:any
  i=2
  addFamilyDetails() {

    if (this.familyDetails.length == 0) {
      this.familyDetails = [
        this.DATE1+""+this.MONTH1
        ];
    }
    else {
      this.familyDetails = [
        ...this.familyDetails,
          this.DATE1+""+this.MONTH1
        ];
     
      this.i++;
    }
    this.data.YEARS=this.familyDetails
  }

  addData() {
    if (this.DATE1 != undefined && this.MONTH1 != undefined ) {
        this.addFamilyDetails()
      }
    else {
      this.message.error("Please Fill Accurate Details", "");
    }
}
j=2
  addTimeDetails() {
    
    if (this.TimeDetails.length == 0) {
      this.TimeDetails = [
         this.HOUR+":"+this.MINUTES+":"+'00'
        ];
      console.log(this.TimeDetails,"time1....")
     }
    else {
      this.TimeDetails = [
        ...this.TimeDetails,
          this.HOUR+":"+this.MINUTES+":"+'00'
      ];
      console.log(this.TimeDetails,"time....")

      this.data.TIME=this.TimeDetails
      this.j++;
    }
    this.data.TIME=this.TimeDetails
  }

  addTime() {
    if (this.HOUR != undefined && this.MINUTES != undefined ) {
        this.addTimeDetails()
      }
    else {
      this.message.error("Please Fill Accurate Details", "");
    }
  }

    }

