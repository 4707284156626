import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import * as xlsx from 'xlsx';
import { ExportService } from 'src/app/Service/export.service';


@Component({
  selector: 'app-sales-executive-sotreport',
  templateUrl: './sales-executive-sotreport.component.html',
  styleUrls: ['./sales-executive-sotreport.component.css']
})
export class SalesExecutiveSOTreportComponent implements OnInit {
  formTitle = "Sales Executive Wise Detailed SOT Report";
  DATE: any = []
  
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'SalesExecutiveWiseDetailedSOTReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [["SALES_CONSULTANT_NAME", "Sales Executive"], ["MODEL", "Model"], ["CUSTOMER_NAME", "Customer Name"],["VARIENT_NAME","Variant Name"],["IS_SOT", "Is SOT"], ["SOT_AMOUNT", "Slab"], ["SALES_MANAGER_NAME", "SM Name"], ["SOT_SALES_MANAGER_RATE", "SM Slab"]];
  STATUS = "AL";
  BRANCH = [];
  SALES_CONSULTANT_ID: any;
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;
  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  sales = []


  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private message: NzNotificationService,  private _exportService: ExportService) { }

  ngOnInit(): void {
    this.search();
    this.getBranch();
    this.getSales();
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }

  dates: any = [];

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue;
  };
  current = new Date();


  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue;
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }



  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getSales() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=28')
      .subscribe(data => {
        this.sales = data['data']
      })
  }


  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  FROM_DATE: any = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  TO_DATE: any = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

  applyFilter() {

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')


    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";
    // if (this.startValue != null && this.endValue != null) {
    //   this.DATE = " AND FROM_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "
    // }

    if (this.BRANCH_ID != undefined || this.BRANCH_ID > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.SALES_CONSULTANT_ID != undefined) {
      this.SALES_CONSULTANT_ID = this.SALES_CONSULTANT_ID;
    }
    if (this.startValue != null) {
      this.FROM_DATE = this.startValue
    }
    if (this.endValue != null) {
      this.TO_DATE = this.endValue
    }
    this.search(true);
    this.isFilterApplied="primary"
  }


  LIKE_STRING:any

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date();
    this.endValue = new Date();
    this.BRANCH_ID = [];
    this.SALES_CONSULTANT_ID = [];
    this.search(true);
    this.isFilterApplied = 'default';

  }
  sotdata = [];
  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    this.LIKE_STRING=this.searchText;
    // if (this.searchText != '') {
    //   likeQuery = ' AND (';
    //   this.columns.forEach((column) => {
    //     likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    // }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.FROM_DATE = this.startValue
    }
    if (this.endValue != null) {
      this.TO_DATE = this.endValue
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.salesExecutiveWiseDetailedSot(this.pageIndex, this.pageSize, this.sortKey, sort, this.LIKE_STRING + this.filterQuery, this.BRANCH_ID, this.SALES_CONSULTANT_ID, this.FROM_DATE, this.TO_DATE)
        .subscribe(
          (data) => {

            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';
          },
          (err) => {
            console.log(err);
          });
    }
    else {
      this.exportLoading = true;

      this.api.salesExecutiveWiseDetailedSot(0,0, this.sortKey, sort, this.LIKE_STRING, this.BRANCH_ID, this.SALES_CONSULTANT_ID, this.FROM_DATE, this.TO_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.sotdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }

  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue);
    this.search(true);
  }

  // exportexcel(): void {
  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Sales Executive SOT Report');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.sotdata .length; i++) {
      obj1['Sales Executive Name'] = this.sotdata [i]['SALES_CONSULTANT_NAME'];
      obj1['Model Name'] = this.sotdata [i]['MODEL_NAME'];
      obj1['Customer Name'] = this.sotdata [i]['CUSTOMER_NAME'];
      obj1['Variant Name'] = this.sotdata [i]['VARIENT_NAME'];
      obj1['Is SOT'] = this.sotdata [i]['IS_SOT'];
      obj1['Slab'] = this.sotdata [i]['SOT_AMOUNT'];
      obj1['SM Name'] = this.sotdata [i]['SALES_MANAGER_NAME'];
      obj1['SM Slab'] = this.sotdata [i]['SOT_SALES_MANAGER_RATE'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.sotdata .length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Detailed SOT Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}

