import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-calling-summary-report',
  templateUrl: './calling-summary-report.component.html',
  styleUrls: ['./calling-summary-report.component.css']
})
export class CallingSummaryReportComponent implements OnInit {

  formTitle = "Calling Summary Report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'CallingSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [['CALL_EXECUTIVE_NAME', "Call Executive Name"], ["TOTAL_CALLS", "Total Calls"], ["LOST", "Total Calls Lost"], ["NOT_CONNECTED", "Not Connected"],
  ["WORK_DONE", "Work Done"], ["WRONG_NUMBER", "Wrong Number"], ["APPOINTMENT", "Appointment"], ["RESCHEDULED", "Rescheduled"]]
  columns1: string[][] = [['SALES_EXECUTIVE_NAME', "Call Executive Name"]]
  BRANCH = [];
  SalesExecutive = [];
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  leaves = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));

  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  FROM_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  // startOpen = false;
  employees = [];
  EXECUTIVE_ID: any = [];
  DATE: any

  CurrentValue: any = new Date();

  dateQuery: string = "";


  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }


  counter: number = 1;
  ngOnInit(): void {

    this.dataList.forEach(() => {
      this.counter++;
    });

    this.getEmployee();
    this.CurrentValue = new Date();

    setTimeout(() => {
      this.search(false);
    }, 2000)

  }
  total:any
  calculateTotal(){
    this.total = 0
    this.LOSTT = 0
    this.NOT_CONNECTEDD=0
    this.WORK_DONEE = 0
    this.WRONG_NUMBERR = 0
    this.APPOINTMENTT=0
    this.RESCHEDULEDD = 0

    for (let e = 0; e < this.dataList.length; e++) {
       this.total += this.dataList[e]['TOTAL_CALLS']
       this.LOSTT += this.dataList[e]['LOST']
       this.NOT_CONNECTEDD += this.dataList[e]['NOT_CONNECTED']
       this.WORK_DONEE += this.dataList[e]['WORK_DONE']
       this.WRONG_NUMBERR += this.dataList[e]['WRONG_NUMBER']
       this.APPOINTMENTT += this.dataList[e]['APPOINTMENT']
       this.RESCHEDULEDD += this.dataList[e]['RESCHEDULED']       
    }    
  }

  LOSTT:any
  NOT_CONNECTEDD :any
  WORK_DONEE 
  WRONG_NUMBERR
  APPOINTMENTT
  RESCHEDULEDD
 
  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe((data) => {
      if (data['code'] == 200) {
        this.employees = data['data'];
        this.onSelectAllCallExicutive(true)
      }
    }, err => {
      console.log(err);
    });
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  dates: any = [];
  today2 = new Date();



  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  current = new Date();



  onStartChange(date: Date): void {
    this.FROM_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {

    this.filterClass = "filter-invisible";

    this.isFilterApplied = "Primary"
    // this.isSpinning = true
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    if (this.EXECUTIVE_ID == 0) {
      this.message.error("Please Select Option To Filter", "")
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "default";

    }
    this.dateQuery = '';
    this.CurrentValue = this.datePipe.transform(this.CurrentValue, 'yyyy-MM-dd');

    if (this.CurrentValue != null) {
      this.DATE = this.CurrentValue
    }

    if (this.EXECUTIVE_ID != null && this.EXECUTIVE_ID != 0 && this.EXECUTIVE_ID != undefined) {
      this.EXECUTIVE_ID = this.EXECUTIVE_ID
    }



    this.search();
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);

    }
    // this.api.CallingSummaryReport(1, this.pageSize, this.sortKey,sort, this.filterQuery,this.DATE,this.EXECUTIVE_ID).subscribe((data) => {
    //         console.log(data);
    //         this.loadingRecords = false;
    //         this.isFilterApplied = 'primary';
    //         this.totalRecords = data['count'];
    //         this.dataList = data['data'];
    //         this.isSpinning = false;
    //         this.filterClass = 'filter-invisible';
    // },
    //         (err) => {
    //           console.log(err);
    //         });
    //   console.log(this.filterQuery);

  }
  SELECT_EXECUTIVE: boolean = false;

  onSelectAllCallExicutive(event: any) {
    this.SELECT_EXECUTIVE = event

    let ids = [];
    if (this.SELECT_EXECUTIVE == true) {
      for (var i = 0; i < this.employees.length; i++) {
        ids.push(this.employees[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.EXECUTIVE_ID = ids
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  dataList2 = []
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }


    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }
    this.CurrentValue = this.datePipe.transform(this.CurrentValue, 'yyyy-MM-dd')


    if (this.CurrentValue != null) {
      this.DATE = this.CurrentValue
    }
    if (this.roleId == 43) {
      if (this.EXECUTIVE_ID != null && this.EXECUTIVE_ID != 0 && this.EXECUTIVE_ID != undefined) {
        this.EXECUTIVE_ID = this.userId
      }
    }
    else {
      if (this.EXECUTIVE_ID != null && this.EXECUTIVE_ID != 0 && this.EXECUTIVE_ID != undefined) {
        this.EXECUTIVE_ID = this.EXECUTIVE_ID
      }
    }


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.CallingSummaryReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery, this.DATE, this.EXECUTIVE_ID).subscribe(data => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.calculateTotal()
      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.CallingSummaryReport(0, 0, this.sortKey, this.sortValue, this.filterQuery + likeQuery, this.DATE, this.EXECUTIVE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList2 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }





  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }

    this.search(true);
  }

  onSelectOff3(event1: any) {
    var a = this.employees.length;
    var b = this.employees.length - event1.length;
    if ((a! = b)) {
      this.SELECT_EXECUTIVE = false;
    } else {
      this.SELECT_EXECUTIVE = true;
    }
    this.EXECUTIVE_ID = event1;
    if (this.EXECUTIVE_ID.length == 0) {
      this.SELECT_EXECUTIVE = false;
    }
  }

  clearFilter() {
    this.CurrentValue = new Date();
    this.filterQuery = '';
    this.dataList = [];


    // this.EXECUTIVE_ID = [];
    let ids = [];
    this.SELECT_EXECUTIVE = true
    for (var i = 0; i < this.employees.length; i++) {
      ids.push(this.employees[i]["EMPLOYEE_ID"]);
    }
    this.EXECUTIVE_ID = ids;
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Call Executive Name'] = this.dataList2[i]['CALL_EXECUTIVE_NAME'];
      obj1['Total Calls '] = this.dataList2[i]['TOTAL_CALLS'];
      obj1['Total Calls Lost'] = this.dataList2[i]['LOST'];
      obj1['Not Connected'] = this.dataList2[i]['NOT_CONNECTED'];
      obj1['Work Done'] = this.dataList2[i]['WORK_DONE'];
      obj1['Wrong Number'] = this.dataList2[i]['WRONG_NUMBER'];
      obj1['Appointment'] = this.dataList2[i]['APPOINTMENT'];
      obj1['Rescheduled'] = this.dataList2[i]['RESCHEDULED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Calling Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}


