import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { tourDestinationMaster } from 'src/app/Models/tourDestination';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-tour-destination-form',
  templateUrl: './tour-destination-form.component.html',
  styleUrls: ['./tour-destination-form.component.css']
})
export class TourDestinationFormComponent implements OnInit {

  @Input() drawerClose!: Function;
  @Input() data: tourDestinationMaster = new tourDestinationMaster();
  @Input() drawerVisible: boolean = false;
  isSpinning = false;

  @Input() listOfData: any
  isOk = true;
 

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getData()
  }
  dataList: any = []

  getData() {
    this.api.getTourDestination(0, 0, '', '', '').subscribe((data) => {
      this.dataList = data['data'];
      console.log('this.dataList', this.dataList);
    })
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {

    this.isOk = true;

    if (this.data.DESTINATION_NAME == undefined && this.data.SHORT_CODE == undefined) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");
    }
    else if
      (this.data.DESTINATION_NAME == undefined || this.data.DESTINATION_NAME == '') {
      this.isOk = false
      this.message.error('Please Enter Tour Destination Name', '')
    }
    else if (this.data.SHORT_CODE == undefined || this.data.SHORT_CODE == '') {
      this.isOk = false
      this.message.error('Please Enter Valid Short Code.', "")
    }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {

        this.isSpinning = false;

        var bundleData = this.listOfData.filter((obj: any) => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });


        if (bundleData.length == 0) {
          this.api.updateTourDestination(this.data).subscribe((successCode: any) => {
            if (successCode['code'] == 200) {
              this.getData();
              // this.isSpinning = true;
              this.message.success("Tour Destination Updated successfully...", "");
              console.log(this.data, 'Update Success')
              if (!addNew) {
                this.drawerClose();
                this.reset(myForm);
                this.isSpinning = false;
              }
              else {
                this.data = new tourDestinationMaster();
                this.message.error("Failed To Update", "");
                console.log(this.data, "fail Update")

                this.isSpinning = false;
              }
              // console.log(this.data.APPLICABLE_TYPES);
            }

          });
        }

        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }
      else {
        this.isSpinning = true;

        var bundleData = this.dataList.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });

        if (bundleData.length == 0) {
          console.log(this.dataList, 'Code')

          this.api.createTourDestination(this.data)
            .subscribe(successCode => {

              if (successCode['code'] == 200) {
                this.message.success("Tour Destination added successfully...", "");
                console.log(this.data, "create Success");
                this.getData();
                if (!addNew) {
                  this.isSpinning = true;
                  myForm.reset();
                  this.drawerClose();

                }
                else {
                  this.data = new tourDestinationMaster();
                  myForm.resetForm();

                }
                this.isSpinning = false;
              }
              else {
                this.message.error("Failed to add Tour Destination...", "");
                console.log(this.data, "Fail data")

                this.isSpinning = false;
              }
            });

        }

        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }

    }
  }
}
