import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { actionMaster } from 'src/app/Models/actionMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-action-master-form',
  templateUrl: './action-master-form.component.html',
  styleUrls: ['./action-master-form.component.css']
})
export class ActionMasterFormComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: actionMaster;
  @Input() listOfData2: any = []

  columns: string[][] = [["ACTION_NAME", "Action Name"]]

  constructor(private message: NzNotificationService, private api: ApiService, private datepipe: DatePipe) { }
  isSpinning = false;

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;
  searchText: string = "";
  sortKey: string = "id";
  sortValue: string = "desc";

  loadingRecords = false;
  date = null;
  isOk = false;
  listOfData: any = []


  ngOnInit() {
    // this.getData();
    this.search();
  }
  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    // console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


    this.api.getActionMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue,'').subscribe(data => {
      // console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.listOfData = data['data'];


    }, err => {
      // console.log(err);
    });
  }

  getData() {
    this.api.getActionMaster(this.pageIndex, this.pageSize, '', '', '' ).subscribe((data) => {
      this.listOfData = data['data'];
      // console.log('this.listOfData', this.listOfData);
      // this.listOfOptions1 = data['data'];
      // console.log(this.listOfOptions1,"hhghh");
    })
  }

  cancel(){ 
  }
  // deleterow(index: any) {
  //   var selected = this.ROLES[index];
  //   var d = this.dataList.filter((item) => {
  //     if (item.ROLE_ID != this.ROLES[index])
  //       return item;
  //   })

  //   // this.ROLES = this.ROLES.filter(item => item !== selected)
  //   this.ROLES.splice(index, 1);
  //   this.ROLES22.splice(index, 1);
  //   this.ROLES = [...[], ...this.ROLES]
  //   this.ROLES22 = [...[], ...this.ROLES22]
  //   this.dataList = [...[], ...d]
  //   this.finalData = [...[], ...d];
  // }

  onSubmit(addNew: boolean, httpForm: NgForm) {

    this.isOk = true;
    if (this.data.ACTION_NAME == null || this.data.ACTION_NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Name.', "")
    }
    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO == "") {
      this.isOk = false
      this.message.error('Please Enter Sequence No.', "")
    }  


    if (this.isOk) {
      this.isSpinning = true;
      if (this.data.ID) {        

        this.isSpinning = false;

          this.api.updateAction(this.data).subscribe((successCode: any) => {
            if (successCode['code'] == 200) {
              this.getData();
              // this.isSpinning = true;
              this.message.success("Action Record Updated Successfully...", "");
              // console.log(this.data, 'Update Success')
              if (!addNew) {
                this.drawerClose();
                this.reset(httpForm);
                this.isSpinning = false;
              }
              else {
                this.data = new actionMaster();
                this.message.error("Failed To Update Action Record", "");
                // console.log(this.data, "fail Update")

                this.isSpinning = false;
              }
              // console.log(this.data.APPLICABLE_TYPES);
            }

          });
        }

      else {
        this.isSpinning = true;

        this.api.createAction(this.data)
            .subscribe(successCode => {

              if (successCode['code'] == 200) {
                this.message.success("Action Record Added Successfully...", "");
                // console.log(this.data, "create Success");
                httpForm.resetForm();
                this.getData();
                if (!addNew) {
                  // this.isSpinning = true;
                  this.drawerClose();

                }
                else {
                  this.data = new actionMaster();
                  httpForm.resetForm();
                  
                }
                this.isSpinning = false;
              }
              else {
                this.message.error("Failed To Add Action Record...", "");
                // console.log(this.data, "Fail data")

                this.isSpinning = false;
              }
            });

        }
    }
  }

  close(httpForm: NgForm) {
    // this.isSpinning=true;
    this.reset(httpForm);
    this.drawerClose();
  }

  reset(httpForm: NgForm) {
    // this.isSpinning = true;
    httpForm.reset();
  }
}
