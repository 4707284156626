import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { isUndefined } from 'util';

@Component({
  selector: 'app-salesexecutivewise-rsasummaryreport',
  templateUrl: './salesexecutivewise-rsasummaryreport.component.html',
  styleUrls: ['./salesexecutivewise-rsasummaryreport.component.css']
})
export class SalesexecutivewiseRSAsummaryreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Sales Executive Wise RSA Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  // DATE:any
  // MODEL:any=[];
  MONTH: any = [];
  YEAR: any = []
  // BRANCH:any;
  models: any = [];
  SALESEXECUTIVE: any = [];
  salesexecutive: any = [];
  // branch:any=[];
  // quotationdate:any;
  isOk = true;
  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive"]];
  exportLoading: boolean;
  dataList1: any;
  // FROMDATE:any;
  // TODATE:any;
  fromdate = new Date();
  todate = new Date();
  FROMDATE: any = new Date(this.fromdate.getFullYear(), this.fromdate.getMonth(), 1);
  TODATE: any = new Date(this.todate.getFullYear(), this.todate.getMonth(), this.todate.getDate());
  // this.FROMDATE=this.datePipe.transform(this.FROMDATE,"yyyy-MM-dd");
  // this.TODATE=this.datePipe.transform(this.TODATE,"yyyy-MM-dd");

  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    // this.MODEL="";
    // this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
    //   this.models = data['data']
    // })
    // this.BRANCH="AL";
    // this.quotationdate=new Date();
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")
    // this.applyFilter();
    // var fromdate =new Date();
    // var todate =new Date();
    // this.FROMDATE=new Date(fromdate.getFullYear(), fromdate.getMonth(), 1);
    // this.TODATE=new Date(todate.getFullYear(), todate.getMonth(), todate.getDate());
    // this.FROMDATE=this.datePipe.transform(this.FROMDATE,"yyyy-MM-dd");
    // this.TODATE=this.datePipe.transform(this.TODATE,"yyyy-MM-dd");
    this.getsalehead();
    // this.applyFilter();
    // this.search(false);
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      
      SALESEXECUTIVE:this.SALESEXECUTIVE, 
      FROMDATE:this.FROMDATE, 
      TODATE:this.TODATE, 
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 63').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }
  startDateChange() {
    var startDate = this.datePipe.transform(this.FROMDATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }
  monthchange(event: any) {
    this.MONTH = this.datePipe.transform(this.MONTH, "MM")
  }
  yearchange(event: any) {
    this.YEAR = this.datePipe.transform(this.YEAR, "yyyy")
  }
  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(
      data => {
        if (data['code'] == 200) {
          this.salesexecutive = data['data'];
          this.onSelectAllSalesExe(true);
          this.search(false);
          // console.log(this.salesexecutive);
        }
      }, err => { console.log(err); });
  }

  SELECT_SALES_EXE: boolean = false;
  onSelectAllSalesExe(event: any) {
    this.SELECT_SALES_EXE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXE == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESEXECUTIVE = ids
  }

  onSelectOff(event: any) {
    // var branchmanager1 = []
    //  console.log(event)
    this.SALESEXECUTIVE = event
    // this.BRANCH_ID=[]
    if (this.SALESEXECUTIVE != undefined) {


      this.SALESEXECUTIVE = this.SALESEXECUTIVE
      this.SELECT_SALES_EXE=false
      console.log(this.SALESEXECUTIVE, " console.log(this.BRANCH_ID);")
    }


    else {
      this.SALESEXECUTIVE = []
      console.log(this.SALESEXECUTIVE, "");
    }
  }

  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    
    this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
    this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      this.sortValue = "desc"
      // this.dataList = []
    }


    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    // else {
    //   // this.applyFilter()
    // }
    // if(this.DATE==null)
    // {
    //   this.DATE=""
    // }
    // else{
    //   this.DATE=""
    // }
    //  this.api.getSalesExWiseRSAsummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,this.SALESEXECUTIVE,this.DATE).subscribe(data => {
    //     console.log(data)
    //     if(data['code']==200)
    //     {
    //     this.loadingRecords = false;
    //     this.isSpinning=false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //     }
    //     else{
    //     this.isSpinning=false;
    //     this.loadingRecords = false;
    //     }
    //   }, err => {
    //     this.isSpinning=false;
    //     this.loadingRecords = false;
    //     console.log(err);
    //   });
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getSalesExWiseRSAsummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.SALESEXECUTIVE, this.FROMDATE, this.TODATE).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
        // else {
        //   this.loadingRecords = false;
        //   this.isSpinning = false;
        // }
      }, err => {
        console.log(err);
        // this.loadingRecords = false;
        // this.isSpinning = false;
      });
    }
    else {
      this.exportLoading = false;
      this.api.getSalesExWiseRSAsummaryreport(0, 0, this.sortKey, sort, likeQuery, this.SALESEXECUTIVE, this.FROMDATE, this.TODATE).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          // this.loadingRecords = false;
          // this.isSpinning = false;
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            //   this.exportLoading = false;
            // this.loadingRecords = false;
            // this.isSpinning = false;
            this.message.error("Server Not Found", "");
        });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
    this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    // this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")
    if (this.SALESEXECUTIVE == undefined && this.MONTH == undefined && this.YEAR == isUndefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false;
      this.isFilterApplied = "default";
    }
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    // else {
    //   // this.applyFilter()
    // }
    // if (this.isOk == true) {
    //   this.api.getSalesExWiseRSAsummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.SALESEXECUTIVE, this.FROMDATE, this.TODATE).subscribe(data => {
    //     console.log(data)
    //     if (data['code'] == 200) {
    //       this.isFilterApplied = "primary";
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //       this.search(false)
    //       // this.convertInExcel();
    //       this.dataList1 = data['data'];
    //       this.totalRecords = data['count'];
    //       this.dataList = data['data'];
    //     }
    //     else {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.loadingRecords = false;
    //     this.isSpinning = false;
    //   });
    // }
    this.search(true)
  }
  clearFilter() {
    //  this.MODEL=null;
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";

    this.fromdate = new Date();
    this.todate = new Date();
    this.FROMDATE = new Date(this.fromdate.getFullYear(), this.fromdate.getMonth(), 1);
    this.TODATE = new Date(this.todate.getFullYear(), this.todate.getMonth(), this.todate.getDate());
    this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
    this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    this.SELECT_SALES_EXE = true;
    // this.MONTH = [];
    // this.YEAR = [];
    //  this.BRANCH="";

    //  this.isFilterApplied="default";
    this.getsalehead();

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Name Of Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['RSA Sold'] = this.dataList1[i]['RSA_SOLD'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise RSA Summary Report"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}
