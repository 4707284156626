import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-modelwisequotationdetails',
  templateUrl: './modelwisequotationdetails.component.html',
  styleUrls: ['./modelwisequotationdetails.component.css']
})
export class ModelwisequotationdetailsComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  
  // dataList = [];
  formTitle = "Model Wise Quotation Detailed Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  MODEL: any = [];
  BRANCH: any = [];
  // model: any = [];
  branch: any = [];
  quotationdate: any;
  models: any = [];
  datalist: any;
  isOk = true;
  // isFilterApplied: string = "default";
  columns: string[][] = [["MODEL_NAME", "Model"], ["BRANCH_NAME", "Branch"], ["SALES_EXECUTIVE_NAME", "Executive"], ["CUSTOMER_NAME", "Customer"],];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe,private _exportService: ExportService) { }

  ngOnInit() {
    this.getAllmodels();
    this.quotationdate = new Date();
    this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")

  }
  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = { 
      MODEL:this.MODEL, 
      BRANCH:this.BRANCH, 
      quotationdate:this.quotationdate
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 18').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  drawerClose1(){
    
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }
  getAllmodels() {
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data'];
      this.onSelectAllModel(true);
      this.getAllBranch();
    })
  }
  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }
  onSelectOffModel(event: any) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL = event;
    if (this.MODEL.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  getAllBranch() {
    this.api.getAllBranch(0, 0, "", "", "").subscribe(data => {
      this.branch = data['data'];
      // this.BRANCH = data['data'];
      this.onSelectAllBranch(true);
      this.search(true);
    })
  }

  SELECT_BRANCH: boolean = false;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }
  onSelectOff(event: any) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  modelQuo=[];

  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      this.sortValue = "desc"
      // this.dataList = []
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";

      var filterQuery = this.filterQuery + likeQuery

      // this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery,this.applicationId,0).subscribe(data => {
      //   console.log(data)
      //   this.loadingRecords = false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      // }, err => {
      //   console.log(err);
      // });
    }
    // else {
    //   // this.applyFilter()
    // }
    if (exportInExcel == false) {
      this.api.getModelWiseQuotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.BRANCH, this.quotationdate).subscribe(data => {
        console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.datalist = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';
          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.getModelWiseQuotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.BRANCH, this.quotationdate).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.modelQuo = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
    // this.api.getModelWiseQuotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.BRANCH, this.quotationdate).subscribe(data => {
    //   if (data['code'] == 200) {
    //     // this.exportLoading = false;
    //     this.datalist = data['data'];
    //     this.loadingRecords = false;
    //     this.isSpinning = false;
    //     this.search(false)
    //     // this.convertInExcel();
    //     this.totalRecords = data['count'];
    //     // this.dataList = data['data'];
    //   }
    //   else {
    //     this.loadingRecords = false;
    //     this.isSpinning = false;
    //   }
    // }, err => {
    //   console.log(err);
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    // });
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
   
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
    this.filterQuery = "";
    if (this.MODEL != undefined && this.BRANCH != undefined && this.quotationdate != undefined) {
      //  this.message.error("Please Select Atleast One Option From The Following",'')
      //  this.filterQuery = " AND MODEL = " + this.MODEL + " AND BRANCH = " + this.BRANCH + " AND QUOTATION_DATE = " + this.quotationdate ;
      this.isSpinning = false;
      this.loadingRecords = false;
      // this.isFilterApplied = "default";
      this.isOk = false;
      console.log("FilterQuery", this.filterQuery)

    }
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    // else if(this.MODEL != undefined  && this.BRANCH == undefined && this.quotationdate == undefined){
    //   this.filterQuery = " AND MODEL = " + this.MODEL;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined  && this.BRANCH != undefined && this.quotationdate == undefined){
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined  && this.BRANCH == undefined && this.quotationdate != undefined){
    //   this.filterQuery = " AND QUOTATION_DATE = " + this.quotationdate;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL != undefined  && this.BRANCH != undefined && this.quotationdate == undefined){
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND BRANCH = " + this.BRANCH;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL != undefined  && this.BRANCH == undefined && this.quotationdate != undefined){
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND QUOTATION_DATE = " + this.quotationdate;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined  && this.BRANCH != undefined && this.quotationdate != undefined){
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH + " AND QUOTATION_DATE = " + this.quotationdate;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined && this.BRANCH ==undefined && this.quotationdate ==undefined){
    //   this.message.error("Please Select Option To Filter",'')
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="default";

    // }
    // if (this.isOk == true) {
    //   // this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,""+this.MODEL).subscribe(data => {
    //   //   console.log(data)
    //   //   if(data['code']==200)
    //   //   {
    //   //   this.loadingRecords = false;
    //   //   this.isSpinning=false;
    //   //   this.totalRecords = data['count'];
    //   //   this.dataList = data['data'];
    //   //   }
    //   //   else{}
    //   // }, err => {
    //   //   console.log(err);
    //   // });
    //   this.api.getModelWiseQuotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.BRANCH, this.quotationdate).subscribe(data => {
    //     if (data['code'] == 200) {
    //       // this.exportLoading = false;
    //       this.datalist = data['data'];
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //       this.search(false)
    //       // this.convertInExcel();
    //       this.totalRecords = data['count'];
    //       // this.dataList = data['data'];
    //     }
    //     else {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.loadingRecords = false;
    //     this.isSpinning = false;
    //   });
    // }
    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";
    //  this.quotationdate=new Date();
    this.quotationdate = new Date();
    this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
    // this.BRANCH = [];
    this.SELECT_BRANCH = true;
    this.SELECT_MODEL = true;

    this.getAllmodels();

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue)
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.modelQuo.length; i++) {
      obj1['Model'] = this.modelQuo[i]['MODEL_NAME'];
      obj1['Quotation Number'] = this.modelQuo[i]['QUOTATION_NO'];
      obj1['Date'] = this.modelQuo[i]['DATE'];
      obj1['Branch Name'] = this.modelQuo[i]['BRANCH_NAME'];
      obj1['Sales Executive Name'] = this.modelQuo[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.modelQuo[i]['CUSTOMER_NAME']; 
      obj1['Total Cost'] = this.modelQuo[i]['TOTAL_AMOUNT'];
      obj1['Total Discount Given'] = this.modelQuo[i]['TOTAL_DISCOUNT'];
      obj1['Approver Name'] = this.modelQuo[i]['APPROVER_NAME'];
      obj1['Discount Amount'] = this.modelQuo[i]['DISCOUNT_AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.modelQuo.length - 1) {
        this._exportService.exportExcel(arry1, 'Model Wise Quotation Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
