import { Component, OnInit, Input } from '@angular/core';
import { feedbackforms } from 'src/app/Models/feedbackforms';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Role } from 'src/app/Models/role';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-feedbackform',
  templateUrl: './feedbackform.component.html',
  styleUrls: ['./feedbackform.component.css']
})
export class FeedbackformComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: feedbackforms;
  passwordVisible = false;
  password: string;
  isSpinning = false;
  dataList: feedbackforms;
  roleLoading = false;
  roles: Role[];
  image: string;
  event1: string;

  userId = Number(this.cookie.get("userId"));

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
    });
    this.showareaname()
  }
  showareaname() {
    this.api.getAllfeedarea(0, 0, '', '', ' AND STATUS = 1').subscribe(data => {
      this.dataList = data['data'];

    },);
  }
  FEEDBACK_TYPE
  FEEDBACK_TYPE1
  getAreaType(event) {
    this.api.getAllfeedarea(0, 0, '', '', ' AND ID = ' + event,)
      .subscribe(data => {
        this.FEEDBACK_TYPE = data['data'][0]['TYPE']
        if (this.FEEDBACK_TYPE == 'F') {
          this.data.FEEDBACK_TYPE = 'F'
        }
        else {
          this.data.FEEDBACK_TYPE = 'C'
        }
      },

      );
  }

  close(): void {
    this.drawerClose();
  }
  fileURL
  onFileSelected(event: any) {
    const reader = new FileReader();
    let isLtsize = false;
    let imgs = new Image()
    imgs.src = window.URL.createObjectURL(event.target.files[0]);
    imgs.onload = () => {
      isLtsize = true;
      this.fileURL = <File>event.target.files[0];
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.image = reader.result as string;

        };



        var number = Math.floor(100000 + Math.random() * 900000);
        console.log('number', number);

        var fileExt = this.fileURL.name.split('.').pop();
        console.log('fileExt', fileExt);

        var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
        console.log(d, 'd');

        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        // url =  d + number + '.' + fileExt;
        console.log(url, 'url');
        this.event1 = url
      }
    }
  }
  removeImage() {
    this.data.ATTACHMENT_URL = '';
    this.fileURL = '';

  }
  date = new Date()
  save(addNew: boolean): void {
    console.log(this.fileURL);
    var isok = true
    if (this.data.AREA_ID == undefined) {
      isok = false
      this.message.error("Please select area ", "")
    }
    else if (this.data.FEEDBACK_TYPE == '' || this.data.FEEDBACK_TYPE == undefined || this.data.FEEDBACK_TYPE == null ) {
      isok = false
      this.message.error("Please select area type", "")
    }
    else if (this.data.FEEDBACK == ''||this.data.FEEDBACK == undefined || this.data.FEEDBACK == null ) {
      isok = false
      this.message.error(" Please fill Feedback/Complaint ", "")

    }
    else {
      isok = true
    }
    if (isok) {
      this.isSpinning = true;
      this.data.FEEDBACK_TYPE = this.data.FEEDBACK_TYPE
      if (this.fileURL != null || this.fileURL != undefined) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.ATTACHMENT_URL != undefined &&
          this.data.ATTACHMENT_URL.trim() != ''
        ) {
          var arr = this.data.ATTACHMENT_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }
        this.api
          .onUpload12('feedbackAttachments', this.fileURL, url)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              this.data.ATTACHMENT_URL = url;
              console.log(url)
              this.data.EMPLOYEE_ID = this.userId
              if (this.data.ID) {
                this.api.updatefeedform(this.data).subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    this.message.success("feedback information updated Successfully...", "");
                    if (!addNew) { }

                    this.drawerClose();
                    this.removeImage()
                    this.isSpinning = false;

                  } else {
                    this.message.error("Failed to update feedback information...", "");
                    this.isSpinning = false;
                  }
                });

              } else {
                this.data.EMPLOYEE_ID = this.userId
                this.data.APPLIED_DATETIME = this.datePipe.transform(this.date, "yyyy-MM-dd")
                this.data.STATUS = "P"
                console.log(this.data.ATTACHMENT_URL, "ok")
                this.api.createfeedform(this.data).subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    this.message.success("feedback information added successfully...", "");

                    if (!addNew) {
                      this.drawerClose();
                      this.removeImage();
                    }
                    else {
                      this.data = new feedbackforms();
                      this.fileURL = null
                    }
                    this.isSpinning = false;

                  } else {
                    this.message.error("Failed to add feedback information...", "");
                    this.isSpinning = false;
                  }
                });
              }
            }
          })
      } else {
        if (this.data.ID) {
          this.api.updatefeedform(this.data).subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("feedback information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;

            } else {
              this.message.error("Failed to update feedback information...", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.data.EMPLOYEE_ID = this.userId
          this.data.APPLIED_DATETIME = this.datePipe.transform(this.date, "yyyy-MM-dd")
          this.data.STATUS = "P"
          console.log(this.data.ATTACHMENT_URL, "ok")
          this.api.createfeedform(this.data).subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("feedback information added successfully...", "");

              if (!addNew)
                this.drawerClose();
              else {
                this.data = new feedbackforms();
              }
              this.isSpinning = false;

            } else {
              this.message.error("Failed to add feedback information...", "");
              this.isSpinning = false;
            }
          });
        }
      }
    }

  }

  searchEmail(emailId): void {
    var likeQuery = "EMAIL_ID=" + emailId + "";

    this.api.getAllfeedform(0, 0, "", "", likeQuery).subscribe(data => {
      console.log(data);
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

}
