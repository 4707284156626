import { Component, OnInit } from '@angular/core';
import { tourDestinationMaster } from 'src/app/Models/tourDestination';
import { ApiService } from 'src/app/Service/api.service';


@Component({
  selector: 'app-tour-destination-master',
  templateUrl: './tour-destination-master.component.html',
  styleUrls: ['./tour-destination-master.component.css']
})
export class TourDestinationMasterComponent implements OnInit {

  formTitle = "Tour Destination Master";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DESTINATION_NAME", "Destination Name"], ["SHORT_CODE", "Short Code"]]
  drawerVisible!: boolean;
  drawerTitle!: string;


  drawerData: tourDestinationMaster = new tourDestinationMaster()
  data: tourDestinationMaster = new tourDestinationMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;

    console.log(this.sortValue + "kk");
    this.search(true);
  }

  // sort(sortKey: string, value: string): void {
  //   if (!sortKey || !value) {
  //     sortKey = this.sortKey;
  //     value = this.sortValue;
  //   }

  //   this.sortKey = sortKey;
  //   this.sortValue = value;
    
  //   for (const key in this.dataList) {
  //     this.dataList[key] = key === sortKey ? value : null;
  //   }
  //   this.search();
  // }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getTourDestination(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  listOfData:any=[]

  add(): void {
    // this.api.getTourDestination(0, 0, '', '', '').subscribe(data => {
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.drawerData = data['data'];

    // }, err => {
    //   console.log(err);
    // });

    this.drawerData = new tourDestinationMaster();
    this.drawerTitle = "Create New Tour Destination";
    this.drawerVisible = true;
  }


  edit(data: tourDestinationMaster): void {
    this.drawerTitle = "Update Tour Destination";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.api.getTourDestination(0, 0, '', '', " AND ID !=" + data.ID).subscribe(data => {
      this.listOfData = data['data'];

    }, err => {
      console.log(err);
    });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
