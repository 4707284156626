import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HolidayMaster } from 'src/app/Models/holiday-master';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { log } from 'console';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css'],
  providers: [DatePipe]
})

export class HolidaysComponent implements OnInit {
  formTitle = "Manage Holiday";
  // var a:number = 0
  holidayid: any;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "asc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DATE", "Date"], ["IS_HOLIDAY", "Holiday Status"], ["REASON", "Reason"]]

  monthFormat = "MMM-yyyy";
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()
  filterClass: string = "filter-visible";
  initFilter = true;
  month = this.today;
  isSpinning = false;

  data: HolidayMaster = new HolidayMaster();

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + " ) ";

    } else {
      this.filterQuery = ""

      if (this.month == undefined) {
        this.message.error("Please Select Month", "");
  
      } else {
        this.month = this.datePipe.transform(this.month, "yyyy-MM-dd");
        var splitmonth = this.month.split("-", -1);
        this.filterQuery = " AND MONTH(DATE) = '" + splitmonth[1] + "' AND YEAR(DATE) = '" + splitmonth[0] + "' AND ";
  
        this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);
        console.log(this.month.split("-", -1));
        console.log(this.filterQuery);
  
        this.isSpinning = true;
        this.loadingRecords = true;
        
        // this.api.getAllHolidays(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
        //   if (data['code'] == 200) {
        //     console.log(data);
        //     this.loadingRecords = false;
        //     this.isFilterApplied = "primary";
        //     this.totalRecords = data['count'];
        //     this.dataList = data['data'];
        //     this.filterClass = "filter-invisible";
        //     this.isSpinning = false;
        //   }
  
        // }, err => {
        //   console.log(err);
        // });
  
        this.filterClass = "filter-invisible";
      }
    }

    this.api.getAllHolidays(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.loadingRecords = false;
        this.isFilterApplied = "primary";
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.filterClass = "filter-invisible";
        this.isSpinning = false;
      }

    }, err => {
      console.log(err);
    });
  }

  applyFilter() {
    this.isSpinning = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "desc" : "asc";

    } catch (error) {
      sort = "";
    }
    this.search()

    // if (this.month == undefined) {
    //   this.message.error("Please Select Month", "");

    // } else {
    //   this.month = this.datePipe.transform(this.month, "yyyy-MM-dd");
    //   var splitmonth = this.month.split("-", -1);
    //   this.filterQuery = " AND MONTH(DATE) = '" + splitmonth[1] + "' AND YEAR(DATE) = '" + splitmonth[0] + "' AND ";

    //   this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);
    //   console.log(this.month.split("-", -1));
    //   console.log(this.filterQuery);

    //   this.isSpinning = true;
    //   this.loadingRecords = true;
      
    //   this.api.getAllHolidays(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
    //     if (data['code'] == 200) {
    //       console.log(data);
    //       this.loadingRecords = false;
    //       this.isFilterApplied = "primary";
    //       this.totalRecords = data['count'];
    //       this.dataList = data['data'];
    //       this.filterClass = "filter-invisible";
    //       this.isSpinning = false;
    //     }

    //   }, err => {
    //     console.log(err);
    //   });

    //   this.filterClass = "filter-invisible";
    // }
  }

  clearFilter() {
    this.month = this.today;

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.filterQuery = "";

    this.applyFilter();
  }

  change(data): void {
    console.log("data :  " + JSON.stringify(data));

    this.api.updateHoliday(data).subscribe(successCode => {
    }, errorCode => {
      console.log("error");
    });
  }
  stepsdisabled1=false
  change1(data): void {
    console.log("data :  " + JSON.stringify(data));

    this.api.updateHoliday1(data).subscribe(successCode => {
      if(data['CODE']==200){
        this.stepsdisabled1=true
      }
    }, errorCode => {
      console.log("error");
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }
  drawerData1: any
  masterdata: any
  data12 = []
  id = []
  isVisible1: boolean = false
  drawerTitle3: string = ''
  drawerTitle2: string = ''
  holiday_id: any
  mapBranch(data:HolidayMaster) {
    // this.showholiday(ID)
    this.drawerTitle2="Branch map"
    this.drawerData1 = Object.assign({}, data);
       this.api.getAllHolidays(0,0,'','','AND ID='+data.ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
         
            for(let i=0;i<this.dataList.length;i++){
           
              this.id.push(this.dataList[i]['ID'])
            console.log(this.id,"Blood Group")
            }
            this.holiday_id=data['data'][0]['ID']     
            console.log( this.holiday_id,"id")
         
          
         
          console.log(this.holidayid,"id")
        }
  
      }, err => {
        console.log(err);
      });
    
    
    // this.api.getAllBranch(0,0,'','','').subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.masterdata1 = data['data'];

    //     // this.branchid=data['data'][0]['ID']
    //   }
    
    // }, err => {
    //   console.log(err);
    // });

    // this.api.getbranches(0,0,'','','AND HOLIDAY_ID='+data.ID).subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.masterdata = data['data'];
    //     for (let i = 0; i < this.masterdata.length; i++) {
    //       this.BRANCH_ID.push(Number(this.masterdata[i]['BRANCH_ID']));
    //       if (i + 1 == this.masterdata.length) {
    //         this.BRANCH_ID = [...[], ...this.BRANCH_ID];
    //         console.log(this.BRANCH_ID);
    //       }
    //     }
    //   }
    //   console.log(this.drawerData, "jhh")
    // }, err => {
    //   console.log(err);
    // });

    this.api.getholidaybranch(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.masterdata = data['data'];
        for (let i = 0; i < this.masterdata.length; i++) {
          if (this.masterdata[i]['STATUS'] == 1)
          this.BRANCH_ID.push(Number(this.masterdata[i]['BRANCH_ID']));
          if (i + 1 == this.masterdata.length) {
            this.BRANCH_ID = [...[], ...this.BRANCH_ID];
            console.log(this.BRANCH_ID);
          }
        }
      }
      console.log(this.drawerData, "jhh")
    }, err => {
      console.log(err);
    });


   
    // console.log(this.drawerData1,"jhh")
    // // this.BRANCH_ID= this.BRANCH_ID == ''||this.BRANCH_ID ==  null ? [] : this.BRANCH_ID.split(',')
   
    // console.log(this.BRANCH_ID,"jhh")
    this.isVisible1 = true;
  }
  handleCancel(): void {

    this.isVisible1 = false;
  }
  MappingData: any
  data1
  BRANCH_ID = []
  BRANCH_NAME:any
  CLUSTER_ID
  CLIENT_ID

  save() {
    var branchmanager = []
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      for (var i = 0; i < this.BRANCH_ID.length; i++) {
        branchmanager.push({

          BRANCH_ID: this.BRANCH_ID[i],
          HOLIDAY_ID: this.holidayid

        })
      }
    }

    this.api.addbranch(this.holidayid, branchmanager).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Branch Mapping Updated Successfully...", "");
        //  this.drawerClose();
        this.isVisible1 = false
        this.BRANCH_ID = []

      }
      else {
        this.message.error("Branch Mapping update failed", '')
        this.isVisible1 = false
      }
    });

    this.isVisible1 = false;
  }
  isVisible2: boolean = false
  // edit() {
  // this.isVisible2 = true;
  // }


  reason: any = ""
  date: any = ""
  holiday: any = ""
  assign: any = []
  drawerData: any = []
  TYPE
  saveholiday(data: HolidayMaster) {
    // this.assign = Object.assign({}, data);
    this.data.ID = this.data.ID
    this.data.DATE = this.data.DATE
    this.data.IS_HOLIDAY = this.data.IS_HOLIDAY
    this.data.REASON = this.data.REASON
    this.data.IS_ATTENDANCE_ALLOWED = this.data.IS_ATTENDANCE_ALLOWED
    // this.data.TYPE=this.data.TYPE
    this.api.updateHoliday(this.data).subscribe(successCode => {
    }, errorCode => {
      console.log("error");
    });
    this.isVisible2 = false
  }
  masterdata1: any
  //  showbranch(){
  //   this.api.getbranches(0,0,'','','').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.masterdata1 = data['data'];
  //     }
  //     console.log(this.drawerData, "jhh")
  //   }, err => {
  //     console.log(err);
  //   });
  //  }
  ids = []

  // showholiday(ID){

  //   this.api.getAllHolidays(0,0,'','','AND ID='+ID).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.loadingRecords = false;
  //       this.totalRecords = data['count'];
  //       this.data12 = data['data'];

  //       console.log(this.holidayid,"id")
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }
  drawerTitle: string = ''
  ID: any
  DATE: any
  edit(data:HolidayMaster) {
    this.drawerTitle3 = "Update Holiday Type";
    this.data = Object.assign({}, data);
    this.ID=this.data.ID
    this.DATE=this.data.DATE
    this.data.TYPE=this.data.TYPE
    console.log(this.data,"")
    this.isVisible2 = true;
  }
  drawerClose2() {
    this.isVisible1 = false
    this.search()
    this.BRANCH_ID = []
  }
  drawerClose3() {
    this.isVisible2 = false
    this.search()
  }

  get closeCallback2() {
    return this.drawerClose2.bind(this);
  }
  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }



}
