import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { payslip } from 'src/app/Models/payslip';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.css']
})
export class PayslipComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }
  data
  formTitle = "Manage Monthly Salary Slip";
  year: any = '2023'

  imgUrl = this.api.retriveimgUrl
  ngOnInit() {
    this.getAllForms(this.year)
  }
  allinfo = []
  isApproveModalConfirmLoading1 = true
  getAllForms(year) {
    this.isApproveModalConfirmLoading1 = true
    this.api.getAllsalaryslip(0, 0, '', 'asc', ' AND YEAR = ' + year).subscribe((data1) => {
      if (data1['code'] == 200) {
        this.isApproveModalConfirmLoading1 = false
        this.allinfo = data1['data'];
        console.log(this.allinfo);
      }



    },
      (err) => {
        console.log(err);
      }
    );
  }
  userId = Number(this.cookie.get("userId"));
  data24: any
  DownloadLink
  isApproveVisible = false
  isApproveModalConfirmLoading
  getsalaryslip(data) {
    this.isApproveModalConfirmLoading = true
    this.isApproveVisible = true


    this.api.getempsalary(this.userId, data.YEAR, data.MONTH_NAME).subscribe((data) => {


      this.data24 = data;

      if (data['DOWNLOAD_LINK'] == "") {
        this.isApproveModalConfirmLoading = true
        // this.isApp/roveVisible = false
        this.message.error('Data Not Found', "")
      }
      else {
        this.DownloadLink = data['DOWNLOAD_LINK']

        this.isApproveModalConfirmLoading = false
        // this.isApproveVisible = false
      }



      console.log(this.DownloadLink);


    },
      (err) => {
        console.log(err);
      }
    );
    // let deviceID = this.cookie.get('DEVICE_ID');
    // this.cookie.deleteAll();
    // this.cookie.set('DEVICE_ID', deviceID, 365, "", "", false, "Strict");
    // sessionStorage.clear();
    // window.location.reload();
    // }, 5000);



  }
  sanitizedLink: any
  url: any
  roleId =Number(this.cookie.get('roleId'))

  getS(link: string) {

  
    var a = link + '#toolbar=0'
    this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

    return this.sanitizedLink;

  }
  fileURL: any
  isSpinning: boolean = false
  PAYSLIP_URL
  IS_FILE_UPLOADED

  update() {


  }
  isLoadingOne = false
  onFileSelected(event: any, data) {
    console.log(event);

    this.isApproveModalConfirmLoading1 = true
    this.fileURL = <File>event.target.files[0];
    if (this.fileURL != null) {
      // this.message.error("Please Select file of type HTML/ PDF1.", "");
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
      var url = '';
      url = d == null ? '' : d + number + '.' + fileExt;

      this.api.onUpload6('employeePaySlip', this.fileURL, url)
        .subscribe((successCode) => {
          if (successCode['code'] == 200) {
            data.PAYSLIP_URL = url
            data.IS_FILE_UPLOADED = 'U'
            this.api.updatesalaryslip(data).subscribe((successCode) => {
              console.log("line")
              if (successCode['code'] == '200') {
                this.message.success('File Uploaded Successfully...', '');
                this.isApproveModalConfirmLoading1 = false
                this.getAllForms(this.year)

                this.data.PAYSLIP_URL = '';
                this.fileURL = '';
                this.isSpinning = false;
                this.isVisible = false

              }

              else {
                this.message.error('Failed to Upload File...', '');
              }
            });

          }
        });


    }




  }

  handleApproveCancel() {
    this.isApproveVisible = false
  }
  removeImage() {
    this.data.PAYSLIP_URL = '';
    this.fileURL = '';
  }
  isVisible: Boolean = false
  allinfo1: payslip
  openmodal(data) {
    this.isVisible = true
    this.api.getAllsalaryslip(0, 0, '', 'asc', ' AND ID = ' + data.ID + ' AND YEAR = ' + this.year).subscribe((data1) => {
      this.allinfo1 = data1['data'][0];
      console.log(this.allinfo);


    },
      (err) => {
        console.log(err);
      }
    );
  }
}
