import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import * as XLSX from 'xlsx';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { timeStamp } from 'console';

@Component({
  selector: 'app-custwisedetail',
  templateUrl: './custwisedetail.component.html',
  styleUrls: ['./custwisedetail.component.css']
})
export class CustwisedetailComponent implements OnInit {
  exportLoading: boolean = false;
  formTitle = "Customer Wise Detailed Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'DeptWise.xlsx';
  dataList = [];
  dataList1 = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [
    ["CUSTOMER_NAME", "Customer Name"],
    ["MODEL_NAME", " Model Name"],
    ["VARIENT_NAME", "Varient"],
    ["FUEL_TYPE", "Fuel"],
    ["TRANSMISSION", "Transmission"],
    ["QUOTATION_DATE", "Quotation Date "],
    ["MEMO_DATE", "Memo Date"],
    ["INVOICE_DATE", "Invoice Date"],
    ["INVOICE_AMOUNT", "Invoice Amount "],
    ["FASTAG", "Fastag"],
    ["INSURANCE", "Insurance"],
    ["RTO", "RTO"],
    ["ACCESSORIES", "Accessories"],
    ["WARRENTY", "Warrenty"],
    ["SALES_EXECUTIVE_NAME", "Sales Executive"],
    ["SALES_MANAGER_NAME", "Assistant Sales Manager "],
    ["SALES_HEAD_NAME", "Sales head"],
    ["GENERAL_MANAGER_NAME", "Generel manager"],
    ["SALES_EXECUTIVE_DISCOUNT", "Sales Executive Discount"],
    ["APPROVED_AMOUNT", "Approved Discount"],

  ]

  MODEL = [];
  // BRANCH = [];
  SALES = [];
  VARIENT = [];
  FUEL = [];
  TRANSMISSION = [];
  GENERALMANAGER = [];
  SALESHEAD = [];
  SALESMANAGER = [];
  isSpinning = false
  filterClass: string = "filter-invisible";

  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  todaysDayOfMonth: any
  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  models = []
  // branches = []
  salesexecutive = []
  varients = []
  fuels = []
  generalmanagers = []
  salesheads = []
  assistantsales = []
  transmissions = []
  DATE: any = new Date()
  QUOTATIONDATE: any = new Date()
  MEMODATE: any = new Date()
  INVOICEDATE: any = new Date()
  SELECT_MODEL: boolean = false
  SELECT_VARIENT: boolean = false
  SELECT_FUEL_TYPE: boolean = false
  SELECT_TRANSMISSION_TYPE: boolean = false
  SELECT_SALES_EXICUTIVE: boolean = false
  SELECT_GENERAL_MANAGER: boolean = false
  SELECT_SALES_HEAD: boolean = false
  SELECT_ASSITANCE_SALES_MANAGERS: boolean = false

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService,) { }

  ngOnInit() {    
    this.QUOTATIONDATE.setDate(new Date().getDate() - 1);
    this.MEMODATE.setDate(new Date().getDate() - 1);
    this.INVOICEDATE.setDate(new Date().getDate() - 1);

    this.getModels();
    this.getvarients();
    this.getFuels();
    this.getTransmission();
    this.getSalesExecutive();
    this.getmanager();
    this.getsalesHead()
    this.getAssistantSales();

    setTimeout(() => {
      this.search();
    }, 1500);
    
  }
  

  getModels()
  {
    this.api.getAllModels(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.models = data['data'];
      this.onSelectAllModel(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }

  getvarients()
  {
    this.api.getAllVarients(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.varients = data['data'];
      this.onSelectAllVarient(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }

  getFuels()
  {
    this.api.getAllFuel(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.fuels = data['data'];
      this.onSelectAllFuelType(true)
      // this.search(false)

    }, err => {
      console.log(err);
    });
  }
  //////////
  getTransmission()
  {
    this.api.getAllTransmission(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.transmissions = data['data'];
      this.onSelectAllTransmissionType(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }

  getSalesExecutive()
  {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      this.salesexecutive = data['data'];
      this.onSelectAllSalesExecutive(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }

  getmanager()
  {
    this.api.getAllEmpRoleMap(0, 0, '', 'ASC', 'AND ROLE_ID IN(30)').subscribe(data => {
      this.generalmanagers = data['data'];
      this.onSelectAllGeneralManager(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }

  getsalesHead()
  {
    this.api.getAllEmpRoleMap(0, 0, '', 'ASC', 'AND ROLE_ID IN(34)').subscribe(data => {
      this.salesheads = data['data'];
      this.onSelectAllSalesHead(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }

  getAssistantSales()
  {
    this.api.getAllEmpRoleMap(0, 0, '', 'ASC', 'AND ROLE_ID IN(29)').subscribe(data => {
      this.assistantsales = data['data'];
      this.onSelectAllAssistantSalesManager(true)
      // this.search(false)
    }, err => {
      console.log(err);
    });
  }
  
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }

  onSelectAllVarient(event: any) {
    this.SELECT_VARIENT = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_VARIENT == true) {
      for (var i = 0; i < this.varients.length; i++) {
        ids.push(this.varients[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT = ids
  }
  onSelectAllFuelType(event: any) {
    this.SELECT_FUEL_TYPE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_FUEL_TYPE == true) {
      for (var i = 0; i < this.fuels.length; i++) {
        ids.push(this.fuels[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.FUEL = ids
  }

  onSelectAllTransmissionType(event: any) {
    this.SELECT_TRANSMISSION_TYPE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_TRANSMISSION_TYPE == true) {
      for (var i = 0; i < this.transmissions.length; i++) {
        ids.push(this.transmissions[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.TRANSMISSION = ids
  }

  onSelectAllSalesExecutive(event: any) {
    this.SELECT_SALES_EXICUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXICUTIVE == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES = ids
  }

  onSelectAllGeneralManager(event: any) {
    this.SELECT_GENERAL_MANAGER = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_GENERAL_MANAGER == true) {
      for (var i = 0; i < this.generalmanagers.length; i++) {
        ids.push(this.generalmanagers[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.GENERALMANAGER = ids
  }
  onSelectAllSalesHead(event: any) {
    this.SELECT_SALES_HEAD = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_HEAD == true) {
      for (var i = 0; i < this.salesheads.length; i++) {
        ids.push(this.salesheads[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESHEAD = ids
  }

  onSelectAllAssistantSalesManager(event: any) {
    this.SELECT_ASSITANCE_SALES_MANAGERS = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_ASSITANCE_SALES_MANAGERS == true) {
      for (var i = 0; i < this.assistantsales.length; i++) {
        ids.push(this.assistantsales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESMANAGER = ids
  }


  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }


  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  clearFilter() {
    this.QUOTATIONDATE = new Date()
    this.MEMODATE = new Date()
    this.INVOICEDATE = new Date()
    this.QUOTATIONDATE.setDate(new Date().getDate() - 1);
    this.MEMODATE.setDate(new Date().getDate() - 1);
    this.INVOICEDATE.setDate(new Date().getDate() - 1);
    this.MODEL = [];
    // this.BRANCH = [];
    this.SALES = [];
    this.VARIENT = [];
    this.FUEL = [];
    this.TRANSMISSION = [];
    this.GENERALMANAGER = [];
    this.SALESHEAD = [];
    this.SALESMANAGER = [];
    this.SELECT_MODEL = false;
    this.SELECT_VARIENT = false;
    this.SELECT_FUEL_TYPE = false;
    this.SELECT_TRANSMISSION_TYPE = false;
    this.SELECT_SALES_EXICUTIVE = false;
    this.SELECT_GENERAL_MANAGER = false;
    this.SELECT_SALES_HEAD = false;
    this.SELECT_ASSITANCE_SALES_MANAGERS = false;
    this.searchText = '';
    this.filterQuery = "";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  importInExcel() {
    this.search(true, true);
  }



  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }
  // search(reset: boolean = false) {

  //   if (reset) {
  //     this.pageIndex = 1;
  //     this.sortKey = "id";
  //     this.sortValue = "desc"
  //     this.dataList = []
  //   }


  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   console.log("search text:" + this.searchText);
  //   if (this.searchText != "") {
  //     var likeQuery = " AND";
  //     this.columns.forEach(column => {
  //       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
  //     });
  //     likeQuery = likeQuery.substring(0, likeQuery.length - 2)

  //     var filterQuery = this.filterQuery + likeQuery


  //   }
  //   else {
  //     this.applyFilter()
  //   }
  // }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    this.QUOTATIONDATE = this.datePipe.transform(this.QUOTATIONDATE, 'yyyy-MM-dd')
    this.MEMODATE = this.datePipe.transform(this.MEMODATE, 'yyyy-MM-dd')
    this.INVOICEDATE = this.datePipe.transform(this.INVOICEDATE, 'yyyy-MM-dd')

    if (this.QUOTATIONDATE != null) {
      this.QUOTATIONDATE = this.QUOTATIONDATE
    }

    if (this.MEMODATE != null) {
      this.MEMODATE = this.MEMODATE
    }

    if (this.INVOICEDATE != null) {
      this.INVOICEDATE = this.INVOICEDATE
    }


    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.QUOTATIONDATE = this.datePipe.transform(this.QUOTATIONDATE, 'yyyy-MM-dd')
    this.MEMODATE = this.datePipe.transform(this.MEMODATE, 'yyyy-MM-dd')
    this.INVOICEDATE = this.datePipe.transform(this.INVOICEDATE, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.Customerwisedetcailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.VARIENT, this.FUEL, this.TRANSMISSION, this.SALES, this.GENERALMANAGER, this.SALESHEAD, this.SALESMANAGER, this.QUOTATIONDATE, this.MEMODATE, this.INVOICEDATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.Customerwisedetcailedreport(0, 0, this.sortKey, sort, likeQuery, this.MODEL, this.VARIENT, this.FUEL, this.TRANSMISSION, this.SALES, this.GENERALMANAGER, this.SALESHEAD, this.SALESMANAGER, this.QUOTATIONDATE, this.MEMODATE, this.INVOICEDATE).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Customer Name  '] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model Name '] = this.dataList1[i]['MODEL_NAME'];
      obj1['Varient '] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Fuel'] = this.dataList1[i]['FUEL_TYPE'];
      obj1['Transmission'] = this.dataList1[i]['TRANSMISSION'];
      obj1['Quotation Date'] = this.dataList1[i]['QUOTATION_DATE'];
      obj1['Memo Date '] = this.dataList1[i]['MEMO_DATE'];
      obj1['Invoice Date '] = this.dataList1[i]['INVOICE_DATE'];
      obj1['Invoice Amount'] = this.dataList1[i]['INVOICE_AMOUNT'];
      obj1['Fastag'] = this.dataList1[i]['FASTAG'];
      obj1['Insurance'] = this.dataList1[i]['INSURANCE'];
      obj1[' RTO'] = this.dataList1[i]['RTO'];
      obj1['Accessories'] = this.dataList1[i]['ACCESSORIES'];
      obj1['Warrenty'] = this.dataList1[i]['WARRENTY'];
      obj1['Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Assistant Sales Manager'] = this.dataList1[i]['SALES_MANAGER_NAME'];
      obj1['Sales head'] = this.dataList1[i]['SALES_HEAD_NAME'];
      obj1['Generel manager'] = this.dataList1[i]['GENERAL_MANAGER_NAME'];
      obj1['Sales Executive Discount '] = this.dataList1[i]['SALES_HEAD_NAME'];
      obj1['Approved Discount'] = this.dataList1[i]['APPROVED_AMOUNT'];
      obj1['Approvd by'] = this.dataList1[i]['APPROVED_BY'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer Wise Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }

  isOk = true
  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }


    if (this.MODEL == undefined && this.VARIENT == undefined && this.FUEL == undefined && this.TRANSMISSION == undefined && this.SALES == undefined && this.GENERALMANAGER == undefined && this.SALESHEAD == undefined && this.SALESMANAGER == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.QUOTATIONDATE = this.datePipe.transform(this.QUOTATIONDATE, 'yyyy-MM-dd')
      this.MEMODATE = this.datePipe.transform(this.MEMODATE, 'yyyy-MM-dd')
      this.INVOICEDATE = this.datePipe.transform(this.INVOICEDATE, 'yyyy-MM-dd')

      // this.api.Salesexecutiveinhouseinsurancesummary(this.pageIndex, this.pageSize, this.sortKey, sort,likeQuery,this.START_DATE ,this.END_DATE,this.SALES_EXECUTIVE ,).subscribe(data => {
      //   console.log(data)
      //   this.loadingRecords = false;
      //   this.isSpinning=false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   this.filterClass = 'filter-invisible';
      // }, err => {
      //   console.log(err);
      // });
      this.search(true);
    }
    this.filterClass = "filter-invisible";
  }



}


