import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
import { NgForm } from '@angular/forms';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
// import { Leave } from '../Models/Leave';
@Component({
  selector: 'app-branchareamap',
  templateUrl: './branchareamap.component.html',
  styleUrls: ['./branchareamap.component.css']
})
export class BranchareamapComponent implements OnInit {

  @Input() drawerClose1: Function;

  @Input() data: Leave;
  @Input() dataListtt = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  one=true
  roles = [];
  model = [];
  leaveTypes = [];
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];
  Array1 = [];
  dataList1 = []

  MODEL1: any
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  @Input() selectedIndex

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Cluster Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence Number"],]

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  modeltype = []
  fueltype = []
  varienttype = []
  tranmission = []

  @Input() Roletype = [];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.getLeaveTypes();
    this.getLeaveTypeMaster();
    this.loadRoles();
    this.getModels();
    this.getRateMaster();
    this.getdata()
  
      ;
      
    // console.log(this.data1);

  }
  user
  empdata=[]
  drawerTitle2:string
  emp(data){
    this.user = Object.assign({},data);
    this.isVisible2 = true;
    this.drawerTitle2="Employee & Points Mapping"

    this.api.getempMapdata(0, 0, '', 'asc', ' AND SUPPORT_STAFF_BRANCH_AREA_MAPPING_ID='+data.ID).subscribe(successCode => {
      this.Modeldata = successCode['data']
      this.SendData = successCode['data']
      console.log(this.empdata);


    })
  }



  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  getModels() {
    this.model = [];

    this.api.getAllModels(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllVarients(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllFuel(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


    this.api.getAllTransmission(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.tranmission = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }


  getdata() {
    // this.dataListtt = this.Roletype
    // console.log(this.dataListtt, "this.dataList");

  }

  getLeaveTypes() {
    this.leaveTypes = [];

    this.api.getAllLeaveType(0, 0, 'NAME', 'asc', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  rateMaster = [];
  getRateMaster() {
    this.api.getRateMaster(0, 0, '', 'asc', '').subscribe((data1) => {
      console.log(data1);
      this.rateMaster = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }


  @Input() ROLES22 = []
  @Input() MODELS22 = []

  

  close(): void {
    this.drawerClose1();
    this.one=true
    this.ROLES = []
    this.Modeldata=[]
    this.ROLESSS=[]
    // this.SendData=[]
    this.va=[]
    // this.reset(myForm);
  }

 
  MappingData

  kkkkk = []
  roleIds = []
  nextsdata = []
  getareaMappingData=[]
  save11(Roletype) {
    console.log(Roletype);
    // this.message.success("Role Selected Successfully...", '')
    this.selectedIndex = 1
    this.nextsdata = []
    var m = ''
    var n = ''
    var l = ''
    var o = ''

    if (this.Roletype.length > 0) {
      for (let i = 0; i < this.Roletype.length; i++) {
        m = this.Roletype[i]['SUPPORT_STAFF_AREA_ID']
        n = this.Roletype[i]['STATUS']      
              
        this.nextsdata.push({
          'SUPPORT_STAFF_AREA_ID': m,
          "STATUS": n,
        })

      }
    }

    this.Modeldata = this.nextsdata;
    // console.log(this.Modeldata);

    var isOk = true;

    console.log(this.data);

    if (this.Modeldata.length == 0) {
      isOk = false;
      this.message.error("Please Select Areas", "");
    }

    var obb = ({
      "BRANCH_ID": this.data.ID,
      "data": this.Modeldata
    })
    // Object.assign(obb,this.dataList)
    if (isOk) {
      this.isSpinning = true;


      this.api.areaAddBulk(obb).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Area Mapped Successfully", "");

          // this.drawerClose1()
          // this.isSpinning = false;
         
          this.api.getareaMappingData(this.data.ID).subscribe(successCode => {
            if(successCode['code']=="200"){
             this.one=false
             this.isSpinning = false;
              this.getareaMappingData = successCode['data']
              this.Roletype = successCode['data']
             
        
              for (let i = 0; i < this.getareaMappingData.length; i++) {
                // if (this.getareaMappingData[i]['STATUS'] == 1) {
                //   this.allareas.push(this.getareaMappingData[i]['ID'])
                // }
                this.Roletype[i]['STATUS'] = this.Roletype[i]['STATUS'] == 1 ? true : false
              }
              console.log(this.Roletype);
        
            }
            })

        } else {
          this.message.error("Area Mapping Failed", "");
          this.isSpinning = false;
        }
      });

    
  }
  
  }
  MODELeee = []

  Modeldata = []
  // SendData = []
  SendData1 = []
  i = 2
  mod: any
  ful: any
  var: any
  trans: any
  mod1: any
  ful1: any
  var1: any
  trans1: any
  
  finalDataa = []
  // dataListtt = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []
  @Input() ROLEIDS = []
  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []
  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }
 
  isVisible2:boolean=false
  drawerClose2() {
    this.isVisible2 = false;
    this.Modeldata = []
    // this.getData();
  }
  get closeCallback2() {
    return this.drawerClose2.bind(this)
  }

  getAmount() {

    if (this.dataListtt.length > 0) {


      this.dataListtt.forEach((x, index) => {
        this.Final.forEach((x2, index2) => {
          if (x.ROLE_ID == x2.ROLE_ID &&
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Final[index2]['AMOUNT'] = this.dataListtt[index].AMOUNT
          }
        })
        if (index + 1 == this.dataListtt.length) {
          this.dataListtt = [...[], ...this.Final];
          console.log(this.dataListtt);

        }
      });
    } else {
      this.dataListtt = [...[], ...this.Final];
    }



  }
  indexx = -1

 

  

  // save(dataList: any): void {
  //   var isOk = true;

  //   console.log(this.data);

  //   if (this.dataList.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Roles And Models", "");
  //   }

  //   var obb = ({
  //     "DIRECT_INCENTIVE_RATES_MASTER_ID": this.data.ID,
  //     // "TYPE": 'S', 
  //     "ROLE_IDS": this.ROLES.toString(),
  //     "MODEL_IDS": this.MODELS.toString(),
  //     "data": this.dataList
  //   })
  //   // Object.assign(obb,this.dataList)
  //   if (isOk) {
  //     this.isSpinning = true;


  //     this.api.rateAddBulk(obb).subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         this.message.success("Roles And Models Mapped Successfully", "");

  //         this.drawerClose1()
  //         this.isSpinning = false;

  //       } else {
  //         this.message.error("Roles And Models Mapping Failed", "");
  //         this.isSpinning = false;
  //       }
  //     });

  //   }
  // }

  today = new Date().setDate(new Date().getDate() + 4);

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  // @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.FROM_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }


  getLeaveTypeMaster() {
    // this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.leaveTypeDetails = data['data'];
    //   }

    // }, err => {
    //   console.log(err);
    // });
    // this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
    //   this.Roletype = roles['data'];
    // }, err => {
    //   console.log(err);
    // });

  }

  // onLeaveTypeSelected(leaveType) {
  //   var leaveData = this.leaveTypeDetails.filter(obj => {
  //     return (obj.ID == leaveType);
  //   });

  //   if (leaveData.length != 0)
  //     this.today = new Date().setDate(new Date().getDate() + Number(leaveData[0]["LEAVE_APPROVAL_DAYS"]));
  //   else
  //     this.today = new Date().setDate(new Date().getDate() + Number(4));

  //   this.data.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
  //   this.data.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");

  //   this.disabledStartDate = (current: Date): boolean =>
  //     differenceInCalendarDays(current, this.today) < 0;
  // }

}
