import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-customerwisequotationdetailedreport',
  templateUrl: './customerwisequotationdetailedreport.component.html',
  styleUrls: ['./customerwisequotationdetailedreport.component.css']
})
export class CustomerwisequotationdetailedreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Customer Wise Quotation Detailed Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODEL: any = [];
  BRANCH: any = [];
  models: any = [];
  branch: any = [];
  FUELTYPE: any = [];
  fueltype: any = []
  SALESEXECUTIVE: any = [];
  salesexecutive: any = [];
  quotationdate: any;
  isOk = true;
  TRANSMISSION: any = []
  transmission: any = []
  VARIENT: any = [];
  varient: any = [];

  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model Name"], ["VARIENT_NAME", "Variant Name"], ["BRANCH_NAME", "Branch"], ["TRANSMISSION_TYPE", "Transmission"], ["SALES_EXECUTIVE_NAME", "Sales Executive Name"], ["FUEL_NAME", "Fuel"]];
  dataList1: any;
  exportLoading: boolean=false;
  frmdate: any;
  todate: any;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {
    this.getAllbranches();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    this.frmdate = new Date(y, m, d - 1)
    this.todate = new Date()
    this.todate = this.datePipe.transform(this.todate, "yyyy-MM-dd")
    this.frmdate = this.datePipe.transform(this.frmdate, "yyyy-MM-dd")
  }
  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = { 
      MODEL:this.MODEL, 
      frmdate:this.frmdate, 
      todate:this.todate, 
      BRANCH:this.BRANCH, 
      SALESEXECUTIVE:this.SALESEXECUTIVE, 
      VARIENT:this.VARIENT, 
      FUELTYPE:this.FUELTYPE, 
      TRANSMISSION:this.TRANSMISSION
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 61').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      if (this.branch.length > 0) {
        this.BRANCH = [data['data'][0]['ID']];
        console.log(this.branch);
      }
      else {
        console.log('Error');
      }
      this.getAllModels();
    }, (err) => {
      console.log(err);
    });
  }

  getAllModels() {
    this.api.getAllModels(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.models = data['data']
      if (this.models.length > 0) {
        this.MODEL = [data['data'][0]['ID']];
        console.log(this.models);
      }
      else {
        console.log('Error');
      }
      this.getAllVarients();
    }, (err) => {
      console.log(err);
    });
  }

  getAllVarients() {
    this.api.getAllVarients(0, 0, "", "", "").subscribe(data => {
      this.varient = data['data']
      let ids = [];
      if (this.SELECT_VARIENT == true) {
        for (var i = 0; i < this.varient.length; i++) {
          ids.push(this.varient[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.VARIENT = ids;
      this.getfuel();
    },
      (err) => {
        console.log(err);
      }
    );
  }
  getfuel() {
    this.api.getAllFuel(0, 0, "", "", "").subscribe(data => {
      this.fueltype = data['data']
      let ids = [];
      if (this.SELECT_FUEL == true) {
        for (var i = 0; i < this.fueltype.length; i++) {
          ids.push(this.fueltype[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.FUELTYPE = ids;
      this.gettransmission();
    },
      (err) => {
        console.log(err);
      }
    );
  }

  gettransmission() {
    this.api.getAllTransmission(0, 0, "", "", "").subscribe(data => {
      this.transmission = data['data']
      let ids = [];
      if (this.SELECT_TRANSMISSION == true) {
        for (var i = 0; i < this.transmission.length; i++) {
          ids.push(this.transmission[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.TRANSMISSION = ids;
      this.getsalehead();
    },
      (err) => {
        console.log(err);
      }
    );
  }
  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(
      data => {
        this.salesexecutive = data['data']
        let ids = [];
        if (this.SELECT_SALESEXECUTIVE == true) {
          for (var i = 0; i < this.salesexecutive.length; i++) {
            ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
          }
        }
        else {
          ids = [];
        }
        this.SALESEXECUTIVE = ids;
        this.search(true);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }

  SELECT_BRANCH: boolean = false;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  onSelectOff2(event) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  SELECT_VARIENT: boolean = true;
  onSelectAllVarient(event: any) {
    this.SELECT_VARIENT = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_VARIENT == true) {
      for (var i = 0; i < this.varient.length; i++) {
        ids.push(this.varient[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT = ids
  }

  onSelectOff4(event) {
    var a = this.varient.length;
    var b = this.varient.length - event.length;
    if ((a! = b)) {
      this.SELECT_VARIENT = false;
    } else {
      this.SELECT_VARIENT = true;
    }
    this.VARIENT = event;
    if (this.VARIENT.length == 0) {
      this.SELECT_VARIENT = false;
    }
  }


  SELECT_FUEL: boolean = true;
  onSelectAllFuel(event: any) {
    this.SELECT_FUEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_FUEL == true) {
      for (var i = 0; i < this.fueltype.length; i++) {
        ids.push(this.fueltype[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.FUELTYPE = ids
  }

  onSelectOff3(event) {
    var a = this.fueltype.length;
    var b = this.fueltype.length - event.length;
    if ((a! = b)) {
      this.SELECT_FUEL = false;
    } else {
      this.SELECT_FUEL = true;
    }
    this.FUELTYPE = event;
    if (this.FUELTYPE.length == 0) {
      this.SELECT_FUEL = false;
    }
  }

  SELECT_TRANSMISSION: boolean = true;
  onSelectAllTransmission(event: any) {
    this.SELECT_TRANSMISSION = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_TRANSMISSION == true) {
      for (var i = 0; i < this.transmission.length; i++) {
        ids.push(this.transmission[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.TRANSMISSION = ids
  }

  onSelectOff5(event) {
    var a = this.transmission.length;
    var b = this.transmission.length - event.length;
    if ((a! = b)) {
      this.SELECT_TRANSMISSION = false;
    } else {
      this.SELECT_TRANSMISSION = true;
    }
    this.TRANSMISSION = event;
    if (this.TRANSMISSION.length == 0) {
      this.SELECT_TRANSMISSION = false;
    }
  }

  SELECT_SALESEXECUTIVE: boolean = true;

  onSelectAllSalesexecutive(event: any) {
    this.SELECT_SALESEXECUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALESEXECUTIVE == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESEXECUTIVE = ids
  }

  onSelectOff6(event) {
    var a = this.salesexecutive.length;
    var b = this.salesexecutive.length - event.length;
    if ((a! = b)) {
      this.SELECT_SALESEXECUTIVE = false;
    } else {
      this.SELECT_SALESEXECUTIVE = true;
    }
    this.SALESEXECUTIVE = event;
    if (this.SALESEXECUTIVE.length == 0) {
      this.SELECT_SALESEXECUTIVE = false;
    }
  }

  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }

  onSelectOff(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL = event;
    if (this.MODEL.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }


    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }

    this.frmdate = this.datePipe.transform(this.frmdate, 'yyyy-MM-dd')
    this.todate = this.datePipe.transform(this.todate, 'yyyy-MM-dd')


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getcustomerwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.FUELTYPE, this.TRANSMISSION).subscribe(data => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        },err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getcustomerwisequotationdetails(0, 0, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.FUELTYPE, this.TRANSMISSION).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
     });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.todate = this.datePipe.transform(this.todate, "yyyy-MM-dd")
    this.frmdate = this.datePipe.transform(this.frmdate, "yyyy-MM-dd")

    if (this.frmdate != undefined) {
      this.frmdate = this.frmdate;
    }

    if (this.todate != undefined) {
      this.todate = this.todate;
    }

    if (this.MODEL != undefined) {
      this.MODEL = this.MODEL;
    }

    if (this.BRANCH != undefined) {
      this.BRANCH = this.BRANCH;
    }
    if (this.VARIENT != undefined) {
      this.VARIENT = this.VARIENT;
    }

    if (this.SALESEXECUTIVE != undefined) {
      this.SALESEXECUTIVE = this.SALESEXECUTIVE;
    }
    if (this.FUELTYPE != undefined) {
      this.FUELTYPE = this.FUELTYPE;
    }
    if (this.TRANSMISSION != undefined) {
      this.TRANSMISSION = this.TRANSMISSION;
    }


    this.search(true);
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
    this.filterClass = "filter-invisible";

  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Variant'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Transmission'] = this.dataList1[i]['TRANSMISSION_TYPE'];
      obj1['Fuel'] = this.dataList1[i]['FUEL_NAME'];
      obj1['Total Cost'] = this.dataList1[i]['TOTAL_AMOUNT'];
      obj1['total Discount'] = this.dataList1[i]['TOTAL_DISCOUNT'];
      obj1['Approved Discount'] = this.dataList1[i]['APPROVED_DISCOUNT'];
      obj1['Approved By'] = this.dataList1[i]['APPROVED_BY'];
      obj1['Converted To Memo'] = this.dataList1[i]['CONVERTED_TO_MEMO'];
      obj1['Converted To Memo Date'] = this.dataList1[i]['MEMO_DATE'];
      obj1['Converted To Invoice'] = this.dataList1[i]['INVOICE_DATE'];
      obj1['Converted To Invoice Date'] = this.dataList1[i]['CONVERTED_TO_INVOICE_DATE'];
      // obj1['Transmission'] = this.dataList1[i]['TRANSMISSION'];
      // obj1['Transmission'] = this.dataList1[i]['TRANSMISSION'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer Wise Quotation Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";

    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    this.frmdate = new Date(y, m, d - 1)
    this.todate = new Date()
    
    this.SELECT_BRANCH = false
    this.SELECT_FUEL = true
    this.SELECT_VARIENT = true 
    this.SELECT_TRANSMISSION = true
    this.SELECT_SALESEXECUTIVE = true
    this.getAllbranches();

    this.SELECT_MODEL = false
    let ids = [];
    for (var i = 0; i < this.models.length; i++) {
      ids.push(this.models[i]["ID"]);
    }
    this.MODEL = ids;
  }

   sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
}
