import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Asset } from 'src/app/Models/Asset';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-quotation-details',
  templateUrl: './quotation-details.component.html',
  styleUrls: ['./quotation-details.component.css']
})

export class QuotationDetailsComponent implements OnInit {
  formTitle = "Quotation Details Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  
  assetData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["QUOTATION_NO", "Quotation No."], ["DATE", "Date"], ["BRANCH_NAME", "Branch"], ["SALES_EXECUTIVE_NAME", "Executive"], ["CUSTOMER_NAME", "Customer"], ["MODEL_NAME", "Model"], ["VARIENT_NAME", "Varient"], ["TOTAL_AMOUNT", "Total Cost"], ["STATUS", "Status"]];
  drawerVisible: boolean;
  drawerTitle: string;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  assetData1=[]
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var quotationDateFilter = "";
    if ((this.QUOTATION_DATE != undefined) && (this.QUOTATION_DATE.length != 0)) {
      quotationDateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.QUOTATION_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.QUOTATION_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getQuotation(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + quotationDateFilter).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.assetData = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
    else {
      this.exportLoading = true;
      this.api.getQuotation(0,0, this.sortKey, sort, likeQuery + quotationDateFilter).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.assetData1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  QUOTATION_DATE = [];

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    if ((this.QUOTATION_DATE.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.QUOTATION_DATE = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  getFuelTypeFullForm(fuelType) {
    if (fuelType == "P")
      return "Petrol";

    else if (fuelType == "D")
      return "Diesel";

    else if (fuelType == "C")
      return "CNG";

    else if (fuelType == "L")
      return "LPG";

    else if (fuelType == "E")
      return "Electrical";

    else if (fuelType == "DT")
      return "Dual Tone";

    else if (fuelType == "PDT")
      return "Petrol Dual Tone";

    else if (fuelType == "DDT")
      return "Diesel Dual Tone";

    else
      return "";
  }

  getTransmissionFullForm(transmissionType) {
    if (transmissionType == "M")
      return "Manual";

    else if (transmissionType == "A")
      return "Automatic";

    else
      return "";
  }

  getStatusFullForm(status) {
    if (status == "C")
      return "Created";

    else if (status == "S")
      return "Scrapped";

    else if (status == "H")
      return "On-Hold";

    else if (status == "E")
      return "Closed";

    else if (status == "V")
      return "Versioned";
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.assetData1.length; i++) {
      obj1['Quotation No.'] = this.assetData1[i]['QUOTATION_NO'];
      obj1['Date'] = this.datePipe.transform(this.assetData1[i]['DATE'], 'dd-MM-yyyy');
      obj1['Branch Name'] = this.assetData1[i]['BRANCH_NAME'];
      obj1['Sales Executive Name'] = this.assetData1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.assetData1[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.assetData1[i]['MODEL_NAME'];
      obj1['Variant Name'] = this.assetData1[i]['VARIENT_NAME'];
      obj1['Total Cost'] = this.assetData1[i]['TOTAL_AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.assetData1.length - 1) {
        this._exportService.exportExcel(arry1, 'Quotation Details Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}
