import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-tourreport',
  templateUrl: './tourreport.component.html',
  styleUrls: ['./tourreport.component.css']
})
export class TourreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Tour Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  isOk: boolean = true
  MODEL: any;
  BRANCH: any;
  BranchName: any[]
  RoleName=[]
  ROLE_ID=[]

  quotationdate: any;
  BRANCH_ID=[]
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  MONTH: any
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  yesterday = new Date().getDate();
  startValue: any = new Date();
  endValue: any = new Date();
  // startValue = this.startValue.setDate(this.startValue.getDate() - 1);
  employees = [];
  EMPLOYEE_ID

  YEAR: any
  SELECT_BRANCH: boolean = false;
  columns1: string[][] = [['EMPLOYEE_NAME','Employee Name'],['DESTINATION_NAME','Destination'],["FROM_DATE", "From Date"],["TO_DATE", "To Date"],["TYPE", "Sourcing Type"],["ROLE_NAME", "Role Name"],["EMPLOYEE_NAME", "Employee Name"],['MEMO_NO','Memo No'],['QUOTATION_NO','Quotation No']];
  orgId = Number(this.cookie.get('orgId'));

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }

  ngOnInit() {
    
    this.getbranches();
    this.getroles();
    this.getEmployee()
    setTimeout(()=>{
      this.search(false);
    },1000);
    // this.applyFilter();
  
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  importInExcel() {
    this.search(true, true);
  }


  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.BranchName.length; i++) {
        ids.push(this.BranchName[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }
  getEmployee() {
    this.employees = [];
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.employees = data1['data'];
      // this.onSelectAllSelesExicutive(true)
    },
      (err) => {
        console.log(err);
      }
    );
  }
  getbranches() {
    this.api.getAllBranch( 0,0,"NAME","asc"," AND ORG_ID=" + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.BranchName = data['data'];
        console.log(this.BranchName);
      }
    }, err => {
      console.log(err);
    });
  }
  getroles() {
    this.api.getAllRoles(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.RoleName = data['data'];
        console.log(this.RoleName);
        // this.onSelectAllBranch(true);
      }
    }, err => {
      console.log(err);
    });
  }
  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE ;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }
  TO_DATE
  FROM_DATE
  ADVANCED_APPROVED
  ADVANCE_APPROVED_DATE
  EXPENSES_APPROVED_BY
  ADVANCE_PENDING_AT
  EXPENSES_PENDING_AT
  EXPENSE_APPROVED_DATE
  isVisible=false
  viewImage(imageName) {
    window.open(imageName);
  }
  model(){
this.isVisible=true
  }
  handleCancel(){}
  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd')
    this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd')
    this.ADVANCE_APPROVED_DATE=this.datePipe.transform(this.ADVANCE_APPROVED_DATE, 'yyyy-MM-dd')
    this.EXPENSE_APPROVED_DATE=this.datePipe.transform(this.EXPENSE_APPROVED_DATE, 'yyyy-MM-dd')
   var dateFilterStr = "";
    if (this.START_DATE != null && this.END_DATE != null) {
      dateFilterStr = " AND SALE_DATE BETWEEN '" + this.START_DATE + "' AND '"+ this.END_DATE + "' "
    }

    var branchFilterStr = "";
    if(this.BRANCH_ID != undefined&&this.BRANCH_ID.length > 0){
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

    var roleFilterStr = "";
    if(this.ROLE_ID != undefined&&this.ROLE_ID.length > 0){
      roleFilterStr = " AND ROLE_ID IN (" + this.ROLE_ID + ")";
    }

    var empFilterStr = "";
    if(this.EMPLOYEE_ID != undefined&&this.EMPLOYEE_ID.length > 0){
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.EMPLOYEE_ID + ")";
    }
  
    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.TourDetailedReports(this.pageIndex, this.pageSize, this.sortKey, sort,this.filterQuery+ likeQuery,this.EMPLOYEE_ID,this.FROM_DATE,this.TO_DATE,this.ADVANCED_APPROVED,this.ADVANCE_APPROVED_DATE,this.ADVANCE_PENDING_AT,this.EXPENSES_APPROVED_BY,this.EXPENSES_PENDING_AT,this.EXPENSE_APPROVED_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.TourDetailedReports(0, 0, this.sortKey, sort, this.filterQuery+ likeQuery,this.EMPLOYEE_ID,this.FROM_DATE,this.TO_DATE,this.ADVANCED_APPROVED,this.ADVANCE_APPROVED_DATE,this.ADVANCE_PENDING_AT,this.EXPENSES_APPROVED_BY,this.EXPENSES_PENDING_AT,this.EXPENSE_APPROVED_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  exportLoading: boolean = false;
  columns: string[][] = [['EMPLOYEE_NAME','Employee Name'],['DESTINATION_NAME','Destination'],["FROM_DATE", "From Date"],["TO_DATE", "To Date"],["CLAIMED_ADVANCE_AMOUNT", "Claimed Advanced Amount"],["PENDING_AT", "Pending At"],['APPROVED_ADVANCE_AMOUNT','Approved Advanced Amount'],['APPROVED_BY','Approved By'],
  ['APPROVED_DATETIME','Approved Date Time'],['HEAD_REMARK','Head Remark'],['GM_REMARK','GM Remark'],['ACCOUNTANT_REMARK','Accountant RemarK'],['EXPENSES_CLAIMEND_DATE_TIME','Expenses Claimed Date Time'],['EXPENSE_PENDING_AT','Pending At'],
  ['EXPENSE_APPROVED_BY','Approved By'],['EXPENSE_APPROVED_DATE_TIME','Approved Date Time'],['EXPENSES_HEAD_REMARK','Head Remark'],['EXPENSES_HR_REMARK','HR Remark'],
  ['EXPENSES_GM_REMARK','GM Remark'],['EXPENSES_ACCOUNTANT_REMARK','Accountant'],];


  columns2: string[][] = [['EMPLOYEE_NAME','Employee Name'],['DESTINATION_NAME','Destination'],["FROM_DATE", "From Date"],["TO_DATE", "To Date"],["ADVANCE_AMOUNT", "Advanced Amount"],["CLAIMED_ADVANCE_AMOUNT", "Claimed Advanced Amount"],["PENDING_AT", "Pending At"],['APPROVED_ADVANCE_AMOUNT','Approved Advanced Amount'],['APPROVED_BY','Approved By'],
  ['APPROVED_DATETIME','Approved Date Time'],['HEAD_REMARK','Head Remark'],['GM_REMARK','GM Remark'],['ACCOUNTANT_REMARK','Accountant RemarK'],['Expenses Claimed','EXPENSES_CLAIMED'],['EXPENSES_CLAIMEND_DATE_TIME','Expenses Claimed Date Time'],['EXPENSE_PENDING_AT','Pending At'],['APPROVED_EXPENSE_AMOUNT','Approved Expenses Amount'],
  ['EXPENSE_APPROVED_BY','Approved By'],['EXPENSE_APPROVED_DATE_TIME','Approved Date Time'],['EXPENSES_HEAD_REMARK','Head Remark'],['EXPENSES_HR_REMARK','HR Remark'],
  ['EXPENSES_GM_REMARK','GM Remark'],['EXPENSES_ACCOUNTANT_REMARK','Accountant'],];
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Employee Name'] = this.dataList1[i]['EMPLOYEE_NAME'];
      obj1['Destination'] = this.dataList1[i]['DESTINATION_NAME'];
      obj1['From Date Time'] = this.dataList1[i]['FROM_DATE'];
      obj1['To Date Time'] = this.dataList1[i]['TO_DATE'];
      obj1['Advanced Amount'] = this.dataList1[i]['ADVANCE_AMOUNT'];
      obj1['Claimed Advanced Amount'] = this.dataList1[i]['CLAIMED_ADVANCE_AMOUNT'];
      obj1['Pending At'] = this.dataList1[i]['PENDING_AT'];
      obj1['Approved Advanced Amount'] = this.dataList1[i]['APPROVED_ADVANCE_AMOUNT'];
      obj1['Approved By'] = this.dataList1[i]['APPROVED_BY'];
      obj1['Approved Date Time'] = this.dataList1[i]['APPROVED_DATETIME'];
      obj1['Head Remark'] = this.dataList1[i]['HEAD_REMARK'];
      obj1['GM Remark'] = this.dataList1[i]['GM_REMARK'];
      obj1['Accountant RemarK'] = this.dataList1[i]['ACCOUNTANT_REMARK'];
      obj1['Expenses Claimed '] = this.dataList1[i]['EXPENSES_CLAIMED'];
      obj1['Expenses Claimed Date Time'] = this.dataList1[i]['EXPENSES_CLAIMEND_DATE_TIME'];
      obj1['Pending At'] = this.dataList1[i]['EXPENSE_PENDING_AT'];
      obj1['Approved Expenses Amount'] = this.dataList1[i]['APPROVED_EXPENSE_AMOUNT'];
      obj1['Approved By '] = this.dataList1[i]['EXPENSE_APPROVED_BY'];
      obj1['Approved Date Time'] = this.dataList1[i]['EXPENSE_APPROVED_DATE_TIME'];
      obj1['Head Remark'] = this.dataList1[i]['EXPENSES_HEAD_REMARK'];
      obj1['HR Remark'] = this.dataList1[i]['EXPENSES_HR_REMARK'];
      obj1['GM Remark'] = this.dataList1[i]['EXPENSES_GM_REMARK'];
      obj1['Accountant'] = this.dataList1[i]['EXPENSES_ACCOUNTANT_REMARK'];
      // obj1['Expenses Attachment'] = this.dataList1[i]['EXPENSES_ATTACHMENTS'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Tour Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }





  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  // applyFilter() {
  //   this.isSpinning = true
  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
  //   this.filterQuery = "";
  //   if (this.MODEL != undefined) {
  //     //  this.message.error("Please Select Atleast One Option From The Following",'')
  //     this.filterQuery = " AND MODEL_NAME = " + this.MODEL;
  //     this.isSpinning = false;
  //     this.loadingRecords = false;
  //     this.isFilterApplied = "primary";
  //     console.log("FilterQuery", this.filterQuery)

  //   }
  //   else if (this.MODEL == undefined) {
  //     this.message.error("Please Select Option To Filter", '')
  //     this.loadingRecords = false;
  //     this.isSpinning = false;
  //     this.isFilterApplied = "default";
  //   }

  //   this.api.branchwiseadvancecollectedreports(this.pageIndex, this.pageSize, this.sortKey, sort, '', 0, '', '').subscribe(data => {
  //     console.log(data)
  //     this.loadingRecords = false;
  //     this.totalRecords = data['count'];
  //     this.dataList = data['data'];
  //   }, err => {
  //     console.log(err);
  //   });
  // }
  // clearFilter() {
  //   this.MODEL = "AL";
  //   this.quotationdate = new Date();
  //   this.BRANCH = "AL";
  //   this.filterClass = 'filter-invisible';
  //   //  this.isFilterApplied="default";

  // }


  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

   

    
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }


    if (this.EMPLOYEE_ID==undefined && this.FROM_DATE == undefined&& this.TO_DATE == undefined&& this.ADVANCED_APPROVED == undefined
      && this.ADVANCE_APPROVED_DATE == undefined&& this.ADVANCE_PENDING_AT == undefined&& this.EXPENSES_APPROVED_BY == undefined&& this.EXPENSES_PENDING_AT == undefined && this.EXPENSE_APPROVED_DATE == undefined ) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;

      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
    }
    if (this.isOk == true) {
      this.BRANCH_ID = this.BRANCH_ID;
    

      this.search(true);
    }

  
  }


  clearFilter() {
    this.startValue = new Date();
    this.endValue = new Date();
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.BRANCH_ID = [];
    this.FROM_DATE = [];
    this.EMPLOYEE_ID = [];
    this.TO_DATE= [];
    this.ADVANCED_APPROVED=[];
    this.ADVANCE_APPROVED_DATE= [];
    this.ADVANCE_PENDING_AT=[];
    this.EXPENSES_APPROVED_BY=[];
    this.EXPENSES_PENDING_AT=[]
    this.EXPENSE_APPROVED_DATE= [];
    this.SELECT_BRANCH = false;
    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(true);

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

  month1(event: any) {

  }

}