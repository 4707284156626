export class memoSignatureDetails {
    // ID:any;
    // SIGNATURE:any
    ACCOUNTANT_NAME:any;
    ACCOUNTANT_SIGNATURE:any;
    ACCOUNT_DATETIME:any; 
    ACCOUNT_ID:any;   
    CLIENT_ID:any;
        
    CONSULTANT_DATETIME:any;
       
    CONSULTANT_ID:any;
      
    CONSULTANT_NAME:any;
       
    CONSULTANT_SIGNATURE:any;
        
    CORPORATE_DATETIME:any;
        
    CORPORATE_ID:any;
        
    CORPORATE_NAME:any;
        
    CORPORATE_SIGNATURE:any;
        
    CREATED_MODIFIED_DATE:any;
        
    DIRECTOR_DATETIME:any;
        
    DIRECTOR_ID:any;
       
    DIRECTOR_NAME:any;
        
    DIRECTOR_SIGNATURE:any;
        
    GENERAL_MANAGER_DATETIME:any;
        
    GENERAL_MANAGER_ID:any;
        
    GM_NAME:any;
        
    GM_SIGNATURE:any;
        
    HPROMISE_DATETIME:any;
        
    HPROMISE_ID:any;
        
    HPROMISE_NAME:any;
       
    HPROMISE_SIGNATURE:any;
       
    ID:any;
       
    MEMO_ID:any;
      
    SALES_HEAD_ID:any;
       
    SALES_HEAD_NAME:any;
        
    SALES_HEAD_SIGNATURE:any;
        
    SALES_MANAGER_DATETIME:any;
    SALES_HEAD_DATETIME:any
       
    SALES_MANAGER_ID:any;
        
    SALES_MANAGER_NAME:any;
        
    SALES_MANAGER_SIGNATURE:any;
        
}