import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-discount-detailed-report',
  templateUrl: './discount-detailed-report.component.html',
  styleUrls: ['./discount-detailed-report.component.css']
})
export class DiscountDetailedReportComponent implements OnInit {

  formTitle = "Discount Detailed Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'EWSalesSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "QUOTATION_NO";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["DATE", "Quotation Date"],
  ["QUOTATION_NO", "Quotation No."],
  ["REQUESTED_AMOUNT", "Discount Requested"],
  ["APPROVED_AMOUNT", "Discount Approved"],
  ["REJECTED_AMOUNT", "Discount Rejected"],
  ["MODEL_NAME", "Model Name"],];


  columns1: string[][] = [["DATE"],
  ["QUOTATION_NO"],
  ["MODEL_NAME"]];
  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;


  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  FROMDATE: any = new Date();
  TODATE: any = new Date();
  endOpen = false;
  startOpen = false;


  MODEL_ID = [];
  month = new Date().getMonth().toString() + (new Date().getMonth()).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_CONSULTANT_ID: any = [];
  current = new Date();

  
  DATE = [];

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.FROMDATE = new Date();
    this.TODATE = new Date();
    this.getModels();
    this.search();

  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      FROMDATE: this.FROMDATE,
      TODATE: this.TODATE,
      MODEL_ID: this.MODEL_ID,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 29').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(): void {
    // this.search(false);
    
  }

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  SELECT_ALL2: boolean = false;

  onSelectAllChecked2(event: any) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids;
  }

  onSelectOff2(event: any) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }
  name: any;
  models = [];
  getModels() {
    this.api.getAllModels(0, 0, "ID", "asc", " AND STATUS=1").subscribe((data) => {
      // if (data["code"] == 200) {
      this.models = data["data"];
      if (this.models.length > 0) {
        this.MODEL_ID = [data['data'][0]['ID']];
        this.onSelectAllChecked2(true);

      }
      else { }
      // }
    },
      (err) => {
        console.log(err);
      }
    );
  }


  applyFilter() {
    this.isFilterApplied = 'primary';

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns1.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+')';
    }

  

    if (this.MODEL_ID != undefined) {
      this.MODEL_ID = this.MODEL_ID;
    }

    this.search();
    this.filterClass = 'filter-invisible';

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.searchText='';
    this.filterQuery = '';
    this.DATE = []
    this.FROMDATE = new Date();
    this.TODATE = new Date();
    // this.SELECT_ALL2 = false;
    // this.getModels();
    this.search(true);
    this.onSelectAllChecked2(true);
  }
  ewsalesdata = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    
    if (this.FROMDATE != undefined && this.FROMDATE.length != 0  && this.TODATE != undefined && this.TODATE.length != 0) {
      this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
      this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.discountDetailedReports(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.MODEL_ID, this.FROMDATE, this.TODATE)
        .subscribe(
          (data) => {
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.discountDetailedReports(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.MODEL_ID, this.FROMDATE, this.TODATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['Quotation Date'] = this.ewsalesdata[i]['DATE'];
      obj1['Quotation No.'] = this.ewsalesdata[i]['QUOTATION_NO'];
      obj1['Discount Requested'] = this.ewsalesdata[i]['REQUESTED_AMOUNT'];
      obj1['Discount Approved'] = this.ewsalesdata[i]['APPROVED_AMOUNT'];
      obj1['Discount Rejected'] = this.ewsalesdata[i]['REJECTED_AMOUNT'];
      obj1['Model Name'] = this.ewsalesdata[i]['MODEL_NAME'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'Discount Detail Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}