import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { log } from 'console';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  userName = this.cookie.get('userName');
  emailId = this.cookie.get('emailId');
  roleId = Number(this.cookie.get("roleId"));
  // currentMonth: string = "";
  // currentYear: string = "";
  // EOM_Name: string = "";
  // EOM_Dept: string = "";
  // EOM_Designation: string = "";
  // EOM_Branch: string = "";
  // EOM_Image: string = undefined;
  // isEOMLoading: boolean = false;
  formTitle = "Branch Wise Leave Summary"
  formTitle1 = "Branch Wise Absent Summary"
  formTitle2 = "Top 10 Superlate Employees"
  formTitle3 = "Top 10 Employee Started Day From Max Distance"
  formTitle4 = "Daily Morning Short Leave"
  formTitle5 = "Daily Evening Short Leave"
  formTitle6 = "Monthly Average Late Employee"
  formTitle7 = "Top 10 Most Late Coming Employee"
  formTitle8 = "Inhouse Finance Report"
  formTitle9 = "Outhouse Finance Report"
  formTitle10 = "Montly Branch Wise Model Wise Invoice Generated"
  formTitle12 = "Montly Model Wise Invoice Generated"
  formTitle13 = "Quotation Generated Today Report"
  formTitle14 = "Outhouse Insurance Report"
  formTitle15 = "Inhouse Insurance Report"
  formTitle16 = "Monthly Branch Wise Most Discount Approval Report"
  formTitle17 = "Branch Wise Memo Pendancy Report"
  formTitle18 = "Branch Wise Discount Pendancy Report"


  pageSize: number = 10;
  pageIndex: number = 1;
  totalRecords: any;
  sortValue: string = "";
  sortKey: string = "";

  sortValue1: string = "desc";
  sortKey1: string = "ID";


  columns: string[][] = [["BRANCH_NAME", "Branch Name"]];
  columns1: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["ABSENT_COUNT", "Absent Count"]];

  superLateDate: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["SHIFT_NAME", "Shift"],
  ["IN_TIME", "In Time"]];

  distancedata: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["IN_DISTANCE", "Day-In Distance"],
  ["DAYIN_REASON", "Reason"],
  ["IN_TIME", "Dayin Time"],];

  morningData: string[][] = [["NAME", "Employee Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["EXPECTED_IN_TIME", "Expected In Time"],
  ["IN_TIME", "Actual In Time"],];

  eveningData: string[][] = [["NAME", "Employee Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["EXPECTED_OUT_TIME", "Expected Out Time"],
  ["OUT_TIME", "Actual Out Time"],];

  lateData: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["NO_OF_LATE_COMMING", "No. Of Late Coming"],];

  avgLateData: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],];

  inHouseFinance: string[][] = [["BANK_NAME", "Bank Name"],
  ["FINANCE_COUNT", "Count"],];

  OutHouseFinance: string[][] = [["BANK_NAME", "Bank Name"],
  ["FINANCE_COUNT", "Count"],];

  monthlybranchwisemodel: string[][] = [["BRANCH_NAME", "Branch"],
  ["MODEL_NAME", "Model"], ["INVOICE_COUNT", "Count Of Invoice Genereated"],
  ["INVOICE_DATE", "Invoice Date"],["MEMO_DATE", "Memo Created Date"]];

  modelwiseinvoicegenerate: string[][] = [["MODEL_NAME", "Model"],
 ["INVOICE_COUNT", "Count Of Invoice Genereated"],
  ["INVOICE_DATE", "Invoice Date"],["MEMO_DATE", "Memo Created Date"]];
 
  Quotationgeneratedtoday: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
  ["COUNT", "Count"],];

  inHouseInsurance : string[][] = [["INSURANCE_NAME", "Insurance Company"],
  ["INSURANCE_COUNT", "Insurance Count"],];

  OutHouseInsurance : string[][] = [["INSURANCE_NAME", "Insurance Company"],
  ["INSURANCE_COUNT", "Insurance Count"],];

  MonthlyBranchWiseMostDiscountApproval: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["SALES_EXECUTIVE_DISCOUNT", "Sales Executive Discount"], ["APPROVED_DISCOUNT", "Approved Discount"],
  ["TOTAL_DISCOUNT", "Total Discount"]];

  BranchWiseMemoPendancy: string[][] = [["BRANCH_NAME", "Branch Name"],
 ["PENDING_MEMOS", "Count of Pending Memo"]];
 
  BranchWiseDiscountPendancy: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["PENDING_DISCOUNT", "Count of Pending Discount"],];
 

  FROM_DATE: any;
  TO_DATE: any;
  DATE = [];
  DATE1 = [];
  DATE2 = [];
  DATE3 = []
  DATE4 = []
  DATE5 = []
  DATE6 = []
  date = new Date()
  startValue1 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue1 = new Date();
  startValue2 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue2 = new Date();
  startValue3 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue3 = new Date();
  startValue4 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue4 = new Date();
  startValue5 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue5 = new Date();
  startValue6 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue6 = new Date();
  startValue7 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue7 = new Date();
  startValue8 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue8 = new Date();
  startValue9 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue9 = new Date();
  startValue10 = new Date(this.date.getFullYear(),this.date.getMonth(),1);
  endValue10 = new Date();
  START_DATE:any;
  END_DATE:any;

  current = new Date();
  constructor(private cookie: CookieService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.DATE[0] = new Date();
    this.DATE[1] = new Date();

    this.DATE1[0] = new Date();
    this.DATE1[1] = new Date();

    this.DATE2[0] = new Date();
    this.DATE2[1] = new Date();

    this.DATE3[0] = new Date();
    this.DATE3[1] = new Date();

    this.DATE4[0] = new Date();
    this.DATE4[1] = new Date();

    this.DATE5[0] = new Date();
    this.DATE5[1] = new Date();

    this.DATE6[0] = new Date();
    this.DATE6[1] = new Date();

    // this.applyFilter();
    // this.applyFilter1();
    // this.applyFilter2()
    // this.applyFilter3();
    // this.applyFilter4();
    // this.applyFilter5();
    // this.applyFilter6();
    // this.applyFilter7();
    // this.applyFilter8();
    // this.applyFilter9();
    // // this.applyFilter10();
    // this.applyFilter12();
    // this.applyFilter13();
    // this.applyFilter14();
    // this.applyFilter15();
    // this.applyFilter16();
    // this.applyFilter17();
    // this.applyFilter18();
  }
  loadingRecordss = false;
  pageIndexs = 1;
  pageSizes = 10;
  totalRecordss = 1;


12 = false;
  pageIndex11 = 1;
  pageSize11 = 10;
  totalRecords11 = 1;

  loadingRecords = false;
  pageIndex1 = 1;
  pageSize1 = 10;
  totalRecords1 = 1;

  isVisible = false;
  empData = [];
  branch = [];
  name: any;
  empData1: any
  id: any = []
  isSpinning = false;
  filterClass: string = "filter-invisible";
  isFilterApplied: any = "default";
  loadingRecords1 = false;

  filterClass1: string = "filter-invisible";
  isFilterApplied1: any = "default";
  isSpinning1 = false;

  filterClass2: string = "filter-invisible";
  isFilterApplied2: any = "default";
  loadingRecords2 = false;
  totalRecords2 = 1
  isSpinning2 = false;
  pageIndex2 = 1;
  pageSize2 = 10;

  filterClass3: string = "filter-invisible";
  isFilterApplied3: any = "default";
  loadingRecords3 = false;
  totalRecords3 = 1
  isSpinning3 = false;

  filterClass4: string = "filter-invisible";
  isFilterApplied4: any = "default";
  loadingRecords4 = false;
  totalRecords4 = 1
  isSpinning4 = false;
  pageIndex4 = 1;
  pageSize4 = 10;

  filterClass5: string = "filter-invisible";
  isFilterApplied5: any = "default";
  pageIndex5 = 1;
  pageSize5 = 10;
  loadingRecords5 = false;
  totalRecords5 = 1
  isSpinning5 = false;

  filterClass6: string = "filter-invisible";
  isFilterApplied6: any = "default";
  loadingRecords6 = false;
  totalRecords6 = 1
  isSpinning6 = false;
  pageIndex6 = 1;
  pageSize6 = 10;

  loadingRecords11 = false;

  filterClass7: string = "filter-invisible";
  isFilterApplied7: any = "default";
  loadingRecords7 = false;
  totalRecords7 = 1
  isSpinning7 = false;

  filterClass8: string = "filter-invisible";
  isFilterApplied8: any = "default";
  loadingRecords8 = false;
  totalRecords8 = 1
  isSpinning8 = false;
  pageIndex8 = 1;
  pageSize8 = 10;

  filterClass9: string = "filter-invisible";
  isFilterApplied9: any = "default";
  loadingRecords9 = false;
  totalRecords9 = 1
  isSpinning9 = false;
  pageIndex9 = 1;
  pageSize9 = 10;

  filterClass10: string = "filter-invisible";
  isFilterApplied10: any = "default";
  loadingRecords10 = false;
  totalRecords10 = 1
  isSpinning10 = false;
  pageIndex10 = 1;
  pageSize10 = 10;
 
  filterClass12: string = "filter-invisible";
  isFilterApplied12: any = "default";
  loadingRecords12 = false;
  totalRecords12 = 1
  isSpinning12 = false;
  pageIndex12 = 1;
  pageSize12 = 10;

  filterClass13: string = "filter-invisible";
  isFilterApplied13: any = "default";
  loadingRecords13 = false;
  totalRecords13 = 1
  isSpinning13 = false;
  pageIndex13 = 1;
  pageSize13 = 10;

  filterClass14: string = "filter-invisible";
  isFilterApplied14: any = "default";
  loadingRecords14 = false;
  totalRecords14 = 1
  isSpinning14 = false;
  pageIndex14 = 1;
  pageSize14 = 10;

  filterClass15: string = "filter-invisible";
  isFilterApplied15: any = "default";
  loadingRecords15 = false;
  totalRecords15 = 1
  isSpinning15 = false;
  pageIndex15 = 1;
  pageSize15 = 10;

  filterClass16: string = "filter-invisible";
  isFilterApplied16: any = "default";
  loadingRecords16 = false;
  totalRecords16 = 1
  isSpinning16 = false;
  pageIndex16 = 1;
  pageSize16 = 10;

  filterClass17: string = "filter-invisible";
  isFilterApplied17: any = "default";
  loadingRecords17 = false;
  totalRecords17 = 1
  isSpinning17 = false;
  pageIndex17 = 1;
  pageSize17 = 10;
  
  filterClass18: string = "filter-invisible";
  isFilterApplied18: any = "default";
  loadingRecords18 = false;
  totalRecords18 = 1
  isSpinning18 = false;
  pageIndex18 = 1;
  pageSize18 = 10;

  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  MONTH2: any = new Date()
  YEAR: any = this.year
  YEAR2: any = this.year
  monthFormat = "MMM-yyyy";
  filterQuery: string = '';


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  
  applyFilter(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied = 'primary'
    this.filterClass = "filter-invisible";

    this.loadingRecords = false;
    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null) {
      this.FROM_DATE = this.DATE[0]
    }

    if (this.DATE[1] != null) {
      this.TO_DATE = this.DATE[1]
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }
    this.loadingRecords = true;
    this.api.getBranchWiseLeaveSummaryReport(0, 0, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords = false;
      this.totalRecords = data1['count'];
      this.empData = data1['data'];
      this.isSpinning = false;
      this.filterClass = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';

    this.DATE = []
    this.DATE[0] = new Date();
    this.DATE[1] = new Date();
    this.applyFilter()
  }
  BRANCH_ID: any
  showModal(data: any, reset: boolean = true): void {
    if (reset) {
      this.pageIndexs = 1;
      this.pageSizes = 10;
    }

    this.isVisible = true;

    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null && this.DATE[1] != null) {
      this.filterQuery = " AND DATE BETWEEN '" + this.DATE[0] + "' AND '" + this.DATE[1] + "' "

      var filter = '';
      filter = this.filterQuery;
    }

    this.pageIndexs = 1
    console.log(data.BRANCH_ID, "branchid")

    this.loadingRecordss = true;
    this.api.getEmployeeLeaveApplicationDetails(0, 0, this.sortKey, this.sortValue, 'AND BRANCH_ID=' + data.BRANCH_ID + this.filterQuery).subscribe((data1) => {
      console.log(data1);
      console.log(data.BRANCH_ID, 'branchID')
      this.empData = data1['data'];
      this.loadingRecordss = false;
      this.totalRecordss = data1['count'];

      for (let i = 0; i < this.empData.length; i++) {
        this.branch.push(this.empData[i]['NAME'])
        console.log(this.branch, "branch")
      }

      // this.empData = data1['data'][0]['NAME'];
      // console.log(this.empData, "names")
    },
      (err) => {
        console.log(err);
      }
    );

  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.applyFilter()
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.applyFilter()
  }

  branch1 = []

  showModal2(data): void {
    this.isVisible2 = true;

    this.DATE1[0] = this.datePipe.transform(this.DATE1[0], 'yyyy-MM-dd')
    this.DATE1[1] = this.datePipe.transform(this.DATE1[1], 'yyyy-MM-dd')

    if (this.DATE1[0] != null && this.DATE1[1] != null) {
      this.filterQuery = " AND DATE BETWEEN '" + this.DATE1[0] + "' AND '" + this.DATE1[1] + "' "

      var filter = '';
      filter = this.filterQuery;
    }

    // if(data != undefined){
    console.log(data)
    this.loadingRecords11 = true;
    // this.api.getAttendance(0, 0, this.sortKey, this.sortValue, 'AND BRANCH_ID=' + data.BRANCH_ID + ' AND STATUS= "A" ' + this.filterQuery).subscribe((data1) => {
    //   console.log(data1);
    //   this.absentData = data1['data'];
    //   this.loadingRecords11 = false;
    //   this.totalRecords11 = data1['count'];

    //   for (let i = 0; i < this.absentData.length; i++) {
    //     this.branch1.push(this.absentData[i]['NAME'])
    //     console.log(this.branch1, "branch")
    //   }
    //   // this.empData = data1['data'][0]['NAME'];
    //   // console.log(this.empData, "names")
    // },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    // }


  }
  isVisible2 = false;
  handleOk2(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.applyFilter1()
  }

  handleCancel2(): void {
    console.log('Button cancel clicked!');
    this.isVisible2 = false;
    this.applyFilter1()
  }

  // branchWiseLeaveSummary() {
  //   this.api.getBranchWiseLeaveSummaryReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.empData = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  absentData = [];
  // branchWiseAbsentSummary() {
  //   this.api.getBranchWiseAbsentSummaryReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.absentData = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  distance = []
  // employeeStartedDayMaxDistanceReport() {
  //   this.api.employeeStartedDayMaxDistanceReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.distance = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  morning = []
  // dailyMorningShortLeave() {
  //   this.api.dailyMorningShortLeave(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.morning = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  evening = []
  // dailyEveningShortLeave() {
  //   this.api.dailyEveningShortLeave(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.evening = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  showFilter1() {
    if (this.filterClass1 === "filter-visible")
      this.filterClass1 = "filter-invisible";

    else
      this.filterClass1 = "filter-visible";
  }


  applyFilter1(reset: boolean = false) {
    if (reset) {
      this.pageIndex1 = 1;
    }
    this.isFilterApplied1 = 'primary'
    this.filterClass1 = "filter-invisible";

    this.loadingRecords = false;
    this.DATE1[0] = this.datePipe.transform(this.DATE1[0], 'yyyy-MM-dd')
    this.DATE1[1] = this.datePipe.transform(this.DATE1[1], 'yyyy-MM-dd')

    if (this.DATE1[0] != null) {
      this.FROM_DATE = this.DATE1[0]
    }

    if (this.DATE1[1] != null) {
      this.TO_DATE = this.DATE1[1]
    }

    if (this.DATE1 != undefined && this.DATE1.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE1[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE1[1], "yyyy-MM-dd");
    }
    this.loadingRecords1 = true;
    this.api.getBranchWiseAbsentSummaryReport(0, 0, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords1 = false;
      this.totalRecords1 = data1['count'];
      this.absentData = data1['data'];
      this.isSpinning1 = false;
      this.filterClass = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter1() {
    this.filterClass1 = 'filter-invisible';
    this.isFilterApplied1 = 'default';

    this.DATE1 = []
    this.DATE1[0] = new Date();
    this.DATE1[1] = new Date();
    this.applyFilter1()

  }
  superLate = []
  showFilter2() {
    if (this.filterClass2 === "filter-visible")
      this.filterClass2 = "filter-invisible";

    else
      this.filterClass2 = "filter-visible";
  }
  SHIFT_ID: any = [];
  applyFilter2(reset: boolean = false) {
    if (reset) {
      this.pageIndex2 = 1;
    }
    this.isFilterApplied2 = 'primary'
    this.filterClass2 = "filter-invisible";

    this.loadingRecords2 = false;
    this.DATE2[0] = this.datePipe.transform(this.DATE2[0], 'yyyy-MM-dd')
    this.DATE2[1] = this.datePipe.transform(this.DATE2[1], 'yyyy-MM-dd')

    if (this.DATE2[0] != null) {
      this.FROM_DATE = this.DATE2[0]
    }

    if (this.DATE2[1] != null) {
      this.TO_DATE = this.DATE2[1]
    }

    if (this.DATE2 != undefined && this.DATE2.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE2[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE2[1], "yyyy-MM-dd");
    }

    if (this.SHIFT_ID != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }


    this.loadingRecords2 = true;
    this.api.suparLateEmployeeReport(this.pageIndex2, this.pageSize2, this.sortKey, this.sortValue, '', this.SHIFT_ID, this.FROM_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords2 = false;
      this.totalRecords2 = data1['count'];
      this.superLate = data1['data'];
      this.isSpinning2 = false;
      this.filterClass2 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter2() {
    this.filterClass2 = 'filter-invisible';
    this.isFilterApplied2 = 'default';

    this.DATE2 = []
    this.DATE2[0] = new Date();
    this.DATE2[1] = new Date();
    this.applyFilter2()

  }


  showFilter3() {
    if (this.filterClass3 === "filter-visible")
      this.filterClass3 = "filter-invisible";

    else
      this.filterClass3 = "filter-visible";
  }

  applyFilter3(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied3 = 'primary'
    this.filterClass3 = "filter-invisible";

    this.loadingRecords3 = false;
    this.DATE3[0] = this.datePipe.transform(this.DATE3[0], 'yyyy-MM-dd')
    this.DATE3[1] = this.datePipe.transform(this.DATE3[1], 'yyyy-MM-dd')

    if (this.DATE3[0] != null) {
      this.FROM_DATE = this.DATE3[0]
    }

    if (this.DATE3[1] != null) {
      this.TO_DATE = this.DATE3[1]
    }

    if (this.DATE3 != undefined && this.DATE3.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE3[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE3[1], "yyyy-MM-dd");
    }

    this.loadingRecords3 = true;
    this.api.employeeStartedDayMaxDistanceReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords3 = false;
      this.totalRecords3 = data1['count'];
      this.distance = data1['data'];
      this.isSpinning3 = false;
      this.filterClass3 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter3() {
    this.filterClass3 = 'filter-invisible';
    this.isFilterApplied3 = 'default';

    this.DATE3 = []
    this.DATE3[0] = new Date();
    this.DATE3[1] = new Date();
    this.applyFilter3()

  }

  showFilter4() {
    if (this.filterClass4 === "filter-visible")
      this.filterClass4 = "filter-invisible";

    else
      this.filterClass4 = "filter-visible";
  }

  applyFilter4() {

    this.pageIndex4 = 1;
    this.pageSize4 = 10;

    this.isFilterApplied4 = 'primary'
    this.filterClass4 = "filter-invisible";

    this.loadingRecords4 = false;
    this.DATE4[0] = this.datePipe.transform(this.DATE4[0], 'yyyy-MM-dd')
    this.DATE4[1] = this.datePipe.transform(this.DATE4[1], 'yyyy-MM-dd')

    if (this.DATE4[0] != null) {
      this.FROM_DATE = this.DATE4[0]
    }

    if (this.DATE4[1] != null) {
      this.TO_DATE = this.DATE4[1]
    }

    if (this.DATE4 != undefined && this.DATE4.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE4[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE4[1], "yyyy-MM-dd");
    }

    if (this.SHIFT_ID != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }

    this.loadingRecords4 = true;
    this.api.dailyMorningShortLeave(this.pageIndex4, this.pageSize4, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE, this.SHIFT_ID).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords4 = false;
      this.totalRecords4 = data1['count'];
      this.morning = data1['data'];
      this.isSpinning4 = false;
      this.filterClass4 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }


  clearFilter4() {
    this.filterClass4 = 'filter-invisible';
    this.isFilterApplied4 = 'default';

    this.DATE4 = []
    this.DATE4[0] = new Date();
    this.DATE4[1] = new Date();
    this.applyFilter4()

  }

  showFilter5() {
    if (this.filterClass5 === "filter-visible")
      this.filterClass5 = "filter-invisible";

    else
      this.filterClass5 = "filter-visible";
  }

  applyFilter5() {
    this.isFilterApplied5 = 'primary'
    this.filterClass5 = "filter-invisible";

    this.loadingRecords5 = false;
    this.DATE5[0] = this.datePipe.transform(this.DATE5[0], 'yyyy-MM-dd')
    this.DATE5[1] = this.datePipe.transform(this.DATE5[1], 'yyyy-MM-dd')

    if (this.DATE5[0] != null) {
      this.FROM_DATE = this.DATE5[0]
    }

    if (this.DATE5[1] != null) {
      this.TO_DATE = this.DATE5[1]
    }

    if (this.DATE5 != undefined && this.DATE5.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE5[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE5[1], "yyyy-MM-dd");
    }

    if (this.SHIFT_ID != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }

    this.loadingRecords5 = true;
    this.api.dailyEveningShortLeave(this.pageIndex5, this.pageSize5, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE, this.SHIFT_ID).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords5 = false;
      this.totalRecords5 = data1['count'];
      this.evening = data1['data'];
      this.isSpinning5 = false;
      this.filterClass5 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter5() {
    this.filterClass5 = 'filter-invisible';
    this.isFilterApplied5 = 'default';

    this.DATE5 = []
    this.DATE5[0] = new Date();
    this.DATE5[1] = new Date();
    this.applyFilter5()

  }

  showFilter6() {
    if (this.filterClass6 === "filter-visible")
      this.filterClass6 = "filter-invisible";

    else
      this.filterClass6 = "filter-visible";
  }
  avgLate = [];

  applyFilter6(reset:boolean=false) {
    if (reset) {
      this.pageIndex6 = 1;
    }
    this.isFilterApplied6 = 'primary'
    this.filterClass6 = "filter-invisible";

    this.loadingRecords6 = false;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");

    if (this.MONTH2 != undefined || this.MONTH2 != '') {
      this.MONTH = this.datePipe.transform(this.MONTH2, 'MM')
    }

    if (this.MONTH2 != undefined || this.MONTH2 != '') {
      this.YEAR = this.datePipe.transform(this.MONTH2, 'yyyy')
    }
    this.pageIndex6=1
    this.pageSize6=10
    this.loadingRecords6 = true;
    // this.api.monthlyAverageLateEmployee(this.pageIndex6, this.pageSize6, this.sortKey, this.sortValue, '',this.SHIFT_ID=1, this.MONTH, this.YEAR).subscribe((data1) => {
    //   console.log(data1);
    //   this.loadingRecords6 = false;
    //   this.totalRecords6 = data1['count'];
    //   this.avgLate = data1['data'];
    //   this.isSpinning6 = false;
    //   this.filterClass6 = 'filter-invisible';
    // },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

  }

  clearFilter6() {
    this.filterClass6 = 'filter-invisible';
    this.isFilterApplied6 = 'default';

    this.MONTH2 = new Date()
    this.YEAR2 = this.year
    this.applyFilter6()

  }

  late = [];

  showFilter7() {
    if (this.filterClass7 === "filter-visible")
      this.filterClass7 = "filter-invisible";

    else
      this.filterClass7 = "filter-visible";
  }

  applyFilter7() {
    this.isFilterApplied7 = 'primary'
    this.filterClass7 = "filter-invisible";

    this.loadingRecords7 = false;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");

    if (this.MONTH1 != undefined || this.MONTH1 != '') {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined || this.MONTH1 != '') {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (this.SHIFT_ID != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }

    this.loadingRecords7 = true;
    this.api.mostLateCommingEmployee(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.MONTH, this.YEAR).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords7 = false;
      this.totalRecords7 = data1['count'];
      this.late = data1['data'];
      this.isSpinning7 = false;
      this.filterClass7 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter7() {
    this.filterClass7 = 'filter-invisible';
    this.isFilterApplied7 = 'default';

    this.MONTH1 = new Date()
    this.YEAR = this.year
    this.applyFilter7()

  }
  // Table 8
  showFilter8() {
    if (this.filterClass8 === "filter-visible")
      this.filterClass8 = "filter-invisible";

    else
      this.filterClass8 = "filter-visible";
  }
  
  dataList:any;
  applyFilter8() {
    this.isFilterApplied8 = 'primary'
    this.filterClass8 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue1, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue1, "yyyy-MM-dd");

    this.loadingRecords8 = true;
    this.api.getInhouseFinanceReport(this.pageIndex8, this.pageSize8, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords8 = false;
        this.totalRecords8 = data['count'];
        this.dataList = data['data'];
        this.isSpinning8 = false;
        this.filterClass8 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter8() {
    this.filterClass8 = 'filter-invisible';
    this.isFilterApplied8 = 'default';
    this.startValue1 = new Date();
    this.endValue1 = new Date();
    this.applyFilter8()

  }

  // Table 9
  showFilter9() {
    if (this.filterClass9 === "filter-visible")
      this.filterClass9 = "filter-invisible";

    else
      this.filterClass9 = "filter-visible";
  }

  dataList9:any;
  applyFilter9() {
    this.isFilterApplied9 = 'primary'
    this.filterClass9 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue2, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue2, "yyyy-MM-dd");

    this.loadingRecords9 = true;
    this.api.getOuthouseFinanceReport(this.pageIndex9, this.pageSize9, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords9 = false;
        this.totalRecords9 = data['count'];
        this.dataList9 = data['data'];
        this.isSpinning9 = false;
        this.filterClass9 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter9() {
    this.filterClass9 = 'filter-invisible';
    this.isFilterApplied9 = 'default';
    this.startValue2 = new Date();
    this.endValue2 = new Date();
    this.applyFilter9()

  }
  // Table 10
  showFilter10() {
    if (this.filterClass10 === "filter-visible")
      this.filterClass10 = "filter-invisible";

    else
      this.filterClass10 = "filter-visible";
  }
  // END_DATE:any;
  // START_DATE:any;
  dataList10:any;
  // applyFilter10() {
  //   this.isFilterApplied10 = 'primary'
  //   this.filterClass10 = "filter-invisible";

  //   this.END_DATE = this.datePipe.transform(this.endValue3, "yyyy-MM-dd");
  //   this.START_DATE = this.datePipe.transform(this.startValue3, "yyyy-MM-dd");

  //   this.loadingRecords10 = true;
  //   this.api.getMontlyBranchWiseModelWiseInvoiceGenerated(this.pageIndex10, this.pageSize10, this.sortKey1, this.sortValue1, '', this.START_DATE,
  //     this.END_DATE
  //   ).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.loadingRecords10 = false;
  //       this.totalRecords10 = data['count'];
  //       this.dataList10 = data['data'];
  //       this.isSpinning10 = false;
  //       this.filterClass10 = 'filter-invisible';
  //     }
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  clearFilter10() {
    this.filterClass10 = 'filter-invisible';
    this.isFilterApplied10 = 'default';
    this.startValue3 = new Date();
    this.endValue3 = new Date();
    // this.applyFilter10()

  }
  // Table 12
  showFilter12() {
    if (this.filterClass12 === "filter-visible")
      this.filterClass12 = "filter-invisible";

    else
      this.filterClass12 = "filter-visible";
  }
  month1 = new Date();
  dataList12:any;
  applyFilter12() {
    this.isFilterApplied12 = 'primary'
    this.filterClass12 = "filter-invisible";

    this.loadingRecords12 = false;
    this.END_DATE = this.datePipe.transform(this.endValue4, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue4, "yyyy-MM-dd");
    this.MONTH = this.datePipe.transform(this.month1, "MM");
    this.loadingRecords12 = true;
    this.api.getMontlyModelWiseInvoiceGenerated(this.pageIndex12, this.pageSize12, this.sortKey1, this.sortValue1, '', this.MONTH,this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords12 = false;
        this.totalRecords12 = data['count'];
        this.dataList12 = data['data'];
        this.isSpinning12 = false;
        this.filterClass12 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter12() {
    this.filterClass12 = 'filter-invisible';
    this.isFilterApplied12 = 'default';
    this.startValue4 = new Date();
    this.endValue4 = new Date();
    this.month1 = new Date();
    this.applyFilter12()
  }

  // Table 13
  showFilter13() {
    if (this.filterClass13 === "filter-visible")
      this.filterClass13 = "filter-invisible";

    else
      this.filterClass13 = "filter-visible";
  }

  dataList13:any;
  applyFilter13() {
    this.isFilterApplied13 = 'primary'
    this.filterClass13 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue5, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue5, "yyyy-MM-dd");

    this.loadingRecords13 = true;
    this.api.getSalesExecutiveWiseQuotationGeneratedTodayReport(this.pageIndex13, this.pageSize13, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords13 = false;
        this.totalRecords13 = data['count'];
        this.dataList13 = data['data'];
        this.isSpinning13 = false;
        this.filterClass13 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter13() {
    this.filterClass13 = 'filter-invisible';
    this.isFilterApplied13 = 'default';
    this.startValue5 = new Date();
    this.endValue5 = new Date();
    this.applyFilter13()

  }
 // Table 14
  showFilter14() {
    if (this.filterClass14 === "filter-visible")
      this.filterClass14 = "filter-invisible";

    else
      this.filterClass14 = "filter-visible";
  }

  dataList14:any;
  applyFilter14() {
    this.isFilterApplied14 = 'primary'
    this.filterClass14 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue6, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue6, "yyyy-MM-dd");

    this.loadingRecords14= true;
    this.api.getOuthouseInsurance(this.pageIndex14, this.pageSize14, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords14 = false;
        this.totalRecords14 = data['count'];
        this.dataList14 = data['data'];
        this.isSpinning14 = false;
        this.filterClass14 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter14() {
    this.filterClass14 = 'filter-invisible';
    this.isFilterApplied14 = 'default';
    this.startValue6 = new Date();
    this.endValue6 = new Date();
    this.applyFilter14()

  }
 
  // Table 15
  showFilter15() {
    if (this.filterClass15 === "filter-visible")
      this.filterClass15 = "filter-invisible";

    else
      this.filterClass15 = "filter-visible";
  }

  dataList15:any;
  applyFilter15() {
    this.isFilterApplied15 = 'primary'
    this.filterClass15 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue7, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue7, "yyyy-MM-dd");

    this.loadingRecords15= true;
    this.api.getInhouseInsurance(this.pageIndex15, this.pageSize15, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords15 = false;
        this.totalRecords15 = data['count'];
        this.dataList15 = data['data'];
        this.isSpinning15 = false;
        this.filterClass15 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter15() {
    this.filterClass15 = 'filter-invisible';
    this.isFilterApplied15 = 'default';
    this.startValue7 = new Date();
    this.endValue7 = new Date();
    this.applyFilter15()

  }

  // Table 16
  showFilter16() {
    if (this.filterClass16 === "filter-visible")
      this.filterClass16 = "filter-invisible";

    else
      this.filterClass16 = "filter-visible";
  }

  dataList16:any;
  applyFilter16() {
    this.isFilterApplied16 = 'primary'
    this.filterClass16 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue8, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue8, "yyyy-MM-dd");

    this.loadingRecords16= true;
    this.api.getBranchWiswMostDiscountApproval(this.pageIndex16, this.pageSize16, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords16 = false;
        this.totalRecords16 = data['count'];
        this.dataList16 = data['data'];
        this.isSpinning16 = false;
        this.filterClass16 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter16() {
    this.filterClass16 = 'filter-invisible';
    this.isFilterApplied16 = 'default';
    this.startValue8 = new Date();
    this.endValue8 = new Date();
    this.applyFilter16()

  }
 
  // Table 17
  showFilter17() {
    if (this.filterClass17 === "filter-visible")
      this.filterClass17 = "filter-invisible";

    else
      this.filterClass17 = "filter-visible";
  }

  dataList17:any;
  applyFilter17() {
    this.isFilterApplied17 = 'primary'
    this.filterClass17 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue9, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue9, "yyyy-MM-dd");

    this.loadingRecords17= true;
    this.api.getBranchWiseMemoPendancy(this.pageIndex17, this.pageSize17, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords17 = false;
        this.totalRecords17 = data['count'];
        this.dataList17 = data['data'];
        this.isSpinning17 = false;
        this.filterClass17 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter17() {
    this.filterClass17 = 'filter-invisible';
    this.isFilterApplied17 = 'default';
    this.startValue9 = new Date();
    this.endValue9 = new Date();
    this.applyFilter17()

  }
  
  // Table 18
  showFilter18() {
    if (this.filterClass18 === "filter-visible")
      this.filterClass18 = "filter-invisible";

    else
      this.filterClass18 = "filter-visible";
  }

  dataList18:any;
  applyFilter18() {
    this.isFilterApplied18 = 'primary'
    this.filterClass18 = "filter-invisible";

    this.END_DATE = this.datePipe.transform(this.endValue10, "yyyy-MM-dd");
    this.START_DATE = this.datePipe.transform(this.startValue10, "yyyy-MM-dd");

    this.loadingRecords18= true;
    this.api.getBranchWiseDiscountPendancy(this.pageIndex18, this.pageSize18, this.sortKey1, this.sortValue1, '', this.START_DATE,
      this.END_DATE
    ).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords18 = false;
        this.totalRecords18 = data['count'];
        this.dataList18 = data['data'];
        this.isSpinning18 = false;
        this.filterClass18 = 'filter-invisible';
      }
    }, err => {
      console.log(err);
    });
  }

  clearFilter18() {
    this.filterClass18 = 'filter-invisible';
    this.isFilterApplied18 = 'default';
    this.startValue10 = new Date();
    this.endValue10 = new Date();
    this.applyFilter18()

  }

}
