import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-psf-complaint-detailes',
  templateUrl: './psf-complaint-detailes.component.html',
  styleUrls: ['./psf-complaint-detailes.component.css']
})
export class PSFComplaintDetailesComponent implements OnInit {
  exportLoading: boolean = false;
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "PSF complaint details";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODEL = [];
  VARIENT: any = '';
  dataList1 = [];
  BANK_ID: any = [];
  BRANCH: any = '';
  model: any = [];
  branch: any = [];
  Varient: any = [];
  bank: any = [];
  YEARS: string = "";
  MONTH: string = "";
  startValue: any = new Date();
  endValue: any = new Date();
  date1 = new Date();
  date2 = new Date();
  branchId = this.cookie.get('branchId')
  today = new Date();
  SELECT_BRANCH: boolean = false;
  SELECT_BANK: boolean = false;
  SELECT_MODEL: boolean = false;
  // SELECT_BANK: boolean = false;
  branchname: any
  varientname: any
  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MOBILE_NUMBER", "Mobile Number"],
  ["REGISTRATION_NO", "Vehicle Registration No."], ["MODEL", "Model"], ["SERVICE_ADVISER_NAME", "Service Adviser"],
  ["TECHNICIAN_NAME", "Technician"], ["WORK_TYPE", "Work Type"], ["COMPLAINT", "3rd Day Dissatisfied Remark"],
  ["COMPLAINT_REMARK", "CRE Remark"]];
  constructor(private cookie: CookieService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }

  ngOnInit() {
    this.startValue = new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    this.endValue = new Date();
    this.loadDataFilter()
    this.getEmployee()
    setTimeout(() => {
      this.search(false);
    }, 1500);
  }
  sales
  CCE_ID
  TECHNICIAN_ID
  WORKTYPE
  filterEmployees = []
  loadDataFilter() {

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND STATUS = 1 AND BRANCH_ID = " + this.branchId + ' AND DESIGNATION_ID IN (7,16,21,27,87,96,150,160,163)').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }



  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, '', 'asc', " AND STATUS = 1 AND ROLE_ID = 48").subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])

        this.search(false, false);
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }


  SELECT_ALL3: boolean = true;
  onSelectAllChecked3(event: any) {
    this.SELECT_ALL3 = event;
    let ids1 = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.Varient.length; i++) {
        ids1.push(this.Varient[i]["ID"]);
      }
    } else {
      ids1 = [];
    }
    this.VARIENT = ids1;
  }

  onSelectOff3(event: any) {
    var a = this.Varient.length;
    var b = this.Varient.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.VARIENT = event;
    if (this.VARIENT.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  importInExcel() {
    this.search(true, true);
  }





  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }


  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.CCE_ID != undefined && this.CCE_ID.length != 0) {
      this.CCE_ID = this.CCE_ID
    }
    var servicefilter = ''
    if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
      servicefilter = " AND SERVICE_ADVISER_ID IN (" + this.CCE_ID + ")";
    }

    var WORKTYPEfilter = ''
    if (this.WORKTYPE != null && this.WORKTYPE != 0 && this.WORKTYPE != undefined) {
      if (this.WORKTYPE == 'Paid Service') {
        WORKTYPEfilter = " AND (WORK_TYPE like '% Paid Service%' OR WORK_TYPE like '%PS%' )"; 
      }
      if (this.WORKTYPE == 'Free Service' ) {
        WORKTYPEfilter = " AND (WORK_TYPE like '%Free Service%' OR WORK_TYPE like '%FS%' )"; 
      }
      if (this.WORKTYPE == 'Running Repair' ) {
        WORKTYPEfilter = " AND (WORK_TYPE like '%Running Repair%' OR WORK_TYPE like '%RR%' )"; 
      }
      if (this.WORKTYPE == 'Accessories' ) {
        WORKTYPEfilter = " AND (WORK_TYPE like '%Accessories%' OR WORK_TYPE like '%A%' )"; 
      }
      
    }  
 

    // if (this.WORKTYPE != null && this.WORKTYPE != 0 && this.WORKTYPE != undefined) {
    //   WORKTYPEfilter = " AND WORK_TYPE like  " + "'%" + this.WORKTYPE + "%'";
    // }

    var TECHNICIAN_IDfilter = ''
    if (this.TECHNICIAN_ID != null && this.TECHNICIAN_ID != 0 && this.TECHNICIAN_ID != undefined) {
      TECHNICIAN_IDfilter = " AND TECHNICIAN_ID IN (" + this.TECHNICIAN_ID + ")";
    }



    this.START_DATE = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.END_DATE, 'yyyy-MM-dd')
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.psfcomplaintdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + servicefilter + WORKTYPEfilter + TECHNICIAN_IDfilter + ' AND SERVICE_ADMIN_ID = ' + this.cookie.get('userId') , this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.psfcomplaintdetailedreport(0, 0, this.sortKey, sort, likeQuery + servicefilter + WORKTYPEfilter + TECHNICIAN_IDfilter+ ' AND SERVICE_ADMIN_ID = ' + this.cookie.get('userId'), this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Sequence Number'] = this.dataList1[i]['SEQUENCE_NUMBER'];
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Mobile Number'] = this.dataList1[i]['MOBILE_NUMBER'];
      obj1['Vehicle Registration No.'] = this.dataList1[i]['REGISTRATION_NO'];
      obj1['Model'] = this.dataList1[i]['MODEL'];
      obj1['Service Adviser'] = this.dataList1[i]['SERVICE_ADVISER_NAME'];
      obj1['Technician'] = this.dataList1[i]['TECHNICIAN_NAME'];
      obj1['Work Type'] = this.dataList1[i]['WORK_TYPE'];
      obj1['3rd Dissatisfied Remark'] = this.dataList1[i]['COMPLAINT'];
      obj1['CRE Remark'] = this.dataList1[i]['COMPLAINT_REMARK'];
      obj1['Complaint Status'] = this.dataList1[i]['COMPLAINT_STATUS'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'PSF complaint details Report"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  isOk = true
  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    // if (this.VARIENT != undefined && this.VARIENT.length != 0) {
    //   this.VARIENT = this.VARIENT;
    // }
    if (this.START_DATE == undefined && this.END_DATE == undefined && this.BRANCH == undefined && this.VARIENT == undefined && this.MODEL == undefined && this.BANK_ID == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.search(true);
    }
  }
  clearFilter() {
    this.startValue = new Date();
    this.startValue = new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    this.endValue = new Date();
    this.WORKTYPE = [];
    this.CCE_ID = [];
    this.TECHNICIAN_ID = [];






    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(true);

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

}
