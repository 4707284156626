import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-day-wise-super-late10-emp-attendance-report",
  templateUrl: "./day-wise-super-late10-emp-attendance-report.component.html",
  styleUrls: ["./day-wise-super-late10-emp-attendance-report.component.css"],
})
export class DayWiseSuperLate10EmpAttendanceReportComponent implements OnInit {
  formTitle = "Day Wise Super Late 10 Emp Attendance Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'DayWiseSuperLate10EmpAttendanceReport.xlsx';

  orgId = this.cookie.get("orgId");
  START_DATE: any = [];
  END_DATE: any = [];
  BRANCH_ID: any = ([] = []);
  DEPARTMENT_ID = [];
  DESIGNATION_ID = [];
  DAY_COUNT: any;

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  SELECT_ALL: boolean = false;
  onSelectAllChecked(event) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.departments.length; i++) {
        ids.push(this.departments[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.DEPARTMENT_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.departments.length;
    var b = this.departments.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.DEPARTMENT_ID = event;
    if (this.DEPARTMENT_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  SELECT_ALL3: boolean = false;
  onSelectAllChecked3(event) {
    this.SELECT_ALL3 = event;
    let ids = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.designations.length; i++) {
        ids.push(this.designations[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.DESIGNATION_ID = ids;
  }

  onSelectOff3(event) {
    var a = this.designations.length;
    var b = this.designations.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.DESIGNATION_ID = event;
    if (this.DESIGNATION_ID.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }

  branches = [];

  getAttendanceBranches() {
    this.branches = [];

    this.api
      .getAllAttendanceBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  departments = [];

  getAttendanceDepartments() {
    this.departments = [];

    this.api
      .getAllAttendanceDepartments(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.departments = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  designations = [];

  getAttendanceDesignations() {
    this.designations = [];

    this.api
      .getAllAttendanceDesignations(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.designations = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = [];
    this.END_DATE = [];
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.DESIGNATION_ID = [];
    this.SELECT_ALL = false;
    this.SELECT_ALL2 = false;
    this.SELECT_ALL3 = false;

    this.setDateToDateFilter();
  }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getAttendanceBranches();
    this.getAttendanceDepartments();
    this.getAttendanceDesignations();

    this.getDaywiseSuperLateTop10Employees();
  }

  applyFilter() {
    if (
      (this.START_DATE != undefined &&
        this.START_DATE != null &&
        this.START_DATE.length >= 0 &&
        this.END_DATE != undefined &&
        this.END_DATE != null &&
        this.END_DATE.length >= 0) ||
      this.BRANCH_ID.length > 0 ||
      this.DEPARTMENT_ID.length > 0 ||
      this.DESIGNATION_ID.length > 0
    )
      this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getDaywiseSuperLateTop10Employees(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.DaywiseSuperLateTop10EmployeesSortKey = sort.key;
    this.DaywiseSuperLateTop10EmployeesSortValue = sort.value;
    this.getDaywiseSuperLateTop10Employees(true);
  }

  DaywiseSuperLateTop10EmployeesPageIndex = 1;
  DaywiseSuperLateTop10EmployeesPageSize = 10;
  DaywiseSuperLateTop10EmployeesTotalRecords = 1;
  DaywiseSuperLateTop10EmployeesSortValue: string = "desc";
  DaywiseSuperLateTop10EmployeesSortKey: string = "ID";
  DaywiseSuperLateTop10EmployeesData = [];

  columns: string[][] = [
    ["NAME", "Employee Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    ["IN_TIME", "In Time"],
  ];

  getDaywiseSuperLateTop10Employees(reset: boolean = false) {
    if (reset) {
      this.DaywiseSuperLateTop10EmployeesPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DaywiseSuperLateTop10EmployeesSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var BranchFilter: any = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID;
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
    }

    var DesignationFilter: any = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID;
    }

    this.loadingRecords = true;
    this.api
      .getDaywiseSuperLateTop10Employees(
        this.DaywiseSuperLateTop10EmployeesPageIndex,
        this.DaywiseSuperLateTop10EmployeesPageSize,
        this.DaywiseSuperLateTop10EmployeesSortKey,
        sort,
        "",
        this.api.orgId,
       
        BranchFilter,
        fromDateFilter,
        toDateFilter,
        DepartmentFilter,
        DesignationFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DaywiseSuperLateTop10EmployeesTotalRecords = data["count"];
            this.DaywiseSuperLateTop10EmployeesData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  exportexcel(): void {
    this.loadingRecords = true;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Day Wise Super Late 10 Emp');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }, 1000);
  }
}
