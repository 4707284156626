import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ShiftMaster } from 'src/app/Models/shiftmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-hrdashboard',
  templateUrl: './hrdashboard.component.html',
  styleUrls: ['./hrdashboard.component.css']
})
export class HRDashboardComponent implements OnInit {

  // userName = this.cookie.get('userName');
  // emailId = this.cookie.get('emailId');
  orgId = this.cookie.get('orgId');
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))
  // currentMonth: string = "";
  // currentYear: string = "";
  // EOM_Name: string = "";
  // EOM_Dept: string = "";
  // EOM_Designation: string = "";
  // EOM_Branch: string = "";
  // EOM_Image: string = undefined;
  // isEOMLoading: boolean = false;
  formTitle = "Branch Wise Leave Summary"
  formTitle1 = "Branch Wise Absent Summary"
  formTitle2 = "Top 10 Superlate Employees"
  formTitle3 = "Top 10 Employee Started Day From Max Distance"
  formTitle4 = "Daily Morning Short Leave"
  formTitle5 = "Daily Evening Short Leave"
  formTitle6 = "Monthly Average Late Employee"
  formTitle7 = "Top 10 Most Late Coming Employee"



  pageSize: number = 10;
  pageIndex: number = 1;
  totalRecords: any;
  sortValue: string = "";
  sortKey: string = "";

  sortValue1: string = "desc";
  sortKey1: string = "ID";
  inTime:any

  columns: string[][] = [["BRANCH_NAME", "Branch Name"]];
  columns1: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["ABSENT_COUNT", "Absent Count"]];

  superLateDate: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["SHIFT_NAME", "Shift"],];

  distancedata: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["IN_DISTANCE", "Day-In Distance"],
  ["DAYIN_REASON", "Reason"],];

  morningData: string[][] = [["EMPLOYEE_NAME", "Employee Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],];

  eveningData: string[][] = [["NAME", "Employee Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],];

  lateData: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],
  ["NO_OF_LATE_COMMING", "No. Of Late Coming"],];

  avgLateData: string[][] = [["NAME", "Employee Name"],
  ["BRANCH_NAME", "Branch Name"],
  ["DEPARTMENT_NAME", "Department"],
  ["DESIGNATION_NAME", "Designation"],];


  FROM_DATE: any;
  TO_DATE: any;
  DATE = [];
  DATE1 = [];
  DATE2 = [];
  DATE3 = []
  DATE4 = []
  DATE5 = []
  DATE6 = []
  date = new Date()

  START_DATE: any;
  END_DATE: any;

  current = new Date();
  constructor(private cookie: CookieService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }
 
  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }
  

  loadingRecordss = false;
  pageIndexs = 1;
  pageSizes = 10;
  totalRecordss = 1;


  12 = false;
  pageIndex11 = 1;
  pageSize11 = 10;
  totalRecords11 = 1;

  loadingRecords = false;
  pageIndex1 = 1;
  pageSize1 = 10;
  totalRecords1 = 1;

  isVisible = false;
  empData = [];
  branch = [];
  name: any;
  empData1: any
  id: any = []
  isSpinning = false;
  filterClass: string = "filter-invisible";
  isFilterApplied: any = "default";
  loadingRecords1 = false;

  filterClass1: string = "filter-invisible";
  isFilterApplied1: any = "default";
  isSpinning1 = false;

  filterClass2: string = "filter-invisible";
  isFilterApplied2: any = "default";
  loadingRecords2 = false;
  totalRecords2 = 1
  isSpinning2 = false;
  pageIndex2 = 1;
  pageSize2 = 10;

  filterClass3: string = "filter-invisible";
  isFilterApplied3: any = "default";
  loadingRecords3 = false;
  totalRecords3 = 1
  isSpinning3 = false;

  filterClass4: string = "filter-invisible";
  isFilterApplied4: any = "default";
  loadingRecords4 = false;
  totalRecords4 = 1
  isSpinning4 = false;
  pageIndex4 = 1;
  pageSize4 = 10;

  filterClass5: string = "filter-invisible";
  isFilterApplied5: any = "default";
  pageIndex5 = 1;
  pageSize5 = 10;
  loadingRecords5 = false;
  totalRecords5 = 1
  isSpinning5 = false;

  filterClass6: string = "filter-invisible";
  isFilterApplied6: any = "default";
  loadingRecords6 = false;
  totalRecords6 = 1
  isSpinning6 = false;
  pageIndex6 = 1;
  pageSize6 = 10;

  loadingRecords11 = false;

  filterClass7: string = "filter-invisible";
  isFilterApplied7: any = "default";
  loadingRecords7 = false;
  totalRecords7 = 1
  isSpinning7 = false;



  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  MONTH2: any = new Date()
  YEAR: any = this.year
  YEAR2: any = this.year
  monthFormat = "MMM-yyyy";
  filterQuery: string = '';
  data: any

  ngOnInit() {
    this.DATE[0] = new Date();
    this.DATE[1] = new Date();

    this.DATE1[0] = new Date();
    this.DATE1[1] = new Date();

    this.DATE2[0] = new Date();
    // this.DATE2[1] = new Date();

    this.DATE3[0] = new Date();
    this.DATE3[1] = new Date();

    this.DATE4[0] = new Date();
    this.DATE4[1] = new Date();

    this.DATE5[0] = new Date();
    this.DATE5[1] = new Date();

    this.DATE6[0] = new Date();
    this.DATE6[1] = new Date();

    this.applyFilter()
    this.applyFilter1()
    this.getShift()
    this.applyFilter3()

    this.applyFilter7()
    this.getShift4()
    this.getShift5()
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied = 'primary'
    this.filterClass = "filter-invisible";

    this.loadingRecords = false;
    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null) {
      this.FROM_DATE = this.DATE[0]
    }

    if (this.DATE[1] != null) {
      this.TO_DATE = this.DATE[1]
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }
    this.loadingRecords = true;
    this.api.getBranchWiseLeaveSummaryReport(0, 0, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords = false;
      this.totalRecords = data1['count'];
      this.empData = data1['data'];
      this.isSpinning = false;
      this.filterClass = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';

    this.DATE = []
    this.DATE[0] = new Date();
    this.DATE[1] = new Date();
    this.applyFilter()
  }
  BRANCH_ID: any
  showModal(data: any, reset: boolean = true): void {
    if (reset) {
      this.pageIndexs = 1;
      this.pageSizes = 10;
    }

    this.isVisible = true;

    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null && this.DATE[1] != null) {
      this.filterQuery = " AND DATE BETWEEN '" + this.DATE[0] + "' AND '" + this.DATE[1] + "' "

      var filter = '';
      filter = this.filterQuery;
    }

    this.pageIndexs = 1
    console.log(data.BRANCH_ID, "branchid")

    this.loadingRecordss = true;
    this.api.getEmployeeLeaveApplicationDetails(0, 0, this.sortKey, this.sortValue, 'AND BRANCH_ID=' + data.BRANCH_ID + this.filterQuery).subscribe((data1) => {
      console.log(data1);
      console.log(data.BRANCH_ID, 'branchID')
      this.empData = data1['data'];
      this.loadingRecordss = false;
      this.totalRecordss = data1['count'];

      for (let i = 0; i < this.empData.length; i++) {
        this.branch.push(this.empData[i]['NAME'])
        console.log(this.branch, "branch")
      }

      // this.empData = data1['data'][0]['NAME'];
      // console.log(this.empData, "names")
    },
      (err) => {
        console.log(err);
      }
    );

  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.applyFilter()
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.applyFilter()
  }

  STATUS: any = []

  branch1 = []
  absentData = [];
  showModal2(data): void {
    this.isVisible2 = true;

    this.DATE1[0] = this.datePipe.transform(this.DATE1[0], 'yyyy-MM-dd')
    this.DATE1[1] = this.datePipe.transform(this.DATE1[1], 'yyyy-MM-dd')

    if (this.DATE1[0] != null) {
      this.START_DATE = this.DATE1[0]
    }

    if (this.DATE1[1] != null) {
      this.END_DATE = this.DATE1[1]
    }

    if (this.STATUS = 'A') {
      this.STATUS = this.STATUS;
    }
    // if (this.DATE1[0] != null && this.DATE1[1] != null) {
    //   this.filterQuery = " AND DATE BETWEEN '" + this.DATE1[0] + "' AND '" + this.DATE1[1] + "' "

    //   var filter = '';
    //   filter = this.filterQuery;
    // }

    // if(data != undefined){
    console.log(data)
    this.loadingRecords11 = true;
    this.api.getAttendanceReport(0, 0, this.sortKey, this.sortValue, 'AND BRANCH_ID=' + data.BRANCH_ID +" AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")", this.STATUS, this.START_DATE, this.END_DATE).subscribe((data1) => {
      console.log(data1);
      this.absentData = data1['data'];
      this.loadingRecords11 = false;
      this.totalRecords11 = data1['count'];

      for (let i = 0; i < this.absentData.length; i++) {
        this.branch1.push(this.absentData[i]['NAME'])
        console.log(this.branch1, "branch")
      }
      // this.empData = data1['data'][0]['NAME'];
      // console.log(this.empData, "names")
    },
      (err) => {
        console.log(err);
      }
    );
  }

  isVisible2 = false;
  handleOk2(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.applyFilter1()
  }

  handleCancel2(): void {
    console.log('Button cancel clicked!');
    this.isVisible2 = false;
    this.applyFilter1()
  }

  shift2: any = []
  getShift() {
    this.api.getAllShifts(0, 0, this.sortKey, this.sortValue, '').subscribe(data => {
      this.shift2 = data['data']
      if (this.shift2.length >= 0) {
        this.SHIFT_ID = [data['data'][0]['ID']];
        console.log(this.shift2);
        this.applyFilter2()

      }
      // else {
      //   this.SHIFT_ID = this.SHIFT_ID
      // }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  // shift7 = [];
  // getShift7() {
  //   this.api.getAllShifts(0, 0, this.sortKey, this.sortValue, '').subscribe(data1 => {
  //     this.shift7 = data1['data']
  //     if (this.shift7.length >= 0) {
  //       this.SHIFT_ID7 = [data1['data'][0]['ID']];
  //       console.log(this.shift7);
  //       this.applyFilter7()
  //     }
  //     else {
  //       this.SHIFT_ID7 = this.SHIFT_ID7
  //     }
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  shift4 = [];
  getShift4() {
    this.api.getAllShifts(0, 0, this.sortKey, this.sortValue, '').subscribe(data => {
      this.shift4 = data['data']
      if (this.shift4.length >= 0) {
        this.SHIFT_ID4 = [data['data'][0]['ID']];
        console.log(this.shift4);
        this.applyFilter4()
      }
      else {
        this.SHIFT_ID4 = this.SHIFT_ID4
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  shift5 = [];
  getShift5() {
    this.api.getAllShifts(0, 0, this.sortKey, this.sortValue, '').subscribe(data => {
      this.shift5 = data['data']
      if (this.shift5.length >= 0) {
        this.SHIFT_ID5 = [data['data'][0]['ID']];
        console.log(this.shift5);
        this.applyFilter5()
      }
      else {
        this.SHIFT_ID5 = this.SHIFT_ID5
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  // branchWiseLeaveSummary() {
  //   this.api.getBranchWiseLeaveSummaryReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.empData = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }


  // branchWiseAbsentSummary() {
  //   this.api.getBranchWiseAbsentSummaryReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.absentData = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  distance = []
  // employeeStartedDayMaxDistanceReport() {
  //   this.api.employeeStartedDayMaxDistanceReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.distance = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  morning = []
  // dailyMorningShortLeave() {
  //   this.api.dailyMorningShortLeave(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE, this.SHIFT_ID).subscribe((data1) => {
  //     console.log(data1);
  //     this.morning = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  evening = []
  // dailyEveningShortLeave() {
  //   this.api.dailyEveningShortLeave(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE, this.SHIFT_ID).subscribe((data1) => {
  //     console.log(data1);
  //     this.evening = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  superLate = []

  // superLateEmployeeReport() {

  //   this.DATE2[0] = this.datePipe.transform(this.DATE2[0], 'yyyy-MM-dd')
  //   this.DATE2[1] = this.datePipe.transform(this.DATE2[1], 'yyyy-MM-dd')

  //   if (this.DATE2[0] != null) {
  //     this.FROM_DATE = this.DATE2[0]
  //   }

  //   if (this.DATE2[1] != null) {
  //     this.TO_DATE = this.DATE2[1]
  //   }

  //   if (this.DATE2 != undefined && this.DATE2.length != 0) {
  //     this.FROM_DATE = this.datePipe.transform(this.DATE2[0], "yyyy-MM-dd");
  //     this.TO_DATE = this.datePipe.transform(this.DATE2[1], "yyyy-MM-dd");
  //   }

  //   if (this.SHIFT_ID != null) {
  //     this.SHIFT_ID = this.SHIFT_ID
  //   }

  //   this.api.suparLateEmployeeReport(this.pageIndex2, this.pageSize2, this.sortKey, this.sortValue, '', this.SHIFT_ID, this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
  //     console.log(data1);
  //     this.loadingRecords2 = false;
  //     this.totalRecords2 = data1['count'];
  //     this.superLate = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  late = [];
  // LateCommingEmployee() {
  //   this.api.mostLateCommingEmployee(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.MONTH, this.YEAR, this.SHIFT_ID).subscribe((data1) => {
  //     console.log(data1);
  //     this.loadingRecords7 = false;
  //     this.totalRecords7 = data1['count'];
  //     this.late = data1['data'];
  //   },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  showFilter1() {
    if (this.filterClass1 === "filter-visible")
      this.filterClass1 = "filter-invisible";

    else
      this.filterClass1 = "filter-visible";
  }


  applyFilter1(reset: boolean = false) {
    if (reset) {
      this.pageIndex1 = 1;
    }
    this.isFilterApplied1 = 'primary'
    this.filterClass1 = "filter-invisible";

    this.loadingRecords = false;
    this.DATE1[0] = this.datePipe.transform(this.DATE1[0], 'yyyy-MM-dd')
    this.DATE1[1] = this.datePipe.transform(this.DATE1[1], 'yyyy-MM-dd')

    if (this.DATE1[0] != null) {
      this.FROM_DATE = this.DATE1[0]
    }

    if (this.DATE1[1] != null) {
      this.TO_DATE = this.DATE1[1]
    }

    if (this.DATE1 != undefined && this.DATE1.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE1[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE1[1], "yyyy-MM-dd");
    }
    this.loadingRecords1 = true;
    this.api.getBranchWiseAbsentSummaryReport(0, 0, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords1 = false;
      this.totalRecords1 = data1['count'];
      this.absentData = data1['data'];
      this.isSpinning1 = false;
      this.filterClass = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter1() {
    this.filterClass1 = 'filter-invisible';
    this.isFilterApplied1 = 'default';

    this.DATE1 = []
    this.DATE1[0] = new Date();
    this.DATE1[1] = new Date();
    this.applyFilter1()

  }

  showFilter2() {
    if (this.filterClass2 === "filter-visible")
      this.filterClass2 = "filter-invisible";

    else
      this.filterClass2 = "filter-visible";
  }
  SHIFT_ID: any = [];
  applyFilter2(reset: boolean = false) {
    if (reset) {
      this.pageIndex2 = 1;
    }
    this.isFilterApplied2 = 'primary'
    this.filterClass2 = "filter-invisible";

    this.loadingRecords2 = false;
    this.DATE2[0] = this.datePipe.transform(this.DATE2[0], 'yyyy-MM-dd')
    // this.DATE2[1] = this.datePipe.transform(this.DATE2[1], 'yyyy-MM-dd')

    if (this.DATE2[0] != null) {
      this.FROM_DATE = this.DATE2[0]
    }

    // if (this.DATE2[1] != null) {
    //   this.TO_DATE = this.DATE2[1]
    // }

    if (this.DATE2 != undefined) {
      this.FROM_DATE = this.datePipe.transform(this.DATE2[0], "yyyy-MM-dd");
      // this.TO_DATE = this.datePipe.transform(this.DATE2[1], "yyyy-MM-dd");
    }

    if (this.SHIFT_ID != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }


    this.loadingRecords2 = true;
    this.api.suparLateEmployeeReport(this.pageIndex2, this.pageSize2, this.sortKey, this.sortValue, '', this.SHIFT_ID, this.FROM_DATE).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords2 = false;
      this.totalRecords2 = data1['count'];
      this.superLate = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter2() {
    this.filterClass2 = 'filter-invisible';
    this.isFilterApplied2 = 'default';

    this.DATE2 = []
    this.DATE2[0] = new Date();
    // this.DATE2[1] = new Date();
    this.applyFilter2()

  }


  showFilter3() {
    if (this.filterClass3 === "filter-visible")
      this.filterClass3 = "filter-invisible";

    else
      this.filterClass3 = "filter-visible";
  }

  applyFilter3(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied3 = 'primary'
    this.filterClass3 = "filter-invisible";

    this.loadingRecords3 = false;
    this.DATE3[0] = this.datePipe.transform(this.DATE3[0], 'yyyy-MM-dd')
    this.DATE3[1] = this.datePipe.transform(this.DATE3[1], 'yyyy-MM-dd')

    if (this.DATE3[0] != null) {
      this.FROM_DATE = this.DATE3[0]
    }

    if (this.DATE3[1] != null) {
      this.TO_DATE = this.DATE3[1]
    }

    if (this.DATE3 != undefined && this.DATE3.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE3[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE3[1], "yyyy-MM-dd");
    }

    // this.loadingRecords3 = true;
    this.api.employeeStartedDayMaxDistanceReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE).subscribe((data1) => {
      console.log(data1);
      // this.loadingRecords3 = false;
      this.totalRecords3 = data1['count'];
      this.distance = data1['data'];
      // this.isSpinning3 = false;
      // this.filterClass3 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter3() {
    this.filterClass3 = 'filter-invisible';
    this.isFilterApplied3 = 'default';

    this.DATE3 = []
    this.DATE3[0] = new Date();
    this.DATE3[1] = new Date();
    this.applyFilter3()

  }

  showFilter4() {
    if (this.filterClass4 === "filter-visible")
      this.filterClass4 = "filter-invisible";

    else
      this.filterClass4 = "filter-visible";
  }
  SHIFT_ID4: any = []
  applyFilter4() {

    this.pageIndex4 = 1;
    this.pageSize4 = 10;

    this.isFilterApplied4 = 'primary'
    this.filterClass4 = "filter-invisible";

    this.loadingRecords4 = false;
    this.DATE4[0] = this.datePipe.transform(this.DATE4[0], 'yyyy-MM-dd')
    this.DATE4[1] = this.datePipe.transform(this.DATE4[1], 'yyyy-MM-dd')

    if (this.DATE4[0] != null) {
      this.FROM_DATE = this.DATE4[0]
    }

    if (this.DATE4[1] != null) {
      this.TO_DATE = this.DATE4[1]
    }

    if (this.DATE4 != undefined && this.DATE4.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE4[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE4[1], "yyyy-MM-dd");
    }

    if (this.SHIFT_ID4 != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }

    this.loadingRecords4 = true;
    this.api.dailyMorningShortLeave(this.pageIndex4, this.pageSize4, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE, this.SHIFT_ID4).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords4 = false;
      this.totalRecords4 = data1['count'];
      this.morning = data1['data'];
      // this.isSpinning4 = false;
      // this.filterClass4 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }


  clearFilter4() {
    this.filterClass4 = 'filter-invisible';
    this.isFilterApplied4 = 'default';

    this.DATE4 = []
    this.DATE4[0] = new Date();
    this.DATE4[1] = new Date();
    this.applyFilter4()

  }

  showFilter5() {
    if (this.filterClass5 === "filter-visible")
      this.filterClass5 = "filter-invisible";

    else
      this.filterClass5 = "filter-visible";
  }

  SHIFT_ID5: any = []

  applyFilter5() {
    this.isFilterApplied5 = 'primary'
    this.filterClass5 = "filter-invisible";

    this.loadingRecords5 = false;
    this.DATE5[0] = this.datePipe.transform(this.DATE5[0], 'yyyy-MM-dd')
    this.DATE5[1] = this.datePipe.transform(this.DATE5[1], 'yyyy-MM-dd')

    if (this.DATE5[0] != null) {
      this.FROM_DATE = this.DATE5[0]
    }

    if (this.DATE5[1] != null) {
      this.TO_DATE = this.DATE5[1]
    }

    if (this.DATE5 != undefined && this.DATE5.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE5[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE5[1], "yyyy-MM-dd");
    }

    if (this.SHIFT_ID5 != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }

    this.loadingRecords5 = true;
    this.api.dailyEveningShortLeave(this.pageIndex5, this.pageSize5, this.sortKey, this.sortValue, '', this.FROM_DATE, this.TO_DATE, this.SHIFT_ID5).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords5 = false;
      this.totalRecords5 = data1['count'];
      this.evening = data1['data'];
      // this.isSpinning5 = false;
      // this.filterClass5 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter5() {
    this.filterClass5 = 'filter-invisible';
    this.isFilterApplied5 = 'default';

    this.DATE5 = []
    this.DATE5[0] = new Date();
    this.DATE5[1] = new Date();
    this.applyFilter5()

  }

  showFilter6() {
    if (this.filterClass6 === "filter-visible")
      this.filterClass6 = "filter-invisible";

    else
      this.filterClass6 = "filter-visible";
  }
  avgLate = [];

  applyFilter6(reset: boolean = false) {
    if (reset) {
      this.pageIndex6 = 1;
    }
    this.isFilterApplied6 = 'primary'
    this.filterClass6 = "filter-invisible";

    this.loadingRecords6 = false;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");

    if (this.MONTH2 != undefined || this.MONTH2 != '') {
      this.MONTH = this.datePipe.transform(this.MONTH2, 'MM')
    }

    if (this.MONTH2 != undefined || this.MONTH2 != '') {
      this.YEAR = this.datePipe.transform(this.MONTH2, 'yyyy')
    }
    this.pageIndex6 = 1
    this.pageSize6 = 10
    this.loadingRecords6 = true;
    // this.api.monthlyAverageLateEmployee(this.pageIndex6, this.pageSize6, this.sortKey, this.sortValue, '',this.SHIFT_ID=1, this.MONTH, this.YEAR).subscribe((data1) => {
    //   console.log(data1);
    //   this.loadingRecords6 = false;
    //   this.totalRecords6 = data1['count'];
    //   this.avgLate = data1['data'];
    //   this.isSpinning6 = false;
    //   this.filterClass6 = 'filter-invisible';
    // },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

  }

  clearFilter6() {
    this.filterClass6 = 'filter-invisible';
    this.isFilterApplied6 = 'default';

    this.MONTH2 = new Date()
    this.YEAR2 = this.year
    this.applyFilter6()

  }

  showFilter7() {
    if (this.filterClass7 === "filter-visible")
      this.filterClass7 = "filter-invisible";

    else
      this.filterClass7 = "filter-visible";
  }

  SHIFT_ID7: any = []

  applyFilter7() {
    this.isFilterApplied7 = 'primary'
    this.filterClass7 = "filter-invisible";

    this.loadingRecords7 = false;
    this.MONTH = this.datePipe.transform(this.month, "M");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");

    if (this.MONTH1 != undefined || this.MONTH1 != '') {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'M')
    }

    if (this.MONTH1 != undefined || this.MONTH1 != '') {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (this.SHIFT_ID7 != null) {
      this.SHIFT_ID = this.SHIFT_ID
    }

    this.loadingRecords7 = true;
    this.api.mostLateCommingEmployee(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '', this.MONTH, this.YEAR).subscribe((data1) => {
      console.log(data1);
      this.loadingRecords7 = false;
      this.totalRecords7 = data1['count'];
      this.late = data1['data'];
      // this.isSpinning7 = false;
      // this.filterClass7 = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFilter7() {
    this.filterClass7 = 'filter-invisible';
    this.isFilterApplied7 = 'default';

    this.MONTH1 = new Date()
    this.YEAR = this.year
    this.applyFilter7()

  }

}

