export class NewCustomerMaster {
    ID: number;
    CUSTOMER_NAME: string;
    CLIENT_ID: number; 
    MOBILE_NUMBER
    REGISTRATION_NO
    VIN
    IS_PICK_UP_AND_DROP
    BT_NUMBER
    BOOKING_DATE
    BOOKING_TIME
    WORK_TYPE
    EXPRESS_CARE
    REMARK
    SPECIAL_MESSAGE 
    CURRENT_KILOMETERS
    SERVICE_ADVISER_ID
    MODEL
    EMAIL
    ADDRESS
    CALL_TYPE
    CALLED_BY   
    CALLED_BY_NAME  
    CALLING_DATE
    CALLING_TIME
    PICK_UP_DATE   
    PICK_UP_TIME   
    LOCATION
    IS_LIVE_STREAMING
    IS_VIDEO_CALL
    CALL_STATUS = 'A'
    //***/
    CCE_ID 
    //***/

}