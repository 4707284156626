import { Component, OnInit, ViewChild ,Input} from "@angular/core";
// import { scheduleMaster } from '../Models/scheduleMaster'
import { schedule } from "src/app/Models/schedule";
import { ApiService } from "src/app/Service/api.service";
import { NzNotificationService } from "ng-zorro-antd";
import { DatePipe } from "@angular/common";
import { ScheduleFormComponent } from "../schedule-form/schedule-form.component";

@Component({
  selector: "app-schedule-master",
  templateUrl: "./schedule-master.component.html",
  styleUrls: ["./schedule-master.component.css"],
})
export class ScheduleMasterComponent implements OnInit {

  mails1: any;
  DATE:any=[]
  MAILS:any=[]
  assign = [];
  TYPES: any = [];
  masterdata: any = [];
  options=[]
  TYPES_ID: any = [];
  REPORT_ID: any = [];
  MAIL_LIST: any = [];
  isVisible = false;
  isSpinning = false;
  startValue: any;
  endValue: any;
  loadingRecords: boolean = true;
  data: schedule = new schedule();
  isFilterApplied: string = "default";
  dataList: any[] = [];
  drawerTitle: string | undefined;
  filterClass: string = "filter-invisible";
  Reports: any = [];
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;
  searchText: string = "";
  filterQuery: string = "";
  typeQuery: string = "";
  columns: string[][] = [
    ["STATUS","Status"],
    ["TYPES", "types"],
    ["REPORT_NAME", "Report Name"],
    ["MAIL_LIST", "mail list"],
   
    ["TIME", "time"],
  ];

  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    private datepipe: DatePipe
  ) {}
 

  ngOnInit() {
    // this.onGet()
    this.search(true);
     this.onGetData();
    // this.onGetMails()

  }
  onGetData() {
    this.api.getMembers(0, 0, "", "", "").subscribe((data: any) => {
      this.masterdata = data["data"];
      console.log(this.masterdata);
    });
  }

  onGet(){
    this.api.getAllMembers(0,0,'','','').subscribe((data:any)=>{
      this.options=data['data'];
    })
  }
   
  drawerClose() {
    this.search(false)
    
   this.data.TIME=[]
   this.isVisible = false;
    
  }
  onGetMails(){
    this.api.getAllemployeeMaster(0,0,'','','').subscribe((data:any)=>{
      this.mails1=data['data'];
    })
  }
  formTitle="Manage Schedule"
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  // sort(sort: { key: string; value: string }): void {
  //   this.sortKey = sort.key;
  //   this.sortValue = sort.value;
  //   this.search(true);
  // }
  // search(reset: boolean = false) {
  //   if (reset) {
  //     this.pageIndex = 1;
  //   }
  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      
  //   } catch (error) {
  //     sort = "";
  //   }

  //   var likeQuery = "";
  //   console.log("search text : " + this.searchText);

  //   if (this.searchText != "") {
  //     likeQuery = " AND ";

  //     this.columns.forEach(column => {
  //       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
  //     });

  //     likeQuery = likeQuery.substring(0, likeQuery.length - 2) ;
  //   }

  // this.loadingRecords=true
  //   this.api.getAllMembers(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.loadingRecords = false;
  //       this.totalRecords = data['count'];
  //       this.dataList = data['data'];
  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });
  // }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) ;
    }

  this.loadingRecords=true
    this.api.getAllMembers(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
      console.log("Server Not Found", "");
    });
  }

  
  

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue );
    this.search(true);
  }
  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
  onActiveStatusChange(data: schedule, status) {
    data.STATUS = status;

    this.api.update11(this.data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Active Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Active Status", "");

      this.search(false);
    });
  }

  add() {
    this.drawerTitle = "Create Schedule";
    this.data=new schedule()
    console.log(this.data,"addition")
    this.isVisible = true;
    
    
  }
  TYPE_OF_ATTACHMENT:any
  edit(data:any) {
    this.drawerTitle = "Update Report";
    this.data = Object.assign({}, data);
    console.log(this.data,"")
   
   
    this.data.TIME= this.data.TIME == ''||this.data.TIME ==  null ? [] : this.data.TIME.split(',')
    this.data.MONTHS= this.data.MONTHS == '' ||this.data.MONTHS ==  null? [] : this.data.MONTHS.split(',')
    this.data.MAIL_LIST= this.data.MAIL_LIST == ''||this.data.MAIL_LIST ==   null ? [] : this.data.MAIL_LIST.split(',')
    this.data.DAYS= this.data.DAYS == '' ||this.data.DAYS ==   null ? [] : this.data.DAYS.split(',')
    this.data.YEARS= this.data.YEARS == '' ||this.data.YEARS ==   null ? [] : this.data.YEARS.split(',')
    this.data.TYPE_OF_ATTACHMENT= this.data.TYPE_OF_ATTACHMENT
    console.log(this.data.TIME,"TIME")
    
    this.isVisible = true;
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
   var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
   this.filterQuery = '';
   
    if (this.REPORT_ID>0) {
      this.filterQuery =
        this.filterQuery + ' AND REPORT_ID=' + "" + this.REPORT_ID + "";
    }
    if (this.TYPES !=null && this.TYPES != 0  && this.TYPES != undefined) {
      this.filterQuery =
        this.filterQuery + ' AND TYPES=' + "'" + this.TYPES + "'";
    }
    if (this.MAIL_LIST !=null && this.MAIL_LIST != 0  && this.MAIL_LIST != undefined) {
      this.filterQuery =
        this.filterQuery + ' AND MAIL_LIST=' + "'" + this.MAIL_LIST + "'";
    }

    this.search();
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
  
    }
  
    this.api.getAllMembers(0, 0, this.sortKey, sort, this.filterQuery).subscribe((data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.assign = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
        (err) => {
          console.log(err);
        }
      );
  
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.typeQuery = "";
    this.MAIL_LIST=[]
    this.DATE=[]
    this.TYPES = [];
    this.REPORT_ID = [];
    this.search();
    this.filterClass = "filter-invisible";
  }
 
}