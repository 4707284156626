import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branch-wise-inhouse-insurace-summary-report',
  templateUrl: './branch-wise-inhouse-insurace-summary-report.component.html',
  styleUrls: ['./branch-wise-inhouse-insurace-summary-report.component.css']
})
export class BranchWiseInhouseInsuraceSummaryReportComponent implements OnInit {

  formTitle = "Branch Wise Inhouse Insurance Summary Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "BRANCH_NAME";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["BRANCH_NAME", "Branch name"],
  ["INVOICE_COUNT", "Number of Invoices"],
  ["NO_OF_INHOUSE_INSURANCE", "Inhouse Insurance	"],];

  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;


  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_CONSULTANT_ID: any = [];
  current = new Date();
  FROM_DATE: any;
  TO_DATE: any;
  DATE = [];

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.FROM_DATE = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.TO_DATE = new Date();
    // this.search();
    this.getBranch();
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      FROM_DATE: this.FROM_DATE,
      TO_DATE: this.TO_DATE,
      BRANCH_ID:this.BRANCH_ID,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 31').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }
  SELECT_ALL2: boolean = true;

  onSelectAllChecked2(event: any) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff2(event: any) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }



  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1').subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
      let ids = [];

      if (this.SELECT_ALL2 == true) {
        for (var i = 0; i < this.branches.length; i++) {
          ids.push(this.branches[i]["ID"]);
        }
      } else {
        ids = [];
      }

      this.BRANCH_ID = ids;
      this.search();
    },
      (err) => {
        console.log(err);
      }
    );
  }

  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }

    this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd')
    this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd')

    if (this.FROM_DATE != null) {
      this.FROM_DATE = this.FROM_DATE
    }

    if (this.TO_DATE != null) {
      this.TO_DATE = this.TO_DATE
    }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE=[]
    this.FROM_DATE = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.TO_DATE = new Date();
    this.SELECT_ALL2 = true
    let ids = [];
    for (var i = 0; i < this.branches.length; i++) {
      ids.push(this.branches[i]["ID"]);
    }

    this.BRANCH_ID = ids;
    this.dataList = [];
     this.search();

  }
  ewsalesdata = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, "yyyy-MM-dd");
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.inhouseInsurance(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.FROM_DATE, this.TO_DATE)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.inhouseInsurance(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.FROM_DATE, this.TO_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['Branch name'] = this.ewsalesdata[i]['BRANCH_NAME'];
      obj1['Number of Invoices'] = this.ewsalesdata[i]['INVOICE_COUNT'];
      obj1['Inhouse Insurance	'] = this.ewsalesdata[i]['NO_OF_INHOUSE_INSURANCE'];



      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Inhouse Insurance Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
