import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { PriceList } from "src/app/Models/PriceList";
import { ApiService } from "src/app/Service/api.service";
import { PriceListDrawerComponent } from "../price-list-drawer/price-list-drawer.component";
import { PriceListMappingComponent } from "../price-list-mapping/price-list-mapping.component";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-price-list-master",
  templateUrl: "./price-list-master.component.html",
  styleUrls: ["./price-list-master.component.css"],
})

export class PriceListMasterComponent implements OnInit {   
  formTitle = "Manage Price List";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["MODEL_NAME", "Model"], ["NAME", "Name"], ["WEF_DATE", "Date"], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: PriceList = new PriceList();
  isSpinning = false;
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  PRICELIST_STATUS: boolean = true;
  clusterId = Number(this.cookie.get('clusterId'));
 

  constructor(private _exportService: ExportService,private cookie: CookieService,private router: Router, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getAllModels();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false, pricelistStatus: boolean = true, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    var modelFilterStr = "";
    if (this.MODEL_ID.length > 0) {
      modelFilterStr = " AND MODEL_ID IN (" + this.MODEL_ID + ")";
    }

    var pricelistStatusStr = "";
    if (pricelistStatus) {
      pricelistStatusStr = " AND STATUS IN (1)";

    } else {
      pricelistStatusStr = " AND STATUS IN (0,1)";
    }

    this.loadingRecords = true;
    if (exportInExcel == false) {
    this.api.getAllPriceList(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + modelFilterStr + pricelistStatusStr + " AND CLUSTER_ID =" + this.clusterId ).subscribe((data) => {
      if (data["code"] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data["count"];
        this.dataList = data["data"];
      }

    }, (err) => {
      if (err["ok"] == false)
        this.message.error("Server Not Found", "");
    });
  }
  else{     
    this.loadingRecords = true;
  this.api.getAllPriceList(0, 0, this.sortKey, sort, likeQuery + modelFilterStr + pricelistStatusStr + " AND CLUSTER_ID =" + this.clusterId ).subscribe((data) => {
    if (data["code"] == 200) {
      this.loadingRecords = false;
      this.totalRecords = data["count"];
      this.dataList = data["data"];
      this.convertInExcel()
    }

  }, (err) => {
    if (err["ok"] == false)
      this.message.error("Server Not Found", "");
  });
}
  }

  @ViewChild(PriceListDrawerComponent, { static: false })
  PriceListDrawerComponentVar: PriceListDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Price List";
    this.drawerData = new PriceList();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.drawerData.WEF_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.PriceListDrawerComponentVar.getAllModelsWithActiveStatus();
  }

  edit(data: PriceList): void {
    this.drawerTitle = "Update Price List Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.getPriceListDetails(data.ID);
    this.PriceListDrawerComponentVar.getAllModels();
  }

  priceListDetailsTotalRecords = 1;
  priceListDetailsDataList = [];

  getPriceListDetails(priceListID) {
    this.PriceListDrawerComponentVar.loadingRecords = true;

    this.api.getAllPriceListDetails(0, 0, "ID", "asc", " AND PRICELIST_ID=" + priceListID).subscribe((data) => {
      if (data["code"] == 200) {
        this.PriceListDrawerComponentVar.loadingRecords = false;
        this.priceListDetailsTotalRecords = data["count"];
        this.priceListDetailsDataList = data["data"];
        console.log(this.priceListDetailsDataList);
      }

    }, (err) => {
      if (err["ok"] == false)
        this.message.error("Server Not Found", "");
    });
  }

  drawerClose(): void {
    this.search(false, this.PRICELIST_STATUS);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  priceListMappingDrawerTitle: string;
  priceListMappingData: [];
  priceListData: PriceList = new PriceList();
  priceListMappingDrawerVisible: boolean;
  @ViewChild(PriceListMappingComponent, { static: false }) PriceListMappingComponentVar: PriceListMappingComponent;

  priceListMapping(data: PriceList) {
    this.PriceListMappingComponentVar.priceListMappingLoadingRecords = true;
    this.PriceListMappingComponentVar.pageIndex = 1;

    this.api.getPriceListMappingDetails(data.ID," AND CLUSTER_ID="+this.clusterId).subscribe((data) => {
      if (data["code"] == 200) {
        this.priceListMappingData = data["data"];
        this.PriceListMappingComponentVar.priceListMappingLoadingRecords = false;
      }

    }, (err) => {
      if (err["ok"] == false) this.message.error("Server Not Found", "");
    });

    this.priceListMappingDrawerTitle = "Branch Mapping > " + data.NAME;
    this.priceListData = Object.assign({}, data);
    this.priceListMappingDrawerVisible = true;
  }

  priceListMappingDrawerClose(): void {
    this.priceListMappingDrawerVisible = false;
  }

  get priceListMappingCloseCallback() {
    return this.priceListMappingDrawerClose.bind(this);
  }

  models = [];

  getAllModels() {
    this.models = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "").subscribe((data) => {
      if (data["code"] == 200) {
        this.models = data["data"];
      }

    }, (err) => {
      console.log(err);
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  MODEL_ID = [];

  applyFilter() {
    if (this.MODEL_ID.length > 0)
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.MODEL_ID = [];
    this.search(true);
  }

  onStatusChange(data: PriceList, status) {
    data.STATUS = status;

    this.api.updatePriceList(data).subscribe((successCode) => {
      if (successCode["code"] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search(false, this.PRICELIST_STATUS);
    });
  }

  makeCopy(data: PriceList) {
    data.NAME = data["MODEL_NAME"] + " " + this.datePipe.transform(new Date(), "ddMMMyyyy");
    data.STATUS = true;

    this.api.copyPriceList(data).subscribe((successCode) => {
      if (successCode["code"] == 200)
        this.message.success("New Price List Successfully Created", "");

      else
        this.message.error("Failed to Create New Price List", "");

      this.search(false, this.PRICELIST_STATUS);
    });
  }

  cancel() { }

  onPricelistStatusChange(status: boolean) {
    this.PRICELIST_STATUS = status;
    this.search(false, this.PRICELIST_STATUS);
  }
  
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.dataList.length; i++) {

    
      obj1['Model Name'] = this.dataList[i]['MODEL_NAME'];
      obj1['Name'] = this.dataList[i]['NAME'];
      obj1['Date'] = this.datePipe.transform(this.dataList[i]['WEF_DATE'],'dd-MM-yy');
      obj1['Status'] = this.dataList[i]['STATUS']=='1'?this.dataList[i]['STATUS']="Yes":this.dataList[i]['STATUS']="No"

      arry1.push(Object.assign({}, obj1));

      if (i == this.dataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Price-List Report' + this.datePipe.transform(new Date(), 'dd-MMM-yy'));
      }
    }
  }
}
