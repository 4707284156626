import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-branchwiseadvancecollectedreport',
  templateUrl: './branchwiseadvancecollectedreport.component.html',
  styleUrls: ['./branchwiseadvancecollectedreport.component.css']
})
export class BranchwiseadvancecollectedreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Branch Wise Advance Collected Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "BRANCH_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  isOk: boolean = true
  MODEL: any;
  BRANCH: any;
  BranchName: any[]
  quotationdate: any;
  BRANCH_ID=[]
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  MONTH: any
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  yesterday = new Date().getDate();
  startValue: any = new Date(this.y,this.m,this.yesterday-1);
  endValue: any = new Date();
  // startValue = this.startValue.setDate(this.startValue.getDate() - 1);

  YEAR: any
  SELECT_BRANCH: boolean = false;
  columns: string[][] = [["BRANCH_NAME", "Branch Name"],];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }

  ngOnInit() {
    
    this.getbranches();
    setTimeout(()=>{
      this.search(false);
    },1000);
    // this.applyFilter();
  
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  importInExcel() {
    this.search(true, true);
  }


  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.BranchName.length; i++) {
        ids.push(this.BranchName[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }

  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.BranchName = data['data'];
        console.log(this.BranchName);
        this.onSelectAllBranch(true);
      }
    }, err => {
      console.log(err);
    });
  }

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.branchwiseadvancecollectedreports(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.BRANCH_ID, this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.branchwiseadvancecollectedreports(0, 0, this.sortKey, sort, likeQuery, this.BRANCH_ID, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  exportLoading: boolean = false;

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {

      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];

      obj1['Advance Collected'] = this.dataList1[i]['TOTAL_ADVANCE'];
      obj1['Advance Pending'] = this.dataList1[i]['PENDING_AMOUNT'];
      obj1['Total Advance'] = this.dataList1[i]['AMOUNT'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Advance Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }





  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  // applyFilter() {
  //   this.isSpinning = true
  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
  //   this.filterQuery = "";
  //   if (this.MODEL != undefined) {
  //     //  this.message.error("Please Select Atleast One Option From The Following",'')
  //     this.filterQuery = " AND MODEL_NAME = " + this.MODEL;
  //     this.isSpinning = false;
  //     this.loadingRecords = false;
  //     this.isFilterApplied = "primary";
  //     console.log("FilterQuery", this.filterQuery)

  //   }
  //   else if (this.MODEL == undefined) {
  //     this.message.error("Please Select Option To Filter", '')
  //     this.loadingRecords = false;
  //     this.isSpinning = false;
  //     this.isFilterApplied = "default";
  //   }

  //   this.api.branchwiseadvancecollectedreports(this.pageIndex, this.pageSize, this.sortKey, sort, '', 0, '', '').subscribe(data => {
  //     console.log(data)
  //     this.loadingRecords = false;
  //     this.totalRecords = data['count'];
  //     this.dataList = data['data'];
  //   }, err => {
  //     console.log(err);
  //   });
  // }
  // clearFilter() {
  //   this.MODEL = "AL";
  //   this.quotationdate = new Date();
  //   this.BRANCH = "AL";
  //   this.filterClass = 'filter-invisible';
  //   //  this.isFilterApplied="default";

  // }


  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }



    if (this.START_DATE == undefined && this.END_DATE == undefined && this.BRANCH_ID == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;

      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.BRANCH_ID = this.BRANCH_ID;
      this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

      // this.api.branchwiseadvancecollectedreports(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "" +this.BRANCH_ID, this.MONTH, this.YEAR).subscribe(data => {
      //   console.log(data)
      //   this.loadingRecords = false;
      //   this.isSpinning=false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   this.filterClass = 'filter-invisible';
      // }, err => {
      //   console.log(err);
      // });
      this.search(true);
    }
  }

  clearFilter() {
    this.startValue = new Date(this.y,this.m,this.yesterday-1);
    this.endValue = new Date();
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.BRANCH_ID = [];
    this.SELECT_BRANCH = false;
    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(true);

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

  month1(event: any) {

  }

}