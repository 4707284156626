import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { insuranceMaster } from 'src/app/Models/incentiveInsurance';
import { ApiService } from 'src/app/Service/api.service';
// import { insuranceMaster } from '../Models/incentiveInsurance';
// import { ApiService } from '../Service/api.service';


@Component({
  selector: 'app-insurance-form',
  templateUrl: './insurance-form.component.html',
  styleUrls: ['./insurance-form.component.css']
})
export class InsuranceFormComponent implements OnInit {

  @Input() drawerClose3: Function;
  @Input() data: insuranceMaster = new insuranceMaster()
  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;

  // insuranceMaster = new insuranceMaster();

  constructor(private api: ApiService, private message: NzNotificationService, private datepipe: DatePipe) { }
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;
  date = null;
  isOk = false;
  switch1 = false;
  isSpinning = false;
  listOfData: any = [];


  ngOnInit() {
    this.getData();
  }

  onChange1(result: Date): void {
    console.log('onChange: ', result);
  }
  onChange2(result: Date): void {
    console.log('onChange: ', result);
  }

  getData() {
    this.api.getInsuranceData(this.pageIndex, this.pageSize, '', '', '').subscribe((data) => {
      this.listOfData = data['data'];
      console.log('this.listOfData', this.listOfData);

    })
  }

  switchChange2(e1: boolean) {
    this.switch1 = e1;
  }

  onSubmit(addNew: boolean, httpForm: NgForm) {

    this.isOk = true;
    if (this.data.INCENTIVE_STRUCUTURE_NAME == null || this.data.INCENTIVE_STRUCUTURE_NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Incentive Name.', "")
    }
    else if (this.data.FROM_DATE == null || this.data.FROM_DATE == 0) {
      this.isOk = false
      this.message.error('Please Enter From Date.', "")
    }
    else if (this.data.TO_DATE == null || this.data.TO_DATE == 0) {
      this.isOk = false
      this.message.error('Please Enter To Date.', "")
    }
    if (this.isOk) {
      if (this.data.ID) {
        this.data.FROM_DATE = this.datepipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd HH:mm:ss');
        this.data.TO_DATE = this.datepipe.transform(this.data.TO_DATE, 'yyyy-MM-dd HH:mm:ss');
        this.api.updateincetiveInsurance(this.data).subscribe((successCode: any) => {
          if (successCode['code'] == 200) {
            this.getData();
            this.message.success("Insurance Incentive Structure Updated successfully...", "");
            console.log(this.data, 'Update Success')
            if (!addNew) {
              this.reset(httpForm);
              this.isSpinning = false;
              this.drawerClose3()
            }
            else {
              this.data = new insuranceMaster();
              this.message.error("Failed To Update", "");
              console.log(this.data, "fail Update")
              this.isSpinning = false;
            }
          }
          else if(successCode['code']==400){
            this.message.error("Not Allowed To Update Overlapped From date and To date Structure...", "");
          }

          else{
            this.message.error("Failed To Update Insurance Incentive Structure...","")
          }
        });
      }
      else {
        this.isSpinning = true;
        this.data.FROM_DATE = this.datepipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
        this.data.TO_DATE = this.datepipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');
        this.api.createInsurance(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Insurance Incentive Structure Added successfully...", "");
            this.getData();
            if (!addNew) {
              this.drawerClose3()
            }
            else {
              this.data = new insuranceMaster();
              httpForm.resetForm();
            }
            this.isSpinning = false;
            httpForm.reset()
          }
          else if(successCode['code']==400){
            this.message.error("Not Allowed To Update Overlapped From date and To date Structure...", "");
          }

          else{
            this.message.error("Failed To Update Insurance Incentive Structure...","")
          }
        });
      }
    }

  }


  close(httpForm: NgForm) {
    this.reset(httpForm);
    this.drawerClose3()
    this.data = new insuranceMaster()
  }

  reset(httpForm: NgForm) {
    httpForm.reset();
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }
}
