import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./pages/CommonModule/Dashboard/dashboard/dashboard.component";
import { UsersComponent } from "./pages/CommonModule/user/users/users.component";
import { RolesComponent } from "./pages/CommonModule/role/roles/roles.component";
import { ApplicationsComponent } from "./pages/CommonModule/application/applications/applications.component";
import { ModulesComponent } from "./pages/CommonModule/module/modules/modules.component";
import { SelectpageComponent } from "./pages/CommonModule/selectpage/selectpage/selectpage.component";
import { LoginComponent } from "./login/login.component";
import { FaqHeadsComponent } from "./pages/SupportModule/FaqHead/faq-heads/faq-heads.component";
import { FaqsComponent } from "./pages/SupportModule/FAQ/faqs/faqs.component";
import { FormsComponent } from "./pages/CommonModule/form/forms/forms.component";
import { DepartmentsComponent } from "./pages/SupportModule/Department/departments/departments.component";
import { FoldersComponent } from "./pages/DocumentModule/folder/folders/folders.component";
import { TicketsComponent } from "./pages/SupportModule/Tickets/tickets/tickets.component";
import { EmailsComponent } from "./pages/CommunicationModule/EmailServer/emails/emails.component";
import { SmssComponent } from "./pages/CommunicationModule/SMSServer/smss/smss.component";
import { UserapplicationmappingComponent } from "./pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component";
import { UsermodulemappingComponent } from "./pages/CommonModule/user/usermodulemapping/usermodulemapping.component";
import { UseraccessmappingComponent } from "./pages/CommonModule/user/useraccessmapping/useraccessmapping.component";
import { UserrolemappingComponent } from "./pages/CommonModule/user/userrolemapping/userrolemapping.component";
import { TasksComponent } from "./pages/SchedularModule/Task/tasks/tasks.component";
import { ViewhistoryComponent } from "./pages/SchedularModule/History/viewhistory/viewhistory.component";
import { LogmoduleComponent } from "./pages/LogModule/logmodule/logmodule.component";
import { UserdepartmentmappingComponent } from "./pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component";
import { TestComponent } from "./pages/CommonModule/test/test.component";
import { TicketgroupComponent } from "./pages/SupportModule/Tickets/ticketgroup/ticketgroup.component";
import { MyticketComponent } from "./pages/UserModule/myticket/myticket.component";
import { TicketreportComponent } from "./pages/Reports/ticketreport/ticketreport.component";
import { SummaryreportComponent } from "./pages/Reports/summaryreport/summaryreport.component";
import { UserwisesummaryComponent } from "./pages/Reports/userwisesummary/userwisesummary.component";
import { DashboardreportComponent } from "./pages/Report/dashboardreport/dashboardreport.component";
import { OrganizationsComponent } from "./pages/Organizations/organizations/organizations.component";
import { BranchesComponent } from "./pages/BranchMaster/branches/branches.component";
import { DesignationsComponent } from "./pages/DesignationMaster/designations/designations.component";
import { TickRolesComponent } from "./pages/RoleMaster/tick-roles/tick-roles.component";
import { FeaturesComponent } from "./pages/FeatureMaster/features/features.component";
import { EmployeemasterlistComponent } from "./pages/employeemaster/employeemasterlist/employeemasterlist.component";
// import { Clusterbranchmasterlistcomponent } from './pages/clusterbranchmaster/clusterbranchmasterlist/clusterbranchmasterlist.component';
// import { ClusterMaster } from './Models/clustermaster';
// import { AuthGuard } from './Service/auth.guard';
// import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { ClustermasterlistComponent } from "./pages/clustermaster/clustermasterlist/clustermasterlist.component";
import { EmployeerolemaplistComponent } from "./pages/employeerolemap/employeerolemaplist/employeerolemaplist.component";
import { EmploginComponent } from "./emplogin/emplogin.component";
import { SearchfaqComponent } from "./pages/UserModule/searchfaq/searchfaq.component";
import { FolderMasterComponent } from "./pages/FolderAndFileModule/Folder/folder-master/folder-master.component";
import { SharedFolderMasterComponent } from "./pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component";
import { FaqresponsereportComponent } from "./pages/Reports/faqresponsereport/faqresponsereport.component";
import { OptionreportComponent } from "./pages/Reports/optionreport/optionreport.component";
import { LeavetypesComponent } from "./pages/EMM_Module/LeaveType/leavetypes/leavetypes.component";
import { ExpensesheadComponent } from "./pages/EMM_Module/ExpenseHead/expenseshead/expenseshead.component";
import { EvaluationcriteriasComponent } from "./pages/EMM_Module/EvaluationCriteria/evaluationcriterias/evaluationcriterias.component";
import { CustomersComponent } from "./pages/EMM_Module/Customer/customers/customers.component";
import { HolidaysComponent } from "./pages/EMM_Module/Holidays/holidays/holidays.component";

import { ThoughsComponent } from "./pages/EMM_Module/Though/thoughs/thoughs.component";
import { BirthdaysComponent } from "./pages/EMM_Module/birthday/birthdays/birthdays.component";
import { AttendancereportComponent } from "./pages/EMM_Module/Reports/attendancereport/attendancereport.component";
import { LeavereportComponent } from "./pages/EMM_Module/Reports/leavereport/leavereport.component";
// import { LatemarkreportComponent } from "./pages/EMM_Module/Reports/latemarkreport/latemarkreport.component";
// import { EarlymarkreportComponent } from "./pages/EMM_Module/Reports/earlymarkreport/earlymarkreport.component";
import { ExpensereportComponent } from "./pages/EMM_Module/Reports/expensereport/expensereport.component";
import { EvaluationreportComponent } from "./pages/EMM_Module/Reports/evaluationreport/evaluationreport.component";
import { AssetCategoryListComponent } from "./pages/materialIssueModule/assetCategory/asset-category-list/asset-category-list.component";
import { AssetListComponent } from "./pages/materialIssueModule/asset/asset-list/asset-list.component";
import { EarlymarkTilesComponent } from "./pages/EMM_Module/Earlymark/earlymark-tiles/earlymark-tiles.component";
import { LatemarkTilesComponent } from "./pages/EMM_Module/LateMark/latemark-tiles/latemark-tiles.component";
import { LeaveTilesComponent } from "./pages/EMM_Module/leave/leave-tiles/leave-tiles.component";
import { ExpensesTilesComponent } from "./pages/EMM_Module/expenses/expenses-tiles/expenses-tiles.component";
import { HeadApproveEmployeeLeavesTilesComponent } from "./pages/EMM_Module/leave/head-approve-employee-leaves-tiles/head-approve-employee-leaves-tiles.component";
import { ApproveEmployeeLeavesTilesComponent } from "./pages/EMM_Module/leave/approve-employee-leaves-tiles/approve-employee-leaves-tiles.component";
import { HeadApproveEmployeeLatemarkTilesComponent } from "./pages/EMM_Module/LateMark/head-approve-employee-latemark-tiles/head-approve-employee-latemark-tiles.component";
import { HeadApproveEmployeeEarlymarkTilesComponent } from "./pages/EMM_Module/Earlymark/head-approve-employee-earlymark-tiles/head-approve-employee-earlymark-tiles.component";
import { ExpensesApproveFromHeadTilesComponent } from "./pages/EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component";
import { ExpensesApproveFromAccountantTilesComponent } from "./pages/EMM_Module/expenses/expenses-approve-from-accountant-tiles/expenses-approve-from-accountant-tiles.component";
import { AssetMappingListComponent } from "./pages/materialIssueModule/assetMapping/asset-mapping-list/asset-mapping-list.component";
import { AssetRequestListComponent } from "./pages/materialIssueModule/assetRequest/asset-request-list/asset-request-list.component";
import { AttendanceregisterComponent } from "./pages/EMM_Module/Reports/attendanceregister/attendanceregister.component";
import { TrainingsublistComponent } from "./pages/TrainingModule/trainingsubmaster/trainingsublist/trainingsublist.component";
import { TrainermasterlistComponent } from "./pages/TrainingModule/trainermaster/trainermasterlist/trainermasterlist.component";
import { TrainingtypelistComponent } from "./pages/TrainingModule/trainingtypemaster/trainingtypelist/trainingtypelist.component";
import { AnnualtrainpointlistComponent } from "./pages/TrainingModule/annualtrainpoint/annualtrainpointlist/annualtrainpointlist.component";
import { TrainingpointmasterlistComponent } from "./pages/TrainingModule/trainingpointmaster/trainingpointmasterlist/trainingpointmasterlist.component";
import { TrainingschedulelistComponent } from "./pages/TrainingModule/trainingschedule/trainingschedulelist/trainingschedulelist.component";
import { InductionarealistComponent } from "./pages/TrainingModule/inductionareamaster/inductionarealist/inductionarealist.component";
import { TrainfeedformmasterlistComponent } from "./pages/TrainingModule/trainingfeedbackformmaster/trainfeedformmasterlist/trainfeedformmasterlist.component";
import { TrainingevalutionformlistComponent } from "./pages/TrainingModule/trainingevalutionform/trainingevalutionformlist/trainingevalutionformlist.component";
import { TrainfeedmasterlistComponent } from "./pages/TrainingModule/trainingfeedbackmaster/trainfeedmasterlist/trainfeedmasterlist.component";
import { TrainattendancecardlistComponent } from "./pages/TrainingModule/trainattendancecard/trainattendancecardlist/trainattendancecardlist.component";
import { TrainerloginviewComponent } from "./pages/TrainingModule/trainerlogin/trainerloginview/trainerloginview.component";
import { AccessorviewComponent } from "./pages/TrainingModule/accessor/accessorview/accessorview.component";
import { AssetRegisterComponent } from "./pages/materialIssueModule/Reports/asset-register/asset-register.component";
import { AssetAllocationRegisterComponent } from "./pages/materialIssueModule/Reports/asset-allocation-register/asset-allocation-register.component";
import { AssetCategoryRegisterComponent } from "./pages/materialIssueModule/Reports/asset-category-register/asset-category-register.component";
import { TrainerAccessorLoginComponent } from "./trainer-accessor-login/trainer-accessor-login.component";
import { ModelMasterComponent } from "./pages/SalesModule/model/model-master/model-master.component";
import { VarientMasterComponent } from "./pages/SalesModule/varient/varient-master/varient-master.component";
import { PriceListMasterComponent } from "./pages/SalesModule/priceList/price-list-master/price-list-master.component";
import { ColorMasterComponent } from "./pages/SalesModule/color/color-master/color-master.component";
import { EmployeeOfTheMonthComponent } from "./pages/EMM_Module/employee-of-the-month/employee-of-the-month.component";
import { SalesModuleReportsComponent } from "./pages/SalesModule/Reports/sales-module-reports/sales-module-reports.component";
import { FuelMasterComponent } from "./pages/SalesModule/Fuel/fuel-master/fuel-master.component";
import { TransmissionMasterComponent } from "./pages/SalesModule/Transmission/transmission-master/transmission-master.component";
import { MemoDetailsComponent } from "./pages/SalesModule/Reports/memo-details/memo-details.component";
import { AttendanceReportsComponent } from "./pages/EMM_Module/Reports/attendance-reports/attendance-reports.component";
import { BankListComponent } from "./pages/BankMaster/bank-list/bank-list.component";
import { InsuranceListComponent } from "./pages/InsuranceMaster/insurance-list/insurance-list.component";
import { MemoDetails2Component } from "./pages/SalesModule/Reports/memo-details2/memo-details2.component";
// import { FinanceMasterComponent } from "./pages/Incentiveforms/Financeincentive/finance-master/finance-master.component";
// import { FinanceMasterFormComponent } from "./pages/Incentiveforms/Financeincentive/finance-master-form/finance-master-form.component";
// import { FinanceMasterComponent } from './pages/Incentiveforms/Financeincentive/finance-master/finance-master.component';
import { RateMasterComponent } from './pages/Incentiveforms/Salesincentive/rate-master/rate-master.component';
// import { InsuranceTableComponent } from "./pages/Incentiveforms/Insuranceincentive/insurance-table/insurance-table.component";
import { DayWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-attendance-report/day-wise-attendance-report.component";
import { BranchWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/branch-wise-attendance-report/branch-wise-attendance-report.component";
import { DepartmentWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/department-wise-attendance-report/department-wise-attendance-report.component";
import { DesignationWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/designation-wise-attendance-report/designation-wise-attendance-report.component";
import { MonthlyAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/monthly-avg-attendance-report/monthly-avg-attendance-report.component";
import { BranchWiseAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/branch-wise-avg-attendance-report/branch-wise-avg-attendance-report.component";
import { DepartmentWiseAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/department-wise-avg-attendance-report/department-wise-avg-attendance-report.component";
import { DesignationWiseAvgAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/designation-wise-avg-attendance-report/designation-wise-avg-attendance-report.component";
import { DayWiseSuperLate10EmpAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-super-late10-emp-attendance-report/day-wise-super-late10-emp-attendance-report.component";
import { MonthlySuperLate10EmpAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/monthly-super-late10-emp-attendance-report/monthly-super-late10-emp-attendance-report.component";
import { DayWiseLeaveAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-leave-attendance-report/day-wise-leave-attendance-report.component";
import { DayWiseLatemarkAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/day-wise-latemark-attendance-report/day-wise-latemark-attendance-report.component";
import { GraphicalAttendanceSummaryReportComponent } from "./pages/Attendance Report/AttendanceReports/graphical-attendance-summary-report/graphical-attendance-summary-report.component";
import { Top10DayInMaxDistanceDayWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-in-max-distance-day-wise-attendance-report/top10-day-in-max-distance-day-wise-attendance-report.component";
import { Top10DayInMaxDistanceMonthlyWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-in-max-distance-monthly-wise-attendance-report/top10-day-in-max-distance-monthly-wise-attendance-report.component";
import { Top10DayOutMaxDistanceDayWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-out-max-distance-day-wise-attendance-report/top10-day-out-max-distance-day-wise-attendance-report.component";
import { Top10DayOutMaxDistanceMonthlyWiseAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-out-max-distance-monthly-wise-attendance-report/top10-day-out-max-distance-monthly-wise-attendance-report.component";
import { Top10DayOutMismatchedEmpAttendanceReportComponent } from "./pages/Attendance Report/AttendanceReports/top10-day-out-mismatched-emp-attendance-report/top10-day-out-mismatched-emp-attendance-report.component";
import { BranchWiseDiscountReportComponent } from "./pages/SalesModule/SalesReports/branch-wise-discount-report/branch-wise-discount-report.component";
import { BranchWiseSalesSummaryComponent } from "./pages/SalesModule/SalesReports/branch-wise-sales-summary/branch-wise-sales-summary.component";
import { ModelWiseDailyDetailedReportComponent } from "./pages/SalesModule/SalesReports/model-wise-daily-detailed-report/model-wise-daily-detailed-report.component";
import { ModelWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/model-wise-summary-report/model-wise-summary-report.component";
import { SalesExecutiveWiseDetailedReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-detailed-report/sales-executive-wise-detailed-report.component";
import { SalesExecutiveWiseModelWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-model-wise-summary-report/sales-executive-wise-model-wise-summary-report.component";
import { SalesExecutiveWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-summary-report/sales-executive-wise-summary-report.component";
// import { ScheduleMasterComponent } from "./pages/Schedulemaster/schedule-master/schedule-master.component";
import { NewincentiveComponent } from "./pages/Incentiveforms/SOTIncentive/newincentive/newincentive.component";
import { FinanceMappingComponent } from "./pages/Incentiveforms/EWIncentive/finance-mapping/finance-mapping.component";
import { FinanceMasterComponent } from "./pages/Incentiveforms/Financeincentive/finance-master/finance-master.component";
import { InsuranceTableComponent } from "./pages/Incentiveforms/Insuranceincentive/insurance-table/insurance-table.component";
import { AreaTableComponent } from "./pages/Area Master/area-table/area-table.component";
import { SalesHeadSummaryReportComponent } from "./pages/SalesModule/SalesReports/sales-head-summary-report/sales-head-summary-report.component";
import { SalesExemodalDsReportComponent } from "./pages/SalesModule/SalesReports/sales-exemodal-ds-report/sales-exemodal-ds-report.component";
import { SalesExemodalInsuranceReportComponent } from "./pages/SalesModule/SalesReports/sales-exemodal-insurance-report/sales-exemodal-insurance-report.component";
import { BackofficeareaempComponent } from "./pages/SalesModule/SalesReports/backofficeareaemp/backofficeareaemp.component";
import { BackOfficeAreaReortComponent } from "./pages/SalesModule/SalesReports/back-office-area-reort/back-office-area-reort.component";
import { IncentiveReportComponent } from "./pages/SalesModule/SalesReports/incentive-report/incentive-report.component";
import { SalesExecutiveSOTreportComponent } from "./pages/SalesModule/SalesReports/sales-executive-sotreport/sales-executive-sotreport.component";
import { SalesExecutiveEWComponent } from "./pages/SalesModule/SalesReports/sales-executive-ew/sales-executive-ew.component";
import { BranchWiseSummaryReportComponent } from "./pages/SalesModule/SalesReports/branch-wise-summary-report/branch-wise-summary-report.component";
import { SOTSalesSummaryReportComponent } from "./pages/SalesModule/SalesReports/sotsales-summary-report/sotsales-summary-report.component";
import { EWSalesSummaryReportComponent } from "./pages/SalesModule/SalesReports/ewsales-summary-report/ewsales-summary-report.component";
import { BranchesallComponent } from "./pages/BranchMaster/SalesMapping/branchesall/branchesall.component";
import { MemoAssAccComponent } from "./pages/SalesModule/Reports/memo-ass-acc/memo-ass-acc.component";
import { HPROMISEComponent } from "./pages/SalesModule/Reports/hpromise/hpromise.component";
import { VINmemoComponent } from "./pages/SalesModule/Reports/vinmemo/vinmemo.component";
// import { DatabasetablemasterlistComponent } from "./pages/Servicemodule/databasetablemaster/databasetablemasterlist/databasetablemasterlist.component";
// import { ListleadCategoryMasterComponent } from "./pages/Servicemodule/LeadCategoryMaster/listlead-category-master/listlead-category-master.component";
// import { ServiceslubdepartlistComponent } from "./pages/Servicemodule/serviceslubdepartmentmaster/serviceslubdepartlist/serviceslubdepartlist.component";
import { EmpLeaveMappingComponent } from "./pages/New folder/emp-leave-mapping/emp-leave-mapping.component";
import { EmpLeavePolicyMaptableComponent } from "./pages/New folder/emp-leave-policy-maptable/emp-leave-policy-maptable.component";
import { EmpTransactionMappingComponent } from "./pages/New folder/emp-transaction-mapping/emp-transaction-mapping.component";
import { LeavePolicyTableComponent } from "./pages/New folder/Leave Policy/leave-policy-table/leave-policy-table.component";
// import { MasterTableComponent } from "./pages/New folder/Leave Policy/master-table/master-table.component";
import { LeaveAdjustmentTableComponent } from "./pages/New folder/leave-adjustment-table/leave-adjustment-table.component";
import { LeaveYearconfigurationtableComponent } from "./pages/New folder/leave-yearconfigurationtable/leave-yearconfigurationtable.component";
import { MasterTableComponent } from "./pages/New folder/Leave Policy/master-table/master-table.component";
import { CustomerWiseMemoDetailedReportComponent } from "./pages/SalesModule/SalesReports/customer-wise-memo-detailed-report/customer-wise-memo-detailed-report/customer-wise-memo-detailed-report.component";
import { MemoDetailedReportComponent } from "./pages/SalesModule/SalesReports/memo-detailed-report/memo-detailed-report/memo-detailed-report.component";
import { MemoWiseDiscountDetailedReportComponent } from "./pages/SalesModule/SalesReports/memo-wise-discount-detailed-report/memo-wise-discount-detailed-report/memo-wise-discount-detailed-report.component";

import { ActivityMasterTableComponent } from "./pages/ActivityMaster/ActivityMaster1/activity-master-table/activity-master-table.component";
// import { ActivityTypeListComponent } from "./pages/ActivityMaster/ActivityType/activity-type-list/activity-type-list.component";
import { ImportmasterComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/importmaster/importmaster.component";
import { ExtendwarrentyComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/extendwarrenty/extendwarrenty.component";
import { InsuranceComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/insurance/insurance.component";
import { PsfmasterComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/psfmaster/psfmaster.component";
import { SmrmasterComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/importmaster/smrmaster/smrmaster.component";
import { ActionMasterTableComponent } from "./pages/ActivityMaster/action-master-table/action-master-table.component";
import { SalesExecutiveWiseDetailedQuotationReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-detailed-quotation-report/sales-executive-wise-detailed-quotation-report.component";
import { SalesExecutiveWiseDetailedMemoReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-detailed-memo-report/sales-executive-wise-detailed-memo-report.component";
import { SalesExecutiveWiseDetailedDiscountReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-detailed-discount-report/sales-executive-wise-detailed-discount-report.component";
import { SalesExecutiveWiseDetailedInvoiceReportComponent } from "./pages/SalesModule/SalesReports/sales-executive-wise-detailed-invoice-report/sales-executive-wise-detailed-invoice-report.component";
import { QuotationDetailsComponent } from "./pages/SalesModule/Reports/quotation-details/quotation-details.component";
import { QuotationDetailedReportComponent } from "./pages/SalesModule/SalesReports/quotation-detailed-report/quotation-detailed-report.component";
import { ActivityTypeListComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/ActivityType/activity-type-list/activity-type-list.component";
import { CallcenterexecutivetableComponent } from "./pages/ActivityMaster/callcenterexecutivetable/callcenterexecutivetable.component";

// new reports 24
import { SalewisedetaileddiscountreportComponent } from './pages/New Reports 24/salewisedetaileddiscountreport/salewisedetaileddiscountreport.component';
import { SalesheadwisediscountsummaryreportComponent } from './pages/New Reports 24/salesheadwisediscountsummaryreport/salesheadwisediscountsummaryreport.component';
import { SalesexecutivewiseinhouseinsurancesummaryreportComponent } from './pages/New Reports 24/salesexecutivewiseinhouseinsurancesummaryreport/salesexecutivewiseinhouseinsurancesummaryreport.component';
import { ModelwisesummaryComponent } from './pages/New Reports 24/modelwisesummary/modelwisesummary.component';
import { ModelwisequotationdetailsComponent } from './pages/New Reports 24/modelwisequotationdetails/modelwisequotationdetails.component';
import { CustwisediscountdetailComponent } from './pages/New Reports 24/custwisediscountdetail/custwisediscountdetail.component';
import { CustwisedetailComponent } from './pages/New Reports 24/custwisedetail/custwisedetail.component';
import { CustomerwiseinvoicedetailedreportComponent } from './pages/New Reports 24/customerwiseinvoicedetailedreport/customerwiseinvoicedetailedreport.component';
import { CustomerwisefinancereportComponent } from './pages/New Reports 24/customerwisefinancereport/customerwisefinancereport.component';
import { CustomerwiseInsurancereportComponent } from './pages/New Reports 24/customerwise-insurancereport/customerwise-insurancereport.component';
import { BranchwiseinhousefinancedetailedreportComponent } from './pages/New Reports 24/branchwiseinhousefinancedetailedreport/branchwiseinhousefinancedetailedreport.component';
import { BranchwiseadvancecollectedreportComponent } from './pages/New Reports 24/branchwiseadvancecollectedreport/branchwiseadvancecollectedreport.component';
import { BankwisememosummaryreportComponent } from './pages/New Reports 24/bankwisememosummaryreport/bankwisememosummaryreport.component';
// import { GeneralManagerWiseDetailedDiscountReportComponent, GeneralManagerWiseDetailedDiscountReportComponent } from "./pages/New Reports 25/general-manager-wise-detailed-discount-report/general-manager-wise-detailed-discount-report.component";
// import { SalesExecutiveWiseAdvanceDetailedReportComponent, SalesExecutiveWiseAdvanceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-advance-detailed-report/sales-executive-wise-advance-detailed-report.component";
import { GeneralManagerWiseDiscountSummaryReportComponent } from "./pages/New Reports 25/general-manager-wise-discount-summary-report/general-manager-wise-discount-summary-report.component";
import { DirectorWiseDiscountSummaryReportComponent } from "./pages/New Reports 25/director-wise-discount-summary-report/director-wise-discount-summary-report.component";
import { BranchWiseInhouseInsuraceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-inhouse-insurace-summary-report/branch-wise-inhouse-insurace-summary-report.component";
import { BranchWiseOuthouseInsuraceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-outhouse-insurace-summary-report/branch-wise-outhouse-insurace-summary-report.component";
import { BranchWiseAdvanceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-advance-summary-report/branch-wise-advance-summary-report.component";
import { BranchWiseSalesExecutiveWiseAdvanceDetailedReportComponent } from "./pages/New Reports 25/branch-wise-sales-executive-wise-advance-detailed-report/branch-wise-sales-executive-wise-advance-detailed-report.component";
import { BranchWiseFinanceSummaryReportComponent } from "./pages/New Reports 25/branch-wise-finance-summary-report/branch-wise-finance-summary-report.component";
import { BranchWiseDiscountSummaryReportComponent } from "./pages/New Reports 25/branch-wise-discount-summary-report/branch-wise-discount-summary-report.component";
// import { SalesExecutiveWiseInhouseInsuraceDetailedReportComponent, SalesExecutiveWiseInhouseInsuraceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-insurace-detailed-report/sales-executive-wise-inhouse-insurace-detailed-report.component";
import { SalesExecutiveWiseInsuranceCompanyWiseInhouseInsuranceSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-insurance-company-wise-inhouse-insurance-summary-report/sales-executive-wise-insurance-company-wise-inhouse-insurance-summary-report.component";
import { SalesExecutiveWiseOuthouseInsuraceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-outhouse-insurace-detailed-report/sales-executive-wise-outhouse-insurace-detailed-report.component";
import { SalesExecutiveWiseInsuranceCompanyWiseOuthouseInsuranceSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-insurance-company-wise-outhouse-insurance-summary-report/sales-executive-wise-insurance-company-wise-outhouse-insurance-summary-report.component";
import { SalesExecutiveWiseFinanceWiseInhouseDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-finance-wise-inhouse-detailed-report/sales-executive-wise-finance-wise-inhouse-detailed-report.component";
import { SalesExecutiveWiseFinanceWiseOuthouseDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-finance-wise-outhouse-detailed-report/sales-executive-wise-finance-wise-outhouse-detailed-report.component";
// import { SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent, SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent } from "./pages/New Reports 25/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report.component";
import { SalesExecutiveWiseBankWiseOuthouseFinanceSummarydReportComponent } from "./pages/New Reports 25/sales-executive-wise-bank-wise-outhouse-finance-summaryd-report/sales-executive-wise-bank-wise-outhouse-finance-summaryd-report.component";
// import { SalesExecutiveWiseInhouseSummaryReportComponent, SalesExecutiveWiseInhouseSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-summary-report/sales-executive-wise-inhouse-summary-report.component";
import { SalesExecutiveWiseOuthouseSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-outhouse-summary-report/sales-executive-wise-outhouse-summary-report.component";
import { SalesExecutiveWiseRSADetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-rsadetailed-report/sales-executive-wise-rsadetailed-report.component";
import { NewModelWiseSummaryReportComponent } from "./pages/New Reports 25/new-model-wise-summary-report/new-model-wise-summary-report.component";
import { SalesManagerWiseSalesExeModelWiseSummaryReportComponent } from "./pages/New Reports 25/sales-manager-wise-sales-exe-model-wise-summary-report/sales-manager-wise-sales-exe-model-wise-summary-report.component";
import { SalesManagerWiseInsuranceWiseSummaryReportComponent } from "./pages/New Reports 25/sales-manager-wise-insurance-wise-summary-report/sales-manager-wise-insurance-wise-summary-report.component";
import { SalesManagerWiseFinanceWiseSummaryReportComponent } from "./pages/New Reports 25/sales-manager-wise-finance-wise-summary-report/sales-manager-wise-finance-wise-summary-report.component";
import { BankWiseSalesManagerWiseFinanceInhouseSummaryReportComponent } from "./pages/New Reports 25/bank-wise-sales-manager-wise-finance-inhouse-summary-report/bank-wise-sales-manager-wise-finance-inhouse-summary-report.component";
import { DiscountDetailedReportComponent } from "./pages/New Reports 25/discount-detailed-report/discount-detailed-report.component";
import { SalesExecutiveWiseInhouseInsuraceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-insurace-detailed-report/sales-executive-wise-inhouse-insurace-detailed-report.component";
import { SalesExecutiveWiseAdvanceDetailedReportComponent } from "./pages/New Reports 25/sales-executive-wise-advance-detailed-report/sales-executive-wise-advance-detailed-report.component";
import { GeneralManagerWiseDetailedDiscountReportComponent } from "./pages/New Reports 25/general-manager-wise-detailed-discount-report/general-manager-wise-detailed-discount-report.component";
import { SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent } from "./pages/New Reports 25/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report/sales-executive-wise-bank-wise-inhouse-finance-summaryd-report.component";
import { SalesExecutiveWiseInhouseSummaryReportComponent } from "./pages/New Reports 25/sales-executive-wise-inhouse-summary-report/sales-executive-wise-inhouse-summary-report.component";
import { ShiftmastersComponent } from "./pages/Shift Module/shiftmasters/shiftmasters.component";
import { MonthlyLeavereportComponent } from "./pages/New Reports 24/monthly-leavereport/monthly-leavereport.component";
import { BranchwisedetailedinvoiceComponent } from "./pages/New Reports 26/Shubham Reports/branchwisedetailedinvoice/branchwisedetailedinvoice.component";
import { BranchwisemodelwisesalesreportComponent } from "./pages/New Reports 26/Shubham Reports/branchwisemodelwisesalesreport/branchwisemodelwisesalesreport.component";
// import { CustomerwiseRSAdetailedreportComponent } from './pages/New Reports 26/Shubham Reports/salesexecutivewise-rsasummaryreport/customerwise-rsadetailedreport/customerwise-rsadetailedreport.component';
import { CustomerwiseRSAdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/customerwise-rsadetailedreport/customerwise-rsadetailedreport.component";
import { CustomerwisequotationdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/customerwisequotationdetailedreport/customerwisequotationdetailedreport.component";
import { ModelwisequotationsummaryComponent } from "./pages/New Reports 26/Shubham Reports/modelwisequotationsummary/modelwisequotationsummary.component";
import { ModelwisevarientdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/modelwisevarientdetailedreport/modelwisevarientdetailedreport.component";
import { FinancebankwisesummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/financebankwisesummaryreport/financebankwisesummaryreport.component";
import { InsurancecompanywisesummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/insurancecompanywisesummaryreport/insurancecompanywisesummaryreport.component";
import { SalesmanagerwisesalesexecutivesummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/salesmanagerwisesalesexecutivesummaryreport/salesmanagerwisesalesexecutivesummaryreport.component";
import { ModelwisepricelistComponent } from "./pages/New Reports 26/Shubham Reports/modelwisepricelist/modelwisepricelist.component";
import { SalesexecutivewiseRSAsummaryreportComponent } from "./pages/New Reports 26/Shubham Reports/salesexecutivewise-rsasummaryreport/salesexecutivewise-rsasummaryreport.component";
import { HeadapproveshortleaveComponent } from "./pages/EMM_Module/Shortleave/headapproveshortleave/headapproveshortleave.component";
import { ShortleavetableComponent } from "./pages/EMM_Module/Shortleave/shortleavetable/shortleavetable.component";
import { ScheduleMasterComponent } from "./pages/Schedule Reports/schedule-master/schedule-master.component";
import { SalesheadmemoComponent } from "./pages/SalesModule/Reports/salesheadmemo/salesheadmemo.component";
import { PayslipComponent } from "./pages/salary_slip/Salary_slip/payslip/payslip.component";
import { SalesreportComponent } from "./pages/Reports_UI/salesreport/salesreport.component";

import { InhouseInsuranceReportComponent } from "./pages/Sales Dash report/inhouse-insurance-report/inhouse-insurance-report.component";
import { OuthouseInsuranceReportComponent } from "./pages/Sales Dash report/outhouse-insurance-report/outhouse-insurance-report.component";
import { BranchWiseMemoPendancyReportComponent } from "./pages/Sales Dash report/branch-wise-memo-pendancy-report/branch-wise-memo-pendancy-report.component";
import { BranchWiseMostDiscountApprovalReportComponent } from "./pages/Sales Dash report/branch-wise-most-discount-approval-report/branch-wise-most-discount-approval-report.component";
import { BranchWiseDiscountPendancyReportComponent } from "./pages/Sales Dash report/branch-wise-discount-pendancy-report/branch-wise-discount-pendancy-report.component";
import { MontlymodelwiseinvoicegeneratedComponent } from "./pages/Sales Dash report/montlymodelwiseinvoicegenerated/montlymodelwiseinvoicegenerated.component";
import { MontlybranchwisemodelwiseinvoicegeneratedComponent } from "./pages/Sales Dash report/montlybranchwisemodelwiseinvoicegenerated/montlybranchwisemodelwiseinvoicegenerated.component";
import { InhouseFinanceReportComponent } from "./pages/Sales Dash report/inhouse-finance-report/inhouse-finance-report.component";
import { OuthouseFinanceReportComponent } from "./pages/Sales Dash report/outhouse-finance-report/outhouse-finance-report.component";
import { SalesExecutiveWiseQuotationGeneratedTodayReportComponent } from "./pages/Sales Dash report/sales-executive-wise-quotation-generated-today-report/sales-executive-wise-quotation-generated-today-report.component";
import { LeaveBalancereportComponent } from "./pages/Reports_UI/Leave reports/leave-balancereport/leave-balancereport.component";
import { LeaveTransactionDetailesreportComponent } from "./pages/Reports_UI/Leave reports/leave-transaction-detailesreport/leave-transaction-detailesreport.component";
import { LeaveTransactionSummaryReportComponent } from "./pages/Reports_UI/Leave reports/leave-transaction-summary-report/leave-transaction-summary-report.component";
// import { SalesDashboardComponent } from "./pages/CommonModule/Dashboard/sales-dashboard/sales-dashboard.component";
import { HRDashboardComponent } from "./pages/CommonModule/Dashboard/hrdashboard/hrdashboard.component";
import { SalesDashboardComponent } from "./pages/CommonModule/Dashboard/sales-dashboard/sales-dashboard.component";
import { MemoWiseReportComponent } from "./pages/Reports_UI/memo-wise-report/memo-wise-report.component";
import { ModelWiseReportComponent } from "./pages/Reports_UI/model-wise-report/model-wise-report.component";
import { IncentiveReportsComponent } from "./pages/Reports_UI/incentive-reports/incentive-reports.component";
import { SalesHeadReportComponent } from "./pages/Reports_UI/sales-head-report/sales-head-report.component";
import { SalesManageReportComponent } from "./pages/Reports_UI/sales-manage-report/sales-manage-report.component";
import { CustomerWiseReportComponent } from "./pages/Reports_UI/customer-wise-report/customer-wise-report.component";
import { BankWiseReportComponent } from "./pages/Reports_UI/bank-wise-report/bank-wise-report.component";
import { GeneralManagerWiseReportComponent } from "./pages/Reports_UI/general-manager-wise-report/general-manager-wise-report.component";
import { DiscountReportComponent } from "./pages/Reports_UI/discount-report/discount-report.component";
import { BranchWiseComponent } from "./pages/Reports_UI/branch-wise/branch-wise.component";
// import { SalaryComponent } from "./pages/salary_slip/salary/salary.component";

import { BranchwisequotationdetailsComponent } from './pages/New Reports 26/Shubham Reports/branchwisequotationdetails/branchwisequotationdetails.component';
import { BranchWiseFinanceDetailedReportComponent } from './pages/New Reports 25/branch-wise-Outhouse-finance-detailed-report/branch-wise-finance-detailed-report/branch-wise-finance-detailed-report.component';
// import { CustomerwisequotationdetailedreportComponent } from "./pages/New Reports 26/Shubham Reports/customerwisequotationdetailedreport/customerwisequotationdetailedreport.component";
import { QuotationwisereportsComponent } from './pages/Reports_UI/quotationwisereports/quotationwisereports.component';
import { BankWiseSMWiseFinanceReportComponent } from "./pages/New Reports 25/bank-wise-sm-wise-finance-report/bank-wise-sm-wise-finance-report.component";
import { SalesManagerWiseBranchWiseReportComponent } from "./pages/New Reports 24/sales-manager-wise-branch-wise-report/sales-manager-wise-branch-wise-report.component";
import { SalesManagerWiseSalesExecutiveWiseReportComponent } from "./pages/New Reports 24/sales-manager-wise-sales-executive-wise-report/sales-manager-wise-sales-executive-wise-report.component";
import { InsuranceCompanyWiseSmWiseInsuranceReportComponent } from "./pages/New Reports 24/insurance-company-wise-sm-wise-insurance-report/insurance-company-wise-sm-wise-insurance-report.component";
import { InsurancereportComponent } from "./pages/Reports_UI/insurancereport/insurancereport.component";
import { InsuranceCompanyWiseSmWiseInsuranceOuthouseReportComponent } from "./pages/New Reports 24/insurance-company-wise-sm-wise-insurance-outhouse-report/insurance-company-wise-sm-wise-insurance-outhouse-report.component";
import { DetailedReportsComponent } from "./pages/Reports_UI/detailed-reports/detailed-reports.component";
import { DetailedHPromiseReportComponent } from './pages/New Reports 24/detailed-hpromise-report/detailed-hpromise-report.component';
import { DetailedVINReportComponent } from './pages/New Reports 24/detailed-vinreport/detailed-vinreport.component';
import { QuotationComponent } from "./pages/View/quotation/quotation.component";
import { Quotation1Component } from "./pages/View/quotation1/quotation1.component";
import { Quotation2Component } from "./pages/View/quotation2/quotation2.component";
import { BranchWiseSalesSummryComponent } from "./pages/Reports_UI/branch-wise-sales-summry/branch-wise-sales-summry.component";
import { ModelWiseSalesSummryComponent } from "./pages/Reports_UI/model-wise-sales-summry/model-wise-sales-summry.component";
import { TransmissionTypeWiseSalesSummaryComponent } from "./pages/Reports_UI/transmission-type-wise-sales-summary/transmission-type-wise-sales-summary.component";
import { FuelTypeWiseSalesSummaryComponent } from "./pages/Reports_UI/fuel-type-wise-sales-summary/fuel-type-wise-sales-summary.component";

import { InsuranceMemoComponent } from './pages/SalesModule/Reports/insurance-memo/insurance-memo.component';
import { FinanceMemoComponent } from './pages/SalesModule/Reports/finance-memo/finance-memo.component';
import { SalesMemoComponent } from './pages/SalesModule/Reports/sales-memo/sales-memo.component';

import { DistrictWiseSalesSummryComponent } from './pages/Reports_UI/district-wise-sales-summry/district-wise-sales-summry.component'

import { TalukaWiseSalesSummryComponent } from './pages/Reports_UI/taluka-wise-sales-summry/taluka-wise-sales-summry.component'
import { BranchwiseinsurancesummaryreportComponent } from "./pages/Report/branchwiseinsurancesummaryreport/branchwiseinsurancesummaryreport.component";
import { AlliedservicesComponent } from "./pages/Report/alliedservices/alliedservices.component";
import { SalesExeWiseModelWiseQuotationsGeneratedReportComponent } from "./pages/Report/sales-exe-wise-model-wise-quotations-generated-report/sales-exe-wise-model-wise-quotations-generated-report.component";
import { SalesExecutiveWiseModelWiseGeneratedReportComponent } from "./pages/Report/sales-executive-wise-model-wise-generated-report/sales-executive-wise-model-wise-generated-report.component";
import { ApptlisttreportComponent } from "./pages/ActivityMaster/S Reports/apptlisttreport/apptlisttreport.component";
import { FinanceuireportComponent } from "./pages/Reports_UI/financeuireport/financeuireport.component";
import { Quotation3Component } from "./pages/View/quotation3/quotation3.component";
import { LatemarkreportComponent } from "./pages/EMM_Module/latemarkreport/latemarkreport.component";
import { EarlymarkreportComponent } from "./pages/EMM_Module/earlymarkreport/earlymarkreport.component";
import { CallingSummaryReportComponent } from './pages/ActivityMaster/S Reports/calling-summary-report/calling-summary-report.component';
import { KpiReportComponent } from "./pages/ActivityMaster/S Reports/kpi-report/kpi-report.component";
import { CCEassignselfComponent } from "./pages/ActivityMaster/cceassignself/cceassignself.component";
import { AcsnewviewComponent } from "./pages/SalesModule/Reports/acsnewview/acsnewview.component";
import { DailyTargetReportComponent } from "./pages/ActivityMaster/S Reports/daily-target-report/daily-target-report.component";
import { RetensionSummaryReportComponent } from "./pages/ActivityMaster/S Reports/retension-summary-report/retension-summary-report.component";
import { AppointmentSummaryReportComponent } from "./pages/ActivityMaster/S Reports/appointment-summary-report/appointment-summary-report.component";
import { AllReportsComponent } from "./pages/Reports_UI/all-reports/all-reports.component";
import { ServiceslubdepartlistComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/serviceslubdepartmentmaster/serviceslubdepartlist/serviceslubdepartlist.component";
import { DatabasetablemasterlistComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/databasetablemaster/databasetablemasterlist/databasetablemasterlist.component";
import { ListleadCategoryMasterComponent } from "./pages/ActivityMaster/Servicesss Module/ServiceModule/LeadCategoryMaster/listlead-category-master/listlead-category-master.component";
import { ServiceadviserComponent } from "./pages/ActivityMaster/serviceadviser/serviceadviser.component";
import { NewcustomersComponent } from './pages/ActivityMaster/newcustomers/newcustomers.component';
import { ToursComponent } from "./pages/TourModule/TourMaster/tours/tours.component";
import { TourDestinationMasterComponent } from "./pages/TourModule/Tour Destination/tour-destination-master/tour-destination-master.component";
import { TourModeMasterComponent } from "./pages/TourModule/Tour Mode/tour-mode-master/tour-mode-master.component";
import { TourClassMasterComponent } from "./pages/TourModule/Tour Class/tour-class-master/tour-class-master.component";
import { TourACComponent } from './pages/TourModule/TourMaster/tour-ac/tour-ac.component';
import { TourHRComponent } from './pages/TourModule/TourMaster/tour-hr/tour-hr.component';
import { AmountmapComponent } from "./pages/TourModule/amountmap/amountmap.component";
// import { tourExpenseMaster } from "./Models/tourExpenseMaster";
import { TourExpenseMasterComponent } from "./pages/TourModule/Tour Expense/tour-expense-master/tour-expense-master.component";
import { TourExpenseHRComponent } from "./pages/TourModule/Tour Expense/tour-expense-hr/tour-expense-hr.component";
import { TourExpenseGMComponent } from "./pages/TourModule/Tour Expense/tour-expense-gm/tour-expense-gm.component";
import { TourExpenseACComponent } from "./pages/TourModule/Tour Expense/tour-expense-ac/tour-expense-ac.component";
import { TourExpenseHeadComponent } from "./pages/TourModule/Tour Expense/tour-expense-head/tour-expense-head.component";
import { InactiveEmpReportComponent } from "./pages/EMM_Module/Reports/inactive-emp-report/inactive-emp-report.component";


import { HmilcceComponent } from './pages/ActivityMaster/hmilcce/hmilcce.component';
import { HmiladminComponent } from './pages/ActivityMaster/hmiladmin/hmiladmin.component';
import { RetensionSummaryreport2Component } from "./pages/ActivityMaster/S Reports/retension-summaryreport2/retension-summaryreport2.component";
import { ProductModelReportComponent } from "./pages/Report/product-model-report/product-model-report.component";
import { ProductFuelReportComponent } from "./pages/Report/product-fuel-report/product-fuel-report.component";
import { ProductTransmissionReportComponent } from "./pages/Report/product-transmission-report/product-transmission-report.component";
import { ProductVarientWiseReportComponent } from "./pages/Report/product-varient-wise-report/product-varient-wise-report.component";
import { DailyVehicleInComponent } from "./pages/ActivityMaster/S Reports/daily-vehicle-in/daily-vehicle-in.component";

import { AlliedSInsuranceSalesReportComponent } from "./pages/Report/allied-sinsurance-sales-report/allied-sinsurance-sales-report.component";
import { AlliedEWSalesReportComponent } from "./pages/Report/allied-ewsales-report/allied-ewsales-report.component";
import { AlliedSOTSalesReportComponent } from "./pages/Report/allied-sotsales-report/allied-sotsales-report.component";
import { AlliedACCESSORIESSalesReportComponent } from "./pages/Report/allied-accessoriessales-report/allied-accessoriessales-report.component";
import { ServiceDiscountSalesReportComponent } from "./pages/Report/service-discount-sales-report/service-discount-sales-report.component";
import { CancellationSummaryReportComponent } from "./pages/Report/cancellation-summary-report/cancellation-summary-report.component";
import { ModificationSummaryreportComponent } from "./pages/Report/modification-summaryreport/modification-summaryreport.component";
import { ExchangeSalesReportComponent } from "./pages/Report/exchange-sales-report/exchange-sales-report.component";
import { DetailesCancellationReportComponent } from "./pages/Report/detailes-cancellation-report/detailes-cancellation-report.component";
import { DetailedModificationReportComponent } from "./pages/Report/detailed-modification-report/detailed-modification-report.component";
import { CorporateSalesReportComponent } from "./pages/Report/corporate-sales-report/corporate-sales-report.component";
import { DetailedNewReportComponent } from "./pages/Report/detailed-new-report/detailed-new-report.component";
import { CallsdestailedreportComponent } from "./pages/ActivityMaster/S Reports/callsdestailedreport/callsdestailedreport.component";
import { PsfleadstableComponent } from "./pages/ActivityMaster/PSF_master/psfleadstable/psfleadstable.component";
import { DessatisfiedleadstableComponent } from "./pages/ActivityMaster/PSF_master/dessatisfiedleadstable/dessatisfiedleadstable.component";
import { ForlogstableComponent } from "./pages/EMM_Module/forlogstable/forlogstable.component";
import { AttendanceadjustmentreportComponent } from "./pages/EMM_Module/Reports/attendanceadjustmentreport/attendanceadjustmentreport.component";
import { PsfsatisleadComponent } from "./pages/ActivityMaster/PSF_master/psfsatislead/psfsatislead.component";
import { SMmemoformComponent } from "./pages/SalesModule/Reports/smmemoform/smmemoform.component";
import { Quotation44Component } from "./pages/View/quotation44/quotation44.component";
import { FeedbackAreasComponent } from "./pages/Feed form/feedback-areas/feedback-areas.component";
import { FeedbackformsComponent } from "./pages/Feed form/feedbackforms/feedbackforms.component";
import { FeedbackformapprovesComponent } from "./pages/Feed form/feedbackformapproves/feedbackformapproves.component";
import { EmployeeCriteriaReportComponent } from "./pages/Feed form/employee-criteria-report/employee-criteria-report.component";
import { FinanceincentiveallocationComponent } from "./pages/Report/financeincentiveallocation/financeincentiveallocation.component";
import { FinanceincentiveallocationsummaryComponent } from "./pages/Report/financeincentiveallocationsummary/financeincentiveallocationsummary.component";
import { UserwiseFeedBackComplaintReportComponent } from "./pages/Report/userwise-feed-back-complaint-report/userwise-feed-back-complaint-report.component";
import { ResolverwiseFeedBackComplaintSummaryReportComponent } from "./pages/Report/resolverwise-feed-back-complaint-summary-report/resolverwise-feed-back-complaint-summary-report.component";
import { UserWiseDetailedReportComponent } from "./pages/Report/user-wise-detailed-report/user-wise-detailed-report.component";
import { BranchWiseFeedbackReportComponent } from "./pages/Report/branch-wise-feedback-report/branch-wise-feedback-report.component";
import { TourreportComponent } from "./pages/TourModule/Reportsfortour/tourreport/tourreport.component";
import { EmployeeevaluationreportComponent } from "./pages/TourModule/Reportsfortour/employeeevaluationreport/employeeevaluationreport.component";
import { LeaveSummarysReportComponent } from "./pages/New folder/leave-summarys-report/leave-summarys-report.component";
import { AsOnDateBalanceReportComponent } from "./pages/New folder/as-on-date-balance-report/as-on-date-balance-report.component";
import { LeaveTransactionDetailedReportComponent } from "./pages/New folder/leave-transaction-detailed-report/leave-transaction-detailed-report.component";
import { ScAndTlIncentiveComponent } from "./pages/Report/sc-and-tl-incentive/sc-and-tl-incentive.component";
import { SMRIncentiveReportComponent } from "./pages/ActivityMaster/S Reports/smrincentive-report/smrincentive-report.component";
import { LeadAptTransferReportComponent } from "./pages/ActivityMaster/S Reports/lead-apt-transfer-report/lead-apt-transfer-report.component";
import { DailyDSIReportReportComponent } from "./pages/ActivityMaster/S Reports/daily-dsireport-report/daily-dsireport-report.component";
import { FeedBackGMApproveTableComponent } from "./pages/Feed form/feed-back-gmapprove-table/feed-back-gmapprove-table.component";
import { PSFComplaintDetailesComponent } from "./pages/ActivityMaster/S Reports/psf-complaint-detailes/psf-complaint-detailes.component";
import { SAWisePSFReportComponent } from "./pages/ActivityMaster/S Reports/sa-wise-psf-report/sa-wise-psf-report.component";
import { PsfDetailedCallingReportComponent } from "./pages/ActivityMaster/S Reports/psf-detailed-calling-report/psf-detailed-calling-report.component";
// import {DailyDSIReportReportComponent}from "./pages/ActivityMaster/S Reports/daily-dsireport-report.compo"

export const routes: Routes = [
  { path: "detailedcallingpsfreport", component: PsfDetailedCallingReportComponent },
  
  { path: "Branchwiseinsurancesummaryreport", component: BranchwiseinsurancesummaryreportComponent },
  { path: "Alliedservices", component: AlliedservicesComponent },
  { path: "salesModelQuotationReport", component: SalesExeWiseModelWiseQuotationsGeneratedReportComponent },
  { path: "SalesExecutiveWiseModelWiseGenerated", component: SalesExecutiveWiseModelWiseGeneratedReportComponent },
  { path: "login", redirectTo: "login" },
  { path: "", pathMatch: "full", redirectTo: "/dashboard" },
  { path: "login", component: LoginComponent },
  { path: "orglogin", component: LoginComponent },
  { path: "adminlogin", component: EmploginComponent },
  { path: "traineraccessorlogin", component: TrainerAccessorLoginComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "admindashboard", component: DashboardreportComponent },
  { path: "DetailedHPromise", component: DetailedHPromiseReportComponent },
  { path: "DetailedVINReport", component: DetailedVINReportComponent },

  { path: 'QuotationwisereportsComponent', component: QuotationwisereportsComponent },

  { path: 'BranchWiseSales', component: BranchWiseSalesSummryComponent },

  { path: 'ModelWiseSales', component: ModelWiseSalesSummryComponent },

  { path: 'TransmissionTypeWiseSales', component: TransmissionTypeWiseSalesSummaryComponent },

  { path: 'FuelTypeWiseSales', component: FuelTypeWiseSalesSummaryComponent },

  { path: 'InsuranceMemoComponent', component: InsuranceMemoComponent },
  { path: 'FinanceMemoComponent', component: FinanceMemoComponent },
  { path: 'SalesMemoComponent', component: SalesMemoComponent },
  // { path: 'modules', component: ModulesComponent },
  // { path: 'supportmodule/departments', component: DepartmentsComponent },
  // { path: 'communicationmodule/emailserver', component: EmailsComponent },
  // { path: 'communicationmodule/smsserver', component: SmssComponent },
  // { path: 'startpage', component: SelectpageComponent },

  { path: "Quotation", component: QuotationComponent },

  { path: "Quotation1", component: Quotation1Component },

  { path: "Quotation2", component: Quotation2Component },

  { path: "users", component: UsersComponent },
  { path: "roles", component: RolesComponent },
  { path: "forms", component: FormsComponent },
  { path: "faqheads", component: FaqHeadsComponent },
  { path: "faqs", component: FaqsComponent },
  { path: "documentmodule/folders", component: FoldersComponent },
  { path: "mytickets", component: MyticketComponent },
  { path: "tickets", component: TicketsComponent },
  { path: "reports/tickets", component: TicketreportComponent },
  { path: "reports/departmentwisesummary", component: SummaryreportComponent },
  { path: "reports/userwisesummary", component: UserwisesummaryComponent },
  { path: "reports/OptionWiseResport", component: OptionreportComponent },

  // { path: 'userapplicationmapping', component: UserapplicationmappingComponent },
  // { path: 'usermodulemapping', component: UsermodulemappingComponent },
  // { path: 'useraccessmapping', component: UseraccessmappingComponent },
  // { path: 'userrolemapping', component: UserrolemappingComponent },
  // { path: 'userdepartmentmapping', component: UserdepartmentmappingComponent },
  // { path: 'Schedularmodule/tasks', component: TasksComponent },
  // { path: 'Schedularmodule/taskshistory', component: ViewhistoryComponent },
  // { path: 'loggermodule/loghistory', component: LogmoduleComponent },
  // { path: 'test', component: TestComponent },
  // {path :'organizations', component:OrganizationsComponent,canActivate:[AuthGuard],data:{roleId:['1']},  },

  { path: "ticketgroups", component: TicketgroupComponent },
  { path: "organizations", component: OrganizationsComponent },
  { path: "departments", component: DepartmentsComponent },
  { path: "branch", component: BranchesComponent },
  { path: "designation", component: DesignationsComponent },
  { path: "tickrole", component: TickRolesComponent },
  { path: "features", component: FeaturesComponent },
  { path: "employee", component: EmployeemasterlistComponent },
  { path: "cluster", component: ClustermasterlistComponent },
  { path: "employeeRoleMapping", component: EmployeerolemaplistComponent },
  { path: "help", component: SearchfaqComponent },
  { path: "folders", component: FolderMasterComponent },
  { path: "sharedfolders", component: SharedFolderMasterComponent },
  { path: "faqresponses", component: FaqresponsereportComponent },

  // EMM Module
  { path: "leavetype", component: LeavetypesComponent },
  { path: "evaluationcriteria", component: EvaluationcriteriasComponent },
  { path: "location", component: CustomersComponent },
  { path: "holiday", component: HolidaysComponent },
  { path: "thought", component: ThoughsComponent },
  { path: "birthday", component: BirthdaysComponent },
  { path: "expensehead", component: ExpensesheadComponent },
  { path: "leave", component: LeaveTilesComponent },
  { path: "expenses", component: ExpensesTilesComponent },
  { path: "earlymark", component: EarlymarkTilesComponent },
  { path: "latemark", component: LatemarkTilesComponent },
  { path: "approveleavesbyhr", component: ApproveEmployeeLeavesTilesComponent },
  { path: "approveleavesbyhead", component: HeadApproveEmployeeLeavesTilesComponent },
  { path: "approvelatemarkbyhead", component: HeadApproveEmployeeLatemarkTilesComponent },
  { path: "approveearlymarkbyhead", component: HeadApproveEmployeeEarlymarkTilesComponent },
  { path: "approveexpensesbyaccountant", component: ExpensesApproveFromAccountantTilesComponent },
  { path: "approveexpensesbyhead", component: ExpensesApproveFromHeadTilesComponent },
  { path: "attendancereport", component: AttendancereportComponent },
  { path: "leavereport", component: LeavereportComponent },
  { path: "latemarkreport", component: LatemarkreportComponent },
  { path: "earlymarkreport", component: EarlymarkreportComponent },
  { path: "expensereport", component: ExpensereportComponent },
  { path: "attendanceregister", component: AttendanceregisterComponent },
  { path: "employeeofthemonth", component: EmployeeOfTheMonthComponent },
  // { path: 'evaluationreport', component: EvaluationreportComponent },

  // Material Issue Module
  { path: "assetcategory", component: AssetCategoryListComponent },
  { path: "assets", component: AssetListComponent },
  { path: "assetrequest", component: AssetRequestListComponent },
  { path: "assetallocation", component: AssetMappingListComponent },
  { path: "assetregister", component: AssetRegisterComponent },
  { path: "assetallocationregister", component: AssetAllocationRegisterComponent },
  { path: "assetcategoryregister", component: AssetCategoryRegisterComponent },

  // Training Module
  { path: "trainingsubjectgroup", component: TrainingsublistComponent },
  { path: "trainingsubject", component: TrainingpointmasterlistComponent },
  { path: "traineraccessor", component: TrainermasterlistComponent },
  { path: "trainingtype", component: TrainingtypelistComponent },
  { path: "annualtrainingpoint", component: AnnualtrainpointlistComponent },
  { path: "trainingschedule", component: TrainingschedulelistComponent },
  { path: "venue", component: InductionarealistComponent },
  { path: "trainingfeedbackform", component: TrainfeedformmasterlistComponent },
  { path: "trainingevaluationform", component: TrainingevalutionformlistComponent },
  { path: "trainingfeedback", component: TrainfeedmasterlistComponent },
  { path: "trainattendcard", component: TrainattendancecardlistComponent },
  { path: "trainingattendance", component: TrainerloginviewComponent },
  { path: "trainingevaluation", component: AccessorviewComponent },

  // Sales Module
  { path: "transmission", component: TransmissionMasterComponent },
  { path: "fuel", component: FuelMasterComponent },
  { path: "color", component: ColorMasterComponent },
  { path: "model", component: ModelMasterComponent },
  { path: "varient", component: VarientMasterComponent },
  { path: "pricelist", component: PriceListMasterComponent },
  { path: "quotation-details", component: QuotationDetailsComponent },
  { path: "sales-reports", component: SalesModuleReportsComponent },
  { path: "memo-details", component: MemoDetailsComponent },
  { path: "salesheadmemo", component: SalesheadmemoComponent },
  { path: "memoverify", component: MemoDetails2Component },
  { path: "attendance-reports", component: AttendanceReportsComponent },
  { path: "bank", component: BankListComponent },
  { path: "insurance", component: InsuranceTableComponent },
  { path: "finance", component: FinanceMasterComponent },
  { path: "salesrate", component: RateMasterComponent },
  { path: "DayWiseAttendanceReport", component: DayWiseAttendanceReportComponent, },
  { path: "BranchWiseAttendanceReport", component: BranchWiseAttendanceReportComponent, },
  { path: "DepartmentWiseAttendanceReport", component: DepartmentWiseAttendanceReportComponent, },
  // { path: "DesignationWiseAttendanceReport", component: DesignationWiseAttendanceReportComponent, },

  { path: "MonthlyAvgAttendanceReport", component: MonthlyAvgAttendanceReportComponent, },
  { path: "BranchWiseAvgAttendanceReport", component: BranchWiseAvgAttendanceReportComponent, },
  { path: "DepartmentWiseAvgAttendanceReport", component: DepartmentWiseAvgAttendanceReportComponent, },
  { path: "DesignationWiseAvgAttendanceReport", component: DesignationWiseAvgAttendanceReportComponent, },
  { path: "DayWiseSuperLate10EmpAttendanceReport", component: DayWiseSuperLate10EmpAttendanceReportComponent, },
  { path: "MonthlySuperLate10EmpAttendanceReport", component: MonthlySuperLate10EmpAttendanceReportComponent, },
  { path: "DayWiseLeaveAttendanceReport", component: DayWiseLeaveAttendanceReportComponent, },
  { path: "DayWiseLatemarkAttendanceReport", component: DayWiseLatemarkAttendanceReportComponent, },
  // { path: "GraphicalAttendanceSummaryReport", component: GraphicalAttendanceSummaryReportComponent, },
  { path: "Top10DayInMaxDistanceDayWiseAttendanceReport", component: Top10DayInMaxDistanceDayWiseAttendanceReportComponent, },
  { path: "Top10DayInMaxDistanceMonthlyWiseAttendanceReport", component: Top10DayInMaxDistanceMonthlyWiseAttendanceReportComponent, },
  { path: "Top10DayOutMaxDistanceDayWiseAttendanceReport", component: Top10DayOutMaxDistanceDayWiseAttendanceReportComponent, },
  { path: "Top10DayOutMaxDistanceMonthlyWiseAttendanceReport", component: Top10DayOutMaxDistanceMonthlyWiseAttendanceReportComponent, },
  { path: "Top10DayOutMismatchedEmpAttendanceReport", component: Top10DayOutMismatchedEmpAttendanceReportComponent, },
  { path: "BranchWiseDiscountReport", component: BranchWiseDiscountReportComponent, },
  { path: "BranchWiseSalesSummary", component: BranchWiseSalesSummaryComponent, },
  { path: "ModelWiseDailyDetailedReport", component: ModelWiseDailyDetailedReportComponent, },
  { path: "ModelWiseSummaryReport", component: ModelWiseSummaryReportComponent, },
  { path: "SalesExecutiveWiseDetailedReport", component: SalesExecutiveWiseDetailedReportComponent, },
  { path: "SalesExecutiveWiseModelWiseSummaryReport", component: SalesExecutiveWiseModelWiseSummaryReportComponent, },
  { path: "SalesExecutiveWiseSummaryReport", component: SalesExecutiveWiseSummaryReportComponent, },
  { path: "Schedule", component: ScheduleMasterComponent },

  { path: "sotrate", component: NewincentiveComponent },
  { path: "ewincentiveMapping", component: FinanceMappingComponent },
  { path: "areamaster", component: AreaTableComponent },
  { path: "salesheadsummaryreport", component: SalesHeadSummaryReportComponent },
  { path: "salesexemodaldirectreport", component: SalesExemodalDsReportComponent },
  { path: "salesexemodelinsurancereport", component: SalesExemodalInsuranceReportComponent },
  { path: "backAreaEmp", component: BackofficeareaempComponent },
  { path: "backArea", component: BackOfficeAreaReortComponent },
  { path: "incentiveReports", component: IncentiveReportComponent },
  { path: "salesSOT", component: SalesExecutiveSOTreportComponent },
  { path: "salesEW", component: SalesExecutiveEWComponent },
  { path: "branchwiseSummary", component: BranchWiseSummaryReportComponent },
  { path: "SOTSalesSummary", component: SOTSalesSummaryReportComponent },
  { path: "EWSalesSummary", component: EWSalesSummaryReportComponent },
  { path: "brancharea", component: BranchesallComponent },
  { path: "accountassist", component: MemoAssAccComponent },
  { path: "hpromisememo", component: HPROMISEComponent },
  { path: "vinmemo", component: VINmemoComponent },
  { path: "servicesub", component: ServiceslubdepartlistComponent },
  { path: "Databasetable", component: DatabasetablemasterlistComponent },
  { path: "leadCategory", component: ListleadCategoryMasterComponent },
  { path: "empleavepolicy", component: EmpLeavePolicyMaptableComponent },


  { path: "leavepolicy", component: LeavePolicyTableComponent },
  // { path: "leaveTable", component: MasterTableComponent },
  { path: "leaveadjustment", component: LeaveAdjustmentTableComponent },

  { path: 'leaveYearConfiguration', component: LeaveYearconfigurationtableComponent },
  { path: 'leaveMapping', component: EmpLeaveMappingComponent },
  { path: 'leaveTransaction', component: EmpTransactionMappingComponent },

  { path: 'leavemaster', component: MasterTableComponent },
  { path: "customermemo", component: CustomerWiseMemoDetailedReportComponent },
  { path: "memo", component: MemoDetailedReportComponent },
  { path: "memodiscount", component: MemoWiseDiscountDetailedReportComponent },
  // {path:""}

  // { path: "salesSOT", component:SalesExecutiveSOTreportComponent },
  // { path: "salesEW", component:SalesExecutiveEWComponent },
  { path: "quotationDetailedReport", component: QuotationDetailedReportComponent },


  { path: "ManageInsurance", component: InsuranceListComponent },
  { path: "ModelWiseDailyDetailedReport", component: ModelWiseDailyDetailedReportComponent, },
  { path: "ModelWiseSummaryReport", component: ModelWiseSummaryReportComponent, },
  // { path: "SalesExecutiveWiseDetailedReport", component: SalesExecutiveWiseDetailedReportComponent, },
  { path: "SalesExecutiveWiseModelWiseSummaryReport", component: SalesExecutiveWiseModelWiseSummaryReportComponent, },
  { path: "salesQuotationReport", component: SalesExecutiveWiseDetailedQuotationReportComponent },
  { path: "salesDiscountReport", component: SalesExecutiveWiseDetailedDiscountReportComponent },
  { path: "salesMemoReport", component: SalesExecutiveWiseDetailedMemoReportComponent },
  { path: "salesInvoiceReport", component: SalesExecutiveWiseDetailedInvoiceReportComponent },
  { path: 'activityMaster', component: ActivityMasterTableComponent },
  { path: 'activitytype', component: ActivityTypeListComponent },
  { path: 'actionMaster', component: ActionMasterTableComponent },

  { path: "mapindata", component: ImportmasterComponent },
  { path: "SMRMaster", component: SmrmasterComponent },
  { path: "insuranceMaster", component: InsuranceComponent },
  // { path: "PSFMaster", component: PsfmasterComponent },
  { path: "ExtentedWarrentyMaster", component: ExtendwarrentyComponent },
  { path: "Callcenterexecutive", component: CallcenterexecutivetableComponent },
  { path: "CCEassignself", component: CCEassignselfComponent },
  // CCEassignselfComponent
  // { path: "sales_Dashboard", component: SalesDashboardComponent },
  { path: "sales_Dashboard", component: SalesDashboardComponent },
  { path: "hr_Dashboard", component: HRDashboardComponent },
  { path: "acsview", component: AcsnewviewComponent },
  { path: 'appointmentreport', component: ApptlisttreportComponent },
  { path: 'CallingSummary', component: CallingSummaryReportComponent },
  { path: 'kpiReport', component: KpiReportComponent },

  { path: 'dailyreportcomponent', component: DailyTargetReportComponent },
  // new reports 24


  { path: "Salewisedetaileddiscountreport", component: SalewisedetaileddiscountreportComponent },
  { path: "Salesheadwisediscountsummaryreport", component: SalesheadwisediscountsummaryreportComponent },
  { path: "Salesexecutivewiseinhouseinsurancesummaryreport", component: SalesexecutivewiseinhouseinsurancesummaryreportComponent },
  { path: "Modelwisesummary1", component: ModelwisesummaryComponent },
  { path: "Modelwisequotationdetails", component: ModelwisequotationdetailsComponent },
  { path: "Custwisediscountdetail", component: CustwisediscountdetailComponent },
  { path: "Custwisedetail", component: CustwisedetailComponent },
  { path: "Customerwiseinvoice1", component: CustomerwiseinvoicedetailedreportComponent },
  { path: "Customerwisefinance", component: CustomerwisefinancereportComponent },
  { path: "Branchwiseinhousefinancedetailedreport", component: BranchwiseinhousefinancedetailedreportComponent },
  { path: "branchwiseadvancecollect", component: BranchwiseadvancecollectedreportComponent },
  { path: "bankwisememosummary", component: BankwisememosummaryreportComponent },
  { path: "CustomerwiseInsurancereport", component: CustomerwiseInsurancereportComponent },
  { path: "BankWiseSMWiseFinanceReport", component: BankWiseSMWiseFinanceReportComponent },
  { path: 'generalManagerDiscount', component: GeneralManagerWiseDetailedDiscountReportComponent },
  { path: 'generalManagerDiscountSummary', component: GeneralManagerWiseDiscountSummaryReportComponent },

  { path: 'salesExecutiveAdvance', component: SalesExecutiveWiseAdvanceDetailedReportComponent },
  { path: 'directorSummaryReport', component: DirectorWiseDiscountSummaryReportComponent },

  { path: 'branchInhouseInsurance', component: BranchWiseInhouseInsuraceSummaryReportComponent },
  { path: 'branchOuthouseInsurance', component: BranchWiseOuthouseInsuraceSummaryReportComponent },
  { path: 'branchAdvanceSummary', component: BranchWiseAdvanceSummaryReportComponent },

  { path: 'branchSalesAdvance', component: BranchWiseSalesExecutiveWiseAdvanceDetailedReportComponent },
  { path: 'branchFinance', component: BranchWiseFinanceSummaryReportComponent },
  { path: 'branchDiscount', component: BranchWiseDiscountSummaryReportComponent },

  { path: 'salesInhouse', component: SalesExecutiveWiseInhouseInsuraceDetailedReportComponent },
  { path: 'salesOuthouse', component: SalesExecutiveWiseOuthouseInsuraceDetailedReportComponent },

  { path: 'InsuranceWiseInhouse', component: SalesExecutiveWiseInsuranceCompanyWiseInhouseInsuranceSummaryReportComponent },
  { path: 'InsuranceWiseOuthouse', component: SalesExecutiveWiseInsuranceCompanyWiseOuthouseInsuranceSummaryReportComponent },

  { path: 'salesFinanceInhouse', component: SalesExecutiveWiseFinanceWiseInhouseDetailedReportComponent },
  { path: 'salesFinanceOuthouse', component: SalesExecutiveWiseFinanceWiseOuthouseDetailedReportComponent },

  { path: 'salesBankInhouse', component: SalesExecutiveWiseBankWiseInhouseFinanceSummarydReportComponent },
  { path: 'salesBankOuthouse', component: SalesExecutiveWiseBankWiseOuthouseFinanceSummarydReportComponent },

  { path: 'salesInhouseSummary', component: SalesExecutiveWiseInhouseSummaryReportComponent },
  { path: 'salesOuthouseSummary', component: SalesExecutiveWiseOuthouseSummaryReportComponent },

  { path: 'salesRSADetailed', component: SalesExecutiveWiseRSADetailedReportComponent },
  { path: 'modelwiseReport', component: NewModelWiseSummaryReportComponent },

  { path: 'salesManagerModel', component: SalesManagerWiseSalesExeModelWiseSummaryReportComponent },

  { path: 'salesManagerInsurance', component: SalesManagerWiseInsuranceWiseSummaryReportComponent },
  { path: 'salesManagerFinance', component: SalesManagerWiseFinanceWiseSummaryReportComponent },

  { path: "Insurancecompanywisesummaryreport1", component: InsuranceCompanyWiseSmWiseInsuranceReportComponent },

  { path: "InsuranceCompanyWiseSmWiseInsuranceOuthouseReport", component: InsuranceCompanyWiseSmWiseInsuranceOuthouseReportComponent },

  { path: 'bankSalesManagerFinance', component: BankWiseSalesManagerWiseFinanceInhouseSummaryReportComponent },

  { path: 'discountDetailed', component: DiscountDetailedReportComponent },

  { path: 'shift', component: ShiftmastersComponent },

  { path: 'MonthlyLeavereport', component: MonthlyLeavereportComponent },

  { path: 'DetailedReportsComponent', component: DetailedReportsComponent },

  { path: "Branchwisedetailedinvoice", component: BranchwisedetailedinvoiceComponent },
  { path: "Branchwisemodelwisesalesreport", component: BranchwisemodelwisesalesreportComponent },
  { path: "CustomerwiseRSAdetailedreport", component: CustomerwiseRSAdetailedreportComponent },
  { path: "Customerwisequotationdetailedreport", component: CustomerwisequotationdetailedreportComponent },
  // { path: "Modelwisequotationdetails", component: ModelwisequotationdetailsComponent }, 
  { path: "Modelwisequotationsummary", component: ModelwisequotationsummaryComponent },
  { path: "Modelwisevarientdetailedreport", component: ModelwisevarientdetailedreportComponent },
  { path: "Financebankwisesummaryreport", component: FinancebankwisesummaryreportComponent },
  { path: "Insurancecompanywisesummaryreport", component: InsurancecompanywisesummaryreportComponent },
  { path: "Salesmanagerwisesalesexecutivesummaryreport", component: SalesmanagerwisesalesexecutivesummaryreportComponent },
  { path: "SalesManagerWiseBranchWiseReport", component: SalesManagerWiseBranchWiseReportComponent },
  { path: "SalesManagerWiseSalesExecutiveWiseReport", component: SalesManagerWiseSalesExecutiveWiseReportComponent },

  { path: "Modelwisepricelist", component: ModelwisepricelistComponent },
  { path: "SalesexecutivewiseRSAsummaryreport", component: SalesexecutivewiseRSAsummaryreportComponent },

  { path: "approveshortleavebyhead", component: HeadapproveshortleaveComponent },
  { path: "shortleave", component: ShortleavetableComponent },
  { path: "payslip", component: PayslipComponent },
  // { path: "salaryslip", component: SalaryComponent }
  { path: "Salesreports", component: SalesreportComponent },
  { path: "ModelWiseReportList", component: ModelWiseReportComponent },

  { path: "IncentiveReportsList", component: IncentiveReportsComponent },

  { path: "SalesHeadList", component: SalesHeadReportComponent },

  { path: "SalesManagerList", component: SalesManageReportComponent },

  { path: "CustomerWiseList", component: CustomerWiseReportComponent },

  { path: "BankWiseList", component: BankWiseReportComponent },

  { path: "MemoWiseReport", component: MemoWiseReportComponent },

  { path: "GeneralManagerWise", component: GeneralManagerWiseReportComponent },

  { path: "Discountreport", component: DiscountReportComponent },

  { path: "BranchWiseReport", component: BranchWiseComponent },

  { path: 'InhouseInsurance', component: InhouseInsuranceReportComponent },

  { path: 'OuthouseInsurance', component: OuthouseInsuranceReportComponent },

  { path: 'BranchWiseMemoPendancy', component: BranchWiseMemoPendancyReportComponent },

  { path: 'BranchWiseMostDiscountApproval', component: BranchWiseMostDiscountApprovalReportComponent },

  { path: 'BranchWiseDiscountPendancy', component: BranchWiseDiscountPendancyReportComponent },


  { path: "MontlyModelWiseInvoiceGenerated", component: MontlymodelwiseinvoicegeneratedComponent },

  { path: "MontlyBranchWiseModelWiseInvoiceGenerated", component: MontlybranchwisemodelwiseinvoicegeneratedComponent },

  { path: "InhouseFinanceReport", component: InhouseFinanceReportComponent },

  { path: "OuthouseFinanceReport", component: OuthouseFinanceReportComponent },

  { path: "SalesExecutiveWiseQuotationGeneratedTodayReport", component: SalesExecutiveWiseQuotationGeneratedTodayReportComponent },

  { path: "leavebalance", component: LeaveBalancereportComponent },

  { path: "LeaveTransactionDetails", component: LeaveTransactionDetailesreportComponent },

  { path: "BranchwisequotationdetailsComponent", component: BranchwisequotationdetailsComponent },

  { path: "BranchWiseFinanceDetailedReportComponent", component: BranchWiseFinanceDetailedReportComponent },

  { path: "LeaveTransactionSummaryReportComponent", component: LeaveTransactionSummaryReportComponent },

  { path: "FinanceuireportComponent", component: FinanceuireportComponent },


  { path: "InsurancereportComponent", component: InsurancereportComponent },
  // 

  { path: 'TalukaWiseSales', component: TalukaWiseSalesSummryComponent },

  { path: 'DistrictWiseSales', component: DistrictWiseSalesSummryComponent },
  // { path: "Quotation", component: QuotationComponent },
  // { path: "Quotation1", component: Quotation1Component },
  // { path: "Quotation2", component: Quotation2Component },
  { path: "Quotation3", component: Quotation3Component },
  { path: 'retensionsummaryreport', component: RetensionSummaryReportComponent },
  { path: 'appointmentSummaryReport', component: AppointmentSummaryReportComponent },
  { path: 'allreport', component: AllReportsComponent },
  { path: 'Serviceadviser', component: ServiceadviserComponent },
  { path: 'Newcustomers', component: NewcustomersComponent },
  { path: 'tourmaster', component: ToursComponent },
  { path: 'tourmasterAC', component: TourACComponent },
  { path: 'tourmasterHR', component: TourHRComponent },

  { path: 'tourDestinationMaster', component: TourDestinationMasterComponent },
  { path: 'tourModeMaster', component: TourModeMasterComponent },
  { path: 'tourClassMaster', component: TourClassMasterComponent },
  { path: 'designationcitymap', component: AmountmapComponent },
  { path: 'tourExpense', component: TourExpenseMasterComponent },

  { path: 'tourHR', component: TourExpenseHRComponent },

  { path: 'tourGM', component: TourExpenseGMComponent },

  { path: 'tourAccountant', component: TourExpenseACComponent },


  { path: 'tourHead', component: TourExpenseHeadComponent },

  { path: 'InactiveEmp', component: InactiveEmpReportComponent },
  { path: 'Hmilcce', component: HmilcceComponent },
  { path: 'Hmiladmin', component: HmiladminComponent },
  { path: 'retensionsummary2', component: RetensionSummaryreport2Component },

  // { path: 'retensionsummary2', component: RetensionSummaryreport2Component },


  { path: 'ProductModelReport', component: ProductModelReportComponent },

  { path: 'prodtFuelSalesReport', component: ProductFuelReportComponent },

  { path: 'prodtTransmissionSalesReport', component: ProductTransmissionReportComponent },

  { path: 'ProductVarientReport', component: ProductVarientWiseReportComponent },

  { path: 'DailyVehicleIn', component: DailyVehicleInComponent },


  { path: 'alliedInsuranceReport', component: AlliedSInsuranceSalesReportComponent },

  { path: 'alliedEWReport', component: AlliedEWSalesReportComponent },

  { path: 'alliedSOTReport', component: AlliedSOTSalesReportComponent },

  { path: 'alliedAccessoriesReport', component: AlliedACCESSORIESSalesReportComponent },

  { path: 'DiscountSalesReport', component: ServiceDiscountSalesReportComponent },

  { path: 'CancellationSummaryReport', component: CancellationSummaryReportComponent },

  { path: 'modificationSummaryReport', component: ModificationSummaryreportComponent },

  { path: 'exchangeSalesReport', component: ExchangeSalesReportComponent },

  { path: 'detailedCancellationReport', component: DetailesCancellationReportComponent },

  { path: 'detailedModificationReport', component: DetailedModificationReportComponent },

  { path: 'corporateSalesReport', component: CorporateSalesReportComponent },

  { path: 'DetailedNewReport', component: DetailedNewReportComponent },

  { path: 'calldeatailedreport', component: CallsdestailedreportComponent },

  { path: "psfmaster", component: PsfleadstableComponent },

  { path: 'importpsf', component: PsfmasterComponent },

  // { path: 'calldeatailedreport', component: CallsdestailedreportComponent },

  { path: 'psfdessleads', component: DessatisfiedleadstableComponent },

  { path: 'forlog', component: ForlogstableComponent },

  { path: 'Attendanceadjustment', component: AttendanceadjustmentreportComponent },

  { path: 'psfsatis', component: PsfsatisleadComponent },

  { path: 'salesmanamemo', component: SMmemoformComponent },

  { path: "Quotation4", component: Quotation44Component },


  { path: "FeedbackAreas", component: FeedbackAreasComponent },

  { path: "Feedbackforms", component: FeedbackformsComponent },

  { path: "Feedbackapprovereje", component: FeedbackformapprovesComponent },

  { path: "employeeCriteria", component: EmployeeCriteriaReportComponent },

  { path: 'financeallocation', component: FinanceincentiveallocationComponent },

  { path: 'financeallocationsummary', component: FinanceincentiveallocationsummaryComponent },

  { path: "UserWiseFeedback", component: UserwiseFeedBackComplaintReportComponent },

  { path: "ResolverWiseFeedback", component: ResolverwiseFeedBackComplaintSummaryReportComponent },

  { path: "UserWiseDetailed", component: UserWiseDetailedReportComponent },

  { path: "BranchWiseFeedback", component: BranchWiseFeedbackReportComponent },


  { path: 'tourreport', component: TourreportComponent },

  { path: 'employeeallocation', component: EmployeeevaluationreportComponent },

  { path: "LeaveSummarys", component: LeaveSummarysReportComponent },

  { path: 'AsonDate', component: AsOnDateBalanceReportComponent },

  { path: 'Leavetransactionreport', component: LeaveTransactionDetailedReportComponent },

  { path: 'ScAndTlIncentive', component: ScAndTlIncentiveComponent },

  { path: "dailyDSI", component: DailyDSIReportReportComponent },

  { path: "SMRIncentive", component: SMRIncentiveReportComponent },

  { path: "leadAptTransfer", component: LeadAptTransferReportComponent },
  // 
 { path: "Feedbackapproveregm", component: FeedBackGMApproveTableComponent },

 
 { path: "psfcomplaintdetails", component: PSFComplaintDetailesComponent },
  
 { path: "sawisepsfreport", component: SAWisePSFReportComponent },
 // 
  // HmilcceComponent


  // NewcustomersComponent
  // 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }