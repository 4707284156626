import { DatePipe, getLocaleFirstDayOfWeek } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRCOLLAPSEMASTER } from 'src/app/Models/smrcollapsemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';


@Component({
  selector: 'app-cceassignself',
  templateUrl: './cceassignself.component.html',
  styleUrls: ['./cceassignself.component.css']
})
export class CCEassignselfComponent implements OnInit {
  servicedue
  formTitle = "SMR Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  filterQuery1: string = "";
  filterQuery2:string =""
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NUMBER", "Sequence Number"], ["BOOKING_DATE", "Booking Date"], ["SERVICE_ADVISOR_NAME", "Service Advisor Name"], ["BT_NUMBER", "BT Number"], ["BT_DATE_TIME", "BT Date Time"],
  ["CUSTOMER_NAME", "Customer Name"], ["BOOKING_CONTACT_NUMBER", "Booking Contact Number"], ["MOBILE_NUMBER", "Mobile Number"], ["REGISTRATION_NO", "Registration No"], ["VIN", "VIN"], ["WORK_TYPE", "Work Type"], ["EXPRESS_CARE", "Express Care"], ["CALLING_DATE", "Calling Date"], ["CALLING_STATUS", "Calling Status"], ["REASON", "Reason"],
  ["SME_EMPLOYEE_ID", "SME Employee ID"], ["BILL_NUMBER", "Bill Number"], ["BILL_AMOUNT", "Bill Amount"], ["BILL_DATE", "Bill Date"], ["MODEL", "Model"], ["REMARK", "Remark"], ["REMINDER_DATE", "Reminder Date"], ["VEDIO_CALL", "Video Call"], ["ONLINE_STREAMING", "Online Streaming"],
  ["FOLLOWUP_NO", "Followup No."], ["CALL_DATE", "Call Date"], ["SERVICE_COUNT", "Service Count"], ["CUST_NO", "Customer Number"], ["SPECIAL_MESSAGE", "Special Message"], ["RO_DATE", "Ro Date"],
  ["RO_NUMBER", "Ro Number"], ["NUMBER_OF_CALLS", "Number Of Calls"], ["LIVE_STREAMING", "Live Streaming"], ["STATUS", "Status"], ["DELIVERY_DATE", "Delivery Date"], ["LOCATION", "Location"], ["PIN_CODE", "Pincode"], ["VARIANT", "Varient"], ["CHASSIS_NUMBER", "Chassis Number"], ["SALES_CONSULTANT_NAME", "Sale Consultant Name"], ["SALE_DATE", "Sale Date"], ["LAST_SERVICE_TYPE", "Last Service type"], ["LAST_RO_DATE", "Last Ro Date"], ["SEGMENT", "Segment"], ["LAST_RO_WORKSHOP", "Last Ro Workshop"], ["LAST_RO_DEALER_NAME", "Last Ro Dealer Name"], ["LAST_RO_REGION", "Last Ro region"], ["NAME", "Name"], ["WORK_PHONE_NUMBER", "Work Phone Number"], ["ROOT", "Root"], ["SME_NAME", "SME Name"], ["PICK_UP_AND_DROP", "Pick Up and Drop"], ["LOST_CUSTOMER_NAME", "Lost Customer Name"], ["SRV_MODEL", "SRV Model"], ["EMAIL", "Email"], ["ADDRESS", "Address"], ["PURCHASE_DATE", "Purchase Date"], ["PREFERED_DLR_NO", "Preferred DLR No."], ["OFFICE_NUMBER", "Office Number"], ["RESIDENCE_NUMBER", "Resindence Number"], ["MILEAGE", "Mileage"], ["FIELD1", "Field 1"], ["FIELD2", "Field 2"], ["FIELD3", "Field 3"], ["FIELD4", "Field 4"], ["FIELD5", "Field 5"], ["FIELD6", "Field 6"], ["FIELD7", "Field 7"], ["FIELD8", "Field 8"], ["FIELD9", "Field 9"], ["FIELD10", "Field 10"]]
  SMRdrawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  isSMRLead: boolean = true
  // SMRdrawerVisible!: boolean; 
  // dataList:any[]=[]
  SHEET_ID
  index: number = 0;
  isClosed = false;
  confrmation: string = 'default'
  noshow: string = 'default'
  midterm: string = 'default'
  servicedone: string = 'default'
  salesdata: string = 'default'
  amslost: string = 'default'
  amslost1: string = 'default'
  oneyear:string ='default'
  appoint:string='default'

  collapseDrawerVisible: boolean = false;
  drawerData1: SMRCOLLAPSEMASTER = new SMRCOLLAPSEMASTER();
  drawerTitle1: string;
  drawerVisible1: boolean;
  drawerData2: ActivityLeadMaster = new ActivityLeadMaster();

  active1: boolean = false
  value1:any;
  active2: boolean = false
  active3: boolean = false
  active4: boolean = false
  active5: boolean = false
  active6: boolean = false
  active7: boolean = false
  active8: boolean = false

  employee = []
  CCE_ID
  Counts

  constructor(private _exportService: ExportService,private cookie: CookieService, private api: ApiService, public datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    // this.search();
    this.getEmployee();
    this.getAllcount(this.userId)
    this.CCEEE()
    
  }
  getAllcount(event) {
    this.api.getAllSubcount1(0,0,'','',' AND CCE_ID = '+ event).subscribe(data => {
      this.Counts = data['CategoryCounts'][0]
      // console.log(this.Counts,"counts");
      
    })
  }
  exportLoading: boolean = false;

  importInExcel() {
    this.search1(this.value12,true);
  }
 
  // getEmployee() {
  //   this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
  //     this.employee = data['data']
  //   })
  // }
  userId = Number(this.cookie.get("userId"));
  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND EMPLOYEE_ID ='+ this.userId + ' AND ROLE_ID = 43').subscribe(data => {
      if(data['code']==200){
        this.employee = data['data']
        this.CCE_IDS = data['data'][0]['EMPLOYEE_ID'] 
        // this.CCEEE(this.CCE_IDS)
      }
     
    })
  }

  Counts1:any
  // CCEEE(event){
  //   this.getAllcount(event)
  //   this.api.getAllSubc(0,0,'','','',event).subscribe(data => {
  //     this.Counts1 = data['TOTAL_LEADS']
  //     console.log(this.Counts,"counts");
      
  //   })
  // }
  get closeCallback() {
    // this.index=0;
    return this.drawerClose.bind(this);
  }
  // Counts1:any
  CCEEE(){
    // 
    this.api.getAllSubc(0,0,'','','',this.userId).subscribe(data => {
      this.Counts1 = data['TOTAL_LEADS']
      // console.log(this.Counts,"counts");
      
    })
  }

  MapCCE() {
    if (this.CCE_IDS != undefined && this.array.length > 0) {
      this.api.MapCCE(this.CCE_IDS, this.array,this.value12,this.userid)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("CCE Mapped Successfully ...", "");
            this.drawerClose();
            // this.isSpinning = false;
            this.array = []
            // this.checked = false
            this.CCE_IDS = null
            this.dataList.forEach(item => {
              this.isChecked = this.value1
              if (this.value1) {
                if (this.value1 == true) {
                  this.checked = false
                  this.checked1 = false
                  this.indeterminate = false
                }
                // console.log(this.array);
                // item.IS_SELECTED = 1
              } else {
                // item.IS_SELECTED = 0
                this.array = []
                this.checked1 = false
                this.indeterminate = true
              }
        
            });
          }
          else {
            this.message.error("CCE Mapping Failed...", "");
            this.array = []
            // this.isSpinning = false;
          }
        });
    } else {
      this.message.error("Please Select Leads & Call Center Executive", "");
    }
  }
  CATEGORY_STATUSSS
  CCE_IDS
  TransferCCE() {
    if (this.CATEGORY_STATUSSS != undefined && this.array.length > 0) {
      this.api.TransferCCE(this.CATEGORY_STATUSSS, this.array)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Lead Transfered Successfully ...", "");
            this.drawerClose();
            this.array = []
            this.CATEGORY_STATUSSS = null
            // this.isSpinning = false;
            this.dataList.forEach(item => {
              this.isChecked = this.value1
              if (this.value1) {
                if (this.value1 == true) {
                  this.checked = false
                  this.checked1 = false
                  this.indeterminate = false
                }
                // console.log(this.array);
                // item.IS_SELECTED = 1
              } else {
                // item.IS_SELECTED = 0
                this.array = []
                this.checked1 = false
                this.indeterminate = true
              }
        
            });

          }
          else {
            this.message.error("Lead Transfer Failed...", "");
            // this.isSpinning = false;
            this.array = []
          }
        });
    } else {
      this.message.error("Please Select Leads & Category Status", "");
    }

  }

  drawerClose(): void {
    this.search1(this.value12,'');
    // this.getAllcount()
    this.SMRdrawerVisible = false;
    // this.isClosed=true
    this.active1 = false
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
    // this.index=0;
  }

  confrmations(event: any) {
    this.confrmation = 'primary'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'default'
  }
  noshows(event: any) {

    this.confrmation = 'default'
    this.noshow = 'primary'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'default'
  }
  midterms(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'primary'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'default'
  }

  servicedones(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'primary'
    this.salesdata = 'default'
    this.amslost = 'default'
  }

  salesdatas(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'primary'
    this.amslost = 'default'
  }

  amslosts(event: any) {
    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.amslost = 'primary'
  }




  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }
  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  drawerClose1(): void {
    this.search1(this.value12,'C');
    this.collapseDrawerVisible = false;
    // this.isClosed=true
    this.active1 = false
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
    // this.index=0;
  }
  filterClass: string = "filter-invisible";
  startValue: any = new Date()
  endValue: any = new Date()
  reminderDate
  STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  // showFilter(): void {
  //   if (this.filterClass === "filter-visible")
  //     this.filterClass = "filter-invisible";
  //   else
  //     this.filterClass = "filter-visible";
  // }
  endOpen = false;
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue.getTime();
  };
  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  // filterQuery1

  applyFilter() {
    this.ok=true
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.reminderDate = this.datePipe.transform(this.reminderDate, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    this.filterQuery1 = '';
    this.filterQuery2='';


    if (this.CATEGORY_STATUS != undefined) {
      this.filterQuery += ' AND CATEGORY_STATUS = ' + "'" + this.CATEGORY_STATUS + "'"
    } else {
      this.filterQuery = '';
    }
    if (this.reminderDate != undefined) {
      this.filterQuery1 += ' AND REMINDER_DATE = ' + "'" + this.reminderDate + "'"
    } else {
      this.filterQuery1 = '';
    }
    if (this.reminderDate != undefined) {
      this.filterQuery2 += ' AND CCE_ID = ' + "'" + this.CCE_ID + "'"
    } else {
      this.filterQuery2 = '';
    }
    if (this.startValue != null) {
      this.STARTDATE = this.startValue
    }
    if (this.endValue != null) {
      this.ENDDATE = this.endValue
    }

    this.search1(this.value12,"",false);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    // this.BRANCH_ID = []
    // this.SALES_CONSULTANT_ID = ''
    this.CATEGORY_STATUS = null
    this.filterQuery = '';
    this.filterQuery1 = '';
    this.filterQuery2=''
    this.CCE_ID=null
    this.reminderDate = ''
    this.startValue = new Date()
    this.endValue = new Date()
    this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.search()
  }



  checked = false;
  checked1 = false;
  checkedd = true;
  indeterminate = true;
  indeterminate1 = false;
  setOfCheckedId = new Set<number>();
  setOfCheckedId1 = new Set<number>();
  array = []
  code:any
  isChecked: boolean
  disable11=false
  onChecked1(id: number, checked: boolean): void {

    
    // this.code = value
    if (checked) {
      // console.log(checked);
      this.isChecked = checked
      this.array.push(id)
      this.disable11=true
      // console.log(this.array);
      this.indeterminate = false
      // this.dataList[ind].IS_SELECTED = 1
    } else {
      // this.dataList[ind].IS_SELECTED = 0
      this.array.pop()
      this.disable11=false
      // console.log(this.array);
      this.indeterminate = true

    }

  }

  onAllChecked1(value: boolean): void {
    // console.log(value);
    this.dataList.forEach(item => {
      this.isChecked = value

      // console.log(this.isChecked,"this.isChecked")
      if (value) {
        if (value == true) {
          this.array.push(item.ID)
          this.checked1 = true
          this.value1=value
          // this.checked=false
          this.indeterminate = false
        }
        // console.log(this.array);
        // item.IS_SELECTED = 1
      } else {
        // item.IS_SELECTED = 0
        this.array = []
        this.checked1 = false
        this.indeterminate = true
      }

    });

  }

value

  drawerClose2(): void { this.drawerVisible1 = false; }
  datalist = []
  Activity(dataa) {
    this.drawerTitle1 = "View Activities";
    this.drawerVisible1 = true;
    this.drawerData2 = Object.assign({}, dataa);
    this.drawerData2.ACTIVITY_TYPE_ID = 2
    this.drawerData2.CALLING_DATE_TIME = new Date()
    this.api.getAllActivityleadMaster(0, 0, '', 'desc', ' AND CUSTOMER_ID = ' + dataa.ID).subscribe(
      data => {
        if (data['code'] == 200) {
          this.datalist = data['data']
          // console.log(this.datalist);
        }
      }, err => {
        // console.log(err);
      });
  }
  // this.drawerData1 = new ActivityTypeMaster();}
  edit(data: SMRCOLLAPSEMASTER): void {
    this.drawerTitle = " Forms ";
    this.drawerData1 = Object.assign({}, data);
    // this.drawerData1=Object.assign({},data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    // this.SMRdrawerVisible = true;
    this.collapseDrawerVisible = true;
  }

 

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search1(this.value12,true);
  }

  keyup(event: any) {
    this.search();

  }
  YEAR_COUNT: number
  eleven_TO_fourteen_MONTHS_COUNT: number
  fourteen_TO_twentyfour_MONTHS_COUNT: number
  twentyfour_MONTHS_COUNT: number
  AMS_LOST_COUNT: number
  COINFIRMATION_COUNT: number
  MID_TERM_COUNT: number
  NO_SHOW_COUNT: number
  SALES_DATA_COUNT: number
  SERVICE_DONE_COUNT: number
  SERVICE_DUE_COUNT: number

  CATEGORY_STATUS

  search(reset: boolean = false,) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }
   
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + this.filterQuery1, this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200 )
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
  
  
      }, err => {
        // console.log(err);
      });
     
   
  }

  // filter() {
  
  //   // this.getAllcount()

  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";

  //   } catch (error) {
  //     sort = "";
  //   }
 
 
  // if (this.value == "WD") {
  //   this.filterQuery = ` AND CATEGORY_STATUS = 'WD' AND CCE_ID = ${this.cookie.get('userId')}  AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}'`
  // }

  //   this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort,  this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
  //     if (data['code'] == 200)
  //       this.loadingRecords = false;
  //     this.totalRecords = data['count'];
  //     this.dataList = data['data'];


  //   }, err => {
  //     console.log(err);
  //   });
  // }
  value12:any
  value13:any
  disable1:boolean=false
  cancel(): void {
    // this.nzMessageService.info('click cancel');
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }
  untoch1: string = 'default'
  reminder1: string = 'default'
  notconnected1: string = 'default'
  workdone1: string = 'default'
  lost1: string = 'default'
  appoint1: string = 'default'
  own: string = 'default'
  subcount12: any
  filterClass3: string = "filter-invisible";
  userid = this.cookie.get('userId')
  value11
  showFilter3(Value: any, VALUE) {

    // this.own = 'primary'

    this.confrmation = 'default'
    this.noshow = 'default'
    this.midterm = 'default'
    this.servicedone = 'default'
    this.salesdata = 'default'
    this.oneyear = 'default'
    this.appoint = 'default'
    this.amslost = 'default'
    this.own = 'primary'


    this.value11 = Value


    this.api.getOwnCalling1(this.CCE_IDS, '').subscribe(data => {
      if (data['code'] == 200) {
        this.subcount12 = data['CategoryCounts'][0]
        this.value = Value
        // console.log("counts");
      }
      else {
        this.message.error("Failed To get Count", "")
      }


    })

    if (this.filterClass3 === "filter-visible") {
      this.filterClass3 = "filter-invisible";


    }


    else {
      this.filterClass3 = "filter-visible";


    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    if (this.searchText != "") {
      likeQuery = "  AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    // console.log(exportInExcel, "true ");


    this.filterQuery = ` AND CALLING_STATUS IN ('-') AND CATEGORY_STATUS='O' AND CCE_ID ='${this.CCE_IDS}'`


    this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
      if (data['code'] == 200)
        this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];


      // console.log(this.dataList, "this.dataList12434")


    }, err => {
      console.log(err);
    });


  }
  ok:boolean=false
  search1(value:any,VALUE,exportInExcel: boolean = false) {
    // if (reset) {
    //   this.pageIndex = 1;
    //   this.sortKey = "id";
    //   this.sortValue = "desc"
    // }

    if( this.searchText != ''){
      this.checked1 = false
      this.array = []
      console.log(this.checked1,"this.checked1");
    }
      
    if (value == 'MT') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'primary'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.amslost1 = 'default'
      this.disable1 = true
      this.filterClass3 = "filter-invisible";
      this.value11 = ''
      this.servicedue = 'default'

    } else if (value == 'OY') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.amslost1 = 'default'
      this.amslost = 'default'
      this.oneyear = 'primary'
      this.appoint = 'default'
      this.filterClass3 = "filter-invisible";
      this.disable1 = true
      this.servicedue = 'default'
      this.value11 = ''
    } else if (value == 'WD') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'primary'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.amslost1 = 'default'
      this.disable1 = true
      this.value11 = ''
      this.filterClass3 = "filter-invisible";
      this.servicedue = 'default'
    }

    else if (value == 'SD') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'primary'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost1 = 'default'
      this.amslost = 'default'
      this.own = 'default'
      this.value11 = ''
      this.servicedue = 'default'
      // this.disable1=true
      // this.filterClass3 = "filter-invisible";

    }

    else if (value == 'C') {
      this.confrmation = 'primary'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost1 = 'default'
      this.amslost = 'default'
      this.own = 'default'
      this.value11 = ''
      this.servicedue = 'default'
      this.filterClass3 = "filter-invisible";

    }

    else if (value == 'NS') {
      this.confrmation = 'default'
      this.noshow = 'primary'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost1 = 'default'
      this.amslost = 'default'
      this.own = 'default'
      this.value11 = ''
      this.servicedue = 'default'
      // this.disable1=true
      this.filterClass3 = "filter-invisible";

    }

    else if (value == 'L') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.amslost1 = 'primary'
      this.own = 'default'
      this.value11 = ''
      this.servicedue = 'default'
      // this.disable1=true
      this.filterClass3 = "filter-invisible";

    }

    else if (value == 'A') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'primary'
      this.amslost = 'default'
      this.own = 'default'
      this.value11 = ''
      this.servicedue = 'default'
      this.amslost1 = 'default'
      // this.disable1=true
      this.filterClass3 = "filter-invisible";

    }

    else if (value == 'O') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.own = 'primary'
      this.servicedue = 'default'
      this.amslost1 = 'default'
      // this.disable1=true
      // this.filterClass3 = "filter-visible";

    }

    else if (value == 'SE') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'default'
      this.own = 'default'
      this.servicedue = 'primary'
      this.amslost1 = 'default'
      // this.disable1=true
      this.value11 = ''
      // this.filterClass3 = "filter-visible";

    }

    else if (value == 'LC') {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.oneyear = 'default'
      this.appoint = 'default'
      this.amslost = 'primary'
      this.own = 'default'
      this.servicedue = 'default'
      this.amslost1 = 'default'
      // this.disable1=true
      this.value11 = ''
      // this.filterClass3 = "filter-visible";

    }
    // if(VALUE == 'C'){
    //   this.pageIndex=1
    //   this.pageSize=10
    //  }else{
      this.pageIndex=this.pageIndex
      this.pageSize=this.pageSize
    //  }

    this.value12=value
    this.value13=VALUE
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    // console.log("search text : " + this.searchText);


    if(this.ok==true){
      if (value == 'MT' || value == 'OY' || value == 'WD'){
        this.filterQuery =`AND CATEGORY_STATUS='${value}' AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}' AND CCE_ID ='${this.userId}'`
      }
      else{
        this.filterQuery =`AND CATEGORY_STATUS='${value}' AND CCE_ID ='${this.userId}'`
      }
    }
    else if (this.value11 == 'O') {
      this.pageIndex=this.pageIndex
      this.pageSize=this.pageSize
      // this.filterClass3 = "filter-invisible";
      this.filterQuery = ` AND CALLING_STATUS IN ('${VALUE}') AND CATEGORY_STATUS='O' AND CCE_ID ='${this.userId}'`

    }

    else if (value== 'L'){
      this.filterQuery =`AND CATEGORY_STATUS='L' AND CCE_ID ='${this.userId}'`
    }
    else{
      this.filterQuery =`AND CATEGORY_STATUS='${value}' AND CCE_ID ='${this.userId}'`
    }

   

   



    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }
    if (exportInExcel == false) {
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+this.filterQuery  , this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200 )
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        // console.log(this.dataList,"this.dataList123");
        

        var key = data['data'][0]['CATEGORY_STATUS']
        // var key1 = data['data'][0]['CALLING_STATUS']
  
        this.value12 = key
        // this.disable1=false
        if (key == 'CO') {
          this.confrmation = 'primary'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
        } else if (key == 'SD') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'primary'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
        } else if (key == 'NS') {
          this.confrmation = 'default'
          this.noshow = 'primary'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.amslost = 'default'
          this.appoint = 'default'
  
        } else if (key == 'MT') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'primary'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
         
          var sort: string;
        } else if (key == 'OY') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.amslost = 'default'
          this.oneyear = 'primary'
          this.appoint = 'default'
         
        } else if (key == 'WD') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'primary'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
         
        }
        else if (key == 'A') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.amslost = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'primary'
        }
  
  
      }, err => {
        // console.log(err);
      });
    }
    else{
      this.exportLoading = true
      this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+this.filterQuery , this.STARTDATE, this.ENDDATE).subscribe(data => {
        if (data['code'] == 200 )
        this.exportLoading = false;
        // this.SalesExecutiveWiseModelWiseSummary = data['data'];
        this.convertInExcel();
       
          this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];

        var key = data['data'][0]['CATEGORY_STATUS']
        // var key1 = data['data'][0]['CALLING_STATUS']
  
        this.value12 = key
      
        if (key == 'CO') {
          this.confrmation = 'primary'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
        } else if (key == 'SD') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'primary'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
        } else if (key == 'NS') {
          this.confrmation = 'default'
          this.noshow = 'primary'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.amslost = 'default'
          this.appoint = 'default'
  
        } else if (key == 'MT') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'primary'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
          var sort: string;
        } else if (key == 'OY') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.amslost = 'default'
          this.oneyear = 'primary'
          this.appoint = 'default'
        } else if (key == 'WD') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.servicedone = 'primary'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'default'
          this.amslost = 'default'
        }
        else if (key == 'A') {
          this.confrmation = 'default'
          this.noshow = 'default'
          this.midterm = 'default'
          this.amslost = 'default'
          this.servicedone = 'default'
          this.salesdata = 'default'
          this.oneyear = 'default'
          this.appoint = 'primary'
        }
  
        // console.log(this.dataList,"this.dataList2345");
  
  
      }, err => {
        // console.log(err);
      });
    }
    
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList.length; i++) {

      if(this.dataList[i]['CATEGORY_STATUS']=="A"){
        obj1['Category Status'] = "Appointment"
      }
      else if(this.dataList[i]['CATEGORY_STATUS']=="CO"){
        obj1['Category Status'] = "Confirmation"
      } else if(this.dataList[i]['CATEGORY_STATUS']=="NS"){
        obj1['Category Status'] = "No Show"
      } else if(this.dataList[i]['CATEGORY_STATUS']=="MT"){
        obj1['Category Status'] = "Mid Term"
      } else if(this.dataList[i]['CATEGORY_STATUS']=="OY"){
        obj1['Category Status'] = "One Year"
      } 
      // else if(this.dataList[i]['CATEGORY_STATUS']=="SE"){
      //   obj1['Category Status'] = "Service "
      // } 
      else if(this.dataList[i]['CATEGORY_STATUS']=="WD"){
        obj1['Category Status'] = "Work Done"
      } else if(this.dataList[i]['CATEGORY_STATUS']=="L"){
        obj1['Category Status'] = "Lost"
      } 
      // else if(this.dataList[i]['CATEGORY_STATUS']=="A"){
      //   obj1['Category Status'] = "Appointment"
      // }


      else if(this.dataList[i]['CATEGORY_STATUS']=="SD"){
        obj1['Category Status'] = "Sales Data"
      }

      
      obj1['Booking Date'] = this.dataList[i]['BOOKING_DATE'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Service Advisor Name'] = this.dataList[i]['SERVICE_ADVISER_NAME'];
      obj1['BT Number'] = this.dataList[i]['BT_NUMBER'];
      obj1['BT Date Time'] = this.dataList[i]['BT_DATE_TIME'];
      obj1['Customer Name'] = this.dataList[i]['CUSTOMER_NAME'];
      obj1['Booking Contact Number'] = this.dataList[i]['BOOKING_CONTACT_NUMBER'];
      obj1['Mobile Number'] = this.dataList[i]['MOBILE_NUMBER'];
      obj1['Registration No'] = this.dataList[i]['REGISTRATION_NO'];
      obj1['Work Type'] = this.dataList[i]['WORK_TYPE'];
      obj1['Express Care'] = this.dataList[i]['EXPRESS_CARE'];
      obj1['Calling Date'] = this.dataList[i]['CALLING_DATE'];
      obj1['Calling Status'] = this.dataList[i]['CALLING_STATUS'];
      obj1['SME Employee ID'] = this.dataList[i]['SME_EMPLOYEE_ID'];
      obj1['Bill Number'] = this.dataList[i]['BILL_NUMBER'];
      obj1['Bill Amount'] = this.dataList[i]['BILL_AMOUNT'];
      obj1['Bill Date'] = this.dataList[i]['BILL_DATE'];
      obj1['Model'] = this.dataList[i]['MODEL'];
      obj1['Remark'] = this.dataList[i]['REMARK'];
      obj1['Reminder Date'] = this.dataList[i]['REMINDER_DATE'];
      obj1['Followup No.'] = this.dataList[i]['FOLLOWUP_NO'];
      obj1['Call Date'] = this.dataList[i]['CALL_DATE'];
      obj1['Service Count'] = this.dataList[i]['SERVICE_COUNT'];
      obj1['Customer Number'] = this.dataList[i]['CUST_NO'];
      obj1['Special Message'] = this.dataList[i]['SPECIAL_MESSAGE'];
      obj1['Ro Date'] = this.dataList[i]['RO_DATE'];
      obj1['Ro Number'] = this.dataList[i]['RO_NUMBER'];
      obj1['Number Of Calls'] = this.dataList[i]['NUMBER_OF_CALLS'];
      obj1['Live Streaming'] = this.dataList[i]['LIVE_STREAMING'];
      obj1['Status'] = this.dataList[i]['STATUS'];
      obj1['Delivery Date'] = this.dataList[i]['DELIVERY_DATE'];
      obj1['Location'] = this.dataList[i]['LOCATION'];
      obj1['Pincode'] = this.dataList[i]['PIN_CODE'];
      obj1['Varient'] = this.dataList[i]['VARIANT'];
      obj1['Chassis Number'] = this.dataList[i]['CHASSIS_NUMBER'];
      obj1['Sale Consultant Name'] = this.dataList[i]['SALES_CONSULTANT_NAME'];
      obj1['Sale Date'] = this.dataList[i]['SALE_DATE'];
      obj1['Last Service type'] = this.dataList[i]['LAST_SERVICE_TYPE'];
      obj1['Last Ro Date'] = this.dataList[i]['LAST_RO_DATE'];
      obj1['Segment'] = this.dataList[i]['SEGMENT'];
      obj1['Last Ro Workshop'] = this.dataList[i]['LAST_RO_WORKSHOP'];
      obj1['Last Ro Dealer Name'] = this.dataList[i]['LAST_RO_DEALER_NAME'];
      obj1['Last Ro region'] = this.dataList[i]['LAST_RO_REGION'];
      obj1['Name'] = this.dataList[i]['NAME'];
      obj1['Work Phone Number'] = this.dataList[i]['WORK_PHONE_NUMBER'];
      obj1['Root'] = this.dataList[i]['ROOT'];
      obj1['SME Name'] = this.dataList[i]['SME_NAME'];
      obj1['Pick Up and Drop'] = this.dataList[i]['PICK_UP_AND_DROP'];
      obj1['Lost Customer Name'] = this.dataList[i]['LOST_CUSTOMER_NAME'];
      obj1['SRV Model'] = this.dataList[i]['SRV_MODEL'];
      obj1['Email'] = this.dataList[i]['EMAIL'];
      obj1['Address'] = this.dataList[i]['ADDRESS'];
      obj1['Purchase Date'] = this.dataList[i]['PURCHASE_DATE'];
      obj1['Preferred DLR No.e'] = this.dataList[i]['PREFERRED_DLR_NO'];
      obj1['Office Number'] = this.dataList[i]['OFFICE_NUMBER'];
      obj1['Resindence Number'] = this.dataList[i]['RESIDENCE_NUMBER'];
      obj1['Mileage'] = this.dataList[i]['MILEAGE'];
      obj1['Field 1'] = this.dataList[i]['FIELD1'];
      obj1['Field 2'] = this.dataList[i]['FIELD2'];
      obj1['Field 3'] = this.dataList[i]['FIELD3'];
      obj1['Field 4'] = this.dataList[i]['FIELD4'];
      obj1['Field 5'] = this.dataList[i]['FIELD5'];
      obj1['Field 6'] = this.dataList[i]['FIELD6'];
      obj1['Field 7'] = this.dataList[i]['FIELD7'];
      obj1['Field 8'] = this.dataList[i]['FIELD8'];
      obj1['Field 9'] = this.dataList[i]['FIELD9'];
      obj1['Field 10'] = this.dataList[i]['FIELD10'];
      // obj1['Resindence Number'] = this.dataList[i]['NO_OF_INVOICE_GENERATED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Service Data' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }



}
