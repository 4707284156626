// import { Component, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { TourMaster } from 'src/app/Models/tourmaster';
import { ApiService } from 'src/app/Service/api.service';
import { TourComponent } from '../tour/tour.component';
import { tourClassMaster } from 'src/app/Models/tourClass';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NgForm } from '@angular/forms';
// import { TourMasterToAdd } from 'src/app/Models/tourmastertoadd';

@Component({
  selector: 'app-tour-gm',
  templateUrl: './tour-gm.component.html',
  styleUrls: ['./tour-gm.component.css']
})
export class TourGMComponent implements OnInit {


  @Input() drawerClose: Function;
  @Input() data: TourMaster;
  @Input() drawerVisible: boolean;

  @Input() model
  @Input() tour
  @Input() alldestinations
  @Input() ID
  designationId = Number(this.cookie.get('designationId'));
  userId = Number(this.cookie.get('userId'));



  orgId = this.cookie.get('orgId');
  ROLE_ID = Number(this.cookie.get('roleId'))

  selectedRoleStatus = this.cookie.get('selectedLoginType');

  HEAD_APPROVED_DATETIME = new Date()
  HR_APPROVED_DATETIME = new Date()
  ACCOUNTANT_APPROVED_DATETIME = new Date()
  d1: any
  d2: any
  d3: any
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    //     this.getTourMaster()
    //     this.loadDataFilter()
    //     this.loadDataFilter1()
    //     this.loadDataFilter2()

    //     this.loadDataFilter3()
    // console.log(this.switchChange1(this.d1))

  }

  categories = [];
  employees = []
  data1() {
    if (this.data.HR_STATUS == "Approved") {
      this.data.HR_STATUS = "AP"
    }
    else {
      this.data.HR_STATUS = "R"
    }
  }

  changeClass(event) {
    this.api.getTourClass(0, 0, '', '', ' AND MODE_ID = ' + event).subscribe((data) => {
      if (data['code'] == 200) {
        this.tour = data['data'];
        // this.data.CLASS_ID = data['data']['ID']
      }
    })
  }

  getamount(event) {
    this.api.getAllcitydesignation(0, 0, '', '', ' AND CITY_ID = ' + event + ' AND DESIGNATION_ID = ' + this.cookie.get('designationId')).subscribe((data) => {
      if (data['code'] == 200) {
        this.tour = data['data'];
        this.data.ADVANCE_AMOUNT = data['data'][0]['AMOUNT']
      }
    })
  }

  // switchChange2(e1: boolean) {
  //   this.HR_STATUS = e1;
  //  if(this.HR_STATUS = true){
  //   this.data.HR_STATUS ="AP"

  //  }
  //  else{
  //   this.data.HR_STATUS="R"

  //  }

  // }

  // switchChange1(e1: boolean) {
  //   this.HEAD_STATUS = e1;
  //  if(this.HEAD_STATUS = true){
  //   this.data.HEAD_STATUS ="AP"

  //  }
  //  else if(this.HEAD_STATUS = false){
  //   this.data.HEAD_STATUS="R"

  //  }
  // console.log()
  // }

  // ACCOUNTANT_STATUS

  loadDataFilter() {
    this.employees = [];

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(employees => {
      if (employees['code'] == 200) {
        this.employees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  modes = []
  loadDataFilter1() {
    this.modes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.modes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  classes = []
  loadDataFilter2() {
    this.classes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.classes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  designations = []
  loadDataFilter3() {
    this.designations = [];

    this.api.getAllEmployees(0, 0, 'NAME ', 'asc', ' AND ORG_ID =' + this.orgId).subscribe(employees => {
      if (employees['code'] == 200) {
        this.designations = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  FROM_TIME: Date | null = null;
  TO_TIME: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }
  TypeDetails = []
  getTourMaster() {

    this.api.getAllTour(0, 0, '', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.TypeDetails = data['data'];

      }

    }, err => {
      console.log(err);
    });
  }
  reset(myForm: NgForm) {
    myForm.form.reset();
  }



  isSpinning = false;
  requestedData: any
  date1 = new Date()


  isApproveVisibleSH = false
  isApproveModalConfirmLoadingSH = false
  SH_REMARK = ''

  openApproveModalsahead() {
    if (this.data.HEAD_REMARK == '') {
      this.message.error("Please Enter Head Remark", "");
    } else {
      this.getsalesHierarchySH()
      this.isApproveVisibleSH = true;
    }
  }
  handleApproveCancelSH() {
    this.isApproveVisibleSH = false;
  }
  APPROVER_ID: any
  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;



    if (this.data.MODE_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Mode", "");
    } else
      if (this.data.CLASS_ID == undefined) {
        isOk = false;
        this.message.error("Please Select Class", "");
      } else

        if (this.data.ADVANCE_AMOUNT == undefined) {
          isOk = false;
          this.message.error("Please Enter Advance Amount", "");
        }
        else if (this.data.EXPECTED_AMOUNT == undefined) {
          isOk = false;
          this.message.error("Please Enter Expected Amount", "");
        }
        else
          if (this.data.FROM_DATE == undefined) {
            isOk = false;
            this.message.error('Please Select From Date', '');
          } else
            if (this.data.TO_DATE == undefined) {
              isOk = false;
              this.message.error('Please Select To Date', '');
            } else
              if (this.data.FROM_TIME == undefined) {
                isOk = false;
                this.message.error('Please Select From Time', '');
              } else
                if (this.data.TO_TIME == undefined) {
                  isOk = false;
                  this.message.error('Please Select TO Time', '');
                } else

                  if (this.data.REASON == undefined) {
                    isOk = false;
                    this.message.error("Please Enter Reason", "");
                  }




    // if (this.data.HEAD_STATUS) {
    //   this.data.STATUS = "AP";


    // } else {
    //   this.data.STATUS = "RE";

    // }


    if (isOk) {

      this.isSpinning = true;

      this.data.FROM_DATE = this.datePipe.transform(this.data.FROM_DATE, 'yyyy-MM-dd');
      this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, 'yyyy-MM-dd');
      // this.data.EMPLOYEE_ID = this.api.userId;
      this.data.TO_TIME = this.datePipe.transform(this.data.TO_TIME, "yyyy-MM-dd HH:mm" + ":00");
      this.data.FROM_TIME = this.datePipe.transform(this.data.TO_TIME, "yyyy-MM-dd HH:mm" + ":00");

      // if (this.APPROVER_ID != null) {
      //   this.data.APPROVER_ID = this.APPROVER_ID
      // } else {
      //   this.data.APPROVER_ID = this.data.APPROVER_ID
      // }

      this.data.APPROVER_ID = this.ID

      console.log(this.data);
      this.isSpinning = true;



      // if(this.ROLE_ID ==14){            
      //   if(this.data.STATUS=="AP"){
      //     this.data.STATUS="FW"
      //   }

      this.data.EMPLOYEE_ID = this.userId
      this.data.DESIGNATION_ID = this.designationId
      this.data.STATUS = 'P'
      this.data.APPLICATION_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm" + ":00");

      if (this.data.ID) {
        this.api.updateTour(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {

            this.message.success("Tour Category Updated Successfully", "");

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Tour Category Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.addTour(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {

            this.message.success("Tour Master Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.reset(myForm);
              this.getTourMaster();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Tour Master Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
      // }



    }
  }


  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: Date): void {
    console.log('onOk', result);
  }

  saleHierarchy5 = []

  getsalesHierarchySH() {



    // this.api.getsalesHierarchy('AND EMPLOYEE_ID= ' + this.cookie.get('userId')).subscribe(successCode => {
    //   if (successCode['code'] == 200) {
    //     console.log(successCode['data'],"rutuja");
    //     this.saleHierarchy = successCode['data']
    //   }
    // })

    //   this.api
    //     .getAllemployeeMaster(
    //       0,
    //       0,
    //       "NAME",
    //       "asc",
    //       " AND STATUS=1 AND ORG_ID=" +
    //       this.orgId +
    //       " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
    //       this.cookie.get("orgId") +
    //       " AND ROLE_ID IN (30,13))"
    //     )
    //     .subscribe(
    //       (data) => {
    //         if (data["code"] == 200) {
    //           this.saleHierarchy5 = data["data"];
    //         }
    //       },
    //       (err) => {
    //         if (err["ok"] == false) this.message.error("Server Not Found", "");
    //       }
    //     );
    // }[]
    var filterquery
    // if(this.ROLE_ID == 14 ){
    //   filterquery =   ' AND ROLE_ID IN (30) '
    // }else if ( this.ROLE_ID ==30 ){
    filterquery = ' AND ROLE_ID IN (13) '
    // }else{
    //   filterquery = ' AND ROLE_ID IN (14) '
    // }

    this.api.getAllEmpRoleMap(0,
      0,
      "ID",
      "asc", filterquery).subscribe(data => {

        if (data["code"] == 200) {
          this.saleHierarchy5 = data["data"];
        }

        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }


      });

  }

}

