import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service';
import { CustomerMaster } from 'src/app/Models/customer-master';
import { NewCustomerMaster } from 'src/app/Models/newcustomer';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-newcustomer',
  templateUrl: './newcustomer.component.html',
  styleUrls: ['./newcustomer.component.css']
})
export class NewcustomerComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: NewCustomerMaster;
  isSpinning = false;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/
  dateFormat = "dd/MM/yyyy"
  dateFormat1 = "HH:mm"
  @Input() stepsdisabled: boolean


  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.location()
    this.getallorg1();
    this.getEmployeeList1()
    this.getAllModelsWithActiveStatus()

  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }
  Leaddata = []
  Checkregi() {
    this.api.getSMRMastertabledata(0, 0, "", "asc", ` AND VIN ='${this.data.VIN}'`, '', '').subscribe(
      (data) => {
        if (data["code"] == 200 && data['count'] > 0) {
          this.Leaddata = data["data"];
          // console.log(this.Leaddata);
          this.stepsdisabled = true
          this.message.warning("VIN No. Already Exists", "")

        } else {
          this.message.info("Please create new customer...", "")
          this.stepsdisabled = false
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  models = [];
  listdata2 = []
  listdata1 = []
  getallorg1() {
    this.listdata1 = [];

    this.api.getAllnewCustomers(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  getallorg2(userID) {
    this.listdata2 = [];

    var tempEmpList = this.listdata1.filter(obj1 => {
      return obj1["ID"] != userID;
    });

    this.listdata2 = tempEmpList;
  console.log(this.listdata2,"listdata")

  }

  getAllModelsWithActiveStatus() {
    this.models = [];

    this.api.getAllModels(0, 0, "NAME", "asc", " AND STATUS=1 AND IS_AVAILABLE_FOR_SALE = 1").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  EmployeeList24 = [];

  getEmployeeList1() {

    this.EmployeeList24 = [];
    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID=48').subscribe(data => {

      if (data['code'] == 200) {

        this.EmployeeList24 = data['data']

      }



    })

  }


  LOCATIONs = []
  WORK_TYPE
  location() {
    this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
      this.LOCATIONs = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }


  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.REGISTRATION_NO == null || this.data.REGISTRATION_NO.trim() == "") {
      isOk = false
      this.message.error('Please Enter Registration No.', "")
    } else


      // else   if (this.data.CUSTOMER_NAME != undefined) {
      //   if (this.data.CUSTOMER_NAME.trim() != '') {
      //     if (!this.api.checkTextBoxIsValid(this.data.CUSTOMER_NAME)) {
      //       isOk = false;
      //       this.message.error("Please Enter Valid Customer Name", "");
      //     }
      //   } else {
      //     isOk = false;
      //     this.message.error("Please Enter Valid Customer Name", "");
      //   }
      // } else {
      //   isOk = false;
      //   this.message.error("Please Enter Valid Customer Name", "");
      // } 
      if (this.data.CUSTOMER_NAME == null || this.data.CUSTOMER_NAME.trim() == "") {
        isOk = false
        this.message.error("Please Enter Valid Customer Name", "");
      }
      else if (this.data.MOBILE_NUMBER == null || this.data.MOBILE_NUMBER == "") {
        isOk = false
        this.message.error('Please Enter Valid Mobile No.', "")
      } else if (this.data.VIN == null || this.data.VIN == "") {
        isOk = false
        this.message.error('Please Enter Valid VIN No.', "")
      } else if (this.data.BOOKING_DATE == null || this.data.BOOKING_DATE == "") {
        isOk = false
        this.message.error('Please Enter Valid Booking Date.', "")
      } else if (this.data.SERVICE_ADVISER_ID == null || this.data.SERVICE_ADVISER_ID == undefined) {
        isOk = false
        this.message.error('Please Select Service Advisor.', "")
      } else if (this.data.LOCATION == null || this.data.LOCATION == undefined) {
        isOk = false
        this.message.error('Please Select Branch.', "")
      }

      else if(this.data.WORK_TYPE == null && this.data.WORK_TYPE == undefined || this.data.WORK_TYPE == ''){

        isOk = false;

        this.message.error('Please Select Work Type', '');

      }

      // data.LOCATION


      //  this.data.CALLING_TIME = this.datePipe.transform(new Date(this.data.CALLING_TIME), "HH:mm:ss")
      //  if(this.data.PICK_UP_DATE!=null && this.data.PICK_UP_TIME!=null){
      //   this.data.PICK_UP_DATE = this.datePipe.transform(this.data.PICK_UP_DATE, "yyyy-MM-dd")
      //   this.data.PICK_UP_TIME = this.datePipe.transform(this.data.PICK_UP_TIME, "HH:mm:ss")
      //  }
      else {
        this.data.CCE_ID = this.cookie.get('userId')
        if (isOk) {



          if (this.data.PICK_UP_DATE != null) {

            this.data.PICK_UP_DATE = this.datePipe.transform(this.data.PICK_UP_DATE, "yyyy-MM-dd")

          }



          if (this.data.BOOKING_DATE != null) {
            this.data.BOOKING_DATE = this.datePipe.transform(this.data.BOOKING_DATE, "yyyy-MM-dd")
          }


          if (this.data.CALLING_DATE != null) {
            this.data.CALLING_DATE = this.datePipe.transform(this.data.CALLING_DATE, "yyyy-MM-dd")
          }

          var date = Object.assign({}, this.data)

          if (this.data.BOOKING_TIME != null) {
            date.BOOKING_TIME = this.datePipe.transform(new Date(date.BOOKING_TIME), "HH:mm:ss")
          }

          // console.log(date.BOOKING_TIME,"date.BOOKING_TIME")

          if (this.data.CALLING_TIME != null) {
            date.CALLING_TIME = this.datePipe.transform(new Date(date.CALLING_TIME), "HH:mm:ss")
          }

          if (this.data.PICK_UP_TIME != null) {

            date.PICK_UP_TIME = this.datePipe.transform(new Date(date.PICK_UP_TIME), "HH:mm:ss")

          }
          this.isSpinning = true;
          // console.log(this.data);

          if (this.data.ID) {
            date.CALL_STATUS = this.data.CALL_STATUS
            var emailData3 = this.listdata2.filter(obj => {
              return (obj.VIN == this.data.VIN);
            });
        
            console.log(emailData3.length==0 ,"emailData3.length ");
            if (emailData3.length == 0) {
            
              this.api.updatenewCustomer(date).subscribe(successCode => {
                if (successCode['code'] == "200") {
                  this.message.success("Location Updated Successfully", "");
                  console.log(emailData3.length==0 ,"emailData3.length ");
                  if (!addNew)
                    this.close(myForm);
                  this.isSpinning = false;

                } else {
                  this.message.error("Location Updation Failed", "");
                  this.isSpinning = false;
                }
              });
            }

            else if(emailData3.length > 0){
              this.message.error("VIN No Already Exist. Please Enter New VIN No", "");
              this.isSpinning = false;
            }

          } else {
            date.CALL_STATUS = 'A'
            this.api.createnewCustomer(date).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Location Created Successfully", "");

                if (!addNew)
                  this.close(myForm);

                else {
                  this.data = new NewCustomerMaster();
                }

                this.isSpinning = false;

              } else {
                this.message.error("Location Creation Failed", "");
                this.isSpinning = false;
              }
            });
          }
        }
      }


  }


  save1(addNew: boolean, myForm: NgForm): void {
    var isOk = true;
    if (this.data.REGISTRATION_NO == null || this.data.REGISTRATION_NO.trim() == "") {
      isOk = false
      this.message.error('Please Enter Registration No.', "")
    } else if (this.data.CUSTOMER_NAME == null || this.data.CUSTOMER_NAME.trim() == "") {
        isOk = false
        this.message.error("Please Enter Valid Customer Name", "");
      }
      else if (this.data.MOBILE_NUMBER == null || this.data.MOBILE_NUMBER == "") {
        isOk = false
        this.message.error('Please Enter Valid Mobile No.', "")
      } else if (this.data.VIN == null || this.data.VIN == "") {
        isOk = false
        this.message.error('Please Enter Valid VIN No.', "")
      } else if (this.data.BOOKING_DATE == null || this.data.BOOKING_DATE == "") {
        isOk = false
        this.message.error('Please Enter Valid Booking Date.', "")
      } else if (this.data.SERVICE_ADVISER_ID == null || this.data.SERVICE_ADVISER_ID == undefined) {
        isOk = false
        this.message.error('Please Select Service Advisor.', "")
      }

      else if (this.data.LOCATION == null || this.data.LOCATION == undefined) {
        isOk = false
        this.message.error('Please Select Branch.', "")
      }

      else if(this.data.WORK_TYPE == null && this.data.WORK_TYPE == undefined || this.data.WORK_TYPE == ''){

        isOk = false;

        this.message.error('Please Select Work Type', '');

      }

      else {
        this.data.CCE_ID = this.cookie.get('userId')
        if (isOk) {
          this.isSpinning = true;
          // console.log(this.data);
          if (this.data.PICK_UP_DATE != null) {
            this.data.PICK_UP_DATE = this.datePipe.transform(this.data.PICK_UP_DATE, "yyyy-MM-dd")
          }

          if (this.data.BOOKING_DATE != null) {
            this.data.BOOKING_DATE = this.datePipe.transform(this.data.BOOKING_DATE, "yyyy-MM-dd")
          }

          if (this.data.CALLING_DATE != null) {
            this.data.CALLING_DATE = this.datePipe.transform(this.data.CALLING_DATE, "yyyy-MM-dd")
          }
          var date = Object.assign({}, this.data)

          if (this.data.BOOKING_TIME != null) {
            date.BOOKING_TIME = this.datePipe.transform(new Date(date.BOOKING_TIME), "HH:mm:ss")
          }

          if (this.data.CALLING_TIME != null) {
            date.CALLING_TIME = this.datePipe.transform(new Date(date.CALLING_TIME), "HH:mm:ss")
          }

          if (this.data.PICK_UP_TIME != null) {
            date.PICK_UP_TIME = this.datePipe.transform(new Date(date.PICK_UP_TIME), "HH:mm:ss")
          }
          if (this.data.ID) {
            var emailData3 = this.listdata2.filter(obj => {
              return (obj.VIN == this.data.VIN);
            });
        
            console.log(emailData3.length==0 ,"emailData3.length ");
            if (emailData3.length == 0) {
            this.api.updatenewCustomer(date).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Customer Updated Successfully", "");
                if (!addNew)
                  this.close(myForm);
                this.isSpinning = false;
              } else {
                this.message.error("Customer Updation Failed", "");
                this.isSpinning = false;
              }
            });
          }

          else if(emailData3.length > 0){
            this.message.error("VIN No Already Exist. Please Enter New VIN No", "");
            this.isSpinning = false;
          }
          } else {
            this.api.createnewCustomer(date).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Customer Created Successfully", "");
                if (!addNew)
                  this.close(myForm);
                else {
                  this.stepsdisabled = true
                  this.data = new NewCustomerMaster();
                }
                this.isSpinning = false;
              } else {
                this.message.error("Customer Creation Failed", "");
                this.isSpinning = false;
              }
            });
          }
        }
      }

  }

}
