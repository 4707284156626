import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-customer-wise-memo-detailed-report',
  templateUrl: './customer-wise-memo-detailed-report.component.html',
  styleUrls: ['./customer-wise-memo-detailed-report.component.css']
})
export class CustomerWiseMemoDetailedReportComponent implements OnInit {

  formTitle = "Customer Wise Memo Detailed Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'CustomerWise.xlsx';
  dataList = [];

  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"],
  ["VARIENT_NAME", "Variant"], ["TRANSMISSION", "Transmission Type"], ["FUEL_TYPE", "Fuel Type"],
  ["SALES_EXECUTIVE_NAME", "Sales Executive Name"], ["MEMO_NO", "Memo No"], ["MEMO_STATUS", "Memo Status"], ["PENDING_AT", "Pending At"], ["DATE", "Date"]]

  isSpinning = false
  filterClass: string = "filter-invisible";


  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];
  exportToExcelLoading = false
  index = 0;
  exportToExcelDataList: any = []
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  departments = []
  supportusers = []
  assign: any = [];
  BRANCH: any = []
  SALES_EXECUTIVE_ID: any = []
  MODEL_ID: any = []
  MEMO_STATUS: any = []
  endValue: any;
  startValue: any;
  date: any = []
  current = new Date()
  constructor(private api: ApiService, private datePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
    this.startValue = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01'); this.endValue = new Date();

    //   this.api.getCustomerwiseMemoDetailed(0,0,'','','').subscribe(data => {
    //     this.departments = data['data'];
    //   }, err => {
    //     console.log(err);
    //   });
    this.getSales()
    this.getModels()

}

sales = [];

getSales() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID IN(28)')
      .subscribe(data => {
        this.sales = data['data'];
      },
        (err) => {
          console.log(err);
        }
    );
}

  models = [];
  getModels() {
    this.api.getAllModels(0, 0, this.sortKey, this.sortValue, 'AND STATUS = 1')
      .subscribe(data => {
        this.models = data['data'];
      },
        (err) => {
          console.log(err);
        }
      );
  }
  // memoDetails = [];
  // getMemoDetail() {
  //   this.api.getMemoDetails(0, 0, this.sortKey, this.sortValue,'')
  //     .subscribe(data => {
  //       this.memoDetails = data['data'];
  //     },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  clearFilter() {
    this.startValue = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01'); 
    this.endValue = new Date();

    this.filterQuery = ""
    this.selectedDate = null;
    this.SALES_EXECUTIVE_ID = []
    this.MODEL_ID = []
    this.MEMO_STATUS = []
    this.value1 = ''
    this.value2 = ''
    this.filterClass = "filter-invisible";
    this.search()
  }

  onKeypressEvent(reset: boolean = false) {
    document.getElementById('button').focus()
    this.search(reset);
  }


  search(reset: boolean = false, exportToExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery =   " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }
    if (exportToExcel == false) {
      this.loadingRecords = true

      this.api.getCustomerwiseMemoDetailed(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery,this.SALES_EXECUTIVE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.exportToExcelLoading = true;


      this.api.getCustomerwiseMemoDetailed(0, 0, this.sortKey, sort, this.filterQuery + likeQuery,this.SALES_EXECUTIVE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isSpinning = true
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "
      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    // if (this.SALES_EXECUTIVE_ID != null && this.SALES_EXECUTIVE_ID != 0 && this.SALES_EXECUTIVE_ID != undefined) {
    //   // this.filterQuery =this.filterQuery + ' AND SALES_EXECUTIVE_ID=' + "'" + this.SALES_EXECUTIVE_ID + "'";

    //   this.filterQuery =this.filterQuery + " AND SALES_EXECUTIVE_ID IN (" + this.SALES_EXECUTIVE_ID + ")";
    // }
    if (this.SALES_EXECUTIVE_ID != undefined && this.SALES_EXECUTIVE_ID.length > 0) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }

    if (this.MODEL_ID != null && this.MODEL_ID != 0 && this.MODEL_ID != undefined) {
      this.filterQuery =
        this.filterQuery + ' AND MODEL_ID=' + "'" + this.MODEL_ID + "'";
    }

    if (this.MEMO_STATUS != null && this.MEMO_STATUS != 0 && this.MEMO_STATUS != undefined) {
      this.filterQuery =
        this.filterQuery + ' AND MEMO_STATUS=' + "'" + this.MEMO_STATUS + "'";
    }

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    this.search();
    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);

    }

    this.api.getCustomerwiseMemoDetailed(0, 0, this.sortKey, sort, this.filterQuery,this.SALES_EXECUTIVE_ID).subscribe((data) => {
      console.log(data);
      this.loadingRecords = false;
      this.isFilterApplied = 'primary';
      this.totalRecords = data['count'];
      this.assign = data['data'];
      this.isSpinning = false;
      this.filterClass = 'filter-invisible';
    },
      (err) => {
        console.log(err);
      }
    );


  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Customer Name'] = this.exportToExcelDataList[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.exportToExcelDataList[i]['MODEL_NAME'];
      obj1['Variant name'] = this.exportToExcelDataList[i]['VARIENT_NAME'];
      obj1['Transmission Type'] = this.exportToExcelDataList[i]['TRANSMISSION'];
      obj1['Fuel Name'] = this.exportToExcelDataList[i]['FUEL_TYPE'];
      obj1['sales Executive Name'] = this.exportToExcelDataList[i]['SALES_EXECUTIVE_NAME'];
      obj1['Memo No'] = this.exportToExcelDataList[i]['MEMO_NO'];
      obj1['Memo status'] = this.exportToExcelDataList[i]['MEMO_STATUS'];
      obj1['Pending at'] = this.exportToExcelDataList[i]['PENDING_AT'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer wise memo detailed report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }

}


