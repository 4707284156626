import { Component, OnInit ,Input} from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
// import { ratebranch } from 'src/app/Models/ratebranch';
import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
import { NzNotificationService } from "ng-zorro-antd";


@Component({
  selector: 'app-reportbranch',
  templateUrl: './reportbranch.component.html',
  styleUrls: ['./reportbranch.component.css']
})
export class ReportbranchComponent implements OnInit {
  @Input() data: rateMaster ;
  @Input() drawerClose: Function;
  @Input() drawerVisible: boolean;
  @Input() MappingData: any;
  isSpinning=false
  
  constructor(private api:ApiService,private message: NzNotificationService) { }

  ngOnInit() {
    }
  
  close() {
    console.log(this.MappingData,"Input")
    this.drawerClose();
  }
  
  save() {
    
    
     
if(this.MappingData.length>0){

    this.api.addBranch(this.data.ID,this.MappingData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Branch Mapping Updated Successfully...", "");
       this.drawerClose();

      } 
      else{
        this.message.error("Branch Mapping update failed",'')
      }
    });
  }
  else{
    this.message.error("no data",'')
  }
}

  
}
