import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { memoMaster } from 'src/app/Models/memo';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-memo-details',
  templateUrl: './memo-details.component.html',
  styleUrls: ['./memo-details.component.css'],
  providers: [DatePipe]
})

export class MemoDetailsComponent implements OnInit {
  formTitle = "Memo Details";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  isSpinning: boolean = false
  sortKey: string = "ID";
  sortValue: string = "desc";
  searchText: string = "";
  columns: string[][] = [["MEMO_NO", "Memo No."], ["DATE", "Date"],["CUSTOMER_NAME", "Customer Name"],  ["SALES_EXECUTIVE_NAME", "Sales Executive"], ["MODEL_NAME", "Model"], ["INVOICE_NO", "Invoice No."], ["REMARK", "Remark"], ["STATUS", "Status"]];
  drawerVisible: boolean;
  recieptData = []
  drawerTitle: string;
  drawerFormPrintData: any
  visible = false
  isVisible = false;
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  userId = this.cookie.get('userId')
  ACCOUNT_DATETIME = new Date()
  questionary = new questionaryMaster();
  employeeget = new EmployeeMaster()
  advanceArray: [];
  memoDetailsArray: [];
  quotedPrice: number;
  memoSignature = new memoSignatureDetails();
  dataid: any;
  discount1 = 0
  discount2 = 0
  exchange = 0
  sum1 = 0
  sum2 = 0
  sum3 = 0
  sum4 = 0
  sum5 = 0
  sum6 = 0
  sum7 = 0
  sum8 = 0
  sum9 = 0
  // FROM_DATE=new Date();
  startValue = new Date()
  endValue: any = new Date()
  TO_DATE :any = new Date()
  FROM_DATE :any=  new Date(this.startValue.getTime() - 7 * 24 * 60 * 60 * 1000);
 
  // sum3 = 0
  rTo: any
  tcs: any
  sdfsdf: any
  sdfsdf2: any
  sdfsdf3: any
  sdfsdf4: any
  sdfsdf5: any
  sdfsdf6: any
  sdfsdf7: any
  formname: any

  signature = sessionStorage.getItem('signature')
  accountname = sessionStorage.getItem('accountname')
  data: memoMaster = new memoMaster()

  constructor(private domSanitizer: DomSanitizer, public datepipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
memoid
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }
    

    var memoDateFilter = "";
    if (this.FROM_DATE != null) {
      this.FROM_DATE =  this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd')
    }
    if (this.TO_DATE != null) {
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd')
    }

    var memoStatusFilter:any = [];
    if (this.STATUS.length > 0) {
      memoStatusFilter = this.STATUS

    } else {
      memoStatusFilter = ["RQ"];
    }

    this.loadingRecords = true;
    this.api.getMemoDetails12(this.userId,memoStatusFilter,this.FROM_DATE,this.TO_DATE,likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];

        // for (let i = 0; i < this.dataList.length; i++) {
        //   this.dataList[i]['QUOTATION_ID']
        //   this.memoid=this.dataList[i]['ID']
        //   console.log( this.memoid);
          
        //   this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + this.dataList[i]['QUOTATION_ID']).subscribe(data => {
        //     this.recieptData = data['data']
        //     console.log(data['data']);
        //   })
        // }
        console.log(this.recieptData);

        this.api.getAllemployeeMaster(0,0, this.sortKey, sort, likeQuery + ' AND ID =' + this.userId).subscribe(data => {
          if (data['code'] == 200 && data['count'] > 0) {
            // this.loadingRecords = false;
            this.employeeget = data['data'][0];
            
            if (this.employeeget != undefined) {
              this.employeeget.SIGNATURE = data['data'][0]['SIGNATURE']
              console.log(this.employeeget, "");
             
            }
            sessionStorage.setItem('signature', data['data'][0]['SIGNATURE'])
            sessionStorage.setItem('accountname', data['data'][0]['NAME'])
            // sessionStorage.setItem('accountname',data['data'][0]['NAME'])

            // this.dataList = data['data'];


          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  
  moduleStartDateHandle(open: boolean) {
   
     
      this.TO_DATE = new Date();

      // this.moduleEndDatePicker.open();
    
  }

  openDrawer(data): void {
    console.log(data);
    this.visible = true
    this.isSpinning = true
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data['data']);
        this.recieptData = data['data']
        this.isSpinning = false
        for (let i = 0; i < this.recieptData.length; i++) {
          console.log(this.recieptData[i]['RECEIPT_PHOTO']);
        }
      }
    })
  }

  imageModalVisible = false
  openPdf(data: memoMaster): void {
    // this.loadingRecords = true
    this.imageModalVisible = true
    this.isSpinning = true
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        this.isSpinning = false
        this.loadingRecords = false
      }
    })
  }

  openReceipt(data: memoMaster, i: any) {
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        this.isSpinning = false
        this.loadingRecords = false
        window.open(this.api.retriveimgUrl + "receiptPhoto/" + this.recieptData[i]['RECEIPT_PHOTO']);
        console.log(this.recieptData[i]['RECEIPT_PHOTO']);
      }
    })

  }

  openReceipt1(data: memoMaster, i: any) {
    this.api.getAdvanceReciept(0, 0, '', "asc", 'AND QUOTATION_ID =' + data.QUOTATION_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.recieptData = data['data']
        console.log(this.recieptData);
        this.isSpinning = false
        this.loadingRecords = false
        window.open(this.api.retriveimgUrl + "paymentPhoto/" + this.recieptData[i]['PAYMENT_PHOTO']);
        console.log(this.recieptData[i]['PAYMENT_PHOTO']);
      }
    })

  }
  handleimgCancel() {
    this.imageModalVisible = false
  }
  close(): void {
    this.visible = false;
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }


  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  DATE = [];
  STATUS = [];

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    if ((this.DATE.length > 0) || (this.STATUS.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.DATE = [];
    this.STATUS = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  getStatusFullForm(status) {
    if (status == "RQ")
      return "Requested";

    else if (status == "FW")
      return "Forward";

    else if (status == "AP")
      return "Approved";

    else if (status == "RJ")
      return "Rejected";
  }

  url: any
  url1: any

  getAttachment(data) {
    console.log(data);

    this.api.getAllCustomer(0, 0, '', 'asc', ' AND ID =' + data.CUSTOMER_ID).subscribe(data => {
      console.log(data['data']);
      if (data['data'][0]['PAN_URL'] != '') {
        this.url = data['data'][0]['PAN_URL']
        window.open(this.api.retriveimgUrl + "panImages/" + this.url);
      } else {
        this.message.info('Pan Not Uploaded', '');
      }

    }, err => {
      console.log(err);
    });

  }
  getAttachment1(data) {
    console.log(data);

    this.api.getAllCustomer(0, 0, '', 'asc', ' AND ID =' + data.CUSTOMER_ID).subscribe(data => {
      console.log(data['data']);
      if (data['data'][0]['AADHAR_URL'] != '') {
        this.url1 = data['data'][0]['AADHAR_URL']
        window.open(this.api.retriveimgUrl + "aadharImages/" + this.url1);
      } else {
        this.message.info('Aadhar Not Uploaded', '');
      }

    }, err => {
      console.log(err);
    });

  }

  isApproveVisible: boolean

  tempData = new Object();
  INVOICE_NO: string = "";

  openApproveModal(data: any) {
    this.tempData = new Object();
    this.tempData = data;
    this.INVOICE_NO = "";
    this.isApproveVisible = true;
    this.get(data.ID)
  }

  handleApproveCancel() {
    this.isApproveVisible = false;
  }

  ACC_REMARK = ""

  handleApproveOk() {
    let isOk = true;

    if (this.ACC_REMARK == undefined || this.ACC_REMARK == '') {
      isOk = false;
      this.message.error('Please Enter Valid Remark.', '');
    }

    if (isOk) {
      this.isApproveModalConfirmLoading = true;
      // this.tempData["INVOICE_NO"] = this.INVOICE_NO;
      // this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "FW";
      this.tempData["ACC_REMARK"] = this.ACC_REMARK

      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isApproveModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "P");
          this.memoSignature.ACCOUNT_ID = this.userId
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");

            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });

          this.message.success("Invoice No. Updated Successfully", "");
          this.isApproveVisible = false;
          this.search(false);



        } else {
          this.isApproveModalConfirmLoading = false;
          this.message.error("Invoice No. Updation Failed", "");
          this.search(false);
        }
      });




    }
  }

  isRejectVisible: boolean = false;
  REMARK: string = "";

  // updatesign() {

  //   this.memoSignature.ACCOUNT_ID = this.userId
  //   // this.memoSignature.MEMO_ID = this.memoSignature.MEMO_ID



  // }

  get(ID) {
    this.api.getmemoSignatureDetails(this.pageIndex, this.pageSize, "", "", "AND MEMO_ID=" + ID).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memoSignature = data['data'][0];
        console.log(this.memoSignature, "this.memoSignature");



      }
    });

  }
  openRejectModal(data: any) {
    this.tempData = new Object();
    this.tempData = data;
    this.REMARK = "";
    this.isRejectVisible = true;
    this.get(data.ID)
  }

  handleRejectCancel() {
    this.isRejectVisible = false;
  }

  handleRejectOk() {
    let isOk = true;

    if (this.REMARK == undefined || this.REMARK.toString() == '') {
      isOk = false;
      this.message.error('Please Enter Valid Remark', '');
    }

    if (isOk) {
      this.isRejectModalConfirmLoading = true;
      this.tempData["REMARK"] = this.REMARK;
      this.tempData["APPROVER_ID"] = this.api.userId;
      this.tempData["STATUS"] = "RJ";
      this.data.APPROVED_DATETIME = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
      this.tempData['APPROVED_DATETIME'] = this.data.APPROVED_DATETIME
      this.api.updateMemoDetails(this.tempData).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.memoSignature.ACCOUNT_ID = this.userId
          this.memoSignature.ACCOUNT_DATETIME = this.datepipe.transform(this.ACCOUNT_DATETIME, "yyyy-MM-dd")
          this.api.updatememoSignatureDetails(this.memoSignature).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Account Signature Updated Successfully", "");

            } else {
              this.message.error("Account Signature Updated  Failed", "");
            }
          });
          this.isRejectModalConfirmLoading = false;
          this.setQuotationStatus(this.tempData["QUOTATION_ID"], "R");

          this.message.success("Remark Updated Successfully", "");
          this.isRejectVisible = false;
          this.search(false);

        } else {
          this.isRejectModalConfirmLoading = false;
          this.message.error("Remark Updation Failed", "");
          this.search(false);
        }
      });
    }
  }



  setQuotationStatus(quotationID: number, status: string) {
    this.api.setQuotationStatus(quotationID, status).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Quotation Updated Successfully", "");

      } else {
        this.message.error("Quotation Updation Failed", "");
      }
    });
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  drawerClose1(): void {
    this.isVisible = false;
    this.advanceArray = []
    this.sumoffall2 = 0
    this.sumofall = 0
    this.discount2 = 0
    this.discount1 = 0
    this.exchange = 0
    this.quotedPrice = 0
    this.sum2 = 0
    this.sum3 = 0
    this.sum4 = 0
    this.sum5 = 0
    this.sum6 = 0
    this.sum7 = 0
    this.sum8 = 0
    this.sum9 = 0
    this.memoDetailsArray = []
    this.search()


  }

  memo12 = new memoMaster();
  acc_remark: any
  isSpinning1 = false
  add1 = 0
  SAMBHADH_SCHEME=0
  // isSpinning1=false
  date24:any
  printForm(data: memoMaster) {

    this.formname = "Memo.Pdf"
    this.drawerFormPrintData = Object.assign({}, data);
    this.date24 = data.DATE

    sessionStorage.setItem('DATE',data.DATE)
  
      this.isSpinning1 = true
      this.isVisible = true;
      
    // }
   
    // this.acc_remark= data1['ACC_REMARK']
    console.log(this.acc_remark, "this.acc_remark");


    var filter = "AND ID=" + data.ID
    this.api.getMemoDetails(0, 0, "", "", filter).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {
        this.memo12 = data['data'][0];
        console.log(this.memo12, "MEMO12");

        if (this.memo12 != null) {
          if (data['data'][0]['ADDITIONAL_DISCOUNT'] != null)
          this.add1 = data['data'][0]['ADDITIONAL_DISCOUNT']
          else
          this.add1=0
          console.log(this.memo12, "ADD1");
        }
        else{
          this.add1=0
        }

        this.getcal2()
        this.isSpinning1 = false
      

    
        this.api.getQuestionaryMaster(0,0, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {

            this.questionary = data['data'][0];


            if (this.questionary.CORPORATE_DISCOUNT != null &&  this.questionary.CORPORATE_DISCOUNT != "") {

              this.discount1 = parseInt(data['data'][0]['CORPORATE_DISCOUNT'])
              console.log(this.discount1, "discount1")
            }
            else {
              this.discount1 = 0
              console.log(this.discount1, "discount1")
            }
            if (this.questionary.EXCHANGE != null && this.questionary.EXCHANGE != "") {
              console.log(this.questionary.EXCHANGE != null && this.questionary.EXCHANGE != "", "")
              this.exchange = parseInt(data['data'][0]['EXCHANGE'])
              console.log(this.exchange, "exchange")
            }
            else {
              this.exchange = 0
            }

            if (this.questionary.SAMBHADH_SCHEME != null &&  this.questionary.SAMBHADH_SCHEME != "") {
              this.SAMBHADH_SCHEME = parseInt(data['data'][0]['SAMBHADH_SCHEME'])
              console.log(this.SAMBHADH_SCHEME, "SAMBHADH_SCHEME")
            }
            else {
              this.SAMBHADH_SCHEME = 0
              console.log(this.SAMBHADH_SCHEME, "SAMBHADH_SCHEME")
            }
            console.log(this.questionary);
            this.getcal()
            this.getcal2()
          }



        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
      
        this.api.getAdvanceMaster(0,0, "", "", "AND QUOTATION_ID=" + this.memo12.QUOTATION_ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            this.totalRecords = data['count'];
            this.advanceArray = data['data'];

            for (let i = 0; i < this.advanceArray.length; i++) {
              this.discount2 = this.discount2 + Number(this.advanceArray[i]['AMOUNT'])
              console.log(this.discount2, "discount2");
              if (i + 1 == this.advanceArray.length) {
                this.getcal2()
              }
            }
          }
        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getMemoDetails1(0,0, "", "", "AND MEMO_MASTER_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoDetailsArray = data['data'];
            console.log(this.memoDetailsArray);

            this.quotedPrice = 0

            for (let i = 0; i < this.memoDetailsArray.length; i++) {
              this.quotedPrice = this.quotedPrice + Number(this.memoDetailsArray[i]['CUSTOMER_QUOTED_PRICE'])

              console.log(this.quotedPrice);
              if (i + 1 == this.memoDetailsArray.length) {
                this.getcal()

              }
            }

            for (let j = 0; j < this.memoDetailsArray.length; j++) {

              this.sum1 = Number(this.memoDetailsArray[j]['CUSTOMER_DISCOUNT'])
              this.sum2 = Number(this.memoDetailsArray[j]['HEAD_DISCOUNT'])

              console.log(this.sum1, "sum1");
              console.log(this.sum2, "sum2");


              // this.sdfsdf=
              this.sdfsdf = this.memoDetailsArray[j]['COMPONENT']

              if (this.sdfsdf == 'SOT') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum3 = this.sum1 + this.sum2

                  console.log(this.sum3, "sum3");

                }
              }

              if (this.sdfsdf == 'Invoice amount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum4 = this.sum1 + this.sum2

                  console.log(this.sum4, "sum4");

                }
              }

              if (this.sdfsdf == 'RTO') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum5 = this.sum1 + this.sum2

                  console.log(this.sum5, "sum5");

                }
              }

              if (this.sdfsdf == 'Accessories') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum6 = this.sum1 + this.sum2

                  console.log(this.sum6, "sum6");

                }
              }

              if (this.sdfsdf == 'Insurance') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum7 = this.sum1 + this.sum2

                  console.log(this.sum7, "sum7");

                }
              }

              if (this.sdfsdf == 'Additional Discount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum8 = this.sum1 + this.sum2

                  console.log(this.sum8, "sum8");

                }
              }

              if (this.sdfsdf == 'Extended Warranty') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum9 = this.sum1 + this.sum2

                  console.log(this.sum9, "sum9");

                }
              }

              if (j + 1 == this.memoDetailsArray.length) {
                this.getcal2()
              }




            }

          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getmemoSignatureDetails(0,0, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoSignature = data['data'][0];


            this.isVisible = true;



          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
      
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

  }






  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl, {
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: "application/json",
        apikey: "68h3u1OxG6We2UnRD4F3IratYZHQ5hRB",
        applicationkey: "AfIpESwBr5eHp7w3",
      }
    });
    console.log(res)
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }


  sumofall = 0
  sumoffall2 = 0

  corporatedis:any
  samband:any

  getcal() {

    if (this.quotedPrice != null && this.quotedPrice != undefined && this.discount1 != undefined && this.discount1 != null) {
     

      if(this.drawerFormPrintData.IS_CORPORATE == 1){
        this.corporatedis = this.discount1
      }
      else{
        this.corporatedis =0 
      }

      if(this.drawerFormPrintData.IS_SAMBHADH_SCHEME == 1){
        this.samband = this.SAMBHADH_SCHEME
      }
      else{
        this.samband =0 
      }
     
     
     
      this.sumofall = Number(this.quotedPrice) + Number(this.corporatedis) + Number(this.samband)
      console.log(this.quotedPrice,this.corporatedis,this.samband, "Total1");

      // if (this.sumoffall2 >= this.sumofall) {
      //   this.getcal4()
      // }

      // else {
      //   this.getcal3()
      // }


    }
  }

  getcal2() {
    if (this.discount2 != null && this.discount2 != undefined && this.sum3 != undefined && this.sum3 != null && this.sum4 != undefined && this.sum4 != null
      && this.sum5 != undefined && this.sum5 != null && this.sum6 != undefined && this.sum6 != null && this.sum7 != undefined && this.sum7 != null && this.sum8 != undefined && this.sum8 != null && this.sum9 != undefined && this.sum9 != null && this.discount1 != undefined && this.discount1 != null
      && this.exchange != undefined && this.exchange != null && this.add1 != null) {

      this.sumoffall2 =
        Number(this.discount2) +
        Number(this.sum3) + Number(this.sum4) +
        Number(this.sum5) + Number(this.sum6) +
        Number(this.sum7) + Number(this.sum8) +
        Number(this.sum9) + Number(this.discount1) + Number(this.exchange) + Number(this.add1)+ Number(this.SAMBHADH_SCHEME)

      console.log(this.sumoffall2, "total2");

      if (this.sumofall >= this.sumoffall2) {
        this.getcal3()
      }
      else {
        this.getcal4()
      }


    }


  }

  getcal3() {

    console.log(this.sumofall, "ok", this.sumoffall2, "ok2");
    console.log();


    if (this.sumofall >= this.sumoffall2) {
      this.sdfsdf4 = this.sumofall - this.sumoffall2
      this.sdfsdf5 = 0
      console.log(this.sdfsdf4, "shortfall");


    }
    else {
      this.sdfsdf4 = 0
    }


  }

  getcal4() {
    if (this.sumoffall2 >= this.sumofall) {
      this.sdfsdf5 = this.sumoffall2 - this.sumofall
      this.sdfsdf4 = 0
      console.log(this.sdfsdf5,"Excess Amount");

    }

    else {
      this.sdfsdf5 = 0
    }
  }


  // getcallseco


  isApproveModalConfirmLoading: boolean = false;
  isRejectModalConfirmLoading: boolean = false;
  // allAccountantEmployeeList = [];

  // getAccountantEmployeeList() {
  //   this.allAccountantEmployeeList = [];

  //   this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=13)').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.allAccountantEmployeeList = data['data'];
  //       console.log(this.allAccountantEmployeeList);
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }
}
