export class LeadCateogaryMaster {

    ID:number=0;   
    SUB_DEPT_ID:number=0;       
    NAME:string='';
   
    STATUS:boolean=true; 
    SEQUENCE_NO:number=0 ;


}