  import { DatePipe } from '@angular/common';
  import { Component, OnInit, ViewChild } from '@angular/core';
  import { NzNotificationService } from 'ng-zorro-antd';
  import { CookieService } from 'ngx-cookie-service';
  import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
  import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
  import { SMRCOLLAPSEMASTER } from 'src/app/Models/smrcollapsemaster';
  import { ApiService } from 'src/app/Service/api.service';
  import { ExportService } from 'src/app/Service/export.service';
import { ActivityleadmasterComponent } from '../Servicesss Module/ServiceModule/activityleadform/activityleadmaster/activityleadmaster.component';
  
  @Component({
    selector: 'app-hmilcce',
    templateUrl: './hmilcce.component.html',
    styleUrls: ['./hmilcce.component.css']
  })
  export class HmilcceComponent implements OnInit {
  
   
    formTitle = "HMIL Leads";
    pageIndex = 1;
    pageSize = 10;
    totalRecords = 1;
    dataList = [];
    loadingRecords = false;
    sortValue: string = "desc";
    sortKey: string = "id";
    searchText: string = "";
    filterQuery: string = "";
    filterQuery1: string = "";
    filterQuery2:string =""
    isFilterApplied: string = "default";
    columns: string[][] = [["SEQUENCE_NUMBER", "Sequence Number"], ["BOOKING_DATE", "Booking Date"], ["SERVICE_ADVISOR_NAME", "Service Advisor Name"], ["BT_NUMBER", "BT Number"], ["BT_DATE_TIME", "BT Date Time"],
    ["CUSTOMER_NAME", "Customer Name"], ["BOOKING_CONTACT_NUMBER", "Booking Contact Number"], ["MOBILE_NUMBER", "Mobile Number"], ["REGISTRATION_NO", "Registration No"], ["VIN", "Vin"], ["WORK_TYPE", "Work Type"], ["EXPRESS_CARE", "Express Care"], ["CALLING_DATE", "Calling Date"], ["CALLING_STATUS", "Calling Status"], ["REASON", "Reason"],
    ["SME_EMPLOYEE_ID", "SME Employee ID"], ["BILL_NUMBER", "Bill Number"], ["BILL_AMOUNT", "Bill Amount"], ["BILL_DATE", "Bill Date"], ["MODEL", "Model"], ["REMARK", "Remark"], ["REMINDER_DATE", "Reminder Date"], ["VEDIO_CALL", "Video Call"], ["ONLINE_STREAMING", "Online Streaming"],
    ["FOLLOWUP_NO", "Followup No."], ["CALL_DATE", "Call Date"], ["SERVICE_COUNT", "Service Count"], ["CUST_NO", "Customer Number"], ["SPECIAL_MESSAGE", "Special Message"], ["RO_DATE", "Ro Date"],
    ["RO_NUMBER", "Ro Number"], ["NUMBER_OF_CALLS", "Number Of Calls"], ["LIVE_STREAMING", "Live Streaming"], ["STATUS", "Status"], ["DELIVERY_DATE", "Delivery Date"], ["LOCATION", "Location"], ["PIN_CODE", "Pincode"], ["VARIANT", "Varient"], ["CHASSIS_NUMBER", "Chassis Number"], ["SALES_CONSULTANT_NAME", "Sale Consultant Name"], ["SALE_DATE", "Sale Date"], ["LAST_SERVICE_TYPE", "Last Service type"], ["LAST_RO_DATE", "Last Ro Date"], ["SEGMENT", "Segment"], ["LAST_RO_WORKSHOP", "Last Ro Workshop"], ["LAST_RO_DEALER_NAME", "Last Ro Dealer Name"], ["LAST_RO_REGION", "Last Ro region"], ["NAME", "Name"], ["WORK_PHONE_NUMBER", "Work Phone Number"], ["ROOT", "Root"], ["SME_NAME", "SME Name"], ["PICK_UP_AND_DROP", "Pick Up and Drop"], ["LOST_CUSTOMER_NAME", "Lost Customer Name"], ["SRV_MODEL", "SRV Model"], ["EMAIL", "Email"], ["ADDRESS", "Address"], ["PURCHASE_DATE", "Purchase Date"], ["PREFERED_DLR_NO", "Preferred DLR No."], ["OFFICE_NUMBER", "Office Number"], ["RESIDENCE_NUMBER", "Resindence Number"], ["MILEAGE", "Mileage"], ["FIELD1", "Field 1"], ["FIELD2", "Field 2"], ["FIELD3", "Field 3"], ["FIELD4", "Field 4"], ["FIELD5", "Field 5"], ["FIELD6", "Field 6"], ["FIELD7", "Field 7"], ["FIELD8", "Field 8"], ["FIELD9", "Field 9"], ["FIELD10", "Field 10"]]
    SMRdrawerVisible!: boolean;
    drawerTitle!: string;
    drawerData: ServiceModuleExcel = new ServiceModuleExcel();
    isSMRLead: boolean = true
    // SMRdrawerVisible!: boolean; 
    // dataList:any[]=[]
    SHEET_ID
    index: number = 0;
    isClosed = false;
    confrmation: string = 'default'
    noshow: string = 'default'
    midterm: string = 'default'
    servicedone: string = 'default'
    salesdata: string = 'default'
    amslost: string = 'default'
    servicedue : string = 'default'
    collapseDrawerVisible: boolean = false;
    drawerData1: SMRCOLLAPSEMASTER = new SMRCOLLAPSEMASTER();
    drawerTitle1: string;
    drawerVisible1: boolean;
    drawerData2: ActivityLeadMaster = new ActivityLeadMaster();
  
    active1: boolean = false
    value1:any;
    active2: boolean = false
    active3: boolean = false
    active4: boolean = false
    active5: boolean = false
    active6: boolean = false
    active7: boolean = false
    active8: boolean = false
    userid = this.cookie.get('userId')
    employee = []
    CCE_ID
    Counts
  
    constructor(private _exportService: ExportService,private cookie: CookieService, private api: ApiService, public datePipe: DatePipe, private message: NzNotificationService) { }
  
    ngOnInit() {
      this.search1(true,this.value12,true,this.STATUS);
      this.getEmployee();
      
    }
    getAllcount(event) {
      this.api.getAllSubcount1(0,0,'','',' AND CCE_ID = '+ event).subscribe(data => {
        this.Counts = data['CategoryCounts'][0]
        // console.log(this.Counts,"counts");
        
      })
    }
    exportLoading: boolean = false;
  
    importInExcel() {
      this.search1(false,this.value12,this.value13,this.STATUS,true);
    }
   
    // getEmployee() {
    //   this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
    //     this.employee = data['data']
    //   })
    // }
  
    getEmployee() {
      this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
        if(data['code']==200){
          this.employee = data['data']
        }
       
      })
    }
    get closeCallback() {
      // this.index=0;
      return this.drawerClose.bind(this);
    }
    Counts1:any
    CCEEE(event){
      this.getAllcount(event)
      this.api.getAllSubc(0,0,'','','',event).subscribe(data => {
        this.Counts1 = data['TOTAL_LEADS']
        // console.log(this.Counts,"counts");
        
      })
    }
  
    MapCCE() {
      if (this.CCE_IDS != undefined && this.array.length > 0) {
        var CATEGORY_STATUS = 'L'
        this.api.MaphmilCCE(this.CCE_IDS, this.array,'L')
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("CCE Mapped Successfully ...", "");
              this.drawerClose();
              // this.isSpinning = false;
              this.array = []
              // this.checked = false
              this.CCE_IDS = null
              this.dataList.forEach(item => {
                this.isChecked = this.value1
                if (this.value1) {
                  if (this.value1 == true) {
                    this.checked = false
                    this.checked1 = false
                    this.indeterminate = false
                  }
                  // console.log(this.array);
                  // item.IS_SELECTED = 1
                } else {
                  // item.IS_SELECTED = 0
                  this.array = []
                  this.checked1 = false
                  this.indeterminate = true
                }
          
              });
            }
            else {
              this.message.error("CCE Mapping Failed...", "");
              this.array = []
              // this.isSpinning = false;
            }
          });
      } else {
        this.message.error("Please Select Leads & Call Center Executive", "");
      }
    }
    CATEGORY_STATUSSS
    CCE_IDS
    TransferCCE() {
      if (this.CATEGORY_STATUSSS != undefined && this.array.length > 0) {
        this.api.TransferCCE(this.CATEGORY_STATUSSS, this.array)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Lead Transfered Successfully ...", "");
              this.drawerClose();
              this.array = []
              this.CATEGORY_STATUSSS = null
              // this.isSpinning = false;
              this.dataList.forEach(item => {
                this.isChecked = this.value1
                if (this.value1) {
                  if (this.value1 == true) {
                    this.checked = false
                    this.checked1 = false
                    this.indeterminate = false
                  }
                  // console.log(this.array);
                  // item.IS_SELECTED = 1
                } else {
                  // item.IS_SELECTED = 0
                  this.array = []
                  this.checked1 = false
                  this.indeterminate = true
                }
          
              });
  
            }
            else {
              this.message.error("Lead Transfer Failed...", "");
              // this.isSpinning = false;
              this.array = []
            }
          });
      } else {
        this.message.error("Please Select Leads & Category Status", "");
      }
  
    }
  
    drawerClose(): void {
      this.search1(true,this.value12,'',this.STATUS);
      // this.getAllcount()
      this.SMRdrawerVisible = false;
      // this.isClosed=true
      this.active1 = false
      this.active2 = false
      this.active3 = false
      this.active4 = false
      this.active5 = false
      this.active6 = false
      this.active7 = false
      this.active8 = false
      // this.index=0;
    }
  
    confrmations(event: any) {
      this.confrmation = 'primary'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.amslost = 'default'
    }
    noshows(event: any) {
  
      this.confrmation = 'default'
      this.noshow = 'primary'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.amslost = 'default'
    }
    midterms(event: any) {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'primary'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.amslost = 'default'
    }
  
    servicedones(event: any) {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'primary'
      this.salesdata = 'default'
      this.amslost = 'default'
    }
  
    salesdatas(event: any) {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'primary'
      this.amslost = 'default'
    }
  
    amslosts(event: any) {
      this.confrmation = 'default'
      this.noshow = 'default'
      this.midterm = 'default'
      this.servicedone = 'default'
      this.salesdata = 'default'
      this.amslost = 'primary'
    }
  
  
  
  
    get closeCallback1() {
      // this.index=0;
      return this.drawerClose1.bind(this);
    }
    get closeCallback2() {
      // this.index=0;
      return this.drawerClose2.bind(this);
    }
  
    drawerClose1(): void {
      this.search1(true,this.value12,'C',this.STATUS);
      this.collapseDrawerVisible = false;
      // this.isClosed=true
      this.active1 = false
      this.active2 = false
      this.active3 = false
      this.active4 = false
      this.active5 = false
      this.active6 = false
      this.active7 = false
      this.active8 = false
      // this.index=0;
    }
    filterClass: string = "filter-invisible";
    startValue: any = new Date()
    endValue: any = new Date()
    reminderDate
    STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    ok:boolean=false
    showFilter(): void {
      if (this.filterClass === "filter-visible"){
  
        this.filterClass = "filter-invisible";
        
      
      }
       
      else
        this.filterClass = "filter-visible";
    }
    endOpen = false;
    disabledStartDate = (startValue: Date): boolean => {
      if (!startValue || !this.endValue) {
        return false;
      }
      return startValue.getTime() > this.endValue.getTime();
    };
  
    disabledEndDate = (endValue: Date): boolean => {
      if (!endValue || !this.startValue) {
        return false;
      }
      return endValue.getTime() < this.startValue.getTime();
    };
    onStartChange(date: Date): void {
      this.startValue = date;
    }
    onEndChange(date: Date): void {
      this.endValue = date;
    }
  
    handleStartOpenChange(open: boolean): void {
      if (!open) {
        this.endOpen = true;
      }
    }
    handleEndOpenChange(open: boolean): void {
      this.endOpen = open;
    }
  
    // filterQuery1
  
    applyFilter() {
      this.ok=true
      this.isFilterApplied = 'primary'
      this.loadingRecords = false;
      var sort: string;
      this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
      this.reminderDate = this.datePipe.transform(this.reminderDate, 'yyyy-MM-dd')
      try {
        sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
      } catch (error) {
        sort = '';
      }
      this.filterQuery = '';
  
      if (this.startValue != null) {
        this.STARTDATE = this.startValue
      }
      if (this.endValue != null) {
        this.ENDDATE = this.endValue
      }
  
      // this.search();
      // this.filter()
      this.filterClass = "filter-invisible";
    }
    showfiltervalue:any
  
    // filter() {
    
    //   // this.getAllcount()
  
    //   this.loadingRecords = true;
    //   var sort: string;
    //   try {
    //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  
    //   } catch (error) {
    //     sort = "";
    //   }
   
   
    // if (this.value == "WD") {
    //   this.filterQuery = ` AND CATEGORY_STATUS = 'WD' AND CCE_ID = '${this.SHEET_ID}'  AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}'`
    // }  else {
    //   this.filterQuery = ` AND CALLING_STATUS IN ('${this.value13}')  AND CCE_ID = '${this.SHEET_ID}' AND DATE(BILL_DATE) BETWEEN '${this.startValue}' AND '${this.endValue}'`
    // }
  
    //   this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort,  this.filterQuery, this.STARTDATE, this.ENDDATE).subscribe(data => {
    //     if (data['code'] == 200)
    //       this.loadingRecords = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
  
  
    //   }, err => {
    //     // console.log(err);
    //   });
    // }
  
    clearFilter() {
      this.filterClass = 'filter-invisible';
      // this.BRANCH_ID = []
      // this.SALES_CONSULTANT_ID = ''
      this.CATEGORY_STATUS = null
      this.reminderDate = ''
      this.startValue = new Date()
      this.endValue = new Date()
      this.STARTDATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.ENDDATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
      this.search1(true,this.value, this.value13,this.STATUS)
    }
  
  
  
    checked = false;
    checked1 = false;
    checkedd = true;
    indeterminate = true;
    indeterminate1 = false;
    setOfCheckedId = new Set<number>();
    setOfCheckedId1 = new Set<number>();
    array = []
  
    isChecked: boolean
    onChecked1(id: number, checked: boolean): void {
      if (checked) {
        // console.log(checked);
        this.isChecked = checked
        this.array.push(id)
  
        // console.log(this.array);
  
        this.indeterminate = false
        // this.dataList[ind].IS_SELECTED = 1
      } else {
        // this.dataList[ind].IS_SELECTED = 0
        this.array.pop()
  
        // console.log(this.array);
  
        this.indeterminate = true
  
      }
  
    }
  
    onAllChecked1(value: boolean): void {
      // console.log(value);
  
      this.dataList.forEach(item => {
        this.isChecked = value
        if (value) {
          if (value == true) {
            this.array.push(item.ID)
            this.checked1 = true
            this.value1=value
            // this.checked=false
            this.indeterminate = false
          }
          // console.log(this.array);
          // item.IS_SELECTED = 1
        } else {
          // item.IS_SELECTED = 0
          this.array = []
          this.checked1 = false
          this.indeterminate = true
        }
  
      });
  
    }
  
  value
  
    drawerClose2(): void { 
      this.drawerVisible1 = false;
      this.search1(true,this.value12,'C',this.STATUS);
     }
    datalist = []
    // Activity(dataa) {
    //   this.drawerTitle1 = "View Activities";
    //   this.drawerVisible1 = true;
    //   this.drawerData2 = Object.assign({}, dataa);
    //   this.drawerData2.ACTIVITY_TYPE_ID = 2
    //   this.drawerData2.CALLING_DATE_TIME = new Date()
    //   this.api.getAllActivityleadMaster(0, 0, '', 'desc', ' AND CUSTOMER_ID = ' + dataa.ID).subscribe(
    //     data => {
    //       if (data['code'] == 200) {
    //         this.datalist = data['data']
    //         // console.log(this.datalist);
    //       }
    //     }, err => {
    //       // console.log(err);
    //     });
    // }
    // this.drawerData1 = new ActivityTypeMaster();}
    edit(data: SMRCOLLAPSEMASTER): void {
      this.drawerTitle = " Forms ";
      this.drawerData1 = Object.assign({}, data);
      // this.drawerData1=Object.assign({},data);
      (localStorage.setItem('editData', JSON.stringify(data.ID)));
      // this.SMRdrawerVisible = true;
      this.collapseDrawerVisible = true;
    }
  
    add(value ): void {
  
      
        this.drawerTitle = " HMIL Leads ";
        this.drawerData = new ServiceModuleExcel();
        this.SMRdrawerVisible = true;
        this.value = value
       
    }
  
    sort(sort: { key: string; value: string }): void {
      this.sortKey = sort.key;
      this.sortValue = sort.value;
      this.search1(true,this.value12,true,this.STATUS);
    }
  
    keyup(event: any) {
      this.search();
  
    }
    YEAR_COUNT: number
    eleven_TO_fourteen_MONTHS_COUNT: number
    fourteen_TO_twentyfour_MONTHS_COUNT: number
    twentyfour_MONTHS_COUNT: number
    AMS_LOST_COUNT: number
    COINFIRMATION_COUNT: number
    MID_TERM_COUNT: number
    NO_SHOW_COUNT: number
    SALES_DATA_COUNT: number
    SERVICE_DONE_COUNT: number
    SERVICE_DUE_COUNT: number
  
    CATEGORY_STATUS
  
    search(reset: boolean = false,) {
      if (reset) {
        this.pageIndex = 1;
        this.sortKey = "id";
        this.sortValue = "desc"
      }
  
      this.loadingRecords = true;
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  
      } catch (error) {
        sort = "";
      }
  
      var likeQuery = "";
  
      // console.log("search text : " + this.searchText);
  
      if (this.searchText != "") {
        likeQuery = " AND";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
  
        likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      }
     
        this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery + this.filterQuery1, this.STARTDATE, this.ENDDATE).subscribe(data => {
          if (data['code'] == 200 )
            this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
    
    
        }, err => {
          // console.log(err);
        });
       
     
    }
    value12:any
    disable1:boolean=false
    oneyear
    appoint
    value13:any
    STATUS:any = 'U'
    primary='primary'
    primary1 = 'default'
    dataList1
  
    search1(reset: boolean = false,value:any,VALUE,status,exportInExcel: boolean = false) {
      if (reset) {
        this.pageIndex = 1;
        this.sortKey = "id";
        this.sortValue = "desc"
      }
  if(status=='U'){
    this.primary='primary'
    this.primary1='default'
  }else if(status=='CD'){
    this.primary='default'
    this.primary1='primary'
  }

  if( this.searchText != ''){
    this.checked1 = false
    this.array = []
    console.log(this.checked1,"this.checked1")
  }
   
  this.STATUS = status
      this.value12=value
      this.value13=VALUE
      this.loadingRecords = true;
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  
      } catch (error) {
        sort = "";
      }
  
      var likeQuery = "";
    
  
      if (this.searchText != "") {
        likeQuery = " AND (";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
  
        likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      } 
  
      this.filterQuery = ` AND HMIL_LOST_STATUS = '${this.STATUS}' AND ASSIGNED_ID = '${this.userid}'`
  
  
      if (exportInExcel == false) {
        this.api.getSMRMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+this.filterQuery  , this.STARTDATE, this.ENDDATE).subscribe(data => {
          if (data['code'] == 200 )
            this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          // console.log(this.dataList,"this.dataList123");
          
    
    
        }, err => {
          // console.log(err);
        });
      }
      else{
        this.exportLoading = true
        this.api.getSMRMastertabledata(0,0, this.sortKey, sort, likeQuery+this.filterQuery , this.STARTDATE, this.ENDDATE).subscribe(data => {
          if (data['code'] == 200 )
          this.exportLoading = false; 
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList1 = data['data']; 
          this.convertInExcel();
    
    
        }, err => {
          // console.log(err);
        });
      }
      
    }
  
    convertInExcel() {
      var arry1 = [];
      var obj1: any = new Object();
      for (var i = 0; i < this.dataList1.length; i++) {

        if (this.dataList1[i]['CATEGORY_STATUS'] == "A") {
          obj1['Category Status'] = "Appointment"
        }
        else if (this.dataList1[i]['CATEGORY_STATUS'] == "CO") {
          obj1['Category Status'] = "Confirmation"
        } else if (this.dataList1[i]['CATEGORY_STATUS'] == "NS") {
          obj1['Category Status'] = "No Show"
        } else if (this.dataList1[i]['CATEGORY_STATUS'] == "MT") {
          obj1['Category Status'] = "Mid Term"
        } else if (this.dataList1[i]['CATEGORY_STATUS'] == "OY") {
          obj1['Category Status'] = "One Year"
        }
        // else if(this.dataList1[i]['CATEGORY_STATUS']=="SE"){
        //   obj1['Category Status'] = "Service "
        // } 
        else if (this.dataList1[i]['CATEGORY_STATUS'] == "WD") {
          obj1['Category Status'] = "Work Done"
        } else if (this.dataList1[i]['CATEGORY_STATUS'] == "L") {
          obj1['Category Status'] = "Lost"
        }
        // else if(this.dataList[i]['CATEGORY_STATUS']=="A"){
        //   obj1['Category Status'] = "Appointment"
        // }
  
  
        else if (this.dataList1[i]['CATEGORY_STATUS'] == "SD") {
          obj1['Category Status'] = "Sales Data"
        }
  
        obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
        obj1['Booking Date'] = this.dataList1[i]['BOOKING_DATE'];
        // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
        obj1['Service Advisor Name'] = this.dataList1[i]['SERVICE_ADVISER_NAME'];
        obj1['BT Number'] = this.dataList1[i]['BT_NUMBER'];
        obj1['BT Date Time'] = this.dataList1[i]['BT_DATE_TIME'];
        
        obj1['Booking Contact Number'] = this.dataList1[i]['BOOKING_CONTACT_NUMBER'];
        obj1['Mobile Number'] = this.dataList1[i]['MOBILE_NUMBER'];
        obj1['Registration No'] = this.dataList1[i]['REGISTRATION_NO'];
        obj1['Work Type'] = this.dataList1[i]['WORK_TYPE'];
        obj1['Express Care'] = this.dataList1[i]['EXPRESS_CARE'];
        obj1['Calling Date'] = this.dataList1[i]['CALLING_DATE'];
        obj1['Calling Status'] = this.dataList1[i]['CALLING_STATUS'];
        obj1['SME Employee ID'] = this.dataList1[i]['SME_EMPLOYEE_ID'];
        obj1['Bill Number'] = this.dataList1[i]['BILL_NUMBER'];
        obj1['Bill Amount'] = this.dataList1[i]['BILL_AMOUNT'];
        obj1['Bill Date'] = this.dataList1[i]['BILL_DATE'];
        obj1['Model'] = this.dataList1[i]['MODEL'];
        obj1['Remark'] = this.dataList1[i]['REMARK'];
        obj1['Reminder Date'] = this.dataList1[i]['REMINDER_DATE'];
        obj1['Followup No.'] = this.dataList1[i]['FOLLOWUP_NO'];
        obj1['Call Date'] = this.dataList1[i]['CALL_DATE'];
        obj1['Service Count'] = this.dataList1[i]['SERVICE_COUNT'];
        obj1['Customer Number'] = this.dataList1[i]['CUST_NO'];
        obj1['Special Message'] = this.dataList1[i]['SPECIAL_MESSAGE'];
        obj1['Ro Date'] = this.dataList1[i]['RO_DATE'];
        obj1['Ro Number'] = this.dataList1[i]['RO_NUMBER'];
        obj1['Number Of Calls'] = this.dataList1[i]['NUMBER_OF_CALLS'];
        obj1['Live Streaming'] = this.dataList1[i]['LIVE_STREAMING'];
        obj1['Status'] = this.dataList1[i]['STATUS'];
        obj1['Delivery Date'] = this.dataList1[i]['DELIVERY_DATE'];
        obj1['Location'] = this.dataList1[i]['LOCATION'];
        obj1['Pincode'] = this.dataList1[i]['PIN_CODE'];
        obj1['Varient'] = this.dataList1[i]['VARIANT'];
        obj1['Chassis Number'] = this.dataList1[i]['CHASSIS_NUMBER'];
        obj1['Sale Consultant Name'] = this.dataList1[i]['SALES_CONSULTANT_NAME'];
        obj1['Sale Date'] = this.dataList1[i]['SALE_DATE'];
        obj1['Last Service type'] = this.dataList1[i]['LAST_SERVICE_TYPE'];
        obj1['Last Ro Date'] = this.dataList1[i]['LAST_RO_DATE'];
        obj1['Segment'] = this.dataList1[i]['SEGMENT'];
        obj1['Last Ro Workshop'] = this.dataList1[i]['LAST_RO_WORKSHOP'];
        obj1['Last Ro Dealer Name'] = this.dataList1[i]['LAST_RO_DEALER_NAME'];
        obj1['Last Ro region'] = this.dataList1[i]['LAST_RO_REGION'];
        obj1['Name'] = this.dataList1[i]['NAME'];
        obj1['Work Phone Number'] = this.dataList1[i]['WORK_PHONE_NUMBER'];
        obj1['Root'] = this.dataList1[i]['ROOT'];
        obj1['SME Name'] = this.dataList1[i]['SME_NAME'];
        obj1['Pick Up and Drop'] = this.dataList1[i]['PICK_UP_AND_DROP'];
        obj1['Lost Customer Name'] = this.dataList1[i]['LOST_CUSTOMER_NAME'];
        obj1['SRV Model'] = this.dataList1[i]['SRV_MODEL'];
        obj1['Email'] = this.dataList1[i]['EMAIL'];
        obj1['Address'] = this.dataList1[i]['ADDRESS'];
        obj1['Purchase Date'] = this.dataList1[i]['PURCHASE_DATE'];
        obj1['Preferred DLR No.e'] = this.dataList1[i]['PREFERRED_DLR_NO'];
        obj1['Office Number'] = this.dataList1[i]['OFFICE_NUMBER'];
        obj1['Resindence Number'] = this.dataList1[i]['RESIDENCE_NUMBER'];
        obj1['Mileage'] = this.dataList1[i]['MILEAGE'];
        obj1['Field 1'] = this.dataList1[i]['FIELD1'];
        obj1['Field 2'] = this.dataList1[i]['FIELD2'];
        obj1['Field 3'] = this.dataList1[i]['FIELD3'];
        obj1['Field 4'] = this.dataList1[i]['FIELD4'];
        obj1['Field 5'] = this.dataList1[i]['FIELD5'];
        obj1['Field 6'] = this.dataList1[i]['FIELD6'];
        obj1['Field 7'] = this.dataList1[i]['FIELD7'];
        obj1['Field 8'] = this.dataList1[i]['FIELD8'];
        obj1['Field 9'] = this.dataList1[i]['FIELD9'];
        obj1['Field 10'] = this.dataList1[i]['FIELD10'];
        // obj1['Resindence Number'] = this.dataList1[i]['NO_OF_INVOICE_GENERATED'];
  
        arry1.push(Object.assign({}, obj1));
        if (i == this.dataList1.length - 1) {
          this._exportService.exportExcel(arry1, 'HMIL Data' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        }
      }
    }

    // datalist = []

    preventSingleClick = false;
  
    timer: any;
  
    delay: Number;
  
    singleClick() {
  
      this.preventSingleClick = false;
  
      const delay = 200;
  
      this.timer = setTimeout(() => {
  
        if (!this.preventSingleClick) {
  
          //Navigate on single click
  
        }
  
      }, delay);
  
    }

    @ViewChild(ActivityleadmasterComponent, { static: false }) ActivityLeadMasterComponentVar: ActivityleadmasterComponent;
    IS_CONFIRMATION_DONE: boolean = true
    CALL_STATUSS
    customername
    mobileno
    ok11: boolean
    hmiltype
    branchId
    LOCATIONs

    Activity(dataa) {
      this.preventSingleClick = true;
  
      this.hmiltype = 'hmil'
      clearTimeout(this.timer);
      this.drawerTitle1 = "View Activities";
      this.drawerVisible1 = true;
      this.CALL_STATUSS = "C"
      this.drawerData2 = Object.assign({}, dataa);
      console.log(dataa);
  
      // if(dataa.ACTIVITY_TYPE_ID == 5 || dataa.ACTIVITY_TYPE_ID == 6){
      this.drawerData2.ACTIVITY_TYPE_ID = 2
      // }else{
      //   this.drawerData2.ACTIVITY_TYPE_ID = 2
      // }
  
      this.drawerData2.CALLING_DATE_TIME = new Date()
      this.drawerData2.CALLING_TIME = new Date()
  
      this.customername = dataa.CUSTOMER_NAME
      this.mobileno = dataa.MOBILE_NUMBER

      this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
        this.LOCATIONs = data['data'];
  
        var i =-1
        i = this.LOCATIONs.findIndex(x => x.ID == this.branchId);
        var roles = this.LOCATIONs[i]['ID']
        if(i > -1){
          this.drawerData2.APPOINTMENT_BRANCH = roles
          console.log(this.drawerData2.APPOINTMENT_BRANCH,"")

        
        }

        if(this.drawerData2.SERVICE_ADVISER_ID != undefined || this.drawerData2.SERVICE_ADVISER_ID != null ||this.drawerData2.SERVICE_ADVISER_ID != '' ){
          this.api.getAllemployeeMaster(0, 0, "", "asc",` AND ROLE_IDS LIKE '%48%' AND BRANCH_ID = '${roles}' `).subscribe(data => {
            if (data['code'] == 200) {
              this.EmployeeList24 = data['data'];
            }
      
          }, err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
        }
      }, err => {
        // this.isSpinning = false;
      });
      // sessionStorage.setItem('customername',dataa.CUSTOMER_NAME)
      // sessionStorage.setItem('mobileno',dataa.MOBILE_NUMBER)
      // this.drawerData2.IS_CONFIRMATION_DONE
  
  
  
      if (this.drawerData2.IS_CONFIRMATION_DONE == 0) {
        this.drawerData2.CALL_STATUS = 'R'
        this.drawerData2.CALL_TYPE = "O"
      }
      else {
  
      }
  
  
  
      // console.log(this.drawerData2, "this.drawerData2")
      // if (this.drawerData2.CALLING_STATUS == 'A' && this.drawerData2.CATEGORY_STATUS == 'C') {
      if (dataa.BOOKING_TIME != null)
        this.drawerData2.BOOKING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.BOOKING_TIME;
  
      if (dataa.SELF_REMINDER_TIME != null)
        this.drawerData2.SELF_REMINDER_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.SELF_REMINDER_TIME;
  
      if (dataa.PICK_UP_TIME != null)
        this.drawerData2.PICK_UP_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.PICK_UP_TIME;
      // }
      if (dataa.REMINDER_TIME != null)
        this.drawerData2.REMINDER_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.REMINDER_TIME;
      // IS_CONFIRMATION_DONE:boolean=true
  
      if (dataa.RESHEDULED_TIME != null)
        this.drawerData2.RESHEDULED_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData2.RESHEDULED_TIME;
  
      // if (dataa.REMINDER_DATE != null)
  
      //   this.drawerData2.REMINDER_DATE = this.datePipe.transform(new Date(), "dd/MM/yyyy") + " " + this.drawerData2.REMINDER_DATE;
  
  
      if (this.drawerData2.CALLING_STATUS == 'A' && this.drawerData2.CATEGORY_STATUS == 'C' && this.drawerData2.IS_CONFIRMATION_DONE == 1) {
        this.IS_CONFIRMATION_DONE = true
  
  
      }
      else {
  
        this.IS_CONFIRMATION_DONE = false
      }
  
  
  
      // console.log(this.IS_CONFIRMATION_DONE, "this.IS_CONFIRMATION_DONE =")
  
      //  this.ActivityLeadMasterComponentVar.pageSize = 8;
      this.ActivityLeadMasterComponentVar.getactivities(dataa);
      // this.drawerData2.ca
  
      this.ok11 = false
      this.getHolidayList()
      this.getEmployeeList1()
  
  
    }
    allHolidayList = []
    today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()
    month = this.today;
    getHolidayList() {
      this.allHolidayList = [];
      var splitmonth = this.month.split("-", -1);
      this.api.getAllHolidays(0, 0, '', 'asc', " AND MONTH(DATE) = '" + splitmonth[1] + "' AND YEAR(DATE) = '" + splitmonth[0] + "'").subscribe(data => {
        if (data['code'] == 200) {
          this.allHolidayList = data['data'];
          // console.log("this.allHolidayList", this.allHolidayList);
  
        }
  
      }, err => {
        // console.log(err);
      });
    }

    EmployeeList24
    getEmployeeList1() {
      this.EmployeeList24 = [];
  
      this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID=48').subscribe(data => {
        if (data['code'] == 200) {
          this.EmployeeList24 = data['data']
        }
  
      })
    }
  }
  