export class quotation {
    ACTION
    APPLIER_ID: number;
    APPLIER_NAME
    APPLIER_REMARK
    APPROVAL_ID: number;
    APPROVAL_REMARK
    APPROVER_NAME
    ARCHIVE_FLAG
    CANCELATION_ID: number;
    CLIENT_ID: number;
    CUSTOMER_NAME: any
    DATE: any
    DEDUCTION_AMOUNT: any
    ID: number;
    IMAGE_PATH:string=""
    IS_CLOSED: boolean
    MODEL_ID: number;
    MODEL_NAME: any
    QUOTATION_ID: number;
    QUOTATION_NO: number;
    SALES_EXECUTIVE_ID: number;
    SALES_EXECUTIVE_NAME: any
    SALES_EXE_REMARK: any
    STATUS: any
    VARIENT_ID: number;
    VARIENT_NAME: any
    CANCELATION_STATUS:any
}