import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { feedbackforms } from 'src/app/Models/feedbackforms';
import { Role } from 'src/app/Models/role';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-feed-back-gmapprove',
  templateUrl: './feed-back-gmapprove.component.html',
  styleUrls: ['./feed-back-gmapprove.component.css']
})
export class FeedBackGMApproveComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: feedbackforms;
  passwordVisible = false;
  password: string;
  isSpinning = false;
  isSpinning1 = false;
  isSpinning2 = false;
  dataList: feedbackforms;
  roleLoading = false;
  roles: Role[];

  constructor(public datePipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.api.getAllfeedarea(0, 0, '', '', '').subscribe(data => {
      this.roles = data['data'];

    },);
  }

  close(): void {
    this.drawerClose();
  }
  ROLE_ID = Number(this.cookie.get('roleId'))

  userId = Number(this.cookie.get("userId"));
  date = new Date()
  status

  save(value): void {
    console.log(this.data.REMARK);

    console.log(this.data.REMARK);
    var isOk = true;

    if (this.data.STATUS == 'FW') {
      if (this.data.GM_REMARK == null || this.data.GM_REMARK.trim() == '') {
        isOk = false;
        this.message.error("Please Enter GM Remark", "");
      }
      else {
        this.data.STATUS = "P"
        this.data.GM_USER_ID = this.userId
      }
    }
    // else if (this.data.STATUS == 'A' && this.data.REMARK != null && this.data.GM_REMARK != null) {
    //   if (this.data.INPROCESS_REMARK == null || this.data.INPROCESS_REMARK.trim() == '') {
    //     isOk = false;
    //     this.message.error("Please Enter In-Process Remark", "");
    //   }
    //   else {
    //     this.data.STATUS = "I"
    //   }
    // }

    else if (this.data.STATUS == 'P' && this.data.REMARK != null && this.data.GM_REMARK != null) {
      this.data.STATUS = "A"
    }

    else {

    }

    // if(this.data.REMARK != null){
    //   console.log(value)
    //   if (value == 'A') {
    //     this.isSpinning = true;
    //   }
    //   else if (value == 'OH') {
    //     this.isSpinning1 = true;

    //   }
    //   else {
    //     this.isSpinning2 = true;

    //   }


    // this.status =value
    // this.data.STATUS = this.status
    if (isOk) {
      this.data.CHECKED_BY_USER_ID = this.userId
      this.data.CHECKED_DATETIME = this.datePipe.transform(this.date, "yyyy-MM-dd")

      if (this.data.ID) {
        this.api.updatefeedform(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            if (value == 'R') {
              this.message.warning("feedback information updated Successfully...", "");
            } else {
              this.message.success("feedback information updated Successfully...", "");
            }

            // if (!addNew)
            this.drawerClose();
            this.isSpinning = false;
            this.isSpinning1 = false;
            this.isSpinning2 = false;

          } else {
            this.message.error("Failed to update feedback information...", "");
            this.isSpinning = false;
            this.isSpinning1 = false;
            this.isSpinning2 = false;

          }
        });

      }
    }
    // }

    // else{
    //   this.message.error('Please enter remark','')
    // }

  }

  reject(): void {

    this.data.STATUS = 'R'
    this.data.CHECKED_BY_USER_ID = this.userId
    this.data.CHECKED_DATETIME = this.datePipe.transform(this.date, "yyyy-MM-dd")

    if (this.data.ID) {
      this.api.updatefeedform(this.data).subscribe(successCode => {
        if (successCode['code'] == "200") {
          // if (value == 'R') {
          //   this.message.warning("feedback information updated Successfully...", "");
          // } 
          //  {
          this.message.success("feedback information updated Successfully...", "");
          // }

          // if (!addNew)
          this.drawerClose();
          this.isSpinning = false;
          this.isSpinning1 = false;
          this.isSpinning2 = false;

        } else {
          this.message.error("Failed to update feedback information...", "");
          this.isSpinning = false;
          this.isSpinning1 = false;
          this.isSpinning2 = false;

        }
      });

    }

  }

  searchEmail(emailId): void {
    var likeQuery = "EMAIL_ID=" + emailId + "";

    this.api.getAllfeedform(0, 0, "", "", likeQuery).subscribe(data => {
      console.log(data);
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  close1(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

}
