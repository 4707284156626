import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-daily-dsireport-report',
  templateUrl: './daily-dsireport-report.component.html',
  styleUrls: ['./daily-dsireport-report.component.css']
})
export class DailyDSIReportReportComponent implements OnInit {

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private message: NzNotificationService) { }

  formTitle = "Daily DSI Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  monthFormat = "MMM-yyyy";
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year

  columns: string[][] = [["CCE_NAME", "CCE"],
  ["CONFIRMATION", "Confirmation"],
  ["NO_SHOW", "No Show"],
  ["REMINDERS", "Reminder"],
  ["TOTAL_FORWORDED", "Total Forworded"],
  ["TOTAL_CALLS", "Total1"],
  ];

  date: any = new Date();
  modalVisible = false;
  isSpinning = false;
  filterClass: string = "filter-invisible";
  sales: any = []
  DATE: any;
  CCE_ID: number
  datalist: any = []
  counter: number = 1;

  ngOnInit() {
    this.getEmployee();
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=50').subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])
        console.log(this.sales);
        this.search(true);
        // this.name = data['data'][0]['NAME'];
      }
      // else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  isVisible: boolean = false

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  openPrint() {
    this.isVisible = true;
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    var filter = ''

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
      filter = " AND MONTH = " + this.MONTH
    }
    else {
      filter = ''
    }
    var filter1 = ''
    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
      filter1 = " AND YEAR = " + this.YEAR
    }
    else {
      filter1 = ''
    }

    var filter2 = ''
    if (this.roleId == 50) {
      filter2 = " AND CCE_ID = " + this.userId
    }
    else {
      filter2 = " AND CCE_ID = " + this.CCE_ID
    }

    // if (this.roleId == 43) {
    //   // if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
    //   this.CCE_ID = this.userId
    //   // }
    // }
    // else {
    //   // if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
    //   this.CCE_ID = this.CCE_ID
    //   // }
    // }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getDailyDSIReport(this.pageIndex, this.pageSize, this.sortKey, sort, filter + filter1 + filter2 + likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.datalist = data['data'];
          this.calculateTotal()
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

          if (this.datalist.length == 0) {
            this.message.error("No Data", "")
          }
        }
      }, err => {
        console.log(err);
      });

    }
  }

  totalRo: any
  TOTALPSF: any
  PFSCONTPER: any
  PSL8RAT: any
  PSL8RATPER: any
  PSL9RAT: any
  PSL9RATPER: any
  PSL10RAT: any
  PSL10RATPER: any

  calculateTotal() {
    this.totalRo = 0
    this.TOTALPSF = 0
    this.PSL8RAT = 0
    this.PSL9RAT = 0
    this.PSL10RAT = 0

    for (let e = 0; e < this.datalist.length; e++) {

      this.totalRo += this.datalist[e]['TOTAL_RO_OPENED']
      this.TOTALPSF += this.datalist[e]['TOTAL_PSF_DONE']

      this.PFSCONTPER = this.TOTALPSF / this.totalRo * 100

      this.PSL8RAT += this.datalist[e]['PS_LESS_8_RATING']

      this.PSL8RATPER = this.PSL8RAT / this.TOTALPSF * 100

      this.PSL9RAT += this.datalist[e]['PS_LESS_9_RATING']

      this.PSL9RATPER = this.PSL9RAT / this.TOTALPSF * 100

      this.PSL10RAT += this.datalist[e]['PS_LESS_10_RATING']

      this.PSL10RATPER = this.PSL10RAT / this.TOTALPSF * 100

    }
  }


  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'
    this.search(true);
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.MONTH1 = new Date()
    this.getEmployee();
    this.isFilterApplied = 'default';
  }

}
