import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ChartOptions, ChartDataSets, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
@Component({
  selector: "app-graphical-attendance-summary-report",
  templateUrl: "./graphical-attendance-summary-report.component.html",
  styleUrls: ["./graphical-attendance-summary-report.component.css"],
})
export class GraphicalAttendanceSummaryReportComponent implements OnInit {
  formTitle = "Graphical Attendance Summary Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;

  orgId = this.cookie.get("orgId");
  START_DATE: any = [];
  END_DATE: any = [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = [];
    this.END_DATE = [];
    this.setDateToDateFilter();
  }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";

    this.countSpins = true;
    this.setDateToDateFilter();
    this.getGraphicalAttendanceSummary(this.date);
  }

  applyFilter() {
    if (
      this.START_DATE != undefined &&
      this.START_DATE != null &&
      this.START_DATE.length >= 0 &&
      this.END_DATE != undefined &&
      this.END_DATE != null &&
      this.END_DATE.length >= 0
    )
      this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getGraphicalAttendanceSummary(this.date);
  }

  sort(sort: { key: string; value: string }): void {}

  EMPLOYEE_COUNT: any = [];
  LEAVES: any = [];
  LATE_MARK: any = [];
  EARLY_MARK: any = [];
  LATE_COMING: any = [];
  PRESENT: any = [];
  ABSENT: any = [];
  date = new Date();
  countSpins = false;
  lineSpins = false;

  public lineChartColors: Color[] = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.0)",
      borderColor: "#bc0250",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = "line";
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
  };

  onLineChange(event) {
    this.EMPLOYEE_COUNT = event;
    if (this.date == undefined || this.date == null) {
      this.message.error("Please Select Month", "");
    } else {
      this.getGraphicalAttendanceSummary(this.date);
    }
  }

  getGraphicalAttendanceSummary(result: Date): void {
    this.lineSpins = true;
    var month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var lastDate = new Date(result.getFullYear(), result.getMonth() + 1, 0);
    this.lineChartLabels = [];
    this.lineChartData = [
      { data: [], label: "Employee Count" },
      { data: [], label: "Leaves" },
      { data: [], label: "Late Mark" },
      { data: [], label: "Early Mark" },
      { data: [], label: "Late Comming" },
      { data: [], label: "Present" },
      { data: [], label: "Absent" },
    ];

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    this.loadingRecords = true;
    this.api
      .getGraphicalAttendanceSummary(
        this.api.orgId,
        fromDateFilter,
        toDateFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            for (var i = 0; i < data["data"].length; i++) {
              this.lineChartLabels.push(data["data"][i]["DATE"]);
              this.lineChartData[0].data.push(
                data["data"][i]["EMPLOYEE_COUNT"]
              );
              this.lineChartData[1].data.push(data["data"][i]["LEAVES"]);
              this.lineChartData[2].data.push(data["data"][i]["LATE_MARK"]);
              this.lineChartData[3].data.push(data["data"][i]["EARLY_MARK"]);
              this.lineChartData[4].data.push(data["data"][i]["LATE_COMING"]);
              this.lineChartData[5].data.push(data["data"][i]["PRESENT"]);
              this.lineChartData[6].data.push(data["data"][i]["ABSENT"]);
            }
          }
          this.lineSpins = false;
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }
}
