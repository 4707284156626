import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branchwisequotationdetails',
  templateUrl: './branchwisequotationdetails.component.html',
  styleUrls: ['./branchwisequotationdetails.component.css']
})
export class BranchwisequotationdetailsComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Branch Wise Quotation Details";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  MODEL: any = [];
  BRANCH: any = [];
  models: any = [];
  branch: any = [];
  frmquotationdate: any;
  toquotationdate: any;
  isOk=true;
  columns: string[][] = [["MODEL_NAME", "Model"], ["QUOTATION_NO", "Quotation Number"], ["BRANCH_NAME", "Branch"], ["SALES_EXECUTIVE_NAME", "Executive"], ["CUSTOMER_NAME", "Customer"], ["TOTAL_AMOUNT", "Total Cost"]];
  exportLoading: boolean;
  dataList1: any;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService:ExportService) { }

  ngOnInit() {
    // this.MODEL="AL";
    // this.BRANCH="AL";
    // this.quotationdate = new Date();
    // this.MODEL = "";
    // this.BRANCH = "";
    this.getAllBranch();
    this.getAllModels();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth() , d = date.getDate();
    this.frmquotationdate = new Date(y, m, d-1)
    this.toquotationdate = new Date()
    this.toquotationdate=this.datePipe.transform(this.toquotationdate,"yyyy-MM-dd")
    // this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
    // this.applyFilter();
    this.api
      .getAllBranch(
        0,
        0,
        'id',
        'asc',
        'AND STATUS=1'
      )
      .subscribe((data) => {
        if (data['code'] == 200) {
          this.branch = data['data'];
          if (this.branch.length > 0) {
            this.BRANCH = [data['data'][0]['ID']];
            // console.log('hdg', this.name);

            console.log(this.branch);
            this.applyFilter();
            // this.name = data['data'][0]['NAME_MR'];
          }
          else { }
        } else {
        }
      });
    this.search(false);
  }
  getAllBranch(){
    this.api.getAllBranch(0, 0, "", "", "").subscribe(data => {
      this.branch = data['data']
      this.search(false);
    })
  }
  getAllModels(){
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data']
      this.onSelectAllModel(true);
      this.search(false);
    })
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }
  SELECT_MODEL: boolean = false;


  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }
  search(reset: boolean,exportInExcel:boolean=false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "";
      this.sortValue = "desc"
      this.dataList = []
    }


    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    // this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,"","","").subscribe(data => {
    //   console.log(data)
    //   if(data['code']==200)
    //   {
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    //   }
    //   else {}
    // }, err => {
    //   console.log(err);
    // });
    if (exportInExcel == false) {
      this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.frmquotationdate,this.toquotationdate, this.MODEL, this.BRANCH).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
        else {
          this.loadingRecords = false;
          this.isSpinning = false
        }
      }, err => {
        this.loadingRecords = false;
        this.isSpinning = false
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getbranchwisequotationdetails(0, 0, this.sortKey, sort, likeQuery,this.frmquotationdate,this.toquotationdate, this.MODEL, this.BRANCH).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.isSpinning = false;
        this.totalRecords = data['count'];
        this.dataList1 = data['data'];
        this.exportLoading = false;
        // this.isFilterApplied="primary";
        this.convertInExcel();
      },
        err => {
          if (err['ok'] == false)
            this.exportLoading = false;
          this.loadingRecords = false;
          this.isSpinning = false;
          this.message.error("Server Not Found", "");
        });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    // this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.frmquotationdate = this.datePipe.transform(this.frmquotationdate, "yyyy-MM-dd")
    this.toquotationdate = this.datePipe.transform(this.toquotationdate, "yyyy-MM-dd")
    // this.quotationdate = this.datePipe.transform(this.quotationdate, "yyyy-MM-dd")
    // this.filterQuery = "";
    // if (this.MODEL != undefined && this.BRANCH != undefined && this.quotationdate != undefined) {
    //   //  this.message.error("Please Select Atleast One Option From The Following",'')
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND BRANCH = " + this.BRANCH + " AND QUOTATION_DATE = " + this.quotationdate;
    //   this.isSpinning = false;
    //   this.loadingRecords = false;
    //   this.isFilterApplied = "primary";
    //   console.log("FilterQuery", this.filterQuery)

    // }
    // else if (this.MODEL != undefined && this.BRANCH == undefined && this.quotationdate == undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL == undefined && this.BRANCH != undefined && this.quotationdate == undefined) {
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL == undefined && this.BRANCH == undefined && this.quotationdate != undefined) {
    //   this.filterQuery = " AND QUOTATION_DATE = " + this.quotationdate;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL != undefined && this.BRANCH != undefined && this.quotationdate == undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND BRANCH = " + this.BRANCH;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL != undefined && this.BRANCH == undefined && this.quotationdate != undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND QUOTATION_DATE = " + this.quotationdate;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL == undefined && this.BRANCH != undefined && this.quotationdate != undefined) {
    //   this.filterQuery = " AND BRANCH = " + this.BRANCH + " AND QUOTATION_DATE = " + this.quotationdate;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    if (this.MODEL == undefined && this.BRANCH == undefined && this.frmquotationdate == undefined && this.toquotationdate == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false;
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if(this.isOk == true){
      this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.frmquotationdate, this.toquotationdate, this.MODEL, this.BRANCH).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false;
          this.isFilterApplied = "primary"
          this.totalRecords = data['count'];
          if (data['count'] > 0) {
            this.dataList = data['data'];
            this.dataList1 = data['data'];
          }
          else {
            this.dataList = [];
            this.dataList1 = [];
          }
          this.search(false);
        }
        else {
          this.loadingRecords = false;
          this.isSpinning = false;
        }
      }, err => {
        console.log(err);
      });
      }
      // else if (this.quotationdate != undefined && this.BRANCH == undefined && this.MODEL == undefined) {
      //   this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "" + this.quotationdate,"","").subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.MODEL != undefined && this.BRANCH == undefined && this.quotationdate == undefined) {
      //   this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "","" + this.MODEL,"").subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.BRANCH != undefined && this.quotationdate == undefined && this.MODEL == undefined) {
      //   this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "","",""+ this.BRANCH).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.BRANCH != undefined && this.quotationdate != undefined && this.MODEL == undefined) {
      //   this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+this.quotationdate,"",""+ this.BRANCH).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.BRANCH != undefined && this.quotationdate == undefined && this.MODEL != undefined) {
      //   this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "",""+this.MODEL,""+ this.BRANCH).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.BRANCH == undefined && this.quotationdate != undefined && this.MODEL != undefined) {
      //   this.api.getbranchwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+this.quotationdate,""+this.MODEL,"").subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
    }
  
  clearFilter() {
    this.MODEL = [];
    // this.frmquotationdate ="";
    this.frmquotationdate = "";
    this.toquotationdate = "";
    this.BRANCH = [];
    //  this.MODEL="AL";
    //  this.frmquotationdate=new Date();
    //  this.frmquotationdate=this.datePipe.transform(this.frmquotationdate,"yyyy-MM-dd")
    //  this.BRANCH="AL";this.SELECT_FUEL =false;
    this.SELECT_MODEL =false;
    this.filterClass = 'filter-invisible';
    this.search(false);
    this.isFilterApplied = "default";
    this.searchText=""
  }
  importInExcel() {
    this.search(true, true);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Quotation Number'] = this.dataList1[i]['QUOTATION_NO'];
      obj1['Branch'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Name Of Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Total Amount'] = this.dataList1[i]['TOTAL_AMOUNT'];
      obj1['Status'] = this.dataList1[i]['STATUS'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch wise Quotation Details' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
}
