import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-model-wise-summary-report",
  templateUrl: "./model-wise-summary-report.component.html",
  styleUrls: ["./model-wise-summary-report.component.css"],
})
export class ModelWiseSummaryReportComponent implements OnInit {
  formTitle = "Model Wise Summary Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;

  orgId = this.cookie.get("orgId");
  date: Date[] = [];
  BRANCH_ID = [];
  MODEL_ID = [];
  VARIENT_ID = [];
  FROMDATE
  TODATE
  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService
  ) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.FROMDATE = new Date();
    this.TODATE = new Date();
    this.setDateTODATE();
    this.getBranches();
    this.getModels();
    this.getModelWiseSummary();
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let ids = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  SELECT_ALL3: boolean = false;
  onSelectAllChecked3(event) {
    this.SELECT_ALL3 = event;
    let ids = [];
    if (this.SELECT_ALL3 == true) {
      for (var i = 0; i < this.varients.length; i++) {
        ids.push(this.varients[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT_ID = ids;
  }

  onSelectOff3(event) {
    var a = this.varients.length;
    var b = this.varients.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL3 = false;
    } else {
      this.SELECT_ALL3 = true;
    }
    this.VARIENT_ID = event;
    if (this.VARIENT_ID.length == 0) {
      this.SELECT_ALL3 = false;
    }
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  models = [];

  getModels() {
    this.models = [];
    this.varients = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

  varients = [];

  getVarients(modelID) {
    this.varients = [];
    this.VARIENT_ID = [];

    this.api
      .getAllVarients(
        0,
        0,
        "NAME",
        "asc",
        " AND MODEL_ID IN (" + modelID + ") AND STATUS=1"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.varients = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  setDateTODATE() {
    let currentDate: Date = new Date();
    this.FROMDATE = currentDate;
    this.TODATE = currentDate;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {

    if (this.FROMDATE.length > 0 && this.TODATE.length > 0) this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getModelWiseSummary(true);
  }

  clearFilter() {
    this.SELECT_ALL1=false;
    this.SELECT_ALL2=false;
    this.SELECT_ALL3=false;

    this.date = [];
    // this.FROMDATE = new Date();
    // this.TODATE = new Date();
    this.BRANCH_ID = [];
    this.MODEL_ID = [];
    this.VARIENT_ID = [];
    this.setDateTODATE();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.ModelWiseSummaryData = [];
    this.getModelWiseSummary(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.ModelWiseSummarySortKey = sort.key;
    this.ModelWiseSummarySortValue = sort.value;
    this.getModelWiseSummary(true);
  }

  ModelWiseSummaryPageIndex = 1;
  ModelWiseSummaryPageSize = 10;
  ModelWiseSummaryTotalRecords = 1;
  ModelWiseSummarySortValue: string = "desc";
  ModelWiseSummarySortKey: string = "MODEL_NAME";
  ModelWiseSummaryData = [];

  columns: string[][] = [
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["QUOTATION_COUNT", "Total Quotation Generated"],
    ["CONVERTED_TO_MEMO", "Converted To Memo"],
    ["CONVERTED_TO_INVOICE", "Converted to Invoice"]
  ];

  exportLoading: boolean = false;
  importInExcel() {
    this.getModelWiseSummary(true, true);
  }
  ModelWiseSummary=[];

  getModelWiseSummary(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.ModelWiseSummaryPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.ModelWiseSummarySortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

  
    if (this.FROMDATE != undefined && this.FROMDATE.length != 0 && this.TODATE != undefined && this.TODATE.length != 0) {
      this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
      this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var modelFilter = "";
    if (this.MODEL_ID != undefined && this.MODEL_ID.length != 0) {
      modelFilter = this.MODEL_ID.toString();
    }

    var varientFilter = "";
    if (this.VARIENT_ID != undefined && this.VARIENT_ID.length != 0) {
      varientFilter = this.VARIENT_ID.toString();
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api
        .getModelWiseSummary(
          this.ModelWiseSummaryPageIndex,
          this.ModelWiseSummaryPageSize,
          this.ModelWiseSummarySortKey,
          sort,
          "",
          this.api.orgId,
          branchFilter,
          this.FROMDATE,
          this.TODATE,
          modelFilter,
          varientFilter
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.loadingRecords = false;
              this.ModelWiseSummaryTotalRecords = data["count"];
              this.ModelWiseSummaryData = data["data"];
            }
          },
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        );
    }
    else {
      this.exportLoading = true;
      this.api
        .getModelWiseSummary(
          0,
          0,
          this.ModelWiseSummarySortKey,
          sort,
          "",
          this.api.orgId,
          branchFilter,
          this.FROMDATE,
          this.TODATE,
          modelFilter,
          varientFilter
        ).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ModelWiseSummary = data['data'];
            this.convertInExcel();
            
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ModelWiseSummary.length; i++) {
      obj1['Model Name'] = this.ModelWiseSummary[i]['MODEL_NAME'];
      obj1['Variant Name'] = this.ModelWiseSummary[i]['VARIENT_NAME'];
      obj1['Branch Name'] = this.ModelWiseSummary[i]['BRANCH_NAME'];
      obj1['Total Quotation Generated'] = this.ModelWiseSummary[i]['QUOTATION_COUNT'];
      obj1['Converted To Memo'] = this.ModelWiseSummary[i]['CONVERTED_TO_MEMO'];
      obj1['Converted to Invoice'] = this.ModelWiseSummary[i]['CONVERTED_TO_INVOICE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.ModelWiseSummary.length - 1) {
        this._exportService.exportExcel(arry1, 'Model Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}

