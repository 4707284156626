export class questionaryMaster {
    QUOTATION_ID
    MEMO_ID
    CORPORATE_DISCOUNT
    EXCHANGE
    RSA
    IS_FINANCE
    FINANCE_TYPE
    BANK_NAME
    CGST_NO
    INSURANCE_TYPE
    VIN_NUMBER:string
    SAMBHADH_SCHEME
    COMPANY_NAME
}