import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-modelwisepricelist',
  templateUrl: './modelwisepricelist.component.html',
  styleUrls: ['./modelwisepricelist.component.css']
})
export class ModelwisepricelistComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Model Wise Price List";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  MODEL: any = [];
  FUELTYPE: any = [];
  TRANSMISSION: any = [];
  models: any = [];
  fueltype: any = [];
  transmission: any = [];
  price: any = [];
  PRICE: any = [];
  isOk = true;
  // isFilterApplied: string = "default";
  columns: string[][] = [["MODEL_NAME", "Model Name"], ["FUEL_NAME", "Fuel Type"], ["TRANSMISSION_NAME", "Transmission"], ["VARIENT_NAME", "Variant Name"], ["INVOICE_AMOUNT", "Invoice Amount"], ["INSURANCE", "Insurance"], ["RTO", "RTO"], ["FASTAG", "Fastag"], ["ACCESSORIES", "Accessories"], ["EX_WARRANTY", "Warranty"]];
  exportLoading: boolean;
  dataList1: any;
  constructor(private api: ApiService, private _exportService: ExportService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.getpricelist();
  }
  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      MODEL:this.MODEL, 
      FUELTYPE:this.FUELTYPE, 
      TRANSMISSION:this.TRANSMISSION, 
      PRICE:this.PRICE
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 16').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  getpricelist() {
    this.api.getAllPriceList(0, 0, "", "", " AND STATUS=1").subscribe(data => {
      this.price = data['data'];
      this.onSelectAllPriceList(true);
      this.getmodels();
    })
  }
  getmodels() {
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data']
      this.onSelectAllModel(true);
      this.getfuel();
    })
  }
  getfuel() {
    this.api.getAllFuel(0, 0, "", "", "").subscribe(data => {
      this.fueltype = data['data']
      this.onSelectAllFuel(true);
      this.gettransmission()
    })
  }
  gettransmission() {
    this.api.getAllTransmission(0, 0, "", "", "").subscribe(data => {
      this.transmission = data['data']
      this.onSelectAllTransmission(true);
      this.search(false);
    })
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }
  importInExcel() {
    this.search(true, true);
  }
  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }
  SELECT_FUEL: boolean = false;
  onSelectAllFuel(event: any) {
    this.SELECT_FUEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_FUEL == true) {
      for (var i = 0; i < this.fueltype.length; i++) {
        ids.push(this.fueltype[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.FUELTYPE = ids
  }
  SELECT_TRANSMISSION: boolean = false;
  onSelectAllTransmission(event: any) {
    this.SELECT_TRANSMISSION = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_TRANSMISSION == true) {
      for (var i = 0; i < this.transmission.length; i++) {
        ids.push(this.transmission[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.TRANSMISSION = ids
  }
  SELECT_PRICELIST: boolean = true;
  onSelectAllPriceList(event: any) {
    this.SELECT_PRICELIST = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_PRICELIST == true) {
      for (var i = 0; i < this.price.length; i++) {
        ids.push(this.price[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.PRICE = ids
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
      // this.dataList = []
    }


    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }

    // this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,this.MODEL,this.FUELTYPE,this.TRANSMISSION,this.PRICE).subscribe(data => {
    //   console.log(data)
    //   if (data['code'] == 200) {
    //     this.loadingRecords = false;
    //     this.isSpinning=false
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //   }
    //   else{
    //     this.loadingRecords = false;
    //     this.isSpinning=false
    //   }
    // }, err => {
    //   this.loadingRecords = false;
    //   this.isSpinning=false
    //   console.log(err);
    // });
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.FUELTYPE, this.TRANSMISSION, this.PRICE).subscribe(data => {
        console.log(data)

        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.isSpinning = false;
        this.filterClass = 'filter-invisible';


      }, err => {
        // this.loadingRecords = false;
        // this.isSpinning=false
        console.log(err);
      });
    }
    else {
      this.exportLoading = false;
      this.api.getmodelwiseprice(0, 0, this.sortKey, sort, likeQuery, this.MODEL, this.FUELTYPE, this.TRANSMISSION, this.PRICE).subscribe(data => {
        console.log(data)
        // this.loadingRecords = false;
        // this.isSpinning = false;
        // this.totalRecords = data['count'];

        this.exportLoading = false;
        this.dataList1 = data['data'];
        // this.isFilterApplied="primary";
        this.convertInExcel();
      },
        err => {
          if (err['ok'] == false)
            //   this.exportLoading = false;
            // this.loadingRecords = false;
            // this.isSpinning = false;
            this.message.error("Server Not Found", "");
        });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    // this.filterQuery = "";
    // if (this.MODEL != undefined && this.FUELTYPE != undefined && this.TRANSMISSION != undefined) {
    //   //  this.message.error("Please Select Atleast One Option From The Following",'')
    //   this.filterQuery = " AND MODEL_NAME = " + this.MODEL + " AND FUEL_TYPE = " + this.FUELTYPE + " AND TRANSMISSION = " + this.TRANSMISSION;
    //   this.isSpinning = false;
    //   this.loadingRecords = false;
    //   this.isFilterApplied = "primary";
    //   console.log("FilterQuery", this.filterQuery)

    // }
    // else if (this.MODEL != undefined && this.FUELTYPE == undefined && this.TRANSMISSION == undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL == undefined && this.FUELTYPE != undefined && this.TRANSMISSION == undefined) {
    //   this.filterQuery = " AND FUEL_TYPE = " + this.FUELTYPE;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL == undefined && this.FUELTYPE == undefined && this.TRANSMISSION != undefined) {
    //   this.filterQuery = " AND TRANSMISSION = " + this.TRANSMISSION;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL != undefined && this.FUELTYPE != undefined && this.TRANSMISSION == undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND FUEL_TYPE = " + this.FUELTYPE;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL != undefined && this.FUELTYPE == undefined && this.TRANSMISSION != undefined) {
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND TRANSMISSION = " + this.TRANSMISSION;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    // else if (this.MODEL == undefined && this.FUELTYPE != undefined && this.TRANSMISSION != undefined) {
    //   this.filterQuery = " AND FUEL_TYPE = " + this.FUELTYPE + " AND TRANSMISSION = " + this.TRANSMISSION;
    //   console.log("FilterQuery", this.filterQuery)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isFilterApplied = "primary";

    // }
    if (this.MODEL == undefined && this.FUELTYPE == undefined && this.TRANSMISSION == undefined && this.PRICE == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false;
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    // else {
    //   // this.applyFilter()
    // }
    // if (this.isOk == true) {
    //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.FUELTYPE, this.TRANSMISSION, this.PRICE).subscribe(data => {
    //     console.log(data)
    //     if (data['code'] == 200) {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //       this.totalRecords = data['count'];
    //       if (data['count'] > 0) {
    //         this.dataList = data['data'];
    //         this.dataList1 = data['data'];
    //       }
    //       else {
    //         this.dataList = [];
    //         this.dataList1 = [];
    //       }
    //       this.isFilterApplied = "primary"
    //     }
    //     else {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //   });
    //   // else if (this.MODEL != undefined && this.FUELTYPE == undefined && this.TRANSMISSION == undefined) {
    //   //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "" + this.MODEL,"","").subscribe(data => {
    //   //     console.log(data)
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    //   // else if (this.TRANSMISSION != undefined && this.FUELTYPE == undefined && this.MODEL == undefined) {
    //   //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "","",""+ this.TRANSMISSION).subscribe(data => {
    //   //     console.log(data)
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    //   // else if (this.FUELTYPE != undefined && this.MODEL == undefined && this.TRANSMISSION == undefined) {
    //   //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "",""+ this.FUELTYPE,"").subscribe(data => {
    //   //     console.log(data)
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    //   // else if (this.FUELTYPE != undefined && this.MODEL != undefined && this.TRANSMISSION == undefined) {
    //   //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+this.MODEL,""+ this.FUELTYPE,"").subscribe(data => {
    //   //     console.log(data)
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    //   // else if (this.FUELTYPE != undefined && this.MODEL == undefined && this.TRANSMISSION != undefined) {
    //   //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "","" + this.FUELTYPE,""+ this.TRANSMISSION).subscribe(data => {
    //   //     console.log(data)
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    //   // else if (this.FUELTYPE == undefined && this.MODEL != undefined && this.TRANSMISSION != undefined) {
    //   //   this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+this.MODEL,"",""+this.TRANSMISSION).subscribe(data => {
    //   //     console.log(data)
    //   //     this.loadingRecords = false;
    //   //     this.isSpinning = false;
    //   //     this.totalRecords = data['count'];
    //   //     this.dataList = data['data'];
    //   //   }, err => {
    //   //     console.log(err);
    //   //   });
    //   // }
    // }
    // else{

    // }
    this.search(true);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Fuel Type'] = this.dataList1[i]['FUEL_NAME'];
      obj1['Transmission'] = this.dataList1[i]['TRANSMISSION'];
      obj1['Varient'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Invoice Amount'] = this.dataList1[i]['INVOICE_AMOUNT'];
      obj1['Insurance'] = this.dataList1[i]['INSURANCE'];
      obj1['RTO'] = this.dataList1[i]['RTO'];
      obj1['Fastag'] = this.dataList1[i]['FASTAG'];
      obj1['Accessories'] = this.dataList1[i]['ACCESSORIES'];
      obj1['Warranty'] = this.dataList1[i]['EX_WARRANTY'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Model wise Price List"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    
    this.SELECT_FUEL = true;
    this.SELECT_MODEL = true;
    this.SELECT_TRANSMISSION = true;
    this.SELECT_PRICELIST=true;
    this.getpricelist();
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue)
  }
}