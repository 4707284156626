import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { Roledetails } from 'src/app/Models/roledetails';

@Component({
  selector: 'app-insurancereport',
  templateUrl: './insurancereport.component.html',
  styleUrls: ['./insurancereport.component.css']
})
export class InsurancereportComponent implements OnInit {

  @Input() data: Roledetails = new Roledetails
  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  menus = [];
  PARENT_ID=240;
  menus1
  PARENT_ID1

constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) {}

ngOnInit(){
    console.log(this.data.PARENT_ID)
    // this.loadForms();
    this.api.getRoleDetails1(this.roleId,"AND PARENT_ID=299").subscribe(data => {
      console.log("data");
      console.log(data['data']);
      this.menus = data['data'];
    }, err => {
      console.log(err);
  });

  this.get()
}


selected:any;
target:any;
// navigateTo(item) {
  
//   this.selected = item.value; 
//     if (item) {
//       this.ngOnInit()
//         this.router.navigate([item]);
//         this.target = item
//         console.log(this.target)
//     }
//     return false;
// }

currentIndex=-1
dataShow:boolean=true;
isSelected:boolean;
sm1: Boolean = undefined;
navigateTo(data,index:number) {
  this.dataShow=false
  this.currentIndex=index
  this.isSelected = true;
  if (data.value === '/generalManagerDiscount') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

get(){
  console.log(this.data.PARENT_ID)
  // this.loadForms();
  this.api.getRoleDetails1(this.roleId,"AND PARENT_ID=303").subscribe(data => {
    console.log("data");
    console.log(data['data']);
    this.menus1 = data['data'];
  }, err => {
    console.log(err);
});
}

backPage(){
  this.dataShow=true
}

}
