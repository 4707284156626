import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { EmployeeRoleMap } from 'src/app/Models/employeerolemap';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addemployeerolemap',
  templateUrl: './addemployeerolemap.component.html',
  styleUrls: ['./addemployeerolemap.component.css']
})

export class AddemployeerolemapComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: EmployeeRoleMap = new EmployeeRoleMap();
  @Input() empdata: EmployeeMaster;
  isSpinning = false
  @Input() listOfData: EmployeeRoleMap[] = [];
  OPEN_TIME
  CLOSE_TIME
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss');
  @Input() DAYS = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  passwordVisible = false;
  BRANCH_ID
  @Input() role: any[] = [];
  dept: any[] = [];
  DEPARTMENT_ID: any[] = [];
  REPORTING_MANAGERS_ID: any[] = [];
  // REPORTING_ACCOUNTANT_ID:any
  ROLE_ID = 0;
  REPORTING_EMP_ID

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.department();
    this.getAllBranches()
  }

  roleinfo() {
    this.api.getAllRoles2(this.cookie.get('roleId'), Number(this.cookie.get('userId'))).subscribe(data => {
      this.role = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  department() {
    this.api.getAllDepartments(0, 0, 'NAME', 'ASC', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      this.dept = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  close(): void {
    this.drawerClose();
  }

  save(data): void {
    console.log(this.data.ROLE_ID == 14);

    this.isSpinning = false;
    this.isOk = true;
    this.data.EMPLOYEE_ID = this.empdata.ID;
    this.data.STATUS = true;

    if (this.data.ROLE_ID == undefined || this.data.ROLE_ID == 0) {
      this.isOk = false;
      this.message.error('Please Select Role', '');
    }

    if ((this.data.ROLE_ID == 6 || this.data.ROLE_ID == 4) && (this.DEPARTMENT_ID == undefined || this.DEPARTMENT_ID.length == 0)) {
      this.isOk = false;
      this.message.error('Please Select Department', '');
    }

    if ((this.data.ROLE_ID == 28 || this.data.ROLE_ID == 29 || this.data.ROLE_ID == 30 || this.data.ROLE_ID == 34) && (this.REPORTING_MANAGERS_ID == undefined || this.REPORTING_MANAGERS_ID.length == 0)) {
      this.isOk = false;
      this.message.error('Please Select Reporting Manager(s)', '');
    }

    if ((this.data.ROLE_ID == 44 ) && (this.data.REPORTING_EMP_ID == undefined || this.data.REPORTING_EMP_ID == 0)) {
      this.isOk = false;
      this.message.error('Please Select Reporting Accountant', '');
    }


    if (this.isOk) {
      this.isSpinning = true;
      var d = [];
      var reportingManagers = [];

      if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length > 0) {
        for (var i = 0; i < this.DEPARTMENT_ID.length; i++) {
          d.push({
            CLIENT_ID: 1,
            EMPLOYEE_ID: this.empdata.ID,
            DEPARTMENT_ID: this.DEPARTMENT_ID[i],
            STATUS: true
          })
        }
      }
      // REPORTING_EMP_ID

      var branchmanager = [];

      if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
        for (var i = 0; i < this.BRANCH_ID.length; i++) {
          branchmanager.push({
            CLIENT_ID: 1,
            EMPLOYEE_ID: this.empdata.ID,
            BRANCH_ID: this.BRANCH_ID[i],
            STATUS: true
          })
        }
      }



      if (this.REPORTING_MANAGERS_ID != undefined && this.REPORTING_MANAGERS_ID.length > 0) {
        for (var i = 0; i < this.REPORTING_MANAGERS_ID.length; i++) {
          reportingManagers.push({
            CLIENT_ID: 1,
            EMPLOYEE_ID: this.empdata.ID,
            ROLE_ID: this.data.ROLE_ID,
            REPORTING_HEAD_ID: this.REPORTING_MANAGERS_ID[i],
            STATUS: true
          })
        }
      }

      this.api.createEmpRoleMap(this.data).subscribe((successCode) => {
        if (successCode.code == 200) {
          if (this.data.ROLE_ID != 6 && this.data.ROLE_ID != 4 && this.data.ROLE_ID != 28 && this.data.ROLE_ID != 29 && this.data.ROLE_ID != 30 && this.data.ROLE_ID != 34 && this.data.ROLE_ID != 14) {
            this.drawerClose();
            this.DEPARTMENT_ID = [];
            this.REPORTING_MANAGERS_ID = [];
            this.message.success('Information Created Successfully', '');
            this.isSpinning = false;

          } else {
            if (this.data.ROLE_ID == 6) {
              this.api.createEmpRoleMapBulk(d, this.empdata.ID).subscribe((successCode) => {
                if (successCode.code == 200) {
                  this.message.success('Information Created Successfully', '');
                  this.drawerClose();
                  this.DEPARTMENT_ID = [];
                  this.REPORTING_MANAGERS_ID = [];
                  this.isSpinning = false;

                } else {
                  this.message.error('Information Creation Failed', '');
                  this.isSpinning = false;
                }
              });
            }

            if (this.data.ROLE_ID == 4) {
              this.api.tickdeskSupportUserMapping(d, this.empdata.ID).subscribe((successCode) => {
                if (successCode.code == 200) {
                  this.message.success('Information Created Successfully', '');
                  this.drawerClose();
                  this.DEPARTMENT_ID = [];
                  this.REPORTING_MANAGERS_ID = [];
                  this.isSpinning = false;

                } else {
                  this.message.error('Information Creation Failed', '');
                  this.isSpinning = false;
                }
              });
            }

            if (this.data.ROLE_ID == 14) {
              this.api.humanResourceHeadMapping(branchmanager, this.empdata.ID).subscribe(successCode => {
                if (successCode.code == 200) {
                  this.drawerClose()
                  this.get()
                  this.BRANCH_ID = []
                  // this.
                  // this.REPORTING_MANAGERS_ID = [];
                  this.isSpinning = false
                }
                else {
                  this.message.error("Information Creation Failed", '')
                  this.isSpinning = false
                }

              })
            }

            if (this.data.ROLE_ID == 28 || this.data.ROLE_ID == 29 || this.data.ROLE_ID == 30 || this.data.ROLE_ID == 34) {
              this.api.salesExecutiveManagerHeadMapping(reportingManagers, this.empdata.ID).subscribe((successCode) => {
                if (successCode.code == 200) {
                  this.message.success('Information Created Successfully', '');
                  this.drawerClose();
                  this.DEPARTMENT_ID = [];
                  this.REPORTING_MANAGERS_ID = [];
                  this.isSpinning = false;
                } else {
                  this.message.error('Information Creation Failed', '');
                  this.isSpinning = false;
                }
              });
            }
          }

        } else {
          this.message.error('Information Creation Failed', '');
          this.isSpinning = false;
        }
      });
    }
  }

  reportingManager: any[] = [];
  orgId = this.cookie.get('orgId');

  reportingManagers(currentEmpID) {
    this.api.getAllemployeeMaster(0, 0, '', '', ' AND STATUS=1' + ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.reportingManager = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  branchesData1 = []
  roleChane(event: any) {
    console.log(event);
    this.data.ROLE_ID = event
    if (this.data.ROLE_ID == 14) {
      this.api.getHrMapData('AND EMPLOYEE_ID = ' + this.empdata.ID).subscribe(successCode => {
        console.log(successCode.data); 
        // this.branchesData1 = successCode['data'].filter((item:any) => item.STATUS == true);
        this.branchesData1 = successCode['data']
        for (let i = 0; i < this.branchesData1.length; i++) {
          this.BRANCH_ID.push(Number(this.branchesData1[i]['BRANCH_ID']));
          if (i + 1 == this.branchesData1.length) {
            this.BRANCH_ID = [...[], ...this.BRANCH_ID];
            console.log(this.BRANCH_ID);
          }
        }
      })
    }
  }


  get(){
    this.api.getHrMapData('AND EMPLOYEE_ID = ' + this.empdata.ID).subscribe(successCode => {
      console.log(successCode.data); 
      this.branchesData1 = successCode['data']
      // for (let i = 0; i < this.branchesData1.length; i++) {
      //   this.BRANCH_ID.push(Number(this.branchesData1[i]['BRANCH_ID']));
      //   if (i + 1 == this.branchesData1.length) {
      //     this.BRANCH_ID = [...[], ...this.BRANCH_ID];
      //     console.log(this.BRANCH_ID);
      //   }
      // }
    })
  }


  reportingManager11: any[] = [];





  reportingManagers11(currentEmpID) {

    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND STATUS=1' + ' AND ORG_ID=' + this.orgId + ' AND ROLE_ID IN (38)').subscribe(data => {

      if (data['code'] == 200) {

        this.reportingManager11 = data['data'];

      }




    }, err => {

      console.log(err);

    });

  }

  branchesData = []
  getAllBranches() {
    var filter = "AND STATUS=1"
    this.api.getAllAttendanceBranch(0, 0, '', '', filter).subscribe(data => {
      this.branchesData = data['data']
      console.log(this.branchesData);

    })
  }
}
