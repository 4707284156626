import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-sales-executive-wise-detailed-memo-report',
  templateUrl: './sales-executive-wise-detailed-memo-report.component.html',
  styleUrls: ['./sales-executive-wise-detailed-memo-report.component.css']
})
export class SalesExecutiveWiseDetailedMemoReportComponent implements OnInit {

  formTitle = "Sales Executive Wise Detailed Memo Report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'SalesExecutiveWiseDetailedMemoReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
  ["BRANCH_NAME", "Branch"], ["QUOTATION_DATE", "Quotation Date"],
  ["CUSTOMER_NAME", "Customer Name"],
  ["QUOTATION_NO", "Quotation No"],
  ["MEMO_NO", "Memo No"],
  ["MEMO_STATUS", "Status"],
  ["PENDING_AT", "Pending At"],
  ["MODEL_NAME", "Model"]];

  STATUS = "AL";
  BRANCH = [];
  SalesExecutive = []
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  // startOpen = false;

  employees = [];
  START_DATE: any;
  END_DATE: any;
  BRANCH_ID: any = [];
  SALES_EXECUTIVE_ID: any = [];
  // MONTH='';
  // YEAR=''
  // LIKE_STRING='';

  isVisible1: boolean = false
  drawerTitle1: string = ''
  AddSchedule() {
    this.isVisible1 = true
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.startValue = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.endValue = new Date();
    this.search();
    // this.applyFilter();
    this.getBranch();
    this.getSalesExecutive();
  }

  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  getSalesExecutive() {
    this.api.getAllEmpRoleMap(0, 0, '', 'asc', 'AND ROLE_ID = 28').subscribe((data1) => {
      console.log(data1);
      this.employees = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  drawerClose1() {
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  // keyup(event: any) {
  //   this.search();
  // }

  dates: any = [];
  today2 = new Date();

  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };
  current = new Date();


  // disabledEndDate = (START_DATE: Date): boolean =>
  //   // Can not select days before today and today
  //   differenceInCalendarDays(START_DATE, this.current) > 0;


  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }

    if (this.SALES_EXECUTIVE_ID != undefined && this.SALES_EXECUTIVE_ID.length > 0) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID
    }

    if (this.MEMO_STATUS != undefined && this.MEMO_STATUS.length > 0) {
      this.MEMO_STATUS = this.MEMO_STATUS
    }

    this.search(true);

    // this.filterClass = "filter-invisible";

  }

  MEMO_STATUS: any = 'APPROVED'
  setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.endValue = new Date();
    this.BRANCH_ID = [];
    this.SALES_EXECUTIVE_ID = [];
    this.MEMO_STATUS = 'APPROVED'
    this.filterQuery = '';
    this.dataList = [];
    this.search(true);
    this.isFilterApplied = 'default';

  }

  // importInExcel

  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  dataList1 = [];
  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    var filter = ''
    filter = ' AND MEMO_STATUS = ' + "'" + this.MEMO_STATUS + "'"

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getSalesExecutiveMemoReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery + filter, this.START_DATE, this.END_DATE, this.BRANCH_ID, this.SALES_EXECUTIVE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.getSalesExecutiveMemoReport(0, 0, this.sortKey, this.sortValue, likeQuery + filter, this.START_DATE, this.END_DATE, this.BRANCH_ID, this.SALES_EXECUTIVE_ID).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Sales Executive Name'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Quotation Date'] = this.datePipe.transform(this.dataList1[i]['QUOTATION_DATE'], 'yyyy-MM-dd');
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Quotation No.'] = this.dataList1[i]['QUOTATION_NO'];

      obj1['Memo No'] = this.dataList1[i]['MEMO_NO'];
      obj1['Pending At'] = this.dataList1[i]['PENDING_AT'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Detailed Memo Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }


  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
}
