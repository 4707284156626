import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { NzNotificationService } from 'ng-zorro-antd';
import * as XLSX from 'xlsx';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-custwisediscountdetail',
  templateUrl: './custwisediscountdetail.component.html',
  styleUrls: ['./custwisediscountdetail.component.css']
})
export class CustwisediscountdetailComponent implements OnInit {
  exportLoading: boolean = false;
  formTitle = "Customer Wise Discount Detailed Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'DeptWise.xlsx';
  dataList = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  dataList1: []
  MONTH: any
  YEARS: any
  SELECT_MODEL: boolean = false;
  SELECT_BRANCH: boolean = false;
  SELECT_SALES_EXECUTIVE: boolean = false;
  SELECT_VARIENT: boolean = false;
  
  columns: string[][] = [
    ["CUSTOMER_NAME", "Customer Name"],
    ["MODEL_NAME", "Model Name"],
    ["VARIENT_NAME", "Variant"],
    ["SALES_EXECUTIVE_NAME", "Sales Executive"],
    ["BRANCH_NAME", "Branch Name"],
    // ["SALES_EXECUTIVE_DISCOUNT", "Sales Executive Discount"],
    // ["APPLIED_DISCOUNT", "Applied Discount"],
    // ["APPROVED_REJECTED", "Approved/Reject"],
    // ["APPROVED_REJECTED_BY", "Approved/Rejected By"],
    // ["APPROVED_DISCOUNT", "Approved Amount"],
    // ["PENDING_AT", "Pending At"],
    // ["TOTAL_DISCOUNT","Total Discount"]
  ]

  MODEL = [];
  BRANCH = [];
  SALES = [];
  VARIENT = [];
  isSpinning = false
  filterClass: string = "filter-invisible";
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  models = []
  branches = []
  salesexecutive = []
  varients = []
  DATE = new Date()
  startValue: any = new Date();
  endValue: any = new Date();
  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private message: NzNotificationService, private _exportService: ExportService,) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.api.getAllModels(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.models = data['data'];
      this.onSelectAllModel(true)
      // this.search(true)
    }, err => {
      console.log(err);
    });

    this.api.getAllBranch(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.branches = data['data'];
      this.onSelectAllBranch(true)
      // this.search(true)
    }, err => {
      console.log(err);
    });

    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      this.salesexecutive = data['data'];
      this.onSelectAllSAleExicutive(true)
      // this.search(true)
    }, err => {
      console.log(err);
    });

    this.api.getAllVarients(0, 0, '', 'ASC', ' AND STATUS = 1').subscribe(data => {
      this.varients = data['data'];
      this.onSelectAllVarient(true)
      // this.search(true)
    }, err => {
      console.log(err);
    });

    setTimeout(() => {
      this.search(false);
    }, 1500)
  }

  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }

  onSelectOff1(event1: any) {
    var a = this.models.length;
    var b = this.models.length - event1.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL = event1;
    if (this.MODEL.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  
  onSelectOff2(event1: any) {
    var a = this.branches.length;
    var b = this.branches.length - event1.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event1;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  onSelectAllSAleExicutive(event: any) {
    this.SELECT_SALES_EXECUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXECUTIVE == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES = ids
  }


  onSelectOff3(event1: any) {
    var a = this.salesexecutive.length;
    var b = this.salesexecutive.length - event1.length;
    if ((a! = b)) {
      this.SELECT_SALES_EXECUTIVE = false;
    } else {
      this.SELECT_SALES_EXECUTIVE = true;
    }
    this.SALES = event1;
    if (this.SALES.length == 0) {
      this.SELECT_SALES_EXECUTIVE = false;
    }
  }

  onSelectAllVarient(event: any) {
    this.SELECT_VARIENT = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_VARIENT == true) {
      for (var i = 0; i < this.varients.length; i++) {
        ids.push(this.varients[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT = ids
  }

  onSelectOff4(event1: any) {
    var a = this.varients.length;
    var b = this.varients.length - event1.length;
    if ((a! = b)) {
      this.SELECT_VARIENT = false;
    } else {
      this.SELECT_VARIENT = true;
    }
    this.VARIENT = event1;
    if (this.VARIENT.length == 0) {
      this.SELECT_VARIENT = false;
    }
  }

  importInExcel() {
    this.search(true, true);
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }


  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }




  clearFilter() {
    this.startValue = new Date();
    this.endValue = new Date();
    this.searchText = '';
    this.filterQuery = "";
    // this.MODEL = [];
    // this.BRANCH = [];
    // this.SALES = [];
    // this.VARIENT = [];
    // this.SELECT_MODEL = false;
    // this.SELECT_BRANCH = false;
    // this.SELECT_SALES_EXECUTIVE = false;
    // this.SELECT_VARIENT = false;


    let ids = [];
    this.SELECT_BRANCH = true
    for (var i = 0; i < this.branches.length; i++) {
      ids.push(this.branches[i]["ID"]);
    }
    this.BRANCH = ids;

    this.SELECT_MODEL = true
    let ids1 = [];

    for (var i = 0; i < this.models.length; i++) {
      ids1.push(this.models[i]["ID"]);
    }
    this.MODEL = ids1;


    this.SELECT_SALES_EXECUTIVE = true
    let ids2 = [];

    for (var i = 0; i < this.salesexecutive.length; i++) {
      ids2.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
    }
    this.SALES = ids2;

    this.SELECT_VARIENT = true
    let ids3 = [];

    for (var i = 0; i < this.varients.length; i++) {
      ids3.push(this.varients[i]["ID"]);
    }
    this.VARIENT = ids3;
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }





  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";


    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.VARIENT != undefined && this.VARIENT.length != 0) {
      this.VARIENT = this.VARIENT;
    }
    if (this.MODEL != undefined && this.MODEL.length != 0) {
      this.MODEL = this.MODEL;
    }
    if (this.SALES != undefined && this.SALES.length != 0) {
      this.SALES = this.SALES;
    }
    if (this.BRANCH != undefined && this.BRANCH.length != 0) {
      this.BRANCH = this.BRANCH;
    }
    this.START_DATE = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.END_DATE, 'yyyy-MM-dd')

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }



    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.customerwisedetaildiscountreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.MODEL, this.SALES, this.VARIENT, this.BRANCH).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
       
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.customerwisedetaildiscountreport(0, 0, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.MODEL, this.SALES, this.VARIENT, this.BRANCH).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }


  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {

      obj1['Customer Name '] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Variant'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Sales Executive Discount '] = this.dataList1[i]['SALES_EXECUTIVE_DISCOUNT'];
      obj1['Applied Discount '] = this.dataList1[i]['APPLIED_DISCOUNT'];
      obj1['Approved/Reject'] = this.dataList1[i]['APPROVED_REJECTED'];
      obj1['Approved/Rejected By '] = this.dataList1[i]['APPROVED_REJECTED_BY'];
      obj1['Approved Amount'] = this.dataList1[i]['APPROVED_DISCOUNT'];
      obj1['Pending At'] = this.dataList1[i]['PENDING_AT'];
      obj1['Total Discount'] = this.dataList1[i]['TOTAL_DISCOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer Wise Discount Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }


  isOk = true
  applyFilter() {
    this.isFilterApplied = 'primary'
    // this.isSpinning = false
   
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    // if (this.MODEL == undefined && this.BRANCH == undefined && this.startValue == undefined && this.endValue == undefined&& this.VARIENT == undefined&& this.SALES == undefined) {
    //   this.message.error("Please Select Option To Filter", '')
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.isOk = false;
    //   this.isFilterApplied = "default";

    // }

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.VARIENT != undefined && this.VARIENT.length != 0) {
      this.VARIENT = this.VARIENT;
    }
    if (this.MODEL != undefined && this.MODEL.length != 0) {
      this.MODEL = this.MODEL;
    }
    if (this.SALES != undefined && this.SALES.length != 0) {
      this.SALES = this.SALES;
    }
    if (this.BRANCH != undefined && this.BRANCH.length != 0) {
      this.BRANCH = this.BRANCH;
    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.search(true);
    }
  }
}
