export class TourMaster {
    ID: number
    CLIENT_ID: number
    EMPLOYEE_ID: number
    EMPLOYEE_NAME 
    DESIGNATION_NAME
    MODE_NAME
    CLASS_NAME
    MODE_ID: number
    CLASS_ID: number
    DESIGNATION_ID: number
    FROM_DATE: any
    TO_DATE: any
    FROM_TIME: any
    TO_TIME: any
    ADVANCE_AMOUNT: any
    REASON: string
    HEAD_REMARK: string
    HEAD_STATUS: string
    HEAD_APPROVED_DATETIME: string
    HR_REMARK: string
    HR_STATUS: string
    HR_APPROVED_DATETIME: string
    ACCOUNTANT_REMARK: string
    ACCOUNTANT_STATUS: string
    ACCOUNTANT_APPROVED_DATETIME: string
    STATUS: string
    APPROVER_ID: number
    GM_STATUS: string
    GM_REMARK:string 
    APPLICATION_DATE
    GM_APPROVED_DATETIME
    PAYMENT_STATUS:string
    EXPECTED_AMOUNT
    APPROVED_AMOUNT
}