import { Component, Input, OnInit } from '@angular/core';
// import { insuranceMaster } from '../Models/incentiveInsurance';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
// import { ApiService } from '../Service/api.service';
import { InsuranceMaster } from '../InsuranceMaster';
import { ApiService } from 'src/app/Service/api.service';
import { insuranceMaster } from 'src/app/Models/incentiveInsurance';
import { directIncentiveRatesDetails } from 'src/app/Models/directIncentiveRateDetails';
import { CookieService } from 'ngx-cookie-service';
// import * as html2pdf from 'html2pdf.js';
// import { directIncentiveRatesDetails } from '../Models/directIncentiveRateDetails';
@Component({
  selector: 'app-insurance-table',
  templateUrl: './insurance-table.component.html',
  styleUrls: ['./insurance-table.component.css']
})
export class InsuranceTableComponent implements OnInit {

  @Input() data: insuranceMaster = new insuranceMaster();
  @Input() directIncentiveRatesDetails: directIncentiveRatesDetails = new directIncentiveRatesDetails();

  constructor(private datepipe: DatePipe, private api: ApiService, private cookie: CookieService) { }

  // DrawerData1:ratebranch=new ratebranch();


  columns: string[][] = [["INCENTIVE_STRUCUTURE_NAME", "Incentive Name"],
  ["FROM_DATE", "From Date"],
  ["TO_DATE", "To date"]]

  formTitle = "Insurance Incentive  Master";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isVisible1 = false;
  isVisible2 = false;
  disabledEndDate2
  selectedIndex = 0
  isVisible21 = false;

  isSpinning = false;
  loadingRecords = false;

  drawerTitle: string | undefined;
  drawerTitle1: string | undefined;
  drawerTitle2: string | undefined;

  drawerTitle21: string | undefined;
  drawerTitle22: string | undefined;

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;


  branchisVisible = false
  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  dataList: any[] = [];
  user: insuranceMaster = new insuranceMaster();
  user1: insuranceMaster = new insuranceMaster();

  orgID = Number(this.cookie.get('orgId'))

  drawerClose3() {
    this.isVisible = false;
    // this.branchisVisible = false
    this.getData();
  }
  get closeCallback() {
    return this.drawerClose3.bind(this)
  }

  drawerClose1() {
    this.isVisible1 = false;
    this.getData();
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this)
  }

  drawerClose21() {
    this.isVisible21 = false;
    this.getData();
  }
  get closeCallback21() {
    return this.drawerClose21.bind(this)
  }
  isVisible22=false;

  drawerClose22() {
    this.isVisible22 = false;
    // this.branchisVisible = false
    this.getData();
  }
  get closeCallback22() {
    return this.drawerClose22.bind(this)
  }


  drawerClose2() {
    this.isVisible2 = false;
    // this.branchisVisible = false
    this.getData();
  }
  get closeCallback2() {
    return this.drawerClose2.bind(this)
  }

  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


    this.api.getInsuranceData(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  getData() {
    this.api.getInsuranceData(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '').subscribe((data: any) => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.totalRecords = data['count'];
      console.log(this.dataList, 'getFunction');
    }, err => {
      console.log(err);
    });
  }
  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }
  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND FROM_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    // if (this.data.ALLOTMENT_TYPE_ID != null) {
    //   this.filterQuery =
    //     this.filterQuery + ' AND ALLOTMENT_TYPE_ID=' + "" + this.data.ALLOTMENT_TYPE_ID + "";
    // }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.api
      .getInsuranceData(0, 0, this.sortKey, sort, this.filterQuery)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = null;
    this.endValue = null;

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }

  modeltype = []
  varienttype = []
  fueltype = []
  tranmission = []
  ngOnInit() {
    this.search();
    this.getData();
    this.getRolesData();
    this.getModels();
    this.getInsuranceIncentiveDetails();
  }

  getModels() {
    this.model = [];

    this.api.getAllModels(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllVarients(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllFuel(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


    this.api.getAllTransmission(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.tranmission = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  add() {
    this.isVisible = true;
    this.drawerTitle = "Create Insurance Incentive";
    this.user1 = new insuranceMaster()


  }

  edit(data: insuranceMaster) {
    this.isVisible = true;
    this.drawerTitle = 'Update Insurance Incentive';
    this.user1 = Object.assign({}, data);
  }

  // ediBranchMap() {
  //   this.isVisible2=true;
  //   this.drawerTitle2 = "Insurance Branch Map"
  //   // this.branchisVisible = true
  // }


  show: any = []
  drawerData1: any

  ediBranchMap(get: insuranceMaster) {
    this.drawerTitle2 = "Insurance Branch Map"
    this.user = Object.assign({}, get);
    console.log(this.user, 'dd')

    this.api.getBranch1(this.cookie.get('orgId'), this.user.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerData1 = data['data'];
      }
      console.log(this.drawerData1, "data")
    }, err => {
      console.log(err);
    });

    this.isVisible2 = true;
  }

  getroles = []
  getRolesData() {
    this.api.getAllRoles(0, 0, '', 'asc', 'AND STATUS=1').subscribe(successCode => {
      this.getroles = successCode['data']
      console.log(this.getroles);
    })
  }
  getModel = [];
  getModelNames() {
    this.api.getAllModels(0, 0, '', '', '').subscribe(successCode => {
      this.getModel = successCode['data']
    })
  }

  getInsuranceIncentiveDetails() {
    this.api.getInsuranceIncentiveRatesDetails(0, 0, '', '', '').subscribe(data => {
      this.directIncentiveRatesDetails = data['data']
    })
  }


  ROLES2 = []
  BRANCHES2 = []
  ROLES22 = []
  BRANCHES22 = []
  drawerData12 = []
  dataa = []
  model = []
  obj = []


  insuranceEdit(data1) {
    console.log(data1);
    this.obj = []
    this.isVisible1 = true;
    this.user = Object.assign({}, data1)
    this.drawerTitle1 = 'Insurance Incentive Role Mapping';
    // this.getRolesData();
    // this.getModelNames();
    // this.getInsuranceIncentiveDetails();
    this.ROLES2 = []
    this.BRANCHES2 = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    // this.user =  Object.assign({}, data1);
    if (data1.ROLE_IDS != null && data1.MODEL_IDS != null) {
      this.ROLES2 = data1.ROLE_IDS.split(",")
      this.BRANCHES2 = data1.MODEL_IDS.split(",")
      // console.log(this.ROLES2);

      // this.BRANCHES2.split(",")
      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    }
    this.dataa = [];

    for (let i = 0; i < this.getroles.length; i++) {

      var kk = this.ROLES2.findIndex(x => x == this.getroles[i]['ID']);
      if (kk > -1) {
        this.getroles[i]['IS_SELECTED'] = true;
      } else {
        this.getroles[i]['IS_SELECTED'] = false;
      }

      console.log(this.getroles);

    }
    // console.log(this.getroles);

    // for (let i = 0; i < this.BRANCHES2.length; i++) {
    //   var kk = this.getModel.findIndex(x => x.ID == this.BRANCHES2[i]);
    //   this.BRANCHES22.push(this.getModel[kk]['NAME']);
    // }

    this.api.getInsuranceIncentiveRatesDetails(0, 0, '', 'asc', ' AND INSURANCE_INCENTIVE_RATES_MASTER_ID=' + data1.ID + " AND INSURANCE_PERCENTAGE_TYPE = 40").subscribe(data => {
      if (data['code'] == 200) {
        this.dataa = data['data'];
        console.log(data['data']);
        this.drawerData12 = this.dataa

      }
    }, err => {
      console.log(err);
    });

  }

  insurancedataEdit(data1) {
    console.log(data1);
    this.obj = []
    this.isVisible21 = true;
    this.user = Object.assign({}, data1)
    this.drawerTitle21 = 'Insurance Incentive Role Mapping';
    // this.getRolesData();
    // this.getModelNames();
    // this.getInsuranceIncentiveDetails();
    this.ROLES2 = []
    this.BRANCHES2 = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    if (data1.ROLE_IDS != null && data1.MODEL_IDS != null) {
      this.ROLES2 = data1.ROLE_IDS.split(",")
      this.BRANCHES2 = data1.MODEL_IDS.split(",")

      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    }
    this.dataa = [];

    for (let i = 0; i < this.getroles.length; i++) {

      var kk = this.ROLES2.findIndex(x => x == this.getroles[i]['ID']);
      if (kk > -1) {
        this.getroles[i]['IS_SELECTED'] = true;
      } else {
        this.getroles[i]['IS_SELECTED'] = false;
      }

      console.log(this.getroles);

    }
    // console.log(this.getroles);

    // for (let i = 0; i < this.BRANCHES2.length; i++) {
    //   var kk = this.getModel.findIndex(x => x.ID == this.BRANCHES2[i]);
    //   this.BRANCHES22.push(this.getModel[kk]['NAME']);
    // }

    this.api.getInsuranceIncentiveRatesDetails(0, 0, '', 'asc', ' AND INSURANCE_INCENTIVE_RATES_MASTER_ID=' + data1.ID + " AND INSURANCE_PERCENTAGE_TYPE = 50").subscribe(data => {
      if (data['code'] == 200) {
        this.dataa = data['data'];
        console.log(data['data']);
        this.drawerData12 = this.dataa

      }
    }, err => {
      console.log(err);
    });

  }

  isApkVersionModalVisible: boolean = false

  handleApkVersionModalCancel() {
    this.isApkVersionModalVisible = false
    this.dataListtt = []
    this.dataListdata = []
    this.MODELSadd = []
    this.drawerData12 = []
    this.obj = []
    this.va = []
  }
  handleApkVersionModalOk() {
    // const printContents = document.getElementById('report_id').innerHTML;

    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();

    // document.body.innerHTML = originalContents;
    // window.location.reload();
    // this.isApkVersionModalVisible=false

    this.generatePDF()

  }

  public generatePDF() {

    // var i = 0;
    // var date = new Date();
    // var datef = this.datePipe.transform(date, "dd-MM-yyyy");
    // var dates = this.datePipe.transform(date, "h:mm:ss a");
    var data = document.getElementById('report_id');

    var opt = {
      margin: 0.2,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
    };
    // html2pdf().set(opt).from(data).save();



  }


  SendData1 = []
  Final = []
  Finalll = []
  va = []
  MODELIDS = []
  ROLEIDS = []
  ROLESSS = []
  dataListtt = []
  MODELSSSS = []
  Roletype = []
  ids = []
  dataaa = []
  dataListdata = []
  AllROLESSS = []
  ROLEIDSSS = []
  MODELSadd = []

  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }
  print(data) {
    this.isApkVersionModalVisible = true
    console.log(data);
    this.ROLES2 = []
    this.AllROLESSS = []

    this.MODELSadd = []
    this.BRANCHES2 = []
    this.drawerData12 = []

    this.ROLESSS = []
    this.va = []

    this.MODELSSSS = []
    this.Roletype = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    this.user = Object.assign({}, data);
    if (data.ROLE_IDS != null && data.MODEL_IDS != null) {
      this.ROLES2 = data.ROLE_IDS.split(",")
      this.BRANCHES2 = data.MODEL_IDS.split(",")
      console.log(this.BRANCHES2);

      // this.BRANCHES2.split(",")
      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    }
    this.dataa = [];
    for (let i = 0; i < this.getroles.length; i++) {
      var kk = this.ROLES2.findIndex(x => x == this.getroles[i]['ID']);
      if (kk > -1) {
        this.getroles[i]['IS_SELECTED'] = true;
      } else {
        this.getroles[i]['IS_SELECTED'] = false;
      }
    }

    this.api.getInsuranceIncentiveRatesDetails(0, 0, '', 'asc', ' AND INSURANCE_INCENTIVE_RATES_MASTER_ID = ' + data.ID + " AND INSURANCE_PERCENTAGE_TYPE = 40").subscribe(data => {
      if (data['code'] == 200) {
        this.dataa = data['data'];
        console.log(data['data']);
        this.drawerData12 = this.dataa
        this.dataListtt = this.drawerData12
      }
    }, err => {
      console.log(err);
    });
    this.SendData1 = []
    this.Final = []

    this.va = []

    this.ROLESSS = this.ROLES2

    this.MODELSSSS = this.obj
    this.Roletype = this.getroles
    this.Finalll = []
    this.MODELIDS = []
    this.ROLEIDS = []



    var abc = -2
    for (let i = 0; i < this.ROLESSS.length; i++) {
      var kk = this.Roletype.findIndex(x => x.ID == this.ROLESSS[i]);
      this.ROLEIDS.push(this.Roletype[kk]['NAME']);
      this.va = [];
      this.SendData1 = [];
      for (let j = 0; j < this.MODELSSSS.length; j++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.MODELSSSS[j]['MODEL1']);
        var ss = this.modeltype[kk]['NAME']
        this.MODELIDS.push(this.modeltype[j]['MODEL1']);

        var a = ''
        var b = ''
        var c = ''
        if (this.MODELSSSS[j]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.MODELSSSS[j]['FULES']);
          a = this.fueltype[kks]['NAME']

        } else {
          this.MODELSSSS[j]['FULES'] = 0

          a = ""
        }

        if (this.MODELSSSS[j]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.MODELSSSS[j]['VARIENT']);
          b = (this.varienttype[aa]['NAME']);
        } else {
          this.MODELSSSS[j]['VARIENT'] = 0
          b = ""
        }

        if (this.MODELSSSS[j]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.MODELSSSS[j]['TRANSMISSION']);
          c = (this.tranmission[vv]['NAME']);
        } else {
          this.MODELSSSS[j]['TRANSMISSION'] = 0
          c = ""

        }

        this.va.push(this.str(ss, a, b, c))
        this.ids.push([this.MODELSSSS[j]['MODEL1'], this.MODELSSSS[j]['FULES'], this.MODELSSSS[j]['VARIENT'], this.MODELSSSS[j]['TRANSMISSION']])
        this.SendData1 = [...this.SendData1, this.MODELSSSS[j]['MODEL1'] + "." + this.MODELSSSS[j]['FULES'] + "." + this.MODELSSSS[j]['VARIENT'] + "." + this.MODELSSSS[j]['TRANSMISSION']];
        // console.log(this.ids);
        console.log(this.va);
        this.Final.push({
          "ROLE_ID": this.ROLESSS[i],
          "MODEL_ID": this.MODELSSSS[j]['MODEL1'],
          "FUEL_TYPE_ID": this.MODELSSSS[j]['FULES'],
          "VARIENT_ID": this.MODELSSSS[j]['VARIENT'],
          "TRANSMISSION_TYPE_ID": this.MODELSSSS[j]['TRANSMISSION'],
          "AMOUNT": 0
        })


      }
      if (i + 1 == this.ROLESSS.length) {

        this.dataListtt = [...[], ...this.Final];
      }

    }


  }



  print2(data) {
    this.isApkVersionModalVisible = true
    console.log(data);
    this.ROLES2 = []
    this.AllROLESSS = []

    this.MODELSadd = []
    this.BRANCHES2 = []
    this.drawerData12 = []

    this.ROLESSS = []
    this.va = []

    this.MODELSSSS = []
    this.Roletype = []

    this.ROLES22 = []
    this.BRANCHES22 = []

    this.user = Object.assign({}, data);
    if (data.ROLE_IDS != null && data.MODEL_IDS != null) {
      this.ROLES2 = data.ROLE_IDS.split(",")
      this.BRANCHES2 = data.MODEL_IDS.split(",")
      console.log(this.BRANCHES2);

      // this.BRANCHES2.split(",")
      for (let index = 0; index < this.BRANCHES2.length; index++) {
        this.model = this.BRANCHES2[index].split(".")
        this.obj.push({ "MODEL1": this.model[0], "FULES": this.model[1], "VARIENT": this.model[2], "TRANSMISSION": this.model[3] }
        )
      }
      console.log(this.obj);

    }
    this.dataa = [];
    for (let i = 0; i < this.getroles.length; i++) {
      var kk = this.ROLES2.findIndex(x => x == this.getroles[i]['ID']);
      if (kk > -1) {
        this.getroles[i]['IS_SELECTED'] = true;
      } else {
        this.getroles[i]['IS_SELECTED'] = false;
      }
    }

    this.api.getInsuranceIncentiveRatesDetails(0, 0, '', 'asc', ' AND INSURANCE_INCENTIVE_RATES_MASTER_ID=' + data.ID + " AND INSURANCE_PERCENTAGE_TYPE = 50").subscribe(data => {
      if (data['code'] == 200) {
        this.dataa = data['data'];
        console.log(data['data']);
        this.drawerData12 = this.dataa
        this.dataListtt = this.drawerData12
      }
    }, err => {
      console.log(err);
    });
    this.SendData1 = []
    this.Final = []

    this.va = []

    this.ROLESSS = this.ROLES2

    this.MODELSSSS = this.obj
    this.Roletype = this.getroles
    this.Finalll = []
    this.MODELIDS = []
    this.ROLEIDS = []



    var abc = -2
    for (let i = 0; i < this.ROLESSS.length; i++) {
      var kk = this.Roletype.findIndex(x => x.ID == this.ROLESSS[i]);
      this.ROLEIDS.push(this.Roletype[kk]['NAME']);
      this.va = [];
      this.SendData1 = [];
      for (let j = 0; j < this.MODELSSSS.length; j++) {
        var kk = this.modeltype.findIndex(x => x.ID == this.MODELSSSS[j]['MODEL1']);
        var ss = this.modeltype[kk]['NAME']
        this.MODELIDS.push(this.modeltype[j]['MODEL1']);

        var a = ''
        var b = ''
        var c = ''
        if (this.MODELSSSS[j]['FULES'] != 0) {
          var kks = this.fueltype.findIndex(x => x.ID == this.MODELSSSS[j]['FULES']);
          a = this.fueltype[kks]['NAME']

        } else {
          this.MODELSSSS[j]['FULES'] = 0

          a = ""
        }

        if (this.MODELSSSS[j]['VARIENT'] != 0) {
          var aa = this.varienttype.findIndex(x => x.ID == this.MODELSSSS[j]['VARIENT']);
          b = (this.varienttype[aa]['NAME']);
        } else {
          this.MODELSSSS[j]['VARIENT'] = 0
          b = ""
        }

        if (this.MODELSSSS[j]['TRANSMISSION'] != 0) {
          var vv = this.tranmission.findIndex(x => x.ID == this.MODELSSSS[j]['TRANSMISSION']);
          c = (this.tranmission[vv]['NAME']);
        } else {
          this.MODELSSSS[j]['TRANSMISSION'] = 0
          c = ""

        }

        this.va.push(this.str(ss, a, b, c))
        this.ids.push([this.MODELSSSS[j]['MODEL1'], this.MODELSSSS[j]['FULES'], this.MODELSSSS[j]['VARIENT'], this.MODELSSSS[j]['TRANSMISSION']])
        this.SendData1 = [...this.SendData1, this.MODELSSSS[j]['MODEL1'] + "." + this.MODELSSSS[j]['FULES'] + "." + this.MODELSSSS[j]['VARIENT'] + "." + this.MODELSSSS[j]['TRANSMISSION']];
        // console.log(this.ids);
        console.log(this.va);
        this.Final.push({
          "ROLE_ID": this.ROLESSS[i],
          "MODEL_ID": this.MODELSSSS[j]['MODEL1'],
          "FUEL_TYPE_ID": this.MODELSSSS[j]['FULES'],
          "VARIENT_ID": this.MODELSSSS[j]['VARIENT'],
          "TRANSMISSION_TYPE_ID": this.MODELSSSS[j]['TRANSMISSION'],
          "AMOUNT": 0
        })


      }
      if (i + 1 == this.ROLESSS.length) {

        this.dataListtt = [...[], ...this.Final];
      }

    }


  }

  getAmount() {

    if (this.dataListdata.length > 0) {
      console.log(this.dataListdata);

      this.dataListdata.forEach((x, index) => {
        this.Finalll.forEach((x2, index2) => {
          if (
            x.MODEL_ID == x2.MODEL_ID &&
            x.FUEL_TYPE_ID == x2.FUEL_TYPE_ID &&
            x.VARIENT_ID == x2.VARIENT_ID &&
            x.TRANSMISSION_TYPE_ID == x2.TRANSMISSION_TYPE_ID) {
            this.Finalll[index2]['AMOUNT'] = this.dataListdata[index].AMOUNT
          }
        })
        if (index + 1 == this.dataListdata.length) {
          this.dataListdata = [...[], ...this.Finalll];


        }
      });
    } else {
      this.dataListdata = [...[], ...this.Finalll];
      console.log(this.dataListdata);
    }



  }

}



