import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
// import { activityMaster } from 'src/app/Models/activityMaster';
import { ApiService } from 'src/app/Service/api.service';
import { activityMaster } from '../activityMaster';

@Component({
  selector: 'app-activity-master-form',
  templateUrl: './activity-master-form.component.html',
  styleUrls: ['./activity-master-form.component.css']
})
export class ActivityMasterFormComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: activityMaster;
  @Input() listOfData2: any = []

  constructor(private message: NzNotificationService, private api: ApiService, private datepipe: DatePipe) { }
  isSpinning = false;

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  date = null;
  isOk = false;
  listOfData: any = []


  ngOnInit() {
    this.getActivityType();
  }
  activities = [];
  // data1 = []
  getActivityType() {
    this.api.getAllActivityTypeMaster(0, 0, '', 'asc', 'AND STATUS=1').subscribe((data1) => {
      // console.log(data1);
      this.activities = data1['data'];
    },
      (err) => {
        // console.log(err);
      }
    );
  }

  getData() {
    this.api.getActivityMaster(0, 0, '', '', 'AND STATUS=1').subscribe((data) => {
      this.listOfData = data['data'];
      // console.log('this.listOfData', this.listOfData);
      // this.listOfOptions1 = data['data'];
      // console.log(this.listOfOptions1,"hhghh");
    })
  }

  onSubmit(addNew: boolean, httpForm: NgForm) {

    this.isOk = true;
    if (this.data.ACTIVITY_TYPE_ID == undefined) {
      // console.log(this.data.ACTIVITY_TYPE_ID)
      this.isOk = false
      this.message.error('Please Select Activity Type.', "")
    }

    else if (this.data.ACTIVITY_NAME == null || this.data.ACTIVITY_NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Activity Name.', "")
    }
    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO == "") {
      this.isOk = false
      this.message.error('Please Enter Sequence No.', "")
    }


    if (this.isOk) {
      this.isSpinning = true;
      if (this.data.ID) {

        this.isSpinning = false;

        this.api.updateActivity(this.data).subscribe((successCode: any) => {
          if (successCode['code'] == 200) {
            this.getData();
            this.message.success("Action Record Updated Successfully...", "");
            // console.log(this.data, 'Update Success')
            if (!addNew) {
              this.drawerClose();
              this.reset(httpForm);
              this.isSpinning = false;
            }
            else {
              this.data = new activityMaster();
              this.message.error("Failed To Update Action Record", "");
              // console.log(this.data, "fail Update")

              this.isSpinning = false;
            }
          }

        });
      }

      else {
        this.isSpinning = true;

        this.api.createActivity(this.data)
          .subscribe(successCode => {

            if (successCode['code'] == 200) {
              this.message.success("Action Record Added Successfully...", "");
              // console.log(this.data, "create Success");
              httpForm.resetForm();
              this.getData();
              if (!addNew) {
                // this.isSpinning = true;
                this.drawerClose();

              }
              else {
                this.data = new activityMaster();
                httpForm.resetForm();

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed To Add Action Record...", "");
              // console.log(this.data, "Fail data")

              this.isSpinning = false;
            }
          });

      }
    }
  }

  close(httpForm: NgForm) {
    this.reset(httpForm);
    this.drawerClose();
  }

  reset(httpForm: NgForm) {
    httpForm.reset();
  }

}
