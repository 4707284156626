import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-bankwisememosummaryreport',
  templateUrl: './bankwisememosummaryreport.component.html',
  styleUrls: ['./bankwisememosummaryreport.component.css']
})
export class BankwisememosummaryreportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Bank Wise Memo Summary";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "QUOTATION_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  isOk = true
  filterClass: string = "filter-invisible";

  // startValue: any = new Date();
  // endValue: any = new Date();
  // isFilterApplied: string = "default";
  // dataList:any[]=[]
  BANK_NAME = [];
  dataList1 = [];
  BRANCH_ID = [];
  bank: any = [];
  branch: any = [];
  DATE: string;
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  startValue:any = new Date();
  endValue: any = new Date();
  SELECT_BANK: boolean = false;
  SELECT_BRANCH: boolean = false;
  exportLoading: boolean = false;
  columns: string[][] = [["BANK_NAME", "Bank Name"], ["BRANCH_NAME", "Branch"], ["CUSTOMER_NAME", "Customer Name"], ["QUOTATION_NO", "Quotation No."], ["CONVERTED_MEMO", "Converted To Memo"], ["MEMO_NO", "Memo No."], ["MODEL_NAME", "Model"], ["STATUS", "Status"],];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }


  importInExcel() {
    this.search(true, true);
  }

  onSelectAllBank(event: any) {
    this.SELECT_BANK = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BANK == true) {
      for (var i = 0; i < this.bank.length; i++) {
        ids.push(this.bank[i]["NAME"]);
      }
    } else {
      ids = [];
    }
    this.BANK_NAME = ids
  }

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }

  ngOnInit() {
    this.getbranches();
    this.getbank();
        
    setTimeout(()=>{
      this.search(false);
    },1000)
    
    // this.api.getQuestionaryMaster(0, 0, 'ID', 'desc', '').subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.bank = data['data'];
    //     console.log(this.bank);
    //   }
    // }, err => {
    //   console.log(err);
    // });
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }


  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
        console.log(this.branch);
        this.onSelectAllBranch(true);
      }
    }, err => {
      console.log(err);
    });
  }

  getbank() {
    this.api.getAllBanks(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.bank = data['data'];
        console.log(this.bank);
        this.onSelectAllBank(true);
      }
    }, err => {
      console.log(err);
    });
  }

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }


  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }
    // this.startValue= new Date(this.y,this.m,1);
    // this.endValue= new Date();
    // this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    // this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.bankwisememoreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.BANK_NAME, this.BRANCH_ID,).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.bankwisememoreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.BANK_NAME, this.BRANCH_ID,).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Bank Name'] = this.dataList1[i]['BANK_NAME'];
      obj1['Branch'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Quotation no.'] = this.dataList1[i]['QUOTATION_NO'];
      obj1['Converted To Memo'] = this.dataList1[i]['CONVERTED_MEMO'];
      obj1['Memo No.'] = this.dataList1[i]['MEMO_NO'];
      obj1['Pending At'] = this.dataList1[i]['PENDING_AT'];
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      if(this.dataList1[i]['STATUS']=='RQ'){
        obj1['Status'] = 'Requested'
      }else if(this.dataList1[i]['STATUS']=='RJ'){
        obj1['Status'] = 'Rejected'
      }else if(this.dataList1[i]['STATUS']=='AP'){
        obj1['Status'] = 'Approved'
      }else if(this.dataList1[i]['STATUS']=='FW'){
        obj1['Status'] = 'Forwarded'
      }else{
        obj1['Status'] = this.dataList1[i]['STATUS']
      }
      
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Bank Wise Memo Summary"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }


  showFilter() {

    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }


  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    this.isFilterApplied = 'primary';
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    // this.BANK_NAME = this.BANK_NAME.toString()
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    if (this.START_DATE == undefined && this.END_DATE == undefined && this.BANK_NAME == undefined && this.BRANCH_ID == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {


      // this.BANK_NAME =this.BANK_NAME.toString(",")
      this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

      this.search(true)
      // this.api.bankwisememoreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE,this.END_DATE, this.BANK_NAME, this.BRANCH_ID,).subscribe(data => {
      //   console.log(data)
      //   this.loadingRecords = false;
      //   this.isSpinning = false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   this.filterClass = 'filter-invisible';
      // }, err => {
      //   console.log(err);
      // });
    }
  }
  clearFilter() {
    this.startValue = new Date();
    this.endValue = new Date();
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.onSelectAllBank(true);
    this.onSelectAllBranch(true);
    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
     this.isFilterApplied="default";
    this.search(true);

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }


}
