import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { TabHeadingDirective } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-salesmanagerwisesdetaileddiscountreport',
  templateUrl: './salesmanagerwisesdetaileddiscountreport.component.html',
  styleUrls: ['./salesmanagerwisesdetaileddiscountreport.component.css']
})
export class SalesmanagerwisesdetaileddiscountreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Sales Manager Wise Sales Executive Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  DATE: any;
  BRANCH: any;
  branch: any = [];
  // BRANCH:any=[];
  SALESEXECUTIVE: any;
  salesexecutive: any = [];
  FROMDATE:any;
  TODATE:any

  // isFilterApplied: string = "default";
  columns: string[][] = [["SALES_MANAGER_NAME", "Sales Manager Name"], ["BRANCH", "Branch"], ["QUOTATION_DATE", "Quotation Date"], ["CUSTOMER_NAME", "Customer Name"], ["QUOTATION_NO", "Quotation No"], ["SALES_CONSULTANT_DISCOUNT", "Sales Consultant Discount"], ["HEAD_APPROVED_DISCOUNT", "Head Approved Discount"], ["TOTAL_DISCOUNT", "Total Discount"], ["MODEL", "Model"]];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
   
    this.FROMDATE = this.datePipe.transform(this.DATE, "yyyy-MM-dd")
    this.TODATE = new Date();
    // this.BRANCH="AL";
    this.getAllbranches();
    // this.SALESEXECUTIVE="AL"
    // this.applyFilter();
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }
  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      if (this.branch.length > 0) {
        this.BRANCH = [data['data'][0]['ID']];
        console.log(this.branch);
      }
      else {
        console.log('Error');
      }
      this.getAllSales();
    }, (err) => {
      console.log(err);
    });
  }
  SELECT_ALL11: boolean = false;

  onSelectAllChecked2(event: any) {
    this.SELECT_ALL11 = event;
    let id = [];
    if (this.SELECT_ALL11 == true) {
      for (var i = 0; i < this.branch.length; i++) {
        id.push(this.branch[i]["ID"]);
      }
    } else {
      id = [];
    }
    this.BRANCH = id;
  }

  getAllSales() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      this.salesexecutive = data['data']
      let ids = [];

      if (this.SELECT_SALES == true) {
        for (var i = 0; i < this.salesexecutive.length; i++) {
          ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
        }
      } else {
        ids = [];
      }
      this.SALESEXECUTIVE = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_SALES: boolean = true;

  onSelectAllSales(event: any) {
    this.SELECT_SALES = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESEXECUTIVE = ids
  }

  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }

    this.FROMDATE = this.datePipe.transform(this.FROMDATE, 'yyyy-MM-dd')
    this.TODATE = this.datePipe.transform(this.TODATE, 'yyyy-MM-dd')


    // if (exportInExcel == false) {
    //   this.loadingRecords = true;
    //   this.api.getcustomerwisequotationdetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.FUELTYPE, this.TRANSMISSION).subscribe(data => {
    //     console.log(data)
    //     if (data['code'] == 200) {
    //       this.loadingRecords = false;
    //       this.isSpinning = false;
    //       this.totalRecords = data['count'];
    //       this.dataList = data['data'];
    //       this.isSpinning = false;
    //       this.filterClass = 'filter-invisible';
    //     }
    //   }, err => {
    //     console.log(err);
    //   });
    // }
    // else {
    //   this.exportLoading = false;
    //   this.api.getcustomerwisequotationdetails(0, 0, this.sortKey, sort, likeQuery, this.MODEL, this.frmdate, this.todate, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.FUELTYPE, this.TRANSMISSION).subscribe(data => {
    //     console.log(data)
    //     this.exportLoading = false;
    //     this.dataList1 = data['data'];
    //     this.convertInExcel();
    //   },
    //     err => {
    //       if (err['ok'] == false)
    //         this.message.error("Server Not Found", "");
    //     });
    // }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // this.SALESEXECUTIVE=this.datePipe.transform(this.SALESEXECUTIVE,"yyyy-MM-dd")
    this.filterQuery = "";
    if (this.DATE != undefined && this.BRANCH != undefined && this.SALESEXECUTIVE != undefined) {
      //  this.message.error("Please Select Atleast One Option From The Following",'')
      this.filterQuery = " AND DATE = " + this.DATE + " AND BRANCH = " + this.BRANCH + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
      this.isSpinning = false;
      this.loadingRecords = false;
      this.isFilterApplied = "primary";
      console.log("FilterQuery", this.filterQuery)

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined) {
      this.filterQuery = " AND DATE = " + this.DATE;
      console.log("FilterQuery", this.filterQuery)
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "primary";

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH != undefined && this.SALESEXECUTIVE == undefined) {
      this.filterQuery = " AND BRANCH = " + this.BRANCH;
      console.log("FilterQuery", this.filterQuery)
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "primary";

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH == undefined && this.SALESEXECUTIVE != undefined) {
      this.filterQuery = " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
      console.log("FilterQuery", this.filterQuery)
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "primary";

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH != undefined && this.SALESEXECUTIVE == undefined) {
      this.filterQuery = " AND DATE = " + this.DATE + " AND BRANCH = " + this.BRANCH;
      console.log("FilterQuery", this.filterQuery)
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "primary";

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH == undefined && this.SALESEXECUTIVE != undefined) {
      this.filterQuery = " AND DATE = " + this.DATE + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
      console.log("FilterQuery", this.filterQuery)
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "primary";

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH != undefined && this.SALESEXECUTIVE != undefined) {
      this.filterQuery = " AND BRANCH = " + this.BRANCH + " AND SALES_EXECUTIVE = " + this.SALESEXECUTIVE;
      console.log("FilterQuery", this.filterQuery)
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "primary";

    }
    else if (this.FROMDATE != undefined && this.TODATE && this.BRANCH == undefined && this.SALESEXECUTIVE == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isFilterApplied = "default";

    }
  }
  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.FROMDATE = this.datePipe.transform(this.DATE, "yyyy-MM-dd")
    this.TODATE = new Date();
    this.SALESEXECUTIVE = "AL";
    this.getAllbranches();
    //  this.SALESEXECUTIVE=this.datePipe.transform(this.SALESEXECUTIVE,"dd-MM-yyyy");
    //  this.BRANCH="AL";
    
    this.SELECT_ALL11 = false
    let ids = [];
    for (var i = 0; i < this.branch.length; i++) {
      ids.push(this.branch[i]["ID"]);
    }
    this.branch = ids;

  }

   sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
}
