import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { AddemployeemasterComponent } from '../addemployeemaster/addemployeemaster.component';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-employeemasterlist',
  templateUrl: './employeemasterlist.component.html',
  styleUrls: ['./employeemasterlist.component.css']
})

export class EmployeemasterlistComponent implements OnInit {
  formTitle = "Manage Employees";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList12 = []
  @Input() dataList2: any[] = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  orgId = this.cookie.get('orgId');
  branchId = this.cookie.get('branchId')
  status = false
  columns: string[][] = [["NAME", "Employee Name"], ["EMAIL_ID", "Email"],
  ["MOBILE_NUMBER", "Mobile Number"], ["DEPARTMENT_NAME", "Department"], ["BRANCH_NAME", "Branch"], ["DESIGNATION_NAME", "Designation"],
  ["EMPLOYEE_REPORTING_HEAD_NAME", "Sanctioning Authority 1"],
  ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Sanctioning Authority 2"], ["EMPLOYEE_CODE", "Employee Code"]]

  columns1: string[][] = [["NAME", "Employee Name"], ["EMAIL_ID", "Email"],
  ["MOBILE_NUMBER", "Mobile Number"], ["DEPARTMENT_NAME", "Department"], ["BRANCH_NAME", "Branch"], ["DESIGNATION_NAME", "Designation"],
  ["EMPLOYEE_REPORTING_HEAD_NAME", "Sanctioning Authority 1"],
  ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Sanctioning Authority 2"], ["EMPLOYEE_CODE", "Employee Code"], ["SEQUENCE_NO", "Sequence no"]]

  drawerVisible: boolean;
  drawerVisible2: boolean;
  drawerVisibleReportPerson: boolean
  drawerTitle: string;
  drawerData: EmployeeMaster = new EmployeeMaster();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  listOfData: EmployeeMaster[] = [];
  OPEN_TIME2 = null;
  CLOSE_TIME2 = null;
  DAYS = false;
  table2 = 0;
  dataList3 = [];
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))
  data24: any;
  exportLoading: boolean = false

  constructor(private _exportService: ExportService, private datePipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
    this.getAttendanceBranches();
    this.getAllfeedarea();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.selectedRoleStatus = this.cookie.get('selectedLoginType');

    var filter = "";
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    if (likeQuery)
      filter += likeQuery;
    if (this.ROLE_ID != 14) {
      this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND ORG_ID =' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId')) + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];

          if (this.totalRecords == 0) {
            data.SEQUENCE_NO = 1;

          } else {
            data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
          }

          // if(data.STATUS==2){


          //   data.STATUS=false
          //   this.fieldsetDisabled3 = true

          // }
          // else{
          //   this.fieldsetDisabled3=false
          // }

          // for(var i=)
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }

    else if (this.ROLE_ID == 14) {
      var branches = ''
      branches = " AND BRANCH_ID IN (" + this.branchId + ")"
      this.loadingRecords = true;
      this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND ORG_ID =' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId')) + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + branches).subscribe((data) => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList1 = data['data'];

          // if (this.totalRecords == 0) {
          //   data.SEQUENCE_NO = 1;

          // } else {
          //   data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
          // }
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
    if (exportInExcel == false) {
      this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];


        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
    else {
      this.loadingRecords = true
      this.exportLoading = true
      this.api.getAllemployeeMaster(0, 0, this.sortKey, sort, likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.exportLoading = false
          this.totalRecords = data['count'];
          this.dataList12 = data['data'];
          this.convertInExcel()

        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }

  }


  branches = [];

  getAttendanceBranches() {
    this.branches = [];

    this.api
      .getAllAttendanceBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  dataList1

  getAllfeedarea() {
    this.dataList1 = [];

    this.api
      .getAllfeedarea(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.dataList1 = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  disableconfirm() {

    // if(this.data.STATUS==false){
    //   this.isVisible=true
    // }
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerData = new EmployeeMaster();
    this.drawerTitle = "Create New Employee";

    this.api.getAllemployeeMaster(1, 1, 'SEQUENCE_NO', 'desc', '' + ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }

    }, err => {
      console.log(err);
    })

    this.drawerVisible = true;
    this.AddemployeemasterComponentVar.fileURL1 = null;

    this.drawerData.BRANCH_ID = 0;
    this.drawerData.DEPARTMENT_ID = 0;
    this.drawerData.DESIGNATION_ID = 0;
    this.drawerData.REPORTING_HEAD_ID = 0;
    this.drawerData.TEMPORARY_HEAD_ID = 0;

    this.drawerData.REPORTING_PERSON_ID = 0;
    this.drawerData.H_PROMISE_ID = 0;
    this.drawerData.CORPORATE_MANAGER_ID = 0;
    this.drawerData.DIRECTOR_ID = 0;
    this.drawerData.ACCOUNT_ID = 0;

    this.AddemployeemasterComponentVar.filterEmployeeList(0);
    this.AddemployeemasterComponentVar.filterHPromiseEmployeeList(0);
    this.AddemployeemasterComponentVar.filterCorporateManagerEmployeeList(0);
    this.AddemployeemasterComponentVar.filterDirectorEmployeeList(0);
    this.AddemployeemasterComponentVar.filterAccountantEmployeeList(0);
    // this.AddemployeerolemapComponentVar.reportingManagers11(this.data.ID);
  }
  fieldsetDisabled3: boolean = false
  @ViewChild(AddemployeemasterComponent, { static: false }) AddemployeemasterComponentVar: AddemployeemasterComponent;

  edit(data: EmployeeMaster): void {
    this.drawerTitle = "Update Employee Information";
    this.drawerData = Object.assign({}, data);
    this.AddemployeemasterComponentVar.getdatat(data.ID)

    if (this.drawerData.DEPARTMENT_ID == 0)
      this.drawerData.DEPARTMENT_ID = 0;

    if (this.drawerData.BRANCH_ID == 0)
      this.drawerData.BRANCH_ID = 0;

    if (this.drawerData.DESIGNATION_ID == 0)
      this.drawerData.DESIGNATION_ID = 0;

    if (this.drawerData.REPORTING_HEAD_ID == 0)
      this.drawerData.REPORTING_HEAD_ID = 0;

    if (this.drawerData.TEMPORARY_HEAD_ID == 0)
      this.drawerData.TEMPORARY_HEAD_ID = 0;

    if ((this.drawerData.REPORTING_PERSON_ID == 0) || (this.drawerData.REPORTING_PERSON_ID == null))
      this.drawerData.REPORTING_PERSON_ID = 0;

    if ((this.drawerData.H_PROMISE_ID == 0) || (this.drawerData.H_PROMISE_ID == null))
      this.drawerData.H_PROMISE_ID = 0;

    if ((this.drawerData.CORPORATE_MANAGER_ID == 0) || (this.drawerData.CORPORATE_MANAGER_ID == null))
      this.drawerData.CORPORATE_MANAGER_ID = 0;

    if ((this.drawerData.DIRECTOR_ID == 0) || (this.drawerData.DIRECTOR_ID == null))
      this.drawerData.DIRECTOR_ID = 0;

    if ((this.drawerData.ACCOUNT_ID == 0) || (this.drawerData.ACCOUNT_ID == null))
      this.drawerData.ACCOUNT_ID = 0;

    this.DAYS = false;
    var d = '';

    if (data.ID != undefined && data.ID > 0) {
      d = ' AND ID!=' + data.ID;
    }

    // if(Number(this.drawerData.STATUS)==2){
    //   this.fieldsetDisabled3=true
    //   console.log("true");

    // }
    // else{
    //   this.fieldsetDisabled3=false
    //   console.log("false");
    // }

    this.AddemployeemasterComponentVar.getallorg2(data.ID);

    this.drawerVisible = true;

    this.AddemployeemasterComponentVar.fileURL1 = null;

    if ((this.drawerData.SIGNATURE != " ") && (this.drawerData.SIGNATURE != null))
      this.drawerData.SIGNATURE = this.api.retriveimgUrl + "signature/" + this.drawerData.SIGNATURE;

    else
      this.drawerData.SIGNATURE = null;

    this.AddemployeemasterComponentVar.filterEmployeeList(data.ID);
    this.AddemployeemasterComponentVar.filterHPromiseEmployeeList(data.ID);
    this.AddemployeemasterComponentVar.filterCorporateManagerEmployeeList(data.ID);
    this.AddemployeemasterComponentVar.filterDirectorEmployeeList(data.ID);
    this.AddemployeemasterComponentVar.filterAccountantEmployeeList(data.ID);
  }
  userId = this.cookie.get('userId')

  shiftdrawerData: EmployeeMaster = new EmployeeMaster();
  Shiftmap
  shiftdrawerVisible: boolean
  listdata1 = []
  array: any = []
  inOut
  CURRENT_SHIFT_ID
  data12
  edit1(data1: EmployeeMaster): void {
    this.Shiftmap = "Shift Mapping";
    this.shiftdrawerData = Object.assign({}, data1);
    console.log(data1, "osw")

    this.shiftdrawerData['CURRENT_SHIFT_ID'] = data1['CURRENT_SHIFT_ID']

    // // console.log( this.shiftdrawerData['CURRENT_SHIFT_ID']);
    // this.CURRENT_SHIFT_ID = data['CURRENT_SHIFT_ID']
    console.log(this.CURRENT_SHIFT_ID);

    this.shiftdrawerVisible = true

    this.array.push({ EMPLOYEE_ID: this.shiftdrawerData.ID })
    console.log(this.array, "wsed");

    this.api.getAllShifts(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        this.api.getstatus(data1.ID, '').subscribe(data1 => {
          if (data1['code'] == 200) {

            if (data1['message'] == "IN") {
              this.inOut = 0;
              console.log(this.inOut);


            } else if (data1['message'] == "OUT") {
              this.inOut = 1;
              console.log(this.inOut);


            } else if (data1['message'] == "DONE") {
              this.inOut = 2;
              console.log(this.inOut);

            }
          }
        });

        this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, '', ' AND ORG_ID =' + this.orgId + ' AND ID =' + data1.ID + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
          if (data['code'] == 200) {
            // this.loadingRecords = false;
            // this.totalRecords = data['count'];
            this.data12 = data['data'];
            // this.CURRENT_SHIFT_IDdata['data'][0]['CURRENT_SHIFT_ID']

            // if (this.totalRecords == 0) {
            //   data.SEQUENCE_NO = 1;

            // } else {
            //   data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
            // }
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
      }

    }, err => {
      console.log(err);
    });





  }

  map(data) {
    this.drawerTitle = "Map Roles";
    this.drawerData = Object.assign({}, data);

    this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND EMPLOYEE_ID=' + data.ID).subscribe(data => {
      this.dataList3 = data['data'];
      this.drawerVisible2 = true;
    });
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  shiftdrawerClose(): void {
    this.search(false);
    this.shiftdrawerVisible = false;
    this.array = []
  }

  get closeCallbackshift() {
    return this.shiftdrawerClose.bind(this);
  }

  drawerClose1(): void {
    this.search(false);
    this.drawerVisible1 = false;
  }

  drawerClose2(): void {
    this.AddemployeemasterComponentVar.getEmployeeList();
    this.AddemployeemasterComponentVar.getHPromiseEmployeeList();
    this.AddemployeemasterComponentVar.getCorporateManagerEmployeeList();
    this.AddemployeemasterComponentVar.getDirectorEmployeeList();
    this.AddemployeemasterComponentVar.getAccountantEmployeeList();

    this.search(false);
    this.drawerVisible2 = false;
  }

  drawerCloseCollege(): void {
    this.search(false);
    this.drawerVisibleEmployeeCollege = false;
  }

  drawerTitle1: string;
  drawerData2: string[];
  drawerData1: EmployeeMaster = new EmployeeMaster();

  expensesMap(data: EmployeeMaster) {
    this.api.getAllEmployeeExpenseHead(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerData2 = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle1 = "Expense Heads for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }

  drawerTitleEmployeeCollege: string;
  drawerVisibleEmployeeCollege: boolean;
  drawerDataEmployeeColleges: string[];

  mapCollege(data: EmployeeMaster) {
    this.api.getAllEmployeeColleges(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerDataEmployeeColleges = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitleEmployeeCollege = "Location for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisibleEmployeeCollege = true;
  }

  get closeCallbackCollege() {
    return this.drawerCloseCollege.bind(this);
  }

  clearDeviceIDForWeb(data: EmployeeMaster) {
    this.api.clearDeviceID(data.ID, "W").subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Web ID Cleared Successfully", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  cancelForWeb(): void { }

  clearDeviceIDForMobile(data: EmployeeMaster) {
    this.api.clearDeviceID(data.ID, "M").subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Mobile ID Cleared Successfully", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  cancelForMobile(): void { }

  onEnterKeyPress() {
    document.getElementById("searchbtn").focus();
  }

  onEmailClick(email) {
    this.api.getLogs(email).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Logs Getting Successfully", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onRoleStatusChange(data: EmployeeMaster, status) {
    data.ROLE_STATUS = status;

    this.api.updateemployeeMaster(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Role Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Role Status", "");

      this.search(false);
    });
  }
  STATUS: any
  isVisible :boolean =false
  DISABLE_REMARK
  status1:any
  // data24
  onActiveStatusChange(data: EmployeeMaster, status) {

    console.log("data")

    this.data24 = data

    data.STATUS= status

    console.log(data.STATUS)

    this.isVisible = true;
    this.isVisiblemodal =false
  }
  DISABLE_DATE = new Date()
  handleOk() {

    if (this.DISABLE_REMARK == "" || this.DISABLE_REMARK == undefined || this.DISABLE_REMARK.toString() == '') {
      this.message.error('Please enter remark', '');
    }
    // else if(this.DISABLE_DATE == null){
    //   this.message.error('Please selc remark', '');
    // }
    else {
      this.data24.STATUS = 2

      this.data24.DISABLE_REMARK = this.DISABLE_REMARK
      this.data24.DISABLE_DATE = this.datePipe.transform(this.DISABLE_DATE, 'yyyy-MM-dd')

      this.api.updateemployeeMaster(this.data24).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Information Updated Successfully", "");
          this.isVisible = false
          this.search(false)
          // this.DISABLE_REMARK=""
          // if(tdata.STATUS==false){
          //   this.fieldsetDisabled3=true
          // }
          // else{
          //   this.fieldsetDisabled3=false
          // }

          // if (!addNew)
          //   this.drawerClose();

          // this.resetDrawer(accountMasterPage);
          // this.isSpinning = false;

        } else {
          this.message.error("Information Updation Failed", "");

        }
      });
    }


  }
  handleCancel() {
    this.isVisible = false
    this.search()
    // this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, '',+ ' AND ORG_ID =' + this.orgId  + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.loadingRecords = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'][0];

  
    //   }

    // }, err => {
    //   if (err['ok'] == false)
    //     this.message.error("Server Not Found", "");
    // });
  
  }

  changeReportingPerson(reportingPersonID: number, data: EmployeeMaster) {
    let obj1 = new Object();
    obj1["EMPLOYEE_ID"] = data.ID;
    obj1["REPORTING_PERSON_ID"] = reportingPersonID;
    obj1["STATUS"] = 1;

    this.api.updateReportingPerson(obj1).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Reporting Person Updated Successfully", "");

      else
        this.message.error("Failed to Update Reporting Person", "");

      this.search(false);
    });
  }

  map2(data: any) {
    this.drawerVisibleReportPerson = true
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.dataList12.length; i++) {

      obj1['UID'] = this.dataList12[i]['ID'];
      obj1['Employee Name'] = this.dataList12[i]['NAME'];
      obj1['Email'] = this.dataList12[i]['EMAIL_ID'];
      obj1['Mobile Number'] = this.dataList12[i]['MOBILE_NUMBER'];
      obj1['Department'] = this.dataList12[i]['DEPARTMENT_NAME'];
      obj1['Branch'] = this.dataList12[i]['BRANCH_NAME'];
      obj1['Sanctioning Authority 1'] = this.dataList12[i]['EMPLOYEE_REPORTING_HEAD_NAME'];
      obj1['Sanctioning Authority 2'] = this.dataList12[i]['EMPLOYEE_TEMPRARY_HEAD_NAME'];
      obj1['Employee Code'] = this.dataList12[i]['EMPLOYEE_CODE'];
      obj1['Disable Remark'] = this.dataList12[i]['DISABLE_REMARK'];
      obj1['Disable Date'] = this.datePipe.transform(this.dataList12[i]['DISABLE_DATE'], 'dd-MM-yy');
      obj1['Sequence No'] = this.dataList12[i]['SEQUENCE_NO'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.dataList12.length - 1) {
        this._exportService.exportExcel(arry1, 'Employee Master' + this.datePipe.transform(new Date(), 'dd-MMM-yy'));
      }
    }
  }

  FEED_AREA_ID
  data246
  BRANCH_ID
  ID: number
  isVisiblemodal :boolean =false

  // openmodal(data) {



  //   this.data246 = data
  //   this.BRANCH_ID = null
  //   this.FEED_AREA_ID = null
  //   console.log(data);

  //   console.log(this.ID, "this.ID");
  //   this.isVisiblemodal = true;
  //   this.api.branchareamappaget(0, 0, '', '', ' AND EMP_ID =' + data.ID).subscribe((data) => {
  //     if (data['code'] == 200) {
  //       // this.FEED_AREA_ID = Number(data['data'][0]['AREAS'])
  //       this.ID = data['data'][0]['ID']
  //       const stringArray = data['data'][0]['BRANCHES'].split(",")
  //       this.BRANCH_ID = stringArray.map(Number);

  //       const stringArray1 = data['data'][0]['AREAS'].split(",")
  //       this.FEED_AREA_ID = stringArray1.map(Number);
  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });
  // }

  openmodal(data) {



    this.data246 = data
    this.BRANCH_ID = null
    this.FEED_AREA_ID = null
    console.log(data);

    console.log(this.ID, "this.ID");
    this.isVisiblemodal = true;
    this.api.branchareamappaget(0, 0, '', '', ' AND EMP_ID =' + data.ID).subscribe((data) => {
      if (data['code'] == 200) {

        this.mapbranch = data['data']
        // this.FEED_AREA_ID = Number(data['data'][0]['AREAS'])
        this.ID = data['data'][0]['ID']
        const stringArray = data['data'][0]['BRANCHES'].split(",")
        this.BRANCH_ID = stringArray.map(Number);

        const stringArray1 = data['data'][0]['AREAS'].split(",")
        this.FEED_AREA_ID = stringArray1.map(Number);
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  handleCancelbranch() {
    this.isVisiblemodal = false
  }

  dataa
  // handleOksave() {
  //   // data246

  //   if (this.BRANCH_ID == null || this.BRANCH_ID == undefined) {
  //     this.message.error('Please select branch', '');
  //   } else if (this.FEED_AREA_ID == undefined || this.FEED_AREA_ID == null) {
  //     this.message.error('Please select feed area', '');
  //   } else {

  //     if (this.ID) {


  //       this.api.branchareamappaddbulk1(1, this.FEED_AREA_ID.toString(), this.BRANCH_ID.toString(), this.data246.ID, 1,this.ID).subscribe(successCode => {
  //         if (successCode['code'] == 200) {
  //           this.message.success("Information Updated Successfully", "");
  //           this.isVisiblemodal = false
  //           this.search(false)

  //         } else {
  //           this.message.error("Information Updation Failed", "");

  //         }
  //       });
  //     }
  //     else {
  //       this.api.branchareamappaddbulk(1, this.FEED_AREA_ID.toString(), this.BRANCH_ID.toString(), this.data246.ID, 1).subscribe(successCode => {
  //         if (successCode['code'] == 200) {
  //           this.message.success("Information Updated Successfully", "");
  //           this.isVisiblemodal = false
  //           this.search(false)

  //         } else {
  //           this.message.error("Information Updation Failed", "");

  //         }
  //       });
  //     }

  //   }


  // }


  modalLoading: boolean = false
  mapbranch:any=[]
  handleOksave() {
    // data246
    if (this.BRANCH_ID == null || this.BRANCH_ID == undefined) {
      this.message.error('Please select branch', '');
    } else if (this.FEED_AREA_ID == undefined || this.FEED_AREA_ID == null) {
      this.message.error('Please select feed area', '');
    } else {
      if (this.mapbranch.length > 0) {
        this.modalLoading = true
        this.api.branchareamappaddbulk1(1, this.FEED_AREA_ID.toString(), this.BRANCH_ID.toString(), this.data246.ID, 1, this.ID).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Information Updated Successfully", "");
            // this.modalLoading = true
            setTimeout(() => {
              this.modalLoading = false;
            }, 1000);
            this.isVisiblemodal = false
            this.search(false)
          } else {
            this.modalLoading = false;
            this.message.error("Information Updation Failed", "");
          }
        });
      }
      else {
        this.modalLoading = true
        this.api.branchareamappaddbulk(1, this.FEED_AREA_ID.toString(), this.BRANCH_ID.toString(), this.data246.ID, 1).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Information Updated Successfully", "")
            setTimeout(() => {
              this.modalLoading = false;
            }, 1000);
            this.isVisiblemodal = false
            this.search(false)
          } else {
            this.modalLoading = false;
            this.message.error("Information Updation Failed", "");
          }
        });
      }
    }
  }
}