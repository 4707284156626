import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-financebankwisesummaryreport',
  templateUrl: './financebankwisesummaryreport.component.html',
  styleUrls: ['./financebankwisesummaryreport.component.css']
})
export class FinancebankwisesummaryreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Finance Bank Wise Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "BANK_NAME";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  DATE: any;
  MODEL: any;
  // BRANCH:any;
  models: any = [];
  FINANCEBANK: any = [];
  financebank: any = [];
  // branch:any=[];
  // quotationdate:any;
  isOk = true;
  columns: string[][] = [["BANK_NAME", "Finance Bank"]];
  month = new Date();
  year = new Date();
  MONTH: any;
  YEAR : any;
  exportLoading: boolean;
  dataList1: any;
  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    // this.MONTH = this.month;
    // this.YEAR = this.year;
    this.getAllBanks();
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    // this.search(false);
  }
  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = { 
      MONTH:this.MONTH, 
      YEAR:this.YEAR, 
      FINANCEBANK:this.FINANCEBANK
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 65').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }
  getAllBanks() {
    this.api.getAllBanks(0, 0, "", "", "").subscribe(data => {
      this.financebank = data['data']
      this.onSelectAllBanks(true);
      this.search(false);
    })
  }

  SELECT_BANKS: boolean = false;
  onSelectAllBanks(event: any) {
    this.SELECT_BANKS = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BANKS == true) {
      for (var i = 0; i < this.financebank.length; i++) {
        ids.push(this.financebank[i]["NAME"]);
      }
    } else {
      ids = [];
    }
    this.FINANCEBANK = ids
  }
  importInExcel() {
    this.search(true, true);
  }
  monthchange(event: any) {
    this.MONTH = this.datePipe.transform(this.month, "MM")
  }
  yearchange(event: any) {
    this.YEAR = this.datePipe.transform(this.year, "yyyy")
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    if (reset) {
      this.pageIndex = 1;
      // this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (exportInExcel == false) {
      this.api.getfinancebankwisesummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.FINANCEBANK).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
        else {
          this.loadingRecords = false;
          this.isSpinning = false;
        }
      }, err => {
        console.log(err);
        this.loadingRecords = false;
        this.isSpinning = false;
      });
    }
    else {
      this.exportLoading = true;
      this.api.getfinancebankwisesummaryreport(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.FINANCEBANK).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.loadingRecords = false;
          this.isSpinning = false;
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.exportLoading = false;
          this.loadingRecords = false;
          this.isSpinning = false;
          this.message.error("Server Not Found", "");
        });
      //  this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,"").subscribe(data => {
      //     console.log(data)
      //     if(data['code']==200)
      //     this.loadingRecords = false;
      //     this.isSpinning=false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.pageIndex = 1;
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // this.quotationdate=this.datePipe.transform(this.quotationdate,"yyyy-MM-dd")
    if (this.FINANCEBANK == undefined && this.MONTH == undefined && this.YEAR == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false;
      this.isFilterApplied = "default";
    }
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      // this.api.getModelwisequotationsummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,""+this.MODEL).subscribe(data => {
      //   console.log(data)
      //   if(data['code']==200)
      //   {
      //   this.loadingRecords = false;
      //   this.isSpinning=false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   }
      //   else{}
      // }, err => {
      //   console.log(err);
      // });
      this.api.getfinancebankwisesummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.FINANCEBANK).subscribe(data => {
        if (data['code'] == 200) {
          // this.exportLoading = false;
          this.dataList1 = data['data'];
          this.loadingRecords = false;
          this.isSpinning = false;
          this.search(false)
          // this.convertInExcel();
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
        else {
          this.loadingRecords = false;
          this.isSpinning = false;
        }
      }, err => {
        console.log(err);
        this.loadingRecords = false;
        this.isSpinning = false;
      });
    }
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.FINANCEBANK = [];
    //  this.MONTH=[];
    //  this.YEAR=[];
    this.month = new Date();
    this.year = new Date();
    this.SELECT_BANKS = false;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    //  this.BRANCH="";
    this.searchText = ""
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(false);

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Finance Bank'] = this.dataList1[i]['BANK_NAME'];
      obj1['In House'] = this.dataList1[i]['INHOUSE'];
      obj1['Out House'] = this.dataList1[i]['OUTHOUSE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Finance Bank Wise Summary Report"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}