import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import * as XLSX from 'xlsx';
import { NzNotificationService } from 'ng-zorro-antd';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-modelwisesummary',
  templateUrl: './modelwisesummary.component.html',
  styleUrls: ['./modelwisesummary.component.css']
})
export class ModelwisesummaryComponent implements OnInit {

  formTitle = "Model Wise Invoice Summary Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'OptionWise.xlsx';
  dataList = [];
  dataList1 = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  y = new Date().getFullYear();
  m = new Date().getMonth();
  yesterday = new Date().getDate();
  startValue:any = new Date(this.y,this.m,this.yesterday-1);
  endValue: any = new Date();
  columns: string[][] = [["MODEL_NAME", "Modal Name"], ]
  HEAD = "AL";
  DATE = new Date()
  isSpinning = false
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'))
  departmentId = Number(this.cookie.get('departmentId'))
  MONTH: any

  date1 = new Date();
  date2 = new Date();
  today = new Date();
  YEAR: any
  MODEL_ID:any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  MODELS:any=[]
  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  models = []
  supportusers = []
  SELECT_MODEL: boolean = false;
  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService,private message: NzNotificationService,private _exportService: ExportService) { }

  ngOnInit() {
   
    // this.startValue =this.startValue.setDate(this.startValue.getDate() - 1);
    // this.endValue = new Date()
    this.getmodels();
    setTimeout(()=>{
      this.search(false)
    },1000);
  }


  getmodels() {
    this.api.getAllModels(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.models = data['data'];
        console.log(this.models);
        this.onSelectAllModel(true);
      }
    }, err => {
      console.log(err);
    });
  }

  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODELS = ids
  }

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };
  
  START_DATE:any
  END_DATE:any
  endOpen = false;
  // disabledEndDate = (END_DATE: Date): boolean => {
  //   if (!END_DATE || !this.START_DATE) {
  //     return false;
  //   }
  //   return END_DATE.getTime() < this.START_DATE - 1;
  // };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }


  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.Modelwiseinvoicesummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODELS, this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          // this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.Modelwiseinvoicesummaryreport(0,0, this.sortKey, sort, likeQuery, this.MODELS, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Advance Collected'] = this.dataList1[i]['INVOICE_GENERATED'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Model Wise Invoice Summary Report ' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  isOk = true

  applyFilter(){
    this.isSpinning = false
    this.loadingRecords = false;
    this.isFilterApplied = 'primary'
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    

    if(this.START_DATE ==undefined && this.END_DATE ==undefined && this.MODELS == undefined ){
      this.message.error("Please Select Option To Filter",'')
      this.loadingRecords=false;
      this.isSpinning=false;
 
      this.isFilterApplied="default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if(this.isOk == true){
      this.MODELS = this.MODELS;
      this.YEAR = this.datePipe.transform(this.YEAR, "yyyy")
      this.MONTH = this.datePipe.transform(this.MONTH, "MM")

    
    // this.api.Modelwiseinvoicesummaryreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "" +this.MODELS, this.MONTH, this.YEAR).subscribe(data => {
    //   console.log(data)
    //   this.loadingRecords = false;
    //   this.isSpinning=false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    //   this.filterClass = 'filter-invisible';
    // }, err => {
    //   console.log(err);
    // });
    this.search(true)
  }
  }

  clearFilter() {
    // this.startValue = '';
    // this.endValue = "";
    this.startValue = new Date(this.y,this.m,this.yesterday-1);
    this.endValue = new Date();
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    // this.MODELS = [];
    // this.SELECT_MODEL = false;
    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
     this.isFilterApplied="default";
    this.search(true);

  }
}


