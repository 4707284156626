import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-salesexecutivewiseinhouseinsurancesummaryreport',
  templateUrl: './salesexecutivewiseinhouseinsurancesummaryreport.component.html',
  styleUrls: ['./salesexecutivewiseinhouseinsurancesummaryreport.component.css']
})
export class SalesexecutivewiseinhouseinsurancesummaryreportComponent implements OnInit {
  exportLoading: boolean = false;
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Sales Executive Wise Inhouse Insurance Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  START_DATE: any;
  END_DATE: any;
  MODEL: any;
  date1 = new Date();
  date2 = new Date();
  SALES_EXECUTIVE=[];
  BRANCH: any;
  branch: any = [];
  Saleshead: any = [];
  quotationdate: any;
   startValue: any = new Date();
  endValue: any = new Date();
  SELECT_SALES_EXECUTIVE:boolean=false
  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"], ];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe ,private _exportService: ExportService,) { }

  ngOnInit() {
    this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    this.endValue = new Date();
    this.getsalehead();
    setTimeout(()=>{
      this.search(false);
    },1000);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }


  onSelectAllSelesExicutive(event: any) {
    this.SELECT_SALES_EXECUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXECUTIVE == true) {
      for (var i = 0; i < this.Saleshead.length; i++) {
        ids.push(this.Saleshead[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE = ids
  }

  onSelectOff(event: any) {
    // var branchmanager1 = []
    //  console.log(event)
    this.SALES_EXECUTIVE = event
    // this.BRANCH_ID=[]
    if (this.SALES_EXECUTIVE != undefined) {


      this.SALES_EXECUTIVE = this.SALES_EXECUTIVE
      this.SELECT_SALES_EXECUTIVE=false
      console.log(this.SALES_EXECUTIVE, " console.log(this.BRANCH_ID);")
    }


    else {
      this.SALES_EXECUTIVE = []
      console.log(this.SALES_EXECUTIVE, "");
    }



  }

  
  importInExcel() {
    this.search(true, true);
  }

  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      if (data['code'] == 200) {
        this.Saleshead = data['data'];
        console.log(this.Saleshead);
        this.onSelectAllSelesExicutive(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }

  endOpen = false;


  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.Salesexecutiveinhouseinsurancesummary(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,this.START_DATE,this.END_DATE,this.SALES_EXECUTIVE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.Salesexecutiveinhouseinsurancesummary(0, 0, this.sortKey, sort, likeQuery,this.START_DATE,this.END_DATE,this.SALES_EXECUTIVE).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Sales Executive Name'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Total Inhouse Insurance'] = this.dataList1[i]['TOTAL_INHOUSE_INSURANCE'];
     
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Inhouse Insurance Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
 
  isOk=true

  applyFilter(){
    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    

    if(this.START_DATE ==undefined && this.END_DATE ==undefined && this.SALES_EXECUTIVE == undefined  ){
      this.message.error("Please Select Option To Filter",'')
      this.loadingRecords=false;
      this.isSpinning=false;
      this.isOk=false
      this.isFilterApplied="default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if(this.isOk == true){
      this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    // this.api.Salesexecutiveinhouseinsurancesummary(this.pageIndex, this.pageSize, this.sortKey, sort,likeQuery,this.START_DATE ,this.END_DATE,this.SALES_EXECUTIVE ,).subscribe(data => {
    //   console.log(data)
    //   this.loadingRecords = false;
    //   this.isSpinning=false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    //   this.filterClass = 'filter-invisible';
    // }, err => {
    //   console.log(err);
    // });
    this.search(true);
  }
  }
  
  clearFilter() {
    this.SALES_EXECUTIVE = [];
    this.SELECT_SALES_EXECUTIVE=false
    this.startValue = new Date();
    this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), +1)
    this.endValue = new Date();
    // this.endValue = new Date();
    this.searchText = '';
    this.filterQuery = '';
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(true);

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

}
