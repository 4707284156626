import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-resolverwise-feed-back-complaint-summary-report',
  templateUrl: './resolverwise-feed-back-complaint-summary-report.component.html',
  styleUrls: ['./resolverwise-feed-back-complaint-summary-report.component.css']
})
export class ResolverwiseFeedBackComplaintSummaryReportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Resolver Wise Feedback/Complaint Summary Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  quotationdate: any;

  models: any = [];
  branch: any = [];

  isOk = true;
  columns: string[][] = [["NAME", "User Name"], ["TYPE", "Type"]];
  dataList1: any;

  FROM_DATE: any
  TO_DATE: any
  MEMO_START_DATE: any
  MEMO_END_DATE: any
  MODEL_ID: any = []
  BRANCH_ID: any = []
  DATE = [];
  DATE2 = [];
  current = new Date();

  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
    this.getAllbranch()
    this.search(true)


  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any
  ids1:any
  getAllbranch() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {

      this.branch = data['data'];
      console.log(this.branch);
      let ids = [];
      // this.SELECT_BRANCH == true
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
  
      this.BRANCH_ID = ids

      console.log(this.BRANCH_ID,"this.BRANCH_ID")

    }, err => {
      console.log(err);
    });
  }

  SELECT_BRANCH: boolean = true;

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }

  onSelectOff1(event) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  TYPE: any = "F"
  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }
  drawerClose1() {

  }
  search(reset: boolean, exportInExcel: boolean = false) {
    console.log('userwise')
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }


    if (this.DATE != undefined && this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }



    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0 ) {
      this.BRANCH_ID =  this.BRANCH_ID
    }
    console.log(this.BRANCH_ID,"this.BRANCH_ID")
    // else{
    //   this.BRANCH_ID = this.BRANCH_ID
    // }

    if (this.TYPE != undefined || this.TYPE != null) {
      this.TYPE = this.TYPE;
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getresolverwisefeedbackReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.FROM_DATE, this.TO_DATE, this.TYPE, this.BRANCH_ID).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.isSpinning = false;
        this.filterClass = 'filter-invisible';

      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = false;
      this.api.getresolverwisefeedbackReport(0, 0, this.sortKey, sort, likeQuery, this.FROM_DATE, this.TO_DATE, this.TYPE, this.BRANCH_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }


  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary';
    this.search(true);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE = [];
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();

    this.TYPE = "F"

    // let ids = [];
    // this.SELECT_BRANCH == true
    // for (var i = 0; i < this.branch.length; i++) {
    //   ids.push(this.branch[i]["ID"]);
    // }

    this.BRANCH_ID = []

    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Resolver Name'] = this.dataList1[i]['NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      if (this.dataList1[i]['FEEDBACK_TYPE'] == 'F') {
        obj1['Feedback/Complaint'] = 'Feedback';
      }
      else {
        obj1['Feedback/Complaint'] = 'Complaint';
      }

      obj1['Numbers'] = this.dataList1[i]['TOTAL'];
      obj1['In-Process'] = this.dataList1[i]['PENDING'];
      obj1['Considered'] = this.dataList1[i]['APPROVED'];
      obj1['Rejected'] = this.dataList1[i]['REJECTED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Resolver Wise Feedback/Complaint Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }


}

