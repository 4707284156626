import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-detailed-hpromise-report',
  templateUrl: './detailed-hpromise-report.component.html',
  styleUrls: ['./detailed-hpromise-report.component.css']
})
export class DetailedHPromiseReportComponent implements OnInit {
  formTitle = "Detailed H - Promise report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Detailed H report.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] =[['CUSTOMER_NAME', "Customer Name"],["EXCHANGE", "H Promise discount"],["MODEL_NAME", "Model"],["VARIENT_NAME", "Variant"],["TRANSMISSION_TYPE", "Transmission type"],["FUEL_NAME", "Fuel type"],["MEMO_NO", "Memo No"],["DATE", "Memo Date"],["MEMO_APPROVER_NAME", "Approver"],
  ["HPROMISE_APPROVED_DATETIME", "Date of approval"]]
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  index = 0;
  value1: string = "";
  value2: string = "";
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  isButtonSpinning: boolean = false;
  APPROVER_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  MODEL_ID = [];
  employees = [];
  LEAVE_TYPE: any;
  APPROVAR_ID=  [];
 
  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.search(true);
    // this.getEmployee();
    this.getModels();
  }

  models = [];

  getModels() {
    this.models = [];
  
    this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.models = data["data"];
        }
      },
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    );
  }

getEmployee(event) {
    this.employees = [];
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.employees = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  dates: any = [];
  today2 = new Date();

  // startDateChange() {
  //   var startDate = this.datePipe.transform(this.APPROVER_DATE, 'yyyy-MM-dd');
  //   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  //   console.log(this.getDaysArray(startDate, endDate));
  //   console.log(this.dates);
  // }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  // onFromDateChange(fromDate: any) {
  //   if (fromDate == null)
  //     this.date2 = new Date();

  //   else
  //     this.date2 = new Date(fromDate);
  // }

  // disabledStartDate = (APPROVER_DATE: Date): boolean => {
  //   if (!APPROVER_DATE || !this.TO_DATE) {
  //     return false;
  //   }
  //   return APPROVER_DATE.getTime() > this.TO_DATE;
  // };
  current = new Date();


  // disabledEndDate = (selected: Date): boolean =>
  //   // Can not select days before today and today
  //   differenceInCalendarDays(selected, this.current) < 0;


  // disabledEndDate = (TO_DATE: Date): boolean => {
  //   if (!TO_DATE || !this.APPROVER_DATE) {
  //     return true;
  //   }
  //   return TO_DATE.getTime() < this.APPROVER_DATE;
  // };

  onStartChange(date: Date): void {
    this.APPROVER_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {
    if ((this.APPROVER_DATE != undefined) ||  (this.MODEL_ID.length > 0)||  (this.APPROVAR_ID.length > 0) )
    this.isFilterApplied = "primary";
    else
    this.isFilterApplied = "default";
    this.search(true);
    this.filterClass = "filter-invisible";

    // var sort: string;
    // try {
    //   sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    // } catch (error) {
    //   sort = '';
    // }

    
 

    // this.filterQuery = '';
    // this.APPROVER_DATE = this.datePipe.transform(this.APPROVER_DATE, 'yyyy-MM-dd');
    // this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd');

    // if (this.APPROVER_DATE != undefined ) {
    //   this.filterQuery = " AND DATE(HPROMISE_APPROVED_DATETIME)='" + this.APPROVER_DATE +  "' "
      
    // }

    // if (this.APPROVER_DATE != null) {
    //   this.filterQuery = " AND DATE(HPROMISE_APPROVED_DATETIME)='" +this.APPROVER_DATE +  "' "
    // }

  //   if (this.APPROVER_DATE  != undefined) {
  //     this.filterQuery = " AND DATE(HPROMISE_APPROVED_DATETIME)='" +this.APPROVER_DATE +  "' "
     
  // }
    // else if(this.APPROVER_DATE != undefined||this.MODEL_ID != undefined){
    //   this.filterQuery = " AND DATE(HPROMISE_APPROVED_DATETIME)='" + this.APPROVER_DATE +  "'AND MODEL_ID  IN (" + "" +this.MODEL_ID + ") AND APPROVER_ID IN ("+ "" + this.APPROVAR_ID + ") "

    // }

    // if (this.MODEL_ID != undefined || this.MODEL_ID.length != 0 ||this.APPROVAR_ID != null) {
    //   this.filterQuery = " AND DATE(HPROMISE_APPROVED_DATETIME)='" + this.APPROVER_DATE +  "'AND MODEL_ID  IN (" + "" +this.MODEL_ID + ") AND APPROVER_ID IN ("+ "" + this.APPROVAR_ID + ") "
           
    // }

    // var filter = '';
    // filter = this.filterQuery;
    // var likeQuery = "";
    // if ( this.MODEL_ID  != undefined||this.MODEL_ID.length != 0) {
      // this.filterQuery = "AND DATE(DATE)='" +this.VIN_DATE +  " AND MODEL_ID IN (" + "" +this.MODEL_ID + ") ";
           
    // }
 
  // if (this.APPROVER_DATE != null && this.TO_DATE != null) {
  //   this.filterQuery = this.filterQuery+" AND APPROVER_DATE BETWEEN '" + this.APPROVER_DATE + "' AND '"+ this.APPROVER_DATE + "' AND TO_DATE BETWEEN '" + this.TO_DATE + "' AND '" +this.TO_DATE +  "' "
  // }
  // if (this.MONTH != undefined) {
  //   this.filterQuery = this.filterQuery+" AND  MONTH ('" + this.MONTH + "')"
  // }

 
    // if (this.APPROVAR_ID != null) {
    //   this.filterQuery = this.filterQuery + ' AND APPROVER_ID=' + "" + this.APPROVAR_ID + "";
    // }

//     var likeQuery = '';

// if (this.searchText != '') {
//       likeQuery = ' AND';
//       this.columns.forEach((column) => {
//         likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
//       });
//       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
// }

// this.api.hpromiseDetailedReport(1, this.pageSize, this.sortKey,sort, this.filterQuery).subscribe((data) => {
//   console.log(data);
//   this.loadingRecords = false;
//   this.isFilterApplied ='primary';
//   this.totalRecords = data['count'];
//   this.dataList = data['data'];
//   this.isSpinning = false;
//   this.filterClass = 'filter-invisible';
// },
//   (err) => {
//     console.log(err);
//   });
//   console.log(this.filterQuery);
}

getNameOfFinalStatus(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "A")
      return "Approved";

    else if (STATUS == "R")
      return "Rejected";
}

setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
}

clearFilter() {
    this.filterClass = 'filter-invisible';
    this.APPROVER_DATE = new Date();
    this.TO_DATE = new Date();
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.dataList = [];
    this.SELECT_ALL2=false;
    this.APPROVAR=false
    this.search(false);
    this.MODEL_ID = [];
    this.APPROVAR_ID = [];
}

  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;

importInExcel() {
    this.search(true, true);
}

  dataList2=[];

search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery =  " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)  + ")";
    }

    this.APPROVER_DATE = this.datePipe.transform(this.APPROVER_DATE, 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd');
    var dateFilterStr = "";
    if (this.APPROVER_DATE != null) {
      dateFilterStr = " AND DATE(HPROMISE_APPROVED_DATETIME)='" +this.APPROVER_DATE +  "' "
    }

    var modelFilterStr = "";
    if (this.MODEL_ID.length > 0) {
      modelFilterStr = " AND MODEL_ID IN (" + this.MODEL_ID + ")";
    }

    var approverFilterStr = "";
    if (this.APPROVAR_ID.length > 0) {
      approverFilterStr = " AND APPROVER_ID IN (" + this.APPROVAR_ID + ")";
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.hpromiseDetailedReport(this.pageIndex, this.pageSize, this.sortKey, sort ,this.filterQuery+likeQuery+dateFilterStr+modelFilterStr+approverFilterStr).subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
     }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.hpromiseDetailedReport(0,0, this.sortKey,sort,this.filterQuery+likeQuery+dateFilterStr+modelFilterStr+approverFilterStr).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
       });
    }
 }

SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL_ID = ids;
}
 
  onSelectOff2(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.MODEL_ID = event;
    if (this.MODEL_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }
 
  APPROVAR:boolean=false
  onSelectAllSelesExicutive(event: any) {
    this.APPROVAR = event
    console.log(event, "Event")
    let ids = [];
    if (this.APPROVAR == true) {
      for (var i = 0; i < this.employees.length; i++) {
        ids.push(this.employees[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.APPROVAR_ID = ids
  }


  onSelectOffOne(event) {
    var a = this.employees.length;
    var b = this.employees.length - event.length;
    if ((a! = b)) {
      this.APPROVAR = false;
    } else {
      this.APPROVAR = true;
    }
    this.APPROVAR_ID = event;
    if (this.APPROVAR_ID.length == 0) {
      this.APPROVAR = false;
    }
  }

sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
}

convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Customer Name '] = this.dataList2[i]['CUSTOMER_NAME'];
      obj1['Model'] = this.dataList2[i]['MODEL_NAME'];
      obj1['Variant'] = this.dataList2[i]['VARIENT_NAME'];
      obj1['Transmission type'] = this.dataList2[i]['TRANSMISSION_TYPE'];
      obj1['Fuel type'] = this.dataList2[i]['FUEL_NAME'];
      obj1['H Promise discount'] = this.dataList2[i]['EXCHANGE'];
      obj1['Memo No'] = this.dataList2[i]['MEMO_NO'];
      obj1['Memo Date'] = this.dataList2[i]['MEMO_DATE'];

      obj1['Approver'] = this.dataList2[i]['MEMO_APPROVER_NAME'];
      obj1['Date of approval'] = this.datePipe.transform(this.dataList2[i]['HPROMISE_APPROVED_DATETIME'], "yyyy-MM-dd");
      
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Detailed H-Promise Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
   }
}

}

