import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { Earlymark } from 'src/app/Models/Earlymark';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-addearlymark',
  templateUrl: './addearlymark.component.html',
  styleUrls: ['./addearlymark.component.css']
})

export class AddearlymarkComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input()
  data: Earlymark = new Earlymark();
  orgId = this.cookie.get('orgId');
  isSpinning = false;
  @Input() listOfData: Earlymark[] = [];
  listdata2 = [];
  listdata1 = [];
  acctype = [];
  date = new Date();
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  @Input() DAYS = false;
  isOk = true;

  constructor(private cookie: CookieService,private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm) {
    this.drawerClose();
    this.resetDrawer(myForm);
  }

  resetDrawer(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Date", "");
    }

    if (this.data.EXPECTED_TIME != undefined) {
      let givenTime = new Date(this.datePipe.transform(this.data.EXPECTED_TIME, "yyyy-MM-dd HH:mm") + ":00");
      let earlymarkFromTime = new Date(this.datePipe.transform(this.data.EXPECTED_TIME, "yyyy-MM-dd 16:00") + ":00");
      let earlymarkToTime = new Date(this.datePipe.transform(this.data.EXPECTED_TIME, "yyyy-MM-dd 18:30") + ":00");

      if ((givenTime.getTime() < earlymarkFromTime.getTime()) || (givenTime.getTime() > earlymarkToTime.getTime())) {
        isOk = false;
        this.message.error("Please Select Valid Time between 04:00 PM to 06:30 PM", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Select Valid Time", "");
    }

    if (this.data.REASON == undefined || this.data.REASON.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Reason", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");
      this.data.EXPECTED_TIME = this.datePipe.transform(this.data.EXPECTED_TIME, "HH:mm") + ":00";
      this.data.EMPLOYEE_ID = this.api.userId;

      if (this.data.ID) {
        this.api.updateEarlymark(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Earlymark Updated Successfully", "");

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Earlymark Updation Failed", "");
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
        });

      } else {
        this.api.createEarlymark(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Earlymark Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new Earlymark();
            }

            this.isSpinning = false;

          }
         else if (successCode['code'] == 300) {
            this.message.error('Leave Cannot be applied more than 2 times in a single month','')
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
           else{
            this.message.error("Earlymark Creation Failed", "");
            this.isSpinning = false;
            this.resetDrawer(myForm);
          }
         
        });
      }
    }
  }

  today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledHours(): number[] {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 19, 20, 21, 22, 23];
  }

  disabledMinutes(hour: number): number[] {
    if (hour === 18) {
      return [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

    } else {
      return [];
    }
  }
}



