import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-appointment-summary-report',
  templateUrl: './appointment-summary-report.component.html',
  styleUrls: ['./appointment-summary-report.component.css']
})
export class AppointmentSummaryReportComponent implements OnInit {

  formTitle = "Appointment Summary Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;

  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive name"], ["BRANCH_NAME", "Branch"], ["DATE", "Quotation Date"], ["CUSTOMER_NAME", "Customer name"], ["QUOTATION_NO", "Quotation No"], ["MODEL_NAME", "Model"]];

  isSpinning = false;
  filterClass: string = "filter-invisible";


  dateFormat = 'dd-MM-yyyy';

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  startValue: any = new Date();
  sales: any = [];

  DATE: any;
  CCE_ID: number

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  counter: number = 1;

  ngOnInit(): void {
    this.appData.forEach(() => {
      this.counter++;
    });

    this.startValue = new Date();
    this.getEmployee();
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=43 AND').subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])
        console.log(this.CCE_ID, "Ids");
        this.search();
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'

    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    // this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.DATE = this.startValue
    }

    if (this.CCE_ID != undefined && this.CCE_ID == null) {
      this.CCE_ID = this.CCE_ID
    }

    this.search(true);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date();
    this.getEmployee()
    this.search(true);
    this.isFilterApplied = 'default';

  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }

  appData: any = [];
  NoData: boolean = false
  appData1: any = []

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')

    if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
      this.CCE_ID = this.CCE_ID
    }


    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getAppointmentSummaryReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.DATE, this.CCE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.NoData = false
          this.appData = data['data'];
          console.log(this.appData, "appData")

          this.appData1 = data['TOTAL'];

          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }
        else if (data['code'] == 300) {
          this.NoData = true
        }

      }, err => {
        console.log(err);
      });
    }

  }

  modalVisible = false;

  openPrint() {

    this.modalVisible = true;
  }


  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.modalVisible = false;
  }

}
