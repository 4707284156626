import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-sc-and-tl-incentive',
  templateUrl: './sc-and-tl-incentive.component.html',
  styleUrls: ['./sc-and-tl-incentive.component.css']
})
export class ScAndTlIncentiveComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "SC AND ASM Incentive";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "EMPLOYEE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  isOk: boolean = true
  MODEL: any;
  BRANCH: any;
  BranchName: any[]
  RoleName = []
  ROLE_ID = []

  quotationdate: any;
  BRANCH_ID = []

  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  yesterday = new Date().getDate();
  startValue: any = new Date();
  endValue: any = new Date();
  // startValue = this.startValue.setDate(this.startValue.getDate() - 1);
  employees = [];
  EMPLOYEE_ID
  ROLESS

  SELECT_BRANCH: boolean = false;
  columns: string[][] = [["EMPLOYEE_NAME", "SC"]]
  columnsq: string[][] = [['SALE_DATE', 'ASM'], ["EMPLOYEE_NAME", "SC"], ["TOTAL_SALES_ACHIEVMENT", "Total Sales Achievement"], ["PRODUCT_EARNED_INCENTIVE_AMOUNT", "Product Earned Incentive Amount"], ["INSURANCE_DONE", "Actual Insurance Done"], ["EMPLOYEE_NAME", "Insurance Earned Incentive Amount"], ['SOT_RETAIL', 'SOT 40 % OF RETAIL'], ['SOT_DONE', 'Actual SOT Done'],
  ['SOT_AMOUNT', 'SOT AMOUNT'], ['EW_RETAIL', 'EW 60% of Retail'], ['EW_DONE', 'Actual EW Done'], ['EW_AMOUNT', 'EW AMOUNT'], ['QUOTATION_NO', 'Additional 10%'], ['TOTAL', 'Total Earned Incentive Amount']];
  orgId = Number(this.cookie.get('orgId'));
  exportInPDFLoading: boolean = false;
  dataListForExport = [];

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }

  ngOnInit() {


    this.SEFilter()

    this.getEmployee()
    this.search(false);

    // this.applyFilter();
    this.getEmployeeChilds()
  }
  idss
  emp = []
  totaData: any = []
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  getEmployee() {
    this.api.getAllEmployees(0, 0, '', '', "AND ROLE_IDS  like '%" + 29 + "%'")
      .subscribe(data => {
        this.emp = data['data']
        if (this.emp.length > 0) {
          this.SALES_MANAGER_ID = [data['data'][4]['ID']];
          console.log(this.SALES_MANAGER_ID)
          // this.applyFilter()
          this.search(true)
        }


      },
        (err) => {
          // console.log(err);
        }
      );
  }
  empid
  getEmployeeChilds() {
    this.empid = 343
    this.api.getEmployeeChild(0, 0, '', '', '', this.empid,'')

      .subscribe(data => {
        this.sales = data['data']
        // this.idss = [];
        // if (this.SELECT_ALL == true) {
        //   for (var i = 0; i < this.sales.length; i++) {
        //     this.idss.push(this.sales[i]["EMPLOYEE_ID"]);
        //   }
        // } else {
        //   this.idss = [];
        // }


        console.log(this.SALES_EXECUTIVE_ID)
        this.onSelectAllChecked(true);
        // this.search(true)
      },
        (err) => {
          console.log(err);
        }
      );
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }
  SELECT_ALL: boolean = true;
  ids=[]
  onSelectAllChecked(event: any) {
    this.SELECT_ALL = event;
    // let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.sales.length; i++) {
        this.ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
     this.ids=[]    }
    this.SALES_EXECUTIVE_ID = this.ids;
  }


  onSelectOff(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if (a! = b) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  sales = [];

  importInExcel() {
    this.search(true, true);
  }
  SALES_MANAGER_IDS = []
  SALES_MANAGER_ID = []
  SALES_EXECUTIVE_ID: any = []
  CLUSTER_ID = []
 

 
  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  search(reset: boolean = false, exportInExcel: boolean = false, exportToPDF: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    this.ROLESS = this.SALES_MANAGER_ID;

    var dateFilterStr = "";

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getSCAndASM(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.dataList = data['data'];
          this.totaData = data['Total'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.getSCAndASM(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList = data['data'];
            this.totaData = data['Total'];

            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    } if (exportToPDF) {
      this.exportInPDFLoading = true;

      this.api.getSCAndASM(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe(data => {
        if (data['code'] == 200) {
          this.exportInPDFLoading = false;
          this.dataList = data['data'];
          this.totaData = data['Total'];

          this.isPDFModalVisible = true;
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }
  }

  exportLoading: boolean = false;

  // convertInExcel() {
  //   var arry1 = [];
  //   var obj1: any = new Object();
  //   for (var i = 0; i < this.dataList1.length; i++) {
  //     obj1['ASM'] = this.dataList1[i]['SALE_DATE'];

  //     obj1['SC'] = this.dataList1[i]['EMPLOYEE_NAME'];

  //     obj1['Total Sales Achievement'] = this.dataList1[i]['TOTAL_SALES_ACHIEVMENT'];
  //     obj1['Product Earned Incentive Amount'] = this.dataList1[i]['PRODUCT_EARNED_INCENTIVE_AMOUNT'];
  //     obj1['Actual Insurance Done  '] = this.dataList1[i]['INSURANCE_DONE'];
  //     obj1['Insurance Earned Incentive Amount'] = this.dataList1[i]['INCENTIVE_AMOUNT'];
  //     obj1['Insurance Percentage'] = this.dataList1[i]['INSURANCE_PERCENTAGE'];

  //     obj1['SOT 40 % OF RETAIL '] = this.dataList1[i]['SOT_RETAIL'];
  //     obj1['Actual SOT Done'] = this.dataList1[i]['SOT_DONE'];
  //     obj1['SOT AMOUNT'] = this.dataList1[i]['SOT_AMOUNT'];
  //     obj1['EW 60% of Retail'] = this.dataList1[i]['EW_RETAIL'];
  //     obj1['Actual EW Done'] = this.dataList1[i]['EW_DONE'];
  //     obj1['EW AMOUNT'] = this.dataList1[i]['EW_AMOUNT'];
  //     obj1['Additional 10%'] = this.dataList1[i]['QUOTATION_NO'];
  //     obj1['Total Earned Incentive Amount'] = this.dataList1[i]['TOTAL'];

  //     arry1.push(Object.assign({}, obj1));
  //     if (i == this.dataList1.length - 1) {
  //       this._exportService.exportExcel(arry1, 'SC AND ASM Incentive' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  //     }
  //   }
  // }





  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }



  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.filterClass = 'filter-invisible';
    // if (this.SALES_EXECUTIVE_ID !== '' || this.SALES_EXECUTIVE_ID !== null) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID;
    // }

    // else {
    //   this.SALES_EXECUTIVE_ID = this.ids1
    // }
    this.ROLESS = this.SALES_MANAGER_ID;
    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }
    console.log("bmsndbfms", this.SALES_EXECUTIVE_ID)
    // this.search(true)
    this.api.getSCAndASM(0, 0, this.sortKey, sort, "", this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.totaData = data['Total'];

      }

    }, err => {
      console.log(err);
    });
    this.isFilterApplied = "primary";
  }

  clearFilter() {
    this.startValue = new Date();
    this.endValue = new Date();
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.BRANCH_ID = [];
    this.EMPLOYEE_ID = [];
    this.ROLESS = null
    this.ROLE_ID = null
    this.SALES_MANAGER_ID = null
    this.SELECT_BRANCH = false;
    this.MONTH1 = new Date()

    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(true);

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

  // month1(event: any) {

  // }
  isPDFModalVisible: boolean = false;
  PDFModalTitle: string = "Export in PDF";

  handlePDFModalCancel() {
    this.isPDFModalVisible = false;
  }
  importInPDF() {
    this.search(false, false, true);

  }

  fileName = 'LeaveRegisterReport.xlsx';

  convertInExcel() {
    let element = document.getElementById('branchmodelwise');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    var wscols = [];
    var cols_width = 13; // Default cell width
    wscols.push({
      wch: 18
    }); // Set 1stColumn @32 character wide
    for (var i = 0; i < 50; i++) {
      // Increase/Decrease condition_value based on the nmbr of columns you've on your excel sheet
      wscols.push({
        wch: cols_width
      });
    }
    ws['!cols'] = wscols;

    ws['!rows'] = [{
      hpx: 0
    }, // "pixels" in row1
    {
      hpx: 0
    }, // "pixels" in row2
    {
      hpx: 20
    }
    ];
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }, 1000);
  }
  id2: any = []

  SEFilter() {
    this.api.getAllEmployees12(0, 0, '', '', "AND ROLE_IDS  like '%" + 25 + "%'", '')
      .subscribe(data => {
        this.sales = data['data']
        this.id2 = [];
        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            this.id2.push(this.sales[i]["ID"]);
          }
        } else {
          this.id2 = [];
        }

        // this.SALES_EXECUTIVE_ID = ids1;
        // setTimeout(() => {
        //   this.search(true);
        // }, 3000);

      },
        (err) => {
          console.log(err);
        }
      );

  }
  ids1: any = []

  getSales(event) {

    let ids12 = [];
    // this.SELECT_ALL = false
    this.ids = []
    this.api.getEmployeeChild(0, 0, '', '', '', event,'')
      .subscribe(data => {
        this.sales = data['data']
        console.log("bmbmbm", this.sales)

        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids12.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids12 = [];
        }

        this.SALES_EXECUTIVE_ID = ids12;
        console.log("bcnmbscm", this.ids1)
        // this.search(true);
      },

      );
  }

}