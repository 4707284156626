import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-modelwisevarientdetailedreport',
  templateUrl: './modelwisevarientdetailedreport.component.html',
  styleUrls: ['./modelwisevarientdetailedreport.component.css']
})
export class ModelwisevarientdetailedreportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Model Wise Variant Details";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  MODEL: any = [];
  VARIENT: any = [];
  // VARIENT:any=[];
  FUELTYPE: any = [];
  models: any = [];
  varient: any = [];
  fueltype: any = [];
  // isFilterApplied: string = "default";
  isOk = true;
  columns: string[][] = [["MODEL_NAME", "Model Name"], ["NAME", "Variant Name"], ["FUEL_NAME", "Fuel Type"], ["TRANSMISSION_NAME", "Transmission Type"], ["CC", "CC"], ["VARIENT_LITER", "Fuel Capacity"]];
  exportLoading: boolean;
  dataList1: any;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService) { }
  ngOnInit() {
    // this.MODEL="";
    // this.VARIENT="";
    // this.FUELTYPE=""
    // this.applyFilter();
    this.getAllModels();
    // this.getAllFuel();
    // this.getAllVarient();
    // this.search(false);
  }
  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = { 
      MODEL:this.MODEL, 
      VARIENT:this.VARIENT,
      FUELTYPE:this.FUELTYPE, 
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 21').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose1(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }
  getAllModels() {
    this.api.getAllModels(0, 0, "", "", "").subscribe(data => {
      this.models = data['data']
      this.onSelectAllModel(true);
      this.getAllFuel()
    })
  }
  getAllFuel() {
    this.api.getAllFuel(0, 0, "", "", "").subscribe(data => {
      this.fueltype = data['data']
      this.onSelectAllFuel(true)
      this.getAllVarient();
    })
  }
  getAllVarient() {
    this.api.getAllVarients(0, 0, "", "", "").subscribe(data => {
      this.varient = data['data']
      this.onSelectAllVarient(true)
      this.search(true);
    })
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }
  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }
  SELECT_FUEL: boolean = false;
  onSelectAllFuel(event: any) {
    this.SELECT_FUEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_FUEL == true) {
      for (var i = 0; i < this.fueltype.length; i++) {
        ids.push(this.fueltype[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.FUELTYPE = ids
  }
  SELECT_VARIENT: boolean = false;
  onSelectAllVarient(event: any) {
    this.SELECT_VARIENT = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_VARIENT == true) {
      for (var i = 0; i < this.varient.length; i++) {
        ids.push(this.varient[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT = ids
  }

  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    // this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "", "", "").subscribe(data => {
    //   console.log(data)
    //   this.loadingRecords = false;
    //   this.isSpinning = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    // }, err => {
    //   console.log(err);
    // });
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.VARIENT, this.FUELTYPE).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        // this.loadingRecords = false;
        // this.isSpinning=false
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.VARIENT, this.FUELTYPE).subscribe(data => {
        console.log(data)
        this.exportLoading = false;
        this.dataList1 = data['data'];
        // this.isFilterApplied="primary";
        this.convertInExcel();
      },
        err => {
          if (err['ok'] == false)
            //   this.exportLoading = false;
            // this.loadingRecords = false;
            // this.isSpinning = false;
            this.message.error("Server Not Found", "");
        });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // this.FUELTYPE=this.datePipe.transform(this.FUELTYPE,"yyyy-MM-dd")
    // this.filterQuery = "";
    // if(this.MODEL != undefined  && this.VARIENT != undefined && this.FUELTYPE != undefined)
    // {
    //   //  this.message.error("Please Select Atleast One Option From The Following",'')
    //    this.filterQuery = " AND MODEL = " + this.MODEL + " AND VARIENT = " + this.VARIENT + " AND FUEL_TYPE = " + this.FUELTYPE ;
    //    this.isSpinning = false;
    //    this.loadingRecords = false;
    //    this.isFilterApplied="primary";
    //    console.log("FilterQuery",this.filterQuery)

    // }
    // else if(this.MODEL != undefined  && this.VARIENT == undefined && this.FUELTYPE == undefined){
    //   this.filterQuery = " AND MODEL = " + this.MODEL;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined  && this.VARIENT != undefined && this.FUELTYPE == undefined){
    //   this.filterQuery = " AND VARIENT = " + this.VARIENT;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined  && this.VARIENT == undefined && this.FUELTYPE != undefined){
    //   this.filterQuery = " AND FUEL_TYPE = " + this.FUELTYPE;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL != undefined  && this.VARIENT != undefined && this.FUELTYPE == undefined){
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND VARIENT = " + this.VARIENT;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL != undefined  && this.VARIENT == undefined && this.FUELTYPE != undefined){
    //   this.filterQuery = " AND MODEL = " + this.MODEL + " AND FUEL_TYPE = " + this.FUELTYPE;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    // else if(this.MODEL == undefined  && this.VARIENT != undefined && this.FUELTYPE != undefined){
    //   this.filterQuery = " AND VARIENT = " + this.VARIENT + " AND FUEL_TYPE = " + this.FUELTYPE;
    //   console.log("FilterQuery",this.filterQuery)
    //   this.loadingRecords=false;
    //   this.isSpinning=false;
    //   this.isFilterApplied="primary";

    // }
    if (this.MODEL == undefined && this.VARIENT == undefined && this.FUELTYPE == undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MODEL, this.VARIENT, this.FUELTYPE).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false;
          this.totalRecords = data['count'];
          if (data['count'] > 0) {
            this.dataList = data['data'];
            this.dataList1 = data['data'];
          }
          else {
            this.dataList = [];
            this.dataList1 = [];
          }
          this.isFilterApplied = "primary"
        }
        else {
          this.loadingRecords = false;
          this.isSpinning = false;
        }
      }, err => {
        console.log(err);
      });
      this.filterClass = "filter-invisible"
      //  if (this.MODEL!=undefined && this.VARIENT!=undefined && this.FUELTYPE != undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+ this.MODEL,""+this.VARIENT,""+ this.FUELTYPE).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.MODEL != undefined && this.FUELTYPE == undefined && this.VARIENT == undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "" + this.MODEL,"","").subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.VARIENT != undefined && this.FUELTYPE == undefined && this.MODEL == undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "","" + this.VARIENT,"").subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.FUELTYPE != undefined && this.MODEL == undefined && this.VARIENT == undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "","",""+ this.FUELTYPE).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.FUELTYPE != undefined && this.MODEL != undefined && this.VARIENT == undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+this.MODEL,"",""+ this.FUELTYPE).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.FUELTYPE != undefined && this.MODEL == undefined && this.VARIENT != undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, "",""+this.VARIENT,""+ this.FUELTYPE).subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }
      // else if (this.FUELTYPE == undefined && this.MODEL != undefined && this.VARIENT != undefined) {
      //   this.api.getmodelwisevarientdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, ""+this.MODEL,""+this.VARIENT,"").subscribe(data => {
      //     console.log(data)
      //     this.loadingRecords = false;
      //     this.isSpinning = false;
      //     this.totalRecords = data['count'];
      //     this.dataList = data['data'];
      //   }, err => {
      //     console.log(err);
      //   });
      // }

    }
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Name'] = this.dataList1[i]['NAME'];
      obj1['Fuel Type'] = this.dataList1[i]['FUEL_NAME'];
      obj1['Transmission Name'] = this.dataList1[i]['TRANSMISSION_NAME'];
      obj1['CC'] = this.dataList1[i]['CC'];
      obj1['Fuel Capacity'] = this.dataList1[i]['FUEL_CAPACITY'];
      // obj1['RTO'] = this.dataList1[i]['RTO'];
      // obj1['Fastag'] = this.dataList1[i]['FASTAG'];
      // obj1['Accessories'] = this.dataList1[i]['ACCESSORIES'];
      // obj1['Warranty'] = this.dataList1[i]['EX_WARRANTY'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Model wise varient detailed report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  importInExcel() {
    this.search(true, true);
  }
  clearFilter() {
    this.MODEL = [];
    this.SELECT_MODEL = false;
    this.FUELTYPE = [];
    this.SELECT_FUEL = false;
    //  this.FUELTYPE=this.datePipe.transform(this.FUELTYPE,"dd-MM-yyyy");
    this.VARIENT = [];
    this.SELECT_VARIENT = false;
    this.filterClass = 'filter-invisible';
    this.search(false);
    this.isFilterApplied = "default";
    this.SELECT_MODEL=true
    this.SELECT_VARIENT=true
    this.SELECT_FUEL=true
    var ids12=[]
   for (var i = 0; i < this.models.length; i++) {
      ids12.push(this.models[i]["ID"]);
    } 
  this.MODEL = ids12
  var ids1=[]
 for (var i = 0; i < this.varient.length; i++) {
    ids1.push(this.varient[i]["ID"]);
} 
this.VARIENT = ids1
var ids2=[]
      
for (var i = 0; i < this.fueltype.length; i++) {
  ids2.push(this.fueltype[i]["ID"]);
} 
this.FUELTYPE = ids2

  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue)
  }
  onSelectOff1(event) {
    var ac = this.models.length;
    var bd = this.models.length - event.length;
    if ((ac! = bd)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL = event;
    if (this.MODEL.length == 0) {
      this.SELECT_MODEL = false;
    }
  }
  onSelectOff2(event) {
    var a = this.varient.length;
    var b = this.varient.length - event.length;
    if ((a! = b)) {
      this.SELECT_VARIENT = false;
    } else {
      this.SELECT_VARIENT = true;
    }
    this.VARIENT = event;
    if (this.VARIENT.length == 0) {
      this.SELECT_VARIENT = false;
    }
  }
  onSelectOff3(event) {
    var c = this.fueltype.length;
    var d = this.fueltype.length - event.length;
    if ((c! = d)) {
      this.SELECT_FUEL = false;
    } else {
      this.SELECT_FUEL = true;
    }
    this.FUELTYPE = event;
    if (this.FUELTYPE.length == 0) {
      this.SELECT_FUEL = false;
    }
  }
}
