import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { setHours } from 'date-fns';
// declare var ngxPrint: any;

@Component({
  selector: 'app-kpi-report',
  templateUrl: './kpi-report.component.html',
  styleUrls: ['./kpi-report.component.css']
})
export class KpiReportComponent implements OnInit {

  formTitle = "KPI Report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'kpi.xlsx';
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive name"], ["BRANCH_NAME", "Branch"], ["DATE", "Quotation Date"], ["CUSTOMER_NAME", "Customer name"], ["QUOTATION_NO", "Quotation No"], ["MODEL_NAME", "Model"]];
  STATUS = "AL";
  BRANCH = [];
  SalesExecutive = []
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();

  endOpen = false;
  // startOpen = false;

  sales: any = "";
  DATE: any;
  CCE_ID: number
  BRANCH_ID: any = [];
  SALES_CCE_ID: any = [];
  // MONTH='';
  // YEAR=''
  // LIKE_STRING='';

  isVisible1: boolean = false
  drawerTitle1: string = ''

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }


  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

 
  ngOnInit(): void {
  
    this.startValue = new Date();
    this.getEmployee();
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
      this.sales = data['data'];
      if (this.sales.length > 0) {
        this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])
        console.log(this.sales);
        this.search();
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }


  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  // keyup(event: any) {
  //   this.search();
  // }

  dates: any = [];
  today2 = new Date();


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'

    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    // this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.DATE = this.startValue
    }

    // if (this.endValue != null) {
    //   this.END_DATE = this.endValue
    // }

    // if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
    //   this.BRANCH_ID = this.BRANCH_ID
    // }

    if (this.CCE_ID != undefined && this.CCE_ID == null) {
      this.CCE_ID = this.CCE_ID
    }

    this.search(true);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date();
    this.getEmployee()
    this.search(true);
    this.isFilterApplied = 'default';

  }

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  dataList1 = [];
  cceData = [];
  lastTotalsData = []
  targetReminderData = []
  timeWiseData = []
  totaData = []

  apptDetailsData = []
  videoData = []
  inbondData = []
  liveData = []
  totalAptData = []
  NoData: boolean = false

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')

    // if (this.CCE_ID != undefined && this.CCE_ID == null) {
    //   if(this.roleId ==43){
    //     this.CCE_ID = this.userId
    //   }
    //   else{
    //     this.CCE_ID = this.CCE_ID
    //   }
    // }
    if (this.roleId == 43) {
      if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
        this.CCE_ID = this.userId
      }
    }
    else {
      if (this.CCE_ID != null && this.CCE_ID != 0 && this.CCE_ID != undefined) {
        this.CCE_ID = this.CCE_ID
      }
    }
    // else{

    // }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getKPISheetReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.DATE, this.CCE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.NoData = false
          this.cceData = data['CCE_DATA'];
          console.log(this.cceData, "cceData")

          this.lastTotalsData = data['LAST_TOTALS'];
          console.log(this.lastTotalsData, "lastTotalsData")

          this.targetReminderData = data['TARGET_AND_REMINDERS'];
          console.log(this.targetReminderData, "targetReminderData")

          this.timeWiseData = data['TIME_WISE_DATA'];
          console.log(this.timeWiseData, "timewisedata")

          this.totaData = data['TOTAL_DATA'];
          console.log(this.totaData, "totaData")

          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }
        else if (data['code'] == 300) {
          this.NoData = true
        }

      }, err => {
        console.log(err);
      });

      this.api.getKPIReportCount(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.DATE, this.CCE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];

          this.apptDetailsData = data['APPOINTMENT_DETAILS'];
          console.log(this.apptDetailsData, "apptDetailsData")

          this.inbondData = data['INBOND_OUTBOND'];
          console.log(this.inbondData, "inbondData")

          this.liveData = data['LIVE_STREAMING'];
          console.log(this.liveData, "liveData")

          this.totalAptData = data['TOTAL_APPOINTMENT'];
          console.log(this.totalAptData, "totalAptData")

          this.videoData = data['VIDEO_CALLS'];
          console.log(this.videoData, "videoData")

          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }

  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  modalVisible = false;

  openPrint() {

    this.modalVisible = true;
  }


  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.modalVisible = false;
  }

}
