import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { EmployeeRoleMap } from 'src/app/Models/employeerolemap';
import { ApiService } from 'src/app/Service/api.service';
import { AddemployeerolemapComponent } from '../addemployeerolemap/addemployeerolemap.component';

@Component({
  selector: 'app-employeerolemaplist',
  templateUrl: './employeerolemaplist.component.html',
  styleUrls: ['./employeerolemaplist.component.css']
})

export class EmployeerolemaplistComponent implements OnInit {
  formTitle = "Employee Role Map";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  @Input() dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["ROLE_NAME", "Role Name"]]
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: EmployeeRoleMap = new EmployeeRoleMap();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: EmployeeRoleMap = new EmployeeRoleMap();
  listOfData: EmployeeRoleMap[] = [];
  OPEN_TIME2 = null
  CLOSE_TIME2 = null
  DAYS = false;
  @Input() data: EmployeeMaster;
  @Input() drawerClose2: Function;
  role = []

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
    this.loadingRecords = false;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND EMPLOYEE_ID=' + this.data.ID).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  @ViewChild(AddemployeerolemapComponent, { static: false }) AddemployeerolemapComponentVar: AddemployeerolemapComponent;

  add(): void {
    this.drawerTitle = "Map New Role for " + this.data.NAME;
    this.drawerData = new EmployeeRoleMap();

    this.api.getAllRoles2(this.cookie.get('roleId'), this.data.ID).subscribe(data => {
      this.role = data['data'];

    }, err => {
      console.log(err);
    });

    this.drawerVisible = true;
    this.AddemployeerolemapComponentVar.reportingManagers(this.data.ID);
    this.AddemployeerolemapComponentVar.reportingManagers11(this.data.ID);
  }

  edit(data: EmployeeRoleMap): void {
    this.drawerTitle = "Update Role";
    this.drawerData = Object.assign({}, data);
    this.DAYS = false;
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  cancel(): void { }

  confirm(data): void {
    this.api.deleteEmpRoleMap({ ROLE_ID: data.ROLE_ID, EMPLOYEE_ID: data.EMPLOYEE_ID }).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Role Deleted Successfully', '');
        this.search();

      } else {
        this.message.error('Failed To Delete', '');
      }
    });
  }

  setStatus(event, i) {
    this.dataList[i]['STATUS'] = event;
    this.api.updateEmpRoleMap(this.dataList[i]).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Updated Successfully', '');
      } else {
        this.message.error('Failed To Update', '');
      }
      this.search();
    });
  }
}

function input() {
  throw new Error('Function not implemented.');
}