import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-user-wise-detailed-report',
  templateUrl: './user-wise-detailed-report.component.html',
  styleUrls: ['./user-wise-detailed-report.component.css']
})
export class UserWiseDetailedReportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "User Wise Detailed Feedback Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  quotationdate: any;

  models: any = [];
  branch: any

  isOk = true;
  columns: string[][] = [["EMPLOYEE_NAME", "Employee name"], ["BRANCH_NAME", "Branch name"], ["AREA_NAME", "Area name"],['FEEDBACK_TYPE','Type'],
  ["FEEDBACK", "Feedback"], ["REMARK", "REMARK"], ["STATUS", "Status"], ["CHECKED_BY_USER_NAME", "CHECKED_BY_USER_NAME"]];
  dataList1: any;

  START_DATE: any
  END_DATE: any
  MEMO_START_DATE: any
  MEMO_END_DATE: any
  MODEL_ID: any = []
  BRANCH_ID: any
  DATE = [];
  DATE2 = [];
  current = new Date();

  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAllbranch();
    this.getEmployee()
    this.DATE[0] = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] = new Date();
    this.search(false)

  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false,false);
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  // getBranchwisefeedbackdetails
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }





  // onKeypressEvent(reset: any) {
  //   const element = window.document.getElementById('button');
  //   if (element != null) element.focus();
  //   // this.search(false);
  // }



  exportLoading: boolean = false;

  importInExcel() {
    this.search(false, true);
  }
  drawerClose1() {

  }

  getAllbranch() {
    this.api.getAllBranch(0, 0, '', 'desc', '').subscribe(data => {

      this.branch = data['data'];
      console.log(this.branch);
      // if (this.branch.length > 0) {
      //   this.BRANCH_ID = data['data'][0]['ID'];
      // }
      // this.onSelectAllBranch(true);

    }, err => {
      console.log(err);
    });
  }

  SELECT_BRANCH: boolean = false;

  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }

  onSelectOff1(event) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  TYPE: any = "C"
  STATUS: any = "A"
  CHECKED_BY_USER_ID: any

  search(reset: boolean, exportInExcel: boolean = false) {
    console.log('userwise')
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);



    var likeQuery = "";
    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      var filterQuery = " AND (DATE(APPLIED_DATETIME)BETWEEN '" + this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd') + "')";
    }

    var filterQuery1 = ''
    if (this.TYPE != undefined) {
      filterQuery1 = ' AND FEEDBACK_TYPE=' + "'" + this.TYPE + "'";
    }
    else {
      filterQuery1 = ''
    }

    var filterQuery2 = ''
    if (this.STATUS != undefined) {
      filterQuery2 = ' AND STATUS=' + "'" + this.STATUS + "'";
    }
    else {
      filterQuery2 = ''
    }

    console.log(this.BRANCH_ID, "BRANCH_ID")
    var filterQuery3 = ''
    if (this.BRANCH_ID != undefined && this.BRANCH_ID != '') {
      filterQuery3 = ' AND BRANCH_ID = ' + "'" + this.BRANCH_ID + "'";
    }
    else {
      filterQuery3 = ''
    }

    var filterQuery4 = ''
    if (this.CHECKED_BY_USER_ID != undefined) {
      filterQuery4 = ' AND CHECKED_BY_USER_ID = ' + "'" + this.CHECKED_BY_USER_ID + "'";
    }
    else {
      filterQuery4 = ''

    }


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getUserwisefeedbackdetails(this.pageIndex, this.pageSize, this.sortKey, sort,
        likeQuery + filterQuery + filterQuery1 + filterQuery2 + filterQuery3 + filterQuery4).subscribe(data => {
          console.log(data)
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }, err => {
          console.log(err);
        });
    }
    else {
      this.exportLoading = false;
      this.api.getUserwisefeedbackdetails(0, 0, this.sortKey, sort,
        likeQuery + filterQuery + filterQuery1 + filterQuery2 + filterQuery3 + filterQuery4).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];

            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.search(true,false);
    this.isFilterApplied = 'primary';

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE = [];
    this.DATE[0] =  new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.DATE[1] =  this.datePipe.transform(this.current, 'yyyy-MM-dd')
    this.TYPE = 'C'
    this.STATUS = 'A'
    this.CHECKED_BY_USER_ID = null

    this.SELECT_BRANCH == false
    this.BRANCH_ID = null

    this.search(true,false)
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['User Name'] = this.dataList1[i]['EMPLOYEE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      if (this.dataList1[i]['FEEDBACK_TYPE'] == 'F') {
        obj1['Feedback/Complaint'] = 'Feedback';
      }
      else {
        obj1['Feedback/Complaint'] = 'Complaint';

      }

      obj1['Description'] = this.dataList1[i]['FEEDBACK'];
      obj1['Date Of Creation'] = this.dataList1[i]['APPLIED_DATETIME'];
      obj1['Status'] = this.dataList1[i]['STATUS'];
      obj1['Remark'] = this.dataList1[i]['REMARK'];
      obj1['Remark By'] = this.dataList1[i]['CHECKED_BY_USER_ID'];
      obj1['Remark Date'] = this.dataList1[i]['CHECKED_DATETIME'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'User Wise Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
  employees: any
  getEmployee() {
    this.api.getAllemployeeMaster(0, 0, this.sortKey, this.sortValue, '').subscribe((data) => {
      if (data['code'] == 200) {
        this.employees = data['data']

      }
    }, err => {
      console.log(err);
    });
  }

}
