import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
// import { financeMaster } from '../Models/FinanceMaster';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns'
import { CookieService } from 'ngx-cookie-service';
import { financeMaster } from 'src/app/Models/FinanceMaster';
// import { financeMaster } from 'src/app/Models/FinanceMaster';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
// import * as html2pdf from 'html2pdf.js';
// import * as html2pdf from 'html2pdf.js'
// import html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-finance-master',
  templateUrl: './finance-master.component.html',
  styleUrls: ['./finance-master.component.css']
})
export class FinanceMasterComponent implements OnInit {

  constructor(private datepipe: DatePipe, private api: ApiService, private cookie: CookieService) { }

  columns: string[][] = [["INCENTIVE_STRUCUTURE_NAME", "Incentive Name"],
  ["FROM_DATE", "From Date"],
  ["TO_DATE", "To date"]]

  formTitle = "Finance Incentive Master";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isVisible6 = false;
  isSpinning = false;
  loadingRecords = false;

  drawerTitle: string | undefined;
  apkVersionModalTitle=""
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;
  drawerTitle1: string
  drawerVisible1: boolean;
  drawerVisible11: boolean;

  drawerData1: Leave = new Leave();
  orgId = this.cookie.get('orgId');

  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  dataList: any[] = [];
  user: financeMaster = new financeMaster();
  user12:financeMaster=new financeMaster()
  assign
  isApkVersionModalVisible: boolean = false
  SendData1 = []
  Final = []
  Finalll = []
  va = []
  MODELIDS = []
  ROLEIDS = []
  ROLESSS = []
  dataListtt = []
  MODELSSSS = []
  Roletype = []
  ids = []
  dataaa = []
  dataListdata = []
  AllROLESSS = []
  ROLEIDSSS = []
  MODELSadd = []
  modeltype = []
  varienttype = []
  fueltype = []
  tranmission = []
  BRANCHES = [];
  ROLES = [];
  finalData = []
  ROLES2 = []
  BRANCHES2 = []
  ROLES22 = []
  BRANCHES22 = []
  drawerData12 = []
  dataa = []
  model = []
  obj = []
  BRANCHES1 = [];
  ROLES1 = [];
  dataList1=[]
  drawerClose1() {
    this.isVisible = false;
    this.search()
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  drawerClose3(): void {
    this.drawerVisible1 = false;
    this.search()
  }

  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }


  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
  branch = []
  roles = []
  isVisible1
  getroles = []

  loadRoles() {
    this.api.getAllRoles(0, 0, '', 'asc', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }
  branch1 = []
  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch1 = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  TYP: string
  roleidddd=[]
  branchidddd=[]

  add1(data, TYPEEE): void {
    console.log(data);
    this.branchidddd=[]
    this.roleidddd=[]


    this.TYP = TYPEEE
    this.ROLES2 = []
    this.BRANCHES2 = []
    if (TYPEEE == 'S') {

      this.drawerTitle1 = "ST Roles and Branch Mapping ";
    } else if (TYPEEE == 'O') {

      this.drawerTitle1 = " OT Roles and Branch Mapping ";
    } else if (TYPEEE == 'C') {

      this.drawerTitle1 = " CT Branch Mapping";
    }

    this.ROLES22 = []
    this.BRANCHES22 = []


    // this.drawerData = new Leave();
    this.drawerVisible1 = true;
    this.drawerData1 = Object.assign({}, data);

    if (this.TYP == "S") {
      if (data.BRANCH_IDS_ST != null && data.ROLE_IDS_ST != null) {
        this.ROLES2 = data.ROLE_IDS_ST.split(",")
        this.BRANCHES2 = data.BRANCH_IDS_ST.split(",")
       
      }

      // ,,,ROLE_IDS_OT,BRANCH_IDS_CT,ROLE_IDS_CT

      this.branch = [];
      for (let i = 0; i < this.ROLES2.length; i++) {
        var kk = this.roles.findIndex(x => x.ID == this.ROLES2[i]);
        this.ROLES22.push(this.roles[kk]['NAME']);
        this.roleidddd.push(Number(parseInt(this.ROLES2[i])))
      }
      for (let i = 0; i < this.BRANCHES2.length; i++) {
        var kk = this.branch1.findIndex(x => x.ID == this.BRANCHES2[i]);
        this.BRANCHES22.push(this.branch1[kk]['NAME']);
        this.branchidddd.push(Number(parseInt(this.BRANCHES2[i])))
      }

    }
    if (this.TYP == "O") {

      if (data.BRANCH_IDS_OT != null && data.ROLE_IDS_OT != null) {
        this.ROLES2 = data.ROLE_IDS_OT.split(",")
        this.BRANCHES2 = data.BRANCH_IDS_OT.split(",")
       
      }


      // ,,,,BRANCH_IDS_CT,ROLE_IDS_CT

      this.branch = [];
      for (let i = 0; i < this.ROLES2.length; i++) {
        var kk = this.roles.findIndex(x => x.ID == this.ROLES2[i]);
        this.ROLES22.push(this.roles[kk]['NAME']);
        this.roleidddd.push(Number(parseInt(this.ROLES2[i])))
      }
      for (let i = 0; i < this.BRANCHES2.length; i++) {
        var kk = this.branch1.findIndex(x => x.ID == this.BRANCHES2[i]);
        this.BRANCHES22.push(this.branch1[kk]['NAME']);
        this.branchidddd.push(Number(parseInt(this.BRANCHES2[i])))
      }
      console.log(this.roleidddd);
      console.log(this.branchidddd);
    }
    if (this.TYP == "C") {
      if (data.BRANCH_IDS_CT != null && data.ROLE_IDS_CT != null) {
        this.ROLES2 = data.ROLE_IDS_CT.split(",")
        this.BRANCHES2 = data.BRANCH_IDS_CT.split(",")
        
      
      }

      this.branch = [];
      for (let i = 0; i < this.ROLES2.length; i++) {
        var kk = this.roles.findIndex(x => x.ID == this.ROLES2[i]);
        this.ROLES22.push(this.roles[kk]['NAME']);
        this.roleidddd.push(Number(parseInt(this.ROLES2[i])))
       
      }
      for (let i = 0; i < this.BRANCHES2.length; i++) {
        var kk = this.branch1.findIndex(x => x.ID == this.BRANCHES2[i]);
        this.BRANCHES22.push(this.branch1[kk]['NAME']);
        this.branchidddd.push(Number(parseInt(this.BRANCHES2[i])))
      }

    }
    this.api.financeIncentiveDetails(0, 0, '', 'asc', ' AND FINANCE_INCENTIVE_MASTER_ID=' + data.ID + ' AND TYPE = ' + "'" + TYPEEE + "'").subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
        // console.log(this.branch);
        this.drawerData12 = this.branch
      }
    }, err => {
      console.log(err);
    });

  }


  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }
    this.api.getFinanceMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList1 = data['data'];

    }, err => {
      console.log(err);
    });
  }


  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');


    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND FROM_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }



    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.api
      .getFinanceMaster(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );

  }



  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = null;
    this.endValue = null;

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }
  ngOnInit() {
    this.loadRoles();
    this.getBranch();
    this.search();
    // this.getData();
  }

  add() {
    this.isVisible = true;
    this.drawerTitle = "Create Finance Incentive";
    this.user12=new financeMaster()
  }

  edit(data: financeMaster) {
    this.isVisible = true;
    this.drawerTitle = 'Update Finance Incentive';
    this.user12 = Object.assign({}, data);

  }

  handleApkVersionModalCancel() {
    this.isApkVersionModalVisible = false
    this.dataList = []
    this.datalist23 = []
    this.BRANCHES23 = []
    // this.obj = []
    // this.va = []
  }

  



  // public generatePDF() {
  //   var i = 0;
  //   var date = new Date();
  //   var datef = this.datepipe.transform(date, "yyyy-MM-dd");
  //   var dates = this.datepipe.transform(date, "h:mm:ss a");
  //   var data = document.getElementById('report_id');
  // }

  str(str1, str2, str3, str4) {
    return (str1 + " " + str2 + " " + str3 + " " + str4);
  }

  ROLES23 = []
  BRANCHES23 = []
  datalist23 = []
  ROLE23=[]
  BRANCH23=[]
  print(data) {
    this.isApkVersionModalVisible = true
    this.ROLES2 = []
    this.AllROLESSS = []

    this.MODELSadd = []
    this.BRANCHES2 = []

    this.ROLESSS = []

    this.MODELSSSS = []
    this.Roletype = []

    this.ROLES22 = []
    this.BRANCHES22 = []
    this.ROLES23 = []
    this.BRANCHES23 = []
    this.user = Object.assign({}, data)
    if (data.ROLE_IDS_ST != null && data.BRANCH_IDS_ST != null) {
      this.ROLES2 = data.ROLE_IDS_ST.split(',')
      this.BRANCHES2 = data.BRANCH_IDS_ST.split(',')
      console.log(this.ROLES2,'ROLES2');
      this.ROLES = this.ROLES2
      this.BRANCHES = this.BRANCHES2
      this.ROLES22 = []

      for (let i = 0; i < this.ROLES.length; i++) {
        var kk = this.roles.findIndex(x => x.ID == this.ROLES[i]);
        if (kk != undefined && kk != null)
          this.ROLES22.push(this.roles[kk]['NAME']);
        this.BRANCHES22 = []
        for (let j = 0; j < this.BRANCHES.length; j++) {
          this.finalData.push({ "ROLE_ID": this.ROLES[i], "BRANCH_ID": this.BRANCHES[j], "RATE": 0 })
          // console.log(this.finalData);
          var kkk = this.branch1.findIndex(x => x.ID == this.BRANCHES[j]);
          if (kkk != undefined && kkk != null)
            this.BRANCHES22.push(this.branch1[kkk]['NAME'])
        }
        if (i + 1 == this.ROLES.length) {
          this.dataList = [...[], ...this.finalData]
          console.log(this.dataList);
        }
      }
      this.api.financeIncentiveDetails(0, 0, '', 'asc', ' AND FINANCE_INCENTIVE_MASTER_ID=' + data.ID  +' AND TYPE="S"').subscribe(data => {
        if (data['code'] == 200) {
          this.branch = data['data'];
          this.drawerData12 = this.branch
          this.dataList = this.drawerData12
          console.log(this.dataList);
        }
      }, err => {
        console.log(err);
      });

    }
    if (data.ROLE_IDS_OT != null && data.BRANCH_IDS_OT != null) {
      this.ROLE23 = data.ROLE_IDS_OT.split(',')
      this.BRANCH23 = data.BRANCH_IDS_OT.split(',')
      this.ROLES1 = this.ROLE23
      this.BRANCHES1 = this.BRANCH23
      this.ROLES23 = []
      console.log(this.ROLE23, 'ROLE23');

      for (let i = 0; i < this.ROLES1.length; i++) {
        var kk = this.roles.findIndex(x => x.ID == this.ROLES1[i]);
        if (kk != undefined && kk != null)
          this.ROLES23.push(this.roles[kk]['NAME']);
        this.BRANCHES23 = []
        for (let j = 0; j < this.BRANCHES1.length; j++) {
          this.finalData.push({ "ROLE_ID": this.ROLES1[i], "BRANCH_ID": this.BRANCHES1[j], "RATE": 0 })
          var kkk = this.branch1.findIndex(x => x.ID == this.BRANCHES1[j]);
          if (kkk != undefined && kkk != null)
            this.BRANCHES23.push(this.branch1[kkk]['NAME'])
        }
        if (i + 1 == this.ROLES1.length) {
          this.datalist23 = [...[], ...this.finalData]
          console.log(this.datalist23);
        }
      }
      this.api.financeIncentiveDetails(0, 0, '', 'asc', 'AND TYPE="O" AND FINANCE_INCENTIVE_MASTER_ID=' + data.ID ).subscribe(data => {
        if (data['code'] == 200) {
          this.branch = data['data'];
          this.drawerData12 = this.branch
          this.datalist23 = this.drawerData12
          console.log(this.datalist23);
        }
      }, err => {
        console.log(err);
      });

    }


  }


  handleApkVersionModalOk(){
    // const printContents = document.getElementById('report_id').innerHTML;

    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();

    // document.body.innerHTML = originalContents;
    // window.location.reload();
    // this.isApkVersionModalVisible=false

    this.generatePDF()

  }

  public generatePDF() {
  
    // var i = 0;
    // var date = new Date();
    // var datef = this.datePipe.transform(date, "dd-MM-yyyy");
    // var dates = this.datePipe.transform(date, "h:mm:ss a");
    var data = document.getElementById('report_id');
   
    var opt = {
      margin:       0.2,
      filename:     'myfile.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 4 },
      jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait' }
    };
    // html2pdf().set(opt).from(data).save();



  }
}
