import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { PSFActivityLeadMaster } from 'src/app/Models/psfaddacivity';
import { PSFCOLLAPSEMASTER } from 'src/app/Models/psfmaster';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRMaster } from 'src/app/Models/SMRmaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-psfleadstable',
  templateUrl: './psfleadstable.component.html',
  styleUrls: ['./psfleadstable.component.css']
})
export class PsfleadstableComponent implements OnInit {
  formTitle = "PSF Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  filterClass: string = "filter-invisible";
  loadingRecords = false;
  loadingRecords1=false
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NUMBER", "Sequence No."], ["WORKSHOP_CODE", "WorkShop Code"],
                         ["RO_NUMBER", "RO Number"], ["BILL_DATE", "Bill Date"], ["RO_DATE", "Ro Date"], 
                         ["CUSTOMER_NAME", "Customer Name"],["MOBILE_NUMBER", "Mobile Number"], ["VIN", "VIN"],
                         ["REGISTRATION_NO", "Reg Number"],["MODEL", "Model Name"], ["SERVICE_ADVISER_NAME", "Service Advisor Name"], 
                         ["WORK_TYPE", "Work Type"],["SALE_DATE", "Sale Date"], ["SPECIAL_MESSAGE", "Special Massage"], 
                         ["FOLLOWUP_DATE", "FollowUp Date"],  ["PSF_CALLING_DATE", "PSF Date"],  ["PSF_CALLING_TIME", "PSF_CALLING_TIME"],
                         ["PSF_CALLING_STATUS", "PSF Status"]];
  isPSFLead: boolean = true;
  PSFdrawerVisible!: boolean;
  drawerTitle!: string;
  index: number = 0;

  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  // PSFdrawerVisible!: boolean; 
  // dataList:any[]=[]


  collapseDrawerVisible: boolean = false;
  drawerData1: PSFCOLLAPSEMASTER = new PSFCOLLAPSEMASTER();
  isClosed = false;
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData2: PSFActivityLeadMaster = new PSFActivityLeadMaster();
  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false

  NotConnected: string = 'default'
  servicedue: string = 'default'
  own: string = 'default'

  Reminder: string = 'default'
  Untouched: string = 'default'
  Connected: string = 'default'
  count: any
  RO_NUMBER:any
  constructor(private api: ApiService,private _exportService: ExportService,private message: NzNotificationService,private datePipe: DatePipe,private cookie: CookieService) { }

  ngOnInit() {
   
    this.getEmployee()
    this.getAllpsfsubcount()


    this.Untouched = "primary"
    this.Reminder = "default",
      this.NotConnected = "default"

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    // console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var filter = " AND PSF_CALLING_STATUS IN('-')"
    this.value2 = "-"

    // var datefilter
    // if (
    //   (this.date != undefined && this.date.length != 0) ||
    //   (this.date1 != undefined && this.date1.length != 0)
    // ) {
    //   this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')
    //   this.DATE1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    //   datefilter =" AND DATE(BILL_DATE) BETWEEN '" + this.DATE + "' AND '" + this.DATE1 + "' "
    // }
    var filtr = " AND ASSIGNED_ID = "+ this.userid

    this.api.getPSFMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + filter + filtr).subscribe(data => {
      if (data['code'] == 200 ){
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.getAllpsfsubcount()
      }
      else{ 

        this.loadingRecords = false;
        this.getAllpsfsubcount()
        // this.message.error("")
      }
       

    }, err => {
      // console.log(err);
    });

  }
  importInExcel() {
    console.log("true")
    this.search(this.value, this.value2, false, true);
  }
  employee = []
  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=50').subscribe(data => {
      if(data['code']==200){
        this.employee = data['data']
        // this.SHEET_ID = data['data'][0]['EMPLOYEE_ID'] 
      //  this.CCEEE( this.SHEET_ID)
      }
     
    })
  }

  cancel(): void {
    // this.nzMessageService.info('click cancel');
  }
  // getAllpsfsubcount

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }
  date: any 
  date1: any 
  DATE: any;
  DATE1: any

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'
    this.pageIndex=1
    this.pageSize=10
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }



    if (this.date != null && this.date1 != null) {
      this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')

      this.DATE1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    }

    this.search(this.value,this.value2);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.date = null
    this.date1 = null
    this.NO_OF_DAYS = null
    this.filterQuery = "" 
    this.search(this.value,this.value2);
    this.isFilterApplied = 'default';

  }

  getAllpsfsubcount() {
    this.api.getAllpsfsubcount(0, 0, '', '', ' AND ASSIGNED_ID ='+this.userid).subscribe(data => {
      // if (data['code'] == 200 && data['count'] > 0)

      this.count = data['CategoryCounts'][0]

      console.log(this.count,"count")


    }, err => {
      // console.log(err);
    });
  }

  preventSingleClick = false;

  timer: any;

  delay: Number;

  singleClick() {

    this.preventSingleClick = false;

    const delay = 200;

    this.timer = setTimeout(() => {

      if (!this.preventSingleClick) {

        //Navigate on single click

      }

    }, delay);

  }

  drawerClose(): void {
    this.search(this.value,this.value2);
    this.PSFdrawerVisible = false;
  }

  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }

  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  drawerClose1(): void {
    this.search(this.value,this.value2);
    this.collapseDrawerVisible = false;
   
    this.isClosed = true
  
    
    // this.index=0;
  }
  drawerClose2(): void {
    this.drawerVisible1 = false;
    this.search(this.value,this.value2);
    this.btnLoadMoreStatus = null;
    this.SERVICE_ADVISER_ID = null
    this.CCEREMARK=''
  }
  datalist = []
  btnLoadMoreStatus: boolean
  SERVICE_ADVISER_NAME:any
  customername:any
  CCEREMARK:any
  modile_no:any
  SERVICE_ADVISER_ID:any
  feedbackdate:any
  disablesavebutton
  Activity(dataa) {
    this.drawerTitle1 = "Create New PSF Lead Activity";
    this.btnLoadMoreStatus = null
    this.drawerVisible1 = true;
    this.drawerData2 = Object.assign({}, dataa);
    this.drawerData2.ACTIVITY_TYPE_ID = 2
    this.SERVICE_ADVISER_ID = this.drawerData2.SERVICE_ADVISER_ID
    this.drawerData2.PREVEOUS_WORK_TYPE = dataa.PREVEOUS_WORK_TYPE
    this.SERVICE_ADVISER_NAME = this.drawerData2.SERVICE_ADVISER_NAME
    this.customername = this.drawerData2.CUSTOMER_NAME
    this.modile_no = this.drawerData2.MOBILE_NUMBER
   
     this.feedbackdate = this.drawerData2.FOLLOWUP_DATE
    this.RO_NUMBER = this.drawerData2.RO_NUMBER

    this.disablesavebutton = false

    this.api.getAllPSFActivityleadMaster(this.pageIndex, 5, '', '', ' AND RO_NUMBER = ' + "'" + dataa.RO_NUMBER + "'").subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0)

        this.totalRecords = data['count'];
      this.datalist = data['data'];
      if (this.datalist.length > 4) {
        this.btnLoadMoreStatus = true;

      } else {
        this.btnLoadMoreStatus = false;
      }

    }, err => {
      // console.log(err);
    });
  }
  edit(data: PSFCOLLAPSEMASTER): void {
    this.drawerTitle = " Forms ";
    this.drawerData1 = Object.assign({}, data);
    // this.drawerData1=Object.assign({},data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    // this.SMRdrawerVisible = true;
    this.collapseDrawerVisible = true;
    this.active1 == false
    this.active2 == false
    this.active3 == false
    this.active4 == false
    this.active5 == false
    this.active6 == false
  }


  add(): void {
    this.drawerTitle = " PSF Leads ";
    this.drawerData = new ServiceModuleExcel();
    this.PSFdrawerVisible = true;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(this.value,this.value2,false);
    // this.search(false);
  }

  keyup(event: any) {
    this.search(this.value,this.value2);


  }

  value: any
  value2: any
  value3: any
  exportLoading
  dataList12:any=[]

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(this.value, this.value2);
  }
  NO_OF_DAYS
  search(value, value2, reset: boolean = false, exportInExcel: boolean = false) {

    if( this.searchText != ''){
      this.checked1 = false
      this.array = []
      console.log(this.checked1,"this.checked1")
    }
    console.log(exportInExcel, "exportInExcel");
    if (value2 == '-') {
      this.Untouched = 'primary'
      this.Reminder = 'default'
      this.NotConnected = 'default'
    } else if (value2 == 'R') {
      this.Reminder = 'primary'
      this.Untouched = 'default'
      this.NotConnected = 'default'
    } else if (value2 == 'NC') {
      this.Untouched = 'default'
      this.Reminder = 'default'
      this.NotConnected = 'primary'
    }
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    // console.log("search text : " + this.searchText);
    var filter2 = ''
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      
      filter2 = ' AND PSF_CATEGORY_STATUS NOT IN ("DS","D","S")'
    }
    this.value = value
    this.value2 = value2
    var filter = ''
    if(this.searchText == ""){
  
    if (this.value2 == "-") {
      filter = " AND PSF_CALLING_STATUS IN('-')"
    }
    else if (this.value2 == "R") {
      filter = " AND PSF_CALLING_STATUS IN('R')"
    }
    else {
      filter = " AND PSF_CALLING_STATUS IN('NC')"
    }
    }
    var datefilter
    if (
      (this.date != undefined && this.date.length != 0) ||
      (this.date1 != undefined && this.date1.length != 0)
    ) {
      datefilter = " AND DATE(BILL_DATE) BETWEEN '" + this.DATE + "' AND '" + this.DATE1 + "' "
    } else {
      datefilter = ''
    }

    var NO_OF_DAYSNO = ''
     
    if ((this.NO_OF_DAYS != null || this.NO_OF_DAYS != undefined)) {
      NO_OF_DAYSNO = ` AND NO_OF_DAYS = '${this.NO_OF_DAYS}'`

    }

    var filtr = " AND ASSIGNED_ID = " + this.userid
    if (exportInExcel == false) {
      this.api.getPSFMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + filter + datefilter + filtr + NO_OF_DAYSNO + filter2).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.getAllpsfsubcount()
          if(data['count'] == 1){
            var button = ''
            button = data['data'][0]['PSF_CALLING_STATUS']
            if (button == '-') {
              this.Untouched = 'primary'
              this.Reminder = 'default'
              this.NotConnected = 'default'
            } else if (button == 'R') {
              this.Reminder = 'primary'
              this.Untouched = 'default'
              this.NotConnected = 'default'
            } else if (button == 'NC') {
              this.Untouched = 'default'
              this.Reminder = 'default'
              this.NotConnected = 'primary'
            }
          }
        }
        else {
          this.loadingRecords = false;
          this.getAllpsfsubcount()
        }
      }, err => {
        // console.log(err);
      });
    }
    else {
      this.exportLoading = true
      this.api.getPSFMastertabledata(0,0, this.sortKey, sort, likeQuery + filter + datefilter + filtr +NO_OF_DAYSNO + filter2).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          // this.SalesExecutiveWiseModelWiseSummary = data['data'];
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList12 = data['data'];
          console.log(this.dataList12, "this.dataList2343");
          this.convertInExcel();
        }
      else{
        this.exportLoading = false;
        // this.SalesExecutiveWiseModelWiseSummary = data['data'];
        this.loadingRecords = false;
      }
      }, err => {
        // console.log(err);
      });
    }
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList12.length; i++) {
      obj1['Workshop Code'] = this.dataList12[i]['WORKSHOP_CODE'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Model'] = this.dataList12[i]['MODEL_NAME'];
      obj1['RO Number'] = this.dataList12[i]['RO_NUMBER'];
      obj1['Bill Date'] = this.dataList12[i]['BILL_DATE'];
      obj1['RO Date'] = this.dataList12[i]['RO_DATE'];
      obj1['Customer Name'] = this.dataList12[i]['CUSTOMER_NAME'];
      obj1['Mobile Number'] = this.dataList12[i]['MOBILE_NUMBER'];
      obj1['VIN'] = this.dataList12[i]['VIN'];
      obj1['Registration No'] = this.dataList12[i]['REGISTRATION_NO'];
      obj1['Model'] = this.dataList12[i]['MODEL'];
      obj1['Service Advisor Name'] = this.dataList12[i]['SERVICE_ADVISER_NAME'];
      obj1['Work Type'] = this.dataList12[i]['WORK_TYPE'];
      obj1['Sale Date'] = this.dataList12[i]['SALE_DATE'];
      obj1['Special Message'] = this.dataList12[i]['SPECIAL_MESSAGE'];
      obj1['Follow-up-Date'] = this.dataList12[i]['FOLLOWUP_DATE'];
      // obj1['Bill Date'] = this.dataList12[i]['BILL_DATE'];
      // obj1['Model'] = this.dataList12[i]['MODEL'];
      // obj1['Remark'] = this.dataList12[i]['REMARK'];
      // obj1['Reminder Date'] = this.dataList12[i]['REMINDER_DATE'];
      // obj1['Followup No.'] = this.dataList12[i]['FOLLOWUP_NO'];
      // obj1['Call Date'] = this.dataList12[i]['CALL_DATE'];
      // obj1['Service Count'] = this.dataList12[i]['SERVICE_COUNT'];
      // obj1['Customer Number'] = this.dataList12[i]['CUST_NO'];
      // obj1['Special Message'] = this.dataList12[i]['SPECIAL_MESSAGE'];
      // obj1['Ro Date'] = this.dataList12[i]['RO_DATE'];
      // obj1['Ro Number'] = this.dataList12[i]['RO_NUMBER'];
      // obj1['Number Of Calls'] = this.dataList12[i]['NUMBER_OF_CALLS'];
      // obj1['Live Streaming'] = this.dataList12[i]['LIVE_STREAMING'];
      // obj1['Status'] = this.dataList12[i]['STATUS'];
      // obj1['Delivery Date'] = this.dataList12[i]['DELIVERY_DATE'];
      // obj1['Location'] = this.dataList12[i]['LOCATION'];
      // obj1['Pincode'] = this.dataList12[i]['PIN_CODE'];
      // obj1['Varient'] = this.dataList12[i]['VARIANT'];
      // obj1['Chassis Number'] = this.dataList12[i]['CHASSIS_NUMBER'];
      // obj1['Sale Consultant Name'] = this.dataList12[i]['SALES_CONSULTANT_NAME'];
      // obj1['Sale Date'] = this.dataList12[i]['SALE_DATE'];
      // obj1['Last Service type'] = this.dataList12[i]['LAST_SERVICE_TYPE'];
      // obj1['Last Ro Date'] = this.dataList12[i]['LAST_RO_DATE'];
      // obj1['Segment'] = this.dataList12[i]['SEGMENT'];
      // obj1['Last Ro Workshop'] = this.dataList12[i]['LAST_RO_WORKSHOP'];
      // obj1['Last Ro Dealer Name'] = this.dataList12[i]['LAST_RO_DEALER_NAME'];
      // obj1['Last Ro region'] = this.dataList12[i]['LAST_RO_REGION'];
      // obj1['Name'] = this.dataList12[i]['NAME'];
      // obj1['Work Phone Number'] = this.dataList12[i]['WORK_PHONE_NUMBER'];
      // obj1['Root'] = this.dataList12[i]['ROOT'];
      // obj1['SME Name'] = this.dataList12[i]['SME_NAME'];
      // obj1['Pick Up and Drop'] = this.dataList12[i]['PICK_UP_AND_DROP'];
      // obj1['Lost Customer Name'] = this.dataList12[i]['LOST_CUSTOMER_NAME'];
      // obj1['SRV Model'] = this.dataList12[i]['SRV_MODEL'];
      // obj1['Email'] = this.dataList12[i]['EMAIL'];
      // obj1['Address'] = this.dataList12[i]['ADDRESS'];
      // obj1['Purchase Date'] = this.dataList12[i]['PURCHASE_DATE'];
      // obj1['Preferred DLR No.e'] = this.dataList12[i]['PREFERRED_DLR_NO'];
      // obj1['Office Number'] = this.dataList12[i]['OFFICE_NUMBER'];
      // obj1['Resindence Number'] = this.dataList12[i]['RESIDENCE_NUMBER'];
      // obj1['Mileage'] = this.dataList12[i]['MILEAGE'];
      // obj1['Field 1'] = this.dataList12[i]['FIELD1'];
      // obj1['Field 2'] = this.dataList12[i]['FIELD2'];
      // obj1['Field 3'] = this.dataList12[i]['FIELD3'];
      // obj1['Field 4'] = this.dataList12[i]['FIELD4'];
      // obj1['Field 5'] = this.dataList12[i]['FIELD5'];
      // obj1['Field 6'] = this.dataList12[i]['FIELD6'];
      // obj1['Field 7'] = this.dataList12[i]['FIELD7'];
      // obj1['Field 8'] = this.dataList12[i]['FIELD8'];
      // obj1['Field 9'] = this.dataList12[i]['FIELD9'];
      // obj1['Field 10'] = this.dataList12[i]['FIELD10'];
      // obj1['Resindence Number'] = this.dataList12[i]['NO_OF_INVOICE_GENERATED'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList12.length - 1) {
        this._exportService.exportExcel(arry1, 'PSF Data' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  checked = false;
  checked1 = false;
  checkedd = true;
  indeterminate = true;
  indeterminate1 = false;
  setOfCheckedId = new Set<number>();
  setOfCheckedId1 = new Set<number>();
  array = []
  userid = this.cookie.get('userId')
  value1: any;
  CCE_IDS:any
  isChecked: boolean
  // onChecked1(id: number, checked: boolean): void {
  //   if (checked) {
  //     console.log(checked);
  //     this.isChecked = checked
  //     this.array.push(id)
  //     console.log(this.array);
  //     this.indeterminate = false
  //     // this.dataList[ind].IS_SELECTED = 1
  //   } else {
  //     // this.dataList[ind].IS_SELECTED = 0
  //     this.array.pop()
  //     console.log(this.array);
  //     this.indeterminate = true

  //   }

  // }

  disable11:boolean=false
  onChecked1(id: number, checked: boolean): void {
   
    // this.code = value
    if (checked) {
      // console.log(checked);
      this.isChecked = checked
      this.array.push(id)
      this.disable11=true
      // console.log(this.array);
      this.indeterminate = false
      // this.dataList[ind].IS_SELECTED = 1
    } else {
      // this.dataList[ind].IS_SELECTED = 0
      this.array.pop()
      this.disable11=false
      // console.log(this.array);
      this.indeterminate = true
    }
  }

  onAllChecked1(value: boolean): void {
    console.log(value);
    this.dataList.forEach(item => {
      this.isChecked = value
      if (value) {
        if (value == true) {
          this.array.push(item.ID)
          this.checked1 = true
          this.value1 = value
          // this.checked=false
          this.indeterminate = false
        }
        console.log(this.array);
        // item.IS_SELECTED = 1
      } else {
        // item.IS_SELECTED = 0
        this.array = []
        this.checked1 = false
        this.indeterminate = true
      }

    });

  }

  MapCCE() {
    if (this.CCE_IDS != undefined && this.array.length > 0) {
      this.api.MappsfCCE(this.CCE_IDS, this.array,'')
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("CCE Mapped Successfully ...", "");
            this.drawerClose();
            // this.isSpinning = false;
            this.array = []
            // this.checked = false
            this.CCE_IDS = null
            this.dataList.forEach(item => {
              this.isChecked = this.value1
              if (this.value1) {
                if (this.value1 == true) {
                  this.checked = false
                  this.checked1 = false
                  this.indeterminate = false
                }
                console.log(this.array);
                // item.IS_SELECTED = 1
              } else {
                // item.IS_SELECTED = 0
                this.array = []
                this.checked1 = false
                this.indeterminate = true
              }

            });
          }
          else {
            this.message.error("CCE Mapping Failed...", "");
            this.array = []
            // this.isSpinning = false;
          }
        });
    } else {
      this.message.error("Please Select Leads & Call Center Executive", "");
    }
  }
}




