import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-department-wise-avg-attendance-report",
  templateUrl: "./department-wise-avg-attendance-report.component.html",
  styleUrls: ["./department-wise-avg-attendance-report.component.css"],
})
export class DepartmentWiseAvgAttendanceReportComponent implements OnInit {
  formTitle = "Department Wise Avg Attendance Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'DepartmentWiseAvgAttendanceReport.xlsx';

  orgId = this.cookie.get("orgId");
  START_DATE: any = [];
  END_DATE: any = [];
  DEPARTMENT_ID = [];
  DAY_COUNT: any;

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  SELECT_ALL: boolean = false;
  onSelectAllChecked(event) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.departments.length; i++) {
        ids.push(this.departments[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.DEPARTMENT_ID = ids;
  }

  onSelectOff(event) {
    var a = this.departments.length;
    var b = this.departments.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.DEPARTMENT_ID = event;
    if (this.DEPARTMENT_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  departments = [];

  getAttendanceDepartments() {
    this.departments = [];

    this.api
      .getAllAttendanceDepartments(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.departments = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  getCount() {
    const fromDateDCFilter = new Date(this.START_DATE);
    const toDateDCFilter = new Date(this.END_DATE);

    const Time = Math.round(
      toDateDCFilter.getTime() - fromDateDCFilter.getTime()
    );
    this.DAY_COUNT = Math.round(Time / (1000 * 3600 * 24));
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = [];
    this.END_DATE = [];
    this.DEPARTMENT_ID = [];
    this.SELECT_ALL = false;
    this.setDateToDateFilter();
    this.getCount();
  }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";

    this.getCount();
    this.setDateToDateFilter();
    this.getAttendanceDepartments();
    this.getDepartmentWiseAvgAttendanceReport();
  }

  applyFilter() {
    if (
      (this.START_DATE != undefined &&
        this.START_DATE != null &&
        this.START_DATE.length >= 0 &&
        this.END_DATE != undefined &&
        this.END_DATE != null &&
        this.END_DATE.length >= 0) ||
      this.DEPARTMENT_ID.length > 0
    )
      this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";

    this.getDepartmentWiseAvgAttendanceReport(true);
    this.getCount();
  }

  sort(sort: { key: string; value: string }): void {
    this.DepartmentWiseAvgAttendanceReportSortKey = sort.key;
    this.DepartmentWiseAvgAttendanceReportSortValue = sort.value;
    this.getDepartmentWiseAvgAttendanceReport(true);
  }

  DepartmentWiseAvgAttendanceReportPageIndex = 1;
  DepartmentWiseAvgAttendanceReportPageSize = 10;
  DepartmentWiseAvgAttendanceReportTotalRecords = 1;
  DepartmentWiseAvgAttendanceReportSortValue: string = "desc";
  DepartmentWiseAvgAttendanceReportSortKey: string = "ID";
  DepartmentWiseAvgAttendanceReportData = [];

  columns: string[][] = [
    ["DEPARTMENT_NAME", "Department Name"],
    ["TOTAL", "Total"],
    ["AVERAGE_INTIME", "Avg In Time"],
    ["AVERAGE_LATE", "Avg Late"],
    ["AVERAGE_LATEMARK", "Avg Latemark"],
    ["AVERAGE_LEAVE", "Avg Leave"],
    ["AVERAGE_ABSENT", "Avg Absent"],
    ["AVERAGE_DAYIN", "Avg Day In"],
    ["AVERAGE_DAYOUT", "Avg Day Out"],
    ["AVERAGE_DAYOUT_PERCENTAGE", "Avg Day Out %"],
  ];

  getDepartmentWiseAvgAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.DepartmentWiseAvgAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DepartmentWiseAvgAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
    }

    if (this.DAY_COUNT != undefined && this.DAY_COUNT.length != 0) {
      this.getCount();
    }

    this.loadingRecords = true;
    this.api
      .getDepartmentWiseAvgAttendanceReport(
        this.DepartmentWiseAvgAttendanceReportPageIndex,
        this.DepartmentWiseAvgAttendanceReportPageSize,
        this.DepartmentWiseAvgAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        fromDateFilter,
        toDateFilter,
        DepartmentFilter,
        this.DAY_COUNT
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DepartmentWiseAvgAttendanceReportTotalRecords = data["count"];
            this.DepartmentWiseAvgAttendanceReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }
  exportexcel(): void {
    this.loadingRecords = true;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Department Attendance Report');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }, 1000);
  }
}
