import { Component,  Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { Roledetails } from 'src/app/Models/roledetails';
@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.css']
})
export class AllReportsComponent implements OnInit {

  @Input() data: Roledetails = new Roledetails
  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  PARENT_ID=198;
  Allactivity: any;
  pageName2: string = "";
  menus = [];
  menus1 = [];
  menus2 = [];
  menus3 = [];
  menus4 = [];
  menus5 = [];
  menus6 = [];
  menus7 = [];
  menus8 = [];
  menus9 = [];
  menus10 = [];
  menus11 = [];
  menus12 = [];

 

constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) {}
ngOnInit(){
  console.log(this.data.PARENT_ID)
this.api.getRoleDetails1(this.roleId,"AND PARENT_ID= 306").subscribe(data => {
      console.log("data");
      console.log(data['data']);
      this.menus = data['data'];
    }, err => {
      console.log(err);
});
this.api.getRoleDetails2(this.roleId,"AND PARENT_ID= 312").subscribe(data => {
    console.log("data");
    console.log(data['data']);
    this.menus1 = data['data'];
}, err => {
    console.log(err);
});
this.api.getRoleDetails3(this.roleId,"AND PARENT_ID= 279").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus2 = data['data'];
}, err => {
  console.log(err);
});
this.api.getRoleDetails4(this.roleId,"AND PARENT_ID= 282").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus3 = data['data'];
}, err => {
  console.log(err);
});
this.api.getRoleDetails5(this.roleId,"AND PARENT_ID= 288").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus4 = data['data'];
}, err => {
  console.log(err);
});
this.api.getRoleDetails6(this.roleId,"AND PARENT_ID= 303").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus5 = data['data'];
}, err => {
  console.log(err);
});
this.api.getRoleDetails7(this.roleId,"AND PARENT_ID= 293").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus6 = data['data'];
}, err => {
  console.log(err);
});
this.api.getRoleDetails8(this.roleId,"AND PARENT_ID= 291").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus7 = data['data'];
}, err => {
  console.log(err);
});

this.api.getRoleDetails9(this.roleId,"AND PARENT_ID= 273").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus8 = data['data'];
}, err => {
  console.log(err);
});

this.api.getRoleDetails10(this.roleId,"AND PARENT_ID= 253").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus9 = data['data'];
}, err => {
  console.log(err);
});

this.api.getRoleDetails11(this.roleId,"AND PARENT_ID= 295").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus10 = data['data'];
}, err => {
  console.log(err);
});

this.api.getRoleDetails12(this.roleId,"AND PARENT_ID= 299").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus11 = data['data'];
}, err => {
  console.log(err);
});
this.api.getRoleDetails13(this.roleId,"AND PARENT_ID= 310").subscribe(data => {
  console.log("data");
  console.log(data['data']);
  this.menus12 = data['data'];
}, err => {
  console.log(err);
});

}

selected:any;
target:any;


currentIndex  = -1
currentIndex1 = -1
currentIndex2 = -1
currentIndex3 = -1
currentIndex4 = -1
currentIndex5 = -1
currentIndex6 = -1
currentIndex7 = -1
currentIndex8 = -1
currentIndex9 = -1
currentIndex10 = -1
currentIndex11 = -1
currentIndex12 = -1

dataShow:boolean=true
dataShow1:boolean=true
dataShow2:boolean=true
dataShow3:boolean=true
dataShow4:boolean=true
dataShow5:boolean=true
dataShow6:boolean=true
dataShow7:boolean=true
dataShow8:boolean=true
dataShow9:boolean=true
dataShow10:boolean=true
dataShow11:boolean=true
dataShow12:boolean=true

isSelected:boolean;
isSelected1:boolean;
isSelected2:boolean;
isSelected3:boolean;
isSelected4:boolean;
isSelected5:boolean;
isSelected6:boolean;
isSelected7:boolean;
isSelected8:boolean;
isSelected9:boolean;
isSelected10:boolean;
isSelected11:boolean;
isSelected12:boolean;

sm1: Boolean = undefined;
navigateTo(data,index: number) {
  this.dataShow=false;
  this.currentIndex = index;
  this.isSelected = true;
  if (data.value === '/customermemo') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo1(data,index: number) {
  this.dataShow1=false;
  this.currentIndex1 = index;
  this.isSelected1 = true;
  if (data.value === '/discountDetailed') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo2(data,index: number) {
  this.dataShow2=false;
  this.currentIndex2 = index;
  this.isSelected2 = true;
  if (data.value === '/memo') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo3(data,index: number) {
  this.dataShow3=false;
  this.currentIndex3 = index;
  this.isSelected3 = true;
  if (data.value === '/ModelWiseDailyDetailedReport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo4(data,index: number) {
  this.dataShow4=false;
  this.currentIndex4 = index;
  this.isSelected4 = true;
  if (data.value === '/customermemo') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo5(data,index: number) {
  this.dataShow5=false;
  this.currentIndex5 = index;
  this.isSelected5 = true;
  if (data.value === '/Financebankwisesummaryreport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo6(data,index: number) {
  this.dataShow6=false;
  this.currentIndex6 = index;
  this.isSelected6 = true;
  if (data.value === '/Bankwisememosummary') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo7(data,index: number) {
  this.dataShow7=false;
  this.currentIndex7 = index;
  this.isSelected7 = true;
  if (data.value === '/quotationDetailedReport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo8(data,index: number) {
  this.dataShow8=false;
  this.currentIndex8 = index;
  this.isSelected8 = true;
  if (data.value === '/BranchWiseDiscountReport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo9(data,index: number) {
  this.dataShow9=false;
  this.currentIndex9 = index;
  this.isSelected9 = true;
  if (data.value === '/SalesExecutiveWiseSummaryReport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo10(data,index: number) {
  this.dataShow10=false;
  this.currentIndex10 = index;
  this.isSelected10 = true;
  if (data.value === '/salesManagerFinance') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

navigateTo11(data,index: number) {
  this.dataShow11=false;
  this.currentIndex11 = index;
  this.isSelected11 = true;
  if (data.value === '/Insurancecompanywisesummaryreport1') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}
navigateTo12(data,index: number) {
  this.dataShow12=false;
  this.currentIndex12 = index;
  this.isSelected12 = true;
  if (data.value === '/Salewisedetaileddiscountreport') {
    this.sm1 = true;
    console.log(data);
  } else {
    this.sm1 = false;
   }
}

backPage(){
  this.dataShow=true;
  this.dataShow1=true;
  this.dataShow2=true;
  this.dataShow3=true;
  this.dataShow4=true;
  this.dataShow5=true;
  this.dataShow6=true;
  this.dataShow7=true;
  this.dataShow8=true;
  this.dataShow9=true;
  this.dataShow10=true;
  this.dataShow11=true;
  this.dataShow12=true;

}
}
