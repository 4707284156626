import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { PSFCOLLAPSEMASTER } from 'src/app/Models/psfmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add-psf-master',
  templateUrl: './add-psf-master.component.html',
  styleUrls: ['./add-psf-master.component.css']
})
export class AddPsfMasterComponent implements OnInit {

  @Input()
  collapseDrawerVisible: boolean = false;
  @Input()
  data1: PSFCOLLAPSEMASTER = new PSFCOLLAPSEMASTER();
  @Input()
  drawerClose1!: Function;
  isOk = true;

  date = null;
  isEnglish = false;


  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  longitudepattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
  latitudepattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
  pat1 = /^\d{6}$/;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  cityRegex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  aaddpattern = /^[a-zA-Z0-9\s,'-]*$/
  namepatte = /[ .a-zA-Z]+/
  passpattern = /^\S*$/

  isSpinning = false;

  //   active1: boolean =false;
  //   active2:  boolean =false;  
  // active3:  boolean =false;  
  // active4:  boolean =false;  
  // active5:  boolean =false; 
  // active6:  boolean =false;

  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false

  constructor(private message: NzNotificationService, private api: ApiService) { }

  ngOnInit() {
    console.log(this.active1)

  }


  close1(): void {
    this.active6 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
    this.drawerClose1();
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  resetDrawer(accountMasterPage: NgForm) {
    this.data1 = new PSFCOLLAPSEMASTER();
    accountMasterPage.form.markAsPristine();
    accountMasterPage.form.markAsUntouched();
  }


  onChange(result: Date): void {
    console.log('onChange: ', result);
  }






  basicInfo() {
    this.active1 = true
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    //  console.log('ff')
  }
  Serviceadvisorinfoform() {
    this.active2 = true
    this.active1 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    //  console.log('ff') 
  }
  customerinfo() {
    this.active3 = true
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false

  }
  vehicleinfo() {
    this.active4 = true
    this.active3 = false
    this.active5 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false
  }

  Ratinginfo() {
    this.active5 = true
    this.active3 = false
    this.active4 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false

  }
  unknowninfo() {
    this.active6 = true
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
  }

  //save
  save(addNew: boolean): void {
    this.isSpinning = false;
    this.isOk = true;



    // create update

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data1.ID) {
          this.api.updatePSFMaster(this.data1).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success(' Information Updated Successfully...', '');
              if (!addNew) this.drawerClose1();
              this.isSpinning = false;
              this.active6 = false
              this.active3 = false
              this.active4 = false
              this.active5 = false
              this.active2 = false
              this.active1 = false
            } else {
              this.message.error(' Failed To Update Information...', '');
              this.isSpinning = false;
            }
          });
        }

      }
    }

  }

}
