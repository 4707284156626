import { Component, Input, OnInit } from '@angular/core';
import { RoleServiceDeptMap } from 'src/app/Models/RoleServiceDeptMap';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add-role-service-dm',
  templateUrl: './add-role-service-dm.component.html',
  styleUrls: ['./add-role-service-dm.component.css']
})
export class AddRoleServiceDMComponent implements OnInit {
  @Input()
  drawerClose2!: Function;

  @Input()
  data1: RoleServiceDeptMap = new RoleServiceDeptMap;

  @Input()
  mapdrawerVisible: boolean = false;

  // @Input()
  // servicemapid:any

  Roles = []

  subdept = []


  isSpinning = false;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.loadForm()
  }

  loadForm()
  {

     this.api.getAllRoles(0, 0, '', '', '').subscribe(data => {


    this.Roles = data['data'];
  }, err => {
    console.log(err);
  });
  }

  loadForm1()
  {

     this.api.getserviceSubDepartmentMaster(0, 0, '', '', '').subscribe(data => {


    this.subdept = data['data'];
  }, err => {
    console.log(err);
  });
  }

 
  

  // @Input()
  // mapdrawerVisible: boolean = true;

  // isSpinning = false
  // loadingForm = false
  // data: RoleServiceDeptMap = new RoleServiceDeptMap();

  // @Input()
  // drawerClose2!: Function;

}

// drawerClose2(){}