import { Component, OnInit, ViewChild } from '@angular/core';
import { BirthdayMaster } from 'src/app/Models/birthday-master';
import { ApiService } from 'src/app/Service/api.service';
import { BirthdayComponent } from '../birthday/birthday.component';

@Component({
  selector: 'app-birthdays',
  templateUrl: './birthdays.component.html',
  styleUrls: ['./birthdays.component.css']
})

export class BirthdaysComponent implements OnInit {
  formTitle = "Manage Birthday";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TITLE", "Title"], ["DESCRIPTION", "Description"]]

  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: BirthdayMaster = new BirthdayMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllBirthday(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  @ViewChild(BirthdayComponent, { static: false }) BirthdayComponentVar: BirthdayComponent;

  add(): void {
    this.drawerTitle = "Create New Birthday";
    this.drawerData = new BirthdayMaster();
    this.drawerVisible = true;
    this.BirthdayComponentVar.fileURL = null;
    this.BirthdayComponentVar.photoURL = null;
  }

  edit(data: BirthdayMaster): void {
    this.drawerTitle = "Update Birthday Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    if (this.drawerData.URL)
      this.BirthdayComponentVar.photoURL = this.drawerData.URL;

    else
      this.BirthdayComponentVar.photoURL = null;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
