import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';


@Component({
  selector: 'app-sales-executive-wise-advance-detailed-report',
  templateUrl: './sales-executive-wise-advance-detailed-report.component.html',
  styleUrls: ['./sales-executive-wise-advance-detailed-report.component.css']
})
export class SalesExecutiveWiseAdvanceDetailedReportComponent implements OnInit {

  formTitle = "Sales Executive Wise Advance Detailed Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'EWSalesSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
  ["CUSTOMER_NAME", "Customer Name"],
  ["DATE", "Date Of Advance Paid"],]


  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  // ticketGroups: Ticketgroup[] = [];
  index = 0;


  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  FROMDATE: any = new Date();
  TODATE: any = new Date();
  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_EXECUTIVE_ID: any = [];


  DATE = [];
  current = new Date();

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.FROMDATE =new Date(new Date().setDate(new Date().getDate() - 7))

    this.TODATE = new Date();
    this.getSales();
    // this.search();
  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      SALES_EXECUTIVE_ID: this.SALES_EXECUTIVE_ID,
      FROMDATE: this.FROMDATE,
      TODATE: this.TODATE,

    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 33').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(): void {
    // this.search(false); 
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  keyup(event: any) {
    this.search();
  }

  SELECT_ALL2: boolean = false;

  onSelectAllChecked2(event: any) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff2(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  // onFromDateChange(fromDate: any) {
  //   if (fromDate == null)
  //     this.date2 = new Date();

  //   else
  //     this.date2 = new Date(fromDate);
  // }

  sales = [];
  getSales() {
    this.api.getAllEmpRoleMap(0, 0,'ID', this.sortValue, ' AND ROLE_ID IN(28)')
      .subscribe(data => {
        this.sales = data['data'];
        if (this.sales.length > 0) {
          this.SALES_EXECUTIVE_ID = data['data'][0]['EMPLOYEE_ID'];
          console.log(this.sales);
          this.search();
        }
        else { }

      },
        (err) => {
          console.log(err);
        }
      );
  }

  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }

    this.search();

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE = []
    this.FROMDATE =new Date(new Date().setDate(new Date().getDate() - 7))

    this.TODATE = new Date();
    // this.SALES_EXECUTIVE_ID=[];
    this.getSales()
    this.dataList = [];
    // this.search();

  }
  ewsalesdata = [];

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.SALES_EXECUTIVE_ID != undefined) {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID;
    }

    if (this.FROMDATE.length != 0 && this.TODATE.length != 0) {
      this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
      this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    }

    this.FROMDATE = this.datePipe.transform(this.FROMDATE, 'yyyy-MM-dd');
    this.TODATE = this.datePipe.transform(this.TODATE, 'yyyy-MM-dd');


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.salesExeWiseAdvanceReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.SALES_EXECUTIVE_ID, this.FROMDATE, this.TODATE)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.salesExeWiseAdvanceReport(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.SALES_EXECUTIVE_ID, this.FROMDATE, this.TODATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['Sales Executive Name'] = this.ewsalesdata[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.ewsalesdata[i]['CUSTOMER_NAME'];
      obj1['Date Of Advance Paid'] = this.ewsalesdata[i]['DATE'];
      obj1['Total Amount Pending'] = this.ewsalesdata[i]['TOTAL_PENDING'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Customer Wise Advance Detailed Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }


}
