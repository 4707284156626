import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-branch-wise-sales-summary",
  templateUrl: "./branch-wise-sales-summary.component.html",
  styleUrls: ["./branch-wise-sales-summary.component.css"],
})

export class BranchWiseSalesSummaryComponent implements OnInit {

  formTitle = "Branch Wise Sales Summary Report";

  isFilterApplied: string = "default";
  filterClass: string = "filter-invisible";
  loadingRecords: boolean = false;

  orgId = this.cookie.get("orgId");
  FROM_DATE: any = new Date()
  TO_DATE: any = new Date()
  BRANCH_ID= [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService,
  ) { }

  ngOnInit() {
    this.getBranch();
    this.getBranchWiseSalesSummary(false);
  }

  SELECT_ALL: boolean = false;
  branches: any = []

  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1').subscribe((data1) => {
      this.branches = data1['data'];
      if (this.branches.length > 0) {
        this.BRANCH_ID = [data1['data'][0]['ID']];
        console.log(this.branches);
        // this.name = data['data'][0]['NAME'];
      }
      else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_ALL11: boolean = false;
  onSelectAllChecked2(event: any) {
    this.SELECT_ALL11 = event;
    let id = [];
    if (this.SELECT_ALL11 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        id.push(this.branches[i]["ID"]);
      }
    } else {
      id = [];
    }
    this.BRANCH_ID = id;
  }

  onSelectOff2(event: any) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL11 = false;
    } else {
      this.SELECT_ALL11 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL11 = false;
    }
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    this.isFilterApplied = "primary";
    this.getBranchWiseSalesSummary(false);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.SELECT_ALL = false;
    this.FROM_DATE = new Date()
    this.TO_DATE = new Date()

    this.getBranch();

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.branchWiseSalesSortKey = sort.key;
    this.branchWiseSalesSortValue = sort.value;
    this.getBranchWiseSalesSummary(true);
  }

  branchWiseSalesPageIndex = 1;
  branchWiseSalesPageSize = 10;
  branchWiseSalesTotalRecords = 15;
  branchWiseSalesSortValue: string = "desc";
  branchWiseSalesSortKey: string = "BRANCH_ID";
  branchWiseSalesData = [];

  columns: string[][] = [
    ["BRANCH_NAME", "Branch"],
    ["QUOTATION_COUNT", "Total Quotation"],
    ["MEMO_COUNT", "No. of memo converted"],
    ["INVOICE_COUNT", "No. of Invoice generated"],
    ["DISCOUNT_COUNT", "Total Discount Applied"],
  ];

  branchSummary = [];
  exportLoading: boolean = false;

  importInExcel() {
    this.getBranchWiseSalesSummary(true, true);
  }

  getBranchWiseSalesSummary(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.branchWiseSalesPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.branchWiseSalesSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.FROM_DATE != undefined && this.FROM_DATE.length != 0 && this.TO_DATE != undefined && this.TO_DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, "yyyy-MM-dd");
    }

    // var branchFilter = "";
    // if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
    //   branchFilter = this.BRANCH_ID.toString();
    // }
    if (this.BRANCH_ID != undefined) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (exportInExcel == false) {
      // this.loadingRecords = true;
      this.api.getBranchWisesalesSummary(this.branchWiseSalesPageIndex, this.branchWiseSalesPageSize, this.branchWiseSalesSortKey, sort,
        "", this.api.orgId, this.FROM_DATE, this.TO_DATE, this.BRANCH_ID).subscribe((data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.branchWiseSalesTotalRecords = data["count"];
            this.branchWiseSalesData = data["data"];
          }
        },
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        );
    }
    else {
      this.exportLoading = false;
      this.api.getBranchWisesalesSummary(0, 0, this.branchWiseSalesSortKey, sort, "", this.api.orgId, this.FROM_DATE, this.TO_DATE, this.BRANCH_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.branchSummary = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.branchSummary.length; i++) {
      obj1['Branch Name'] = this.branchSummary[i]['BRANCH_NAME'];
      obj1['Total Quotation'] = this.branchSummary[i]['QUOTATION_COUNT'];
      obj1['No. of memo converted'] = this.branchSummary[i]['MEMO_COUNT'];
      obj1['No. of Invoice generated'] = this.branchSummary[i]['INVOICE_COUNT'];
      obj1['Total Discount Applied'] = this.branchSummary[i]['DISCOUNT_COUNT'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.branchSummary.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Sales Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  // fileName = 'BranchWiseSalesSummary.xlsx';

  // exportexcel(): void {
  //   this.loadingRecords = true;
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: xlsx.WorkBook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Branch Wise Sales Summary');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     /* save to file */
  //     xlsx.writeFile(wb, this.fileName);
  //   }, 1000);
  // }

}
