import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ExpensesHeadMaster } from 'src/app/Models/expenses-head-master';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-expenseshead',
  templateUrl: './expenseshead.component.html',
  styleUrls: ['./expenseshead.component.css']
})

export class ExpensesheadComponent implements OnInit {
  formTitle = "Manage Expenses Head";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["DESCRIPTION", "Description"]]
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ExpensesHeadMaster = new ExpensesHeadMaster();
  orgId = this.cookie.get('orgId');

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.api.getAllExpenseHead(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND ORGANIZATION_ID =' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create Expense Head";
    this.drawerData = new ExpensesHeadMaster();
    this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;
  }

  edit(data: ExpensesHeadMaster): void {
    this.drawerTitle = "Update Expense Head";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
