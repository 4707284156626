import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NzDatePickerComponent, NzNotificationService } from "ng-zorro-antd";
import { UpdateAttendanceRegister } from "src/app/Models/UpdateAttendanceRegister";
import { ApiService } from "src/app/Service/api.service";
import { CookieService } from "ngx-cookie-service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: 'app-inactive-emp-report',
  templateUrl: './inactive-emp-report.component.html',
  styleUrls: ['./inactive-emp-report.component.css']
})
export class InactiveEmpReportComponent implements OnInit {
  monthFormat = "yyyy-MMM-dd";
  formTitle = "Salary process sheet";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  startValue1 = undefined;
  endValue: Date | null = null;
  endOpen = false;
  startOpen = false;
  isFilterApplied: string = "default";
  columns: string[][] = [
    ["1", "1"],
    ["2", "2"],
    ["3", "3"],
    ["4", "4"],
    ["5", "5"],
    ["6", "6"],
    ["7", "7"],
    ["8", "8"],
    ["9", "9"],
    ["10", "10"],
    ["11", "11"],
    ["12", "12"],
    ["13", "13"],
    ["14", "14"],
    ["15", "15"],
    ["16", "16"],
    ["17", "17"],
    ["18", "18"],
    ["19", "19"],
    ["20", "20"],
    ["21", "21"],
    ["22", "22"],
    ["23", "23"],
    ["24", "24"],
    ["25", "25"],
    ["26", "26"],
    ["27", "27"],
    ["28", "28"],
    ["29", "29"],
    ["30", "30"],
    ["31", "31"]];

  columns1: string[][] = [
    ["MONTH", "Month"],
    ["YEAR", "Year"],
    ["WORKING", "Total Days"],
    ["HOLIDAY", "Weakly Off"],
    ["P_LEAVE", "Paid Leave"],
    ["M_LEAVE", "Sick Leave"],
    ["C_LEAVE", "Casual Leave"],
    
    ["COMPOFF", "Comp Off"],
    ["CO_LEAVE", "Comp Off Leave Taken"],
    ["HALFDAY", "Halfday"],
    ["LATEMARK", "Morning Short Leave"], ["EARLYMARK", "Evening Short Leave"],
    ["LATECOMMING", "Late Coming"],
    ["D_LATEMARK", "Latemark Deduction"], ["ABSENT", "LWP"],
    ["PAYABLE_DAYS", "Payable days"]
  ]
  // deduction
  ROLE_ID = Number(this.cookie.get('roleId'))
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString();
  current = new Date();
  filterClass: string = "filter-invisible";
  initFilter = true;
  isSpinning = false;
  
  empId: number;
  drawerVisible: boolean;
  drawerData: UpdateAttendanceRegister = new UpdateAttendanceRegister();
  drawerTitle: string;
  excelDataList = [];
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService, private _exportService: ExportService) { }
  branchId = this.cookie.get('branchId')
  ngOnInit() {
    this.startValue1 = this.current.getMonth() + 1;
    this.endValue = this.current;
    this.getBranch();
    this.getDepartment();
    this.search(true);
    console.log(this.branchId);

  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }

    return startValue.getTime() > this.current.getTime();
  };

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
    this.startOpen = open;
  }

  @ViewChild("moduleEndDatePicker", { static: true }) moduleEndDatePickerVar: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.endOpen = true;
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  filterQuery: string = "";
  orgId = this.cookie.get('orgId');

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.selectedRoleStatus = this.cookie.get('selectedLoginType');

    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      likeQuery += " EMPLOYEE_NAME like ('%" + this.searchText + "%') OR";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var monthFilterStr = "";
    if (this.startValue1 != undefined) {
      monthFilterStr = " AND MONTH IN (" + this.startValue1 + ")";
    }

    var yearFilterStr = "";
    if (this.endValue != undefined) {
      yearFilterStr = " AND YEAR IN (" + this.datePipe.transform(this.endValue, "yyyy") + ")";
    }

    var branchFilterStr = "";
    if (this.BRANCH_ID.length > 0) {
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

    var departmentFilterStr = "";
    if (this.DEPARTMENT_ID.length > 0) {
      departmentFilterStr = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";
    }

    if (exportInExcel) {
      this.exportLoading = true;

      var branches = ''
      branches = " and BRANCH_ID in (" + this.branchId + ")"
      this.api.getEmployeeAttendanceRegister(0, 0, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS IN(0,2) AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + " AND EMP_ID != 1").subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.excelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }

    else if (this.ROLE_ID == 14) {
      var branches = ''
      branches = " and BRANCH_ID in (" + this.branchId + ")"
      this.loadingRecords = true;
      this.api.getEmployeeAttendanceRegister(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS IN(0,2) AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + " AND EMP_ID != 1" + branches).subscribe((data) => {
        if (data["code"] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data["count"];
          this.dataList = data["data"];

          if (this.initFilter) {
            this.initFilter = false;
          }
        }

      }, (err) => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
    else {
      this.loadingRecords = true;
      this.api.getEmployeeAttendanceRegister(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS IN(0,2) AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + " AND EMP_ID != 1").subscribe((data) => {
        if (data["code"] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data["count"];
          this.dataList = data["data"];

          if (this.initFilter) {
            this.initFilter = false;
          }
        }

      }, (err) => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  clearFilter() {
    this.startValue1 = this.current.getMonth() + 1;
    this.endValue = this.current;
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  applyFilter() {
    if ((this.startValue1 != undefined) || (this.endValue != undefined) || (this.BRANCH_ID.length > 0) || (this.DEPARTMENT_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else this.filterClass = "filter-visible";
  }

  edit(data: UpdateAttendanceRegister, empId) {
    data.EMPLOYEE_ID = empId;
    this.drawerData = Object.assign({}, data);
    this.drawerTitle = "Update Status";
    this.drawerVisible = true;
  }

  BRANCH_ID = [];
  DEPARTMENT_ID = [];
  branch = [];
  department = [];

  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDepartment() {
    this.department = [];

    this.api.getAllDepartments(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.department = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.excelDataList.length; i++) {

      obj1['ID'] = this.excelDataList[i]['EMP_ID'];
      obj1['Reference No'] = this.excelDataList[i]['EMPLOYEE_CODE'];
      obj1['Employee Name'] = this.excelDataList[i]['EMPLOYEE_NAME'];
      obj1['Branch Name'] = this.excelDataList[i]['BRANCH_NAME'];
      obj1['Dept. Name'] = this.excelDataList[i]['DEPARTMENT_NAME'];
      obj1[' 1'] = this.excelDataList[i]['1'];
      obj1[' 2'] = this.excelDataList[i]['2'];
      obj1[' 3'] = this.excelDataList[i]['3'];
      obj1[' 4'] = this.excelDataList[i]['4'];
      obj1[' 5'] = this.excelDataList[i]['5'];
      obj1[' 6'] = this.excelDataList[i]['6'];
      obj1[' 7'] = this.excelDataList[i]['7'];
      obj1[' 8'] = this.excelDataList[i]['8'];
      obj1[' 9'] = this.excelDataList[i]['9'];
      obj1[' 10'] = this.excelDataList[i]['10'];
      obj1[' 11'] = this.excelDataList[i]['11'];
      obj1[' 12'] = this.excelDataList[i]['12'];
      obj1[' 13'] = this.excelDataList[i]['13'];
      obj1[' 14'] = this.excelDataList[i]['14'];
      obj1[' 15'] = this.excelDataList[i]['15'];
      obj1[' 16'] = this.excelDataList[i]['16'];
      obj1[' 17'] = this.excelDataList[i]['17'];
      obj1[' 18'] = this.excelDataList[i]['18'];
      obj1[' 19'] = this.excelDataList[i]['19'];
      obj1[' 20'] = this.excelDataList[i]['20'];
      obj1[' 21'] = this.excelDataList[i]['21'];
      obj1[' 22'] = this.excelDataList[i]['22'];
      obj1[' 23'] = this.excelDataList[i]['23'];
      obj1[' 24'] = this.excelDataList[i]['24'];
      obj1[' 25'] = this.excelDataList[i]['25'];
      obj1[' 26'] = this.excelDataList[i]['26'];
      obj1[' 27'] = this.excelDataList[i]['27'];
      obj1[' 28'] = this.excelDataList[i]['28'];
      obj1[' 29'] = this.excelDataList[i]['29'];
      obj1[' 30'] = this.excelDataList[i]['30'];
      obj1[' 31'] = this.excelDataList[i]['31'];
      obj1['Month'] = this.getMonthInText(this.excelDataList[i]['MONTH']);
      obj1['Year'] = this.excelDataList[i]['YEAR'];
      obj1['Total Days'] = this.excelDataList[i]['WORKING'];
      obj1['Weakly Off'] = this.excelDataList[i]['HOLIDAY'];
      obj1["Paid Leave"] = this.excelDataList[i]['P_LEAVE'];
      obj1["Sick Leave"] = this.excelDataList[i]['M_LEAVE'];
      obj1["Casual Leave"] = this.excelDataList[i]['C_LEAVE'];
      obj1['Comp Off'] = this.excelDataList[i]['COMPOFF'];
      obj1['Comp Off Leave Taken'] = this.excelDataList[i]['CO_LEAVE'];
      // ["CO_LEAVE", "Comp Off Leave Taken"],
      obj1['Halfday'] = this.excelDataList[i]['HALFDAY'];
      obj1['Morning Short Leave '] = this.excelDataList[i]['LATEMARK'];
      obj1['Evening Short Leave '] = this.excelDataList[i]['EARLYMARK'];
      // obj1["Short Leave"] = this.excelDataList[i]['SHORT_LEAVE'];
      obj1['Late Coming'] = this.excelDataList[i]['LATECOMMING'];
      obj1['Latemark Deduction'] = this.excelDataList[i]['D_LATEMARK'];
      obj1['LWP'] = this.excelDataList[i]['ABSENT'];
      obj1['Payable days'] = this.excelDataList[i]['PAYABLE_DAYS'];




      arry1.push(Object.assign({}, obj1));

      if (i == this.excelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Attendance Register ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }

  getMonthInText(monthInNumber: string) {
    if (monthInNumber === "1")
      return "January";

    else if (monthInNumber === "2")
      return "February";

    else if (monthInNumber === "3")
      return "March";

    else if (monthInNumber === "4")
      return "April";

    else if (monthInNumber === "5")
      return "May";

    else if (monthInNumber === "6")
      return "June";

    else if (monthInNumber === "7")
      return "July";

    else if (monthInNumber === "8")
      return "August";

    else if (monthInNumber === "9")
      return "September";

    else if (monthInNumber === "10")
      return "October";

    else if (monthInNumber === "11")
      return "November";

    else if (monthInNumber === "12")
      return "December";
  }
}