import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzNotificationService } from 'ng-zorro-antd';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { AddEmployeeLeaveComponent } from '../add-employee-leave/add-employee-leave.component';

@Component({
  selector: 'app-leave-tiles',
  templateUrl: './leave-tiles.component.html',
  styleUrls: ['./leave-tiles.component.css']
})

export class LeaveTilesComponent implements OnInit {
  formTitle = "Manage Leave";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  // columns: string[][] = [["STATUS", "Status"], ["LEAVE_TYPE_NAME", "Leave Type"], ["FROM_DATE", "From Date"], ["TO_DATE", "To Date"], ["REASON", "Reason"], ["HEAD_APPROVED_DATES", "Head Approved Date"], ["HEAD_REMARK", "Head Remark"], ["HR_APPROVED_DATES", "HR Approved Date"], ["HR_REMARK", "HR Remark"], ["REJECT_REMARK", "Reject Remark"]];
  columns: string[][] = [ ["FROM_DATE", "From Date"], ["TO_DATE", "To Date"], ["REASON", "Reason"],];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Leave = new Leave();
  isSpinning = false;

  constructor( private sanitizer: DomSanitizer, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.date = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var dateFilter = "";
    if (this.date != undefined && this.date.length != 0) {
      dateFilter = " AND (FROM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "')";
    }

    var status = "";
    if (this.STATUS == "P")
      status = " AND STATUS in ('P')";

    else if (this.STATUS == "A")
      status = " AND STATUS in ('A','HA')";

    else if (this.STATUS == "R")
      status = " AND STATUS in ('R','HR')";

    this.api.getAllEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + "AND EMPLOYEE_ID=" + this.api.userId + dateFilter + status).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  
  isVisible = false;

  handleCancel() {
    this.isVisible = false;
 }
 handleOk() {
   this.isVisible = false;
 }
 imgUrl=this.api.retriveimgUrl
 certificate:any
 sanitizedLink:any
 getS(link:string){
  var a= this.imgUrl+'MedicalCertificate/'+link+'#toolbar=0'
  this.sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl(a);

  return  this.sanitizedLink ;

}
isApkVersionModalConfirmLoading:boolean=false

  pdfDisplay(data:Leave){
 
    this.isVisible = true;
    this.isApkVersionModalConfirmLoading = true
  
    this.api .getAllEmployeeLeave(0, 0, '', 'asc', ' AND ID =' + data.ID).subscribe( data => {
        console.log(data['data']);

        if(data['code']==200 && data['count']>0){
          this.isApkVersionModalConfirmLoading = false
          this.certificate=data['data'][0]['MEDICAL_CERTIFICATE']
        }
        
        console.log(this.certificate,'cer')
     
      },
      (err) => {
        console.log(err);
      }
    );
    
   }

  today = new Date()
  // .setDate(new Date().getDate() + 4);
  @ViewChild(AddEmployeeLeaveComponent, { static: false }) AddEmployeeLeaveComponentVar: AddEmployeeLeaveComponent;

  add(): void {
    this.drawerTitle = "Add Leave";
    this.drawerData = new Leave();
    this.drawerVisible = true;
    this.drawerData.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
    this.drawerData.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
    this.AddEmployeeLeaveComponentVar.getLeaveTypeMaster();
  }

  // edit(data: Leave): void {
  //   this.drawerTitle = "Update Leave";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }

  isDisabled2:boolean=false
  FROM_DATE:any
  FROM_DATE1:any
  TO_DATE:any
  TO_DATE1:any
  diff:any
  daydiff:any
  visibility:boolean=false
status:any
length:any
leaveTypes
balance
fileURL

  edit(data:Leave): void {
    // this.visibility=false
    this.isDisabled2=true
    this.drawerTitle = "Update leave";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

   console.log(data,"data");
   
   this.drawerData.MODE =this.drawerData.MODE
   this.drawerData.SESSION = data.SESSION

     
      console.log(this.isDisabled2);
      
      
    this.FROM_DATE = this.datePipe.transform(this.drawerData.FROM_DATE, "yyyy,MM,dd");
    this.TO_DATE = this.datePipe.transform(this.drawerData.TO_DATE, "yyyy,MM,dd");
    this.FROM_DATE1= new Date(this.FROM_DATE)
    this.TO_DATE1= new Date(this.TO_DATE) 
    this.daydiff = Math.floor(this.TO_DATE1.getTime()  - this.FROM_DATE1.getTime())/(1000 * 60 * 60 * 24) ;
    if(Math.floor(this.TO_DATE1.getTime()  - this.FROM_DATE1.getTime())/(1000 * 60 * 60 * 24) < 4){
         this.visibility=true
         console.log(this.visibility);
         
     }

     else{
      this.visibility=false
     }

     this.api.getAllEmployeeLeave12(0, 0, '', 'asc', ' AND PAYROLL_LEAVE_ID='+this.drawerData.TYPE_ID + ' AND EMPLOYEE_ID=' + this.api.userId,).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
        this.balance=data['data'][0]['BALANCE']
      }

    }, err => {
      console.log(err);
    });
     
     this.status=this.drawerData.STATUS
     this.length=this.drawerData.TYPE_ID
     console.log(this.drawerData,"drawerData")
     
  }

  onFileSelected(event:any,data) {
   
    this.fileURL = <File>event.target.files[0];
    if (this.fileURL != null) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
      var url = '';
      url = d == null ? '' : d + number + '.' + fileExt;

    
      // if (this.fileURL != null) {
      //   // this.message.error("Please Select file of type HTML/ PDF1.", "");
      //   var number = Math.floor(100000 + Math.random() * 900000);
      //   var fileExt = this.fileURL.name.split('.').pop();
      //   var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
      //   var url = '';
      //   url = d == null ? '' : d + number + '.' + fileExt;
     
      // if (
      //   data.MEDICAL_CERTIFICATE != undefined &&
      //   data.MEDICAL_CERTIFICATE.trim() != ''
          
      // ) {
      //    var arr = data.MEDICAL_CERTIFICATE.split('/');
      //   if (arr.length > 1) {
      //     url = arr[5];
      //   }
      // }
      this.api.onUpload5('MedicalCertificate', this.fileURL, url)
        .subscribe((successCode) => {
          if (successCode['code']== '200') {
            data.MEDICAL_CERTIFICATE = url;
         
              this.api.updateLeave(data).subscribe((successCode) => {
                console.log("line")
                if (successCode['code'] == '200') {
                  this.message.success('Medical Certificate Uploaded Successfully...', '');
                  this.drawerClose();
                   data.MEDICAL_CERTIFICATE = '';
                  this.fileURL = '';
                  this.isSpinning = false;
              
        }
      });
      }
    });
    
    }
  }

  drawerClose(): void {
    this.STATUS = "P";
    this.search(true);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  date: Date[] = [];
  filterStatusValue: string[] = ['P'];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  employeeFilter
  dateFormat = "dd/MMM/yyyy";

  clearFilter() {
    this.date = [];
    this.filterStatusValue = ['P'];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.employeeFilter = 1;
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.date == undefined || this.date.length == 0) {
      this.message.error("Please Select Date", "");

    } else {
      this.filterQuery = " AND (FROM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "' ) AND ";
      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);

      var status = "";
      if (this.STATUS == "P")
        status = " and STATUS in ('P')";

      else if (this.STATUS == "A")
        status = " and STATUS in ('A','HA')";

      else if (this.STATUS == "R")
        status = " and STATUS in ('R','HR')";

      this.isSpinning = true;
      this.api.getAllEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + " and EMPLOYEE_ID=" + this.api.userId + status).subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isFilterApplied = "primary";
        }

      }, err => {
        console.log(err);
      });
    }

    this.filterClass = "filter-invisible";
  }

  STATUS = "P";

  changeRadioButton(earlymarkStatus) {
    this.STATUS = earlymarkStatus;
    this.search(true);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
}
