import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-salewisedetaileddiscountreport',
  templateUrl: './salewisedetaileddiscountreport.component.html',
  styleUrls: ['./salewisedetaileddiscountreport.component.css']
})
export class SalewisedetaileddiscountreportComponent implements OnInit {

  loadingRecords = false;
  dataList1 = []
  exportLoading = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Sales Head Wise Detailed Discount Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "QUOTATION_NO";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  date1 = new Date();
  date2 = new Date();
  startValue: any = new Date();
  endValue: any = new Date();
  isOk = true
  MONTH: any = ""
  YEAR: any = ""
  MODEL: any;
  BRANCH_ID = [];
  REPORTING_HEAD_ID = [];
  // BRANCH_ID: any;
  model: any = [];
  branch: any = [];
  Saleshead: any = [];
  SELECT_SALES_HEAD: boolean = false;
  CUSTOMER_NAME:any = '';
  customers: any = [];
  // MONTH: any;
  columns: string[][] = [["APPROVER_NAME", "Sales Head Name"], ["BRANCH_ID", "Branch"], ["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"], ["QUOTATION_NO", "Quotation No."], ["SALES_CONSULTANT_DISCOUNT ", "Sales Consultant Discount"],];
  isVisible1: boolean;
  FILTER_QUERY: string;
  drawerTitle1: string;
  members: any;
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }

  ngOnInit() {
    this.startValue =this.startValue.setDate(this.startValue.getDate());
    this.endValue = new Date() 
    this.getbranches();
    this.getsalehead();
    // this.getcustomers();
    setTimeout(()=>{
      this.search(false);
    },1000);

  }

  customername:any
  // getcustomers() {
  //   this.api.getAllCustomer(0, 0, 'ID', 'desc', '').subscribe(data => {
  //   //   if (data['code'] == 200) {
  //   //     this.customers = data['data'];
  //   //     console.log(this.customers);
  //   //     // this.onSelectAllCustomer(true)
  //   //     // this.search(true)
  //   //   }
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  //   this.customers = data['data'];
  //     if (this.customers.length > 0) {
  //       this.CUSTOMER_NAME = [data['data'][0]['NAME']];
  //       console.log('hdg', this.customername);
  //       // this.applyFilter()
  //       console.log(this.branch);
  //       this.customername = data['data'][0]['NAME'];
  //       // this.search(true)
  //     }
  //   });
  // }

  onSelectAllSaleHead(event: any) {
    this.SELECT_SALES_HEAD = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_HEAD == true) {
      for (var i = 0; i < this.Saleshead.length; i++) {
        ids.push(this.Saleshead[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.REPORTING_HEAD_ID = ids
  }

  onSelectOff4(event) {
    var a = this.Saleshead.length;
    var b = this.Saleshead.length - event.length;
    if ((a! = b)) {
      this.SELECT_SALES_HEAD = false;
    } else {
      this.SELECT_SALES_HEAD = true;
    }
    this.REPORTING_HEAD_ID = event;
    if (this.REPORTING_HEAD_ID.length == 0) {
      this.SELECT_SALES_HEAD = false;
    }
  }

  SELECT_SALES_HEAD11: boolean = false;
  
  onSelectAllSaleHead111(event: any) {
    this.SELECT_SALES_HEAD11 = event
    // console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_HEAD11 == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }
  onSelectOff43(event) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_SALES_HEAD11 = false;
    } else {
      this.SELECT_SALES_HEAD11 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_SALES_HEAD11 = false;
    }
  }

  importInExcel() {
    this.search(true, true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  branchname: any

  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      //   if (data['code'] == 200) {
      //     this.branch = data['data'];
      //     console.log(this.branch);
      //   }
      // }, err => {
      //   console.log(err);
      // });
      this.branch = data['data'];
      if (this.branch.length > 0) {
        this.BRANCH_ID = data['data'][0]['ID'];
        // console.log('hdg', this.branchname);
        // this.applyFilter()
        // console.log(this.branch);
        this.branchname = data['data'][0]['NAME'];
        this.onSelectAllSaleHead111(true);
        // this.search(false);
      }
    });

  }
  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(34)').subscribe(data => {
      if (data['code'] == 200) {
        this.Saleshead = data['data'];
        console.log(this.Saleshead);
        this.onSelectAllSaleHead(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }

  endOpen = false;


  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any

  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }



  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (exportInExcel == false) {

      this.loadingRecords = true;
      this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

      this.api.SalesHeadwisedetaileddiscount(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.REPORTING_HEAD_ID, this.BRANCH_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.SalesHeadwisedetaileddiscount(0, 0, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.REPORTING_HEAD_ID, this.BRANCH_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Sales Head Name'] = this.dataList1[i]['REPORTING_HEAD_NAME'];
      obj1['Branch'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Quotation no.'] = this.dataList1[i]['QUOTATION_NO'];
      obj1['Sales Consultant Discount'] = this.dataList1[i]['SALES_CONSULTANT_DISCOUNT'];
      obj1['Approved Discount'] = this.dataList1[i]['APPROVED_DISCOUNT'];
      obj1['Rejected Discount'] = this.dataList1[i]['REJECTED_DISCOUNT'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Head Wise Detailed Discount Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }


  applyFilter() {
    this.isSpinning = false
    this.loadingRecords = false;
    this.isFilterApplied = 'primary';
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.CUSTOMER_NAME != undefined && this.CUSTOMER_NAME.length != 0) {
      this.CUSTOMER_NAME = this.CUSTOMER_NAME.toString();
    }
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (this.START_DATE == undefined && this.YEAR == undefined && this.REPORTING_HEAD_ID == undefined && this.BRANCH_ID == undefined && this.CUSTOMER_NAME== undefined) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {
      this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')



      // this.api.SalesHeadwisedetaileddiscount(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.REPORTING_HEAD_ID, this.BRANCH_ID,).subscribe(data => {
      //   console.log(data)
      //   this.loadingRecords = false;
      //   this.isSpinning = false;
      //   this.totalRecords = data['count'];
      //   this.dataList = data['data'];
      //   this.filterClass = 'filter-invisible';
      // }, err => {
      //   console.log(err);
      // });
      this.search(true)
    }
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    // this.REPORTING_HEAD_ID = []
    // this.BRANCH_ID = [];
    // this.startValue = "";
    // this.endValue = "";
    this.startValue = new Date();
    // this.startValue =this.startValue.setDate(this.startValue.getDate() - 1);
    this.endValue = new Date()
    // this.CUSTOMER_NAME=[]
    this.onSelectAllSaleHead(true);
    this.onSelectAllSaleHead111(true);
    // this.SELECT_SALES_HEAD = false;
    this.searchText = '';
    this.filterQuery = '';
    this.filterClass = 'filter-invisible';
     this.isFilterApplied="default";
    this.search(true)

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

  // AddSchedule() {

  //   this.isVisible1 = true;

  //   var obj = {
  //     START_DATE: this.START_DATE,
  //     END_DATE : this.END_DATE,
  //     REPORTING_HEAD_ID: this.REPORTING_HEAD_ID,
  //     BRANCH_ID: this.BRANCH_ID,
  //   }
  //   this.FILTER_QUERY = JSON.stringify(obj);
  //   this.drawerTitle1 = "Manage Schedule"
  //   console.log("hi")
  //   this.api.getAllMembers(0, 0, '', '', ' AND REPORT_ID = 25').subscribe((data: any) => {
  //     this.members = data['data'];
  //   })
  // }

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

}

