import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
// import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-employee-criteria-report',
  templateUrl: './employee-criteria-report.component.html',
  styleUrls: ['./employee-criteria-report.component.css']
})
export class EmployeeCriteriaReportComponent implements OnInit {

  columns: string[][] = [['EMPLOYEE_NAME', 'EMPLOYEE NAME'], ['DESIGNATION_NAME', 'DESIGNATION_NAME'],
  ['DEPARTMENT_NAME', 'DEPARTMENT_NAME'], ['NAME', 'NAME'], ['WEITAGE', 'WEITAGE'], ['MARKS', 'MARKS']]

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "";
  sortValue: string = "";
  filter = ''
  loadingRecords = false;
  searchText: string = "";
  formTitle = "Employee Criteria Report"
  startValue: any;
  endValue: any;
  isSpinning = false;
  dataList = []
  totalRecords = []
  showfilter: Boolean = false

  filterQuery: string = ""

  orgId = this.cookie.get('orgId');
  employee = []
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH12: any = new Date()
  dateforbooking = new Date(this.MONTH12.getFullYear(), this.MONTH12.getMonth() - 1, 1);
    
  // Set booking1 to the same date as dateforbooking
  MONTH1 = new Date(this.dateforbooking);

  YEAR: any = this.year
  monthFormat = "MMM-yyyy";


  constructor(private api: ApiService, public message: NzNotificationService, public datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService) { }


  ngOnInit() {
    this.getEmployee();
    this.getAllEmployee()
    this.search()
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  emp: any = [];

  getEmployee() {
    this.api.getAllEmployees(0, 0, '', '', "AND ROLE_IDS  like '%" + 29 + "%'")
      .subscribe(data => {
        this.emp = data['data']


      },
        (err) => {
          // console.log(err);
        }
      );
  }

  currentMonth: Date = new Date();

  // disabledStartDate2 = (current: Date): boolean => {
  //   const today = new Date();
  //   const tomorrow = new Date();
  //   tomorrow.setDate(today.getDate() + 1);

  //   return (
  //     differenceInCalendarDays(current, today) !== 1 &&
  //     differenceInCalendarDays(current, tomorrow) !== 1
  //   );
  // };

  disabledStartDate2 = (current: Date): boolean => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const selectedYear = current.getFullYear();
    const selectedMonth = current.getMonth();
  
    // Disable the current month and any previous months
    return selectedYear > currentYear || (selectedYear === currentYear && selectedMonth >= currentMonth);
  };

  EMPLOYEE: any
  employeeList: any = [];
  REPORTING_PERSON_ID: any
  getAllEmployee() {

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.employeeList = data['data'];
        // this.EMPLOYEE = data['data'][0]['ID']
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    })
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    var filter = ''
    if (this.REPORTING_PERSON_ID != undefined) {
      filter = ' AND REPORTING_PERSON_ID = ' + this.REPORTING_PERSON_ID
    }

    else {
      filter = ''
    }
    


    var filter1 = ''
    if (this.EMPLOYEE != undefined) {
      filter1 = ' AND EMPLOYEE_ID = ' + this.EMPLOYEE
    }
    else {
      filter1 = ''
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getEmployeeEvaluation(this.pageIndex, this.pageSize, this.sortKey, sort, ' AND MONTH = ' + this.MONTH + ' AND YEAR = ' + this.YEAR
        + filter + filter1).subscribe(data => {
          this.loadingRecords = false;
          this.dataList = data['data']
          this.totalRecords = data['count']
          console.log(this.dataList);
        });
    }
    // else {

    //   this.api.getCancelationDetailedReport(0, 0, this.sortKey, sort, likeQuery, this.MONTH, this.YEAR, this.SALES_EXECUTIVE_ID, this.ROLESS).subscribe(data => {
    //     if (data['code'] == 200) {
    //       this.exportLoading = false;
    //       this.datas1 = data['data'];
    //       this.convertInExcel();
    //     }
    //   },
    //     err => {
    //       if (err['ok'] == false)
    //         this.message.error("Server Not Found", "");
    //     });
    // }

  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  filterClass: string = "filter-invisible";
  isFilterApplied: string = "default";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    this.search();

  }


  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.MONTH = this.month;
    this.MONTH1 = new Date()
    this.YEAR = this.year
    this.search()
  }

}
