import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service/cookie-service/cookie.service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-ashiftmaping',
  templateUrl: './ashiftmaping.component.html',
  styleUrls: ['./ashiftmaping.component.css']
})
export class AshiftmapingComponent implements OnInit {

  @Input() shiftdrawerClose: Function;
  @Input() data: EmployeeMaster = new EmployeeMaster();
  @Input() listdata1: any = []
  @Input() array
  @Input() inOut
  @Input() CURRENT_SHIFT_ID

  
  // @ViewChild('accountMasterPage', { static: false }) public userFrm: NgForm;

  SHIFT_ID:any
  START_DATE:any
  END_DATE:any
  Time1
  Time2
  SendData11
  // array=[]

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe,) { }

  ngOnInit() {
    // console.log(this.CURRENT_SHIFT_ID,"");
    
  }

  save(){
    var isOk = true;
    this.Time1 = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd")
    this.Time2 = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd")

    if (this.SHIFT_ID == null) {
      isOk = false;
      this.message.error("Please Select Shift", "");
    }

    if ((this.START_DATE == undefined) || (this.START_DATE == null)) {
      isOk = false;
      this.message.error("Please Enter Start Date", "");
    }

    if ((this.END_DATE == undefined) || (this.END_DATE == null)) {
      isOk = false;
      this.message.error("Please Enter End Date", "");
    }


    if (isOk) {

      this.SendData11 = ({
        SHIFT_ID: this.SHIFT_ID,
        START_DATE: this.Time1,
        END_DATE: this.Time2,
        data:this.array,
        
      });
      
     
      console.log(this.array);
      this.api.addbulkShiftMap(this.SendData11)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Shift Mapped Successfully ...", "");
            this.shiftdrawerClose();
            this.SHIFT_ID=[]
            // this.array = []
            this.START_DATE = null;
            this.END_DATE = null;
            // this.indeterminate = true
            // this.filterClass = 'filter-visible'
            // this.loadingRecords = true;
          }
          else {
            this.message.error("Shift Mapping Failed...", "");

            // this.array = []
            // this.indeterminate = true
          }
        });
    }
  }

  close(){
    this.shiftdrawerClose()
  }
  today = new Date().setDate(new Date().getDate() + 0);

  
  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;
    
  onLeaveTypeSelected(leaveType) {

    // if (this.leaveTypeDetails.length > 0) {
    //   var leaveData = this.leaveTypeDetails.filter(obj => {
    //     return (obj.PAYROLL_LEAVE_ID == leaveType);


    //   });

    //   console.log(leaveData, leaveType, "leaveType");

      if (this.inOut==1) {
        this.today = new Date().setDate(new Date().getDate() + 1);
        // this.balance = leaveData[0]["BALANCE"]
        // this.data.MODE = leaveData[0]['APPLICABLE_TYPES']
        // this.leavetype = leaveData[0]["PAYROLL_LEAVE_NAME"]

        // console.log(this.data.MODE);
        // if(this.balance==0 &&this.leavetype!="LWP" ){
        //   this.disabledmode=true
        // }

      }
      else
        this.today = new Date().setDate(new Date().getDate() + 0);

        this.START_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
      this.END_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
      console.log(this.START_DATE, "E");

        this.disabledStartDate = (current: Date): boolean =>
          differenceInCalendarDays(current, this.today) < 0;
      
      // this.data.MODE=null
      
    }
  

  disabledEndDate = (endValue: Date): boolean => {
    if (this.START_DATE != null) {
      if (!endValue) {
        return false;
      }

      // var modulePreviousDate = new Date(this.data.FROM_DATE);
      // modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      // var modulePreviousDate = new Date(this.START_DATE)

      // return endValue.getTime()  this.START_DATE.getTime();
      var modulePreviousDate = new Date(this.START_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }

}
