import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { tourModeMaster } from 'src/app/Models/tourMode';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-tour-mode-form',
  templateUrl: './tour-mode-form.component.html',
  styleUrls: ['./tour-mode-form.component.css']
})
export class TourModeFormComponent implements OnInit {

  @Input() drawerClose!: Function;
  @Input() data: tourModeMaster = new tourModeMaster();
  @Input() drawerVisible: boolean = false;
  isSpinning = false;

  @Input() listOfData: any
  isOk = true;
  
  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getData()
  }
  dataList: any = []

  getData() {
    this.api.getTourMode(0, 0, '', '', '').subscribe((data) => {
      this.dataList = data['data'];
      console.log('this.dataList', this.dataList);
    })
  }

 

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {

    this.isOk = true;

    if (this.data.MODE_NAME == null || this.data.MODE_NAME == "") {
      this.isOk = false
      this.message.error("Please Enter Tour Mode", "");
    }

    if (this.isOk) {
      if (this.data.ID) {

        this.isSpinning = false;
        this.api.updateTourMode(this.data).subscribe((successCode: any) => {
          if (successCode['code'] == 200) {
            this.getData();
            // this.isSpinning = true;
            this.message.success("Tour Mode Updated successfully...", "");
            console.log(this.data, 'Update Success')

            if (!addNew) {
              this.drawerClose();
              this.reset(myForm);
              this.isSpinning = false;
            }
            else {
              this.data = new tourModeMaster();
              this.message.error("Failed To Update Tour Mode", "");
              console.log(this.data, "fail Update")

              this.isSpinning = false;
            }
            // console.log(this.data.APPLICABLE_TYPES);
          }

        });

      }
      else {
        this.isSpinning = true;

        console.log(this.dataList, 'Code')

        this.api.createTourMode(this.data)
          .subscribe(successCode => {

            if (successCode['code'] == 200) {
              this.message.success("Tour Mode added successfully...", "");
              console.log(this.data, "create Success");
              this.getData();
              if (!addNew) {
                // this.isSpinning = true;
                this.drawerClose();

              }
              else {
                this.data = new tourModeMaster();
                myForm.resetForm();

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add Tour Mode...", "");
              console.log(this.data, "Fail data")

              this.isSpinning = false;
            }
          });


      }


    }
  }
}
