import { Component, OnInit } from '@angular/core';
// import { feedbackforms } from 'src/app/Models/feedbackforms';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { feedbackforms } from 'src/app/Models/feedbackforms';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
// import { feedbackformsaccessmapping } from 'src/app/Models/feedbackformsaccessmapping';

@Component({
  selector: 'app-feedbackformapproves',
  templateUrl: './feedbackformapproves.component.html',
  styleUrls: ['./feedbackformapproves.component.css']
})
export class FeedbackformapprovesComponent implements OnInit {

  formTitle = "Feedback Approve";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  STATUS = "P";
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["AREA_NAME", "Area Name"], ["FEEDBACK_TYPE", "Area Type"], ["EMPLOYEE_NAME", "Employee Name"], ["FEEDBACK", "Feedback"], ["REMARK", "Remark"],
  ["GM_REMARK", "GM Remark"], ["INPROCESS_REMARK", "In-Process Remark"], ["CLOSED_REMARK", "Closure Remark"]]

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: feedbackforms = new feedbackforms();
  imgurl = this.api.retriveimgUrl;
  drawerData2: string[];
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  userId = this.cookie.get('userId')
  drawerData: feedbackforms = new feedbackforms();
  constructor(private api: ApiService, private message: NzNotificationService, private cookie: CookieService, private datePipe: DatePipe) { }

  ngOnInit() {

    this.openmodal()
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  BRANCH_ID
  FEED_AREA_ID
  openmodal() {

    this.api.branchareamappaget(0, 0, '', '', ' AND EMP_ID =' + this.userId).subscribe((data) => {
      if (data['code'] == 200) {
        // this.FEED_AREA_ID = Number(data['data'][0]['AREAS'])

        const stringArray = data['data'][0]['BRANCHES'].split(",")
        this.BRANCH_ID = stringArray.map(Number);

        console.log(this.BRANCH_ID, "this.BRANCH_ID")
        const stringArray1 = data['data'][0]['AREAS'].split(",")
        this.FEED_AREA_ID = stringArray1.map(Number);
        console.log(this.FEED_AREA_ID, "this.FEED_AREA_ID")

        this.search();
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }
  onEnterKeyPress() {
    document.getElementById("searchbtn").focus();
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    var likeQuery = ''
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    
    // var filterQuery = ''
    // if (this.STATUS == '') {
    //   filterQuery += " AND STATUS='P'";
    // }
    // else {
    //   filterQuery += " AND STATUS='" + this.STATUS + "'";
    // }

    var status = "";
    if (this.STATUS == "P")
      status = " AND STATUS = 'P'";

    else if (this.STATUS == "A")
      status = " AND STATUS = 'A'";

    else if (this.STATUS == "I")
      status = " AND STATUS ='I'";

    else if (this.STATUS == "C")
      status = " AND STATUS = 'C' ";

    else if (this.STATUS == "R")
      status = " AND STATUS = 'R' ";

    else {
      return 'None'

    }
    var branch = ''
    branch = ` AND BRANCH_ID IN (${this.BRANCH_ID})`

    var area = ''
    area = ` AND AREA_ID IN (${this.FEED_AREA_ID})`
    var datefilter = ''
    if (this.FROM_DATE != undefined && this.TO_DATE != undefined) {
      datefilter = " AND DATE(APPLIED_DATETIME) BETWEEN '" + this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd') + "'";
    }

    console.log(status, "filterQuery")
    console.log("likeQuery" + likeQuery);

    this.api.getfeedform(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + status + datefilter + branch + area, this.userId).subscribe(data => {
      console.log(data);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  changeRadioButton(event) {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.search(true);
  }

  add(): void {
    this.drawerTitle = "Create New feedback ";
    this.drawerData = new feedbackforms();
    // this.drawerData.IS_ACTIVE = true
    this.drawerVisible = true;
  }

  // MapApplicationModules(data:feedbackforms)
  // {
  //   this.api.getApplicationModuleMapping(data.ID).subscribe(data => {
  //     console.log(data);
  //    this.drawerData2 =data['data'];
  //     }, err => {
  //     console.log(err);
  //   });
  //   this.drawerTitle1 = "Map Application Modules for " + data.NAME + "";
  //   this.drawerData1 = Object.assign({}, data);
  //   this.drawerVisible1 = true;
  // }

  edit(data: feedbackforms): void {
    this.drawerTitle = "Approve Feedback";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

  }

  feedbackimg: any

  print(data: feedbackforms) {
    this.imageModalVisible = true
    this.feedbackimg = data.ATTACHMENT_URL

  }
  imageModalVisible: boolean = false

  filterClass: string = "filter-invisible";

  handleimgCancel() {
    this.imageModalVisible = false
  }

  FROM_DATE: any = new Date()
  TO_DATE: any = new Date()
  startValue = new Date()
  endValue = new Date()
  datepicker = new Date()

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    console.log((this.STATUS.length > 0) || (this.FROM_DATE != null) || (this.TO_DATE != null));

    // if ((this.STATUS.length > 0) || (this.FROM_DATE != null) || (this.TO_DATE != null))
    //   this.isFilterApplied = "primary";

    // else
    //   this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }
  clearFilter() {
    // this.STATUS = [];
    // this.date = [];
    this.FROM_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}
