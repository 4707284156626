import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-customerwise-rsadetailedreport',
  templateUrl: './customerwise-rsadetailedreport.component.html',
  styleUrls: ['./customerwise-rsadetailedreport.component.css']
})
export class CustomerwiseRSAdetailedreportComponent implements OnInit {

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Customer Wise RSA Detailed Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  DATE: any;
  BRANCH: any = [];
  branch: any = [];
  // BRANCH:any=[];
  SALESEXECUTIVE: any = [];
  salesexecutive: any = [];
  MODEL: any = [];
  VARIENT: any = [];
  CUSTOMERNAME: any = [];
  models: any = [];
  varient: any = [];
  salesmanager: any = [];
  customername: any = [];
  FROMDATE: any;
  TODATE: any;
  // isFilterApplied: any = "default";
  isOk = true;
  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model"], ["VARIENT_NAME", "Variant"], ["SALES_EXECUTIVE_NAME", "Name Of Sales Executive"], ["BRANCH_NAME", "Branch"]];
  exportLoading: boolean;
  dataList1: any;
  current=new Date();

  constructor(private api: ApiService, private _exportService: ExportService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.FROMDATE = new Date(new Date().setDate(new Date().getDate() - 7));
    this.TODATE = new Date();
    this.getsalehead();
    // this.getAllcustomers();
    // this.getAllbranches();
    // this.getAllModels();
    // this.getAllVarients();
    
    // var fromdate = new Date();
    // var todate = new Date();
    // this.FROMDATE = new Date(fromdate.getFullYear(), fromdate.getMonth(), fromdate.getDate() - 7);
    // this.TODATE = new Date(todate.getFullYear(), todate.getMonth(), todate.getDate());
    // this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
    // this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd");
    // this.applyFilter();
    // this.search(false);

  }

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      BRANCH:this.BRANCH, 
      SALESEXECUTIVE:this.SALESEXECUTIVE, 
      VARIENT:this.VARIENT, 
      MODEL:this.MODEL, 
      FROMDATE:this.FROMDATE, 
      TODATE:this.TODATE, 
      CUSTOMERNAME:this.CUSTOMERNAME,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 62').subscribe((data: any) => {
      this.members = data['data'];
    })
  }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false);
  }

  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(
      data => {
        this.salesexecutive = data['data']
        let ids = [];
        if (this.SELECT_SALES == true) {
          for (var i = 0; i < this.salesexecutive.length; i++) {
            ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
          }
        }
        else {
          ids = [];
        }
        this.SALESEXECUTIVE = ids;
        this.getAllcustomers();
      },
        (err) => {
          console.log(err);
        }
      );
    }

  SELECT_SALES: boolean = true;
  onSelectAllSales(event: any) {
    this.SELECT_SALES = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES == true) {
      for (var i = 0; i < this.salesexecutive.length; i++) {
        ids.push(this.salesexecutive[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALESEXECUTIVE = ids
  }

  onSelectOff4(event) {
    var a = this.salesexecutive.length;
    var b = this.salesexecutive.length - event.length;
    if ((a! = b)) {
      this.SELECT_SALES = false;
    } else {
      this.SELECT_SALES = true;
    }
    this.SALESEXECUTIVE = event;
    if (this.SALESEXECUTIVE.length == 0) {
      this.SELECT_SALES = false;
    }
  }

  getAllcustomers() {
    this.api.getAllCustomer(0, 0, 'ID', 'desc', '').subscribe(
      data => {
        this.customername = data['data']
        let ids = [];
        if (this.SELECT_CUSTOMER == true) {
          for (var i = 0; i < this.customername.length; i++) {
            ids.push(this.customername[i]["ID"]);
          }
        }
        else {
          ids = [];
        }
        this.CUSTOMERNAME = ids;
        this.getAllModels();
      },
        (err) => {
          console.log(err);
        }
      );
    }

  SELECT_CUSTOMER: boolean = true;
  onSelectAllCustomer(event: any) {
    this.SELECT_CUSTOMER = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_CUSTOMER == true) {
      for (var i = 0; i < this.customername.length; i++) {
        ids.push(this.customername[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.CUSTOMERNAME = ids
  }

  onSelectOff3(event) {
    var a = this.customername.length;
    var b = this.customername.length - event.length;
    if ((a! = b)) {
      this.SELECT_CUSTOMER = false;
    } else {
      this.SELECT_CUSTOMER = true;
    }
    this.CUSTOMERNAME = event;
    if (this.CUSTOMERNAME.length == 0) {
      this.SELECT_CUSTOMER = false;
    }
  }


  getAllModels() {
    this.api.getAllModels(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.models = data['data']
      if (this.models.length > 0) {
        this.MODEL = [data['data'][0]['ID']];
        console.log(this.models);
      }
      else {
        console.log('Error');
      }
      this.getAllVarients();
    }, (err) => {
      console.log(err);
    });
  }
  SELECT_MODEL: boolean = false;
  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.models.length; i++) {
        ids.push(this.models[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODEL = ids
  }


  onSelectOff(event) {
    var a = this.models.length;
    var b = this.models.length - event.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODEL = event;
    if (this.MODEL.length == 0) {
      this.SELECT_MODEL = false;
    }
  }
  getAllVarients() {
    this.api.getAllVarients(0, 0, "", "", "").subscribe(data => {
      this.varient = data['data']
      let ids = [];
      if (this.SELECT_VARIENT == true) {
        for (var i = 0; i < this.varient.length; i++) {
          ids.push(this.varient[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.VARIENT = ids;
      this.getAllbranches();
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_VARIENT: boolean = true;
  onSelectAllVarient(event: any) {
    this.SELECT_VARIENT = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_VARIENT == true) {
      for (var i = 0; i < this.varient.length; i++) {
        ids.push(this.varient[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.VARIENT = ids
  }
  
  onSelectOff1(event) {
    var a = this.varient.length;
    var b = this.varient.length - event.length;
    if ((a! = b)) {
      this.SELECT_VARIENT = false;
    } else {
      this.SELECT_VARIENT = true;
    }
    this.VARIENT = event;
    if (this.VARIENT.length == 0) {
      this.SELECT_VARIENT = false;
    }
  }


  getAllbranches() {
    this.api.getAllBranch(0, 0, "", "asc", "AND STATUS=1").subscribe(data => {
      this.branch = data['data']
      let ids = [];
      if (this.SELECT_BRANCH == true) {
        for (var i = 0; i < this.branch.length; i++) {
          ids.push(this.branch[i]["ID"]);
        }
      }
      else {
        ids = [];
      }
      this.BRANCH = ids;
      this.search(true);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_BRANCH: boolean = true;
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }

  onSelectOff5(event) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }
  // datechange(event: any) {
  //   this.FROMDATE = this.datePipe.transform(this.FROMDATE, "yyyy-MM-dd");
  //   this.TODATE = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 7);
  //   this.TODATE = this.datePipe.transform(this.TODATE, "yyyy-MM-dd")
  // }
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    var filter = "";
    
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.FROMDATE = this.datePipe.transform(this.FROMDATE, 'yyyy-MM-dd')
    this.TODATE = this.datePipe.transform(this.TODATE, 'yyyy-MM-dd')


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getCustomerWiseRSAdetailedreport(this.pageIndex, this.pageSize, this.sortKey, sort,likeQuery, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.MODEL, this.FROMDATE, this.TODATE, this.CUSTOMERNAME).subscribe(data => {
        console.log(data)
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = false;
      this.api.getCustomerWiseRSAdetailedreport(0, 0, this.sortKey, sort, likeQuery, this.BRANCH, this.SALESEXECUTIVE, this.VARIENT, this.MODEL, this.FROMDATE, this.TODATE, this.CUSTOMERNAME).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataList1 = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.exportLoading = false;
          this.message.error("Server Not Found", "");
        });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  applyFilter() {

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.FROMDATE = this.datePipe.transform(this.FROMDATE, 'yyyy-MM-dd')
    this.TODATE = this.datePipe.transform(this.TODATE, 'yyyy-MM-dd')

    if (this.FROMDATE != null) {
      this.FROMDATE = this.FROMDATE;
    }

    if (this.TODATE != null) {
      this.TODATE = this.TODATE;
    }

    if (this.MODEL != undefined) {
      this.MODEL = this.MODEL;
    }

    if (this.BRANCH != undefined) {
      this.BRANCH = this.BRANCH;
    }
    if (this.VARIENT != undefined) {
      this.VARIENT = this.VARIENT;
    }

    if (this.SALESEXECUTIVE != undefined) {
      this.SALESEXECUTIVE = this.SALESEXECUTIVE;
    }
    if (this.CUSTOMERNAME != undefined) {
      this.CUSTOMERNAME = this.CUSTOMERNAME;
    }
  

    this.search(true);
    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

  }
  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";

    this.FROMDATE = new Date(new Date().setDate(new Date().getDate() - 7));
    this.TODATE = new Date();
    this.SELECT_SALES = true;
    this.SELECT_MODEL=false;
    this.SELECT_VARIENT= true;
    this.SELECT_SALES= true;
    this.SELECT_CUSTOMER= true;
    this.SELECT_BRANCH= true;
    // this.getAllModels();
    // this.getAllVarients();
    // this.getAllcustomers();

    this.getsalehead();
    // this.SELECT_BRANCH = true
    // let ids = [];
    // for (var i = 0; i < this.branch.length; i++) {
    //   ids.push(this.branch[i]["ID"]);
    // }
    // this.BRANCH = ids;
  }


  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
  
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Variant'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Name Of Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Is RSA Purchased.'] = this.dataList1[i]['IS_RSA_PURCHASED'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer Wise Rsa Detailed Report"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
