import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-as-on-date-balance-report',
  templateUrl: './as-on-date-balance-report.component.html',
  styleUrls: ['./as-on-date-balance-report.component.css']
})
export class AsOnDateBalanceReportComponent implements OnInit {

  formTitle = "As On Date Balance Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Quotationdetailed.xlsx';
  dataList = [];
  exportToExcelLoading = false
  index = 0;
  exportToExcelDataList: any = []

  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  dateQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["EMPLOYEE_NAME", "Employee name"],
  ["E_LEVAE", "Earn Leave"],
  ["C_LEVAE", "Casual Leave"],
  ["CO_LEAVE", "CompOff Leave"],
  ["M_LEVAE", "Medical Leave"],
  ["LWP", "LWP"],
  ["TOTAL", "Total"],]

  isSpinning = false
  filterClass: string = "filter-invisible";
  SALES_EXECUTIVE_ID: any = []
  Value1: any
  Value2: any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  MEMO_DATE: any
  data1 = [];
  INVOICE_DATE: any
  MODEL_ID: any = []
  FROM_DATE: any;
  TO_DATE: any;
  DATE :any=new Date();

  balance: any = []

  MEMO_STATUS: any[];
  constructor(private api: ApiService, private datePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  search(reset: boolean = false, exportToExcel: boolean = false) {

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.DATE != undefined && this.DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.DATE, "yyyy-MM-dd");
      // this.TO_DATE = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    if (exportToExcel == false) {
      this.loadingRecords = true

      this.api.asOnDateBalance(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.FROM_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.balance = data['data'];
          this.filterClass = "filter-invisible";
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.exportToExcelLoading = true;
      this.api.asOnDateBalance(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.FROM_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "primary";
    this.DATE = new Date();
    // this.DATE[1] = new Date();
    this.search()
  }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  Value: any

  applyFilter(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied = 'primary'
    this.filterClass = "filter-invisible";

    this.loadingRecords = false;
    this.DATE[0] = this.datePipe.transform(this.DATE[0], 'yyyy-MM-dd')
    // this.DATE[1] = this.datePipe.transform(this.DATE[1], 'yyyy-MM-dd')

    if (this.DATE[0] != null) {
      this.FROM_DATE = this.DATE[0]
    }

    // if (this.DATE[1] != null) {
    //   this.TO_DATE = this.DATE[1]
    // }
  
    this.search(true)
  }
  // [["EMPLOYEE_NAME", "Employee name"],
  // ["E_LEVAE", "Earn Leave"],
  // ["C_LEVAE", "Casual Leave"],
  // ["CO_LEAVE", "CompOff Leave"],
  // ["M_LEVAE", "Medical Leave"],
  // ["TOTAL", "Total"],]
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Employee name '] = this.exportToExcelDataList[i]['EMPLOYEE_NAME'];
      // obj1['Quotation Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd MMM yyyy');

      obj1['Earn Leave'] = this.exportToExcelDataList[i]['E_LEVAE'];
      obj1['Casual Leave'] = this.exportToExcelDataList[i]['C_LEVAE'];
      obj1['CompOff Leave'] = this.exportToExcelDataList[i]['CO_LEAVE'];
      obj1['Medical Leave'] = this.exportToExcelDataList[i]['M_LEVAE'];
      obj1['LWP'] = this.exportToExcelDataList[i]['LWP'];
      // obj1['Memo Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['MEMO_DATE'], 'dd MMM yyyy');
      obj1['Total'] = this.exportToExcelDataList[i]['TOTAL'];
      // obj1['Invoice Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['INVOICE_DATE'], 'dd MMM yyyy');



      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'As On Date Summary Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }
}
