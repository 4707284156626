import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-rolesmap',
  templateUrl: './rolesmap.component.html',
  styleUrls: ['./rolesmap.component.css']
})
export class RolesmapComponent implements OnInit {
  @Input()Roletype:any
  @Input()MappingData:any
  leaveTypeDetails1=[]

  constructor(private api:ApiService,private message: NzNotificationService) { }

  ngOnInit() {
    // this.mapBranch() 
  }

  changeInfo(value, data) {
    if (value) {
     
      data.STATUS = 1;
    }
    else {
     
      data.STATUS = 0;
    }

  }

  // mapBranch() {
   


  //   this.leaveTypeDetails1=this.Roletype

  //   console.log(this.leaveTypeDetails1);
    
   
 
   
    
  // }

  // applyFilter() {
  //   this.dataList = [];
  //   this.finalData = []
  //   var isOk = true;

  //   if (this.ROLES2.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Roles", "");

  //   } else if (this.MODELS2.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Models", "");
  //   }

  //   if (isOk) {
  //     this.ROLES = this.ROLES2
  //     this.MODELS = this.MODELS2
  //     this.ROLES22 = []
  //     for (let i = 0; i < this.ROLES.length; i++) {
  //       var kk = this.roles.findIndex(x => x.ID == this.ROLES[i]);
  //       this.ROLES22.push(this.roles[kk]['NAME']);
  //       this.MODELS22 = []
  //       for (let j = 0; j < this.MODELS.length; j++) {
  //         this.finalData.push({ "ROLE_ID": this.ROLES[i], "MODEL_ID": this.MODELS[j], "AMOUNT": 0 })
  //         // console.log(this.finalData);
  //         var kkk = this.model.findIndex(x => x.ID == this.MODELS[j]);
  //         this.MODELS22.push(this.model[kkk]['NAME'])
  //       }
  //       if (i + 1 == this.ROLES.length) {
  //         this.dataList = [...[], ...this.finalData]
  //       }



  //     }
  //     // for (let m = 0; m < this.dataList.length; m++) {
  //     //   this.dataList.push({'ID': m + 1})
  //     //   // console.log(this.dataList);
  //     // }
  //   }
  //   this.MODELS2=[]
  //   this.ROLES2=[]
  // }

//   if(this.MappingData.length>0){

//     this.api.addBranch(this.data.ID,this.MappingData).subscribe(successCode => {
//       if (successCode['code'] == 200) {
//         this.message.success("Branch Mapping Updated Successfully...", "");
//        this.drawerClose();

//       } 
//       else{
//         this.message.error("Branch Mapping update failed",'')
//       }
//     });
//   }
//   else{
//     this.message.error("no data",'')
//   }
// }

save(){
  if(this.Roletype.length>0){
    
    this.leaveTypeDetails1=this.Roletype

    console.log(this.leaveTypeDetails1,"");
    
  }
}

}
