import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { PSFCOLLAPSEMASTER } from 'src/app/Models/psfmaster';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRMaster } from 'src/app/Models/SMRmaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-psfmaster',
  templateUrl: './psfmaster.component.html',
  styleUrls: ['./psfmaster.component.css']
})
export class PsfmasterComponent implements OnInit {
  formTitle = "PSF Leads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NUMBER", "Sequence No."], ["WORKSHOP_CODE", "WorkShop Code"],
  ["RO_NUMBER", "RO Number"], ["BILL_DATE", "Bill Date"], ["RO_DATE", "Ro Date"], 
  ["CUSTOMER_NAME", "Customer Name"],["MOBILE_NUMBER", "Mobile Number"], ["VIN", "VIN"],
  ["REGISTRATION_NO", "Reg Number"],["MODEL", "Model Name"], ["SERVICE_ADVISER_NAME", "Service Advisor Name"], 
  ["WORK_TYPE", "Work Type"],["SALE_DATE", "Sale Date"], ["SPECIAL_MESSAGE", "Special Massage"], 
  ["FOLLOWUP_DATE", "FollowUp Date"],  ["PSF_CALLING_DATE", "PSF Date"],  ["PSF_CALLING_TIME", "PSF_CALLING_TIME"],
  ["PSF_CALLING_STATUS", "PSF Status"]];
  isPSFLead:boolean=true;
  PSFdrawerVisible!: boolean;
  drawerTitle!: string;
  SHEET_ID:any
    index:number=0;
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  // PSFdrawerVisible!: boolean; 
  // dataList:any[]=[]
  exportLoading: boolean = false;


  
  collapseDrawerVisible: boolean = false;
  drawerData1: PSFCOLLAPSEMASTER = new PSFCOLLAPSEMASTER();
  isClosed=false;  
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData2: ActivityLeadMaster=new ActivityLeadMaster();
  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false

  constructor( public datePipe: DatePipe,private _exportService: ExportService,private api:ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.search();
    this.getEmployee();
  }
  employee = []
  getEmployee() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID=50').subscribe(data => {
      if(data['code']==200){
        this.employee = data['data']
      }
     
    })
  }


  Counts1:any
  CCEEE(event){
    // 
    this.api.getAllSubcountforpsf(0,0,'','','',event).subscribe(data => {
      this.Counts1 = data['TOTAL_LEADS']
      // console.log(this.Counts,"counts");
      this.search(false);
    })
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.PSFdrawerVisible = false;
  }

  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }

  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  drawerClose1(): void {
    this.search();
    this.collapseDrawerVisible = false;
    this.isClosed=true
    // this.index=0;
  }
  drawerClose2(): void {
    this.drawerVisible1 = false;
  }
  datalist=[]
  Activity(dataa){
  this.drawerTitle1 = "Create New PSF Lead Activity";
  this.drawerVisible1 = true;
  this.drawerData2= Object.assign({}, dataa);
  this.api.getAllActivityleadMaster(0, 0, '', 'desc', ' AND CUSTOMER_ID = ' + dataa.ID).subscribe(
  data => {
    if (data['code'] == 200) 
  {
  this.datalist = data['data']
  console.log(this.datalist);}
  }, err => {
  console.log(err);
  });
  }
  edit(data: PSFCOLLAPSEMASTER): void {
    this.drawerTitle = " Forms ";
    this.drawerData1= Object.assign({}, data);
    // this.drawerData1=Object.assign({},data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    // this.SMRdrawerVisible = true;
    this.collapseDrawerVisible=true;
    this.active1 == false
    this.active2 == false
    this.active3 == false
    this.active4 == false
    this.active5 == false
    this.active6 == false
  }

  MASTER:any

  add(): void {
    this.MASTER = 'PSF_MASTER'
    console.log(this.MASTER);
    
    this.drawerTitle = " PSF Leads "; 
    this.drawerData = new ServiceModuleExcel();
    if(this.SHEET_ID==null || this.SHEET_ID==undefined){
      this.message.error("Please Select CCE ...", "");
    }else{
      this.PSFdrawerVisible = true;
    }
   
    
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  keyup(event: any) {
    this.search();
  
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(false, false);
  }

  search(reset: boolean = false,exportInExcel: boolean = false) {
    // this.dataList=[]
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    if( this.searchText != ''){
      this.checked1 = false
      this.array = []
      console.log(this.checked1,"this.checked1")
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+ ")"
    }

    this.filterQuery = " AND CCE_ID ="+this.SHEET_ID
    console.log(exportInExcel,"exportInExcel")
    if (exportInExcel == false) {
    this.api.getPSFMastertabledata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+this.filterQuery).subscribe(data => {
      if(data['code']==200 && data['count']>0){ 
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }
      else{
        this.loadingRecords = false; 
      }
  
      
    }, err => {
      console.log(err);
    });
  } else{
    this.exportLoading = true
    this.api.getPSFMastertabledata(0, 0, this.sortKey, sort, likeQuery+this.filterQuery).subscribe(data => {
      if (data['code'] == 200 ) {
        this.exportLoading = false;
        // this.SalesExecutiveWiseModelWiseSummary = data['data'];
       
       
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList12 = data['data'];
        console.log(this.dataList12,"this.dataList2343");
        this.convertInExcel();
      }
      else{
        this.exportLoading = false; 
      }
   


    }, err => {
      // console.log(err);
    });
  }
  
  }

  dataList12=[]
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList12.length; i++) {
      obj1['Workshop Code'] = this.dataList12[i]['WORKSHOP_CODE'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseModelWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Model'] = this.dataList12[i]['MODEL_NAME'];
      obj1['RO Number'] = this.dataList12[i]['RO_NUMBER'];
      obj1['Bill Date'] = this.dataList12[i]['BILL_DATE'];
      obj1['RO Date'] = this.dataList12[i]['RO_DATE'];
      obj1['Customer Name'] = this.dataList12[i]['CUSTOMER_NAME'];
      obj1['Mobile Number'] = this.dataList12[i]['MOBILE_NUMBER'];
      obj1['VIN'] = this.dataList12[i]['VIN'];
      obj1['Registration No'] = this.dataList12[i]['REGISTRATION_NO'];
      obj1['Model'] = this.dataList12[i]['MODEL'];
      obj1['Service Advisor Name'] = this.dataList12[i]['SERVICE_ADVISER_NAME'];
      obj1['Work Type'] = this.dataList12[i]['WORK_TYPE'];
      obj1['Sale Date'] = this.dataList12[i]['SALE_DATE'];
      obj1['Special Message'] = this.dataList12[i]['SPECIAL_MESSAGE'];
      obj1['Follow-up-Date'] = this.dataList12[i]['FOLLOWUP_DATE'];
      // obj1['Bill Date'] = this.dataList[i]['BILL_DATE'];
      // obj1['Model'] = this.dataList[i]['MODEL'];
      // obj1['Remark'] = this.dataList[i]['REMARK'];
      // obj1['Reminder Date'] = this.dataList[i]['REMINDER_DATE'];
      // obj1['Followup No.'] = this.dataList[i]['FOLLOWUP_NO'];
      // obj1['Call Date'] = this.dataList[i]['CALL_DATE'];
      // obj1['Service Count'] = this.dataList[i]['SERVICE_COUNT'];
      // obj1['Customer Number'] = this.dataList[i]['CUST_NO'];
      // obj1['Special Message'] = this.dataList[i]['SPECIAL_MESSAGE'];
      // obj1['Ro Date'] = this.dataList[i]['RO_DATE'];
      // obj1['Ro Number'] = this.dataList[i]['RO_NUMBER'];
      // obj1['Number Of Calls'] = this.dataList[i]['NUMBER_OF_CALLS'];
      // obj1['Live Streaming'] = this.dataList[i]['LIVE_STREAMING'];
      // obj1['Status'] = this.dataList[i]['STATUS'];
      // obj1['Delivery Date'] = this.dataList[i]['DELIVERY_DATE'];
      // obj1['Location'] = this.dataList[i]['LOCATION'];
      // obj1['Pincode'] = this.dataList[i]['PIN_CODE'];
      // obj1['Varient'] = this.dataList[i]['VARIANT'];
      // obj1['Chassis Number'] = this.dataList[i]['CHASSIS_NUMBER'];
      // obj1['Sale Consultant Name'] = this.dataList[i]['SALES_CONSULTANT_NAME'];
      // obj1['Sale Date'] = this.dataList[i]['SALE_DATE'];
      // obj1['Last Service type'] = this.dataList[i]['LAST_SERVICE_TYPE'];
      // obj1['Last Ro Date'] = this.dataList[i]['LAST_RO_DATE'];
      // obj1['Segment'] = this.dataList[i]['SEGMENT'];
      // obj1['Last Ro Workshop'] = this.dataList[i]['LAST_RO_WORKSHOP'];
      // obj1['Last Ro Dealer Name'] = this.dataList[i]['LAST_RO_DEALER_NAME'];
      // obj1['Last Ro region'] = this.dataList[i]['LAST_RO_REGION'];
      // obj1['Name'] = this.dataList[i]['NAME'];
      // obj1['Work Phone Number'] = this.dataList[i]['WORK_PHONE_NUMBER'];
      // obj1['Root'] = this.dataList[i]['ROOT'];
      // obj1['SME Name'] = this.dataList[i]['SME_NAME'];
      // obj1['Pick Up and Drop'] = this.dataList[i]['PICK_UP_AND_DROP'];
      // obj1['Lost Customer Name'] = this.dataList[i]['LOST_CUSTOMER_NAME'];
      // obj1['SRV Model'] = this.dataList[i]['SRV_MODEL'];
      // obj1['Email'] = this.dataList[i]['EMAIL'];
      // obj1['Address'] = this.dataList[i]['ADDRESS'];
      // obj1['Purchase Date'] = this.dataList[i]['PURCHASE_DATE'];
      // obj1['Preferred DLR No.e'] = this.dataList[i]['PREFERRED_DLR_NO'];
      // obj1['Office Number'] = this.dataList[i]['OFFICE_NUMBER'];
      // obj1['Resindence Number'] = this.dataList[i]['RESIDENCE_NUMBER'];
      // obj1['Mileage'] = this.dataList[i]['MILEAGE'];
      // obj1['Field 1'] = this.dataList[i]['FIELD1'];
      // obj1['Field 2'] = this.dataList[i]['FIELD2'];
      // obj1['Field 3'] = this.dataList[i]['FIELD3'];
      // obj1['Field 4'] = this.dataList[i]['FIELD4'];
      // obj1['Field 5'] = this.dataList[i]['FIELD5'];
      // obj1['Field 6'] = this.dataList[i]['FIELD6'];
      // obj1['Field 7'] = this.dataList[i]['FIELD7'];
      // obj1['Field 8'] = this.dataList[i]['FIELD8'];
      // obj1['Field 9'] = this.dataList[i]['FIELD9'];
      // obj1['Field 10'] = this.dataList[i]['FIELD10'];
      // obj1['Resindence Number'] = this.dataList[i]['NO_OF_INVOICE_GENERATED'];
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList12.length - 1) {
        this._exportService.exportExcel(arry1, 'PSF Data' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  checked = false;
  checked1 = false;
  checkedd = true;
  indeterminate = true;
  indeterminate1 = false;
  setOfCheckedId = new Set<number>();
  setOfCheckedId1 = new Set<number>();
  array = []
  value1: any;
  CCE_IDS:any
  isChecked: boolean
  disable11:boolean=false
  onChecked1(id: number, checked: boolean): void {
   
    // this.code = value
    if (checked) {
      // console.log(checked);
      this.isChecked = checked
      this.array.push(id)
      this.disable11=true
      // console.log(this.array);
      this.indeterminate = false
      // this.dataList[ind].IS_SELECTED = 1
    } else {
      // this.dataList[ind].IS_SELECTED = 0
      this.array.pop()
      this.disable11=false
      // console.log(this.array);
      this.indeterminate = true
    }
  }

  // onChecked1(id: number, checked: boolean): void {
  //   if (checked) {
  //     console.log(checked);
  //     this.isChecked = checked
  //     this.array.push(id)
  //     console.log(this.array);
  //     this.indeterminate = false
  //     // this.dataList[ind].IS_SELECTED = 1
  //   } else {
  //     // this.dataList[ind].IS_SELECTED = 0
  //     this.array.pop()
  //     console.log(this.array);
  //     this.indeterminate = true

  //   }

  // }
  onAllChecked1(value: boolean): void {
    console.log(value);
    this.dataList.forEach(item => {
      this.isChecked = value
      if (value) {
        if (value == true) {
          this.array.push(item.ID)
          this.checked1 = true
          this.value1 = value
          // this.checked=false
          this.indeterminate = false
        }
        console.log(this.array);
        // item.IS_SELECTED = 1
      } else {
        // item.IS_SELECTED = 0
        this.array = []
        this.checked1 = false
        this.indeterminate = true
      }

    });

  }
  // onAllChecked1(value: boolean): void {
  //   console.log(value);
  //   this.dataList.forEach(item => {
  //     this.isChecked = value
  //     if (value) {
  //       if (value == true) {
  //         this.array.push(item.ID)
  //         this.checked1 = true
  //         this.value1 = value
  //         // this.checked=false
  //         this.indeterminate = false
  //       }
  //       console.log(this.array);
  //       // item.IS_SELECTED = 1
  //     } else {
  //       // item.IS_SELECTED = 0
  //       this.array = []
  //       this.checked1 = false
  //       this.indeterminate = true
  //     }

  //   });

  // }
  importInExcel() {
    this.search(false,true);
  }
  MapCCE() {
    if (this.CCE_IDS != undefined && this.array.length > 0) {
      this.api.MappsfCCE(this.CCE_IDS, this.array,'')
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("CCE Mapped Successfully ...", "");
            this.drawerClose();
            // this.isSpinning = false;
            this.array = []
            // this.checked = false
            this.CCE_IDS = null
            this.dataList.forEach(item => {
              this.isChecked = this.value1
              if (this.value1) {
                if (this.value1 == true) {
                  this.checked = false
                  this.checked1 = false
                  this.indeterminate = false
                }
                console.log(this.array);
                // item.IS_SELECTED = 1
              } else {
                // item.IS_SELECTED = 0
                this.array = []
                this.checked1 = false
                this.indeterminate = true
              }

            });
          }
          else {
            this.message.error("CCE Mapping Failed...", "");
            this.array = []
            // this.isSpinning = false;
          }
        });
    } else {
      this.message.error("Please Select Leads & Call Center Executive", "");
    }
  }




  }

  


