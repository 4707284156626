import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-financeincentiveallocationsummary',
  templateUrl: './financeincentiveallocationsummary.component.html',
  styleUrls: ['./financeincentiveallocationsummary.component.css']
})
export class FinanceincentiveallocationsummaryComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];
  formTitle = "Finance Incentive Allocation Summary";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  isOk: boolean = true
  MODEL: any;
  BRANCH: any;
  BranchName: any[]
  RoleName=[]

  quotationdate: any;
  BRANCH_ID=[]
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  MONTH: any
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  y = new Date().getFullYear();
  m = new Date().getMonth();
  yesterday = new Date().getDate();
  startValue: any = new Date(this.y,this.m,this.yesterday-1);
  endValue: any = new Date();
  // startValue = this.startValue.setDate(this.startValue.getDate() - 1);
  employees = [];
  EMPLOYEE_ID=[]

  YEAR: any
  SELECT_BRANCH: boolean = false;
  columns1: string[][] = [["YEAR", "Year"],["MONTH", "Month"],["BRANCH_NAME", "Branch Name"],["ROLE_NAME", "Role Name"],["EMPLOYEE_NAME", "Employee Name"],
  ["TOTAL_SALE", "Total Sale Count"],["TOTAL_INHOUSE_FINANCE", "Total Inhouse Finance Count"],["RATE", "Rate"],["TOTAL_AMOUNT", "Total Amount"]];
  columns: string[][] = [["YEAR", "Year"],["MONTH", "Month"],["BRANCH_NAME", "Branch Name"],["ROLE_NAME", "Role Name"],["EMPLOYEE_NAME", "Employee Name"]];
  orgId = Number(this.cookie.get('orgId'));

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe, private _exportService: ExportService,) { }

  ngOnInit() {
    
    this.getbranches();
    this.getroles();
    this.getEmployee()
    setTimeout(()=>{
      this.search(false);
    },1000);
    // this.applyFilter();
  
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  importInExcel() {
    this.search(true, true);
  }


  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.BranchName.length; i++) {
        ids.push(this.BranchName[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
  }
  getEmployee() {
    this.employees = [];
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.employees = data1['data'];
      // this.onSelectAllSelesExicutive(true)
    },
      (err) => {
        console.log(err);
      }
    );
  }
  getbranches() {
    this.api.getAllBranch( 0,0,"NAME","asc","AND ORG_ID=" + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.BranchName = data['data'];
        console.log(this.BranchName);
      }
    }, err => {
      console.log(err);
    });
  }
  ROLE_ID
  getroles() {
    this.api.getAllRoles(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.RoleName = data['data'];
        console.log(this.RoleName);
        // this.onSelectAllBranch(true);
      }
    }, err => {
      console.log(err);
    });
  }
  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    var branchFilterStr = "";
    if(this.BRANCH_ID != undefined&&this.BRANCH_ID.length > 0){
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

  

    var empFilterStr = "";
    if(this.EMPLOYEE_ID != undefined&&this.EMPLOYEE_ID.length > 0){
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.EMPLOYEE_ID + ")";
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.FinanceAllocationSummaryReports(this.pageIndex, this.pageSize, this.sortKey, sort,this.filterQuery+ likeQuery+branchFilterStr+empFilterStr).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.FinanceAllocationSummaryReports(0, 0, this.sortKey, sort,this.filterQuery+ likeQuery+branchFilterStr+empFilterStr).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  exportLoading: boolean = false;

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Year'] = this.dataList1[i]['YEAR'];
      obj1['Month'] = this.dataList1[i]['MONTH'];

      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Role Name'] = this.dataList1[i]['ROLE_NAME'];
      obj1['Employee Name'] = this.dataList1[i]['EMPLOYEE_NAME'];
      obj1['Total Sale Count'] = this.dataList1[i]['TOTAL_SALE'];
    
      obj1['Total Inhouse Finance Count'] = this.dataList1[i]['TOTAL_INHOUSE_FINANCE'];
      obj1['Rate'] = this.dataList1[i]['RATE'];
      obj1['Total Amount'] = this.dataList1[i]['TOTAL_AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Finance Incentive Allocation Summary' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }





  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
  


  applyFilter() {
    // this.isSpinning = false
    // this.loadingRecords = false;
    // var sort: string;
    // try {
    //   sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    // } catch (error) {
    //   sort = "";
    // }

    // this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    // this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    // if (this.startValue != null) {
    //   this.START_DATE = this.startValue
    // }

    // if (this.endValue != null) {
    //   this.END_DATE = this.endValue
    // }

    // if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
    //   this.BRANCH_ID = this.BRANCH_ID
    // }



    // if (this.START_DATE == undefined && this.END_DATE == undefined && this.BRANCH_ID == undefined) {
    //   this.message.error("Please Select Option To Filter", '')
    //   this.loadingRecords = false;
    //   this.isSpinning = false;

    //   this.isFilterApplied = "default";

    // }
    // if (this.searchText != "") {
    //   var likeQuery = " AND (";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

    //   var filterQuery = this.filterQuery + likeQuery
    // }
    // else {
    // }
    // if (this.isOk == true) {
    //   this.BRANCH_ID = this.BRANCH_ID;
    //   this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    //   this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

 
    //   this.search(true);
    // }
    if (  (this.BRANCH_ID.length > 0)||  (this.EMPLOYEE_ID.length > 0) )
    this.isFilterApplied = "primary";
  
    else
    this.isFilterApplied = "default";
  
    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.startValue = new Date(this.y,this.m,this.yesterday-1);
    this.endValue = new Date();
    this.START_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.BRANCH_ID = [];
    this.EMPLOYEE_ID = [];

    this.SELECT_BRANCH = false;
    this.searchText = "";
    this.filterQuery = "";
    this.filterClass = 'filter-invisible';
    //  this.isFilterApplied="default";
    this.search(true);

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

  month1(event: any) {

  }

}