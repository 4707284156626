import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-customerwise-insurancereport',
  templateUrl: './customerwise-insurancereport.component.html',
  styleUrls: ['./customerwise-insurancereport.component.css']
})
export class CustomerwiseInsurancereportComponent implements OnInit {
  exportLoading: boolean = false;
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Customer Wise Insurance Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false
  filterClass: string = "filter-invisible";
  dataList1 = [];
  SALES_EXECUTIVE=[];
  MODELS=[];
  BRANCH=[];
  INSURANCE_COMPANY=[];
  INSURANCE_DATE: any;
  date2 = new Date();
  startValue: any = new Date();
  endValue: any = new Date();
  MODE=[];
  model: any = [];
  branch: any = [];
  insurancecompany: any = [];
  insurancedate: any = [];
  inhouse_outhouse: any = [];
  quotationdate: any;
  Saleshead: any = [];
  SELECT_SALES_EXICUTIVE: boolean = false;
  SELECT_MODEL: boolean = false;
  SELECT_BRANCH: boolean = false;
  SELECT_INSURANCE_COMPANY: boolean = false;
  SELECT_MODE: boolean = false;
  columns: string[][] = [["CUSTOMER_NAME", "Customer Name"], ["MODEL_NAME", "Model Name"],["VARIENT_NAME", "Variant"],["SALES_EXECUTIVE_NAME", "Sales Executive"],["BRANCH_NAME", "Branch Name"],["DATE", "Insurance Date"],["INSURANCE_NAME", "Insurance Company"],];
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe,private _exportService: ExportService) { }

  ngOnInit() {
    this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(), 1)
    this.endValue = new Date();
    this.getbranches();
    this.getmodels();
    this.getsalehead();
    // this.getmodes()
    this.getinsurance();
    setTimeout(()=>{
      this.search(false);
    },1500);
  }
  
  importInExcel() {
    this.search(true, true);
  }


  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    // this.search(false);
  }

  getsalehead() {
    this.api.getAllEmpRoleMap(0, 0, 'ID', 'desc', 'AND ROLE_ID IN(28)').subscribe(data => {
      if (data['code'] == 200) {
        this.Saleshead = data['data'];
        console.log(this.Saleshead);
        this.onSelectAllSalesExecutive(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }

  getbranches() {
    this.api.getAllBranch(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
        console.log(this.branch);
        this.onSelectAllBranch(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }
  getmodels() {
    this.api.getAllModels(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.model = data['data'];
        console.log(this.model);
        this.onSelectAllModel(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }
  getinsurance() {
    this.api.getQuestionaryMaster(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.insurancecompany = data['data'];
        console.log(this.insurancecompany);
        this.onSelectAllInsuranceCompany(true)
        // this.search(true)
      }
    }, err => {
      console.log(err);
    });
  }


  getmodes() {
    this.api.getQuestionaryMaster(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.inhouse_outhouse = data['data'];
        console.log(this.inhouse_outhouse);
      }
    }, err => {
      console.log(err);
    });
  }

  onSelectAllSalesExecutive(event: any) {
    this.SELECT_SALES_EXICUTIVE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_SALES_EXICUTIVE == true) {
      for (var i = 0; i < this.Saleshead.length; i++) {
        ids.push(this.Saleshead[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE = ids
  }



  onSelectAllModel(event: any) {
    this.SELECT_MODEL = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODEL == true) {
      for (var i = 0; i < this.model.length; i++) {
        ids.push(this.model[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.MODELS = ids
  }
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.branch.length; i++) {
        ids.push(this.branch[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH = ids
  }
  onSelectAllInsuranceCompany(event: any) {
    this.SELECT_INSURANCE_COMPANY = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_INSURANCE_COMPANY == true) {
      for (var i = 0; i < this.insurancecompany.length; i++) {
        ids.push(this.insurancecompany[i]["INSURANCE_NAME"]);
      }
    } else {
      ids = [];
    }
    this.INSURANCE_COMPANY = ids
  }
  onSelectAllMode(event: any) {
    this.SELECT_MODE = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_MODE == true) {
      for (var i = 0; i < this.inhouse_outhouse.length; i++) {
        ids.push(this.inhouse_outhouse[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.MODE = ids
  }

  onSelectOff(event) {
    var a = this.Saleshead.length;
    var b = this.Saleshead.length - event.length;
    if ((a! = b)) {
      this.SELECT_SALES_EXICUTIVE = false;
    } else {
      this.SELECT_SALES_EXICUTIVE = true;
    }
    this.SALES_EXECUTIVE = event;
    if (this.SALES_EXECUTIVE.length == 0) {
      this.SELECT_SALES_EXICUTIVE = false;
    }
  }

  onSelectOff1(event) {
    var a = this.model.length;
    var b = this.model.length - event.length;
    if ((a! = b)) {
      this.SELECT_MODEL = false;
    } else {
      this.SELECT_MODEL = true;
    }
    this.MODELS = event;
    if (this.MODELS.length == 0) {
      this.SELECT_MODEL = false;
    }
  }

  onSelectOff2(event) {
    var a = this.branch.length;
    var b = this.branch.length - event.length;
    if ((a! = b)) {
      this.SELECT_BRANCH = false;
    } else {
      this.SELECT_BRANCH = true;
    }
    this.BRANCH = event;
    if (this.BRANCH.length == 0) {
      this.SELECT_BRANCH = false;
    }
  }

  onSelectOff3(event) {
    var a = this.insurancecompany.length;
    var b = this.insurancecompany.length - event.length;
    if ((a! = b)) {
      this.SELECT_INSURANCE_COMPANY = false;
    } else {
      this.SELECT_INSURANCE_COMPANY = true;
    }
    this.INSURANCE_COMPANY = event;
    if (this.INSURANCE_COMPANY.length == 0) {
      this.SELECT_INSURANCE_COMPANY = false;
    }
  }

 

  dates: any = [];
  startDateChange() {
    var startDate = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }



  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  // disabledStartDate = (selected: Date): boolean => 
  //   differenceInCalendarDays(selected, this.current) > -1;
  current = new Date();

  disabledStartDate = (START_DATE: Date): boolean => {
    if (!START_DATE || !this.END_DATE) {
      return false;
    }
    return START_DATE.getTime() > this.END_DATE;
  };

  START_DATE: any
  END_DATE: any
  endOpen = false;
  disabledEndDate = (END_DATE: Date): boolean => {
    if (!END_DATE || !this.START_DATE) {
      return false;
    }
    return END_DATE.getTime() < this.START_DATE - 2;
  };

  onStartChange(date: Date): void {
    this.START_DATE = date;
  }
  onEndChange(date: Date): void {
    this.END_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }


  // search(reset: boolean) {
  //   if (reset) {
  //     this.pageIndex = 1;
  //     this.sortKey = "id";
  //     this.sortValue = "desc"
  //     this.dataList = []
  //   }


  //   this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   console.log("search text:" + this.searchText);
  //   if (this.searchText != "") {
  //     var likeQuery = " AND";
  //     this.columns.forEach(column => {
  //       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
  //     });
  //     likeQuery = likeQuery.substring(0, likeQuery.length - 2)

  //     var filterQuery = this.filterQuery + likeQuery

  //     // this.api.getmodelwiseprice(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery,this.applicationId,0).subscribe(data => {
  //     //   console.log(data)
  //     //   this.loadingRecords = false;
  //     //   this.totalRecords = data['count'];
  //     //   this.dataList = data['data'];
  //     // }, err => {
  //     //   console.log(err);
  //     // });
  //   }
  //   else {
  //     // this.applyFilter()
  //   }
  // }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    this.START_DATE = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.END_DATE, 'yyyy-MM-dd')
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.customerwiseinsurancereport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,this.START_DATE,this.END_DATE,this.SALES_EXECUTIVE,this.MODELS,this.BRANCH,this.MODE,this.INSURANCE_COMPANY).subscribe(data => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        },err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.customerwiseinsurancereport(0,0, this.sortKey, sort, likeQuery,this.START_DATE,this.END_DATE,this.SALES_EXECUTIVE,this.MODELS,this.BRANCH,this.MODE,this.INSURANCE_COMPANY).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList1 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1['Customer Name'] = this.dataList1[i]['CUSTOMER_NAME'];
      obj1['Model Name'] = this.dataList1[i]['MODEL_NAME'];
      obj1['Variant'] = this.dataList1[i]['VARIENT_NAME'];
      obj1['Sales Executive'] = this.dataList1[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
      obj1['Insurance Date'] = this.dataList1[i]['DATE'];
      obj1['Insurance Company'] = this.dataList1[i]['INSURANCE_NAME'];
      obj1['Insurance Amount'] = this.dataList1[i]['INSURANCE_AMOUNT'];
      obj1['Mode'] = this.dataList1[i]['MODE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(arry1, 'Customer Wise Insurance Report"' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }
 

  isOk = true
  applyFilter() {
    this.isFilterApplied = "primary";

    this.isSpinning = false
    this.loadingRecords = false;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }

    if (this.START_DATE == undefined && this.END_DATE == undefined && this.SALES_EXECUTIVE == undefined && this.MODELS == undefined && this.BRANCH == undefined && this.INSURANCE_COMPANY == undefined && this.MODE == undefined ) {
      this.message.error("Please Select Option To Filter", '')
      this.loadingRecords = false;
      this.isSpinning = false;
      this.isOk = false
      this.isFilterApplied = "default";

    }
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery
    }
    else {
      // this.applyFilter()
    }
    if (this.isOk == true) {  
      this.search(true)
    }
    this.filterClass = "filter-invisible";
}

clearFilter() {
    // this.SALES_EXECUTIVE=[]
    // this.MODELS = [];
    this.startValue = new Date();
    this.startValue=new Date(this.startValue.getFullYear(), this.startValue.getMonth(),1) 
    this.endValue = new Date();
    // this.BRANCH = [];
    // this.INSURANCE_COMPANY = [];
    // this.MODE = [];
    // this.SELECT_SALES_EXICUTIVE = false;
    // this.SELECT_MODEL = false;
    // this.SELECT_BRANCH = false;
    // this.SELECT_INSURANCE_COMPANY = false;
    this.searchText = '';
    this.filterQuery = '';
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = "default";

    let ids = [];
    this.SELECT_BRANCH = true
    for (var i = 0; i < this.branch.length; i++) {
      ids.push(this.branch[i]["ID"]);
    }
    this.BRANCH = ids;

    let ids1 = [];
    this.SELECT_MODEL = true
    for (var i = 0; i < this.model.length; i++) {
      ids1.push(this.model[i]["ID"]);
    }
    this.MODELS = ids1;

    let ids2 = [];
    this.SELECT_SALES_EXICUTIVE = true
    for (var i = 0; i < this.Saleshead.length; i++) {
      ids2.push(this.Saleshead[i]["EMPLOYEE_ID"]);
    }
    this.SALES_EXECUTIVE = ids2;


    let ids3 = [];
    this.SELECT_INSURANCE_COMPANY = true
    for (var i = 0; i < this.insurancecompany.length; i++) {
      ids3.push(this.insurancecompany[i]["INSURANCE_NAME"]);
    }
    this.INSURANCE_COMPANY = ids3;
    this.search(true);
    //  this.isFilterApplied="default";
  }


  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }

}


