import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: 'app-bank-wise-sales-manager-wise-finance-inhouse-summary-report',
  templateUrl: './bank-wise-sales-manager-wise-finance-inhouse-summary-report.component.html',
  styleUrls: ['./bank-wise-sales-manager-wise-finance-inhouse-summary-report.component.css']
})
export class BankWiseSalesManagerWiseFinanceInhouseSummaryReportComponent implements OnInit {

  formTitle = "Branch Wise Sales Manager Wise Finance Inhouse Summary Report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'EWSalesSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["BRANCH_NAME", "Branch Name"],
  ["SALES_MANAGER_NAME", "Sales Manager Name"],];

  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;


  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
FROM_DATE:any = new Date();
  TO_DATE:any = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];

  monthFormat = "MMM-yyyy";
  SALES_MANAGER_ID: any = [];

  START_DATE: any;
  END_DATE: any;
  DATE = [];
  current = new Date();

  isVisible1: boolean = false
  drawerTitle1: string = ''
  members: any = []
  FILTER_QUERY: any

  AddSchedule() {
    this.isVisible1 = true;
    var obj = {
      START_DATE: this.START_DATE,
      END_DATE: this.END_DATE,
      SALES_MANAGER_ID: this.SALES_MANAGER_ID,
    }
    this.FILTER_QUERY = JSON.stringify(obj);
    this.drawerTitle1 = "Manage Schedule"
    console.log("hi")
    this.api.getAllMembers1(0, 0, '', '', ' AND REPORT_ID = 71').subscribe((data: any) => {
      this.members = data['data'];
    })
  }

  drawerClose1(): void {
    // this.search(false);
  }

  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.FROM_DATE = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.TO_DATE = new Date();
    // this.search();
    this.getSales();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
  keyup(event: any) {
    this.search(true);
  }
  SELECT_ALL: boolean = true;

  onSelectAllChecked(event: any) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_MANAGER_ID = ids;
  }

  onSelectOff(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.SALES_MANAGER_ID = event;
    if (this.SALES_MANAGER_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

 

  sales = [];
  getSales() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID IN(29)')
      .subscribe(data => {
        this.sales = data['data']
        let ids = [];

        if (this.SELECT_ALL == true) {
          for (var i = 0; i < this.sales.length; i++) {
            ids.push(this.sales[i]["EMPLOYEE_ID"]);
          }
        } else {
          ids = [];
        }

        this.SALES_MANAGER_ID = ids;
        this.search();

      },
        (err) => {
          console.log(err);
        }
      );
  }
  applyFilter() {

    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }

    if (this.SALES_MANAGER_ID != undefined) {
      this.SALES_MANAGER_ID = this.SALES_MANAGER_ID;
    }
    this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, 'yyyy-MM-dd')
    this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd')

    if (this.FROM_DATE != null) {
      this.FROM_DATE = this.FROM_DATE;
    }

    if (this.TO_DATE != null) {
      this.TO_DATE = this.TO_DATE;
    }


    this.search();


  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.DATE = []
    this.FROM_DATE = new Date(this.current.getFullYear() + '-' + (this.current.getMonth() + 1) + '-01');
    this.TO_DATE = new Date();
    this.SELECT_ALL = true
    let ids = [];
    this.search()

    for (var i = 0; i < this.sales.length; i++) {
      ids.push(this.sales[i]["EMPLOYEE_ID"]);
    }


    this.SALES_MANAGER_ID = ids;
  }
  ewsalesdata = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

 
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.branchwisesalesManagerwiseInhouse(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.SALES_MANAGER_ID, this.FROM_DATE, this.TO_DATE)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.branchwisesalesManagerwiseInhouse(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.SALES_MANAGER_ID, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['Branch Name'] = this.ewsalesdata[i]['BRANCH_NAME'];
      obj1['Sales Manager Name'] = this.ewsalesdata[i]['SALES_MANAGER_NAME'];
      obj1['No. of Finances'] = this.ewsalesdata[i]['FINANCE_COUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'Bank Wise Sales Manager Wise Finance Inhouse Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
