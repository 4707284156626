import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { Log } from 'src/app/Models/logs';
import { PSFActivityLeadMaster } from 'src/app/Models/psfaddacivity';
import { PSFCOLLAPSEMASTER } from 'src/app/Models/psfmaster';
import { ServiceModuleExcel } from 'src/app/Models/servicemoduleexcel';
import { SMRMaster } from 'src/app/Models/SMRmaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-forlogstable',
  templateUrl: './forlogstable.component.html',
  styleUrls: ['./forlogstable.component.css']
})
export class ForlogstableComponent implements OnInit {
  formTitle = "Attendance Register Adjustment";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NUMBER", "Sequence No."], ["WORKSHOP_CODE", "WorkShop Code"], ["R_O_NUMBER", "RO Number"], ["BILL_DATE", "Bill Date"], ["RO_DATE", "Ro Date"], ["CUSTOMER_NAME", "Customer Name"], ["MOBILE_NUMBER", "Mobile Number"], ["VIN", "VIN"], ["REG_NUMBER", "Reg Number"], ["MODEL_NAME", "Model Name"], ["SERVICE_ADVISOR_NAME", "Service Advisor Name"], ["WORK_TYPE", "Work Type"], ["SALE_DATE", "Sale Date"], ["SPECIAL_MASSAGE", "Special Massage"], ["FOLLOW_UP_DATE", "Follow Up Date"], ["PSF_DATE", "PSF Date"], ["CAR_MAINTAINANCE_RATE", "Car Maintainance Rate"], ["CAR_CLEANLINESS_RATE", "Car CleanLiness Rate"], ["RATE_FOR_SERVICE_ADVISOR", "Rate For Service Advisor"], ["INSURANCE_CLAIM_RATE", "Insurance Claim Rate"], ["REGULAR_UPDATED_STATUS", "Regular Updated Status"], ["DELIVER_ON_DATE_TIME", "Deliver On Date Time"], ["RATE_FOR_WORKSHOP", "Rate For Workshop"], ["DAY3_PSF_STATUS", "Days PSF Status"], ["DAY2_CALL_COMPLAINT", "Days To Days Complaint"], ["CRE_ROOT", "CRE root"], ["DISSATISFIED_ROOT", "Dissatisfied Root"], ["FOLLOWUP_DATE", "FollowUp Date"], ["CONCERN_ROOT", "Concern Root"], ["SUB_ROOT", "Sub Root"], ["DAY5_STATUS", "Days Status"], ["REMARK", "REmark"], ["DAY5_CALL_COMPLAINT", "Days Call Complaints"], ["ACTION_TAKEN", "Action Taken"], ["HV_DATE_OR_FOLLOW_UP_DATE", "HV Date Or Follow Up Date"], ["FINAL_REMARK", "Final Remark"], ["UPLOADED_DATE (RUC_CALLING)", "Uploaded Date (RUC Calling)"], ["CALL_STATUS_RATE", "Call status Rate"], ["FAIRNESS_OF_CHARGES", "Fairness Of Charges"], ["LOYAL10_STATUS", "Loyal Status"], ["RUC_CALLING_REMARK", "Ruc Calling Remark"], ["WASHING_AND_CLEANING", "Washing And Cleaning"]];
  isPSFLead: boolean = true;
  PSFdrawerVisible!: boolean;
  drawerTitle!: string;
  index: number = 0;
  now = new Date();
  MONTH:any
  EMP:any
  isButtonVisible: boolean;
  monthformat:any='MMM-yyyy'
  drawerData: ServiceModuleExcel = new ServiceModuleExcel();
  userId = Number(this.cookie.get('userId'));
  userName = this.cookie.get('userName');
  date1:any
  // PSFdrawerVisible!: boolean; 
  // dataList:any[]=[]


  collapseDrawerVisible: boolean = false;
  drawerData1: PSFCOLLAPSEMASTER = new PSFCOLLAPSEMASTER();
  isClosed = false;
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData2: PSFActivityLeadMaster = new PSFActivityLeadMaster();
  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false

  constructor(private api: ApiService, public datePipe: DatePipe, private message: NzNotificationService,private cookie: CookieService) {

   }

  ngOnInit() {
    // this.search();
    this.getEmployee();
    this.MONTH=new Date().setMonth(this.now.getMonth() - 1)
    
    // this.MONTH = this.datePipe.transform(this.MONTH, 'MM-yyyy')
    console.log(this.MONTH);
  }

  dataaa : Log = new Log()
  employee=[]
  getEmployee() {
    this.api.getAllEmployees(0,0, this.sortKey, this.sortValue,  '').subscribe(data => {
      if(data['code']==200){
        this.employee = data['data']       
        
      }
     
    })
  }


  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.PSFdrawerVisible = false;
  }

  get closeCallback1() {
    // this.index=0;
    return this.drawerClose1.bind(this);
  }

  get closeCallback2() {
    // this.index=0;
    return this.drawerClose2.bind(this);
  }

  drawerClose1(): void {
    this.search();
    this.collapseDrawerVisible = false;
    this.btnLoadMoreStatus = null;
    this.isClosed = true
    // this.index=0;
  }
  drawerClose2(): void {
    this.drawerVisible1 = false;
  }
  datalist = []
  btnLoadMoreStatus:boolean

  isApkVersionModalVisible = false;
  isApkVersionModalConfirmLoading = false;
  apkVersionModalTitle: string = "";
  alldata=[]
  CURRENTSTATUS:any
  REMARK:any
  NEW_STATUS:any
  UPDATE_LOG:any
  DATE:any
  Activity(data:any): void {
    this.isApkVersionModalVisible = true;
    this.apkVersionModalTitle = "Update Log";
    this.alldata = data
    this.CURRENTSTATUS = data.STATUS
    this.UPDATE_LOG = data.UPDATE_LOG
    this.DATE = data.DATEz
    this.REMARK = data.REMARK


  }

  handleApkVersionModalCancel(): void {
    this.isApkVersionModalVisible = false;
    this.isApkVersionModalConfirmLoading = false;
    // this.isProgressVisible = false;
    this.REMARK = undefined;
    this.NEW_STATUS = undefined;
    // this.fileURL = null;

  }

  today = new Date().setMonth(this.now.getMonth() - 1)

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  differenceInCalendarDays(current, this.today) > 0;

  handleApkVersionModalOk(): void {
    var isOk = true;
    this.dataaa = new Log()

    if(this.NEW_STATUS == undefined ) {
      this.message.error("Please Enter New Status", "");
      isOk=false
  } else if(this.REMARK == undefined || this.REMARK == '' || this.REMARK == null){
    isOk=false
    this.message.error("Please Enter Remark", "");
  }

 
  if (isOk) {
    this.isApkVersionModalConfirmLoading = true;
    var mon = this.datePipe.transform(this.MONTH, 'MM')


    var year = this.datePipe.transform(this.MONTH, 'yyyy')
    this.dataaa.CURRENT_STATUS = this.CURRENTSTATUS
    this.dataaa.EMP_ID = this.EMP
    this.dataaa.NEW_STATUS = this.NEW_STATUS
    this.dataaa.REMARK = this.REMARK
    this.dataaa.USER_ID = this.userId
    this.dataaa.USER_NAME = this.cookie.get('userName')
    this.dataaa.DATE = this.DATE

    this.api.updatelogsss(this.dataaa).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.isApkVersionModalConfirmLoading = false;
        this.message.success("Log Updated Successfully", "");
        this.REMARK = undefined;
        this.NEW_STATUS = undefined;
        this.isApkVersionModalVisible = false;
        this.search(false)
      } else {
        this.isApkVersionModalConfirmLoading = false;
        this.message.error("Failed to Update Log", "");
        this.REMARK = undefined;
        this.NEW_STATUS = undefined;
        this.isApkVersionModalVisible = false;
      }
    });
  }
}
  edit(data: PSFCOLLAPSEMASTER): void {
    this.drawerTitle = " Forms ";
    this.drawerData1 = Object.assign({}, data);
    // this.drawerData1=Object.assign({},data);
    (localStorage.setItem('editData', JSON.stringify(data.ID)));
    // this.SMRdrawerVisible = true;
    this.collapseDrawerVisible = true;
    this.active1 == false
    this.active2 == false
    this.active3 == false
    this.active4 == false
    this.active5 == false
    this.active6 == false
  }


  add(): void {
    this.drawerTitle = " PSF Leads ";
    this.drawerData = new ServiceModuleExcel();
    this.PSFdrawerVisible = true;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  keyup(event: any) {
    this.search();

  }

  search(reset: boolean = false) {

        // const currentDate = new Date();

       
        
         
      if (reset) {
        this.pageIndex = 1;
        this.sortKey = "id";
        this.sortValue = "desc"
      }
  
      this.loadingRecords = true;
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  
      } catch (error) {
        sort = "";
      }
  
      var likeQuery = "";
      console.log("search text : " + this.searchText);
  
      if (this.searchText != "") {
        likeQuery = " AND";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
  
        likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      }

      
  
      if(this.MONTH!=undefined && this.EMP != undefined){
  
       var mon = this.datePipe.transform(this.MONTH, 'MM')
       console.log(mon);
  
       var year = this.datePipe.transform(this.MONTH, 'yyyy')
       console.log(year);

       const currentDate =  this.date1
    
        
      const dayOfMonth = parseInt(this.datePipe.transform(currentDate, 'd'));
      console.log(dayOfMonth,"dayOfMonthdayOfMonth");
      this.isButtonVisible = dayOfMonth === 1 || dayOfMonth === 2;
  
        this.api.getlogsdata(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+ '',this.EMP,mon,year).subscribe(data => {
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.dataList = data['data']['0'];

          } 
          else if (data['code'] == 401) {
            this.loadingRecords = false;
            this.message.warning("Invalid date to update attendance", "")
          }
          else{
            this.loadingRecords = false;
            this.message.error("Failed to get records", "")

          }
        }, err => {
          console.log(err);
        });
      } else {
        this.message.error("Please Select Emplyee & Date", "")
      }
  
        
        


  }
}




