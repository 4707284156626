import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { AssetRequest } from 'src/app/Models/AssetRequest';
import { ApiService } from 'src/app/Service/api.service';
import { AssetRequestDrawerComponent } from '../asset-request-drawer/asset-request-drawer.component';

@Component({
  
  selector: 'app-asset-request-list',
  templateUrl: './asset-request-list.component.html',
  styleUrls: ['./asset-request-list.component.css']
})

export class AssetRequestListComponent implements OnInit {
  formTitle = "Manage Asset Request";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  assetRequestData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["STATUS", "Status"], ["ASSET_CATEGORY_NAME", "Asset Category"], ["ASSET_NAME", "Asset"], ["DESCRIPTION", "Description"], ["REQUESTED_DATE_TIME", "Requested Date Time"], ["EXPECTED_DATE_TIME", "Expected Date Time"]];
  approveTableColumns: string[][] = [["STATUS", "Status"], ["ASSET_CATEGORY_NAME", "Asset Category"], ["ASSET_NAME", "Asset"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: AssetRequest = new AssetRequest();
  drawerVisible1: boolean;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.loadingRecords = true;
    if (this.STATUS == "P") {
      this.api.getAllRequestedAsset(this.pageIndex, this.pageSize, this.sortKey, sort, '', this.api.userId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.assetRequestData = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (this.STATUS == "A") {
      this.api.getAllAllocatedAsset(this.api.userId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.assetRequestData = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  @ViewChild(AssetRequestDrawerComponent, { static: false }) AssetRequestDrawerComponentVar: AssetRequestDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Asset Request";
    this.drawerData = new AssetRequest();
    this.drawerVisible = true;
  }

  edit(data: AssetRequest): void {
    this.drawerTitle = "Update Asset Category";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  STATUS = "P";

  changeRadioButton(status) {
    this.STATUS = status;
    this.search(true);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
}
