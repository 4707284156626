export class advanceMaster {
    DATE
    RECEIPT_NUMBER
    AMOUNT
    PAYMENT_MODE
    REFERENCE_NUMBER
    RECEIPT_PHOTO
    PAYMENT_PHOTO
    NARRATION
    QUOTATION_ID
}