import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { PSFActivityLeadMaster } from 'src/app/Models/psfaddacivity';
// import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { ApiService } from 'src/app/Service/api.service';
import { activityMaster } from 'src/app/pages/ActivityMaster/ActivityMaster1/activityMaster';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { log } from 'console';
// import { ActivityLeadMaster } from '../../ActivityLeadmaster (1) (1)';
// import { ActivityLeadMaster } from '../../ActivityMaster/ActivityLeadmaster (1) (1)';

@Component({
  selector: 'app-psfleadactivity',
  templateUrl: './psfleadactivity.component.html',
  styleUrls: ['./psfleadactivity.component.css']
})
export class PsfleadactivityComponent implements OnInit {

  @Input()
  drawerClose2: Function;
  @Input()
  data2: PSFActivityLeadMaster = new PSFActivityLeadMaster()
  @Input() datalist = []
  @Input() CUSTOMER_ID
  @Input() allHolidayList = []
  @Input() EmployeeList24 = []
  @Input() customername
  @Input() SERVICE_ADVISER_NAME
  @Input() SERVICE_ADVISER_ID
  @Input() modile_no
  @Input() feedbackdate
  @Input() complaint
  @Input() CCEREMARK
  @Input() RO_NUMBER: any
  @Input() updateSAdata
  @Input() disablesavebutton
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  orgId = this.cookie.get('orgId');
  drawerVisible: boolean;
  isSpinning = false;
  isSpinning111 = false;
  roleId = Number(this.cookie.get('roleId'));
  viewlog: boolean = false
  totalRecords: number;
  pageSize = 5
  drawerTitle: string;
  @Input() CALL_STATUSS: any
  WORK_TYPE: any
  @Input() btnLoadMoreStatus
  @Input() IS_CONFIRMATION_DONE: boolean
  @Input() IS_HQ_CONCERN: boolean
  @Input() data24: PSFActivityLeadMaster = new PSFActivityLeadMaster()

  userId = this.cookie.get('userId')
  branchId = this.cookie.get('branchId')

  @Input() data: PSFActivityLeadMaster = new PSFActivityLeadMaster()
  // IS_REMINDER
  // CALL_TYPE:any
  // LOCATION:any
  // CALLED_BY:any
  // BT_NUMBER
  // BOOKING_DATE
  // BT_DATE_TIME
  forsadisable


  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.dept();
    this.location()
    this.des()
    this.getallorg1();
    this.loadDataFilter()
    this.loadserviceadviser()
    this.loadDataFilterFI()
    // this.getEmployeeList();
    // this.getHPromiseEmployeeList();
    // this.getCorporateManagerEmployeeList();
    // this.getDirectorEmployeeList();
    // this.getAccountantEmployeeList();
    this.getHolidayList()
    this.data.CALLING_DATE = new Date()
    this.data.PSF_DATE = new Date()
    this.data.CALLING_TIME = new Date()
    this.data.ACTIVITY_TYPE_ID = 2
    // this.data.BOOKING_DATE = new Date()
    // this.CALL_STATUSS = 'C'
    if (this.roleId == 45) {
      this.forsadisable = true

    }
    // this.filteredOptions = this.options;
  }
  filterEmployees = []
  loadDataFilter() {

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND STATUS = 1 AND BRANCH_ID = " + this.branchId + ' AND DESIGNATION_ID IN (7,16,21,27,87,96,150,160,163)').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  filterEmployeesFI = []
  loadDataFilterFI() {

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND STATUS = 1 AND BRANCH_ID = " + this.branchId + ' AND DESIGNATION_ID IN (50)').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployeesFI = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  loadserviceadviserid = []
  loadserviceadviser() {

    this.api.getAllEmpRoleMap(0, 0, '', 'asc', " AND STATUS = 1 AND ROLE_ID = 48").subscribe(employees => {
      if (employees['code'] == 200) {
        this.loadserviceadviserid = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  customerID = []
  getactivities(dataa) {
    this.isSpinning111 = true;
    this.customerID = dataa
    // this.api.getAllPSFActivityleadMaster(1, this.pageSize, 'ID', 'desc', ' AND LEAD_ID = ' + dataa).subscribe(
    //   data => {
    //     if (data['code'] == 200) {
    //       this.totalRecords = data['count'];
    //       this.datalist = data['data']
    //       this.isSpinning111 = false;
    //       // console.log(this.datalist);
    //       if (this.totalRecords == this.datalist.length) {
    //         this.btnLoadMoreStatus = false;

    //       } else {
    //         this.btnLoadMoreStatus = true;
    //       }
    //     }
    //   }, err => {
    //     // console.log(err);
    //   });

    this.api.getAllPSFActivityleadMaster(1, this.pageSize, 'ID', 'desc', ' AND RO_NUMBER = ' + "'" + dataa + "'").subscribe(data => {
      if (data['code'] == 200) {
        this.totalRecords = data['count'];
        this.datalist = data['data']
        this.isSpinning111 = false;
        // console.log(this.datalist);
        if (this.totalRecords == this.datalist.length) {
          this.btnLoadMoreStatus = false;

        } else {
          this.btnLoadMoreStatus = true;
        }
      }
    }, err => {
      // console.log(err);
    });
  }

  data1: PSFActivityLeadMaster = new PSFActivityLeadMaster()
  logvieww(data1: PSFActivityLeadMaster) {
    this.viewlog = true;
    this.data = new PSFActivityLeadMaster()
    this.data1 = Object.assign({}, data1);
    console.log(data1);

    if (data1.IS_HQ_CONCERN == '1') {
      this.data1.IS_HQ_CONCERN = true
    } else {
      this.data1.IS_HQ_CONCERN = false
    }


    if (data1.CALLING_TIME != null)
      this.data1.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.data1.CALLING_TIME;

    if (data1.SELF_REMINDER_TIME != null)
      this.data1.SELF_REMINDER_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.data1.SELF_REMINDER_TIME;


    if (data1.REMINDER_TIME != null)
      this.data1.REMINDER_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.data1.REMINDER_TIME;

    this.data1.ACTIVITY_TYPE_ID = data1.ACTIVITY_TYPE_ID
  }


  handleCancel2(): void {
    // console.log('Button cancel clicked!');
    this.viewlog = false;

  }



  // onStatu(event) {
  //   console.log(event);

  // }
  // close(accountMasterPage: NgForm) {
  //   this.drawerClose();
  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    // this.data.BOOKING_TIME =null
    this.data.CALLING_TIME = null

  }
  // }
  ddd: any
  dateFormat = "dd/MM/yyyy"
  dateFormat1 = "HH:mm"
  REMARK1: any
  // dateFormat2 
  isOk = true;
  inputValue: string;
  filteredOptions: string[] = [];
  options = ['Customer Lost their car ', 'Car was damaged', 'Customer sold their car', 'Customer has serviced the car from outside'];
  onChange(value: string): void {

    this.filteredOptions = this.options.filter(
      (option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }


  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    console.log(this.data.RECEIVED_ON_DELIVERY_DATE);


    this.data.LEAD_ID = this.data2.LEAD_ID
    this.data.PSF_CCE_ID = this.cookie.get('userId')



    if (this.data.ACTIVITY_TYPE_ID == null || this.data.ACTIVITY_TYPE_ID == undefined) {
      this.isOk = false;
      this.message.error('Please Select Activity Type', '');
    }

    if (this.SERVICE_ADVISER_ID == null || this.SERVICE_ADVISER_ID == undefined) {
      this.isOk = false;
      this.message.error('Please Select Service Adviser', '');
    }


    if (this.data.IS_REMINDER) {

      if (this.data.SELF_REMINDER_DATE == null || this.data.SELF_REMINDER_DATE == undefined) {
        this.isOk = false;
        this.message.error('Please Select Self Reminder Date', '');
      }
    }

    if (this.data.CALL_STATUS == 'N') {
      if (this.data.NC_CALLING_STATUS == undefined || this.data.NC_CALLING_STATUS == null) {
        this.message.error("Please select Not connected Call Status", "")
      }
      console.log('ok')
    }

    if (this.roleId != 45) {
      // if ((this.complaint == null || this.complaint == undefined || this.complaint == "") 
      // || ((this.data.IS_REMINDER == false) || (this.data.CALL_STATUS != 'N'))) {

      if (this.data.CALL_STATUS == 'C' &&  this.data.IS_REMINDER == false) {
        if (this.complaint == null || this.complaint == undefined || this.complaint == "") {
          this.isOk = false;
          this.message.error('Please Enter 3rd Day Calling Complaint if any', '');
        }
      }

      if (this.data.REMARK == null || this.data.REMARK == undefined || this.data.REMARK == '') {
        this.isOk = false;
        this.message.error('Please Enter Remark', '');
      }

    }


    // else {
    console.log('ok1')
    if (this.isOk) {
      this.data.STATUS = ''

      console.log(this.data.MAINTAIN_AND_REPAIR_RATING, "");

      if (this.data.MAINTAIN_AND_REPAIR_RATING != undefined &&
        this.data.CONDITION_CLEANESS_RATING != undefined &&
        this.data.SA_EXPLAIN_RATING != undefined &&
        this.data.PICK_UP_DROP_RATING != undefined &&
        this.data.DEALER_FACILITY_RATING != undefined &&
        this.data.OVERALL_EXPERIENCE_RATING != undefined) {

        // var value = ((this.data.MAINTAIN_AND_REPAIR_RATING +
        //   this.data.CONDITION_CLEANESS_RATING +
        //   this.data.SA_EXPLAIN_RATING +
        //   this.data.PICK_UP_DROP_RATING +
        //   this.data.DEALER_FACILITY_RATING +
        //   this.data.OVERALL_EXPERIENCE_RATING) / 6);

        // console.log(value);

        // if (value == 10) {
        //   this.data.STATUS = 'D'
        // } else if ((value >= 9) && (value < 10)) {
        //   this.data.STATUS = 'S'
        // } else if ((value < 9) && (value > 0)) {
        //   this.data.STATUS = 'DS'
        // }




        if (this.data.MAINTAIN_AND_REPAIR_RATING == 10 ||
          this.data.CONDITION_CLEANESS_RATING == 10 ||
          this.data.SA_EXPLAIN_RATING == 10 ||
          this.data.PICK_UP_DROP_RATING == 10 ||
          this.data.DEALER_FACILITY_RATING == 10 ||
          this.data.OVERALL_EXPERIENCE_RATING == 10) {
          this.data.STATUS = ''
          this.data.STATUS = 'D'
        }

        if (this.data.MAINTAIN_AND_REPAIR_RATING == 9 ||
          this.data.CONDITION_CLEANESS_RATING == 9 ||
          this.data.SA_EXPLAIN_RATING == 9 ||
          this.data.PICK_UP_DROP_RATING == 9 ||
          this.data.DEALER_FACILITY_RATING == 9 ||
          this.data.OVERALL_EXPERIENCE_RATING == 9) {
          this.data.STATUS = ''
          this.data.STATUS = 'S'
        }

        if (this.data.MAINTAIN_AND_REPAIR_RATING < 9 ||
          this.data.CONDITION_CLEANESS_RATING < 9 ||
          this.data.SA_EXPLAIN_RATING < 9 ||
          this.data.PICK_UP_DROP_RATING < 9 ||
          this.data.DEALER_FACILITY_RATING < 9 ||
          this.data.OVERALL_EXPERIENCE_RATING < 9) {
          this.data.STATUS = ''
          this.data.STATUS = 'DS'
        }

        if (this.data.IS_REMINDER) {
          this.data.PSF_CALLING_STATUS = "R"
          this.data.STATUS = "R"
          this.data.IS_ADDED_FIRST = 'N'
          this.data.MAINTAIN_AND_REPAIR_RATING = 0
          this.data.CONDITION_CLEANESS_RATING = 0
          this.data.SA_EXPLAIN_RATING = 0
          this.data.PICK_UP_DROP_RATING = 0
          this.data.DEALER_FACILITY_RATING = 0
          this.data.OVERALL_EXPERIENCE_RATING = 0
          // console.log(this.data.IS_REMINDER == true && (this.data.SELF_REMINDER_DATE != undefined || this.data.SELF_REMINDER_DATE != null));
          // console.log(this.data.IS_REMINDER == true && (this.data.REMINDER_DATE != undefined || this.data.REMINDER_DATE != null));
        }

        if (this.data.CALL_STATUS == 'C' && !this.data.IS_REMINDER) {
          this.data.PSF_CALLING_STATUS = "C"

        }
        else if (this.data.CALL_STATUS == 'N' && !this.data.IS_REMINDER) {
          this.data.PSF_CALLING_STATUS = "NC"
          this.data.IS_ADDED_FIRST = 'N'
          this.data.STATUS = "NC"
          this.data.MAINTAIN_AND_REPAIR_RATING = 0
          this.data.CONDITION_CLEANESS_RATING = 0
          this.data.SA_EXPLAIN_RATING = 0
          this.data.PICK_UP_DROP_RATING = 0
          this.data.DEALER_FACILITY_RATING = 0
          this.data.OVERALL_EXPERIENCE_RATING = 0
        }

        else if (this.data.IS_REMINDER == true) {
          this.data.PSF_CALLING_STATUS = "R"
          this.data.STATUS = "R"
          this.data.MAINTAIN_AND_REPAIR_RATING = 0
          this.data.CONDITION_CLEANESS_RATING = 0
          this.data.SA_EXPLAIN_RATING = 0
          this.data.PICK_UP_DROP_RATING = 0
          this.data.DEALER_FACILITY_RATING = 0
          this.data.OVERALL_EXPERIENCE_RATING = 0
        }

        if (this.data.IS_CLOSED == true) {
          this.data.PSF_CATEGORY_STATUS = "D"
        }
        //  else {
        //   this.data.PSF_CATEGORY_STATUS = "DS"
        // }



        if (this.data.REMINDER_DATE != undefined || this.data.REMINDER_TIME != undefined) {
          this.data.REMINDER_DATE = this.datePipe.transform(this.data.REMINDER_DATE, "yyyy-MM-dd")
          this.data.REMINDER_TIME = this.datePipe.transform(this.data.REMINDER_TIME, "HH:mm:00")

        }

        if (this.data.SELF_REMINDER_DATE != undefined || this.data.SELF_REMINDER_TIME != undefined) {
          this.data.SELF_REMINDER_DATE = this.datePipe.transform(this.data.SELF_REMINDER_DATE, "yyyy-MM-dd")
          this.data.SELF_REMINDER_TIME = this.datePipe.transform(this.data.SELF_REMINDER_TIME, "HH:mm:00")

        }

        if (((this.data.SELF_REMINDER_DATE != undefined) && (this.data.SELF_REMINDER_TIME == undefined))) {
          this.data.SELF_REMINDER_TIME = '10:00:00'
        }


        if (((this.data.REMINDER_DATE != undefined) && (this.data.REMINDER_TIME == undefined))) {
          this.data.REMINDER_TIME = '10:00:00'
        }



        if (this.data.CALLING_DATE != undefined || this.data.CALLING_TIME != undefined) {
          this.data.CALLING_DATE = this.datePipe.transform(this.data.CALLING_DATE, "yyyy-MM-dd")
          // this.data.CALLING_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.data.CALLING_TIME;
        }

        if (this.data.CALLING_TIME != undefined && this.roleId != 45) {
          this.data.CALLING_TIME = this.datePipe.transform(this.data.CALLING_TIME, "HH:mm:ss")
        }



        if (this.feedbackdate != undefined) {
          this.data.FOLLOW_UP_DATE = this.datePipe.transform(this.feedbackdate, "yyyy-MM-dd")
        }

        if (this.data.PSF_DATE != undefined) {
          this.data.PSF_DATE = this.datePipe.transform(this.data.PSF_DATE, "yyyy-MM-dd")
        }

        if (this.data.CALL_STATUS == 'N' && this.data.IS_REMINDER) {
          this.data.STATUS = ''
        }
        this.data.COMPLAINT = this.complaint

        // if (this.datalist.length == 0) {
        //   this.data.IS_ADDED_FIRST = 'Y'
        // } else {
        //   this.data.IS_ADDED_FIRST = 'N'
        // }

        if (this.roleId == 45) {
          this.data.SERVICE_ADMIN_ID = this.userId
          this.data.IS_CALLED_BY_SA = "Y"
        }


        // if (value == 10) {
        //   this.data.STATUS = 'D'
        // } else if (value == 9) {
        //   this.data.STATUS = 'S'
        // } else if ((value < 9) && (value > 0)) {
        //   this.data.STATUS = 'DS'
        // }
        if ((this.data.STATUS == 'D' || this.data.STATUS == 'S' || this.data.STATUS == 'DS') && (this.roleId != 45)) {
          this.data.IS_ADDED_FIRST = 'Y'
        } else {
          this.data.IS_ADDED_FIRST = 'N'
        }

        console.log(this.data.STATUS);
        console.log((this.data.STATUS == 'D' || this.data.STATUS == 'S' || this.data.STATUS == 'DS') && (this.roleId != 45));



        this.data.RO_NUMBER = this.RO_NUMBER
        this.data.SERVICE_ADVISER_ID = this.SERVICE_ADVISER_ID

        console
        if (this.roleId == 45) {
          console.log(this.data24);
          if (this.data24.ID) {
            console.log('ok1234');
            this.data24.ROOT = this.data.ROOT
            this.data24.SUB_ROOT = this.data.SUB_ROOT
            this.data24.COMPLAINT_TYPE = this.data.COMPLAINT_TYPE
            this.data24.ACTION_REMARK = this.data.ACTION_REMARK
            this.data24.FOLLOWUP_REMARK = this.data.FOLLOWUP_REMARK
            this.data24.FINAL_REMARK = this.data.FINAL_REMARK
            this.data24.UPLOADED_REMARK = this.data.UPLOADED_REMARK
            this.data24.RATE_US_REMARK = this.data.RATE_US_REMARK

            this.data24.FAIRNESS_REMARK = this.data.FAIRNESS_REMARK
            this.data24.TECH_ROOT_REMARK = this.data.TECH_ROOT_REMARK
            this.data24.TECHNICIAN_ID = this.data.TECHNICIAN_ID
            this.data24.FI_ID = this.data.FI_ID
            this.data24.COMPLAINT_REMARK = this.data.COMPLAINT_REMARK



            this.data24.IS_CLOSED = this.data.IS_CLOSED
            this.data24.IS_HQ_CONCERN = this.data.IS_HQ_CONCERN







            //  this.data24 = this.data 
            this.api.updatPSFActivityleadmaster(this.data24).subscribe(successCode => {
              if (successCode['code'] == 200) {

                this.message.success("Information Saved Successfully", "");
                this.SERVICE_ADVISER_ID = null
                this.CCEREMARK = null
                this.complaint = null
                this.data.ACTIVITY_TYPE_ID = 2
                this.disablesavebutton = true


                this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + this.RO_NUMBER + "'" + " AND IS_ADDED_FIRST = 'Y'").subscribe(data => {
                  if (data['code'] == 200 && data['count'] > 0) {
                    this.data = data['data'][0];
                    this.data.CALLING_TIME = new Date()
                    this.data.CALLING_DATE = new Date()
                    console.log(this.data);

                    this.data.ACTIVITY_TYPE_ID = 2
                    this.data.PREVEOUS_WORK_TYPE = this.data.PREVEOUS_WORK_TYPE
                    this.feedbackdate = this.data.FOLLOWUP_DATE
                    this.customername = this.data.CUSTOMER_NAME
                    this.SERVICE_ADVISER_NAME = this.data.SERVICE_ADVISER_NAME
                    this.SERVICE_ADVISER_ID = this.data.SERVICE_ADVISER_ID
                    this.CCEREMARK = this.data.REMARK
                    this.complaint = this.data.COMPLAINT
                    this.modile_no = this.data.MOBILE_NUMBER
                    this.RO_NUMBER = this.data.RO_NUMBER
                  } else {

                    data = new PSFActivityLeadMaster()
                  }
                }, err => {
                  // console.log(err);
                });





                this.api.getAllActivityTypeMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
                  this.ActivityTypes = data['data'];
                  this.data.ACTIVITY_TYPE_ID = data['data'][0]['ID']
                  this.data.NO_OF_CALLS = 1
                }, err => {
                  this.isSpinning = false;
                });
                this.api.getAllPSFActivityleadMaster(1, this.pageSize, '', '', ' AND RO_NUMBER = ' + "'" + this.data2.RO_NUMBER + "'").subscribe(data => {
                  if (data['code'] == 200 && data['count'] > 0)
                    this.isSpinning = false;
                  this.totalRecords = data['count'];
                  this.datalist = data['data'];
                  if (this.datalist.length >= 5) {
                    this.btnLoadMoreStatus = true;

                  } else {
                    this.btnLoadMoreStatus = false;
                  }

                }, err => {
                  // console.log(err);
                });

              } else {
                this.message.error("Failed To Add Activity", "");
                this.isSpinning = false;
                this.data.CALLING_TIME = new Date()
                this.data.CALLING_DATE = new Date()
                this.drawerClose()
              }
            });
          } else {
            this.api.cretePSFActivityleadmaster(this.data).subscribe(successCode => {
              if (successCode['code'] == 200) {

                this.message.success("Information Saved Successfully", "");
                this.SERVICE_ADVISER_ID = null
                this.CCEREMARK = null
                this.complaint = null
                this.data.ACTIVITY_TYPE_ID = 2
                this.disablesavebutton = true


                this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + this.RO_NUMBER + "'" + " AND IS_ADDED_FIRST = 'Y'").subscribe(data => {
                  if (data['code'] == 200 && data['count'] > 0) {
                    this.data = data['data'][0];
                    this.data.CALLING_TIME = new Date()
                    this.data.CALLING_DATE = new Date()
                    console.log(this.data);

                    this.data.ACTIVITY_TYPE_ID = 2
                    this.data.PREVEOUS_WORK_TYPE = this.data.PREVEOUS_WORK_TYPE
                    this.feedbackdate = this.data.FOLLOWUP_DATE
                    this.customername = this.data.CUSTOMER_NAME
                    this.SERVICE_ADVISER_NAME = this.data.SERVICE_ADVISER_NAME
                    this.SERVICE_ADVISER_ID = this.data.SERVICE_ADVISER_ID
                    this.CCEREMARK = this.data.REMARK
                    this.complaint = this.data.COMPLAINT
                    this.modile_no = this.data.MOBILE_NUMBER
                    this.RO_NUMBER = this.data.RO_NUMBER
                  } else {

                    data = new PSFActivityLeadMaster()
                  }
                }, err => {
                  // console.log(err);
                });





                this.api.getAllActivityTypeMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
                  this.ActivityTypes = data['data'];
                  this.data.ACTIVITY_TYPE_ID = data['data'][0]['ID']
                  this.data.NO_OF_CALLS = 1
                }, err => {
                  this.isSpinning = false;
                });
                this.api.getAllPSFActivityleadMaster(1, this.pageSize, '', '', ' AND RO_NUMBER = ' + "'" + this.data2.RO_NUMBER + "'").subscribe(data => {
                  if (data['code'] == 200 && data['count'] > 0)
                    this.isSpinning = false;
                  this.totalRecords = data['count'];
                  this.datalist = data['data'];
                  if (this.datalist.length >= 5) {
                    this.btnLoadMoreStatus = true;

                  } else {
                    this.btnLoadMoreStatus = false;
                  }

                }, err => {
                  // console.log(err);
                });

              } else {
                this.message.error("Failed To Add Activity", "");
                this.isSpinning = false;
                this.data.CALLING_TIME = new Date()
                this.data.CALLING_DATE = new Date()
                this.drawerClose()
              }
            });
          }
        }
        if (this.roleId != 45) {
          this.api.cretePSFActivityleadmaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {

              this.message.success("Information Saved Successfully", "");
              this.SERVICE_ADVISER_ID = null
              this.CCEREMARK = null
              this.complaint = null
              this.data.ACTIVITY_TYPE_ID = 2
              this.disablesavebutton = true


              this.api.getAllPSFActivityleadMaster(1, 5, '', '', ' AND RO_NUMBER = ' + "'" + this.RO_NUMBER + "'" + " AND IS_ADDED_FIRST = 'Y'").subscribe(data => {
                if (data['code'] == 200 && data['count'] > 0) {
                  this.data = data['data'][0];
                  this.data.CALLING_TIME = new Date()
                  this.data.CALLING_DATE = new Date()
                  console.log(this.data);

                  this.data.ACTIVITY_TYPE_ID = 2
                  this.data.PREVEOUS_WORK_TYPE = this.data.PREVEOUS_WORK_TYPE
                  this.feedbackdate = this.data.FOLLOWUP_DATE
                  this.customername = this.data.CUSTOMER_NAME
                  this.SERVICE_ADVISER_NAME = this.data.SERVICE_ADVISER_NAME
                  this.SERVICE_ADVISER_ID = this.data.SERVICE_ADVISER_ID
                  this.CCEREMARK = this.data.REMARK
                  this.complaint = this.data.COMPLAINT
                  this.modile_no = this.data.MOBILE_NUMBER
                  this.RO_NUMBER = this.data.RO_NUMBER
                } else {

                  data = new PSFActivityLeadMaster()
                }
              }, err => {
                // console.log(err);
              });





              this.api.getAllActivityTypeMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
                this.ActivityTypes = data['data'];
                this.data.ACTIVITY_TYPE_ID = data['data'][0]['ID']
                this.data.NO_OF_CALLS = 1
              }, err => {
                this.isSpinning = false;
              });
              this.api.getAllPSFActivityleadMaster(1, this.pageSize, '', '', ' AND RO_NUMBER = ' + "'" + this.data2.RO_NUMBER + "'").subscribe(data => {
                if (data['code'] == 200 && data['count'] > 0)
                  this.isSpinning = false;
                this.totalRecords = data['count'];
                this.datalist = data['data'];
                if (this.datalist.length >= 5) {
                  this.btnLoadMoreStatus = true;

                } else {
                  this.btnLoadMoreStatus = false;
                }

              }, err => {
                // console.log(err);
              });

            } else {
              this.message.error("Failed To Add Activity", "");
              this.isSpinning = false;
              this.data.CALLING_TIME = new Date()
              this.data.CALLING_DATE = new Date()
              this.drawerClose()
            }
          });
        }


      } else {
        this.message.error("Please Give All Ratings...", '');
      }




    }


  }
  ActivityTypes = []
  Allactivity = []
  allactions = []
  LOCATIONs = []
  ACTIVITY_NAME
  dept() {
    this.api.getAllActivityTypeMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.ActivityTypes = data['data'];
        this.data.ACTIVITY_TYPE_ID = data['data'][0]['ID']
      }
    }, err => {
      this.isSpinning = false;
    });
  }

  location() {
    this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.LOCATIONs = data['data'];
      }
    }, err => {
      this.isSpinning = false;
    });
  }


  des() {

    this.api.getAllActivityMaster(0, 0, '', '', ' AND STATUS=1 ').subscribe(data => {
      if (data['code'] == 200) {
        this.Allactivity = data['data'];
      }
    }, err => {
      this.isSpinning = false;
    });
  }

  getallorg1() {

    this.api.getAllActionMaster(0, 0, 'ID', 'desc', ' ').subscribe(data => {
      if (data['code'] == 200) {
        this.allactions = data['data'];
      }

    }, err => {
      // console.log(err);
    });
  }
  allEmployeeList = [];
  allHPromiseEmployeeList = [];
  allCorporateManagerEmployeeList = [];
  allDirectorEmployeeList = [];
  allAccountantEmployeeList = [];

  employeeList = [];
  hPromiseEmployeeList = [];
  corporateManagerEmployeeList = [];
  directorEmployeeList = [];
  accountantEmployeeList = [];


  getEmployeeList() {
    this.allEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, "NAME", "asc", ' AND ORG_ID=' + this.orgId + ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ')').subscribe(data => {
      if (data['code'] == 200) {
        this.allEmployeeList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }


  getEmployeeList1() {
    this.allEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, "NAME", "asc", ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.allEmployeeList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getHPromiseEmployeeList() {
    this.allHPromiseEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=31)').subscribe(data => {
      if (data['code'] == 200) {
        this.allHPromiseEmployeeList = data['data'];
      }

    }, err => {
      // console.log(err);
    });
  }

  getCorporateManagerEmployeeList() {
    this.allCorporateManagerEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=32)').subscribe(data => {
      if (data['code'] == 200) {
        this.allCorporateManagerEmployeeList = data['data'];
      }

    }, err => {
      // console.log(err);
    });
  }

  getDirectorEmployeeList() {
    this.allDirectorEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=33)').subscribe(data => {
      if (data['code'] == 200) {
        this.allDirectorEmployeeList = data['data'];
      }

    }, err => {
      // console.log(err);
    });
  }

  getAccountantEmployeeList() {
    this.allAccountantEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=13)').subscribe(data => {
      if (data['code'] == 200) {
        this.allAccountantEmployeeList = data['data'];
      }

    }, err => {
      // console.log(err);
    });
  }
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()
  month = this.today;
  // allHolidayList = []
  getHolidayList() {
    this.allHolidayList = [];
    var splitmonth = this.month.split("-", -1);
    this.api.getAllHolidays(0, 0, '', 'asc', " AND MONTH(DATE) = '" + splitmonth[1] + "' AND YEAR(DATE) = '" + splitmonth[0] + "'").subscribe(data => {
      if (data['code'] == 200) {
        this.allHolidayList = data['data'];
        // console./log("this.allHolidayList", this.allHolidayList);

      }

    }, err => {
      // console.log(err);
    });
  }

  close() {
    this.drawerClose2();
    this.data = new PSFActivityLeadMaster()
    this.SERVICE_ADVISER_ID = null
    this.CCEREMARK = ''
    this.disablesavebutton = false
    this.complaint = ''
    this.data24 = new PSFActivityLeadMaster()
    // this.pageSize = 5
    // this.api.getAllPSFActivityleadMaster(0, 0, 'ID', 'desc', ' AND CUSTOMER_ID = ' + this.data.ID + ' AND EXECUTIVE_ID =' + this.cookie.get('userId')).subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.datalist = data['data']
    //     console.log(this.datalist,"datalist");

    //   }
    // }, err => {
    //   console.log(err);
    // })
    // this.resetDrawer(accountMasterPage);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  drawerClose(): void {

    var filter
    if (this.data.ID != null || this.data.ID != undefined) {
      filter = this.data.ID
    }
    else {
      filter = this.CUSTOMER_ID
    }

    this.drawerVisible = false;
    this.data = new PSFActivityLeadMaster()
    this.SERVICE_ADVISER_ID = null
    this.CCEREMARK = ''
    this.complaint = ''
    // this.api.getAllPSFActivityleadMaster(0, 0, 'ID', 'desc', '' ).subscribe(data => {
    //   if (data['code'] == 200) {

    //     this.datalist = data['data']
    //     // this.message.success("I")

    //   }
    //   else{
    //     this.message.error("Failed to get leadactivitydetaileses ","")
    //   }
    // }, err => {
    //   console.log(err);
    // })
  }

  drawerData: PSFActivityLeadMaster = new PSFActivityLeadMaster()
  ide: number
  add(): void {
    this.drawerData = new PSFActivityLeadMaster();
    this.drawerTitle = "Create Activity";

    this.ide = this.data.ID
    this.drawerVisible = true;

  }
  added2hrs
  dataList12 = []




  onLoadMoreClick() {
    // this.pageIndex++
    this.pageSize = this.pageSize + 5;
    this.getactivities(this.data2.RO_NUMBER);
  }

}
