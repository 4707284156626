import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';




@Component({
  selector: 'app-back-office-area-reort',
  templateUrl: './back-office-area-reort.component.html',
  styleUrls: ['./back-office-area-reort.component.css']
})
export class BackOfficeAreaReortComponent implements OnInit {
  formTitle = "Back office Area wise summary report";

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'BackofficeArea.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "AREA_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] = [["AREA_NAME", "Role"], ["BRANCH_NAME", "Branch"], ["TOTAL_VEHICLE_SALE", "Veh Sold"], ["RATE", "Rate"], ["SANCTION_AMOUNT", "Sanctioned Amount"], ["MEAN_AMOUNT", "Mean"], ["POINTS", "Points"], ["AMOUNT", "Final Amount"]];
  STATUS = "AL";
  BRANCH = [];
  SalesExecutive = []
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";



  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.search();
    // this.applyFilter();
    // this.getReports();
    this.getBranch();

  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }
  moduleStartDateHandle(open: boolean) {

    if (!open) {
      this.endOpen = true;
    }
  }
  dates: any = [];


  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }



  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }
  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (this.BRANCH_ID != undefined || this.BRANCH_ID > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    // this.api.supportStaffAreaWiseSummary(0, 0, this.sortKey, sort, this.filterQuery,'','','')
    //   .subscribe((data) => {
    //     console.log(data);
    //     this.loadingRecords = false;
    //     this.isFilterApplied = 'primary';
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //     this.isSpinning = false;
    //     this.filterClass = 'filter-invisible';
    //     // this.search();
    //   },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );

  }

  setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.MONTH1 = new Date();
    this.BRANCH_ID = [];
    this.dataList = [];


    this.search();
  }

  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  dataList2=[]
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    if (this.searchText != "") {
      likeQuery += this.searchText;
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.supportStaffAreaWiseSummary(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.MONTH, this.YEAR)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = true;
      this.api.supportStaffAreaWiseSummary(0,0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.MONTH, this.YEAR).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
            this.isSpinning = false;
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  // importInPDF(){}

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Role Name'] = this.dataList2[i]['AREA_NAME'];
      obj1['Branch Name'] = this.dataList2[i]['BRANCH_NAME'];
      obj1['Veh Regn done'] = this.dataList2[i]['TOTAL_VEHICLE_SALE'];
      obj1['Rate'] = this.dataList2[i]['RATE'];
      obj1['Sanctioned Amount'] = this.dataList2[i]['SANCTION_AMOUNT'];
      obj1['Mean Amount'] = this.dataList2[i]['MEAN_AMOUNT'];
      obj1['Points'] = this.dataList2[i]['POINTS'];
      obj1['Final Amount'] = this.dataList2[i]['AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Back Office Area Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }
}
