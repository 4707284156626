import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { ModelMaster } from 'src/app/Models/ModelMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ColorMaster } from "src/app/Models/ColorMaster";
import { FuelMaster } from "src/app/Models/FuelMaster";
import { TransmissionMaster } from "src/app/Models/TransmissionMaster";
import { VarientMaster } from "src/app/Models/VarientMaster";

@Component({
  selector: 'app-model-drawer',
  templateUrl: './model-drawer.component.html',
  styleUrls: ['./model-drawer.component.css']

})

export class ModelDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: ModelMaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  // ngOnInit() { 
  //   this.getAllColors();
  // }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
    this.clear();
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined && this.data.NAME != null) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Model Name", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Model Name", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Model Name", "");
    }

    if (this.data.CODE != undefined && this.data.CODE != null) {
      if (this.data.CODE.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.CODE)) {
          isOk = false;
          this.message.error("Please Enter Valid Model Code", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Model Code", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Model Code", "");
    }
     if (this.data.COLOR_ID == undefined || this.data.COLOR_ID == null || this.data.COLOR_ID.length == 0) {
      isOk = false;
      this.message.error("Please Select Valid Color(s)", "");
    }

    if (isOk) {

      if (this.data.COLOR_ID != undefined && this.data.COLOR_ID != null)
      this.data.COLOR_ID = this.data.COLOR_ID.toString();
      this.isSpinning = true;
      this.imageUpload();
      this.data.IMAGE_URL = this.uploadedAttachmentStr;
      console.log(this.data);

      if (this.data.ID) {
        this.api.updateModel(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Model Details Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Model Details Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createModel(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Model Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new ModelMaster();
              this.getSequenceNo();
            }

          } else {
            this.message.error("Model Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

    // Color Master
    allColors = [];

  getAllColors() {
    this.allColors = [];

    this.api.getAllColors(0, 0, "NAME", "asc", "").subscribe((data) => {
      if (data["code"] == 200) {
        this.allColors = data["data"];
        console.log( this.allColors," this.allColors")
      }

    }, (err) => {
      console.log(err);
    });
  }

  colors = [];

  getColors(id) {
    this.colors = [];

    if (id) {
      let colors = this.allColors.filter((obj1: ColorMaster) => {
        return obj1;
      });

      this.colors = colors;
      console.log(this.colors,"  this.colors");
    } else {
      let colors = this.allColors.filter((obj1: ColorMaster) => {
        return obj1.STATUS == true;
      });

      this.colors = colors;
     
      
    }
  }

  getSequenceNo() {
    this.api.getAllModels(1, 1, 'ID', 'desc', '').subscribe(data => {
      console.log(data);

      if (data['code'] == 200 && data['count'] > 0) {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;

      } else {
        this.data.SEQUENCE_NO = 1;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  fileURL: File = null;
  photoURL: any;
  imageSrc1: string;

  onFileSelected(event) {
    console.log(Number(parseFloat(String((event.target.files[0].size / 1024))).toFixed(0)));

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      if (Number(parseFloat(String((event.target.files[0].size / 1024))).toFixed(0)) <= 150) {
        this.fileURL = <File>event.target.files[0];
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);

          reader.onload = () => {
            this.imageSrc1 = reader.result as string;
            this.photoURL = this.imageSrc1;
          };
        }

      } else {
        this.fileURL = null;
        this.message.error("File size Must be Less than or Equal to '150 KB'", "");
      }

    } else {
      this.fileURL = null;
      this.message.error("File Type Must be JPEG/ JPG/ PNG", "");
    }
  }

  clear() {
    this.fileURL = null;
    this.photoURL = null;
  }

  clear1() {
    this.photoURL = null;
  }

  folderName = "modelImages";
  uploadedAttachmentStr: string;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (!this.data.ID) {
      if (this.fileURL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var url = "MI" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.uploadedAttachmentStr = url;

      } else {
        this.uploadedAttachmentStr = "";
      }

    } else {
      if (this.fileURL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var url = "MI" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.uploadedAttachmentStr = url;

      } else {
        if (this.photoURL)
          this.uploadedAttachmentStr = this.data.IMAGE_URL;
        else
          this.uploadedAttachmentStr = " ";
      }
    }
  }


  ngOnInit() {
    this.getAllColors();
    this.getAllfuels();
    this.getAllTransmissions();
  }

  models = [];

  getAllModelsWithActiveStatus() {
    this.models = [];

    this.api.getAllModels(0, 0, "NAME", "asc", " AND STATUS=1").subscribe((data) => {
      if (data["code"] == 200) {
        this.models = data["data"];
      }

    }, (err) => {
      console.log(err);
    });
  }

  getAllModels() {
    this.models = [];

    this.api.getAllModels(0, 0, "NAME", "asc", "").subscribe((data) => {
      if (data["code"] == 200) {
        this.models = data["data"];
      }

    }, (err) => {
      console.log(err);
    });
  }

  // Color Master
  // allColors = [];

  // getAllColors() {
  //   this.allColors = [];

  //   this.api.getAllColors(0, 0, "NAME", "asc", "").subscribe((data) => {
  //     if (data["code"] == 200) {
  //       this.allColors = data["data"];
  //     }

  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  // colors = [];

  // getColors(id) {
  //   this.colors = [];

  //   if (id) {
  //     let colors = this.allColors.filter((obj1: ColorMaster) => {
  //       return obj1;
      
  //     });

  //     console.log(colors,"colors")

  //     this.colors = colors;
  //   } else {
  //     let colors = this.allColors.filter((obj1: ColorMaster) => {
  //       return obj1.STATUS == true;
  //     });

  //     this.colors = colors;
  //   }
  // }

  // Fuel Master
  allFuels = [];

  getAllfuels() {
    this.allFuels = [];

    this.api.getAllFuel(0, 0, "NAME", "asc", "").subscribe((data) => {
      if (data["code"] == 200) {
        this.allFuels = data["data"];
      }

    }, (err) => {
      console.log(err);
    });
  }

  fuels = [];

  getFuels(id) {
    this.fuels = [];

    if (id) {
      let fuels = this.allFuels.filter((obj1: FuelMaster) => {
        return obj1;
      });

      this.fuels = fuels;

    } else {
      let fuels = this.allFuels.filter((obj1: FuelMaster) => {
        return obj1.STATUS == true;
      });

      this.fuels = fuels;
    }
  }

  // Transmissions Master
  allTransmissions = [];

  getAllTransmissions() {
    this.allTransmissions = [];

    this.api.getAllTransmission(0, 0, "NAME", "asc", "").subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.allTransmissions = data["data"];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  Transmissions = [];

  getTransmissions(id) {
    this.Transmissions = [];

    if (id) {
      let Transmissions = this.allTransmissions.filter((obj1: TransmissionMaster) => {
        return obj1;
      });

      this.Transmissions = Transmissions;
    } else {
      let Transmissions = this.allTransmissions.filter((obj1: TransmissionMaster) => {
        return obj1.STATUS == true;
      });

      this.Transmissions = Transmissions;
    }
  }

  // close(myForm: NgForm): void {
  //   this.drawerClose();
  //   this.reset(myForm);
  // }

  // reset(myForm: NgForm) {
  //   myForm.form.reset();
  // }

  // save(addNew: boolean, myForm: NgForm): void {
  //   var isOk = true;

  //   if (this.data.MODEL_ID == undefined || this.data.MODEL_ID == null) {
  //     isOk = false;
  //     this.message.error("Please Select Valid Model", "");
  //   }

  //   if (this.data.NAME != undefined && this.data.NAME != null) {
  //     if (this.data.NAME.trim() != "") {
  //       if (
  //         !this.api.checkTextBoxIsValidWithRoundBracketsDotPlus(this.data.NAME)
  //       ) {
  //         isOk = false;
  //         this.message.error("Please Enter Valid Variant Name", "");
  //       }
  //     } else {
  //       isOk = false;
  //       this.message.error("Please Enter Valid Variant Name", "");
  //     }
  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Valid Variant Name", "");
  //   }

  //   if (this.data.CODE != undefined && this.data.CODE != null) {
  //     if (this.data.CODE.trim() != "") {
  //       if (!this.api.checkTextBoxIsValidWithRoundBracketsDotPlus(this.data.CODE)) {
  //         isOk = false;
  //         this.message.error("Please Enter Valid Model Code", "");
  //       }

  //     } else {
  //       isOk = false;
  //       this.message.error("Please Enter Valid Model Code", "");
  //     }

  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Valid Model Code", "");
  //   }

  //   if (this.data.FUEL_TYPE == undefined || this.data.FUEL_TYPE == null || this.data.FUEL_TYPE.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Valid Fuels Type", "");
  //   }

  //   if (this.data.TRANSMISSION == undefined || this.data.TRANSMISSION == null || this.data.TRANSMISSION.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Valid Transmission Type", "");
  //   }

  //   if (this.data.CC != undefined) {
  //     if (this.data.CC.trim() == "") {
  //       isOk = false;
  //       this.message.error("Please Enter Valid CC", "");
  //     }

  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Valid CC", "");
  //   }

  //   if (this.data.COLOR_ID == undefined || this.data.COLOR_ID == null || this.data.COLOR_ID.length == 0) {
  //     isOk = false;
  //     this.message.error("Please Select Valid Color(s)", "");
  //   }

  //   if (isOk) {
  //     this.isSpinning = true;

  //     if (this.data.COLOR_ID != undefined && this.data.COLOR_ID != null)
  //       this.data.COLOR_ID = this.data.COLOR_ID.toString();

  //     if (this.data.FUEL_TYPE != undefined && this.data.FUEL_TYPE != null)
  //       this.data.FUEL_TYPE = this.data.FUEL_TYPE.toString();

  //     if (this.data.TRANSMISSION != undefined && this.data.TRANSMISSION != null)
  //       this.data.TRANSMISSION = this.data.TRANSMISSION.toString();

  //     if (this.data.ID) {
  //       this.api.updateVarient(this.data).subscribe((successCode) => {
  //         if (successCode["code"] == 200) {
  //           this.message.success("Varient Details Updated Successfully", "");
  //           this.isSpinning = false;

  //           if (!addNew)
  //             this.close(myForm);

  //         } else {
  //           this.message.error("Varient Details Updation Failed", "");
  //           this.isSpinning = false;
  //         }
  //       });

  //     } else {
  //       this.api.createVarientWithPriceList(this.data).subscribe((successCode) => {
  //         if (successCode["code"] == 200) {
  //           this.message.success("Varient Created Successfully", "");
  //           this.isSpinning = false;

  //           if (!addNew)
  //             this.close(myForm);

  //           else {
  //             this.data = new VarientMaster();
  //             this.getSequenceNo();
  //           }

  //         } else {
  //           this.message.error("Varient Creation Failed", "");
  //           this.isSpinning = false;
  //         }
  //       });
  //     }
  //   }
  // }

  // getSequenceNo() {
  //   this.api.getAllVarients(1, 1, "ID", "desc", "").subscribe((data) => {
  //     if (data["code"] == 200 && data["count"] > 0) {
  //       this.data.SEQUENCE_NO = Number(data["data"][0]["SEQUENCE_NO"]) + 1;

  //     } else {
  //       this.data.SEQUENCE_NO = 1;
  //     }

  //   }, (err) => {
  //     if (err["ok"] == false) this.message.error("Server Not Found", "");
  //   });
  // }

  // numberOnly(event: any) {
  //   const charCode = event.which ? event.which : event.keyCode;

  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }

  //   return true;
  // }
}
