import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-sales-executive-wise-summary-report",
  templateUrl: "./sales-executive-wise-summary-report.component.html",
  styleUrls: ["./sales-executive-wise-summary-report.component.css"],
})
export class SalesExecutiveWiseSummaryReportComponent implements OnInit {
  formTitle = " Sales Executive Wise Summary Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'SalesExecutiveWiseSummaryReport.xlsx';
  searchText: string = "";

  orgId = this.cookie.get("orgId");
FROM_DATE
TO_DATE
  BRANCH_ID = [];
  SALES_EXECUTIVE_ID = [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService
  ) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getBranches();
    this.getSalesExecutives();
    this.getSalesExecutiveWiseSummaryReport();
  }

  SELECT_ALL1: boolean = false;
  onSelectAllChecked1(event) {
    this.SELECT_ALL1 = event;
    let ids = [];
    if (this.SELECT_ALL1 == true) {
      for (var i = 0; i < this.branches.length; i++) {
        ids.push(this.branches[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids;
  }

  onSelectOff1(event) {
    var a = this.branches.length;
    var b = this.branches.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL1 = false;
    } else {
      this.SELECT_ALL1 = true;
    }
    this.BRANCH_ID = event;
    if (this.BRANCH_ID.length == 0) {
      this.SELECT_ALL1 = false;
    }
  }

  SELECT_ALL2: boolean = false;
  onSelectAllChecked2(event) {
    this.SELECT_ALL2 = event;
    let ids = [];
    if (this.SELECT_ALL2 == true) {
      for (var i = 0; i < this.salesExecutives.length; i++) {
        ids.push(this.salesExecutives[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff2(event) {
    var a = this.salesExecutives.length;
    var b = this.salesExecutives.length - event.length;
    if ((a! = b)) {
      this.SELECT_ALL2 = false;
    } else {
      this.SELECT_ALL2 = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL2 = false;
    }
  }

  exportexcel(): void {
    this.loadingRecords = true;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sales Executive Summary Report');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }, 1000);
  }

  branches = [];

  getBranches() {
    this.branches = [];

    this.api
      .getAllBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  salesExecutives = [];

  getSalesExecutives() {
    this.salesExecutives = [];

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" +
        this.orgId +
        " AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=" +
        this.cookie.get("orgId") +
        " AND ROLE_ID=28)"
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.salesExecutives = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.FROM_DATE = currentDate;
    this.TO_DATE = currentDate;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    if (this.FROM_DATE.length > 0 && this.TO_DATE.length > 0) this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";
    this.getSalesExecutiveWiseSummaryReport(true);
  }

  clearFilter() {
    this.SELECT_ALL1 = false;
    this.SELECT_ALL2 = false;

    this.FROM_DATE=new Date()
    this.TO_DATE=new Date()
    this.BRANCH_ID = [];
    this.SALES_EXECUTIVE_ID = [];
    this.setDateToDateFilter();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.getSalesExecutiveWiseSummaryReport(true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.getSalesExecutiveWiseSummaryReport(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.SalesExecutiveWiseSummaryReportSortKey = sort.key;
    this.SalesExecutiveWiseSummaryReportSortValue = sort.value;
    this.getSalesExecutiveWiseSummaryReport(true);
  }

  SalesExecutiveWiseSummaryReportPageIndex = 1;
  SalesExecutiveWiseSummaryReportPageSize = 10;
  SalesExecutiveWiseSummaryReportTotalRecords = 1;
  SalesExecutiveWiseSummaryReportSortValue: string = "desc";
  SalesExecutiveWiseSummaryReportSortKey: string = "SALES_EXECUTIVE_ID";
  SalesExecutiveWiseSummaryReportData = [];

  columns: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],

    ["QUOTATIONS", "Total Quotation"],
    ["MEMO_COUNT", "No. of memo converted"],
    ["INVOICE_COUNT", "No. of Invoice generated"],
  ];

  columns1: string[][] = [
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["BRANCH_NAME", "Branch Name"],
  ];

  filterQuery: string = '';

  exportLoading: boolean = false;
  importInExcel() {

    this.getSalesExecutiveWiseSummaryReport(true, true);
  }
  SalesExecutiveWiseSummary = []

  
  
  getSalesExecutiveWiseSummaryReport(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.SalesExecutiveWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.SalesExecutiveWiseSummaryReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.FROM_DATE != undefined && this.FROM_DATE.length != 0 && this.TO_DATE != undefined && this.TO_DATE.length != 0) {
      this.FROM_DATE = this.datePipe.transform(this.FROM_DATE, "yyyy-MM-dd");
      this.TO_DATE = this.datePipe.transform(this.TO_DATE, "yyyy-MM-dd");
    }

    var branchFilter = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      branchFilter = this.BRANCH_ID.toString();
    }

    var salesExecutiveFilter = "";
    if (this.SALES_EXECUTIVE_ID != undefined && this.SALES_EXECUTIVE_ID.length != 0) {
      salesExecutiveFilter = this.SALES_EXECUTIVE_ID.toString();
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api
        .getSalesExecutiveWiseSummaryReport(
          this.SalesExecutiveWiseSummaryReportPageIndex,
          this.SalesExecutiveWiseSummaryReportPageSize,
          this.SalesExecutiveWiseSummaryReportSortKey, sort, this.filterQuery + likeQuery, this.api.orgId, branchFilter, this.FROM_DATE, this.TO_DATE, salesExecutiveFilter)
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.loadingRecords = false;
              this.SalesExecutiveWiseSummaryReportTotalRecords = data["count"];
              this.SalesExecutiveWiseSummaryReportData = data["data"];
            }
          },
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        );
    }
    else {
      this.exportLoading = true;
      this.api.getSalesExecutiveWiseSummaryReport(0, 0, this.SalesExecutiveWiseSummaryReportSortKey,
        sort,
        this.filterQuery + likeQuery,
        this.api.orgId,
        branchFilter,
        this.FROM_DATE,
        this.TO_DATE,
        salesExecutiveFilter).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.SalesExecutiveWiseSummary = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.SalesExecutiveWiseSummary.length; i++) {
      obj1['Sales Executive Name'] = this.SalesExecutiveWiseSummary[i]['SALES_EXECUTIVE_NAME'];
      obj1['Branch Name'] = this.SalesExecutiveWiseSummary[i]['BRANCH_NAME'];
      // obj1['Sales Executive Designation Name'] = this.SalesExecutiveWiseSummary[i]['SALES_EXECUTIVE_DESIGNATION_NAME'];
      obj1['Total Quotation'] = this.SalesExecutiveWiseSummary[i]['QUOTATIONS'];
      obj1['No. of memo converted'] = this.SalesExecutiveWiseSummary[i]['MEMO_COUNT'];
      obj1['No. of Invoice generated'] = this.SalesExecutiveWiseSummary[i]['INVOICE_COUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.SalesExecutiveWiseSummary.length - 1) {
        this._exportService.exportExcel(arry1, 'Sales Executive Wise Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
