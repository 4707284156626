import { Component, Input, OnInit, Output } from '@angular/core';
import { RoleServiceDeptMap } from 'src/app/Models/RoleServiceDeptMap';
import { ServiceSubDepartmentmaster } from 'src/app/Models/servicesubdepartmentmaster';
import { ApiService } from 'src/app/Service/api.service';


@Component({
  selector: 'app-serviceslubdepartlist',
  templateUrl: './serviceslubdepartlist.component.html',
  styleUrls: ['./serviceslubdepartlist.component.css']
})
export class ServiceslubdepartlistComponent implements OnInit {

  @Input()
  close: Function;
  mapdrawerVisible!: boolean;
  mapdrawerTitle!: string;
  servicesub!: number;

  formTitle = "Service Sub Department ";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["SEQUENCE_NO", "Sequence No."]]
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: ServiceSubDepartmentmaster = new ServiceSubDepartmentmaster();
  drawerData1: RoleServiceDeptMap = new RoleServiceDeptMap();



  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search(true);
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getserviceSubDepartmentMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
 
  

  get mapCloseCallback() {
    return this.mapDrawerClose.bind(this);
  }

  
  
  map(data : any): void {
    this.servicesub = data.ID
    this.mapdrawerTitle = ' Service Department Mapping';
    
    this.drawerData1 = new RoleServiceDeptMap();
    this.mapdrawerVisible = true;

  }

  add(): void {
    this.drawerData = new ServiceSubDepartmentmaster();

    this.api.getserviceSubDepartmentMaster(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle = "Create New Service Sub Depertment";
    this.drawerData.STATUS = true;
    this.drawerVisible = true;
  }

  edit(data: ServiceSubDepartmentmaster): void {
    this.drawerTitle = "Update Service Sub Depertment";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    // this.close();
    // console.log(this.close(),"this.close();");
    
    this.drawerVisible = false;
  }
  mapDrawerClose(): void {
    // this.search();
    this.mapdrawerVisible = false;
  }
}

