export class INSURANCECOLLAPSEMASTER {

    ID:number=0;
   
    // NAME:string='';
    // CAST_NAMES: any = []
    // DESCRIPTION:string='';
    // DRAMA_IMAGE:string=''; 
    // STATUS:boolean=true;
    // SEQUENCE_NUMBER:number=0 ;
    // FROM_DATE:any;
    // TO_DATE:any;
    // CITY_ID:number=0;
    // CITY_NAME:string='';
    


    //Basic Info
    INSURED_NAME:any;
    SEQUENCE_NUMBER:any;


    //Customer info
    // INSURED_NAME:any
    ADDRESS:any;
    MOBILE_COUNTRY_CODE:any;
    MOBILE_NUMBER:any;
    LANDLINE_NUMBER:any;
    EMAIL_ID:any;
    REGISTRATION_NAME:any;
    CONTACT_NUMBER1:any;
    CONTACT_NUMBER2:any;
    CONTACT_NUMBER3:any;



//service advisor info
DEALER_ID:any;
DEALER:any;
LOCATION:any;
EXECUTIVE_NAME:any;
REMINDER_DATE:any;
STATUS:any;
REMARK_DATE:any;
RENEWAL_NOTICE_SEND:any;
COURIER_STATUS:any;
MAIN_DEALER_CODE:any;
INVOICE_DATE:any;
DELIVERY_DATE:any;
INVOICE_NUMBER:any;



//insurance Info
PREV_POLICY_EXPIRY_DATE:any;
NUMBER_OF_CLAIMS:any;
TOTAL_IDV:any;
RENEWAL_VEHICLE_IDV:any;



//Insurance Calculation Info 
ELEC_ACC_IDV:any;
NONELEC_ACC_IDV:any;
RENEWAL_CNGLPG_KIT_IDV:any;
BASIC_PREMIUM_VEHICLE:any;
BASIC_PREMIUM_ELEC_ACCES:any;
BASIC_PREMIUM_NONELEC_ACCES:any;
BASIC_PREMIUM_CNGLPG:any;
SUB_TOTAL:any;
NCB_AMOUNT:any;
DISCOUNT:any;
TOTAL_OWN_DAMAGE_PREM:any;
NET_OWN_DAMAGE_PREM_A:any;
NET_LIABILITY_PREM_B:any;
TOTAL_PREM_AB:any;
SERVICE_TAX:any;
GROSS_PREMIUM:any;
MANUFACTURING_YEAR:any;

//Unknown info

ADD_ON:any;
STD_CODE:any;
STR_COVER_TYPE:any;
STR_COVER_TYPE_DATA:any;
ADD_ON_COVER_PREM:any;
ADD_ON_NAME:any;
DATA_TYPE:any;




}