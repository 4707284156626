import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityTypeMaster } from 'src/app/Models/ActivityType';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-activity-typeform',
  templateUrl: './activity-typeform.component.html',
  styleUrls: ['./activity-typeform.component.css']
})
export class ActivityTypeformComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: ActivityTypeMaster = new ActivityTypeMaster();
  orgId = this.cookie.get('orgId')
  isSpinning = false
  @Input() listOfData: ActivityTypeMaster[] = [];
  OPEN_TIME
  CLOSE_TIME
  listdata2 = []
  listdata1 = []
  acctype = []
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  @Input() DAYS = false;
  isOk = true;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  regexp = /^\S/
  namepatte = /^[A-Za-z ]*$/
  spatt = /^[a-zA-Z0-9 ]+$/

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.getallorg1();
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    // this.add();
  }

  // add(): void {
  //   this.api.getAllActivityMaster(1, 1, 'SEQUENCE_NO', 'desc', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
  //     if (data['count'] == 0) {
  //       this.data.SEQUENCE_NO = 1;

  //     } else {
  //       this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
  //       this.data.STATUS = true;
  //     }
  //   }, err => {
  //     console.log(err);
  //   })
  // }

  designation() {
    this.api.getAllActivityMaster(this.pageIndex, this.pageSize, this.sortKey, '', ' and STATUS=1 ').subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  getallorg1() {
    this.api.getAllActivityMaster(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1);
      }

    }, err => {
      console.log(err);
    });
  }



  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;

    this.isOk = true;

    if (

      this.data.ACTIVITY_TYPE_NAME.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All Required Information', '');



    } else if (this.data.ACTIVITY_TYPE_NAME == undefined || this.data.ACTIVITY_TYPE_NAME.trim() == '') {
      this.isOk = false;
      this.message.error("Please Enter Name", '');

    } else if (!this.namepatte.test(this.data.ACTIVITY_TYPE_NAME.toString())) {
      this.isOk = false;
      this.message.error("Please Enter Valid Name", '');
    }
    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Sequence No.', "")


    } else if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        console.log(this.data)
        this.api.updateActivityMaster(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Form information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update form information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {
        console.log(this.data)
        this.api.createActivityMaster(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Form information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {

                this.data = new ActivityTypeMaster();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add form information...", "");
              this.isSpinning = false;
            }
          });
      }
    }
  }
}
