import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { setHours, differenceInCalendarDays } from 'date-fns'
import { activityMaster } from 'src/app/Models/activityMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-activity-master-table',
  templateUrl: './activity-master-table.component.html',
  styleUrls: ['./activity-master-table.component.css']
})
export class ActivityMasterTableComponent implements OnInit {

  constructor(private datepipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  columns: string[][] = [["ACTIVITY_TYPE_NAME", "Activity Type"],
  ["ACTIVITY_NAME", "Activity Name"],]

  formTitle = "Activity Master";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isSpinning = false;
  loadingRecords = false;

  isVisible3 = false;

  orgID = Number(sessionStorage.getItem('orgId'))
  drawerTitle: string | undefined;
  drawerTitle1: string | undefined;

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;


  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  dataList: any[] = [];
  listOfData2: any = [];

  user: activityMaster = new activityMaster();

  drawerData = [];

  drawerClose() {
    this.isVisible = false;
    this.getData();
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    // console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    // console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }


    this.api.getActivityMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      // console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.isSpinning = false;
      this.filterClass = 'filter-invisible';

    }, err => {
      // console.log(err);
    });
  }

  getData() {
    this.api.getActivityMaster(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '').subscribe((data: any) => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.totalRecords = data['count'];
      // console.log(this.dataList, 'getFunction');
      // console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE, 'getClubbed');


    }, err => {
      // console.log(err);
    });
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND DATE(CREATED_MODIFIED_DATE) BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    // if (this.data.ALLOTMENT_TYPE_ID != null) {
    //   this.filterQuery =
    //     this.filterQuery + ' AND ALLOTMENT_TYPE_ID=' + "" + this.data.ALLOTMENT_TYPE_ID + "";
    // }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    // this.api
    //   .getActionMaster(0, 0, this.sortKey, sort, this.filterQuery)
    //   .subscribe(
    //     (data) => {
    //       console.log(data);
    //       this.loadingRecords = false;
    //       this.isFilterApplied = 'primary';
    //       this.totalRecords = data['count'];
    //       this.dataList = data['data'];
    //       this.isSpinning = false;
    //       this.filterClass = 'filter-invisible';
    //       // this.search();
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );

  }



  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date();
    this.endValue = new Date();

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }
  ngOnInit() {
    this.search();
    // this.getData();

  }

  add() {

    this.drawerTitle = "Create Activity";
    this.user = new activityMaster();

    this.api.getActivityMaster(0, 0, 'SEQUENCE_NO', 'desc', '').subscribe((data) => {
      if (data['count'] == 0) {
        this.user.SEQUENCE_NO = 1;
      } else {
        this.user.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
    }, (err) => {
      console.log(err);
    });
    this.isVisible = true;

  }

  activity: any = [];

  edit(data: activityMaster) {
    this.isVisible = true;
    this.drawerTitle = 'Update Activity';
    this.user = Object.assign({}, data);

    this.api.getActivityMaster(0, 0, '', '', '').subscribe(data => {
      this.activity = data['data'];

    }, err => {
      console.log(err);
    });

    this.user.ACTIVITY_TYPE_NAME = this.user.ACTIVITY_TYPE_NAME;

  }

}
