import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { tourClassMaster } from 'src/app/Models/tourClass';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-tour-class-form',
  templateUrl: './tour-class-form.component.html',
  styleUrls: ['./tour-class-form.component.css']
})
export class TourClassFormComponent implements OnInit {

  @Input() drawerClose!: Function;
  @Input() data: tourClassMaster = new tourClassMaster();
  @Input() drawerVisible: boolean = false;

  isSpinning = false;

  @Input() listOfData: any = [];
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getData()
    this.getMode()
  }
  dataList: any = []

  getData() {
    this.api.getTourClass(0, 0, '', '', '').subscribe((data) => {
      this.dataList = data['data'];
      console.log('this.dataList', this.dataList);
    })
  }

  mode: any = []
  getMode() {
    this.api.getTourMode(0, 0, '', '', ' AND IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.mode = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {

    this.isOk = true;

    if (this.data.CLASS_NAME == undefined && this.data.MODE_ID == undefined) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");
    }
    else if (this.data.CLASS_NAME == "" || this.data.CLASS_NAME == undefined) {
      this.isOk = false
      this.message.error("Please Enter Class Name", "");
    }

    else if(this.data.MODE_ID == undefined || this.data.MODE_ID == null) {
      this.isOk = false
      this.message.error("Please Select Mode Name", "");

    }

    if (this.isOk) {

      this.isSpinning = true;

      if (this.data.ID) {

        this.isSpinning = false;

        var bundleData = this.listOfData.filter((obj: any) => {
          return obj.CLASS_NAME == this.data.CLASS_NAME;
        });

        if (bundleData.length == 0) {
          this.api.updateTourClass(this.data).subscribe((successCode: any) => {
            if (successCode['code'] == 200) {
              this.getData();
              this.message.success("Tour Class Updated successfully...", "");
              console.log(this.data, 'Update Success')

              if (!addNew) {
                this.drawerClose();
                this.reset(myForm);
                this.isSpinning = false;
              }
              else {
                this.data = new tourClassMaster();
                this.message.error("Failed To Update Tour Class", "");
                console.log(this.data, "fail Update")

                this.isSpinning = false;
              }
              // console.log(this.data.APPLICABLE_TYPES);
            }
          });
        }
        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }
      else {
        this.isSpinning = true;

        var bundleData = this.dataList.filter(obj => {
          return obj.CLASS_NAME == this.data.CLASS_NAME;
        });

        if (bundleData.length == 0) {
          console.log(this.dataList, 'Code')

          this.api.createTourClass(this.data).subscribe(successCode => {

            if (successCode['code'] == 200) {
              this.message.success("Tour Class added successfully...", "");
              console.log(this.data, "create Success");
              this.getData();
              if (!addNew) {
                // this.isSpinning = true;
                this.drawerClose();
              }
              else {
                this.data = new tourClassMaster();
                myForm.resetForm();

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add Tour Class...", "");
              console.log(this.data, "Fail data")
              this.isSpinning = false;
            }
          });
        }
        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }
    }
  }

}
