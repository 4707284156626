export class slabMaster {
    ID: any;
    STRUCUTURE_NAME: any;
    FROM_DATE: any;
    TO_DATE: any
    STATUS: boolean;
    BRANCH_IDS: any;
    CLIENT_ID: any;
    SLAB: any;
    ROLE_IDS:any
}