import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-branch-wise-discount-pendancy-report',
  templateUrl: './branch-wise-discount-pendancy-report.component.html',
  styleUrls: ['./branch-wise-discount-pendancy-report.component.css']
})
export class BranchWiseDiscountPendancyReportComponent implements OnInit {

  formTitle = "Branch Wise Discount Pendancy Report";
  filterClass: string = "filter-visible";
  isFilterApplied: string = "default";
  START_DATE: any = [];
  END_DATE: any = [];
  exportLoading: boolean;
  orgId = this.cookie.get("orgId");

  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];


  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false

  columns: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
   
  ];
  startValue: string;
  endValue: string;


  constructor(private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe,
    private _exportService: ExportService) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.search(false);

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }


  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }
    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    this.search(true);
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = new Date()
    this.END_DATE = new Date()
    this.setDateToDateFilter();
    this.search()
  }

  toDateFilter: string = ''
  fromDateFilter: string = ''
  search(reset: boolean = false, ExportToExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    var sort: string;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    // console.log("search text : " + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.startValue != null) {
      this.START_DATE = this.startValue
    }
    if (this.endValue != null) {
      this.END_DATE = this.endValue
    }
    this.START_DATE = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.END_DATE, 'yyyy-MM-dd')

    if (ExportToExcel == false) {
      this.loadingRecords = true;
      this.api.getBranchWiseDiscountPendancy(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        // console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getBranchWiseDiscountPendancy(0, 0, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  importInExcel() {
    this.search(true, true);
  }



  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList.length; i++) {
      obj1['Branch Name'] = this.dataList[i]['BRANCH_NAME'];
      obj1['Count Pending Memo'] = this.dataList[i]['PENDING_DISCOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Branch Wise Discount Pendancy Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
