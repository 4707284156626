import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { memoMaster } from 'src/app/Models/memo';
import { memoDetails } from 'src/app/Models/memoDetails';
import { memoSignatureDetails } from 'src/app/Models/memoSignatureDetails';
import { questionaryMaster } from 'src/app/Models/questionaryMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-detailed-new-report',
  templateUrl: './detailed-new-report.component.html',
  styleUrls: ['./detailed-new-report.component.css']
})
export class DetailedNewReportComponent implements OnInit {
  formTitle = "Detailed New Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords: boolean = false;
  sortValue: string = "desc";
  sortKey: string = "SALES_EXECUTIVE_ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [
    ["MEMO_DATE", "Memo Date"],
    ["QUOTATION_NO", "Quotation Number"],
    ["MEMO_NO", "Memo No."],
    ["INVOICE_DATE", "Invoice Date"],
    ["INVOICE_NO", "Invoice No."],
    ["SALES_EXECUTIVE_NAME", "Sales Executive Name"],
    ["CUSTOMER_NAME", "Customer Name"],
    ["CUSTOMER_MOBILE_NO", "Mobile No."],
    ["ADDRESS", "Address"],
    ["CGST_NO", "CGST No."],
    ["PAN_NUMBER", "PAN No."],
    ["AADHAR_NUMBER", "Adhhar No."],
    ["BRANCH_NAME", "Branch Name"],
    ["MODEL_NAME", "Model Name"],
    ["FUEL_NAME", "Fuel Name"],
    ["TRANSMISSION_TYPE", "Transmission"],
    ["COLOR", "Color"],
    ["VARIENT_NAME", "Varient Name"],
    ["VIN_NUMBER", "VIN No."],
    ["PAYMENT_TYPE", "Payment Type"],
    ["INHOUSE_FINANCE_NAME", "Inhouse Finance Name"],
    ["OUTHOUSE_FINANCE_NAME", "Outhouse Finance Name"],
    ["INHOUSE_INSURANCE_NAME", "Inhouse Insurance Name"],
    ["OUTHOUSE_INSURANCE_NAME", "Outhouse Insurance Name"],
    ["INVOICE_AMOUNT", "Invoice Amount"],
    ["FASTAG", "Fastag"],
    ["RTO", "RTO"],
    ["INSURANCE", "Insurance"],
    ["EW", "EW"],
    ["ACCESSORIES", "Accessories"],
    ["TCS_1", "TCS1"],
    ["TCS_5", "TCS5"],
    ["SOT", "SOT"],
    ["HMIL_DISCOUNT", "HMIL Discount"],
    ["EXCHANGE_DISCOUNT", "Exchange Discount"],
    ["CORPORATE_DISCOUNT", "Corporate Discount"],
    ["SAMBHADH_SCHEME_DISCOUNT", "Sambandh Scheme Discount"],
    ["ACCESSORIES_DISCOUNT", "Accessories Discount"],
    ["RTO_DISCOUNT", "RTO Discount"],
    ["INSURANCE_DISCOUNT", "Insurance Discount"],
    ["SOT_DISCOUNT", "SOT Discount"],
    ["EXTENDED_WARRANTY", "Extented Warranty"],
    ["ADDITIONAL_DISCOUNT", "Additional Discount"],
    ["TOTAL_DISCOUNT", "Total Discount"],
    ["OVERFLOW", "Excess Amount"],
    ["SHORTFALL", "ShortFall"],
    ["COMPANY_NAME", "Company Name"],
    ["APPROVER_NAME", "Approver Name"],
    ["INSURANCE_PERCENTAGE", "Insurance %"],
    ["IS_RSA_AVAILABLE", "Is RSA Available"],
    ["TOTAL", "Total"],]

  STATUS = "AL";
  BRANCH = [];
  SalesExecutive = []
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";

  isButtonSpinning: boolean = false;
  START_DATE: any = new Date();
  END_DATE: any = new Date();
  endOpen = false;

  employees = [];

  BRANCH_ID: any = []
  SALES_EXECUTIVE_ID: any = []

  dataList: any = []

  isVisible1: boolean = false
  drawerTitle1: string = ''
  ClusterId = Number(this.cookie.get("clusterId"));

  drawerVisible: boolean;
  drawerTitle: string;
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  visible = false;
  modalVisible = false
  visible1 = false
  questionary = new questionaryMaster();
  quotationId: any
  saleHierarchy = []
  APPROVER_ID: number = 0;
  memoSignature: memoSignatureDetails = new memoSignatureDetails()
  userid = this.cookie.get('userId')
  // memo: createMemo = new createMemo()
  acc_remark1
  memo12
  iid
  recieptData = []
  memoGet: memoMaster = new memoMaster()
  memoDetailsArray = [new memoDetails()]
  array = []
  COMPANY_NAME = ""
  SAMBHADH_SCHEME = 0

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.START_DATE = new Date();
    this.END_DATE = new Date();
    this.getBranch();

  }

  branches: any = []

  getBranch() {
    var filter = ''

    if (this.ClusterId == 0) {
      filter = ''
    }
    else {
      filter = 'AND CLUSTER_ID = ' + this.ClusterId
    }
    this.api.getAllBranch(0, 0, '', 'asc', filter).subscribe(data => {
      this.branches = data['data']
      if (this.branches.length > 0) {
        this.BRANCH_ID = data['data'][0]['ID']
        console.log(this.BRANCH_ID);
        this.getEmployee()
      }
      else {
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  SELECT_ALL: boolean = true;

  onSelectAllChecked(event: any) {
    this.SELECT_ALL = event;
    let ids = [];
    if (this.SELECT_ALL == true) {
      for (var i = 0; i < this.sales.length; i++) {
        ids.push(this.sales[i]["EMPLOYEE_ID"]);
      }
    } else {
      ids = [];
    }
    this.SALES_EXECUTIVE_ID = ids;
  }

  onSelectOff(event: any) {
    var a = this.sales.length;
    var b = this.sales.length - event.length;
    if (a! = b) {
      this.SELECT_ALL = false;
    } else {
      this.SELECT_ALL = true;
    }
    this.SALES_EXECUTIVE_ID = event;
    if (this.SALES_EXECUTIVE_ID.length == 0) {
      this.SELECT_ALL = false;
    }
  }

  sales: any = [];
  getEmployee() {
    this.filterClass = 'filter-invisible';

    var filter = ''
    if (this.ClusterId == 0) {
      filter = ''
    }
    else {
      filter = ' AND CLUSTER_ID = ' + this.ClusterId
    }

    this.api.getAllEmpRoleMap(0, 0, '', this.sortValue, ' AND ROLE_ID = 28 AND BRANCH_ID = ' + this.BRANCH_ID + filter).subscribe(data => {
      this.sales = data['data']
      let ids1 = [];
      if (this.SELECT_ALL == true) {
        for (var i = 0; i < this.sales.length; i++) {
          ids1.push(this.sales[i]["EMPLOYEE_ID"]);
        }
      } else {
        ids1 = [];
      }
      this.SALES_EXECUTIVE_ID = ids1;

      this.search(false)

    },
      (err) => {
        console.log(err);
      }
    );
  }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  dataExcel: any = []
  exportLoading: boolean = false;

  importInExcel() {
    this.search(false, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.START_DATE = this.datePipe.transform(this.START_DATE, 'yyyy-MM-dd')
    this.END_DATE = this.datePipe.transform(this.END_DATE, 'yyyy-MM-dd')

    if (this.START_DATE != null) {
      this.START_DATE = this.START_DATE
    }

    if (this.END_DATE != null) {
      this.END_DATE = this.END_DATE
    }

    if (this.BRANCH_ID != undefined || this.BRANCH_ID != null) {
      this.BRANCH_ID = this.BRANCH_ID;
    }

    if (this.SALES_EXECUTIVE_ID != undefined || this.SALES_EXECUTIVE_ID != null || this.SALES_EXECUTIVE_ID != "") {
      this.SALES_EXECUTIVE_ID = this.SALES_EXECUTIVE_ID;
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getDetailedAlliedServices(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.BRANCH_ID, this.SALES_EXECUTIVE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = 'filter-invisible';
        }
      }, err => {
        console.log(err);
      });
    }
    else {
      this.exportLoading = true;
      this.api.getDetailedAlliedServices(0, 0, this.sortKey, sort, likeQuery, this.START_DATE, this.END_DATE, this.BRANCH_ID, this.SALES_EXECUTIVE_ID).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataExcel = data['data'];
          this.convertInExcel();
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
    }
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataExcel.length; i++) {
      obj1['Memo Date'] = this.datePipe.transform(this.dataExcel[i]['MEMO_DATE'], "yyyy-MM-dd");
      obj1['Quotation No.'] = this.dataExcel[i]['QUOTATION_NO'];
      obj1['Memo No.'] = this.dataExcel[i]['MEMO_NO'];
      obj1['Invoice Date'] = this.datePipe.transform(this.dataExcel[i]['INVOICE_DATE'], "yyyy-MM-dd");
      obj1['Invoice No.'] = this.dataExcel[i]['INVOICE_NO'];
      obj1['Sales Executive Name'] = this.dataExcel[i]['SALES_EXECUTIVE_NAME'];
      obj1['Customer Name'] = this.dataExcel[i]['CUSTOMER_NAME'];
      obj1['Mobile No.'] = this.dataExcel[i]['CUSTOMER_MOBILE_NO'];
      obj1['Address'] = this.dataExcel[i]['ADDRESS'];
      obj1['CGST No.'] = this.dataExcel[i]['CGST_NO'];
      obj1['PAN No.'] = this.dataExcel[i]['PAN_NUMBER'];
      obj1['Adhhar No.'] = this.dataExcel[i]['AADHAR_NUMBER'];
      obj1['Branch Name'] = this.dataExcel[i]['BRANCH_NAME'];
      obj1['Model Name'] = this.dataExcel[i]['MODEL_NAME'];
      obj1['Fuel Name'] = this.dataExcel[i]['FUEL_NAME'];
      obj1['Transmission'] = this.dataExcel[i]['TRANSMISSION_TYPE'];
      obj1['Color'] = this.dataExcel[i]['COLOR'];
      obj1['Varient'] = this.dataExcel[i]['VARIENT_NAME'];
      obj1['VIN No.'] = this.dataExcel[i]['VIN_NUMBER'];
      obj1['Payment Type'] = this.dataExcel[i]['PAYMENT_TYPE'];
      obj1['Inhouse Finance Name'] = this.dataExcel[i]['INHOUSE_FINANCE_NAME'];
      obj1['Outhouse Finance Name'] = this.dataExcel[i]['OUTHOUSE_FINANCE_NAME'];
      obj1['Inhouse Insurance Name'] = this.dataExcel[i]['INHOUSE_INSURANCE_NAME'];
      obj1['Outhouse Insurance Name'] = this.dataExcel[i]['OUTHOUSE_INSURANCE_NAME'];
      obj1['Invoice Amount'] = this.dataExcel[i]['INVOICE_AMOUNT'];
      obj1['Fastag'] = this.dataExcel[i]['FASTAG'];
      obj1['RTO'] = this.dataExcel[i]['RTO'];
      obj1['Insurance'] = this.dataExcel[i]['INSURANCE'];
      obj1['EW'] = this.dataExcel[i]['EW'];
      obj1['Accessories'] = this.dataExcel[i]['ACCESSORIES'];
      obj1['TCS1'] = this.dataExcel[i]['TCS_1'];
      obj1['TCS5'] = this.dataExcel[i]['TCS_5'];
      obj1['SOT'] = this.dataExcel[i]['SOT'];
      obj1['HMIL Discount'] = this.dataExcel[i]['HMIL_DISCOUNT'];
      obj1['Exchange Discount'] = this.dataExcel[i]['EXCHANGE_DISCOUNT'];
      obj1['Corporate Discount'] = this.dataExcel[i]['CORPORATE_DISCOUNT'];
      obj1['Sambandh Scheme Discount'] = this.dataExcel[i]['SAMBHADH_SCHEME_DISCOUNT'];
      obj1['Accessories Discount'] = this.dataExcel[i]['ACCESSORIES_DISCOUNT'];
      obj1['RTO Discount'] = this.dataExcel[i]['RTO_DISCOUNT'];
      obj1['Insurance Discount'] = this.dataExcel[i]['INSURANCE_DISCOUNT'];
      obj1['SOT Discount'] = this.dataExcel[i]['SOT_DISCOUNT'];
      obj1['Extended Warranty'] = this.dataExcel[i]['EXTENDED_WARRANTY'];
      obj1['Additional Discount'] = this.dataExcel[i]['ADDITIONAL_DISCOUNT'];
      obj1['Total Discount'] = this.dataExcel[i]['TOTAL_DISCOUNT'];
      obj1['Excess Amount'] = this.dataExcel[i]['OVERFLOW'];
      obj1['Shortfall'] = this.dataExcel[i]['SHORTFALL'];
      obj1['Company Name'] = this.dataExcel[i]['COMPANY_NAME'];
      obj1['Approver Name'] = this.dataExcel[i]['APPROVER_NAME'];
      obj1['Insurance %'] = this.dataExcel[i]['INSURANCE_PERCENTAGE'];
      obj1['Is RSA Available'] = this.dataExcel[i]['IS_RSA_AVAILABLE'];
      obj1['Is Exchange'] = this.dataExcel[i]['IS_EXCHANGE'];
      obj1['Total'] = this.dataExcel[i]['TOTAL'];
      arry1.push(Object.assign({}, obj1));

      if (i == this.dataExcel.length - 1) {
        this._exportService.exportExcel(arry1, 'Detailed New Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.filterClass = "filter-invisible";
    this.search(true);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.START_DATE = new Date();
    this.END_DATE = new Date();

    this.getBranch()
    this.getEmployee()

    this.search(true);
    this.isFilterApplied = 'default';
  }

  isVisible12 = false
  drawerFormPrintData: any = []
  employeeget = new EmployeeMaster()
  advanceArray: [];
  // memoSignature = new memoSignatureDetails();
  dataid: any;
  discount1 = 0
  discount2 = 0
  exchange = 0
  sum1 = 0
  sum2 = 0
  sum3 = 0
  sum4 = 0
  sum5 = 0
  sum6 = 0
  sum7 = 0
  sum8 = 0
  sum9 = 0
  // sum3 = 0
  rTo: any
  tcs: any
  sdfsdf: any
  sdfsdf2: any
  sdfsdf3: any
  sdfsdf4: any
  sdfsdf5: any
  sdfsdf6: any
  sdfsdf7: any
  formname: any
  sumofall = 0
  sumoffall2 = 0
  signature = sessionStorage.getItem('signature')
  accountname = sessionStorage.getItem('accountname')
  data: memoMaster = new memoMaster()
  quotedPrice: number;
  isLoading = false
  add1 = 0
  date24: any

  drawerClose1(): void {
    this.isVisible12 = false;
    this.advanceArray = []
    this.sumoffall2 = 0
    this.sumofall = 0
    this.discount2 = 0
    this.discount1 = 0
    this.exchange = 0
    this.quotedPrice = 0
    this.sum2 = 0
    this.sum3 = 0
    this.sum4 = 0
    this.sum5 = 0
    this.sum6 = 0
    this.sum7 = 0
    this.sum8 = 0
    this.sum9 = 0

    this.memoDetailsArray = []
    this.search()

  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  printForm(data: memoMaster) {

    this.formname = "Memo.Pdf"
    this.drawerFormPrintData = Object.assign({}, data);
    this.isLoading = true
    this.date24 = data.DATE
    // this.isVisible12 = true;
    console.log(this.dataList.MEMO_ID)

    if (data.STATUS == "FW") {
      var filter = "AND QUOTATION_ID=" + data.QUOTATION_ID
    }

    else if (data.STATUS == "AP") {
      var filter = "AND QUOTATION_ID=" + data.QUOTATION_ID
    }

    else {
      var filter = " AND ID = " + this.drawerFormPrintData.MEMO_ID
      console.log(this.dataList.MEMO_ID)

    }
    console.log(data.ID)

    this.api.getMemoDetails(0, 0, "ID", "desc", filter).subscribe(data => {

      if (data['code'] == 200 && data['count'] > 0) {

        this.memo12 = data['data'][0];
        this.isLoading = false
        if (this.memo12 != null) {
          if (data['data'][0]['ADDITIONAL_DISCOUNT'] != null)
            this.add1 = data['data'][0]['ADDITIONAL_DISCOUNT']
          else
            this.add1 = 0
          console.log(this.add1, "ADD1");
        }
        else {
          this.add1 = 0
        }
        this.getcal2()
        console.log(this.add1, "this.add1")
        this.isVisible12 = true;
        this.api.getmemoSignatureDetails(0, 0, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoSignature = data['data'][0];

            this.isVisible12 = true;

          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getQuestionaryMaster(0, 0, "", "", "AND MEMO_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {

            this.questionary = data['data'][0];


            if (this.questionary.CORPORATE_DISCOUNT != null) {

              // if(data['IS_CORPORATE']==1){
              this.discount1 = data['data'][0]['CORPORATE_DISCOUNT']
              // }
              // else{
              //   this.discount1=0
              // }

              console.log(this.discount1, "discount1")
            }
            else {
              this.discount1 = 0
            }
            if (this.questionary.EXCHANGE != null) {

              this.exchange = data['data'][0]['EXCHANGE']
              console.log(this.exchange, "exchange")
            }
            else {
              this.discount1 = 0
            }
            if (this.questionary.SAMBHADH_SCHEME != null) {

              // if(data['IS_SAMBHADH_SCHEME']==1){
              this.SAMBHADH_SCHEME = data['data'][0]['SAMBHADH_SCHEME']

              // }
              // else{
              //   this.SAMBHADH_SCHEME=0
              // }

            }
            else {
              this.SAMBHADH_SCHEME = 0
            }
            console.log(this.questionary);
            this.getcal()
            this.getcal2()
          }



        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });

        this.api.getAdvanceMaster(0, 0, "", "", "AND QUOTATION_ID=" + this.memo12.QUOTATION_ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            this.totalRecords = data['count'];
            this.advanceArray = data['data'];

            for (let i = 0; i < this.advanceArray.length; i++) {
              this.discount2 = this.discount2 + Number(this.advanceArray[i]['AMOUNT'])

              console.log(this.discount2, "discount2");


              if (i + 1 == this.advanceArray.length) {
                this.getcal2()
              }

            }
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });


        this.api.getMemoDetails1(0, 0, "", "", "AND MEMO_MASTER_ID=" + this.memo12.ID).subscribe(data => {

          if (data['code'] == 200 && data['count'] > 0) {
            // this.totalRecords = data['count'];
            this.memoDetailsArray = data['data'];
            console.log(this.memoDetailsArray);

            this.quotedPrice = 0

            for (let i = 0; i < this.memoDetailsArray.length; i++) {
              this.quotedPrice = this.quotedPrice + Number(this.memoDetailsArray[i]['CUSTOMER_QUOTED_PRICE'])

              console.log(this.quotedPrice);
              if (i + 1 == this.memoDetailsArray.length) {
                this.getcal()

              }
            }

            for (let j = 0; j < this.memoDetailsArray.length; j++) {

              this.sum1 = Number(this.memoDetailsArray[j]['CUSTOMER_DISCOUNT'])
              this.sum2 = Number(this.memoDetailsArray[j]['HEAD_DISCOUNT'])

              console.log(this.sum1, "sum1");
              console.log(this.sum2, "sum2");


              // this.sdfsdf=
              this.sdfsdf = this.memoDetailsArray[j]['COMPONENT']

              if (this.sdfsdf == 'SOT') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum3 = this.sum1 + this.sum2

                  console.log(this.sum3, "sum3");

                }
              }

              if (this.sdfsdf == 'Invoice amount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum4 = this.sum1 + this.sum2

                  console.log(this.sum4, "sum4");

                }
              }

              if (this.sdfsdf == 'RTO') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum5 = this.sum1 + this.sum2

                  console.log(this.sum5, "sum5");

                }
              }

              if (this.sdfsdf == 'Accessories') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum6 = this.sum1 + this.sum2

                  console.log(this.sum6, "sum6");

                }
              }

              if (this.sdfsdf == 'Insurance') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum7 = this.sum1 + this.sum2

                  console.log(this.sum7, "sum7");

                }
              }

              if (this.sdfsdf == 'Additional Discount') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum8 = this.sum1 + this.sum2

                  console.log(this.sum8, "sum8");

                }
              }

              if (this.sdfsdf == 'Extended Warranty') {
                if (this.sum1 != null && this.sum1 != undefined && this.sum2 != undefined && this.sum2 != null) {

                  this.sum9 = this.sum1 + this.sum2

                  console.log(this.sum9, "sum9");

                }
              }

              if (j + 1 == this.memoDetailsArray.length) {
                this.getcal2()
              }




            }

          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });


      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });



  }

  getcal2() {
    if (this.discount2 != null && this.discount2 != undefined && this.sum3 != undefined && this.sum3 != null && this.sum4 != undefined && this.sum4 != null
      && this.sum5 != undefined && this.sum5 != null && this.sum6 != undefined && this.sum6 != null && this.sum7 != undefined && this.sum7 != null && this.sum8 != undefined && this.sum8 != null && this.sum9 != undefined && this.sum9 != null && this.discount1 != undefined && this.discount1 != null
      && this.exchange != undefined && this.exchange != null && this.add1 != null) {

      this.sumoffall2 =
        Number(this.discount2) +
        Number(this.sum3) + Number(this.sum4) +
        Number(this.sum5) + Number(this.sum6) +
        Number(this.sum7) + Number(this.sum8) +
        Number(this.sum9) + Number(this.discount1) + Number(this.exchange) + Number(this.add1) + Number(this.SAMBHADH_SCHEME)

      console.log(this.sumoffall2, "total2");

      if (this.sumofall >= this.sumoffall2) {
        this.getcal3()
      }
      else {
        this.getcal4()
      }

    }
  }

  getcal3() {
    console.log(this.sumofall, "ok", this.sumoffall2, "ok2");
    console.log();

    if (this.sumofall >= this.sumoffall2) {
      this.sdfsdf4 = this.sumofall - this.sumoffall2
      this.sdfsdf5 = 0
      console.log(this.sdfsdf4, "shortfall");

    }
    else {
      this.sdfsdf4 = 0
    }

  }

  getcal4() {
    if (this.sumoffall2 >= this.sumofall) {
      this.sdfsdf5 = this.sumoffall2 - this.sumofall
      this.sdfsdf4 = 0
      console.log(this.sdfsdf5, "Excess Amount");
    }

    else {
      this.sdfsdf5 = 0
    }
  }

  corporatedis: any
  samband: any
  getcal() {

    if (this.quotedPrice != null && this.quotedPrice != undefined && this.discount1 != undefined && this.discount1 != null) {

      if (this.drawerFormPrintData.IS_CORPORATE == 1) {
        this.corporatedis = this.discount1
      }
      else {
        this.corporatedis = 0
      }

      if (this.drawerFormPrintData.IS_SAMBHADH_SCHEME == 1) {
        this.samband = this.SAMBHADH_SCHEME
      }
      else {
        this.samband = 0
      }

      this.sumofall = Number(this.quotedPrice) + Number(this.corporatedis) + Number(this.samband)
      console.log(this.quotedPrice, this.corporatedis, this.samband, "Total1");

      // if (this.sumoffall2 >= this.sumofall) {
      //   this.getcal4()
      // }

      // else {
      //   this.getcal3()
      // }


    }
  }

}
