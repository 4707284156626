import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-sotsales-summary-report',
  templateUrl: './sotsales-summary-report.component.html',
  styleUrls: ['./sotsales-summary-report.component.css']
})
export class SOTSalesSummaryReportComponent implements OnInit {

  formTitle = "SOT Sales Summary Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = ' SOTsalesSummaryReport.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["EMPLOYEE_NAME", "Sales Executive"], ["SOT_TARGET", "Target"], ["TOTAL_SALES", "Total Sales"], ["SOT_DONE", "Achieved"],
  ["SOT_AMOUNT", "Total SOT Incentive"],];

  STATUS = "AL";
  BRANCH = [];

  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;


  branches = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;


  BRANCH_ID: any = [];
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  SALES_CONSULTANT_ID: any = [];
  SALES_EXECUTIVE_ID: any = []


  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.search();
    this.getBranch();
    this.getSales();
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  onFromDateChange(fromDate: any) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  sales = [];
  getSales() {
    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, ' AND ROLE_ID IN(28,29)')
      .subscribe(data => {
        this.sales = data['data']
      })
  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.SALES_CONSULTANT_ID != undefined) {
      this.SALES_CONSULTANT_ID = this.SALES_CONSULTANT_ID;
    }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.MONTH1 = new Date();
    this.BRANCH_ID = [];
    this.SALES_CONSULTANT_ID = [];
    this.search();

  }
  ewsalesdata = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";


    // if (this.searchText != "") {
    //   likeQuery += this.searchText;
    // }
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.sotSalesSummaryReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.SALES_CONSULTANT_ID, this.MONTH, this.YEAR)
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            this.isSpinning = false;
            this.filterClass = 'filter-invisible';

          },
          (err) => {
            console.log(err);
          }
        );
    }
    else {
      this.exportLoading = false;

      this.api.sotSalesSummaryReport(0, 0, this.sortKey, sort, this.filterQuery + likeQuery, this.BRANCH_ID, this.SALES_CONSULTANT_ID, this.MONTH, this.YEAR).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.ewsalesdata = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  // ["EMPLOYEE_NAME", "Sales Executive"], ["SOT_TARGET", "Target"],["TOTAL_SALES", "Total Sales"], ["SOT_DONE", "Achieved"], 
  // ["SOT_AMOUNT", "Total SOT Incentive"],

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.ewsalesdata.length; i++) {
      obj1['Sales Executive Name'] = this.ewsalesdata[i]['EMPLOYEE_NAME'];
      obj1['Target'] = this.ewsalesdata[i]['SOT_TARGET'];
      obj1['Total Sales'] = this.ewsalesdata[i]['TOTAL_SALES'];
      obj1['Achieved'] = this.ewsalesdata[i]['SOT_DONE'];
      obj1['Total SOT Incentive'] = this.ewsalesdata[i]['SOT_AMOUNT'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.ewsalesdata.length - 1) {
        this._exportService.exportExcel(arry1, 'SOT Sales Summary Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
    }
  }

}
