import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-daily-target-report',
  templateUrl: './daily-target-report.component.html',
  styleUrls: ['./daily-target-report.component.css']
})
export class DailyTargetReportComponent implements OnInit {

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private message: NzNotificationService) { }

  formTitle = "Daily Target Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";

  columns: string[][] = [["CCE_NAME", "CCE"],
  ["CONFIRMATION", "Confirmation"],
  ["NO_SHOW", "No Show"],
  ["REMINDERS", "Reminder"],
  ["TOTAL_FORWORDED", "Total Forworded"],
  ["TOTAL_CALLS", "Total1"],
  ];

  date: any = new Date();
  modalVisible = false;
  isSpinning = false;
  filterClass: string = "filter-invisible";
  sales: any = []
  DATE: any;
  CCE_ID: number
  datalist: any = []

  counter: number = 1;

  ngOnInit() {
    this.datalist.forEach(() => {
      this.counter++;
    });

    this.date = new Date();
    this.getEmployee();
    this.search();
  }

  getEmployee() {
    this.api.getAllEmpRoleMap(0, 0, this.sortKey, this.sortValue, ' AND ROLE_ID=43').subscribe(data => {
      this.sales = data['data'];
      // if (this.sales.length > 0) {
      //   this.CCE_ID = Number([data['data'][0]['EMPLOYEE_ID']])
      //   console.log(this.sales);
      // this.search();
      // this.name = data['data'][0]['NAME'];
      // }
      // else { }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  isVisible: boolean = false

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  openPrint() {
    this.isVisible = true;
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {

    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.DATE = this.datePipe.transform(this.date, 'yyyy-MM-dd')

    if (this.CCE_ID != undefined && this.CCE_ID == null) {
      this.CCE_ID = this.CCE_ID
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.api.getdailyTargetReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery, this.DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.datalist = data['data'];


          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

        else if (data['code'] == 300) {

        }

      }, err => {
        console.log(err);
      });

    }
  }

  applyFilter() {
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'primary'

    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd')
    // this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    if (this.date != null) {
      this.DATE = this.date
    }

    if (this.CCE_ID != undefined && this.CCE_ID != null) {
      this.filterQuery = " AND CCE_ID = " + this.CCE_ID
      console.log(this.filterQuery);

      // var filter = '';
      // filter = this.filterQuery;
      // var likeQuery = "";
    }


    this.search(true);

  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.date = new Date();
    this.getEmployee();
    this.search(true);
    this.isFilterApplied = 'default';

  }

}
