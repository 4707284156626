import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Designationmaster } from 'src/app/Models/Designationmaster';
import { Branchmaster } from 'src/app/Models/branchmaster';
// import { Branchmaster } from 'src/app/Models/Branchmaster';
import { Department } from 'src/app/Models/department';
// import { Designationmaster } from 'src/app/Models/designationmaster';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addemployeemaster',
  templateUrl: './addemployeemaster.component.html',
  styleUrls: ['./addemployeemaster.component.css']
})

export class AddemployeemasterComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: EmployeeMaster = new EmployeeMaster();
  @Input() listOfData2: any = []
  @ViewChild('accountMasterPage', { static: false }) public userFrm: NgForm;
  fieldsetDisabled3 : boolean
  joinDate: any = []
  endValue: any = [];
  panNo
  adhhaar
  Passport
  listdata1 = [];
  listdata2 = [];
  isSpinning = false;
  @Input() listOfData: EmployeeMaster[] = [];
  OPEN_TIME;
  CLOSE_TIME;
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  @Input() DAYS = false;
  isOk = true;
  @Input() dataList: any[] = [];
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  divopattern = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/; 
  
  passwordVisible = false;
  branch: Branchmaster[] = [];
  department: Department[] = [];
  designation: Designationmaster[] = [];
  orgId = this.cookie.get('orgId');
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))
  @Input() TYPE_OF_LICENSE:any
  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }
  branchId = sessionStorage.getItem('branchId')
  ngOnInit() {
    this.branchinfo();
    this.dept();
    this.desig();
    this.getallorg1();
    this.getEmployeeList();
    this.getHPromiseEmployeeList();
    this.getCorporateManagerEmployeeList();
    this.getDirectorEmployeeList();
    this.getAccountantEmployeeList();
   console.log( this.data.TYPE_OF_LICENSE,'type')
  }



  branchinfo() {
    if (this.ROLE_ID != 14) {
      this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
        this.branch = data['data'];

      }, err => {
        this.isSpinning = false;
      });
    }
    else if (this.ROLE_ID == 14) {
      var branches = ''
      branches = " AND ID IN (" + this.branchId + ")"
      this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1 AND ORG_ID=' + this.orgId + branches).subscribe(data => {
        this.branch = data['data'];

      }, err => {
        this.isSpinning = false;
      });

    }

  }

  dept() {
    this.api.getAllDepartments(0, 0, '', '', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.department = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  desig() {
    this.api.getAllDesignation(0, 0, '', '', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.designation = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  clsAlphaNoOnly(event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);

    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  getallorg1() {
    this.listdata1 = [];

    this.api.getAllemployeeMaster(0, 0, 'ID', 'desc', ' AND ORG_ID = ' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId')) + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ") " + " AND STATUS != 2").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        //()
        console.log(this.listdata1,"this.listdata1");
        
      }

    }, err => {
      console.log(err);
    });
  }

  data24:EmployeeMaster = new EmployeeMaster()
  status=false
  getdatat(ID){

    this.api.getAllemployeeMaster(0, 0, '', '', ' AND ID ='+ID).subscribe((data) => {
      if (data['code'] == 200) {

        // this.data=data['data']
        this.data.STATUS=data['data'][0]['STATUS']

        if(Number(this.data.STATUS)==2){
          this.fieldsetDisabled3=true
          this.data.STATUS=false
          console.log("true");
          
        }
        
        else{
          this.fieldsetDisabled3=false
         
          console.log("false");
        }
       
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
    
  }
  

  getallorg2(userID) {
    this.listdata2 = [];

    var tempEmpList = this.listdata1.filter(obj1 => {
      return obj1["ID"] != userID;
    });

    this.listdata2 = tempEmpList;
  }

  resetDrawer2() {
    this.userFrm.form.reset();
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add1();
  }

  add1() {
    this.api.getAllemployeeMaster(1, 1, 'SEQUENCE_NO', 'desc', ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;

      } else {
        this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }

      this.data.STATUS = true;

    }, err => {
      console.log(err);
    })
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.NAME == undefined || this.data.NAME.toString() == '' &&
      this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '' &&
      this.data.PASSWORD != undefined &&
      this.data.MOBILE_NUMBER == undefined || this.data.MOBILE_NUMBER <= 0 &&
      
      this.data.MOBILE_NUMBER == undefined || this.data.MOBILE_NUMBER <= 0 &&
      this.data.EMPLOYEE_CODE == null || this.data.EMPLOYEE_CODE.trim() == '' &&
      this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <= 0 &&
      this.data.JOINING_DATE == undefined &&
      this.data.CONFIRMATION_DATE == undefined &&
      this.data.LEAVE_DATE == undefined &&
     
      this.data.DEPARTMENT_ID == undefined && 
      this.data.BRANCH_ID == undefined &&
      this.data.DESIGNATION_ID == undefined &&
      this.data.REPORTING_HEAD_ID == undefined &&
      this.data.TEMPORARY_HEAD_ID == undefined

      ) {
      this.isOk = false;
      this.message.error('Please fill all the required fields ', '');
    }

     if (this.data.NAME == undefined || this.data.NAME.toString() == '') {
      this.isOk = false;
      this.message.error('Please Enter Name', '');
    }

    if (!this.namepatt.test(this.data.NAME)) {
      this.isOk = false;
      this.message.error('Please Enter Valid Name', '');
    }

    if (this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Email ID', '');
    }

 if (!this.emailpattern.test(this.data.EMAIL_ID)) {
      this.isOk = false;
      this.message.error('Please Enter Valid Email ID', '');
    }

    if (this.data.PASSWORD != undefined) {
      if (this.data.PASSWORD.trim() != "") {
        if (this.data.PASSWORD.length >= 8) {
          if (!this.api.passwordIsValid(this.data.PASSWORD)) {
            this.isOk = false;
            this.message.error("Please Enter Valid Password", "");
          }

        } else {
          this.isOk = false;
          this.message.error("Password Must be or Greater than 8 Characters", "");
        }

      } else {
        this.isOk = false;
        this.message.error("Please Enter Password", "");
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Password", "");
    }

     if (this.data.MOBILE_NUMBER == undefined || this.data.MOBILE_NUMBER <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Mobile Number', '');
    }

    if (!this.mobpattern.test(this.data.MOBILE_NUMBER.toString())) {
      this.isOk = false;
      this.message.error('Please Enter Valid Mobile Number', '');
    }

    else if (this.data.EMPLOYEE_CODE == null || this.data.EMPLOYEE_CODE.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Employee Code', '');
    }

    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Sequence Number', '');
    }

    // else if (this.data.JOINING_DATE == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Select Joining Date', '');
    // }
    // else if (this.data.CONFIRMATION_DATE == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Select Confirmation Date', '');
    // }
    // else if (this.data.LEAVE_DATE == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Select Leave Date', '');
    // }
    // else if (this.data.DRIVING_LICENSE_NO == null) {
    //   this.isOk = false;
    //   this.message.error('Please Enter Driving License Number', '');
    // }
    // else if (this.data.TYPE_OF_LICENSE == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Enter Type of License', '');
    // }

    // else if (this.data.VALIDITY_OF_LICENSE == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Select Validity of License', '');
    // }
    // else if (this.data.BLOOD_GROUP == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Select Blood Group', '');
    // }

    else if (this.data.DEPARTMENT_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Department', '')
    }

    else if (this.data.BRANCH_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Branch', '')
    }

    else if (this.data.DESIGNATION_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Designation', '')
    }

    else if (this.data.REPORTING_HEAD_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Reporting Head', '')
    }

    else if (this.data.TEMPORARY_HEAD_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Temporary Head', '')
    }

    if (this.isOk) {

      this.data.JOINING_DATE = this.datePipe.transform(this.data.JOINING_DATE, 'yyyy-MM-dd');
      this.data.CONFIRMATION_DATE = this.datePipe.transform(this.data.CONFIRMATION_DATE, 'yyyy-MM-dd');
      this.data.LEAVE_DATE = this.datePipe.transform(this.data.LEAVE_DATE, 'yyyy-MM-dd');
      this.data.VALIDITY_OF_LICENSE = this.datePipe.transform(this.data.VALIDITY_OF_LICENSE, 'yyyy-MM-dd');
      if (this.data.DEPARTMENT_ID == "0")
        this.data.DEPARTMENT_ID = "0";

      if (this.data.BRANCH_ID == "0")
        this.data.BRANCH_ID = "0";

      if (this.data.DESIGNATION_ID == "0")
        this.data.DESIGNATION_ID = "0";

      if (this.data.REPORTING_HEAD_ID == "0")
        this.data.REPORTING_HEAD_ID = "0";

      if (this.data.TEMPORARY_HEAD_ID == "0")
        this.data.TEMPORARY_HEAD_ID = "0";

      if (this.data.REPORTING_PERSON_ID == "0")
        this.data.REPORTING_PERSON_ID = 0;

      if (this.data.H_PROMISE_ID == "0")
        this.data.H_PROMISE_ID = 0;

      if (this.data.CORPORATE_MANAGER_ID == "0")
        this.data.CORPORATE_MANAGER_ID = 0;

      if (this.data.DIRECTOR_ID == "0")
        this.data.DIRECTOR_ID = 0;

      if (this.data.ACCOUNT_ID == "0")
        this.data.ACCOUNT_ID = 0;

      this.isSpinning = true;
      this.data.ORG_ID = Number(this.orgId);

      this.imageUpload1();
      this.data.SIGNATURE = (this.photo1Str == "") ? " " : this.photo1Str;


      // if (this.data.ID) {



      //   var emailData2 = this.listdata2.filter(obj => {
      //     return (obj.EMPLOYEE_CODE == this.data.EMPLOYEE_CODE) || (obj.EMAIL_ID == this.data.EMAIL_ID);
      //   });

      //   if (emailData2.length == 0) {
      //     this.api.updateemployeeMaster(this.data).subscribe(successCode => {
      //       if (successCode['code'] == 200) {
      //         this.message.success("Information Updated Successfully", "");

      //         if (!addNew)
      //           this.drawerClose();

      //         this.resetDrawer(accountMasterPage);
      //         this.isSpinning = false;

      //       } else {
      //         this.message.error("Information Updation Failed", "");
      //         this.isSpinning = false;
      //       }
      //     });

      //   } else {
      //     this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
      //     this.isSpinning = false;
      //   }

      // } else {
      //   this.data.JOINING_DATE = this.datePipe.transform(this.data.JOINING_DATE, 'yyyy-MM-dd');
      //   this.data.CONFIRMATION_DATE = this.datePipe.transform(this.data.CONFIRMATION_DATE, 'yyyy-MM-dd');
      //   this.data.LEAVE_DATE = this.datePipe.transform(this.data.LEAVE_DATE, 'yyyy-MM-dd');

      //   var emailData = this.listdata1.filter(obj => {
      //     return ((obj.EMPLOYEE_CODE == this.data.EMPLOYEE_CODE) || (obj.EMAIL_ID == this.data.EMAIL_ID));

      //   });
      //   ;

      //   if (emailData.length == 0) {
      //     this.api.createemployeeMaster(this.data).subscribe(successCode => {
      //       if (successCode['code'] == 200) {
      //         this.isSpinning = false;
      //         this.message.success("Information Saved Successfully", "");

      //         if (!addNew) {
      //           this.drawerClose();
      //           this.resetDrawer(accountMasterPage);

      //         } else {
      //           this.data = new EmployeeMaster();
      //           this.resetDrawer(accountMasterPage);
      //           this.getallorg1();
      //         }

      //       } else {
      //         this.message.error("Information Saved Failed", "");
      //         this.isSpinning = false;
      //       }
      //     });

      //   } else {
      //     this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
      //     this.isSpinning = false;
      //   }
      // }


      if (this.data.ID) {
        var emailData2 = this.listdata2.filter(obj => {
          return (obj.EMPLOYEE_CODE == this.data.EMPLOYEE_CODE) 
        });
        var emailData3 = this.listdata2.filter(obj => {
          return  (obj.EMAIL_ID == this.data.EMAIL_ID);
        });
        if ((emailData2.length == 0) && (emailData3.length == 0)) {
          this.api.updateemployeeMaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Information Updated Successfully", "");
              if (!addNew)
                this.drawerClose();
              this.resetDrawer(accountMasterPage);
              this.isSpinning = false;
            } else {
              this.message.error("Information Updation Failed", "");
              this.isSpinning = false;
            }
          });
        } else if(emailData2.length > 0){
          this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
          this.isSpinning = false;
        } else if(emailData3.length > 0){
          this.message.error("Email-ID Already Exist. Please Enter Other Email-ID", "");
          this.isSpinning = false;
        }
      } else {
        this.data.JOINING_DATE = this.datePipe.transform(this.data.JOINING_DATE, 'yyyy-MM-dd');
        this.data.CONFIRMATION_DATE = this.datePipe.transform(this.data.CONFIRMATION_DATE, 'yyyy-MM-dd');
        this.data.LEAVE_DATE = this.datePipe.transform(this.data.LEAVE_DATE, 'yyyy-MM-dd');
        var emailData = this.listdata1.filter(obj => {
          return  (obj.EMPLOYEE_CODE == this.data.EMPLOYEE_CODE)
        });
        var emailData4 = this.listdata1.filter(obj => {
          return  (obj.EMAIL_ID == this.data.EMAIL_ID)
        });
        if ((emailData.length == 0) && (emailData4.length == 0)) {
          this.api.createemployeeMaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Information Saved Successfully", "");
              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);
              } else {
                this.data = new EmployeeMaster();
                this.resetDrawer(accountMasterPage);
                this.getallorg1();
              }
            } else {
              this.message.error("Information Saved Failed", "");
              this.isSpinning = false;
            }
          });
        } else if(emailData.length > 0){
          this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
          this.isSpinning = false;
        } else if(emailData4.length > 0){
          this.message.error("Email-ID Already Exist. Please Enter Other Email-ID", "");
          this.isSpinning = false;
        }
      }
    }
  }

  fileURL1: any = null;

  clear1() {
    this.fileURL1 = null;
    this.data.SIGNATURE = null;
  }

  viewImage(imageName) {
    window.open(imageName);
  }

  onFileSelected1(event: any) {
    if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.fileURL1 = <File>event.target.files[0];

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.data.SIGNATURE = reader.result as string;
        };
      }

    } else {
      this.message.error('Please Choose Only JPEG/ JPG/ PNG File', '');
      this.fileURL1 = null;
    }
  }

  folderName = "signature";
  photo1Str: string;

  imageUpload1() {
    this.photo1Str = "";

    if (!this.data.ID) {
      if (this.fileURL1) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL1.name.split('.').pop();
        var url = "S" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL1, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.photo1Str = url;

      } else {
        this.photo1Str = "";
      }

    } else {
      if (this.fileURL1) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL1.name.split('.').pop();
        var url = "S" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL1, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.photo1Str = url;

      } else {
        if (this.data.SIGNATURE) {
          let photoURL = this.data.SIGNATURE.split("/");
          this.photo1Str = photoURL[photoURL.length - 1];

        } else
          this.photo1Str = "";
      }
    }
  }

  cancel() { }

  allEmployeeList = [];
  allHPromiseEmployeeList = [];
  allCorporateManagerEmployeeList = [];
  allDirectorEmployeeList = [];
  allAccountantEmployeeList = [];

  employeeList = [];
  hPromiseEmployeeList = [];
  corporateManagerEmployeeList = [];
  directorEmployeeList = [];
  accountantEmployeeList = [];

  getEmployeeList() {
    this.allEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, "NAME", "asc", ' AND ORG_ID=' + this.orgId + '  AND ROLE_STATUS IN (' + this.selectedRoleStatus + ')').subscribe(data => {
      if (data['code'] == 200) {
        this.allEmployeeList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getHPromiseEmployeeList() {
    this.allHPromiseEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=31)').subscribe(data => {
      if (data['code'] == 200) {
        this.allHPromiseEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getCorporateManagerEmployeeList() {
    this.allCorporateManagerEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=32)').subscribe(data => {
      if (data['code'] == 200) {
        this.allCorporateManagerEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDirectorEmployeeList() {
    this.allDirectorEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=33)').subscribe(data => {
      if (data['code'] == 200) {
        this.allDirectorEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getAccountantEmployeeList() {
    this.allAccountantEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ROLE_STATUS IN (' + this.selectedRoleStatus + ') AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=13)').subscribe(data => {
      if (data['code'] == 200) {
        this.allAccountantEmployeeList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  filterEmployeeList(userID: number) {
    console.log(userID);

    this.employeeList = [];

    var tempEmpList = this.allEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.employeeList = tempEmpList;
    console.log(this.employeeList,"::")
  }

  filterHPromiseEmployeeList(userID: number) {
    this.hPromiseEmployeeList = [];

    var tempEmpList = this.allHPromiseEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.hPromiseEmployeeList = tempEmpList;
  }

  filterCorporateManagerEmployeeList(userID: number) {
    this.corporateManagerEmployeeList = [];

    var tempEmpList = this.allCorporateManagerEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.corporateManagerEmployeeList = tempEmpList;
  }

  filterDirectorEmployeeList(userID: number) {
    this.directorEmployeeList = [];

    var tempEmpList = this.allDirectorEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.directorEmployeeList = tempEmpList;
  }

  filterAccountantEmployeeList(userID: number) {
    this.accountantEmployeeList = [];

    var tempEmpList = this.allAccountantEmployeeList.filter(obj1 => {
      if (userID != 0)
        return obj1["ID"] != userID;

      else
        return obj1;
    });

    this.accountantEmployeeList = tempEmpList;
  }

  isVisible:boolean=false
  isModalConfirmLoading=false
  DISABLE_REMARK
  disableconfirm(){

    if(this.data.STATUS==false){
      this.isVisible=true
    }
  }

  STATUS:any

  handleOk(){

    this.STATUS=2
    this.data.STATUS=this.STATUS
    this.data.DISABLE_REMARK=this.DISABLE_REMARK
    this.data.DISABLE_DATE=this.datePipe.transform(this.data.DISABLE_DATE, 'yyyy-MM-dd');
    this.api.updateemployeeMaster(this.data).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Information Updated Successfully", "");
        this.isVisible=false
        this.drawerClose()
        if(this.data.STATUS==false){
          this.fieldsetDisabled3=true
        }
        else{
          this.fieldsetDisabled3=false
        }
    
        // if (!addNew)
        //   this.drawerClose();

        // this.resetDrawer(accountMasterPage);
        // this.isSpinning = false;

      } else {
        this.message.error("Information Updation Failed", "");
        
      }
    });
    
  }

  handleCancel(){
    this.isVisible=false
  }
}
