import { Component, OnInit,Input } from '@angular/core';
import { HolidayMaster } from 'src/app/Models/holiday-master';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-holiday-type',
  templateUrl: './holiday-type.component.html',
  styleUrls: ['./holiday-type.component.css']
})
export class HolidayTypeComponent implements OnInit {
  @Input() drawerClose3: Function;
@Input () data:HolidayMaster
isVisible2:boolean=false
@Input()TYPE:any
 options=[]
assign:any
DATE:any
IS_HOLIDAY:boolean
REASON:any
ATTENDANCE_ALLOW:boolean
isSpinning=false
isOk=true
onGet(){
  this.api.getAllHolidays(0,0,'','','').subscribe((data:any)=>{
    this.options=data['data'];
  })
}
close(){
 this.drawerClose3()
}
  constructor(private api: ApiService,private message: NzNotificationService) { }
  menu
  ngOnInit() {
  }
   saveholiday(data:HolidayMaster){
    this.isSpinning = false
    this.isOk = false
    if (this.data.TYPE == null || this.data.TYPE == '') {
      this.isOk = false
      this.message.error('Please Select Leave Type.', "")
    }
    else{
     this.api.updateHoliday(this.data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.onGet();
        this.drawerClose3();
        this.isSpinning = false;
        }
        else{
          
        }
    });
  
}
   }
  
}
