export class ModelMaster {
    ID: number;
    CLIENT_ID: number;
    NAME: string;
    CODE: string;
    STATUS: boolean = true;
    SEQUENCE_NO: number;
    IMAGE_URL: string;
    COLOR_ID:any
    IS_AVAILABLE_FOR_SALE:any
}