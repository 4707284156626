export class assetLog {
    ID:number
    ASSEST_ID:number
    DATE:any
    LOG_ACTION:any
    DESCRIPTION:any
    LOG_DATETIME:any
    LOG_TYPE:any
    USER_ID:number
    MAINTAINANCE_BY:any
    REMARK:any
    ALLOCATED_BY:any
    CREATED_MODIFIED_DATE:any
    READ_ONLY:any
    ARCHIVE_FLAG:any
    CLIENT_ID:any
}