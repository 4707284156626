import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { PriceList } from 'src/app/Models/PriceList';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-price-list-mapping',
  templateUrl: './price-list-mapping.component.html',
  styleUrls: ['./price-list-mapping.component.css']
})

export class PriceListMappingComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: PriceList;
  @Input() priceListMappingData: [];
  @Input() drawerVisible: boolean;
  priceListMappingLoadingRecords = false;
  pageIndex = 1;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  save() {
    this.priceListMappingLoadingRecords = true;
    console.log(this.priceListMappingData);

    this.api.addPriceListMappingDetails(this.data.ID, this.priceListMappingData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Price List Mapping Details Added Successfully", "");
        this.priceListMappingLoadingRecords = false;
        this.drawerClose();

      } else {
        this.message.error("Failed to Add Price List Mapping Details", "");
        this.priceListMappingLoadingRecords = false;
      }
    });
  }
}
