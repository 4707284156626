import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";
import * as xlsx from 'xlsx';

@Component({
  selector: "app-monthly-avg-attendance-report",
  templateUrl: "./monthly-avg-attendance-report.component.html",
  styleUrls: ["./monthly-avg-attendance-report.component.css"],
})
export class MonthlyAvgAttendanceReportComponent implements OnInit {
  formTitle = "Monthly Avg Attendance Report";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;
  fileName = 'MonthlyAvgAttendanceReport.xlsx';

  orgId = this.cookie.get("orgId");
  START_DATE: any = [];
  END_DATE: any = [];
  DAY_COUNT: any;

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.START_DATE = currentDate;
    this.END_DATE = currentDate;
  }

  getCount() {
    const fromDateDCFilter = new Date(this.START_DATE);
    const toDateDCFilter = new Date(this.END_DATE);

    const Time = Math.round(
      toDateDCFilter.getTime() - fromDateDCFilter.getTime()
    );
    this.DAY_COUNT = Math.round(Time / (1000 * 3600 * 24));
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.START_DATE = [];
    this.END_DATE = [];
    this.setDateToDateFilter();
    this.getCount();
  }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.getCount();
    this.setDateToDateFilter();
    this.getMonthlyAttendanceReport();
  }

  applyFilter() {
    if (
      this.START_DATE != undefined &&
      this.START_DATE != null &&
      this.START_DATE.length >= 0 &&
      this.END_DATE != undefined &&
      this.END_DATE != null &&
      this.END_DATE.length >= 0
    )
      this.isFilterApplied = "primary";
    else this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";

    this.getMonthlyAttendanceReport(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.MonthlyAttendanceReportSortKey = sort.key;
    this.MonthlyAttendanceReportSortValue = sort.value;
    this.getMonthlyAttendanceReport(true);
  }

  MonthlyAttendanceReportPageIndex = 1;
  MonthlyAttendanceReportPageSize = 10;
  MonthlyAttendanceReportTotalRecords = 1;
  MonthlyAttendanceReportSortValue: string = "desc";
  MonthlyAttendanceReportSortKey: string = "ID";
  MonthlyAttendanceReportData = [];

  columns: string[][] = [
    ["AVERAGE_INTIME", "Avg In Time"],
    ["AVERAGE_LATE", "Avg Late"],
    ["AVERAGE_LATEMARK", "Avg Latemark"],
    ["AVERAGE_LEAVE", "Avg Leave"],
    ["AVERAGE_ABSENT", "Avg Absent"],
  ];

  getMonthlyAttendanceReport(reset: boolean = false) {
    if (reset) {
      this.MonthlyAttendanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.MonthlyAttendanceReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (
      (this.START_DATE != undefined && this.START_DATE.length != 0) ||
      (this.END_DATE != undefined && this.END_DATE.length != 0)
    ) {
      fromDateFilter = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
    }

    if (this.DAY_COUNT != undefined && this.DAY_COUNT.length != 0) {
      this.getCount();
    }

    this.loadingRecords = true;
    this.api
      .getMonthlyAttendanceReport(
        this.MonthlyAttendanceReportPageIndex,
        this.MonthlyAttendanceReportPageSize,
        this.MonthlyAttendanceReportSortKey,
        sort,
        "",
        this.api.orgId,
        fromDateFilter,
        toDateFilter,
        this.DAY_COUNT
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.MonthlyAttendanceReportTotalRecords = data["count"];
            this.MonthlyAttendanceReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }
  exportexcel(): void {
    this.loadingRecords = true;
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Monthly Avg Attendance Report');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }, 1000);
  }
}
