import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-detailed-vinreport',
  templateUrl: './detailed-vinreport.component.html',
  styleUrls: ['./detailed-vinreport.component.css']
})
export class DetailedVINReportComponent implements OnInit {

  formTitle = "Detailed VIN report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Detailed VIN Report.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "VIN_NUMBER";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] =[ ['CUSTOMER_NAME', "Customer Name"],["SALES_EXECUTIVE_NAME", "Sales Executive"],["MODEL_NAME", "Model"],["VARIENT_NAME", "Varient"],["TRANSMISSION_TYPE", "Transmission type"],["FUEL_NAME", "Fuel type"],["VIN_NUMBER", "VIN no"],
  ["DATE", "Date of VIN entered"] ]
  BRANCH = [];
  SalesExecutive = [];
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  // ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  leaves = [];
  supportusers = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  // orgName: string = this.api.ORGANIZATION_NAME;
  isButtonSpinning: boolean = false;
  VIN_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  // startOpen = false;
  employees = [];
  LEAVE_TYPE: any;
  APPROVAR_ID: any;
  // MONTH='';
  // YEAR=''
  // LIKE_STRING='';
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM";

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.search();
    // this.applyFilter();
    this.getModels();
  }

models = [];

getModels() {
  this.models = [];

  this.api.getAllModels(0, 0, "NAME", "asc", "  AND STATUS=1").subscribe(
    (data) => {
      if (data["code"] == 200) {
        this.models = data["data"];
      }
    },
    (err) => {
      if (err["ok"] == false) this.message.error("Server Not Found", "");
    }
  );
}



  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  dates: any = [];
  today2 = new Date();

  // startDateChange() {
  //   var startDate = this.datePipe.transform(this.VIN_DATE, 'yyyy-MM-dd');
  //   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  //   console.log(this.getDaysArray(startDate, endDate));
  //   console.log(this.dates);
  // }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


  showFilter() {
    
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  // onFromDateChange(fromDate: any) {
  //   if (fromDate == null)
  //     this.date2 = new Date();

  //   else
  //     this.date2 = new Date(fromDate);
  // }

  // disabledStartDate = (VIN_DATE: Date): boolean => {
  //   if (!VIN_DATE || !this.TO_DATE) {
  //     return false;
  //   }
  //   return VIN_DATE.getTime() > this.TO_DATE;
  // };
  current = new Date();


  // disabledEndDate = (selected: Date): boolean =>
  //   // Can not select days before today and today
  //   differenceInCalendarDays(selected, this.current) < 0;


  // disabledEndDate = (TO_DATE: Date): boolean => {
  //   if (!TO_DATE || !this.VIN_DATE) {
  //     return true;
  //   }
  //   return TO_DATE.getTime() < this.VIN_DATE;
  // };

  onStartChange(date: Date): void {
    this.VIN_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {

    // var sort: string;
    // try {
    //   sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    // } catch (error) {
    //   sort = '';
    // }

  
    // this.filterQuery = '';

    // this.VIN_DATE = this.datePipe.transform(this.VIN_DATE, 'yyyy-MM-dd');
    // this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd');
    // this.MONTH = this.datePipe.transform(this.MONTH1, 'yyyy-MM-dd');


   

     

// if (this.VIN_DATE  != undefined) {
//     this.filterQuery = " AND DATE(DATE)='" +this.VIN_DATE +  "' "
//     var filter = '';
//     filter = this.filterQuery;
//     var likeQuery = "";
// }

// if ( this.MODEL_ID  != undefined||this.MODEL_ID.length != 0) {
//         this.filterQuery = this.filterQuery+"'AND MODEL_ID  IN (" + "" +this.MODEL_ID + ")  "
       
// }
  // if (this.MONTH != undefined) {
  //   this.filterQuery = this.filterQuery+" AND  MONTH ('" + this.MONTH + "')"
  // }

 
    // if (this.APPROVAR_ID != null) {
    //   this.filterQuery = this.filterQuery + ' AND APPROVER_ID=' + "" + this.APPROVAR_ID + "";
    // }

if ((this.VIN_DATE != undefined) ||  (this.MODEL_ID.length > 0) )
    this.isFilterApplied = "primary";
else
    this.isFilterApplied = "default";
    this.search(true);
    this.filterClass = "filter-invisible";
  
//     var likeQuery = '';

// if (this.searchText != '') {
//       likeQuery = ' AND';
//       this.columns.forEach((column) => {
//         likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
//       });
//       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
// }

// this.api.VINDetailedReport(1, this.pageSize, this.sortKey,sort, this.filterQuery).subscribe((data) => {
//         console.log(data);
//         this.loadingRecords = false;
//         this.isFilterApplied = 'primary';
//         this.totalRecords = data['count'];
//         this.dataList = data['data'];
//         this.isSpinning = false;
//         this.filterClass = 'filter-invisible';
// },
//         (err) => {
//           console.log(err);
//         });
//   console.log(this.filterQuery);
}

getNameOfFinalStatus(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "A")
      return "Approved";

    else if (STATUS == "R")
      return "Rejected";
}

  setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
  }

clearFilter() {
    this.filterClass = 'filter-invisible';
    this.VIN_DATE = new Date();
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.dataList = [];
    this.SELECT_ALL2=false;
    this.search(false);
    this.MODEL_ID = [];
}

  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  
  dataList2=[]
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    //  this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.VIN_DATE = this.datePipe.transform(this.VIN_DATE, 'yyyy-MM-dd');
    this.TO_DATE = this.datePipe.transform(this.TO_DATE, 'yyyy-MM-dd');
    var dateFilterStr = "";
    if (this.VIN_DATE != null) {
      dateFilterStr = " AND DATE(DATE)='" +this.VIN_DATE +  "' "
    }
    var branchFilterStr = "";
    if (this.MODEL_ID.length > 0) {
      branchFilterStr = " AND MODEL_ID IN (" + this.MODEL_ID + ")";
    }
   if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.VINDetailedReport(this.pageIndex, this.pageSize, this.sortKey, sort ,this.filterQuery+likeQuery+dateFilterStr+branchFilterStr).subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
     }, err => {
        console.log(err);
      });
  }
    else {
      this.exportLoading = true;
      this.api.VINDetailedReport(0,0, this.sortKey,sort,this.filterQuery+likeQuery+dateFilterStr+branchFilterStr).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
       });
    }
}

MODEL_ID = [];

SELECT_ALL2: boolean = false;
onSelectAllChecked2(event) {
   this.SELECT_ALL2 = event;
   let ids = [];
   if (this.SELECT_ALL2 == true) {
     for (var i = 0; i < this.models.length; i++) {
       ids.push(this.models[i]["ID"]);
     }
   }else {
     ids = [];
   }
   this.MODEL_ID = ids;
}

onSelectOff2(event) {
   var a = this.models.length;
   var b = this.models.length - event.length;
   if ((a! = b)) {
     this.SELECT_ALL2 = false;
   } else {
     this.SELECT_ALL2 = true;
   }
   this.MODEL_ID = event;
   if (this.MODEL_ID.length == 0) {
     this.SELECT_ALL2 = false;
   }
}

sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
}
               
convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Customer Name '] = this.dataList2[i]['CUSTOMER_NAME'];
      obj1['Sales Executive'] = this.dataList2[i]['SALES_EXECUTIVE_NAME'];
      obj1['Model'] = this.dataList2[i]['MODEL_NAME'];
      obj1['Varient'] = this.dataList2[i]['VARIENT_NAME'];
      obj1['Transmission type'] = this.dataList2[i]['TRANSMISSION_TYPE'];
      obj1['Fuel type'] = this.dataList2[i]['FUEL_TYPE'];
      obj1['VIN no'] = this.dataList2[i]['VIN_NUMBER'];
      obj1['Date of VIN entered'] = this.datePipe.transform(this.dataList2[i]['DATE'], "yyyy-MM-dd");
      
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Detailed VIN Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
   }
}

}
