import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { SMRCOLLAPSEMASTER } from 'src/app/Models/smrcollapsemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add-smr',
  templateUrl: './add-smr.component.html',
  styleUrls: ['./add-smr.component.css']
})
export class AddSmrComponent implements OnInit {

  @Input()
  collapseDrawerVisible: boolean = false;
  @Input()
  data1: SMRCOLLAPSEMASTER = new SMRCOLLAPSEMASTER();
  @Input() drawerClose1!: Function;
  @Input() active1: boolean = false
  @Input() active2: boolean = false
  @Input() active3: boolean = false
  @Input() active4: boolean = false
  @Input() active5: boolean = false
  @Input() active6: boolean = false
  @Input() active7: boolean = false
  @Input() active8: boolean = false
  isOk = true;

  date = null;
  isEnglish = false;
  close1(): void {
    this.drawerClose1();
    this.active1 = false
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
  }

  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  longitudepattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
  latitudepattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
  pat1 = /^\d{6}$/;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  cityRegex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  aaddpattern = /^[a-zA-Z0-9\s,'-]*$/
  namepatte = /[ .a-zA-Z]+/
  passpattern = /^\S*$/

  isSpinning = false;

  constructor(private message: NzNotificationService, private api: ApiService) { }

  ngOnInit() {
  }


  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  resetDrawer(accountMasterPage: NgForm) {
    this.data1 = new SMRCOLLAPSEMASTER();
    accountMasterPage.form.markAsPristine();
    accountMasterPage.form.markAsUntouched();
  }


  onChange(result: Date): void {
    console.log('onChange: ', result);
  }


 



  Customerinfo() {
    this.active1 = true
    this.active2 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
    //  console.log('ff')
  }
  Vehicleinfo() {
    this.active2 = true
    this.active1 = false
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active7 = false
    this.active8 = false
    //  console.log('ff') 
  }
  ServiceAdvisorInfo() {
    this.active3 = true
    this.active4 = false
    this.active5 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false
    this.active7 = false
    this.active8 = false

  }
  CallingInfo() {
    this.active4 = true
    this.active3 = false
    this.active5 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false
    this.active7 = false
    this.active8 = false
  }

  BillingInfo() {
    this.active5 = true
    this.active3 = false
    this.active4 = false
    this.active6 = false
    this.active2 = false
    this.active1 = false
    this.active7 = false
    this.active8 = false

  }
  BookingInfo() {
    this.active6 = true
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
    this.active7 = false
    this.active8 = false
  }

  UnknownInfo() {

    this.active7 = true
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
    this.active6 = false
    this.active8 = false

  }

  ReserveFieldInfo() {
    this.active8 = true
    this.active3 = false
    this.active4 = false
    this.active5 = false
    this.active2 = false
    this.active1 = false
    this.active7 = false
    this.active6 = false

  }

  //save
  save(addNew: boolean): void {
    this.isSpinning = false;
    this.isOk = true;



    // create update

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.data1.ID) {
          this.api.updateServiceSMRMaster(this.data1).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success(' Information Updated Successfully...', '');
              if (!addNew) this.drawerClose1();
              this.isSpinning = false;
            } else {
              this.message.error(' Failed To Update Information...', '');
              this.isSpinning = false;
            }
          });
        }

      }
    }

  }
}
