import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Asset } from 'src/app/Models/Asset';
import { ApiService } from 'src/app/Service/api.service';
import { AssetDrawerComponent } from '../asset-drawer/asset-drawer.component';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { assetLog } from 'src/app/Models/assetLog';


@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css']
})

export class AssetListComponent implements OnInit {
  formTitle = "Manage Assets";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  assetData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["ASSET_CATEGORY_NAME", "Category"], ["NAME", "Asset Name"], ["DESCRIPTION", "Description"], ["PURCHASE_DATE", "Purchase Date"], ["EXPIRY_DATE", "Expiry Date"], ["MAINTENANCE_FREQUENCY", "Maintenance Frequency"], ["LAST_MAINTENANCE_DATE", "Last Maintenance Date Time"], ["NEXT_MAINTENANCE_DATE", "Next Maintenance Date Time"], ["ALLOCATED_EMPLOYEE_NAME", "Allocated To"], ["ALLOCATION_DATETIME", "Allocation Date Time"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Asset = new Asset();
  asset: assetLog = new assetLog()
  dateFormat = 'dd MMM yyyy'
  logdrawerVisible = false
  logdrawerTitle = ""
  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAssetCategory();
    this.getAllocatedEmployeeList();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    var categoryFilterStr = "";
    if (this.CATEGORY_ID.length > 0) {
      categoryFilterStr = " AND CATEGORY_ID IN (" + this.CATEGORY_ID + ")";
    }

    var statusFilterStr = "";
    if (this.STATUS.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.STATUS + ")";
    }

    var allocatedToFilterStr = "";
    if (this.ALLOCATED_EMPLOYEE_ID.length > 0) {
      allocatedToFilterStr = " AND ALLOCATED_EMPLOYEE_ID IN (" + this.ALLOCATED_EMPLOYEE_ID + ")";
    }

    var purchaseDateFilter = "";
    if ((this.PURCHASE_DATE != undefined) && (this.PURCHASE_DATE.length != 0)) {
      purchaseDateFilter = " AND (PURCHASE_DATE BETWEEN '" + this.datePipe.transform(this.PURCHASE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.PURCHASE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var expiryDateFilter = "";
    if ((this.EXPIRY_DATE != undefined) && (this.EXPIRY_DATE.length != 0)) {
      expiryDateFilter = " AND (EXPIRY_DATE BETWEEN '" + this.datePipe.transform(this.EXPIRY_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.EXPIRY_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var lastMaintenanceDateFilter = "";
    if ((this.LAST_MAINTENANCE_DATE != undefined) && (this.LAST_MAINTENANCE_DATE.length != 0)) {
      lastMaintenanceDateFilter = " AND (LAST_MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.LAST_MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.LAST_MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var nextMaintenanceDateFilter = "";
    if ((this.NEXT_MAINTENANCE_DATE != undefined) && (this.NEXT_MAINTENANCE_DATE.length != 0)) {
      nextMaintenanceDateFilter = " AND (NEXT_MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.NEXT_MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.NEXT_MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.loadingRecords = true;
    this.api.getAllAssets(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + categoryFilterStr + statusFilterStr + allocatedToFilterStr + purchaseDateFilter + expiryDateFilter + lastMaintenanceDateFilter + nextMaintenanceDateFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.assetData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  @ViewChild(AssetDrawerComponent, { static: false }) AssetDrawerComponentVar: AssetDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Asset";
    this.drawerData = new Asset();
    this.drawerVisible = true;
    this.AssetDrawerComponentVar.assetCategoryYesNo = false;
    this.drawerData.MAINTENANCE_FREQUENCY_TYPE = "M";
    // this.getAssetLog()
  }

  edit(data: Asset): void {
    this.drawerTitle = "Update Asset";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.AssetDrawerComponentVar.assetCategoryYesNo = true;
    if ((this.datePipe.transform(this.drawerData.EXPIRY_DATE, "yyyyMMdd")) === '19000101')
      this.drawerData.EXPIRY_DATE = null;
  }

  drawerClose(): void {
    this.search();
    // this.getAssetLog()
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  logdrawerClose(): void {
    this.logdrawerVisible = false
  }

  isAllocateReAllocateModalVisible = false;
  isConfirmLoading = false;
  allocateReAllocateModalTitle: string = "";
  allocateReAllocateModalBtnTitle: string = "";
  allocationReAllocationData: any;
  NEW_EMPLOYEE_ID: number;

  showAllocatioReAllocationModal(data: Asset): void {
    console.log(data);
    this.allocationReAllocationData = data;
    this.isAllocateReAllocateModalVisible = true;
    this.allocateReAllocateModalTitle = (data["ALLOCATED_EMPLOYEE_ID"] == 0) ? "Allocation of " + data["NAME"] + " (" + data["ASSET_CODE"] + ")" : "Re-Allocation of " + data["NAME"] + " (" + data["ASSET_CODE"] + ")";
    this.allocateReAllocateModalBtnTitle = (data["ALLOCATED_EMPLOYEE_ID"] == 0) ? "Allocate" : "Re-Allocate";
    this.NEW_EMPLOYEE_ID = undefined;

    let empID: number;
    if ((data['ALLOCATED_EMPLOYEE_ID'] == undefined) || (data['ALLOCATED_EMPLOYEE_ID'] == null) || (data['ALLOCATED_EMPLOYEE_ID'] == 0))
      empID = 0;
    else
      empID = data['ALLOCATED_EMPLOYEE_ID'];

    this.getEmployeeList(empID);
  }

  handleAllocateReAllocateModalCancel(): void {
    this.isAllocateReAllocateModalVisible = false;
  }

  employees = [];

  getEmployeeList(empID) {
    this.employees = [];
    this.employeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1 AND ID!=' + empID).subscribe(data => {
      if (data['code'] == 200) {
        this.employees = data['data'];
        this.employeeLoading = false;
      }

    }, err => {
      this.employeeLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  employeeLoading: boolean = false;

  handleAllocateReAllocateModalOk(): void {
    var isOk = true;

    if (this.NEW_EMPLOYEE_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Employee", "");
    }

    if (isOk) {
      this.isConfirmLoading = true;
      var data = new Object();
      data["ASSET_ID"] = this.allocationReAllocationData["ID"];
      data["EMPLOYEE_ID"] = this.NEW_EMPLOYEE_ID;

      if (this.allocationReAllocationData["ALLOCATED_EMPLOYEE_ID"] == 0)
        data["STATUS"] = "A";
      else
        data["STATUS"] = "RA";

      this.api.allocatingAssetWithoutRequest(data).subscribe(successCode => {
        if (successCode['code'] == 200) {
          if (data["STATUS"] == "A")
            this.message.success("Asset Allocated Successfully", "");

          else if (data["STATUS"] == "RA")
            this.message.success("Asset Re-Allocated Successfully", "");

          this.search();

          this.asset.ASSEST_ID = data['ASSET_ID']
          this.asset.ALLOCATED_BY = data["EMPLOYEE_ID"]
          this.asset.DATE = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
          this.asset.DESCRIPTION = ""
          this.asset.LOG_ACTION = 'CREATED'
          this.asset.LOG_TYPE = 'I'
          this.asset.MAINTAINANCE_BY = this.allocationReAllocationData["ID"];
          this.asset.REMARK = this.MAINTENANCE_REMARK
          this.asset.USER_ID = Number(this.cookie.get('userId'))
          this.api.craeteAssetLog(this.asset).subscribe(data => {
            if (data['code'] = 200) {
            }
          })
          this.isConfirmLoading = false;
          this.isMaintenanceModalVisible = false;

        } else {
          if (data["STATUS"] == "A")
            this.message.error("Failed to Allocating the Asset", "");

          else if (data["STATUS"] == "RA")
            this.message.error("Failed to Re-Allocating the Asset", "");

          this.isConfirmLoading = false;
        }

      }, err => {
        this.isConfirmLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  isMaintenanceModalVisible = false;
  isMaintenanceModalConfirmLoading = false;
  maintenanceModalTitle: string = "";
  maintenanceData: any;
  lastMaintenanceDate: any;
  nextMaintenanceDate: any;

  showMaintenanceModal(data: Asset): void {
    console.log(data);
    this.maintenanceData = data;
    this.isMaintenanceModalVisible = true;
    this.maintenanceModalTitle = "Maintenance of " + data["NAME"] + " (" + data.ASSET_CODE + ")";
    this.MAINTENANCE_DONE_BY = undefined;
    this.MAINTENANCE_REMARK = undefined;
    this.lastMaintenanceDate = undefined;
    this.nextMaintenanceDate = undefined;

    this.lastMaintenanceDate = new Date(data.LAST_MAINTENANCE_DATE);
    var tempLastMaintenanceDate = new Date();

    if (data.MAINTENANCE_FREQUENCY_TYPE == "D")
      this.nextMaintenanceDate = tempLastMaintenanceDate.setDate(tempLastMaintenanceDate.getDate() + Number(data.MAINTENANCE_FREQUENCY));

    else if (data.MAINTENANCE_FREQUENCY_TYPE == "M")
      this.nextMaintenanceDate = tempLastMaintenanceDate.setMonth(tempLastMaintenanceDate.getMonth() + Number(data.MAINTENANCE_FREQUENCY));

    else if (data.MAINTENANCE_FREQUENCY_TYPE == "Y")
      this.nextMaintenanceDate = tempLastMaintenanceDate.setFullYear(tempLastMaintenanceDate.getFullYear() + Number(data.MAINTENANCE_FREQUENCY));

    this.nextMaintenanceDate = new Date(this.nextMaintenanceDate);
  }

  handleMaintenanceModalCancel(): void {
    this.isMaintenanceModalVisible = false;
  }

  MAINTENANCE_DONE_BY: string;
  MAINTENANCE_REMARK: string;

  handleMaintenanceModalOk(): void {
    var isOk = true;

    if ((this.MAINTENANCE_DONE_BY == undefined) || (this.MAINTENANCE_DONE_BY.trim() == "")) {
      isOk = false;
      this.message.error("Please Enter Valid Employee Name", "");
    }

    if ((this.MAINTENANCE_REMARK == undefined) || (this.MAINTENANCE_REMARK.trim() == "")) {
      isOk = false;
      this.message.error("Please Enter Valid Remark", "");
    }

    if (isOk) {
      this.isMaintenanceModalConfirmLoading = true;
      var data = new Object();
      data["ASSET_ID"] = this.maintenanceData["ID"];
      data["MAINTENANCE_DONE_BY"] = this.MAINTENANCE_DONE_BY;
      data["REMARK"] = this.MAINTENANCE_REMARK;
      // data["LAST_MAINTENANCE_DATE"] = this.datePipe.transform(this.lastMaintenanceDate, "yyyy-MM-dd");
      // data["NEXT_MAINTENANCE_DATE"] = this.datePipe.transform(this.nextMaintenanceDate, "yyyy-MM-dd");

      this.api.doAssetMaintenance(data).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Asset Maintenance Information Added Successfully", "");
          this.isMaintenanceModalConfirmLoading = false;
          this.search();

          this.asset.ASSEST_ID = data['ASSET_ID']
          this.asset.ALLOCATED_BY = this.maintenanceData["ALLOCATED_EMPLOYEE_ID"]
          this.asset.DATE = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
          this.asset.DESCRIPTION = ""
          this.asset.LOG_ACTION = 'CREATED'
          this.asset.LOG_TYPE = 'I'
          this.asset.MAINTAINANCE_BY = this.maintenanceData["ID"];
          this.asset.REMARK = this.MAINTENANCE_REMARK
          this.asset.USER_ID = Number(this.cookie.get('userId'))
          this.api.craeteAssetLog(this.asset).subscribe(data => {
            if (data['code'] = 200) {
              this.isMaintenanceModalVisible = false;
            }
          })

        } else {
          this.message.error("Failed to Add Asset Maintenance Information", "");
          this.isMaintenanceModalConfirmLoading = false;
        }

      }, err => {
        this.isMaintenanceModalConfirmLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }

  }

  cancel(): void { }

  deAllocateData: any;

  deAllocateAsset(data: Asset): void {
    this.deAllocateData = data;
    console.log(this.deAllocateAsset);

    var assetData = new Object();
    assetData["ASSET_ID"] = this.deAllocateData["ID"];
    assetData["STATUS"] = "DA";

    this.api.allocatingAssetWithoutRequest(assetData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Asset De-Allocated Successfully", "");
        this.search();

        this.asset.ASSEST_ID = assetData['ASSET_ID']
        console.log(assetData['ASSET_ID']);
        this.asset.ALLOCATED_BY = this.assetData["ALLOCATED_EMPLOYEE_ID"]
        this.asset.DATE = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        this.asset.DESCRIPTION = ""
        this.asset.LOG_ACTION = 'CREATED'
        this.asset.LOG_TYPE = 'I'
        this.asset.MAINTAINANCE_BY = this.deAllocateData["ID"];
        this.asset.REMARK = this.MAINTENANCE_REMARK
        this.asset.USER_ID = Number(this.cookie.get('userId'))
        this.api.craeteAssetLog(this.asset).subscribe(data => {
          if (data['code'] = 200) {
          }
        })

      } else {
        this.message.error("Failed to De-Allocating the Asset", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  scrapData: any;

  scrapAsset(data: Asset): void {
    this.scrapData = data;
    var assetData = new Object();
    assetData["ASSET_ID"] = this.scrapData["ID"];
    assetData["STATUS"] = "S";
    console.log(assetData);

    this.api.allocatingAssetWithoutRequest(assetData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Asset Scrapped Successfully", "");
        this.search();

        this.asset.ASSEST_ID = assetData['ASSET_ID']
        console.log(assetData['ASSET_ID']);
        this.asset.ALLOCATED_BY = this.assetData["ALLOCATED_EMPLOYEE_ID"]
        this.asset.DATE = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        this.asset.DESCRIPTION = ""
        this.asset.LOG_ACTION = 'CREATED'
        this.asset.LOG_TYPE = 'I'
        this.asset.MAINTAINANCE_BY = this.scrapData["ID"];
        this.asset.REMARK = this.MAINTENANCE_REMARK
        this.asset.USER_ID = Number(this.cookie.get('userId'))
        this.api.craeteAssetLog(this.asset).subscribe(data => {
          if (data['code'] = 200) {
          }
        })

      } else {
        this.message.error("Failed to Scrap the Asset", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  CATEGORY_ID = [];
  STATUS = [];
  ALLOCATED_EMPLOYEE_ID = [];
  PURCHASE_DATE = [];
  EXPIRY_DATE = [];
  LAST_MAINTENANCE_DATE = [];
  NEXT_MAINTENANCE_DATE = [];
  categories = [];
  nxtMaiDate = ''


  getAssetCategory() {
    this.categories = [];

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' AND IS_LAST=1').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.CATEGORY_ID = [];
    this.STATUS = [];
    this.ALLOCATED_EMPLOYEE_ID = [];
    this.PURCHASE_DATE = [];
    this.EXPIRY_DATE = [];
    this.LAST_MAINTENANCE_DATE = [];
    this.NEXT_MAINTENANCE_DATE = [];
    this.search(true);
  }

  applyFilter() {
    if ((this.CATEGORY_ID.length > 0) || (this.STATUS.length > 0) || (this.ALLOCATED_EMPLOYEE_ID.length > 0) || (this.PURCHASE_DATE.length > 0) || (this.EXPIRY_DATE.length > 0) || (this.LAST_MAINTENANCE_DATE.length > 0) || (this.NEXT_MAINTENANCE_DATE.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  allocatedEmployees = [];
  allocatedEmployeeLoading = false;

  getAllocatedEmployeeList() {
    this.allocatedEmployees = [];
    this.allocatedEmployeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.allocatedEmployees = data['data'];
        this.allocatedEmployeeLoading = false;
      }

    }, err => {
      this.allocatedEmployeeLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getMaintenanceFrequencyInFullForm(frequency) {
    if (frequency == "D")
      return "Day(s)";

    else if (frequency == "M")
      return "Month(s)";

    else if (frequency == "Y")
      return "Year(s)";
  }

  DATE_FOR_MAINTENANCE = new Date();

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.lastMaintenanceDate) < 0;
    assetLogData=[]
  viewLog(data: any) {
    console.log(data);
    this.logdrawerVisible = true
    this.logdrawerTitle = "Asset Logs"
    this.api.getAssetLog(0, 0, 'ID', 'desc', '').subscribe(data => {
      console.log(data['data']);
      this.assetLogData = data['data']
    })
  }

}
