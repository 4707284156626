import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
// import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  pageIndex=0
  pageSize=10
  sortKey=''
  sort=''
  loadingRecords=false
  isVisible23=false
  totalRecords=[]
  dataList=[]

  quotationId=[]
 @Input() recieptData 
 @Input()isSpinning=false
 imgUrl1:any
  constructor(private api:ApiService) { }

  
  ngOnInit() {
   this.imgUrl1 =  this.api.retriveimgUrl
    
    this.api.getMemoDetails(this.pageIndex, this.pageSize, this.sortKey, ''," AND APPROVER_ID=" + this.api.userId).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        for (let i = 0; i < this.dataList.length; i++) {
           this.quotationId = this.dataList[i]['QUOTATION_ID']    
           console.log(this.quotationId);      
        }
      }
  })
}
url:any
open(item){
  this.imgUrl1 =  this.api.retriveimgUrl
  this.url=item
  console.log(this.imgUrl1+"receiptPhoto/"+this.url);
  this.isVisible23=true
}
handleaddsubtask(){

}
handleCancel(){
  this.isVisible23=false
}

}
