import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-asset-log-table',
  templateUrl: './asset-log-table.component.html',
  styleUrls: ['./asset-log-table.component.css']
})
export class AssetLogTableComponent implements OnInit {
 @Input() logs=[]
 
  totalRecords=[]
  loadingRecords = true
  pageIndex=0
  pageSize=0
  data
  constructor(private api:ApiService) { }

  ngOnInit() {
    this.getAssetLog()
  }

  getAssetLog(){
    this.api.getAssetLog(this.pageIndex,this.pageSize,'ID','desc','').subscribe(data =>{
      console.log(data.data);
      this.logs=data['data']
      this.totalRecords=data['count']
      this.loadingRecords=false
    })
  }

}
