export class memoMaster{
    ID:any
    DATE:any
    QUOTATION_ID:any;
    CREATOR_ID:any;
    MEMO_NO:any
    STATUS:any
    INVOICE_NO:any
    APPROVED_DATETIME:any
    APPROVER_ID:any
    PDF_URL:any
    REMARK:any
    CUSTOMER_NAME:any
    ADDRESS1:any
    ADDRESS2:any
    STATE:any
    CITY:any
    SALES_EXECUTIVE_NAME:any
    CUSTOMER_MOBILE_NO:any
    ADDITIONAL_DISCOUNT
    IS_RSA_AVAILABLE
    QUOTATION_NO
    MODEL_NAME
    COLOR
    FUEL_NAME
    TRANSMISSION_TYPE
    VARIENT_NAME
    VIN_NO
    ACC_REMARK
    REG_NO
    CR_DATE
    INSURANCE_DATE
    IS_CORPORATE
    IS_SAMBHADH_SCHEME
}