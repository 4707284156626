import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { tourExpenseMaster } from 'src/app/Models/tourExpenseMaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { Console, log } from 'console';
@Component({
  selector: 'app-tour-expense-form',
  templateUrl: './tour-expense-form.component.html',
  styleUrls: ['./tour-expense-form.component.css']
})

export class TourExpenseFormComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: any = tourExpenseMaster;
  @Input() drawerVisible: boolean;
  @Input() HEAD_STATUS: boolean
  @Input() HR_STATUS: boolean
  @Input() ACCOUNTANT_STATUS: boolean
  // @Input() PAYMENT_STATUS
  @Input() GM_STATUS: boolean

  orgId = this.cookie.get('orgId');
  ROLE_ID = Number(this.cookie.get('roleId'))
  dateFormat = "dd/MMM/yyyy";
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  userId = Number(this.cookie.get("userId"));

  HEAD_APPROVED_DATETIME = new Date()
  HR_APPROVED_DATETIME = new Date()
  ACCOUNTANT_APPROVED_DATETIME = new Date()
  d1: any
  d2: any
  d3: any
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {

  }

  APPLICATION_DATE: any
  DATE: any

  categories = [];
  employees = []

  data1() {
    if (this.data.HR_STATUS == "Approved") {
      this.data.HR_STATUS = "AP"
    }
    else {
      this.data.HR_STATUS = "R"
    }
  }

  switchChange2(e1: boolean) {
    this.HR_STATUS = e1;
    if (this.HR_STATUS = true) {
      this.data.HR_STATUS = "AP"

    }
    else {
      this.data.HR_STATUS = "R"

    }

  }

  switchChange1(e1: boolean) {
    this.HEAD_STATUS = e1;
    if (this.HEAD_STATUS = true) {
      this.data.HEAD_STATUS = "AP"

    }
    else if (this.HEAD_STATUS = false) {
      this.data.HEAD_STATUS = "R"

    }
    console.log()
  }

  // ACCOUNTANT_STATUS

  loadDataFilter() {
    this.employees = [];

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', " AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")").subscribe(employees => {
      if (employees['code'] == 200) {
        this.employees = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  modes = []
  loadDataFilter1() {
    this.modes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.modes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  classes = []
  loadDataFilter2() {
    this.classes = [];

    this.api.getAllEmployees(0, 0, '', 'asc', " ").subscribe(employees => {
      if (employees['code'] == 200) {
        this.classes = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  designations = []
  loadDataFilter3() {
    this.designations = [];

    this.api.getAllEmployees(0, 0, 'NAME ', 'asc', ' AND ORG_ID =' + this.orgId).subscribe(employees => {
      if (employees['code'] == 200) {
        this.designations = employees['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
    this.rejectbutton = true
    this.forward = true
    this.submitbutton = true
  }

  submitbutton: boolean = true

  paymentstatus() {
    this.submitbutton = false
  }
  TypeDetails = []

  getTourExpense() {

    this.api.getTourExpense(0, 0, '', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.TypeDetails = data['data'];

      }

    }, err => {
      console.log(err);
    });
  }
  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  rejectbutton: boolean = true
  forward: boolean = true

  remarkchange() {
    this.rejectbutton = false
    this.forward = false
  }

  isSpinning = false;
  requestedData: any
  date1 = new Date()

  reject(myForm: NgForm) {

    var isOk = true;
    if (this.ROLE_ID != 14 && this.ROLE_ID != 13 && this.ROLE_ID != 30) {


      if (this.data.HEAD_REMARK == undefined || this.data.HEAD_REMARK == null || this.data.HEAD_REMARK == '') {

        isOk = false;
        this.message.error("Please Enter Remark", "");
      } else {
        this.data.HEAD_STATUS = "R"
        this.data.FORWARD_APPROVER_ID = this.userId
      }

    }

    else if (this.ROLE_ID == 14) {

      // this.data.HR_REMARK ="R"

      if (this.data.HR_REMARK == undefined || this.data.HR_REMARK == null || this.data.HR_REMARK == '') {


        isOk = false;
        this.message.error("Please Enter HR Remark", "");
      } else {
        this.data.HR_STATUS = "R"
        this.data.FORWARD_APPROVER_ID = this.userId
      }

    }

    else if (this.ROLE_ID == 13) {


      if (this.data.ACCOUNTANT_REMARK == undefined || this.data.ACCOUNTANT_REMARK == null || this.data.ACCOUNTANT_REMARK == '') {


        isOk = false;
        this.message.error("Please Enter Accountant Remark", "");
      } else {
        this.data.ACCOUNTANT_STATUS = "R"
        this.data.FORWARD_APPROVER_ID = this.userId
      }

    }
    else if (this.ROLE_ID == 30) {



      if (this.data.GM_REMARK == undefined || this.data.GM_REMARK == null || this.data.GM_REMARK == '') {
        isOk = false;
        this.message.error("Please Enter GM Remark", "");
      } else {
        this.data.GM_STATUS = "R"
        this.data.FORWARD_APPROVER_ID = this.userId
      }


    }

    // if (this.data.HEAD_REMARK == undefined || this.data.HEAD_REMARK == null || this.data.HEAD_REMARK == '') {
    //   isOk = false;
    //   this.message.error("Please Enter Remark", "");
    // }


    if (isOk) {
      this.data.STATUS = 'R';
      this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd');
      console.log(this.data.STATUS)
      if (this.data.ID) {
        this.api.updateExpense(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            // if(this.data.STATUS != "R"){
            //   this.message.success("Tour Approve Successfully", "");
            // }
            // else{
            this.message.warning("Expense Rejected", "");
            // }

            this.isSpinning = false;
            this.close(myForm);

          } else {
            this.message.error("Expense Rejection Failed", "");
            this.isSpinning = false;
            this.isVisible = false
          }
        });

      }

    }
  }



  APPROVER_ID: any

  // approveforward: boolean = true
  forwardtodo() {
    // this.approveforward = false
  }

  cancel(){
    
  }
  isSpinning11 = false

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;
  
    // this.switchChange1(this.d1)
    this.switchChange2(this.d2)
    // this.switchChange3(this.d3)

    if (this.ROLE_ID != 14 && this.ROLE_ID != 13 && this.ROLE_ID != 30) {


      // if (this.data.HEAD_REMARK == undefined  || this.data.HEAD_REMARK == null || this.data.HEAD_REMARK == '') {

      //   isOk = false;
      //   this.message.error("Please Enter Remark", "");
      // }else{

      this.data.HEAD_STATUS = 'AP'
      this.data.STATUS = 'FW'
      this.data.APPROVER_ID = this.APPROVER_ID
      this.data.FORWARD_APPROVER_ID = this.userId
      this.data.HEAD_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");
    }
    // else if (this.data.APPROVED_AMOUNT == undefined) {
    //   isOk = false;
    //   this.message.error("Please Enter Approved Amount", "");
    // }


    // this.isVisible = true

    // this.data.HEAD_STATUS = 'R'

    // }

    else if (this.ROLE_ID == 14) {
      // if (this.HR_STATUS == true) {
      // if (this.data.HR_REMARK == undefined) {
      //   isOk = false;
      //   this.message.error("Please Enter HR Remark", "");
      // } else {
      this.data.HR_STATUS = 'AP'
      this.data.APPROVER_ID = this.APPROVER_ID
      this.data.FORWARD_APPROVER_ID = this.userId
      this.data.HR_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");
      this.data.HR_ID = this.userId;
      // }


      // }
      // else {
      // this.data.HR_STATUS = 'R'
      // }
    }

    else if (this.ROLE_ID == 13) {



      // if (this.data.APPROVED_AMOUNT == 0 || this.data.APPROVED_AMOUNT == undefined ) {
        isOk = false;
      //   this.message.error("Please Enter Approved amount", "");
      // }

      this.data.APPROVER_ID = this.userId
      this.data.FORWARD_APPROVER_ID = this.userId
      this.data.ACCOUNTANT_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");
      this.data.ACCOUNTANT_ID = this.userId;
      this.data.STATUS = 'AP'

      // else{
      //   this.data.ACCOUNTANT_STATUS = "AP"

      // this.data.ACCOUNTANT_ID.PAYMENT_STATUS = this.PAYMENT_STATUS
      // } 



    }

    else if (this.ROLE_ID == 30) {
      console.log(this.GM_STATUS)
      // if (this.GM_STATUS == true) {


      this.data.GM_STATUS = 'AP'
      this.data.APPROVER_ID = this.APPROVER_ID
      this.data.FORWARD_APPROVER_ID = this.userId
      this.data.GM_APPROVED_DATETIME = this.datePipe.transform(this.date1, "yyyy-MM-dd HH:mm" + ":00");
      this.data.GM_ID = this.userId;



      // }
      // else {
      // console.log(this.GM_STATUS)
      // this.data.GM_STATUS = 'R'
      // }
    }


    // if (this.ROLE_ID == 13 && this.ACCOUNTANT_STATUS == true) {

    // }
    // else {
    //   this.data.STATUS = 'FW'
    // }

    // if(this.APPROVER_ID !){

    // }

    if (this.ROLE_ID != 13) {
      if (this.APPROVER_ID == null && this.APPROVER_ID == undefined) {
        isOk = false;
        this.message.error("Please Select Employee..", "")
      }
      else {
        this.data.APPROVER_ID = this.APPROVER_ID
        this.data.STATUS = 'FW'
      }
    }

    else {
      if(this.data.PAYMENT_STATUS == '' || this.data.PAYMENT_STATUS == null || this.data.PAYMENT_STATUS == undefined){
       isOk = false
        this.message.error("Please select payment status","")
      }
      else{
        isOk = true
        this.data.APPROVER_ID = this.userId
        this.data.STATUS = 'AP'
      }
      }
    
    if (isOk) {
      console.log(this.data);
      this.isSpinning = true;
      this.isSpinning11 = true
     
      // if()

      if (this.data.ID) {
        this.data.APPLICATION_DATE = this.datePipe.transform(this.data.APPLICATION_DATE, 'yyyy-MM-dd');
        this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd');
  
        this.api.updateExpense(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {

            this.isSpinning11 = false
            if (this.ROLE_ID == 13) {
              this.message.success("Expense Approved", "");
            } else {
              this.message.success("Expense Forwarded", "");
            }
            this.isVisible = false

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;
            this.APPROVER_ID =null

          } else {
            this.message.error("Failed To Forward Expense", "");
            this.isSpinning = false;
            this.isVisible = false
            this.isSpinning11 = false
            this.APPROVER_ID =null
          }
        });

      }
      // }

      // this.isSpinning = true;

    }
  }

  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: Date): void {
    console.log('onOk', result);
  }

  emppData: any = []
  isVisible: boolean = false;

  handleOk(): void {

    this.api.getAllEmpRoleMap(0, 0, "ID", "asc", ' AND ROLE_ID IN (14) ' + " AND CLUSTER_ID =" + this.clusterId).subscribe(data => {
      if (data["code"] == 200) {
        this.emppData = data["data"];
        console.log(this.emppData, "this.emppData")
      }
      (err) => {
        if (err["ok"] == false) this.message.error("Server Not Found", "");
      }
    });

  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    // this.approveforward = true
  }
  clusterId = Number(this.cookie.get('clusterId'));

  modalOpen() {

   
    var filter

    // if (this.ROLE_ID == 14) {
    //   filter = ' AND ROLE_ID IN (30,13)'
    // }
    // else if (this.ROLE_ID == 30) {
    //   filter = ' AND ROLE_ID IN (13)'
    // }

    // else {
      
    // }
    this.APPROVER_ID =null
    if (this.ROLE_ID != 14 && this.ROLE_ID != 13 && this.ROLE_ID != 30) {


      if (this.data.HEAD_REMARK == undefined || this.data.HEAD_REMARK == null || this.data.HEAD_REMARK == '') {

        // isOk = false;
        this.message.error("Please Enter Remark", "");
      }

      else{
        this.isVisible = true

        filter = ' AND ROLE_ID IN (14)'
        this.api.getAllEmpRoleMap(0, 0, "ID", "asc", filter + " AND CLUSTER_ID =" + this.clusterId).subscribe(data => {
          if (data["code"] == 200) {
            this.emppData = data["data"];
            console.log(this.emppData, "this.emppData")
          }
          
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        });
      }

    }

    else if (this.ROLE_ID == 14) {
      // if (this.HR_STATUS == true) {
      if (this.data.HR_REMARK == undefined || this.data.HR_REMARK == null || this.data.HR_REMARK == '') {
        // isOk = false;
        this.message.error("Please Enter HR Remark", "");
      }

      else{
        this.isVisible = true

        filter = ' AND ROLE_ID IN (30,13)'
        this.api.getAllEmpRoleMap(0, 0, "ID", "asc", filter + " AND CLUSTER_ID =" + this.clusterId).subscribe(data => {
          if (data["code"] == 200) {
            this.emppData = data["data"];
            console.log(this.emppData, "this.emppData")
          }
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        });
      }
    }

    else {


      if (this.data.GM_REMARK == undefined || this.data.GM_REMARK == null || this.data.GM_REMARK == '') {

        this.message.error("Please Enter GM Remark", "");
      }
      else{
        this.isVisible = true

        filter = ' AND ROLE_ID IN (13)'
        this.api.getAllEmpRoleMap(0, 0, "ID", "asc", filter + " AND CLUSTER_ID =" + this.clusterId).subscribe(data => {
          if (data["code"] == 200) {
            this.emppData = data["data"];
            console.log(this.emppData, "this.emppData")
          }
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        });
      }

    }

    // else{
     

    
   
    }

   

  // }
}


